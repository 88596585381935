import React from "react";
import { Form, Col } from "react-bootstrap";
import { UseFormMethods } from "react-hook-form";

interface Props<Type>
{
	form? 		: any;//UseFormMethods<Type>;
	
	name? 		: string;
	label? 			: string;
	placeholder? 	: string;
	adicionalText? : string;
	length? 	: number;
	rows? 		: number;
	required? 	: boolean;
	readonly? 	: boolean;
	layoutColumns? 	: number;
	enabled?	:boolean;
	key?			: any;
	defaultValue?	: any;
	isInvalidCheck? : any;
	noLabel?	: boolean;
	sup?		: any;
}

export default function TextArea<Type> (props: Props<Type>)
{
	const [enabled_, setEnabled] = React.useState<boolean>(true);
	const [defaultName, setDefaultName] = React.useState<string>("TextArea");

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    }, [props.enabled]);
	
	return <Form.Group as={Col} md={props.layoutColumns ?? 12}>
		{props.noLabel === true ? '' :
		<Form.Label>
			{props.label}{props.sup ? <sup>{props.sup}</sup>:''} {props.required ? <em>*</em> : ''}
		</Form.Label>
		}
		<Form.Control
			key={props.key}
			defaultValue={props.defaultValue}
			ref={props?.form?.register}
			name={props.name ?? defaultName}			
			placeholder={props.placeholder}
			type="text"
			as="textarea"
			readOnly={props.readonly ?? false}
			rows={props.rows ?? 5}
			maxLength={props.length}
			disabled={!enabled_}
			// isInvalid={props.form.errors[props.name] !== undefined}
			isInvalid={
				(props.isInvalidCheck === true && enabled_) ? (
				(props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined
				): false 
			}
		/>
		{props.adicionalText ? <span className="small text-info">{props.adicionalText}</span> : ''}
		<Form.Control.Feedback type="invalid">					
			{props?.name ?props.form?.errors[props?.name]?.message : ''}
		</Form.Control.Feedback>
	</Form.Group>;
}