
import TextField from  "../../../components/form/TextField";
import CheckBox from "../../../components/form/CheckBox";
import DropDown from  "../../../components/form/DropDown";

import EditView, { EditField } from  "../../../components/page/EditView";
import { LpmaEquipeTecnica } from "../../../models/modelsLPMA";
import { useForm } from "react-hook-form";
import LpmaService from "../services";
import { STRINGS_LPMA } from "../../../util/lpma/string";
import RadioBox from "../../../components/form/RadioBox";
import FileUpload from "../../../components/form/FileUpload";
import DataHide from "../../../components/form/DataHide";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { STRINGS } from "../../../util/strings";

export default function Edit (){
	const LpmaServiceApi = new LpmaService();
	const [entity, setEntity] = React.useState<LpmaEquipeTecnica>();

	const defaultForm = useForm({
		defaultValues: {
			ativo: true,
		} as any,
		mode: "onSubmit",
		resolver: yupResolver(yup.object().shape({
			nome 		: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
			titulacao 	: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
			documento 	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
			telefone	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
			email 		: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		}))
	});
	const Titulacao =[
		{id: 1, nome: "Biológo"},
		{id: 2, nome: "Biológa"},
		{id: 3, nome: "Engenheiro Agrônomo"},
		{id: 4, nome: "Engenheira Agrônoma"},
		{id: 5, nome: "Farmaceutica"},
		{id: 6, nome: "Farmaceutico"},
	]

	const MemBroCIBIO = ()=>{
		const funcaoCIBIO = Number(defaultForm.watch("funcaoCIBIO") ?? 0);
		return funcaoCIBIO === 1 || funcaoCIBIO === 2;
	}

	return <EditView<LpmaEquipeTecnica> 
		title={STRINGS_LPMA.EDITION_EQUIPE_TECNICA}
		getLoadById={async (id: any)=>{ 
			let dados = await LpmaServiceApi.getEquipeTecnicaById(id);
			let t = Titulacao.find(e=> e.id === dados.titulacao);
			dados.titulacao = t ? Object.assign({ value: t.id, label: t.nome }) : null;
			return dados;
		 }}
		 form={defaultForm}
		 getEntity={(entity:any)=>{
			setEntity(entity)
		 }}
		 MsgSaveSubmitSucess="Tecnico Salvo com Sucesso"
		 OnSaveSubmit={async (values:any, entity: any)=>{	
			try{
				let formData = new FormData();
				const newvalues = {
					...values,
					funcaoCIBIO	: Number(values?.funcaoCIBIO ?? 0),
					titulacao	: values?.titulacao?.value ?? 1,
				}
				const iFile = defaultForm.getValues('curriculoFileTemp');
				if(iFile) {for(let i = 0, t = iFile.length; i < t; i++){
					//newvalues['curriculoFile'] = iFile[i];
					formData.append('curriculoFile', iFile[i]);    	         
				}}
				const aFile = defaultForm.getValues('assinaturaFileTemp');
				if(aFile) {for(let i = 0, t = aFile.length; i < t; i++){
					formData.append('assinaturaFile', aFile[i]);    	         
				}}
				for (var key in newvalues) {
					const valueByKey = newvalues[key];
					if(key === 'assinaturaFileTemp') 	{continue;}
					if(key === 'curriculoFileTemp') 	{continue;}
					if(valueByKey === null || valueByKey === undefined) {continue;}

					formData.append(key, valueByKey);
				}
				await LpmaServiceApi.getEquipeTecnicaSave(formData); 
				return {isOk: true};
			} catch (error:any) {
				return {isOk: false};
			}			 
		 }}
		 OnSaveSubmitIsOkGoBack={false}	 
		>
		<TextField<LpmaEquipeTecnica> form={defaultForm} required isInvalidCheck name="nome" label="Nome do Tecnico" layoutColumns={8}  />
		<DropDown form={defaultForm} required isInvalidCheck name="titulacao" label="Titulação" layoutColumns={4} options={Titulacao.map(e=> Object.assign({ value: e.id, label: e.nome }))} />
		<TextField<LpmaEquipeTecnica> form={defaultForm} required isInvalidCheck name="documento" label="Documento/CPF/CREA" layoutColumns={4}  />
		<TextField<LpmaEquipeTecnica> form={defaultForm} name="link_CV_Lattes" label="Curriculo Lates" layoutColumns={4}  />
		<TextField<LpmaEquipeTecnica> form={defaultForm} required isInvalidCheck name="telefone" label="Telefone" layoutColumns={4}  />
		<TextField<LpmaEquipeTecnica> form={defaultForm} required  isInvalidCheck name="email" label="E-mail" layoutColumns={4}  />
		<TextField<LpmaEquipeTecnica> form={defaultForm} name="userName" label="(UserName) Usuário do Sistema" layoutColumns={8} />

		<CheckBox form={defaultForm} name="ativo" label="Ativo" {...defaultForm.register(`ativo`)} defaultChecked={entity?.ativo} layoutColumns={12}  />

		<TextField<LpmaEquipeTecnica> form={defaultForm} name="endereco" label="Endereco" layoutColumns={12}  />

		 
		<FileUpload layoutColumns={6} 
			enabled={true} form={defaultForm} 
			name="assinaturaFileTemp"
			otherName="assinaturaFileName"
			label="Assinatura (Imagem)" 
			text={entity?.assinaturaFileName} textButton="Inserir Assinatura" 
			onChange={(target:any,e:any)=>{				
				defaultForm.setValue(`assinaturaFileName`, e);
			}}
			// preview={true}
			urlpreview={entity?.curriculoFileUrlPreview}
			isClearable={true}
			clearRightPosition={160}
			accept="image/png, image/jpeg"
			/>				
		<DataHide form={defaultForm}  binds={[
			{defaultValue: entity?.assinaturaFileGuid, name: `assinaturaFileGuid`}, 
			{defaultValue: entity?.assinaturaFileName, name: `assinaturaFileName`},
			{defaultValue: entity?.assinaturaFileName, name: `assinaturaFileNameRemove`}]} 
		/>	

		<FileUpload layoutColumns={6} 
			enabled={true} form={defaultForm} 
			name="curriculoFileTemp"
			otherName="curriculoFileName"
			label="Curriculo (Arquivo de Imagem ou PDF)" 
			text={entity?.curriculoFileName} textButton="Inserir Curriculo" 
			onChange={(target:any,e:any)=>{
				defaultForm.setValue(`curriculoFileName`, e);
			}}
			// preview={true}
			urlpreview={entity?.curriculoFileUrlPreview}
			isClearable={true}
			clearRightPosition={160}
			accept="application/pdf, image/png, image/jpeg"
			/>				
		<DataHide form={defaultForm}  binds={[
			{defaultValue: entity?.curriculoFileGuid, name: `curriculoFileGuid`}, 
			{defaultValue: entity?.curriculoFileName, name: `curriculoFileName`},
			{defaultValue: entity?.curriculoFileName, name: `curriculoFileName-remove`}]} 
		/>	

		<EditField    title="Cargo na CIBIO" layoutColumns={9}>
			<RadioBox key="funcaoCIBIO.1" form={defaultForm} name="funcaoCIBIO" layoutColumns={4}
				{...defaultForm.register("funcaoCIBIO")}
				label="Presidente" value="2" enabled={true} 
				defaultChecked={entity?.funcaoCIBIO === 2}
				// textBoxName="cargoCIBIO"	
			/> 

			<RadioBox key="funcaoCIBIO.2" form={defaultForm} name="funcaoCIBIO" layoutColumns={4}
				label="Equipe" value="1" enabled={true} 
				defaultChecked={entity?.funcaoCIBIO === 1}
			/> 

			<RadioBox key="funcaoCIBIO.3" form={defaultForm} name="funcaoCIBIO" layoutColumns={4}
				label="Não é membro da CIBIO" value="0" enabled={true} 
				defaultChecked={entity?.funcaoCIBIO === 0 || entity?.funcaoCIBIO === null}

			/> 
			{MemBroCIBIO()
			?<TextField<LpmaEquipeTecnica> form={defaultForm} name="cargoCIBIO" label="Cargo" layoutColumns={12}  />
			: ''
			}
			
	
		</EditField>
	</EditView>
}
