export const STATUS = {
    EmPreenchimento:            1,
    EmAnalisePeloRegulatorio:   2,

    EmpAprovacao:               3,
    Aprovado:                   3,

    EmCorrecao:                 4,    
    EmAnaliseTecnico:           4,/* Tem o mesmo valor de  EmCorrecao, 
                                    é aplicado ao Relatório conclusivo como
                                    Status semelhante ao de correção*/

    Cancelado:                  6,
    Concluido:                  5,

    SAVE:                       10,
}

export const STATUSTEXT = [
    {t: 'Em Preenchimento', s: 1},
    {t: 'Em Análise Pelo Regulatório', s: 2},
    {t: 'Aprovado pelo Regulatório', s: 3},
    {t: 'Em Correção', s: 4},
    {t: 'Cancelado', s: 6},

];

export const STATUS_RC_LPMA = [
    {t: 'Em Preenchimento', s: 1},
    {t: 'Em Análise Pelo Regulatório', s: 2},
    {t: 'Aprovado pelo Regulatório', s: 3},
    {t: 'Em Análise Técnico', s: 4 },
    {t: 'Aprovado', s: 5}, 
    //{t: 'Cancelado', s: 6},

];

export const Titulacao =[
    {id: 1, nome: "Biológo"},
    {id: 2, nome: "Biológa"},
    {id: 3, nome: "Engenheiro Agrônomo"},
    {id: 4, nome: "Engenheira Agrônoma"},
    {id: 5, nome: "Farmaceutica"},
    {id: 6, nome: "Farmaceutico"},
]

// export const STATUSTEXT = [
//     'None',                             /*0*/
//     'Em Preenchimento',                 /*1*/
//     'Em Análise Pelo Regulatório',      /*2*/
//     'Aprovado pelo Regulatório',        /*3*/
//     'Em Correção',                      /*4*/    
//     'Cancelado',                        /*6*/
//     //'Concluído',                        /*5*/
// ];