
import TextField from  "../../../components/form/TextField";
import EditView from  "../../../components/page/EditView";
import { LpmaLocal } from "../../../models/modelsLPMA";
import { useForm } from "react-hook-form";
import LpmaService from "../services";
import { STRINGS_LPMA } from "../../../util/lpma/string";
import RichText from "../../../components/form/RichText";

export default function Edit (){
	const LpmaServiceApi = new LpmaService();
	
	const defaultForm = useForm({
		defaultValues: {} as any,
		mode: "onSubmit",
	});

	return <EditView<LpmaLocal> 
		title={STRINGS_LPMA.EDITION_LOCAL}
		getLoadById={async (id: any)=>{ 
			return await LpmaServiceApi.getLocaisById(id)
		 }}
		 form={defaultForm}
		 getEntity={(entity:any)=>{
			//console.log(entity);
		 }}
		 MsgSaveSubmitSucess="Local Salvo com Sucesso"
		 OnSaveSubmit={async (values:any, entity: any)=>{	
			try{
				await LpmaServiceApi.getLocaisSave(values); 
				return {isOk: true};
			} catch (error:any) {
				return {isOk: false};
			}		 
			 
		 }}
		 OnSaveSubmitIsOkGoBack={true}	 
		>
		<TextField<LpmaLocal> form={defaultForm} required name="nomeDaFazenda" label="Nome da Fazenda" layoutColumns={6}  />
		<TextField<LpmaLocal> form={defaultForm} required name="cnpj" label="CNPJ" layoutColumns={6}  />
		<TextField<LpmaLocal> form={defaultForm} name="nomeProprietario" label="Nome do Proprietario" layoutColumns={6}  />
		<TextField<LpmaLocal> form={defaultForm} required name="nomePessoaResponsavel" label="Nome do Técnico Responsável" layoutColumns={6}  />
		<TextField<LpmaLocal> form={defaultForm} required name="endereço" label="Endereço" layoutColumns={6}  />
		<TextField<LpmaLocal> form={defaultForm} required name="municipio" label="Municipio" layoutColumns={6}  />
		<TextField<LpmaLocal> form={defaultForm} required name="uf" label="UF" layoutColumns={6}  />
		<TextField<LpmaLocal> form={defaultForm} required name="cep" label="CEP" layoutColumns={6}  />
		<TextField<LpmaLocal> form={defaultForm} required name="pais" label="PAIS" layoutColumns={6}  />

		<TextField<LpmaLocal> form={defaultForm} required name="cultivosVizinhos" label="Cultivos Vizinhos" layoutColumns={12}   />		
		<RichText<LpmaLocal> form={defaultForm} required name="tiposBiomaVegetacao" label="Tipo do Bioma e Vegetação" layoutColumns={12}  />
		<RichText<LpmaLocal> form={defaultForm} required name="dadosClimaticos" label="Tipo de Clima" layoutColumns={12}  />
		<RichText<LpmaLocal> form={defaultForm} required name="dadosPedologicos" label="Dados Pedológicos (solo)" layoutColumns={12}  />


	</EditView>
}
