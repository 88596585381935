import React from 'react';
import { Spinner } from 'react-bootstrap';

function LoadingApplication() {
    return (
        <div style={{height: '60vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Spinner 
                animation="grow"
                variant="success"
                style={{width: 60, height: 60}}
            />
        </div>
    );
}

export default LoadingApplication;