export default function CheckMsgErros (props: any, setMsgErroFunc:any, setAsErroFunc:any, otherName: any = null)
{
    if(props?.name == null || props?.form == null){return;}
    const name_check = otherName ?? props?.name;

    let 	_asErro = (name_check ? props.form?.errors[name_check] : undefined)  !== undefined;
    const 	arrayNm = name_check?.includes('.') ?? false;
    if(name_check  && props.form?.errors && !_asErro && arrayNm){
        const _name_ = name_check.split('.')
        if(_name_.length >= 3)
        {
            let msg = props.form?.errors[_name_[0]];
            if(!(!msg)) 
            {
                msg = msg[_name_[1]];
                if(!(!msg)) {
                    const elm = {...msg}							
                    _asErro = (elm[_name_[2]] !== undefined);
                    if(_asErro) { 
                        setMsgErroFunc(elm[_name_[2]]?.message);
                        setAsErroFunc(_asErro)
                        return;
                    }
                }                
            }
        }
    }    
    const _msgx = props.form?.errors[name_check]?.message;
    setMsgErroFunc(_msgx);
    setAsErroFunc(_asErro)
}