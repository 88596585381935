import React from 'react';
import { Alert } from 'react-bootstrap';

interface Props {
    message: string;
}
          
function AlertError({ message }: Props) {
    return (
        <Alert variant="danger">
            <span className="alert-message">{message}</span>
        </Alert>
    );
}

export default AlertError;