import React from 'react';
import ToolTip from './Tooltip';
import { GoCircleSlash } from 'react-icons/go';

interface Props {
    onClick: any;
    disabled?: any;
}

function CancelRequisitionButton({ onClick, disabled }: Props) {
    return (
        <ToolTip tooltipTxt="Cancelar requisição">
            <GoCircleSlash style={{color: disabled ? '#c9cacc' : 'red'}} className="cancel-btn" size={20} onClick={disabled ? null : onClick} />
        </ToolTip>
    );
}

export default CancelRequisitionButton;