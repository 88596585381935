import React from "react";
import { Form, Col } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { Controller } from "react-hook-form";
import "./style.scss";

interface DateFieldProps
{
	form? : any;
	name?:string;
	label?:string;
	isInvalid?:boolean;
	placeholder?:string;
	register?:any;
	length?:number;
	layoutColumns?: number;
	required?: boolean;
    onDateChanged?: any;
    className?:string;
    isClearable?:boolean;
    enabled?:boolean;
    keyDisable?:boolean;
    dateFormat?:string;
    showMonthYearPicker?: boolean;
    showYearPicker?: boolean;
    maxDate?: Date;
    minDate?: Date;
    isInvalidCheck? : boolean;
    key?		: any;
    prefix?		: any;
	suffix?		: any;
}

export default function DateField (props: DateFieldProps)
{
    const defaultName = "DateField";
    const [enabled_, setEnabled] = React.useState<boolean>(true);
    const [asErro, setAsErro] = React.useState<boolean>(true);

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }

        let _asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
        setAsErro(_asErro)

    }, [props.enabled, props.form?.errors, props?.name]);
    
    return  <Form.Group className="custum-dateFiled-form-group" 
                as={Col} md={props.layoutColumns ?? 12}>
                {props.label ? 
                <><Form.Label>{props.label} {props.required ? <em>*</em> : ''}</Form.Label>
                <br /></>
                : ''}
                <Controller        
                    key={props.key}                
                    control={props.form.control}
                    name={props.name ?? defaultName}
                    readOnly={true}    
                    md={props.layoutColumns ?? 12}
                    prefix={props.prefix}
				    suffix={props.suffix}                    
                    isInvalid={(props.isInvalidCheck && asErro)}
                    render={({ onChange, value }) => (
                    <DatePicker                             
                        maxDate={props.maxDate}  
                        minDate={props.minDate}                              
                        onKeyDown={(d)=>{         
                            if(props.keyDisable === true)                       
                            {
                                d.preventDefault();
                                return false;
                            }
                        }}                       
                        selected={value}
                        disabled={!enabled_}
                        onChange={(d)=>{
                            onChange(d); 
                            if(props.onDateChanged)
                            {props.onDateChanged(d)}
                        }}
                        className={"input-custom-to-date custum_dateFiled " + (props.className ?? '')}
                        locale="pt-BR"
                        dateFormat={ props.dateFormat ?? "dd/MM/yyyy" }
                        showMonthYearPicker={props.showMonthYearPicker ?? false}
                        showYearPicker={props.showYearPicker ?? false}
                        placeholderText={props.placeholder}                        
                        isClearable={(props.isClearable ?? true) && enabled_}
                    />
                    )}                    
                />
                 {(props.isInvalidCheck && asErro)? 
                <p className="error-field">{props?.name ?props.form?.errors[props?.name]?.message : ''}</p>
                :''}
            </Form.Group>
}
