export const PERMISSIONS = {
    Admin: "lpma.admin",
    Regulatorio: "lpma.regulatorio",
    VisualizarTodas: "lpma.visualizar.todas",
    CriarRequisicao: "lpma.criar_requisicao",
    EditarRequisicao: "lpma.editar_requisicao",
    VisualizarMinhas: "lpma.visualizar.minhas",
    EditarTabelasAuxiliar: "lpma.editar_tabelas_auxiliar",
}



