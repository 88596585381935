import { PERMISSIONS } from '../usersPermissions/usersPermissionsCadernoConformidade';

export const isRequesterReader = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.RequisitanteRead);

    return isRole.length ? true : false;
}

export const isRequesterWriter = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.RequisitanteWrite);

    return isRole.length ? true : false;
}