import React from 'react';
import { FiTrash } from 'react-icons/fi';
import Tooltip from './Tooltip';

interface Props {
    onClick?: any;
    tooltip?: string;
}

function DeleteButton({ onClick, tooltip }: Props) {
    return (
        <Tooltip tooltipTxt={tooltip || 'Excluir'}>
            <FiTrash className="delete-btn" size={20} onClick={onClick} />
        </Tooltip>
    );
}

export default DeleteButton;