import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import If from '../../../../components/If';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/Button';
import EditButton from '../../../../components/EditButton';
import SearchEmpty from '../../../../components/SearchEmpty';
import api from '../../../../services/api';
import { BreederResults } from '../../../../models/models';
import { statusToText } from '../../../../functions/formatStatus';
import '../../styles_list.css';

export default function TechnicalTeam ()
{
	const history = useHistory();
	const [breeders, setBreeders] = useState<Array<BreederResults>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);

	const { register, handleSubmit, getValues } = useForm({
		defaultValues: {
			name: '',
			cpf: '',
			registrationClassOrgan: '',
			active: true
		}
	})

	useEffect(() => {
		const getBreeders = async () => {
			try {
				const { data } = await api.get<BreederResults[]>(`api/v1/cultivares/EquipeTecnicaMelhorista/obterporfiltro?Ativo=${getValues('active')}`);

				setBreeders(data);
			} catch (error:any) {
				setError(true);
			}
			setLoading(false);
		}
		getBreeders();
	}, [getValues])

	const search = async () => {
		setError(false);
		setLoadingSearch(true);

		try {
			const { data } = await api.get(`api/v1/cultivares/EquipeTecnicaMelhorista/obterporfiltro?Nome=${getValues('name')}&Cpf=${getValues('cpf')}&RegistroOrgaoClasse=${getValues('registrationClassOrgan')}&Ativo=${getValues('active')}`);

			if (data.length) {
				setBreeders(data);
				setEmptySearch(false);
			} else {
				setEmptySearch(true);
			}
		} catch (error:any) {
			setError(true);
		}
		setLoadingSearch(false);
	}

	const actions = ({ data }: any) => (
		<Fragment>
			<EditButton
				onClick={() => {
					history.push({
						pathname: '/editar/melhoristas',
						state: {
							id: data.id
						}
					})
				}}
			/>
		</Fragment>
	)

	return <div className="container-custom-search">
		<div className="top-content">
			<h3>Consulta de melhoristas</h3>
		</div>
		<div className="row-buttons">
			<Button
				onClick={() => history.push('/cadastrar/melhoristas')}
				contained
				title="Adicionar"
				type="button"
			/>
		</div>
		<br />
		<Form onSubmit={handleSubmit(search)}>
			<Form.Row>
				<Form.Group as={Col} md={4}>
					<Form.Label>
						Nome do&#40;a&#41; melhorista
					</Form.Label>
					<Form.Control
						ref={register}
						name="name"
						autoComplete="off"
						maxLength={100}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						CPF
					</Form.Label>
					<Form.Control
						ref={register}
						as={NumberFormat}
						getInputRef={register}
						type="text"
						name="cpf"
						format="###.###.###-##"
						autoComplete="off"
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Registro &#40;CREA/CTNBio&#41;
					</Form.Label>
					<Form.Control
						ref={register}
						name="registrationClassOrgan"
						type="text"
					/>
				</Form.Group>
				<Form.Group as={Col} md={.5}>
					<Form.Label />
					<Form.Check
						ref={register}
						name="active"
						type="checkbox"
						label="Ativos"
					/>
				</Form.Group>
				<span className="filter-btn">
					<Button
						title="Buscar"
						type="submit"
						contained
						isLoading={loadingSearch}
					/>
				</span>
			</Form.Row>
		</Form>
		<If condition={emptySearch}>
			<div className="div-empty">
				<SearchEmpty />
			</div>
		</If>
		<If condition={!loading && !error && !emptySearch && breeders.length}>
			<div className="search-content">
				<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
					<AgGridReact
						rowData={breeders}
						defaultColDef={{ flex: 1, sortable: true, resizable: true }}
						pagination
						paginationPageSize={10}
						frameworkComponents={{
							actions: actions,
						}}
					>
						<AgGridColumn
							headerName='Nome'
							field='nome'
							wrapText
							autoHeight
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName="Registro (CREA/CTNBio)"
							field="registroOrgaoClasse"
							maxWidth={200}
						/>
						<AgGridColumn
							headerName="CPF"
							field="cpf"
							maxWidth={200}
						/>
						<AgGridColumn
							headerName="Status"
							field="ativo"
							valueFormatter={({ data }: any) => statusToText(data.ativo)}
							maxWidth={160}
						/>
						<AgGridColumn
							field='Ações'
							cellRenderer='actions'
							maxWidth={130}
						/>
					</AgGridReact>
				</div>
			</div>
		</If>
		<If condition={loading}>
			<div className="loading-container">
				<Loading />
			</div>
		</If>
	</div>;
}