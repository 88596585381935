import React from 'react';
import * as IconFi from 'react-icons/fi';
import * as IconBi from 'react-icons/bi';
import * as IconFa from 'react-icons/fa';
import * as IconVsc from 'react-icons/vsc';
import * as Bootstrap from 'react-bootstrap';

import './style.scss';

type ButtonModel = 'default' | 'edit' | 'add' | 'insert' | 'delete' | 'config' | 'pdf' | 'zip' | 'history' | 'download' | 'send';
type AutoPlacement = "auto" | "auto-start" | "auto-end";
type BasePlacement = "top" | "bottom" | "right" | "left";
type VariationPlacement = "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end"

interface Props
{
	type?						: ButtonModel;
	placement?					: AutoPlacement | BasePlacement | VariationPlacement;
	text						: string;
	size?						: number;
	tooltip?					: string;
	disabled?					: boolean;
	onClick?					: {() : any},
}

interface ButtonProps
{
	style						: React.CSSProperties;
	className					: string;
	size						: number;
	onClick?					: {() : any},
}

interface State
{
	onClick?					: {() : any},
}

export default class CustomButton extends React.Component<Props, State>
{
	public static defaultProps = {
		type					: 'default',
		placement				: 'right',
		disabled				: false,
		size					: 20,
		tooltip					: '',
		onClick					: () => {},
	};
	
	constructor (props:Props)
	{
		super(props)
		
		this.state				= {
			onClick					: () => {},
		};
	}

	ParseIcon () : JSX.Element
	{
		let buttonProps : ButtonProps = {
			style			: {color: !this.props.disabled ? '#000000' : '#c9cacc'},
			className		: 'custom-button',
			size			: this.props.size || 20,
			onClick			: !this.props.disabled ? this.props.onClick : this.state.onClick,
		};

		switch (this.props.type)
		{
			case 'edit':
				
				return <IconFi.FiEdit {...buttonProps} />;

			case 'insert':
				
				return <IconFi.FiFilePlus {...buttonProps} />;
			
			case 'config':
				
				return <IconFi.FiSettings {...buttonProps} />;

			case 'pdf':

				buttonProps.className += ' generate';
				
				return <IconFa.FaFilePdf {...buttonProps} />;
				
			case 'zip':

				buttonProps.className += ' view';
				
				return <IconFa.FaFileArchive {...buttonProps} />;
				
			case 'history':

				buttonProps.className += ' history';
			
				return <IconVsc.VscHistory {...buttonProps} />;

			case 'download':

				buttonProps.className += ' add';
				
				return <IconFi.FiDownloadCloud {...buttonProps} />;

			case 'add':

				buttonProps.className += ' add';
			
				return <IconFi.FiPlus {...buttonProps} />;

			case 'send':

				buttonProps.className += ' send';

				return <IconBi.BiMailSend {...buttonProps} />;

			case 'delete':

				buttonProps.className += ' delete';
			
				return <IconFi.FiTrash {...buttonProps} />;

		}

		return <React.Fragment />;
	}

	ParseTooltip () : JSX.Element
	{
		return <Bootstrap.Tooltip
			id="default-tooptip"
		>
			{this.props?.tooltip}
		</Bootstrap.Tooltip>;
	}

	render ()
	{
		if (!this.props.tooltip)
			return this.ParseIcon();

		return <Bootstrap.OverlayTrigger placement={this.props.placement} overlay={this.ParseTooltip()}>
			{this.ParseIcon()}
		</Bootstrap.OverlayTrigger>;
	}
}