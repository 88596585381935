import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Form, Col } from 'react-bootstrap';
import * as Bootstrap from "react-bootstrap";
import * as Router from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import Modal from "../../../components/Basics/Modal";
import Table, {Column} from '../../../components/Basics/Table';
import CustomButton from '../../../components/Basics/CustomButton';
import BlankPage from '../../../components/page/BlankPage';
import Fieldset from '../../../components/Fieldset';
import * as Models from '../../../models/modelsLPMA';
import Button from "../../../components/Button";
import Text from '../../../components/form/Text';
import Datepicker from '../../../components/form/Datepicker';
import AlertSuccess from '../../../components/AlertSuccess';
import AlertError from '../../../components/AlertError';

import './style.scss';
import api from '../../../services/api';
import { _generateDate } from '../../../util/convert';
import { AxiosResponse } from 'axios';

import If from '../../../components/If';


type MessageType	= 'success' | 'error';

interface Message
{
	type			: MessageType;
	content			: string;
}

interface LocalidadeFile
{
	id				: number;
	content			: File;
}

export default function Edit (props:any)
{

	let history = Router.useHistory();
	
	enum statuses {
		idle,					//Nothing is taking the screen right now
		loading,				//Loading data
		editingDate,			//Editing dates
		sendingMapa,			//Sending to MAPA
		sendingMapaServer,		//Sending by API to server
	}

	let [modalData, setModalData] = React.useState<boolean>();
	let [status, setStatus] = React.useState<statuses>(statuses.idle);
	let [message, setMessage] = React.useState<Message | null>(null);
	
	let [entity, setEntity] = React.useState<Models.OficioCIBIO>({});
	let [processoCTNBIO, setProcessoCTNBIO] = React.useState<string>("");
	let [onEditLocalidade, setOnEditLocalidade] = React.useState<Models.OficioCIBIO_Localidade>();
	let [onEditEvento, setOnEditEvento] = React.useState<Models.OficioCIBIO_Evento>();

	let defaultColumnsStyle : React.CSSProperties = {
		verticalAlign:'middle',
		textAlign:'center',
		width:'13%',
	};

	const { control: controlOficio, register: registerOficio, errors: errorsOficio, handleSubmit: handleSubmitOficio, trigger: triggerOficio, watch: watchOficio } = useForm({
		mode: 'all'
	})

	const onSubmitOficio = (info: Models.OficioCIBIO_Evento): void => {

		var perMonitoramento = "7";



		const arr = null;

		//setOnEditEvento([...arr, {
		//	localidadeId: info.localidadeId,
		//	ensaioId: info.ensaioId,
		//	plantioInicioId: info.plantioInicioId,
		//	plantioTerminoId: info.plantioTerminoId,
		//	colheitaInicioId: info.colheitaInicioId,
		//	colheitaTerminoId: info.colheitaTerminoId,
		//	monitoramentoInicioId: info.monitoramentoInicioId,
		//	monitoramentoTerminoId: info.monitoramentoTerminoId,
		//	periodoMonitoramento: perMonitoramento,
		//}]);

	}

	let Save = async (callback : {() : any}) =>
	{
		let response = await api.post<Models.OficioCIBIO>(`api/v1/lpma/oficio/${props?.match?.params?.id}/save`, entity);

		if (response.status != 200)
		{
			SetTemporaryMessage('error', 'Ocorreu um erro durante o processamento');
			throw Error('Ocorreu um erro durante o processamento');
		}

		let files : LocalidadeFile[] = [];

		if (entity.localidades)
		{
			for (let localidade of entity.localidades)
			{
				if (!localidade.croquiFile || !localidade.id)
					continue;

				files.push({
					id		: localidade.id,
					content	: localidade.croquiFile,
				});
			}
		}

		if (files.length > 0)
		{
			for (let file of files)
			{
				let postData = new FormData();

				postData.append('file', file.content);

				let response = await api.post(`api/v1/lpma/oficio/${file.id}/croqui`, postData);

				if (response.status != 200)
				{
					SetTemporaryMessage('error', 'Ocorreu um erro no envio dos arquivos');
					throw Error('Ocorreu um erro no envio dos arquivos');
				}
			}
		}

		callback();
	}

	let OnSave = () =>
	{
		SetTemporaryMessage('success', 'Dados salvos com sucesso');
		GetOficio();
	}

	let OnSend = async () => {

		let response = await api.get(`/api/v1/lpmaoficio/${props?.match?.params?.id}/send/${onEditLocalidade?.id || ""}`);

		if (response.status != 200)
		{
			SetTemporaryMessage('error', 'Erro ao gerar o PDF');
			throw Error('Erro ao gerar o PDF');
		}

		setStatus(statuses.idle);
		SetTemporaryMessage('success', 'PDF gerado para envio ao MAPA');

		GetOficio();
	}
	
	let SetTemporaryMessage = (type: MessageType, content : string) =>
	{
		setMessage({
			type: type,
			content: content
		});

		window.scrollTo({ top: 0, behavior: 'smooth' });

		setTimeout(() => {
			setMessage(null);
		}, 10000);
	}

	let GetOficio = async () =>
	{
		setStatus(statuses.loading);

		let response = await api.get<Models.OficioCIBIO>(`api/v1/lpma/oficio/${props?.match?.params?.id}`);

		if (response.status == 200)
		{
			setEntity(response.data);
			setProcessoCTNBIO(response?.data?.lpma06?.numeroAutorizacao || response?.data?.lpma35?.numeroAutorizacao || '');
			setStatus(statuses.idle);
		}
	}

	React.useEffect(() => {

		setModalData(false);
		GetOficio();

	}, []);

	let defaultColumns = (area : Models.OficioCIBIO_Localidade) : Column<Models.OficioCIBIO_Evento>[] =>
	{
		let blueStyle = {color:'#0070c0', fontWeight:'bold'};

		return [
			{
				title:'Ensaio',
				parser: (evento: Models.OficioCIBIO_Evento) : string | JSX.Element => {

					if (evento.ensaio06 && evento.ensaio06.descricao)
						return evento.ensaio06.descricao;

					if (evento.ensaio35 && evento.ensaio35.descricao)
						return evento.ensaio35.descricao;

					return '{desconhecido}';
				},
				style:Object.assign({}, defaultColumnsStyle, {fontWeight: 'bold', width:'auto'})
			},
			{
				title:"Data de Início do Plantio",
				parser: (evento: Models.OficioCIBIO_Evento) : string | JSX.Element => {

					let style = evento.plantioInicio?.date && !evento.plantioInicio.sentAt ? blueStyle : {};
					let date = evento.plantioInicio?.date ? _generateDate(evento.plantioInicio?.date, 'Pt') : 'Aguardando';

					return <span style={style}>{date}</span>;
				},
				style:defaultColumnsStyle
			},
			{
				title:"Data de Término do Plantio",
				parser: (evento: Models.OficioCIBIO_Evento) : string | JSX.Element => {
					let style = evento.plantioTermino?.date && !evento.plantioTermino.sentAt ? blueStyle : {};
					let date = evento.plantioTermino?.date ? _generateDate(evento.plantioTermino?.date, 'Pt') : 'Aguardando';

					return <span style={style}>{date}</span>;
				},
				style:defaultColumnsStyle
			},
			{
				title:"Início de Colheita / Destruição",
				parser: (evento: Models.OficioCIBIO_Evento) : string | JSX.Element => {
					let style = evento.colheitaInicio?.date && !evento.colheitaInicio.sentAt ? blueStyle : {};
					let date = evento.colheitaInicio?.date ? _generateDate(evento.colheitaInicio?.date, 'Pt') : 'Aguardando';

					return <span style={style}>{date}</span>;
				},
				style:defaultColumnsStyle
			},
			{
				title:"Término de Colheita / Destruição",
				parser: (evento: Models.OficioCIBIO_Evento) : string | JSX.Element => {
					let style = evento.colheitaTermino?.date && !evento.colheitaTermino.sentAt ? blueStyle : {};
					let date = evento.colheitaTermino?.date ? _generateDate(evento.colheitaTermino?.date, 'Pt') : 'Aguardando';

					return <span style={style}>{date}</span>;
				},
				style:defaultColumnsStyle
			},
			{
				title:"Data de Início do Monitoramento",
				parser: (evento: Models.OficioCIBIO_Evento) : string | JSX.Element => {
					let style = evento.monitoramentoInicio?.date && !evento.monitoramentoInicio.sentAt ? blueStyle : {};
					let date = evento.monitoramentoInicio?.date ? _generateDate(evento.monitoramentoInicio?.date, 'Pt') : 'Aguardando';

					return <span style={style}>{date}</span>;
				},
				style:defaultColumnsStyle
			},
			{
				title:"Data de Término do Monitoramento",
				parser: (evento: Models.OficioCIBIO_Evento) : string | JSX.Element => {
					let style = evento.monitoramentoTermino?.date && !evento.monitoramentoTermino.sentAt ? blueStyle : {};
					let date = evento.monitoramentoTermino?.date ? _generateDate(evento.monitoramentoTermino?.date, 'Pt') : 'Aguardando';

					return <span style={style}>{date}</span>;
				},
				style:defaultColumnsStyle
			},
			{
				title: "Período de Monitoramento",
				parser: (evento: Models.OficioCIBIO_Evento): string | JSX.Element => {
					return <span >{evento.periodoMonitoramento}</span>;
				},
				style: defaultColumnsStyle
			},
			{
				title:"Ações",
				parser: (evento: Models.OficioCIBIO_Evento) => {
					return <React.Fragment>
						<CustomButton type="edit" text={'Editar: '} onClick={() => {
							setOnEditLocalidade(area);
							setOnEditEvento(evento);
							setStatus(statuses.editingDate);
							setModalData(true);
						}} />
					</React.Fragment>
				},
				style:Object.assign({}, defaultColumnsStyle, {width:'50px;'})
			},
		];
	}

	let modalMapa : JSX.Element = <Modal
		show={status == statuses.sendingMapa || status == statuses.sendingMapaServer}
		title={<h2>{onEditLocalidade?.area06?.descricao || onEditLocalidade?.area35?.descricao || 'Gerar PDFs para serem enviados ao MAPA'}</h2>}
		onHide={()=>setStatus(statuses.idle)}
		animated
		centered
	>
		<Bootstrap.Form>
			<p>Tem certeza que deseja salvar todas as informações e gerar o PDF que deverá ser enviado aos respectivos orgãos competentes?</p>
			<p style={{color:'#f00'}}>OBS: Nenhuma comunicação direta será feita neste momento. Apenas um PDF será disponibilizado para envio</p>
			<p>Essa operação não poderá ser desfeita.</p>
			<div className="button-position">
				<Button
					title="Cancelar"
					contained={true}
					type="button"
					isLoading={status == statuses.sendingMapaServer}
					onClick={() => setStatus(statuses.idle)}
				/>
				<Button
					title="Ok"
					contained={true}
					type="button"
					isLoading={status == statuses.sendingMapaServer}
					onClick={() => {
						setStatus(statuses.sendingMapaServer);
						Save(OnSend);
					}}
				/>
				
			</div>
		</Bootstrap.Form>
	</Modal>;

	let ParseSystemMessage = () : JSX.Element =>
	{
		if (message == null)
			return <React.Fragment />;

		switch (message.type)
		{
			case 'success':

				return <AlertSuccess message={message.content} />;

			case 'error':

				return <AlertError message={message.content} />;
		}

		return <React.Fragment />;
	}

	return <BlankPage
		title="Ofício CIBio"
		description="CIBio da BASF S.A. (CQB 031/97) vem informar as datas modificadas (GM) referentes à Liberação Planejada no Meio Ambiente (LPMA)"
		loading={status == statuses.loading}
	>
		{ParseSystemMessage()}
		{entity.localidades?.map((localidade : Models.OficioCIBIO_Localidade) => {
			return <Fieldset title={localidade?.area06?.lpma_Local?.nomeDaFazenda || localidade?.area35?.lpma_Local?.nomeDaFazenda} legendClassName="local-legend">
				<Table<Models.OficioCIBIO_Evento>
					columns={defaultColumns(localidade)}
					rows={localidade.eventos || []}
					striped
					bordered
					hover
				/>
				<Bootstrap.Form.Row className="row-custom">					
					<Bootstrap.Form.Group as={Bootstrap.Col} md={6}>
						<Bootstrap.Form.Label>Dimensões Aprovadas do Croqui [Área com OGM x Área Total]</Bootstrap.Form.Label>
						<Bootstrap.Form.Row className="row-custom" style={{marginBottom:'-1rem'}}>
							<Text
								type="number"
								label=""
								columns={6}
								placeholder="Área com OGM"
								mask="{?} m (larg.)"
								value={localidade.areaAprovadaX}
								onChange={(event) => {
									localidade.areaAprovadaX = parseFloat(event.target.value);
									setEntity({...entity});
								}}
							/>
							<Text
								type="number"
								columns={6}
								placeholder="Área Total"
								mask="{?} m (comp.)"
								value={localidade.areaAprovadaY}
								onChange={(event) => {
									localidade.areaAprovadaY = parseFloat(event.target.value);
									setEntity({...entity});
								}}
							/>
						</Bootstrap.Form.Row>
					</Bootstrap.Form.Group>

					<Bootstrap.Form.Group as={Bootstrap.Col} md={6}>
						<Bootstrap.Form.Label>Dimensões Plantadas do Croqui [Área com OGM x Área Total]</Bootstrap.Form.Label>
						<Bootstrap.Form.Row className="row-custom" style={{marginBottom:'-1rem'}}>
							<Text
								type="number"
								label=""
								columns={6}
								placeholder="Área com OGM"
								mask="{?} m (larg.)"
								value={localidade.areaPlantadaX}
								onChange={(event) => {
									localidade.areaPlantadaX = parseFloat(event.target.value);
									setEntity({...entity});
								}}
							/>
							<Text
								type="number"
								columns={6}
								placeholder="Área Total"
								mask="{?} m (comp.)"
								value={localidade.areaPlantadaY}
								onChange={(event) => {
									localidade.areaPlantadaY = parseFloat(event.target.value);
									setEntity({...entity});
								}}
							/>
						</Bootstrap.Form.Row>
					</Bootstrap.Form.Group>

					<Text
						type="text"
						label="Número do processo CTNBio"
						columns={5}
						placeholder="processo"
						value={processoCTNBIO}
						onChange={(event:any) : any => {

							let value = event?.target?.value || '';

							setProcessoCTNBIO(value);

							if (entity.lpma06)
								entity.lpma06.numeroAutorizacao = value;

							if (entity.lpma35)
								entity.lpma35.numeroAutorizacao = value;

							return null;
						}}
					/>

					<Text
						type="text"
						label="Título do Anexo do Croqui"
						columns={8}
						placeholder="título"
						value={localidade.croquiTitulo}
						onChange={(event:any) : any => {

							localidade.croquiTitulo = event.target.value;
							setEntity({...entity});
						}}
					/>

					<Bootstrap.Form.Group as={Bootstrap.Col} md={4}>
						<Bootstrap.Form.Label>Arquivo Final do Croqui</Bootstrap.Form.Label>
						<Bootstrap.Form.File
							name="ratePlaces"
							label={localidade?.croquiFile?.name || localidade?.croqui?.filename || 'Arquivo Croqui'}
							data-browse="upload"
							custom
							accept="image/*"
							onChange={(event: any) => {
								
								localidade.croquiFile = event?.target?.files[0];
								setEntity({...entity});

							}}
						/>
					</Bootstrap.Form.Group>

					<Text
						type="textarea"
						label="Observações Gerais"
						columns={12}
						placeholder="Observações gerais"
						value={localidade.observacoes}
						onChange={(event) => {
							localidade.observacoes = event.target.value;
							setEntity({...entity});
						}}
					/>
					
					<Bootstrap.Form.Group as={Bootstrap.Col} md={12}>
					
					<Bootstrap.Form.Label><strong>PDFs Gerados e enviados</strong>:</Bootstrap.Form.Label>
					<div>
						<ul style={{margin:'0px 20px'}}>
							{localidade.pdfs?.map((pdf:Models.OficioCIBIO_Pdf) => {
								return <li><a href={`/api/v1/lpma/oficio/${pdf.documento?.id}/download`} target="_blank">{pdf.documento?.filename}</a></li>
							})}
							{!localidade.pdfs || localidade.pdfs.length <= 0 ? <p className="text-muted"><i>Nenhum PDF gerado até o momento</i></p> : <React.Fragment />}
						</ul>
					</div>
					</Bootstrap.Form.Group>
				</Bootstrap.Form.Row>
				<div className="button-position">
					<button
						className="btn btn-danger"
						title="Gerar PDFs para envio ao MAPA"
						onClick={() => {
							setOnEditLocalidade(localidade);
							setTimeout(() => {
								setStatus(statuses.sendingMapa);
							}, 500);
						}}
					>
						Gerar PDFs para envio ao MAPA
					</button>
					<a
						href={`/api/v1/lpma/oficio/${props?.match?.params?.id}/pdf/${localidade.id}`}
						className="btn btn-primary"
						title="Baixar PDF Atual"
						target="_blank"
						style={{padding:'13px 26px', fontWeight: 'bold'}}
					>
						Baixar PDF Atual
					</a>
			</div>

			</Fieldset>;
		})}
		{modalMapa}
		<div className="button-position">
			<Button
				title="Voltar"
				contained={false}
				type="button"
				onClick={() => {
					history.goBack();
				}}
			/>
			<Button
				title="Salvar todos os dados"
				type="submit"
				contained={true}
				onClick={(event:any) => Save(OnSave)}
			/>

			<If condition={modalData}>
				<Modal
					show={true}
					onHide={() => setModalData(false)}
					title={<h2>
						{onEditLocalidade?.area06?.lpma_Local?.nomeDaFazenda || onEditLocalidade?.area35?.lpma_Local?.nomeDaFazenda} &rsaquo;
						{onEditEvento?.ensaio06?.descricao || onEditEvento?.ensaio35?.descricao}
					</h2>}
				>
					<Form autoComplete="off">
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Data de Início do Plantio
								</Form.Label>
								<Datepicker
									columns={6}
									value={onEditEvento?.plantioInicio?.date}
									disabled={onEditEvento?.plantioInicio?.sentAt != undefined}
									onChange={(date: Date) => {
										if (!onEditEvento?.plantioInicio)
											return;
										onEditEvento.plantioInicio.date = date;
										setOnEditEvento({ ...onEditEvento });
									}}
								/>
							</Form.Group>
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Data de Término do Plantio
								</Form.Label>
								<Datepicker
									columns={6}
									value={onEditEvento?.plantioTermino?.date}
									disabled={onEditEvento?.plantioTermino?.sentAt != undefined}
									onChange={(date: Date) => {
										if (!onEditEvento?.plantioTermino)
											return;
										onEditEvento.plantioTermino.date = date;
										setOnEditEvento({ ...onEditEvento });
									}}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Data de Início da Colheita
								</Form.Label>
								<Datepicker
									columns={6}
									value={onEditEvento?.colheitaInicio?.date}
									disabled={onEditEvento?.colheitaInicio?.sentAt != undefined}
									onChange={(date: Date) => {
										if (!onEditEvento?.colheitaInicio)
											return;
										onEditEvento.colheitaInicio.date = date;
										setOnEditEvento({ ...onEditEvento });
									}}
								/>
							</Form.Group>
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Data de Término da Colheita
								</Form.Label>
								<Datepicker
									columns={6}
									value={onEditEvento?.colheitaTermino?.date}
									disabled={onEditEvento?.colheitaTermino?.sentAt != undefined}
									onChange={(date: Date) => {
										if (!onEditEvento?.colheitaTermino)
											return;
										onEditEvento.colheitaTermino.date = date;
										setOnEditEvento({ ...onEditEvento });
									}}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Data de Início do Monitoramento
								</Form.Label>
								<Datepicker
									columns={6}
									value={onEditEvento?.monitoramentoInicio?.date}
									disabled={onEditEvento?.monitoramentoInicio?.sentAt != undefined}
									onChange={(date: Date) => {
										if (!onEditEvento?.monitoramentoInicio)
											return;
										onEditEvento.monitoramentoInicio.date = date;
										setOnEditEvento({ ...onEditEvento });
									}}
								/>
							</Form.Group>
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Data de Término do Monitoramento
								</Form.Label>
								<Datepicker
									columns={6}
									value={onEditEvento?.monitoramentoTermino?.date}
									disabled={onEditEvento?.monitoramentoTermino?.sentAt != undefined}
									onChange={(date: Date) => {
										if (!onEditEvento?.monitoramentoTermino)
											return;
										onEditEvento.monitoramentoTermino.date = date;
										setOnEditEvento({ ...onEditEvento });
									}}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Período do Monitoramento
								</Form.Label>
								<Text
									type="number"
									label="Período de Monitoramento"
									columns={10}
									placeholder="periodoMonitoramento"
									value={onEditEvento?.periodoMonitoramento}
									onChange={(event: any): any => {

										let value = event?.target?.value || '';

										if (!onEditEvento?.periodoMonitoramento)
											return;
										onEditEvento.periodoMonitoramento = value;
										setOnEditEvento({ ...onEditEvento });
									}}
								/>
							</Form.Group>
						</Form.Row>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setModalData(false)}
							/>
							<Button
								title="Salvar"
								contained={true}
								type="button"
								onClick={() => {
									handleSubmitOficio(onSubmitOficio)();
									setModalData(false)
								}}
							/>
						</div>
					</Form>
				</Modal>

			</If>

		</div>
	</BlankPage>;
}