import React, { useState } from "react";
import { Form, Col } from "react-bootstrap";
import { uuid } from "../../services/uuid";

import "./style.scss";

interface DataHideProps
{
    form       :any;
	binds       :any[];
	control?    :any;	
    onChange?   : any;
    text?       : string;
    key?		: any;
    defaultValue?: any;
}

export default function DataHide(props: DataHideProps){
    
    return  <Form.Group className="dataHide" as={Col} md={1}>
            {props.binds.map(element => {
                return <Form.Control
                    className="dataHide"
                    key={element.key}
                    defaultValue={element.defaultValue}
                    name={element.name}			
                    ref={props.form?.register}                    
                    type="text"
                />
            })}
            </Form.Group>
}

interface LabelAreaProps
{
    layoutColumns?  : number;
    required?       : boolean;
    children        : any;
    className?      : string;
}

export function LabelArea(props: LabelAreaProps){
    
    return  <Form.Group key={uuid()} className={props.className} as={Col} md={props.layoutColumns ?? 6}>
                <Form.Label>{props.children} {props.required ? <em>*</em> : ''}</Form.Label>
            </Form.Group>
}
