import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Col, InputGroup } from 'react-bootstrap';
import AddButton from '../../../components/AddButton';
import Button from '../../../components/Button';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import LoadingInForm from '../../../components/LoadingInForm';
import GenericButton from '../../../components/FlowGenericButton';
import EditButton from '../../../components/EditButton';
import SimpleInput from '../../../components/ReactSimpleInput';
import { STRINGS } from '../../../util/strings';
import Loading from '../../../components/Loading';
import ConfirmationModal from '../../../components/ConfirmationModal';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from 'react-datepicker';
import jwtDecode from 'jwt-decode';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { Context } from '../../../context/AuthContext'
import { DecodedToken } from '../../../models/user';
import '../../../pages/Registers/styles.css';
import api, { apiFileDownload } from '../../../services/api';
import { _generateDate } from '../../../util/convert';
import '../styles.scss';
import NavigationMessage, { MessageContent, MessageType } from "../../../components/NavigationMessages";
import { STRINGS_LPMA } from "../../../util/lpma/string";
import TabControl from "../../../components/form/TabControl";
import { DecimalAsComma } from '../NumberFormat';

import LpmaDialogs, { LpmaDialogShow } from "../lpmaDialogs";

import {
	Enum,
	LpmaRN35,
	Lpma_RN35_Comunicados,
	Lpma_RN35_Comunicados_Datas,
	Lpma_RN35_Comunicados_Areas,
	OficioCIBIO,
	LpmaRN35Ensaio,
	LpmaRN35Areas,
	Lpma_RN35_Comunicados_Materiais,
	Lpma_RN35_Comunicados_Materiais_Aprovados,
	Lpma_RN35_Comunicados_Materiais_Plantados,

} from "../../../models/modelsLPMA";

import LpmaService, { RolesService } from "../services";

import "./../styles.scss";
import { listEnumItem, ParamsType, StateType } from "../../../models/modelCommum";

import '../../../pages/Registers/styles.css';

registerLocale('pt-BR', ptBR);

export default function Edit()
{
	const { token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);
	const [gridProps, setGridProps] = useState<any>(null);

	const [areaSelecionadaID, setAreaSelecionadaID] = useState<any>();
	const [ensaioSelecionadoID, setEnsaioSelecionadoID] = useState<any>();
	const [areaEnsaioSelecionadoID, setAreaEnsaioSelecionadoID] = useState<any>();
	const [entityLPMA, setEntityLPMA] = useState<LpmaRN35>();
	const [entityComunicado, setEntityComunicado] = useState<Lpma_RN35_Comunicados>();
	const [entityComunicadoData, setEntityComunicadoData] = useState<Lpma_RN35_Comunicados_Datas[]>([]);
	const [entityComunicadoArea, setEntityComunicadoArea] = useState<Lpma_RN35_Comunicados_Areas[]>([]);
	const [entityComunicadoMaterial, setEntityComunicadoMaterial] = useState<Lpma_RN35_Comunicados_Materiais[]>([]);
	const [entityComunicadoMaterialAprovado, setEntityComunicadoMaterialAprovado] = useState<Lpma_RN35_Comunicados_Materiais_Aprovados[]>([]);
	const [entityComunicadoMaterialPlantado, setEntityComunicadoMaterialPlantado] = useState<Lpma_RN35_Comunicados_Materiais_Plantados[]>([]);
	const [areasComunicado, setAreasComunicado] = useState<LpmaRN35Areas[]>([]);
	const [ensaiosComunicado, setEnsaiosComunicado] = useState<LpmaRN35Ensaio[]>([]);
	const [loadingRequestData, setLoadingRequestData] = useState<boolean>(true);
	const [verAreaSelecionada, setVerAreaSelecionada] = useState<boolean>(false);
	const [verEnsaioSelecionado, setVerEnsaioSelecionado] = useState<boolean>(false);

	const [enumMaterial, setEnumMaterial] = useState<Enum[]>([]);

	const [dataToEdit, setDataToEdit] = useState<Lpma_RN35_Comunicados_Datas>({
		id: 0
	});
	const [materialAprovadoToEdit, setMaterialAprovadoToEdit] = useState<Lpma_RN35_Comunicados_Materiais_Aprovados>({
		id: 0,
		contagem: 0,
	});
	const [materialPlantadoToEdit, setMaterialPlantadoToEdit] = useState<Lpma_RN35_Comunicados_Materiais_Plantados>({
		id: 0,
		contagem: 0,
	});
	const [modalData, setModalData] = useState<boolean>(false);
	const [modalMaterialAprovado, setModalMaterialAprovado] = useState<boolean>(false);
	const [modalMaterialPlantado, setModalMaterialPlantado] = useState<boolean>(false);
	const [modalEditMaterialAprovado, setModalEditMaterialAprovado] = useState<boolean>(false);
	const [modalEditMaterialPlantado, setModalEditMaterialPlantado] = useState<boolean>(false);

	const { control: controlEditData, register: registerEditData, errors: errorsEditData, handleSubmit: handleSubmitEditData, trigger: triggerEditData, watch: watchEditData } = useForm({
		mode: 'all',
	})

	const { control: controlMaterialAprovado, register: registerMaterialAprovado, errors: errorsMaterialAprovado, handleSubmit: handleSubmitMaterialAprovado, trigger: triggerMaterialAprovado, watch: watchMaterialAprovado } = useForm({
		mode: 'all',
	})

	const { control: controlMaterialPlantado, register: registerMaterialPlantado, errors: errorsMaterialPlantado, handleSubmit: handleSubmitMaterialPlantado, trigger: triggerMaterialPlantado, watch: watchMaterialPlantado } = useForm({
		mode: 'all',
	})

	const { control: controlEditMaterialAprovado, register: registerEditMaterialAprovado, errors: errorsEditMaterialAprovado, handleSubmit: handleSubmitEditMaterialAprovado, trigger: triggerEditMaterialAprovado, watch: watchEditMaterialAprovado } = useForm({
		mode: 'all',
	})

	const { control: controlEditMaterialPlantado, register: registerEditMaterialPlantado, errors: errorsEditMaterialPlantado, handleSubmit: handleSubmitEditMaterialPlantado, trigger: triggerEditMaterialPlantado, watch: watchEditMaterialPlantado } = useForm({
		mode: 'all',
	})

	const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [msgSuccessSubmit, setMsgSuccessSubmit] = useState<string>("");

	const UrlParam = useParams<ParamsType>();
	const formRef = useRef<HTMLFormElement>(null);

	const GoBackToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }) }

	const location = useLocation<StateType>();
	const history = useHistory();

	const [titleForm, setTitleForm] = useState<string>(`${STRINGS_LPMA.NEW_LPMA_RNCOMUNICADOS} RN35`);

	//Page notification
	const [pageMessages, setPageMessages] = useState<MessageContent[]>([]);
	//const hasPageMessages = () : boolean => {return pageMessages.length > 0;}
	const clearPageMessages = async () => { await setPageMessages([]); }
	const addPageMessages = (message: MessageContent, clearMessages: boolean = true) => {
		let currentPageMessages: MessageContent[] = !clearMessages ? pageMessages : [];
		setPageMessages([]);
		currentPageMessages.push(message);
		setPageMessages(currentPageMessages);
	}

	//Loading screen
	const [loading, setLoading] = useState<string[]>([]);
	const setLoadingScreen = (tag: string, type: boolean = true) => {

		let currentLoading = loading;
		let index = currentLoading.indexOf(tag);

		if (type)
		{
			if (index >= 0)
				return;

			currentLoading.push(tag);
		}
		else
		{
			if (index <= -1)
				return;

			currentLoading.splice(index, 1);
		}

		setLoading([]);
		setLoading(currentLoading);
	}

	const defaultForm = useForm({

	});


	const setFormData = (dadosComunicados: any, dadosLPMA: any) => {
		defaultForm.reset({
			id: dadosComunicados.id,
			dataCriacao: moment(dadosComunicados.dataCriacao).toDate(),
			usuarioCriacao: dadosComunicados.usuarioCriacao,

			descricaoLPMA: dadosLPMA.titulo,

			areas: dadosLPMA.areas,
			ensaios: dadosLPMA.ensaios,
		});

		setEntityLPMA(dadosLPMA);
		setEntityComunicado(dadosComunicados);
		setAreasComunicado(dadosLPMA.areas);
		setEnsaiosComunicado(dadosLPMA.ensaios);

		setEntityComunicadoArea(dadosComunicados.areas);
		setEntityComunicadoData(dadosComunicados.datas);
		setEntityComunicadoMaterialAprovado(dadosComunicados.materiaisAprovados);
		setEntityComunicadoMaterialPlantado(dadosComunicados.materiaisPlantados);
	}

	useEffect(() => {

		clearPageMessages();

		const getEntity = async () => {

			setLoadingScreen('get_data_form');
			let responseLPMA = await api.get<LpmaRN35>(`api/v1/lpma/rn35/${UrlParam.id}`);
			setEntityLPMA(responseLPMA.data);

			try {
				let responseComunicados = await api.get<OficioCIBIO>(`api/v1/lpmacomunicadosrn35/${UrlParam.id}`);

				setFormData(responseComunicados.data, responseLPMA.data);

				const material = await api.get<Enum[]>('api/v1/LPMA/Enum/material');

				setEnumMaterial(material.data);
			}
			catch (error: any) {
				console.error(error);
				GoBackToTop();
			}

			setLoadingScreen('get_data_entity');

		};

		getEntity();
		setLoadingRequestData(false);

	}, [location.state, defaultForm.reset]);

	const gravarDadosArea = () =>
	{
		if (areaSelecionadaID != undefined) {
			const arr = entityComunicadoArea.filter(e => e.area_ID != areaSelecionadaID);
			const oldData = entityComunicadoArea.filter(e => e.area_ID == areaSelecionadaID)[0];

			let areaEditada: Lpma_RN35_Comunicados_Areas = {
				id: oldData.id,
				comunicado_Id: oldData.comunicado_Id,
				area_ID: oldData.area_ID,
				areaAprovadaX: Number(defaultForm.getValues("areaAprovadaX")),
				areaAprovadaY: Number(defaultForm.getValues("areaAprovadaY")),
				areaPlantadaX: Number(defaultForm.getValues("areaPlantadaX")),
				areaPlantadaY: Number(defaultForm.getValues("areaPlantadaY")),
				observacoes: defaultForm.getValues("observacoesArea"),
			}

			arr.push(areaEditada);

			setEntityComunicadoArea(arr);
		}
	}

	const setInformacoesArea = (id: any) =>
	{
		const areaSelecionada = entityComunicado?.areas?.filter(x => x.area_ID == id)[0];
		const areaLPMA = entityLPMA?.areas?.filter(x => x.id == id)[0];

		defaultForm.reset({
			id: entityComunicado!.id,
			dataCriacao: moment(entityComunicado!.dataCriacao).toDate(),
			usuarioCriacao: entityComunicado!.usuarioCriacao,

			descricaoLPMA: defaultForm.getValues("descricaoLPMA"),

			areas: entityComunicado!.areas,
			ensaios: entityLPMA!.ensaios,

			ensaio: entityLPMA!.ensaios,

			areaAprovadaX: areaSelecionada?.areaAprovadaX,
			areaAprovadaY: areaSelecionada?.areaAprovadaY,
			areaPlantadaX: areaSelecionada?.areaPlantadaX,
			areaPlantadaY: areaSelecionada?.areaPlantadaY,
			observacoesArea: areaSelecionada?.observacoes,
		});

		setVerAreaSelecionada(true);
		setVerEnsaioSelecionado(false);
		setAreaSelecionadaID(areaSelecionada?.area_ID);

	}

	const setInformacoesEnsaio = (id: any) => {
		const ensaioLPMA = entityLPMA?.ensaios?.filter(x => x.id == id)[0];
		const areasLPMA = entityLPMA?.areas?.filter(x => x.id = areaSelecionadaID)[0];
		const areaEnsaioLPMA = areasLPMA?.area_Ensaio.filter((x: any) => x.ensaioId = ensaioLPMA.id)[0];

		setEnsaioSelecionadoID(ensaioLPMA?.id);
		setAreaEnsaioSelecionadoID(areaEnsaioLPMA?.id);

		const datasSelecionadas = entityComunicado?.datas?.filter(x => x.area_Ensaio_ID === areaEnsaioLPMA.id);

		if (datasSelecionadas !== undefined) {
			let datas: Lpma_RN35_Comunicados_Datas[] = [];

			for (let i = 0, t = datasSelecionadas.length; i < t; i++) {
				let novaData: Lpma_RN35_Comunicados_Datas =
				{
					id: datasSelecionadas[i].id,
					comunicado_Id: datasSelecionadas[i].comunicado_Id,
					area_Ensaio_ID: datasSelecionadas[i].area_Ensaio_ID,
					epoca: datasSelecionadas[i].epoca,
					periodoMonitoramento: datasSelecionadas[i].periodoMonitoramento,
					dataInicioPlantio: datasSelecionadas[i].dataInicioPlantio,
					dataTerminoPlantio: datasSelecionadas[i].dataTerminoPlantio,
					dataInicioColheitaDestruicao: datasSelecionadas[i].dataInicioColheitaDestruicao,
					dataTerminoColheitaDestruicao: datasSelecionadas[i].dataTerminoColheitaDestruicao,
					dataInicioMonitoramento: datasSelecionadas[i].dataInicioMonitoramento,
					dataPrevisaoTerminoMonitoramento: datasSelecionadas[i].dataPrevisaoTerminoMonitoramento,
					dataTerminoMonitoramento: datasSelecionadas[i].dataTerminoMonitoramento,
					dataSubmissaoRelatorioConclusivo: datasSelecionadas[i].dataSubmissaoRelatorioConclusivo,
					dataPublicacaoDOU: datasSelecionadas[i].dataPublicacaoDOU,
				}

				datas.push(novaData);
			}

			setEntityComunicadoData(datas);
		}

		const materialAprovadoSelecionado = entityComunicado?.materiaisAprovados?.filter(x => x.area_Ensaio_ID === areaEnsaioLPMA.id);

		if (materialAprovadoSelecionado !== undefined)
		{
			let materiaisAprovados: Lpma_RN35_Comunicados_Materiais_Aprovados[] = [];

			for (let i = 0, t = materialAprovadoSelecionado.length; i < t; i++) {
				let novoMaterialAprovado: Lpma_RN35_Comunicados_Materiais_Aprovados =
				{
					id: materialAprovadoSelecionado[i].id,
					comunicado_Id: materialAprovadoSelecionado[i].comunicado_Id,
					area_Ensaio_ID: materialAprovadoSelecionado[i].area_Ensaio_ID,
					material: materialAprovadoSelecionado[i].material,
					numeroEntradas: materialAprovadoSelecionado[i].numeroEntradas,
					numeroRepeticoes: materialAprovadoSelecionado[i].numeroRepeticoes,
					numeroTratamentos: materialAprovadoSelecionado[i].numeroTratamentos,
					totalParcelas: materialAprovadoSelecionado[i].totalParcelas,
					areaParcela: materialAprovadoSelecionado[i].areaParcela,
					areaTotal: materialAprovadoSelecionado[i].areaTotal,
					contagem: i + 1,
				}

				materiaisAprovados.push(novoMaterialAprovado);
			}
			setEntityComunicadoMaterialAprovado(materiaisAprovados);
		}

		const materialPlantadoSelecionado = entityComunicado?.materiaisPlantados?.filter(x => x.area_Ensaio_ID === areaEnsaioLPMA.id);

		if (materialPlantadoSelecionado !== undefined) 		{
			let materiaisPlantados: Lpma_RN35_Comunicados_Materiais_Plantados[] = [];

			for (let i = 0, t = materialPlantadoSelecionado.length; i < t; i++) {
				let novoMaterialPlantado: Lpma_RN35_Comunicados_Materiais_Plantados =
				{
					id: materialPlantadoSelecionado[i].id,
					comunicado_Id: materialPlantadoSelecionado[i].comunicado_Id,
					area_Ensaio_ID: materialPlantadoSelecionado[i].area_Ensaio_ID,
					material: materialPlantadoSelecionado[i].material,
					numeroEntradas: materialPlantadoSelecionado[i].numeroEntradas,
					numeroRepeticoes: materialPlantadoSelecionado[i].numeroRepeticoes,
					numeroTratamentos: materialPlantadoSelecionado[i].numeroTratamentos,
					totalParcelas: materialPlantadoSelecionado[i].totalParcelas,
					areaParcela: materialPlantadoSelecionado[i].areaParcela,
					areaTotal: materialPlantadoSelecionado[i].areaTotal,
					contagem: i + 1,
				}

				materiaisPlantados.push(novoMaterialPlantado);
			}
			setEntityComunicadoMaterialPlantado(materiaisPlantados);
		}

		setVerEnsaioSelecionado(true);
	}

	const actionsComunicadoDatas = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				setDataToEdit({
					id: props.data.id,
					comunicado_Id: props.data.comunicado_Id,
					epoca: props.data.epoca,
					periodoMonitoramento: props.data.periodoMonitoramento,
					dataInicioPlantio: props.data.dataInicioPlantio ? moment(props.data.dataInicioPlantio).toDate() : null,
					dataTerminoPlantio: props.data.dataTerminoPlantio ? moment(props.data.dataTerminoPlantio).toDate() : null,
					dataInicioColheitaDestruicao: props.data.dataInicioColheitaDestruicao ? moment(props.data.dataInicioColheitaDestruicao).toDate() : '',
					dataTerminoColheitaDestruicao: props.data.dataTerminoColheitaDestruicao ? moment(props.data.dataTerminoColheitaDestruicao).toDate() : '',
					dataInicioMonitoramento: props.data.dataInicioMonitoramento ? moment(props.data.dataInicioMonitoramento).toDate() : '',
					dataPrevisaoTerminoMonitoramento: props.data.dataPrevisaoTerminoMonitoramento ? moment(props.data.dataPrevisaoTerminoMonitoramento).toDate() : '',
					dataTerminoMonitoramento: props.data.dataTerminoMonitoramento ? moment(props.data.dataTerminoMonitoramento).toDate() : '',
				})

				setGridProps(props.node);

				setModalData(true)
			}} />
		</Fragment>
	)

	const onSubmitEditData = (info: any): void => {

		const arr = entityComunicadoData.filter(e => e.id != info.editid);
		const oldData = entityComunicadoData.filter(e => e.id == info.editid)[0];

		let calculoDataPrevisaoTerminoMonitoramento = undefined;

		if (info.editDataInicioMonitoramento !== '')
			calculoDataPrevisaoTerminoMonitoramento = moment(new Date(info.editDataInicioMonitoramento)).add(parseInt(info.editPeriodoMonitoramento), 'months').toDate();

		let dataEditada: Lpma_RN35_Comunicados_Datas = {
			id: oldData.id,
			comunicado_Id: oldData.comunicado_Id,
			area_Ensaio_ID: oldData.area_Ensaio_ID,
			epoca: oldData.epoca,
			periodoMonitoramento: info.editPeriodoMonitoramento,
			dataInicioPlantio: info.editDataInicioPlantio !== undefined ? info.editDataInicioPlantio : "",
			dataTerminoPlantio: info.editDataTerminoPlantio !== undefined ? info.editDataTerminoPlantio : "",
			dataInicioColheitaDestruicao: info.editDataInicioColheitaDestruicao !== undefined ? info.editDataInicioColheitaDestruicao : "",
			dataTerminoColheitaDestruicao: info.editDataTerminoColheitaDestruicao !== undefined ? info.editDataTerminoColheitaDestruicao : "",
			dataInicioMonitoramento: info.editDataInicioMonitoramento !== undefined ? info.editDataInicioMonitoramento : "",
			dataTerminoMonitoramento: info.editDataTerminoMonitoramento !== undefined ? info.editDataTerminoMonitoramento : "",
			dataPrevisaoTerminoMonitoramento: calculoDataPrevisaoTerminoMonitoramento !== undefined ? calculoDataPrevisaoTerminoMonitoramento : "",
		};

		arr.push(dataEditada);

		setEntityComunicadoData(arr);

		setModalData(false);
	}

	const actionsComunicadoMaterialAprovado = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				let materialSelecionado = enumMaterial.filter(x => x.value === props.data.material);

				setMaterialAprovadoToEdit({
					id: props.data.id,
					comunicado_Id: props.data.comunicado_Id,
					material: {
						label: materialSelecionado[0].value,
						value: materialSelecionado[0].key,
					},
					numeroEntradas: props.data.numeroEntradas,
					numeroRepeticoes: props.data.numeroRepeticoes,
					numeroTratamentos: props.data.numeroTratamentos,
					totalParcelas: props.data.totalParcelas,
					areaParcela: props.data.areaParcela,
					areaTotal: props.data.areaTotal,
					contagem: props.data.contagem,
				});

				setGridProps(props.node);

				setModalEditMaterialAprovado(true)
			}} />
		</Fragment>
	)

	const onSubmitMaterialAprovado = (info: any): void => {

		if (entityComunicadoMaterialAprovado !== undefined) {
			const arr = entityComunicadoMaterialAprovado;

			setEntityComunicadoMaterialAprovado([...arr,
				{
					id: 0,
					comunicado_Id: entityComunicado!.id,
					area_Ensaio_ID: areaEnsaioSelecionadoID,
					material: info.materialAprovadoMaterial.label,
					numeroEntradas: info.materialAprovadoNumeroEntradas,
					numeroTratamentos: info.materialAprovadoNumeroTratamentos,
					numeroRepeticoes: info.materialAprovadoNumeroRepeticoes,
					totalParcelas: info.materialAprovadoTotalParcelas,
					areaParcela: info.materialAprovadoAreaParcela,
					areaTotal: info.materialAprovadoAreaTotal,
					contagem: arr.length + 1,
				}]);

			setModalMaterialAprovado(false);
		}
	}

	const onSubmitEditMaterialAprovado = (info: any): void => {

		if (entityComunicadoMaterialAprovado !== undefined) {
			const arr = entityComunicadoMaterialAprovado!.filter(e => e.contagem !== Number(info.editMaterialAprovadoContagem));
			const oldMaterialAprovado = entityComunicadoMaterialAprovado!.filter(e => e.contagem === Number(info.editMaterialAprovadoContagem))[0];

			let MaterialAprovadoEditado: Lpma_RN35_Comunicados_Materiais_Aprovados = {
				id: oldMaterialAprovado.id,
				comunicado_Id: oldMaterialAprovado.comunicado_Id,
				area_Ensaio_ID: oldMaterialAprovado.area_Ensaio_ID,
				material: info.editMaterialAprovadoMaterial.label,
				numeroEntradas: info.editMaterialAprovadoNumeroEntradas,
				numeroTratamentos: info.editMaterialAprovadoNumeroTratamentos,
				numeroRepeticoes: info.editMaterialAprovadoNumeroRepeticoes,
				totalParcelas: info.editMaterialAprovadoTotalParcelas,
				areaParcela: info.editMaterialAprovadoAreaParcela,
				areaTotal: info.editMaterialAprovadoAreaTotal,
				contagem: oldMaterialAprovado.contagem,
			};

			arr.push(MaterialAprovadoEditado);

			setEntityComunicadoMaterialAprovado(arr);

			setModalEditMaterialAprovado(false);
		}
	}

	const actionsComunicadoMaterialPlantado = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {

				let materialSelecionado = enumMaterial.filter(x => x.value === props.data.material);

				setMaterialPlantadoToEdit({
					id: props.data.id,
					comunicado_Id: props.data.comunicado_Id,
					material: {
						label: materialSelecionado[0].value,
						value: materialSelecionado[0].key,
					},
					numeroEntradas: props.data.numeroEntradas,
					numeroRepeticoes: props.data.numeroRepeticoes,
					numeroTratamentos: props.data.numeroTratamentos,
					totalParcelas: props.data.totalParcelas,
					areaParcela: props.data.areaParcela,
					areaTotal: props.data.areaTotal,
					contagem: props.data.contagem,
				})

				setGridProps(props.node);

				setModalEditMaterialPlantado(true)
			}} />
		</Fragment>
	)

	const onSubmitMaterialPlantado = (info: any): void => {

		if (entityComunicadoMaterialPlantado !== undefined) {
			const arr = entityComunicadoMaterialPlantado;

			setEntityComunicadoMaterialPlantado([...arr,
			{
				id: 0,
				comunicado_Id: entityComunicado!.id,
				area_Ensaio_ID: areaEnsaioSelecionadoID,
				material: info.materialPlantadoMaterial.label,
				numeroEntradas: info.materialPlantadoNumeroEntradas,
				numeroTratamentos: info.materialPlantadoNumeroTratamentos,
				numeroRepeticoes: info.materialPlantadoNumeroRepeticoes,
				totalParcelas: info.materialPlantadoTotalParcelas,
				areaParcela: info.materialPlantadoAreaParcela,
				areaTotal: info.materialPlantadoAreaTotal,
				contagem: arr.length + 1,
			}]);

			setModalMaterialPlantado(false);
		}
	}

	const onSubmitEditMaterialPlantado = (info: any): void => {

		if (entityComunicadoMaterialPlantado !== undefined) {
			const arr = entityComunicadoMaterialPlantado!.filter(e => e.contagem !== Number(info.editMaterialPlantadoContagem));
			const oldMaterialPlantado = entityComunicadoMaterialPlantado!.filter(e => e.contagem === Number(info.editMaterialPlantadoContagem))[0];

			let MaterialPlantadoEditada: Lpma_RN35_Comunicados_Materiais_Plantados = {
				id: oldMaterialPlantado.id,
				comunicado_Id: oldMaterialPlantado.comunicado_Id,
				area_Ensaio_ID: oldMaterialPlantado.area_Ensaio_ID,
				material: info.editMaterialPlantadoMaterial.label,
				numeroEntradas: info.editMaterialPlantadoNumeroEntradas,
				numeroTratamentos: info.editMaterialPlantadoNumeroTratamentos,
				numeroRepeticoes: info.editMaterialPlantadoNumeroRepeticoes,
				totalParcelas: info.editMaterialPlantadoTotalParcelas,
				areaParcela: info.editMaterialPlantadoAreaParcela,
				areaTotal: info.editMaterialPlantadoAreaTotal,
				contagem: oldMaterialPlantado.contagem,
			};

			arr.push(MaterialPlantadoEditada);

			setEntityComunicadoMaterialPlantado(arr);

			setModalEditMaterialPlantado(false);
		}
	}

	const setValuesToSubmit = () => {

		const arr = entityComunicadoArea.filter(e => e.area_ID != areaSelecionadaID);

		const oldData = entityComunicadoArea.filter(e => e.area_ID == areaSelecionadaID)[0];

		let areaEditada: Lpma_RN35_Comunicados_Areas = {
			id: oldData.id,
			comunicado_Id: oldData.comunicado_Id,
			area_ID: oldData.area_ID,
			areaAprovadaX: Number(defaultForm.getValues("areaAprovadaX")),
			areaAprovadaY: Number(defaultForm.getValues("areaAprovadaY")),
			areaPlantadaX: Number(defaultForm.getValues("areaPlantadaX")),
			areaPlantadaY: Number(defaultForm.getValues("areaPlantadaY")),
			observacoes: defaultForm.getValues("observacoesArea"),
		}

		arr.push(areaEditada);

		let novoComunicado =
		{
			id: entityComunicado?.id,
			lpma_Id: entityComunicado?.lpma_Id,
			usuarioCriacao: entityComunicado?.usuarioCriacao,
			dataCriacao: entityComunicado?.dataCriacao,
			lpma_RN35ID: entityComunicado?.id,

			datas: entityComunicadoData,

			areas: arr,

			materiais: entityComunicadoMaterial,
			materiaisAprovados: entityComunicadoMaterialAprovado,
			materiaisPlantados: entityComunicadoMaterialPlantado,
		}

		return novoComunicado;
	}

	const submitForm = async () => {
		try {
			setSuccessSubmit(false);
			setErrorSubmit(null);


			gravarDadosArea();
			var valuesToSubmit = setValuesToSubmit();

			const { status, data } = await api.post(`api/v1/lpmacomunicadosrn35/salvar`, valuesToSubmit);

			if ([200, 201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');

			setSuccessSubmit(true);
			setMsgSuccessSubmit(STRINGS_LPMA.SAVE_COMUNICADO_SUCCESS);
			window.scrollTo({ top: 0, behavior: 'smooth' });

		} catch (error: any) {
			console.error(error);
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);

		}

	}

	const parseButton = () => {
		let btnCancelar = <Button
			title="Voltar"
			contained={false}
			type="button"
			onClick={() => {
				history.push('/lpma/rn35/consultar');
			}}
		/>;

		let btnSalvar = <Button
			title="Salvar"
			contained
			type="button"
			onClick={() => submitForm()}
		/>;


		return <div className="button-position">
			{btnCancelar}
			{btnSalvar}
		</div>;
	}

	const errosHandler = (errData: any) => {
		console.error(errData);
	};

	return (
		<div className="container container-fluid">
			<If condition={loadingRequestData}>
				<span className="loading-page">
					<Loading />
				</span>
			</If>
			<NavigationMessage messages={pageMessages} />
			<If condition={successSubmit && !errorSubmit}>
				<AlertSuccess
					message={msgSuccessSubmit}
				/>
			</If>
			<If condition={!successSubmit && errorSubmit !== null}>
				<AlertError
					message={STRINGS.ALERT_GENERIC_ERROR}
				/>
			</If>
			<Form ref={formRef} onSubmit={defaultForm.handleSubmit(submitForm, errosHandler)}>
				<Fieldset disabled={location?.state?.readonly} title={titleForm}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>
								LPMA
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="descricaoLPMA"
								placeholder=""
								readOnly
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>
								Areas
							</Form.Label>
							<Form.Control
								as="select"
								ref={defaultForm.register}
								name="area"
								//isInvalid={errors.area !== undefined}
								onChange={({ target }: any) => {
									gravarDadosArea();
									setInformacoesArea(target.value);
								}}
							>
								<Form.Control
									as="option"
									label="Escolha uma opcao"
									value=""
								/>
								{areasComunicado.map(item => (
									<Form.Control
										as="option"
										label={item.lpma_Local?.nomeDaFazenda !== "" ? item.lpma_Local?.nomeDaFazenda : "Nao informado"}
										value={item.id}
										key={item.id}
									/>
								))}
							</Form.Control>
						</Form.Group>
					</Form.Row>
					<If condition={verAreaSelecionada}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={12}>
								<Form.Label>
									Ensaios
								</Form.Label>
								<Form.Control
									as="select"
									ref={defaultForm.register}
									name="ensaio"
									//isInvalid={errors.area !== undefined}
									onChange={({ target }: any) => {
										setInformacoesEnsaio(target.value);
									}}
								>
									<Form.Control
										as="option"
										label="Escolha uma opcao"
										value=""
									/>
									{ensaiosComunicado.map(item => (
										<Form.Control
											as="option"
											label={item.descricao}
											value={item.id}
											key={item.id}
										/>
									))}
								</Form.Control>
							</Form.Group>
						</Form.Row>

						<If condition={verEnsaioSelecionado}>
							<Fieldset title={'Datas'}>
								<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
									<AgGridReact
										rowData={entityComunicadoData}
										defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
										gridOptions={{ headerHeight: 80 }}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
										frameworkComponents={{
											actions: actionsComunicadoDatas,
										}}
									>
										<AgGridColumn
											headerName='Epoca'
											valueGetter={({ data }: any) => data.epoca}
											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Data do Inicio do Plantio'
											field="dataInicioPlantio"
											headerClass="myfunc-default-header"
											valueFormatter={({ data }: any) =>
												data.dataInicioPlantio ? _generateDate(data.dataInicioPlantio, 'Pt') : ''
											}
										/>
										<AgGridColumn
											headerName='Data do Termino do Plantio'
											field="dataTerminoPlantio"
											headerClass="myfunc-default-header"
											valueFormatter={({ data }: any) =>
												data.dataTerminoPlantio ? _generateDate(data.dataTerminoPlantio, 'Pt') : ''
											}
										/>
										<AgGridColumn
											headerName='Data do Inicio da Colheita'
											field="dataInicioColheitaDestruicao"
											headerClass="myfunc-default-header"
											valueFormatter={({ data }: any) =>
												data.dataInicioColheitaDestruicao ? _generateDate(data.dataInicioColheitaDestruicao, 'Pt') : ''}
										/>
										<AgGridColumn
											headerName='Data do Termino da Colheita'
											field="dataTerminoColheitaDestruicao"
											headerClass="myfunc-default-header"
											valueFormatter={({ data }: any) =>
												data.dataTerminoColheitaDestruicao ? _generateDate(data.dataTerminoColheitaDestruicao, 'Pt') : ''}
										/>
										<AgGridColumn
											headerName='Data do Inicio do Monitoramento'
											field="dataInicioMonitoramento"
											headerClass="myfunc-default-header"
											valueFormatter={({ data }: any) =>
												data.dataInicioMonitoramento ? _generateDate(data.dataInicioMonitoramento, 'Pt') : ''}
										/>
										<AgGridColumn
											headerName='Periodo do Monitoramento'
											valueGetter={({ data }: any) =>
												data.periodoMonitoramento}
											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Data de Previsao do Termino do Monitoramento'
											field="dataPrevisaoTerminoMonitoramento"
											headerClass="myfunc-default-header"
											valueFormatter={({ data }: any) =>
												data.dataPrevisaoTerminoMonitoramento ? _generateDate(data.dataPrevisaoTerminoMonitoramento, 'Pt') : ''}
										/>
										<AgGridColumn
											headerName='Data do Termino do Monitoramento'
											field="dataTerminoMonitoramento"
											headerClass="myfunc-default-header"
											valueFormatter={({ data }: any) => data.dataTerminoMonitoramento ? _generateDate(data.dataTerminoMonitoramento, 'Pt') : ''}
										/>
										<AgGridColumn
											headerName="Acoes"
											cellRenderer="actions"
											maxWidth={50}
										/>
									</AgGridReact>
								</div>
							</Fieldset>
							<Fieldset title={'Quantidade Aprovada'}>
								<Form.Row className="row-custom">
									<Form.Group as={Col}>
										<Form.Label>
											<AddButton
												type="button"
												onClick={() => setModalMaterialAprovado(true)}
											/>
										</Form.Label>
									</Form.Group>
								</Form.Row>
								<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
									<AgGridReact
										rowData={entityComunicadoMaterialAprovado}
										defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
										gridOptions={{ headerHeight: 80 }}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
										frameworkComponents={{
											actions: actionsComunicadoMaterialAprovado,
										}}
									>
										<AgGridColumn
											headerName='Material'
											valueGetter={({ data }: any) =>
												data.material}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Numero Entradas'
											valueGetter={({ data }: any) =>
												data.numeroEntradas}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Numero Tratamentos'
											valueGetter={({ data }: any) =>
												data.numeroTratamentos}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Numero Repeticoes'
											valueGetter={({ data }: any) =>
												data.numeroRepeticoes}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Total de Parcelas'
											valueGetter={({ data }: any) =>
												data.totalParcelas}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Area Parcela'
											valueGetter={({ data }: any) =>
												data.areaParcela}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Area Total'
											valueGetter={({ data }: any) =>
												data.areaTotal}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName="Acoes"
											cellRenderer="actions"
											maxWidth={50}
										/>
									</AgGridReact>
								</div>
							</Fieldset>
							<Fieldset title={'Quantidade Plantada'}>
								<Form.Row className="row-custom">
									<Form.Group as={Col}>
										<Form.Label>
											<AddButton
												type="button"
												onClick={() => setModalMaterialPlantado(true)}
											/>
										</Form.Label>
									</Form.Group>
								</Form.Row>
								<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
									<AgGridReact
										rowData={entityComunicadoMaterialPlantado}
										defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
										gridOptions={{ headerHeight: 80 }}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
										frameworkComponents={{
											actions: actionsComunicadoMaterialPlantado,
										}}
									>
										<AgGridColumn
											headerName='Material'
											valueGetter={({ data }: any) =>
												data.material}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Numero Entradas'
											valueGetter={({ data }: any) =>
												data.numeroEntradas}

											cellStyle={{
												justifyContent: 'left'
											}}
										/>
										<AgGridColumn
											headerName='Numero Tratamentos'
											valueGetter={({ data }: any) =>
												data.numeroTratamentos}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Numero Repeticoes'
											valueGetter={({ data }: any) =>
												data.numeroRepeticoes}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Total de Parcelas'
											valueGetter={({ data }: any) =>
												data.totalParcelas}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Area Parcela'
											valueGetter={({ data }: any) =>
												data.areaParcela}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName='Area Total'
											valueGetter={({ data }: any) =>
												data.areaTotal}

											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
										/>
										<AgGridColumn
											headerName="Acoes"
											cellRenderer="actions"
											maxWidth={50}
										/>
									</AgGridReact>
								</div>
							</Fieldset>
						</If>
					</If>
				</Fieldset>

				<If condition={modalData}>
					<Modal
						show={true}
						onHide={() => setModalData(false)}
						title="DATAS DO COMUNICADO"
					>
						<Form autoComplete="off">
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Data do Inicio do Plantio
									</Form.Label>
									<br />
									<Controller
										control={controlEditData}
										name="editDataInicioPlantio"
										defaultValue={dataToEdit.dataInicioPlantio}
										render={({ onChange, value }) => (
											<DatePicker
												selected={value}
												onChange={onChange}
												className="input-custom-to-date"
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												placeholderText="Selecionar a data"
											/>
										)}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>
										Data do Termino do Plantio
									</Form.Label>
									<br />
									<Controller
										control={controlEditData}
										name="editDataTerminoPlantio"
										defaultValue={dataToEdit.dataTerminoPlantio}
										render={({ onChange, value }) => (
											<DatePicker
												selected={value}
												onChange={onChange}
												className="input-custom-to-date"
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												placeholderText="Selecionar a data"
											/>
										)}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Data do Inicio da Colheita
									</Form.Label>
									<br />
									<Controller
										control={controlEditData}
										name="editDataInicioColheitaDestruicao"
										defaultValue={dataToEdit.dataInicioColheitaDestruicao}
										render={({ onChange, value }) => (
											<DatePicker
												selected={value}
												onChange={onChange}
												className="input-custom-to-date"
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												placeholderText="Selecionar a data"
											/>
										)}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>
										Data do Termino da Colheita
									</Form.Label>
									<br />
									<Controller
										control={controlEditData}
										name="editDataTerminoColheitaDestruicao"
										defaultValue={dataToEdit.dataTerminoColheitaDestruicao}
										render={({ onChange, value }) => (
											<DatePicker
												selected={value}
												onChange={onChange}
												className="input-custom-to-date"
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												placeholderText="Selecionar a data"
											/>
										)}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Data do Inicio do Monitoramento
									</Form.Label>
									<br />
									<Controller
										control={controlEditData}
										name="editDataInicioMonitoramento"
										defaultValue={dataToEdit.dataInicioMonitoramento}
										render={({ onChange, value }) => (
											<DatePicker
												selected={value}
												onChange={onChange}
												className="input-custom-to-date"
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												placeholderText="Selecionar a data"
											/>
										)}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>
										Data do Termino do Monitoramento
									</Form.Label>
									<br />
									<Controller
										control={controlEditData}
										name="editDataTerminoMonitoramento"
										defaultValue={dataToEdit.dataTerminoMonitoramento}
										render={({ onChange, value }) => (
											<DatePicker
												selected={value}
												onChange={onChange}
												className="input-custom-to-date"
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												placeholderText="Selecionar a data"
											/>
										)}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Periodo de Monitoramento<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditData}
										name="editPeriodoMonitoramento"
										placeholder="Insira o Periodo de Monitoramento"
										maxLength={5}
										type="number"
										step="any"
										isInvalid={errorsEditData.editPeriodoMonitoramento !== undefined}
										defaultValue={dataToEdit.periodoMonitoramento}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>
										Data de Previsao de Termino do Monitoramento
									</Form.Label>
									<br />
									<Controller
										control={controlEditData}
										disabled={true}
										name="editDataPrevisaoTerminoMonitoramento"
										defaultValue={dataToEdit.dataPrevisaoTerminoMonitoramento}
										render={({ onChange, value }) => (
											<DatePicker
												disabled={true}
												readOnly={true}
												selected={value}
												onChange={onChange}
												className="input-custom-to-date"
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												placeholderText="Selecionar a data"

											/>
										)}
									/>
								</Form.Group>

							</Form.Row>
							<Form.Row className="row-invisible">
								<Form.Group as={Col}>
									<Form.Label>
										ID
									</Form.Label>
									<Form.Control
										ref={registerEditData}
										name="editid"
										placeholder=""
										maxLength={5}
										type="number"
										step="any"
										isInvalid={errorsEditData.editid !== undefined}
										defaultValue={dataToEdit.id}
									/>
								</Form.Group>
							</Form.Row>
							<div className="button-position">
								<Button
									title="Cancelar"
									contained={false}
									type="button"
									onClick={() => setModalData(false)}
								/>
								<Button
									title="Salvar"
									contained={true}
									type="button"
									onClick={() => {
										triggerEditData().then(validated => {
											if (validated) {
												handleSubmitEditData(onSubmitEditData)();
											}
										})
									}}
								/>
							</div>
						</Form>
					</Modal>
				</If>

				<If condition={modalMaterialAprovado}>
					<Modal
						show={true}
						onHide={() => setModalMaterialAprovado(false)}
						title="Quantidade Aprovada"
					>
						<Form autoComplete="off">
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={6}>
									<Form.Label>
										Material
									</Form.Label>
									<Controller
										control={controlMaterialAprovado}
										name="materialAprovadoMaterial"
										isInvalid={errorsMaterialAprovado.material !== undefined}
										as={<Select
											placeholder="Selecione um material"
											options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
											isClearable
										/>}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Entradas
									</Form.Label>
									<Form.Control
										ref={registerMaterialAprovado}
										name="materialAprovadoNumeroEntradas"
										placeholder="Insira o numero de entradas"
										type="number"
										step="any"
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Tratamentos
									</Form.Label>
									<Form.Control
										ref={registerMaterialAprovado}
										name="materialAprovadoNumeroTratamentos"
										placeholder="Insira o numero de tratamentos"
										type="number"
										step="any"
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Repeticoes
									</Form.Label>
									<Form.Control
										ref={registerMaterialAprovado}
										name="materialAprovadoNumeroRepeticoes"
										placeholder="Insira o numero de repeticoes"
										type="number"
										step="any"

									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Total de Parcelas
									</Form.Label>
									<Form.Control
										ref={registerMaterialAprovado}
										name="materialAprovadoTotalParcelas"
										placeholder="Insira o total de parcelas"
										type="number"
										step="any"
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Area da Parcela
									</Form.Label>
									<Form.Control
										ref={registerMaterialAprovado}
										name="materialAprovadoAreaParcela"
										placeholder="Insira a area da parcela"
										type="number"
										step="any"
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Area Total
									</Form.Label>
									<Form.Control
										ref={registerMaterialAprovado}
										name="materialAprovadoAreaTotal"
										placeholder="Insira a Area Total"
										type="number"
										step="any"
									/>
								</Form.Group>
							</Form.Row>

							<div className="button-position">
								<Button
									title="Cancelar"
									contained={false}
									type="button"
									onClick={() => setModalMaterialAprovado(false)}
								/>
								<Button
									title="Salvar"
									contained={true}
									type="button"
									onClick={() => {
										triggerMaterialAprovado().then(validated => {
											if (validated) {
												handleSubmitMaterialAprovado(onSubmitMaterialAprovado)();
											}
										})
									}}
								/>
							</div>
						</Form>
					</Modal>
				</If>

				<If condition={modalEditMaterialAprovado}>
					<Modal
						show={true}
						onHide={() => setModalEditMaterialAprovado(false)}
						title="Quantidade Aprovada"
					>
						<Form autoComplete="off">
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={6}>
									<Form.Label>
										Material
									</Form.Label>
									<Controller
										control={controlEditMaterialAprovado}
										name="editMaterialAprovadoMaterial"
										isInvalid={errorsEditMaterialAprovado.material !== undefined}
										defaultValue={materialAprovadoToEdit.material}
										as={<Select
											placeholder="Selecione um material"
											options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
											isClearable
										/>}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Entradas
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialAprovado}
										name="editMaterialAprovadoNumeroEntradas"
										placeholder="Insira o numero de entradas"
										type="number"
										step="any"
										defaultValue={materialAprovadoToEdit.numeroEntradas}
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Tratamentos
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialAprovado}
										name="editMaterialAprovadoNumeroTratamentos"
										placeholder="Insira o numero de tratamentos"
										type="number"
										step="any"
										defaultValue={materialAprovadoToEdit.numeroTratamentos}

									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Repeticoes
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialAprovado}
										name="editMaterialAprovadoNumeroRepeticoes"
										placeholder="Insira o numero de repeticoes"
										type="number"
										step="any"
										defaultValue={materialAprovadoToEdit.numeroRepeticoes}

									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Total de Parcelas
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialAprovado}
										name="editMaterialAprovadoTotalParcelas"
										placeholder="Insira o total de parcelas"
										type="number"
										step="any"
										defaultValue={materialAprovadoToEdit.totalParcelas}
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Area da Parcela
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialAprovado}
										name="editMaterialAprovadoAreaParcela"
										placeholder="Insira a area da parcela"
										type="number"
										step="any"
										defaultValue={materialAprovadoToEdit.areaParcela}

									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Area Total
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialAprovado}
										name="editMaterialAprovadoAreaTotal"
										placeholder="Insira a Area Total"
										type="number"
										step="any"
										defaultValue={materialAprovadoToEdit.areaTotal}

									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-invisible">
								<Form.Group as={Col}>
									<Form.Label>
										Contagem
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialAprovado}
										name="editMaterialAprovadoContagem"
										placeholder=""
										maxLength={5}
										type="number"
										step="any"
										isInvalid={errorsEditData.editid !== undefined}
										defaultValue={materialAprovadoToEdit.contagem}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>
										ID
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialAprovado}
										name="editMaterialAprovadoId"
										placeholder=""
										maxLength={5}
										type="number"
										step="any"
										isInvalid={errorsEditMaterialAprovado.editMaterialAprovadoId !== undefined}
										defaultValue={materialAprovadoToEdit.id}
									/>
								</Form.Group>
							</Form.Row>

							<div className="button-position">
								<Button
									title="Cancelar"
									contained={false}
									type="button"
									onClick={() => setModalEditMaterialAprovado(false)}
								/>
								<Button
									title="Salvar"
									contained={true}
									type="button"
									onClick={() => {
										triggerEditMaterialAprovado().then(validated => {
											if (validated) {
												handleSubmitEditMaterialAprovado(onSubmitEditMaterialAprovado)();
											}
										})
									}}
								/>
							</div>
						</Form>
					</Modal>
				</If>

				<If condition={modalMaterialPlantado}>
					<Modal
						show={true}
						onHide={() => setModalMaterialPlantado(false)}
						title="Quantidade Plantada"
					>
						<Form autoComplete="off">
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={6}>
									<Form.Label>
										Material
									</Form.Label>

								<Controller
									control={controlMaterialPlantado}
									name="materialPlantadoMaterial"
									isInvalid={errorsEditMaterialPlantado.material !== undefined}
									as={<Select
										placeholder="Selecione um material"
										options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
										isClearable
									/>}
								/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Entradas
									</Form.Label>
									<Form.Control
										ref={registerMaterialPlantado}
										name="materialPlantadoNumeroEntradas"
										placeholder="Insira o numero de entradas"
										type="number"
										step="any"
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Tratamentos
									</Form.Label>
									<Form.Control
										ref={registerMaterialPlantado}
										name="materialPlantadoNumeroTratamentos"
										placeholder="Insira o numero de tratamentos"
										type="number"
										step="any"
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Repeticoes
									</Form.Label>
									<Form.Control
										ref={registerMaterialPlantado}
										name="materialPlantadoNumeroRepeticoes"
										placeholder="Insira o numero de repeticoes"
										type="number"
										step="any"

									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Total de Parcelas
									</Form.Label>
									<Form.Control
										ref={registerMaterialPlantado}
										name="materialPlantadoTotalParcelas"
										placeholder="Insira o total de parcelas"
										type="number"
										step="any"
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Area da Parcela
									</Form.Label>
									<Form.Control
										ref={registerMaterialPlantado}
										name="materialPlantadoAreaParcela"
										placeholder="Insira a area da parcela"
										type="number"
										step="any"
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Area Total
									</Form.Label>
									<Form.Control
										ref={registerMaterialPlantado}
										name="materialPlantadoAreaTotal"
										placeholder="Insira a Area Total"
										type="number"
										step="any"
									/>
								</Form.Group>
							</Form.Row>

							<div className="button-position">
								<Button
									title="Cancelar"
									contained={false}
									type="button"
									onClick={() => setModalMaterialPlantado(false)}
								/>
								<Button
									title="Salvar"
									contained={true}
									type="button"
									onClick={() => {
										triggerMaterialPlantado().then(validated => {
											if (validated) {
												handleSubmitMaterialPlantado(onSubmitMaterialPlantado)();
											}
										})
									}}
								/>
							</div>
						</Form>
					</Modal>
				</If>

				<If condition={modalEditMaterialPlantado}>
					<Modal
						show={true}
						onHide={() => setModalEditMaterialPlantado(false)}
						title="Quantidade Plantada"
					>
						<Form autoComplete="off">
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={6}>
									<Form.Label>
										Material
									</Form.Label>
									<Controller
										control={controlEditMaterialPlantado}
										name="editMaterialPlantadoMaterial"
										isInvalid={errorsEditMaterialPlantado.material !== undefined}
										defaultValue={materialPlantadoToEdit.material}

										as={<Select
											placeholder="Selecione um material"
											options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
											isClearable
										/>}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Entradas
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialPlantado}
										name="editMaterialPlantadoNumeroEntradas"
										placeholder="Insira o numero de entradas"
										type="number"
										step="any"
										defaultValue={materialPlantadoToEdit.numeroEntradas}
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Tratamentos
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialPlantado}
										name="editMaterialPlantadoNumeroTratamentos"
										placeholder="Insira o numero de tratamentos"
										type="number"
										step="any"
										defaultValue={materialPlantadoToEdit.numeroTratamentos}

									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Numero Repeticoes
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialPlantado}
										name="editMaterialPlantadoNumeroRepeticoes"
										placeholder="Insira o numero de repeticoes"
										type="number"
										step="any"
										defaultValue={materialPlantadoToEdit.numeroRepeticoes}

									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Total de Parcelas
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialPlantado}
										name="editMaterialPlantadoTotalParcelas"
										placeholder="Insira o total de parcelas"
										type="number"
										step="any"
										defaultValue={materialPlantadoToEdit.totalParcelas}
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Area da Parcela
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialPlantado}
										name="editMaterialPlantadoAreaParcela"
										placeholder="Insira a area da parcela"
										type="number"
										step="any"
										defaultValue={materialPlantadoToEdit.areaParcela}

									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>
										Area Total
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialPlantado}
										name="editMaterialPlantadoAreaTotal"
										placeholder="Insira a Area Total"
										type="number"
										step="any"
										defaultValue={materialPlantadoToEdit.areaTotal}

									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-invisible">
								<Form.Group as={Col}>
									<Form.Label>
										Contagem
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialPlantado}
										name="editMaterialPlantadoContagem"
										placeholder=""
										maxLength={5}
										type="number"
										step="any"
										isInvalid={errorsEditData.editid !== undefined}
										defaultValue={materialPlantadoToEdit.contagem}
									/>
								</Form.Group>
								<Form.Group as={Col}>
									<Form.Label>
										ID
									</Form.Label>
									<Form.Control
										ref={registerEditMaterialPlantado}
										name="editMaterialPlantadoId"
										placeholder=""
										maxLength={5}
										type="number"
										step="any"
										isInvalid={errorsEditData.editid !== undefined}
										defaultValue={materialPlantadoToEdit.id}
									/>
								</Form.Group>
							</Form.Row>

							<div className="button-position">
								<Button
									title="Cancelar"
									contained={false}
									type="button"
									onClick={() => setModalEditMaterialPlantado(false)}
								/>
								<Button
									title="Salvar"
									contained={true}
									type="button"
									onClick={() => {
										triggerEditMaterialPlantado().then(validated => {
											if (validated) {
												handleSubmitEditMaterialPlantado(onSubmitEditMaterialPlantado)();
											}
										})
									}}
								/>
							</div>
						</Form>
					</Modal>
				</If>
				{parseButton()}

			</Form>
		</div>
	);
}
