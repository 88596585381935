import React, { useRef } from "react";
import Modal from "../Modal";
import { Form, Col, Dropdown } from "react-bootstrap";
import { AgGridColumn, AgGridReact, AgGridReactProps, } from "ag-grid-react";
import { ValueFormatterParams } from "ag-grid-community";
import Button from "../Button";
import EditButton from "../EditButton";
import DeleteButton from "../DeleteButton";
import {  useForm } from "react-hook-form";
import "./style.scss";
import { uuid } from "../../services/uuid";
import CheckMsgErros from './_msgErrs';
import NavigationMessage, { MessageContent, MessageType } from "../NavigationMessages";

interface GridProps<FormType>
{
	form? : any;
	// form : UseFormMethods<FormType>;

	name 			: string;
	defaultValue?	: any[];
	label? 			: string;
	isInvalid? 		: boolean;
	placeholder? 	: string;
	register? 	: any;
	length? 		: number;
	layoutColumns? : number;
	style? 		: any;
	gridOptions? : any;
	children? 	: any;
	collumns? 	: GridCollsProps[];
	titleModal? : string;
	required? 	: boolean;
	onChanged?	: any;
	internalForm? : any;
	useStates? 		: any;
	pinnedBottomRowData?: any;
	enabled?		:boolean;	
	pinnedValueFormmatter?: any;
	isInvalidCheck? 	: boolean;
	DropList?			: any;
	overlayNoRowsTemplate?: string;
	validarValores		?: (campos:any[], valores:any) => boolean;
	validarCampos		?: any[];
	validarValoresMsg	?: string;
}

interface GridCollsProps
{
	header 		: string,
	field 		: string;
	minWidth		?: number;
	gridCellStyle	?: any;
	justifyContent	?: string;
	showOnGrid		?: boolean;
	showOnModal		? 		: boolean;
	showOnModalCondificional		?: boolean;
	Condificional	?: (data:any) => any;
	gridValueFormatter	?: any;//((params: ValueFormatterParams) => string) | string;
	custumControls		?: any;
	//customRender?		: any;
	countNumber			?: 	boolean;
	pinnedRowCellRenderer?: boolean;
	pinnedRowCellRendererParams?: boolean;
	cellRenderer	?: 	any;
	uniqueMessage	?: 	any;
	key				?: 	any;
	dropListGetData	?: 	any;
	defaultValue	?: 	any;
	maxWidth		?:	any
	enabled			?: 	boolean;
}

export default function Grid<FormType, EntityType extends {uid?:string}> (props: GridProps<FormType>)
{
	const [isStarted, setStared] = React.useState<boolean>(false);
	const [listitems, setListItems] = React.useState<EntityType[]>([]);
	const [modalShow, setModalShow] = React.useState<boolean>();

	const [modalItem, setModalItem] = React.useState<EntityType>();
	const [editModalItem, setEditModalItem] = React.useState<EntityType>();
	const [isEnabled, setEnabled] = React.useState<boolean>(true);
	const formRef = useRef<HTMLFormElement>(null);

	const [droplistItem, setDroplistItem] = React.useState<any>();
	const [pageMessages, setPageMessages] = React.useState<MessageContent[]>([]);
	const clearPageMessages = async () => {await setPageMessages([]); }
	const addPageMessages = (message : MessageContent, clearMessages : boolean = true) => {
		let currentPageMessages:MessageContent[] = !clearMessages ? pageMessages : [];
		setPageMessages([]);
		currentPageMessages.push(message);
		setPageMessages(currentPageMessages);
	}
	
	const itemsForm = useForm({
		defaultValues: modalItem as any
	});	

	const submit_form = (value: any) =>{

		if(modalItem && value)
		{
			value = Object.assign({}, modalItem, value); 			
		}

		let index = listitems.findIndex(element => element?.uid === value?.uid)

		if(props.validarValores)
		{
			if(!props.validarValores(props.validarCampos ?? [], value)){ 
				if(props.validarValoresMsg !== null && props.validarValoresMsg !== undefined){
					addPageMessages({type:MessageType.success, content:props.validarValoresMsg});
					//addPageMessages(props.validarValoresMsg);
				}				
				return; 
			}
		}

		if (index < 0)
		{
			let colUnique = props.collumns?.filter(e=> e.uniqueMessage);
			if(colUnique){
				for(var i = 0,  t = colUnique.length; i < t; i++){
					let col 	= colUnique[i];
					let valItem = value[col.field];
					let encontrou = listitems.find(element=> Object.assign(element)[col.field] === valItem);
					if(encontrou){ col?.uniqueMessage(encontrou); return; }
				}
			}
			value.uid = uuid();
			listitems.push(value);
		}
		else
		{ 
			listitems[index] = value;
		}

		let currentListItens = [...listitems];
		
		props.form.setValue(props.name, currentListItens);
		setListItems([]);
		setListItems(currentListItens);

		setModalShow(false);
		if(props.onChanged) { props.onChanged(props, currentListItens);}

	};

	const parseModal= () => {
		
		return <Modal
			title={props.titleModal ?? props.label}
			show={modalShow === true}
			onHide={() => { setModalShow(false) }}
		>
			<Form ref={formRef} onSubmit={itemsForm.handleSubmit(submit_form)} autoComplete="off">
				<NavigationMessage messages={pageMessages} />
				<Form.Row className="row-custom">{
					 props.collumns?.filter(e=> e.showOnModal === true || e.showOnModalCondificional === true).map((e, elementIndex) =>{
						let customRender_ = e.custumControls;
						// if(e.customRender){
						// 	customRender_ = e.customRender(e, droplistItem);
						// }
						let dlgetData = null;
						if(e.dropListGetData){
							dlgetData = e.dropListGetData(droplistItem, elementIndex, (!modalItem));
						}
						let defaultValue = ( dlgetData ? dlgetData :  customRender_.props.defaultValue);
						const newName = ( customRender_?.props?.name ?? e.field);
						if(modalItem){
							const values = {...modalItem}
							for (var key in values) {
								if(key === newName && !defaultValue)
								{
									defaultValue = values[key];
								}
							}							
							
						}						

						let elm = {...customRender_};
						elm.props = {
							...customRender_.props,
							defaultValue: defaultValue,// ( dlgetData ? dlgetData :  customRender_.props.defaultValue),
							form:itemsForm,
							name: newName,//( customRender_?.props?.name ?? e.field),
							label: ( customRender_?.props?.label ?? e.header),
							enabled: (e.enabled == null || e.enabled === undefined) ? true : e.enabled,
						}
						if(e.Condificional){
							if(e.Condificional({form: itemsForm, data: itemsForm.getValues(), render: elm}) === false) return <></>;
						}
						return elm;
					})
				}</Form.Row>
				<div className="button-position">
					<Button
						title="Cancelar"
						contained={false}
						type="button"
						onClick={() => { setModalShow(false) }}
					/>
					<Button
						title="Salvar"
						contained={true}
						type="button"
						onClick={() => { submit_form(itemsForm.getValues()); } }
					/>
				</div>
			</Form>
		</Modal>;
	}

	const parsenNoActions = ({data}: any) => {
		return <React.Fragment>

		</React.Fragment>
	}

	const EditItemClickEvent = (data:any)=>{		
		let realitems = props.form.getValues(props.name) as EntityType[];
		if(!realitems){return;}
		let ItemSel = realitems.find(element => element?.uid === data?.uid);
		itemsForm.reset({...ItemSel});
		setModalItem(ItemSel);
		clearPageMessages();
		setModalShow(true);
	}

	const parseActions = ({data}: any) => {
		if(data.noRenderButtons === true ){
			return <React.Fragment>

			</React.Fragment>
		}
	
		return <React.Fragment>
				<EditButton
					onClick={() => EditItemClickEvent(data)}
				/>
				&nbsp;&nbsp;
				<DeleteButton
					onClick={() => {
						let realitems = props.form.getValues(props.name) as EntityType[];	
						if(!realitems){
							if(listitems){
								realitems = listitems;							}
							else 
							{ return; }
						}
						const newitems = realitems.filter(element => element?.uid !== data?.uid);
						if (newitems != null){
							let currentListItens = [...newitems];							
							setListItems(currentListItens);
							if(props.onChanged) { props.onChanged(props, currentListItens);}	
							props.form.setValue(props.name, currentListItens);						
						}
					}}
				/>
				
		</React.Fragment>
	};

	React.useEffect(() => {
		itemsForm.reset();
		setModalItem(editModalItem);

	}, [editModalItem]);

	
	React.useEffect(() => {
		props.form.register(`${props.name}`);//;{name:props.name});		
		let realitems = props.form.getValues(props.name) as EntityType[];
		if(realitems?.length > 0){
			setStared(true);
			realitems.forEach(e=> { if(!e.uid) { e.uid = uuid(); } })
			setListItems(realitems);			
		}
		else if(props.defaultValue){
			props.form.setValue(props.name, props.defaultValue);
			realitems = props.defaultValue;
			realitems.forEach(e=> { if(!e.uid) { e.uid = uuid(); } })
			setListItems(realitems);		
		}
	}, [props.name, props.defaultValue]);

	
	React.useEffect(() => {
		if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }

	}, [props.enabled]);

	const [asErro, setAsErro] = React.useState<boolean>(true);
	const [msgError, setMsgError] = React.useState<any>();
	const myRef = useRef(null);

	React.useEffect(() => {
		CheckMsgErros(props, (msg:any)=>{ setMsgError(msg) }, (err:any)=>{ 
			setAsErro(err) 
			if(err && props.isInvalidCheck){
				if(myRef != null) {
					let elmt = myRef.current as any;
					const yOffset = -100; 
					const y = elmt.getBoundingClientRect().top + window.pageYOffset + yOffset;
					window.scrollTo({top: y, behavior: 'smooth'});

					// let elmt = myRef.current as any;
					// //window.scrollTo(0, elmt?.offsetTop - 100)   
					// elmt?.scrollIntoView({ behavior: 'smooth', block: 'start' }, 500);
				}
			}
		})
        // let _asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
        // setAsErro(_asErro)
    }, [props.form?.errors, props?.name]);

		
	return <Form.Group ref={myRef} className="customGrid mb-0" as={Col} md={props.layoutColumns ?? 12}>
		{parseModal()}
		<Form.Row className="row-custom mb-0 mt-2">			
			<Form.Group className="mb-0" as={Col}>
				<Form.Label>{props.label} {(props.required && isEnabled) ? <em>*</em> : ''}</Form.Label>
			</Form.Group>
			
			{(props.isInvalidCheck && asErro)? 
			<Form.Group className="mb-0" as={Col}>
			<p className="error-field">{props?.name ? ( msgError ??  props.form?.errors[props?.name]?.message) : ''}</p>
			</Form.Group>
			:''}
			
			{isEnabled ? 			
			<Form.Group className="mb-0 text-right" as={Col} md={4}>
				
				{props.DropList? 
				<Dropdown>
				<Dropdown.Toggle className="btn-sm btn-success grid-dropdown-edit" variant="success" id="dropdown-basic">
					+ Adicionar
				</Dropdown.Toggle>

				<Dropdown.Menu>
					{props.DropList?.map((text:any, pindex: any) =>{
						return <Dropdown.Item href="#"
						onClick  = {(e: any)=>{ 	
							setDroplistItem({value: text, index: pindex, event: e})
							setModalItem(undefined); itemsForm.reset({}); setModalShow(true)
						}}
						>{text}</Dropdown.Item>
					})}					
				</Dropdown.Menu>
				</Dropdown>
				:<Button
					className="btn-sm btn-success"
					title="+ Adicionar"
					contained={true}
					type="button"
					onClick={() => { setModalItem(undefined); itemsForm.reset({}); clearPageMessages(); setModalShow(true) }}
				/>}
			</Form.Group>: ''}		
		</Form.Row> 
		<Form.Row className="row-custom mb-0">
			<Form.Group as={Col}>
				<div className="ag-theme-alpine custom-gridview" style={props.style}>          
					<AgGridReact
						rowData={listitems}
						overlayNoRowsTemplate={`<span class="ag-overlay-no-rows-center">${props.overlayNoRowsTemplate ?? ''}</span>`}
						//deltaRowDataMode={true}
						immutableData={true}
						defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
						gridOptions={Object.assign({ 
							getRowNodeId: (data:any) => {return data.uid;}
						}, props.gridOptions) as AgGridReactProps}
						stopEditingWhenGridLosesFocus={true}
						singleClickEdit={true}
						pinnedBottomRowData={props?.pinnedValueFormmatter ? [props?.pinnedValueFormmatter(listitems)] : undefined}
						frameworkComponents={{
							actions: parseActions,
							noAction: parsenNoActions,
							pinnedRowCellRenderer: <span>{"TESTE"}</span>,
						}}						
					>
						{props.collumns?.filter(e => e.showOnGrid !== false).map((e, index) =>{
							return <AgGridColumn
							headerName={e.header}
							field={e.field}
							cellStyle={Object.assign({
								justifyContent: e.justifyContent ?? false
							}, e.gridCellStyle)}
							autoHeight
							wrapText
							minWidth={e.minWidth ?? 10}
							maxWidth={e.maxWidth ?? 2000}
							valueFormatter={ (e.countNumber? (event:any)=>{ return (index + 1).toString()} :e.gridValueFormatter)}
							cellRenderer={e.cellRenderer}
							/>
						})}
						{isEnabled ? 
						<AgGridColumn
							headerName="Ações"
							cellRenderer="actions"
							maxWidth={100}
						/>:
						<AgGridColumn
						headerName="Ações"
						cellRenderer="noAction"
						maxWidth={30}
						/>}
						
					
					</AgGridReact>					
				</div>				
			</Form.Group>
		</Form.Row>
	</Form.Group>
}
