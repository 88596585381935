import React, { useState, useEffect, useReducer, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { FiX, FiCheck } from 'react-icons/fi';
import {
	CultureForm,
	CultureResults,
	DescriptorsTableResults,
	DiseaseTableResults,
	RecordTypesResults,
	DescriptorsResults,
	DiseasesResults,
	RegionsResults,
	FilesCultura,
	ModelFilesProtection
} from '../../../../models/models';
import Button from '../../../../components/Button';
import AddButton from '../../../../components/AddButton';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';
import If from '../../../../components/If';
import AlertError from '../../../../components/AlertError';
import AlertSuccess from '../../../../components/AlertSuccess';
import Modal from '../../../../components/Modal';
import LoadingInForm from '../../../../components/LoadingInForm';
import FileExistent from '../../../../components/FileExistent';
import { STRINGS } from '../../../../util/strings';
import Fieldset from '../../../../components/Fieldset';
import api, { apiFile, apiFileDownload } from '../../../../services/api';
import { statusToText } from '../../../../functions/formatStatus';
import { modelosDeTiposdeRegistro } from '../../../../util/tiposDeRegistro.json';
import Loading from '../../../../components/Loading';
import Toggle from '../../../../components/form/Toggle/Toggle';
import '../../styles_edit.css';

interface stateType {
	id: number;
}

export default function Culture ()
{
	const history = useHistory();
	const location = useLocation<stateType>();
	const [cultureResult, setCultureResult] = useState<CultureResults>();
	const [recordsType, setRecordsType] = useState<Array<RecordTypesResults>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingRecordsType, setLoadingRecordsType] = useState<boolean>(true);
	const [loadingCulture, setLoadingCulture] = useState<boolean>(false);
	const [loadingFilesRegister, setLoadingFilesRegister] = useState<boolean>(false);
	const [loadingFilesProtection, setLoadingFilesProtection] = useState<boolean>(false);
	const [successfulResponseCulture, setSuccessfulResponseCulture] = useState<boolean>(false);
	const [successfulResponseRecordsType, setSuccessfulResponseRecordTypes] = useState<boolean>(false);
	const [successfulResponseProtection, setSuccessfulResponseProtection] = useState<boolean>(false);
	const [errorCulture, setErrorCulture] = useState<any>(null);
	const [errorFiles, setErrorFiles] = useState<boolean>(false);
	const [errorFilesProtection, setErrorFilesProtection] = useState<boolean>(false);
	const [errorRecordsType, setErrorRecordsType] = useState<boolean>(false);
	const [showDescriptors, setShowDescriptores] = useState<boolean>(false);
	const [showDisease, setShowDisease] = useState<boolean>(false);
	const [showRegion, setShowRegion] = useState<boolean>(false);
	const [selectedDescriptors, setSelectedDescriptors] = useState<DescriptorsTableResults>();
	const [selectedDisease, setSelectedDisease] = useState<DiseaseTableResults>();
	const [descriptors, setDescriptors] = useState<Array<DescriptorsResults>>([]);
	const [tempCharacteristicList, setTempCharacteristicList] = useState<Array<any>>([]);
	const [diseases, setDiseases] = useState<Array<DiseasesResults>>([]);
	const [tempReactionsList, setTempReactionsList] = useState<Array<any>>([]);
	const [regions, setRegions] = useState<Array<RegionsResults>>([]);
	const [lblFile, setLblFile] = useState('Escolher arquivo');
	const [lblDescriptors, setLblDescriptors] = useState('Escolher arquivo');
	const [lblReport, setLblReport] = useState('Escolher arquivo');
	const [files, setFiles] = useState<Array<any>>([]);
	const [registerTypeName, setRegisterTypeName] = useState('');
	const [modelRegisterType, setModelRegisterType] = useState('');
	const [errorGetCulture, setErrorGetCulture] = useState<boolean>(false);
	const [fileProtectionRT, setFilesProtectionRT] = useState<FilesCultura>();
	const [fileProtectionDesc, setFileProtectionDesc] = useState<FilesCultura>();
	const [manipulatedFiles, setManipulatedFiles] = useState<any[]>([]);
	const [registerFilesSubmit, setRegisterFilesSubmit] = useState<any[]>([]);
	const [infoFilesProtection, setInfoFilesProtection] = useState<ModelFilesProtection>();
	const [loadingEditDesc, setLoadingEditDesc] = useState<boolean>(false);
	const [editDescriptors, setEditDescriptors] = useState<boolean>(false);
	const [descriptorToEdit, setDescriptorToEdit] = useState<DescriptorsResults>({
		ativo: true,
		caracteristicasDescritores: [],
		descricao: ''
	});
	const [avaliacaoCulivarAsText, setAvaliacaoCulivarAsText] = useState<boolean>(false);
	const [cicloAgronomicoAsText, setCicloAgronomicoAsText] = useState<boolean>(false);
	const [loadingEditDisease, setLoadingEditDisease] = useState<boolean>(false);
	const [editDiseases, setEditDiseases] = useState<boolean>(false);
	const [diseaseToEdit, setDiseaseToEdit] = useState<DiseasesResults>({
		ativo: true,
		culturaId: 0,
		descricao: '',
		reacoesDoencas: [],
	});

	var formDataProtection = new FormData();

	const cultureSchema = yup.object().shape({
		name								: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		description							: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		descriptorDescription				: showDescriptors ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		descriptorCharacteristic			: showDescriptors ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		descriptorStatus					: yup.boolean(),
		diseaseDescription					: showDisease ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		diseaseReaction						: showDisease ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		diseaseStatus						: yup.boolean(),
		regionName							: showRegion ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		regionStatus						: yup.boolean(),
		registerType						: yup.number(),
		modelRegisterType					: yup.string(),
		active								: yup.boolean(),
		modelCultivarRate					: yup.mixed(),
		modelCycleAndCharacteristics		: yup.mixed(),
		modelProductivity					: yup.mixed(),
		modelQuality						: yup.mixed(),
		modelTechnicalReport				: yup.mixed(),
		modelBreedersDeclaration			: yup.mixed(),
		modelDescriptorsTableProtection		: yup.mixed(),
		modelTechnicalReportProtection		: yup.mixed(),
		modelCultivarRateMatExp				: yup.mixed(),
		modelCycleAndCharacteristicsMatExp	: yup.mixed(),
		modelProductivityMatExp				: yup.mixed(),
		modelQualityMatExp					: yup.mixed(),
		modelTechnicalReportMatExp			: yup.mixed(),
		modelBreedersDeclarationMatExp		: yup.mixed(),
		modelCultivarRateLinPar				: yup.mixed(),
		modelCycleAndCharacteristicsLinPar	: yup.mixed(),
		modelProductivityLinPar				: yup.mixed(),
		modelQualityLinPar					: yup.mixed(),
		modelTechnicalReportLinPar			: yup.mixed(),
		modelBreedersDeclarationLinPar		: yup.mixed(),
	})

	const defaultForm = useForm({
		mode: 'onTouched',
		resolver: yupResolver(cultureSchema)
	})

	const getCultureById = async () =>
	{
		try
		{
			const { data, status } = await api.get<CultureResults>(`api/v1/cultivares/Cultura/${location.state.id}`);

			if (status !== 500)
			{
				// console.log('Get Data');
				// console.log(data);

				defaultForm.reset({
					name: data.nome,
					description: data.descricao,
					active: data.ativo,
				});
				setDescriptors(data.descritores);
				setDiseases(data.doencas);
				setRegions(data.culturaRegioes);
				setAvaliacaoCulivarAsText(data.avaliacaoCulivarAsText);
				setCicloAgronomicoAsText(data.cicloAgronomicoAsText);

				if (data.modeloRegistrosCultivares.length)
				{
					const allRegisterFiles = data.modeloRegistrosCultivares.map(item => {return item})
					setFiles(allRegisterFiles);
					ParseFilesToList(allRegisterFiles);
				}

				if (data.modeloProtecaoCultivares)
				{
					setInfoFilesProtection(data.modeloProtecaoCultivares);
					setFilesProtectionRT(data.modeloProtecaoCultivares.modeloRelatorioTecnicoObtencao)
					setFileProtectionDesc(data.modeloProtecaoCultivares.modeloTabelaDescritores)
				}
			}
			else
			{
				setErrorGetCulture(true);
			}
		}
		catch (error:any)
		{
			setErrorGetCulture(true);
		}
	}

	const getRegistersType = async () =>
	{
		try
		{
			const { data } = await api.get<RecordTypesResults[]>('api/v1/cultivares/TipoRegistro/obterporfiltro?Ativo=true');
			setRecordsType(data);

		}
		catch (error:any)
		{
			setErrorRecordsType(true);
		}
		
		setLoadingRecordsType(false);
	}

	useEffect(() =>
	{
		getCultureById();
	}, [location.state.id, defaultForm.reset]);

	useEffect(() =>
	{
		getRegistersType();
		setTimeout(() => {setLoading(false);}, 1000);
	}, [])

	const verifyErrorsOnForm = (): boolean =>
	{
		if (defaultForm.errors.name || defaultForm.errors.description)
			return true;
		return false;
	}

	const onSubmitCulture = async (cultureData: CultureForm) =>
	{
		setLoadingCulture(true);
		setErrorCulture(null);
		try
		{
			const { data, status } = await api.put('api/v1/cultivares/Cultura', {
				id: location.state.id,
				nome: cultureData.name,
				descricao: cultureData.description,
				ativo: cultureData.active,
				descritores: descriptors,
				doencas: diseases,
				culturaRegioes: regions,
				modeloRegistrosCultivares: expectedFilesRegister(),
				avaliacaoCulivarAsText:avaliacaoCulivarAsText,
				cicloAgronomicoAsText:cicloAgronomicoAsText,
				modeloProtecaoCultivares: {
					id: infoFilesProtection?.id,
					culturaId: location.state.id,
					modeloRelatorioTecnicoObtencaoId: fileProtectionRT?.id,
					modeloTabelaDescritoresId: fileProtectionDesc?.id
				}
			});

			if (status === 200)
			{
				setSuccessfulResponseCulture(true);
				setCultureResult(data);

				if (registerFilesSubmit.length)
				{
					onSubmitFilesRegisters();
				}

				if (defaultForm.getValues('modelTechnicalReportProtection').length || defaultForm.getValues('modelDescriptorsTableProtection').length)
				{
					onSubmitFilesProtection();
				}
			}
		}
		catch (error:any)
		{
			setErrorCulture(error.response.data);
		}

		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}, 1000);

		setTimeout(() => {
			history.goBack();
		}, 3500);

		setLoadingCulture(false);
	}

	const onSubmitFilesRegisters = async () =>
	{
		setErrorFiles(false);
		setLoadingFilesRegister(true);

		for (let count = 0, countLen = registerFilesSubmit.length; count < countLen; count++)
		{
			let formNew = new FormData()
			try
			{
				formNew.append(`${registerFilesSubmit[count].modeloTipoDeRegistro}`, registerFilesSubmit[count].arquivo);
				const { status } = await apiFile.post(`api/v1/cultivares/Cultura/${location.state.id}/modelosregistro/${registerFilesSubmit[count].idTipoRegistro}`, formNew);
				if (status === 200)
				{
					setSuccessfulResponseRecordTypes(true);
				}
			}
			catch (error:any)
			{
				setErrorFiles(true);
			}
		}

		setLoadingFilesRegister(false);
	}

	const onSubmitFilesProtection = async () =>
	{
		setErrorFilesProtection(false);
		setLoadingFilesProtection(true);
		let fileRelatorioTec = defaultForm.getValues('modelTechnicalReportProtection');
		let fileDescritores = defaultForm.getValues('modelDescriptorsTableProtection');

		try
		{
			formDataProtection.append('fileModeloRelatorioTecnicoObtencao', fileRelatorioTec[0]);
			formDataProtection.append('fileModeloTabelaDescritores', fileDescritores[0]);

			const { status } = await apiFile.post(`api/v1/cultivares/Cultura/${location.state.id}/modelosprotecao`, formDataProtection);

			if (status === 200)
			{
				setSuccessfulResponseProtection(true);
			}
		}
		catch (error:any)
		{
			setErrorFilesProtection(true);
		}

		setLoadingFilesProtection(false);
	}

	function expectedFilesRegister(): any[]
	{
		let requestArray: any = {};
		let culturaId = location.state.id;

		for (let i = 0; i < manipulatedFiles.length; i++)
		{
			let it = manipulatedFiles[i];
			let novoRegistro = false;
			let temp = requestArray[it.idRegistro];
			
			if (temp == null)
			{
				temp = mountRegisterFileObject(it.id, culturaId, it.idRegistro);
				novoRegistro = true;
			}
			
			switch (it.nomeModelo)
			{
				case "fileModeloAvaliacaoCultivar":
					
					temp.modeloAvaliacaoCultivarId = it.modeloAvaliacaoCultivarId;
					break;
				
				case "fileModeloCicloAgronomico":
					
					temp.modeloCicloAgronomicoId = it.modeloCicloAgronomicoId;
					break;

				case "fileModeloProdutividade":
					
					temp.modeloProdutividadeId = it.modeloProdutividadeId;
					break;

				case "fileModeloQualidade":
					
					temp.modeloQualidadeId = it.modeloQualidadeId;
					break;

				case "fileModeloRelatorioTecnico":
					
					temp.modeloRelatorioTecnicoId = it.modeloRelatorioTecnicoId;
					break;

				case "fileModeloDeclaracaoMelhorista":
					
					temp.modeloDeclaracaoMelhoristaId = it.id;
					break;
			}

			if (novoRegistro)
			{
				requestArray[it.idRegistro] = temp;
			}
		}

		return Object.keys(requestArray).map((key: any) => {return requestArray[key];});
	}

	function mountRegisterFileObject(id: any, culturaId: any, tipoRegistro: any)
	{
		return {
			id: id,
			culturaId: culturaId,
			tipoRegistroId: tipoRegistro,
			modeloAvaliacaoCultivarId: null,
			modeloCicloAgronomicoId: null,
			modeloProdutividadeId: null,
			modeloQualidadeId: null,
			modeloRelatorioTecnicoId: null,
			modeloDeclaracaoMelhoristaId: null
		};
	}

	const verifyErrorsOnFormDiseases = (): boolean =>
	{
		if (defaultForm.errors.diseaseDescription)
			return true;
		
		return false;
	}

	const onRowSelectedDisease = ({ data, node }: any) =>
	{
		showDiseasesResults(data.descricao, node);
	}

	const showDiseasesResults = (description: any, node: any) =>
	{
		let arr: DiseasesResults[] = [];

		node.gridApi.forEachNode(({ data }: any) => arr.push(data));

		let results = diseases.filter((disease => {
			return disease.descricao === description;
		}))

		let result: DiseaseTableResults = results[0];
		setSelectedDisease(result);
	}

	const actionsDiseases = (props: any) =>
	{
		return <Fragment>
			<EditButton onClick={() => {
				setEditDiseases(true);
				setDiseaseToEdit(props.data);
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setDiseases([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setDiseases([...arr]);
			}} />
		</Fragment>;
	}

	const actionsModalDiseases = (props: any) =>
	{
		return <Fragment>
			<EditButton onClick={() => {
				props.api.startEditingCell({
					rowIndex: props.rowIndex,
					colKey: 'descricao',
				});
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setTempReactionsList([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setTempReactionsList([...arr]);
			}} />
		</Fragment>;
	}

	const onSubmitDiseases = () =>
	{
		setDiseases([...diseases, {
			culturaId: location.state.id,
			descricao: defaultForm.getValues('diseaseDescription'),
			reacoesDoencas: tempReactionsList,
			ativo: defaultForm.getValues('diseaseStatus'),
		}]);
	}

	const verifyErrorsOnFormDescriptors = (): boolean =>
	{
		if (defaultForm.errors.descriptorDescription)
			return true;
		
		return false;
	}

	const onRowSelectedDescriptors = (props: any) =>
	{
		showDescriptorsResults(props.data.descricao, props.node);
	}

	const showDescriptorsResults = (description: any, node: any) =>
	{
		let arr: DescriptorsResults[] = [];

		node.gridApi.forEachNode(({ data }: any) => arr.push(data));

		let results = arr.filter((descriptor => {
			return descriptor.descricao === description;
		}))

		let result: DescriptorsTableResults = results[0];
		setSelectedDescriptors(result);
	}

	const actionsDescriptors = (props: any) =>
	{
		return <Fragment>
			<EditButton onClick={() => {
				setEditDescriptors(true);
				setDescriptorToEdit(props.data);
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setDescriptors([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setDescriptors([...arr]);
			}} />
		</Fragment>;
	}

	const actionsModalDescriptors = (props: any) =>
	{
		return <Fragment>
			<EditButton onClick={() => {
				props.api.startEditingCell({
					rowIndex: props.rowIndex,
					colKey: 'descricao',
				});
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setTempCharacteristicList([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setTempCharacteristicList([...arr]);
			}} />
		</Fragment>;
	}

	const actionsModalEditDiseases = (props: any) =>
	{
		let editButton = <EditButton
			onClick={() => {
				props.api.startEditingCell({
					rowIndex: props.rowIndex,
					colKey: 'descricao',
				});
			}}
		/>;

		let deleteButton = <DeleteButton
			onClick={() => {
				setDiseaseToEdit(state => ({
					ativo: state.ativo,
					reacoesDoencas: [],
					descricao: state.descricao,
					culturaId: state.culturaId,
					id: state.id
				}))

				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setDiseaseToEdit(state => ({
					ativo: state.ativo,
					reacoesDoencas: [...arr],
					descricao: state.descricao,
					culturaId: state.culturaId,
					id: state.id
				}))
			}}
		/>;

		return <Fragment>
			{editButton}
			&nbsp;&nbsp;
			{deleteButton}
		</Fragment>;
	}

	const actionsModalEditDescriptors = (props: any) =>
	{
		let editButton = <EditButton
			onClick={() => {
				props.api.startEditingCell({
					rowIndex: props.rowIndex,
					colKey: 'descricao',
				});
			}}
		/>;

		let deleteButton = <DeleteButton
			onClick={() => {
				setDescriptorToEdit(state => ({
					ativo: state.ativo,
					caracteristicasDescritores: [],
					descricao: state.descricao,
					culturaId: state.culturaId,
					id: state.id
				}))

				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setDescriptorToEdit(state => ({
					ativo: state.ativo,
					caracteristicasDescritores: [...arr],
					descricao: state.descricao,
					culturaId: state.culturaId,
					id: state.id
				}))
			}}
		/>;

		return <Fragment>
			{editButton}
			&nbsp;&nbsp;
			{deleteButton}
		</Fragment>;
	}

	const onSubmitDescriptors = () =>
	{
		setDescriptors([...descriptors, {
			culturaId: location.state.id,
			descricao: defaultForm.getValues('descriptorDescription'),
			caracteristicasDescritores: tempCharacteristicList,
			ativo: defaultForm.getValues('descriptorStatus'),
		}]);
	}

	const actionsRegions = (props: any) =>
	{
		return <Fragment>
			<DeleteButton onClick={() => {
				setRegions([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setRegions([...arr]);
			}} />
		</Fragment>;
	}

	const verifyErrorsOnFormRegions = (): boolean => {
		if (defaultForm.errors.regionName)
			return true;
		return false;
	}

	const onSubmitRegions = () =>
	{
		setRegions([...regions, {
			culturaId: location.state.id,
			descricao: defaultForm.getValues('regionName'),
			ativo: defaultForm.getValues('regionStatus')
		}]);
	}

	const actionsFilesRegister = (props: any) =>
	{
		return <DeleteButton
				onClick={() => {
					setManipulatedFiles([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setManipulatedFiles(arr);
				}}
		/>;
	}

	const downloadModelFile = async (file: FilesCultura | undefined) =>
	{
		if (file === undefined)
			return;
			
		try
		{
			apiFileDownload.get(`api/v1/cultivares/Download?codigo=${file.codigo}`).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.nome);
				document.body.appendChild(link);
				link.click();
			});
		}
		catch (error:any)
		{
			console.error(error);
		}
	}

	const getModelName = (modelo: string): string => {
		const result = modelosDeTiposdeRegistro.filter(item => item.modelo === modelo);
		const name = result.length ? result[0].label : '';

		return name;
	}

	const getRegisterTypeName = (id: number): string =>
	{
		let result = recordsType.filter(item => item.id === id);

		if (result.length <= 0)
			return '';

		return result[0].nome;
	}

	const ParseFilesToList = (files: any[]) =>
	{
		const newFiles: any = [];

		if (files.length <= 0)
		{
			setManipulatedFiles([...manipulatedFiles, ...newFiles]);
			return;
		}

		for (let i = 0, t = files.length; i < t; i++)
		{
			if (files[i] === undefined)
				continue;

			let file = files[i];

			if (file.modeloAvaliacaoCultivar)
			{
				newFiles.push({
					id: file.id,
					modeloAvaliacaoCultivarId: file.modeloAvaliacaoCultivar.id,
					idRegistro: file.tipoRegistroId,
					modelo: file.modeloAvaliacaoCultivar,
					nomeModelo: "fileModeloAvaliacaoCultivar",
				});
			}
			
			if (file.modeloCicloAgronomico)
			{
				newFiles.push({
					id: file.id,
					modeloCicloAgronomicoId: file.modeloCicloAgronomico.id,
					idRegistro: file.tipoRegistroId,
					modelo: file.modeloCicloAgronomico,
					nomeModelo: "fileModeloCicloAgronomico",
				});
			}
			
			if (file.modeloDeclaracaoMelhorista)
			{
				newFiles.push({
					id: file.id,
					modeloDeclaracaoMelhoristaId: file.modeloDeclaracaoMelhorista.id,
					idRegistro: file.tipoRegistroId,
					modelo: file.modeloDeclaracaoMelhorista,
					nomeModelo: "fileModeloDeclaracaoMelhorista",
				});
			}
			
			if (file.modeloProdutividade)
			{
				newFiles.push({
					id: file.id,
					modeloProdutividadeId: file.modeloProdutividade.id,
					idRegistro: file.tipoRegistroId,
					modelo: file.modeloProdutividade,
					nomeModelo: "fileModeloProdutividade",
				});
			}
			
			if (file.modeloQualidade)
			{
				newFiles.push({
					id: file.id,
					modeloQualidadeId: file.modeloQualidade.id,
					idRegistro: file.tipoRegistroId,
					modelo: file.modeloQualidade,
					nomeModelo: "fileModeloQualidade",
				});
			}
			
			if (file.modeloRelatorioTecnico)
			{
				newFiles.push({
					id: file.id,
					modeloRelatorioTecnicoId: file.modeloRelatorioTecnico.id,
					idRegistro: file.tipoRegistroId,
					modelo: file.modeloRelatorioTecnico,
					nomeModelo: "fileModeloRelatorioTecnico",
				});
			}
		}

		setManipulatedFiles([...manipulatedFiles, ...newFiles]);
	}

	const radioBtnDescriptors = (props: any) =>
	{
		return <div className="ngSelectionCell">
			<input onChange={() => onRowSelectedDescriptors(props)} name="selected" type="radio" />
		</div>;
	}

	const radioBtnDiseases = (props: any) =>
	{
		return <div className="ngSelectionCell">
			<input onChange={() => onRowSelectedDisease(props)} name="selected" type="radio" />
		</div>;
	}

	const updateDescriptor = () =>
	{
		setLoadingEditDesc(true);
		setSelectedDescriptors(undefined);
		let idx = 0;
		let descriptor: any = null;
		let allDescriptors = descriptors;
		setDescriptors([]);

		for (let count = 0; count < allDescriptors.length; count++)
		{
			if (allDescriptors[count].id === descriptorToEdit.id)
			{
				idx = count;
				descriptor = descriptorToEdit;
			}
		}

		allDescriptors.splice(idx, 1);
		allDescriptors.push(descriptor);
		allDescriptors.sort((a: any, b: any): number => {
			return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
		});
		setDescriptors(allDescriptors);

		setTimeout(() => {
			setLoadingEditDesc(false);
		}, 2000)
	}

	const updateDiseases = () =>
	{
		setLoadingEditDisease(true);
		setSelectedDisease(undefined);
		let idx = 0;
		let disease: any = null;
		let allDiseases = diseases;
		setDiseases([]);

		for (let count = 0; count < allDiseases.length; count++)
		{
			if (allDiseases[count].id !== diseaseToEdit.id)
				continue;
		
			idx = count;
			disease = diseaseToEdit;
		}

		allDiseases.splice(idx, 1);
		allDiseases.push(disease);
		allDiseases.sort((a: any, b: any): number => {
			return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
		});
		setDiseases(allDiseases);

		setTimeout(() => {
			setLoadingEditDisease(false);
		}, 2000)
	}

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			<AlertError
				message={STRINGS.ALERT_WARNING_FIELDS}
			/>
		</If>
		<If condition={successfulResponseCulture && !errorCulture}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS}
			/>
		</If>
		<If condition={successfulResponseRecordsType || successfulResponseProtection}>
			<AlertSuccess
				message={STRINGS.ALERT_FILES_SUCCESS}
			/>
		</If>
		<If condition={!successfulResponseCulture && errorCulture !== null}>
			{errorCulture !== null && errorCulture.message.length > 0 ? errorCulture.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<If condition={loading}>
			<span className="loading-page">
				<Loading />
			</span>
		</If>
		<If condition={!loading && !errorGetCulture}>
			<h3>Edição de Cultura</h3>
			<Form onSubmit={defaultForm.handleSubmit(onSubmitCulture)}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Nome da cultura<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="name"
							type="text"
							placeholder="Insira o nome da cultura"
							isInvalid={defaultForm.errors.name !== undefined}
							autoComplete="off"
							maxLength={100}
						/>
						<If condition={defaultForm.errors.name}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.name?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Nome científico<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="description"
							type="text"
							placeholder="Insira o nome científico da cultura"
							isInvalid={defaultForm.errors.description !== undefined}
							autoComplete="off"
							maxLength={255}
						/>
						<If condition={defaultForm.errors.description}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.description?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					&nbsp;&nbsp;
				<Form.Check
						ref={defaultForm.register}
						name="active"
						label="Ativa"
					/>
				</Form.Row>

				<Fieldset title="Descritores">
					<Fragment>
						<AddButton type="button" onClick={() => setShowDescriptores(true)} />
						<If condition={loadingEditDesc}>
							<LoadingInForm />
						</If>
						<If condition={descriptors.length && !loadingEditDesc}>
							<div className="ag-theme-alpine" style={{ height: '150px', width: '100%' }}>
								<AgGridReact
									rowData={descriptors}
									defaultColDef={{ flex: 1, sortable: true, resizable: true, editable: true }}
									stopEditingWhenGridLosesFocus={true}
									singleClickEdit={true}
									frameworkComponents={{
										actions: actionsDescriptors,
										radioButton: radioBtnDescriptors,
									}}
								>
									<AgGridColumn
										headerName="Selecionar"
										pinned="left"
										editable={false}
										cellRenderer='radioButton'
									/>
									<AgGridColumn
										headerName='Descritores'
										field='descricao'
									/>
									<AgGridColumn
										headerName='Status'
										field='ativo'
										valueFormatter={({ data }: any) => statusToText(data.ativo)}
										editable={false}
										maxWidth={120}
									/>
									<AgGridColumn
										headerName='Ações'
										cellRenderer='actions'
										editable={false}
										maxWidth={100}
									/>
								</AgGridReact>
							</div>
							<br />
							<If condition={selectedDescriptors?.caracteristicasDescritores.length}>
								<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
									<AgGridReact
										rowData={selectedDescriptors?.caracteristicasDescritores}
										defaultColDef={{ flex: 1, sortable: true }}
									>
										<AgGridColumn
											headerName='Características'
											field="descricao"
											wrapText={true}
											autoHeight
										/>
									</AgGridReact>
								</div>
							</If>
						</If>
					</Fragment>
				</Fieldset>

				<Fieldset title="Doenças">
					<Fragment>
						<AddButton type="button" onClick={() => setShowDisease(true)} />
						<If condition={loadingEditDisease}>
							<LoadingInForm />
						</If>
						<If condition={diseases.length && !loadingEditDisease}>
							<div className="ag-theme-alpine" style={{ height: '150px', width: '100%' }}>
								<AgGridReact
									rowData={diseases}
									defaultColDef={{ flex: 1, sortable: true, resizable: true }}
									frameworkComponents={{
										actions: actionsDiseases,
										radioButton: radioBtnDiseases,
									}}
								>
									<AgGridColumn
										headerName="Selecionar"
										pinned="left"
										cellRenderer='radioButton'
									/>
									<AgGridColumn
										headerName='Doenças'
										field='descricao'
									/>
									<AgGridColumn
										headerName='Status'
										field='ativo'
										valueFormatter={({ data }: any) => statusToText(data.ativo)}
										maxWidth={120}
									/>
									<AgGridColumn
										headerName='Ações'
										cellRenderer='actions'
										maxWidth={100}
									/>
								</AgGridReact>
							</div>
							<br />
							<If condition={selectedDisease?.reacoesDoencas.length}>
								<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
									<AgGridReact
										rowData={selectedDisease?.reacoesDoencas}
										defaultColDef={{ flex: 1, sortable: true }}
									>
										<AgGridColumn
											headerName='Reações'
											field="descricao"
											wrapText={true}
											autoHeight
										/>
									</AgGridReact>
								</div>
							</If>
						</If>
					</Fragment>
				</Fieldset>

				<Fieldset title="Regiões">
					<Fragment>
						<AddButton type="button" onClick={() => setShowRegion(true)} />
						<If condition={regions.length}>
							<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
								<AgGridReact
									rowData={regions}
									defaultColDef={{ flex: 1, sortable: true, resizable: true }}
									frameworkComponents={{
										actions: actionsRegions,
									}}
								>
									<AgGridColumn
										headerName='Regiões'
										field='descricao'
									/>
									<AgGridColumn
										headerName='Status'
										field='ativo'
										valueFormatter={({ data }: any) => statusToText(data.ativo)}
										maxWidth={120}
									/>
									<AgGridColumn
										headerName='Ações'
										cellRenderer='actions'
										maxWidth={100}
									/>
								</AgGridReact>
							</div>
						</If>
					</Fragment>
				</Fieldset>

				<Fieldset title="Modelos de Tipos de Registro">
					<span className="models-registers-type">
						<Form.Row className="row-custom">
							<Toggle
								id="toggle-ciclo-agronomico"
								name="ciclo_agronomico_toggle"
								label={<span>Usar <strong>10. Avaliação do ciclo e características agronômicas da cultivar</strong> como texto Livre</span>}
								small
								defaultValue={cicloAgronomicoAsText}
								onChange={(newStats:boolean) => {
									setCicloAgronomicoAsText(newStats);
								}}
							/>
							<Toggle
								id="toggle-avaliacao-cultivar"
								name="avaliacao_cultivar_toggle"
								label={<span>Usar <strong>12. Avaliação da qualidade tecnológica/industrial</strong> como texto Livre</span>}
								small
								defaultValue={avaliacaoCulivarAsText}
								onChange={(newStats:boolean) => {
									setAvaliacaoCulivarAsText(newStats);
								}}
							/>
							<Form.Group as={Col}>
								<Form.Label>
									Tipo de registro
								</Form.Label>
								&nbsp;&nbsp;
								<If condition={loadingRecordsType}>
									<LoadingInForm />
								</If>
								<Controller
									control={defaultForm.control}
									name="registerType"
									render={() => <Form.Control
										as="select"
										onChange={({ target }: any) => {
											let index = target.selectedIndex;
											defaultForm.setValue('registerType', target.value)
											setRegisterTypeName(target.options[index].label);
										}}
									>
										<Form.Control
											as="option"
											label="Escolha uma opção"
											value=""
										/>
										{recordsType.map((record: RecordTypesResults) => (<Form.Control
												key={record.id}
												as="option"
												label={record.nome}
												value={record.id}
										/>))}
									</Form.Control>}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Modelos de tipo de registro
							</Form.Label>
								<Form.Control
									as="select"
									ref={defaultForm.register}
									name="modelRegisterType"
									onChange={({ target }: any) => {
										let index = target.selectedIndex;
										setModelRegisterType(target.options[index].label);
									}}
								>
									<Form.Control
										as="option"
										label="Escolha uma opção"
										value=""
									/>
									{modelosDeTiposdeRegistro.map((model) => (
										<Form.Control
											key={model.id}
											as="option"
											label={model.label}
											value={model.modelo}
										/>
									))}
								</Form.Control>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Anexar arquivo
							</Form.Label>
								<Form.File
									type="file"
									label={lblFile}
									data-browse="upload"
									custom
									onChange={({ target }: any) => {
										if (target.value) {
											setLblFile(`${target.files[0].name}`);
											setManipulatedFiles([...manipulatedFiles, {
												idRegistro: Number(defaultForm.getValues('registerType')),
												modelo: target.files[0],
												nomeModelo: defaultForm.getValues('modelRegisterType'),
											}]);
											setRegisterFilesSubmit([...registerFilesSubmit, {
												idTipoRegistro: defaultForm.getValues('registerType'),
												modeloTipoDeRegistro: defaultForm.getValues('modelRegisterType'),
												arquivo: target.files[0],
											}])
											setLblFile('Escolher arquivo');
										}
									}}
								/>
							</Form.Group>
						</Form.Row>
					</span>
					<If condition={manipulatedFiles.length}>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={manipulatedFiles}
								defaultColDef={{ flex: 1, sortable: true, resizable: true }}
								frameworkComponents={{
									actions: actionsFilesRegister
								}}
							>
								<AgGridColumn
									headerName="Tipo de Registro"
									valueGetter={({ data }: any) => getRegisterTypeName(data.idRegistro)}
									cellStyle={{
										alignItems: 'center'
									}}
									wrapText
									autoHeight
								/>
								<AgGridColumn
									headerName="Modelo"
									valueGetter={({ data }: any) => getModelName(data.nomeModelo)}
									cellStyle={{
										alignItems: 'center'
									}}
									wrapText
									autoHeight
								/>
								<AgGridColumn
									headerName="Arquivo"
									valueGetter={({ data }: any) => data.modelo.nome ? data.modelo.nome : data.modelo.name}
									cellStyle={{
										alignItems: 'center'
									}}
									wrapText
									autoHeight
								/>
								<AgGridColumn
									headerName="Ação"
									cellRenderer='actions'
									cellStyle={{
										alignItems: 'center'
									}}
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>
				</Fieldset>

				<Fieldset title="Modelos de Proteção">
					<span className="models-registers-type">
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Relatório Técnico
							</Form.Label>
								<Form.File
									ref={defaultForm.register}
									name="modelTechnicalReportProtection"
									type="file"
									label={lblReport}
									data-browse="upload"
									custom
									onChange={({ target }: any) => {
										if (target.value) {
											setLblReport(`${target.files[0].name}`);
										}
									}}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row style={{ margin: 5 }}>
							<If condition={fileProtectionRT?.id}>
								<FileExistent text={fileProtectionRT?.nome} date={fileProtectionRT?.dataAtualizacao}
									onDownload={(e: any) => {
										e.preventDefault();
										downloadModelFile(fileProtectionRT);
									}}
									onClick={() => setFilesProtectionRT({
										id: null,
										codigo: '',
										dataAtualizacao: '',
										nome: '',
										path: '',
										usuario: ''
									})}
								/>
							</If>
						</Form.Row>
						<br />
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Tabela de descritores
								</Form.Label>
								<Form.File
									ref={defaultForm.register}
									name="modelDescriptorsTableProtection"
									type="file"
									label={lblDescriptors}
									data-browse="upload"
									custom
									onChange={({ target }: any) => {
										if (target.value) {
											setLblDescriptors(`${target.files[0].name}`);
										}
									}}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row style={{ margin: 5 }}>
							<If condition={fileProtectionDesc?.id}>
								<FileExistent text={fileProtectionDesc?.nome} date={fileProtectionDesc?.dataAtualizacao}
									onDownload={(e: any) => {
										e.preventDefault();
										downloadModelFile(fileProtectionDesc);
									}}
									onClick={() => setFileProtectionDesc({
										id: null,
										codigo: '',
										dataAtualizacao: '',
										nome: '',
										path: '',
										usuario: ''
									})}
								/>
							</If>
						</Form.Row>
					</span>
				</Fieldset>

				<div className="button-position">
					<Button
						title="Cancelar"
						type="button"
						contained={false}
						onClick={() => history.goBack()}
					/>
					<Button
						title="Salvar"
						type="submit"
						contained={true}
						isLoading={loadingCulture || (loadingFilesProtection || loadingFilesRegister)}
					/>
				</div>
			</Form>
		</If>

		<If condition={showDescriptors}>
			<Modal show={showDescriptors} title="Adicionar descritores" onHide={() => setShowDescriptores(false)}>
				<If condition={verifyErrorsOnFormDescriptors()}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form>
					<Form.Row className="row-custom">
						<Form.Label>
							Descrição<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="descriptorDescription"
							type="text"
							placeholder="Insira o descritor"
							isInvalid={defaultForm.errors.descriptorDescription !== undefined}
							autoComplete="off"
							maxLength={300}
						/>
						<If condition={defaultForm.errors.descriptorDescription}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.descriptorDescription?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Check
							ref={defaultForm.register}
							name="descriptorStatus"
							type="checkbox"
							label="Ativo"
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Fieldset title="Características" className="form-row-fieldset">
							<span className="popup-field">
								<Form.Control
									ref={defaultForm.register}
									name="descriptorCharacteristic"
									type="text"
									placeholder="Insira a caraterística do descritor"
									autoComplete="off"
									style={{ width: '90%' }}
									maxLength={300}
								/>
								<FiX
									onClick={() => defaultForm.setValue('descriptorCharacteristic', '')}
									color="red"
									cursor="pointer"
								/>
								<FiCheck
									onClick={() => {
										if (defaultForm.getValues('descriptorCharacteristic')?.length) {
											setTempCharacteristicList(state => [...state, {
												descricao: defaultForm.getValues('descriptorCharacteristic')
											}]);
										}
										setTimeout(() => {
											defaultForm.setValue('descriptorCharacteristic', '');
										}, 5)
									}}
									color="green"
									cursor="pointer"
								/>
							</span>
							<br />
							<If condition={tempCharacteristicList.length}>
								<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
									<AgGridReact
										rowData={tempCharacteristicList}
										defaultColDef={{ flex: 1, sortable: true, editable: true }}
										frameworkComponents={{
											actions: actionsModalDescriptors
										}}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
									>
										<AgGridColumn
											headerName='Descrição'
											field="descricao"
											wrapText={true}
											autoHeight
										/>
										<AgGridColumn
											headerName='Ações'
											cellRenderer='actions'
											editable={false}
											maxWidth={100}
										/>
									</AgGridReact>
								</div>
							</If>
						</Fieldset>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							contained={false}
							onClick={() => setShowDescriptores(false)}
						/>
						<Button
							title="Cadastrar"
							type="button"
							contained={true}
							onClick={() => {
								if (!verifyErrorsOnFormDescriptors() &&
									defaultForm.getValues('descriptorDescription').length &&
									tempCharacteristicList.length) {
									setTempCharacteristicList([]);
									onSubmitDescriptors();
									setShowDescriptores(false);
								}
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={showDisease}>
			<Modal show={showDisease} title="Adicionar doenças" onHide={() => setShowDisease(false)}>
				<If condition={verifyErrorsOnFormDiseases()}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form>
					<Form.Row className="row-custom">
						<Form.Label>
							Descrição<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="diseaseDescription"
							type="text"
							placeholder="Insira a descrição da doença"
							isInvalid={defaultForm.errors.diseaseDescription !== undefined}
							autoComplete="off"
							maxLength={300}
						/>
						<If condition={defaultForm.errors.diseaseDescription}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.diseaseDescription?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Check
							ref={defaultForm.register}
							name="diseaseStatus"
							label="Ativo"
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Fieldset title="Reações" className="form-row-fieldset">
							<span className="popup-field">
								<Form.Control
									ref={defaultForm.register}
									name="diseaseReaction"
									type="text"
									placeholder="Insira a reação da doença"
									autoComplete="off"
									style={{ width: '90%' }}
									maxLength={300}
								/>
								<FiX
									onClick={() => defaultForm.setValue('diseaseReaction', '')}
									color="red"
									cursor="pointer"
								/>
								<FiCheck
									onClick={() => {
										if (defaultForm.getValues('diseaseReaction')?.length) {
											setTempReactionsList([...tempReactionsList, {
												descricao: defaultForm.getValues('diseaseReaction')
											}]);
										}
										setTimeout(() => {
											defaultForm.setValue('diseaseReaction', '');
										}, 2)
									}}
									color="green"
									cursor="pointer"
								/>
							</span>
							<br />
							<If condition={tempReactionsList.length}>
								<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
									<AgGridReact
										rowData={tempReactionsList}
										defaultColDef={{ flex: 1, sortable: true, editable: true }}
										frameworkComponents={{
											actions: actionsModalDiseases
										}}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
									>
										<AgGridColumn
											headerName='Descrição'
											field="descricao"
											wrapText={true}
											autoHeight
										/>
										<AgGridColumn
											headerName='Ações'
											cellRenderer='actions'
											editable={false}
											maxWidth={100}
										/>
									</AgGridReact>
								</div>
							</If>
						</Fieldset>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							contained={false}
							onClick={() => setShowDisease(false)}
						/>
						<Button
							title="Cadastrar"
							type="button"
							contained={true}
							onClick={() => {
								if (!verifyErrorsOnFormDiseases() &&
									defaultForm.getValues('diseaseDescription').length &&
									tempReactionsList.length) {
									setTempReactionsList([]);
									onSubmitDiseases();
									setShowDisease(false);
								}
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={showRegion}>
			<Modal show={showRegion} title="Adicionar região" onHide={() => setShowRegion(false)}>
				<If condition={verifyErrorsOnFormRegions()}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form>
					<Form.Row className="row-custom">
						<Form.Label>
							Região<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="regionName"
							type="text"
							placeholder="Insira a região da cultura"
							isInvalid={defaultForm.errors.regionName !== undefined}
							autoComplete="off"
							maxLength={10}
						/>
						<If condition={defaultForm.errors.regionName}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.regionName?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Check
							ref={defaultForm.register}
							name="regionStatus"
							label="Ativo"
						/>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							contained={false}
							onClick={() => setShowRegion(false)}
						/>
						<Button
							title="Cadastrar"
							type="button"
							contained={true}
							onClick={() => {
								if (!verifyErrorsOnFormRegions() && defaultForm.getValues('regionName').length) {
									setRegions([]);
									onSubmitRegions();
									setShowRegion(false);
								}
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={editDescriptors}>
			<Modal show={editDescriptors} title="Editar descritor" onHide={() => setEditDescriptors(false)}>
				<Form>
					<Form.Row className="row-custom">
						<Form.Label>
							Descrição<em>*</em>
						</Form.Label>
						<Form.Control
							value={descriptorToEdit?.descricao}
							type="text"
							placeholder="Insira o descritor"
							onChange={({ target }: any) => setDescriptorToEdit(state => ({
								ativo: state.ativo,
								caracteristicasDescritores: state.caracteristicasDescritores,
								descricao: target.value,
								culturaId: state.culturaId,
								id: state.id
							}))}
							autoComplete="off"
							maxLength={300}
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Check
							checked={descriptorToEdit.ativo}
							onChange={() => setDescriptorToEdit(state => ({
								ativo: !state.ativo,
								caracteristicasDescritores: state.caracteristicasDescritores,
								descricao: state.descricao,
								culturaId: state.culturaId,
								id: state.id
							}))}
							type="checkbox"
							label="Ativo"
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Fieldset title="Características" className="form-row-fieldset">
							<span className="popup-field">
								<Form.Control
									ref={defaultForm.register}
									name="descriptorCharacteristic"
									type="text"
									placeholder="Insira a caraterística do descritor"
									autoComplete="off"
									style={{ width: '90%' }}
									maxLength={300}
								/>
								<FiX
									onClick={() => defaultForm.setValue('descriptorCharacteristic', '')}
									color="red"
									cursor="pointer"
								/>
								<FiCheck
									onClick={() => {
										if (defaultForm.getValues('descriptorCharacteristic')?.length) {
											setDescriptorToEdit(state => ({
												ativo: state.ativo,
												caracteristicasDescritores: [...state.caracteristicasDescritores, {
													descricao: defaultForm.getValues('descriptorCharacteristic')
												}],
												descricao: state.descricao,
												culturaId: state.culturaId,
												id: state.id
											}))
										}
										setTimeout(() => {
											defaultForm.setValue('descriptorCharacteristic', '');
										}, 5)
									}}
									color="green"
									cursor="pointer"
								/>
							</span>
							<br />
							<If condition={descriptorToEdit.caracteristicasDescritores.length}>
								<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
									<AgGridReact
										rowData={descriptorToEdit.caracteristicasDescritores}
										defaultColDef={{ flex: 1, sortable: true, editable: true }}
										frameworkComponents={{
											actions: actionsModalEditDescriptors
										}}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
									>
										<AgGridColumn
											headerName='Descrição'
											field="descricao"
											wrapText={true}
											autoHeight
										/>
										<AgGridColumn
											headerName='Ações'
											cellRenderer='actions'
											editable={false}
											maxWidth={100}
										/>
									</AgGridReact>
								</div>
							</If>
						</Fieldset>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							contained={false}
							onClick={() => setEditDescriptors(false)}
						/>
						<Button
							title="Salvar"
							type="button"
							contained={true}
							onClick={() => {
								setEditDescriptors(false);
								updateDescriptor();
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={editDiseases}>
			<Modal show={editDiseases} title="Editar doença" onHide={() => setEditDiseases(false)}>
				<Form>
					<Form.Row className="row-custom">
						<Form.Label>
							Descrição<em>*</em>
						</Form.Label>
						<Form.Control
							value={diseaseToEdit.descricao}
							type="text"
							placeholder="Insira a descrição da doença"
							onChange={({ target }: any) => setDiseaseToEdit(state => ({
								ativo: state.ativo,
								reacoesDoencas: state.reacoesDoencas,
								descricao: target.value,
								culturaId: state.culturaId,
								id: state.id
							}))}
							autoComplete="off"
							maxLength={300}
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Check
							checked={descriptorToEdit.ativo}
							onChange={() => setDiseaseToEdit(state => ({
								ativo: !state.ativo,
								reacoesDoencas: state.reacoesDoencas,
								descricao: state.descricao,
								culturaId: state.culturaId,
								id: state.id
							}))}
							label="Ativo"
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Fieldset title="Reações" className="form-row-fieldset">
							<span className="popup-field">
								<Form.Control
									ref={defaultForm.register}
									name="diseaseReaction"
									type="text"
									placeholder="Insira a reação da doença"
									autoComplete="off"
									style={{ width: '90%' }}
									maxLength={300}
								/>
								<FiX
									onClick={() => defaultForm.setValue('diseaseReaction', '')}
									color="red"
									cursor="pointer"
								/>
								<FiCheck
									onClick={() => {
										if (defaultForm.getValues('diseaseReaction')?.length) {
											setDiseaseToEdit(state => ({
												ativo: state.ativo,
												reacoesDoencas: [...state.reacoesDoencas, {
													descricao: defaultForm.getValues('diseaseReaction')
												}],
												descricao: state.descricao,
												culturaId: state.culturaId,
												id: state.id
											}))
										}
										setTimeout(() => {
											defaultForm.setValue('diseaseReaction', '');
										}, 2)
									}}
									color="green"
									cursor="pointer"
								/>
							</span>
							<br />
							<If condition={diseaseToEdit.reacoesDoencas.length}>
								<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
									<AgGridReact
										rowData={diseaseToEdit.reacoesDoencas}
										defaultColDef={{ flex: 1, sortable: true, editable: true }}
										frameworkComponents={{
											actions: actionsModalEditDiseases
										}}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
									>
										<AgGridColumn
											headerName='Descrição'
											field="descricao"
											wrapText={true}
											autoHeight
										/>
										<AgGridColumn
											headerName='Ações'
											cellRenderer='actions'
											editable={false}
											maxWidth={100}
										/>
									</AgGridReact>
								</div>
							</If>
						</Fieldset>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							contained={false}
							onClick={() => setEditDiseases(false)}
						/>
						<Button
							title="Salvar"
							type="button"
							contained={true}
							onClick={() => {
								setEditDiseases(false);
								updateDiseases();
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

	</div >;
}