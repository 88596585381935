import React from 'react';
import { FiPlus, FiDownloadCloud } from 'react-icons/fi';
import If from './If';
import './styles.css';

interface Props
{
	onClick?: any;
	otherTitle?: string;
	otherTitleAdd?: string;
	isDownload?: boolean;
	type: any;
	disabled?: boolean;
	showAsButton?:boolean;
}

export default function LittleButton (props: Props)
{
	const GetStyle = () : React.CSSProperties =>
	{
		let style : React.CSSProperties = {
			color:'#00793A'
		};

		if (props.disabled)
		{
			style.color = '#7C7C7C';
		}

		if (props.showAsButton)
		{
			style.lineHeight = '24px';
			style.marginTop = '8px';
			style.verticalAlign = 'text-bottom';
		}

		return style;
	}

	const GetStyleIcon = (style : React.CSSProperties) : React.CSSProperties =>
	{
		if (props.showAsButton)
		{
			style.marginTop = '-4px';
			style.marginRight = '4px';
		}

		return style;
	}

	return <button
		disabled={props.disabled}
		style={GetStyle()}
		className={props.showAsButton ? "btn btn-success text-white btn-block" : "add-button"}
		type={props.type}
		onClick={props.onClick}
	>
		<If condition={props.isDownload}>
			<FiDownloadCloud style={GetStyleIcon({})} size={13} /> {props.otherTitle}
		</If>
		<If condition={!props.isDownload}>
			<FiPlus style={GetStyleIcon({ border: '1px solid' })} size={13} /> {props.otherTitleAdd ? props.otherTitleAdd : "Adicionar"} 
		</If>
	</button>;
}