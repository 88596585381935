import React from 'react';
import { Router } from 'react-router-dom';
import history from './routes/history';
import { AuthProvider } from './context/AuthContext';
import Routes from './routes/Root'; 

function App() {
	return (
		<AuthProvider>
			<Router history={history}>
				<Routes />
			</Router>
		</AuthProvider>
	);
}

export default App;
