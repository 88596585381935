import { PERMISSIONS } from './usersPermissions/usersPermissionsCultivares';

export const isReguladorRegister = (roles: string[]): boolean => {
    const isRoles = roles.filter(role => role === PERMISSIONS.ReqRegistroRegulador)

    return isRoles.length ? true : false;
}

export const isReguladorProtection = (roles: string[]): boolean => {
    const isRoles = roles.filter(role => role === PERMISSIONS.ReqRegistroRegulador)

    return isRoles.length ? true : false;
}