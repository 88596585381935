
import React, { useState, useEffect,  useContext, Fragment, Component } from 'react';
import { Form } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import Button from  "../../components/Button";
import Loading from '../Loading';
import SearchEmpty from '../SearchEmpty';
import EditButton from '../EditButton';
import { uuid } from "../../services/uuid";


interface ListingViewProps<FormType>
{
	title			: string;
	children?		: any[];
	form?			: any;
	ShowButtonAddNew?: boolean;
	onButtonAddEvent?	:any;
	ButtonAddText?	: string;
	OnInit?			: any;//function
	
	onEditEvent		?:	any;
	filters			?: 	any[];
	filterRefresh	?: 	boolean;
	filterRefreshFinish	?: 	()=> void;
	onFilterSearch 	?:	any;
	onListItemActionsButtons?:  any;

	ModalPopups		?: any;

	actionsMaxWidth?: any;
	actionsMinWidth?: any;
	actionsCellStyle?: any;
}

interface Dic {
    [key: string ]: any
}

export default function ListingView<FormType>(props: ListingViewProps<FormType>){
    
	const [list, setList] = useState<FormType[]>([]);
	
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
 	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);	
	const [onInitCall, setOnInitCall] = useState<boolean>(false);	
	const [cellRendererAction, setCellRenderer] = useState<any>(null);	

	React.useEffect(() => {
        if(props.filterRefresh == null) { return; }
		if(props.filterRefresh === true) { searchOrGetList();}
    }, [props.filterRefresh]);


	const acoesbotoes = ({ data }: any) => {
		if(props.onListItemActionsButtons){
			return props.onListItemActionsButtons(data);
		}
		else if(props.onEditEvent){
			return <EditButton onClick={() => {
				if(props.onEditEvent)		
				{
					props.onEditEvent(data);
				}		           
			}} />;

		}
		return <Fragment />       
	}

	const searchOrGetList = async (queries?: any) => {
		if(props.onFilterSearch){
			setError(false);
			setLoadingSearch(true);
	
			try {
				const getLpmaSearch =  async () => {
					let dados = await props.onFilterSearch(queries);
					if(props.filterRefreshFinish){ props.filterRefreshFinish(); }
					//await LpmaServiceApi.getProjetos(queries as LpmaProjeto);
					if (!dados.length)
					{
						setEmptySearch(true);
						return;
					}
					setList(dados);
					setEmptySearch(false);
				}
				getLpmaSearch();				
			}
			catch (error:any)
			{
				setError(true);
				if(props.filterRefreshFinish){ props.filterRefreshFinish(); }
			}
	
			setLoadingSearch(false);
		}
	}	


	useEffect(() => {
		const iniciar = async () => {
			if(!onInitCall){
				setOnInitCall(true); 
				let initReturn = {};
				if(props.OnInit) { initReturn = await props.OnInit();}
				await searchOrGetList(null);	
			}
		};		
		iniciar();
	}, [props.form]);
	
	const errorHandler = (infoerr:any)=>{
		console.error("ErroHandler no Filtro", infoerr);
	}
	useEffect(() => {
		let cellRendererList ={}
		if(!(!props.children)){
			for(var index = 0, t = props.children?.length; index < t;index++){
				const e = props.children[index];			
				if(!(!e.props.cellRenderer)){
					let cellRendererItem: Dic = {}
					cellRendererItem[ `actions_${index}`] =  e.props.cellRenderer;
					cellRendererList = {...cellRendererList, ...cellRendererItem}			
				}
			}
		}		
		setCellRenderer(cellRendererList);
	}, [props.children]);	
	
	return (
		<div className="container-custom-search">
			{props.ModalPopups ?? '' }
			<div className="search-content-filters ">
			
				<div className="top-content">
					<h3>{props.title}</h3>
				</div>
				<div className="row-buttons">
					{ props.ShowButtonAddNew ?
						<Button
						onClick={props.onButtonAddEvent}
						contained
						title={props.ButtonAddText ?? ''}
						type="button"
						/>
					:''}
				</div>
				<br />
				<Form onSubmit={props.form?.handleSubmit(searchOrGetList, errorHandler)} autoComplete="off">
					<Form.Row>

						{props.filters? props.filters.map((e, index)=> {
							return {...e, key: index};
						}) 
						: ''}

						<span className="filter-btn" >
							<Button 
								title="Buscar"
								type="submit"
								contained
								isLoading={loadingSearch}
							/>
						</span>

					</Form.Row>
				</Form>
				{emptySearch	? <div className="div-empty"> <SearchEmpty text={'Código não encontrado.'} /> </div>	: ''}
				{loading		? <div className="loading-container"> <Loading /> </div>		: ''}				
			</div>			

			{(!loading && !error && !emptySearch && list.length) ? 
				<div className="search-content">
					<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
						<AgGridReact
							rowData={list}
							defaultColDef={{ flex: 1, sortable: true, resizable: true }}
							pagination
							paginationPageSize={10}
							gridOptions={{ headerHeight: 80 }}
							frameworkComponents={{
								...cellRendererAction,
								actions: acoesbotoes,
							}}							
							
						>
							{props.children?.map((e:ListCol, index:any)=>{
								return <AgGridColumn
									headerName ={e.props.header}
									field	= {e.props.field}
									key		= {e.props.key ?? (e.props.field ?? uuid())}
									sort 	= {e.props.sort}
									maxWidth = {e.props.maxWidth}
									minWidth  = {e.props.minWidth}
									headerClass = {e.props.headerClass}
									valueFormatter = {e.props.valueFormatter}
									cellStyle = {e.props.cellStyle}									
									wrapText = {e.props.wrapText}
									cellRenderer = {e.props.cellRenderer ? `actions_${index}` : undefined}
									/>	
							})}			
						
							<AgGridColumn
								field='Ações'
								cellRenderer='actions'
								maxWidth = {props.actionsMaxWidth}
								minWidth = {props.actionsMinWidth}
								cellStyle = {props.actionsCellStyle}
							/>
						</AgGridReact>
					</div>
				</div>
			: ''}
		</div>
	);
	
}

interface ListColProps{
	header	: string;
	field	: string;
	key?	: string;
	sort?	: string;
	maxWidth?		: number;
	wrapText?		: boolean;
	minWidth?		: number;
	valueFormatter?	: any;
	headerClass? 	: string;
	cellStyle? 		: any;
	cellRenderer?	: any;
}

export class ListCol extends Component<ListColProps, {}> {
}