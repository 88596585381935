import React from 'react';
import * as fiIcon from 'react-icons/fi';
import Tooltip from './Tooltip';

interface Props
{
	onClick?: any;
	disable?: boolean;
	tooltip?: string;
}

export default function EditButton({ onClick, disable, tooltip }: Props)
{
	return <Tooltip tooltipTxt={tooltip || 'Editar'}>
			<fiIcon.FiEdit
				style={{color: disable ? '#c9cacc' : '#003f79'}}
				className="edit-btn"
				size={20}
				onClick={!disable ? onClick : null}
			/>
	</Tooltip>;
}

export function InsertButton({ onClick, disable, tooltip }: Props)
{
	return <Tooltip tooltipTxt={tooltip || 'Editar'}>
			<fiIcon.FiFilePlus
				style={{color: disable ? '#c9cacc' : '#003f79'}}
				className="edit-btn"
				size={20}
				onClick={!disable ? onClick : null}
			/>
	</Tooltip>;
}

export function ConfigButton({ onClick, disable, tooltip }: Props)
{
	return <Tooltip tooltipTxt={tooltip || 'Configurar'}>
			<fiIcon.FiSettings
				style={{color: disable ? '#c9cacc' : '#003f79'}}
				className="edit-btn"
				size={20}
				onClick={!disable ? onClick : null}
			/>
	</Tooltip>;
}

export function SearchButton({ onClick, disable, tooltip }: Props)
{
	return <Tooltip tooltipTxt={tooltip || 'Configurar'}>
			<fiIcon.FiSearch
				style={{color: disable ? '#c9cacc' : '#003f79'}}
				className="edit-btn"
				size={20}
				onClick={!disable ? onClick : null}
			/>
	</Tooltip>;
}

export function SelectButton({ onClick, disable, tooltip }: Props)
{
	return <Tooltip tooltipTxt={tooltip || 'Configurar'}>
			<fiIcon.FiCheck				
				style={{color: disable ? '#c9cacc' : '#003f79'}}
				className="edit-btn"
				size={20}
				onClick={!disable ? onClick : null}
			/>
	</Tooltip>;
}

export function CameraButton({ onClick, disable, tooltip }: Props)
{
	return <Tooltip tooltipTxt={tooltip || 'Image View'}>
			<fiIcon.FiCamera				
				style={{color: disable ? '#c9cacc' : '#003f79'}}
				className="edit-btn"
				size={20}
				onClick={!disable ? onClick : null}
			/>
	</Tooltip>;
}

export function AnexoButton({ onClick, disable, tooltip }: Props)
{
	return <Tooltip tooltipTxt={tooltip || 'Image View'}>
			<fiIcon.FiPaperclip				
				style={{color: disable ? '#c9cacc' : '#003f79'}}
				className="edit-btn"
				size={20}
				onClick={!disable ? onClick : null}
			/>
	</Tooltip>;
}

export function DownloadButton({ onClick, disable, tooltip }: Props)
{
	return <Tooltip tooltipTxt={tooltip || 'Download'}>
			<fiIcon.FiDownload				
				style={{color: disable ? '#c9cacc' : '#003f79'}}
				className="edit-btn"
				size={20}
				onClick={!disable ? onClick : null}
			/>
	</Tooltip>;
}

export function ClearButton({ onClick, disable, tooltip }: Props)
{
	return <Tooltip tooltipTxt={tooltip || 'Remove'}>
			<fiIcon.FiTrash		
				style={{color: disable ? '#c9cacc' : 'red'}}
				className="edit-btn"
				size={20}
				onClick={!disable ? onClick : null}
			/>
	</Tooltip>;
}
