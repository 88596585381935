import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import TextField from '../../../components/form/TextField';
import ListingView, { ListCol } from "../../../components/page/ListingView";
import { CqbEquipeTecnica } from '../../../models/modelsCQB';
import { STRINGS_CQB } from "../../../util/cqb/string";
import CqbService from '../services';

export default function List (){
	const CqbServiceApi = new CqbService();
	const history = useHistory();


    const defaultForm = useForm({
		defaultValues: {
			id: 0,
			codigo: '',
			nome: '',
			documento: '',
			link_CV_Lattes: '',
			endereco: '',
			telefone: '',
			email: '',
			ativo: true,
        } as CqbEquipeTecnica,
	})	
    
	
	return <ListingView<CqbEquipeTecnica>
	title	=	{STRINGS_CQB.SEARCH_EQUIPE_TECNICA}	
	form={defaultForm}
	onEditEvent = {(data:any )=>{  history.push({
		pathname: `/cqb-equipe-tecnica/editar/${data.id}`,						
		})
	 }}

	 ShowButtonAddNew={true}
	 onButtonAddEvent = {()=>{ history.push("/cqb-equipe-tecnica/novo") }}
	 ButtonAddText="Novo Técnico"

	 filters={[
		<TextField<CqbEquipeTecnica> form={defaultForm} layoutColumns={4} name="nome" 
		label="Nome" required={false}  isClearable={true}
		/>,
		<TextField<CqbEquipeTecnica> form={defaultForm} layoutColumns={6} name="documento" 
		label="Documento/CPF/CREA" required={false}  isClearable={true}
		/>
	 ]}

	onFilterSearch = {async (queries?: any)=>{
		let filtro = {
			...defaultForm.getValues(),
			...queries
		}
		return await CqbServiceApi.getEquipeTecnica(filtro as CqbEquipeTecnica);
	}}

	actionsMaxWidth={75}
	>		

	<ListCol
		header='Código'
		field='id'
		maxWidth={100}
	/>	

	<ListCol
		header='Nome'
		field='nome'
		cellStyle={{
			justifyContent: 'left'
		}}
	/>			

	<ListCol
		header='E-Mail'
		field='email'
		cellStyle={{
			justifyContent: 'left'
		}}
	/>	

	<ListCol
		header='Documento/CPF/CREA'
		field='documento'
	/>			

	<ListCol
		header='Curriculo Lates'
		field='link_CV_Lattes'	
		cellStyle={{
			justifyContent: 'left'
		}}							
	/>	

	</ListingView>
}
