import React from 'react';
import { STRINGS } from '../util/strings';
import './styles.css';

function EmptyInfoArray() {
    return (
        <span className="empty-info-array">
            {STRINGS.EMPTY_INFO_ARRAY}
        </span>
    );
}

export default EmptyInfoArray;