import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../models/user';
import { isRefreshTokenValid, isTokenValid } from '../functions/isSessionExpired';
import SignedIn from './SignedIn';
import Login from '../pages/Login';
import { Context } from '../context/AuthContext';
import LoadingApplication from '../components/LoadingApplication';

const isSessionValid = (): boolean => {
	let token = localStorage.getItem('token');
	let refreshExpiresIn = localStorage.getItem('refreshExpiresIn');
	let tokenTimeCreated = localStorage.getItem('tokenTimeCreated');

	if (!token)
		return false;

	let decoded: DecodedToken = token ? jwtDecode(String(token)) : { exp: 0, roles: [], unique_name: '' };

	if (decoded.roles.length && !isRefreshTokenValid(Number(refreshExpiresIn), String(tokenTimeCreated))) {
		localStorage.removeItem('token');
		localStorage.removeItem('refreshExpiresIn');
		localStorage.removeItem('tokenTimeCreated');
		localStorage.removeItem('refreshToken');
	}

	return ((isTokenValid(decoded.exp) || isRefreshTokenValid(Number(refreshExpiresIn), String(tokenTimeCreated))));
}

const CustomRoute = ({ isPrivate, ...rest }: any) => {
	const { loading } = useContext(Context);

	if (loading) {
		return <LoadingApplication />
	}

	if (isPrivate && !isSessionValid()) {
		return <Redirect to='/login' />
	}

	return <Route {...rest} />
}

const Routes = () => (
	<Switch>
		<CustomRoute exact path='/login' component={Login} />
		<CustomRoute isPrivate path='/' component={SignedIn} />
	</Switch>
)

export default Routes;