import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Navbar from '../components/Navbar';
import Home from '../pages/Home';
import RegisterRequester from '../pages/Registers/Requester';
import SearchRequester from '../pages/Searchs/Requester';
import RegisterOperatingUnitys from '../pages/Registers/OperatingUnitys';
import SearchOperatingUnitys from '../pages/Searchs/OperatingUnitys';
import RegisterRegulatoryBodies from '../pages/Registers/RegulatoryBodies';
import SearchRegulatorieBodies from '../pages/Searchs/RegulatorieBodies';

import RegisterExternalOrgans from '../pages/Registers/ExternalOrgans';
import SearchExternalOrgans from '../pages/Searchs/ExternalOrgans';
import RegisterGroundsType from '../pages/Registers/GroundsType';
import SearchGroundsType from '../pages/Searchs/GroudsType';
import RegisterMaterialCategories from '../pages/Registers/MaterialCategories';
import SearchMaterialCategories from '../pages/Searchs/MaterialCategories';

/**
 * Administração
 */

import USUARIO_LIST from '../pages/usuario-acesso/usuarios/list';
import USUARIO_EDIT from '../pages/usuario-acesso/usuarios/edit';
import USUARIO_GRUPO_PERMISSAO_LIST from '../pages/usuario-acesso/grupo-permissao/list';
import USUARIO_GRUPO_PERMISSAO_EDIT from '../pages/usuario-acesso/grupo-permissao/edit';

/**
 * Cultivares
 */

import Cultivares_RegistroRNC_Add from '../pages/Cultivares/RegistroRNC/Add';
import Cultivares_RegistroRNC_Edit from '../pages/Cultivares/RegistroRNC/Edit';
import Cultivares_RegistroRNC_View from '../pages/Cultivares/RegistroRNC/View';
import Cultivares_RegistroRNC_List from '../pages/Cultivares/RegistroRNC/List';
import Cultivares_ProtecaoSNPC_Add from '../pages/Cultivares/ProtecaoSNPC/Add';
import Cultivares_ProtecaoSNPC_List from '../pages/Cultivares/ProtecaoSNPC/List';
import Cultivares_ProtecaoSNPC_Edit from '../pages/Cultivares/ProtecaoSNPC/Edit';
import Cultivares_ProtecaoSNPC_View from '../pages/Cultivares/ProtecaoSNPC/View';
import Cultivares_Cadastros_Requerente_List from '../pages/Cultivares/Cadastros/Requerentes/List';
import Cultivares_Cadastros_Requerente_Add from '../pages/Cultivares/Cadastros/Requerentes/Add';
import Cultivares_Cadastros_Requerente_Edit from '../pages/Cultivares/Cadastros/Requerentes/Edit';
import Cultivares_Cadastros_Melhorista_List from '../pages/Cultivares/Cadastros/Melhoristas/List';
import Cultivares_Cadastros_Melhorista_Add from '../pages/Cultivares/Cadastros/Melhoristas/Add';
import Cultivares_Cadastros_Melhorista_Edit from '../pages/Cultivares/Cadastros/Melhoristas/Edit';
import Cultivares_Cadastros_Culturas_List from '../pages/Cultivares/Cadastros/Culturas/List';
import Cultivares_Cadastros_Culturas_Add from '../pages/Cultivares/Cadastros/Culturas/Add';
import Cultivares_Cadastros_Culturas_Edit from '../pages/Cultivares/Cadastros/Culturas/Edit';
import Cultivares_Cadastros_Eventos_List from '../pages/Cultivares/Cadastros/Eventos/List';
import Cultivares_Cadastros_Eventos_Add from '../pages/Cultivares/Cadastros/Eventos/Add';
import Cultivares_Cadastros_Eventos_Edit from '../pages/Cultivares/Cadastros/Eventos/Edit';
import Cultivares_Cadastros_Instituicao_List from '../pages/Cultivares/Cadastros/Instituicoes/List';
import Cultivares_Cadastros_Instituicao_Add from '../pages/Cultivares/Cadastros/Instituicoes/Add';
import Cultivares_Cadastros_Instituicao_Edit from '../pages/Cultivares/Cadastros/Instituicoes/Edit';
import Cultivares_Cadastros_Instituicao_View from '../pages/Cultivares/Cadastros/Instituicoes/View';
import Cultivares_Cadastros_TiposRegistro_Add from '../pages/Cultivares/Cadastros/TiposRegistro/Add';
import Cultivares_Cadastros_TiposRegistro_List from '../pages/Cultivares/Cadastros/TiposRegistro/List';
import Cultivares_Cadastros_TiposRegistro_Edit from '../pages/Cultivares/Cadastros/TiposRegistro/Edit';

/**
 * AAAs
 */

import CIBIO_AMI_Add from '../pages/AuthorizationsCIBIO/AMI/Add';
import CIBIO_AMI_Edit from '../pages/AuthorizationsCIBIO/AMI/Edit';
import CIBIO_AMI_View from '../pages/AuthorizationsCIBIO/AMI/View';
import CIBIO_AMI_List from '../pages/AuthorizationsCIBIO/AMI/List';
import CIBIO_AAC_Add from '../pages/AuthorizationsCIBIO/AAC/Add';
import CIBIO_AAC_Edit from '../pages/AuthorizationsCIBIO/AAC/Edit';
import CIBIO_AAC_List from '../pages/AuthorizationsCIBIO/AAC/List';
import CIBIO_AAC_View from '../pages/AuthorizationsCIBIO/AAC/View';
import CIBIO_AI_List from '../pages/AuthorizationsCIBIO/AI/List';
import CIBIO_AI_Edit from '../pages/AuthorizationsCIBIO/AI/Edit';
import CIBIO_AE_List from '../pages/AuthorizationsCIBIO/AE/List';
import CIBIO_AE_Edit from '../pages/AuthorizationsCIBIO/AE/Edit';
import CIBIO_Cadastros_Culturas_Add from '../pages/AuthorizationsCIBIO/Cadastros/Culturas/Add';
import CIBIO_Cadastros_Culturas_Edit from '../pages/AuthorizationsCIBIO/Cadastros/Culturas/Edit';
import CIBIO_Cadastros_Culturas_List from '../pages/AuthorizationsCIBIO/Cadastros/Culturas/List';
import CIBIO_Cadastros_Destinatarios_Add from '../pages/AuthorizationsCIBIO/Cadastros/Destinatarios/Add';
import CIBIO_Cadastros_Destinatarios_Edit from '../pages/AuthorizationsCIBIO/Cadastros/Destinatarios/Edit';
import CIBIO_Cadastros_Destinatarios_List from '../pages/AuthorizationsCIBIO/Cadastros/Destinatarios/List';
import CIBIO_Cadastros_Estacoes_Add from '../pages/AuthorizationsCIBIO/Cadastros/Estacoes/Add';
import CIBIO_Cadastros_Estacoes_Edit from '../pages/AuthorizationsCIBIO/Cadastros/Estacoes/Edit';
import CIBIO_Cadastros_Estacoes_List from '../pages/AuthorizationsCIBIO/Cadastros/Estacoes/List';
import CIBIO_Cadastros_Eventos_Add from '../pages/AuthorizationsCIBIO/Cadastros/Eventos/Add';
import CIBIO_Cadastros_Eventos_Edit from '../pages/AuthorizationsCIBIO/Cadastros/Eventos/Edit';
import CIBIO_Cadastros_Eventos_List from '../pages/AuthorizationsCIBIO/Cadastros/Eventos/List';

/**
 * Shipment
 */

import SHIP_SOLICITACOES from '../pages/shipment/solicitacao/List';
import SHIP_SOLICITACOES_NOVA from '../pages/shipment/solicitacao/Edit';
import SHIP_SOLICITACOES_EDIT from '../pages/shipment/solicitacao/Edit';
import SHIP_CULTURA_LIST from '../pages/shipment/cultura/List';
import SHIP_CULTURA_EDIT from '../pages/shipment/cultura/Edit';
import SHIP_MATERIAL_LIST from '../pages/shipment/material/List';
import SHIP_MATERIAL_EDIT from '../pages/shipment/material/Edit';
import SHIP_REQUISIT_LIST from '../pages/shipment/requisitantes/List';
import SHIP_REQUISIT_EDIT from '../pages/shipment/requisitantes/Edit';

/**
 * LPMA
 */

import LPMA_PROJETOS_LIST from '../pages/lpma/projeto/list'
import LPMA_PROJETOS_EDIT from '../pages/lpma/projeto/edit'
import LPMA_LOCAL_LIST from '../pages/lpma/locais/list'
import LPMA_LOCAL_EDIT from '../pages/lpma/locais/edit'
import LPMA_EQUIPE_TECNICA_LIST from '../pages/lpma/equipe-tecnica/list'
import LPMA_EQUIPE_TECNICA_EDIT from '../pages/lpma/equipe-tecnica/edit'
import LPMA_CONFIGS_LIST from '../pages/lpma/configuracoes/list'
import LPMA_CONFIGS_EDIT from '../pages/lpma/configuracoes/edit'
import LPMA_RN35_LIST from '../pages/lpma/rn35/list'
import LPMA_RN35_EDIT from '../pages/lpma/rn35/edit'
import LPMA_RN35_COMUNICADOS from '../pages/lpma/rn35/comunicados'
//import LPMA_RN35_COMUNICADOS_CREATE from '../pages/lpma/rn35/criarcomunicados'
import LPMA_RN35_COMUNICADOS_EDIT from '../pages/lpma/rn35/editarcomunicados'
import LPMA_RN06_LIST from '../pages/lpma/rn06/list'
import LPMA_RN06_EDIT from '../pages/lpma/rn06/edit'
import LPMA_RN06_COMUNICADOS from '../pages/lpma/rn06/comunicados'
//import LPMA_RN06_COMUNICADOS_CREATE from '../pages/lpma/rn06/criarcomunicados'
import LPMA_RN06_COMUNICADOS_EDIT from '../pages/lpma/rn06/editarcomunicados'
import LPMA_OFICIO_EDIT from '../pages/lpma/oficio_cibio/edit';

/**
 * CQB
 */
import CQB_LIST from '../pages/cqb/list'
import CQB_EDIT from '../pages/cqb/edit'
import CQB_LOCAL_LIST from '../pages/cqb/locais/list'
import CQB_LOCAL_EDIT from '../pages/cqb/locais/edit'
import CQB_EQUIPE_TECNICA_LIST from '../pages/cqb/equipe-tecnica/list'
import CQB_EQUIPE_TECNICA_EDIT from '../pages/cqb/equipe-tecnica/edit'
import CQB_COMISSAO_CIBIO_EDIT from '../pages/cqb/equipe-tecnica/edit-cibio'
import CQB_CONFIGS_LIST from '../pages/cqb/configuracoes/list'
import CQB_CONFIGS_EDIT from '../pages/cqb/configuracoes/edit'

/**
 * RELATÓRIO CONCLUSIVO DO LPMA - OGM
 */

import RCLPMA_LIST from '../pages/lpma_rc/list'
import RCLPMA_EDIT from '../pages/lpma_rc/edit'

/**
 * Caderno Conformidade
 */

import CADERNO_CONFORMIDADE_Add from '../pages/CadernoConformidade/vegetacao/Add';
import CADERNO_CONFORMIDADE_Edit from '../pages/CadernoConformidade/vegetacao/Edit';
import CADERNO_CONFORMIDADE_View from '../pages/CadernoConformidade/vegetacao/View';
import CADERNO_CONFORMIDADE_List from '../pages/CadernoConformidade/vegetacao/List';


export default function Routes()
{
	return <BrowserRouter>
		<Navbar />
		<Switch>
			
			<Route exact path='/' component={Home} />

			{/* Módulo Base */}

			<Route path='/usuario/consultar' component={USUARIO_LIST} />
			<Route path='/usuario/editar' component={USUARIO_EDIT} />
			<Route path='/usuario/cadastrar' component={USUARIO_EDIT} />
			<Route path='/grupo-permissao/consultar' component={USUARIO_GRUPO_PERMISSAO_LIST} />
			<Route path='/grupo-permissao/editar' component={USUARIO_GRUPO_PERMISSAO_EDIT} />
			<Route path='/grupo-permissao/cadastrar' component={USUARIO_GRUPO_PERMISSAO_EDIT} />

			{/* Módulo Cultivares */}

			<Route path='/cultivares/instituicao/cadastrar' component={Cultivares_Cadastros_Instituicao_Add} />
			<Route path='/cultivares/instituicao/consultar' component={Cultivares_Cadastros_Instituicao_List} />
			<Route path='/cultivares/instituicao/editar' component={Cultivares_Cadastros_Instituicao_Edit} />
			<Route path='/cultivares/instituicao/visualizar' component={Cultivares_Cadastros_Instituicao_View} />

			<Route path='/cultivares/registro-rnc/cadastrar' component={Cultivares_RegistroRNC_Add} />
			<Route path='/cultivares/registro-rnc/editar' component={Cultivares_RegistroRNC_Edit} />
			<Route path='/cultivares/registro-rnc/visualizar' component={Cultivares_RegistroRNC_View} />
			<Route path='/cultivares/registro-rnc/consultar' component={Cultivares_RegistroRNC_List} />

			<Route path='/cultivares/protecao-snpc/cadastrar' component={Cultivares_ProtecaoSNPC_Add} />
			<Route path='/cultivares/protecao-snpc/editar' component={Cultivares_ProtecaoSNPC_Edit} />
			<Route path='/cultivares/protecao-snpc/visualizar' component={Cultivares_ProtecaoSNPC_View} />
			<Route path='/cultivares/protecao-snpc/consultar' component={Cultivares_ProtecaoSNPC_List} />

			<Route path='/cadastrar/requisitante' component={RegisterRequester} />
			<Route path='/consultar/requisitante' component={SearchRequester} />

			<Route path='/cadastrar/unidades-operativas' component={RegisterOperatingUnitys} />
			<Route path='/consultar/unidades-operativas' component={SearchOperatingUnitys} />

			<Route path='/cadastrar/orgaos-reguladores' component={RegisterRegulatoryBodies} />
			<Route path='/consultar/orgaos-reguladores' component={SearchRegulatorieBodies} />

			<Route path='/cadastrar/cultura' component={Cultivares_Cadastros_Culturas_Add} />
			<Route path='/cultivares/consultar' component={Cultivares_Cadastros_Culturas_List} />
			<Route path='/editar/cultura' component={Cultivares_Cadastros_Culturas_Edit} />

			<Route path='/cadastrar/tipos-de-solo' component={RegisterGroundsType} />
			<Route path='/consultar/tipos-de-solo' component={SearchGroundsType} />

			<Route path='/cadastrar/categorias-do-material' component={RegisterMaterialCategories} />
			<Route path='/consultar/categorias-do-material' component={SearchMaterialCategories} />

			<Route path='/cadastrar/requerente' component={Cultivares_Cadastros_Requerente_Add} />
			<Route path='/editar/requerente' component={Cultivares_Cadastros_Requerente_Edit} />
			<Route path='/cultivares/requerente/consultar' component={Cultivares_Cadastros_Requerente_List} />

			<Route path='/cultivares/tipos-de-registro/cadastrar' component={Cultivares_Cadastros_TiposRegistro_Add} />
			<Route path='/cultivares/tipos-de-registro/consultar' component={Cultivares_Cadastros_TiposRegistro_List} />
			<Route path='/cultivares/tipos-de-registro/editar' component={Cultivares_Cadastros_TiposRegistro_Edit} />

			<Route path='/cadastrar/orgaos-externos' component={RegisterExternalOrgans} />
			<Route path='/consultar/orgaos-externos' component={SearchExternalOrgans} />

			<Route path='/cadastrar/eventos' component={Cultivares_Cadastros_Eventos_Add} />
			<Route path='/cultivares/eventos/consultar' component={Cultivares_Cadastros_Eventos_List} />
			<Route path='/editar/evento' component={Cultivares_Cadastros_Eventos_Edit} />

			<Route path='/cadastrar/melhoristas' component={Cultivares_Cadastros_Melhorista_Add} />
			<Route path='/cultivares/melhoristas/consultar' component={Cultivares_Cadastros_Melhorista_List} />
			<Route path='/editar/melhoristas' component={Cultivares_Cadastros_Melhorista_Edit} />

			{/* Módulo AAAs */}

			<Route path='/autorizacoes/cadastrar/unidades-estacoes-basf' component={CIBIO_Cadastros_Estacoes_Add}/>
			<Route path='/autorizacoes/consultar/unidades-estacoes-basf' component={CIBIO_Cadastros_Estacoes_List}/>
			<Route path='/autorizacoes/editar/unidades-estacoes-basf' component={CIBIO_Cadastros_Estacoes_Edit}/>

			<Route path='/autorizacoes/cadastrar/cultura' component={CIBIO_Cadastros_Culturas_Add}/>
			<Route path='/autorizacoes/consultar/cultura' component={CIBIO_Cadastros_Culturas_List}/>
			<Route path='/autorizacoes/editar/cultura' component={CIBIO_Cadastros_Culturas_Edit}/>

			<Route path='/autorizacoes/cadastrar/evento' component={CIBIO_Cadastros_Eventos_Add}/>
			<Route path='/autorizacoes/consultar/evento' component={CIBIO_Cadastros_Eventos_List}/>
			<Route path='/autorizacoes/editar/evento' component={CIBIO_Cadastros_Eventos_Edit}/>

			<Route path='/autorizacoes/cadastrar/destinatario' component={CIBIO_Cadastros_Destinatarios_Add} />
			<Route path='/autorizacoes/consultar/destinatario' component={CIBIO_Cadastros_Destinatarios_List} />
			<Route path='/autorizacoes/editar/destinatario' component={CIBIO_Cadastros_Destinatarios_Edit} />

			<Route path='/autorizacoes/cadastrar/ami' component={CIBIO_AMI_Add}/>
			<Route path='/autorizacoes/consultar/ami' component={CIBIO_AMI_List}/>
			<Route path='/autorizacoes/editar/ami' component={CIBIO_AMI_Edit}/>
			<Route path='/autorizacoes/visualizar/ami' component={CIBIO_AMI_View}/>

			<Route path='/autorizacoes/cadastrar/aac' component={CIBIO_AAC_Add}/>
			<Route path='/autorizacoes/consultar/aac' component={CIBIO_AAC_List}/>
			<Route path='/autorizacoes/editar/aac' component={CIBIO_AAC_Edit}/>
			<Route path='/autorizacoes/visualizar/aac' component={CIBIO_AAC_View}/>

			<Route path='/autorizacoes/consultar/ai' component={CIBIO_AI_List}/>
			<Route path='/autorizacoes/cadastrar/ai' component={CIBIO_AI_Edit}/>
			<Route path='/autorizacoes/editar/ai' component={CIBIO_AI_Edit}/>
			<Route path='/autorizacoes/visualizar/ai' component={CIBIO_AI_Edit}/>

			<Route path='/autorizacoes/consultar/ae' component={CIBIO_AE_List}/>
			<Route path='/autorizacoes/cadastrar/ae' component={CIBIO_AE_Edit}/>
			<Route path='/autorizacoes/editar/ae' component={CIBIO_AE_Edit}/>
			<Route path='/autorizacoes/visualizar/ae' component={CIBIO_AE_Edit}/>

			{/* Módulo Shipment */}
			
			<Route path='/shipment/consultar' component={SHIP_SOLICITACOES}/>
			<Route path='/shipment/editar' component={SHIP_SOLICITACOES_EDIT}/>
			<Route path='/shipment/visualizar' component={SHIP_SOLICITACOES_EDIT}/>
			<Route path='/shipment/nova' component={SHIP_SOLICITACOES_NOVA}/>

			<Route path='/shipment/cultura/consultar' component={SHIP_CULTURA_LIST}/>
			<Route path='/shipment/cultura/editar' component={SHIP_CULTURA_EDIT}/>
			<Route path='/shipment/cultura/nova' component={SHIP_CULTURA_EDIT}/>

			<Route path='/shipment/material/consultar' component={SHIP_MATERIAL_LIST}/>
			<Route path='/shipment/material/editar' component={SHIP_MATERIAL_EDIT}/>
			<Route path='/shipment/material/nova' component={SHIP_MATERIAL_EDIT}/>

			<Route path='/shipment/requisitante/consultar' component={SHIP_REQUISIT_LIST}/>
			<Route path='/shipment/requisitante/editar' component={SHIP_REQUISIT_EDIT}/>
			<Route path='/shipment/requisitante/nova' component={SHIP_REQUISIT_EDIT}/>

			{/* Módulo LPMA */}

			<Route path='/lpma/projeto/consultar' component={LPMA_PROJETOS_LIST}/>
			<Route path='/lpma/projeto/editar/:id' component={LPMA_PROJETOS_EDIT}/>
			<Route path='/lpma/projeto/visualizar/:id' component={LPMA_PROJETOS_EDIT}/> 
			<Route path='/lpma/projeto/novo' component={LPMA_PROJETOS_EDIT}/>

			<Route path='/lpma/equipe-tecnica/consultar' component={LPMA_EQUIPE_TECNICA_LIST}/>
			<Route path='/lpma/equipe-tecnica/editar/:id' component={LPMA_EQUIPE_TECNICA_EDIT}/>
			<Route path='/lpma/equipe-tecnica/visualizar/:id' component={LPMA_EQUIPE_TECNICA_EDIT}/> 
			<Route path='/lpma/equipe-tecnica/novo' component={LPMA_EQUIPE_TECNICA_EDIT}/>

			<Route path='/lpma/local/consultar' component={LPMA_LOCAL_LIST}/>
			<Route path='/lpma/local/editar/:id' component={LPMA_LOCAL_EDIT}/>
			<Route path='/lpma/local/visualizar/:id' component={LPMA_LOCAL_EDIT}/> 
			<Route path='/lpma/local/novo' component={LPMA_LOCAL_EDIT}/>

			<Route path='/lpma/configuracoes/consultar' component={LPMA_CONFIGS_LIST}/>
			<Route path='/lpma/configuracoes/editar/:id' component={LPMA_CONFIGS_EDIT}/>
			<Route path='/lpma/configuracoes/visualizar/:id' component={LPMA_CONFIGS_EDIT}/> 

			<Route path='/lpma/rn35/consultar' component={LPMA_RN35_LIST}/>
			<Route path='/lpma/rn35/comunicados/:id' component={LPMA_RN35_COMUNICADOS} /> 
			<Route path='/lpma/rn35/editarcomunicados/:id' component={LPMA_RN35_COMUNICADOS_EDIT} /> 
			<Route path='/lpma/rn35/:modo/:id' component={LPMA_RN35_EDIT}/>
			<Route path='/lpma/rn35/:modo' component={LPMA_RN35_EDIT}/>

			<Route path='/lpma/rn06/consultar' component={LPMA_RN06_LIST}/> 
			<Route path='/lpma/rn06/comunicados/:id' component={LPMA_RN06_COMUNICADOS} /> 
			<Route path='/lpma/rn06/editarcomunicados/:id' component={LPMA_RN06_COMUNICADOS_EDIT} /> 
			<Route path='/lpma/rn06/:modo/:id' component={LPMA_RN06_EDIT}/> 
			<Route path='/lpma/rn06/:modo' component={LPMA_RN06_EDIT}/>

			{/* LPMA - Oficio CIBIO */}
			<Route path="/oficiolpma/:id" component={LPMA_OFICIO_EDIT} />

			{/* Módulo CQB */}

			<Route path='/cqb/consultar' component={CQB_LIST}/> 
			<Route path='/cqb/:modo/:id' component={CQB_EDIT}/> 
			<Route path='/cqb/:modo' component={CQB_EDIT}/>  

			<Route path='/cqb-equipe-tecnica/consultar' component={CQB_EQUIPE_TECNICA_LIST}/>
			<Route path='/cqb-equipe-tecnica/:modo/:id' component={CQB_EQUIPE_TECNICA_EDIT}/>
			<Route path='/cqb-equipe-tecnica/:modo' component={CQB_EQUIPE_TECNICA_EDIT}/>

			<Route path='/cqb-comissao-cibio' component={CQB_COMISSAO_CIBIO_EDIT}/>

			<Route path='/cqb-local/consultar' component={CQB_LOCAL_LIST}/>
			<Route path='/cqb-local/:modo/:id' component={CQB_LOCAL_EDIT}/>
			<Route path='/cqb-local/:modo' component={CQB_LOCAL_EDIT}/>

			<Route path='/cqb-configuracoes/consultar' component={CQB_CONFIGS_LIST}/>
			<Route path='/cqb-configuracoes/:modo/:id' component={CQB_CONFIGS_EDIT}/>

			{/* Módulo RELATÓRIO CONCLUSIVO OGM */}

			<Route path='/rclpma/consultar' component={RCLPMA_LIST}/> 
			<Route path='/rclpma/:modo/:id' component={RCLPMA_EDIT}/> 
			<Route path='/rclpma/:modo' component={RCLPMA_EDIT}/>  

			{/* Módulo Caderno Conformidades */}

			<Route path='/cadernoconformidade/consultar/vegetacao' component={CADERNO_CONFORMIDADE_List} />
			<Route path='/cadernoconformidade/editar/vegetacao' component={CADERNO_CONFORMIDADE_Edit} />
			<Route path='/cadernoconformidade/visualizar/vegetacao' component={CADERNO_CONFORMIDADE_View} />	
			<Route path='/cadernoconformidade/cadastrar/vegetacao' component={CADERNO_CONFORMIDADE_Add} />
			
		</Switch>
	</BrowserRouter>;
}