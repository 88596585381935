import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import Button from "../Button";
import DeleteButton from '../DeleteButton';
import EditButton, { InsertButton, CameraButton, AnexoButton } from '../EditButton';
import GeneratePDF, { GenerateAnexo } from '../GeneratePDF';

import TextField from './TextField';
import ReasonModal from '../../components/ReasonModal';
import { Form } from 'react-bootstrap';
import "./style.scss";
import CheckMsgErros from './_msgErrs';
import { uuid } from '../../services/uuid';


interface initialValues{
    guid       : any;
    name       : string;
    desc       : string;
    legenda    : string;
}

interface Props {
    form?           :any;
	name?           :string;
    nameGuid?       :string;
    nameFile?       :string;
    nameAdicionalText? :string;
    nameAdicionalText2?:string;
    nameValidCheck? : string;
	label           :string;
	placeholder?    :string;
	control?        :any;	
	layoutColumns?  : number;
    required?       : boolean;
    textButton?     : string;
    onChange?       : any;
    multiple?       : boolean;
    enabled?        :boolean;
    key?            : any;
    defaultValue?   : initialValues[];
    isInvalidCheck? : boolean;
    adicionalText?  : string;
    adicionalText2? : string;
    accept?         : string;
    urlpreview?     : string;
    minHeight?      : any;
    maxHeight?      : any;
    isAdicionalText?: boolean;
    isAdicionalText2?:boolean

    iconView        ?: 'IMG' | 'FILE' | 'PDF' | 'ANEXO';

    ClickFile       ?:(f:fileData, isLocal:boolean) => void;
}

interface fileData{
    uid             ?: any;
    name            ?: string;
    desc            ?: string;
    legenda         ?: string;
    blob            ?: File | undefined | null;
    guid            ?: string | null;
    urlPreview      ?: any;
}

//const FilesUpload: React.FC = (props: Props) => {
function FilesUpload(props: Props){
    
    const [rowsData,    setRowsData]    = React.useState<fileData[]>    ([]);
    const [enabled,     setEnabled]     = React.useState<boolean>       (true);
    const [asErro,      setAsErro]      = React.useState<boolean>       (false);
    const [msgError,    setMsgError]    = React.useState<string | null> (null);
    const [editItem,    setEditItem]    = React.useState<boolean>       (false);
    const [showDialogItem, setShowDialogItem] = React.useState<any>     ({element: null as any, index: 0, updateDesc: null, updateLegenda: null});
    const [showDialog,  setShowDialog]  = React.useState<boolean>       (false);

    const inputFileRef  = React.useRef<HTMLInputElement>(null); // React.createRef();// useRef<HTMLFormElement>(null); 
    const GrpRef        = React.useRef(); //React.createRef();// useRef<HTMLFormElement>(null);

    React.useEffect(() => {
        const enabled_check = (props.enabled === true || props.enabled === undefined || props.enabled === null)
        setEnabled(enabled_check)
        setEditItem(enabled_check && (props.isAdicionalText ?? false));

    },[props.enabled, props.isAdicionalText ]);

    const Registrar = ()=>{
        props.form?.register(`${props.name}`);
        props.form?.register(`${props.nameGuid}`);
        if (props.nameAdicionalText != null) {
            props.form?.register(`${props.nameAdicionalText}`);
        }
        if (props.nameAdicionalText2 != null) {
            props.form?.register(`${props.nameAdicionalText2}`);
        }
        if(props.nameFile != null){  props.form?.register(`${props.nameFile}`); }
    }

    React.useEffect(() => {
        Registrar();
        
        let fBlob = props.form.getValues(props.name) as []      ?? [];
        let fGuid = props.form.getValues(props.nameGuid) as []  ?? [];
        let fAddN = props.form.getValues(props.nameAdicionalText) as [] ??[];
        let fAddD = props.form.getValues(props.nameAdicionalText2) as [] ?? [];
        let fAddF = props.form.getValues(props.nameFile) as [] ??[];

        var itemsInicial: fileData[] = []
        if(fBlob.length > 0 || fGuid.length > 0 || fAddN.length> 0 || fAddF.length> 0){
            let mx = fBlob.length; 
            mx = (fGuid.length > mx) ? fGuid.length : mx;
            mx = (fAddN.length > mx) ? fAddN.length : mx;
            mx = (fAddD.length > mx) ? fAddD.length : mx;
            mx = (fAddF.length > mx) ? fAddF.length : mx;

            for(let i = 0, t = mx;  i < t ; i++){
                const b = (i < (fBlob.length - 1) ? fBlob[i] : null);
                const g = (i < (fGuid.length - 1) ? fGuid[i] : '');
                const a = (i < (fAddN.length - 1) ? fAddN[i] : '');
                const d = (i < (fAddD.length - 1) ? fAddD[i] : '');
                const n = (i < (fAddF.length - 1) ? fAddF[i] : '');
                itemsInicial.push({
                    uid: uuid(),
                    blob: b,
                    guid: g,
                    desc: d,
                    legenda: a,
                    name: n,
                })
            }
        }
        if(itemsInicial.length > 0){
            //setStared(true);
            setRowsData(itemsInicial);    
        }
    },[]);

    React.useEffect(() => {
       
        try{
            Registrar();
            
            var itemsInicial: fileData[] = []        
            if(props.defaultValue){
                //props.form.setValue(props.name, props.defaultValue);
                for(var i = 0, t = (props.defaultValue?.length ?? 0); i < t; i++)
                {
                    const elm = props.defaultValue[i];
                    itemsInicial.push({name: elm.name, guid: elm.guid, desc: elm.desc, blob: null, legenda: elm.legenda});
                }                
                UpdateItemInForm(itemsInicial);
                setRowsData(itemsInicial);    
            }
            /***

            if(!props.form || !props.nameGuid || !props.defaultValue) {return; }
            // const filesGuid = props.form?.getValues(props.nameGuid);
            // const filesName = props.form?.getValues(props.name);
            // const filesDesc = props.nameAdicionalText != null ? props.form?.getValues(props.nameAdicionalText) : '';
   
            var itemsInicial: fileData[] = [];
            for(var i = 0, t = (props.defaultValue?.length ?? 0); i < t; i++){
                // props.form?.register(`${props.name}.${i}`);
                //props.form?.register(`${props.nameGuid}.${i}`);
                //if(props.nameAdicionalText != null){  props.form?.register(`${props.nameAdicionalText}.${i}`); }
                const elm = props.defaultValue[i];
                itemsInicial.push({name: elm.name, guid: elm.guid, descrition: elm.desc, blob: null});
            }

            UpdateItemInForm(itemsInicial);
            setRowsData(itemsInicial);    
            ***/        
            //CheckMsgErros(props, (msg:any)=>{ setState({msgError:msg}) }, (err:any)=>{ setState({asErro:err}) }, props.nameGuid)
                         
        }catch(error:any){
            console.error("ERROR", error)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue, props.name]);//, props.form

    React.useEffect(() => {
		CheckMsgErros(props, (msg:any)=>{ setMsgError(msg) }, (err:any)=>{ 
            setAsErro(err) 
            if(err && props.isInvalidCheck){
                if(GrpRef != null) {
                    let elmt = GrpRef.current as any;
                    //window.scrollTo(0, elmt?.offsetTop - 100)   
                    elmt?.scrollIntoView({ behavior: 'smooth', block: 'start' }, 500);
                }
            }
        }, props.nameValidCheck)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.errors, props.name]);


    const dialogEditUpdate = (evt:any, def:any, tipo: any) => {
        //evt.target.value
         let setUp = showDialogItem;

        if (tipo === 'descricao') {
            setUp.updateDesc = evt.target.value
        }
        if (tipo === 'legenda') {
            setUp.updateLegenda = evt.target.value
        }
        setShowDialogItem(setUp);
    }
    const dialogEdit = () => {
        return <ReasonModal
            className_={`dialogEditBox modal-dialog-50`} show={showDialog}
            onHide={() => setShowDialog(false)}
            title={"Editar"}
            actionMsg={"Confirmar"}
            onConfirm={() => {           
                let items = [...rowsData];    
                if(items !== null){
                    items[showDialogItem.index].desc =
                        showDialogItem.updateDesc ? showDialogItem.updateDesc : items[showDialogItem.index].desc;
                    items[showDialogItem.index].legenda =
                        showDialogItem.updateLegenda ? showDialogItem.updateLegenda : items[showDialogItem.index].legenda;
                    UpdateItemInForm(items);
                    setRowsData(items);                    
                }   
                setShowDialog(false)               
            }}
            loading={false}
            disabled={false}
            // hideFooter={successCancel}
        >
            <TextField defaultValue={showDialogItem?.element?.name} label="Arquivo" enabled={false} onChange={(evt:any) => dialogEditUpdate(evt, showDialogItem?.element?.name, '')}></TextField>
            <TextField defaultValue={showDialogItem?.element?.legenda} label={props.adicionalText ?? "Legenda"} onChange={(evt:any) => dialogEditUpdate(evt, showDialogItem?.element?.legenda, 'legenda')}></TextField>
            <TextField defaultValue={showDialogItem?.element?.desc} label={props.adicionalText2 ?? "Descrição"} onChange={(evt:any) => dialogEditUpdate(evt, showDialogItem?.element?.desc, 'descricao')}></TextField>
        </ReasonModal>;

    }
    const ViewAction = (linha: any) =>{
        return <React.Fragment>   
            {props.iconView === 'IMG' 
            ? <CameraButton tooltip={'Ver Image'} onClick={() => { }} /> 
            : '' }   
            {props.iconView === 'ANEXO' 
            ? <AnexoButton tooltip={'Baixar'} onClick={() => {
                if(props.ClickFile) {
                    const DATA = rowsData[linha - 1];

                    if(!(!DATA.blob )){
                        const blobF = DATA.blob as any;
                        let blob = new Blob([blobF], { type: 'application/pdf' });
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        if (typeof a.download === "undefined") { 	window.location.href = url; }
                        else {
                        a.href = url; a.download = DATA.name ?? `Download Item${linha}`;
                        document.body.appendChild(a);
                        a.click();
                        }
                        props.ClickFile(rowsData[linha - 1], true)
                    }                  
                    else {
                        props.ClickFile(rowsData[linha - 1], false)
                    }
                   
                }
             }} /> 
            : '' }          
         </React.Fragment>;
    }
    const ColunaAction = (linha:any) => {
        return <React.Fragment>   
           {editItem ? <EditButton tooltip={'Editar '} onClick={() => {
               setShowDialog(true);
               setShowDialogItem({element: rowsData[linha - 1], index : linha - 1 });  
           }} />: ''}
           
           <DeleteButton tooltip={'Remover '} onClick={() => {
                let items = [...rowsData];
                items.splice((linha - 1), 1);
                UpdateItemInForm(items);
                setRowsData(items);                

                if(inputFileRef?.current !== null && inputFileRef?.current !== undefined){
                    (inputFileRef?.current as any).value = "";
                }
                    
                //(inputFileRef?.current as any)?.value='';
           }} />
       </React.Fragment>;
   }

    const InsertFile = () => {
        return <React.Fragment>   
            <InsertButton tooltip={props.textButton ?? "+ Adicionar"} 
            onClick={() => {
                if(inputFileRef?.current !== null && inputFileRef?.current !== undefined){
                    inputFileRef.current.click();
                }
			}} />
        </React.Fragment>;
    }

    const UpdateItemInForm = (filesData: fileData[])=>{

        const filesI = filesData.map(e=> e.blob);
        const descricao = filesData.map(e=> (!e.desc ? '' : e.desc));
        const legenda = filesData.map(e => (!e.legenda ? '' : e.legenda));
        const guids_ = filesData.map(e=> e.guid);

        props.form?.setValue(`${props.name}`, filesI);   
        if(props.nameAdicionalText != null){ 
            props.form?.setValue(`${props.nameAdicionalText}`, legenda); 
        }

        if (props.nameAdicionalText2 != null) {
            props.form?.setValue(`${props.nameAdicionalText2}`, descricao);
        }
        props.form?.setValue(`${props.nameGuid}`, guids_);     

        //TODO:remover
        // props.form?.setValue(`${props.name}`, null);   
        // props.form?.setValue(`${props.nameAdicionalText}`, null);   
        // props.form?.setValue(`${props.nameGuid}`, null);   

        // for(let i = 0, t = filesData.length; i < t; i++){
        //     const row = filesData[i];
        //     props.form?.setValue(`${props.name}.${i}`, row.blob);   
        //     props.form?.setValue(`${props.nameGuid}.${i}`, row.guid);   
        //     props.form?.setValue(`${props.nameAdicionalText}.${i}`, row.descrition);
                          
        // }
    }
    
    const fileUpload = (({ target }: any)=>{
        if (!target.value) { return; }
        let items = rowsData as fileData[];
        for(let i = 0, t = target.files.length; i < t; i++){
            items.push({uid: uuid(), name: target.files[i]?.name, blob: target.files[i]})                                    
        }
        const updtFiles =[...items];    
        UpdateItemInForm(updtFiles);
        setRowsData([])
        setRowsData(updtFiles)
        
    });

    return <Bootstrap.Form.Group ref={GrpRef}  as={Bootstrap.Col} className="custum_FilesUpload" md={props.layoutColumns}>
            {dialogEdit()}   
            <Bootstrap.Form.Row>
            </Bootstrap.Form.Row>
            <Bootstrap.Form.Row>   
                <Bootstrap.Table
                    style={{minHeight: (props.minHeight ?? '120px'), maxHeight: (props.maxHeight ?? '250px')}}
                    >
                    <thead>
                        <th style={{textAlign: 'center'}}></th>
                        <th>{props.label} {props.required ? <em>*</em> : ''}
                        {(props.isInvalidCheck && asErro)? <><br />
                        <span className="error-field">{props?.nameValidCheck ? ( msgError ??  props.form?.errors[props?.nameValidCheck]?.message) : ''}</span>
                        </>:''}
                        </th> 
                        {enabled ?                    
                        <th style={{textAlign: 'center'}}>
                        <input type="file"		
                            placeholder='-' aria-label='-'		
                            ref={inputFileRef}
                            name={`${props.name}-set-files`}
                            accept={props.accept}
                            // label={
                            //     //anexosItemsNames.map(e=> e).join(", ")
                            // }
                            data-browse={<Button
                                className="btn-sm btn-success"
                                title="+ Adicionar"
                                contained={true}
                                type="button"
                            /> }
                            multiple={true}
                            style={{display : 'none'}}
                            onChange={fileUpload}
                        />	
                        {InsertFile()}
                        </th>:''}   
                    </thead>
                    <tbody>
                        {rowsData?.map((row:fileData, rowIndex:any) => {
                            if(row.blob != null){
                                //props.form?.setValue(`${props.name}.${rowIndex}`, row.blob);                                
                            }   
                            let campo = '';
                            if(props.isAdicionalText2 && props.adicionalText2){
                                const descricaoLinha = props.isAdicionalText2 && props.adicionalText2 ? props.adicionalText2 : '';
                                campo = `${descricaoLinha}: ${row.desc ?? ""}`;
                            }                  
                 
                            return <tr key={row.uid}>
                                <td className='fi-upload-td' style={{ textAlign: 'center' }}>{ViewAction(rowIndex + 1)}</td>
                                <td className='fi-upload-td'>{row.name}<small><br /><span className="small-bold">{campo}</span></small>
                                    <Form.Group className="dataHide" as={Bootstrap.Col} md={1}>     
                                        {row.blob != null ?         
                                        <Form.File
                                            // ref={props.form?.register}   
                                            key={props.key}             
                                            value=""
                                            name={`${props.name}no.${rowIndex}`}
                                            onChange={({ target }: any) => {}}
                                        />  
                                        :
                                        <Form.Control
                                            className="dataHide"
                                            key={props.key}
                                            defaultValue={row.name}
                                            name={`${props.name}no.${rowIndex}`}			
                                            // ref={props.form?.register}                    
                                            type="text"
                                        />
                                        }
                                        <Form.Control className="dataHide"
                                            key={props.key} 
                                            defaultValue={row.guid ?? ''}
                                            name={`${props.nameGuid}.${rowIndex}`}	
                                            // ref={props.form?.register}   
                                        />
                                        <Form.Control className="dataHide"
                                            //  {...props.form?.register(`${props.nameAdicionalText}.${rowIndex}`)}
                                            key={props.key} 
                                            defaultValue={row.legenda ?? ''}
                                            name={`${props.nameAdicionalText}.${rowIndex}`}	
                                            // ref={props.form?.register}   
                                        />
                                        <Form.Control className="dataHide"
                                            //  {...props.form?.register(`${props.nameAdicionalText}.${rowIndex}`)}
                                            key={props.key}
                                            defaultValue={row.desc ?? ''}
                                            name={`${props.nameAdicionalText2}.${rowIndex}`}
                                        // ref={props.form?.register}   
                                        />
                                    </Form.Group>
                                </td>
                                 {enabled ?       
                                <td className='fi-upload-td' style={{textAlign: 'center'}}>{ColunaAction(rowIndex + 1)}</td>
                                :''}
                            </tr>;
                        })} 
                    </tbody>
                    <tfoot>

                    </tfoot>
                </Bootstrap.Table>
            </Bootstrap.Form.Row>
            </Bootstrap.Form.Group>;

}

export default FilesUpload;