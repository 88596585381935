import React from 'react'

interface Props
{
	title: any;
	children: any;
	className?: string;
	legendClassName?: string;
	disabled?: boolean;
	required?: boolean;
}

export default function Fieldset(props: Props)
{
	return <fieldset disabled={props.disabled} className={`custom-fieldset ${props.className}`}>
		<legend className={props.legendClassName || 'custom-legend'}>{props.required ? <em>*</em>: ''} {props.title}</legend>
		{props.children}
	</fieldset>;
}