import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Col, InputGroup } from 'react-bootstrap';
import AddButton from '../../../components/AddButton';
import Button from '../../../components/Button';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import LoadingInForm from '../../../components/LoadingInForm';
import GenericButton from '../../../components/FlowGenericButton';
import EditButton from '../../../components/EditButton';
import { STRINGS } from '../../../util/strings';
import { STRINGS_CADERNOCONFORMIDADE } from '../../../util/cadernoConformidade/string';
import Loading from '../../../components/Loading';
import ConfirmationModal from '../../../components/ConfirmationModal';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from 'react-datepicker';
import jwtDecode from 'jwt-decode';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { Context } from '../../../context/AuthContext'
import { DecodedToken } from '../../../models/user';

import '../../../pages/Registers/styles.css';
import api from '../../../services/api';
import { DecimalAsComma } from '../NumberFormat';
import { _generateDate } from '../../../util/convert';
import '../styles_add.scss';
import { isAdmin } from '../../../users/CadernoConformidade/isAdmin';
import { isRequesterReader, isRequesterWriter } from '../../../users/CadernoConformidade/isRequester';
import { isReguladorWriter, isReguladorReader } from '../../../users/CadernoConformidade/isRegulador';

import { STATUS_CADERNOCONFORMIDADE } from '../../../util/cadernoConformidade/status';

import { StateType, Enum } from '../../../models/modelsCadernoConformidade';


registerLocale('pt-BR', ptBR);

export default function CadernoConformidade()
{
	const history = useHistory();
	const location = useLocation<StateType>();
	const { token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);
	const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
	const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [loadingGetData, setLoadingGetData] = useState<boolean>(true);
	const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
//	const [successEvent, setSuccessEvent] = useState<boolean>(false);
//	const [successTransport, setSuccessTransport] = useState<boolean>(false);
//	const [successMaterial, setSuccessMaterial] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [errorGetData, setErrorGetData] = useState<any>(null);
//	const [locations, setLocations] = useState<UnityStationResult[]>([]);
//	const [events, setEvents] = useState<EventResult[]>([]);
//	const [cultures, setCultures] = useState<CultureResult[]>([]);
//	const [transport, setTransport] = useState<Enum[]>([]);
//	const [modalTransports, setModalTransports] = useState<boolean>(false);
//	const [modalEnvio, setModalEnvio] = useState<boolean>(false);
//	const [modalRecebimentos, setModalRecebimentos] = useState<boolean>(false);
//	const [modalEditEnvio, setModalEditEnvio] = useState<boolean>(false);
//	const [modalEditRecebimento, setModalEditRecebimento] = useState<boolean>(false);
//	const [selectedTransports, setSelectedTransports] = useState<TransportsOnEditAMI[]>([]);
//	const [modalEvents, setModalEvents] = useState<boolean>(false);
//	const [modalEditEvents, setModalEditEvents] = useState<boolean>(false);
//	const [selectedEvents, setSelectedEvents] = useState<EventsOnEditAMI[]>([]);
//	const [eventToEdit, setEventToEdit] = useState<EventListAMI>({
//		id: 0,
//		evento: { label: '', value: 0 },
//		material: { label: '', value: 0 },
//		quantidadeAutorizada: 0,
//		quantidadeTransportada: 0,
//		unidadeMedidaAutorizada: { label: '', value: 0 },
//		unidadeMedidaTransportada: { label: '', value: 0 }
//	});
//	const [transportToEdit, setTransportToEdit] = useState<TransportListAMI>({
//		id: 0,
//		localPartida: { label: '', value: 0 },
//		localDestino: { label: '', value: 0 },
//		embalagemDuplaImpermeavel: 0,
//		identificacaoSimboloRiscoBiologico: 0,
//		responsavelTransporte: '',
//		dataEnvio: '',
//		qtdTransportada: 0,
//		unidadeMedidaTransportada: { label: '', value: 0 },
//		materialChegouDestinoPerfeitasCondicoes: 0,
//		qtdRecebidaDeAcordoComQtdEnviada: 0,
//		responsavelRecebimento: '',
//		dataRecebimento: '',
//		qtdRecebida: 0,
//		unidadeMedidaRecebida: { label: '', value: 0 },
//		contagem: 0
//	});
//	const [enumMaterial, setEnumMaterial] = useState<Enum[]>([]);
//	const [enumUnidadeMedida, setEnumUnidadeMedida] = useState<Enum[]>([]);
	const [gridProps, setGridProps] = useState<any>(null);
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);
//	const [eventMsg, setEventsMsg] = useState('');
//	const [transportMsg, setTransportsMsg] = useState('');
	const [statusAuthorization, setStatusAuthorization] = useState<number>(0);
//	const [isAprove, setIsAprove] = useState<boolean>(false);
//	const [loadingAprove, setLoadingAprove] = useState<boolean>(false);
//	const [successAprove, setSuccessAprove] = useState<boolean>(false);
//	const [errorAprove, setErrorAprove] = useState<any>(null);
//	const [isCorrection, setIsCorrection] = useState<boolean>(false);
//	const [loadingCorrection, setLoadingCorrection] = useState<boolean>(false);
//	const [successCorrection, setSuccessCorrection] = useState<boolean>(false);
//	const [errorCorrection, setErrorCorrection] = useState<any>(null);
//	const [isComplete, setIsComplete] = useState<boolean>(false);
//	const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
//	const [successComplete, setSuccessComplete] = useState<boolean>(false);
//	const [errorComplete, setErrorComplete] = useState<any>(null);
//	const [loadingRegulatory, setLoadingRegulatory] = useState<boolean>(false);
//	const [errorRegulatory, setErrorRegulatory] = useState<any>(null);
//	const [successRegulatory, setSuccessRegulatory] = useState<boolean>(false);
//	const [destinatarios, setDestinatarios] = useState<DestinatarioResult[]>([]);
//	const [requisitanteAMI, setRequisitanteAMI] = useState('');


//	//#region Validações
//		const isEvento = modalEvents ? {
//			evento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
//		} : {
//			evento: yup.mixed()
//		}

//		const isEditEvento = modalEditEvents ? {
//			editEvento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
//		} : {
//			editEvento: yup.mixed()
//		}

//		const isQuantidadeAutorizada = modalEvents ? {
//			quantidadeAutorizada: yup
//				.number()
//				.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
//				.nullable()
//				.required(STRINGS.FORM_FIELD_REQUIRED)
//				.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
//		} : {
//			quantidadeAutorizada: yup.number().nullable()
//		}

//		const isEditQuantidadeAutorizada = modalEditEvents ? {
//			editQuantidadeAutorizada: yup
//				.number()
//				.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
//				.nullable()
//				.required(STRINGS.FORM_FIELD_REQUIRED)
//				.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
//		} : {
//			editQuantidadeAutorizada: yup.number().nullable()
//		}

//		const isMaterial = modalEvents ? {
//			material: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
//		} : {
//			material: yup.mixed()
//		}

//		const isEditMaterial = modalEditEvents ? {
//			editMaterial: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
//		} : {
//			editMaterial: yup.mixed()
//		}

//		const isResponsavelTransporte = modalTransports ? {
//			responsavelTransporte: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
//		} : {
//			responsavelTransporte: yup.mixed()
//		}

//		const isEditResponsavelRecebimento = modalEditRecebimento ? {
//			editresponsavelRecebimento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
//		} : {
//			editresponsavelRecebimento: yup.mixed()
//		}

//		const isQuantidadeTransportada = modalTransports ? {
//			quantidadeTransportada: yup
//				.number()
//				.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
//				.nullable()
//				.required(STRINGS.FORM_FIELD_REQUIRED)
//				.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
//		} : {
//			quantidadeTransportada: yup.number().nullable()
//		}

//		const isEditqtdRecebida = modalEditRecebimento ? {
//			editqtdRecebida: yup
//				.number()
//				.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
//				.nullable()
//				.required(STRINGS.FORM_FIELD_REQUIRED)
//				.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
//		} : {
//			editqtdRecebida: yup.number().nullable()
//		}
//	//#endregion

//	//#region Schemas
//	const amiSchema = yup.object({
//		localDeEnvio: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
//		//destinatario: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
//		localDeDestino: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
//		possuiCQB: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
//		modoDeTransporte: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
//		origem: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
//		objetivoUtilizacao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
//		observacaoPosAprovacao: yup.string(),
//		culturaOrganismo: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
//		observacoes: yup.string(),
//		trechoLPMA: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
//		escopoLPMA: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
//		embalagemDupla: yup.string(),
//		identificacaoComSimboloRiscoBiologico: yup.string(),
//		responsavelTransporte: yup.string(),
//		dataEnvio: yup.date(),
//		materialPerfeitasCondicoes: yup.string(),
//		qtdRecebidaDeAcordoQtdEnviada: yup.string(),
//		responsavelRecebimento: yup.string(),
//		dataRecebimento: yup.date(),
//		dataCriacao: yup.date().nullable(),
//		id: yup.number(),
//		status: yup.string(),
//		numeroAutorizacaoCibio: yup.mixed().nullable(),
//		dataEmissaoCibio: yup.date().nullable(),
//		dataAprovacao: yup.date().nullable(),
//		observacoesAnaliseRegulatorio: yup.string(),
//		autorCancelamento: yup.string(),
//		dataCancelamento: yup.date(),
//		listaDestinatario: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
//	});

//	const initialValues: AMIForm = {
//		requisitante: '',
//		localDeEnvio: undefined,
//		destinatario: '',
//		listaDestinatario: { label: '', value: 0 },
//		localDeDestino: undefined,
//		possuiCQB: '0',
//		modoDeTransporte: undefined,
//		origem: '',
//		objetivoUtilizacao: '',
//		observacaoPosAprovacao: '',
//		culturaOrganismo: undefined,
//		observacoes: '',
//		trechoLPMA: '',
//		escopoLPMA: '0',
//		embalagemDupla: '0',
//		identificacaoComSimboloRiscoBiologico: '0',
//		responsavelTransporte: '',
//		dataEnvio: undefined,
//		materialPerfeitasCondicoes: '1',
//		qtdRecebidaDeAcordoQtdEnviada: '1',
//		responsavelRecebimento: '',
//		dataRecebimento: undefined,
//		id: undefined,
//		status: '',
//		numeroAutorizacaoCibio: 0,
//		dataEmissaoCibio: undefined,
//		dataAprovacao: undefined,
//		observacoesAnaliseRegulatorio: '',
//		autorCancelamento: '',
//		dataCancelamento: undefined,
//		motivoCancelamento: ''
//	}

//	const eventSchema = yup.object({
//		...isEvento,
//		...isQuantidadeAutorizada,
//		unidadeMedidaAutorizada: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
//		unidadeMedidaTransportada: yup.mixed().nullable(),
//		...isMaterial,
//		unidMedAutorizadaOutros: yup.string(),
//		materialOutros: yup.string(),
//		unidMedTranspOutros: yup.string()
//	});

//	const transportSchema = yup.object({
//		...isResponsavelTransporte,
//	});

//	const envioSchema = yup.object({
//		...isQuantidadeTransportada,
//		...isResponsavelTransporte,
//	});

//	const eventEditSchema = yup.object({
//		...isEditEvento,
//		...isEditQuantidadeAutorizada,
//		editUnidadeMedidaAutorizada: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
//		editUnidadeMedidaTransportada: yup.mixed().nullable(),
//		...isEditMaterial,
//		unidMedAutorizadaOutros: yup.string(),
//		materialOutros: yup.string(),
//		unidMedTranspOutros: yup.string()
//	});

//	const transportEditSchema = yup.object({
//		...isEditResponsavelRecebimento
//	});

//	const envioEditSchema = yup.object({
//	});
//	//#endregion

//	const { register, reset, setValue, getValues, errors, trigger, handleSubmit, control, watch, formState: { isDirty } } = useForm({
//		defaultValues: initialValues,
//		mode: 'onSubmit',
//		resolver: yupResolver(amiSchema)
//	});

//	const { getValues: getValuesEvent, control: controlEvent, register: registerEvent, errors: errorsEvent, handleSubmit: handleSubmitEvent, trigger: triggerEvent, watch: watchEvent } = useForm({
//		mode: 'all',
//		resolver: yupResolver(eventSchema)
//	})

//	const { getValues: getValuesTransport, control: controlTransport, register: registerTransport, errors: errorsTransport, handleSubmit: handleSubmitTransport, trigger: triggerTransport, watch: watchTransport } = useForm({
//		mode: 'all',
//		resolver: yupResolver(transportSchema)
//	})

//	const { control: controlEditEvent, register: registerEditEvent, errors: errorsEditEvent, handleSubmit: handleSubmitEditEvent, trigger: triggerEditEvent, watch: watchEditEvent } = useForm({
//		mode: 'all',
//		resolver: yupResolver(eventEditSchema)
//	})

//	const { control: controlEditTransport, register: registerEditTransport, errors: errorsEditTransport, handleSubmit: handleSubmitEditTransport, trigger: triggerEditTransport, watch: watchEditTransport } = useForm({
//		mode: 'all',
//		resolver: yupResolver(transportEditSchema)
//	})

//	const { control: controlEditEnvio, register: registerEditEnvio, errors: errorsEditEnvio, handleSubmit: handleSubmitEditEnvio, trigger: triggerEditEnvio, watch: watchEditEnvio } = useForm({
//		mode: 'all',
//		resolver: yupResolver(envioEditSchema)
//	})

//	//#region Inicializações
//	const setValuesWithDataResponse = (data: any) => {
//		reset({
//			dataCriacao: moment(data.dataCriacao).toDate(),
//			status: data.statusDescricao,
//			id: data.id,
//			localDeEnvio: {
//				label: data.localPartida?.nome,
//				value: data.localPartida?.id,
//			},
//			destinatario: data?.destinatario,
//			listaDestinatario: {
//				label: data.destinatarioLista?.nome,
//				value: data.destinatarioLista?.id,
//			},
//			localDeDestino: {
//				label: data.localDestino?.nome,
//				value: data.localDestino?.id,
//			},
//			modoDeTransporte: {
//				label: data.modoTransporteDescricao,
//				value: data.modoTransporteId,
//			},
//			origem: data.origem,
//			objetivoUtilizacao: data.objetivoUtilizacao,
//			observacaoPosAprovacao: data.observacaoPosAprovacao ? data.observacaoPosAprovacao : "",
//			culturaOrganismo: {
//				label: data.cultura?.nome,
//				value: data.cultura?.id,
//			},
//			observacoes: data.observacoes,
//			trechoLPMA: data.trechoLPMALocaisMovimentacaoMaterial,
//			numeroAutorizacaoCibio: data.codigo,
//			dataEmissaoCibio: data.dataEmissaoCibio ? moment(data.dataEmissaoCibio).toDate() : null,
//			dataAprovacao: data.dataAprovacao ? moment(data.dataAprovacao).toDate() : null,
//			observacoesAnaliseRegulatorio: data.observacoesAnaliseRegulatorio,
//			responsavelTransporte: data.responsavelTransporte,
//			dataEnvio: data.dataEnvio ? moment(data.dataEnvio).toDate() : null,
//			responsavelRecebimento: data.responsavelRecebimento,
//			dataRecebimento: data.dataRecebimento ? moment(data.dataRecebimento).toDate() : null,
//			possuiCQB: String(data.localDestinoPossuiCQB),
//			escopoLPMA: String(data.escopoLPMAContemplaMaterial),
//			embalagemDupla: String(data.embalagemDuplaImpermeavel),
//			identificacaoComSimboloRiscoBiologico: String(data.identificacaoSimboloRiscoBiologico),
//			materialPerfeitasCondicoes: String(data.materialPerfeitasCondicoes),
//			qtdRecebidaDeAcordoQtdEnviada: String(data.qtdRecebidaDeAcordoQtdEnviada),
//			autorCancelamento: data.autorCancelamento,
//			dataCancelamento: data.dataCancelamento ? moment(data.dataCancelamento).toDate() : null,
//			motivoCancelamento: data.motivoCancelamento,
//			requisitante: data.requisitante
//		});
//		setRequisitanteAMI(data.requisitante);

//		setStatusAuthorization(data.status);

//		setSelectedEvents([]);

//		setSelectedTransports([]);

//		setSuccessEvent(true);

//		setSuccessMaterial(true);
//		setSuccessTransport(true);

//		setTimeout(() => {

//			if (data?.eventos?.length) {

//				let newEventos: EventsOnEditAMI[] = [];

//				for (let n = 0, t = data?.eventos?.length; n < t; n++) {
//					let evento: EventsOnEditAMI = {
//						id: data.eventos[n].id,
//						unidadeMedidaAutorizadaDescricao: data.eventos[n].unidadeMedidaAutorizadaDescricao,
//						materialDescricao: data.eventos[n].materialDescricao,
//						unidadeMedidaTransportadaDescricao: data.eventos[n].unidadeMedidaTransportadaDescricao,
//						eventoNome: data.eventos[n].eventoNome,
//						qtdAutorizada: data.eventos[n].qtdAutorizada,
//						qtdTransportada: data.eventos[n].qtdTransportada,
//						unidadeMedidaAutorizada: data.eventos[n].unidadeMedidaAutorizada,
//						unidMedAutorizadaOutros: data.eventos[n].unidMedAutorizadaOutros,
//						material: data.eventos[n].material,
//						materialOutros: data.eventos[n].materialOutros,
//						unidadeMedidaTransportada: data.eventos[n].unidadeMedidaTransportada,
//						unidMedTranspOutros: data.eventos[n].unidMedTranspOutros,
//						eventoId: data.eventos[n].eventoId,
//					};

//					newEventos.push(evento);
//				}

//				setSelectedEvents(newEventos);
//			}

//		}, 1500);

//		setTimeout(() => {

//			if (data?.transportes?.length) {

//				let newTransportes: TransportsOnEditAMI[] = [];

//				for (let n = 0, t = data?.transportes?.length; n < t; n++) {
//					let transporte: TransportsOnEditAMI = {
//						id: data.transportes[n].id,
//						localPartidaNome: data.transportes[n].localPartidaNome,
//						localDestinoNome: data.transportes[n].localDestinoNome,
//						localPartidaId: data.transportes[n].localPartidaId,
//						localDestinoId: data.transportes[n].localDestinoId,
//						embalagemDuplaImpermeavel: data.transportes[n].embalagemDuplaImpermeavel,
//						identificacaoSimboloRiscoBiologico: data.transportes[n].identificacaoSimboloRiscoBiologico,
//						responsavelTransporte: data.transportes[n].responsavelTransporte,
//						dataEnvio: data.transportes[n].dataEnvio,
//						qtdTransportada: data.transportes[n].qtdTransportada,
//						unidadeMedidaTransportada: data.transportes[n].unidadeMedidaTransportada,
//						unidadeMedidaTransportadaDescricao: data.transportes[n].unidadeMedidaTransportadaDescricao,
//						materialChegouDestinoPerfeitasCondicoes: data.transportes[n].materialChegouDestinoPerfeitasCondicoes,
//						qtdRecebidaDeAcordoComQtdEnviada: data.transportes[n].qtdRecebidaDeAcordoComQtdEnviada,
//						responsavelRecebimento: data.transportes[n].responsavelRecebimento,
//						dataRecebimento: data.transportes[n].dataRecebimento,
//						qtdRecebida: data.transportes[n].qtdRecebida,
//						unidadeMedidaRecebida: data.transportes[n].unidadeMedidaRecebida,
//						unidadeMedidaRecebidaDescricao: data.transportes[n].unidadeMedidaRecebidaDescricao,

//						contagem: n + 1,

//					};

//					newTransportes.push(transporte);
//				}

//				setSelectedTransports(newTransportes);
//			}

//		}, 1500);
//	}

//	const getAMI = async () => {
//		try {
//			const { data, status } = await api.get<AMIByIdToEdit>(`api/v1/autorizacoes-cibio/MovimentacaoInterna/${location.state.id}`);
//			if (status === 200) {
//				setValuesWithDataResponse(data);
//			}
//		} catch (error: any) {
//			setErrorGetData(error.response.data);
//		}
//		setLoadingGetData(false);
//	}

//	useEffect(() => {
//		const getDataToForm = async () => {
//			try {
//				const locations = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');

//				const events = await api.get<EventResult[]>('api/v1/autorizacoes-cibio/Evento/obterporfiltro?Ativo=true');

//				const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');

//				const material = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/material');

//				const unidadeMedida = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/unidade-medida');

//				const transporte = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/modo-transporte');

//				const destinatarios = await api.get<DestinatarioResult[]>('api/v1/autorizacoes-cibio/Destinatario/obterporfiltro?Ativo=true');

//				if (locations.status === 200) {
//					setLocations(locations.data);
//				}

//				if (events.status === 200) {
//					setEvents(events.data);
//				}

//				if (cultures.status === 200) {
//					setCultures(cultures.data);
//				}

//				if (material.status === 200) {
//					setEnumMaterial(material.data);
//				}

//				if (unidadeMedida.status === 200) {
//					setEnumUnidadeMedida(unidadeMedida.data);
//				}

//				if (transporte.status === 200) {
//					setTransport(transporte.data);
//				}

//				if (destinatarios.status === 200) {
//					setDestinatarios(destinatarios.data);
//				}

//				getAMI();

//			} catch (error: any) {
//				setErrorGetData(error?.response?.data);
//			}
//			setLoadingGetData(false);
//		}

//		getDataToForm();
//	}, []);
//	//#endregion

//	//#region Erros
//	const verifyErrorsOnForm = (): string[] => {
//		let errorsForm: any[] = [];

//		if (!isEmptyObj(errors)) {
//			Object.entries(errors).map(err => errorsForm.push(err[0]));
//		}
//		return errorsForm;
//	}

//	const verifyErrorsOnFormEvent = (): string[] => {
//		let errorsForm: any[] = [];

//		if (!isEmptyObj(errorsEvent)) {
//			Object.entries(errorsEvent).map(err => errorsForm.push(err[0]));
//		}

//		return errorsForm;
//	}

//	const verifyErrorsOnFormTransport = (): string[] => {
//		let errorsForm: any[] = [];

//		if (!isEmptyObj(errorsTransport)) {
//			Object.entries(errorsTransport).map(err => errorsForm.push(err[0]));
//		}

//		return errorsForm;
//	}

//	const verifyErrorsOnFormEditEvent = (): string[] => {
//		let errorsForm: any[] = [];

//		if (!isEmptyObj(errorsEditEvent)) {
//			Object.entries(errorsEditEvent).map(err => errorsForm.push(err[0]));
//		}
//		return errorsForm;
//	}

//	const verifyErrorsOnFormEditEnvio = (): string[] => {
//		let errorsForm: any[] = [];

//		if (!isEmptyObj(errorsEditEnvio)) {
//			Object.entries(errorsEditEnvio).map(err => errorsForm.push(err[0]));
//		}
//		return errorsForm;
//	}

//	const verifyErrorsOnFormEditTransport = (): string[] => {
//		let errorsForm: any[] = [];

//		if (!isEmptyObj(errorsEditTransport)) {
//			Object.entries(errorsEditTransport).map(err => errorsForm.push(err[0]));
//		}

//		return errorsForm;
//	}
//	//#endregion

//	//#region Submits
//	const getValuesToSubmit = () => {
//		return {
//			id: location.state.id,
//			requisitante: getValues('requisitante'),
//			destinatario: getValues('listaDestinatario')?.label,
//			destinatarioId: getValues('listaDestinatario')?.value,
//			localDestinoPossuiCQB: getValues('possuiCQB'),
//			origem: getValues('origem'),
//			objetivoUtilizacao: getValues('objetivoUtilizacao'),
//			observacaoPosAprovacao: getValues('observacaoPosAprovacao') !== undefined ? getValues('observacaoPosAprovacao') : '' ,
//			observacoes: getValues('observacoes'),
//			trechoLPMALocaisMovimentacaoMaterial: getValues('trechoLPMA'),
//			escopoLPMAContemplaMaterial: getValues('escopoLPMA'),
//			dataAprovacao: getValues('dataAprovacao') !== undefined ? getValues('dataAprovacao') : '',
//			embalagemDuplaImpermeavel: 0,
//			identificacaoSimboloRiscoBiologico: 0,
//			responsavelTransporte: '',
//			dataEnvio: '',
//			materialChegouDestinoPerfeitasCondicoes: 0,
//			qtdRecebidaDeAcordoComQtdEnviada: 0,
//			responsavelRecebimento: '',
//			dataRecebimento: '',
//			culturaId: getValues('culturaOrganismo')?.value,
//			modoTransporteId: getValues('modoDeTransporte')?.value,
//			localPartidaId: getValues('localDeEnvio')?.value,
//			localDestinoId: getValues('localDeDestino')?.value,
//			observacoesAnaliseRegulatorio: getValues('observacoesAnaliseRegulatorio'),
//			eventos: expectedEventos(),
//			transportes: expectedTransports()
//		};
//	}

//	const onSubmit = async () => {
//		setErrorSubmit(null);
//		setErrorDraft(null);
//		setErrorRegulatory(null);
//		setErrorAprove(null);
//		setErrorCorrection(null);
//		setErrorComplete(null);

//		setLoadingSubmit(true);

//		try {
//			const valuesToSubmit = getValuesToSubmit();
//			const { status, data } = await api.put('/api/v1/autorizacoes-cibio/MovimentacaoInterna', valuesToSubmit);

//			if (status === 200) {
//				// setValuesWithDataResponse(data);
//				getAMI();
//				window.scrollTo({ top: 0, behavior: 'smooth' });
//				setSuccessSubmit(true);
//			}
//		} catch (error: any) {
//			window.scrollTo({ top: 0, behavior: 'smooth' });
//			setErrorSubmit(error.response.data);
//		}

//		setLoadingSubmit(false);
//	}

//	const onSubmitDraft = async () => {
//		setErrorSubmit(null);
//		setErrorDraft(null);
//		setErrorRegulatory(null);
//		setErrorAprove(null);
//		setErrorCorrection(null);
//		setErrorComplete(null);

//		setLoadingDraft(true);

//		try {
//			const amiToSubmitDraft = getValuesToSubmit();
//			const { status, data } = await api.put('api/v1/autorizacoes-cibio/MovimentacaoInterna/rascunho', amiToSubmitDraft);

//			if (status === 200) {
//				getAMI();
//				setSuccessDraft(true);
//				window.scrollTo({ top: 0, behavior: 'smooth' });
//			}
//		} catch (error: any) {
//			console.log(error);
//			window.scrollTo({ top: 0, behavior: 'smooth' });
//			setErrorDraft(error.response.data);
//		}

//		setLoadingDraft(false);
//	}

//	const onSubmitRegulatory = async () => {
//		setErrorSubmit(null);
//		setErrorDraft(null);
//		setErrorRegulatory(null);
//		setErrorAprove(null);
//		setErrorCorrection(null);
//		setErrorComplete(null);

//		setLoadingRegulatory(true);

//		try {
//			const valuesToSubmit = getValuesToSubmit();
//			const { status, data } = await api.post('api/v1/autorizacoes-cibio/MovimentacaoInterna/enviarregulatorio', valuesToSubmit);

//			if (status === 200) {
//				// setValuesWithDataResponse(data);
//				getAMI();
//				window.scrollTo({ top: 0, behavior: 'smooth' });
//				setSuccessRegulatory(true);
//			}
//		} catch (error: any) {
//			console.log(error);
//			window.scrollTo({ top: 0, behavior: 'smooth' });
//			setErrorRegulatory(error.response.data);
//		}
//		setLoadingRegulatory(false);
//	}

//	const onSubmitEvent = (info: EventListAMI): void => {

//		let unidadeMedidaAutorizada = info.unidadeMedidaAutorizada.label;
//		let unidadeMedidaTransportada = info.unidadeMedidaTransportada?.label;
//		let material = info.material.label;

//		let validacao = true;

//		if (material == "Grão")
//		{
//			if(unidadeMedidaAutorizada !== '')
//				if (unidadeMedidaAutorizada !== "Kg" && unidadeMedidaAutorizada !== "Gramas")
//					validacao = false;

//			if (unidadeMedidaTransportada !== '')
//				if (unidadeMedidaTransportada !== "Kg" && unidadeMedidaTransportada !== "Gramas")
//					validacao = false;
//		}

//		if (validacao) {


//			setSelectedEvents([...selectedEvents, {
//				id: 0,
//				unidadeMedidaAutorizadaDescricao: info.unidadeMedidaAutorizada.label,
//				materialDescricao: info.material.label,
//				unidadeMedidaTransportadaDescricao: info?.unidadeMedidaTransportada?.label,
//				eventoNome: info.evento.label,
//				qtdAutorizada: info.quantidadeAutorizada,
//				qtdTransportada: info.quantidadeTransportada,
//				unidadeMedidaAutorizada: info.unidadeMedidaAutorizada.value,
//				unidMedAutorizadaOutros: info?.unidMedAutorizadaOutros,
//				material: info.material.value,
//				materialOutros: info?.materialOutros,
//				unidadeMedidaTransportada: info?.unidadeMedidaTransportada?.value,
//				unidMedTranspOutros: info?.unidMedTranspOutros,
//				eventoId: info.evento.value
//			}]);

//			setSuccessEvent(true);

//			setModalEvents(false);
//		}
//		else {
//			setSuccessEvent(false);
//		}

//	}

//	const onSubmitEditiEvent = (info: any): void => {

//		let unidadeMedidaAutorizada = info.editUnidadeMedidaAutorizada.label;
//		let unidadeMedidaTransportada = info.editUnidadeMedidaTransportada?.label;
//		let material = info.editMaterial.label;

//		let validacao = true;

//		if (material == "Grão") {
//			if (unidadeMedidaAutorizada !== '')
//				if (unidadeMedidaAutorizada !== "Kg" && unidadeMedidaAutorizada !== "Gramas")
//					validacao = false;

//			if (unidadeMedidaTransportada !== '')
//				if (unidadeMedidaTransportada !== "Kg" && unidadeMedidaTransportada !== "Gramas")
//					validacao = false;
//		}

//		if (validacao) {
//			setSelectedEvents([]);

//			const arr = selectedEvents.filter(e => e.id != info.editid);

//			setSelectedEvents([...arr, {
//				id: info.editid,
//				unidadeMedidaAutorizadaDescricao: info.editUnidadeMedidaAutorizada.label,
//				materialDescricao: info.editMaterial.label,
//				unidadeMedidaTransportadaDescricao: info.editUnidadeMedidaTransportada !== null ? info.editUnidadeMedidaTransportada.label : '',
//				eventoNome: info.editEvento.label,
//				qtdAutorizada: info.editQuantidadeAutorizada,
//				qtdTransportada: info.editQuantidadeTransportada,
//				unidadeMedidaAutorizada: info.editUnidadeMedidaAutorizada.value,
//				unidMedAutorizadaOutros: info?.unidMedAutorizadaOutros,
//				material: info.editMaterial.value,
//				materialOutros: info?.materialOutros,
//				unidadeMedidaTransportada: info.editUnidadeMedidaTransportada !== null ? info.editUnidadeMedidaTransportada.value : 0,
//				unidMedTranspOutros: info?.unidMedTranspOutros,
//				eventoId: info.editEvento.value
//			}]);

//			setSuccessEvent(true);

//			setModalEditEvents(false);
//		}
//		else
//		{
//			setSuccessEvent(false);
//		}
//	}

//	const onSubmitTransport = (info: TransportListAMI): void => {

//		const valuesToSubmit = getValuesToSubmit();
//		let itens = selectedTransports.length + 1;
//		let dataAprovacaoCIBIO = valuesToSubmit.dataAprovacao !== null ? valuesToSubmit.dataAprovacao.setHours(0, 0, 0, 0) : null;
//		let dataEnvioForm = info.dataEnvio !== undefined ? info.dataEnvio.setHours(0, 0, 0, 0) : null;

//		let unidadeMedidaTransportada = info?.unidadeMedidaTransportada?.label;
//		let unidadeMedidaRecebida = info?.unidadeMedidaRecebida?.label;
//		let material = valuesToSubmit.eventos[0]?.materialDescricao;

//		let validacao = true;

//		if (material == "Grão") {
//			if (unidadeMedidaRecebida !== '')
//				if (unidadeMedidaRecebida !== "Kg" && unidadeMedidaRecebida !== "Gramas")
//					validacao = false;

//			if (unidadeMedidaTransportada !== '')
//				if (unidadeMedidaTransportada !== "Kg" && unidadeMedidaTransportada !== "Gramas")
//					validacao = false;
//		}

//		if (validacao) {
//			setSuccessMaterial(true);

//			if (dataEnvioForm >= dataAprovacaoCIBIO) {

//				setSelectedTransports([...selectedTransports, {
//					id: 0,
//					localPartidaId: info.localPartida.value,
//					localDestinoId: info.localDestino.value,
//					localPartidaNome: info.localPartida.label,
//					localDestinoNome: info.localDestino.label,
//					embalagemDuplaImpermeavel: info.embalagemDuplaImpermeavel,
//					identificacaoSimboloRiscoBiologico: info.identificacaoSimboloRiscoBiologico,
//					responsavelTransporte: info.responsavelTransporte,
//					dataEnvio: info.dataEnvio !== undefined ? info.dataEnvio : "",
//					qtdTransportada: info.qtdTransportada !== undefined ? info.qtdTransportada : 0,
//					unidadeMedidaTransportada: info?.unidadeMedidaTransportada?.value,
//					unidadeMedidaTransportadaDescricao: info?.unidadeMedidaTransportada?.label,
//					materialChegouDestinoPerfeitasCondicoes: info.materialChegouDestinoPerfeitasCondicoes !== undefined ? info.materialChegouDestinoPerfeitasCondicoes : 0,
//					qtdRecebidaDeAcordoComQtdEnviada: info.qtdRecebidaDeAcordoComQtdEnviada !== undefined ? info.qtdRecebidaDeAcordoComQtdEnviada : 0,
//					responsavelRecebimento: info.responsavelRecebimento !== undefined ? info.responsavelRecebimento : "",
//					dataRecebimento: info.dataRecebimento !== undefined ? info.dataRecebimento : "",
//					qtdRecebida: info.qtdRecebida !== undefined ? info.qtdRecebida : 0,
//					unidadeMedidaRecebida: info?.unidadeMedidaRecebida?.value,
//					unidadeMedidaRecebidaDescricao: info?.unidadeMedidaRecebida?.label,
//					contagem: itens
//				}]);

//				setModalTransports(false);

//				setSuccessTransport(true);
//			}
//			else {
//				setSuccessTransport(false);
//			}
//		}
//		else
//		{
//			setSuccessMaterial(false);
//		}
//	}

//	const onSubmitEditEnvio = (info: any): void => {

//		const valuesToSubmit = getValuesToSubmit();
//		const arr = selectedTransports.filter(e => e.id != info.editid);
//		let amiOld = selectedTransports.filter(e => e.id == info.editid)[0];
//		let dataAprovacaoCIBIO = valuesToSubmit.dataAprovacao !== null ? valuesToSubmit.dataAprovacao.setHours(0, 0, 0, 0) : null;
//		let dataEnvioForm = info.editdataEnvio !== undefined ? info.editdataEnvio.setHours(0, 0, 0, 0) : null;

//		let unidadeMedidaTransportada = info?.editUnidadeMedidaTransportada?.label;
//		let material = valuesToSubmit.eventos[0]?.materialDescricao;

//		let validacao = true;

//		if (material == "Grão") {
//			if (unidadeMedidaTransportada !== '')
//				if (unidadeMedidaTransportada !== "Kg" && unidadeMedidaTransportada !== "Gramas")
//					validacao = false;
//		}

//		if (validacao) {
//			setSuccessMaterial(true);

//			if (dataEnvioForm >= dataAprovacaoCIBIO) {
//				let transporte: TransportsOnEditAMI = {
//					id: amiOld.id,
//					contagem: amiOld.contagem,
//					localPartidaId: info.editlocalPartida.value,
//					localDestinoId: info.editlocalDestino.value,
//					localPartidaNome: info.editlocalPartida.label,
//					localDestinoNome: info.editlocalDestino.label,
//					embalagemDuplaImpermeavel: info.editembalagemDuplaImpermeavel,
//					identificacaoSimboloRiscoBiologico: info.editidentificacaoSimboloRiscoBiologico,
//					responsavelTransporte: info.editresponsavelTransporte,
//					dataEnvio: info.editdataEnvio !== undefined ? info.editdataEnvio : '',
//					qtdTransportada: info.editqtdTransportada,
//					unidadeMedidaTransportada: info.editUnidadeMedidaTransportada.value,
//					unidadeMedidaTransportadaDescricao: info.editUnidadeMedidaTransportada.label,
//					materialChegouDestinoPerfeitasCondicoes: amiOld.materialChegouDestinoPerfeitasCondicoes,
//					qtdRecebidaDeAcordoComQtdEnviada: amiOld.qtdRecebidaDeAcordoComQtdEnviada,
//					responsavelRecebimento: amiOld.responsavelRecebimento,
//					dataRecebimento: amiOld.dataRecebimento,
//					qtdRecebida: amiOld.qtdRecebida,
//					unidadeMedidaRecebida: amiOld.unidadeMedidaRecebida,
//					unidadeMedidaRecebidaDescricao: amiOld.unidadeMedidaRecebidaDescricao
//				};

//				arr.push(transporte);

//				setSelectedTransports(arr);

//				setModalEditEnvio(false);

//				setSuccessTransport(true);
//			}
//			else {
//				setSuccessTransport(false);
//			}
//		}
//		else {
//			setSuccessMaterial(false);
//		}
//	}

//	const onSubmitEditTransport = (info: any): void => {

//		const valuesToSubmit = getValuesToSubmit();
//		const arr = selectedTransports.filter(e => e.contagem != info.editContagem);
//		let amiOld = selectedTransports.filter(e => e.contagem == info.editContagem)[0];
//		let dataAprovacaoCIBIO = valuesToSubmit.dataAprovacao !== null ? valuesToSubmit.dataAprovacao.setHours(0, 0, 0, 0) : null;
//		let dataRecebimentoForm = info.editdataRecebimento !== "" ? info.editdataRecebimento.setHours(0, 0, 0, 0) : null;

//		let unidadeMedidaRecebida = info?.editunidadeMedidaRecebida?.label;
//		let material = valuesToSubmit.eventos[0]?.materialDescricao;

//		let validacao = true;

//		if (material == "Grão") {
//			if (unidadeMedidaRecebida !== '')
//				if (unidadeMedidaRecebida !== "Kg" && unidadeMedidaRecebida !== "Gramas")
//					validacao = false;
//		}

//		if (validacao) {

//			setSuccessMaterial(true);

//			if (dataRecebimentoForm >= dataAprovacaoCIBIO) {
//				let transporte: TransportsOnEditAMI = {
//					id: amiOld.id,
//					contagem: amiOld.contagem,
//					localPartidaId: amiOld.localPartidaId,
//					localDestinoId: amiOld.localDestinoId,
//					localPartidaNome: amiOld.localPartidaNome,
//					localDestinoNome: amiOld.localDestinoNome,
//					embalagemDuplaImpermeavel: amiOld.embalagemDuplaImpermeavel,
//					identificacaoSimboloRiscoBiologico: amiOld.identificacaoSimboloRiscoBiologico,
//					responsavelTransporte: amiOld.responsavelTransporte,
//					dataEnvio: amiOld.dataEnvio,
//					qtdTransportada: amiOld.qtdTransportada,
//					unidadeMedidaTransportada: amiOld.unidadeMedidaTransportada,
//					unidadeMedidaTransportadaDescricao: amiOld.unidadeMedidaTransportadaDescricao,
//					materialChegouDestinoPerfeitasCondicoes: info.editmaterialChegouDestinoPerfeitasCondicoes !== undefined ?
//						info.editmaterialChegouDestinoPerfeitasCondicoes : 0,
//					qtdRecebidaDeAcordoComQtdEnviada: info.editqtdRecebidaDeAcordoComQtdEnviada !== undefined ?
//						info.editqtdRecebidaDeAcordoComQtdEnviada : 0,
//					responsavelRecebimento: info.editresponsavelRecebimento !== undefined ? info.editresponsavelRecebimento : "",
//					dataRecebimento: info.editdataRecebimento !== undefined ? info.editdataRecebimento : "",
//					qtdRecebida: info.editqtdRecebida !== undefined ? info.editqtdRecebida : 0,
//					unidadeMedidaRecebida: info.editunidadeMedidaRecebida !== undefined ? info.editunidadeMedidaRecebida.value : 0,
//					unidadeMedidaRecebidaDescricao: info.editunidadeMedidaRecebida !== undefined ? info.editunidadeMedidaRecebida.label : ""
//				};

//				arr.push(transporte);

//				setSelectedTransports(arr);

//				setModalEditRecebimento(false);

//				setSuccessTransport(true);
//			}
//			else {
//				setSuccessTransport(false);
//			}
//		}
//		else {
//			setSuccessMaterial(false);
//		}
//	}

//	const aprove = async () => {
//		setLoadingAprove(true);
//		setErrorSubmit(null);
//		setErrorDraft(null);
//		setErrorRegulatory(null);
//		setErrorAprove(null);
//		setErrorCorrection(null);
//		setErrorComplete(null);

//		try {
//			const valuesToSubmit = getValuesToSubmit();
//			const { status, data } = await api.post('api/v1/autorizacoes-cibio/MovimentacaoInterna/aprovar', valuesToSubmit);

//			if (status === 200) {
//				getAMI();
//				setSuccessAprove(true);
//				setTimeout(() => setIsAprove(false), 2900);
//				window.scrollTo({ top: 0, behavior: 'smooth' });
//			}
//		} catch (error: any) {
//			setErrorAprove(error.response.data);
//			setIsAprove(false);
//			window.scrollTo({ top: 0, behavior: 'smooth' });
//		}
//		setLoadingAprove(false);
//	}

//	const requestCorrection = async () => {
//		setLoadingCorrection(true);
//		setErrorSubmit(null);
//		setErrorDraft(null);
//		setErrorRegulatory(null);
//		setErrorAprove(null);
//		setErrorCorrection(null);
//		setErrorComplete(null);

//		try {
//			const valuesToSubmit = getValuesToSubmit();
//			const { status, data } = await api.post('api/v1/autorizacoes-cibio/MovimentacaoInterna/solicitarcorrecao', valuesToSubmit);

//			if (status === 200) {
//				// setValuesWithDataResponse(data);
//				getAMI();
//				setSuccessCorrection(true);
//				setTimeout(() => setIsCorrection(false), 2900);
//				window.scrollTo({ top: 0, behavior: 'smooth' });
//			}
//		} catch (error: any) {
//			setErrorCorrection(error.response.data);
//			setIsCorrection(false);
//			window.scrollTo({ top: 0, behavior: 'smooth' });
//		}
//		setLoadingCorrection(false);
//	}

//	const complete = async () => {
//		setLoadingComplete(true);
//		setErrorSubmit(null);
//		setErrorDraft(null);
//		setErrorRegulatory(null);
//		setErrorAprove(null);
//		setErrorCorrection(null);
//		setErrorComplete(null);

//		setSuccessComplete(false);

//		try {
//			const valuesToSubmit = getValuesToSubmit();
//			const { status, data } = await api.post('/api/v1/autorizacoes-cibio/MovimentacaoInterna/concluir', valuesToSubmit);

//			if (status === 200) {
//				setSuccessComplete(true);
//				setStatusAuthorization(data.status);
//				getAMI();
//				setTimeout(() => setIsComplete(false), 2900);
//				window.scrollTo({ top: 0, behavior: 'smooth' });
//			}
//		} catch (error: any) {
//			setErrorComplete(error.response.data);
//			setIsComplete(false);
//			window.scrollTo({ top: 0, behavior: 'smooth' });
//		}
//		setLoadingComplete(true);
//	}
//	//#endregion

//	const expectedEventos = (): ExpectedEvento[] => {
//		let arrEvent: ExpectedEvento[] = [];

//		for (let count = 0, countLen = selectedEvents.length; count < countLen; count++) {
//			arrEvent.push({
//				Id: selectedEvents[count].id,
//				eventoId: selectedEvents[count].eventoId,
//				eventoNome: selectedEvents[count].eventoNome,
//				qtdAutorizada: selectedEvents[count].qtdAutorizada,
//				qtdTransportada: selectedEvents[count].qtdTransportada !== undefined ? selectedEvents[count].qtdTransportada : 0,
//				unidadeMedidaAutorizada: selectedEvents[count].unidadeMedidaAutorizada,
//				unidadeMedidaAutorizadaDescricao: selectedEvents[count].unidadeMedidaAutorizadaDescricao,
//				unidMedAutorizadaOutros: selectedEvents[count].unidMedAutorizadaOutros,
//				material: selectedEvents[count].material,
//				materialDescricao: selectedEvents[count].materialDescricao,
//				materialOutros: selectedEvents[count].materialOutros,
//				unidadeMedidaTransportada: selectedEvents[count].unidadeMedidaTransportada,
//				unidMedTranspOutros: selectedEvents[count].unidMedTranspOutros,
//				unidadeMedidaTransportadaDescricao: selectedEvents[count].unidadeMedidaTransportadaDescricao
//			})
//		}
//		return arrEvent;
//	}

//	const expectedTransports = (): ExpectedTransporte[] => {
//		let arrTransport: ExpectedTransporte[] = [];

//		for (let count = 0, countLen = selectedTransports.length; count < countLen; count++) {
//			arrTransport.push({
//				Id: selectedTransports[count].id,
//				localPartidaId: selectedTransports[count].localPartidaId,
//				localDestinoId: selectedTransports[count].localDestinoId,
//				localPartidaNome: selectedTransports[count].localPartidaNome,
//				localDestinoNome: selectedTransports[count].localDestinoNome,
//				embalagemDuplaImpermeavel: selectedTransports[count].embalagemDuplaImpermeavel,
//				identificacaoSimboloRiscoBiologico: selectedTransports[count].identificacaoSimboloRiscoBiologico,
//				responsavelTransporte: selectedTransports[count].responsavelTransporte,
//				dataEnvio: selectedTransports[count].dataEnvio !== undefined ? selectedTransports[count].dataEnvio : '',
//				qtdTransportada: selectedTransports[count].qtdTransportada,
//				unidadeMedidaTransportada: selectedTransports[count].unidadeMedidaTransportada,
//				materialChegouDestinoPerfeitasCondicoes: selectedTransports[count].materialChegouDestinoPerfeitasCondicoes,
//				qtdRecebidaDeAcordoComQtdEnviada: selectedTransports[count].qtdRecebidaDeAcordoComQtdEnviada,
//				responsavelRecebimento: selectedTransports[count].responsavelRecebimento,
//				dataRecebimento: selectedTransports[count].dataRecebimento !== undefined ? selectedTransports[count].dataRecebimento : '',
//				qtdRecebida: selectedTransports[count].qtdRecebida,
//				unidadeMedidaRecebida: selectedTransports[count].unidadeMedidaRecebida,
//				contagem: selectedTransports[count].contagem
//			})
//		}
//		return arrTransport;
//	}

//	const actionsEvents = (props: any) => (
//		<Fragment>
//			<EditButton onClick={() => {
//				var unidadeAutorizada = enumUnidadeMedida.find(x => x.key == props.data.unidadeMedidaAutorizada);
//				var unidadeTransportada = enumUnidadeMedida.find(x => x.key == props.data.unidadeMedidaTransportada);

//				setEventToEdit({
//					id: props.data.id,
//					evento: {
//						label: props.data.eventoNome,
//						value: props.data.eventoId,
//					},
//					quantidadeAutorizada: props.data.qtdAutorizada,
//					quantidadeTransportada: props.data.qtdTransportada,
//					unidadeMedidaAutorizada: unidadeAutorizada ? { value: unidadeAutorizada.key, label: unidadeAutorizada.value } : { value: 0, label: '' },
//					unidadeMedidaTransportada: unidadeTransportada ? { value: unidadeTransportada.key, label: unidadeTransportada.value } : { value: 0, label: '' },
//					material: {
//						label: props.data.materialDescricao,
//						value: props.data.material
//					},
//					materialOutros: props.data.materialOutros,
//					unidMedAutorizadaOutros: props.data.unidMedAutorizadaOutros,
//					unidMedTranspOutros: props.data.unidMedTranspOutros,
//				});

//				setGridProps(props.node);

//				setModalEditEvents(true)
//			}} />
//			&nbsp;&nbsp;
//			<DeleteButton onClick={() => {
//				setSelectedEvents([]);
//				let arr: any = [];
//				const { node } = props;

//				node.gridApi.updateRowData({ remove: [node.data] })

//				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
//				setSelectedEvents([...arr]);
//			}} />
//		</Fragment>
//	)

//	const actionsEnvio = (props: any) => (
//		<Fragment>
//			<EditButton onClick={() => {
//				setSuccessMaterial(true);
//				setSuccessTransport(true);

//				setTransportToEdit({
//					id: props.data.id,
//					contagem: props.data.contagem,
//					localPartida: { label: props.data.localPartidaNome, value: props.data.localPartidaId },
//					localDestino: { label: props.data.localDestinoNome, value: props.data.localDestinoId },
//					embalagemDuplaImpermeavel: parseInt(props.data.embalagemDuplaImpermeavel, 10),
//					identificacaoSimboloRiscoBiologico: parseInt(props.data.identificacaoSimboloRiscoBiologico, 10),
//					responsavelTransporte: props.data.responsavelTransporte,
//					dataEnvio: props.data.dataEnvio ? moment(props.data.dataEnvio).toDate() : '',
//					qtdTransportada: props.data.qtdTransportada ? props.data.qtdTransportada : 0,
//					unidadeMedidaTransportada: { label: props.data.unidadeMedidaTransportadaDescricao, value: props.data.unidadeMedidaTransportada },
//					materialChegouDestinoPerfeitasCondicoes: props.data.materialChegouDestinoPerfeitasCondicoes,
//					qtdRecebidaDeAcordoComQtdEnviada: props.data.qtdRecebidaDeAcordoComQtdEnviada,
//					responsavelRecebimento: props.data.reponsavelRecebimento,
//					dataRecebimento: props.data.dataRecebimento ? props.data.dataRecebimento : '',
//					qtdRecebida: props.data.qtdRecebida ? props.data.qtdRecebida : 0,
//					unidadeMedidaRecebida: { label: props.data.unidadeMedidaRecebidaDescricao, value: props.data.unidadeMedidaRecebida }
//				});

//				setGridProps(props.node);

//				setModalEditEnvio(true)
//			}} />
//			&nbsp;&nbsp;
//			<DeleteButton onClick={() => {
//				setSelectedTransports([]);
//				let arr: any = [];
//				const { node } = props;

//				node.gridApi.updateRowData({ remove: [node.data] })

//				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
//				setSelectedTransports([...arr]);
//			}} />
//		</Fragment>
//	)

//	const actionsTransports = (props: any) => (
//		<Fragment>
//			<EditButton onClick={() => {
//				setSuccessMaterial(true);
//				setSuccessTransport(true);

//				setTransportToEdit({
//					id: props.data.id,
//					contagem: props.data.contagem,
//					localPartida: { label: props.data.localPartidaNome, value: props.data.localPartidaId },
//					localDestino: { label: props.data.localDestinoNome, value: props.data.localDestinoId },
//					embalagemDuplaImpermeavel: props.data.embalagemDuplaImpermeavel,
//					identificacaoSimboloRiscoBiologico: props.data.identificacaoSimboloRiscoBiologico,
//					responsavelTransporte: props.data.responsavelTransporte,
//					dataEnvio: props.data.dataEnvio ? moment(props.data.dataEnvio).toDate() : '',
//					qtdTransportada: props.data.qtdTransportada ? props.data.qtdTransportada : 0,
//					unidadeMedidaTransportada: { label: props.data.unidadeMedidaTransportadaDescricao, value: props.data.unidadeMedidaTransportada },
//					materialChegouDestinoPerfeitasCondicoes: props.data.materialChegouDestinoPerfeitasCondicoes,
//					qtdRecebidaDeAcordoComQtdEnviada: props.data.qtdRecebidaDeAcordoComQtdEnviada,
//					responsavelRecebimento: props.data.responsavelRecebimento,
//					dataRecebimento: props.data.dataRecebimento ? moment(props.data.dataRecebimento).toDate() : '',
//					qtdRecebida: props.data.qtdRecebida ? props.data.qtdRecebida : 0,
//					unidadeMedidaRecebida: { label: props.data.unidadeMedidaRecebidaDescricao, value: props.data.unidadeMedidaRecebida },
//				});

//				setGridProps(props.node);

//				setModalEditRecebimento(true)
//			}} />
//			&nbsp;&nbsp;
//			<DeleteButton onClick={() => {
//				setSelectedTransports([]);
//				let arr: any = [];
//				const { node } = props;

//				node.gridApi.updateRowData({ remove: [node.data] })

//				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
//				setSelectedTransports([...arr]);
//			}} />
//		</Fragment>
//	)

//	const can_edit_values = (itemGroup?: string) => {

//		if (location?.state?.readonly === true)
//			return false;

//		if (isAdmin(decoded.roles)) {
//			if (itemGroup != 'aprovado')
//				return true;
//			else
//				return false;
//		}

//		if (statusAuthorization === STATUS_CIBIO.Cancelado)
//			return false;

//		if (statusAuthorization === STATUS_CIBIO.Concluido)
//			return false;

//		let isRequisitante: boolean = requisitanteAMI === decoded.unique_name;
//		let isRequester: boolean = isRequesterReader(decoded.roles) || isRequesterWriter(decoded.roles);
//		let isRegulador: boolean = isReguladorReader(decoded.roles) || isReguladorWriter(decoded.roles);

//		if (statusAuthorization === STATUS_CIBIO.EmPreenchimento || statusAuthorization === STATUS_CIBIO.EmCorrecao || statusAuthorization === STATUS_CIBIO.CorrecaoSolicitada) {
//			if (!isRequester)
//				return false;

//			if (itemGroup != 'basic' && itemGroup != 'projeto' && itemGroup != 'plantio')
//				return false;
//		}

//		if (statusAuthorization === STATUS_CIBIO.EmAnalisePeloRegulatorio) {
//			if (!isRegulador)
//				return false;

//			if (itemGroup != 'aprovacao')
//				return false;
//		}

//		if (statusAuthorization === STATUS_CIBIO.AprovadopeloRegulatorio) {
//			if (!isRequisitante)
//				return false;

//			if (!isRequester)
//				return false;

//			if (itemGroup != 'basic' && itemGroup != 'aprovado')
//				return false;
//		}

//		return true;
//	}

	return <div className="container container-fluid">
		<h3>{STRINGS_CADERNOCONFORMIDADE.REGISTER_CADERNO_CONFORMIDADE_VEGETACAO}</h3>
	</div>

//		<If condition={verifyErrorsOnForm()}>
//			{verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
//		</If>
//		<If condition={eventMsg}>
//			<AlertError message={eventMsg} />
//		</If>
//		<If condition={successDraft && !errorDraft}>
//			<AlertSuccess message={STRINGS.SUCCESS_DRAFT} />
//		</If>
//		<If condition={!successDraft && errorDraft}>
//			{errorDraft !== null && errorDraft.message.length > 0 ? errorDraft.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
//		</If>
//		<If condition={verifyErrorsOnForm()}>
//			{verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
//		</If>
//		<If condition={!successAprove && errorAprove}>
//			{errorAprove !== null && errorAprove?.message?.length > 0 ? errorAprove.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
//		</If>
//		<If condition={!successCorrection && errorCorrection}>
//			{errorCorrection !== null && errorCorrection?.message?.length > 0 ? errorCorrection.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
//		</If>
//		<If condition={eventMsg}>
//			<AlertError message={eventMsg} />
//		</If>
//		<If condition={successRegulatory && !errorRegulatory}>
//			<AlertSuccess
//				message={STRINGS.REGULATORY_SUCCESS}
//			/>
//		</If>
//		<If condition={!successRegulatory && errorRegulatory !== null}>
//			{errorRegulatory !== null && errorRegulatory?.message?.length > 0 ? errorRegulatory.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
//		</If>
//		<If condition={successSubmit && !errorSubmit}>
//			<AlertSuccess
//				message={STRINGS.AMI_SUBMIT_SUCCESS}
//			/>
//		</If>
//		<If condition={!successSubmit && errorSubmit !== null}>
//			{errorSubmit !== null && errorSubmit?.message?.length > 0 ? errorSubmit.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
//		</If>
//		<If condition={!successComplete && errorComplete !== null}>
//			{errorComplete !== null && errorComplete?.message?.length > 0 ? errorComplete.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
//		</If>
//		<If condition={loadingGetData}>
//			<span className="loading-page">
//				<Loading />
//			</span>
//		</If>
//		<If condition={!loadingGetData}>
//			<h3>{STRINGS_CIBIO.REGISTER_AMI}</h3>
//			<Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
//				<Fieldset disabled={!can_edit_values('basic') } title={STRINGS_CIBIO.AMI_BLOCK_ONE}>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Solicitante/Requisitante<em>*</em>
//							</Form.Label>
//							<Form.Control
//								ref={register}
//								readOnly
//								name="requisitante"
//								maxLength={100}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Local Envio/Partida<em>*</em>
//								&nbsp;&nbsp;
//								<If condition={loadingGetData}>
//									<LoadingInForm />
//								</If>
//							</Form.Label>
//							<Controller
//								control={control}
//								name="localDeEnvio"
//								isInvalid={errors.localDeEnvio !== undefined}
//								as={<Select
//									placeholder="Selecione um local"
//									isDisabled={!can_edit_values('basic') }
//									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
//									isClearable
//								/>}
//							/>
//							<If condition={errors.localDeEnvio}>
//								<p className="error-field">{errors.localDeEnvio?.message}</p>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Destinatário<em>*</em>
//								&nbsp;&nbsp;
//								<If condition={loadingGetData}>
//									<LoadingInForm />
//								</If>
//							</Form.Label>
//							<Controller
//								control={control}
//								name="listaDestinatario"
//								isInvalid={errors.listaDestinatario !== undefined}
//								as={<Select
//									placeholder="Selecione um destinatário"
//									isDisabled={!can_edit_values('basic')}
//									options={destinatarios.map(local => Object.assign({ value: local.id, label: local.nome }))}
//									isClearable
//								/>}
//							/>
//							<If condition={errors.listaDestinatario}>
//								<p className="error-field">{errors.listaDestinatario?.message}</p>
//							</If>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Local Destino<em>*</em>
//								&nbsp;&nbsp;
//								<If condition={loadingGetData}>
//									<LoadingInForm />
//								</If>
//							</Form.Label>
//							<Controller
//								control={control}
//								name="localDeDestino"
//								isInvalid={errors.localDeDestino !== undefined}
//								as={<Select
//									placeholder="Selecione um local"
//									isDisabled={!can_edit_values('basic')}
//									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
//									isClearable
//								/>}
//							/>
//							<If condition={errors.localDeDestino}>
//								<span className="error-field">{errors.localDeDestino?.message}</span>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<p />
//					<Form.Row className="row-custom">
//						&nbsp;&nbsp;
//						<Form.Label>
//							O local de destino possui CQB?<em>*</em>
//						</Form.Label>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={register}
//							name="possuiCQB"
//							type="radio"
//							aria-label="radio 1"
//							label="Sim"
//							value={2}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={register}
//							name="possuiCQB"
//							type="radio"
//							aria-label="radio 1"
//							label="Não"
//							value={1}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={register}
//							name="possuiCQB"
//							type="radio"
//							aria-label="radio 1"
//							label="Não se aplica"
//							value={3}
//						/>
//					</Form.Row>
//					<p />
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Modo de transporte<em>*</em>
//							</Form.Label>
//							<Controller
//								control={control}
//								name="modoDeTransporte"
//								isInvalid={errors.modoDeTransporte}
//								as={<Select
//									placeholder="Selecione um modo de transporte"
//									isDisabled={!can_edit_values('basic')}
//									options={transport.map(transp => Object.assign({ label: transp.value, value: transp.key }))}
//									isClearable
//								/>}
//							/>
//							<If condition={errors.modoDeTransporte}>
//								<p className="error-field">{errors.modoDeTransporte?.message}</p>
//							</If>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Origem<em>*</em>
//							</Form.Label>
//							<Form.Control
//								ref={register}
//								name="origem"
//								placeholder="Insira a origem"
//								isInvalid={errors.origem !== undefined}
//								maxLength={150}
//							/>
//							<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_ORIGEM_HELP_TEXT}</Form.Text>
//							<Form.Control.Feedback type="invalid">
//								{errors.origem?.message}
//							</Form.Control.Feedback>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Objetivo da utilização<em>*</em>
//							</Form.Label>
//							<Form.Control
//								ref={register}
//								name="objetivoUtilizacao"
//								as="textarea"
//								rows={4}
//								placeholder="Insira o objetivo da utilização"
//								isInvalid={errors.objetivoUtilizacao !== undefined}
//							/>
//							<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_OBJETIVO_HELP_TEXT}</Form.Text>
//							<Form.Control.Feedback type="invalid">
//								{errors.objetivoUtilizacao?.message}
//							</Form.Control.Feedback>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Evento<em>*</em>{`  `}
//								<AddButton
//									type="button"
//									onClick={() => setModalEvents(true)}
//								/>
//							</Form.Label>
//							<If condition={eventMsg}>
//								<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<If condition={selectedEvents.length}>
//						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
//							<AgGridReact
//								rowData={selectedEvents}
//								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
//								gridOptions={{ headerHeight: 80 }}
//								stopEditingWhenGridLosesFocus={true}
//								singleClickEdit={true}
//								frameworkComponents={{
//									actions: actionsEvents,
//								}}
//							>
//								<AgGridColumn
//									headerName='Evento'
//									field="eventoNome"
//									cellStyle={{
//										justifyContent: 'left'
//									}}
//									autoHeight
//									wrapText
//									minWidth={250}
//								/>
//								<AgGridColumn
//									headerName='Quantidade Autorizada'
//									field="qtdAutorizada"
//									headerClass="myfunc-default-header"
//									valueFormatter={({ data }: any) => { return DecimalAsComma(data.qtdAutorizada); }}
//								/>
//								<AgGridColumn
//									headerName='Unidade Medida Autorizada'
//									headerClass="myfunc-default-header"
//									field="unidadeMedidaAutorizadaDescricao"
//									valueFormatter={({ data }: any) => {

//										if (data?.unidadeMedidaAutorizada == 4)
//											return `Outros (${data.unidMedAutorizadaOutros})`;

//										return data?.unidadeMedidaAutorizada?.label;

//									}}
//								/>
//								<AgGridColumn
//									headerName='Quantidade Transportada'
//									field="qtdTransportada"
//									headerClass="myfunc-default-header"
//									valueFormatter={({ data }: any) => { return DecimalAsComma(data.qtdTransportada); }}
//								/>
//								<AgGridColumn
//									headerName='Unidade Medida Transportada'
//									field="unidadeMedidaTransportadaDescricao"
//									headerClass="myfunc-default-header"
//									valueFormatter={({ data }: any) => {

//										if (data?.unidadeMedidaTransportada == 4)
//											return `Outros (${data.unidMedTranspOutros})`;

//										return data?.unidadeMedidaTransportada?.label;

//									}}
//								/>
//								<AgGridColumn
//									headerName='Material'
//									field="materialDescricao"
//									headerClass="myfunc-default-header"
//									valueFormatter={({ data }: any) => {

//										if (data?.material == 7)
//											return `Outros (${data.materialOutros})`;

//										return data.materialDescricao;

//									}}
//								/>
//								<AgGridColumn
//									headerName="Ações"
//									cellRenderer="actions"
//									maxWidth={100}
//								/>
//							</AgGridReact>
//						</div>
//					</If>
//					<br />
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Cultura/Organismo<em>*</em>
//							</Form.Label>
//							&nbsp;&nbsp;
//							<If condition={loadingGetData}>
//								<LoadingInForm />
//							</If>
//							<Controller
//								control={control}
//								name="culturaOrganismo"
//								isInvalid={errors.culturaOrganismo}
//								as={<Select
//									isDisabled={!can_edit_values('basic')}
//									options={cultures.map(culture => Object.assign({ label: culture.nome, value: culture.id }))}
//									placeholder="Selecione uma cultura/organismo"
//									isClearable
//								/>}
//							/>
//							<If condition={errors.culturaOrganismo}>
//								<p className="error-field">{errors.culturaOrganismo?.message}</p>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Observações
//							</Form.Label>
//							<Form.Control
//								ref={register}
//								name="observacoes"
//								as="textarea"
//								rows={5}
//								placeholder="Insira as observações necessárias"
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Transcrever o trecho da LPMA em que constam os locais contemplados para movimentação de material<em>*</em>
//							</Form.Label>
//							<Form.Control
//								ref={register}
//								name="trechoLPMA"
//								as="textarea"
//								rows={4}
//								placeholder="Insira o trecho LPMA em que constam os locais contemplados para movimentação de material"
//								isInvalid={errors.trechoLPMA !== undefined}
//							/>
//							<If condition={errors.trechoLPMA}>
//								<Form.Control.Feedback type="invalid">
//									{errors.trechoLPMA?.message}
//								</Form.Control.Feedback>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col} md={12}>
//							<Form.Label>
//								O escopo da LPMA, CQB ou Permissão de Importação contempla a referida atividade com material/cultura objeto da solicitação?<em>*</em>
//							</Form.Label>
//							<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_ESCOPO_LPMA_HELP_TEXT}</Form.Text>
//						</Form.Group>
//						<br />
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={register}
//							name="escopoLPMA"
//							type="radio"
//							label="Não"
//							value={1}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={register}
//							name="escopoLPMA"
//							type="radio"
//							label="Sim"
//							value={2}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={register}
//							name="escopoLPMA"
//							type="radio"
//							label="Não se aplica"
//							value={3}
//						/>
//						<br />
//						<If condition={errors.escopoLPMA}>
//							<p className="error-field">{errors.escopoLPMA?.message}</p>
//						</If>
//					</Form.Row>
//				</Fieldset>

//				<Fieldset disabled={!can_edit_values('aprovado')} title="">
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Observações Pós Aprovação
//							</Form.Label>
//							<Form.Control
//								ref={register}
//								name="observacaoPosAprovacao"
//								as="textarea"
//								rows={5}
//								placeholder="Insira as observações necessárias"
//							/>
//						</Form.Group>
//					</Form.Row>
//				</Fieldset>

//				<Fieldset disabled={!can_edit_values('aprovacao')} title={STRINGS_CIBIO.AMI_BLOCK_TWO}>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col} md={6}>
//							<Form.Label>
//								Nº Autorização CIBio
//							</Form.Label>
//							<Form.Control
//								readOnly
//								ref={register}
//								name="numeroAutorizacaoCibio"
//								placeholder="Nº autorização CIBio"
//								maxLength={20}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Data Emissão CIBio
//							</Form.Label>
//							<br />
//							<Controller
//								control={control}
//								name="dataEmissaoCibio"
//								render={({ onChange, value }) => (
//									<DatePicker
//										disabled
//										selected={value}
//										onChange={onChange}
//										className="input-custom-to-date"
//										locale="pt-BR"
//										dateFormat="dd/MM/yyyy"
//										placeholderText="Selecionar a data"
//										maxDate={new Date()}
//									/>
//								)}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Data da Aprovação
//							</Form.Label>
//							<br />
//							<Controller
//								control={control}
//								name="dataAprovacao"
//								render={({ onChange, value }) => (
//									<DatePicker
//										disabled
//										selected={value}
//										onChange={onChange}
//										className="input-custom-to-date"
//										locale="pt-BR"
//										dateFormat="dd/MM/yyyy"
//										placeholderText="Selecionar a data"
//										maxDate={new Date()}
//									/>
//								)}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Observações da Análise do Regulatório
//							</Form.Label>
//							<Form.Control
//								ref={register}
//								name="observacoesAnaliseRegulatorio"
//								as="textarea"
//								rows={5}
//								placeholder="Observações da análise do regulatório"
//							/>
//						</Form.Group>
//					</Form.Row>
//				</Fieldset>

//				<Fieldset title={STRINGS_CIBIO.AMI_BLOCK_THREE}>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								<b>Não esqueça de inserir informações do envio, principalmente as informações de embalagem dupla impermeável e de identificação de risco biológico!</b>
//							</Form.Label>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Informações do Envio<em>*</em>{`  `}
//								<AddButton
//									type="button"
//									onClick={() => setModalTransports(true)}
//								/>
//							</Form.Label>
//							<If condition={transportMsg}>
//								<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
//						<AgGridReact
//							rowData={selectedTransports}
//							defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
//							gridOptions={{ headerHeight: 80 }}
//							stopEditingWhenGridLosesFocus={true}
//							singleClickEdit={true}
//							frameworkComponents={{
//								actions: actionsEnvio,
//							}}
//						>
//							<AgGridColumn
//								headerName='Responsável pelo Transporte'
//								valueGetter={({ data }: any) => data.responsavelTransporte}
//								cellStyle={{
//									justifyContent: 'left'
//								}}
//								autoHeight
//								wrapText
//								minWidth={250}
//							/>
//							<AgGridColumn
//								headerName='Data do Envio'
//								field="dataEnvio"
//								headerClass="myfunc-default-header"
//								valueFormatter={({ data }: any) => data.dataEnvio ? _generateDate(data.dataEnvio, 'Pt') : ''}

//							/>
//							<AgGridColumn
//								headerName='Local de Recebimento'
//								valueGetter={({ data }: any) => data.localDestinoNome}
//								cellStyle={{
//									justifyContent: 'left'
//								}}

//							/>
//							<AgGridColumn
//								headerName='Quantidade Transportada'
//								field="qtdTransportada"
//								type="number"
//								headerClass="myfunc-default-header"
//								valueFormatter={({ data }: any) => { return DecimalAsComma(data.qtdTransportada); }}
//							/>
//							<AgGridColumn
//								headerName='Unidade Medida Transportada'
//								field="unidadeMedidaTransportadaDescricao"
//								headerClass="myfunc-default-header"
//								valueFormatter={({ data }: any) => {

//									if (data?.unidadeMedidaTransportada?.label == 'Outros')
//										return `Outros (${data.unidMedTranspOutros})`;

//									return data?.unidadeMedidaTransportada?.label;

//								}}
//							/>
//							<AgGridColumn
//								headerName="Ações"
//								cellRenderer="actions"
//								maxWidth={100}
//							/>
//						</AgGridReact>
//					</div>
//				</Fieldset>

//				<Fieldset title={STRINGS_CIBIO.AMI_BLOCK_FOUR}>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Informações do Recebimento<em>*</em>{`  `}
//							</Form.Label>
//							<If condition={transportMsg}>
//								<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
//						<AgGridReact
//							rowData={selectedTransports}
//							defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
//							gridOptions={{ headerHeight: 80 }}
//							stopEditingWhenGridLosesFocus={true}
//							singleClickEdit={true}
//							frameworkComponents={{
//								actions: actionsTransports,
//							}}
//						>
//							<AgGridColumn
//								headerName='Responsável pelo Transporte'
//								valueGetter={({ data }: any) => data.responsavelTransporte}
//								cellStyle={{
//									justifyContent: 'left'
//								}}
//								autoHeight
//								wrapText
//								minWidth={250}
//							/>
//							<AgGridColumn
//								headerName='Data do Envio'
//								field="dataEnvio"
//								headerClass="myfunc-default-header"
//								valueFormatter={({ data }: any) => data.dataEnvio ? _generateDate(data.dataEnvio, 'Pt') : ''}

//							/>
//							<AgGridColumn
//								headerName='Local de Envio'
//								valueGetter={({ data }: any) => data.localPartidaNome}
//								cellStyle={{
//									justifyContent: 'left'
//								}}

//							/>
//							<AgGridColumn
//								headerName='Responsável pelo Recebimento'
//								valueGetter={({ data }: any) => data.responsavelRecebimento}
//								cellStyle={{
//									justifyContent: 'left'
//								}}
//								autoHeight
//								wrapText
//								minWidth={250}
//							/>
//							<AgGridColumn
//								headerName='Data do Recebimento'
//								field="dataRecebimento"
//								headerClass="myfunc-default-header"
//								valueFormatter={({ data }: any) => data.dataRecebimento ? _generateDate(data.dataRecebimento, 'Pt') : ''}

//							/>
//							<AgGridColumn
//								headerName='Quantidade Recebida'
//								field="qtdRecebida"
//								headerClass="myfunc-default-header"
//								type="number"
//								valueFormatter={({ data }: any) => { return DecimalAsComma(data.qtdRecebida); }}
//							/>
//							<AgGridColumn
//								headerName="Ações"
//								cellRenderer="actions"
//								maxWidth={100}
//							/>
//						</AgGridReact>
//					</div>
//				</Fieldset>

//				<div className="button-position">
//					<Button
//						title="Voltar"
//						contained={false}
//						type="button"
//						onClick={() => {
//							isDirty ? setIsConfirmation(true) : history.push('/autorizacoes/consultar/ami');
//						}}
//					/>

//					<If condition={
//						(statusAuthorization !== STATUS_CIBIO.EmPreenchimento && isAdmin(decoded.roles))
//						||
//						(statusAuthorization === STATUS_CIBIO.CorrecaoSolicitada && (isRequesterReader(decoded.roles) || isRequesterWriter(decoded.roles)))
//						||
//						(statusAuthorization === STATUS_CIBIO.AprovadopeloRegulatorio && (isRequesterReader(decoded.roles) || isRequesterWriter(decoded.roles)))
//					}>
//						<Button
//							title="Salvar"
//							contained
//							type="button"
//							onClick={() => trigger().then(validated => {
//								if (validated && selectedEvents?.length) {
//									onSubmitDraft();
//									setEventsMsg('');
//								} else if (validated && !selectedEvents?.length) {
//									setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
//									window.scrollTo({ top: 0, behavior: 'smooth' });
//								} else {
//									setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
//								}
//							})}
//							isLoading={loadingSubmit}
//						/>
//					</If>

//					<If condition={statusAuthorization === STATUS_CIBIO.EmAnalisePeloRegulatorio && (isReguladorWriter(decoded.roles) || isAdmin(decoded.roles))}>
//						<GenericButton
//							title="Solicitar correção"
//							variant="btn-danger"
//							type="button"
//							loading={false}
//							onClick={() => setIsCorrection(true)}
//						/>
//					</If>

//					<If condition={statusAuthorization === STATUS_CIBIO.EmAnalisePeloRegulatorio && (isAdmin(decoded.roles) || isReguladorWriter(decoded.roles) || isReguladorReader(decoded.roles))}>
//						<GenericButton
//							title="Aprovar"
//							variant="btn-success"
//							type="button"
//							loading={false}
//							onClick={() => trigger().then(validated => {
//								if (validated) {
//									setIsAprove(validated);
//								} else {
//									window.scrollTo({ top: 0, behavior: 'smooth' });
//								}
//							})}
//						/>
//					</If>

//					<If condition={statusAuthorization === STATUS_CIBIO.AprovadopeloRegulatorio && (isAdmin(decoded.roles) || isRequesterWriter(decoded.roles))}>
//						<GenericButton
//							title="Concluir"
//							variant="btn-info"
//							type="button"
//							loading={false}
//							onClick={() => setIsComplete(true)}
//						/>
//					</If>

//					<If condition={(statusAuthorization === STATUS_CIBIO.EmPreenchimento || statusAuthorization === STATUS_CIBIO.CorrecaoSolicitada || statusAuthorization === STATUS_CIBIO.EmCorrecao) && (isRequesterWriter(decoded.roles) || isAdmin(decoded.roles))}>
//						<GenericButton
//							title="Salvar e enviar para regulatório"
//							variant="btn-warning"
//							type="button"
//							loading={loadingRegulatory}
//							onClick={() => trigger().then(validated => {
//								if (validated) {
//									setIsRegulatory(validated);
//								} else {
//									window.scrollTo({ top: 0, behavior: 'smooth' });
//								}
//							})}
//						/>
//					</If>

//					<If condition={statusAuthorization === STATUS_CIBIO.EmPreenchimento}>
//						<Button
//							title="Salvar rascunho"
//							contained={true}
//							type="button"
//							onClick={() => onSubmitDraft()}
//							isLoading={loadingDraft}
//						/>
//					</If>
//				</div>

//				<If condition={isRegulatory}>
//					<ConfirmationModal
//						show={isRegulatory}
//						onHide={() => {
//							setIsRegulatory(false);
//						}}
//						title={STRINGS.REGULATORY_TITLE}
//						confirmationMsg={STRINGS.REGULATORY_ACTION}
//						onConfirm={() => {
//							onSubmitRegulatory();
//							setIsRegulatory(false);
//						}}
//						variant="success"
//						color="white"
//					>
//						<p>{STRINGS.REGULATORY_MESSAGE}</p>
//					</ConfirmationModal>
//				</If>
//			</Form>
//		</If>
		
//		<If condition={modalEvents}>
//			<Modal
//				show={true}
//				onHide={() => setModalEvents(false)}
//				title={STRINGS_CIBIO.AMI_EVENT_MODAL_TITLE}
//			>
//				<If condition={verifyErrorsOnFormEvent()}>
//					{verifyErrorsOnFormEvent().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
//				</If>
//				<If condition={!successEvent}>
//					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
//				</If>
//				<Form onSubmit={handleSubmitEvent(onSubmitEvent)} autoComplete="off">
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Evento<em>*</em>
//							</Form.Label>
//							<Controller
//								control={controlEvent}
//								name="evento"
//								isInvalid={errorsEvent.evento !== undefined}
//								as={
//									<Select
//										placeholder="Selecione um evento"
//										options={events.map(event => Object.assign({ value: event.id, label: event.nome }))}
//										isClearable
//									/>
//								}
//							/>
//							<If condition={errorsEvent.evento}>
//								<p className="error-field">{errorsEvent.evento?.message}</p>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Quantidade Autorizada<em>*</em>
//							</Form.Label>
//							<Form.Control
//								ref={registerEvent}
//								name="quantidadeAutorizada"
//								placeholder="Insira a quantidade autorizada"
//								maxLength={5}
//								type="number"
//								step="any"
//								isInvalid={errorsEvent.quantidadeAutorizada !== undefined}
//							/>
//							<Form.Control.Feedback type="invalid">
//								{errorsEvent.quantidadeAutorizada?.message}
//							</Form.Control.Feedback>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Unidade de Medida Autorizada<em>*</em>
//							</Form.Label>
//							<Controller
//								control={controlEvent}
//								name="unidadeMedidaAutorizada"
//								as={<Select
//									placeholder="Selecione uma unidade de medida"
//									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
//									isClearable
//								/>}
//							/>
//							<If condition={errorsEvent.unidadeMedidaAutorizada}>
//								<span className="error-field">{errorsEvent.unidadeMedidaAutorizada?.message}</span>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<If condition={!isEmptyObj(watchEvent())}>
//						<If condition={watchEvent().unidadeMedidaAutorizada?.value === 4}>
//							<Form.Row className="row-custom">
//								<Form.Group as={Col} />
//								<Form.Group as={Col}>
//									<Form.Label>
//										Especifique a unidade de medida autorizada<em>*</em>
//									</Form.Label>
//									<Form.Control
//										ref={registerEvent()}
//										required
//										name="unidMedAutorizadaOutros"
//										placeholder="Insira a unidade de medida autorizada"
//									/>
//									<span className="error-field">
//										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
//									</span>
//								</Form.Group>
//							</Form.Row>
//						</If>
//					</If>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Quantidade Transportada
//							</Form.Label>
//							<Form.Control
//								ref={registerEvent}
//								name="quantidadeTransportada"
//								placeholder="Insira a quantidade transportada"
//								maxLength={5}
//								type="number"
//								step="any"
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Unidade de Medida Transportada
//							</Form.Label>
//							<Controller
//								control={controlEvent}
//								name="unidadeMedidaTransportada"
//								as={<Select
//									placeholder="Selecione uma unidade de medida"
//									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
//									isClearable
//								/>}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<If condition={!isEmptyObj(watchEvent())}>
//						<If condition={watchEvent().unidadeMedidaTransportada?.value === 4}>
//							<Form.Row className="row-custom">
//								<Form.Group as={Col} />
//								<Form.Group as={Col}>
//									<Form.Label>
//										Especifique a unidade de medida transportada<em>*</em>
//									</Form.Label>
//									<Form.Control
//										ref={registerEvent}
//										required
//										name="unidMedTranspOutros"
//										placeholder="Insira a unidade de medida transportada"
//									/>
//									<span className="error-field">
//										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
//									</span>
//								</Form.Group>
//							</Form.Row>
//						</If>
//					</If>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Material<em>*</em>
//							</Form.Label>
//							<Controller
//								control={controlEvent}
//								name="material"
//								isInvalid={errorsEvent.material !== undefined}
//								as={<Select
//									placeholder="Selecione um material"
//									options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
//									isClearable
//								/>}
//							/>
//							<If condition={errorsEvent.material}>
//								<p className="error-field">{errorsEvent.material?.message}</p>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<If condition={!isEmptyObj(watchEvent())}>
//						<If condition={watchEvent().material?.value === 7}>
//							<Form.Row className="row-custom">
//								<Form.Group as={Col}>
//									<Form.Label>
//										Especifique o material<em>*</em>
//									</Form.Label>
//									<Form.Control
//										ref={registerEvent}
//										required
//										name="materialOutros"
//										placeholder="Insira o material"
//									/>
//									<span className="error-field">
//										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
//									</span>
//								</Form.Group>
//							</Form.Row>
//						</If>
//					</If>
//					<div className="button-position">
//						<Button
//							title="Cancelar"
//							contained={false}
//							type="button"
//							onClick={() => setModalEvents(false)}
//						/>
//						<Button
//							title="Inserir"
//							contained={true}
//							type="submit"
//						/>
//					</div>
//				</Form>
//			</Modal>
//		</If>

//		<If condition={modalEditEvents}>
//			<Modal
//				show={true}
//				onHide={() => setModalEditEvents(false)}
//				title={STRINGS_CIBIO.AMI_EVENT_EDIT_MODAL_TITLE}
//			>
//				<If condition={verifyErrorsOnFormEditEvent()}>
//					{verifyErrorsOnFormEditEvent().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
//				</If>
//				<If condition={!successEvent}>
//					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
//				</If>
//				<Form autoComplete="off">
//					<Form.Row className="row-invisible">
//						<Form.Group as={Col}>
//							<Form.Label>
//								ID
//							</Form.Label>
//							<Form.Control
//								ref={registerEditEvent}
//								name="editid"
//								placeholder=""
//								maxLength={5}
//								type="number"
//								step="any"
//								isInvalid={errorsEditEvent.editid !== undefined}
//								defaultValue={eventToEdit.id}
//							/>
//							<Form.Control.Feedback type="invalid">
//								{errorsEvent.id?.message}
//							</Form.Control.Feedback>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Evento<em>*</em>
//							</Form.Label>
//							<Controller
//								control={controlEditEvent}
//								name="editEvento"
//								isInvalid={errorsEditEvent.editEvento !== undefined}
//								defaultValue={eventToEdit.evento}
//								as={
//									<Select
//										placeholder="Selecione um evento"
//										options={events.map(event => Object.assign({ value: event.id, label: event.nome }))}
//										isClearable
//									/>
//								}
//							/>
//							<If condition={errorsEditEvent.editEvento}>
//								<p className="error-field">{errorsEditEvent.editEvento?.message}</p>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Quantidade Autorizada<em>*</em>
//							</Form.Label>
//							<Form.Control
//								ref={registerEditEvent}
//								name="editQuantidadeAutorizada"
//								placeholder="Insira a quantidade autorizada"
//								maxLength={5}
//								type="number"
//								step="any"
//								isInvalid={errorsEditEvent.editQuantidadeAutorizada !== undefined}
//								defaultValue={eventToEdit.quantidadeAutorizada}
//							/>
//							<Form.Control.Feedback type="invalid">
//								{errorsEditEvent.editQuantidadeAutorizada?.message}
//							</Form.Control.Feedback>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Unidade Medida Autorizada<em>*</em>
//							</Form.Label>
//							<Controller
//								control={controlEditEvent}
//								name="editUnidadeMedidaAutorizada"
//								defaultValue={eventToEdit.unidadeMedidaAutorizada}
//								as={<Select
//									placeholder="Selecione uma unidade de medida"
//									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
//									isClearable
//									onInputChange={() =>
//										setEventToEdit(state => ({
//											id: state.id,
//											evento: state.evento,
//											material: state.material,
//											quantidadeAutorizada: state.quantidadeAutorizada,
//											quantidadeTransportada: state.quantidadeTransportada,
//											unidadeMedidaAutorizada: state.unidadeMedidaAutorizada,
//											unidadeMedidaTransportada: state.unidadeMedidaTransportada,
//											materialOutros: state.materialOutros,
//											unidMedAutorizadaOutros: '',
//											unidMedTranspOutros: state.unidMedTranspOutros,
//										}))
//									}
//								/>}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<If condition={!isEmptyObj(watchEditEvent()) || eventToEdit.unidadeMedidaAutorizada}>
//						<If condition={watchEditEvent().editUnidadeMedidaAutorizada?.value === 4 || eventToEdit.unidMedAutorizadaOutros}>
//							<Form.Row className="row-custom">
//								<Form.Group as={Col} />
//								<Form.Group as={Col}>
//									<Form.Label>
//										Especifique a unidade de medida autorizada<em>*</em>
//									</Form.Label>
//									<Form.Control
//										ref={registerEditEvent}
//										defaultValue={eventToEdit.unidMedAutorizadaOutros}
//										required
//										name="unidMedAutorizadaOutros"
//										placeholder="Insira a unidade de medida autorizada"
//									/>
//									<span className="error-field">
//										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
//									</span>
//								</Form.Group>
//							</Form.Row>
//						</If>
//					</If>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Quantidade Transportada
//							</Form.Label>
//							<Form.Control
//								ref={registerEditEvent}
//								name="editQuantidadeTransportada"
//								placeholder="Insira a quantidade transportada"
//								maxLength={5}
//								type="number"
//								step="any"
//								defaultValue={eventToEdit.quantidadeTransportada}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Unidade Medida Transportada
//							</Form.Label>
//							<Controller
//								control={controlEditEvent}
//								name="editUnidadeMedidaTransportada"
//								defaultValue={eventToEdit.unidadeMedidaTransportada}
//								as={<Select
//									placeholder="Selecione uma unidade de medida"
//									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
//									isClearable
//									onInputChange={() =>
//										setEventToEdit(state => ({
//											id: state.id,
//											evento: state.evento,
//											material: state.material,
//											quantidadeAutorizada: state.quantidadeAutorizada,
//											quantidadeTransportada: state.quantidadeTransportada,
//											unidadeMedidaAutorizada: state.unidadeMedidaAutorizada,
//											unidadeMedidaTransportada: state.unidadeMedidaTransportada,
//											materialOutros: state.materialOutros,
//											unidMedAutorizadaOutros: state.unidMedAutorizadaOutros,
//											unidMedTranspOutros: '',
//										}))
//									}
//								/>}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<If condition={!isEmptyObj(watchEditEvent()) || eventToEdit.unidadeMedidaTransportada}>
//						<If condition={watchEditEvent().editUnidadeMedidaTransportada?.value === 4 || eventToEdit.unidMedTranspOutros}>
//							<Form.Row className="row-custom">
//								<Form.Group as={Col} />
//								<Form.Group as={Col}>
//									<Form.Label>
//										Especifique a unidade de medida transportada<em>*</em>
//									</Form.Label>
//									<Form.Control
//										ref={registerEditEvent}
//										defaultValue={eventToEdit.unidMedTranspOutros}
//										required
//										name="unidMedTranspOutros"
//										placeholder="Insira a unidade de medida transportada"
//									/>
//									<span className="error-field">
//										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
//									</span>
//								</Form.Group>
//							</Form.Row>
//						</If>
//					</If>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Material<em>*</em>
//							</Form.Label>
//							<Controller
//								control={controlEditEvent}
//								name="editMaterial"
//								isInvalid={errorsEditEvent.editMaterial !== undefined}
//								defaultValue={eventToEdit.material}
//								as={<Select
//									placeholder="Selecione um material"
//									options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
//									isClearable
//									onInputChange={() =>
//										setEventToEdit(state => ({
//											id: state.id,
//											evento: state.evento,
//											material: state.material,
//											quantidadeAutorizada: state.quantidadeAutorizada,
//											quantidadeTransportada: state.quantidadeTransportada,
//											unidadeMedidaAutorizada: state.unidadeMedidaAutorizada,
//											unidadeMedidaTransportada: state.unidadeMedidaTransportada,
//											materialOutros: '',
//											unidMedAutorizadaOutros: state.unidMedAutorizadaOutros,
//											unidMedTranspOutros: state.unidMedTranspOutros,
//										}))
//									}
//								/>}
//							/>
//							<If condition={errorsEditEvent.editMaterial}>
//								<p className="error-field">{errorsEditEvent.editMaterial?.message}</p>
//							</If>
//						</Form.Group>
//					</Form.Row>
//					<If condition={!isEmptyObj(watchEditEvent()) || eventToEdit.materialOutros}>
//						<If condition={watchEditEvent().editMaterial?.value === 7 || eventToEdit.materialOutros}>
//							<Form.Row className="row-custom">
//								<Form.Group as={Col}>
//									<Form.Label>
//										Especifique o material<em>*</em>
//									</Form.Label>
//									<Form.Control
//										ref={registerEditEvent}
//										required
//										name="materialOutros"
//										placeholder="Insira o material"
//										defaultValue={eventToEdit.materialOutros}
//									/>
//									<span className="error-field">
//										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
//									</span>
//								</Form.Group>
//							</Form.Row>
//						</If>
//					</If>
//					<div className="button-position">
//						<Button
//							title="Cancelar"
//							contained={false}
//							type="button"
//							onClick={() => setModalEditEvents(false)}
//						/>
//						<Button
//							title="Salvar"
//							contained={true}
//							type="button"
//							onClick={() => {
//								triggerEditEvent().then(validated => {
//									if (validated) {
//										handleSubmitEditEvent(onSubmitEditiEvent)();
//									}
//								})
//							}}
//						/>
//					</div>
//				</Form>
//			</Modal>
//		</If>

//		<If condition={modalTransports}>
//			<Modal
//				show={true}
//				onHide={() => setModalTransports(false)}
//				title={STRINGS_CIBIO.AMI_TRANSPORT_MODAL_TITLE}
//			>
//				<If condition={verifyErrorsOnFormTransport()}>
//					{verifyErrorsOnFormTransport().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
//				</If>
//				<If condition={!successTransport}>
//					<AlertError message="A Data de Envio não pode ser anterior à Data de Aprovação!" />
//				</If>
//				<If condition={!successMaterial}>
//					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
//				</If>
//				<Form onSubmit={handleSubmitTransport(onSubmitTransport)} autoComplete="off">
//					<Form.Row className="row-custom">
//						&nbsp;&nbsp;
//						<Form.Label>
//							Embalagem dupla sendo uma impermeável?
//						</Form.Label>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerTransport}
//							name="embalagemDuplaImpermeavel"
//							type="radio"
//							aria-label="radio 1"
//							label="Sim"
//							value={2}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerTransport}
//							name="embalagemDuplaImpermeavel"
//							type="radio"
//							aria-label="radio 1"
//							label="Não"
//							value={1}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerTransport}
//							name="embalagemDuplaImpermeavel"
//							type="radio"
//							aria-label="radio 1"
//							label="Não se aplica"
//							value={3}
//						/>
//					</Form.Row>
//					<br />
//					<Form.Row className="row-custom">
//						&nbsp;&nbsp;
//						<Form.Label>
//							Identificação com o símbolo de risco biológico?
//						</Form.Label>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerTransport}
//							name="identificacaoSimboloRiscoBiologico"
//							type="radio"
//							aria-label="radio 1"
//							label="Sim"
//							value={2}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerTransport}
//							name="identificacaoSimboloRiscoBiologico"
//							type="radio"
//							aria-label="radio 1"
//							label="Não"
//							value={1}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerTransport}
//							name="identificacaoSimboloRiscoBiologico"
//							type="radio"
//							aria-label="radio 1"
//							label="Não se aplica"
//							value={3}
//						/>
//					</Form.Row>
//					<br />
//					<Form.Row className="row-custom">
//						<Form.Group as={Col} md={8}>
//							<Form.Label>
//								Responsável pelo transporte
//							</Form.Label>
//							<Form.Control
//								ref={registerTransport}
//								name="responsavelTransporte"
//								placeholder="Insira o responsável pelo transporte"
//								maxLength={150}
//								isInvalid={errorsTransport.responsavelTransporte !== undefined}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Data do Envio
//							</Form.Label>
//							<br />
//							<Controller
//								control={controlTransport}
//								name="dataEnvio"
//								isInvalid={errorsTransport.dataEnvio !== undefined}
//								render={({ onChange, value }) => (
//									<DatePicker
//										selected={value}
//										onChange={onChange}
//										className="input-custom-to-date"
//										locale="pt-BR"
//										dateFormat="dd/MM/yyyy"
//										placeholderText="Selecionar a data"
//									/>
//								)}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col} md={8}>
//							<Form.Label>
//								Local de Envio
//							</Form.Label>
//							<Controller
//								control={controlTransport}
//								defaultValue={getValues('localDeEnvio')}
//								name="localPartida"
//								as={<Select
//									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
//									placeholder="Selecione um local"
//									isClearable
//								/>}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col} md={8}>
//							<Form.Label>
//								Local de Recebimento
//							</Form.Label>
//							<Controller
//								control={controlTransport}
//								defaultValue={getValues('localDeDestino')}
//								name="localDestino"
//								as={<Select
//									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
//									placeholder="Selecione um local"
//									isClearable
//								/>}
//							/>

//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Quantidade Transportada
//							</Form.Label>
//							<Form.Control
//								ref={registerTransport}
//								name="qtdTransportada"
//								placeholder="Insira a quantidade transportada"
//								maxLength={5}
//								type="number"
//								step="any"
//								isInvalid={errorsTransport.qtdTransportada !== undefined}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Unidade de medida
//							</Form.Label>
//							<Controller
//								control={controlTransport}
//								name="unidadeMedidaTransportada"
//								as={<Select
//									placeholder="Selecione uma unidade de medida"
//									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
//									isClearable
//								/>}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<div className="button-position">
//						<Button
//							title="Cancelar"
//							contained={false}
//							type="button"
//							onClick={() => setModalTransports(false)}
//						/>
//						<Button
//							title="Inserir"
//							contained={true}
//							type="submit"
//						/>
//					</div>
//				</Form>
//			</Modal>
//		</If>

//		<If condition={modalEditEnvio}>
//			<Modal
//				show={true}
//				onHide={() => setModalEditEnvio(false)}
//				title={STRINGS_CIBIO.AMI_TRANSPORT_EDIT_MODAL_TITLE}
//			>
//				<If condition={verifyErrorsOnFormEditEnvio()}>
//					{verifyErrorsOnFormEditEnvio().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
//				</If>
//				<If condition={!successTransport}>
//					<AlertError message="A Data de Envio não pode ser anterior à Data de Aprovação!" />
//				</If>
//				<If condition={!successMaterial}>
//					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
//				</If>
//				<Form autoComplete="off">
//					<Form.Row className="row-custom">
//						&nbsp;&nbsp;
//						<Form.Label>
//							Embalagem dupla sendo uma impermeável?
//						</Form.Label>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditEnvio}
//							name="editembalagemDuplaImpermeavel"
//							type="radio"
//							aria-label="radio 1"
//							label="Sim"
//							value={2}
//							defaultChecked={transportToEdit.embalagemDuplaImpermeavel === 2}	
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditEnvio}
//							name="editembalagemDuplaImpermeavel"
//							type="radio"
//							aria-label="radio 1"
//							label="Não"
//							value={1}
//							defaultChecked={transportToEdit.embalagemDuplaImpermeavel === 1}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditEnvio}
//							name="editembalagemDuplaImpermeavel"
//							type="radio"
//							aria-label="radio 1"
//							label="Não se aplica"
//							value={3}
//							defaultChecked={transportToEdit.embalagemDuplaImpermeavel === 3}
//						/>
//					</Form.Row>
//					<br />
//					<Form.Row className="row-custom">
//						&nbsp;&nbsp;
//						<Form.Label>
//							Identificação com o símbolo de risco biológico?
//						</Form.Label>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditEnvio}
//							name="editidentificacaoSimboloRiscoBiologico"
//							type="radio"
//							aria-label="radio 1"
//							label="Sim"
//							value={2}
//							defaultChecked={transportToEdit.identificacaoSimboloRiscoBiologico === 2}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditEnvio}
//							name="editidentificacaoSimboloRiscoBiologico"
//							type="radio"
//							aria-label="radio 1"
//							label="Não"
//							value={1}
//							defaultChecked={transportToEdit.identificacaoSimboloRiscoBiologico === 1}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditEnvio}
//							name="editidentificacaoSimboloRiscoBiologico"
//							type="radio"
//							aria-label="radio 1"
//							label="Não se aplica"
//							value={3}
//							defaultChecked={transportToEdit.identificacaoSimboloRiscoBiologico === 3}
//						/>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col} md={8}>
//							<Form.Label>
//								Responsável pelo transporte
//							</Form.Label>
//							<Form.Control
//								ref={registerEditEnvio}
//								name="editresponsavelTransporte"
//								maxLength={150}
//								defaultValue={transportToEdit.responsavelTransporte}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Data do Envio
//							</Form.Label>
//							<br />
//							<Controller
//								control={controlEditEnvio}
//								name="editdataEnvio"
//								defaultValue={transportToEdit.dataEnvio}
//								render={({ onChange, value }) => (
//									<DatePicker
//										selected={value}
//										onChange={onChange}
//										className="input-custom-to-date"
//										locale="pt-BR"
//										dateFormat="dd/MM/yyyy"
//										placeholderText="Selecionar a data"
//									/>
//								)}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Local de Envio
//							</Form.Label>
//							<Controller
//								control={controlEditEnvio}
//								name="editlocalPartida"
//								defaultValue={transportToEdit.localPartida}
//								as={
//									<Select
//										options={
//											locations.map(local => Object.assign({
//												value: local.id, label: local.nome
//											}))
//										}
//									/>
//								}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Local de Recebimento
//							</Form.Label>
//							<Controller
//								control={controlEditEnvio}
//								name="editlocalDestino"
//								defaultValue={transportToEdit.localDestino}
//								as={
//									<Select
//										options={
//											locations.map(local => Object.assign({
//												value: local.id, label: local.nome
//											}))
//										}
//									/>
//								}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Quantidade Transportada
//							</Form.Label>
//							<Form.Control
//								ref={registerEditEnvio}
//								name="editqtdTransportada"
//								placeholder="Insira a quantidade transportada"
//								maxLength={10}
//								type="number"
//								step="any"
//								isInvalid={errorsTransport.qtdTransportada !== undefined}
//								defaultValue={transportToEdit.qtdTransportada}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Unidade de medida
//							</Form.Label>
//							<Controller
//								control={controlEditEnvio}
//								name="editUnidadeMedidaTransportada"
//								defaultValue={transportToEdit.unidadeMedidaTransportada}
//								as={<Select
//									placeholder="Selecione uma unidade de medida"
//									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
//									isClearable
//								/>}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-invisible" >
//						<Form.Control
//							ref={registerEditEnvio}
//							readOnly
//							name="editid"
//							placeholder=""
//							maxLength={5}
//							type="number"
//							step="any"
//							defaultValue={transportToEdit.id}
//						/>
//					</Form.Row>
//					<div className="button-position">
//						<Button
//							title="Cancelar"
//							contained={false}
//							type="button"
//							onClick={() => setModalEditEnvio(false)}
//						/>
//						<Button
//							title="Salvar"
//							contained={true}
//							type="button"
//							onClick={() => {
//								triggerEditEnvio().then(validated => {
//									if (validated) {
//										handleSubmitEditEnvio(onSubmitEditEnvio)();
//									}
//								})
//							}}
//						/>
//					</div>
//				</Form>
//			</Modal>
//		</If>

//		<If condition={modalEditRecebimento}>
//			<Modal
//				show={true}
//				onHide={() => setModalEditRecebimento(false)}
//				title={STRINGS_CIBIO.AMI_TRANSPORT_EDIT_MODAL_TITLE}
//			>
//				<If condition={verifyErrorsOnFormEditTransport()}>
//					{verifyErrorsOnFormEditTransport().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
//				</If>
//				<If condition={!successTransport}>
//					<AlertError message="A Data de Recebimento não pode ser anterior à Data de Aprovação!" />
//				</If>
//				<If condition={!successMaterial}>
//					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
//				</If>
//				<Form autoComplete="off">
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Local de Envio
//							</Form.Label>
//							<Controller
//								control={controlEditTransport}
//								name="editlocalPartida"
//								defaultValue={transportToEdit.localPartida}
//								as={<Select
//									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
//									isOptionDisabled={(option) => option.disabled}
//								/>}
//							/>

//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Local de Recebimento
//							</Form.Label>
//							<Controller
//								control={controlEditTransport}
//								name="editlocalDestino"
//								defaultValue={transportToEdit.localDestino}
//								as={
//									<Select
//										options={
//											locations.map(local => Object.assign({
//												value: local.id, label: local.nome
//											}))
//										}
//										isOptionDisabled={(option) => option.disabled}
//									/>
//								}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col}>
//							<Form.Label>
//								Quantidade Transportada
//							</Form.Label>
//							<Form.Control
//								ref={registerEditTransport}
//								name="editquantidadeTransportada"
//								readOnly
//								maxLength={150}
//								type="number"
//								step="any"
//								defaultValue={transportToEdit.qtdTransportada}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Unidade de medida
//							</Form.Label>
//							<Controller
//								control={controlEditTransport}
//								name="editunidadeMedidaTransportada"
//								isDisabled={true}
//								as={<Select
//									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
//									isClearable
//								/>}
//								readOnly
//								defaultValue={transportToEdit.unidadeMedidaTransportada}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col} md={8}>
//							<Form.Label>
//								Responsável pelo transporte
//							</Form.Label>
//							<Form.Control
//								ref={registerEditTransport}
//								name="editresponsavelTransporte"
//								readOnly
//								maxLength={150}
//								defaultValue={transportToEdit.responsavelTransporte}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Data do Envio
//							</Form.Label>
//							<br />
//							<Controller
//								control={controlEditTransport}
//								name="editdataEnvio"
//								defaultValue={transportToEdit.dataEnvio}
//								isDisabled={true}
//								readOnly
//								render={({ onChange, value }) => (
//									<DatePicker
//										selected={value}
//										onChange={onChange}
//										className="input-custom-to-date"
//										locale="pt-BR"
//										dateFormat="dd/MM/yyyy"
//										placeholderText="Selecionar a data"
//									/>
//								)}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						&nbsp;&nbsp;
//						<Form.Label>
//							O material chegou ao destino em perfeitas condições?
//						</Form.Label>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditTransport}
//							name="editmaterialChegouDestinoPerfeitasCondicoes"
//							type="radio"
//							aria-label="radio 1"
//							label="Sim"
//							value={2}
//							defaultChecked={transportToEdit.materialChegouDestinoPerfeitasCondicoes === 2}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditTransport}
//							name="editmaterialChegouDestinoPerfeitasCondicoes"
//							type="radio"
//							aria-label="radio 1"
//							label="Não"
//							value={1}
//							defaultChecked={transportToEdit.materialChegouDestinoPerfeitasCondicoes === 1}
//						/>
//					</Form.Row>
//					<br />
//					<Form.Row className="row-custom">
//						&nbsp;&nbsp;
//						<Form.Label>
//							A quantidade recebida está de acordo com a quantidade enviada e/ou transportada?
//						</Form.Label>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditTransport}
//							name="editqtdRecebidaDeAcordoComQtdEnviada"
//							type="radio"
//							aria-label="radio 1"
//							label="Sim"
//							value={2}
//							defaultChecked={transportToEdit.qtdRecebidaDeAcordoComQtdEnviada === 2}
//						/>
//						&nbsp;&nbsp;
//						<Form.Check
//							ref={registerEditTransport}
//							name="editqtdRecebidaDeAcordoComQtdEnviada"
//							type="radio"
//							aria-label="radio 1"
//							label="Não"
//							value={1}
//							defaultChecked={transportToEdit.qtdRecebidaDeAcordoComQtdEnviada === 1}
//						/>
//					</Form.Row>
//					<br />
//					<Form.Row className="row-custom">
//						<Form.Group as={Col} md={8}>
//							<Form.Label>
//								Responsável pelo recebimento
//							</Form.Label>
//							<Form.Control
//								ref={registerEditTransport}
//								name="editresponsavelRecebimento"
//								placeholder="Insira o responsável pelo recebimento"
//								maxLength={150}
//								defaultValue={transportToEdit.responsavelRecebimento}
//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Data do Recebimento
//							</Form.Label>
//							<br />
//							<Controller
//								control={controlEditTransport}
//								name="editdataRecebimento"
//								defaultValue={transportToEdit.dataRecebimento}
//								render={({ onChange, value }) => (
//									<DatePicker
//										selected={value}
//										onChange={onChange}
//										className="input-custom-to-date"
//										locale="pt-BR"
//										dateFormat="dd/MM/yyyy"
//										placeholderText="Selecionar a data"
//									/>
//								)}
//							/>
//						</Form.Group>
//					</Form.Row>
//					<Form.Row className="row-custom">
//						<Form.Group as={Col} md={8}>	
//							<Form.Label>
//								Quantidade Recebida
//							</Form.Label>
//							<Form.Control
//								ref={registerEditTransport}
//								name="editqtdRecebida"
//								placeholder="Insira a quantidade recebida"
//								maxLength={10}
//								type="number"
//								step="any"
//								isInvalid={errorsTransport.editqtdRecebida !== undefined}
//								defaultValue={transportToEdit.qtdRecebida}

//							/>
//						</Form.Group>
//						<Form.Group as={Col}>
//							<Form.Label>
//								Unidade de medida
//							</Form.Label>
//							<Controller
//								control={controlEditTransport}
//								isDisabled={true}
//								name="editunidadeMedidaRecebida"
//								as={<Select
//									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
//									isClearable
//								/>}
//								readOnly
//								defaultValue={transportToEdit.unidadeMedidaTransportada}
//							/>
//						</Form.Group>
//					</Form.Row>

//					<Form.Row className="row-invisible" >
//						<Form.Control
//							ref={registerEditTransport}
//							name="editContagem"
//							readOnly
//							defaultValue={transportToEdit.contagem}
//						/>
//						<Form.Control
//							ref={registerEditTransport}
//							name="editId"
//							readOnly
//							defaultValue={transportToEdit.id}
//						/>
//						<Form.Control
//							ref={registerEditTransport}
//							name="editembalagemDuplaImpermeavel"
//							readOnly
//							defaultValue={transportToEdit.embalagemDuplaImpermeavel}
//						/>
//						<Form.Control
//							ref={registerEditTransport}
//							name="editidentificacaoSimboloRiscoBiologico"
//							readOnly
//							defaultValue={transportToEdit.identificacaoSimboloRiscoBiologico}
//						/>
//					</Form.Row>

//					<div className="button-position">
//						<Button
//							title="Cancelar"
//							contained={false}
//							type="button"
//							onClick={() => setModalEditRecebimento(false)}
//						/>
//						<Button
//							title="Salvar"
//							contained={true}
//							type="button"
//							onClick={() => {
//								triggerEditTransport().then(validated => {
//									if (validated) {
//										handleSubmitEditTransport(onSubmitEditTransport)();
//									}
//								})
//							}}
//						/>
//					</div>
//				</Form>
//			</Modal>
//		</If>

//		<If condition={isConfirmation}>
//			<ConfirmationModal
//				show={isConfirmation}
//				confirmationMsg={STRINGS_CIBIO.CONFIRM_LEFT_EDITION}
//				cancelMsg={STRINGS_CIBIO.CANCEL_LEFT_EDITION}
//				onConfirm={() => history.goBack()}
//				onHide={() => setIsConfirmation(false)}
//				title={STRINGS_CIBIO.EDITION_TITLE_CONFIRMATION}
//				type="button"
//				variant="danger"
//			>
//				{STRINGS_CIBIO.EDITION_REGISTER_CONFIRMATION_CANCEL}
//			</ConfirmationModal>
//		</If>

//		<If condition={isAprove}>
//			<ConfirmationModal
//				show={isAprove}
//				onHide={() => setIsAprove(false)}
//				title={STRINGS_CIBIO.AMI_APROVE_TITLE}
//				confirmationMsg={STRINGS.APROVE_CONFIRMATION}
//				onConfirm={() => aprove()}
//				loading={loadingAprove}
//				variant="success"
//				color="white"
//				hideFooter={successAprove}
//			>
//				<If condition={successAprove}>
//					<AlertSuccess
//						message={STRINGS_CIBIO.AMI_APROVE_SUCCESS}
//					/>
//				</If>
//				<p>{STRINGS_CIBIO.AMI_APROVE_BODY_MSG}</p>
//			</ConfirmationModal>
//		</If>

//		<If condition={isCorrection}>
//			<ConfirmationModal
//				show={isCorrection}
//				onHide={() => setIsCorrection(false)}
//				title={STRINGS_CIBIO.AMI_CORRECTION_TITLE}
//				confirmationMsg={STRINGS_CIBIO.AMI_CORRECTION_CONFIRMATION_MSG}
//				onConfirm={() => requestCorrection()}
//				loading={loadingCorrection}
//				variant="success"
//				color="white"
//				hideFooter={successCorrection}
//			>
//				<If condition={successCorrection}>
//					<AlertSuccess
//						message={STRINGS_CIBIO.AMI_CORRECTION_SUCCESS}
//					/>
//				</If>
//				<p>{STRINGS_CIBIO.AMI_CORRECTION_BODY_MSG}</p>
//			</ConfirmationModal>
//		</If>

//		<If condition={isComplete}>
//			<ConfirmationModal
//				show={isComplete}
//				onHide={() => setIsComplete(false)}
//				title={STRINGS_CIBIO.AMI_COMPLETE_TITLE}
//				confirmationMsg={STRINGS_CIBIO.AMI_COMPLETE_CONFIRMATION_MSG}
//				onConfirm={() => complete()}
//				loading={loadingComplete}
//				variant="success"
//				color="white"
//			>
//				<If condition={successComplete}>
//					<AlertSuccess
//						message={STRINGS_CIBIO.AMI_COMPLETE_SUCCESS}
//					/>
//				</If>
//				<p>{STRINGS_CIBIO.AMI_COMPLETE_BODY_MSG}</p>
//			</ConfirmationModal>
//		</If>
//	</div>;
}