import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { Form, Col } from 'react-bootstrap';
import Button from '../../components/Button';
import If from '../../components/If';
import LoadingInForm from '../../components/LoadingInForm';
import AlertError from '../../components/AlertError';
import AlertSuccess from '../../components/AlertSuccess';
import { STRINGS } from '../../util/strings';
import viaCepApi from '../../services/viaCepApi';
import estadosApi from '../../services/estadosApi';
import { States, RegulatoryBodiesForm, Address } from '../../models/models';
import './styles.css';

function RegulatoryBodies() {
    const history = useHistory();
    const [states, setStates] = useState<States>([]);
    const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
    const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);

    const regulatoryBodiesSchema = yup.object().shape({
        name: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        address: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        neighborhood: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        zipCode: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        city: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        state: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        officeName: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        nameOfContactPerson: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        email: yup
            .string()
            .email(STRINGS.FORM_EMAIL_INVALID)
            .required(STRINGS.FORM_FIELD_REQUIRED)
    })

    const initialValues: RegulatoryBodiesForm = {
        name: '',
        address: '',
        neighborhood: '',
        zipCode: '',
        city: '',
        state: '',
        officeName: '',
        nameOfContactPerson: '',
        email: ''
    }

    const { register, handleSubmit, setError, setValue, errors } = useForm({
        defaultValues: initialValues,
        mode: 'onTouched',
        resolver: yupResolver(regulatoryBodiesSchema)
    })

    useEffect(() => {
        getStates();
    })

    const getStates = async () => {
        try {
            const { data } = await estadosApi.get('');
            setStates(data);

        } catch (error:any) {
            console.error(error)
        }
    }

    const setZipCodeError = () => {
        setError('zipCode', {
            type: "validate",
            message: STRINGS.ZIP_CODE_ERROR
        })
    }

    const getAddress = async (e: any) => {
        const zipCode = e.target.value;
        try {
            const { data } = await viaCepApi.get<Address>(`${zipCode}/json/`);

            if (data.bairro)
                setValue('neighborhood', `${data.bairro}`);

            if (data.logradouro)
                setValue('address', `${data.logradouro}`);

            if (data.localidade)
                setValue('city', `${data.localidade}`);

            if (data.uf)
                setValue('state', `${data.uf}`);

            if (data.erro) {
                setZipCodeError();
            }
        } catch (error:any) {
            setZipCodeError();
        }
        setLoadingAddress(false)
    }

    const verifyErrorsOnForm = (): boolean => {
        if (errors.name ||
            errors.zipCode ||
            errors.address ||
            errors.city ||
            errors.neighborhood ||
            errors.state ||
            errors.nameOfContactPerson ||
            errors.officeName ||
            errors.email
        )
            return true;
        return false;
    }

    const onSubmit = (data: any) => {
        // console.log(data);

        setSuccessfulResponse(true);
    };

    return (
        <div className="container container-fluid">
            <If condition={verifyErrorsOnForm()}>
                <AlertError
                    message={STRINGS.ALERT_WARNING_FIELDS}
                />
            </If>
            <If condition={successfulResponse && !verifyErrorsOnForm()}>
                <AlertSuccess
                    message={STRINGS.ALERT_MESSAGE_SUCCESS}
                />
            </If>
            <h3>Cadastro de Órgãos Reguladores</h3>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row className="row-custom">
                    <Form.Group
                        as={Col}
                        md={6}
                        controlId="formGridName"
                    >
                        <Form.Label>
                            Nome do órgão regulador<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            type="text"
                            name="name"
                            placeholder="Insira o nome do órgão regulador"
                            autoComplete="off"
                            isInvalid={errors.name !== undefined}
                            maxLength={100}
                        />
                        <If condition={errors.name}>
                            <Form.Control.Feedback type="invalid">
                                {errors.name?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col} md={4} controlId="formGridZipCode">
                        <Form.Label>
                            CEP<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            as={NumberFormat}
                            getInputRef={register}
                            type="text"
                            placeholder="Insira o CEP do órgão regulador"
                            name="zipCode"
                            isInvalid={errors.zipCode !== undefined}
                            format="#####-###"
                            autoComplete="off"
                            onBlur={(e: any) => {
                                getAddress(e);
                                setLoadingAddress(true);
                            }}
                        />
                        <If condition={loadingAddress && !errors.zipCode}>
                            <LoadingInForm />
                        </If>
                        <If condition={errors.zipCode}>
                            <Form.Control.Feedback type="invalid">
                                {errors.zipCode?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col} md={2} controlId="formGridUF">
                        <Form.Label>UF<em>*</em></Form.Label>
                        <Form.Control
                            as="select"
                            ref={register}
                            name="state"
                            isInvalid={errors.state !== undefined}
                        >
                            <Form.Control
                                as="option"
                                label=''
                            />
                            {states.map((state) => (
                                <Form.Control
                                    key={state.id}
                                    as="option"
                                    label={state.sigla}
                                    value={state.sigla}
                                />
                            ))}
                        </Form.Control>
                        <If condition={errors.state}>
                            <Form.Control.Feedback type="invalid">
                                {errors.state?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                </Form.Row>
                <Form.Row className="row-custom">
                    <Form.Group as={Col} controlId="formGridAddress">
                        <Form.Label>
                            Endereço<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            type="text"
                            name="address"
                            placeholder="Insira o endereço do órgão regulador"
                            autoComplete="off"
                            isInvalid={errors.address !== undefined}
                            maxLength={255}
                        />
                        <If condition={errors.address}>
                            <Form.Control.Feedback type="invalid">
                                {errors.address?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridNeighborhood">
                        <Form.Label>
                            Bairro<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            type="text"
                            name="neighborhood"
                            placeholder="Insira o bairro do órgão regulador"
                            autoComplete="off"
                            isInvalid={errors.neighborhood !== undefined}
                            maxLength={100}
                        />
                        <If condition={errors.neighborhood}>
                            <Form.Control.Feedback type="invalid">
                                {errors.neighborhood?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                </Form.Row>
                <Form.Row className="row-custom">
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>Município<em>*</em></Form.Label>
                        <Form.Control
                            ref={register}
                            type="text"
                            name="city"
                            placeholder="Insira o município do órgão regulador"
                            autoComplete="off"
                            isInvalid={errors.city !== undefined}
                            maxLength={100}
                        />
                        <If condition={errors.city}>
                            <Form.Control.Feedback type="invalid">
                                {errors.city?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridOfficeName">
                        <Form.Label>
                            Nome do cargo<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            type="text"
                            name="officeName"
                            placeholder="Insira o nome do cargo"
                            isInvalid={errors.officeName !== undefined}
                            autoComplete="off"
                            maxLength={100}
                        />
                        <If condition={errors.officeName}>
                            <Form.Control.Feedback type="invalid">
                                {errors.officeName?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                </Form.Row>
                <Form.Row className="row-custom">
                    <Form.Group as={Col} controlId="formGridPersonContact">
                        <Form.Label>
                            Nome da pessoa de contato<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            type="text"
                            name="nameOfContactPerson"
                            placeholder="Insira o nome da pessoa de contato"
                            isInvalid={errors.nameOfContactPerson !== undefined}
                            autoComplete="off"
                            maxLength={100}
                        />
                        <If condition={errors.nameOfContactPerson}>
                            <Form.Control.Feedback type="invalid">
                                {errors.nameOfContactPerson?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Email da pessoa de contato<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            type="text"
                            name="email"
                            placeholder="Insira o email da pessoa de contato"
                            autoComplete="off"
                            isInvalid={errors.email !== undefined}
                            maxLength={100}
                        />
                        <If condition={errors.email}>
                            <Form.Control.Feedback type="invalid">
                                {errors.email?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                </Form.Row>
                <div className="button-position">
                    <Button
                        title="Cancelar"
                        type="button"
                        contained={false}
                        onClick={() => history.goBack()}
                    />
                    <Button
                        title="Cadastrar"
                        type="submit"
                        contained={true}
                    />
                </div>
            </Form>
        </div>
    );
}

export default RegulatoryBodies;