import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import Select from 'react-select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Form, Col } from 'react-bootstrap';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '../../../components/Button';
import GenericButton from '../../../components/FlowGenericButton';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import LoadingInForm from '../../../components/LoadingInForm';
import {
	UnityStationResult,
	EventResult,
	AACForm,
	CultureResult,
	Enum,
	StateType,
	AACByIdToEdit,
	EventsOnEditAAC,
	DocumentsOnEditAAC
} from '../../../models/modelsAuthorizationsCIBIO';
import { STRINGS } from '../../../util/strings';
import { STRINGS_CIBIO } from '../../../util/authorizationsCIBIO/string';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { Context } from '../../../context/AuthContext'
import { DecodedToken } from '../../../models/user';
import '../../../pages/Registers/styles.css';
import api, { apiFileDownload } from '../../../services/api';
import jwtDecode from 'jwt-decode';
import { DecimalAsComma } from '../NumberFormat';
import '../styles_view.scss';


registerLocale('pt-BR', ptBR);

export default function AAC ()
{
	const history = useHistory();
	const location = useLocation<StateType>();
	const { token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);
	const [loadingGetData, setLoadingGetData] = useState<boolean>(true);
	const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [errorGetData, setErrorGetData] = useState<any>(null);
	const [locations, setLocations] = useState<UnityStationResult[]>([]);
	const [events, setEvents] = useState<EventResult[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [transport, setTransport] = useState<Enum[]>([]);
	const [contencao, setContencao] = useState<Enum[]>([]);
	const [documents, setDocuments] = useState<Enum[]>([]);
	const [modalEvents, setModalEvents] = useState<boolean>(false);
	const [modalEditEvents, setModalEditEvents] = useState<boolean>(false);
	const [selectedEvents, setSelectedEvents] = useState<EventsOnEditAAC[]>([]);
	const [eventToEdit, setEventToEdit] = useState<EventsOnEditAAC>({
		eventoId: 0,
		evento: { label: '', value: 0 },
		materialId: 0,
		material: { label: '', value: 0 },
		qtdUtilizada: 0,
		unidadeMedidaId: 0,
		unidadeMedida: { label: '', value: 0 }
	});
	
	const [selectedDocuments, setSelectedDocuments] = useState<DocumentsOnEditAAC[]>([]);
	const [documentToEdit, setDocumentToEdit] = useState<DocumentsOnEditAAC>({
		documentoId: 0,
		documento: { label: '', value: 0 },
		quantidadeKg: '',
		unidadeMedidaId: 0,
		unidadeMedida: { label: '', value: 0 },
		unidMedOutros: ''

	});    
	const [enumMaterial, setEnumMaterial] = useState<Enum[]>([]);
	const [enumUnidadeMedida, setEnumUnidadeMedida] = useState<Enum[]>([]);
	const [gridProps, setGridProps] = useState<any>(null);
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);
	const [isAprove, setIsAprove] = useState<boolean>(false);
	const [loadingAprove, setLoadingAprove] = useState<boolean>(false);
	const [successAprove, setSuccessAprove] = useState<boolean>(false);
	const [errorAprove, setErrorAprove] = useState<any>(null);
	const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
	const [successComplete, setSuccessComplete] = useState<boolean>(false);
	const [errorComplete, setErrorComplete] = useState<any>(null);
	const [isCorrection, setIsCorrection] = useState<boolean>(false);
	const [loadingCorrection, setLoadingCorrection] = useState<boolean>(false);
	const [successCorrection, setSuccessCorrection] = useState<boolean>(false);
	const [errorCorrection, setErrorCorrection] = useState<any>(null);
	const [eventMsg, setEventsMsg] = useState('');

	const [relatoriosFotograficos, setRelatoriosFotograficos] = useState<any[]>([]);

	const { register, reset, getValues, errors, trigger, handleSubmit, control, watch, formState: { isDirty } } = useForm({
		mode: 'onSubmit',
		shouldUnregister: false
	});

	const { getValues: getValuesEvent, control: controlEvent, register: registerEvent, errors: errorsEvent, handleSubmit: handleSubmitEvent, trigger: triggerEvent, watch: watchEvent } = useForm({
		mode: 'all',
	})

	const { control: controlEditEvent, register: registerEditEvent, errors: errorsEditEvent, handleSubmit: handleSubmitEditEvent, trigger: triggerEditEvent, watch: watchEditEvent } = useForm({
		mode: 'all',
		shouldUnregister: false
	})       

	useEffect(() => {
		const getDataToForm = async () => {
			try {
				const locations = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');
				const events = await api.get<EventResult[]>('api/v1/autorizacoes-cibio/Evento/obterporfiltro?Ativo=true');
				const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');
				const material = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/material');
				const unidadeMedida = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/unidade-medida');
				const transporte = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/modo-transporte');
				const contencaoReq = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/contencao');
				const documentoReq = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/documento');

				if (locations.status === 200) {
					setLocations(locations.data);
				}

				if (events.status === 200) {
					setEvents(events.data);
				}

				if (cultures.status === 200) {
					setCultures(cultures.data);
				}

				if (material.status === 200) {
					setEnumMaterial(material.data);
				}

				if (unidadeMedida.status === 200) {
					setEnumUnidadeMedida(unidadeMedida.data);
				}

				if (transporte.status === 200) {
					setTransport(transporte.data);
				}

				if (contencaoReq.status === 200) {
					setContencao(contencaoReq.data);
				}

				if (documentoReq.status === 200) {
					setDocuments(documentoReq.data);
				}

			} catch (error:any) {                
				setErrorGetData(error?.response?.data ?? 'Ocorreu um erro ao tentar buscar os enumeradores.');
			}
			setLoadingGetData(false);
		}

		const getAAC = async () => {
			try {
				const { data, status } = await api.get<AACByIdToEdit>(`api/v1/autorizacoes-cibio/AtividadeContencao/${location.state.id}`);
				if (status === 200) {
					const dataFromServer = {
						id: data.id,
						atividadeObjetivaFuturasLPMA: String(data.atividadeObjetivaFuturasLPMA),
						codigoEnsaio: data.codigoEnsaio,
						dataAprovacao: data.dataAprovacao ? moment(data.dataAprovacao).toDate() : null,
						dataCadastro: moment(data.dataCadastro).toDate(),
						dataCancelamento: data.dataCancelamento ? moment(data.dataCancelamento).toDate() : null,
						dataEmissaoCibio: data.dataEmissaoCibio ? moment(data.dataEmissaoCibio).toDate() : null,
						dataPrevisaoInicio: data.dataPrevisaoInicio ? moment(data.dataPrevisaoInicio).toDate() : null,
						dataPrevisaoTermino: data.dataPrevisaoTermino ? moment(data.dataPrevisaoTermino).toDate() : null,
						departamentoArea: data.departamentoArea,
						descricaoAtividadeMaterialUtilizado: data.descricaoAtividadeMaterialUtilizado,
						destruicaoDescarte: data.destruicaoDescarte,
						enderecoPropriedade: data.enderecoPropriedade,
						escopoCQBContemplaAtividadeComReferidoMaterialCultura: String(data.escopoCQBContemplaAtividadeComReferidoMaterialCultura),
						haveraColheitadaAAC: String(data.haveraColheitadaAAC),
						eventoPassouRN6: String(data.eventoPassouRN6),
						finalidade: data.finalidade,
						finalizacaoProjeto: data.finalizacaoProjeto,
						georreferenciamentoArea: data.georreferenciamentoArea,
						local: data.local,
						localPossuiCQB: String(data.localPossuiCQB),
						localizacaoArea: data.localizacaoArea,
						nomePropriedade: data.nomePropriedade,
						numeroAutorizacaoCibio: data.numeroAutorizacaoCibio,
						numeroInstalacao: data.numeroInstalacao,
						objetivoProjeto: data.objetivoProjeto,
						observacoes: data.observacoes,
						observacoesAnaliseRegulatorio: data.observacoesAnaliseRegulatorio,
						origemMaterial: data.origemMaterial,
						plantioConducaoAtividade: data.plantioConducaoAtividade,
						projeto: data.projeto,
						solicitanteTecnicoPrincipal: data.solicitanteTecnicoPrincipal,
						tecnicoPrincipal: data.tecnicoPrincipal,
						motivoCancelamento: data.motivoCancelamento,
						contencao: {
							value: data.contencaoId ? data.contencaoId : null,
							label: data.contencaoNome ? data.contencaoNome : null
						}, 
						culturaOrganismo: {
							value: data.cultura?.id,
							label: data.cultura?.nome
						},
						unidadeEstacao: {
							value: data.unidadeEstacaoId,
							label: data.unidadeEstacaoNome
						},
						usoFinalidade: data.usoFinalidade
					};

					reset(dataFromServer);

					if (data.eventos.length) {
						setSelectedEvents(data.eventos);
					}

					if (data.documentos.length) {
						setSelectedDocuments(data.documentos);
					}

					if (data.relatoriosFotograficos.length) {
						setRelatoriosFotograficos(data.relatoriosFotograficos);
					}
				}
			} catch (error:any) {
				console.error(error);
				setErrorGetData(error.response.data);
			}
			setLoadingGetData(false);
		}

		getDataToForm();
		getAAC();
	}, [location.state.id, reset]);    

	const [activeStep, setActiveStep] = React.useState(0);
	const steps = ['Autorização AAC', 'Projeto OGM'];

	const handleStep = (step: number) => () => {
		setActiveStep(step);
	};

	const downloadArquivo = (e: any, arquivo: any) => {
		e.preventDefault();
		if (arquivo !== undefined) {
			try {
				apiFileDownload
					.get(`api/v1/autorizacoes-cibio/AtividadeContencao/download?path=${arquivo.path}`)
					.then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', arquivo.filename);
						document.body.appendChild(link);
						link.click();
					});
			} catch (error:any) {
				console.error(error);
			}
		}
	}

	const linkRelatorioFoto = (props: any) => (
		<Fragment>
			<a href='#' onClick={(e) => {downloadArquivo(e, props.node.data)}}>
				{props.node.data.filename}
			</a>
		</Fragment>
	)

	return <div className="container container-fluid">
		<h3>{STRINGS_CIBIO.REGISTER_AAC}</h3>            
		<Form autoComplete="off">
			<Stepper nonLinear activeStep={activeStep}>
				{steps.map((label, index) => (
				<Step key={label}>
					<StepButton color="inherit" onClick={handleStep(index)}>
					{label}
					</StepButton>
				</Step>
				))}
			</Stepper>
			<If condition={activeStep === 0}>
				<Fieldset disabled title={STRINGS_CIBIO.AMI_BLOCK_ONE}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Solicitante/Técnico(a) Principal
							</Form.Label>
							<Form.Control
								value={decoded.unique_name}
								readOnly
								maxLength={100}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Departamento/Área<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="departamentoArea"
								isInvalid={errors.departamentoArea !== undefined}
								placeholder="Insira o departamento/área"
								type="text"
								maxLength={150}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.departamentoArea?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<If condition="watchEvent().motivoCancelamento != ''">
							<Form.Group as={Col} md={12}>
								<Form.Label>
									Motivo do Cancelamento
								</Form.Label>
								<Form.Control
									ref={register}
									as="textarea"
									name="motivoCancelamento"
									readOnly
								/>
							</Form.Group>
						</If>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Técnico(a) Principal
							</Form.Label>
							<Form.Control
								ref={register}
								type="text"
								name="tecnicoPrincipal"
								readOnly
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Estação/Unidade Operativa<em>*</em>
								&nbsp;&nbsp;
								<If condition={loadingGetData}>
									<LoadingInForm />
								</If>
							</Form.Label>
							<Controller                                    
								control={control}
								name="unidadeEstacao"
								isInvalid={errors.unidadeEstacao !== undefined}
								as={<Select isDisabled={true}
									placeholder="Selecione uma unidade/estação"
									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
									isClearable
								/>}
							/>
							<If condition={errors.unidadeEstacao}>
								<p className="error-field">{errors.unidadeEstacao?.message}</p>
							</If>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Local<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="local"
								isInvalid={errors.local !== undefined}
								placeholder="Insira o local"
								type="text"
								maxLength={150}
							/>
							<span className="small text-info">(Descrever o local: Casa de Vegetação, Laboratório, Sala etc.)</span>
							<Form.Control.Feedback type="invalid">
								{errors.local?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Nº da Instalação<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="numeroInstalacao"
								isInvalid={errors.numeroInstalacao !== undefined}
								placeholder="Insira o número da instalação"
								type="text"
								maxLength={50}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.numeroInstalacao?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Código do Ensaio<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="codigoEnsaio"
								isInvalid={errors.codigoEnsaio !== undefined}
								placeholder="Insira o código do ensaio"
								type="text"
								maxLength={50}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.codigoEnsaio?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Cultura/Organismo<em>*</em>
							</Form.Label>
							&nbsp;&nbsp;
							<If condition={loadingGetData}>
								<LoadingInForm />
							</If>
							<Controller
								control={control}
								name="culturaOrganismo"
								isInvalid={errors.culturaOrganismo}
								as={<Select isDisabled={true}
									options={cultures.map(culture => Object.assign({ label: culture.nome, value: culture.id }))}
									placeholder="Selecione uma cultura/organismo"
									isClearable
								/>}
							/>
							<If condition={errors.culturaOrganismo}>
								<p className="error-field">{errors.culturaOrganismo?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Projeto<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="projeto"
								as="textarea"
								rows={5}
								placeholder="Descrever um breve projeto da Contenção, e caso seja necessário anexar ao documento a AMI)"
								maxLength={2000}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Previsão de Início da Atividade<em>*</em>
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataPrevisaoInicio"
								render={({ onChange, value }) => (
									<DatePicker
										disabled={true}
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										maxDate={new Date()}
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Previsão de Término da Atividade<em>*</em>
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataPrevisaoTermino"
								render={({ onChange, value }) => (
									<DatePicker
										disabled={true}
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										minDate={new Date()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Contenção<em>*</em>
							</Form.Label>
							<Controller
								control={control}
								name="contencao"
								isInvalid={errors.contencao}
								as={<Select isDisabled={true}
									options={contencao.map(transp => Object.assign({ label: transp.value, value: transp.key }))}
									placeholder="Selecione a contenção"
									isClearable
								/>}
							/>
							<If condition={errors.contencao}>
								<p className="error-field">{errors.contencao?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<If condition={relatoriosFotograficos.length}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Relatório Fotográfico                                    
								</Form.Label>
							</Form.Group>
						</Form.Row>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={relatoriosFotograficos}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									linkRelatorioFoto: linkRelatorioFoto
								}}
							>
								<AgGridColumn
									headerName='Nome'
									cellStyle={{
										justifyContent: 'left'
									}}
									wrapText
									minWidth={250}
									cellRenderer="linkRelatorioFoto"
								/>
							</AgGridReact>
						</div>
					</If>
					<If condition={watch().contencao?.value === 2}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={12}>
								<Form.Label>
									O evento já passou por uma RN6?<em>*</em>
								</Form.Label>
							</Form.Group>
							<br />
							&nbsp;&nbsp;
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="eventoPassouRN6"
								type="radio"
								label="Sim"
								value={2}
							/>
							<Form.Check
								ref={register}
								name="eventoPassouRN6"
								type="radio"
								label="Não"
								value={1}
							/>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="eventoPassouRN6"
								type="radio"
								label="Não se aplica"
								value={3}
							/>
							<br />
							<If condition={errors.eventoPassouRN6}>
								<p className="error-field">{errors.eventoPassouRN6?.message}</p>
							</If>
						</Form.Row>
					</If>
					<p />
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							O local possui CQB?<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="localPossuiCQB"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="localPossuiCQB"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="localPossuiCQB"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
						/>
					</Form.Row>
					<p />
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							O escopo do CQB do local contempla a atividade com referido material/cultura objeto da solicitação?<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="escopoCQBContemplaAtividadeComReferidoMaterialCultura"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="escopoCQBContemplaAtividadeComReferidoMaterialCultura"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="escopoCQBContemplaAtividadeComReferidoMaterialCultura"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Uso/Finalidade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="usoFinalidade"
								as="textarea"
								rows={4}
								maxLength={2000}
								placeholder="Descreva o uso pretendido do material reprodutivo produzido: sementes, toletes, etc"
								isInvalid={errors.usoFinalidade !== undefined}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.usoFinalidade?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Destruição e Descarte<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="destruicaoDescarte"
								as="textarea"
								rows={4}
								maxLength={2000}
								placeholder="Informe o local e descreva os procedimentos planejados para a destruição e descarte das sobras, resíduos e restos vegetais do material"
								isInvalid={errors.destruicaoDescarte !== undefined}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.destruicaoDescarte?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							A atividade em contenção objetiva futuras liberações planejadas no meio ambiente (LPMAs)?<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="atividadeObjetivaFuturasLPMA"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="atividadeObjetivaFuturasLPMA"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="atividadeObjetivaFuturasLPMA"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observações
							</Form.Label>
							<Form.Control
								ref={register}
								name="observacoes"
								as="textarea"
								rows={5}
								maxLength={4000}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							Haverá Colheita da AAC?<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="haveraColheitadaAAC"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="haveraColheitadaAAC"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
					</Form.Row>

					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Eventos{`  `}
							</Form.Label>
							<If condition={eventMsg}>
								<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
							</If>
						</Form.Group>
					</Form.Row>

					<If condition={selectedEvents.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedEvents}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
							>
								<AgGridColumn
									headerName='Evento'
									field="eventoNome"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
									minWidth={250}
								/>
								<AgGridColumn
									headerName='Material'
									field="materialDescricao"
									headerClass="myfunc-default-header"
								/>
								<AgGridColumn
									headerName='Quantidade a ser Utilizada'
									field="qtdUtilizada"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => {return DecimalAsComma(data.qtdUtilizada);}}
								/>
								<AgGridColumn
									headerName='Unidade Medida'
									field="unidadeMedidaDescricao"
									headerClass="myfunc-default-header"
								/>
							</AgGridReact>
						</div>
					</If>
				</Fieldset>
				<Fieldset disabled title={STRINGS_CIBIO.AMI_BLOCK_TWO}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Nº Autorização CIBio
							</Form.Label>
							<Form.Control
								readOnly
								ref={register}
								name="numeroAutorizacaoCibio"
								maxLength={20}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data Emissão CIBio
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataEmissaoCibio"
								render={({ onChange, value }) => (
									<DatePicker
										disabled
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										maxDate={new Date()}
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data da Aprovação
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataAprovacao"
								render={({ onChange, value }) => (
									<DatePicker
										disabled
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										maxDate={new Date()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observações da Análise do Regulatório
							</Form.Label>
							<Form.Control
								ref={register}
								readOnly={true}
								name="observacoesAnaliseRegulatorio"
								as="textarea"
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>

				
			</If>
			<If condition={activeStep === 1}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Objetivo<em>*</em>
						</Form.Label>
						<Form.Control disabled
							ref={register}
							name="objetivoProjeto"
							as="textarea"
							rows={5}
							maxLength={2000}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Descrição da atividade/Descrição do material utilizado no ensaio<em>*</em>
						</Form.Label>
						<Form.Control disabled
							ref={register}
							name="descricaoAtividadeMaterialUtilizado"
							as="textarea"
							rows={5}
							maxLength={2000}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Origem do Material<em>*</em>
						</Form.Label>
						<Form.Control disabled
							ref={register}
							name="origemMaterial"
							isInvalid={errors.origemMaterial !== undefined}
							placeholder="Insira a origem do material"
							type="text"
							maxLength={100}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.origemMaterial?.message}
						</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Finalidade<em>*</em>
						</Form.Label>
						<Form.Control disabled
							ref={register}
							name="finalidade"
							isInvalid={errors.finalidade !== undefined}
							placeholder="Insira a finalidade"
							type="text"
							maxLength={100}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.finalidade?.message}
						</Form.Control.Feedback>                            
					</Form.Group>
				</Form.Row>
				<Fieldset disabled title={'Local de condução do projeto'}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Nome da Propriedade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="nomePropriedade"
								isInvalid={errors.nomePropriedade !== undefined}
								placeholder="Insira o nome da propriedade"
								type="text"
								maxLength={100}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Endereço da Propriedade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="enderecoPropriedade"
								isInvalid={errors.enderecoPropriedade !== undefined}
								placeholder="Insira o endereço da propriedade"
								type="text"
								maxLength={100}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Georreferenciamento da área<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="georreferenciamentoArea"
								isInvalid={errors.georreferenciamentoArea !== undefined}
								placeholder="Insira a referência geográfica da área"
								type="text"
								maxLength={20}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Localização da área<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="localizacaoArea"
								isInvalid={errors.localizacaoArea !== undefined}
								placeholder="Insira a localização da área"
								type="text"
								maxLength={100}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset disabled title={'Documentação solicitada para condução do projeto'}>
					<Form.Label>
						Documentos
					</Form.Label>
					<If condition={selectedDocuments.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedDocuments}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
							>
								<AgGridColumn
									headerName='Documento'
									field="documentoNome"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
									minWidth={250}
								/>
								<AgGridColumn
									headerName='Descrição'
									field="descricao"
									headerClass="myfunc-default-header"
								/>
								<AgGridColumn
									headerName='Quantidade'
									field="quantidadeKg"
									headerClass="myfunc-default-header"
								/>
								<AgGridColumn
									headerName='Unidade Medida'
									field="unidadeMedidaDescricao"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => {

										if (data.unidadeMedidaDescricao == 'Outros')
											return `Outros (${data.unidMedOutros})`;

										return data.unidadeMedidaDescricao;

									}}
								/>
							</AgGridReact>
						</div>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Finalização do projeto<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="finalizacaoProjeto"
								as="textarea"
								rows={5}
								maxLength={2000}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Plantio e condução da atividade (tratos culturais, etc)<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="plantioConducaoAtividade"
								as="textarea"
								rows={5}
								maxLength={2000}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
			</If>
			<div className="button-position">
				<Button
					title="Voltar"
					contained={false}
					type="button"
					onClick={() => { history.goBack(); }}
				/>
			</div>
		</Form>
	</div>;
}