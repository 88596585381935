
import TextField from  "../../components/form/TextField";
import TextArea from  "../../components/form/TextArea";
import EditView, { EditField } from  "../../components/page/EditView";
import { useForm } from "react-hook-form";
import CqbService, { RolesService } from "./services";
import { STRINGS_CQB } from "../../util/cqb/string";
import RichText from "../../components/form/RichText";
import FileUpload from "../../components/form/FileUpload";
import FilesUpload from "../../components/form/FilesUpload";
import TabControl from  "../../components/form/TabControl";
import { CQBAtendimentoMedicoHospitais, CqbDto, CqbEquipeTecnica, CQBInstalacoes, CQBInstalacoesAprovadas, CqbLocal, CqbProjeto, CqbTecnicos } from "../../models/modelsCQB";
import React, { Fragment, useContext, useState } from "react";
import * as Bootstrap from 'react-bootstrap';
import { useHistory, useLocation, useParams } from "react-router";
import { STATUS, STATUSTEXT } from "../../util/lpma/status";
import { CQB_TIPOS } from "../../util/cqb/tipos";

import DropDown from "../../components/form/DropDown";
import CheckBox from "../../components/form/CheckBox";
import Grid from "../../components/form/Grid";
import Button from "../../components/Button";
import GenericButton from '../../components/FlowGenericButton';

import DialogEdit, { DialogEditSingle } from "../../components/form/DialogEdit";

import { listEnumItem, ParamsType, StateType } from "../../models/modelCommum";
import { ArrayFirstOrDefault, isNumber } from "../../util/jsextends";
import { uuid } from "../../services/uuid";
//import { Local } from "./locais/edit";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { STRINGS } from "../../util/strings";
import DataHide from "../../components/form/DataHide";


export default function Edit (){
	const CqbServiceApi = new CqbService();
	const CqbRoles		 = new RolesService(useContext);

	const UrlParam = useParams<ParamsType>();
	
	const location = useLocation<StateType>();
	const history = useHistory();

	const [entity, setEntity] = useState<CqbDto>();
	const [titleForm, setTitleForm] = useState<string>(`${STRINGS_CQB.NEW_CQB}`);

	const [itemsEquipeTecnica, setItemsEquipeTecnica] = useState<CqbEquipeTecnica[]>();
	const [itemsLocais, setItemsLocais] = useState<CqbLocal[]>();
	const [localAdd, setLocalAdd] = useState<CqbLocal>();

	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [estagio, setEstagio] = useState<any>(STATUS.EmPreenchimento);
	const [tipo, setTipo] = useState<any>(null);
	const [editarAll, setEditarAll] = useState<boolean>(false);
	const [editarReg, setEditarReg] = useState<boolean>(false);
	const [editarAdmin, setEditarAdmin] = useState<boolean>(false);

	const [atividadesDesenvolvidas, setAtividadesDesenvolvidas] = useState<listEnumItem[]>([]);
	const [finalidadeDaSolicitacao, setFinalidadeDaSolicitacao] = useState<listEnumItem[]>([]);
	const [tipoCqb, setTipoCqb] = useState<listEnumItem[]>([]);
	const [titulacao, setTitulacao] = useState<listEnumItem[]>([]);
	const [cfgDefaults, setCfgDefaults] = useState<listEnumItem[]>([]);
	const [documentConfig, setDocumentConfig] = useState<listEnumItem[]>([]);
	const [documentConfigList, setDocumentConfigList] = useState<any[]>([]);

	const [itemRemovedInstalacoes, setItemRemovedInstalacoes] = useState<any[]>([]);

	const ViewNormal = () => {		
		const tipocqb = defaultForm.watch('tipo')?.value ?? CQB_TIPOS.Inclusao;
		return (tipocqb !== CQB_TIPOS.Cancelamento);
	};

	const ViewCancelamento = () => {		
		const tipocqb = defaultForm.watch('tipo')?.value ?? CQB_TIPOS.Inclusao;
		return (tipocqb === CQB_TIPOS.Cancelamento);
	};

	const Validacao = () =>{
		return {
			tipo	:	yup.object(),
			//local 	:	yup.object(),
			//tecnicoPrincipal : yup.object(),
			organogramaFileName :	yup.string(),
			instalacoes		: 		yup.array(),
			instalacoesAprovadas : 		yup.array(),
			equipeTecnica :		yup.array(),
		};
	}

	const ValidacaoPrincipal = () =>{
		if((estagio === STATUS.EmAnalisePeloRegulatorio || estagio === STATUS.Aprovado || estagio === STATUS.EmCorrecao) 
		&& tipo !== CQB_TIPOS.Cancelamento)
		{
			return {
				tipo	: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),	
				itensDaSolicitacao	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),	
				local 	: 	yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),	
				tecnicoPrincipal : 	yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),	
				organogramaFileName	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				instalacoes : yup.array().of(yup.object().shape({
					descricao : yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					figuraFileLegenda	: 
							yup.array()
							.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos uma figura`)
							.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos uma figura`),
					//yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
					plantaFileLegenda	: 
							yup.array()
							.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos uma planta baixa`)
							.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos uma planta baixa`),
					figuraFileDescricao:
						yup.array()
							.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos uma figura`)
							.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos uma figura`),
					//yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
					plantaFileDescricao:
						yup.array()
							.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos uma planta baixa`)
							.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos uma planta baixa`),
				}))
				.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 instalação`)
				.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 instalação`),

				// equipeTecnica: yup.array().of(yup.object().shape({
				// 	tecnicoId :  yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
				// }))
				equipeTecnica :		yup.array()
				.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 Técnico para Equipe`)
				.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 Técnico para Equipe`),

				instalacoesAprovadas :		yup.array()
				.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 parecer de instalação aprovada`)
				.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 parecer de instalação aprovada`),

				atendimentoMedicoHospitais :		yup.array()
				.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 Hospitais da Cidade Selecionada`)
				.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 Hospitais da Cidade Selecionada`),
			};
		}
		return ValidacaoCancelamento();
	}

	const ValidacaoCancelamento = ()=>{
		if((estagio === STATUS.EmAnalisePeloRegulatorio || estagio === STATUS.Aprovado || estagio === STATUS.EmCorrecao) 
			&& tipo === CQB_TIPOS.Cancelamento){
			return  {
				tipo				: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),	
				itensDaSolicitacao	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				atividadesRealizadasUltimosMeses	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				local 				: 	yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),	
				tecnicoPrincipal 	: 	yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				instalacoes 		: yup.array().of(yup.object().shape({
					descricao 		:	 yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
				}))
				.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 instalação`)
				.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 instalação`),

			}
		}
		return Validacao();
	}

	const defaultForm = useForm({
		defaultValues: {} as any,
		mode: "onSubmit",
		resolver: yupResolver(yup.object().shape({
			...ValidacaoPrincipal()
		})),
	});

	// useEffect(() => {
	// }, [location.state, defaultForm.reset]);

	const localForm = useForm({
		defaultValues: {} as any,
		mode: "onSubmit",
	});

	const ListWatch = ()=>{
		const f = defaultForm.watch('finalidadeList');
		const a = defaultForm.watch('atividadeList');
		const d = defaultForm.watch('documentConfigList');
		return {
			finalidadeList 		: (f?.lenght !== 0) ? f : entity?.finalidadeList,
			atividadeList 		: (a?.lenght !== 0) ? a : entity?.atividadeList,
			documentConfigList 	: (d?.lenght !== 0) ? d : entity?.documentConfigList,
			//defaultForm.watch('atividadeList') ?? entity?.atividadeList,
		}
	};

	const [msgSalvoComSucesso, setMsgSalvoComSucesso] = useState<string>("Solicitação CQB Salvo com Sucesso");
	const [reloadId, setReloadId] = useState<boolean>(false);

	
	const parseButton = () => {
		let btnCancelar = <Button
			title="Voltar"
			contained={false}
			type="button"
			onClick={() => {
				history.push(`/cqb/consultar`)
				//defaultForm.formState.isDirty ? setStatus(statuses.go_back) :
				//history.goBack();
			}}
		/>;

		let btnViewPDF ='';
		let btnSalvarAprovar = <Fragment />
		let btnSalvarEnviarRegulatorio = <Fragment />
		let btnSolicitarCorrecao = <Fragment />
		let btnSalvarAdmin = null;
		let eStatus = (entity?.status ?? STATUS.EmPreenchimento);
		
		if(CqbRoles.isCreatOREditRequisicao()
			&& (!(entity?.status) 
			|| entity?.status === STATUS.EmPreenchimento
			|| entity?.status === STATUS.EmCorrecao
			//|| entity?.status === STATUS.Aprovado
			)
		){
			let title = "Salvar e enviar para Regulatório";
			if(entity?.status === STATUS.EmCorrecao)
			{ title = "Reenviar Correção para Regulatório" }
			else if(entity?.status === STATUS.Aprovado)
			{ title = "Concluir" }

			btnSalvarEnviarRegulatorio =<GenericButton
			title={title}
			variant="btn-warning"
			loading={loadingSubmit && (estagio === STATUS.EmAnalisePeloRegulatorio)}
			onClick={() => {
				//defaultForm.register("dataProposta", {required: true})
				if(entity?.status === STATUS.Aprovado)
				{
					setEstagio(STATUS.Concluido)			
				}
				else 
				{
					//setValTitulo(true);
					setEstagio(STATUS.EmAnalisePeloRegulatorio)			
				}
			}}
			type="submit"
			/>

			if(entity?.status !== STATUS.Aprovado)
			{
				btnSalvarAprovar = <Button
				title="Salvar Rascunho"
				contained={true}
				className="btn-success"
				isLoading={loadingSubmit  && (estagio !== STATUS.EmAnalisePeloRegulatorio)}
				onClick={() => { setEstagio(STATUS.SAVE)}}
				type="submit"
				/>;

			}else if(CqbRoles.isAdmin() && (editarAll || editarReg)){
				btnSalvarAdmin = <Button
				title="Salvar"
				contained={true}
				className="btn-success"
				isLoading={loadingSubmit  && (estagio !== STATUS.EmAnalisePeloRegulatorio)}
				onClick={() => { setEstagio(STATUS.SAVE)}}
				type="submit"
				/>;
			}
			
		}
		else if(entity?.status === STATUS.EmAnalisePeloRegulatorio && CqbRoles.isRegulatorio()){
			btnSalvarAprovar = <Button
			title="Aprovar"
			contained={true}
			className="btn-success"
			type="submit"
			isLoading={loadingSubmit  && (estagio !== STATUS.EmCorrecao)}
			onClick={() => setEstagio(STATUS.EmpAprovacao)}
			/>;

			btnSolicitarCorrecao = 	<GenericButton
			title="Solicitar Correção"
			variant="btn-danger"
			type="submit"
			loading={loadingSubmit && (estagio === STATUS.EmCorrecao)}
			onClick={() => setEstagio(STATUS.EmCorrecao)}
			/>;

			if(CqbRoles.isAdmin() && (editarAll || editarReg)){
				btnSalvarAdmin = <Button
				title="Salvar"
				contained={true}
				className="btn-success"
				isLoading={loadingSubmit  && (estagio !== STATUS.EmAnalisePeloRegulatorio)}
				onClick={() => { setEstagio(STATUS.SAVE)}}
				type="submit"
				/>;
			}
		}
		if(UrlParam.modo !== 'editar' && UrlParam.modo !== 'nova') return <div className="button-position">{btnCancelar}</div>
		if((btnSalvarAdmin === null && eStatus !== STATUS.EmPreenchimento) && CqbRoles.isAdmin() && (editarAll || editarReg)){
			btnSalvarAdmin = <Button
				title="Salvar"
				contained={true}
				className="btn-success"
				isLoading={loadingSubmit  && (estagio !== STATUS.EmAnalisePeloRegulatorio)}
				onClick={() => { setEstagio(STATUS.SAVE)}}
				type="submit"
				/>;
		}

		return <div className="button-position">
			{btnCancelar}
			{btnViewPDF}
			{btnSolicitarCorrecao}
			{btnSalvarEnviarRegulatorio}
			{btnSalvarAprovar}		
			{btnSalvarAdmin ?? <Fragment />}						
		</div>;
	}

	const submitFormErro = async (errData:any)=>{
		console.error("Erro Data: ", errData)
	}

	const submitForm = async (newvalues:any, entity:any) => {
		switch(estagio){
			case STATUS.EmpAprovacao: 
				return await submitFormSaveData('aprovar', STRINGS_CQB.ALERT_STATUS_APROVADA_SUCCESS);
			case STATUS.EmCorrecao: 
				if(CqbRoles.isAdmin()){ return await submitFormSaveData('salvar_correcao', STRINGS_CQB.ALERT_STATUS_CORRECAO_SUCCESS); }
				return await submitFormUpdateStatus('correcao', STRINGS_CQB.ALERT_STATUS_CORRECAO_SUCCESS); 
			case STATUS.EmAnalisePeloRegulatorio: 
				return await submitFormSaveData('salvar_regulatorio', STRINGS_CQB.ALERT_STATUS_REGULATORIO_SUCCESS); 
			case STATUS.Concluido: 
				return await submitFormUpdateStatus('concluir', STRINGS_CQB.ALERT_STATUS_CONCLUIDA_SUCCESS); 
			default:
				return await submitFormSaveData('salvar', STRINGS_CQB.ALERT_MESSAGE_SUCCESS); 
		}
	}
	const submitFormUpdateStatus = async (acao: string, msgSucess: string)  => {
		try {
			setLoadingSubmit(true);
			const getValues = Object.assign(defaultForm.getValues())
			let formData = new FormData();
			formData.append("id", entity?.id);
			formData.append("observacoesRegulatorio", getValues.observacoesRegulatorio ?? "");
			formData.append("observacoes", getValues.observacoes ?? "");

			await CqbServiceApi.getCQBAction(acao, formData);  
			//defaultForm.reset({});
			setMsgSalvoComSucesso(msgSucess);
			setReloadId(true);
			return {isOk: true, message: msgSucess};
			// setSuccessMessageAndRedirect(msgSucess, () => {
			// 	setLoadingSubmit(false);
			// 	//setFormData(dados, null, null, null, null);
			// 	//setModoFluxo(true);
			// 	//history.goBack();
			// 	//history.push({ pathname: `/lpma/rn06/editar/${dados.id}`})
			// })
		} catch (error:any) {
			console.error(error)
			setLoadingSubmit(false);
			return {isOk: false};
		}
		
		
	};
	let entityId:any = null;
	const submitFormisOK = async()=>{
		if(UrlParam.modo === 'nova' || UrlParam.modo === 'nova') 
		{
			history.push(`/cqb/editar/${entityId}`)
		}
	};

	const submitFormSaveData = async (acao: string, msgSucess: string)  => {
		try{
			setLoadingSubmit(true);	
			const getValues = Object.assign(defaultForm.getValues())
			const localId = getValues?.local?.value;	

			const instalacoesLst = getValues.instalacoes?.filter((e:any, index:any)=>{
				return itemRemovedInstalacoes.indexOf(index) === -1;
			})


			let values = {
				...getValues,
				id						: entity?.id,
				status					: entity?.status,
				tecnicoPrincipalId		: getValues?.tecnicoPrincipal?.value,
				tipo               		: getValues?.tipo?.value,
				finalidadeList			: getValues?.finalidadeList?.filter((val:any)=> val !== false),
				atividadeList			: getValues?.atividadeList?.filter((val:any)=> val !== false),
				documentConfigList		: documentConfigList,
				//getValues?.documentConfigList?.filter((val:any)=> val !== false),
				instalacoesAprovadas	: getValues?.instalacoesAprovadas?.map((e:any)=>{
											return{ ...e, uid: uuid(), }}),
				instalacoes				: instalacoesLst?.map((e:any)=>{
											const imgF =  e.figuraFileTemp?.filter((e:any)=> e !== null && e !== undefined);
											const imgP =  e.plantaFileTemp?.filter((e:any)=> e !== null && e !== undefined);

											return {
												...e,
												figuraFileTemp		: imgF?.length > 0? imgF: null,
												plantaFileTemp		: imgP?.length > 0? imgP: null,
												id		: isNumber(e.id) === true ? e.id : null,
												uid		: uuid(),
												figuraFile		: null,
												plantaFile		: null,
												usoOuAtividade	: e.usoOuAtividade ?? '',
												equipamentos	: e.equipamentos ?? '',
												figuraGuid		: e.figuraFileGuid,
												plantaGuid		: e.plantaFileGuid,
												figuraLegenda	: e.figuraFileLegenda,
												plantaLegenda	: e.plantaFileLegenda,
												figuraDescricao	: e.figuraFileDescricao,
												plantaDescricao	: e.plantaFileDescricao,
											}}),
				localId					: (localId === 'addnew' ? null : localId),
				cfgAddLocal				: (localId === 'addnew' ? localAdd : null),
			};

			let formData = new FormData();
			let dimenoesDadosFormatados=[
				'dimensaoTotal', 'dimensaoLargura', 'dimensaoComprimento'
			]
			const dimenoesDadosFormatadosClear = (value:any)=>{
				let isNumber = Number(value) === value && (value % 1 !== 0 || value % 1 === 0);
				if(isNumber === true){return value;}	
				if(value === null || value === undefined) { return 0;}
				
				value = value.replace("m (larg.)", '');
				value = value.replace("m (comp.)", '');
				return value.trim();
			}

			let keysToIgnore = ['ignore-01','ignore-02', 'local'];
			for (var key in values) {				
				if (keysToIgnore.includes(key) || !values[key]) { continue; }
				const valueByKey = values[key];
				if(key === 'finalidadeList' || key === 'atividadeList' || key === 'documentConfigList'){
					for(let i = 0, t = valueByKey.length; i < t; i ++){
						formData.append(`${key}[${i}]`, valueByKey[i]);  
					}					
					continue;
				}
				if (key === 'organogramaFileTemp') {
					const iFile = defaultForm.getValues('organogramaFileTemp');
					if(iFile) {for(let i = 0, t = iFile.length; i < t; i++){
						formData.append('organogramaFile', iFile[i]);    	         
					}}
					continue;     
				}
				if (key === 'instalacoesAprovadas' || key === 'atendimentoMedicoHospitais'){					
					for(let i = 0, t = valueByKey.length; i < t; i++){
						var jsonTag = valueByKey[i];
						for(var p in jsonTag){	
							formData.append(`${key}[${i}].${p}`, jsonTag[p]);  
						}
					}
					continue;	
				}	
				if (key === 'equipeTecnica'){	
					const equi_tec = values[key].map((equi:any)=> equi.tecnico.value);				
					for(let i = 0, t = equi_tec.length; i < t; i++){
						formData.append(`equipeTecnica[${i}].tecnicoID`, equi_tec[i]);  
					}
					continue;	
				}				
				if (key === 'instalacoes') {
					for(let i = 0, t = valueByKey.length; i < t; i++){
						var itemInstalacao = valueByKey[i];
						var imagensGuidCount = 0;
						var imagensFileCount = 0;
						for(var ekey in itemInstalacao){	
							const valByEkey = itemInstalacao[ekey];
							if(	valByEkey!== null && valByEkey !== undefined){
								if(ekey === 'figuraFileTemp' || ekey === 'plantaFileTemp'){
									let file = itemInstalacao[ekey];
									const saveName 	= (ekey === 'figuraFileTemp' ? 'figuraFile' : 'plantaFile');
									let lege_key 	= (ekey === 'figuraFileTemp' ? 'figuraLegenda' : 'plantaLegenda');
									let legendas 	= itemInstalacao[lege_key];
									if(file?.length > 0){for(let x = 0, et = file.length; x < et; x++){
										if(!(file[x] === undefined || file[x] === null)){										
											formData.append(`instalacoes[${i}].${saveName}`, file[x]); 
											try{ 				formData.append(`instalacoes[${i}].${saveName}Legenda`, legendas[x]);  
											}catch (erLgd) { 	formData.append(`instalacoes[${i}].${saveName}Legenda`, ''); }
										}
										
									}}								
								}	
								else if(ekey === 'figuraLegenda' || ekey === 'plantaLegenda'){
									/**Continue*/
								}
								else if(ekey === 'figuraGuid' || ekey === 'plantaGuid'){
									let legendakey = (ekey === 'figuraGuid' ? 'figuraLegenda' : 'plantaLegenda');
									let descricaokey = (ekey === 'figuraGuid' ? 'figuraDescricao' : 'plantaDescricao');
									let figurasGuid = itemInstalacao[ekey];
									let legendas = itemInstalacao[legendakey];
									let descricoes = itemInstalacao[descricaokey];
									for(let y = 0, t = figurasGuid.length; y < t; y++){
										if(!(figurasGuid[y] == null || figurasGuid[y] === undefined))
										{
											let cidx = imagensGuidCount;
											formData.append(`instalacoes[${i}].imagensGuid[${cidx}]`, figurasGuid[y]);  
											try {
												formData.append(`instalacoes[${i}].imagensGuidLegenda[${cidx}]`, legendas[y]);  
												formData.append(`instalacoes[${i}].imagensGuidDescricao[${cidx}]`, descricoes[y]);  
											}catch (erLgd) {formData.append(`instalacoes[${i}].imagensGuidLegenda[${cidx}]`, ''); }
											imagensGuidCount++;
										}
									}
								}
								else if(dimenoesDadosFormatados.includes(ekey)){
									formData.append(`instalacoes[${i}].${ekey}`, dimenoesDadosFormatadosClear(itemInstalacao[ekey]));
								}
								else if(itemInstalacao[ekey]) {
									formData.append(`instalacoes[${i}].${ekey}`, itemInstalacao[ekey]);  
								}
							}
						}
					}
					continue;	
				}
				if (key === 'cfgAddLocal'){
					for(var sKey in valueByKey){
						formData.append(`cfgAddLocal.${sKey}`, valueByKey[sKey]);  
					}
					continue;	
				}
				formData.append(key, values[key]);
			}
			
			const result = await CqbServiceApi.getCQBAction(acao, formData);
			entityId =   result.id;
			setLoadingSubmit(false);			
			//defaultForm.reset({});
			setMsgSalvoComSucesso(msgSucess);
			setReloadId(true);
			setEntity(result);
			return {isOk: true, message: msgSucess};
			
		} catch (error:any) {
			console.error(error);
			setLoadingSubmit(false);
			return {isOk: false};
		}	
	};	

	const getLoadById = async (id: any, initData: any) => {
		setReloadId(false);
		const dados = await CqbServiceApi.getCQBById(id);

		const getEnumRef = (tLista: any[], Tid: any, labelTrat: any = null) => {
			if(Tid === null){ return null; }
			let elm = tLista?.find(e=> e.id === Tid);
			if(elm === null){ return null; }
			return {...elm, value: Tid, label: (!labelTrat ? elm?.nome : labelTrat(elm)) };
		}

		const parsenData = {
			...dados,
			tipo		: getEnumRef(initData.tipoCqb, dados?.tipo), // {value: dados?.tipo?.id, label: projDef},  
			local		: getEnumRef(initData.locais,  dados?.localId, (i:any)=>{ return `${i.nomeDaFazenda} - ${i.municipio}/${i.uf}`; }), 
			tecnicoPrincipal : getEnumRef(initData.equipeTecnica,  dados?.tecnicoPrincipalId, (i:any)=>{ return `${initData.titulacao.find((e:any)=> e.id === i.titulacao)?.nome} ${i.nome}`; }), 
			instalacoes: dados?.instalacoes?.map((e: any) => {
				return {
						...e, 
						uid: uuid(),	
						figuraFile: !e.figuras ? null : e.figuras.map((e: any) => {
							return { name: e.fileName, guid: e.fileGuid, desc: e.descricao, legenda: e.legenda }
						}),		
						figuraFileName: !e.figuras ? null : e.figuras.map((e:any)=> e.fileName),
						figuraFileGuid: !e.figuras ? null : e.figuras.map((e: any) => e.fileGuid),
						figuraFileLegenda: !e.figuras ? null : e.figuras.map((e: any) => e.legenda),
						figuraFileDescricao: !e.figuras ? null : e.figuras.map((e: any) => e.descricao),

						plantaFile: !e.plantas ? null : e.plantas.map((e: any) => {
							return { name: e.fileName, guid: e.fileGuid, desc: e.descricao, legenda: e.legenda }
						}),		
						plantaFileName: !e.plantas ? null : e.plantas.map((e:any)=> e.fileName),
						plantaFileGuid: !e.plantas ? null : e.plantas.map((e:any)=> e.fileGuid),
						plantaFileLegenda: !e.plantas ? null : e.plantas.map((e:any)=> e.legenda),
						plantaFileDescricao: !e.plantas ? null : e.plantas.map((e: any) => e.descricao),
			}}),
			equipeTecnica: dados?.equipeTecnica?.map((e:any)=>{ const infoData = getEnumRef(initData.equipeTecnica, e?.tecnicoId); return {
						...e,
						...infoData,
						uid: uuid(),	
						tecnico: infoData, 
			}})

		};
		setDocumentConfigList(parsenData.documentConfigList ?? []);
		let estagio_ = parsenData.status ?? STATUS.EmPreenchimento;	
		if(UrlParam.modo !== 'editar'){
			//!visualizar
			setTipo(null);
			setEditarAll(false);
			setEditarReg(false);			
			setTitleForm(STRINGS_CQB.VIEW_CQB)
		}
		else {
			setTipo(parsenData.tipo?.value ?? null);
			setTitleForm(STRINGS_CQB.EDIT_CQB);
			setEstagio(estagio_);		
			setEditarAll(((estagio_ === STATUS.EmPreenchimento || estagio_ === STATUS.EmCorrecao) && CqbRoles.isCreatOREditRequisicao()) || CqbRoles.isAdmin());
			setEditarReg((estagio_ === STATUS.EmAnalisePeloRegulatorio && CqbRoles.isRegulatorio()) || CqbRoles.isAdmin());

		}
		setItemRemovedInstalacoes([]);

		return parsenData;
	}

	return <EditView<CqbDto> 
		 title={titleForm}		 
		 form={defaultForm}
		 getEntity={(_entity:any)=>{
			setEntity(_entity);
		 }}
		 MsgSaveSubmitSucess={msgSalvoComSucesso}
		 ReloadById={reloadId}
		//  OnSaveSubmit={async (values:any, entity: any)=>{			
			 
		// 	 await CqbServiceApi.getCQBAction("save", values)//.getLocaisSave(values); 
		//  }}
		OnSaveSubmit={submitForm}
		OnSaveSubmitErro={submitFormErro}
		OnSaveSubmitIsOk={submitFormisOK}	 
		parseButtons={parseButton}
		OnInit={async ()=>{
			const equipeTecnica = await CqbServiceApi.getEquipeTecnica();
			const locais		= await CqbServiceApi.getLocais();
			setItemsEquipeTecnica(equipeTecnica)
			setItemsLocais(locais)
			let defaultData = await CqbServiceApi.getDefaultData();
			if(defaultData != null){
				setAtividadesDesenvolvidas(defaultData.atividades);
				setFinalidadeDaSolicitacao(defaultData.finalidades);
				setTipoCqb(defaultData.tipoCqb);
				setTitulacao(defaultData.titulacao);
				setCfgDefaults(defaultData.cfgDefaults);
				setDocumentConfig(defaultData.documentConfig);
			}
			setEditarAll(true);
			setEditarReg(false);
			const initData =  {				
				equipeTecnica	: equipeTecnica,
				locais			: locais,
				tipoCqb			: defaultData.tipoCqb,
				titulacao		: defaultData.titulacao,
				documentConfig	: defaultData.documentConfig,
			};
			return initData;
		}}
		getLoadById={getLoadById}
		>
		{entity?.id ? <>
			<TextField<CqbDto> isSingle form={defaultForm} 
			name="id" label="Nº da Solicitação" layoutColumns={2} 						
			/>

			{entity?.numeroAutorizacao == null || entity?.numeroAutorizacao === undefined ? '':
			<TextField<CqbDto> isSingle form={defaultForm} 
			name="numeroAutorizacao" label="Número da Autorização CIBIO" layoutColumns={4} 					
			/>}
			
			<TextField<CqbDto> isSingle form={defaultForm} 
			name="status" label="Status" layoutColumns={3} 		
			value={STATUSTEXT.find(a=> a.s === (entity.status ?? 1))?.t}				
			/>
			<TextField<CqbDto> isSingle form={defaultForm} 
			name="solicitante" label="Solicitante" layoutColumns={4} 					
			/>			
			</>
		: ''}		

		<EditField title="">

			<DropDown required enabled={editarAll} form={defaultForm} name="tipo" label="Tipo de CQB" layoutColumns={4} 
			isClearable={editarAll} defaultValue={entity?.tipo}
			placeholder={"Selecione o tipo de CQB"}
			options={tipoCqb?.map(i => Object.assign({ value: i.id, label: i.nome }))}  
			isInvalidCheck={editarAll}
			onInputChange={(a:string, e: any)=>{				
				const tipocqb = defaultForm.watch('tipo')?.value ?? null;;				
				setTipo(tipocqb)
			}} />

			<DropDown name="tecnicoPrincipal" label="Responsável Estação Experimental" 
			layoutColumns={8} form={defaultForm}
			options={itemsEquipeTecnica?.map(i => Object.assign({ value: i.id, label: `${titulacao.find(e=> e.id === i.titulacao)?.nome} ${i.nome}`  }))}
			enabled={editarAll} isClearable={editarAll} 
			placeholder="Selecione o(a) Técnico(a) Principal"
			required
			isInvalidCheck={editarAll}
			/>

			{/* <ActionField layoutColumns={12}>
				<DropDown name="local" label="Local Proponente"  
				form={defaultForm}
				options={itemsLocais?.map(i => Object.assign({ value: i.id, label: `${i.nomeDaFazenda} - ${i.municipio}/${i.uf}` }))}  
				enabled={editarAll} isClearable={editarAll} 
				placeholder="Selecione um Local Proponente"
				required
				isInvalidCheck={editarAll} />
			</ActionField> */}
		<EditField title="Local Proponente" required layoutColumns={12}		
		>
				<DropDown name="local" layoutColumns={12} 
				form={defaultForm}
				defaultValue={entity?.localId}
				options={itemsLocais?.map(i => Object.assign({ value: i.id, label: `${i.nomeDaFazenda} - ${i.municipio}/${i.uf}` }))}  
				enabled={editarAll} isClearable={editarAll} 
				placeholder="Selecione um Local Proponente"
				required
				isInvalidCheck={editarAll}
				insertButton={
					<DialogEdit label={'Adicionar'} 
					isButtonType='normal' positionTopAlign width50 layoutColumns={12}
					enabled={editarAll && CqbRoles.isAdmin()} 
					form={localForm} onConfirme={()=>{
						let atualizar = itemsLocais;
						const local = Object.assign(localForm.getValues())					
						const idx = itemsLocais?.findIndex(e=> e.addNew === true) ?? -1;
						if(idx === -1){
							local.addNew = true;
							setLocalAdd(local)
							
							atualizar?.push(local);
							setItemsLocais(atualizar)
						}
						else if(atualizar != null) {
							atualizar[idx] = local;
							setItemsLocais(atualizar)
						}
						defaultForm.setValue("local", {value: 'addnew', label: `${local.nomeDaFazenda} - ${local.municipio}/${local.uf}` });
						}}>
					{/*	<Local form={localForm}></Local>*/}
					</DialogEdit>}
				/>
				{/* <DropDown enabled={editarAll} form={defaultForm} 
				name="local" layoutColumns={12} 
				isClearable={editarAll}
				placeholder={"Selecione um Local Proponente"}
				options={itemsLocais?.map(i => Object.assign({ value: i.id, label: `${i.nomeDaFazenda} - ${i.municipio}/${i.uf}` }))}  
				isInvalidCheck={!location?.state?.readonly}				
				/>
				  */}
				
			</EditField>	

			{ViewNormal() ? 
			<FileUpload required isInvalidCheck={editarAll}  layoutColumns={7} 
			enabled={editarAll} form={defaultForm} 
			name="organogramaFileTemp"
			otherName="organogramaFileName"
			label="Organograma (Arquivo de Imagem)" 
			text={entity?.organogramaFileName} textButton="Inserir Arquivo" 
			onChange={(target:any,e:any)=>{
				defaultForm.setValue(`organogramaFileName`, e);
			}}
			// preview={true}
			urlpreview={entity?.organogramaFileUrlPreview}
			accept="image/png, image/jpeg"
			/>
			:''}	
			
			<DataHide form={defaultForm}  binds={[
				{defaultValue:entity?.organogramaFileGuid, name: `organogramaFileGuid`}, 
				{defaultValue: entity?.organogramaFileName, name: `organogramaFileName`}]} 
			/>		
			
			<RichText<CqbDto> enabled={editarAll} 
			form={defaultForm} 
			{...defaultForm.register(`itensDaSolicitacao`)} 
			name="itensDaSolicitacao" required isInvalidCheck
			defaultValue={entity?.itensDaSolicitacao}
			label={ViewCancelamento() ? "Corpo do Oficio" : "Itens da Solicitação"}
			layoutColumns={12}
			posText={ ViewCancelamento() ? <>{STRINGS_CQB.SESSAO_TAB_AUXILIA}</> : null}
			/>

			{ViewCancelamento() ? 
			<RichText<CqbDto> enabled={editarAll} 
			{...defaultForm.register(`atividadesRealizadasUltimosMeses`)} 
			form={defaultForm} required isInvalidCheck
			name="atividadesRealizadasUltimosMeses"
			defaultValue={entity?.atividadesRealizadasUltimosMeses}
			label="Atividades realizadas nos últimos 12 meses nas Localidade/Áreas citadas" layoutColumns={12} />
			:''}

		</EditField>

		<EditField visible={ViewNormal()} {...defaultForm.register(`finalidadeList`)}  title="Finalidade da solicitação de concessão de CQB" layoutColumns={12}>
			{finalidadeDaSolicitacao?.map((e: any, index: any)=>{
				return <CheckBox form={defaultForm}  name="finalidadeList" layoutColumns={4}
					//{...defaultForm.register(`finalidadeList.${index}`)} 
					key={`uid-${index}`}
					label={e.nome} value={e.id}	
					enabled={editarAll} 
					defaultChecked={entity?.finalidadeList?.includes(e.id) || (entity?.finalidadeList == null && e.checked === true)}
					textBoxName="finalidadeOutros"					
					textBoxChecked={(e.setOutroValor === true)}
				/> 
			})}			
		</EditField>

		<EditField visible={ViewNormal()} {...defaultForm.register(`atividadeList`)}  title="Atividades desenvolvidas com" layoutColumns={12}>
			{atividadesDesenvolvidas.map((e: any, index: any)=>{
				return <CheckBox form={defaultForm} name="atividadeList" layoutColumns={3}	
				// {...defaultForm.register(`atividadeList.${index}`)}    
				key={`uid-chk2-${index}`}
				label={e.nome} value={e.id} 
				enabled={editarAll} 	
				defaultChecked={entity?.atividadeList?.includes(e.id) || (entity?.atividadeList == null && e.checked === true)}
				textBoxName="atividadeOutros"
				textBoxChecked={(e.setOutroValor === true)}
				/> 
			})}			
		</EditField>

		<EditField title="Descrição das instalações que serão utilizadas nas atividades com OGM e seus derivados">
			{ViewNormal() ? 
			<Grid<CqbDto, CQBInstalacoesAprovadas>
				form={defaultForm}
				{...defaultForm.register(`InstalacoesAprovadas`)} 
				name="instalacoesAprovadas"
				defaultValue={entity?.instalacoesAprovadas}

				required={true}
				style={{ height: '210px', width: '100%' }}
				label="Instalações Aprovadas" 
				layoutColumns={12}
				enabled={editarAll} 	
				isInvalidCheck={!location?.state?.readonly}							
				collumns={[
					{header: 'Aprovação da CTNBio', field: 'parecerDeAprovacao', 
					showOnModal: true, custumControls : <TextField layoutColumns={12} /> },
					{header: 'Instalações', field: 'instalacao', 
					showOnModal: true, custumControls : <TextArea rows={4} layoutColumns={12} /> },
				]}
			></Grid> :''}

			<TabControl<CqbDto, CQBInstalacoes>
			location={location}
			label={ViewNormal() ? "Instalações/Sala/Ambientes" : "Instalações/Sala/Ambientes a serem excluídas"}
			form={defaultForm} 
			enabled={editarAll} 
			isInvalidCheck={editarAll}
			name="instalacoes"
			addNewItem={()=>{ return { descricao: 'NOVA INSTALAÇÃO', uid: '', id: 0 } as CQBInstalacoes }}
			titleWatchField="descricao"
			msgTabRemoveTitle="Remover ambiente"
			msgTabRemovePrompt="Confirmar remoção de instalação"
			defaultValue={entity?.instalacoes}
			indicesRemoved={(idxRmv:any[])=>{ setItemRemovedInstalacoes(idxRmv)  }}
			renderItem={(item: any, index:any)=>{
				return <Bootstrap.Form.Row className="row-custom">
					 <DataHide form={defaultForm}  binds={[
						{defaultValue: item.id, name: `instalacoes.${index}.id`}]} 
					/>
					<TextField required isInvalidCheck={editarAll} enabled={editarAll}  form={defaultForm} 
					// {...defaultForm.register(`instalacoes.${index}.descricao`)}    
					name={`instalacoes.${index}.descricao`} defaultValue={item.descricao} 
					label="Descrição" layoutColumns={12} />  

					{ViewNormal() ? <>
					
					<RichText<CQBInstalacoes>
					enabled={editarAll} 
					form={defaultForm} 
					name={`instalacoes.${index}.usoOuAtividade`}
					defaultValue={item?.usoOuAtividade}
					label={STRINGS_CQB.SESSAO_7_1LABEL} layoutColumns={12} />

					<FilesUpload required isInvalidCheck={editarAll} enabled={editarAll} form={defaultForm}
					label="Figura (Arquivo de Imagem)" 
					name={`instalacoes.${index}.figuraFileTemp`} 
					isAdicionalText={true}
					adicionalText="Legenda da Imagem"
					nameAdicionalText={`instalacoes.${index}.figuraFileLegenda`} 

					isAdicionalText2={true}
					adicionalText2="Descrição da Imagem"
					nameAdicionalText2={`instalacoes.${index}.figuraFileDescricao`} 

					nameGuid={`instalacoes.${index}.figuraFileGuid`}			
					textButton="Inserir Arquivo"  layoutColumns={6} 	
					defaultValue={item?.figuraFile}
					nameValidCheck={`instalacoes.${index}.figuraFileLegenda`} 
					accept="image/png, image/jpeg"	
					/>

					<FilesUpload required isInvalidCheck={editarAll} enabled={editarAll} form={defaultForm}
					label="Planta Baixa (Arquivo de Imagem)" 
					isAdicionalText={true}
					adicionalText="Legenda da Imagem"
					nameAdicionalText={`instalacoes.${index}.plantaFileLegenda`}

					isAdicionalText2={true}
					adicionalText2="Descrição da Imagem"
					nameAdicionalText2={`instalacoes.${index}.plantaFileDescricao`} 

					name={`instalacoes.${index}.plantaFileTemp`} 
					nameGuid={`instalacoes.${index}.plantaFileGuid`}			
					textButton="Inserir Arquivo"  layoutColumns={6} 	
					defaultValue={item?.plantaFile}		
					nameValidCheck={`instalacoes.${index}.plantaFileLegenda`} 			
					accept="image/png, image/jpeg"	
					/>

					{/* <FileUpload multiple required isInvalidCheck={editarAll} enabled={editarAll} form={defaultForm} 
					name={`instalacoes.${index}.plantaFileTemp`} 
					otherName={`instalacoes.${index}.plantaFileName`}
					label="Planta Baixa (Arquivo de Imagem)" 
					text={item.plantaFileName} textButton="Inserir Arquivo"  layoutColumns={6} 
					onChange={(a:any,e:any)=>{
						defaultForm.setValue(`instalacoes.${index}.plantaFileName`, e);
					}}
					accept="image/png, image/jpeg"
					/> */}
					

					{/* <DataHide form={defaultForm}  binds={[
						{defaultValue: item.plantaFileGuid, name: `instalacoes.${index}.plantaFileGuid`}, 
						{defaultValue: item.plantaFileName, name: `instalacoes.${index}.plantaFileName`},
						{defaultValue: item.figuraFileGuid, name: `instalacoes.${index}.figuraFileGuid`}, 
						{defaultValue: item.figuraFileName, name: `instalacoes.${index}.figuraFileName`},]} 
					/>					 */}

					<RichText<CQBInstalacoes> enabled={editarAll} 
					form={defaultForm}
					name={`instalacoes.${index}.informacoesCaracteristicas`}
					{...defaultForm.register(`instalacoes.${index}.informacoesCaracteristicas`)} 
					defaultValue={item.informacoesCaracteristicas}
					label={STRINGS_CQB.SESSAO_7_2LABEL} layoutColumns={12} />

					<TextField form={defaultForm}  enabled={editarAll}
					name={`instalacoes.${index}.dimensaoTotal`} label="Área Total da Instalação m" sup="2" 
					defaultValue={item.dimensaoTotal} layoutColumns={6} isNumer={true} 
					/>		

					<RichText<CQBInstalacoes> enabled={editarAll} 
					form={defaultForm}
					name={`instalacoes.${index}.informacoesPlanta`}
					{...defaultForm.register(`instalacoes.${index}.informacoesPlanta`)} 
					defaultValue={item.informacoesPlanta}
					label={STRINGS_CQB.SESSAO_7_3LABEL} layoutColumns={12} />

					</>:<>
					
					{/* <RichText<CQBInstalacoes>
					enabled={editarAll} 
					form={defaultForm} 
					name={`instalacoes.${index}.usoOuAtividade`}
					defaultValue={item?.usoOuAtividade}
					label={STRINGS_CQB.SESSAO_7_1LABEL} layoutColumns={12} /> */}

					<TextField form={defaultForm}  enabled={editarAll}
					name={`instalacoes.${index}.dimensaoTotal`} label="Área Total da Instalação m" sup="2" 
					defaultValue={item.dimensaoTotal} layoutColumns={6} isNumer={true} 
					/>	
					</>}

				</Bootstrap.Form.Row>
			}}
			/>

			{ViewNormal() ? <>
			<RichText<CqbDto> enabled={editarAll} 
			form={defaultForm}
			name="equipamentos"
			{...defaultForm.register(`equipamentos`)} 
			defaultValue={entity?.equipamentos}
			label={STRINGS_CQB.SESSAO_08_LABEL} layoutColumns={12} />

			<EditField title="Medidas de Biossegurança">
				<TextField enabled={editarAll} 
				form={defaultForm}
				name="medidasBiossegurancaTitulo"
				{...defaultForm.register(`medidasBiossegurancaTitulo`)} 
				defaultValue={(entity?.medidasBiossegurancaTitulo ?? "As medidas a serem adotadas, estão listadas abaixo")}
				label="Titulo" layoutColumns={12} />

				<RichText<CqbDto> enabled={editarAll} 
				form={defaultForm}
				name="medidasBiosseguranca"
				{...defaultForm.register(`medidasBiosseguranca`)} 
				defaultValue={entity?.medidasBiosseguranca}
				label="Descrição" layoutColumns={12} />
			</EditField>
			</>:''}
			
		</EditField>
	 
		
		<EditField visible={ViewNormal()} title={STRINGS_CQB.SESSAO_09_LABEL}>
			<Grid<CqbDto, CqbTecnicos>
				isInvalidCheck={editarAll} required={editarAll}
				form={defaultForm}
				style={{ height: '210px', width: '100%' }}
				name="equipeTecnica" label="Equipe Técnica BASF" 
				layoutColumns={12}
				enabled={editarAll} 
				defaultValue={entity?.equipeTecnica}
				collumns={[
				{header: 'Técnico', field: 'tecnico', 
				gridValueFormatter: (event:any)=>{ return event.data?.tecnico?.label },
				showOnModal: true, custumControls : <DropDown name="tecnico" label="Escolha um técnico para ser inserido" layoutColumns={12} 
				options={itemsEquipeTecnica?.map(i => Object.assign({...i, tecnico: i, value: i.id, label: `${titulacao.find(e=> e.id === i.titulacao)?.nome} ${i.nome}`  }))}  />  },
				
				{showOnModal: false, field: 'tecnico', header: 'CPF/CREA/CRBio',
				gridValueFormatter: (event:any)=>{ return event.data?.tecnico?.documento } },

				{showOnModal: false, field: 'tecnico', header: 'Link Lattes/CV',
				gridValueFormatter: (event:any)=>{ 
					const cv = event.data?.tecnico?.link_CV_Lattes;
					return (cv === null || cv === undefined || cv === '' ? 'Anexado' : cv)
					} },

				{showOnModal: false, showOnGrid: false, field: 'tecnico_Id', 
				gridValueFormatter: (event:any)=>{ return event.data?.tecnico?.id }, header: '' },

				]}
			></Grid>
		</EditField>

		<EditField visible={ViewNormal()} title="Especificar as condições para atendimentos médicos de emergência na instituição.">
			<Grid<CqbDto, CQBAtendimentoMedicoHospitais>
				form={defaultForm}
				{...defaultForm.register(`atendimentoMedicoHospitais`)} 
				name={`atendimentoMedicoHospitais`} 
				defaultValue={entity?.atendimentoMedicoHospitais}
				label="Lista de Hospitais da Cidade Selecionada"
				titleModal="Editar Hospital"
				required={true}
				style={{ height: '210px', width: '100%' }}					
				layoutColumns={12}
				enabled={editarAll} 	
				isInvalidCheck={editarAll}							
				collumns={[
					{header: 'Hospital', field: 'hospital', 
					showOnModal: true, custumControls : <TextField label='Nome do Hospital' layoutColumns={12} /> },
					{header: 'Telefone(s)', field: 'telefone', 
					showOnModal: true, custumControls : <TextField layoutColumns={6} /> },
					{header: 'Endereço', field: 'endereco', 
					showOnModal: true, custumControls : <TextArea layoutColumns={12} rows={3} /> },
					
				]}
			></Grid> 

		</EditField>
	    
		<EditField visible={editarAll && CqbRoles.isAdmin()}>
			<DialogEditSingle label="Configurar" dlgTitle={STRINGS_CQB.MODO_CONFIGURAR_DLG} 
			isButtonType='config' 
			width50
			enabled={editarAll && CqbRoles.isAdmin()} 
			form={localForm} 
			onShow={()=>{ }}
			onConfirme={()=>{
				const idSel = ListWatch().documentConfigList.map((e:any)=> parseInt(e));
				setDocumentConfigList(idSel);
				defaultForm.setValue("documentConfigList", idSel);
			}}>{
				documentConfig?.map((e:listEnumItem, index:any)=>{
					return <CheckBox key={index} form={defaultForm}  name="documentConfigList"
					{...defaultForm.register(`documentConfigList`)}
					layoutColumns={12}		
					label={e.nome} value={e.id}	
					enabled={editarAll} 
					onChange={(chk: any)=>{e.checked = chk}}
					defaultChecked={documentConfigList?.includes(e.id)}
				/> 
				})}				
			</DialogEditSingle>&nbsp;&nbsp;
			<label>{STRINGS_CQB.MODO_CONFIGURAR}</label>			
		</EditField>		

		<EditField visible={(entity?.status ?? STATUS.EmPreenchimento) !== STATUS.EmPreenchimento} 
		title="Observações da Análise do Regulatório.">
			<TextArea<CqbDto> isInvalidCheck={!location?.state?.readonly} enabled={editarReg} rows={3} form={defaultForm} name="observacoesRegulatorio" noLabel layoutColumns={12} />
		</EditField>

	</EditView>;
}
