import React from 'react';
import CustomButton from '../../components/Basics/CustomButton';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import Loading from '../../components/Loading';

type DefaultCallback = {(id:number) : any}

interface Props
{
	show				: boolean;
	onAction			: DefaultCallback;
	onCreate			: {(callback:DefaultCallback) : number};
	id?					: number;
}

interface State
{
	loading				: boolean;
	modal				: boolean;
}

export default class OficioLPMA extends React.Component<Props, State>
{
	public static defaultProps = {};
	
	constructor (props:Props)
	{
		super(props)
		
		this.state				= {
			loading					: false,
			modal					: false,
		};
	}

	render ()
	{
		if (!this.props.show)
			return <React.Fragment />;

		return <React.Fragment>
			<Modal
				show={this.state.modal}
				onHide={() => this.setState({modal:false})}
				title="Gerar dados do Comunicado"
			>
				<p>É necessário primeiro gerar os dados iniciais do comunicado. Deseja gerá-los agora?</p>
				<div className="button-position">
					<Button
						title="Cancelar"
						isLoading={this.state.loading}
						contained={false}
						type="button"
						onClick={() => this.setState({modal:false})}
					/>
					<Button
						title="Gerar Dados"
						contained={true}
						isLoading={this.state.loading}
						type="button"
						onClick={() => {

							this.setState({loading:true});

							this.props.onCreate((id:number) => {
								this.props.onAction(id);
								this.setState({loading:false});
							});
							
						}}
					/>
				</div>
			</Modal>
			<CustomButton
				type="send"
				text="Oficio"
				onClick={() => {

					if (!this.props.id)
						return this.setState({modal:true});
						
					this.props.onAction(this.props.id);
				}}
			/>
		</React.Fragment>;
	}
}