import React from "react";
import * as Bootstrap from 'react-bootstrap';
import { Controller } from "react-hook-form";
import Select from 'react-select';
import TextField from "./TextField";
import ToggleSwitch from '../ToggleSwitch';
import { uuid } from "../../services/uuid";

interface ToggleBoxProps
{
	form?           :any;
	name?           :string;
	label?          :any | string;
	isInvalid?      :boolean;
	placeholder?    :string;
	control?        :any;
	length?         :number;	
	layoutColumns?  : number;
    labelCollums?   : number;
    options?        : any;
	isClearable?    : boolean;
	required?       : boolean;
	enabled?        :boolean;
    isInvalidCheck? : boolean;
    onChange?       : any;
    onInputChange?  : any;
    onMenuClose?    : any;
    onBlur?         : any;
    key?		    : any;
	defaultChecked? : boolean;
    value?          : any;
    checked?        :boolean;
    textBoxChecked? : boolean;
    textBoxName?    : string;
}


export default function ToggleBox(props: ToggleBoxProps)
{
	const nameDef = "realToggleBox";
	const [enabled_, setEnabled] = React.useState<boolean>(true);
    const [asErro, setAsErro] = React.useState<boolean>(true);
    const [textBoxChecked, setTextBoxChecked] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    }, [props.enabled, props.form?.errors, props?.name]);

    React.useEffect(() => {
        let _asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
        setAsErro(_asErro)
    }, [props.form?.errors, props?.name]);

    React.useEffect(()=>{
        if(props?.textBoxChecked !== null )
        { setTextBoxChecked(props?.textBoxChecked ?? false);  }
        else { setTextBoxChecked(false);}
    },[props?.textBoxChecked])

    React.useEffect(()=>{        
        props.form?.register(`${props.name}`);
        let dBool = props.form.getValues(props.name);

        if (props.name?.includes("areaUtilizada"))
            dBool = null;

        if(dBool !== null && dBool !== undefined){
            setChecked(dBool);  
        }
        else if(props?.defaultChecked !== null )
        { 
            const rvalue = props?.defaultChecked ?? false;
            setChecked(rvalue);  
            if(rvalue === true){props.form?.setValue(props.name, props.value);}
            
        }       
    },[props?.defaultChecked])

    const UpdateSet = (check: boolean) =>{
        props.form?.setValue(`${props.name}`, check); 
    }
    

    return <Bootstrap.Form.Group as={Bootstrap.Col} md={props.layoutColumns ?? 12}>
            <Bootstrap.Form.Row className="ToggleBoxMain row-custom">
                <Bootstrap.Col xs={props.labelCollums ?? 7}>
                    <Bootstrap.Form.Label>
                        {props.label} {props.required ? <em>*</em> : ''}
                    </Bootstrap.Form.Label>
                </Bootstrap.Col>
                <Bootstrap.Col xs={2}>
                    <ToggleSwitch
                        id={uuid()}
                        formref={props.form?.register}
                        name={props.name ?? nameDef}
                        small
                        disabled={!enabled_}
                        checked={checked}        
                        onChange={()=>{ 
                            const newChecked = !checked;
                            setChecked(newChecked);
                            if(props.onChange){
                                props.onChange(newChecked)
                            }
                            UpdateSet(newChecked);
                            // permissoes[index].checked = !permissoes[index].checked                                    
                            // getPermissao([... permissoes]); 
                        }}                                    
                    />
                </Bootstrap.Col>
            </Bootstrap.Form.Row>
        </Bootstrap.Form.Group>;

    // return <Bootstrap.Form.Group className="ToggleBoxMain" as={Col} md={props.layoutColumns ?? 6}>
    //             <Bootstrap.Form.Label>
    //                     {props.label} {props.required ? <em>*</em> : ''}               
    //             </Bootstrap.Form.Label>
    //     </Bootstrap.Form.Group>;

}
