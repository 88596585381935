import React from 'react';
import { STRINGS } from '../util/strings';

export default function SearchEmpty(props:any)
{
	const { text } = props;
	return (
		<span>
			<br />
			<p className="empty-lbl">{text ? text : STRINGS.EMPTY_SEARCH}</p>
		</span>
	);
}