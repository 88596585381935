import React, { Fragment, useContext, useState } from 'react';
import * as Bootstrap from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import AlertSuccess from '../../components/AlertSuccess';
import EditButton from '../../components/EditButton';
import Button 		from "../../components/Button";
import DropDown from '../../components/form/DropDown';
import TextField from '../../components/form/TextField';
import Loading from '../../components/Loading';
import ListingView, { ListCol } from "../../components/page/ListingView";

import ReasonModal from '../../components/ReasonModal';
import ViewButton from '../../components/ViewButton';
import PDFButton, {GenerateAnexo as ANEXOButton} 	from '../../components/GeneratePDF';
import CancelButton from '../../components/CancelRequisitionButton';

import { _generateDate } from '../../util/convert';
import { STRINGS_LPMA } from "../../util/lpma/string";
import { STRINGS_RCLPMA } from "../../util/rclpma/string";
import { STATUS, STATUS_RC_LPMA } from '../../util/lpma/status';
import RCLpmaService, { RolesService } from './services';
import LpmaService, * as lpmaServ from "../lpma/services";
import { RCLpmaDto } from '../../models/modelsRCLPMA';
import { DialogEditSingle } from '../../components/form/DialogEdit';
import { uuid } from '../../services/uuid';
import CheckBox from '../../components/form/CheckBox';
import { listEnumItem } from '../../models/modelCommum';
import { LpmaEquipeTecnica } from '../../models/modelsLPMA';

export default function List (){
	const RCLpmaServiceApi 	= new RCLpmaService();
	const RCLpmaRoles		= new RolesService(useContext);
	const LPMAServiceApi 	= new LpmaService();
	const history = useHistory();

	// const [successCancel, setSuccessCancel] = useState<boolean>(false);
	// const [loadingById, setLoadingById] = useState<boolean>(false);
	// const [reasonCancel, setReasonCancel] = useState('');
	// const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	// const [isCancel, setIsCancel] = useState<boolean>(false);
	// const [itemSel, setItemSel] = useState<any>();
	const [loadingButPdf, setLoadingButPdf] = useState<boolean>(false);
	const [loadingButAnx, setLoadingButAnx] = useState<{an1: boolean, an2: boolean}>();
	const [defaultData, setDefaultData] = useState<any>({});	
	const [itemSel, setItemSel] = useState<any>();
	const [refreshFilter, setRefreshFilter] = useState<boolean | undefined>(false);


	const goUrl = (url: string)=> {
		history.push({pathname: url,});
	}

	const nomeSolicitante =()=>{
		return (RCLpmaRoles.isAdmin() || RCLpmaRoles.isRegulatorio()) ? ''  : RCLpmaRoles.getUserName();
	}

	const TecnicoLogado = () =>{
		const tecnicoE = defaultData?.equipeTecnica?.find((e:LpmaEquipeTecnica)=> 
			e.userName === RCLpmaRoles.getUserName() ||
			e.email === RCLpmaRoles.getUserName());

		if(tecnicoE == null)
			return {value: null, label: null};

		const tecnico = {...tecnicoE, value: tecnicoE?.id, label: tecnicoE?.nome}
		return tecnico;
	}

    const defaultForm = useForm({
		defaultValues: {
            titulo			: 	'',
			solicitante		: 	RCLpmaRoles.isTecnicoPermissao() ? '' : nomeSolicitante(),
			local			: 	{value: null, label: null},
			tecnicoPrincipal: 	RCLpmaRoles.isTecnicoPermissao() ? TecnicoLogado() :  {value: null, label: null},
			status			:  	RCLpmaRoles.isTecnicoPermissao() ? {value: STATUS.EmAnaliseTecnico, label: 'Em Análise Técnico'}  : {value: null, label: null},
			tipo			:  	{value: null, label: null},
			documentConfigList : [],
			numeroAutorizacao: '',
			numeroControle: '',
        }// as CqbDto,
	})	


	// React.useEffect(() => {
    //     if(refreshFilter === true) { setRefreshFilter(false); }
    // }, [refreshFilter]);
	
	const DocumentConfigCQB = async (dados?: any) =>{		
		return await UpdateCQB(
			'update_config', 
			{...dados, documentConfigList: dados.documentConfigList}
			//{...dados, documentConfigList :  defaultForm.getValues()?.documentConfigList?.map((e:any)=> parseInt(e))}
			);		
	};


	const UpdateCQB = async (acao: string, dados?: any, finish:any = null, timeOutFinish: any = null) =>{		
		try {
			let saveDados = {...dados};// as CqbDto;

			let keysToIgnore = ['documentConfig'];
			let formData = new FormData();
			for (var key in saveDados) {
				if (keysToIgnore.includes(key) || !saveDados[key]) { continue; }
				const valueByKey = saveDados[key];
				if(key === 'documentConfigList'){
					for(let i = 0, t = valueByKey.length; i < t; i ++){
						formData.append(`${key}[${i}]`, valueByKey[i]);  
					}					
					continue;
				}
				//const valueByKey = saveDados[key];				
				formData.append(key, saveDados[key]);
			}
			saveDados = await RCLpmaServiceApi.getRCLPMAAction(acao, formData);
			if(finish != null) {finish(true);}
			setRefreshFilter(true);
			setTimeout(() => {				
				if(timeOutFinish != null) {timeOutFinish();}						
			}, 3000);		

			return true;
		} catch (error:any) {
			if(finish != null) {finish(false);}
			console.error("erro", error);
			return false;
		}
	};

	const btnNumeroAutorizacao = (data:any)=>{
		if(!(RCLpmaRoles.isAdmin() || RCLpmaRoles.isRegulatorio())) { return; }
		return <DialogEditSingle label="Infomar Número de Autorização CIBIO" 	
		dlgTitle={<span className="singleTitle">{`${STRINGS_LPMA.MODO_NUMER_AUTORIZ_DLG}`}<br />
		{`Cod. Controle: ${data.numeroProcessoEAno}`}</span>}			
		isButtonType='add' 
		width25
		enabled={true}
		popData={data}
		onShow={(pdata:any)=>{
			setItemSel(pdata);		
			defaultForm.setValue("numeroAutorizacao", pdata.numeroAutorizacao);
		}}
		onConfirme={async ()=>{
			const idSel = defaultForm.watch('numeroAutorizacao');
			const sedData = {...itemSel, ...data, numeroAutorizacao: idSel}
			// return await DocumentConfigCQB(sedData);
			const updateCQB = await UpdateCQB(
				'update_config_num_aut', 
				{...sedData, numeroAutorizacao: sedData.numeroAutorizacao}
				);		
			return updateCQB;
		}}>						
		<TextField<RCLpmaDto> label={STRINGS_LPMA.MODO_NUMER_AUTORIZ}
		form={defaultForm} name="numeroAutorizacao"
		value={data.numeroAutorizacao}
		length={150}
		isClearable enabled={true} layoutColumns={12}  />				
		</DialogEditSingle>
	}

	const configButton = (isEditavel: boolean, data: any) =>{
		return <DialogEditSingle label="Configurar" 	
		dlgTitle={<><h5 style={{width:'100%'}}>{`${STRINGS_RCLPMA.MODO_CONFIGURAR_DLG}`}</h5>
		<h5>{`Nº Controle: ${data.numeroProcessoEAno}`}</h5></>}			
		isButtonType='config' 
		width50
		enabled={isEditavel}
		popData={data}
		onShow={(pdata:any)=>{	
			setItemSel(pdata);		
			defaultForm.setValue("documentConfigList", data.documentConfigList);
		}}
		onConfirme={async ()=>{
			const idSel = defaultForm.watch('documentConfigList')?.map((e:any)=> parseInt(e));;
			const sedData = {...itemSel, ...data, documentConfigList: idSel}
			return await DocumentConfigCQB(sedData);
		}}>
		<label>{STRINGS_RCLPMA.MODO_CONFIGURAR}</label>								
		{defaultData?.documentConfig?.map((e:listEnumItem, index: any)=>{
			return <CheckBox form={defaultForm}  name="documentConfigList"
			{...defaultForm.register(`documentConfigList`)}
			key={`chk-df-${index}`}
			layoutColumns={12}		
			label={e.nome} value={e.id}	
			enabled={isEditavel} 
			onChange={(chk: any)=>{e.checked = chk}}
			defaultChecked={data.documentConfigList?.includes(e.id)}
		/>})}				
		</DialogEditSingle>
	}
	const GerarAnexo = async (data:any, numero: number, numberByFileName: number) =>{
		//setLoadingButPdf(true); 
		setLoadingButAnx({
			an1 : (numero === 1) ?? loadingButAnx?.an1,
			an2 : (numero === 2) ?? loadingButAnx?.an2,
		})
		await RCLpmaServiceApi.DownloadRCLPMAPDF(data?.id, 
			(numberByFileName === 1? data?.documentoFileNameAnexo1 : data?.documentoFileNameAnexo2 ) ,
			`anexo-${numero}`, "A"); 
		//setLoadingButPdf(false);
		setLoadingButAnx({
			an1 : false,
			an2 : false,
		})
	}
	const GerarPDF = async (data:any) =>{
		setLoadingButPdf(false); 
		let filenameOriginal = data?.documentoFileName;
		let indiceExtensao = filenameOriginal.lastIndexOf(".pdf");
		let filename = filenameOriginal.substring(0, indiceExtensao);

		await RCLpmaServiceApi.DownloadRCLPMAPDF(data?.id, filename + " (CONFIDENCIAL).pdf", 'pdf', "C"); 
		await RCLpmaServiceApi.DownloadRCLPMAPDF(data?.id, filename + " (PÚBLICO).pdf", 'pdf', "P"); 
		setLoadingButPdf(true);
	}
    const AnexoButton = (isEditavel: boolean, data: any) =>{
		let estagio_ = data.status ?? STATUS.EmPreenchimento;
		let gerarPDF: boolean = !(RCLpmaRoles.isTecnicoPermissao() && estagio_ === STATUS.EmPreenchimento);
		let has2Anex: boolean = (data.hasAnexoTecnico ?? false);
		//(data.resumoResultadosConfidencial? true : false) && (data.fiscalizacaoConfidencial? true : false)

		return <DialogEditSingle label="Configurar" 	
		dlgTitle={<><h5 style={{width:'100%'}}>Selecione Anexo</h5>
		<h5>{`Nº Controle: ${data.numeroProcessoEAno}`}</h5></>}			
		isButtonType='custom' 
		CloseME={loadingButPdf}
		enabled={isEditavel}
		popData={data}
		width50
		hideFooter
		isButtonCustom={(tiggerOnClick: any)=>{
			const isItemConfidencial = data.resumoResultadosConfidencial || data.fiscalizacaoConfidencial;
			if(!isItemConfidencial){
				return <>
				<PDFButton disabled={!gerarPDF} isLoading={loadingButPdf} tooltipTxt='Gerar Documentos'  onClick={async ()=> await GerarPDF(data)} />				
			</>
			}
			const n = (data.resumoResultadosConfidencial ? 1 : 0) + (data.fiscalizacaoConfidencial ? 1 : 0);
			return <>
				<PDFButton disabled={!gerarPDF} tooltipTxt='Gerar Documentos e Anexos'  onClick={tiggerOnClick} />
				<sup>{n}</sup>				
			</>
		}}
		onConfirme={async ()=>{
			// const idSel = defaultForm.watch('documentConfigList')?.map((e:any)=> parseInt(e));;
			// const sedData = {...itemSel, ...data, documentConfigList: idSel}
			// return await DocumentConfigCQB(sedData);
		}}>	
			<div style={{margin: 'auto' , paddingTop:'10px'}}>
				<Button
					title="GERAR RELATÓRIO CONCLUSIVO"
					isLoading={loadingButPdf}
					contained={true}
					className="btn-primary"
					type="button" 
					onClick={async ()=> await GerarPDF(data) }/>

				&nbsp;&nbsp;
				{data.resumoResultadosConfidencial?<>
				<Button
					isLoading={loadingButAnx?.an1}
					title="GERAR ANEXO I"
					contained={true}
					className="btn-danger"
					type="button" 
					onClick={async ()=> await GerarAnexo(data, 1, 1) }/>
				&nbsp;&nbsp;</>
				: ''}
				{data.fiscalizacaoConfidencial?<>
				<Button
					isLoading={loadingButAnx?.an2}
					title={(has2Anex ? "GERAR ANEXO II" : "GERAR ANEXO I")}
					contained={true}
					className="btn-primary"
					type="button" 
					onClick={async ()=> await GerarAnexo(data, 2, (has2Anex?2:1)) }/>
				&nbsp;&nbsp;</>
				: ''}
			</div>
								

		</DialogEditSingle>
	}
	
	

	const Filtro = () => {
		if(RCLpmaRoles.isTecnicoPermissao()){
			const tecnico = TecnicoLogado();	

			return [
				<TextField name="solicitante" label="Solicitante/Regulatório" form={defaultForm}
				defaultValue="" key={uuid()}
				isClearable
				enabled={RCLpmaRoles.isTecnicoPermissao()}
				layoutColumns={3}  />,
		
				<TextField form={defaultForm} layoutColumns={6} name="titulo" 
				label="Título" required={false}  isClearable={true} key={uuid()}
				/>,
		
				<TextField form={defaultForm} layoutColumns={3} name="numeroAutorizacao" 
				label="Número de Controle" required={false}  isClearable={true} key={uuid()}
				/>
				,
				<DropDown name="tecnicoPrincipal" label="Tecnico Responsável" layoutColumns={3} form={defaultForm}				
				options={defaultData?.equipeTecnica?.map((i:any) => Object.assign({...i, value: i.id, label: i.nome }))}
				isClearable={false} enabled={false}
				defaultValue={tecnico}
				/>,
				<DropDown name="status" label="STATUS do R.C." layoutColumns={4} form={defaultForm}
				options={STATUS_RC_LPMA.map((val:any, index: any)=>  Object.assign({ value: val.s, label: val.t } ) )}
				isClearable={true}
				/>	
			 ];
		}
		return [
			<TextField name="solicitante" label="Solicitante/Requisitante" form={defaultForm}
			defaultValue={nomeSolicitante()} key={uuid()}
			isClearable
			enabled={RCLpmaRoles.isAdmin() || RCLpmaRoles.isRegulatorio()}
			layoutColumns={3}  />,
	
			<TextField form={defaultForm} layoutColumns={6} name="titulo" 
			label="Título" required={false}  isClearable={true} key={uuid()}
			/>,
	
			<TextField form={defaultForm} layoutColumns={3} name="numeroControle" 
			label="Número de Controle" required={false}  isClearable={true} key={uuid()}
			/>
			,
			<DropDown name="tecnicoPrincipal" label="Tecnico Responsável" layoutColumns={3} form={defaultForm}
			options={defaultData?.equipeTecnica?.map((i:any) => Object.assign({ value: i.id, label: i.nome }))}
			isClearable={true}
			/>,
			<DropDown name="status" label="STATUS do R.C." layoutColumns={4} form={defaultForm}
			options={STATUS_RC_LPMA.map((val:any, index: any)=>  Object.assign({ value: val.s, label: val.t } ) )}
			isClearable={true}
			/>	
		 ];
	}

	return <ListingView<RCLpmaDto>
	// ModalPopups = {CancelPopUp()}
	title	=	{STRINGS_RCLPMA.SEARCH_RCLPMA}	
	ButtonAddText={STRINGS_RCLPMA.NEW_RCLPMA}
	form={defaultForm}
	filterRefresh={refreshFilter}
	filterRefreshFinish={()=> setRefreshFilter(false)}
	OnInit={async ()=>{
			const equipeTecnica = await LPMAServiceApi.getEquipeTecnica();
			const locais		= await LPMAServiceApi.getLocais();
			const projetos		= await LPMAServiceApi.getProjetos();
			const defaultDataR 	= await RCLpmaServiceApi.getDefaultData();
			const dd = {
				equipeTecnica	: equipeTecnica,
				locais			: locais,
				projetos		: projetos,
				documentConfig	: defaultDataR.documentConfig,
				// tipoCqb			: defaultDataR.tipoCqb,
				// atividades		: defaultDataR.atividades,
				// finalidades		: defaultDataR.atividades,
			};

			setDefaultData(dd);
			defaultForm.reset({});	
			setRefreshFilter(true);
			return dd;
	}}
	onFilterSearch = {async (queries?: any)=>{
		setRefreshFilter(false);
		let filtro = {
			tipo					: defaultForm.getValues('tipo')?.value,
			localId               	: defaultForm.getValues('local')?.value,
			tecnicoPrincipalId      : defaultForm.getValues('tecnicoPrincipal')?.value,
			status					: defaultForm.getValues('status')?.value,
			solicitante				: defaultForm.getValues('solicitante'),
			numeroAutorizacao		: defaultForm.getValues('numeroControle'),
			titulo					: defaultForm.getValues('titulo'),
		}; //as CqbDto;

		if(RCLpmaRoles.isTecnicoPermissao()){
			filtro.tecnicoPrincipalId = TecnicoLogado()?.id ?? 0;
		}

		console.log(filtro);

		return await RCLpmaServiceApi.getList(filtro);
	}}
	ShowButtonAddNew = {RCLpmaRoles.isRegulatorio()}
	onButtonAddEvent = {()=>{ history.push("/rclpma/nova") }}
	filters={Filtro()}
	 onListItemActionsButtons = {( data : any) =>{
		let estagio_ = data.status ?? STATUS.EmPreenchimento;
		let isEditavel = (RCLpmaRoles.isRegulatorio() 
			&& (estagio_ === STATUS.EmAnalisePeloRegulatorio || estagio_ === STATUS.EmPreenchimento))
		|| (RCLpmaRoles.isTecnico()
			&& estagio_ === STATUS.EmCorrecao)
		|| RCLpmaRoles.isAdmin();

		let isCancel = 	(RCLpmaRoles.isRegulatorio() || RCLpmaRoles.isAdmin()) && estagio_ !== STATUS.Cancelado;

		return (
				<Fragment>			
					<EditButton disable={!isEditavel} onClick={() => goUrl(`/rclpma/editar/${data.id}`)} />
					&nbsp;&nbsp;
					<ViewButton onClick={() => goUrl(`/rclpma/visualizar/${data.id}`)} />
					
					&nbsp;&nbsp;					
					{configButton(isEditavel, data)}
					
					&nbsp;&nbsp;
					{AnexoButton(isEditavel, data)}
					
				</Fragment>);
	 }}	
	actionsCellStyle ={{ justifyContent: 'left', fontSize: '0.85rem' }}
	>		

	<ListCol header='Nº Relatório' field='numeroProcessoEAno' maxWidth={100}
		cellStyle={{fontSize: '0.85rem'}} headerClass="myfunc-default-header" />	

	<ListCol header='LPMA/Projeto- Titulo' minWidth={275} valueFormatter={({ data }: any) => {
			const rnN = (data.lpmarn === 'rn35' ? 'LPMA RN 35' : 'LPMA RN 06');
			const prj = (data?.projeto ? ` (${data?.projeto?.nome} - ${data?.projeto?.cultura}) ` : '');
			return `${rnN}${prj} ${data.titulo}`			
		}} 
		field='Titulo' wrapText	headerClass="myfunc-default-header" 
		cellStyle={{ 
			justifyContent: 'left',
			fontSize: '0.85rem'
			}}  />	

	<ListCol
		header='Código LPMA' 
		field='codigoLPMA'
		maxWidth={100}
		wrapText headerClass="myfunc-default-header"
		cellStyle={{
			justifyContent: 'left',
			fontSize: '0.85rem'
		}} />	

	<ListCol
		header='Regulatório'
		field='solicitante'
		headerClass="myfunc-default-header"
		cellStyle={{fontSize: '0.85rem'}} 	
	/>

	<ListCol
		header='Tecnico Responsável'
		headerClass="myfunc-default-header"
		field='tecnicoPrincipal'
		valueFormatter={({ data }: any) => data?.tecnicoPrincipal?.nome ?? ''}
		cellStyle={{
			justifyContent: 'left',
			fontSize: '0.85rem'
		}}
	/>	

	<ListCol
		header='Data da Aprovação'
		field='dataAprovacao'
		headerClass="myfunc-default-header"
		maxWidth={110}
		cellStyle={{
			fontSize: '0.75rem'
		}}
		valueFormatter={({ data }: any) => data.dataAprovacao ? _generateDate(data.dataAprovacao, 'Pt') : ''}
	/>	

	<ListCol
		header='Nº Processo CIBIO'
		headerClass="myfunc-default-header"
		field='numeroAutorizacao'
		cellRenderer={({data}:any)=>{
			if(data.status === STATUS.Aprovado){// && (!data.numeroAutorizacao || data.numeroAutorizacao?.length === 0)){
				return <span>{data.numeroAutorizacao}&nbsp;&nbsp;{btnNumeroAutorizacao(data)}</span>;
			}
			return "";
		}}
		minWidth={140}
		cellStyle={{
			fontSize: '0.85rem',
			justifyContent: (RCLpmaRoles.isAdmin() || RCLpmaRoles.isRegulatorio()) ? 'right' : 'center'
		}}
	/>
	
	<ListCol
		header='Status'
		headerClass="myfunc-default-header"
		field='status'
		wrapText
		minWidth={150}
		cellStyle={{
			fontSize: '0.85rem'
		}}
		valueFormatter={({ data }: any) =>  STATUS_RC_LPMA.find(a=> a.s === (data.status ?? 1))?.t }
	/>	

		<ListCol header='id' field='id' sort="asc" 
		cellStyle={{ fontSize: '0.85rem' }} headerClass="myfunc-default-header" />	

	</ListingView>
}
