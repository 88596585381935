import React, { useState } from "react";
import { Form, Col } from "react-bootstrap";
import CheckMsgErros from './_msgErrs';

import "./style.scss";
import DialogEdit from "./DialogEdit";
import * as EditButtons from "../../components/EditButton";

interface FileUploadProps
{
    form?       :any;
	name?       :string;
    otherName?  :string;
	label       :string;
	placeholder?:string;
	control?    :any;	
	layoutColumns?: number;
    required?   : boolean;
    textButton? : string;
    onChange?   : any;
    multiple?   : boolean;
    text?       : string;
    enabled?    :boolean;
    key?		: any;
    defaultValue?: any;
    isInvalidCheck? : boolean;
    adicionalText? : string;
    accept? : string;
    preview?    : boolean;
    urlpreview? : string | undefined | null;
    isClearable?: boolean;
    clearRightPosition  ?: number;
    onDownloadUrl? : (url:string | null | undefined)=>void;
}

export default function FileUpload(props: FileUploadProps){
    
    const [arquivo, setArquivo] = useState('');
    const [enabled_, setEnabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }        
    }, [props.enabled]);
    
    
    const [asErro, setAsErro] = React.useState<boolean>(true);
	const [msgError, setMsgError] = React.useState<any>();

    const [localPreeview, setLocalPreeview] = React.useState<string|null>(null);
    const [localBlobFile, setLocalBlobFile] = React.useState<any|null>(null);
    const [clearAction, setClear] = React.useState<Boolean>(false);
    
    React.useEffect(() => {
		CheckMsgErros(props, (msg:any)=>{ setMsgError(msg) }, (err:any)=>{ setAsErro(err) }, props.otherName)
    }, [props.form?.errors, props?.name, props?.otherName]);

    React.useEffect(() => {
        if(props?.text != null)  { setArquivo(props?.text) }
    }, [props?.text]);
    
    const ClearButton = ()=>{
		//if(!enabled_ || props.isClearable === false || !props.isClearable) {return ''; }
        if(!enabled_) return '';
        if(!arquivo || arquivo === '')  {return ''; }
        return <EditButtons.ClearButton disable={false} tooltip='Remover Arquivo'  onClick={() => { Clear()  }} />  
	}

    const PreeViewButtonOnClick = () =>{        
        const blobF = localBlobFile as any;
        let blob = new Blob([blobF], { type: 'application/pdf' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        if (typeof a.download === "undefined") { 	window.location.href = url; }
        else {
            a.href = url; a.download = arquivo ?? `Download`;
            document.body.appendChild(a);
            a.click();
        }
    }
    const PreeViewButton = () =>{
        const buttonAdd = <EditButtons.DownloadButton disable={false} tooltip='Download'  onClick={PreeViewButtonOnClick} />  
        if(!(!localBlobFile)) return buttonAdd;
        if(!(!props.urlpreview) &&  !(!props.onDownloadUrl) && !clearAction){
            return <EditButtons.DownloadButton disable={false} tooltip='Download'  onClick={()=> {
                if(props.onDownloadUrl){
                    props.onDownloadUrl(props.urlpreview)}
                }                
            } />  
        }
        return '';
    }

    const Clear = () =>{
		if(props.form == null) return;
		if(props.name == null) return;
		props.form?.setValue(`${props.name}`, null);       
        props.form?.setValue(`${props.name}Remove`, 'OK');   
        setArquivo('');
        if(props.onChange) {
            props.onChange(null, null)
        }  
        setLocalBlobFile(null);
        setLocalPreeview(""/*URL.createObjectURL(target.files[0])*/);
        setClear(true);
	}
    return  <Form.Group className="custum_FileUploadMain" as={Col} md={props.layoutColumns ?? 6}>
                <Form.Label>{props.label} {props.required ? <em>*</em> : ''}</Form.Label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <Form.File            
                    key={props.key}
                    defaultValue={props.defaultValue}
                    ref={props.form?.register}
                    name={props.name}
                    className="custum_FileUpload"
                    label={(arquivo !== '' ? arquivo : 'Escolher arquivo')}
                    // label={props.text ?? (arquivo !== '' ? arquivo : 'Escolher arquivo') }
                    multiple={props.multiple ?? false}
                    data-browse={props.textButton ?? 'Upload'}
                    custom
                    disabled={!enabled_}     
                    accept={props.accept}
                    onChange={({ target }: any) => {
                        if (!target.value) {
                            return
                        }                             
                        let names = ''
                        for(var i = 0, t = target.files.length; i < t; i++){
                            names += target.files[i].name;
                            if(i < (t - 1)) { names += ',' }
                        }                   
                        props.form?.setValue(`${props.name}`, target.files);        
                        setArquivo(names);
                        if(props.onChange) {
                            props.onChange(target, names)
                        }  
                        setLocalPreeview(URL.createObjectURL(target.files[0]));
                        setLocalBlobFile(target.files[0]);
                        //setLblRelatorioFotoContencao(`${target.files[0].name}`);
                    }}
                    isInvalid={(props.isInvalidCheck && asErro && enabled_)}
                />                
                <div className="previewIcon" >
                    {props.preview ? PreeViewButton() : ''}     
                    {props.isClearable? ClearButton() : ''}
                </div>
                {/* {ClearButton()}            */}
                {props.adicionalText ? <span className="small text-info">{props.adicionalText}</span> : ''}
                {/* <Form.Control.Feedback type="invalid">					
                    {props?.name ?props.form?.errors[props?.name]?.message : ''}
                </Form.Control.Feedback> */}
                <Form.Control.Feedback type="invalid" style={{display: (asErro ? 'inline' : 'none')}}>					
                    {props?.name ? (msgError ??  props.form?.errors[props?.name]?.message) : ''}
                </Form.Control.Feedback>
                        
            </Form.Group>
}
