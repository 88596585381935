import { PERMISSIONS } from './usersPermissions/usersPermissionsCultivares';

export const isRequesterReadProtection = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.ReqProtecaoRead);

    return isRole.length ? true : false;
}

export const isRequesterReadRegister = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.ReqRegistroRead);

    return isRole.length ? true : false;
}

export const isRequesterWriteProtection = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.ReqProtecaoNovo)

    return isRole.length ? true : false;
}

export const isRequesterWriteRegister = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.ReqRegistroNovo)

    return isRole.length ? true : false;
}

export const isOnlyRequesterRegister = (roles: string[]): boolean => {
    const admin = roles.includes(PERMISSIONS.Admin);
    const regulador = roles.includes(PERMISSIONS.ReqRegistroRegulador);
    const avaliador = roles.includes(PERMISSIONS.ReqRegistroAvaliarDescritor) || roles.includes(PERMISSIONS.ReqRegistroAvaliarDoenca);
    const registroLeituraCriacao = roles.includes(PERMISSIONS.ReqRegistroRead) || roles.includes(PERMISSIONS.ReqRegistroNovo);

    return registroLeituraCriacao && !admin && !regulador && !avaliador;
}

export const isDescritor = (roles: string[]): boolean => {
    return roles.includes(PERMISSIONS.ReqRegistroAvaliarDescritor);
}

export const isAvaliadorDoenca = (roles: string[]): boolean => {
    return roles.includes(PERMISSIONS.ReqRegistroAvaliarDoenca);
}
