import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import LoadingInForm from './LoadingInForm';
import If from './If';

interface Props {
    show: boolean;
    title: any;
    children: any;
    onHide: any;
    confirmationMsg: string;
    onConfirm: any;
    loading?: boolean;
    variant?: string;
    color?: string;
    disabled?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    cancelMsg?: string;
    hideFooter?: boolean;
}
function ConfirmationModal({ show, title, onHide, children, confirmationMsg, onConfirm, loading, variant, color, disabled, type, cancelMsg, hideFooter }: Props) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            animation={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <If condition={!hideFooter}>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={onHide}>
                        {cancelMsg ? cancelMsg : "Cancelar"}
                    </Button>
                    <Button type={type ? type : "button"} disabled={disabled || loading} variant={variant ? variant : "danger"} onClick={onConfirm}>
                        {loading ? <LoadingInForm color={color} /> : confirmationMsg}
                    </Button>
                </Modal.Footer>
            </If>
        </Modal>
    );
}

export default ConfirmationModal;