import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../components/Button';
import Fieldset from '../../../components/Fieldset';
import If from '../../../components/If';
import LoadingInForm from '../../../components/LoadingInForm';
import EmptyInfoArray from '../../../components/EmptyInfoArray';
import { STRINGS } from '../../../util/strings';
import { STRINGS_CIBIO } from '../../../util/authorizationsCIBIO/string';
import Loading from '../../../components/Loading';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from 'react-datepicker';
import jwtDecode from 'jwt-decode';
import { Context } from '../../../context/AuthContext'
import { DecodedToken } from '../../../models/user';
import {
	DestinatarioResult,
	UnityStationResult,
	TransporteAMIById,
	EventResult,
	AMIForm,
	EventoAMIById,
	CultureResult,
	Enum,
	StateType,
	AMIById
} from '../../../models/modelsAuthorizationsCIBIO';
import api from '../../../services/api';
import { STATUS_CIBIO } from '../../../util/authorizationsCIBIO/status';
import { isAdmin } from '../../../users/AuthorizationsCIBio/isAdmin';
import { isReguladorWriter } from '../../../users/AuthorizationsCIBio/isRegulador';
import { DecimalAsComma } from '../NumberFormat';
import { _generateDate } from '../../../util/convert';

registerLocale('pt-BR', ptBR);

export default function AMI ()
{
	const history = useHistory();
	const location = useLocation<StateType>();
	const { token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);
	const [loadingGetData, setLoadingGetData] = useState<boolean>(true);
	const [errorGetData, setErrorGetData] = useState<any>(null);
	const [locations, setLocations] = useState<UnityStationResult[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [transport, setTransport] = useState<Enum[]>([]);
	const [selectedTransports, setSelectedTransports] = useState<TransporteAMIById[]>([]);
	const [selectedEvents, setSelectedEvents] = useState<EventoAMIById[]>([]);
	const [statusAuthorization, setStatusAuthorization] = useState<number>();
	const [gridProps, setGridProps] = useState<any>(null);
	const [modalEnvio, setModalEnvio] = useState<boolean>(false);
	const [modalRecebimentos, setModalRecebimentos] = useState<boolean>(false);


	const amiSchema = yup.object({
		localDeEnvio: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		destinatario: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		localDeDestino: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		possuiCQB: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		modoDeTransporte: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		origem: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		objetivoUtilizacao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		observacaoPosAprovacao: yup.string(),
		culturaOrganismo: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		observacoes: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		trechoLPMA: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		escopoLPMA: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		embalagemDupla: yup.mixed(),
		identificacaoComSimboloRiscoBiologico: yup.mixed(),
		responsavelTransporte: yup.string(),
		dataEnvio: yup.date(),
		materialPerfeitasCondicoes: yup.mixed(),
		qtdRecebidaDeAcordoQtdEnviada: yup.mixed(),
		responsavelRecebimento: yup.string(),
		dataRecebimento: yup.date(),
		dataCriacao: yup.date(),
		id: yup.number(),
		status: yup.string(),
		numeroAutorizacaoCibio: yup.number(),
		dataEmissaoCibio: yup.date(),
		dataAprovacao: yup.date(),
		observacoesAnaliseRegulatorio: yup.string(),
		autorCancelamento: yup.string(),
		dataCancelamento: yup.date(),
	});

	const initialValues: AMIForm = {
		requisitante:'',
		localDeEnvio: undefined,
		destinatario: '',
		listaDestinatario: undefined,
		localDeDestino: undefined,
		possuiCQB: "1",
		modoDeTransporte: undefined,
		origem: '',
		objetivoUtilizacao: '',
		observacaoPosAprovacao: '',
		culturaOrganismo: undefined,
		observacoes: '',
		trechoLPMA: '',
		escopoLPMA: '1',
		embalagemDupla: '1',
		identificacaoComSimboloRiscoBiologico: '1',
		responsavelTransporte: '',
		dataEnvio: undefined,
		materialPerfeitasCondicoes: '1',
		qtdRecebidaDeAcordoQtdEnviada: '1',
		responsavelRecebimento: '',
		dataRecebimento: undefined,
		id: undefined,
		status: '',
		numeroAutorizacaoCibio: undefined,
		dataEmissaoCibio: undefined,
		dataAprovacao: undefined,
		observacoesAnaliseRegulatorio: '',
		autorCancelamento: '',
		dataCancelamento: undefined,
		motivoCancelamento: '',
	}

	const { register, reset, setValue, getValues, errors, trigger, handleSubmit, control, watch, formState: { isDirty } } = useForm({
		defaultValues: initialValues,
		mode: 'onSubmit',
		resolver: yupResolver(amiSchema)
	});

	useEffect(() => {
		const getAMI = async () => {
			try {
				const { data, status } = await api.get<AMIById>(`api/v1/autorizacoes-cibio/MovimentacaoInterna/${location.state.id}`);

				if (status === 200) {
					reset({
						dataCriacao: moment(data.dataCriacao).toDate(),
						status: data.statusDescricao,
						id: data.id,
						localDeEnvio: {
							label: data.localPartida?.nome,
							value: data.localPartida?.id,
						},
						destinatario: data?.destinatario,
						localDeDestino: {
							label: data.localDestino?.nome,
							value: data.localDestino?.id,
						},
						modoDeTransporte: {
							label: data.modoTransporteDescricao,
							value: data.modoTransporteId,
						},
						origem: data.origem,
						objetivoUtilizacao: data.objetivoUtilizacao,
						observacaoPosAprovacao: data.observacaoPosAprovacao,
						culturaOrganismo: {
							label: data.cultura?.nome,
							value: data.cultura?.id,
						},
						observacoes: data.observacoes,
						trechoLPMA: data.trechoLPMALocaisMovimentacaoMaterial,
						numeroAutorizacaoCibio: data.codigo,
						dataEmissaoCibio: data.dataEmissaoCibio ? moment(data.dataEmissaoCibio).toDate() : null,
						dataAprovacao: data.dataAprovacao ? moment(data.dataAprovacao).toDate() : null,
						observacoesAnaliseRegulatorio: data.observacoesAnaliseRegulatorio,
						responsavelTransporte: data.responsavelTransporte,
						dataEnvio: data.dataEnvio ? moment(data.dataEnvio).toDate() : null,
						responsavelRecebimento: data.responsavelRecebimento,
						dataRecebimento: data.dataRecebimento ? moment(data.dataRecebimento).toDate() : null,
						possuiCQB: String(data.localDestinoPossuiCQB),
						escopoLPMA: String(data.escopoLPMAContemplaMaterial),
						embalagemDupla: String(data.embalagemDuplaImpermeavel),
						identificacaoComSimboloRiscoBiologico: String(data.identificacaoSimboloRiscoBiologico),
						materialPerfeitasCondicoes: String(data.materialPerfeitasCondicoes),
						qtdRecebidaDeAcordoQtdEnviada: String(data.qtdRecebidaDeAcordoQtdEnviada),
						autorCancelamento: data.autorCancelamento,
						dataCancelamento: data.dataCancelamento ? moment(data.dataCancelamento).toDate() : null,
						motivoCancelamento: data.motivoCancelamento,
						requisitante: data.requisitante,
					});
					setStatusAuthorization(data.status);

					if(data.eventos.length) {
						setSelectedEvents(data.eventos);
					}

					if (data.transportes.length) {
						setSelectedTransports(data.transportes);
					}

				}
			} catch (error:any) {
				setErrorGetData(error?.response?.data);
			}
			setLoadingGetData(false);
		}

		getAMI();
	}, [location.state.id, reset]);

	return <div className="container container-fluid">
		<If condition={loadingGetData}>
			<span className="loading-page">
				<Loading />
			</span>
		</If>
		<If condition={!loadingGetData}>
			<h3>{STRINGS_CIBIO.EDITION_AMI}</h3>
			<Form autoComplete="off">
				<Fieldset disabled title={STRINGS_CIBIO.AMI_BLOCK_ONE}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={3}>
							<Form.Label>
								Número da Solicitação
							</Form.Label>
							<Form.Control
								ref={register}
								readOnly
								name="id"
								maxLength={10}
							/>
						</Form.Group>
						<Form.Group as={Col} md={3}>
							<Form.Label>
								Status
							</Form.Label>
							<Form.Control
								ref={register}
								name="status"
								readOnly
								maxLength={30}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data de Criação
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataCriacao"
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										disabled
									/>
								)}
							/>
						</Form.Group>
						<If condition="watchEvent().motivoCancelamento != ''">
							<Form.Group as={Col} md={12}>
								<Form.Label>
									Motivo do Cancelamento
								</Form.Label>
								<Form.Control
									ref={register}
									as="textarea"
									name="motivoCancelamento"
									readOnly
									maxLength={2000}
								/>
							</Form.Group>
						</If>
					</Form.Row>
					<If condition={statusAuthorization === STATUS_CIBIO.Cancelado}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Autor Cancelamento
								</Form.Label>
								<Form.Control
									ref={register}
									name="autorCancelamento"
									readOnly
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Data Cancelamento
								</Form.Label>
								<br />
								<Controller
									control={control}
									name="dataCancelamento"
									render={({ onChange, value }) => (
										<DatePicker
											disabled
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
										/>
									)}
								/>
							</Form.Group>
						</Form.Row>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Solicitante/Requisitante
							</Form.Label>
							<Form.Control
								ref={register}
								readOnly
								name="requisitante"
								maxLength={100}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Local Envio/Partida
								&nbsp;&nbsp;
								<If condition={loadingGetData}>
									<LoadingInForm />
								</If>
							</Form.Label>
							<Controller
								control={control}
								name="localDeEnvio"
								isInvalid={errors.localDeEnvio !== undefined}
								as={<Select
									isDisabled={true}
									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
									isClearable
								/>}
							/>
							<If condition={errors.localDeEnvio}>
								<p className="error-field">{errors.localDeEnvio?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Destinatário
							</Form.Label>
							<Form.Control
								ref={register}
								name="destinatario"
								isInvalid={errors.destinatario !== undefined}
								type="text"
								maxLength={150}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.destinatario?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Local Destino
								&nbsp;&nbsp;
						<If condition={loadingGetData}>
									<LoadingInForm />
								</If>
							</Form.Label>
							<Controller
								control={control}
								name="localDeDestino"
								isInvalid={errors.localDeDestino !== undefined}
								as={<Select
									isDisabled={true}
									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
									isClearable
								/>}
							/>
							<If condition={errors.localDeDestino}>
								<span className="error-field">{errors.localDeDestino?.message}</span>
							</If>
						</Form.Group>
					</Form.Row>
					<p />
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
				<Form.Label>
							O local de destino possui CQB?
				</Form.Label>
				&nbsp;&nbsp;
				<Form.Check
							ref={register}
							type="radio"
							name="possuiCQB"
							label="Sim"
							value={2}
						/>
				&nbsp;&nbsp;
				<Form.Check
							ref={register}
							type="radio"
							name="possuiCQB"
							aria-label="radio 2"
							label="Não"
							value={1}
						/>
				&nbsp;&nbsp;
				<Form.Check
							ref={register}
							type="radio"
							name="possuiCQB"
							aria-label="radio 3"
							label="Não se aplica"
							value={3}
						/>
					</Form.Row>
					<p />
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Modo de transporte
							</Form.Label>
							<Controller
								control={control}
								name="modoDeTransporte"
								isInvalid={errors.modoDeTransporte}
								as={<Select
									isDisabled={true}
									options={transport.map(transp => Object.assign({ label: transp.value, value: transp.key }))}
									isClearable
								/>}
							/>
							<If condition={errors.modoDeTransporte}>
								<p className="error-field">{errors.modoDeTransporte?.message}</p>
							</If>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Origem
							</Form.Label>
							<Form.Control
								ref={register}
								name="origem"
								isInvalid={errors.origem !== undefined}
								maxLength={150}
							/>
							<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_ORIGEM_HELP_TEXT}</Form.Text>
							<Form.Control.Feedback type="invalid">
								{errors.origem?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Objetivo da utilização
							</Form.Label>
							<Form.Control
								ref={register}
								name="objetivoUtilizacao"
								as="textarea"
								rows={4}
								isInvalid={errors.objetivoUtilizacao !== undefined}
							/>
							<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_OBJETIVO_HELP_TEXT}</Form.Text>
							<Form.Control.Feedback type="invalid">
								{errors.objetivoUtilizacao?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Objetivo da utilização
							</Form.Label>
							<Form.Control
								ref={register}
								name="objetivoUtilizacao"
								as="textarea"
								rows={4}
								isInvalid={errors.objetivoUtilizacao !== undefined}
							/>
							<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_OBJETIVO_HELP_TEXT}</Form.Text>
							<Form.Control.Feedback type="invalid">
								{errors.objetivoUtilizacao?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observação Pós Aprovação
							</Form.Label>
							<Form.Control
								ref={register}
								name="observacaoPosAprovacao"
								as="textarea"
								rows={4}
								isInvalid={errors.objetivoUtilizacao !== undefined}
							/>
							<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_OBJETIVO_HELP_TEXT}</Form.Text>
							<Form.Control.Feedback type="invalid">
								{errors.observacaoPosAprovacao?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>
								Evento{`  `}
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={!selectedEvents.length}>
						<EmptyInfoArray />
					</If>
					<If condition={selectedEvents.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedEvents}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
							>
								<AgGridColumn
									headerName='Evento'
									field="eventoNome"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
									minWidth={250}
								/>
								<AgGridColumn
									headerName='Quantidade Autorizada'
									field="qtdAutorizada"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => {return DecimalAsComma(data.qtdAutorizada);}}
								/>
								<AgGridColumn
									headerName='Unidade Medida Autorizada'
									field="unidadeMedidaAutorizadaDescricao"
									headerClass="myfunc-default-header"
								/>
								<AgGridColumn
									headerName='Quantidade Transportada'
									field="qtdTransportada"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => {return DecimalAsComma(data.qtdTransportada);}}
								/>
								<AgGridColumn
									headerName='Unidade Medida Transportada'
									field="unidadeMedidaTransportadaDescricao"
									headerClass="myfunc-default-header"
								/>
								<AgGridColumn
									headerName='Material'
									field="materialDescricao"
									headerClass="myfunc-default-header"
								/>
							</AgGridReact>
						</div>
					</If>
					<br />
					<br />
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Cultura/Organismo
							</Form.Label>
					&nbsp;&nbsp;
					<If condition={loadingGetData}>
								<LoadingInForm />
							</If>
							<Controller
								control={control}
								name="culturaOrganismo"
								isInvalid={errors.culturaOrganismo}
								as={<Select
									isDisabled={true}
									options={cultures.map(culture => Object.assign({ label: culture.nome, value: culture.id }))}
									isClearable
								/>}
							/>
							<If condition={errors.culturaOrganismo}>
								<p className="error-field">{errors.culturaOrganismo?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observações
							</Form.Label>
							<Form.Control
								ref={register}
								name="observacoes"
								as="textarea"
								rows={5}
								maxLength={2000}
								isInvalid={errors.observacoes !== undefined}
							/>
							<If condition={errors.observacoes}>
								<Form.Control.Feedback type="invalid">
									{errors.observacoes?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Transcrever o trecho da LPMA em que constam os locais contemplados para movimentação de material
							</Form.Label>
							<Form.Control
								ref={register}
								name="trechoLPMA"
								as="textarea"
								rows={4}
								maxLength={4000}
								isInvalid={errors.trechoLPMA !== undefined}
							/>
							<If condition={errors.trechoLPMA}>
								<Form.Control.Feedback type="invalid">
									{errors.trechoLPMA?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>
								O escopo da LPMA, CQB ou Permissão de Importação contempla a referida atividade com material/cultura objeto da solicitação?
							</Form.Label>
							<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_ESCOPO_LPMA_HELP_TEXT}</Form.Text>
						</Form.Group>
						<br />
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="escopoLPMA"
							type="radio"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="escopoLPMA"
							type="radio"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="escopoLPMA"
							type="radio"
							label="Não se aplica"
							value={3}
						/>
						<br />
						<If condition={errors.escopoLPMA}>
							<p className="error-field">{errors.escopoLPMA?.message}</p>
						</If>
					</Form.Row>
				</Fieldset>

				<Fieldset disabled title={STRINGS_CIBIO.AMI_BLOCK_TWO}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Nº Autorização CIBio
							</Form.Label>
							<Form.Control
								readOnly
								ref={register}
								name="numeroAutorizacaoCibio"
								maxLength={20}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data Emissão CIBio
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataEmissaoCibio"
								render={({ onChange, value }) => (
									<DatePicker
										disabled
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										maxDate={new Date()}
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data da Aprovação
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataAprovacao"
								render={({ onChange, value }) => (
									<DatePicker
										disabled
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										maxDate={new Date()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observações da Análise do Regulatório
							</Form.Label>
							<Form.Control
								ref={register}
								readOnly={!(statusAuthorization !== STATUS_CIBIO.EmAnalisePeloRegulatorio && !(isAdmin(decoded.roles) || isReguladorWriter(decoded.roles)))}
								name="observacoesAnaliseRegulatorio"
								as="textarea"
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>

				<Fieldset title={STRINGS_CIBIO.AMI_BLOCK_THREE}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Informações do Envio<em>*</em>{`  `}
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
						<AgGridReact
							rowData={selectedTransports}
							defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
							gridOptions={{ headerHeight: 80 }}
							stopEditingWhenGridLosesFocus={true}
							singleClickEdit={true}
						>
							<AgGridColumn
								headerName='Responsável pelo Transporte'
								valueGetter={({ data }: any) => data.responsavelTransporte}
								cellStyle={{
									justifyContent: 'left'
								}}
								autoHeight
								wrapText
								minWidth={250}
							/>
							<AgGridColumn
								headerName='Data do Envio'
								field="dataEnvio"
								headerClass="myfunc-default-header"
								valueFormatter={({ data }: any) => data.dataEnvio ? _generateDate(data.dataEnvio, 'Pt') : ''}

							/>
							<AgGridColumn
								headerName='Local de Recebimento'
								valueGetter={({ data }: any) => data.localDestinoNome}
								cellStyle={{
									justifyContent: 'left'
								}}

							/>
							<AgGridColumn
								headerName='Quantidade Transportada'
								field="qtdTransportada"
								type="number"
								headerClass="myfunc-default-header"
								valueFormatter={({ data }: any) => { return DecimalAsComma(data.qtdTransportada); }}
							/>
							<AgGridColumn
								headerName='Unidade Medida Transportada'
								field="unidadeMedidaTransportadaDescricao"
								headerClass="myfunc-default-header"
								valueFormatter={({ data }: any) => {

									if (data?.unidadeMedidaTransportada?.label == 'Outros')
										return `Outros (${data.unidMedTranspOutros})`;

									return data?.unidadeMedidaTransportada?.label;

								}}
							/>
						</AgGridReact>
					</div>
				</Fieldset>

				<Fieldset title={STRINGS_CIBIO.AMI_BLOCK_FOUR}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Informações do Recebimento<em>*</em>{`  `}
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
						<AgGridReact
							rowData={selectedTransports}
							defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
							gridOptions={{ headerHeight: 80 }}
							stopEditingWhenGridLosesFocus={true}
							singleClickEdit={true}
						>
							<AgGridColumn
								headerName='Responsável pelo Transporte'
								valueGetter={({ data }: any) => data.responsavelTransporte}
								cellStyle={{
									justifyContent: 'left'
								}}
								autoHeight
								wrapText
								minWidth={250}
							/>
							<AgGridColumn
								headerName='Data do Envio'
								field="dataEnvio"
								headerClass="myfunc-default-header"
								valueFormatter={({ data }: any) => data.dataEnvio ? _generateDate(data.dataEnvio, 'Pt') : ''}

							/>
							<AgGridColumn
								headerName='Local de Envio'
								valueGetter={({ data }: any) => data.localPartidaNome}
								cellStyle={{
									justifyContent: 'left'
								}}

							/>
							<AgGridColumn
								headerName='Responsável pelo Recebimento'
								valueGetter={({ data }: any) => data.responsavelRecebimento}
								cellStyle={{
									justifyContent: 'left'
								}}
								autoHeight
								wrapText
								minWidth={250}
							/>
							<AgGridColumn
								headerName='Data do Recebimento'
								field="dataRecebimento"
								headerClass="myfunc-default-header"
								valueFormatter={({ data }: any) => data.dataRecebimento ? _generateDate(data.dataRecebimento, 'Pt') : ''}

							/>
							<AgGridColumn
								headerName='Quantidade Recebida'
								field="qtdRecebida"
								headerClass="myfunc-default-header"
								type="number"
								valueFormatter={({ data }: any) => { return DecimalAsComma(data.qtdRecebida); }}
							/>
						</AgGridReact>
					</div>
				</Fieldset>

				<div className="button-position">
					<Button
						title="Voltar"
						contained={false}
						type="button"
						onClick={() => history.goBack()}
					/>
				</div>
			</Form>
		</If>
	</div>;
}