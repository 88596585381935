export const STATUS_SHIPMENT = {
    //EmPreenchimento: 1,
   EmCriacao                : 1,
   ReferenciaCriada         : 2,
   PermissaoImportacao      : 3,
   AguardandoDocumentos     : 4,
   DocumentosEnviados       : 5,
   AguardandoGreenLight     : 6,
   EmbarqueAprovado         : 7,
   Coleta_Entrega           : 8,
   EmLiberacaoOrigem        : 9,
   EmTransitoInternacional  : 10,
   EmLiberadaDestino        : 11,
   EmQuarentena             : 12,
   EmTransitoFinal          : 13,
   Entregue                 : 14,
   Cancelado                : 99,
}