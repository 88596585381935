import React from 'react';
import DeleteButton from './DeleteButton';
import { _generateDate } from '../util/convert';
import If from './If';

interface Props {
	text?: string;
	label?: string;
	date?: any;
	onClick?: any;
	onDownload?: any;
}
function FileExistent (props: Props)
{
	return <span>
		{props.label ? props.label : 'Arquivo anexado'}: <a href="#" onClick={props.onDownload}>
			{props.text} - {_generateDate(new Date(props.date ? props.date : ''), 'Pt')}
		</a>
		<If condition={props.onClick}>
			<DeleteButton onClick={props.onClick}/>
		</If>
	</span>;
}

export default FileExistent;