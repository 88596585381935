export const STATUS_REGISTER = {
    EmPreenchimento: 1,
    Cancelado: 2,
    EmAnalisePeloRegulatorio: 3,
    ValidadoPeloRegulatorio: 4,
    ReprovadoPeloRegulatorio: 5,
    EmCorrecao: 6,
    EmValidacaoPelaSGFITO: 7,
    ValidadoPelaSGFITO: 8,
    ValidadoPelaSGFITOComAlteracoes: 9,
    AguardandoAprovacaoMAPA: 10,
    AprovadoPeloMAPA: 11,
    ReprovadoPeloMAPA: 12
}

export const STATUS_PROTECTION = {
    EmPreenchimento: 1,
    Cancelado: 2,
    EmAnalisePeloRegulatorio: 3,
    ValidadoPeloRegulatorio: 4,
    ReprovadoPeloRegulatorio: 5,
    EmCorrecao: 6,
    EmValidacaoPelaSGFITO: 7,
    ValidadoPelaSGFITO: 8,
    AguardandoProtocolo: 9,
    AguardandoAprovacaoMAPA: 10,
    AprovadoPeloMAPA: 11,
    ReprovadoPeloMAPA: 12
}

export const STATUS_PROTECTION_ARCHIVE = {
    Enviado: 1,
    Validado: 2,
    Recusado: 3,
    Revalidar: 4,
    Assinado: 5
}

export const STATUS_PROTECTION_ATTACHMENT = {
    EmAnalise: 0,
    Validado: 1,
    Recusado: 2
}