import { PERMISSIONS } from '../usersPermissions/usersPermissionsAuthorizationsCIBio';

export const isReguladorReader = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.ReguladorRead);

    return isRole.length ? true : false;
}

export const isReguladorWriter = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.ReguladorWrite);

    return isRole.length ? true : false;
}