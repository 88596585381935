import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../../components/Button';
import If from '../../../../components/If';
import AlertError from '../../../../components/AlertError';
import AlertSuccess from '../../../../components/AlertSuccess';
import ResponseError from '../../../../components/ResponseError';
import Loading from '../../../../components/Loading';
import { STRINGS } from '../../../../util/strings';

import { estados } from '../../../../util/states.json';
import { countries } from '../../../../util/countries';
import { InstitutionDataForm, InstitutionResults } from '../../../../models/models';

import api from '../../../../services/api';
import '../../styles_edit.css';

interface stateType {
	id: number;
}

export default function InstitutionData ()
{
	const history = useHistory();
	const location = useLocation<stateType>();
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);
	const [country, setCountry] = useState<string>('');

	const institutionDataSchema = yup.object().shape({
		name: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		cnpj: country === "Brasil" ? yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
			.min(14, 'Mínimo de 14 caracteres.') : yup.string(),
		address: yup
			.string(),
		zipCode: yup
			.string(),
		city: yup
			.string(),
		state: yup
			.string(),
		country: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		phone: yup
			.string(),
		email: yup
			.string()
			.email(STRINGS.FORM_EMAIL_INVALID)
			.required(STRINGS.FORM_FIELD_REQUIRED),
		active: yup
			.bool()
	})

	const { handleSubmit, register, errors, reset, control, setValue } = useForm({
		mode: 'onTouched',
		resolver: yupResolver(institutionDataSchema)
	});

	useEffect(() => {
		const getInstitutionById = async () => {
			try {
				const { data } = await api.get<InstitutionResults>(`api/v1/Instituicao/${location.state.id}`);

				reset({
					name: data.nome,
					cnpj: data.cnpj,
					address: data.endereco,
					zipCode: data.cep,
					city: data.municipio,
					state: data.uf,
					country: data.pais,
					phone: data.telefone,
					email: data.email,
					active: data.ativo
				});
				setCountry(data.pais);
			} catch (error:any) {
				setError(error.response.data);
			}
			setLoading(false);
		}

		getInstitutionById();
	}, [location.state.id, reset]);

	const verifyErrorsOnForm = (): boolean => {
		if (errors.name || errors.country) {
			return true;
		}
		return false;
	}

	const onSubmit = async (data: InstitutionDataForm) => {
		setLoadingSubmit(true);
		setError(null);
		try {
			const { status } = await api.put('api/v1/Instituicao', {
				id: location.state.id,
				nome: data.name,
				cnpj: data.cnpj,
				endereco: data.address,
				cep: data.zipCode,
				municipio: data.city,
				uf: data.state,
				pais: data.country,
				telefone: data.phone,
				email: data.email,
				ativo: data.active
			});

			if (status === 200) {
				setSuccessfulResponse(true);
				setTimeout(() => {
					history.goBack();
				}, 3000);
			}
		} catch (error:any) {
			setError(error.response.data);
		}
		setLoadingSubmit(false);
	}

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			<AlertError
				message={STRINGS.ALERT_WARNING_FIELDS}
			/>
		</If>
		<If condition={successfulResponse && !error}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS_EDIT}
			/>
		</If>
		<If condition={!successfulResponse && error !== null}>
			{error !== null && error.message.length > 0 ? error.message.map((errorE: string) => <AlertError message={errorE} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<h3>Edição de instituição responsável pelo ensaio</h3>
		<If condition={loading && !error}>
			<span className="loading-page">
				<Loading />
			</span>
		</If>
		<If condition={!loading}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Nome da instituição<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							name="name"
							placeholder="Insira o nome da instituição"
							isInvalid={errors.name !== undefined}
							autoComplete="off"
							maxLength={200}
						/>
						<If condition={errors.name}>
							<Form.Control.Feedback type="invalid">
								{errors.name?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							CNPJ da instituição
							<If condition={country === "Brasil"}>
								<em>*</em>
							</If>
						</Form.Label>
						<Controller
							isInvalid={errors.cnpj !== undefined}
							control={control}
							name="cnpj"
							render={(props: any) => {
								return (
									<NumberFormat
										value={props.value}
										onValueChange={(target) => {
											props.onChange();
											setValue('cnpj', target.value);
										}}
										onBlur={props.onBlur}
										format="##.###.###/####-##"
										className="form-control"
									/>
								)
							}}
						/>
						<If condition={errors.cnpj !== undefined}>
							<div className="error-field">
								{errors.cnpj?.message}
							</div>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Email da instituição<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							name="email"
							placeholder="Insira o email da instituição"
							isInvalid={errors.email !== undefined}
							autoComplete="off"
							maxLength={200}
						/>
						<If condition={errors.email}>
							<Form.Control.Feedback type="invalid">
								{errors.email?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Telefone</Form.Label>
						<Controller
							control={control}
							name="phone"
							render={(props: any) => {
								return (
									<NumberFormat
										value={props.value}
										onValueChange={(target) => {
											props.onChange();
											setValue('phone', target.value);
										}}
										onBlur={props.onBlur}
										format="(##) #####-####"
										className="form-control"
									/>
								)
							}}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							CEP
						</Form.Label>
						<Controller
							control={control}
							name="zipCode"
							render={(props: any) => {
								return (
									<NumberFormat
										value={props.value}
										onValueChange={(target) => {
											props.onChange();
											setValue('zipCode', target.value);
										}}
										onBlur={props.onBlur}
										format="#####-###"
										className="form-control"
									/>
								)
							}}
						/>
						<If condition={errors.zipCode}>
							<Form.Control.Feedback type="invalid">
								{errors.zipCode?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>Endereço</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							placeholder="Insira o endereço da instituição"
							name="address"
							autoComplete="off"
							maxLength={255}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Município</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							placeholder="Insira o município da instituição"
							name="city"
							autoComplete="off"
							maxLength={100}
						/>
					</Form.Group>
					<Form.Group as={Col} md={1}>
						<Form.Label>UF</Form.Label>
						<Form.Control
							as="select"
							name="state"
							ref={register}
						>
							<Form.Control
								as="option"
								label=''
							/>
							{estados.map((state) => (
								<Form.Control
									key={state.id}
									as="option"
									label={state.nome}
									value={state.nome}
								/>
							))}
						</Form.Control>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>País<em>*</em></Form.Label>
						<Form.Control
							as="select"
							name="country"
							isInvalid={errors.country !== undefined}
							ref={register}
							onChange={({ target }: any) => setCountry(target.value)}
						>
							<Form.Control
								as="option"
								label=''
							/>
							{countries.map((country) => (
								<Form.Control
									key={country}
									as="option"
									label={country}
									value={country}
								/>
							))}
						</Form.Control>
						<If condition={errors.country}>
							<Form.Control.Feedback type="invalid">
								{errors.country?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} md={1} className="checkbox-lg">
						<Form.Check
							type="checkbox"
							ref={register}
							name="active"
							label="Ativo"
						/>
					</Form.Group>
				</Form.Row>
				<div className="button-position">
					<Button
						title="Cancelar"
						type="button"
						contained={false}
						onClick={() => history.goBack()}
					/>
					<Button
						title="Salvar"
						type="submit"
						contained={true}
						isLoading={loading}
					/>
				</div>
			</Form>
		</If>
	</div>;
}