
import React,  { useContext } from "react";
import api, { apiFile , apiFileDownload } from "../../services/api"
import { 
	isCheck as isLPMACheckRoles
} from '../../users/lpma/isCheck';
import { 
	PERMISSIONS as LPMA_PERMISSIONS
} from '../../users/usersPermissions/usersPermissionsLPMA';
import { DecodedToken } from "../../models/user";
import jwtDecode from "jwt-decode";
import { Context } from '../../context/AuthContext'
import {
    LpmaRN35, LpmaConfigucaoes, LpmaEquipeTecnica, LpmaLocal, LpmaProjeto,
    Lpma_RN_PotencialConstrucoes, Lpma_RN_Comunicados, LpmaRN06, OficioCIBIO,
    Lpma_RN06_PotencialConstrucoes, Lpma_RN35_PotencialConstrucoes
} from "../../models/modelsLPMA";
import { listEnumItem } from "../../models/modelCommum";


export default class LpmaService {
    baseUrl: string = '/api/v1/lpma';
    urlEquiTecnica: string = `${this.baseUrl}/EquipeTecnica`;
    urlLocais: string = `${this.baseUrl}/Local`;
    urlProjeto: string = `${this.baseUrl}/Projeto`;
    urlPotConstrucoes06: string = `${this.baseUrl}/PotCons`;
    urlPotConstrucoes35: string = `${this.baseUrl}/PotenciaisConstrucoes`;
    urlConfigs: string = `${this.baseUrl}/Configs`;
    urlRN35: string = `${this.baseUrl}/rn35`;
    urlRN35List: string = `${this.baseUrl}/rn35/list`;
    urlRN06: string = `${this.baseUrl}/rn06`;
    urlRN06List: string = `${this.baseUrl}/rn06/list`;
    urlComunicados: string = `${this.baseUrl}/Comunicados`;
    urlRN06Comunicados: string = `${this.baseUrl}/ComunicadosRN06`;
    urlRN35Comunicados: string = `${this.baseUrl}/ComunicadosRN35`;
    
    async getListRN(url:string, filtro:any = null){
      const { data, status } = await api.post<LpmaRN35[]>(`${url}`, filtro);
        if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    async getRNById(url:string, id: any){

        if (url.includes("rn35")) {
            const { data, status } = await api.get<LpmaRN35>(`${url}/${id}`);
            if ([200, 201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
            return data;
        }
        else
        {
            const { data, status } = await api.get<LpmaRN06>(`${url}/${id}`);
            if ([200, 201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
            return data;
        }
    }


    async getRNAction(url: string, acao: string, dto: any) {
      const { data, status } = await api.post(`${url}/${acao}`, dto);
        if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    async getRNSaveFiles(url:string, id: number, dto:FormData){
      const { data, status } = await apiFile.post(`${url}/${id}`, dto);
        if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    async DownloadRNPDF(doc_url: string, id: number, documentFileName: string, font: string, opcao:string){
        try {

            if (opcao !== "")
            {
                if (opcao === "C")
                    documentFileName = documentFileName.replace('.pdf', ' (CONFIDENCIAL).pdf');
                else
                    documentFileName = documentFileName.replace('.pdf', ' (PÚBLICO).pdf');

                const { data, headers } = await apiFileDownload.get(`${doc_url}/pdf/${opcao}/${id}`);
//                const { data, headers } = await apiFileDownload.get(`${doc_url}/pdf/${id}`);
                let blob = new Blob([data], { type: 'application/pdf' });
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                if (typeof a.download === "undefined") { window.location.href = url; }
                else {
                    a.href = url; a.download = documentFileName ?? `rn06_${id}`;
                    document.body.appendChild(a);
                    a.click();
                }
            }
            else
            {
                const { data, headers } = await apiFileDownload.get(`${doc_url}/pdf/${id}`);
                let blob = new Blob([data], { type: 'application/pdf' });
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                if (typeof a.download === "undefined") { window.location.href = url; }
                else {
                    a.href = url; a.download = documentFileName ?? `rn35_${id}`;
                    document.body.appendChild(a);
                    a.click();
                };
        }
      } catch (error:any) {
        console.error(error);
        throw new Error(error);       
      }
    }

    getUrlFileDownload(url:string, guid: string, defi: string){
      return `${url}/downloadfile?guid=${guid}&defition=${defi}`;
    }

    /*** CHAMADAS PARA LPMA RN35 */

    public async getListRN35(filtro:any = null){
      return await this.getListRN(this.urlRN35List, filtro);
    }

    public async getRN35ById(id: any){
      return await this.getRNById(this.urlRN35, id);
    }

    public async getRN35Action(acao: string, dto: any) {
      return await this.getRNAction(this.urlRN35, acao, dto);
    }

    public async getRN35SaveFiles(id: number, dto:FormData){
      return await this.getRNSaveFiles(`${this.urlRN35}/files`, id, dto);
    }

    public async LpmaRN35EnviarRegulatorio(dto: any)
    {
        return await api.put(`${this.urlRN35}/enviarregulatoriorn35`, dto);
    }


    public async DownloadRN35PDF(id: number, documentFileName: string, opcao: string){
      await this.DownloadRNPDF(`${this.urlRN35}/document`, id, documentFileName, "", opcao);
    }

    public UrlFileDownloadRN35(id: number, guid: string, defi: string){
      if(guid == null || id == null) return null;
      return `${this.urlRN35}/file/${id}/${defi}/${guid}`;
      //return this.getUrlFileDownload(`${this.urlRN35}/file`, guid, defi);
    }

    /*** CHAMADAS PARA LPMA RN06 */

    public async getListRN06(filtro:any = null){
      return await this.getListRN(this.urlRN06List, filtro);
    }

    public async getRN06ById(id: any){
      return await this.getRNById(this.urlRN06, id);
    }

    public async getRN06ComunicadosById(id: any) {
        const { data, status } = await api.get<Lpma_RN_Comunicados>(this.urlRN06Comunicados + `/${id}`);

        //const { data, status } = await api.get<Lpma_RN_Comunicados>(`api/v1/lpma/ComunicadosRN06/${id}`);
        if ([200, 201].indexOf(status) <= -1)
            throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }

    public async getRN06Action(acao: string, dto: any) {
      return await this.getRNAction(this.urlRN06, acao, dto);
    }
    public async getRN06SaveFiles(id: number, dto:FormData){
      return await this.getRNSaveFiles(`${this.urlRN06}/files`, id, dto);
    }

    public async DownloadRN06PDF(id: number, documentFileName: string, opcao: string) {
      this.DownloadRNPDF(`${this.urlRN06}/document`, id, documentFileName, "", opcao);
    }

    public UrlFileDownloadRN06(id: number, guid: string, defi: string){
      if(guid == null || id == null) return null;
      return `${this.urlRN06}/file/${id}/${defi}/${guid}`;
      //return this.getUrlFileDownload(`${this.urlRN35}/file`, guid, defi);
    }

    /****** API's para Tratamento das Potenciais Construções *******/
    public async getPotenciaisConstrucoes06(filter: any = null) {
        if (!filter) filter = { id: 0, ativo: true } as Lpma_RN06_PotencialConstrucoes;
        const { data, status } = await api.post<Lpma_RN06_PotencialConstrucoes[]>(`${this.urlPotConstrucoes06}`, filter);
        if ([200, 201].indexOf(status) <= -1)
            throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }

    /****** API's para Tratamento das Potenciais Construções *******/
    public async getPotenciaisConstrucoes35(filter: any = null) {
        if (!filter) filter = { id: 0, ativo: true } as Lpma_RN35_PotencialConstrucoes;
        const { data, status } = await api.post<Lpma_RN35_PotencialConstrucoes[]>(`${this.urlPotConstrucoes35}`, filter);
        if ([200, 201].indexOf(status) <= -1)
            throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }

    /******* API's Para Tratamento de Locais para o LPMA *******/
    public  async getLocais (filter:any = null){
        if(!filter) filter =  {id: 0,ativo: true, } as LpmaLocal;
        const { data, status } = await api.post<LpmaLocal[]>(`${this.urlLocais}`, filter);
        if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public  async getLocaisById (id: any){
      const { data, status } = await api.get<LpmaLocal>(`${this.urlLocais}/${id}`);
        if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public  async getLocaisSave (values:any){
      const { data, status } = await api.post<LpmaLocal[]>(`${this.urlLocais}/save`, values);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }

    /****** API's para Tratamento das Equipe Tecnicas *******/
    public  async getEquipeTecnica (filter:any = null){
       if(!filter) filter =  {id: 0,ativo: true} as LpmaEquipeTecnica;
        const { data, status } = await api.post<LpmaEquipeTecnica[]>(`${this.urlEquiTecnica}`, filter);
        if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public  async getEquipeTecnicaById (id: any){
      const { data, status } = await api.get<LpmaEquipeTecnica>(`${this.urlEquiTecnica}/${id}`);
        if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public  async getEquipeTecnicaSave (values:any){
      const { data, status } = await api.post<LpmaEquipeTecnica[]>(`${this.urlEquiTecnica}/save-form-data`, values);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }

    /******** Api's para Tratamento de Projetos *********/
    public  async getProjetos (filter:any = null){
        if(!filter) filter =  {id: 0,ativo: true} as LpmaProjeto;
        const { data, status } = await api.post<LpmaProjeto[]>(`${this.urlProjeto}`, filter);
        if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public  async getProjetosById (id: any){
      const { data, status } = await api.get<LpmaProjeto>(`${this.urlProjeto}/${id}`);
        if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public  async getProjetosSave (values:any){
      const { data, status } = await api.post<LpmaProjeto[]>(`${this.urlProjeto}/save`, values);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }

    /****** API's para Tratamento dos Comunicados *******/
    public async getComunicados(filter: any = null) {
        if (!filter) filter = { id: 0, ativo: true } as LpmaEquipeTecnica;
        const { data, status } = await api.post<LpmaEquipeTecnica[]>(`${this.urlEquiTecnica}`, filter);
        if ([200, 201].indexOf(status) <= -1)
            throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }


  /******** Api's para Tratamento de Dados Pré-Configurados *********/
  public  async getConfigs (filter:any = null){
    const { data, status } = await api.post<LpmaConfigucaoes[]>(`${this.urlConfigs}`, filter);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }
  public  async getConfigsById (id: any){
    const { data, status } = await api.get<LpmaConfigucaoes>(`${this.urlConfigs}/${id}`);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
  }
  public  async getConfigsSave (values:any){
    const { data, status } = await api.post<LpmaConfigucaoes[]>(`${this.urlConfigs}/save-form-data`, values);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }
  public async getEnsaioTipoTratamento(){
    const { data, status } = await api.get<LpmaConfigucaoes[]>(`${this.urlConfigs}/GetConfigData/TipoTratamento`);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }
  public async getListPorcentagemFitotoxidez(){
    const { data, status } = await api.get<LpmaConfigucaoes[]>(`${this.urlConfigs}/GetConfigData/ListPorcentagemFitotoxidez`);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }
  public async getListConfiguracoesLPMA(){
    const { data, status } = await api.get<LpmaConfigucaoes[]>(`${this.urlConfigs}/GetConfigData/ListConfiguracoesLPMA`);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }
  public async getListCondfigDocumentLPMA(lpmaTp:('RN35' | 'RN06')){
    const { data, status } = await api.get<listEnumItem[]>(`${this.urlConfigs}/GetConfigData/ListCondfigDocumentLPMA-${lpmaTp}`);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }

    public async DownloadFile(urlFileName: string, documentFileName: string) {
    try {
      const { data, status, headers } = await apiFileDownload.get(`${urlFileName}`);      
      let blob = new Blob([data], { type: 'application/file' });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      if (typeof a.download === "undefined") { 	window.location.href = url; }
      else {
        a.href = url; a.download = documentFileName ?? `download file`;
        document.body.appendChild(a);
        a.click();
      }
      return true;
    } catch (error:any) {
      if(error.status === 400){
        console.log('Arquivo não encontrado no servidor:', urlFileName);
        return true;
      } 
      console.log(error);
      return false;
    }
  }
}

// //export function RolesService (): any {
// export var RolesServiceX = (function () {
//   const {token} = useContext(Context);
// 	//const decoded: DecodedToken = jwtDecode(token.access_token);
//   //decode: any;// = jwtDecode(this.token.access_token);
// 	const { roles, unique_name }: DecodedToken = jwtDecode(token.access_token);
	
//   let isCreatRequisicao = () =>{
//     return isLPMACheckRoles(roles, LPMA_PERMISSIONS.CriarRequisicao)||
// 	  isLPMACheckRoles(roles, LPMA_PERMISSIONS.Admin) 
//   }

//   // public isCreatRequisicao() {
// 	// 	return isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.CriarRequisicao)||
// 	// 	isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.Admin) 
// 	// }
// 	// public isEditRequisicao() {
// 	// 	return isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.EditarRequisicao) ||
// 	// 	isLPMACheckRoles(roles, LPMA_PERMISSIONS.Admin) 
// 	// }
// 	// public isRegulatorio() {
// 	// 	return isLPMACheckRoles(roles, LPMA_PERMISSIONS.Regulatorio)||
// 	// 	isLPMACheckRoles(roles, LPMA_PERMISSIONS.Admin) 
// 	// }
  
//   return RolesServiceX
// })

export class RolesService {
  conext:   any;
  decode:   DecodedToken;
  roles:    any;
  constructor(_useContext:any) {
      this.conext = _useContext(Context);;//Context;//React.createContext(Context);// useContext(Context); };
      this.decode = jwtDecode(this.conext.token.access_token);
      this.roles  = this.decode.roles;
  }
  public getUserName(){
    //const { roles, unique_name }: DecodedToken = jwtDecode(token);
    return this.decode?.unique_name;
  }
  public isCreatRequisicao(){
    return isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.CriarRequisicao)||
	  isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.Admin) 
  }
  
  public isEditRequisicao() {
		return isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.EditarRequisicao) ||
		isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.Admin) 
	}

  public isCreatOREditRequisicao() {
    return this.isCreatRequisicao() || this.isEditRequisicao();
  }
	
  public isRegulatorio() {
		return isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.Regulatorio)||
		isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.Admin) 
	}

  public isAdmin(){
    return isLPMACheckRoles(this.roles, LPMA_PERMISSIONS.Admin);
  }

}