import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import TextField from '../../../components/form/TextField';
import ListingView, { ListCol } from "../../../components/page/ListingView";
import { CqbEquipeTecnica, CqbLocal } from '../../../models/modelsCQB';
import { STRINGS_CQB } from "../../../util/cqb/string";
import CqbService from '../services';

export default function List() {
	const CqbServiceApi = new CqbService();
	const history = useHistory();


	const defaultForm = useForm({
	defaultValues: {
			id: 0,
	//		codigo: '',
	//		nome: '',
	//		documento: '',
	//		link_CV_Lattes: '',
	//		endereco: '',
	//		telefone: '',
	//		email: '',
			ativo: true,
		} as CqbLocal,
	})


	return <ListingView<CqbLocal>
		title={STRINGS_CQB.SEARCH_LOCAL}
		form={defaultForm}
		onEditEvent={(data: any) => {
			history.push({
				pathname: `/cqb-local/editar/${data.id}`,
				state: {
					id: data.id
				}
			})
		}}

		ShowButtonAddNew={true}
		onButtonAddEvent={() => { history.push("/cqb-local/novo") }}
		ButtonAddText="Novo Local"

		filters={[
			<TextField<CqbLocal> form={defaultForm} layoutColumns={4} name="nomeDaFazenda"
				label="Nome da Fazenda" required={false} isClearable={true}
			/>,
			<TextField<CqbLocal> form={defaultForm} layoutColumns={6} name="cnpj"
				label="CNPJ" required={false} isClearable={true}
			/>
		]}

		onFilterSearch={async (queries?: any) => {
			let filtro = {
				...defaultForm.getValues(),
				...queries
			}
			return await CqbServiceApi.getLocais(filtro as CqbLocal);
		}}
	>

		<ListCol
			header='Nome da Fazenda'
			field='nomeDaFazenda'
			sort="asc"
		/>

		<ListCol
			header='CNPJ'
			field='cnpj'
			sort="asc"
		/>

		<ListCol
			header='UF/Pais'
			field='uf'
			valueFormatter={(item: any) => { return `${item?.data.uf}/${item?.data.pais}` }}
			sort="asc"
		/>

	</ListingView>
}