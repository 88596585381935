import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../../components/Button';
import EditButton from '../../../components/EditButton';
import ViewButton from '../../../components/ViewButton';
import GeneratePDF from '../../../components/GeneratePDF';
import ZipButton from '../../../components/GenerateZip';
import CancelButton from '../../../components/CancelRequisitionButton';
import ReasonModal from '../../../components/ReasonModal';
import If from '../../../components/If';
import ptBR from 'date-fns/locale/pt-BR';
import api, { apiFileDownload } from '../../../services/api';
import { CultureResults, RequestProtectionSNPC, StatusResults } from '../../../models/models';
import { _generateDate } from '../../../util/convert';
import SearchEmpty from '../../../components/SearchEmpty';
import Loading from '../../../components/Loading';
import { STRINGS } from '../../../util/strings';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../../../models/user';
import { isAdmin } from '../../../users/isAdmin';
import {
	isRequesterWriteProtection, isOnlyRequesterRegister, isRequesterWriteRegister, 
	isDescritor, isAvaliadorDoenca
} from '../../../users/isRequester';
import { STATUS_PROTECTION, STATUS_REGISTER } from '../../../util/status';
import { isDescriptors } from '../../../users/isDescriptors';
import { isDiseases } from '../../../users/isDiseases';
import { isReguladorProtection } from '../../../users/isRegulador';
import '../styles_list.css';

registerLocale('pt-BR', ptBR);

export default function ProtectionSNPC ()
{
	const history = useHistory();
	const token = String(localStorage.getItem('token'));
	const { roles, unique_name }: DecodedToken = jwtDecode(token);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [arrayCulture, setArrayCulture] = useState<CultureResults[]>([]);
	const [arrayStataus, setArrayStatus] = useState<StatusResults[]>([]);
	const [arrayRequestProtection, setArrayRequestProtection] = useState<RequestProtectionSNPC[]>([]);
	const [tempId, setTempId] = useState<number>(0);
	const [showReasonModal, setShowReasonModal] = useState<boolean>(false);
	const [reasonTxt, setReasonTxt] = useState<string>('');
	const [loadingCancelling, setLoadingCancelling] = useState<boolean>(false);

	const { register, handleSubmit, control } = useForm({
		defaultValues: {
			code: '',
			requester: isOnlyRequesterRegister(roles) ? unique_name : '',
			initialDate: null,
			finalDate: null,
			status: '',
			culture: '',
		}
	});

	const getRequestsProtection = async () => {
		setError(false);
		try {
			let filtro = '';
			if (isOnlyRequesterRegister(roles))
				filtro = 'Requisitante=' + unique_name;

			//if (isDescritor(roles))
			//	filtro = 'UsuarioValidacaoDescritores=' + unique_name;

			//if (isAvaliadorDoenca(roles))
			//	filtro = 'UsuarioValidacaoDoencas=' + unique_name;

			let resultRequestProtection = await api.get<RequestProtectionSNPC[]>(`api/v1/cultivares/CultivaresProtecao/obterporfiltro?${filtro}`);

			if (resultRequestProtection.status === 200)
				setArrayRequestProtection(resultRequestProtection.data);
		} catch (error:any) {
			setError(true);
		}
	}

	useEffect(() => {
		const getAllCulture = async () => {
			try {
				let resultCulture = await api.get<CultureResults[]>(`api/v1/cultivares/Cultura/obterporfiltro?Ativo=true`);
				let restulStatus = await api.get<StatusResults[]>(`api/v1/cultivares/CultivaresProtecao/obterlistastatus`);

				if (resultCulture.status === 200)
					setArrayCulture(resultCulture.data);
				if (restulStatus.status === 200) {
					setArrayStatus(restulStatus.data);
				}
			} catch (error:any) {
				setError(true);
			}
			setLoading(false);
		}
		getRequestsProtection();
		getAllCulture();
	}, []);

	const search = async (queries?: any) => {
		setError(false);
		setLoadingSearch(true);

		let requesterUsername = queries?.requester;
		if (isOnlyRequesterRegister(roles)) {
			requesterUsername = unique_name;
		}

		try {
			const { data } = await api.get<RequestProtectionSNPC[]>(`api/v1/cultivares/CultivaresProtecao/obterporfiltro?Requisitante=${requesterUsername}&Codigo=${queries.codigo}&Status=${queries?.status}&DataInicio=${queries?.initialDate ? _generateDate(new Date(queries?.initialDate), 'En') : ''}&DataFim=${queries?.finalDate ? _generateDate(new Date(queries?.finalDate), 'En') : ''}&CulturaId=${queries?.culture}&DenominacaoProposta=${queries?.proposedName}`);

			if (data.length) {
				setArrayRequestProtection(data);
				setEmptySearch(false);
			} else {
				setEmptySearch(true);
			}
		}
		catch (error:any) {
			setError(true);
		}
		setLoadingSearch(false);
	}

	const getZip = async (id: number) => {
		try {
			const { data } = await apiFileDownload.get(`api/v1/cultivares/CultivaresProtecao/downloadzip/${id}`);

			let blob = new Blob([data], {
				type: 'application/zip'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = `protecao-documentos-${id}`;
				document.body.appendChild(a);
				a.click();
			}
		} catch (error:any) {
			console.error(error);
		}
	}

	const generatePDF = async (id: number) => {
		try {
			const { data } = await apiFileDownload.get(`api/v1/cultivares/Relatorios/requisicaoprotecao?id=${id}`);

			let blob = new Blob([data], {
				type: 'application/pdf'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = `requisição-proteção-${id}`;
				document.body.appendChild(a);
				a.click();
			}
		} catch (error:any) {
			console.error(error);
		}
	}

	const cancelRequisition = async (id: number) => {
		setLoadingCancelling(true);
		try {
			const { status } = await api.post(`api/v1/cultivares/CultivaresProtecao/${id}/cancelar`, {
				motivo: reasonTxt
			});
			if (status === 200) {
				setShowReasonModal(false);
				getRequestsProtection();
				setReasonTxt('');
			}
		} catch (error:any) {
			console.error(error);
		}
		setLoadingCancelling(false);
	}

	const actions = ({ data }: any) => {

		let canEdit : boolean = (
				isRequesterWriteRegister(roles)
				&& (
				data.status == STATUS_PROTECTION.EmPreenchimento
					|| data.status == STATUS_PROTECTION.ValidadoPeloRegulatorio
					|| data.status == STATUS_PROTECTION.ReprovadoPeloRegulatorio
					|| data.status == STATUS_PROTECTION.EmCorrecao
				)
			)
			||
			(
				isReguladorProtection(roles)
				&& (
					data.status == STATUS_PROTECTION.EmAnalisePeloRegulatorio
					|| data.status == STATUS_PROTECTION.ValidadoPeloRegulatorio
					|| data.status == STATUS_PROTECTION.EmValidacaoPelaSGFITO
					|| data.status == STATUS_PROTECTION.ValidadoPelaSGFITO
					|| data.status == STATUS_PROTECTION.AguardandoProtocolo
					|| data.status == STATUS_PROTECTION.AguardandoAprovacaoMAPA
					|| data.status == STATUS_PROTECTION.AprovadoPeloMAPA
					|| data.status == STATUS_PROTECTION.ReprovadoPeloMAPA
				)
			)
			||
			(
				(isDescriptors(roles) || isDiseases(roles))
				&& data.status == STATUS_PROTECTION.EmValidacaoPelaSGFITO
			)
			||
			isAdmin(roles)
		;

		let canCancel : boolean = (isRequesterWriteProtection(roles) && (data.status === STATUS_PROTECTION.EmPreenchimento || data.status === STATUS_PROTECTION.EmCorrecao || data.status === STATUS_PROTECTION.ReprovadoPeloRegulatorio)) || isAdmin(roles) || (isReguladorProtection(roles) && (data.status === STATUS_PROTECTION.EmAnalisePeloRegulatorio || data.status === STATUS_PROTECTION.ValidadoPeloRegulatorio)) || isAdmin(roles);

		let buttonEdit = <EditButton disable={!canEdit} onClick={() => {
			if (!canEdit)
				return;
				
			history.push({
				pathname: '/cultivares/protecao-snpc/editar',
				state: {
					id: data.id
				}
			})
		}}
		/>;

		let buttonCancel = <CancelButton disabled={!canCancel} onClick={() => {
			if (!canCancel)
				return;

			setShowReasonModal(true);
			setTempId(data.id);
		}} />;

		let buttonView = <ViewButton onClick={() => history.push({
			pathname: '/cultivares/protecao-snpc/visualizar',
			state: {id: data.id}
		})} />;

		let buttonPdf = <GeneratePDF onClick={() => generatePDF(data.id)} />;

		let buttonZip = <ZipButton Text="Gerar Zip" onClick={() => getZip(data.id)} />;

		return <Fragment>
			{buttonEdit}
			&nbsp;&nbsp;
			{buttonCancel}
			&nbsp;&nbsp;
			{buttonView}
			&nbsp;&nbsp;
			{buttonPdf}
			&nbsp;&nbsp;
			{buttonZip}
			&nbsp;&nbsp;
		</Fragment>;
	}

	return <div className="container-custom-search">
		<div className="top-content">
			<h3>Consulta Proteção &#45; SNPC</h3>
		</div>
		<If condition={isAdmin(roles) || isRequesterWriteProtection(roles)}>
			<div className="row-buttons">
				<Button
					onClick={() => history.push('/cultivares/protecao-snpc/cadastrar')}
					contained
					title="Adicionar Requisição"
					type="button"
				/>
			</div>
		</If>
		<br />
		<Form onSubmit={handleSubmit(search)}>
			<Form.Row>
				<Form.Group as={Col} md={3}>
					<Form.Label>Código</Form.Label>
					<Form.Control
						ref={register}
						name="codigo"
						maxLength={100}
						autoComplete="off"
					/>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>Denominação Proposta</Form.Label>
					<Form.Control
						ref={register}
						name="proposedName"
						maxLength={100}
						autoComplete="off"
					/>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>Cultura</Form.Label>
					<Form.Control
						as="select"
						ref={register}
						name="culture"
					>
						<Form.Control
							as="option"
							label="Escolha uma cultura"
						/>
						{arrayCulture.map(culture =>
							<Form.Control
								key={culture.id}
								as="option"
								label={culture.nome}
								value={culture.id}
							/>
						)}
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>Requisitante</Form.Label>
					<Form.Control
						ref={register}
						name="requester"
						maxLength={100}
						autoComplete="off"
						disabled={isOnlyRequesterRegister(roles)}
					/>
				</Form.Group>                    
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Data inicial
					</Form.Label>
					<br />
					<Controller
						control={control}
						name="initialDate"
						render={({ onChange, value }) => (
							<DatePicker
								selected={value}
								onChange={onChange}
								className="input-custom-to-date"
								locale="pt-BR"
								dateFormat="dd/MM/yyyy"
								placeholderText="Selecionar a data"
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Data final
					</Form.Label>
					<br />
					<Controller
						control={control}
						name="finalDate"
						render={({ onChange, value }) => (
							<DatePicker
								selected={value}
								onChange={onChange}
								className="input-custom-to-date"
								locale="pt-BR"
								dateFormat="dd/MM/yyyy"
								placeholderText="Selecionar a data"
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>Status</Form.Label>
					<Form.Control
						ref={register}
						as="select"
						name="status"
					>
						<Form.Control
							as="option"
							label="Escolha um status"
						/>
						{arrayStataus.map(status =>
							<Form.Control
								key={status.id}
								as="option"
								label={status.nome}
								value={status.id}
							/>
						)}
					</Form.Control>
				</Form.Group>
				<span className="filter-btn">
					<Button
						title="Buscar"
						type="submit"
						isLoading={loadingSearch}
						contained
					/>
				</span>
			</Form.Row>
		</Form>
		<If condition={emptySearch}>
			<div className="div-empty">
				<SearchEmpty />
			</div>
		</If>
		<If condition={!loading && !error && !emptySearch && arrayRequestProtection.length}>
			<div className="search-content">
				<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
					<AgGridReact
						rowData={arrayRequestProtection}
						defaultColDef={{ flex: 1, sortable: true }}
						pagination
						paginationPageSize={10}
						frameworkComponents={{
							actionsBtn: actions,
						}}
					>
						<AgGridColumn
							headerName='Código'
							field='codigo'
						/>
						<AgGridColumn
							headerName='Denominação Proposta'
							field='denominacaoProposta'
						/>                            
						<AgGridColumn
							headerName='Cultura'
							field='nomeComumDaEspecie'
						/>
						<AgGridColumn
							headerName='Requisitante'
							field='usuario'
						/>
						<AgGridColumn
							headerName='Data de criação'
							field='dataCriacao'
							valueFormatter={({ data }: any) => _generateDate(data.dataCriacao, 'Pt')}
						/>
						<AgGridColumn
							headerName='Status'
							field='statusDescricao'
						/>
						<AgGridColumn
							field='Ações'
							cellRenderer='actionsBtn'
						/>
					</AgGridReact>
				</div>
			</div>
		</If>
		<If condition={loading}>
			<div className="loading-container">
				<Loading />
			</div>
		</If>
		<If condition={showReasonModal}>
			<ReasonModal
				show={true}
				onHide={() => setShowReasonModal(false)}
				title={STRINGS.REASON_MODAL_TITLE}
				actionMsg={STRINGS.REASON_MODAL_ACTION}
				disabled={reasonTxt.length ? false : true}
				onConfirm={() => cancelRequisition(tempId)}
				loading={loadingCancelling}
			>
				<Form.Label>
					<strong>{STRINGS.REASON_MODAL_MSG}</strong>
				</Form.Label>
				<Form.Control
					onChange={({ target }: any) =>
						setReasonTxt(target.value)
					}
					value={reasonTxt}
					as="textarea"
				/>
			</ReasonModal>
		</If>
	</div>;
}