import React, { useState, useEffect,  useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import TextField from '../../../components/form/TextField';
import ListingView, { ListCol } from "../../../components/page/ListingView";
import { LpmaProjeto } from '../../../models/modelsLPMA';
import { STRINGS_LPMA } from "../../../util/lpma/string";
import LpmaService, { RolesService } from '../services';



export default function List (){
	const LpmaServiceApi = new LpmaService();
	const LpmaRoles		 = new RolesService(useContext);
	const history = useHistory();


    const defaultForm = useForm({
		defaultValues: {
            id: 0,
            nome : '',
            cultura : '',
            medidasBiossegurancaRN35 : '',
			medidasBiossegurancaRN06 : '',
            oGM : '',
            ativo : true,
        } as LpmaProjeto,
	})	

    
	
	return <ListingView<LpmaProjeto>
	title	=	{STRINGS_LPMA.SEARCH_PROJETOS}	
	ButtonAddText="Novo Projeto"
	form={defaultForm}
	ShowButtonAddNew = {LpmaRoles.isCreatRequisicao()}
	onButtonAddEvent = {()=>{ history.push("/lpma/projeto/novo") }}
	onEditEvent = {(data:any )=>{  history.push({
		pathname: `/lpma/projeto/editar/${data.id}`,						
		})
	 }}

	filters={[
		<TextField<LpmaProjeto> form={defaultForm} layoutColumns={4} name="nome" 
		label="Código do Projeto/Descrição" required={false}  isClearable={true}
		/>,
		<TextField<LpmaProjeto> form={defaultForm} layoutColumns={6} name="cultura" 
		label="Cultura" required={false}  isClearable={true}
		/>
	 ]}

	onFilterSearch = {async (queries?: any)=>{
		// console.log(queries);
		let filtro = {
			...defaultForm.getValues(),
			...queries
		}
		return await LpmaServiceApi.getProjetos(filtro as LpmaProjeto);
	}}
	>		

	<ListCol
		header='Projeto'
		field='nome'
		sort="asc"
	/>			

	<ListCol
		header='Cultura'
		field='cultura'	
	/>		
	
	<ListCol
		header='Nome Cientifico'
		field='nomeCientifico'
	/>		

	<ListCol
		header='OGM'
		field='ogm'	
	/>	

	</ListingView>
}
// //Basic REACT Components
// import React, { useState, useEffect,  useContext } from 'react';
// import { useForm } from 'react-hook-form';
// import { useHistory } from "react-router-dom";
// import { Form } from 'react-bootstrap';
// import { AgGridColumn, AgGridReact } from 'ag-grid-react';

// //Custom Componentes
// import Button from "../../../components/Button";
// import EditButton from '../../../components/EditButton';

// import SearchEmpty from '../../../components/SearchEmpty';
// import Loading from '../../../components/Loading';
// import "../styles.scss";
// import LpmaService, { RolesService } from '../services';
// import { LpmaProjeto } from '../../../models/modelsLPMA';


// //Page Specifics
// import { STRINGS_LPMA } from "../../../util/lpma/string";

// export default function List(){

//     const LpmaServiceApi = new LpmaService();
// 	const LpmaRoles		 = new RolesService(useContext);

//     //Base da página
// 	const history = useHistory();
//     const [list, setList] = useState<LpmaProjeto[]>([]);

//     const [loading, setLoading] = useState<boolean>(false);

// 	//Interações da Página
// 	const [error, setError] = useState<any>(null);
// 	const [emptySearch, setEmptySearch] = useState<boolean>(false);
// 	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

//     const defaultForm = useForm({
// 		defaultValues: {
//             id: 0,
//             nome : '',
//             cultura : '',
//             medidasBiosseguranca : '',
//             oGM : '',
//             ativo : '',
//         } as LpmaProjeto,
// 	})	

//     useEffect(() => {

// 		searchOrGetList( null);	
	
// 	}, []);

// 	const searchOrGetList = async (queries?: any) => {

// 		setError(false);
// 		setLoadingSearch(true);

// 		try {
// 			const getLpmaSearch =  async () => {
// 				let dados = await LpmaServiceApi.getProjetos(queries as LpmaProjeto);
// 				if (!dados.length)
// 				{
// 					setEmptySearch(true);
// 					return;
// 				}
// 				setList(dados);
// 				setEmptySearch(false);
// 			}
// 			getLpmaSearch();				
// 		}
// 		catch (error:any)
// 		{
// 			setError(true);
// 		}

// 		setLoadingSearch(false);
// 	}	

//     const acoesbotoes = ({ data }: any) => {
// 		return <EditButton onClick={() => {				
//             history.push({
//             pathname: `/lpma/projeto/editar/${data.id}`,						
//             })
//         }} />;        
// 	}
	
//     return (
// 		<div className="container-custom-search">
// 			<div className="search-content-filters ">
			
// 				<div className="top-content">
// 					<h3>{STRINGS_LPMA.SEARCH_PROJETOS}</h3>
// 				</div>
// 				<div className="row-buttons">
// 					{LpmaRoles.isCreatRequisicao() ?
// 						<Button
// 						onClick={() => history.push("/lpma/projeto/novo")}
// 						contained
// 						title="Novo Projeto"
// 						type="button"
// 						/>
// 					:''}
// 				</div>
// 				<br />
// 				<Form onSubmit={defaultForm.handleSubmit(searchOrGetList)} autoComplete="off">
// 					<Form.Row>

// 						<Text<LpmaProjeto> form={defaultForm} layoutColumns={5} name="projeto" 
// 						label="Código do Projeto/Descrição" required={false} 
// 						></Text>	

// 						<span className="filter-btn" >
// 							<Button 
// 								title="Buscar"
// 								type="submit"
// 								contained
// 								isLoading={loadingSearch}
// 							/>
// 						</span>

// 					</Form.Row>
// 				</Form>
// 				{emptySearch	? <div className="div-empty"> <SearchEmpty text={'Código não encontrado.'} /> </div>	: ''}
// 				{loading		? <div className="loading-container"> <Loading /> </div>		: ''}				
// 			</div>			

// 			{(!loading && !error && !emptySearch && list.length) ? 
// 				<div className="search-content">
// 					<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
// 						<AgGridReact
// 							rowData={list}
// 							defaultColDef={{ flex: 1, sortable: true, resizable: true }}
// 							pagination
// 							paginationPageSize={10}
// 							gridOptions={{ headerHeight: 80 }}
// 							frameworkComponents={{
// 								actions: acoesbotoes,
// 							}}
// 						>
// 							<AgGridColumn
// 								headerName='Projeto'
// 								field='nome'
// 								sort="asc"
// 							/>			

//                             <AgGridColumn
// 								headerName='Cultura'
// 								field='cultura'
// 								sort="asc"
// 							/>			

//                             <AgGridColumn
// 								headerName='OGM'
// 								field='ogm'
// 								sort="asc"
// 							/>				
						
// 							<AgGridColumn
// 								field='Ações'
// 								cellRenderer='actions'
// 							/>
// 						</AgGridReact>
// 					</div>
// 				</div>
// 			: ''}
// 		</div>
// 	);
// }