import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { STRINGS_SHIPMENT } from "../../../util/shipment/string";
import { STRINGS } from '../../../util/strings';
import If from '../../../components/If';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';
import EditButton from '../../../components/EditButton';
import SearchEmpty from '../../../components/SearchEmpty';
import AlertError from '../../../components/AlertError';
import { CultureResult } from '../../../models/modelShipment';
import { statusToText } from '../../../functions/formatStatus';
import api from '../../../services/api';
import '../../../pages/Searchs/styles.css';

import '../solicitacao/styles.scss';

function Culture() {
    const history = useHistory();
    const [cultures, setcultures] = useState<CultureResult[]>([]);
    const [errorSearch, setErrorSearch] = useState<any>(null);
    const [errorGetCultures, setErrorGetCultures] = useState<any>(null);
    const [emptySearch, setEmptySearch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

    const { register, handleSubmit, getValues } = useForm({
        defaultValues: { name: '', active: true },
    })

    useEffect(() => {
        const getCultures = async () => {
            try {
                const { data, status } = await api.get<CultureResult[]>('api/v1/shipment/Cultura/obterporfiltro?Ativo=true');

                if (status === 200) {
                    setcultures(data);
                }
            } catch (error:any) {
                setErrorGetCultures(error.response.data);
            }
            setLoading(false);
        }

        getCultures();
    }, []);

    const search = async () => {
        let nome = getValues('name');
        let ativo = getValues('active');
        setLoadingSearch(true);
        setErrorSearch(null);

        try {
            const { data } = await api.get<CultureResult[]>(`api/v1/shipment/Cultura/obterporfiltro?Nome=${nome}&Ativo=${ativo}`);

            if (data.length) {
                setcultures(data);
                setEmptySearch(false);
            } else {
                setEmptySearch(true);
            }

        } catch (error:any) {
            setErrorSearch(error.response.data);
        }
        setLoadingSearch(false);
    }

    const actions = ({ data }: any) => (
        <Fragment>
            <EditButton
                onClick={() => history.push({
                    pathname: '/shipment/cultura/editar',
                    state: {
                        id: data.id
                    }
                })}
            />
        </Fragment>
    )

    return (
        <div className="container-custom-search">
            <div className="search-content-filters">
                <If condition={errorGetCultures !== null && !cultures.length}>
                    {errorGetCultures !== null && errorGetCultures.message.length > 0 ? errorGetCultures.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
                </If>
                <If condition={errorSearch !== null && !cultures.length}>
                    {errorSearch !== null && errorSearch.message.length > 0 ? errorSearch.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
                </If>
                <div className="top-content">
                    <h3>{STRINGS_SHIPMENT.SEARCH_CULTURE}</h3>
                </div>
                <div className="row-buttons">
                    <Button
                        onClick={() => history.push('/shipment/cultura/nova')}
                        contained
                        title="Adicionar/New"
                        type="button"
                    />
                </div>
                <br />
                <Form onSubmit={handleSubmit(search)}>
                    <Form.Row className="row-custom">
                        <Form.Group as={Col} md={8}>
                            <Form.Label>Nome da Cultura/Culture Name</Form.Label>
                            <Form.Control
                                ref={register}
                                name="name"
                                maxLength={60}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label />
                            <Form.Check
                                type="checkbox"
                                ref={register}
                                name="active"
                                label="Ativos/Actives"
                            />
                        </Form.Group>
                        <span className="filter-btn">
                            <Button
                                title="Buscar/Search"
                                type="submit"
                                contained
                                isLoading={loadingSearch}
                            />
                        </span>
                    </Form.Row>
                </Form>
                </div>
                <If condition={!loading && !emptySearch && cultures.length}>
                    <div className="search-content">
                        <div className="ag-theme-alpine" style={{ height: '70vh', width: '100%' }}>
                            <AgGridReact
                                rowData={cultures}
                                defaultColDef={{ flex: 1, sortable: true, resizable: true }}
                                pagination
                                paginationPageSize={10}
                                frameworkComponents={{
                                    actions: actions,
                                }}
                            >
                                <AgGridColumn
                                    headerName='Nome da Cultura/Culture Name'
                                    field="nome"
                                    cellStyle={{
                                        justifyContent: 'left'
                                    }}
                                    sort="asc"
                                />
                                <AgGridColumn
                                    headerName="Status/Status"
                                    field="ativo"
                                    valueFormatter={({ data }: any) => statusToText(data.ativo)}
                                    maxWidth={130}
                                />
                                <AgGridColumn
                                    field='Ações/Actions'
                                    cellRenderer='actions'
                                    maxWidth={130}
                                />
                            </AgGridReact>
                        </div>
                    </div>
                </If>
                <If condition={loading}>
                    <div className="loading-container">
                        <Loading />
                    </div>
                </If>
                <If condition={emptySearch}>
                    <div className="div-empty">
                        <SearchEmpty />
                    </div>
                </If>
            
        </div>
    );
}

export default Culture;