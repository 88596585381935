import React from 'react';
import { Spinner } from 'react-bootstrap';
import {colors} from '../util/colors';

function LoadingRequest() {
    return (
        <Spinner animation="border" size="sm" style={{color: colors.white}}/>
    );
};

export default LoadingRequest;