import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../components/Button';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import Loading from '../../../components/Loading';
import { STRINGS } from '../../../util/strings';
import { STRINGS_SHIPMENT } from "../../../util/shipment/string";
import { STRINGS_CIBIO } from '../../../util/authorizationsCIBIO/string';
import { CultureForm, CultureResult, StateType } from '../../../models/modelsAuthorizationsCIBIO';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import './styles.scss';
import api from '../../../services/api';
import '../solicitacao/styles.scss';


function Culture() {
    const history = useHistory();
    const location = useLocation<StateType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [errorGetData, setErrorGetData] = useState<any>(null);
    const [isConfirmation, setIsConfirmation] = useState<boolean>(false);

    const cultureSchema = yup.object().shape({
        nome: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        ativo: yup
            .boolean()
    })

    const { register, errors, handleSubmit, reset, formState: { isDirty } } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(cultureSchema)
    });

    useEffect(() => {
        const getCultureData = async () => {
            if (location.state && location.state.id) {
                try {
                    const { data } = await api.get<CultureResult>(`api/v1/shipment/Cultura/${location.state.id}`);
    
                    reset({
                        nome: data.nome,
                        ativo: data.ativo
                    })
                } catch (error:any) {
                    setErrorGetData(error?.response.data);
                }
                setLoading(false);
            }
        }
        getCultureData();
    }, [location.state, reset])

    const verifyErrorsOnForm = (): string[] => {
        let errorsForm: any[] = [];

        if (!isEmptyObj(errors)) {
            Object.entries(errors).map(err => errorsForm.push(err[0]));
        }
        return errorsForm;
    }

    const onSubmit = async (culture: CultureForm) => {
        setLoadingSubmit(true);
        setError(null);

        try {
            let saveApi = api.post;
            let itemID = location?.state?.id ?? 0;
            // console.log("itemID = ", itemID)

            if(itemID !== 0) saveApi = api.put;

            const { status } = await saveApi('api/v1/shipment/Cultura', {
                id: location?.state?.id ?? 0,
                nome: culture.nome,
                ativo: culture.ativo
            });

            if (status === 200 || status === 201) {
                setSuccess(true);

                setTimeout(() => {
                    history.goBack();
                }, 3500);
            }
        } catch (error:any) {
            console.error("error = ", error)
            setError(error?.response.data);
        }

        setLoadingSubmit(false);
    }

    return (
        <div className="container container-fluid">
            <div className="search-content-filters">
                <If condition={verifyErrorsOnForm()}>
                    {verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
                </If>
                <If condition={success && !error}>
                    <AlertSuccess
                        message={STRINGS.ALERT_MESSAGE_SUCCESS_EDIT}
                    />
                </If>
                <If condition={!success && error !== null}>
                    {error !== null && error.message.length > 0 ? error.message.map((errorE: string) => <AlertError message={errorE} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
                </If>
                <h3>{STRINGS_SHIPMENT.EDITION_CULTURE}</h3>
                <If condition={loading}>
                    <span className="loading-page">
                        <Loading />
                    </span>
                </If>
                <If condition={!loading && !errorGetData}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col}>
                                <Form.Label>
                                    Nome da Cultura/Culture Name<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="nome"
                                    type="text"
                                    placeholder="Insira o nome da cultura/Insert culture name"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            &nbsp;&nbsp;
                        <Form.Group className="checkbox-forms">
                                <Form.Check
                                    type="checkbox"
                                    ref={register}
                                    name="ativo"
                                    label="Ativo/Active?"
                                />
                            </Form.Group>
                        </Form.Row>
                        <div className="button-position">
                            <Button
                                title="Cancelar/Cancel"
                                type="button"
                                contained={false}
                                onClick={() => {
                                    isDirty ? setIsConfirmation(true) :
                                        history.goBack();
                                }}
                            />
                            <Button
                                title="Salvar/Save"
                                type="submit"
                                contained={true}
                                isLoading={loadingSubmit}
                            />
                        </div>
                    </Form>
                </If>
                <If condition={isConfirmation}>
                    <ConfirmationModal
                        show={isConfirmation}
                        confirmationMsg={STRINGS_CIBIO.CONFIRM_LEFT_EDITION}
                        cancelMsg={STRINGS_CIBIO.CANCEL_LEFT_EDITION}
                        onConfirm={() => history.goBack()}
                        onHide={() => setIsConfirmation(false)}
                        title={STRINGS_CIBIO.EDITION_TITLE_CONFIRMATION}
                        type="button"
                        variant="danger"
                    >
                        {STRINGS_CIBIO.EDITION_REGISTER_CONFIRMATION_CANCEL}
                    </ConfirmationModal>
                </If>
            </div>
        </div>
    );
}

export default Culture;