import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './styles.css';
import Button from '../../components/Button';
import InactiveButton from '../../components/InactiveButton';
import EditButton from '../../components/EditButton';
import { materialCategorieResults } from '../../tempdb.json';

function MaterialCategories() {
    const history = useHistory();
    const [rowSelected, setRowSelected] = useState<boolean>(false);

    const { register, handleSubmit } = useForm({
        defaultValues: { name: '', materialType: '', active: false },
    })

    const onSubmit = (data: any) => {
        // console.log(data);
    }

    const onRowSelected = (event: any) => {
        const { api } = event;
        // console.log(api.getSelectedNodes());

        if (!api.getSelectedNodes().length)
            setRowSelected(false);
        else
            setRowSelected(true)
    }

    const EditBtn = (props: any) => (
        <EditButton
            onClick={() => {}}
        />
    )

    return (
        <div className="container-custom-search">
            <div className="top-content">
                <h3>Consulta de categorias do material</h3>
            </div>
            <div className="row-buttons">
                <Button
                    onClick={() => history.push('/cadastrar/categorias-do-material')}
                    contained
                    title="Adicionar"
                    type="button"
                />
            </div>
            <br />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Form.Group as={Col} md={4}>
                        <Form.Label>Nome da categoria do material</Form.Label>
                        <Form.Control
                            ref={register}
                            name="name"
                            maxLength={100}
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            type="checkbox"
                            ref={register}
                            name="active"
                            label="Inativos"
                        />
                    </Form.Group>
                    <span className="filter-btn">
                        <Button
                            title="Buscar"
                            type="submit"
                            contained
                        />
                    </span>
                    <span className="filter-btn">
                        <InactiveButton
                            disable={!rowSelected}
                            onClick={() => alert()}
                        />
                    </span>
                </Form.Row>
            </Form>
            <div className="search-content">
                <div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
                    <AgGridReact
                        rowData={materialCategorieResults.row}
                        defaultColDef={{ flex: 1, sortable: true }}
                        suppressRowClickSelection={true}
                        rowSelection={'multiple'}
                        onRowSelected={onRowSelected}
                        pagination
                        paginationPageSize={10}
                        frameworkComponents={{
                            editBtn: EditBtn,
                        }}
                    >
                        <AgGridColumn
                            headerName="Selecionar"
                            headerCheckboxSelection={true}
                            checkboxSelection={true}
                            pinned="left"
                        />
                        <AgGridColumn
                            headerName='Nome da categoria do material'
                            field='nome'
                            wrapText={true}
                        />
                        <AgGridColumn
                            headerName='Descrição'
                            field='descricao'
                            wrapText={true}
                        />
                        <AgGridColumn
                            headerName='Tipo de material'
                            field='tipo_material'
                        />
                        <AgGridColumn
                            headerName="Inativo"
                            field="inativo"
                        />
                        <AgGridColumn
                            field='Editar'
                            cellRenderer='editBtn'
                        />
                    </AgGridReact>
                </div>
            </div>
        </div>
    );
}

export default MaterialCategories;