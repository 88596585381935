import React from 'react';
import DatePicker from 'react-datepicker';
import * as Bootstrap from 'react-bootstrap';

import './style.scss';

type CalendarType = 'default';
type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props
{
	value?						: Date | null;
	columns						: ColumnSize;
	type?						: CalendarType;
	label?						: string;
	format?						: string;
	disabled?					: boolean;
	maxDate?					: Date;
	minDate?					: Date;
	required?					: boolean;
	clearable?					: boolean;
	placeholder?				: string;
	onChange?					: {(date:Date) : any}
}

interface State
{
	
}

export default class Datepicker extends React.Component<Props, State>
{
	public static defaultProps = {
		type					: 'default',
		size					: 12,
		format					: 'dd/MM/yyyy',
		disabled				: false,
		clearable				: true,
		onChange				: (date:Date) => {},
	};

	constructor (props:Props)
	{
		super(props)

		this.state = {}
	}

	GetSimpleDate () : Date | null
	{
		if (this.props.value)
			return new Date(this.props.value);

		return null;
	}

	GetShowMonth () : boolean
	{
		return false;
	}

	GetShowYear () : boolean
	{
		return false;
	}

	ParseLabel () : JSX.Element
	{
		if (!this.props.label)
			return <React.Fragment />

		return <Bootstrap.Form.Label>{this.props.label} {this.props.required ? <em>*</em> : ''}</Bootstrap.Form.Label>;
	}

	render ()
	{
		return <Bootstrap.Form.Group
			className="custom-datefiled-form-group"
			as={Bootstrap.Col}
			md={this.props.columns}
		>
			{this.ParseLabel()}
			<DatePicker
				maxDate={this.props.maxDate}
				minDate={this.props.minDate}
				disabled={this.props.disabled}
				selected={this.GetSimpleDate()}
				onChange={(date:Date)=>{
					this.setState({date:date});

					if (this.props.onChange != undefined)
						this.props.onChange(date);
				}}
				onKeyDown={(event)=>{
					event.preventDefault();
					return false;
				}} 
				className={"input-custom-to-date custum_dateFiled "}
				locale="pt-BR"
				dateFormat={this.props.format}
				showMonthYearPicker={this.GetShowMonth()}
				showYearPicker={this.GetShowYear()}
				isClearable={this.props.clearable}
				placeholderText={this.props.placeholder}
			/>
		</Bootstrap.Form.Group>
	}
}