import axios from 'axios';
import { isTokenValid, isRefreshTokenValid } from '../functions/isSessionExpired';
import { DecodedToken } from '../models/user';
import jwtDecode from 'jwt-decode';
//import AppSettings from 'appsettings';

// const baseURI = 'https://localhost:5001';
// const baseURI = 'http://portalhermes-dev.basf.com';
// const baseURI = 'http://portalhermes-qa.basf.com';
const baseURI = 'https://portalhermes.basf.com';

const api = axios.create({
	baseURL: baseURI,
	headers: {
		'Content-Type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest', 
		'Access-Control-Allow-Origin': '*',
	}
});

export const apiPlusFile = axios.create({
	baseURL: baseURI,
	headers: {
		'Content-Type': "multipart/form-data",
	}
});

export const apiFile = axios.create({
	baseURL: baseURI,
	headers: {
		'Content-Type': 'application/pdf',
	}
});

export const apiFileDownload = axios.create({
	baseURL: baseURI,
	responseType: 'blob',
	headers: {
		'Content-Type': 'application/octet-stream',
		'Content-Disposition': 'attachment'
	}
})

const verifyEndUrl = (url: string): boolean =>
{
	return url.endsWith('Authenticate');
}

api.interceptors.request.use(async (config: any) =>
{
	if (!verifyEndUrl(config.url))
	{
		try
		{
			let token = localStorage.getItem('token');
			let refreshExpiresIn = localStorage.getItem('refreshExpiresIn');
			let tokenTimeCreated = localStorage.getItem('tokenTimeCreated');
			let refreshToken = localStorage.getItem('refreshToken');
			let decoded: DecodedToken = jwtDecode(String(token));

			if (!isTokenValid(decoded.exp) && isRefreshTokenValid(Number(refreshExpiresIn), String(tokenTimeCreated)))
			{
				const { data, status } = await axios.post(`${baseURI}/api/v1/Login/refresh-token`, {
					refreshToken
				})
				
				if (status === 200)
				{
					localStorage.setItem('token', data.access_token);
					localStorage.setItem('refreshToken', data.refresh_token);
					localStorage.setItem('refreshExpiresIn', data.refresh_expires_in);
					localStorage.setItem('tokenTimeCreated', data.created);

					api.defaults.headers.Authorization = `${data.token_type} ${data.access_token}`;
				}
			}
			else if (!isTokenValid(decoded.exp) && !isRefreshTokenValid(Number(refreshExpiresIn), String(tokenTimeCreated)))
			{
				localStorage.removeItem('token');
				localStorage.removeItem('refreshToken');
				localStorage.removeItem('refreshExpiresIn');
				localStorage.removeItem('tokenTimeCreated');

				window.location.pathname = "/login";
			}

			return config;
		}
		catch (error:any)
		{
			console.error(error);
		}
	}

	return config;
})

apiFile.interceptors.request.use(async (config: any) =>
{
	try
	{
		let token = localStorage.getItem('token');
		let refreshExpiresIn = localStorage.getItem('refreshExpiresIn');
		let tokenTimeCreated = localStorage.getItem('tokenTimeCreated');
		let refreshToken = localStorage.getItem('refreshToken');
		let decoded: DecodedToken = jwtDecode(String(token));

		if (!isTokenValid(decoded.exp) && isRefreshTokenValid(Number(refreshExpiresIn), String(tokenTimeCreated)))
		{
			const { data, status } = await axios.post(`${baseURI}/api/v1/Login/refresh-token`, {
				refreshToken
			})

			if (status === 200)
			{
				localStorage.setItem('token', data.access_token);
				localStorage.setItem('refreshToken', data.refresh_token);
				localStorage.setItem('refreshExpiresIn', data.refresh_expires_in);
				localStorage.setItem('tokenTimeCreated', data.created);

				apiFile.defaults.headers.Authorization = `${data.token_type} ${data.access_token}`;
			}
		}
		else if (!isTokenValid(decoded.exp) && !isRefreshTokenValid(Number(refreshExpiresIn), String(tokenTimeCreated)))
		{
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('refreshExpiresIn');
			localStorage.removeItem('tokenTimeCreated');

			window.location.pathname = "/login";
		}

		return config;
	}
	catch (error:any)
	{
		console.error(error);
	}

	return config;
})

apiFileDownload.interceptors.request.use(async (config: any) =>
{
	try
	{
		let token = localStorage.getItem('token');
		let refreshExpiresIn = localStorage.getItem('refreshExpiresIn');
		let tokenTimeCreated = localStorage.getItem('tokenTimeCreated');
		let refreshToken = localStorage.getItem('refreshToken');
		let decoded: DecodedToken = jwtDecode(String(token));

		if (!isTokenValid(decoded.exp) && isRefreshTokenValid(Number(refreshExpiresIn), String(tokenTimeCreated)))
		{
			const { data, status } = await axios.post(`${baseURI}/api/v1/Login/refresh-token`, {
				refreshToken
			})

			if (status === 200)
			{
				localStorage.setItem('token', data.access_token);
				localStorage.setItem('refreshToken', data.refresh_token);
				localStorage.setItem('refreshExpiresIn', data.refresh_expires_in);
				localStorage.setItem('tokenTimeCreated', data.created);

				apiFileDownload.defaults.headers.Authorization = `${data.token_type} ${data.access_token}`;
			}
		}
		else if (!isTokenValid(decoded.exp) && !isRefreshTokenValid(Number(refreshExpiresIn), String(tokenTimeCreated)))
		{
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('refreshExpiresIn');
			localStorage.removeItem('tokenTimeCreated');

			window.location.pathname = "/login";
		}

		return config;
	}
	catch (error:any)
	{
		console.error(error);
	}
	
	return config;
})

export default api;