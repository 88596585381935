export const PERMISSIONS = {
    Admin: "lpma.admin",
    EditarFluxo: "lpma.editar_fluxo",
    VisualizarTodas: "lpma.visualizar.todas",
    CriarRequisicao: "lpma.criar_requisicao",
    EditarRequisicao: "lpma.editar_requisicao",
    VisualizarMinhas: "lpma.visualizar.minhas",
    EditarTabelasAuxiliar: "lpma.editar_tabelas_auxiliar",
}


export const isAdmin = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.Admin);

    return isRole.length ? true : false;
}
export const isCheck = (roles: string[], isPerm: string): boolean => {
    const isRole = roles.filter(role => role === isPerm);
    return isRole.length ? true : false;
}
