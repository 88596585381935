import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import SimpleInput from '../../../components/ReactSimpleInput';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../../pages/Searchs/styles.css';
import Select from 'react-select';
import If from '../../../components/If';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';
import EditButton from '../../../components/EditButton';
import CancelButton from '../../../components/CancelRequisitionButton';
import ViewButton from '../../../components/ViewButton';
import PDFButton from '../../../components/GeneratePDF';
import SearchEmpty from '../../../components/SearchEmpty';
import ReasonModal from '../../../components/ReasonModal';
import { AMIById, AMISearchResult, CultureResult, Enum, UnityStationResult } from '../../../models/modelsAuthorizationsCIBIO';
import { DecodedToken } from '../../../models/user';
import { STRINGS_CIBIO } from '../../../util/authorizationsCIBIO/string';

import { isAdmin } from '../../../users/AuthorizationsCIBio/isAdmin';
import { isRequesterReader, isRequesterWriter } from '../../../users/AuthorizationsCIBio/isRequester';
import { isReguladorWriter, isReguladorReader } from '../../../users/AuthorizationsCIBio/isRegulador';

import { STATUS_CIBIO } from '../../../util/authorizationsCIBIO/status';
import jwtDecode from 'jwt-decode';
import api, { apiFileDownload } from '../../../services/api';
import ptBR from 'date-fns/locale/pt-BR';
import { _generateDate } from '../../../util/convert';
import AlertSuccess from '../../../components/AlertSuccess';
import { isOptionDisabled } from 'react-select/src/builtins';
import '../styles_add.scss';

registerLocale('pt-BR', ptBR);

export default function AMI ()
{
	const history = useHistory();
	const token = String(localStorage.getItem('token'));
	const { roles, unique_name }: DecodedToken = jwtDecode(token);
	const [amis, setAmis] = useState<AMISearchResult[]>([]);
	const [locais, setLocais] = useState<UnityStationResult[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [status, setStatus] = useState<Enum[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [isCancel, setIsCancel] = useState<boolean>(false);
	const [reasonCancel, setReasonCancel] = useState('');
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	const [successCancel, setSuccessCancel] = useState<boolean>(false);
	const [cancelError, setCancelError] = useState<any>(null);
	const [tempId, setTempId] = useState<number>(0);
	const [loadingAuthorizationById, setLoadingAuthorizationById] = useState<boolean>(false);
	const [authorization, setAuthorization] = useState<AMIById>();

	const { register, handleSubmit, watch, control } = useForm({
		defaultValues: {
			codigo: '',
			solicitante: (isRequesterReader(roles) || isRequesterWriter(roles)) ? unique_name : '',
			localEnvio: null,
			localDestino: null,
			cultura: null,
			dataInicial: null,
			dataFinal: null,
			status: null,
		}
	})

	const getAuthorizationsAMI = async () => {
		setError(false);
		try {
			let requester = (isRequesterReader(roles) || isRequesterWriter(roles)) ? unique_name : '';
			const { data } = await api.get<AMISearchResult[]>(`api/v1/autorizacoes-cibio/MovimentacaoInterna/obterporfiltro?Requisitante=${requester}`);
			setAmis(data);
		} catch (error:any) {
			setError(true);
		}
	}

	useEffect(() => {
		const getData = async () => {
			try {
				const locais = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');

				const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');

				const status = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/status');

				setLocais(locais.data);
				setCultures(cultures.data);
				setStatus(status.data);

			} catch (error:any) {

			}
			setLoading(false);
		}

		getData();
		getAuthorizationsAMI();
	}, [])


	const search = async (queries?: any) => {
		setError(false);
		setLoadingSearch(true);

		const {
			codigo,
			solicitante
		} = watch();

		try {	
			const { data } = await api.get<AMISearchResult[]>(`api/v1/autorizacoes-cibio/MovimentacaoInterna/obterporfiltro?Codigo=${codigo}&Requisitante=${solicitante}&LocalPartidaId=${queries?.localEnvio ? queries?.localEnvio.value : ''}&LocalDestinoId=${queries?.localDestino ? queries?.localDestino.value : ''}&CulturaId=${queries?.cultura ? queries?.cultura.value : ''}&DataInicial=${queries?.dataInicial ? _generateDate(new Date(queries?.dataInicial), 'En') : ''}&DataFinal=${queries?.dataFinal ? _generateDate(new Date(queries?.dataFinal), 'En') : ''}&Status=${queries?.status ? queries.status.value : ''}`);

			if (data.length) {
				setAmis(data);
				setEmptySearch(false);
			} else {
				setEmptySearch(true);
			}
		} catch (error:any) {
			setError(true);
		}
		setLoadingSearch(false);
	}

	const can_edit = (info : string) : boolean =>
	{
		const possuiPerfilAdmin = isAdmin(roles);
		const possuiPerfilRegulador = isReguladorReader(roles) || isReguladorWriter(roles);
		const possuiPerfilRequisitante = isRequesterReader(roles) || isRequesterWriter(roles);

		switch (info)
		{
			case 'filtro_solicitante':

				return possuiPerfilRequisitante || possuiPerfilRegulador || possuiPerfilAdmin;
		}

		return true;
	}

	const acoes = ({ data }: any) =>
	{
		const possuiPerfilAdmin = isAdmin(roles);
		const possuiPerfilRegulador = isReguladorReader(roles) || isReguladorWriter(roles);
		const ehRequisitanteDaRequisicao = data.requisitante === unique_name;
		const ehDestinatario = data.destinatarioLista.email === unique_name;

		const canEditStatusPerfilRequisitante = [
			STATUS_CIBIO.EmPreenchimento
			, STATUS_CIBIO.CorrecaoSolicitada
			, STATUS_CIBIO.EmCorrecao
			, STATUS_CIBIO.AprovadopeloRegulatorio
		];

		const canEditStatusPerfilRegulador = [
			STATUS_CIBIO.EmAnalisePeloRegulatorio
		];

		const canEditStatusPerfilDestinatario = [
			STATUS_CIBIO.AprovadopeloRegulatorio
		];

		let canEdit = false;

		if (possuiPerfilAdmin) {
			canEdit = true;
		} else if (possuiPerfilRegulador && canEditStatusPerfilRegulador.includes(data.status)) {
			canEdit = true;
		} else if (ehRequisitanteDaRequisicao && canEditStatusPerfilRequisitante.includes(data.status)) {
			canEdit = true;
		} else if (ehDestinatario && canEditStatusPerfilDestinatario.includes(data.status)) {
			canEdit = true;
		}
		
		let canCancel = canEdit && data.status !== STATUS_CIBIO.Cancelado;

		if (!possuiPerfilAdmin && !possuiPerfilRegulador)
		{
			const canCancelPerfilRequisitante = [
				STATUS_CIBIO.EmPreenchimento,
				STATUS_CIBIO.CorrecaoSolicitada,
				STATUS_CIBIO.EmAnalisePeloRegulatorio,
			];

			canCancel = canCancel && canCancelPerfilRequisitante.includes(data.status);
		}

		return (
			<Fragment>
				<EditButton disable={!canEdit} onClick={() => {
					if (canEdit) {
						history.push({
							pathname: '/autorizacoes/editar/ami',
							state: {
								id: data.id
							}
						})
					}
				}} />
				&nbsp;&nbsp;
				<CancelButton disabled={!canCancel} onClick={() => {

					if (!canCancel) return;

					setIsCancel(true);
					getAuthorizationById(data.id);
					setSuccessCancel(false);
					setReasonCancel('');

				}} />
				&nbsp;&nbsp;
				<ViewButton onClick={() => history.push({
					pathname: '/autorizacoes/visualizar/ami',
					state: {
						id: data.id
					}
				})} />
				&nbsp;&nbsp;
	
				<PDFButton onClick={() => getPdf(data.id, data.codigo)} />
			</Fragment>
		);
	}


	const getPdf = async (id: number, codigo:string) => {
		try {
			const { data } = await apiFileDownload.get(`api/v1/autorizacoes-cibio/Relatorios/movimentacao-interna?id=${id}`);

			if (codigo !== null)
				codigo = codigo.replace('/', '-');
			else
				codigo = "temporario-id-" + id.toString();

			let blob = new Blob([data], {
				type: 'application/pdf'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = `ami-${codigo}`;
				document.body.appendChild(a);
				a.click();
			}
		} catch (error:any) {
			console.error(error);
		}
	}


	const getAuthorizationById = async (id: number) => {
		setLoadingAuthorizationById(true);
		try {
			const { data } = await api.get(`api/v1/autorizacoes-cibio/MovimentacaoInterna/${id}`);

			setAuthorization(data);
		} catch (error:any) {
			throw new Error(error);
		}
		setLoadingAuthorizationById(false);
	}

	const cancelRequisition = async (data: any) => {
		setLoadingCancel(true);
		setCancelError(null);

		try {
			const { status } = await api.post('api/v1/autorizacoes-cibio/MovimentacaoInterna/cancelar', {
				id: data.id,
				requisitante: data.requisitante,
				destinatario: data.destinatario,
				destinatarioId: data.listaDestinatarioId,
				localDestinoPossuiCQB: data.possuiCQB,
				origem: data.origem,
				objetivoUtilizacao: data.objetivoUtilizacao,
				observacoes: data.observacoes,
				trechoLPMALocaisMovimentacaoMaterial: data.trechoLPMALocaisMovimentacaoMaterial,
				escopoLPMAContemplaMaterial: data.escopoLPMAContemplaMaterial,
				embalagemDuplaImpermeavel: data.embalagemDuplaImpermeavel,
				identificacaoSimboloRiscoBiologico: data.identificacaoSimboloRiscoBiologico,
				responsavelTransporte: data.responsavelTransporte,
				dataEnvio: data.dataEnvio,
				materialChegouDestinoPerfeitasCondicoes: data.materialChegouDestinoPerfeitasCondicoes,
				qtdRecebidaDeAcordoComQtdEnviada: data.qtdRecebidaDeAcordoComQtdEnviada,
				responsavelRecebimento: data.responsavelRecebimento,
				dataRecebimento: data.dataRecebimento,
				culturaId: data.culturaId,
				modoTransporteId: data.modoTransporteId,
				localPartidaId: data.localPartidaId,
				localDestinoId: data.localDestinoId,
				eventos: data.eventos,
				transportes: data.transportes,
				observacoesAnaliseRegulatorio: data.observacoesAnaliseRegulatorio,
				motivoCancelamento: reasonCancel,
				atividadeContencaoId: data.atividadeContencaoId,
			});
			if (status === 200) {
				setSuccessCancel(true);

				setTimeout(() => {
					setIsCancel(false);
					search();
				}, 3000);
			}
		} catch (error:any) {
			setCancelError(error.response.data);
		}
		setLoadingCancel(false);
	}

	return <div className="container-custom-search">
		<div className="top-content">
			<h3>{STRINGS_CIBIO.SEARCH_AMI}</h3>
		</div>
		<div className="row-buttons">
			<If condition={isAdmin(roles) || isRequesterWriter(roles)}>
				<Button
					onClick={() => history.push('/autorizacoes/cadastrar/ami')}
					contained
					title="Adicionar Autorização"
					type="button"
				/>
			</If>
		</div>
		<br />
		<Form onSubmit={handleSubmit(search)} autoComplete="off">
			<Form.Row>
				<Form.Group as={Col}>
					<Form.Label>Solicitante/Requisitante</Form.Label>
						<Controller
							control={control}
							name="solicitante"                                
							render={({ onChange, value }) => (
								<SimpleInput
									defaultValue={value}
									clearButton={can_edit('filtro_solicitante')}
									disabled={!can_edit('filtro_solicitante')}
									className="form-control"
									onChange={onChange}
								/>
							)}
						/>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>
						Local de envio/partida
					</Form.Label>
					<Controller
						control={control}
						name="localEnvio"
						isClearable
						placeholder=""
						as={<Select options={locais.map(local => Object.assign({ value: local.id, label: local.nome }))} />}
					/>
				</Form.Group>
				<Form.Group as={Col} md={4}>
					<Form.Label>
						Local de destino
					</Form.Label>
					<Controller
						control={control}
						name="localDestino"
						placeholder=""
						isClearable
						as={<Select options={locais.map(local => Object.assign({ value: local.id, label: local.nome }))} />}
					/>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Cultura/Organismo
					</Form.Label>
					<Controller
						control={control}
						name="cultura"
						isSearchable={false}
						isClearable
						placeholder=""
						as={<Select options={cultures.map(culture => Object.assign({ value: culture.id, label: culture.nome }))} />}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>Nº Autorização CIBio</Form.Label>
					<Controller
						control={control}
						name="codigo"
						render={({ onChange }) => (
							<SimpleInput 
								clearButton 
								className="form-control" 
								onChange={onChange}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Data inicial
					</Form.Label>
					<br />
					<Controller
						control={control}
						name="dataInicial"
						render={({ onChange, value }) => (
							<DatePicker
								selected={value}
								onChange={onChange}
								className="input-custom-to-date"
								locale="pt-BR"
								dateFormat="dd/MM/yyyy"
								placeholderText="Selecionar a data"
								isClearable
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Data Final
					</Form.Label>
					<br />
					<Controller
						control={control}
						name="dataFinal"
						render={({ onChange, value }) => (
							<DatePicker
								selected={value}
								onChange={onChange}
								className="input-custom-to-date"
								locale="pt-BR"
								dateFormat="dd/MM/yyyy"
								placeholderText="Selecionar a data"
								minDate={watch().dataInicial}
								isClearable
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Status
					</Form.Label>
					<Controller
						control={control}
						name="status"
						isSearchable={false}
						isClearable
						placeholder=""
						as={<Select options={status.map(stat => Object.assign({ value: stat.key, label: stat.value }))} />}
					/>
				</Form.Group>
				<span className="filter-btn">
					<Button
						title="Buscar"
						type="submit"
						contained
						isLoading={loadingSearch}
					/>
				</span>
			</Form.Row>
		</Form>
		<If condition={emptySearch}>
			<div className="div-empty">
				<SearchEmpty />
			</div>
		</If>
		<If condition={loading}>
			<div className="loading-container">
				<Loading />
			</div>
		</If>
		<If condition={!loading && !error && !emptySearch && amis.length}>
			<div className="search-content">
				<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
					<AgGridReact
						rowData={amis}
						defaultColDef={{ flex: 1, sortable: true, resizable: true }}
						pagination
						paginationPageSize={10}
						gridOptions={{ headerHeight: 80 }}
						frameworkComponents={{
							actions: acoes,
						}}
					>
						<AgGridColumn
							headerName='Código'
							field='id'
							maxWidth={130}
							sort="asc"
						/>
						<AgGridColumn
							headerName='Solicitante/ Requisitante'
							headerClass="myfunc-default-header"
							field='requisitante'
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName='Local do envio/ partida'
							headerClass="myfunc-default-header"
							field='localPartidaNome'
						/>
						<AgGridColumn
							headerName='Local de destino'
							headerClass="myfunc-default-header"
							field='localDestinoNome'
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName='Cultura/ Organismo'
							headerClass="myfunc-default-header"
							field='culturaNome'
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName='Nº Autorização CIBio'
							headerClass="myfunc-default-header"
							field='codigo'
						/>
						<AgGridColumn
							headerName='Emissão CIBio'
							headerClass="myfunc-default-header"
							field='dataEmissaoCibio'
							valueFormatter={({ data }: any) => data.dataEmissaoCibio ? _generateDate(data.dataEmissaoCibio, 'Pt') : ''}
						/>
						<AgGridColumn
							headerName='Status'
							field='statusDescricao'
						/>
						<AgGridColumn
							field='Ações'
							cellRenderer='actions'
						/>
					</AgGridReact>
				</div>
			</div>
		</If>

		<If condition={isCancel}>
			<ReasonModal
				show={isCancel}
				onHide={() => setIsCancel(false)}
				title={STRINGS_CIBIO.AMI_CANCEL_TITLE}
				actionMsg="Confirmar"
				disabled={reasonCancel.length ? false : true}
				onConfirm={() => {
					cancelRequisition(authorization);
				}}
				loading={loadingCancel}
				hideFooter={successCancel}
			>
				<If condition={loadingAuthorizationById}>
					<div className="loading-container">
						<Loading />
					</div>
				</If>
				<If condition={successCancel}>
					<AlertSuccess
						message={STRINGS_CIBIO.AMI_CANCEL_SUCCESS}
					/>
				</If>
				<If condition={!loadingAuthorizationById}>
					<Form.Label>
						<strong>{STRINGS_CIBIO.AMI_CANCEL_BODY_MSG}</strong>
					</Form.Label>
					<Form.Control
						onChange={({ target }: any) =>
							setReasonCancel(target.value)
						}
						value={reasonCancel}
						as="textarea"
						maxLength={200}
					/>
				</If>
			</ReasonModal>
		</If>
	</div>;
}