export const STRINGS_CQB = {
    NAV_CQB: "CQB",
    NAV_REGISTERS: "Cadastros",
    NAV_SOLICITACOES: "Solicitações",
    NAV_UNITYS_BASF: "Unidades/Estações BASF",
    NAV_CULTURE: "Cultura",
    NAV_PROJETO: "Projetos",
    NAV_LOCAL: "Locais",
    NAV_EQUIPE_TECNICA: "Equipe Técnica",
    NAV_EQUIPE_CIBIO: "Composição da CIBio",
    NAV_CONFIGURACOES: "Configurações",

    SEARCH_UNITIES_STATIONS: "Consulta de Unidades/Estações BASF",
    REGISTER_UNITIES_STATIONS: "Cadastro de Unidades/Estações BASF",
    EDITION_UNITIES_STATIONS: "Edição de Unidades/Estações BASF",

    SEARCH_PROJETOS: "Consulta de Projetos",
    REGISTER_PROJETOS: "Cadastro de Projetos",
    EDITION_PROJETOS: "Edição de Projetos",

    SEARCH_CONFIG: "Consulta de Configurações",
    EDITION_CONFIG: "Edição das Configurações",

    SEARCH_LOCAL: "Consulta de Local",
    REGISTER_LOCAL: "Cadastro de Local",
    EDITION_LOCAL: "Edição de Local",

    SEARCH_EQUIPE_TECNICA: "Consulta de Equipe Técnica",
    REGISTER_EQUIPE_TECNICA: "Cadastro de Equipe Técnica",
    EDITION_EQUIPE_TECNICA: "Edição de Equipe Técnica",

    EDITION_REGISTER_CONFIRMATION_CANCEL: "Todos os dados informados serão perdidos. Tem certeza que deseja cancelar?",
    EDITION_TITLE_CONFIRMATION: "Tem certeza?",
    CONFIRM_LEFT_EDITION: "Sim, voltar",
    CANCEL_LEFT_EDITION: "Não, permanecer",

    ALERT_SEND_SUCCESS: "E-Mail enviado com sucesso",
    APROVE_BODY_MSG: "Essa ação não poderá ser desfeita.",
    CANCEL_TITLE: "Deseja cancelar a autorização?",

    SEARCH_CQB: "Consulta de Solicitações de CQB",
    CANCEL_SUCCESS: "Requisição CQB cancelada com sucesso",

    NEW_CQB: "Nova Solicitação de CQB",
    EDIT_CQB: "Edição de Solicitação de CQB",
    VIEW_CQB: "Visualização de Solicitação de CQB",

    REGISTER_CQB: "Solicitação CQB",

    SESSAO_05_LABEL: "Informar a Classe de Risco dos Organismos Geneticamente Modificados (OGM) objeto das atividades a serem desenvolvidas, de acordo com as Resoluções Normativas da CTNBio.",
    SESSAO_06_LABEL: "Resumo dos projetos de pesquisa ou demais atividades que serão desenvolvidas com OGM e seus derivados.",
    SESSAO_13_LABEL: "Declaração: Declaração formal dos interessados quanto à competência técnica e de infra-estrutura da Unidade Operativa para a execução do trabalho programado.",
    SESSAO_7_1LABEL: "Modo de Uso ou Atividades/Especificar pormenorizadamente os Laboratórios, Casas de Vegetação, Campos Experimentais, unidade de beneficiamento e armazenamento de sementes, câmara fria, sala de manuseio e preparo de experimentos, biotério e outras instalações, nomeando e identificando cada uma na planta baixa a ser anexada ao processo.",
    SESSAO_7_2LABEL: "Informar localização, dimensões e características especiais relacionadas à biossegurança, atendendo às normas específicas da CTNBio.",
    SESSAO_7_3LABEL: "Informações sobre a planta de localização das áreas contíguas às instalações",
    SESSAO_08_LABEL: "Relacionar os principais equipamentos utilizados nos experimentos, as medidas e os Equipamentos de Proteção Individual e Coletiva (EPI e EPC) disponíveis na Unidade Operativa, informando, no que couber, sua localização na planta baixa",
    SESSAO_09_LABEL: "Relacionar os nomes, CPF, formação profissional, titulação e capacitação em biossegurança da equipe técnica envolvida no trabalho com OGM e seus derivados, apresentando Curriculum Vitae de cada participante que não estiver inscrito na Plataforma Lattes.",

    SESSAO_TAB_AUXILIA: "Para incluir a tabela de instalações/salas neste campo utilize a tag {{TABELA}}",

    MODO_CONFIGURAR: "Algumas sessões e tópicos necessitam de ajuste na geração do documento final(PDF).",
    MODO_CONFIGURAR_DLG: "Selecione os tópicos que inciaram em nova pagina",

    MODO_NUMER_AUTORIZ: "Infome o número de autorização da CIBIO",
    MODO_NUMER_AUTORIZ_DLG: "Número de autorização da CIBIO",

    ANEXO_REM_CONFIRMATION_CANCEL: "Confirmar a excluir anexo?",
    ANEXO_REM_TITLE_CONFIRMATION: "Tem certeza?",
    ANEXO_REM_CONFIRM_LEFT_EDITION: "Sim, excluir",
    ANEXO_REM_CANCEL_LEFT_EDITION: "Não, permanecer",

    ENVIAR_DATAS_FAIL_TITLE: "Campo de data invalido!",
    ENVIAR_DATAS_FAIL_TEXT: "Campo não pode estar vazio ou ser preenchido com campo invalido. Verifique a seguinte data:",

    BLOCK_ONE: "1. Dados da Solicitação",
    BLOCK_P_E: "2. Protocolo Experimental",
    BLOCK_TWO: "3. Dados Incluídos pelo Regulatório",
    BLOCK_TRE: "2. Fluxo de Envio",
    
    AMI_BLOCK_THREE: "3. Dados do Envio",
    AMI_BLOCK_FOUR: "4. Dados do Recebimento",
    AMI_FIELD_TO_SPECIFY: "Este campo deve ser preenchido",

    ALERT_WARNING_FIELDS: "Campos obrigatórios devem ser preenchidos",
    ALERT_MESSAGE_SUCCESS: "O registro foi salvo com sucesso",
    ALERT_STATUS_CORRECAO_SUCCESS: "Solicitação de Correção enviada com sucesso",
    ALERT_STATUS_APROVADA_SUCCESS: "Solicitação Aprovada com sucesso",
    ALERT_STATUS_CONCLUIDA_SUCCESS: "Solicitação Concluída com sucesso",
    ALERT_STATUS_REGULATORIO_SUCCESS: "Solicitação atualizada e Enviada ao Regulatório com sucesso",
};