
import TextField from  "../../../components/form/TextField";

import EditView from  "../../../components/page/EditView";
import { useForm } from "react-hook-form";
import CqbService from "../services";
import { STRINGS_CQB } from "../../../util/cqb/string";
import TextArea from "../../../components/form/TextArea";
import Fieldset from "../../../components/Fieldset";
import JsonToTable from "../../../components/form/JsonToTable";
import RichText from "../../../components/form/RichText";
import { CqbConfigucaoes } from "../../../models/modelsCQB";
import React from "react";
import { Case, Switch } from "../../../components/Basics/Switch";
import FileUpload from "../../../components/form/FileUpload";

interface IFileInfo{
	fileGuid	?: string;
	fileName	?: string;
	fileTemp	?: string;
	title		?: string;
}

export default function Edit (){
	const CqbServiceApi = new CqbService();
	const [entity, setEntity] = React.useState<CqbConfigucaoes>();
	const [fileInfo, setFileInfo] = React.useState<IFileInfo>();
	
	const defaultForm = useForm({
		defaultValues: entity,
		mode: "onSubmit",
	});

	return <EditView<CqbConfigucaoes> 
		title={STRINGS_CQB.EDITION_CONFIG}
		getLoadById={async (id: any)=>{ 
			return await CqbServiceApi.getConfigsById(id)
		 }}
		 form={defaultForm}
		 getEntity={(localentity:any)=>{
			 if(localentity?.tipoDeDado === 4){
				const jsonR = JSON.parse(localentity?.conteudo);
				setFileInfo(jsonR);
			 }
			setEntity(localentity)
		 }}
		 MsgSaveSubmitSucess="Parametro de Configuração Salvo com Sucesso"
		 OnSaveSubmit={async (values:any, entity: any)=>{			 
			try{
				let formData = new FormData();
				const newvalues = {
					...values,
				}
				for (var key in newvalues) {
					const valueByKey = newvalues[key];
					if(entity?.tipoDeDado === 4 &&  (key === "conteudo"))
					{
						const iFile = defaultForm.getValues('conteudo_file_temp');
						if(iFile) {for(let i = 0, t = iFile.length; i < t; i++){
							formData.append('fileUpload', iFile[i]);    	         
						}}
						const vJson = JSON.stringify({
							fileGuid: fileInfo?.fileGuid,
							fileName: fileInfo?.fileName,
							title: fileInfo?.title,
							//fileTemp: fileInfo?.fileTemp,
						})
						formData.append("fileInfo.fileGuid", fileInfo?.fileGuid ?? '');
						formData.append("fileInfo.fileName", fileInfo?.fileName ?? '');
						formData.append("fileInfo.title", fileInfo?.title ?? '');
						formData.append("conteudo", vJson);
						continue;
					}
					if(entity?.tipoDeDado === 4 &&  (key === "conteudo_file_temp")) {continue; }
					formData.append(key, valueByKey);
				}
				await CqbServiceApi.getConfigsSave(formData); 
				return {isOk: true};
			} catch (error:any) {
				return {isOk: false};
			}
		 }}
		 OnSaveSubmitIsOkGoBack={true}	 
		>
		<TextField<CqbConfigucaoes> form={defaultForm} required readonly={true} name="parametro" label="Parâmetro" layoutColumns={6}  />
		<TextArea<CqbConfigucaoes> rows={3} form={defaultForm} required readonly={true} name="descricao" label="Descrição" layoutColumns={12}  />
		{
			(entity?.tipoDeDado === 1)
			? <TextField<CqbConfigucaoes> 	form={defaultForm} required name="conteudo" label="Conteúdo" layoutColumns={12}  />
			: ((entity?.tipoDeDado === 2)
			? <RichText<CqbConfigucaoes> {...defaultForm.register(`conteudo`)} defaultValue={entity?.conteudo} 	form={defaultForm} required name="conteudo" label="Conteúdo" layoutColumns={12} />
			: ((entity?.tipoDeDado === 3) 
			? <JsonToTable<CqbConfigucaoes> 	form={defaultForm} name="conteudo" label="Conteúdo" layoutColumns={12}  /> 
			: ((entity?.tipoDeDado === 4) 
			? <FileUpload 	
				form={defaultForm} 
				name="conteudo_file_temp"
				otherName={fileInfo?.fileName}
				label={`${fileInfo?.title} (Arquivo de Imagem)`} 
				text={fileInfo?.fileTemp}
				textButton="Inserir Imagem" 
				accept="image/png, image/jpeg"
				layoutColumns={12}  onChange={(target:any,e:any)=>{		
					//fileInfo, setFileInfo	
					const fi = {
						...fileInfo,
						fileName: e,
					}
					setFileInfo(fi);
					defaultForm.setValue(fileInfo?.fileName ?? 'conteudo', e);
				}}/> 
			: '')))
		}
		
		{/* <Switch test={defaultForm.watch().tipoDeDado}>
		 	<Case value="1"><TextField<CqbConfigucaoes> 	form={defaultForm} required name="conteudo" label="Conteúdo" layoutColumns={12}  /></Case>
			<Case value="2"><RichText<CqbConfigucaoes> {...defaultForm.register(`conteudo`)} defaultValue={entity?.conteudo} 	form={defaultForm} required name="conteudo" label="Conteúdo" layoutColumns={12} /></Case>
		</Switch>	 */}
	</EditView>
}
