import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../../components/Button';
import If from '../../../../components/If';
import AlertError from '../../../../components/AlertError';
import AlertSuccess from '../../../../components/AlertSuccess';
import ResponseError from '../../../../components/ResponseError';
import { STRINGS } from '../../../../util/strings';
import { estados } from '../../../../util/states.json';
import { countries } from '../../../../util/countries';
import { ApplicantForm } from '../../../../models/models';
import api from '../../../../services/api';
import '../../styles_add.css';

export default function Applicant ()
{
	const history = useHistory();
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);
	const [country, setCountry] = useState<string>('');

	const applicantSchema = yup.object().shape({
		name: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		active: yup
			.boolean()
	})

	const initialValues: ApplicantForm = {
		name: '',
		cnpj: '',
		address: '',
		zipCode: '',
		city: '',
		state: '',
		phone: '',
		email: '',
		country: '',
		active: true
	}

	const { handleSubmit, register, errors, reset, setValue, control } = useForm({
		defaultValues: initialValues,
		mode: 'onTouched',
		resolver: yupResolver(applicantSchema)
	})

	const verifyErrorsOnForm = (): boolean => {
		if (errors.name) {
			return true;
		}
		return false;
	}

	const onSubmit = async (applicantData: ApplicantForm) => {
		setLoading(true);
		setError(null);
		try {
			const { status } = await api.post('api/v1/cultivares/Requerente', {
				nome: applicantData.name,
				ativo: applicantData.active,
				cnpj: applicantData.cnpj,
				endereco: applicantData.address,
				cep: applicantData.zipCode,
				municipio: applicantData.city,
				uf: applicantData.state,
				pais: applicantData.country,
				telefone: applicantData.phone,
				email: applicantData.email,
			});

			if (status === 201) {
				setSuccessfulResponse(true);
				reset({
					name: ''
				});
				setTimeout(() => {
					history.goBack();
				}, 3000);
			}
		} catch (error:any) {
			setError(error.response.data);
		}
		setLoading(false);
	}

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			<AlertError
				message={STRINGS.ALERT_WARNING_FIELDS}
			/>
		</If>
		<If condition={successfulResponse && !error}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS}
			/>
		</If>

		<If condition={!successfulResponse && error !== null}>
			{error !== null && error.message.length > 0 ? error.message.map((errorE: string) => <AlertError message={errorE} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<h3>Cadastro de Requerente</h3>
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Nome do requerente<em>*</em>
					</Form.Label>
					<Form.Control
						ref={register}
						name="name"
						type="text"
						placeholder="Insira o nome do requerente"
						isInvalid={errors.name !== undefined}
						autoComplete="off"
						maxLength={100}
					/>
					<If condition={errors.name}>
						<Form.Control.Feedback type="invalid">
							{errors.name?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>
						CNPJ/CPF
						<If condition={country === "Brasil"}>
							<em>*</em>
						</If>
					</Form.Label>
					<Controller
						isInvalid={errors.cnpj !== undefined}
						control={control}
						name="cnpj"
						render={(props: any) => {
							return (
								<NumberFormat
									value={props.value}
									onValueChange={(target) => {
										props.onChange();
										setValue('cnpj', target.value);
									}}
									onBlur={props.onBlur}
									format="##.###.###/####-##"
									className="form-control"
								/>
							)
						}}
					/>
					<If condition={errors.cnpj !== undefined}>
						<div className="error-field">
							{errors.cnpj?.message}
						</div>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.Label>
						Email<em>*</em>
					</Form.Label>
					<Form.Control
						ref={register}
						type="text"
						name="email"
						placeholder="Insira o email"
						isInvalid={errors.email !== undefined}
						autoComplete="off"
						maxLength={200}
					/>
					<If condition={errors.email}>
						<Form.Control.Feedback type="invalid">
							{errors.email?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Telefone</Form.Label>
					<Controller
						control={control}
						name="phone"
						render={(props: any) => {
							return (
								<NumberFormat
									value={props.value}
									onValueChange={(target) => {
										props.onChange();
										setValue('phone', target.value);
									}}
									onBlur={props.onBlur}
									format="(##) #####-####"
									className="form-control"
								/>
							)
						}}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>
						CEP
					</Form.Label>
					<Controller
						control={control}
						name="zipCode"
						render={(props: any) => {
							return (
								<NumberFormat
									value={props.value}
									onValueChange={(target) => {
										props.onChange();
										setValue('zipCode', target.value);
									}}
									onBlur={props.onBlur}
									format="#####-###"
									className="form-control"
								/>
							)
						}}
					/>
					<If condition={errors.zipCode}>
						<Form.Control.Feedback type="invalid">
							{errors.zipCode?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>Endereço</Form.Label>
					<Form.Control
						ref={register}
						type="text"
						placeholder="Insira o endereço da instituição"
						name="address"
						autoComplete="off"
						maxLength={255}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Município</Form.Label>
					<Form.Control
						ref={register}
						type="text"
						placeholder="Insira o município"
						name="city"
						autoComplete="off"
						maxLength={100}
					/>
				</Form.Group>
				<Form.Group as={Col} md={1}>
					<Form.Label>UF</Form.Label>
					<Form.Control
						as="select"
						name="state"
						ref={register}
					>
						<Form.Control
							as="option"
							label=''
						/>
						{estados.map((state) => (
							<Form.Control
								key={state.id}
								as="option"
								label={state.nome}
								value={state.nome}
							/>
						))}
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>País<em>*</em></Form.Label>
					<Form.Control
						as="select"
						name="country"
						isInvalid={errors.country !== undefined}
						ref={register}
						onChange={({ target }: any) => setCountry(target.value)}
					>
						<Form.Control
							as="option"
							label=''
						/>
						{countries.map((country) => (
							<Form.Control
								key={country}
								as="option"
								label={country}
								value={country}
							/>
						))}
					</Form.Control>
					<If condition={errors.country}>
						<Form.Control.Feedback type="invalid">
							{errors.country?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				&nbsp;&nbsp;
				<Form.Group className="checkbox-forms">
					<Form.Check
						type="checkbox"
						ref={register}
						name="active"
						label="Ativo?"
					/>
				</Form.Group>
			</Form.Row>
			<div className="button-position">
				<Button
					title="Cancelar"
					type="button"
					contained={false}
					onClick={() => history.goBack()}
				/>
				<Button
					title="Salvar"
					type="submit"
					contained={true}
					isLoading={loading}
				/>
			</div>
		</Form>
	</div>;
}