import React, { useState } from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ColaboratorUser } from '../../models/models';
import AlertSuccess from '../../components/AlertSuccess';
import { STRINGS } from '../../util/strings';
import api from '../../services/api';
import { useHistory } from 'react-router';
import If from '../../components/If';
import LoadingInForm from '../../components/LoadingInForm';

interface Props
{
	show					: boolean;
	onHide					: {() : any};
	colaboratorsDescriptors	: ColaboratorUser[];
	colaboratorsDiseases	: ColaboratorUser[];
	apiURL					: string;
}

interface Entity
{
	collabDiseases			: string;
	collabDescriptors		: string;
}

export default function ValidateRequisition (props: Props)
{
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false)
	const defaultForm = useForm({
		defaultValues: {} as Entity,
		resolver: yupResolver(yup.object().shape({
			collabDiseases: yup.string(),
			collabDescriptors: yup.string()
		}))
	});

	const onSubmit = async (data: Entity) => {
		setLoading(true);
		try {
			const { status } = await api.post(props.apiURL, {
				usuarioDescritores: data.collabDescriptors,
				usuarioDoencas: data.collabDiseases
			});

			if (status === 200) {
				setSuccess(true);
				setTimeout(() => {history.goBack();}, 1500);
			}
		} catch (error:any) {
			console.error(error);
		}
		setLoading(false);
	}

	const skipFito = () : boolean =>
	{
		if (defaultForm.watch().collabDescriptors)
			return false;

		if (defaultForm.watch().collabDiseases)
			return false;

		return true;
	}

	return <Modal
			animation
			show={props.show}
			onHide={props.onHide}
		>
		<Modal.Header closeButton>
			<Modal.Title>
				Enviar requisição para validação
			</Modal.Title>
		</Modal.Header>
		<If condition={success}>
			<AlertSuccess message={!skipFito() ? STRINGS.SUCCESS_SG_FITO : STRINGS.SUCCESS_SG_FITO_SKIP} />
		</If>
		<Modal.Body>
			<Form onSubmit={defaultForm.handleSubmit(onSubmit)}>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>
							Colaborador&#40;a&#41; Descritores
						</Form.Label>
						<Form.Control
							as="select"
							ref={defaultForm.register}
							name="collabDescriptors"
						>
							<Form.Control as="option" label="Não enviar para colaborador descritor" value="" />
							{props.colaboratorsDescriptors.map(item => <Form.Control as="option" key={item.userName} label={item.nome} value={item.userName} />)}
						</Form.Control>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>
							Colaborador&#40;a&#41; Doenças
						</Form.Label>
						<Form.Control
							as="select"
							ref={defaultForm.register}
							name="collabDiseases"
						>
							<Form.Control as="option" label="Não enviar para colaborador de doenças" value="" />
							{props.colaboratorsDiseases.map(item => <Form.Control as="option" key={item.userName} label={item.nome} value={item.userName} />)}
						</Form.Control>
					</Form.Group>
				</Form.Row>
				<Modal.Footer>
					<Button variant="secondary" onClick={props.onHide}>
						Cancelar
					</Button>
					<Button variant="success" type="submit">
						{loading ? <LoadingInForm color="white" /> : "Enviar"}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal.Body>
	</Modal>;
}