import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import If from '../../../../components/If';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/Button';
import EditButton from '../../../../components/EditButton';
import SearchEmpty from '../../../../components/SearchEmpty';
import { RecordTypesResults } from '../../../../models/models';
import { statusToText } from '../../../../functions/formatStatus';
import api from '../../../../services/api';
import '../../styles_list.css';

export default function RecordTypes ()
{
	const history = useHistory();
	const [recordTypes, setRecordTypes] = useState<Array<RecordTypesResults>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [tempId, setTempId] = useState<number>(0);

	const { register, handleSubmit, getValues } = useForm({
		defaultValues: { name: '', active: true },
	})

	useEffect(() => {
		const getRecordTypes = async () => {
			try {
				const { data } = await api.get<RecordTypesResults[]>(`api/v1/cultivares/TipoRegistro/obterporfiltro?Ativo=${getValues('active')}`);

				setRecordTypes(data);
			} catch (error:any) {
				setError(true);
			}
			setLoading(false);
		}
		getRecordTypes();
	}, [getValues]);

	const search = async () => {
		setError(false);
		setLoadingSearch(true);

		try {
			const { data } = await api.get<RecordTypesResults[]>(`api/v1/cultivares/TipoRegistro/obterporfiltro?Nome=${getValues('name')}&Ativo=${getValues('active')}`);

			if (data.length) {
				setRecordTypes(data);
				setEmptySearch(false);
			} else {
				setEmptySearch(true);
			}
		} catch (error:any) {
			setError(true);
		}
		setLoadingSearch(false);
	}

	const actions = ({ data }: any) => (
		<Fragment>
			<EditButton
				onClick={() => history.push({
					pathname: '/cultivares/tipos-de-registro/editar',
					state: {
						id: data.id
					}
				})}
			/>
		</Fragment>
	)

	return <div className="container-custom-search">
		<div className="top-content">
			<h3>Consulta de tipos de registro</h3>
		</div>
		<div className="row-buttons">
			<Button
				onClick={() => history.push('/cultivares/tipos-de-registro/cadastrar')}
				contained
				title="Adicionar"
				type="button"
			/>
		</div>
		<br />
		<Form onSubmit={handleSubmit(search)}>
			<Form.Row>
				<Form.Group as={Col} md={8}>
					<Form.Label>Nome do tipo de registro</Form.Label>
					<Form.Control
						ref={register}
						name="name"
						maxLength={100}
						autoComplete="off"
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label />
					<Form.Check
						type="checkbox"
						ref={register}
						name="active"
						label="Ativos"
					/>
				</Form.Group>
				<span className="filter-btn">
					<Button
						title="Buscar"
						type="submit"
						contained
						isLoading={loadingSearch}
					/>
				</span>
			</Form.Row>
		</Form>
		<If condition={emptySearch}>
			<div className="div-empty">
				<SearchEmpty />
			</div>
		</If>
		<If condition={!loading && !error && !emptySearch && recordTypes.length}>
			<div className="search-content">
				<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
					<AgGridReact
						rowData={recordTypes}
						defaultColDef={{ flex: 1, sortable: true, resizable: true }}
						pagination
						sortingOrder={["asc"]}
						paginationPageSize={10}
						frameworkComponents={{
							actions: actions,
						}}
					>
						<AgGridColumn
							headerName='Nome do tipo de registro'
							field='nome'
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName="Status"
							field="ativo"
							valueFormatter={({ data }: any) => statusToText(data.ativo)}
							maxWidth={130}
						/>
						<AgGridColumn
							field='Ações'
							cellRenderer='actions'
							maxWidth={130}
						/>
					</AgGridReact>
				</div>
			</div>
		</If>
		<If condition={loading}>
			<div className="loading-container">
				<Loading />
			</div>
		</If>
	</div>;
}