import React from 'react';
import './styles.css';
import ToolTip from './Tooltip';
import LoadingRequest from './LoadingRequest';

interface ButtonCustomProps {
    title: string;
	onClick?: any;
	contained?: boolean;
	isLoading?: boolean;
	name?: string;
	disabled?: boolean;
	className?: string;
	children?: any;
}

function ButtonCustom(e: ButtonCustomProps) {
    return (
        <ToolTip tooltipTxt={e.title}>
			{e.isLoading ?
				<LoadingRequest />
				:
				e.children
			}
        </ToolTip>
    );
}
export default ButtonCustom;