export const PERMISSIONS = {
    Admin: "cqb.admin",
    Regulatorio: "cqb.regulatorio",
    VisualizarTodas: "cqb.visualizar.todas",
    CriarRequisicao: "cqb.criar_requisicao",
    EditarRequisicao: "cqb.editar_requisicao",
    VisualizarMinhas: "cqb.visualizar.minhas",
    EditarTabelasAuxiliar: "cqb.editar_tabelas_auxiliar",
}


export const isAdmin = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.Admin);

    return isRole.length ? true : false;
}
export const isCheck = (roles: string[], isPerm: string): boolean => {
    const isRole = roles.filter(role => role === isPerm);
    return isRole.length ? true : false;
}

