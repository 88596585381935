import React from "react";
import { Form, Col } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from 'react-select';
import TextField from "./TextField";


interface CheckBoxProps
{
	form?       :any;
	name?       :string;
	label?      :string;
	isInvalid?  :boolean;
	placeholder?:string;
	control?    :any;
	length?     :number;	
	layoutColumns?  : number;
    options?        : any;
	isClearable?: boolean;
	required?       : boolean;
	enabled?        :boolean;
    isInvalidCheck? : boolean;
    onChange?       : any;
    onInputChange?       : any;
    onMenuClose?       : any;
    onBlur?             : any;
    key?		        : any;
	defaultChecked?   : boolean;
    value?          : any;
    checked?        :boolean;
    textBoxChecked? : boolean;
    textBoxName?    : string;
}

export default function CheckBox(props: CheckBoxProps)
{
	const nameDef = "realCheckBox";
	const [enabled_, setEnabled] = React.useState<boolean>(true);
    const [asErro, setAsErro] = React.useState<boolean>(true);
    const [textBoxChecked, setTextBoxChecked] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    }, [props.enabled, props.form?.errors, props?.name]);

    React.useEffect(() => {
        let _asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
        setAsErro(_asErro)
    }, [props.form?.errors, props?.name]);

    React.useEffect(()=>{ 
        if(props?.textBoxChecked !== null )
        { setTextBoxChecked(props?.textBoxChecked ?? false);  }
        else { setTextBoxChecked(false);}
    },[props?.defaultChecked, props?.textBoxChecked])

    React.useEffect(()=>{        
        if(props?.defaultChecked !== null )
        { setChecked(props?.defaultChecked ?? false);  }       
    },[props?.defaultChecked])


    return <Form.Group className="CheckBoxMain" as={Col} md={props.layoutColumns ?? 6}>
                <Form.Label>{props.label} {props.required ? <em>*</em> : ''}
                {/* <Form.Check
							type="checkbox"
							ref={props.form?.register}
							name={props.name ?? nameDef}
							label={null}
                            onChange={props.onChange}
                            onClick={props.onInputChange}
                            value={props.value}
						/> */}
                {/* <div className="form-check">
                    <input
                        className="form-check-input"
                        ref={props.form?.register}
                        name={props.name ?? nameDef}
                        type="checkbox"
                        checked={props.checked}
                        onChange={props.onChange}
                        onClick={props.onInputChange}
                        value={props.value}
                        />
                </div> */}
                 <input
                    disabled={!enabled_}
                    placeholder={props.placeholder}
                    className="form-check-input"
                    ref={props.form?.register}
                    name={props.name ?? nameDef}
                    type="checkbox"
                    checked={checked}
                    onChange={()=>{
                        const newchg = !checked;
                        setChecked(newchg);
                        if(props.onChange!=null) {props.onChange(newchg);}
                    }}
                    onClick={props.onInputChange}
                    value={props.value}
                    // defaultChecked={props.defaultValue}
                    // value={props.value}
                    />
                {(textBoxChecked === true && checked === true) ?
                <TextField form={props.form} noLabel enabled={enabled_}
				name={props.textBoxName} />
				: ''}
                {/* <Controller
                    defaultValue={props.defaultValue}
                    key={props.key}
                    control={props.form?.control}
                    name={props.name ?? nameDef}
                    isInvalid={(props.isInvalidCheck && asErro)}    
                    render={(chilProps)=>{
                        return  <input
                        {...chilProps}
                        className="form-check-input"
                        ref={props.form?.register}
                        name={props.name ?? nameDef}
                        type="checkbox"
                        // checked={props.checked ?? false}
                        onChange={props.onChange}
                        onClick={props.onInputChange}
                        value={props.value}
                        />
                    }}                     
                />         */}
                <span className="geekmark"></span>    
                </Form.Label>
                 
        </Form.Group>;

}
