import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col, FormGroup } from 'react-bootstrap';
import Button from '../../../../components/Button';
import If from '../../../../components/If';
import AlertError from '../../../../components/AlertError';
import AlertSuccess from '../../../../components/AlertSuccess';
import ResponseError from '../../../../components/ResponseError';
import Loading from '../../../../components/Loading';
import { STRINGS } from '../../../../util/strings';
import { RecordTypesForm, RecordTypesResults } from '../../../../models/models';
import api from '../../../../services/api';
import Fieldset from '../../../../components/Fieldset';
import ToggleSwitch from '../../../../components/ToggleSwitch';
import '../../styles_edit.css';

interface stateType {
	id: number;
}

export default function RecordTypes ()
{
	const history = useHistory();
	const location = useLocation<stateType>();
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);

	const [melhoristaResponsavel, setMelhoristaResponsavel] = useState(false);
	const [instituicaoResponsavel, setInstituicaoResponsavel] = useState(false);
	const [origemDaCultivar, setOrigemDaCultivar] = useState(false);
	const [avaliacoesDeCultivar, setAvaliacoesDeCultivar] = useState(false);
	const [descritores, setDescritores] = useState(false);
	const [reacoesDoencas, setReacoesDoencas] = useState(false);
	const [avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura, setAvaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura] = useState(false);
	const [avaliacaoDaProdutividade, setAvaliacaoDaProdutividade] = useState(false);
	const [avaliacaoDaQualidadeTecnologicaIndustrical, setAvaliacaoDaQualidadeTecnologicaIndustrical] = useState(false);
	const [intencaoDeComercializacao, setIntencaoDeComercializacao] = useState(false);
	const [sistemasDeProducaoSugeridosParaCultivar, setSistemasDeProducaoSugeridosParaCultivar] = useState(false);
	const [limitacoesDaCultivar, setLimitacoesDaCultivar] = useState(false);
	const [informacoesAdicionais, setInformacoesAdicionais] = useState(false);

	const recordTypesSchema = yup.object().shape({
		name: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		active: yup
			.boolean()
	})

	const { register, errors, handleSubmit, reset } = useForm({
		mode: 'onTouched',
		resolver: yupResolver(recordTypesSchema)
	});

	useEffect(() => {
		const getRecordTypesById = async () => {
			try {
				const { data } = await api.get<RecordTypesResults>(`api/v1/cultivares/TipoRegistro/${location.state.id}`);

				reset({
					name: data.nome,
					active: data.ativo
				})
				setMelhoristaResponsavel(data.melhoristaResponsavel);
				setInstituicaoResponsavel(data.instituicaoResponsavel);
				setOrigemDaCultivar(data.origemDaCultivar);
				setAvaliacoesDeCultivar(data.avaliacoesDeCultivar);
				setDescritores(data.descritores);
				setReacoesDoencas(data.reacoesDoencas);
				setAvaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura(data.avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura);
				setAvaliacaoDaProdutividade(data.avaliacaoDaProdutividade);
				setAvaliacaoDaQualidadeTecnologicaIndustrical(data.avaliacaoDaQualidadeTecnologicaIndustrical);
				setIntencaoDeComercializacao(data.intencaoDeComercializacao);
				setSistemasDeProducaoSugeridosParaCultivar(data.sistemasDeProducaoSugeridosParaCultivar);
				setLimitacoesDaCultivar(data.limitacoesDaCultivar);
				setInformacoesAdicionais(data.informacoesAdicionais);
			} catch (error:any) {
				setError(error.response.data);
			}
			setLoading(false);
		}

		getRecordTypesById();
	}, [location.state.id, reset]);

	const verifyErrorsOnForm = (): boolean => {
		if (errors.name) {
			return true;
		}
		return false;
	}

	const onSubmit = async (recordType: RecordTypesForm) => {
		setLoadingSubmit(true);
		setError(null);
		try {
			const { status } = await api.put('api/v1/cultivares/TipoRegistro', {
				id: location.state.id,
				nome: recordType.name,
				ativo: recordType.active,
				melhoristaResponsavel: melhoristaResponsavel,
				instituicaoResponsavel: instituicaoResponsavel,
				origemDaCultivar: origemDaCultivar,
				avaliacoesDeCultivar: avaliacoesDeCultivar,
				descritores: descritores,
				reacoesDoencas: reacoesDoencas,
				avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura: avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura,
				avaliacaoDaProdutividade: avaliacaoDaProdutividade,
				avaliacaoDaQualidadeTecnologicaIndustrical: avaliacaoDaQualidadeTecnologicaIndustrical,
				intencaoDeComercializacao: intencaoDeComercializacao,
				sistemasDeProducaoSugeridosParaCultivar: sistemasDeProducaoSugeridosParaCultivar,
				limitacoesDaCultivar: limitacoesDaCultivar,
				informacoesAdicionais: informacoesAdicionais
			});

			if (status === 200) {
				setSuccessfulResponse(true);
				reset({
					name: ''
				});
				window.scrollTo({top: 0, behavior: 'smooth'});
				setTimeout(() => {
					history.goBack();
				}, 3000);
			}
		} catch (error:any) {
			window.scrollTo({top: 0, behavior: 'smooth'});
			setError(error.response.data);
		}
		setLoadingSubmit(false);
	}

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			<AlertError
				message={STRINGS.ALERT_WARNING_FIELDS}
			/>
		</If>
		<If condition={successfulResponse && !verifyErrorsOnForm()}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS_EDIT}
			/>
		</If>
		<If condition={!successfulResponse && error}>
			{error !== null && error.message.length > 0 ? error.message.map((error: string) => <AlertError message={error} />) : null}
		</If>
		<h3>Edição de tipo de registro</h3>
		<If condition={loading}>
			<span className="loading-page">
				<Loading />
			</span>
		</If>
		<If condition={!loading}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Nome do tipo de registro<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="name"
							type="text"
							placeholder="Insira o nome do tipo de registro"
							isInvalid={errors.name !== undefined}
							autoComplete="off"
							maxLength={100}
						/>
						<If condition={errors.name}>
							<Form.Control.Feedback type="invalid">
								{errors.name?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					&nbsp;&nbsp;
					<Form.Group className="checkbox-forms">
						<Form.Check
							type="checkbox"
							ref={register}
							name="active"
							label="Ativo?"
						/>
					</Form.Group>
				</Form.Row>
				<Fieldset title="Campos obrigatórios">
					<div className="riquered-fieldset">
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Melhorista responsável
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="melhoristaResponsavel"
									small
									disabled={false}
									checked={melhoristaResponsavel}
									onChange={() => setMelhoristaResponsavel(!melhoristaResponsavel)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Instituição responsável
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="instituicaoResponsavel"
									small
									disabled={false}
									checked={instituicaoResponsavel}
									onChange={() => setInstituicaoResponsavel(!instituicaoResponsavel)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Origem cultivar
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="origemDaCultivar"
									small
									disabled={false}
									checked={origemDaCultivar}
									onChange={() => setOrigemDaCultivar(!origemDaCultivar)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Avaliação da cultivar
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="avaliacoesDeCultivar"
									small
									disabled={false}
									checked={avaliacoesDeCultivar}
									onChange={() => setAvaliacoesDeCultivar(!avaliacoesDeCultivar)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Descritores
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="descritores"
									small
									disabled={false}
									checked={descritores}
									onChange={() => setDescritores(!descritores)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Reações doenças
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="reacoesDoencas"
									small
									disabled={false}
									checked={reacoesDoencas}
									onChange={() => setReacoesDoencas(!reacoesDoencas)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Avaliação do cliclo de caracteristicas agronomicas da cultura
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura"
									small
									disabled={false}
									checked={avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura}
									onChange={() => setAvaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura(!avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Avaliação da produtividade
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="avaliacaoDaProdutividade"
									small
									disabled={false}
									checked={avaliacaoDaProdutividade}
									onChange={() => setAvaliacaoDaProdutividade(!avaliacaoDaProdutividade)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Avaliação da qualidade tecnológica industrial
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="avaliacaoDaQualidadeTecnologicaIndustrical"
									small
									disabled={false}
									checked={avaliacaoDaQualidadeTecnologicaIndustrical}
									onChange={() => setAvaliacaoDaQualidadeTecnologicaIndustrical(!avaliacaoDaQualidadeTecnologicaIndustrical)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Intenção de comercialização
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="intencaoDeComercializacao"
									small
									disabled={false}
									checked={intencaoDeComercializacao}
									onChange={() => setIntencaoDeComercializacao(!intencaoDeComercializacao)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Sistema de produção sugeridos para cultivar
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="sistemasDeProducaoSugeridosParaCultivar"
									small
									disabled={false}
									checked={sistemasDeProducaoSugeridosParaCultivar}
									onChange={() => setSistemasDeProducaoSugeridosParaCultivar(!sistemasDeProducaoSugeridosParaCultivar)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Limitacoes da cultivar
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="limitacoesDaCultivar"
									small
									disabled={false}
									checked={limitacoesDaCultivar}
									onChange={() => setLimitacoesDaCultivar(!limitacoesDaCultivar)}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="row-custom">
							<Col xs={10}>
								<Form.Label>
									Informações adicionais
						</Form.Label>
							</Col>
							<Col>
								<ToggleSwitch
									id="informacoesAdicionais"
									small
									disabled={false}
									checked={informacoesAdicionais}
									onChange={() => setInformacoesAdicionais(!informacoesAdicionais)}
								/>
							</Col>
						</Form.Row>
					</div>
				</Fieldset>

				<div className="button-position">
					<Button
						title="Cancelar"
						type="button"
						contained={false}
						onClick={() => history.goBack()}
					/>
					<Button
						title="Salvar"
						type="submit"
						contained={true}
						isLoading={loadingSubmit}
					/>
				</div>
			</Form>
		</If>
	</div>;
}