export const STRINGS_LPMA = {
    NAV_LPMA: "LPMA",
    NAV_REGISTERS: "Cadastros",
    NAV_SOLICITACOES: "Solicitações",
    NAV_UNITYS_BASF: "Unidades/Estações BASF",
    NAV_CULTURE: "Cultura",
    NAV_PROJETO: "Projetos",
    NAV_LOCAL: "Locais",
    NAV_EQUIPE_TECNICA: "Equipe Técnica",
    NAV_CONFIGURACOES: "Configurações",

    SEARCH_UNITIES_STATIONS: "Consulta de Unidades/Estações BASF",
    REGISTER_UNITIES_STATIONS: "Cadastro de Unidades/Estações BASF",
    EDITION_UNITIES_STATIONS: "Edição de Unidades/Estações BASF",

    SEARCH_PROJETOS: "Consulta de Projetos",
    REGISTER_PROJETOS: "Cadastro de Projetos",
    EDITION_PROJETOS: "Edição de Projetos",

    SEARCH_CONFIG: "Consulta de Configurações",
    EDITION_CONFIG: "Edição das Configurações",

    // SEARCH_CULTURE: "Consulta de culturas",
    // REGISTER_CULTURE: "Cadastro de cultura",
    // EDITION_CULTURE: "Edição de cultura",

    SEARCH_LOCAL: "Consulta de Local",
    REGISTER_LOCAL: "Cadastro de Local",
    EDITION_LOCAL: "Edição de Local",

    SEARCH_EQUIPE_TECNICA: "Consulta de Equipe Técnica",
    REGISTER_EQUIPE_TECNICA: "Cadastro de Equipe Técnica",
    EDITION_EQUIPE_TECNICA: "Edição de Equipe Técnica",

    ALERT_SEND_SUCCESS: "E-Mail enviado com sucesso",
    APROVE_BODY_MSG: "Essa ação não poderá ser desfeita.",
    CANCEL_TITLE: "Deseja cancelar a autorização?",

    SEARCH_LPMA_RN35: "Consulta de Solicitações de LPMA RN35",
    SEARCH_LPMA_RN06: "Consulta de Solicitações de LPMA RN06",
    CANCEL_SUCCESS: "Requisição LPMA cancelada com sucesso",

    NEW_LPMA_RN: "Nova Solicitação de LPMA",
    EDIT_LPMA_RN: "Edição de Solicitação de LPMA",
    VIEW_LPMA_RN: "Visualização de Solicitação de LPMA",

    REGISTER_LPMA_RN35: "Solicitação LPMA RN35",
    REGISTER_LPMA_RN06: "Solicitação LPMA RN06",

    ANEXO_REM_CONFIRMATION_CANCEL: "Confirmar a excluir anexo?",
    ANEXO_REM_TITLE_CONFIRMATION: "Tem certeza?",
    ANEXO_REM_CONFIRM_LEFT_EDITION: "Sim, excluir",
    ANEXO_REM_CANCEL_LEFT_EDITION: "Não, permanecer",

    ENVIAR_DATAS_FAIL_TITLE: "Campo de data invalido!",
    ENVIAR_DATAS_FAIL_TEXT: "Campo não pode estar vazio ou ser preenchido com campo invalido. Verifique a seguinte data:",

    BLOCK_DADOS: "1. Dados da Solicitação",
    BLOCK_PROTOCOLO: "2. Protocolo Experimental",
    BLOCK_L_E: "3. Locais de Ensaio",
    BLOCK_CONSTRUCOES: "4. Potenciais Construções",
    BLOCK_GENES: "5. Genes de Interesse",
    BLOCK_REGULATORIO: "6. Dados Incluídos pelo Regulatório",

    BLOCK_ONE: "1. Dados da Solicitação",
    BLOCK_TWO: "2. Protocolo Experimental",

    //AMI_BLOCK_THREE: "3. Dados do Envio",
    //AMI_BLOCK_FOUR: "4. Dados do Recebimento",
    //AMI_FIELD_TO_SPECIFY: "Este campo deve ser preenchido",

    ALERT_WARNING_FIELDS: "Campos obrigatórios devem ser preenchidos",
    ALERT_MESSAGE_SUCCESS: "O registro foi salvo com sucesso",
    ALERT_STATUS_CORRECAO_SUCCESS: "Solicitação de Correção enviada com sucesso",
    ALERT_STATUS_APROVADA_SUCCESS: "Solicitação Aprovada com sucesso",
    ALERT_STATUS_CONCLUIDA_SUCCESS: "Solicitação Concluída com sucesso",
    ALERT_STATUS_REGULATORIO_SUCCESS: "Solicitação atualizada e Enviada ao Regulatório com sucesso",

    MODO_NUMER_AUTORIZ: "Infome o número de autorização da CIBIO",
    MODO_NUMER_AUTORIZ_DLG: "Número de autorização da CIBIO",

    MODO_CONFIGURAR: "Algumas sessões e tópicos necessitam de ajuste na geração do documento final(PDF).",
    MODO_CONFIGURAR_DLG: "Selecione os tópicos que inciaram em nova pagina",

    GENEINTERESSE_MODAL_TITLE: "Adicionar gene de interesse",
    GENEINTERESSE_EDIT_MODAL_TITLE: "Editar gene de interesse",

    NEW_LPMA_RNCOMUNICADOS: "Novos Comunicados para LPMA",
    EDIT_LPMA_RNCOMUNICADOS: "Edição de Comunicados para LPMA",
    VIEW_LPMA_RNCOMUNICADOS: "Visualização de Comunicados de LPMA",

    CREATE_COMUNICADO_SUCESS: "Comunicado criado com sucesso",
    CREATE_COMUNICADO_ERROR: "Erro na criação do Comunicado",
    SAVE_COMUNICADO_SUCCESS: "Comunicado salvo com sucesso",

};