import React from "react";
import { Form, Col } from "react-bootstrap";
import { Controller } from "react-hook-form";

import NumberFormat from 'react-number-format';
import CheckMsgErros from './_msgErrs';

import "./style.scss";
import { clear } from "console";

interface Props<Type>
{
	form		?: any;// UseFormMethods<Type>;
	
	name		?: string;
	label		?: string;
	placeholder	?: string;
	adicionalText? : string;
	length		?: number;
	required	?: boolean;
	readonly	?: boolean;
	autoComplete	? 	: boolean;
	layoutColumns	? : number;
	className		?:string;
	enabled		?:boolean;
	isNumer			?: boolean;
	isInvalid		?: any;
	isInvalidCheck	?: boolean;
	isClearable		?: boolean;
	onChange		?: any;
	onKeyDown		?: any;
	key				?: any;
	defaultValue	?: any;
	FormGroupClassName ?: any;
	prefix			?: any;
	suffix		?: any;
	thousandsGroupStyle?: 'thousand' | 'lakh' | 'wan';
	value		?: string | string[] | number;
	isSingle	?: boolean;
	noLabel		?: boolean;
	sup			?: any;
}

export default function TextField<Type> (props: Props<Type>)
{
	const nameDef = "TextField";
	const [enabled_, setEnabled] = React.useState<boolean>(true);
	//	{/* {props?.isInvalid ? props?.isInvalid(props.form.errors) : ''} */}
	const [asErro, setAsErro] = React.useState<boolean>(true);
	const [msgError, setMsgError] = React.useState<any>();
	const [valueChanged, setValueChanged] = React.useState<string | string[] | number>('');

	React.useEffect(() => {	
		if(props.value !== null && props.value !== undefined){
			setValueChanged(props.value ?? '');
		}
		else if(props.defaultValue !== null && props.defaultValue !== undefined)
		{
			setValueChanged(props.defaultValue);
		}
	},[]);

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    },[props.enabled]);

	React.useEffect(() => {

		CheckMsgErros(props, (msg:any)=>{ setMsgError(msg) }, (err:any)=>{ setAsErro(err) })

        // let 	_asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
		// const 	arrayNm = props?.name?.includes('.') ?? false;
        // if(props?.name  && props.form?.errors && !_asErro && arrayNm){
		// 	const _name_ = props?.name.split('.')
		// 	if(_name_.length >= 3)
		// 	{
		// 		const elm = {...props.form?.errors[_name_[0]][_name_[1]]}							
		// 		_asErro = (elm[_name_[2]] !== undefined);
		// 		if(_asErro) { setMsgError(elm[_name_[2]]?.message);}
		// 	}
		// }
		// else {
		// 	setMsgError(null);
		// }
		// setAsErro(_asErro)
    }, [props.form?.errors, props?.name]);

	const ClearButton = ()=>{
		if(!enabled_ || props.isClearable === false || !props.isClearable) {return ''; }
		return <div className='textField-input-clear' onClick={ () => { Clear()  } }>&times;</div>
	}

	const Clear = () =>{
		if(props.form == null) return;
		if(props.name == null) return;
		props.form.setValue(props.name, '');
	}

	if(props.isSingle === true){
		return <Form.Group as={Col}>
				{props.noLabel === true ? '' :
				<Form.Label>
					{props.label}{props.sup ? <sup>{props.sup}</sup>:''} {props.required ? <em>*</em> : ''}
				</Form.Label>
				}
				<Form.Control
					ref={props.form?.register}			
					name={props.name ?? nameDef}
					value={props.value}
					type="text"
					readOnly
				/>
				{ClearButton()}
			</Form.Group>
	}
	  
	return (props.isNumer === true ) 
		?<Form.Group className={props.FormGroupClassName} as={Col} md={props.layoutColumns ?? 12}>
				{props.noLabel === true ? '' :
				<Form.Label>
					{props.label}{props.sup ? <sup>{props.sup}</sup>:''} {props.required ? <em>*</em> : ''}
				</Form.Label>
				}
				<Controller				
				required
				name={props.name ?? nameDef}
				control={props.form?.control}
				key={props.key}			
				ref={props.form?.register}				
				isInvalid={(props.isInvalidCheck && asErro)}			
				render={(chilProps) => (
					<NumberFormat
					{...chilProps}
					inputRef={chilProps.ref}
            		onBlur={chilProps.onBlur}
					
					className={(props.className ?? '') + " form-control disableMode text-align-right"}
					decimalSeparator=","
					prefix={props.prefix}
					suffix={props.suffix}
					defaultValue={props.defaultValue ?? '0'}	
					placeholder={props.placeholder}
					readOnly={props.readonly ?? false}
					maxLength={props.length}					
					type="text"
					disabled={!enabled_}
					onChange={chilProps.onChange}
					onKeyDown={props.onKeyDown}					
					onValueChange={(values)=>{						
						props.form.setValue(props.name, values.floatValue );
					}}		
					isInvalid={(props.isInvalidCheck && asErro)}									
					/>
				)}							
				/>
				{ClearButton()}
								
				{(props.isInvalidCheck && asErro)? 
                <p className="error-field">{
					props?.name ? (msgError ?? props.form?.errors[props?.name]?.message) : ''
					}</p>
                :''}
			</Form.Group>

	  : <Form.Group as={Col} md={props.layoutColumns ?? 12}>
		  {props.noLabel === true ? '' :
		<Form.Label>
			{props.label}{props.sup ? <sup>{props.sup}</sup>:''} {props.required ? <em>*</em> : ''}
		</Form.Label> }
		<Form.Control
			key={props.key}
			defaultValue={props.defaultValue}
			value={(props.value == null || props.value === undefined ? undefined : valueChanged)}
			ref={props.form?.register}
			name={props.name}			
			placeholder={props.placeholder}
			readOnly={props.readonly ?? false}
			maxLength={props.length}
			autoComplete={props.autoComplete === false ? "off" : ''}
			className={props.className ?? ''}
			disabled={!enabled_}
			type={props.isNumer ? "number" : "text"}
			onChange={(event:any)=>{
				if(props.onChange) {props.onChange(event);}
				setValueChanged(event?.target?.value);
			}}
			onKeyDown={props.onKeyDown}
			isInvalid={(props.isInvalidCheck && asErro && enabled_)}
		/>
		{ClearButton()
		/* <div className="col-auto">
            <button className="btn btn-outline-secondary border-0 btn-pos" type="button">
                <i className="fa fa-times"></i>
            </button>
        </div> */}
		{props.adicionalText ? <span className="small text-info">{props.adicionalText}</span> : ''}
		<Form.Control.Feedback type="invalid">					
			{props?.name ? (msgError ??  props.form?.errors[props?.name]?.message) : ''}
		</Form.Control.Feedback>
	</Form.Group>
	;
}