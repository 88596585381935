import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { STRINGS_SHIPMENT } from "../../../util/shipment/string";
import { STRINGS } from '../../../util/strings';
import If from '../../../components/If';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';
import EditButton from '../../../components/EditButton';
import SearchEmpty from '../../../components/SearchEmpty';
import AlertError from '../../../components/AlertError';
import { RequsitanteDto } from '../../../models/modelShipment';
import { statusToText } from '../../../functions/formatStatus';
import api from '../../../services/api';
import '../../../pages/Searchs/styles.css';
import '../solicitacao/styles.scss';


function RequisitanteList() {
    const history = useHistory();
    const [Requisitantes, setRequisitantes] = useState<RequsitanteDto[]>([]);
    const [errorSearch, setErrorSearch] = useState<any>(null);
    const [errorGetRequisitantes, setErrorGetRequisitantes] = useState<any>(null);
    const [emptySearch, setEmptySearch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

    const { register, handleSubmit, getValues } = useForm({
        defaultValues: { name: '', email: '', active: true },
    })

    useEffect(() => {
        const getRequisitantes = async () => {
            try {
                const { data, status } = await api.get<RequsitanteDto[]>('api/v1/shipment/Requisitante/obterporfiltro?Ativo=true');

                if (status === 200) {
                    setRequisitantes(data);
                }
            } catch (error:any) {
                setErrorGetRequisitantes(error.response.data);
            }
            setLoading(false);
        }

        getRequisitantes();
    }, []);

    const search = async () => {
        let nome = getValues('name');
        let ativo = getValues('active');
        let email = getValues('email');
        setLoadingSearch(true);
        setErrorSearch(null);

        try {
            const { data } = await api.get<RequsitanteDto[]>(`api/v1/shipment/Requisitante/obterporfiltro?Nome=${nome}&Email=${email}&Ativo=${ativo}`);

            if (data.length) {
                setRequisitantes(data);
                setEmptySearch(false);
            } else {
                setEmptySearch(true);
            }

        } catch (error:any) {
            setErrorSearch(error.response.data);
        }
        setLoadingSearch(false);
    }

    const actions = ({ data }: any) => (
        <Fragment>
            <EditButton
                onClick={() => history.push({
                    pathname: '/shipment/requisitante/editar',
                    state: {
                        id: data.id
                    }
                })}
            />
        </Fragment>
    )

    return (
        <div className="container-custom-search">
            <div className="search-content-filters">
            <If condition={errorGetRequisitantes !== null && !Requisitantes.length}>
                {errorGetRequisitantes !== null && errorGetRequisitantes.message.length > 0 ? errorGetRequisitantes.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
            </If>
            <If condition={errorSearch !== null && !Requisitantes.length}>
                {errorSearch !== null && errorSearch.message.length > 0 ? errorSearch.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
            </If>
            <div className="top-content">
                <h3>{STRINGS_SHIPMENT.SEARCH_REQUISITANTES}</h3>
            </div>
            <div className="row-buttons">
                <Button
                    onClick={() => history.push('/shipment/requisitante/nova')}
                    contained
                    title="Adicionar/New"
                    type="button"
                />
            </div>
            <br />
            <Form onSubmit={handleSubmit(search)}>
                <Form.Row className="row-custom">
                    <Form.Group as={Col} md={4}>
                        <Form.Label>Nome do Requisitante/Requester name</Form.Label>
                        <Form.Control
                            ref={register}
                            name="name"
                            maxLength={60}
                        />
                    </Form.Group>


                    <Form.Group as={Col} md={5}>
                        <Form.Label>E-mail/E-mail</Form.Label>
                        <Form.Control
                            ref={register}
                            name="email"
                            maxLength={60}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={1}>
                        <Form.Label />
                        <Form.Check
                            type="checkbox"
                            ref={register}
                            name="active"
                            label="Ativos/Actives"
                        />
                    </Form.Group>   

                     <Form.Group as={Col} md={2}>
                        <span className="filter-btn">
                            <Button
                                title="Buscar/Search"
                                type="submit"
                                contained
                                isLoading={loadingSearch}
                            />
                        </span>
                    </Form.Group>  

                </Form.Row>     
            </Form>
            </div>
            <If condition={!loading && !emptySearch && Requisitantes.length}>
                <div className="search-content">
                    <div className="ag-theme-alpine" style={{ height: '70vh', width: '100%' }}>
                        <AgGridReact
                            rowData={Requisitantes}
                            defaultColDef={{ flex: 1, sortable: true, resizable: true }}
                            pagination
                            paginationPageSize={10}
                            frameworkComponents={{
                                actions: actions,
                            }}
                        >
                            <AgGridColumn
                                headerName='Nome do Requisitante/Requester name'
                                field="nome"
                                cellStyle={{
                                    justifyContent: 'left'
                                }}
                                sort="asc"
                            />
                            <AgGridColumn
                                headerName='E-mail/E-mail'
                                field="email"
                                cellStyle={{
                                    justifyContent: 'left'
                                }}
                                sort="asc"
                            />
                            <AgGridColumn
                                headerName="Status/Status"
                                field="ativo"
                                valueFormatter={({ data }: any) => statusToText(data.ativo)}
                                maxWidth={130}
                            />
                            <AgGridColumn
                                field='Ações/Actions'
                                cellRenderer='actions'
                                maxWidth={130}
                            />
                        </AgGridReact>
                    </div>
                </div>
            </If>
            <If condition={loading}>
                <div className="loading-container">
                    <Loading />
                </div>
            </If>
            <If condition={emptySearch}>
                <div className="div-empty">
                    <SearchEmpty />
                </div>
            </If>            
        </div>
    );
}

export default RequisitanteList;