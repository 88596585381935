import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import * as EditButtons from "../EditButton";
import Button from  "../../components/Button";
import "./style.scss";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import {uuid} from "../../services/uuid";
import { ListCol } from '../page/ListingView';
import Loading from '../Loading';
import ConfirmationModal from "../ConfirmationModal";
import { PaginationChangedEvent } from 'ag-grid-community';

interface searchdialogProps{
    title           ?:  string;
    className       ?:  string;
    enabled         ?:  boolean;
    buttonSearch    ?:  string; 
    form            ?:  any;
    name            ?:  string;

    filters         ?:  any;  
    onClose         ?:  () => void;
    selectResult    ?:  (itemsel: any, formDataValues: any) => Promise<boolean>;
    multiSelect     ?:  boolean;
    selectMultiResult?:  (itemsel: any[]) => Promise<boolean>;
    searching       ?:  any;
    children        ?:  any;

    actionsMaxWidth?: any;
	actionsMinWidth?: any;

    showMe        : boolean;
    msgText       ?: string;
}

export default function SearchBtnDialog(props: searchdialogProps){

    const [showMe,          setShowMe]          = React.useState<boolean>(false);
    const [loadingSearch,   setLoadingSearch]   = React.useState<boolean>(false);
    const [listItems,       setListItems]       = React.useState<any[]>([]);
    const [formUid,         setFormUid]         = React.useState<string>(uuid());
    const [selecionando,    setSelecionando]    = React.useState<boolean>(false);
    const [isCheckSel,      setIsCheckSel]    = React.useState<boolean>(false);
    const [checkItemMode,   setCheckItemMode]    = React.useState<boolean>(false);

    const [modalConfirm, setModalConfirm] = React.useState<any>({show:false, title: '', mensagem: '', actionConfirm: ()=>{} });
    

    React.useEffect(() => {
        if(props.showMe === true){
            setListItems([]);
        }
        setShowMe(props.showMe);
    },[props.showMe]);

    
    React.useEffect(() => {
        const checkList = listItems.filter((e:any)=> e.checked === true).length > 0;
        setIsCheckSel(checkList)
    },[listItems, checkItemMode]);

    const filterSearch = async (setvalues:any) => {
        setLoadingSearch(true)

        if(props.searching){
            const values = {...setvalues, ...props.form.getValues()}
            const searchingresult = await props.searching(values);
            const searchingresultMap = searchingresult.map((e:any)=>{
               return { ...e, checked: false}
            })
            setListItems(searchingresultMap);            
            setIsCheckSel(false);
            checkItem = false;
        }
        setLoadingSearch(false);
    }


    const acoesbotoesSelecionar = async (dataSel:any, values:any) => {
        const acao = async ()=>{
            setSelecionando(true);
            if(props.selectResult)
            {
                const check = await props.selectResult(dataSel, values); 
                if(check === true) {
                    setShowMe(false);
                    if(props.onClose) {props.onClose()} 
                }
            }
            setSelecionando(false);
        }       

        await acao();
    }    

    const acoesbotoes = (dataSel:any) =>  {
        const values = {...props.form.getValues()}
        return  <EditButtons.SelectButton tooltip="Selecionar" 
        onClick={() => {             
            acoesbotoesSelecionar(dataSel, values) 
        }} />
    }

    let checkItem = false;
    const acoesOnCheckBox =(e:any, dataSel:any)=>{
        dataSel.checked = !(dataSel.checked === true) 
        const chg = !(checkItem === true);
         checkItem = chg;
        setCheckItemMode(checkItem);
    }

    const acoescheckBox= (dataSel:any) => {
        var propChecked = {}
        //if(dataSel?.checked === true) propChecked = {checked : true}
        return <label className='checksContainer'>
            <input type='checkbox' {...propChecked}
            onChange={(e)=>{ acoesOnCheckBox(e, dataSel); }}
            />
             <span className="checkmark"></span>
        </label> 
       // return `<input onChange={this.handleChange} type='checkbox' ${params.checked ? 'checked' : ''} />`;
    }  

    const SelecionarVarios = async ()=>{
        const selecionados = listItems.filter((e:any)=> e.checked === true);      
        
        setSelecionando(true);
        if(props.selectMultiResult)
        {
            const check = await props.selectMultiResult(selecionados); 
            if(check === true) {
                setShowMe(false);
                if(props.onClose) {props.onClose()} 
            }
        }
        setSelecionando(false);
    }

    const AgGonPaginationChanged = (event: PaginationChangedEvent)=>{
        //console.log(event);
        
    }
    

    return <>
     <ConfirmationModal
            show={modalConfirm.show}
            confirmationMsg="Sim"
            cancelMsg="Não"
            onConfirm={()=> modalConfirm.actionConfirm()}
            onHide={() => setModalConfirm({show:false})}
            title={modalConfirm.title}
            type="button"
            variant="danger"
        >
        {modalConfirm.mensagem}
    </ConfirmationModal>
    <Bootstrap.Modal
    show={showMe}
    key={`${formUid}-modal-dlg`}
    onHide={()=>{setShowMe(false); if(props.onClose) {props.onClose()}  }}
    animation={true}
    className={`SearchBtnDialog ${props.className}`}     
    style={{minWidth: '60%'}}       
>
    <Bootstrap.Modal.Header closeButton>
        <Bootstrap.Modal.Title>
            {props.title}
        </Bootstrap.Modal.Title>
    </Bootstrap.Modal.Header>             
    <Bootstrap.Modal.Body>
    {selecionando ? 
    <span id="content-loading"><Loading lg={6} mensagem={props.msgText ?? 'Item Selecionado, Aguarde...'} /></span>
    // <NavigationMessage messages={[{type: MessageType.success, content: 'Selecionado, Aguarde...' }]} /> 
    :<>   
        <Bootstrap.Form.Group key={props.name} as={Bootstrap.Col} md={12}>
            {/* <Form id={formUid} onSubmit={props.form.handleSubmit((data:any)=> filterSearch(data) , (dataerro:any)=>errorHandler(dataerro))} autoComplete="off"> */}
                <Bootstrap.Form.Group as={Bootstrap.Row}>   
                    {props.filters(props.form)}    
                    <span className="filter-btn" >
                        <Button 
                            title="Buscar"
                            type="button"
                            onClick={()=>{ filterSearch(null); }}
                            contained
                            isLoading={loadingSearch}
                        />
                    </span>
                </Bootstrap.Form.Group>      
            {/* </Form>      */}
        </Bootstrap.Form.Group>
        {/* {props.children} */}
    
        <div className="search-content search-dialog-content" style={{ height: "50vh"}}>
            <div className="ag-theme-alpine" style={{ height: "100%", width: '100%' }}>
                <AgGridReact
                    rowData={listItems}
                    defaultColDef={{ flex: 1, sortable: true, resizable: true }}
                    pagination
                    paginationPageSize={100}
                    onPaginationChanged={AgGonPaginationChanged}
                    gridOptions={{ headerHeight: 60 }}
                    frameworkComponents={{
                        actions: ({data}: any)=>{ return acoesbotoes(data)},
                        checkBoxHandler : ({data}: any)=>{ return acoescheckBox(data)},
                    }}
                >
                    {props.multiSelect ? 
                    <AgGridColumn       
                    cellRenderer='checkBoxHandler'  
                    headerComponentParams= {{ menuIcon: 'fa-external-link-alt' }}                    
                    ></AgGridColumn>
                    : ''}
                    {props.children?.map((e:ListCol, index:any)=>{                    
                        return <AgGridColumn
                            headerName ={e.props.header}
                            field	= {e.props.field}
                            key  = {`${props?.name}-${e.props.field}`}
                            //key		= {e.props.key ?? (`${props?.name}-${e.props.field}` ? e.props.field : uuid())}
                            sort 	= {e.props.sort}
                            maxWidth = {e.props.maxWidth}
                            minWidth  = {e.props.minWidth}
                            headerClass = {e.props.headerClass}
                            valueFormatter = {e.props.valueFormatter}
                            cellStyle = {e.props.cellStyle}
                            wrapText = {e.props.wrapText}
                            />	
                    })}			
                
                    <AgGridColumn
                        field='Ações'
                        cellRenderer='actions'
                        maxWidth = {props.actionsMaxWidth}
                        minWidth = {props.actionsMinWidth}
                    />
                </AgGridReact>
            </div>
        </div>
        </>}
    </Bootstrap.Modal.Body>
    <Bootstrap.Modal.Footer>
    {props.multiSelect ? 
        <span className="filter-btn" >
            <Button 
                disabled={!isCheckSel}
                title="Incluir os Itens Selecionados"
                type="button"
                onClick={()=>{ SelecionarVarios(); }}
                contained                
            />
        </span> : ''
    }
    </Bootstrap.Modal.Footer>
</Bootstrap.Modal>
{/* <EditButtons.SearchButton disable={props.enabled===false} tooltip={props?.buttonSearch ?? 'Pesquisar'}  onClick={()=>{DialogOpen()}} />  */}
</>
}

