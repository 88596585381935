import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Form, Col } from 'react-bootstrap';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import AddButton from '../../../components/AddButton';
import Button from '../../../components/Button';
import GenericButton from '../../../components/FlowGenericButton';
import EditButton from '../../../components/EditButton';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import LoadingInForm from '../../../components/LoadingInForm';
import ConfirmationModal from '../../../components/ConfirmationModal';
import {
	UnityStationResult,
	EventResult,
	AACForm,
	CultureResult,
	Enum,
	EventsOnEditAAC,
	DocumentsOnEditAAC
} from '../../../models/modelsAuthorizationsCIBIO';
import { STRINGS } from '../../../util/strings';
import { STRINGS_CIBIO } from '../../../util/authorizationsCIBIO/string';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { Context } from '../../../context/AuthContext'
import { DecodedToken } from '../../../models/user';
import '../../../pages/Registers/styles.css';
import api from '../../../services/api';
import jwtDecode from 'jwt-decode';
import { DecimalAsComma } from '../NumberFormat';
import '../styles_add.scss';

registerLocale('pt-BR', ptBR);

export default function AAC ()
{
	const history = useHistory();
	const { token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);
	const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
	const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [loadingGetData, setLoadingGetData] = useState<boolean>(true);
	const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [errorGetData, setErrorGetData] = useState<any>(null);
	const [locations, setLocations] = useState<UnityStationResult[]>([]);
	const [events, setEvents] = useState<EventResult[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [transport, setTransport] = useState<Enum[]>([]);
	const [contencao, setContencao] = useState<Enum[]>([]);
	const [documents, setDocuments] = useState<Enum[]>([]);
	const [modalEvents, setModalEvents] = useState<boolean>(false);
	const [modalEditEvents, setModalEditEvents] = useState<boolean>(false);
	const [selectedEvents, setSelectedEvents] = useState<EventsOnEditAAC[]>([]);
	const [eventToEdit, setEventToEdit] = useState<EventsOnEditAAC>({
		eventoId: 0,
		evento: { label: '', value: 0 },
		materialId: 0,
		material: { label: '', value: 0 },
		qtdUtilizada: 0,
		unidadeMedidaId: 0,
		unidadeMedida: { label: '', value: 0 }
	});
	const [modalDocuments, setModalDocuments] = useState<boolean>(false);
	const [modalEditDocuments, setModalEditDocuments] = useState<boolean>(false);
	const [selectedDocuments, setSelectedDocuments] = useState<DocumentsOnEditAAC[]>([]);
	const [documentToEdit, setDocumentToEdit] = useState<DocumentsOnEditAAC>({
		documentoId: 0,
		documento: { label: '', value: 0 },
		quantidadeKg: '',
		unidadeMedidaId: 0,
		unidadeMedida: { label: '', value: 0 },
		unidMedOutros: ''
	});
	const [enumMaterial, setEnumMaterial] = useState<Enum[]>([]);
	const [enumUnidadeMedida, setEnumUnidadeMedida] = useState<Enum[]>([]);
	const [gridProps, setGridProps] = useState<any>(null);
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);
	const [eventMsg, setEventsMsg] = useState('');

	const [lblRelatorioFotoContencao, setLblRelatorioFotoContencao] = useState('Escolher arquivo');
	const [relatoriosFotograficos, setRelatoriosFotograficos] = useState<any[]>([]);
	const [modalAtivo, setModalAtivo] = useState<string>('');

	const isEvento = modalEvents ? {
		evento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		evento: yup.mixed()
	}

	const isEditEvento = modalEditEvents ? {
		editEvento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		editEvento: yup.mixed()
	}

	const isQtdUtilizada = modalEvents ? {
		qtdUtilizada: yup
			.number()
			.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
			.nullable()
			.required(STRINGS.FORM_FIELD_REQUIRED)
			.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
	} : {
		qtdUtilizada: yup.number().nullable()
	}

	const isEditQtdUtilizada = modalEditEvents ? {
		editQtdUtilizada: yup
			.number()
			.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
			.nullable()
			.required(STRINGS.FORM_FIELD_REQUIRED)
			.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
	} : {
		editQtdUtilizada: yup.number().nullable()
	}    

	const isMaterial = modalEvents ? {
		material: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		material: yup.mixed()
	}

	const isEditMaterial = modalEditEvents ? {
		editMaterial: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		editMaterial: yup.mixed()
	}
	
	const amiSchema = yup.object({
		localDeEnvio: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		destinatario: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		localDeDestino: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		possuiCQB: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		modoDeTransporte: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		origem: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		objetivoUtilizacao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		culturaOrganismo: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		observacoes: yup.string(),
		trechoLPMA: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		escopoLPMA: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		embalagemDupla: yup.string(),
		identificacaoComSimboloRiscoBiologico: yup.string(),
		responsavelTransporte: yup.string(),
		dataEnvio: yup.date(),
		materialPerfeitasCondicoes: yup.string(),
		qtdRecebidaDeAcordoQtdEnviada: yup.string(),
		responsavelRecebimento: yup.string(),
		dataRecebimento: yup.date()
	});

	const initialValues: AACForm = {
		departamentoArea: '',
		unidadeEstacao: undefined,
		local: '',
		numeroInstalacao: '',
		codigoEnsaio: '',
		culturaOrganismo: undefined,
		projeto: '',
		dataPrevisaoInicio: undefined,
		dataPrevisaoTermino: undefined,
		contencao: {value: 1, label: 'Fixa'},
		eventoPassouRN6: '1',
		localPossuiCQB: '1',
		escopoCQBContemplaAtividadeComReferidoMaterialCultura: '1',
		haveraColheitadaAAC: 0, 
		usoFinalidade: '',
		destruicaoDescarte: '',
		atividadeObjetivaFuturasLPMA: '1',
		observacoes: '',
		observacoesAnaliseRegulatorio: '',
		objetivoProjeto: '',        
		descricaoAtividadeMaterialUtilizado: '',
		finalizacaoProjeto: '',
		plantioConducaoAtividade: '',
		origemMaterial: '',
		finalidade: '',
		nomePropriedade: '',
		enderecoPropriedade: '',
		georreferenciamentoArea: '',
		localizacaoArea: '',
		id: undefined,
		numeroAutorizacaoCibio: undefined,   
		solicitanteTecnicoPrincipal: undefined,
		tecnicoPrincipal: undefined,
		dataCadastro: undefined,
		dataAprovacao: undefined,
		dataCancelamento: undefined,
		dataEmissaoCibio: undefined,
		culturaId: undefined
	}

	const eventSchema = yup.object({
		...isEvento,
		...isQtdUtilizada,
		unidadeMedida: yup.mixed().nullable(),
		...isMaterial,
		unidMedAutorizadaOutros: yup.string(),
		materialOutros: yup.string(),
		unidMedTranspOutros: yup.string()
	});

	const eventEditSchema = yup.object({
		...isEditEvento,
		...isEditQtdUtilizada,
		editUnidadeMedida: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		...isEditMaterial,
		unidMedAutorizadaOutros: yup.string(),
		materialOutros: yup.string(),
		unidMedTranspOutros: yup.string()
	});

	const { register, getValues, errors, trigger, handleSubmit, control, watch, formState: { isDirty } } = useForm({
		defaultValues: initialValues,
		mode: 'onSubmit',
		resolver: yupResolver(amiSchema),
		shouldUnregister: false
	});

	//inicio config campos evento...
	const { getValues: getValuesEvent, control: controlEvent, register: registerEvent, errors: errorsEvent, handleSubmit: handleSubmitEvent, trigger: triggerEvent, watch: watchEvent, reset:resetEvent } = useForm({
		mode: 'all',
		resolver: yupResolver(eventSchema),
		shouldUnregister: false
	})

	const { 
		control: controlEditEvent,
		register: registerEditEvent, 
		errors: errorsEditEvent,
		handleSubmit: handleSubmitEditEvent, 
		trigger: triggerEditEvent, 
		watch: watchEditEvent,
		reset: resetEditEvent
	} = useForm({
		mode: 'all',
		resolver: yupResolver(eventEditSchema),
		shouldUnregister: false
	})
	//fim config campos evento...

	//inicio config campos documento...

	const documentSchema = yup.object({
		documento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		descricao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		quantidadeKg: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		unidadeMedida: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		unidMedOutros: yup.string(),
	});

	const { control: controlDocument, register: registerDocument, errors: errorsDocument, handleSubmit: handleSubmitDocument } = useForm({
		mode: 'all',
		resolver: yupResolver(documentSchema),
	})

	const verifyErrorsOnFormDocument = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsDocument)) {
			Object.entries(errorsDocument).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const editDocumentSchema = yup.object({
		editDocumento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		editDescricao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		editQuantidadeKg: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		editUnidadeMedida: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		editUnidMedOutros: yup.string(),
	});

	const { control: controlEditDocument, register: registerEditDocument, errors: errorsEditDocument, handleSubmit: handleSubmitEditDocument } = useForm({
		mode: 'all',
		resolver: yupResolver(editDocumentSchema),
	})

	const verifyErrorsOnFormEditDocument = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEditDocument)) {
			Object.entries(errorsEditDocument).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	//fim config campos documento...

	//inicio config modal arquivo relatório...
	const modalArquivo = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(yup.object({
			arquivos: yup.mixed()
		})),
		shouldUnregister: false
	})
	//fim config modal arquivo relatório...

	useEffect(() => {
		const getDataToForm = async () => {
			try {
				const locations = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');
				const events = await api.get<EventResult[]>('api/v1/autorizacoes-cibio/Evento/obterporfiltro?Ativo=true');
				const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');
				const material = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/material');
				const unidadeMedida = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/unidade-medida');
				const transporte = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/modo-transporte');
				const contencaoReq = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/contencao');
				const documentoReq = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/documento');

				if (locations.status === 200) {
					setLocations(locations.data);
				}

				if (events.status === 200) {
					setEvents(events.data);
				}

				if (cultures.status === 200) {
					setCultures(cultures.data);
				}

				if (material.status === 200) {
					setEnumMaterial(material.data);
				}

				if (unidadeMedida.status === 200) {
					setEnumUnidadeMedida(unidadeMedida.data);
				}

				if (transporte.status === 200) {
					setTransport(transporte.data);
				}

				if (contencaoReq.status === 200) {
					setContencao(contencaoReq.data);
				}

				if (documentoReq.status === 200) {
					setDocuments(documentoReq.data);
				}

			} catch (error:any) {
				setErrorGetData(error?.response?.data ?? 'Ocorreu um erro ao tentar buscar os enumeradores.');
			}
			setLoadingGetData(false);
		}

		getDataToForm();
	}, []);

	const verifyErrorsOnForm = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errors)) {
			Object.entries(errors).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const verifyErrorsOnFormEvent = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEvent)) {
			Object.entries(errorsEvent).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const verifyErrorsOnFormEditEvent = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEditEvent)) {
			Object.entries(errorsEditEvent).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const getValuesToSubmit = () => {
		return {
			requisitante: decoded.unique_name,
			solicitanteTecnicoPrincipal: decoded.unique_name,
			tecnicoPrincipal: getValues('tecnicoPrincipal'),
			destinatario: getValues('destinatario'),
			localDestinoPossuiCQB: getValues('possuiCQB'),
			origem: getValues('origem'),
			objetivoUtilizacao: getValues('objetivoUtilizacao'),
			observacoes: getValues('observacoes'),
			trechoLPMALocaisMovimentacaoMaterial: getValues('trechoLPMA'),
			escopoLPMAContemplaMaterial: getValues('escopoLPMA'),
			embalagemDuplaImpermeavel: getValues('embalagemDupla'),
			identificacaoSimboloRiscoBiologico: getValues('identificacaoComSimboloRiscoBiologico'),
			responsavelTransporte: getValues('responsavelTransporte'),
			dataEnvio: getValues('dataEnvio'),
			materialChegouDestinoPerfeitasCondicoes: getValues('materialPerfeitasCondicoes'),
			qtdRecebidaDeAcordoComQtdEnviada: getValues('qtdRecebidaDeAcordoQtdEnviada'),
			responsavelRecebimento: getValues('responsavelRecebimento'),
			dataRecebimento: getValues('dataRecebimento'),
			culturaId: getValues('culturaOrganismo')?.value,
			departamentoArea: getValues('departamentoArea'),
			unidadeEstacaoId: getValues('unidadeEstacao')?.value,
			local: getValues('local'),
			numeroInstalacao: getValues('numeroInstalacao'),
			codigoEnsaio: getValues('codigoEnsaio'),
			projeto: getValues('projeto'),
			dataPrevisaoInicio: getValues('dataPrevisaoInicio'),
			dataPrevisaoTermino: getValues('dataPrevisaoTermino'),            
			contencaoId: getValues('contencao')?.value,
			eventoPassouRN6: getValues('eventoPassouRN6'),
			localPossuiCQB: getValues('localPossuiCQB'),
			escopoCQBContemplaAtividadeComReferidoMaterialCultura: getValues('escopoCQBContemplaAtividadeComReferidoMaterialCultura'),
			haveraColheitadaAAC: getValues('haveraColheitadaAAC'),
			usoFinalidade: getValues('usoFinalidade'),
			destruicaoDescarte: getValues('destruicaoDescarte'),
			atividadeObjetivaFuturasLPMA: getValues('atividadeObjetivaFuturasLPMA'),
			observacoesAnaliseRegulatorio: getValues('observacoesAnaliseRegulatorio'),
			objetivoProjeto: getValues('objetivoProjeto'),
			descricaoAtividadeMaterialUtilizado: getValues('descricaoAtividadeMaterialUtilizado'),
			finalizacaoProjeto: getValues('finalizacaoProjeto'),
			plantioConducaoAtividade: getValues('plantioConducaoAtividade'),
			origemMaterial: getValues('origemMaterial'),
			finalidade: getValues('finalidade'),
			nomePropriedade: getValues('nomePropriedade'),
			enderecoPropriedade: getValues('enderecoPropriedade'),
			georreferenciamentoArea: getValues('georreferenciamentoArea'),
			localizacaoArea: getValues('localizacaoArea'),
			eventos: selectedEvents,
			documentos: selectedDocuments
		};
	}

	const validarRelatorioFotograficoContencao = () => {
		let contencaoId = getValues('contencao')?.value;
		if (contencaoId === 2) {            
			if (!relatoriosFotograficos || relatoriosFotograficos.length === 0) {
				const error = {response: {data: {message: ['Relatório Fotográfico é obrigatório.']}}};
				throw error;
			}
		}
	}

	const submitFiles = async (aacId: number) => {
		let contencaoId = getValues('contencao')?.value;
		if (contencaoId !== 3) {

			if (relatoriosFotograficos && relatoriosFotograficos.length) {
				var formDataFiles = new FormData();

				for (let index = 0; index < relatoriosFotograficos.length; index++) {
					const arquivo = relatoriosFotograficos[index];
					
					formDataFiles.append('arquivos', arquivo);
				}

				const { status } = await api.post(`api/v1/autorizacoes-cibio/AtividadeContencao/${aacId}/SalvarListaArquivos`, formDataFiles);
				return status;
			}
		}
		
		return 200;
	}

	const onSubmit = async () => {
		setErrorSubmit(null);
		setLoadingSubmit(true);

		try {
			validarRelatorioFotograficoContencao();

			const aacToSubmit = getValuesToSubmit();
			const { status, data } = await api.post('api/v1/autorizacoes-cibio/AtividadeContencao/enviarregulatorio', aacToSubmit);

			if (status === 200) {
				submitFiles(data.id)
				.then((fileStatus) => {
					if (fileStatus === 200) {
						window.scrollTo({ top: 0, behavior: 'smooth' });
						setSuccessSubmit(true);
						setTimeout(() => {
							history.push('/autorizacoes/editar/aac', { id: data.id });
						}, 3000)
					}
				})
			}
		} catch (error:any) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);
		}

		setLoadingSubmit(false);
	}    

	const onSubmitDraft = async () => {
		setErrorDraft(null);
		setLoadingDraft(true);
		
		try {
			const aacToSubmit = getValuesToSubmit();
			const { status, data } = await api.post('api/v1/autorizacoes-cibio/AtividadeContencao/rascunho', aacToSubmit);

			if (status === 201) {
				submitFiles(data.id)
				.then((fileStatus) => {
					if (fileStatus === 200) {                        
						setSuccessDraft(true);
						window.scrollTo({ top: 0, behavior: 'smooth' });
						setTimeout(() => {
							history.push('/autorizacoes/editar/aac', { id: data.id });
						}, 3000)
					}
				})
			}
		} catch (error:any) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorDraft(error.response.data);
		}

		setLoadingDraft(false);
	}

	const onSubmitEvent = (info: EventsOnEditAAC): void => {
		setModalEvents(false);
		setSelectedEvents([...selectedEvents, {
			eventoId: info.evento.value,
			eventoNome: info.evento.label,
			evento: info.evento,
			materialId: info.material.value,
			materialDescricao: info.material.label,
			material: info.material,
			materialOutros: info.materialOutros,
			qtdUtilizada: info.qtdUtilizada,
			unidadeMedidaId: info.unidadeMedida.value,
			unidadeMedidaDescricao: info.unidadeMedida.label,
			unidadeMedida: info.unidadeMedida,            
			unidMedOutros: info.unidMedOutros
		}]);
	}

	const onSubmitEditiEvent = (info: any): void => {
		setSelectedEvents([]);
		let arr: any = [];

		gridProps.gridApi.updateRowData({ remove: [gridProps.data] })
		const editedEvent = {
			evento: info.editEvento,
			eventoId: info.editEvento.value,
			eventoNome: info.editEvento.label,
			
			material: info.editMaterial,
			materialId: info.editMaterial.value,
			materialDescricao: info.editMaterial.label,
			materialOutros: info.editMaterialOutros,
			
			unidadeMedida: info.editUnidadeMedida,
			unidadeMedidaId: info.editUnidadeMedida.value,
			unidadeMedidaDescricao: info.editUnidadeMedida.label,
			unidMedOutros: info.editUnidMedOutros,

			qtdUtilizada: info.editQtdUtilizada
		};

		gridProps.gridApi.forEachNode(({ data }: any) => arr.push(data));

		setSelectedEvents([...arr, editedEvent]);

		setModalEditEvents(false);
	}

	const onSubmitDocument = (info: DocumentsOnEditAAC): void => {
		setModalDocuments(false);
		setSelectedDocuments([...selectedDocuments, {
			documentoId: info.documento.value,
			documentoNome: info.documento.label,
			documento: info.documento,            
			descricao: info.descricao,
			quantidadeKg: info.quantidadeKg,
			unidadeMedida: info.unidadeMedida,
			unidadeMedidaId: info.unidadeMedida.value,
			unidadeMedidaDescricao: info.unidadeMedida.label,
			unidMedOutros: info.unidMedOutros,

		}]);
	}

	const onSubmitEditiDocument = (info: any): void => {
		setSelectedDocuments([]);
		let arr: any = [];

		gridProps.gridApi.updateRowData({ remove: [gridProps.data] })

		gridProps.gridApi.forEachNode(({ data }: any) => arr.push(data));
		setSelectedDocuments([...arr, {
			documentoId: info.editDocumento.value,
			documentoNome: info.editDocumento.label,
			documento: info.editDocumento,            
			descricao: info.editDescricao,
			quantidadeKg: info.editQuantidadeKg,
			unidadeMedidaId: info.editUnidadeMedida.value,
			unidadeMedidaDescricao: info.editUnidadeMedida.label,
			unidadeMedida: info.unidadeMedida,
			unidMedOutros: info.unidMedOutros

		}]);

		setModalEditDocuments(false);
	}

	const actionsEvents = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				resetEditEvent(props.data);
				setEventToEdit(props.data);
				setGridProps(props.node);
				setModalEditEvents(true)
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedEvents([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedEvents([...arr]);
			}} />
		</Fragment>
	)

	const actionsDocuments = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				setDocumentToEdit(props.data);
				setGridProps(props.node);
				setModalEditDocuments(true);
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedDocuments([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedDocuments([...arr]);
			}} />
		</Fragment>
	)

	const acoesRelatorioFoto = (props: any) => (
		<Fragment>            
			<DeleteButton onClick={() => {
				setRelatoriosFotograficos([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setRelatoriosFotograficos([...arr]);
			}} />
		</Fragment>
	)    
	
	const submitArquivos = async (formArquivosData: any) => {
		setModalAtivo('');        
		const arquivo = formArquivosData.arquivos[0];
		setRelatoriosFotograficos([...relatoriosFotograficos, arquivo]);
		setLblRelatorioFotoContencao('Escolher arquivo');
		modalArquivo.reset();
	}

	const [activeStep, setActiveStep] = React.useState(0);
	const steps = ['Autorização AAC', 'Projeto OGM'];

	const handleStep = (step: number) => () => {
		setActiveStep(step);
	};

	return <div className="container container-fluid">
				<h3>{STRINGS_CIBIO.REGISTER_AAC}</h3>
			<If condition={verifyErrorsOnForm()}>
				{verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
			</If>
			<If condition={eventMsg}>
				<AlertError message={eventMsg} />
			</If>
			<If condition={successDraft && !errorDraft}>
				<AlertSuccess message={STRINGS.SUCCESS_DRAFT} />
			</If>
			<If condition={!successDraft && errorDraft}>
				{errorDraft !== null && errorDraft?.message?.length > 0 ? errorDraft?.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
			</If>
			<If condition={successSubmit && !errorSubmit}>
				<AlertSuccess
					message={STRINGS.REGULATORY_SUCCESS}
				/>
			</If>
			<If condition={!successSubmit && errorSubmit !== null}>
				{errorSubmit !== null && errorSubmit.message.length > 0 ? errorSubmit.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
			</If>

			<Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<Stepper nonLinear activeStep={activeStep}>
					{steps.map((label, index) => (
					<Step key={label}>
						<StepButton color="inherit" onClick={handleStep(index)}>
						{label}
						</StepButton>
					</Step>
					))}
				</Stepper>
				<If condition={activeStep === 0}>
					<Fieldset title={STRINGS_CIBIO.AMI_BLOCK_ONE}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Solicitante
								</Form.Label>
								<Form.Control
									value={decoded.unique_name}
									readOnly
									maxLength={100}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Departamento/Área<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="departamentoArea"
									isInvalid={errors.departamentoArea !== undefined}
									placeholder="Insira o departamento/área"
									type="text"
									maxLength={150}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.departamentoArea?.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Técnico(a) Principal
								</Form.Label>
								<Form.Control
									ref={register}
									type="text"
									name="tecnicoPrincipal"
									maxLength={100}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Estação/Unidade Operativa<em>*</em>
									&nbsp;&nbsp;
									<If condition={loadingGetData}>
										<LoadingInForm />
									</If>
								</Form.Label>
								<Controller
									control={control}
									name="unidadeEstacao"
									isInvalid={errors.unidadeEstacao !== undefined}
									as={<Select
										placeholder="Selecione uma unidade/estação"
										options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
										isClearable
									/>}
								/>
								<If condition={errors.unidadeEstacao}>
									<p className="error-field">{errors.unidadeEstacao?.message}</p>
								</If>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Local<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="local"
									isInvalid={errors.local !== undefined}
									placeholder="Insira o local"
									type="text"
									maxLength={150}
								/>
								<span className="small text-info">(Descrever o local: Casa de Vegetação, Laboratório, Sala etc.)</span>
								<Form.Control.Feedback type="invalid">
									{errors.local?.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Nº da Instalação<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="numeroInstalacao"
									isInvalid={errors.numeroInstalacao !== undefined}
									placeholder="Insira o número da instalação"
									type="text"
								/>
								<Form.Control.Feedback type="invalid">
									{errors.numeroInstalacao?.message}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Código do Ensaio<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="codigoEnsaio"
									isInvalid={errors.codigoEnsaio !== undefined}
									placeholder="Insira o código do ensaio"
									type="text"
								/>
								<Form.Control.Feedback type="invalid">
									{errors.codigoEnsaio?.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Cultura/Organismo<em>*</em>
								</Form.Label>
								&nbsp;&nbsp;
								<If condition={loadingGetData}>
									<LoadingInForm />
								</If>
								<Controller
									control={control}
									name="culturaOrganismo"
									isInvalid={errors.culturaOrganismo}
									as={<Select
										options={cultures.map(culture => Object.assign({ label: culture.nome, value: culture.id }))}
										placeholder="Selecione uma cultura/organismo"
										isClearable
									/>}
								/>
								<If condition={errors.culturaOrganismo}>
									<p className="error-field">{errors.culturaOrganismo?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Projeto<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="projeto"
									as="textarea"
									rows={5}
									placeholder="Descrever um breve projeto da Contenção, e caso seja necessário anexar ao documento a AMI)"
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Previsão de Início da Atividade<em>*</em>
								</Form.Label>
								<br />
								<Controller
									control={control}
									name="dataPrevisaoInicio"
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
										/>
									)}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Previsão de Término da Atividade<em>*</em>
								</Form.Label>
								<br />
								<Controller
									control={control}
									name="dataPrevisaoTermino"
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
											minDate={new Date()}
										/>
									)}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Contenção<em>*</em>
								</Form.Label>
								<Controller
									control={control}
									name="contencao"
									defaultValue={initialValues.contencao}
									isInvalid={errors.contencao}
									as={<Select
										options={contencao.map(transp => Object.assign({ label: transp.value, value: transp.key }))}
										placeholder="Selecione a contenção"
										isClearable
									/>}
								/>
								<If condition={errors.contencao}>
									<p className="error-field">{errors.contencao?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<If condition={watch().contencao?.value !== 3}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Relatório Fotográfico
										<If condition={watch().contencao?.value === 2}>
											<em>*</em>
										</If>
										{`  `}
										<AddButton type="button" onClick={() => setModalAtivo('RELATORIO_FOTOGRAFICO')} />
									</Form.Label>
									<If condition={eventMsg}>
										<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
									</If>
								</Form.Group>
							</Form.Row>
							<If condition={relatoriosFotograficos.length}>
								<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
									<AgGridReact
										rowData={relatoriosFotograficos}
										defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
										gridOptions={{ headerHeight: 80 }}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
										frameworkComponents={{
											actions: acoesRelatorioFoto
										}}
									>
										<AgGridColumn
											headerName='Nome'
											field="name"
											cellStyle={{
												justifyContent: 'left'
											}}
											autoHeight
											wrapText
											minWidth={250}
										/>                                        
										<AgGridColumn
											headerName="Ações"
											cellRenderer="actions"
											maxWidth={100}
										/>
									</AgGridReact>
								</div>
							</If>                            
						</If>
						<If condition={watch().contencao?.value === 2}>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={12}>
									<Form.Label>
										O evento já passou por uma RN6?<em>*</em>
									</Form.Label>
								</Form.Group>
								<br />
								&nbsp;&nbsp;
								&nbsp;&nbsp;
								<Form.Check
									ref={register}
									name="eventoPassouRN6"
									type="radio"
									label="Sim"
									value={2}
								/>
								<Form.Check
									ref={register}
									name="eventoPassouRN6"
									type="radio"
									label="Não"
									value={1}
								/>
								&nbsp;&nbsp;
								<Form.Check
									ref={register}
									name="eventoPassouRN6"
									type="radio"
									label="Não se aplica"
									value={3}
								/>
								<br />
								<If condition={errors.eventoPassouRN6}>
									<p className="error-field">{errors.eventoPassouRN6?.message}</p>
								</If>
							</Form.Row>
						</If>
						<p />
						<Form.Row className="row-custom">
							&nbsp;&nbsp;
							<Form.Label>
								O local possui CQB?<em>*</em>
							</Form.Label>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="localPossuiCQB"
								type="radio"
								aria-label="radio 1"
								label="Não"
								value={1}
							/>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="localPossuiCQB"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								value={2}
							/>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="localPossuiCQB"
								type="radio"
								aria-label="radio 1"
								label="Não se aplica"
								value={3}
							/>
						</Form.Row>
						<p />
						<Form.Row className="row-custom">
							&nbsp;&nbsp;
							<Form.Label>
								O escopo do CQB do local contempla a atividade com referido material/cultura objeto da solicitação?<em>*</em>
							</Form.Label>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="escopoCQBContemplaAtividadeComReferidoMaterialCultura"
								type="radio"
								aria-label="radio 1"
								label="Não"
								value={1}
							/>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="escopoCQBContemplaAtividadeComReferidoMaterialCultura"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								value={2}
							/>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="escopoCQBContemplaAtividadeComReferidoMaterialCultura"
								type="radio"
								aria-label="radio 1"
								label="Não se aplica"
								value={3}
							/>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Uso/Finalidade<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="usoFinalidade"
									as="textarea"
									rows={4}
									placeholder="Descreva o uso pretendido do material reprodutivo produzido: sementes, toletes, etc"
									isInvalid={errors.usoFinalidade !== undefined}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.usoFinalidade?.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Destruição e Descarte<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="destruicaoDescarte"
									as="textarea"
									rows={4}
									placeholder="Informe o local e descreva os procedimentos planejados para a destruição e descarte das sobras, resíduos e restos vegetais do material"
									isInvalid={errors.destruicaoDescarte !== undefined}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.destruicaoDescarte?.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							&nbsp;&nbsp;
							<Form.Label>
								A atividade em contenção objetiva futuras liberações planejadas no meio ambiente (LPMAs)?<em>*</em>
							</Form.Label>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="atividadeObjetivaFuturasLPMA"
								type="radio"
								aria-label="radio 1"
								label="Não"
								value={1}
							/>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="atividadeObjetivaFuturasLPMA"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								value={2}
							/>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="atividadeObjetivaFuturasLPMA"
								type="radio"
								aria-label="radio 1"
								label="Não se aplica"
								value={3}
							/>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Observações
								</Form.Label>
								<Form.Control
									ref={register}
									name="observacoes"
									as="textarea"
									rows={5}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="row-custom">
							&nbsp;&nbsp;
							<Form.Label>
								Haverá Colheita da AAC?<em>*</em>
							</Form.Label>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="haveraColheitadaAAC"
								type="radio"
								aria-label="radio 1"
								label="Não"
								value={1}
							/>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="haveraColheitadaAAC"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								value={2}
							/>
						</Form.Row>

						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Evento<em>*</em>{`  `}
									<AddButton
										type="button"
										onClick={() => {setModalEvents(true); resetEvent();}}
									/>
								</Form.Label>
								<If condition={eventMsg}>
									<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
								</If>
							</Form.Group>
						</Form.Row>

						<If condition={selectedEvents.length}>
							<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
								<AgGridReact
									rowData={selectedEvents}
									defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
									gridOptions={{ headerHeight: 80 }}
									stopEditingWhenGridLosesFocus={true}
									singleClickEdit={true}
									frameworkComponents={{
										actions: actionsEvents,
									}}
								>
									<AgGridColumn
										headerName='Evento'
										field="eventoNome"
										cellStyle={{
											justifyContent: 'left'
										}}
										autoHeight
										wrapText
										minWidth={250}
									/>
									<AgGridColumn
										headerName='Material'
										field="materialDescricao"
										headerClass="myfunc-default-header"
										valueFormatter={({ data }: any) => {

											if (data.materialDescricao == 'Outros')
												return `Outros (${data.materialOutros})`;

											return data.materialDescricao;
											
										}}
									/>
									<AgGridColumn
										headerName='Quantidade a ser Utilizada'
										field="qtdUtilizada"
										headerClass="myfunc-default-header"
										valueFormatter={({ data }: any) => {return DecimalAsComma(data.qtdUtilizada);}}
									/>
									<AgGridColumn
										headerName='Unidade Medida'
										field="unidadeMedidaDescricao"
										headerClass="myfunc-default-header"
										valueFormatter={({ data }: any) => {

											if (data.unidadeMedidaDescricao == 'Outros')
												return `Outros (${data.unidMedOutros})`;

											return data.unidadeMedidaDescricao;
											
										}}
									/>
									<AgGridColumn
										headerName="Ações"
										cellRenderer="actions"
										maxWidth={100}
									/>
								</AgGridReact>
							</div>
						</If>
					</Fieldset>
					<Fieldset disabled={true} title={STRINGS_CIBIO.AMI_BLOCK_TWO}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Nº Autorização CIBio
								</Form.Label>
								<Form.Control
									readOnly
									ref={register}
									name="numeroAutorizacaoCibio"
									placeholder="Nº autorização CIBio"
									maxLength={20}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Data Emissão CIBio
								</Form.Label>
								<br />
								<Controller
									control={control}
									name="dataEmissaoCibio"
									render={({ onChange, value }) => (
										<DatePicker
											disabled
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
											maxDate={new Date()}
										/>
									)}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Data da Aprovação
								</Form.Label>
								<br />
								<Controller
									control={control}
									name="dataAprovacao"
									render={({ onChange, value }) => (
										<DatePicker
											disabled
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
											maxDate={new Date()}
										/>
									)}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Observações da Análise do Regulatório
								</Form.Label>
								<Form.Control
									ref={register}
									name="observacoesAnaliseRegulatorio"
									as="textarea"
									rows={5}
								/>
							</Form.Group>
						</Form.Row>
					</Fieldset>
				</If>
				<If condition={activeStep === 1}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Objetivo<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="objetivoProjeto"
								as="textarea"
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Descrição da atividade/Descrição do material utilizado no ensaio<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="descricaoAtividadeMaterialUtilizado"
								as="textarea"
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Origem do Material<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="origemMaterial"
								isInvalid={errors.origemMaterial !== undefined}
								placeholder="Insira a origem do material"
								type="text"
							/>
							<Form.Control.Feedback type="invalid">
								{errors.origemMaterial?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Finalidade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="finalidade"
								isInvalid={errors.finalidade !== undefined}
								placeholder="Insira a finalidade"
								type="text"
							/>
							<Form.Control.Feedback type="invalid">
								{errors.finalidade?.message}
							</Form.Control.Feedback>                            
						</Form.Group>
					</Form.Row>
					<Fieldset title={'Local de condução do projeto'}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Nome da Propriedade<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="nomePropriedade"
									isInvalid={errors.nomePropriedade !== undefined}
									placeholder="Insira o nome da propriedade"
									type="text"
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Endereço da Propriedade<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="enderecoPropriedade"
									isInvalid={errors.enderecoPropriedade !== undefined}
									placeholder="Insira o endereço da propriedade"
									type="text"
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Georreferenciamento da área<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="georreferenciamentoArea"
									isInvalid={errors.georreferenciamentoArea !== undefined}
									placeholder="Insira a referência geográfica da área"
									type="text"
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Localização da área<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="localizacaoArea"
									isInvalid={errors.localizacaoArea !== undefined}
									placeholder="Insira a localização da área"
									type="text"
								/>
							</Form.Group>
						</Form.Row>
					</Fieldset>
					<Fieldset title={'Documentação solicitada para condução do projeto'}>
						<Form.Label>
							Documentos<em>*</em>
						</Form.Label>
						<AddButton type="button" onClick={() => setModalDocuments(true)} />
						<If condition={selectedDocuments.length}>
							<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
								<AgGridReact
									rowData={selectedDocuments}
									defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
									gridOptions={{ headerHeight: 80 }}
									stopEditingWhenGridLosesFocus={true}
									singleClickEdit={true}
									frameworkComponents={{
										actions: actionsDocuments,
									}}
								>
									<AgGridColumn
										headerName='Documento'
										field="documentoNome"
										cellStyle={{
											justifyContent: 'left'
										}}
										autoHeight
										wrapText
										minWidth={250}
									/>
									<AgGridColumn
										headerName='Descrição'
										field="descricao"
										headerClass="myfunc-default-header"
									/>
									<AgGridColumn
										headerName='Quantidade'
										field="quantidadeKg"
										headerClass="myfunc-default-header"
									/>
									<AgGridColumn
										headerName='Unidade Medida'
										field="unidadeMedidaDescricao"
										headerClass="myfunc-default-header"
										valueFormatter={({ data }: any) => {

											if (data.unidadeMedidaDescricao == 'Outros')
												return `Outros (${data.unidMedOutros})`;

											return data.unidadeMedidaDescricao;

										}}
									/>
									<AgGridColumn
										headerName="Ações"
										cellRenderer="actions"
										maxWidth={100}
									/>
								</AgGridReact>
							</div>
						</If>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Finalização do projeto<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="finalizacaoProjeto"
									as="textarea"
									rows={5}
									/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Plantio e condução da atividade (tratos culturais, etc)
								</Form.Label>
								<Form.Control
									ref={register}
									name="plantioConducaoAtividade"
									as="textarea"
									rows={5}
									/>
							</Form.Group>
						</Form.Row>                    
					</Fieldset>                    
				</If>
				<div className="button-position">
					<Button
						title="Voltar"
						contained={false}
						type="button"
						onClick={() => {
							isDirty ? setIsConfirmation(true) :
								history.goBack();
						}}
					/>
					<GenericButton
						title="Salvar e enviar para regulatório"
						variant="btn-warning"
						type="button"
						loading={loadingSubmit}
						onClick={() => trigger().then(validated => {
							if (validated && selectedEvents.length) {
								setIsRegulatory(validated);
								setEventsMsg('');
							} else if (validated && !selectedEvents.length) {
								setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
								window.scrollTo({ top: 0, behavior: 'smooth' });
							} else {
								setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
							}
						})}
					/>
					<Button
						title="Salvar rascunho"
						contained={true}
						type="button"
						onClick={() => onSubmitDraft()}
						isLoading={loadingDraft}
					/>
				</div>
				<If condition={isRegulatory}>
					<ConfirmationModal
						show={isRegulatory}
						onHide={() => {
							setIsRegulatory(false);
						}}
						title={STRINGS.REGULATORY_TITLE}
						confirmationMsg={STRINGS.REGULATORY_ACTION}
						onConfirm={() => {
							onSubmit();
							setIsRegulatory(false);
						}}
						loading={loadingSubmit}
						variant="success"
						color="white"
					>
						<p>{STRINGS.REGULATORY_MESSAGE}</p>
					</ConfirmationModal>
				</If>
			</Form>
			<If condition={modalEvents}>
				<Modal
					show={true}
					onHide={() => setModalEvents(false)}
					title={STRINGS_CIBIO.AMI_EVENT_MODAL_TITLE}
				>
					<If condition={verifyErrorsOnFormEvent()}>
						{verifyErrorsOnFormEvent().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
					</If>
					<Form onSubmit={handleSubmitEvent(onSubmitEvent)} autoComplete="off">
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Evento<em>*</em>
								</Form.Label>
								<Controller
									control={controlEvent}
									name="evento"
									isInvalid={errorsEvent.evento !== undefined}
									as={
										<Select
											placeholder="Selecione um evento"
											options={events.map(event => Object.assign({ value: event.id, label: event.nome }))}
											isClearable
										/>
									}
								/>
								<If condition={errorsEvent.evento}>
									<p className="error-field">{errorsEvent.evento?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Material<em>*</em>
								</Form.Label>
								<Controller
									control={controlEvent}
									name="material"
									isInvalid={errorsEvent.material !== undefined}
									as={<Select
										placeholder="Selecione um material"
										options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
										isClearable
									/>}
								/>
								<If condition={errorsEvent.material}>
									<p className="error-field">{errorsEvent.material?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<If condition={watchEvent().material?.value === 7}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Especifique o material<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEvent}
										required
										name="materialOutros"
										placeholder="Insira o material"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Quantidade a ser utilizada<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerEvent}
									name="qtdUtilizada"
									placeholder="Insira a quantidade a ser utilizada"
									maxLength={5}
									type="number"
									step="any"
									isInvalid={errorsEvent.quantidadeAutorizada !== undefined}
								/>
								<Form.Control.Feedback type="invalid">
									{errorsEvent.quantidadeAutorizada?.message}
								</Form.Control.Feedback>
							</Form.Group>                            
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Unidade de Medida<em>*</em>
								</Form.Label>
								<Controller
									control={controlEvent}
									name="unidadeMedida"
									as={<Select
										placeholder="Selecione uma unidade de medida"
										options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
										isClearable
									/>}
								/>
							</Form.Group>
						</Form.Row>
						<If condition={watchEvent().unidadeMedida?.value === 4}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Especifique a unidade de medida<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEvent()}
										required
										name="unidMedOutros"
										placeholder="Insira a unidade de medida"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setModalEvents(false)}
							/>
							<Button
								title="Inserir"
								contained={true}
								type="submit"
							/>
						</div>
					</Form>
				</Modal>
			</If>
			<If condition={modalEditEvents}>
				<Modal
					show={true}
					onHide={() => setModalEditEvents(false)}
					title={STRINGS_CIBIO.AMI_EVENT_EDIT_MODAL_TITLE}
				>
					<If condition={verifyErrorsOnFormEditEvent()}>
						{verifyErrorsOnFormEditEvent().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
					</If>
					<Form autoComplete="off">
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Evento<em>*</em>
								</Form.Label>
								<Controller
									control={controlEditEvent}
									name="editEvento"
									isInvalid={errorsEditEvent.editEvento !== undefined}
									defaultValue={eventToEdit.evento}
									as={
										<Select
											placeholder="Selecione um evento"
											options={events.map(event => Object.assign({ value: event.id, label: event.nome }))}
											isClearable
										/>
									}
								/>
								<If condition={errorsEditEvent.editEvento}>
									<p className="error-field">{errorsEditEvent.editEvento?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Material<em>*</em>
								</Form.Label>
								<Controller
									control={controlEditEvent}
									name="editMaterial"
									isInvalid={errorsEditEvent.editMaterial !== undefined}
									defaultValue={eventToEdit.material}
									as={<Select
										placeholder="Selecione um material"
										options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
										isClearable
										onInputChange={() =>
											setEventToEdit(state => ({
												eventoId: state.evento.value,
												evento: state.evento,
												materialId: state.material.value,
												material: state.material,
												materialOutros: '',
												qtdUtilizada: state.qtdUtilizada,
												unidadeMedidaId: state.unidadeMedida.value,
												unidadeMedida: state.unidadeMedida,
												unidMedOutros: state.unidMedOutros
											}))
										}
									/>}
								/>
								<If condition={errorsEditEvent.editMaterial}>
									<p className="error-field">{errorsEditEvent.editMaterial?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<If condition={watchEditEvent().editMaterial?.value === 7 || eventToEdit.materialOutros}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Especifique o material<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditEvent}
										required
										name="editMaterialOutros"
										placeholder="Insira o material"
										defaultValue={eventToEdit.materialOutros}
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Quantidade a ser utilizada<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerEditEvent}
									name="editQtdUtilizada"
									placeholder="Insira a quantidade a ser utilizada"
									maxLength={5}
									type="number"
									step="any"
									isInvalid={errorsEditEvent.editQtdUtilizada !== undefined}
									defaultValue={eventToEdit.qtdUtilizada}
								/>
								<Form.Control.Feedback type="invalid">
									{errorsEditEvent.editQtdUtilizada?.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Form.Row>                        
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Unidade Medida<em>*</em>
								</Form.Label>
								<Controller
									control={controlEditEvent}
									name="editUnidadeMedida"
									defaultValue={eventToEdit.unidadeMedida}
									as={<Select
										placeholder="Selecione uma unidade de medida"
										options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
										isClearable
										onInputChange={() =>
											setEventToEdit(state => ({
												eventoId: state.evento.value,
												evento: state.evento,
												materialId: state.material.value,
												material: state.material,
												materialOutros: '',
												qtdUtilizada: state.qtdUtilizada,
												unidadeMedidaId: state.unidadeMedida.value,
												unidadeMedida: state.unidadeMedida,
												unidMedOutros: state.unidMedOutros
											}))
										}
									/>}
								/>
							</Form.Group>
						</Form.Row>
						<If condition={watchEditEvent().editUnidadeMedida?.value === 4 || eventToEdit.unidMedOutros}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Especifique a unidade de medida transportada<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditEvent}
										defaultValue={eventToEdit.unidMedOutros}
										required
										name="editUnidMedOutros"
										placeholder="Insira a unidade de medida transportada"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setModalEditEvents(false)}
							/>
							<Button
								title="Salvar"
								contained={true}
								type="button"
								onClick={() => {
									triggerEditEvent().then(validated => {
										if (validated) {
											handleSubmitEditEvent(onSubmitEditiEvent)();
										}
									})
								}}
							/>
						</div>
					</Form>
				</Modal>
			</If>

			<If condition={modalDocuments}>
				<Modal
					show={true}
					onHide={() => setModalDocuments(false)}
					title={"Adicionar Documento"}
				>
					<If condition={verifyErrorsOnFormDocument()}>
						{verifyErrorsOnFormDocument().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
					</If>
					<Form onSubmit={handleSubmitDocument(onSubmitDocument)} autoComplete="off">
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Documento<em>*</em>
								</Form.Label>
								<Controller
									control={controlDocument}
									name="documento"
									isInvalid={errorsDocument.documento !== undefined}
									as={
										<Select
											placeholder="Selecione um documento"
											options={documents.map(doc => Object.assign({ value: doc.key, label: doc.value }))}
											isClearable
										/>
									}
								/>
								<If condition={errorsDocument.documento}>
									<p className="error-field">{errorsDocument.documento?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Descrição<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerDocument()}
									required
									name="descricao"
									placeholder="Insira a descrição"
									isInvalid={errorsDocument.descricao !== undefined}
								/>
								<If condition={errorsDocument.descricao}>
									<p className="error-field">{errorsDocument.descricao?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>                        
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Quantidade<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerDocument}
									name="quantidadeKg"
									placeholder="Insira a quantidade a ser utilizada"
									step="any"
									isInvalid={errorsDocument.quantidadeKg !== undefined}
								/>
								<Form.Control.Feedback type="invalid">
									{errorsDocument.quantidadeKg?.message}
								</Form.Control.Feedback>
							</Form.Group>                            
						</Form.Row>                        
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Unidade de Medida<em>*</em>
								</Form.Label>
								<Controller
									control={controlDocument}
									name="unidadeMedida"
									as={<Select
										placeholder="Selecione uma unidade de medida"
										options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
										isClearable
									/>}
								/>
							</Form.Group>
						</Form.Row>
						<If condition={watchEvent().unidadeMedida?.value === 4}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Especifique a unidade de medida<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEvent()}
										required
										name="unidMedOutros"
										placeholder="Insira a unidade de medida"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setModalDocuments(false)}
							/>
							<Button
								title="Inserir"
								contained={true}
								type="submit"
							/>
						</div>
					</Form>
				</Modal>
			</If>
			
			<If condition={modalEditDocuments}>
				<Modal
					show={true}
					onHide={() => setModalEditDocuments(false)}
					title={"Editar Documento"}
				>
					<If condition={verifyErrorsOnFormEditDocument()}>
						{verifyErrorsOnFormEditDocument().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
					</If>
					<Form onSubmit={handleSubmitEditDocument(onSubmitEditiDocument)} autoComplete="off">
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Documento<em>*</em>
								</Form.Label>
								<Controller
									control={controlEditDocument}
									name="editDocumento"
									isInvalid={errorsEditDocument.editDocumento !== undefined}
									defaultValue={documentToEdit.documento}
									as={
										<Select
											placeholder="Selecione um documento"
											options={documents.map(doc => Object.assign({ value: doc.key, label: doc.value }))}
											isClearable
											onInputChange={() =>
												setDocumentToEdit(state => ({
													documentoId: state.documento.value,
													documento: state.documento,
													descricao: state.descricao,
													quantidadeKg: state.quantidadeKg,
													unidadeMedidaId: state.unidadeMedida.value,
													unidadeMedida: state.unidadeMedida,
													unidMedOutros: state.unidMedOutros

												}))
											}
										/>
									}
								/>
								<If condition={errorsEditDocument.editDocumento}>
									<p className="error-field">{errorsEditDocument.editDocumento?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Descrição<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerEditDocument()}
									required
									name="editDescricao"
									placeholder="Insira a descrição"
									isInvalid={errorsEditDocument.editDescricao !== undefined}
									defaultValue={documentToEdit.descricao}
								/>
								<If condition={errorsEditDocument.editDescricao}>
									<p className="error-field">{errorsEditDocument.editDescricao?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>                        
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Quantidade<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerEditDocument}
									name="editQuantidadeKg"
									placeholder="Insira a quantidade a ser utilizada"
									step="any"
									isInvalid={errorsEditDocument.editQuantidadeKg !== undefined}
									defaultValue={documentToEdit.quantidadeKg}
								/>
								<Form.Control.Feedback type="invalid">
									{errorsEditDocument.editQuantidadeKg?.message}
								</Form.Control.Feedback>
							</Form.Group>                            
						</Form.Row>                        
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Unidade de Medida<em>*</em>
								</Form.Label>
							<Controller
								control={controlEditDocument}
								name="editUnidadeMedida"
								defaultValue={documentToEdit.unidadeMedida}
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
									onInputChange={() =>
										setDocumentToEdit(state => ({
											documentoId: state.documento.value,
											documento: state.documento,
											descricao: state.descricao,
											quantidadeKg: state.quantidadeKg,
											unidadeMedidaId: state.unidadeMedida.value,
											unidadeMedida: state.unidadeMedida,
											unidMedOutros: state.unidMedOutros
										}))
									}
								/>}
								/>
							</Form.Group>
						</Form.Row>
						<If condition={watchEvent().unidadeMedida?.value === 4}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Especifique a unidade de medida<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditDocument()}
										defaultValue={documentToEdit.unidMedOutros}
										required
										name="editUnidMedOutros"
										placeholder="Insira a unidade de medida"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setModalEditDocuments(false)}
							/>
							<Button
								title="Editar"
								contained={true}
								type="submit"
							/>
						</div>
					</Form>
				</Modal>
		</If>

			<If condition={modalAtivo === 'RELATORIO_FOTOGRAFICO'}>
				<Modal show={true} onHide={() => setModalAtivo('')} title={'Adicionar arquivo'}>
					<Form onSubmit={modalArquivo.handleSubmit(submitArquivos)} autoComplete="off">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Anexar Relatório Fotográfico<em>*</em>
							</Form.Label>
							<Form.File
								ref={modalArquivo.register}
								name="arquivos"
								label={lblRelatorioFotoContencao}
								data-browse="upload"
								custom
								onChange={({ target }: any) => {
									if (!target.value) {
										return
									}
									setLblRelatorioFotoContencao(`${target.files[0].name}`);
								}}
							/>
						</Form.Group>
						<div className="button-position">
							<Button title="Cancelar" contained={false} type="button" onClick={() => setModalAtivo('')} />
							<Button title="Inserir" contained={true} type="submit" />
						</div>
					</Form>
				</Modal>
			</If>

			<If condition={isConfirmation}>
				<ConfirmationModal
					show={isConfirmation}
					confirmationMsg={STRINGS_CIBIO.CONFIRM_LEFT_EDITION}
					cancelMsg={STRINGS_CIBIO.CANCEL_LEFT_EDITION}
					onConfirm={() => history.goBack()}
					onHide={() => setIsConfirmation(false)}
					title={STRINGS_CIBIO.EDITION_TITLE_CONFIRMATION}
					type="button"
					variant="danger"
				>
					{STRINGS_CIBIO.EDITION_REGISTER_CONFIRMATION_CANCEL}
				</ConfirmationModal>
			</If>

	</div>;
}