import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../components/Button';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import Loading from '../../../components/Loading';
import { STRINGS } from '../../../util/strings';
import { STRINGS_ADMIN } from '../../../util/admin/strings';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { DecodedToken, Permissao, User, UsersGroups, UserStateType } from '../../../models/user';
import ToggleSwitch from '../../../components/ToggleSwitch';


import './../styles.scss';
import api from '../../../services/api';

export default function Edit (){

    const apiUrl = 'api/v1/admin/Usuarios/grupos';
    const apiUrlPermissoes = 'api/v1/admin/Usuarios/permissoes';

    const history = useHistory();
    const location = useLocation<UserStateType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [errorGetData, setErrorGetData] = useState<any>(null);
    const [isConfirmation, setIsConfirmation] = useState<boolean>(false);

    const [permissoes, getPermissao] = useState<Permissao[]>([]);

    const UsuarioSchema = yup.object().shape({
        nomeDoGrupo: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
    })

    const { register, errors, handleSubmit, reset, formState: { isDirty } } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(UsuarioSchema)
    });

    useEffect(() => {
        const getUsuarioData = async () => {

            const { status, data } = await api.get(apiUrlPermissoes);

            if (!(status === 200 || status === 201)) {
                setError(error.response.data);
                return;
            }
            let g = data as Permissao[];
            
            // console.log(location.state)
            if (location.state && location.state.id) {
                try {
                    const { data } = await api.get<UsersGroups>(`${apiUrl}/${location.state.id}`);
                    
                    reset({
                        id: data.id,
                        nomeDoGrupo: data.nomeDoGrupo,
                    })

                    let n_g = [] as Permissao[];
                    g.forEach(e=>{
                        let check = false;
                        let elm =  data.grupoPermissaos?.find(us=> e.id === us.permissaoId);
                        if(elm!=null) check = true;      
                        n_g.push({
                            ...e,
                            checked: check, 
                        });     
                    })
   
                    // const user_g = g.map(e=>{
                    //     let elm =  data.grupoPermissaos?.find(gp=> e.id === gp.permissaoId);
                    //     if(elm!=null) e.checked = true;
                    //     else e.checked = false;                        
                    //     return e;
                    // })
                    
                    getPermissao(n_g);
                    

                } catch (error:any) {
                    setErrorGetData(error.response.data);
                }
                setLoading(false);
            }
            else {
                getPermissao(g);

            }
        }
        getUsuarioData();
    }, [location.state, reset])

    const verifyErrorsOnForm = (): string[] => {
        let errorsForm: any[] = [];

        if (!isEmptyObj(errors)) {
            Object.entries(errors).map(err => errorsForm.push(err[0]));
        }
        return errorsForm;
    }

    const onSubmit = async (grupo: UsersGroups) => {
        setLoadingSubmit(true);
        setError(null);

        try {
            let permsSave = permissoes.filter(e=> e.checked === true).map(item=>{
                return {
                    ...item,
                    grupoId: item.grupoId,
                    permissaoId: item.id,
                }
            })
            let saveApi = api.post;
            let itemID = location?.state?.id ?? 0;
            // console.log("itemID = ", itemID)

            if(itemID !== 0) saveApi = api.put;

            const { status } = await saveApi(apiUrl, {
                id: location?.state?.id ?? 0,
                nomeDoGrupo:  grupo.nomeDoGrupo,
                grupoPermissaos: permsSave,
            });

            if (status === 200 || status === 201) {
                setSuccess(true);

                setTimeout(() => {
                    history.goBack();
                }, 3500);
            }
        } catch (error:any) {
            console.error("error = ", error)
            setError(error.response.data);
        }

        setLoadingSubmit(false);
    }

    const GrupoPermissoes = () =>{        
        const creatElemnts = <div className="riquered-fieldset">{
            permissoes.map((item, index) =>
                    <Form.Row className="row-custom">
                        <Col xs={10}>
                            <Form.Label>
                                {item.nomePermissao}
                        </Form.Label>
                        </Col>
                        <Col>
                            <ToggleSwitch
                                id={item.id?.toString() ?? ''}
                                small
                                disabled={false}
                                checked={item.checked}        
                                onChange={()=>{ 
                                    permissoes[index].checked = !permissoes[index].checked                                    
                                    getPermissao([... permissoes]); 
                                }}                                    
                            />
                        </Col>
                    </Form.Row>
            )}</div>
        //onChange={() => setMelhoristaResponsavel(!melhoristaResponsavel)}

        return creatElemnts
    }

    return (
        <div className="container container-fluid">
            <div className="search-content-filters">
                <If condition={verifyErrorsOnForm()}>
                    {verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
                </If>
                <If condition={success && !error}>
                    <AlertSuccess
                        message={STRINGS.ALERT_MESSAGE_SUCCESS_EDIT}
                    />
                </If>
                <If condition={!success && error !== null}>
                    {error !== null && error.message.length > 0 ? error.message.map((errorE: string) => <AlertError message={errorE} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
                </If>
                <h3>{STRINGS_ADMIN.EDITION_USUARIO}</h3>
                <If condition={loading}>
                    <span className="loading-page">
                        <Loading />
                    </span>
                </If>
                <If condition={!loading && !errorGetData}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row className="row-custom">
                        <Form.Group as={Col}>
                                <Form.Label>
                                Nome do Grupo<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="nomeDoGrupo"
                                    type="text"
                                    placeholder="Insira o Nome do Grupo"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={100}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>

                        </Form.Row>                       
                        {/* <Form.Row className="row-custom">
                            &nbsp;&nbsp;
                        <Form.Group className="checkbox-forms">
                                <Form.Check
                                    type="checkbox"
                                    ref={register}
                                    name="ativo"
                                    label="Ativo?"                                    
                                />
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row>
                            {GrupoPermissoes()}
                        </Form.Row>
                        <div className="button-position">
                            <Button
                                title="Cancelar"
                                type="button"
                                contained={false}
                                onClick={() => {
                                    isDirty ? setIsConfirmation(true) :
                                        history.goBack();
                                }}
                            />
                            <Button
                                title="Salvar"
                                type="submit"
                                contained={true}
                                isLoading={loadingSubmit}
                            />
                        </div>
                    </Form>
                </If>
                <If condition={isConfirmation}>
                    <ConfirmationModal
                        show={isConfirmation}
                        confirmationMsg={STRINGS_ADMIN.CONFIRM_LEFT_EDITION}
                        cancelMsg={STRINGS_ADMIN.CANCEL_LEFT_EDITION}
                        onConfirm={() => history.goBack()}
                        onHide={() => setIsConfirmation(false)}
                        title={STRINGS_ADMIN.EDITION_TITLE_CONFIRMATION}
                        type="button"
                        variant="danger"
                    >
                        {STRINGS_ADMIN.EDITION_REGISTER_CONFIRMATION_CANCEL}
                    </ConfirmationModal>
                </If>
            </div>
        </div>
    );

}