import React from 'react';

import Loading from '../../Loading';
import './style.scss';

interface Props
{
	title					: string | JSX.Element;
	description?			: string | JSX.Element;
	loading?				: boolean;
}

export default class BlankPage extends React.Component<Props>
{
	public static defaultProps = {
		addButton		: {validate: () => {return false;}, text: '', click: () => {}},
		filter			: {validate: () => {return false;}, columns: []},
		loading			: false,
	};

	ParseTitle ()
	{
		if (!this.props.title)
			return <React.Fragment />;

		return <h3 id="blank-page-main-title">{this.props.title}</h3>;
	}

	ParseSubtitle ()
	{
		if (!this.props.description)
			return <React.Fragment />;

		return <small id="blank-page-page-subtitle">{this.props.description}</small>;
	}

	render ()
	{
		return <div className="container container-fluid">
			{this.ParseTitle()}
			{this.ParseSubtitle()}
			{!this.props.loading ? this.props.children : <span id="content-loading"><Loading /></span>}
		</div>;
	}
}