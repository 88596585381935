import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
    tooltipTxt: string;
    children: any;
}

function TooltipComponent({ children, tooltipTxt }: Props) {
    return (
        <OverlayTrigger placement="right" overlay={
            <Tooltip id="overlay-example">
                {tooltipTxt}
            </Tooltip>

        }>
            {children}
        </OverlayTrigger>
    );
}

export default TooltipComponent;