import React from "react";
import { useRef } from "react";
import { Col, Form, Tab, Tabs } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import { uuid } from "../../services/uuid";
import ConfirmationModal from "../ConfirmationModal";
import Button from "../Button";
import Fieldset from "../Fieldset";
import "./style.scss";

interface TabControlProps<EntityType>{
    form        ?: any;//UseFormMethods<Type>;
    name        ?: string;
    label       ?: string;
    enabled     ?: boolean;
    required    ?: boolean;
    children    ?: any;
    addNewItem  ?: any;
    renderItem  ?: any;
    titleWatchField?: any;
    titleWatch  ?: any;
    location    ?: any;
    isInvalidCheck  ?: boolean;

    msgTabRemoveTitle?: string;
    msgTabRemovePrompt?: string;
    tabIndex?: any;
    tabpills?       : boolean;

    defaultValue   ?: EntityType[];

    indicesRemoved ?: (indices: number[])=> void;
}

export default function TabControl<FormType, EntityType extends {id?:any, uid?:any}>(props: TabControlProps<EntityType>)
{
    const formRef = useRef<HTMLFormElement>(null);
    const [enabled_,    setEnabled]    = React.useState<boolean>(true);
    const [tabItemSelect, setTabItemSelect]   = React.useState<EntityType>();
    const [asErro,      setAsErro]      = React.useState<boolean>(true);
    const [removendo,   setRemovendo]   = React.useState<boolean>(false);

    const [modalConfirm, setModalConfirm] = React.useState<any>({show:false});
    const myRef = useRef(null);

    const { control, register } = props.form;
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: props.name ?? '', // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
        keyName: props.name ?? uuid(),
      });

    const [itemRemoved, setItemsRemoved]  = React.useState<number[]>([]);
    
    React.useEffect(() => {
        setItemsRemoved([]);
    },[]);

    React.useEffect(()=>{
        setItemsRemoved([]);
    },[props.defaultValue])

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    },[props.enabled]);

    React.useEffect(() => {
        let _asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
        setAsErro(_asErro)
        if(!(!_asErro)){
            if(myRef != null) {
                let elmt = myRef.current as any;
                elmt?.scrollIntoView({ behavior: 'smooth', block: 'start' }, 500);
            }
        }
    }, [props.form?.errors, props?.name]);

    const tabIndex = () =>{
        if(tabItemSelect?.uid !== null) { return tabItemSelect?.uid};
        if(fields?.length > 0) { return fields[0].uid; }
        return null;
    }

    const removeTab  = (item: any, index: any)=>{
        setModalConfirm({show: true, uid: item.uid, itemIndex: index as number});
    }
    const ConfirmRemove = () =>{
        setRemovendo(true);

        setTimeout(() => { 

            let nItems = itemRemoved;
            nItems.push(modalConfirm.itemIndex);
            setItemsRemoved(nItems);
            if(props.indicesRemoved) props.indicesRemoved(nItems);

            const clenght   = fields.length;
            if(clenght === 1) { return }
            //if(modalConfirm.itemIndex < (clenght - 1)){
            let selectItem = modalConfirm.itemIndex + 1;
            for(let i = 0; i < (2 * clenght); i++){          
                if(selectItem >= clenght) { selectItem = 0;}      
                if(nItems.indexOf(selectItem) === -1 ){break}                
                selectItem++;            
            }                    
            setTabItemSelect(fields[selectItem] as EntityType);
            setModalConfirm({show:false, uid: null})
            setRemovendo(false)

        }, 2000);
    };
    return <Form.Group ref={myRef}     
        className="tab-control mb-3" as={Col} md={12}> 
        <ConfirmationModal
				show={modalConfirm.show}
				confirmationMsg="Sim"
				cancelMsg="Não"
                loading={removendo}
				onConfirm={()=> ConfirmRemove()}
				onHide={() => setModalConfirm({show:false})}
				title={props.msgTabRemoveTitle ?? "Remover Item?"}
				type="button"
				variant="danger"
			>
                {props.msgTabRemovePrompt ?? "Confirmação a remoção do item?"}
			</ConfirmationModal>
		<Form.Row className="row-custom mb-0">
            <Form.Group className="mb-0" as={Col}>
            <Fieldset disabled={props.location?.state?.readonly} title={props.label}>
                {(props.isInvalidCheck && asErro)? 
                <p className="error-field">{props?.name ?props.form?.errors[props?.name]?.message : ''}</p>
                :''}
                {/* <Tabs activeKey={ tabIndex  ? fields[tabIndex].id  : tabItemSelect?.id} md={12}   */}
                <Tabs activeKey={ tabIndex() } md={12}  
                    id={`tabcontrol-${props?.name}`}                    
                    className="mb-3 tab-control-tabs" 
                    variant={props.tabpills === true ? 'pills' : 'tabs'}                    
                    onSelect={(selectUid:any) => {
                        if(selectUid === 'add-new-tab'){
                            if(props.addNewItem){
                                let item = props.addNewItem();
                                item.uid = uuid();
                                item.id  = uuid();
                                append(item);
                                setTabItemSelect(item);                            }
                        }
                        else{
                            let item = fields.find(element => element?.id === selectUid)
                            setTabItemSelect(item as EntityType); 
                         }                   
                    }}
                    >
                    {fields.map((item, index) => {       
                            if(itemRemoved.indexOf(index) >= 0){
                                return <></>
                            }
                           return <Tab 
                            key={`tabcontrol-${props?.name}-tab${index}`}
                            //key={`tab-item-${uuid()}`}                           
                            eventKey={item.uid} 
                            // title={<div>{ props.titleWatch ? props.titleWatch(index) : `item${index}` }
                            // {enabled_ === true ? <Button className="btn-sm btn-remove-class" 
                            // onClick={()=>removeTab(item, index)} 
                            // title="x" contained={false} type="button" /> : ''}
                            // </div>}  
                            
                            title={<div>{ props.titleWatch ? props.titleWatch(index) : (props.titleWatchField ? props.form.watch(`${props.name}.${index}.${props.titleWatchField }`) : '') }
                            {enabled_ === true ? <Button className="btn-sm btn-remove-class" onClick={()=>removeTab(item, index)} title="x" contained={false} type="button" /> : ''}
                            </div>}   
                            tabClassName="tabItem"                         
                            >
                             {props.renderItem ? props.renderItem(item, index) : ''}                       
                        </Tab>
                    })}
                    {enabled_ === true?
                    <Tab key={`tabcontrol-${props?.name}-add-new-tab`} tabClassName="tabItem-new-tab" eventKey="add-new-tab" title="+">                        
                    </Tab> : ''}
                               
                </Tabs>
			    </Fieldset>	
            </Form.Group>
        </Form.Row>
    </Form.Group>;

}