import { CSSProperties, useEffect, useRef, useState } from "react";
import {
	//BrowserRouter as Router,
	useParams,
	useHistory
} from "react-router-dom";
import { Form, Col } from "react-bootstrap";
import Button from "../../components/Button";
import { useLocation } from "react-router-dom";
import NavigationMessage, { MessageContent, MessageType } from "../NavigationMessages";

import "./../form/style.scss"
import { uuid } from "../../services/uuid";

interface ParamsType{
	id: string
}
interface StateType {
	readonly: boolean;
	id: number;
}
export interface OnSaveSubmitResult{
	isOk?			: boolean;
	message?		: string;
}
interface EditViewProps<FormType>
{
	id?				: any;
	title			: string;	
	form			: any;
	children?		: any;
	getLoadById?	: any;
	getEntity?		: any;
	setEntityByPopUp?: any;
	OnInit?			: any;//function
	OnSaveSubmit?	: (newvalues:any, entity:any) => Promise<OnSaveSubmitResult>;
	OnSaveSubmitErro?		:any;
	OnSaveSubmitIsOk?		:any;
	OnSaveSubmitIsOkGoBack?	: boolean;
	MsgSaveSubmitSucess?:any;
	parseButtons?	: any;
	ReloadById?		: boolean;

	setformRef			?: (f: React.RefObject<HTMLFormElement>) => any;
}

export default function EditView<FormType>(props: EditViewProps<FormType>){

	const UrlParam = useParams<ParamsType>();
	const formRef = useRef<HTMLFormElement>(null);

    const location 		= useLocation<StateType>();
	const history 		= useHistory();
    const [entity, 			setEntity] = useState<FormType>();
	const [loadingSubmit, 	setLoadingSubmit] = useState<boolean>(false);
	const [msgSaveSubmitSucess, setMsgSaveSubmitSucess] = useState<string>();
	

    //Page notification
    const GoBackToTop = () => {window.scrollTo({ top: 0, behavior: 'smooth' })}
	const [pageMessages, setPageMessages] = useState<MessageContent[]>([]);
	const hasPageMessages = () : boolean => {return pageMessages.length > 0;}
	const clearPageMessages = async () => {await setPageMessages([]); }
	const addPageMessages = (message : MessageContent, clearMessages : boolean = true) => {
		let currentPageMessages:MessageContent[] = !clearMessages ? pageMessages : [];
		setPageMessages([]);
		currentPageMessages.push(message);
		setPageMessages(currentPageMessages);
	}
	const setSuccessMessageAndRedirect = (message : string, callback: any) => {
		//setStatus(statuses.idle);
		addPageMessages({type:MessageType.success, content:message});
		GoBackToTop();
		setTimeout(() => { callback(); }, 3500);
	}

	useEffect(() => {
		if(props.getEntity){
			props.getEntity(entity as any);
		}		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity]);

	useEffect(() => {
		if(!(!props.setEntityByPopUp)){
			
			props.form?.reset({});
			setEntity({} as any);

			setTimeout(() => {
				props.form?.reset(props.setEntityByPopUp);
				setEntity(props.setEntityByPopUp);
			}, 500);
		}	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.setEntityByPopUp]);

	const getEntity = async () => {  
		try {
			let paramrefs = {help: 'esse parâmetro é usado para passagem de referência'};
			if(props.OnInit) {paramrefs = await props.OnInit();}

			if(props.getLoadById && props.form !== null)
			{
				let dados = await props.getLoadById(UrlParam.id, paramrefs);  					
				props.form?.reset(dados);
				setEntity(dados);
			}
			
		} catch (error:any) {
			console.error(error, 'Form:', props.form);
		}
	};



    useEffect(() => { 
		clearPageMessages();        		
        if (UrlParam && UrlParam.id) getEntity();
		else { if(props.OnInit) { props.OnInit(); } }

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state, props.form.reset]);

	useEffect(() => {
		if(props.ReloadById === true){			
			if (UrlParam && UrlParam.id) getEntity();
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [UrlParam, props.ReloadById]);

	useEffect(() => {
		setMsgSaveSubmitSucess(props.MsgSaveSubmitSucess)		
	}, [props.MsgSaveSubmitSucess]);

    const submitForm = async (values:any) => {

		try {
			setLoadingSubmit(true);
			let _submitOk : OnSaveSubmitResult = {isOk: false};
			if(props.OnSaveSubmit){	
				let newvalues = {
					...entity,
					...values
				}			
				_submitOk = await props.OnSaveSubmit(newvalues, entity);
				if(!_submitOk.isOk) { 
					setLoadingSubmit(false); 
					return;
				}
			}
			setSuccessMessageAndRedirect( _submitOk.message ?? (msgSaveSubmitSucess ?? ""), () => {
				if(props.OnSaveSubmitIsOk && !props.OnSaveSubmitIsOkGoBack){
					props.OnSaveSubmitIsOk()
				}
				else if(props.OnSaveSubmitIsOkGoBack) {
					history.goBack();
				}
			})
		} catch (error:any) {
			console.error(error)
		}
		setLoadingSubmit(false);

	}

    const parseDefaultButton = (formref_:any) => {
		let btnCancelar = <Button
			title="Voltar"
			contained={false}
			type="button"
			onClick={() => {
				//defaultForm.formState.isDirty ? setStatus(statuses.go_back) :
				history.goBack();
			}}
		/>;
        let btnSalvar = <Button
        title="Salvar"
		isLoading={loadingSubmit}
        contained={true}
        className="btn-success"
        type="submit"
        />;

        return <div className="button-position">
			{btnCancelar}
            {btnSalvar}
		</div>;
	}
	const errosHandler = (errData:any) =>{
		if(props.OnSaveSubmitErro!= null){
			props.OnSaveSubmitErro(errData);
		}
		console.log(errData);
	};
    return (
		<div className="container container-fluid">
			<NavigationMessage messages={pageMessages} />			
			<Form id={props.id ?? uuid()} ref={formRef} onSubmit={props.form.handleSubmit(submitForm, errosHandler)}>
				{props.setformRef ? props.setformRef(formRef)  : ''}
				<h3 className="mb-12 form-group col">{props.title}</h3>
				<Form.Group className="mb-0" as={Col}>
					<Form.Row className="row-custom">
						{props.children}      
					</Form.Row>    
				</Form.Group>
				<div className="button-position">
					{props.parseButtons ? props.parseButtons (formRef) : parseDefaultButton(formRef)}
				</div>
			</Form>
		</div>
	);
}


interface EditFieldProps {
    title?			: any;
    children		: any;
    className?		: string;
    disabled?		: boolean;
	style?			: CSSProperties;
	layoutColumns?	: any;
	actionButtons?	: any;
	rightButton?	: any;
	required?       : boolean;
	visible?		: boolean;
}
export function EditField({ title, children, className, style, disabled , layoutColumns, actionButtons, rightButton, required, visible}: EditFieldProps):any {
	const [defaultVisible, setDefaultVisible] = useState<boolean>();

	useEffect(() => {
		const d_ = ((visible === null || visible === undefined) ? true : visible);
		setDefaultVisible(d_)
	}, [visible]);

	return (defaultVisible ? 
		<Form.Group className="custom-edit-field" as={Col} md={layoutColumns ?? 12} >			
			<fieldset style={style} disabled={disabled} className={`custom-fieldset c-fieldset ${className ?? ''}`}>
				{rightButton? <div className="button-position custom-edit-field-rightButton">
					{rightButton}
				</div>: ''}
				<legend className="custom-legend">{title} {required ? <em>*</em> : ''}</legend>
				<Form.Row className="row-custom">{children}</Form.Row>
				{actionButtons? <Form.Row className="row-custom button-position custom-legend-buttons">{actionButtons()}</Form.Row> : ''}
			</fieldset>
		</Form.Group>
    	: '');
	//block['Row'] = Row;
	//return block;
}

// export function EditCollpase({ title, children, className, style, disabled , layoutColumns, actionButtons, rightButton, required, visible}: EditFieldProps):any {
//     const [isOpened, setIsOpened] = useState<boolean>(false);

// 	const defaultVisible = ((visible === null || visible === undefined) ? true : visible);
// 	const block = <>{defaultVisible ? 
// 		<Form.Group className="custom-edit-field" as={Col} md={layoutColumns ?? 12} >			
// 			<fieldset style={style} disabled={disabled} className={`custom-fieldset c-fieldset ${className ?? ''}`}>
// 				{rightButton? <div className="button-position custom-edit-field-rightButton">
// 					{rightButton}
// 				</div>: ''}
// 				<legend className="custom-legend">{title} {required ? <em>*</em> : ''}</legend>
// 				<Form.Row className="row-custom">
				
// 				<Collapse in={isOpened}>
// 				<Form.Group className="custom-edit-field" as={Col} md={12}>
// 					{children}
// 					<div id="example-collapse-text">           
// 					Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
// 					terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
// 					labore wes anderson cred nesciunt sapiente ea proident.
// 					</div>
// 				</Form.Group>				
// 				</Collapse></Form.Row>
// 				{actionButtons? <Form.Row className="row-custom button-position custom-legend-buttons">{actionButtons()}</Form.Row> : ''}
// 			</fieldset>
// 		</Form.Group>
//     	: ''}</>;
// 	//block['Row'] = Row;
// 	return block;
// }

export function ActionField({ title, children, className, style, disabled , layoutColumns, actionButtons, required}: EditFieldProps) {
	return <div className="custom-action-field form-row col-md-12">
	{(Array.isArray(children) ? children : [children])?.map((child:any)=>{
		return {...child, layoutColumns: 11}
	})}
	<Form.Group className="custom-action-field-button" as={Col} md={1} >
		<Button title="+"
            contained={true}
            type="button" onClick={() => {	}} />
	</Form.Group>
	</div>;
}



