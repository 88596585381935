export{}
declare global {
    interface Array<T> {
        firstOrDefault(find_:any): T;
        pushArray(arr:any[] | undefined): any[];
    }
}

if (!Array.prototype.firstOrDefault) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.firstOrDefault = function(find_:any): any {
    const item = this.find(find_);
    if(!item) return null;
    return item;
  }
}

export const ArrayFirstOrDefault = (array: any[], find_:any) =>{
    const item = array.find(find_);
    if(!item) return null;
    return item;
}

export function isNumber(value: string | number | any): boolean
{
  try{
    return ((value != null) &&
           (value !== '') &&
           !isNaN(Number(value.toString())));
  } catch (error:any) {return false;}	
}

if (!Array.prototype.pushArray) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.pushArray = function(arr:any[] | undefined): any[] {
    if(arr == null || arr === undefined) return this;
    this.push.apply(this, arr);
    return this;
  }
}

// Array.prototype.pushArray = function(arr) {
//   this.push.apply(this, arr);
// };