import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { Form, Col } from 'react-bootstrap';
import Button from '../../components/Button';
import If from '../../components/If';
import LoadingInForm from '../../components/LoadingInForm';
import AlertError from '../../components/AlertError';
import AlertSuccess from '../../components/AlertSuccess';
import { STRINGS } from '../../util/strings';
import viaCepApi from '../../services/viaCepApi';
import estadosApi from '../../services/estadosApi';
import { OperatingUnitysForm, States, Address } from '../../models/models';
import './styles.css';

function OperatingUnitys() {
    const [lblMapsandContracts, setLblMapsandContracts] = useState('Escolher arquivo');
    const [lblMaterialList, setLblMaterialList] = useState('Escolher arquivo');
    const [lblBlueprint, setLblBlueprint] = useState('Escolher arquivo');
    const [lblEquipmentsList, setLblEquipmentsList] = useState('Escolher arquivo');
    const [lblTechnicalTeamAndCurriculum, setLblTechnicalTeamAndCurriculum] = useState('Escolher arquivos');
    const [lblCultivationNearby, setLblCultivationNearby] = useState('Escolher arquivo');
    const history = useHistory();
    const [states, setStates] = useState<States>([]);
    const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
    const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);

    const operatingUnitysSchema = yup.object().shape({
        name: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        operationType: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        address: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        neighborhood: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        zipCode: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        city: yup
            .string(),
        state: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        uploadAreaMapsAndContact: yup
            .mixed(),
        uploadMaterialListIE: yup
            .mixed(),
        aditionalInformation: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        uploadBlueprint: yup
            .mixed(),
        uploadEquipmentsList: yup
            .mixed(),
        uploadTechnicalTeamAndCurriculum: yup
            .mixed(),
        uploadCultivationNearby: yup
            .mixed(),
        nearestMedicalCenter: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        materialType: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        materialCategory: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
    })

    const initialValues: OperatingUnitysForm = {
        name: '',
        operationType: 'importação',
        address: '',
        neighborhood: '',
        zipCode: '',
        city: '',
        state: '',
        uploadAreaMapsAndContract: '',
        uploadMaterialListIE: '',
        aditionalInformation: '',
        uploadBlueprint: '',
        uploadEquipmentsList: '',
        uploadTechnicalTeamAndCurriculum: '',
        uploadCultivationNearby: '',
        nearestMedicalCenter: '',
        materialType: 'viavel',
        materialCategory: ''
    }

    const { register, handleSubmit, setError, setValue, errors, getValues } = useForm({
        defaultValues: initialValues,
        mode: 'onTouched',
        resolver: yupResolver(operatingUnitysSchema)
    })

    useEffect(() => {
        getStates();
    })

    const getStates = async () => {
        try {
            const { data } = await estadosApi.get('');
            setStates(data);

        } catch (error:any) {
            console.error(error)
        }
    }

    const setZipCodeError = () => {
        setError('zipCode', {
            type: "validate",
            message: STRINGS.ZIP_CODE_ERROR
        })
    }

    const getAddress = async (e: any) => {
        const zipCode = e.target.value;
        try {
            const { data } = await viaCepApi.get<Address>(`${zipCode}/json/`);

            if (data.bairro)
                setValue('neighborhood', `${data.bairro}`);

            if (data.logradouro)
                setValue('address', `${data.logradouro}`);

            if (data.localidade)
                setValue('city', `${data.localidade}`);

            if (data.uf)
                setValue('state', `${data.uf}`);

            if (data.erro) {
                setZipCodeError();
            }
        } catch (error:any) {
            setZipCodeError();
        }
        setLoadingAddress(false)
    }

    const verifyErrorsOnForm = (): boolean => {
        if (errors.name ||
            errors.operationType ||
            errors.address ||
            errors.neighborhood ||
            errors.aditionalInformation ||
            errors.nearestMedicalCenter ||
            errors.materialType ||
            errors.materialCategory
        )
            return true;
        return false;
    }

    const onSubmit = (data: any) => {
        // console.log(data);

        setSuccessfulResponse(true);
    };

    return (
        <div className="container container-fluid">
            <If condition={verifyErrorsOnForm()}>
                <AlertError
                    message={STRINGS.ALERT_WARNING_FIELDS}
                />
            </If>
            <If condition={successfulResponse && !verifyErrorsOnForm()}>
                <AlertSuccess
                    message={STRINGS.ALERT_MESSAGE_SUCCESS}
                />
            </If>
            <h3>Cadastro de Unidades Operativas/Lotes de Apoio</h3>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row className="row-custom">
                    <Form.Group as={Col}>
                        <Form.Label>
                            Nome da Unidade Operativa<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            type="text"
                            name="name"
                            placeholder="Insira o nome da unidade operativa"
                            autoComplete="off"
                            isInvalid={errors.name !== undefined}
                            maxLength={100}
                        />
                        <If condition={errors.name}>
                            <Form.Control.Feedback type="invalid">
                                {errors.name?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col} md={3}>
                        <Form.Label>
                            Tipo de operação<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            name="operationType"
                            as="select"
                            isInvalid={errors.operationType !== undefined}
                        >
                            <Form.Control
                                as="option"
                                value="importação"
                                label="Importação"
                            />
                            <Form.Control
                                as="option"
                                value="exportação"
                                label="Exportação"
                            />
                        </Form.Control>
                        <If condition={errors.operationType}>
                            <Form.Control.Feedback type="invalid">
                                {errors.operationType?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col} md={3}>
                        <Form.Label>
                            CEP<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            as={NumberFormat}
                            getInputRef={register}
                            type="text"
                            name="zipCode"
                            placeholder="Insira o cep da unidade operativa"
                            isInvalid={errors.zipCode !== undefined}
                            format="#####-###"
                            autoComplete="off"
                            onBlur={(e: any) => {
                                getAddress(e);
                                setLoadingAddress(true)
                            }}
                        />
                        <If condition={loadingAddress && !errors.zipCode}>
                            <LoadingInForm />
                        </If>
                        <If condition={errors.zipCode}>
                            <Form.Control.Feedback type="invalid">
                                {errors.zipCode?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                </Form.Row>
                <Form.Row className="row-custom">
                    <Form.Group as={Col}>
                        <Form.Label>Endereço<em>*</em></Form.Label>
                        <Form.Control
                            ref={register}
                            name="address"
                            type="text"
                            placeholder="Insira o endereço da unidade operativa"
                            autoComplete="off"
                            isInvalid={errors.address !== undefined}
                            maxLength={255}
                        />
                        <If condition={errors.address}>
                            <Form.Control.Feedback type="invalid">
                                {errors.address?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Bairro</Form.Label>
                        <Form.Control
                            ref={register}
                            name="neighborhood"
                            type="text"
                            placeholder="Insira o bairro da unidade operativa"
                            autoComplete="off"
                            maxLength={255}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row className="row-custom">
                    <Form.Group as={Col} md={1}>
                        <Form.Label>UF<em>*</em></Form.Label>
                        <Form.Control
                            as="select"
                            ref={register}
                            name="state"
                            isInvalid={errors.state !== undefined}
                        >
                            <Form.Control
                                as="option"
                                label=''
                            />
                            {states.map((state) => (
                                <Form.Control
                                    key={state.id}
                                    as="option"
                                    label={state.sigla}
                                    value={state.sigla}
                                />
                            ))}
                        </Form.Control>
                        <If condition={errors.state}>
                            <Form.Control.Feedback type="invalid">
                                {errors.state?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col} md={5}>
                        <Form.Label>
                            Município
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            name="city"
                            type="text"
                            autoComplete="off"
                            placeholder="Insira o município da unidade operativa"
                            maxLength={255}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Mapas e contrato de área
                        </Form.Label>
                        <Form.File
                            type="file"
                            ref={register}
                            name="uploadAreaMapsAndContact"
                            label={lblMapsandContracts}
                            onChange={(e: any) => {
                                if (!e.target.value) {
                                    return
                                }
                                setLblMapsandContracts(e.target.files[0].name)
                            }}
                            custom
                            data-browse="upload"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row className="row-custom">
                    <Form.Group as={Col}>
                        <Form.Label>
                            Lista de materiais &#40;Importada/Exportada&#41;
                        </Form.Label>
                        <Form.File
                            type="file"
                            ref={register}
                            name="uploadMaterialListIE"
                            label={lblMaterialList}
                            onChange={(e: any) => {
                                if (!e.target.value) {
                                    return
                                }
                                setLblMaterialList(e.target.files[0].name)
                            }}
                            custom
                            data-browse="upload"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Plantas baixas das instalações
                        </Form.Label>
                        <Form.File
                            type="file"
                            ref={register}
                            label={lblBlueprint}
                            name="uploadBlueprint"
                            onChange={(e: any) => {
                                if (!e.target.value) {
                                    return
                                }
                                setLblBlueprint(e.target.files[0].name)
                            }}
                            custom
                            data-browse="upload"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row className="row-custom">
                    <Form.Group as={Col}>
                        <Form.Label>
                            Lista de equipamentos
                        </Form.Label>
                        <Form.File
                            type="file"
                            ref={register}
                            label={lblEquipmentsList}
                            name="uploadEquipmentsList"
                            onChange={(e: any) => {
                                if (!e.target.value) {
                                    return
                                }
                                setLblEquipmentsList(e.target.files[0].name)
                            }}
                            custom
                            data-browse="upload"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Lista de equipe técnica e currículo lattes
                        </Form.Label>
                        <Form.File
                            type="file"
                            ref={register}
                            label={lblTechnicalTeamAndCurriculum}
                            name="uploadTechnicalTeamAndCurriculum"
                            onChange={(e: any) => {
                                if (!e.target.value) {
                                    return
                                }
                                setLblTechnicalTeamAndCurriculum(`${e.target.files[0].name}, ${e.target.files[1].name}`)
                            }}
                            custom
                            multiple
                            data-browse="upload"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row className="row-custom">
                    <Form.Group as={Col}>
                        <Form.Label>
                            Cultivo nas proximidades
                        </Form.Label>
                        <Form.File
                            type="file"
                            ref={register}
                            label={lblCultivationNearby}
                            name="uploadCultivationNearby"
                            onChange={(e: any) => {
                                if (!e.target.value) {
                                    return
                                }
                                setLblCultivationNearby(e.target.files[0].name)
                            }}
                            custom
                            data-browse="upload"
                            unselectable="on"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Centro médico mais próximo<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            type="text"
                            name="nearestMedicalCenter"
                            placeholder="Inisira o centro médico mais próximo"
                            autoComplete="off"
                            isInvalid={errors.nearestMedicalCenter !== undefined}
                            maxLength={100}
                        />
                        <If condition={errors.nearestMedicalCenter}>
                            <Form.Control.Feedback type="invalid">
                                {errors.nearestMedicalCenter?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                </Form.Row>
                <Form.Row className="row-custom">
                    <Form.Group as={Col}>
                        <Form.Label>
                            Tipo de material<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            as="select"
                            name="materialType"
                            isInvalid={errors.materialType !== undefined}
                        >
                            <Form.Control
                                as="option"
                                label="Viável"
                                value="viavel"
                            />
                            <Form.Control
                                as="option"
                                label="Inviável"
                                value="inviavel"
                            />
                        </Form.Control>
                        <If condition={errors.materialType}>
                            <Form.Control.Feedback type="invalid">
                                {errors.materialType?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Categoria do material<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            as="select"
                            name="materialCategory"
                            isInvalid={errors.materialCategory !== undefined}
                        >
                            <Form.Control
                                as="option"
                                label="Sementes"
                                value="sementes"
                            />
                            <Form.Control
                                as="option"
                                label="Grãos"
                                value="graos"
                            />
                            <Form.Control
                                as="option"
                                label="Folhas"
                                value="folhas"
                            />
                        </Form.Control>
                        <If condition={errors.materialCategory}>
                            <Form.Control.Feedback type="invalid">
                                {errors.materialCategory?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Informações adicionais<em>*</em>
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            ref={register}
                            name="aditionalInformation"
                            autoComplete="off"
                            isInvalid={errors.aditionalInformation !== undefined}
                        />
                        <If condition={errors.aditionalInformation}>
                            <Form.Control.Feedback type="invalid">
                                {errors.aditionalInformation?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                </Form.Row>
                <div className="button-position">
                    <Button
                        title="Cancelar"
                        type="button"
                        contained={false}
                        onClick={() => history.goBack()}
                    />
                    <Button
                        title="Cadastrar"
                        type="submit"
                        contained={true}
                    />
                </div>
            </Form>
        </div>
    );
}

export default OperatingUnitys;