import React from 'react';
// import NumberFormat from 'react-number-format';
import * as Models from '../../../models/models';
import * as Bootstrap from 'react-bootstrap';
import * as HookForm from 'react-hook-form';
import If from '../../../components/If';

// import "./style.scss";

interface Props
{
	form					: HookForm.UseFormMethods;
	name					: string;
	culture?				: Models.CultureResults;
}

interface State
{
	arr_12_1				: string;
	arr_12_2				: string;
	arr_12_3				: string;
}

export default class Group_12 extends React.Component<Props, State>
{
	public static defaultProps = {
		required						: false,
		readOnly						: false,
		columns							: 12,
	};

	static emptyData = {
		arr_12_1			: '',
		arr_12_2			: '',
		arr_12_3			: '',
	};

	constructor (props:Props)
	{
		super(props)

		this.props.form.register(this.props.name);
	}

	componentDidMount ()
	{
		let data = this.props.form.getValues(this.props.name) ? JSON.parse(this.props.form.getValues(this.props.name)) : Group_12.emptyData;

		this.setState(data);
	}

	save ()
	{
		this.props.form.setValue(this.props.name, JSON.stringify(this.state));

		// console.log('Chamou Save');
		// console.log(JSON.parse(JSON.stringify(this.state)));
	}

	formArroz () : JSX.Element
	{
		let labelSize: number = 3;

		return <Bootstrap.Form.Group as={Bootstrap.Col} md={12}>
			<Bootstrap.Form.Group as={Bootstrap.Row}>
				<Bootstrap.Form.Label column sm={labelSize}>12.1. Qualidades Nutricionais:</Bootstrap.Form.Label>
				<Bootstrap.Col sm={12 - labelSize}>
					<Bootstrap.Form.Control type="text" placeholder="" value={this.state?.arr_12_1} onChange={event => this.setState({arr_12_1:event.target.value}, this.save)} />
				</Bootstrap.Col>
			</Bootstrap.Form.Group>
			<Bootstrap.Form.Group as={Bootstrap.Row}>
				<Bootstrap.Form.Label column sm={labelSize}>12.2. Classe:</Bootstrap.Form.Label>
				<Bootstrap.Col sm={12 - labelSize}>
					<Bootstrap.Form.Control type="text" placeholder="" value={this.state?.arr_12_2} onChange={event => this.setState({arr_12_2:event.target.value}, this.save)} />
				</Bootstrap.Col>
			</Bootstrap.Form.Group>
			<Bootstrap.Form.Group as={Bootstrap.Row}>
				<Bootstrap.Form.Label column sm={labelSize}>12.3. Qualidades tecnológicas/industriais:</Bootstrap.Form.Label>
				<Bootstrap.Col sm={12 - labelSize}>
					<Bootstrap.Form.Control type="text" value={this.state?.arr_12_3} onChange={event => this.setState({arr_12_3:event.target.value}, this.save)} />
				</Bootstrap.Col>
			</Bootstrap.Form.Group>
		</Bootstrap.Form.Group>;
	}

	formTextoLivre () : JSX.Element
	{
		return <Bootstrap.Form.Group as={Bootstrap.Col}>
			<Bootstrap.Form.Control
				as="textarea"
				type="text"
				ref={this.props.form.register}
				name={this.props.name}
				placeholder="Insira os detalhes do ciclo agronomico"
				autoComplete="off"
				isInvalid={this.props.form.errors[this.props.name] !== undefined}
				rows={10}
			/>
			<If condition={this.props.form.errors[this.props.name]}>
				<Bootstrap.Form.Control.Feedback type="invalid">
					{this.props.form.errors[this.props.name]?.message}
				</Bootstrap.Form.Control.Feedback>
			</If>
		</Bootstrap.Form.Group>;
	}

	render ()
	{
		if (!this.props.culture || !this.props.culture?.avaliacaoCulivarAsText)
			return <React.Fragment />;

		switch (this.props.culture.nome.toLowerCase())
		{
			case 'arroz':

				return this.formArroz();

			default:

				return this.formTextoLivre();

		}
	}
}