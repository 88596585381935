import * as Bootstrap from 'react-bootstrap';
import React, { CSSProperties } from "react";
import { useState } from "react";
import { Form, Col } from "react-bootstrap";
import Button from "../../components/Button";
import * as EditButtons from "../../components/EditButton";
import ReasonModal from '../../components/ReasonModal';
import { uuid } from "../../services/uuid";
import "./style.scss";

interface dialogEditProps{
    form?           : any;
	name?           : string;    
    isButtonType?   : 'config' | 'edit' | 'normal' | 'add' | 'custom' | 'none'; //string |
    isButtonCustom? : any;
    label?          : string;
    dlgTitle?       : string | any;
    children?	    : any;
    enabled?	    :boolean;
	key?		    : any;
    onConfirme?     : any;
    onClose?        : any;
    onShow?         : any;
    onShowAsync?    : any;
    layoutColumns? : any;
    positionTopAlign?: boolean;
    width50?        : boolean;
    width25?        : boolean;
    OnClick?        : any;
    popData?        : any;
    hideFooter      ?: boolean; 
    CloseME         ?: boolean;
    style           ?: CSSProperties;
}
export default function DialogEdit(props: dialogEditProps)
{
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [enabled_, setEnabled] = React.useState<boolean>(true);
    const [childrens, setChildrens] = React.useState<any[]>([]);

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    },[props.enabled]);

    const tiggerOnClick = async ()=>{
        if(props.onShow)        {props.onShow(); }
        if(props.onShowAsync)   {await props.onShowAsync(); }
        if(props.OnClick)       {props.OnClick(); } 
        setShowDialog(true);
        
    }
    return <>
    <ReasonModal
        className_={`dialogEditBox ${props.width50 === true ? 'modal-dialog-50' : ''}`}        show={showDialog}
        style = {props.style}
        onHide={() => {setShowDialog(false); if(props.onClose){ props.onClose();} }}
        title={props.dlgTitle ?? 'Editar'}
        actionMsg={enabled_ ? "Confirmar" : "Fechar"}
        hideFooter={!enabled_}
        footerOpctional={!enabled_}    
        onConfirm={() => {            
            let isContinue = true;
            if(props.onConfirme!=null){ isContinue = props.onConfirme() ?? true; }
            if(isContinue === false) { return ;}
            setShowDialog(false)
        }}
        loading={loading}
        disabled={false}
        // hideFooter={successCancel}
    ><Form.Row className="row-custom">{ 
        (Array.isArray(props.children) ? props.children : [props.children])?.map((child:any)=>{
        return {...child, ...child.props, enabled: ((props.enabled === null) ? child.enabled : props.enabled), name: child.name,  form: props.form, classNames: 'disableMode ' + (child?.className ?? ''), defaultValue: child?.defaultValue, value: child?.value }
    })} </Form.Row>
    </ReasonModal>
    {enabled_ ? 
        (props.isButtonType === 'normal'? 
        <Form.Row className="row-custom button-position custom-legend-buttons">  
            <Button 
            title={props?.label ?? 'Editar'}
            contained={true}
            type="button"
            onClick={tiggerOnClick} />
        </Form.Row>
        : (props.isButtonType === 'config' ?
        <EditButtons.ConfigButton tooltip={props?.label ?? 'Editar'}  onClick={tiggerOnClick} />  
        : (props.isButtonType === 'add' ?
        <EditButtons.InsertButton tooltip={props?.label ?? '+ Adicionar'}  onClick={tiggerOnClick} />  
        : (props.isButtonType === 'custom' ? 
        {...props.isButtonCustom, onClick: tiggerOnClick, type: "button", title: (props?.label ?? 'Editar') }
        :''))))
    : ''}
    </>
}


export function DialogEditSingle(props: dialogEditProps)
{
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [enabled_, setEnabled] = React.useState<boolean>(true);
    const [childrens, setChildrens] = React.useState<any[]>([]);

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    },[props.enabled]);

    const tiggerOnClick = async ()=>{
        if(props.onShow)        {props.onShow(props.popData); }
        if(props.onShowAsync)   {await props.onShowAsync(props.popData); }
        if(props.OnClick)       {props.OnClick(props.popData); } 
        setShowDialog(true);        
    }
    React.useEffect(() => {
        if(props.CloseME === true){ setShowDialog(false); }
    },[props.CloseME]);
    return <>
    <ReasonModal
        className_={`dialogEditBox ${props.width50 === true ? 'modal-dialog-50' : ''} ${props.width25 === true ? 'modal-dialog-25' : ''}`}        show={showDialog}
        style = {props.style}
        onHide={() => {setShowDialog(false); if(props.onClose){ props.onClose();} }}
        title={props.dlgTitle ?? 'Editar'}
        actionMsg={enabled_ ? "Confirmar" : "Fechar"}
        hideFooter={!enabled_ || (props.hideFooter === true)}
        footerOpctional={!enabled_}    
        onConfirm={(event:any) => {            
            let isContinue = true;
            if(props.onConfirme!=null){ isContinue = props.onConfirme(event) ?? true; }
            if(isContinue === false) { return ;}
            setShowDialog(false)
        }}
        loading={loading}
        disabled={false}
        // hideFooter={successCancel}
    ><Form.Row key={uuid()} className="row-custom">{props.children}</Form.Row>
    </ReasonModal>
    {(props.isButtonType === 'normal' && enabled_? 
        <Form.Row className="row-custom button-position custom-legend-buttons">  
            <Button             
            title={props?.label ?? 'Editar'}
            contained={true}
            type="button"
            onClick={tiggerOnClick} />
        </Form.Row>
        : (props.isButtonType === 'config' ?
        <EditButtons.ConfigButton disable={!enabled_} tooltip={props?.label ?? 'Editar'}  onClick={tiggerOnClick} />  
        : (props.isButtonType === 'add' ?
        <EditButtons.InsertButton disable={!enabled_} tooltip={props?.label ?? '+ Adicionar'}  onClick={tiggerOnClick} />  
        : (props.isButtonType === 'custom' && props.isButtonCustom ? 
        <>{props.isButtonCustom(tiggerOnClick)}</>
        :''))))
    }
    </>
}



interface DialogPopUpProps{
    showMe          : boolean;
    form?           : any;
    dlgTitle?       : string | any;
    className       ?:  string;
    onClose         ?:  () => void;
    children?	    : any;

    width50?        : boolean;
    width25?        : boolean;

    name?           : string;
    buttons         ?: any;

    // 
	//     
    // 
    // children?	    : any;
    // enabled?	    :boolean;
	// key?		    : any;
    // onConfirme?     : any;
    // onClose?        : any;
    // show            : boolean;
    // positionTopAlign?: boolean;
    
}
export function DialogPopUp(props: DialogPopUpProps)
{
    const [showMe,          setShowMe]          = React.useState<boolean>(false);
    const [loadingSearch,   setLoadingSearch]   = React.useState<boolean>(false);
    const [listItems,       setListItems]       = React.useState<any[]>([]);
    const [formUid,         setFormUid]         = React.useState<string>(uuid());
    const [selecionando,    setSelecionando]    = React.useState<boolean>(false);

    React.useEffect(() => {
        setShowMe(props.showMe);
    },[props.showMe]);

    
    return (
        <Bootstrap.Modal
            show={showMe}
            key={`${formUid}-modal-dlg`}
            onHide={()=>{setShowMe(false); if(props.onClose) {props.onClose()}  }}
            animation={true}
            className={`SearchBtnDialog ${props.className}`}     
            style={{minWidth: '60%'}}       
        >
        <Bootstrap.Modal.Header closeButton>
        <Bootstrap.Modal.Title>
                {props.dlgTitle}
        </Bootstrap.Modal.Title>
        </Bootstrap.Modal.Header>             
        <Bootstrap.Modal.Body>
            <Bootstrap.Form.Group>
                {props.children}
            </Bootstrap.Form.Group>
        </Bootstrap.Modal.Body>       
        </Bootstrap.Modal>
    )    
}