import React, { useState } from 'react';
import './styles.css';
import { colors } from '../util/colors';
import LoadingRequest from './LoadingRequest';

interface Props {
	title: string;
	onClick?: any;
	type: any;
	contained: boolean;
	isLoading?: boolean;
	name?: string;
	disabled?: boolean;
	className?: string;
}

function MyButton({ title, type, onClick, contained, isLoading, name, disabled, className }: Props) {
	const [mouseOver, setMouseOver] = useState<boolean>(false);

	const containedStyle = {
		backgroundColor: colors.green5,
		color: colors.white,
	};
	const containedHover = {
		backgroundColor: colors.green4,
		color: colors.white,
	};
	const outlinedStyle = {
		border: `1.5px solid ${colors.green5}`,
		color: colors.green5,
	};
	const outlinedHover = {
		border: `1.5px solid ${colors.green4}`,
		color: colors.green4,
	};

	const disabledStyle = {
		color: colors.gray2
	}
	let nClass = (className ?? ""); 

	return (
		<>
			{contained ? (
				<button
					className={"btn-default " + nClass}
					onClick={onClick}
					type={type}
					style={disabled ? disabledStyle : mouseOver ? containedHover : containedStyle}
					onMouseOver={() => setMouseOver(true)}
					onMouseOut={() => setMouseOver(false)}
					name={name}
					disabled={disabled}
				>
					{isLoading ?
						<LoadingRequest />
						:
						title
					}
				</button>
			) : (
				<button
					className={"btn-default transparent-btn " + nClass}
					onClick={onClick}
					type={type}
					style={mouseOver ? outlinedHover : outlinedStyle}
					onMouseOver={() => setMouseOver(true)}
					onMouseOut={() => setMouseOver(false)}>
					{title}
				</button>
			)}
		</>
	);
}

export default MyButton;
