import FileUpload from "../../../components/form/FileUpload";
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../components/Button';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import Loading from '../../../components/Loading';
import { STRINGS } from '../../../util/strings';
import { STRINGS_CQB } from "../../../util/cqb/string";
import { CQBInstalacoesImagensDto, CqbLocal, StateType } from "../../../models/modelsCQB";
import ConfirmationModal from '../../../components/ConfirmationModal';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import api from '../../../services/api';
import '../styles.scss';
import RichText from "../../../components/form/RichText";

export default function Local() {
    const [entity, setEntity] = useState<CqbLocal>();
    const history = useHistory();
    const location = useLocation<StateType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [errorGetData, setErrorGetData] = useState<any>(null);
    const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
    const [lblArquivoCroquis, setLblArquivoCroquis] = useState('Escolher arquivo');
    const [lblArquivoMapaAreaCredenciada, setLblArquivoMapaAreaCredenciada] = useState('Escolher arquivo');
    const [lblArquivoMapaUnidadesConservacao, setLblArquivoMapaUnidadesConservacao] = useState('Escolher arquivo');
    const [selectedImagens, setSelectedImagens] = useState<CQBInstalacoesImagensDto[]>([]);


    const localSchema = yup.object().shape({
        //nome: yup
        //    .string()
        //    .required(STRINGS.FORM_FIELD_REQUIRED),
        //ativo: yup
        //    .boolean()
    })

    const { register, errors, handleSubmit, getValues, reset, formState: { isDirty } } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(localSchema)
    });

    useEffect(() => {
        const getLocalData = async () => {
            if (location.state && location.state.id) {
                try {
                    const { data } = await api.get<CqbLocal>(`api/v1/cqb/local/${location.state.id}`);

                    setEntity(data);

                    reset({
                        ativo: data.ativo,
                        CEP: data.cep,
                        cnpj: data.cnpj,
                        cultivosVizinhos: data.cultivosVizinhos,
                        dadosClimaticos: data.dadosClimaticos,
                        dadosPedologicos: data.dadosPedologicos,
                        email: data.email,
                        endereco: data.endereço,
                        id: data.id,
                        localExecucao: data.localExecucao,
                        municipio: data.municipio,
                        nomeDaFazenda: data.nomeDaFazenda,
                        nomePessoaResponsavel: data.nomePessoaResponsavel,
                        nomeProprietario: data.nomeProprietario,
                        pais: data.pais,
                        parecerTecnico: data.parecerTecnico,
                        taX_ID_VAT: data.taX_ID_VAT,
                        telefone: data.telefone,
                        tiposBiomaVegetacao: data.tiposBiomaVegetacao,
                        imagemCroquisFileName: data.imagemCroquisFileName ,
                        imagemCroquisPath: data.imagemCroquisPath ,
                        imagemMapaAreaFileName: data.imagemMapaAreaFileName ,
                        imagemMapaAreaPath: data.imagemMapaAreaPath ,
                        imagemMapaUnidadeConservacaoFileName: data.imagemMapaUnidadeConservacaoFileName ,
                        imagemMapaUnidadeConservacaoPath: data.imagemMapaUnidadeConservacaoPath,
                        uf: data.uf


                    })
                } catch (error: any) {
                    setErrorGetData(error?.response.data);
                }
                setLoading(false);
            }
        }
        getLocalData();
    }, [location.state, reset])

    const verifyErrorsOnForm = (): string[] => {
        let errorsForm: any[] = [];

        if (!isEmptyObj(errors)) {
            Object.entries(errors).map(err => errorsForm.push(err[0]));
        }
        return errorsForm;
    }

    const getValuesToSubmit = () => {
        return {
            id: location.state.id,
            ativo: getValues('ativo'),
            CEP: getValues('CEP'),
            cnpj: getValues('cnpj'),
            cultivosVizinhos: getValues('cultivosVizinhos'),
            dadosClimaticos: getValues('dadosClimaticos'),
            dadosPedologicos: getValues('dadosPedologicos'),
            email: getValues('email'),
            endereço: getValues('endereco'),
            localExecucao: getValues('localExecucao'),
            municipio: getValues('municipio'),
            nomeDaFazenda: getValues('nomeDaFazenda'),
            nomePessoaResponsavel: getValues('nomePessoaResponsavel'),
            nomeProprietario: getValues('nomeProprietario'),
            pais: getValues('pais'),
            parecerTecnico: getValues('parecerTecnico'),
            taX_ID_VAT: getValues('taX_ID_VAT'),
            telefone: getValues('telefone'),
            tiposBiomaVegetacao: getValues('tiposBiomaVegetacao'),
            uf: getValues('uf'),
            imagemCroquisFileName: lblArquivoCroquis,
            imagemCroquisPath: lblArquivoCroquis,
            imagemMapaAreaFileName: lblArquivoMapaAreaCredenciada,
            imagemMapaAreaPath: lblArquivoMapaAreaCredenciada,
            imagemMapaUnidadeConservacaoFileName: lblArquivoMapaUnidadesConservacao,
            imagemMapaUnidadeConservacaoPath: lblArquivoMapaUnidadesConservacao,

            //Imagens: expectedImagens(),
        };
    }

    const onSubmit = async (local: CqbLocal) => {
        setLoadingSubmit(true);
        setError(null);

        try {
            let saveApi = api.post;
            let itemID = location?.state?.id ?? 0;

            if (itemID !== 0) saveApi = api.put;

            let values = getValuesToSubmit();

            const { status } = await saveApi('api/v1/cqb/cqblocal', values);

            if (status === 200 || status === 201) {
                setSuccess(true);

                setTimeout(() => {
                    history.goBack();
                }, 3500);
            }
        } catch (error: any) {
            console.error("error = ", error)
            setError(error?.response.data);
        }

        setLoadingSubmit(false);
    }

    return (
        <div className="container container-fluid">
            <div className="search-content-filters">
                <If condition={verifyErrorsOnForm()}>
                    {verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
                </If>
                <If condition={success && !error}>
                    <AlertSuccess
                        message={STRINGS.ALERT_MESSAGE_SUCCESS_EDIT}
                    />
                </If>
                <If condition={!success && error !== null}>
                    {error !== null && error.message.length > 0 ? error.message.map((errorE: string) => <AlertError message={errorE} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
                </If>
                <h3>{STRINGS_CQB.EDITION_LOCAL}</h3>
                <If condition={loading}>
                    <span className="loading-page">
                        <Loading />
                    </span>
                </If>
                <If condition={!loading && !errorGetData}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row className="row-custom">
                            &nbsp;&nbsp;
                            <Form.Group className="checkbox-forms">
                                <Form.Check
                                    type="checkbox"
                                    ref={register}
                                    name="ativo"
                                    label="Ativa?"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={8}>
                                <Form.Label>
                                    Nome da Fazenda<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="nomeDaFazenda"
                                    type="text"
                                    placeholder="Insira o nome da fazenda"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    CNPJ da Fazenda<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="cnpj"
                                    type="text"
                                    placeholder="Insira o CNPJ da fazenda"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={6}>
                                <Form.Label>
                                    Nome do Proprietário<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="nomeProprietario"
                                    type="text"
                                    placeholder="Insira o nome do proprietário"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                                <Form.Label>
                                    Nome do Técnico Principal<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="nomePessoaResponsavel"
                                    type="text"
                                    placeholder="Insira o nome do técnico principal"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={6}>
                                <Form.Label>
                                    Telefone<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="telefone"
                                    type="text"
                                    placeholder="Insira o telefone"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                                <Form.Label>
                                    E-Mail<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="email"
                                    type="text"
                                    placeholder="Insira o e-mail"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={8}>
                                <Form.Label>
                                    Endereço<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="endereco"
                                    type="text"
                                    placeholder="Insira o endereço"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    Município<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="municipio"
                                    type="text"
                                    placeholder="Insira o município"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    CEP<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="CEP"
                                    type="text"
                                    placeholder="Insira o CEP"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    UF<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="uf"
                                    type="text"
                                    placeholder="Insira a Unidade Federativa"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    País<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="pais"
                                    type="text"
                                    placeholder="Insira o país"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={60}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={12}>
                                <Form.Label>
                                    Anexar arquivo Croquis
                                </Form.Label>
                                <FileUpload layoutColumns={12}
                                    name="imagemCroquisFileName"
                                    otherName="imagemCroquisFileName"
                                    label=""
                                    text={entity?.imagemCroquisFileName} textButton="Inserir Arquivo"
                                    onChange={(target: any, e: any) => {
                                        setLblArquivoCroquis(e);
                                    }}
                                    accept="image/png, image/jpeg"
                                />
                            </Form.Group>
                        </Form.Row>


                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={12}>
                                <Form.Label>
                                    Cultivos Vizinhos
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="cultivosVizinhos"
                                    as="textarea"
                                    rows={6}
                                    placeholder="Insira os cultivos vizinhos"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={12}>
                                <Form.Label>
                                    Tipo do Bioma e Vegetação
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="tiposBiomaVegetacao"
                                    as="textarea"
                                    rows={6}
                                    placeholder="Insira o tipo do bioma e vegetação"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={12}>
                                <Form.Label>
                                    Tipo de Clima
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="dadosClimaticos"
                                    as="textarea"
                                    rows={6}
                                    placeholder="Insira o tipo de clima"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={12}>
                                <Form.Label>
                                    Dados Pedológicos (solo)
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="dadosPedologicos"
                                    as="textarea"
                                    rows={6}
                                    placeholder="Insira os dados pedológicos (solo)"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    Parecer Técnico
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="parecerTecnico"
                                    as="textarea"
                                    rows={6}
                                    placeholder="Insira os dados pedológicos (solo)"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    Tax ID
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="taX_ID_VAT"
                                    as="textarea"
                                    rows={6}
                                    placeholder=""
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    Local Execução
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="localExecucao"
                                    as="textarea"
                                    rows={6}
                                    placeholder=""
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={12}>
                                <Form.Label>
                                    Anexar arquivo Mapa da área a ser credenciada
                                </Form.Label>
                                <FileUpload layoutColumns={12}
                                    name="imagemMapaAreaFileName"
                                    otherName="imagemMapaAreaFileName"
                                    label=""
                                    text={entity?.imagemMapaAreaFileName} textButton="Inserir Arquivo"
                                    onChange={(target: any, e: any) => {
                                        setLblArquivoMapaAreaCredenciada(e);
                                    }}
                                    accept="image/png, image/jpeg"
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row className="row-custom">
                            <Form.Group as={Col} md={12}>
                                <Form.Label>
                                    Anexar arquivo Mapa da área experimental e as Unidades de Conservação
                                </Form.Label>
                                <FileUpload layoutColumns={12}
                                    name="imagemMapaUnidadeConservacaoFileName"
                                    otherName="imagemMapaUnidadeConservacaoFileName"
                                    label=""
                                    text={entity?.imagemMapaUnidadeConservacaoFileName} textButton="Inserir Arquivo"
                                    onChange={(target: any, e: any) => {
                                        setLblArquivoMapaUnidadesConservacao(e);
                                    }}
                                    accept="image/png, image/jpeg"
                                />
                            </Form.Group>
                        </Form.Row>

                        <div className="button-position">
                            <Button
                                title="Cancelar"
                                type="button"
                                contained={false}
                                onClick={() => {
                                    isDirty ? setIsConfirmation(true) :
                                        history.goBack();
                                }}
                            />
                            <Button
                                title="Salvar"
                                type="submit"
                                contained={true}
                                isLoading={loadingSubmit}
                            />
                        </div>
                    </Form>
                </If>
                <If condition={isConfirmation}>
                    <ConfirmationModal
                        show={isConfirmation}
                        confirmationMsg={STRINGS_CQB.CONFIRM_LEFT_EDITION}
                        cancelMsg={STRINGS_CQB.CANCEL_LEFT_EDITION}
                        onConfirm={() => history.goBack()}
                        onHide={() => setIsConfirmation(false)}
                        title={STRINGS_CQB.EDITION_TITLE_CONFIRMATION}
                        type="button"
                        variant="danger"
                    >
                        {STRINGS_CQB.EDITION_REGISTER_CONFIRMATION_CANCEL}
                    </ConfirmationModal>
                </If>
            </div>
        </div>
    );

}
