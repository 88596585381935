import React, { useState, useEffect, useContext, Fragment } from "react";
import { STRINGS } from "../../util/strings";
import ConfirmationModal from "../../components/ConfirmationModal";

interface LPMADialogsProps{
    ShowConfirmeSendRegulatorio?: boolean;
    onConfirmeSendRegulatorio?  : any;
    onHide? : any;
}
export interface LpmaDialogShow extends LPMADialogsProps{
    ShowConfirmeSendRegulatorio?: boolean;
    onConfirmeSendRegulatorio?  : any;
}
export default function LpmaDialogs (props: LPMADialogsProps)
{
    const [sendRegulatorio, setSendRegulatorio] = useState<boolean>(false);

    useEffect(() => {
        setSendRegulatorio(props.ShowConfirmeSendRegulatorio === true);
    }, [props.ShowConfirmeSendRegulatorio]);

    return (
        <ConfirmationModal
				show={sendRegulatorio}
				onHide={() => {setSendRegulatorio(false); if(props.onHide){props.onHide();} }}
				title={STRINGS.REGULATORY_TITLE}
				confirmationMsg={STRINGS.REGULATORY_ACTION}
				onConfirm={()=>{
                    if(props.onConfirmeSendRegulatorio){props.onConfirmeSendRegulatorio();}
                    if(props.onHide){props.onHide();} 
                }}
				// loading={isLoadingScreen('submit')}
				variant="success"
				color="white"
			>
				<p>{STRINGS.REGULATORY_MESSAGE}</p>
			</ConfirmationModal>
    );
}

