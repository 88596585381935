import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
	//BrowserRouter as Router,
	useParams,
	useHistory,
	Redirect
} from "react-router-dom";
import { Form, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Context } from '../../../context/AuthContext'
import { DecodedToken } from '../../../models/user';
import jwtDecode from 'jwt-decode';

import NavigationMessage, { MessageContent, MessageType } from "../../../components/NavigationMessages";
import { STRINGS_LPMA } from "../../../util/lpma/string";

import api, { apiFileDownload } from '../../../services/api';

import Loading from '../../../components/Loading';
import Button from "../../../components/Button";

import { useLocation } from "react-router-dom";
import {
	LpmaRN35,
	Lpma_RN35_Comunicados,
	Lpma_RN35_Comunicados_Datas,
	Lpma_RN35_Comunicados_Areas,
	Lpma_RN35_Comunicados_Materiais,
	Lpma_RN35_Comunicados_Materiais_Aprovados,
	Lpma_RN35_Comunicados_Materiais_Plantados,

	OficioCIBIO,
	LpmaRN35Ensaio,
	LpmaRN35Areas
} from "../../../models/modelsLPMA";

import LpmaService, { RolesService } from "../services";

import "./../styles.scss";
import { listEnumItem, ParamsType, StateType } from "../../../models/modelCommum";

import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';


export default function Comunicados() {
	const { token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);

	const [entityLPMA, setEntityLPMA] = useState<LpmaRN35>();
	const [areas, setAreas] = useState<LpmaRN35Areas[]>([]);
	const [ensaios, setEnsaios] = useState<LpmaRN35Ensaio[]>([]);
	const [loadingRequestData, setLoadingRequestData] = useState<boolean>(true);

	const LpmaServiceApi = new LpmaService();
	const LpmaRoles = new RolesService(useContext);

	const UrlParam = useParams<ParamsType>();
	const formRef = useRef<HTMLFormElement>(null);

	const GoBackToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }) }

	const location = useLocation<StateType>();
	const history = useHistory();

	const [titleForm, setTitleForm] = useState<string>(`${STRINGS_LPMA.NEW_LPMA_RNCOMUNICADOS} RN35`);

	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [successSubmit, setSuccessSubmit] = useState<boolean>(false);


	//Page notification
	const [pageMessages, setPageMessages] = useState<MessageContent[]>([]);
	//const hasPageMessages = () : boolean => {return pageMessages.length > 0;}
	const clearPageMessages = async () => { await setPageMessages([]); }
	const addPageMessages = (message: MessageContent, clearMessages: boolean = true) => {
		let currentPageMessages: MessageContent[] = !clearMessages ? pageMessages : [];
		setPageMessages([]);
		currentPageMessages.push(message);
		setPageMessages(currentPageMessages);
	}
	const setSuccessMessageAndRedirect = (message: string, callback: any) => {
		//setStatus(statuses.idle);
		addPageMessages({ type: MessageType.success, content: message });
		GoBackToTop();
		setTimeout(() => { callback(); }, 3500);
	}

	//Loading screen
	const [loading, setLoading] = useState<string[]>([]);
	const setLoadingScreen = (tag: string, type: boolean = true) => {

		let currentLoading = loading;
		let index = currentLoading.indexOf(tag);

		if (type) {
			if (index >= 0)
				return;

			currentLoading.push(tag);
		}
		else {
			if (index <= -1)
				return;

			currentLoading.splice(index, 1);
		}

		setLoading([]);
		setLoading(currentLoading);
	}



	const defaultForm = useForm({

	});


	const setFormData = (dadosComunicados: any, dadosLPMA: any) => {
		defaultForm.reset({
			id: dadosComunicados.id,
			dataCriacao: moment(dadosComunicados.dataCriacao).toDate(),
			usuarioCriacao: dadosComunicados.usuarioCriacao,

			descricaoLPMA: dadosLPMA.titulo,

			areas: dadosLPMA.areas,
			ensaios: dadosLPMA.ensaios,
		});

		setEntityLPMA(dadosLPMA);

		var teste = "";
	}

	useEffect(() => {

		clearPageMessages();

		const getEntity = async () => {

			setLoadingScreen('get_data_form');
			let responseLPMA = await api.get<LpmaRN35>(`api/v1/lpma/rn35/${UrlParam.id}`);
			setEntityLPMA(responseLPMA.data);

			try {
				let responseComunicados = await api.get<OficioCIBIO>(`api/v1/lpmacomunicadosrn35/${UrlParam.id}`);

				if (responseComunicados.status === 200) {
					history.push('/lpma/rn35/editarcomunicados/' + responseLPMA.data.id);
				}
			}
			catch (error: any) {
				console.error(error);
				GoBackToTop();
			}

			setLoadingScreen('get_data_entity');

		};

		getEntity();
		setLoadingRequestData(false);

	}, [location.state, defaultForm.reset]);

	const setValuesToSubmit = () => {

		let novoComunicado =
		{
			id: 0,
			lpma_id: entityLPMA?.id,
			usuarioCriacao: decoded.unique_name,
			dataCriacao: undefined,
			lpma_RN35ID: entityLPMA?.id,

			datas: setDatasNovoComunicado(),

			areas: setAreasNovoComunicado(),

			//materiais: setMateriaisNovoComunicado(),

			//materiaisAprovados: setMateriaisAprovadosNovoComunicado(),

			//materiaisPlantados: setMateriaisPlantadosNovoComunicado(),

		}


		return novoComunicado;
	}

	const setDatasNovoComunicado = () => {
		var datasNovoComunicado: Lpma_RN35_Comunicados_Datas[] = [];

		for (var indexArea = 0, limiteAreas = entityLPMA!.areas!.length; indexArea < limiteAreas; indexArea++) {
			var area = entityLPMA!.areas?.[indexArea];
			var tamanhoAreaEnsaio = 1;

			for (var indexEnsaio = 0, limiteEnsaios = tamanhoAreaEnsaio; indexEnsaio < limiteEnsaios; indexEnsaio++) {
				var AreaEnsaioID = area!.area_Ensaio?.[indexEnsaio].id;

				let novaData =
				{
					id: 0,
					Comunicado_id: 0,
					Area_Ensaio_id: AreaEnsaioID,
					Epoca: 1,
					periodoMonitoramento: 0,
					dataInicioPlantio: undefined,
					dataTerminoPlantio: undefined,
					dataInicioColheitaDestruicao: undefined,
					dataTerminoColheitaDestruicao: undefined,
					dataInicioMonitoramento: undefined,
					dataPrevisaoTerminoMonitoramento: undefined,
					dataTerminoMonitoramento: undefined,
					dataSubmissaoReleatorioConclusivo: undefined,
					dataPublicaoDOU: undefined
				}

				datasNovoComunicado.push(novaData);

			}
		}

		return datasNovoComunicado;
	}

	const setAreasNovoComunicado = () => {
		var areasNovoComunicado: Lpma_RN35_Comunicados_Areas[] = [];

		for (var indexArea = 0, limiteAreas = entityLPMA!.areas!.length; indexArea < limiteAreas; indexArea++) {
			var area = entityLPMA!.areas?.[indexArea];

			let novaArea =
			{
				id: 0,
				Comunicado_id: 0,
				Area_id: area?.id,
				areaAprovadaX: 0,
				areaAprovadaY: 0,
				areaPlantadaX: 0,
				areaPlantadaY: 0,
				observacoes: '',
			}

			areasNovoComunicado.push(novaArea);
		}

		return areasNovoComunicado;
	}

	const setMateriaisNovoComunicado = () => {
		var materiaisNovoComunicado: Lpma_RN35_Comunicados_Materiais[] = [];

		for (var indexArea = 0, limiteAreas = entityLPMA!.areas!.length; indexArea < limiteAreas; indexArea++) {
			var area = entityLPMA!.areas?.[indexArea];
			var tamanhoAreaEnsaio = 1;

			for (var indexEnsaio = 0, limiteEnsaios = tamanhoAreaEnsaio; indexEnsaio < limiteEnsaios; indexEnsaio++) {
				var AreaEnsaioID = area!.area_Ensaio?.[indexEnsaio].id;

				let novoMaterial =
				{
					id: 0,
					Comunicado_id: 0,
					Area_Ensaio_id: AreaEnsaioID,
					Material: '',
					numeroEntradas: 0,
					numeroRepeticoes: 0,
					numeroTratamento: 0,
				}

				materiaisNovoComunicado.push(novoMaterial);

			}
		}

		return materiaisNovoComunicado;
	}

	const setMateriaisAprovadosNovoComunicado = () => {
		var materiaisAprovadosNovoComunicado: Lpma_RN35_Comunicados_Materiais_Aprovados[] = [];

		for (var indexArea = 0, limiteAreas = entityLPMA!.areas!.length; indexArea < limiteAreas; indexArea++) {
			var area = entityLPMA!.areas?.[indexArea];
			var tamanhoAreaEnsaio = 1;

			for (var indexEnsaio = 0, limiteEnsaios = tamanhoAreaEnsaio; indexEnsaio < limiteEnsaios; indexEnsaio++) {
				var AreaEnsaioID = area!.area_Ensaio?.[indexEnsaio].id;

				let novoMaterialAprovado =
				{
					id: 0,
					Comunicado_id: 0,
					Area_Ensaio_id: AreaEnsaioID,
					Material: '',
					numeroEntradas: 0,
					numeroRepeticoes: 0,
					numeroTratamento: 0,
					totalParcelas: 0,
					areaParcela: 0,
					areaTotal: 0,
				};

				materiaisAprovadosNovoComunicado.push(novoMaterialAprovado);
			}
		}

		return materiaisAprovadosNovoComunicado;
	}

	const setMateriaisPlantadosNovoComunicado = () => {
		var materiaisPlantadosNovoComunicado: Lpma_RN35_Comunicados_Materiais_Plantados[] = [];

		for (var indexArea = 0, limiteAreas = entityLPMA!.areas!.length; indexArea < limiteAreas; indexArea++) {
			var area = entityLPMA!.areas?.[indexArea];
			var tamanhoAreaEnsaio = 1;

			for (var indexEnsaio = 0, limiteEnsaios = tamanhoAreaEnsaio; indexEnsaio < limiteEnsaios; indexEnsaio++) {
				var AreaEnsaioID = area!.area_Ensaio?.[indexEnsaio].id;

				let novoMaterialAprovado =
				{
					id: 0,
					Comunicado_id: 0,
					Area_Ensaio_id: AreaEnsaioID,
					Material: '',
					numeroEntradas: 0,
					numeroRepeticoes: 0,
					numeroTratamento: 0,
					totalParcelas: 0,
					areaParcela: 0,
					areaTotal: 0,
				};

				materiaisPlantadosNovoComunicado.push(novoMaterialAprovado);
			}
		}

		return materiaisPlantadosNovoComunicado;
	}



	const createComunicados = async () => {
		setErrorSubmit(null);
		setLoadingSubmit(true);

		try {
			const novoComunicado = setValuesToSubmit();
			const { data, status } = await api.post('api/v1/lpmacomunicadosrn35/criarcomunicado', novoComunicado)

			if (status === 200) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setSuccessSubmit(true);
				var link = '/lpma/rn35/editarcomunicados/' + UrlParam.id;
				setTimeout(() => {
					history.push(link);
				}, 3000);
				//novoComunicado.lpma_id
			}
		}
		catch (error: any) {
			setErrorSubmit(error.response.data);
			setLoadingSubmit(false);
		}
		setLoadingSubmit(false);
	}

	const submitForm = async () => {
		var submit = true;
		var Teste = true;
	}

	const parseButton = () => {
		let btnCancelar = <Button
			title="Voltar"
			contained={false}
			type="button"
			onClick={() => {
				history.goBack();
			}}
		/>;

		let btnCriar = <Button
			title="Criar Comunicados"
			contained
			type="button"
			onClick={() => createComunicados()}
		/>;



		return <div className="button-position">
			{btnCancelar}
			{btnCriar}
		</div>;
	}

	const errosHandler = (errData: any) => {
		console.error(errData);
	};

	return (
		<div className="container container-fluid lpma">
			<If condition={loadingRequestData}>
				<span className="loading-page">
					<Loading />
				</span>
			</If>
			<NavigationMessage messages={pageMessages} />
			<If condition={successSubmit && !errorSubmit}>
				<AlertSuccess
					message={STRINGS_LPMA.CREATE_COMUNICADO_SUCESS}
				/>
			</If>
			<If condition={!successSubmit && errorSubmit !== null}>
				{errorSubmit !== null && errorSubmit.message.length > 0 ? errorSubmit.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS_LPMA.CREATE_COMUNICADO_ERROR} />}
			</If>
			<Form ref={formRef} onSubmit={defaultForm.handleSubmit(submitForm, errosHandler)}>
				<h3>{titleForm}</h3>
				Deseja criar os comunicados para a LPMA RN35?
				{parseButton()}

			</Form>
		</div>
	);
}