import React from 'react';
import * as Bootstrap from 'react-bootstrap';

type ModalSize = 'sm' | 'lg' | 'xl';

interface Props
{
	show						: boolean;
	animated?					: boolean;
	size?						: ModalSize;
	centered?					: boolean;
	title						: string | JSX.Element;
	onHide?						: {() : any};
}

export default class Modal extends React.Component<Props>
{
	public static defaultProps = {
		animated				: false,
		size					: 'lg',
		centered				: false,
		onHide					: () => {},
	};

	render ()
	{
		return <Bootstrap.Modal
			show={this.props.show}
			animation={this.props.animated}
			onHide={this.props.onHide}
			size={this.props.size}
			centered={this.props.centered}
		>
			<Bootstrap.Modal.Header closeButton>
				<Bootstrap.Modal.Title>
					<h3>{this.props.title}</h3>
				</Bootstrap.Modal.Title>
			</Bootstrap.Modal.Header>
			<Bootstrap.Modal.Body>
				{this.props.children}
			</Bootstrap.Modal.Body>
		</Bootstrap.Modal>;
	}
}