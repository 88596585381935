import React, { useState, useEffect, useReducer, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { FiX, FiCheck } from 'react-icons/fi';
import {
	CultureForm,
	CultureResults,
	DescriptorsForm,
	DiseaseForm,
	RegionForm,
	DescriptorsTableResults,
	DiseaseTableResults,
	RecordTypesResults,
	RegionsTableResults
} from '../../../../models/models';
import Button from '../../../../components/Button';
import AddButton from '../../../../components/AddButton';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';
import If from '../../../../components/If';
import AlertError from '../../../../components/AlertError';
import AlertSuccess from '../../../../components/AlertSuccess';
import ResponseError from '../../../../components/ResponseError';
import Modal from '../../../../components/Modal';
import LoadingInForm from '../../../../components/LoadingInForm';
import { STRINGS } from '../../../../util/strings';
import Fieldset from '../../../../components/Fieldset';
import Toggle from '../../../../components/form/Toggle/Toggle';
import api, { apiFile } from '../../../../services/api';
import { statusToText } from '../../../../functions/formatStatus';
import { modelosDeTiposdeRegistro } from '../../../../util/tiposDeRegistro.json';
import '../../styles_add.css';

interface RecordsTypeFiles {
	idTipoRegistro: number;
	modeloTipoDeRegistro: string;
	arquivo: any;
	nomeArquivo: string;
	tipoDeRegistroNome: string;
	nomeModelo?: string;
}

interface ModelRegisterType {
	id: number;
	modelo: string;
	label: string;
}

export default function Culture ()
{
	const history = useHistory();
	const [cultureResult, setCultureResult] = useState<CultureResults>();
	const [recordsType, setRecordsType] = useState<Array<RecordTypesResults>>([]);
	const [loadingRecordsType, setLoadingRecordsType] = useState<boolean>(true);
	const [loadingCulture, setLoadingCulture] = useState<boolean>(false);
	const [loadingFilesRegister, setLoadingFilesRegister] = useState<boolean>(false);
	const [loadingFilesProtection, setLoadingFilesProtection] = useState<boolean>(false);
	const [successfulResponseCulture, setSuccessfulResponseCulture] = useState<boolean>(false);
	const [successfulResponseRecordsType, setSuccessfulResponseRecordTypes] = useState<boolean>(false);
	const [successfulResponseProtection, setSuccessfulResponseProtection] = useState<boolean>(false);
	const [errorCulture, setErrorCulture] = useState<any>(null);
	const [errorFiles, setErrorFiles] = useState<boolean>(false);
	const [errorFilesProtection, setErrorFilesProtection] = useState<boolean>(false);
	const [errorRecordsType, setErrorRecordsType] = useState<boolean>(false);
	const [showDescriptors, setShowDescriptores] = useState<boolean>(false);
	const [showDisease, setShowDisease] = useState<boolean>(false);
	const [showRegion, setShowRegion] = useState<boolean>(false);
	const [selectedDescriptors, setSelectedDescriptors] = useState<DescriptorsTableResults>();
	const [selectedDisease, setSelectedDisease] = useState<DiseaseTableResults>();
	const [descriptors, setDescriptors] = useState<Array<DescriptorsForm>>([]);
	const [tempCharacteristicList, setTempCharacteristicList] = useState<Array<any>>([]);
	const [diseases, setDiseases] = useState<Array<DiseaseForm>>([]);
	const [tempReactionsList, setTempReactionsList] = useState<Array<any>>([]);
	const [regions, setRegions] = useState<Array<RegionsTableResults>>([]);
	const [lblFile, setLblFile] = useState('Escolher arquivo');
	const [lblDescriptors, setLblDescriptors] = useState('Escolher arquivo');
	const [lblReport, setLblReport] = useState('Escolher arquivo');
	const [files, setFiles] = useState<Array<RecordsTypeFiles>>([]);
	const [registerTypeName, setRegisterTypeName] = useState('');
	const [modelRegisterType, setModelRegisterType] = useState('');
	const [avaliacaoCulivarAsText, setAvaliacaoCulivarAsText] = useState<boolean>(false);
	const [cicloAgronomicoAsText, setCicloAgronomicoAsText] = useState<boolean>(false);

	var formDataProtection = new FormData();

	const isDescriptorDescription = showDescriptors ? {
		descriptorDescription: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		descriptorDescription: yup
			.string()
	}

	const isDescriptorCharacteristic = showDescriptors ? {
		descriptorCharacteristic: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		descriptorCharacteristic: yup
			.string()
	}

	const isDiseaseDescription = showDisease ? {
		diseaseDescription: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		diseaseDescription: yup
			.string()
	}

	const isDiseaseReaction = showDisease ? {
		diseaseReaction: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		diseaseReaction: yup
			.string()
	}

	const isRegionName = showRegion ? {
		regionName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		regionName: yup
			.string()
	}

	const cultureSchema = yup.object().shape({
		name: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		description: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		...isDescriptorDescription,
		...isDescriptorCharacteristic,
		descriptorStatus: yup
			.boolean(),
		...isDiseaseDescription,
		...isDiseaseReaction,
		diseaseStatus: yup
			.boolean(),
		...isRegionName,
		regionStatus: yup
			.boolean(),
		registerType: yup
			.number(),
		modelRegisterType: yup
			.string(),
		active: yup
			.boolean(),
		modelCultivarRate: yup
			.mixed(),
		modelCycleAndCharacteristics: yup
			.mixed(),
		modelProductivity: yup
			.mixed(),
		modelQuality: yup
			.mixed(),
		modelTechnicalReport: yup
			.mixed(),
		modelBreedersDeclaration: yup
			.mixed(),
		modelDescriptorsTableProtection: yup
			.mixed(),
		modelTechnicalReportProtection: yup
			.mixed(),
		//
		modelCultivarRateMatExp: yup
			.mixed(),
		modelCycleAndCharacteristicsMatExp: yup
			.mixed(),
		modelProductivityMatExp: yup
			.mixed(),
		modelQualityMatExp: yup
			.mixed(),
		modelTechnicalReportMatExp: yup
			.mixed(),
		modelBreedersDeclarationMatExp: yup
			.mixed(),
		modelCultivarRateLinPar: yup
			.mixed(),
		modelCycleAndCharacteristicsLinPar: yup
			.mixed(),
		modelProductivityLinPar: yup
			.mixed(),
		modelQualityLinPar: yup
			.mixed(),
		modelTechnicalReportLinPar: yup
			.mixed(),
		modelBreedersDeclarationLinPar: yup
			.mixed(),
	})

	const initialValues: CultureForm = {
		name: '',
		description: '',
		registerType: 0,
		modelRegisterType: '',
		modelCultivarRate: '',
		modelCycleAndCharacteristics: '',
		modelProductivity: '',
		modelQuality: '',
		modelTechnicalReport: '',
		modelBreedersDeclaration: '',
		active: true,
		descriptorDescription: '',
		descriptorCharacteristic: '',
		descriptorStatus: true,
		diseaseReaction: '',
		diseaseDescription: '',
		diseaseStatus: true,
		regionName: '',
		regionStatus: true,
		modelDescriptorsTableProtection: '',
		modelTechnicalReportProtection: '',
		modelBreedersDeclarationMatExp: '',
		modelCultivarRateMatExp: '',
		modelCycleAndCharacteristicsMatExp: '',
		modelProductivityMatExp: '',
		modelQualityMatExp: '',
		modelTechnicalReportMatExp: '',
		modelTechnicalReportLinPar: '',
		modelBreedersDeclarationLinPar: '',
		modelCultivarRateLinPar: '',
		modelCycleAndCharacteristicsLinPar: '',
		modelProductivityLinPar: '',
		modelQualityLinPar: ''
	}

	const { register, errors, handleSubmit, getValues, setValue, control } = useForm({
		defaultValues: initialValues,
		mode: 'onTouched',
		resolver: yupResolver(cultureSchema)
	})

	useEffect(() => {
		const getRegistersType = async () => {
			try {
				const { data } = await api.get<RecordTypesResults[]>('api/v1/cultivares/TipoRegistro/obterporfiltro?Ativo=true');
				setRecordsType(data);

			} catch (error:any) {
				setErrorRecordsType(true);
			}
			setLoadingRecordsType(false);
		}
		getRegistersType();
	}, [])

	const verifyErrorsOnForm = (): boolean => {
		if (errors.name || errors.description)
			return true;
		return false;
	}

	const onSubmitCulture = async (cultureData: CultureForm) => {
		setLoadingCulture(true);
		setErrorCulture(null);
		try {
			const { data, status } = await api.post('api/v1/cultivares/Cultura', {
				nome: cultureData.name,
				descricao: cultureData.description,
				descritores: descriptors,
				doencas: diseases,
				culturaRegioes: regions,
				avaliacaoCulivarAsText:avaliacaoCulivarAsText,
				cicloAgronomicoAsText:cicloAgronomicoAsText,
				ativo: cultureData.active
			});

			if (status === 201) {
				setSuccessfulResponseCulture(true);
				setCultureResult(data);

				if (files.length) {
					onSubmitFilesRegisters(data);
				}

				if (getValues('modelTechnicalReportProtection').length || getValues('modelDescriptorsTableProtection').length) {
					onSubmitFilesProtection(data);
				}
			}
		} catch (error:any) {
			setErrorCulture(error.response.data);
		}

		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}, 1000);

		setTimeout(() => {
			history.goBack();
		}, 3500);

		setLoadingCulture(false);
	}

	const onSubmitFilesRegisters = async (culture?: CultureResults) => {
		setErrorFiles(false);
		setLoadingFilesRegister(true);

		for (let count = 0, countLen = files.length; count < countLen; count++) {
			let formNew = new FormData()
			try {
				formNew.append(`${files[count].modeloTipoDeRegistro}`, files[count].arquivo);
				const { status } = await apiFile.post(`api/v1/cultivares/Cultura/${culture?.id}/modelosregistro/${files[count].idTipoRegistro}`, formNew);
				if (status === 200) {
					setSuccessfulResponseRecordTypes(true);
				}
			} catch (error:any) {
				setErrorFiles(true);
			}
		}
		setLoadingFilesRegister(false);
	}

	const onSubmitFilesProtection = async (culture: CultureResults) => {
		setErrorFilesProtection(false);
		setLoadingFilesProtection(true);
		let fileRelatorioTec = getValues('modelTechnicalReportProtection');
		let fileDescritores = getValues('modelDescriptorsTableProtection');

		try {
			formDataProtection.append('fileModeloRelatorioTecnicoObtencao', fileRelatorioTec[0]);
			formDataProtection.append('fileModeloTabelaDescritores', fileDescritores[0]);

			const { status } = await apiFile.post(`api/v1/cultivares/Cultura/${culture.id}/modelosprotecao`, formDataProtection);

			if (status === 200) {
				setSuccessfulResponseProtection(true);
			}
		} catch (error:any) {
			setErrorFilesProtection(true);
		}
		setLoadingFilesProtection(false);
	}

	const verifyErrorsOnFormDiseases = (): boolean => {
		if (errors.diseaseDescription)
			return true;
		return false;
	}

	const onRowSelectedDisease = ({ data, node }: any) => {
		showDiseasesResults(data.descricao, node);
	}

	const showDiseasesResults = (description: any, node: any) => {
		let arr: any[] = [];

		node.gridApi.forEachNode(({ data }: any) => arr.push(data));

		let results = arr.filter(((disease: any) => {
			return disease.descricao === description;
		}))

		let result: DiseaseTableResults = results[0];
		setSelectedDisease(result);
	}

	const actionsDiseases = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				props.api.startEditingCell({
					rowIndex: props.rowIndex,
					colKey: 'descricao',
				});
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setDiseases([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setDiseases([...arr]);
			}} />
		</Fragment>
	)

	const actionsModalDiseases = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				props.api.startEditingCell({
					rowIndex: props.rowIndex,
					colKey: 'descricao',
				});
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setTempReactionsList([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setTempReactionsList([...arr]);
			}} />
		</Fragment>
	)

	const onSubmitDiseases = () => {
		setDiseases([...diseases, {
			descricao: getValues('diseaseDescription'),
			reacoesDoencas: tempReactionsList,
			ativo: getValues('diseaseStatus'),
		}]);
	}

	const verifyErrorsOnFormDescriptors = (): boolean => {
		if (errors.descriptorDescription)
			return true;
		return false;
	}

	const onRowSelectedDescriptors = ({ data, node }: any) => {
		showDescriptorsResults(data.descricao, node);
	}

	const showDescriptorsResults = (description: any, node: any) => {
		let arr: any[] = [];

		node.gridApi.forEachNode(({ data }: any) => arr.push(data));

		let results = arr.filter(((descriptor: any) => {
			return descriptor.descricao === description;
		}))

		let result: DescriptorsTableResults = results[0];
		setSelectedDescriptors(result);
	}

	const actionsDescriptors = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				props.api.startEditingCell({
					rowIndex: props.rowIndex,
					colKey: 'descricao',
				});
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setDescriptors([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setDescriptors([...arr]);
			}} />
		</Fragment>
	)

	const actionsModalDescriptors = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				props.api.startEditingCell({
					rowIndex: props.rowIndex,
					colKey: 'descricao',
				});
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setTempCharacteristicList([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setTempCharacteristicList([...arr]);
			}} />
		</Fragment>
	)

	const onSubmitDescriptors = () => {
		setDescriptors([...descriptors, {
			descricao: getValues('descriptorDescription'),
			caracteristicasDescritores: tempCharacteristicList,
			ativo: getValues('descriptorStatus')
		}]);
	}

	const actionsRegions = (props: any) => (
		<Fragment>
			<DeleteButton onClick={() => {
				setRegions([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setRegions([...arr]);
			}} />
		</Fragment>
	)

	const verifyErrorsOnFormRegions = (): boolean => {
		if (errors.regionName)
			return true;
		return false;
	}

	const onSubmitRegions = () => {
		setRegions([...regions, {
			descricao: getValues('regionName'),
			ativo: getValues('regionStatus')
		}]);
	}

	const actionsFilesRegister = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setFiles([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setFiles([...arr]);
				}}
			/>
		)
	}

	const radioBtnDescriptors = (props: any) => {
		return (
			<div className="ngSelectionCell">
				<input onChange={() => onRowSelectedDescriptors(props)} name="selected" type="radio" />
			</div>
		)
	}

	const radioBtnDiseases = (props: any) => {
		return (
			<div className="ngSelectionCell">
				<input onChange={() => onRowSelectedDisease(props)} name="selected" type="radio" />
			</div>
		)
	}

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			<AlertError
				message={STRINGS.ALERT_WARNING_FIELDS}
			/>
		</If>
		<If condition={successfulResponseCulture && !errorCulture}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS}
			/>
		</If>
		<If condition={successfulResponseRecordsType || successfulResponseProtection}>
			<AlertSuccess
				message={STRINGS.ALERT_FILES_SUCCESS}
			/>
		</If>
		<If condition={!successfulResponseCulture && errorCulture !== null}>
			{errorCulture !== null && errorCulture.message.length > 0 ? errorCulture.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<h3>Cadastro de Cultura</h3>
		<Form onSubmit={handleSubmit(onSubmitCulture)}>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Nome da cultura<em>*</em>
					</Form.Label>
					<Form.Control
						ref={register}
						name="name"
						type="text"
						placeholder="Insira o nome da cultura"
						isInvalid={errors.name !== undefined}
						autoComplete="off"
						maxLength={100}
					/>
					<If condition={errors.name}>
						<Form.Control.Feedback type="invalid">
							{errors.name?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Nome científico<em>*</em>
					</Form.Label>
					<Form.Control
						ref={register}
						name="description"
						type="text"
						placeholder="Insira o nome científico da cultura"
						isInvalid={errors.description !== undefined}
						autoComplete="off"
						maxLength={255}
					/>
					<If condition={errors.description}>
						<Form.Control.Feedback type="invalid">
							{errors.description?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				&nbsp;&nbsp;
				<Form.Check
					ref={register}
					name="active"
					label="Ativa"
				/>
			</Form.Row>

			<Fieldset title="Descritores">
				<Fragment>
					<AddButton type="button" onClick={() => setShowDescriptores(true)} />
					<If condition={descriptors.length}>
						<div className="ag-theme-alpine" style={{ height: '150px', width: '100%' }}>
							<AgGridReact
								rowData={descriptors}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, editable: true }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsDescriptors,
									radioButton: radioBtnDescriptors
								}}
							>
								<AgGridColumn
									headerName="Selecionar"
									pinned="left"
									editable={false}
									cellRenderer="radioButton"
								/>
								<AgGridColumn
									headerName='Descritores'
									field='descricao'
								/>
								<AgGridColumn
									headerName='Status'
									field='ativo'
									valueFormatter={({ data }: any) => statusToText(data.ativo)}
									editable={false}
								/>
								<AgGridColumn
									headerName='Ações'
									cellRenderer='actions'
									editable={false}
								/>
							</AgGridReact>
						</div>
						<br />
						<If condition={selectedDescriptors?.caracteristicasDescritores.length}>
							<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
								<AgGridReact
									rowData={selectedDescriptors?.caracteristicasDescritores}
									defaultColDef={{ flex: 1, sortable: true, editable: true }}
									stopEditingWhenGridLosesFocus={true}
									singleClickEdit={true}
								>
									<AgGridColumn
										headerName='Características'
										field="descricao"
										wrapText={true}
										autoHeight
										editable
										singleClickEdit
									/>
								</AgGridReact>
							</div>
						</If>
					</If>
				</Fragment>
			</Fieldset>

			<Fieldset title="Doenças">
				<Fragment>
					<AddButton type="button" onClick={() => setShowDisease(true)} />
					<If condition={diseases.length}>
						<div className="ag-theme-alpine" style={{ height: '150px', width: '100%' }}>
							<AgGridReact
								rowData={diseases}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, editable: true }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsDiseases,
									radioButton: radioBtnDiseases
								}}
							>
								<AgGridColumn
									headerName="Selecionar"
									pinned="left"
									editable={false}
									cellRenderer="radioButton"
								/>
								<AgGridColumn
									headerName='Doenças'
									field='descricao'
								/>
								<AgGridColumn
									headerName='Status'
									field='ativo'
									editable={false}
									valueFormatter={({ data }: any) => statusToText(data.ativo)}
								/>
								<AgGridColumn
									headerName='Ações'
									cellRenderer='actions'
									editable={false}
								/>
							</AgGridReact>
						</div>
						<br />
						<If condition={selectedDisease?.reacoesDoencas.length}>
							<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
								<AgGridReact
									rowData={selectedDisease?.reacoesDoencas}
									defaultColDef={{ flex: 1, sortable: true, editable: true }}
									stopEditingWhenGridLosesFocus={true}
									singleClickEdit={true}
								>
									<AgGridColumn
										headerName='Reações'
										field="descricao"
										wrapText={true}
										autoHeight
										editable
										singleClickEdit
									/>
								</AgGridReact>
							</div>
						</If>
					</If>
				</Fragment>
			</Fieldset>

			<Fieldset title="Regiões">
				<Fragment>
					<AddButton type="button" onClick={() => setShowRegion(true)} />
					<If condition={regions.length}>
						<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={regions}
								defaultColDef={{ flex: 1, sortable: true, resizable: true }}
								frameworkComponents={{
									actions: actionsRegions,
								}}
							>
								<AgGridColumn
									headerName='Regiões'
									field='descricao'
								/>
								<AgGridColumn
									headerName='Status'
									field='ativo'
									valueFormatter={({ data }: any) => statusToText(data.ativo)}
								/>
								<AgGridColumn
									headerName='Ações'
									cellRenderer='actions'
								/>
							</AgGridReact>
						</div>
					</If>
				</Fragment>
			</Fieldset>

			<Fieldset title="Modelos de Tipos de Registro">
				<span className="models-registers-type">
					<Form.Row className="row-custom">
						<Toggle
							name="ciclo_agronomico_toggle"
							label={<span>Usar <strong>10. Avaliação do ciclo e características agronômicas da cultivar</strong> como texto Livre</span>}
							small
							defaultValue={cicloAgronomicoAsText}
							onChange={(newStats:boolean) => {
								setCicloAgronomicoAsText(newStats);
							}}
						/>
						<Toggle
							name="avaliacao_cultivar_toggle"
							label={<span>Usar <strong>12. Avaliação da qualidade tecnológica/industrial</strong> como texto Livre</span>}
							small
							defaultValue={avaliacaoCulivarAsText}
							onChange={(newStats:boolean) => {
								setAvaliacaoCulivarAsText(newStats);
							}}
						/>
						<Form.Group as={Col}>
							<Form.Label>
								Tipo de registro
							</Form.Label>
							&nbsp;&nbsp;
							<If condition={loadingRecordsType}>
								<LoadingInForm />
							</If>
							<Controller
								control={control}
								name="registerType"
								render={() =>
									<Form.Control
										as="select"
										onChange={({ target }: any) => {
											let index = target.selectedIndex;
											setValue('registerType', target.value)
											setRegisterTypeName(target.options[index].label);
										}}
									>
										<Form.Control
											as="option"
											label="Escolha uma opção"
											value=""
										/>
										{recordsType.map((record: RecordTypesResults) => (
											<Form.Control
												key={record.id}
												as="option"
												label={record.nome}
												value={record.id}
											/>
										))}
									</Form.Control>
								}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Modelos de tipo de registro
							</Form.Label>
							<Form.Control
								as="select"
								ref={register}
								name="modelRegisterType"
								onChange={({ target }: any) => {
									let index = target.selectedIndex;
									setModelRegisterType(target.options[index].label);
								}}
							>
								<Form.Control
									as="option"
									label="Escolha uma opção"
									value=""
								/>
								{modelosDeTiposdeRegistro.map((model) => (
									<Form.Control
										key={model.id}
										as="option"
										label={model.label}
										value={model.modelo}
									/>
								))}
							</Form.Control>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Anexar arquivo
							</Form.Label>
							<Form.File
								type="file"
								label={lblFile}
								data-browse="upload"
								custom
								onChange={({ target }: any) => {
									if (target.value) {
										setLblFile(`${target.files[0].name}`);
										setFiles([...files, {
											idTipoRegistro: getValues('registerType'),
											modeloTipoDeRegistro: getValues('modelRegisterType'),
											arquivo: target.files[0],
											nomeArquivo: target.files[0].name,
											tipoDeRegistroNome: registerTypeName,
											nomeModelo: modelRegisterType
										}]);
										setLblFile('Escolher arquivo');
									}
								}}
							/>
						</Form.Group>
					</Form.Row>
				</span>
				<If condition={files.length}>
					<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={files}
							defaultColDef={{ flex: 1, sortable: true, resizable: true }}
							frameworkComponents={{
								actions: actionsFilesRegister
							}}
						>
							<AgGridColumn
								headerName="Tipo de Registro"
								field="tipoDeRegistroNome"
								cellStyle={{
									alignItems: 'center'
								}}
								wrapText
								autoHeight
							/>
							<AgGridColumn
								headerName="Modelo"
								field="nomeModelo"
								cellStyle={{
									alignItems: 'center'
								}}
								wrapText
								autoHeight
							/>
							<AgGridColumn
								headerName="Arquivo"
								field="nomeArquivo"
								cellStyle={{
									alignItems: 'center'
								}}
								wrapText
								autoHeight
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer='actions'
								cellStyle={{
									alignItems: 'center'
								}}
								wrapText
								autoHeight
							/>
						</AgGridReact>
					</div>
				</If>
			</Fieldset>

			<Fieldset title="Modelos de Proteção">
				<span className="models-registers-type">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Relatório Técnico
							</Form.Label>
							<Form.File
								ref={register}
								name="modelTechnicalReportProtection"
								type="file"
								label={lblReport}
								data-browse="upload"
								custom
								onChange={({ target }: any) => {
									if (target.value) {
										setLblReport(`${target.files[0].name}`);
									}
								}}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Tabela de descritores
							</Form.Label>
							<Form.File
								ref={register}
								name="modelDescriptorsTableProtection"
								type="file"
								label={lblDescriptors}
								data-browse="upload"
								custom
								onChange={({ target }: any) => {
									if (target.value) {
										setLblDescriptors(`${target.files[0].name}`);
									}
								}}
							/>
						</Form.Group>
					</Form.Row>
				</span>
			</Fieldset>

			<div className="button-position">
				<Button
					title="Cancelar"
					type="button"
					contained={false}
					onClick={() => history.goBack()}
				/>
				<Button
					title="Cadastrar"
					type="submit"
					contained={true}
					isLoading={loadingCulture || (loadingFilesProtection || loadingFilesRegister)}
				/>
			</div>
		</Form>

		<If condition={showDescriptors}>
			<Modal show={showDescriptors} title="Adicionar descritores" onHide={() => setShowDescriptores(false)}>
				<If condition={verifyErrorsOnFormDescriptors()}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form>
					<Form.Row className="row-custom">
						<Form.Label>
							Descrição<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="descriptorDescription"
							type="text"
							placeholder="Insira o descritor"
							isInvalid={errors.descriptorDescription !== undefined}
							autoComplete="off"
							maxLength={300}
						/>
						<If condition={errors.descriptorDescription}>
							<Form.Control.Feedback type="invalid">
								{errors.descriptorDescription?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Check
							ref={register}
							name="descriptorStatus"
							type="checkbox"
							label="Ativo"
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Fieldset title="Características" className="form-row-fieldset">
							<span className="popup-field">
								<Form.Control
									ref={register}
									name="descriptorCharacteristic"
									type="text"
									placeholder="Insira a caraterística do descritor"
									autoComplete="off"
									style={{ width: '90%' }}
									maxLength={300}
								/>
								<FiX
									onClick={() => setValue('descriptorCharacteristic', '')}
									color="red"
									cursor="pointer"
								/>
								<FiCheck
									onClick={() => {
										if (getValues('descriptorCharacteristic')?.length) {
											setTempCharacteristicList(state => [...state, {
												descricao: getValues('descriptorCharacteristic')
											}]);
										}
										setTimeout(() => {
											setValue('descriptorCharacteristic', '');
										}, 5)
									}}
									color="green"
									cursor="pointer"
								/>
							</span>
							<br />
							<If condition={tempCharacteristicList.length}>
								<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
									<AgGridReact
										rowData={tempCharacteristicList}
										defaultColDef={{ flex: 1, sortable: true, editable: true }}
										frameworkComponents={{
											actions: actionsModalDescriptors
										}}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
									>
										<AgGridColumn
											headerName='Descrição'
											field="descricao"
											wrapText={true}
											autoHeight
										/>
										<AgGridColumn
											headerName='Ações'
											cellRenderer='actions'
											editable={false}
										/>
									</AgGridReact>
								</div>
							</If>
						</Fieldset>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							contained={false}
							onClick={() => setShowDescriptores(false)}
						/>
						<Button
							title="Cadastrar"
							type="button"
							contained={true}
							onClick={() => {
								if (!verifyErrorsOnFormDescriptors() &&
									getValues('descriptorDescription').length &&
									tempCharacteristicList.length) {
									setTempCharacteristicList([]);
									onSubmitDescriptors();
									setShowDescriptores(false);
								}
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={showDisease}>
			<Modal show={showDisease} title="Adicionar doenças" onHide={() => setShowDisease(false)}>
				<If condition={verifyErrorsOnFormDiseases()}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form>
					<Form.Row className="row-custom">
						<Form.Label>
							Descrição<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="diseaseDescription"
							type="text"
							placeholder="Insira a descrição da doença"
							isInvalid={errors.diseaseDescription !== undefined}
							autoComplete="off"
							maxLength={300}
						/>
						<If condition={errors.diseaseDescription}>
							<Form.Control.Feedback type="invalid">
								{errors.diseaseDescription?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Check
							ref={register}
							name="diseaseStatus"
							label="Ativo"
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Fieldset title="Reações" className="form-row-fieldset">
							<span className="popup-field">
								<Form.Control
									ref={register}
									name="diseaseReaction"
									type="text"
									placeholder="Insira a reação da doença"
									autoComplete="off"
									style={{ width: '90%' }}
									maxLength={300}
								/>
								<FiX
									onClick={() => setValue('diseaseReaction', '')}
									color="red"
									cursor="pointer"
								/>
								<FiCheck
									onClick={() => {
										if (getValues('diseaseReaction')?.length) {
											setTempReactionsList([...tempReactionsList, {
												descricao: getValues('diseaseReaction')
											}]);
										}
										setTimeout(() => {
											setValue('diseaseReaction', '');
										}, 2)
									}}
									color="green"
									cursor="pointer"
								/>
							</span>
							<br />
							<If condition={tempReactionsList.length}>
								<div className="ag-theme-alpine" style={{ height: "20vh", width: '100%' }}>
									<AgGridReact
										rowData={tempReactionsList}
										defaultColDef={{ flex: 1, sortable: true, editable: true }}
										frameworkComponents={{
											actions: actionsModalDiseases
										}}
										stopEditingWhenGridLosesFocus={true}
										singleClickEdit={true}
									>
										<AgGridColumn
											headerName='Descrição'
											field="descricao"
											wrapText={true}
											autoHeight
										/>
										<AgGridColumn
											headerName='Ações'
											cellRenderer='actions'
											editable={false}
										/>
									</AgGridReact>
								</div>
							</If>
						</Fieldset>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							contained={false}
							onClick={() => setShowDisease(false)}
						/>
						<Button
							title="Cadastrar"
							type="button"
							contained={true}
							onClick={() => {
								if (!verifyErrorsOnFormDiseases() &&
									getValues('diseaseDescription').length &&
									tempReactionsList.length) {
									setTempReactionsList([]);
									onSubmitDiseases();
									setShowDisease(false);
								}
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={showRegion}>
			<Modal show={showRegion} title="Adicionar região" onHide={() => setShowRegion(false)}>
				<If condition={verifyErrorsOnFormRegions()}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form>
					<Form.Row className="row-custom">
						<Form.Label>
							Região<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="regionName"
							type="text"
							placeholder="Insira a região da cultura"
							isInvalid={errors.regionName !== undefined}
							autoComplete="off"
							maxLength={10}
						/>
						<If condition={errors.regionName}>
							<Form.Control.Feedback type="invalid">
								{errors.regionName?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Check
							ref={register}
							name="regionStatus"
							label="Ativo"
						/>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							contained={false}
							onClick={() => setShowRegion(false)}
						/>
						<Button
							title="Cadastrar"
							type="button"
							contained={true}
							onClick={() => {
								if (!verifyErrorsOnFormRegions() && getValues('regionName').length) {
									setRegions([]);
									onSubmitRegions();
									setShowRegion(false);
								}
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>
	</div >;
}