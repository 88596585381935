import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Col, Row } from 'react-bootstrap';
import Button from '../../../components/Button';
import Fieldset from '../../../components/Fieldset';
import If from '../../../components/If';
import DatePicker from 'react-datepicker';
import AddButton from '../../../components/AddButton';
import LoadingInForm from '../../../components/LoadingInForm';
import NotAvailableModel from '../../../components/NotAvailableModel';
import FileExistent from '../../../components/FileExistent';
import { STRINGS } from '../../../util/strings';
import {
	RegisterRNCForm,
	CultureResults,
	RecordTypesResults,
	ApplicantResults,
	EventsResult,
	BreederResults,
	InstitutionResults,
	FilesTypeResults,
	DescriptorsResults,
	CharacteristicsDescriptors,
	DiseasesReactionsResults,
	DiseasesResults,
	RequestRegisterDisease,
	ReactionsDiseasesResult,
	RequestRegisterDescriptors,
	RequestRegisterMelhoristas,
	RequestRegisterApplicants,
	RequestRegisterRegions,
	ExpectedDescriptors,
	ExpectedDisease,
	ModelFileRegister,
	ModelsFiles,
	CultivaresRegistroResponse,
	DescritoresResponseRegistro,
	DiseasesResponseRegistro,
	RegionsResponseRegistro,
	Regions,
	AdditionalsFilesRegistroUpdate,
	RequestRegisterMelhoristasEquipeTecnica,
	Status,
	RegistroInfoValidation
} from '../../../models/models';
import api, { apiFileDownload } from '../../../services/api';
import Loading from '../../../components/Loading';
import { STATUS_REGISTER } from '../../../util/status';
import moment from 'moment';
import '../styles_view.css';

interface stateType {
	id: number;
}

export default function RegisterRNC ()
{
	const history = useHistory();
	const location = useLocation<stateType>();
	const [codigoRequisicao, setCodigoRequisicao] = useState<string>('');
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [protectedCultivar, setProtectedCultivar] = useState<boolean>(false);
	const [transferredCultivar, setTransferredCultivar] = useState<boolean>(false);
	const [foreignCultivar, setForeignCultivar] = useState<boolean>(false);
	const [essentiallyDerived, setEssentiallyDerived] = useState<boolean>(false);
	const [modifiedOrganism, setModifiedOrganism] = useState<boolean>(false);
	const [chooseApplicant, setChooseApplicant] = useState<boolean>(false);
	const [selectedApplicants, setSelectedApplicants] = useState<Array<ApplicantResults>>([]);
	const [unselectedApplicants, setUnselectedApplicants] = useState<Array<ApplicantResults>>([]);
	const [choosenApplicants, setChoosenApplicants] = useState<Array<ApplicantResults>>([]);
	const [inserApplicants, setInsertApplicants] = useState<boolean>(false);
	const [insertDescriptors, setInsertDescriptors] = useState<boolean>(false);
	const [insertDiseases, setInsertDiseases] = useState<boolean>(false);
	const [choosenDescriptors, setChoosenDescriptors] = useState<boolean>(false);
	const [descriptorsByCulture, setDescriptorsByCulture] = useState<Array<DescriptorsResults>>([]);
	const [characteristicsByDescriptor, setCharacteristicsByDescriptor] = useState<Array<CharacteristicsDescriptors>>([]);
	const [selectedDescriptors, setSelectedDescriptors] = useState<Array<DescritoresResponseRegistro>>([]);
	const [choosenDiseases, setChoosenDiseases] = useState<boolean>(false);
	const [diseasesByCulture, setDiseasesByCulture] = useState<Array<DiseasesResults>>([]);
	const [diseasesReactions, setDiseasesReactions] = useState<Array<DiseasesReactionsResults>>([]);
	const [selectedDiseasesOrNematodes, setSelectedDiseasesOrNematodes] = useState<Array<DiseasesResponseRegistro>>([]);
	const [selectedFilesAdditionals, setSelectedFilesAdditionals] = useState<Array<any>>([]);
	const [labelAdditionalFiles, setLabelAdditionalFiles] = useState('Escolher arquivo');
	const [lblAdaptationRegions, setLblAdaptationRegions] = useState('Escolher arquivo');
	const [lblAgronomicCharacteristics, setLblAgronomicCharacteristics] = useState('Escolher arquivo');
	const [lblProductivityAssessment, setLblProductivityAssessment] = useState('Escolher arquivo');
	const [lblQualityAssessment, setLblQualityAssessment] = useState('Escolher arquivo');
	const [lblTechnicalObtainingReport, setLblTechnicalObtainingReport] = useState('Escolher arquivo');
	const [lblBreederStatement, setLblBreederStatement] = useState('Escolher arquivo');
	const [insertAdaptationRegions, setInsertAdaptationRegions] = useState<boolean>(false);
	const [chooseAdaptationRegions, setChooseAdaptationRegions] = useState<boolean>(false);
	const [unselectedAdaptationRegions, setUnselectedAdaptationRegions] = useState<Array<Regions>>([]);
	const [choosenAdaptationRegions, setChoosenAdaptationRegions] = useState<Array<Regions>>([]);
	const [selectedAdaptationRegions, setSelectedAdaptationRegions] = useState<Array<Regions>>([]);
	const [chooseBreeder, setChooseBreeder] = useState<boolean>(false);
	const [insertBreeder, setInsertBreeder] = useState<boolean>(false);
	const [selectedBreeder, setSelectedBreeder] = useState<Array<BreederResults>>([]);
	const [unselectedBreeder, setUnselectedBreeder] = useState<Array<BreederResults>>([]);
	const [choosenBreeder, setChoosenBreeder] = useState<Array<BreederResults>>([]);
	const [submited, setSubmited] = useState<boolean>(false);
	const [errorOnSubmit, setErrorOnSubmit] = useState<string>('');
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [culture, setCulture] = useState<CultureResults[]>([]);
	const [cultureDetails, setCultureDetails] = useState<CultureResults>();
	const [recordsType, setRecordsType] = useState<RecordTypesResults[]>([]);
	const [applicants, setApplicants] = useState<ApplicantResults[]>([]);
	const [events, setEvents] = useState<EventsResult[]>([]);
	const [breeders, setBreeders] = useState<BreederResults[]>([]);
	const [regions, setRegions] = useState<Regions[]>([]);
	const [institutions, setInstitutions] = useState<InstitutionResults[]>([]);
	const [selectedInstitution, setSelectedInstitution] = useState<InstitutionResults>();
	const [filesType, setFilesType] = useState<FilesTypeResults[]>([]);
	const [loadingNeededData, setLoadingNeededData] = useState<boolean>(true);
	const [errorGetNeededData, setErrorGetNeededData] = useState<boolean>(false);
	const [fieldApplicantResponsible, setFieldApplicantResponsible] = useState<boolean>(false);
	const [fieldInstitution, setFieldInstitution] = useState<boolean>(false);
	const [fieldCultivarOrigin, setFieldCultivarOrigin] = useState<boolean>(false);
	const [fieldCultivarRate, setFieldCultivarRate] = useState<boolean>(false);
	const [fieldDescriptors, setFieldDescriptors] = useState<boolean>(false);
	const [fieldDiseaseOrNematode, setFieldDiseaseOrNematode] = useState<boolean>(false);
	const [fieldAgronomicCharacteristics, setFieldAgronomicCharacteristics] = useState<boolean>(false);
	const [fieldProductivity, setFieldProductivity] = useState<boolean>(false);
	const [fieldQuality, setFieldQuality] = useState<boolean>(false);
	const [fieldCommercialization, setFieldCommercialization] = useState<boolean>(false);
	const [fieldProductionSystem, setFieldProductionSystem] = useState<boolean>(false);
	const [fieldLimitationCultivar, setFieldLimitationCultivar] = useState<boolean>(false);
	const [fieldAdditionalInformation, setFieldAdditionalInformation] = useState<boolean>(false);
	const [recordTypeName, setRecordTypeName] = useState<string>('');
	const [selectedEvent, setSelectedEvent] = useState<EventsResult>();
	const [specieName, setSpecieName] = useState<string>('');
	const [models, setModels] = useState<ModelsFiles>();
	const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
	const [loadingRequestData, setLoadingRequestData] = useState<boolean>(true);
	const [fileField7, setFileField7] = useState<ModelFileRegister>();
	const [fileField10, setFileField10] = useState<ModelFileRegister>();
	const [fileField11, setFileField11] = useState<ModelFileRegister>();
	const [fileField12, setFileField12] = useState<ModelFileRegister>();
	const [fileField17, setFileField17] = useState<ModelFileRegister>();
	const [fileField18, setFileField18] = useState<ModelFileRegister>();
	const [status, setStatus] = useState<Status>({});
	const [registroInfoValidation, setRegistroInfoValidation] = useState<RegistroInfoValidation>();
	const [registro, setRegistro] = useState('');
	const [dataRegistro, setDataRegistro] = useState<Date>();
	const [protocolo, setProtocolo] = useState('');
	const [dataProtocolo, setDataProtocolo] = useState<Date>();

	const conditionalCultivarCountry = protectedCultivar ? {
		cultivarCountry: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarCountry: yup
			.string()
	}

	const conditionalCultivarCertificateNumber = protectedCultivar ? {
		cultivarCertificateNumber: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarCertificateNumber: yup
			.string()
	}

	const conditionalCultivarNameOfTransferor = transferredCultivar ? {
		cultivarNameOfTransferor: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarNameOfTransferor: yup
			.string()
	}

	const conditionalCultivarInstrumentUsed = transferredCultivar ? {
		cultivarInstrumentUsed: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarInstrumentUsed: yup
			.string()
	}

	const conditionalCultivarCountryOfOrigin = foreignCultivar ? {
		cultivarCountryOfOrigin: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarCountryOfOrigin: yup
			.string()
	}

	const conditionalCultivarImportCountry = foreignCultivar ? {
		cultivarImportCountry: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarImportCountry: yup
			.string()

	}

	const conditionalCultivarImportProcess = foreignCultivar ? {
		cultivarImportProcess: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarImportProcess: yup
			.string()

	}

	const conditionalDerivedCultivar = essentiallyDerived ? {
		derivedCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		derivedCultivar: yup
			.string()
	}

	const conditionalCultivarGeneticTransformationEvent = modifiedOrganism ? {
		cultivarGeneticTransformationEvent: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarGeneticTransformationEvent: yup
			.string()
	}

	const conditionalDescriptorRegisterRNC = insertDescriptors ? {
		descriptorRegisterRNC: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		descriptorRegisterRNC: yup
			.string()
	}

	const conditionalCharacteristicRegisterRNC = insertDescriptors ? {
		characteristicRegisterRNC: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		characteristicRegisterRNC: yup
			.string()
	}

	const conditionalDiseaseOrNematode = insertDiseases ? {
		diseaseOrNematode: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		diseaseOrNematode: yup
			.string()
	}

	const conditionalGreenhouseReactionDisease = insertDiseases ? {
		greenhouseReactionDisease: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		greenhouseReactionDisease: yup
			.string()
	}

	const conditionalCountrysideReactionDisease = insertDiseases ? {
		countrysideReactionDisease: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		countrysideReactionDisease: yup
			.string()
	}

	const conditionalInstitutionName = fieldInstitution ? {
		institutionName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		institutionName: yup
			.string()
	}

	const conditionalResponsibleTechnician = fieldInstitution ? {
		responsibleTechnician: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
	} : {
		responsibleTechnician: yup
			.string()
	}

	const conditionalInstitutionCompany = fieldCultivarOrigin ? {
		institutionCompany: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		institutionCompany: yup
			.string()
	}

	const conditionalBreederParticipant = fieldCultivarOrigin ? {
		breederParticipant: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		breederParticipant: yup
			.string()
	}

	const conditionalCrossingYear = fieldCultivarOrigin ? {
		crossingYear: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		crossingYear: yup
			.string()
	}

	const conditionalCrossingPlace = fieldCultivarOrigin ? {
		crossingPlace: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		crossingPlace: yup
			.string()
	}

	const conditionalCrossingInstitution = fieldCultivarOrigin ? {
		crossingInstitution: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		crossingInstitution: yup
			.string()
	}

	const conditionalGenealogy = fieldCultivarOrigin ? {
		genealogy: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		genealogy: yup
			.string()
	}

	const conditionalDesignation = fieldCultivarOrigin ? {
		designation: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		designation: yup
			.string()
	}

	const conditionalYearOfcommercialization = fieldCommercialization ? {
		yearOfcommercialization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		yearOfcommercialization: yup
			.string()
	}

	const conditionalExperimentalMaterial = fieldCommercialization ? {
		experimentalMaterial: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		experimentalMaterial: yup
			.string()
	}

	const conditionalProductionSystemsForTheCultivar = fieldProductionSystem ? {
		productionSystemsForTheCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		productionSystemsForTheCultivar: yup
			.string()
	}

	const conditionalLimitationCultivar = fieldLimitationCultivar ? {
		limitationsOfTheCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		limitationsOfTheCultivar: yup
			.string()
	}

	const conditionalAdditionalInformation = fieldAdditionalInformation ? {
		additionalInformation: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		additionalInformation: yup
			.string()
	}

	const registerRNCSchema = yup.object().shape({
		speciesName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		scientificName: yup
			.string(),
		speciesGroup: yup
			.string(),
		registerType: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		nameCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		...conditionalInstitutionName,
		...conditionalResponsibleTechnician,
		...conditionalCultivarCountry,
		...conditionalCultivarCertificateNumber,
		...conditionalCultivarNameOfTransferor,
		...conditionalCultivarInstrumentUsed,
		...conditionalCultivarCountryOfOrigin,
		...conditionalCultivarImportCountry,
		...conditionalCultivarImportProcess,
		...conditionalDerivedCultivar,
		...conditionalCultivarGeneticTransformationEvent,
		...conditionalInstitutionCompany,
		...conditionalBreederParticipant,
		...conditionalCrossingYear,
		...conditionalCrossingPlace,
		...conditionalCrossingInstitution,
		...conditionalGenealogy,
		...conditionalDesignation,
		...conditionalDescriptorRegisterRNC,
		...conditionalCharacteristicRegisterRNC,
		...conditionalDiseaseOrNematode,
		diseaseOrNematodeNote: yup
			.string(),
		...conditionalCountrysideReactionDisease,
		...conditionalGreenhouseReactionDisease,
		...conditionalYearOfcommercialization,
		...conditionalExperimentalMaterial,
		...conditionalProductionSystemsForTheCultivar,
		...conditionalLimitationCultivar,
		...conditionalAdditionalInformation,
		fileType: yup
			.string(),
		additionalFiles: yup
			.mixed(),
		ratePlaces: yup
			.mixed(),
		agronomicCharacteristics: yup
			.mixed(),
		productivityAssessment: yup
			.mixed(),
		qualityAssessment: yup
			.mixed(),
		technicalObtainingReport: yup
			.mixed(),
		breederStatement: yup
			.mixed(),
	})

	const { register, getValues, errors, reset, control } = useForm({
		mode: 'onTouched',
		resolver: yupResolver(registerRNCSchema)
	})

	const handleRequiredFields = (idRecordType: number) => {
		const recordType = recordsType.filter(record => {
			return record.id === Number(idRecordType);
		});
		const fields: RecordTypesResults = recordType[0];

		setFieldApplicantResponsible(fields.melhoristaResponsavel);
		setFieldInstitution(fields.instituicaoResponsavel);
		setFieldCultivarOrigin(fields.origemDaCultivar);
		setFieldCultivarRate(fields.avaliacoesDeCultivar);
		setFieldDescriptors(fields.descritores);
		setFieldDiseaseOrNematode(fields.reacoesDoencas);
		setFieldAgronomicCharacteristics(fields.avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura);
		setFieldProductivity(fields.avaliacaoDaProdutividade);
		setFieldQuality(fields.avaliacaoDaQualidadeTecnologicaIndustrical);
		setFieldCommercialization(fields.intencaoDeComercializacao);
		setFieldProductionSystem(fields.sistemasDeProducaoSugeridosParaCultivar);
		setFieldLimitationCultivar(fields.limitacoesDaCultivar);
		setFieldAdditionalInformation(fields.informacoesAdicionais);
	}

	useEffect(() => {
		const getDataNeeded = async () => {
			try {
				const responseCulture = await api.get<CultureResults[]>('api/v1/cultivares/Cultura/obterporfiltro?Ativo=true');
				const responseRecordsType = await api.get<RecordTypesResults[]>('api/v1/cultivares/TipoRegistro/obterporfiltro?Ativo=true');
				const responseApplicants = await api.get<ApplicantResults[]>('api/v1/cultivares/Requerente/obterporfiltro?Ativo=true');
				const responseEvents = await api.get<EventsResult[]>('api/v1/cultivares/Evento/obterporfiltro?Ativo=true');
				const responseBreeders = await api.get<BreederResults[]>('api/v1/cultivares/EquipeTecnicaMelhorista/obterporfiltro?Ativo=true');
				const responseInstitutions = await api.get<InstitutionResults[]>('api/v1/Instituicao');
				const responseFilesType = await api.get<FilesTypeResults[]>('api/v1/TipoDeAnexo');

				if (responseCulture.status === 200) {
					setCulture(responseCulture.data);
				}
				if (responseRecordsType.status === 200) {
					setRecordsType(responseRecordsType.data);
				}
				if (responseApplicants.status === 200) {
					setApplicants(responseApplicants.data);
				}
				if (responseEvents.status === 200) {
					setEvents(responseEvents.data);
				}
				if (responseBreeders.status === 200) {
					setBreeders(responseBreeders.data);
				}
				if (responseInstitutions.status === 200) {
					setInstitutions(responseInstitutions.data);
				}
				if (responseFilesType.status === 200) {
					setFilesType(responseFilesType.data);
				}
			} catch (error:any) {
				setErrorGetNeededData(true);
			}
			setLoadingNeededData(false);
		}

		const getRequest = async () => {
			try {
				const { data } = await api.get<CultivaresRegistroResponse>(`api/v1/cultivares/CultivaresRegistro/${location.state?.id}`);
				if (!loadingNeededData) {
					reset({
						speciesName: data.culturaId,
						scientificName: data.nomeCientificoDaEspecie,
						speciesGroup: data.grandesEspecie,
						registerType: data.tipoRegistroId,
						nameCultivar: data.denominacaoDaCultivarLinhagem,
						institutionName: data.instituicaoId,
						responsibleTechnician: data.tecnicoResponsavelPeloEnsaio,
						cultivarCountry: data.pais,
						cultivarCertificateNumber: data.numeroDoCertificadoDeProtecao,
						cultivarNameOfTransferor: data.nomeDoCedente,
						cultivarInstrumentUsed: data.instrumentoUltilizadoParaTransferencia,
						cultivarCountryOfOrigin: data.paisDeOrigem,
						cultivarImportCountry: data.paisDeImportacaoProcedencia,
						cultivarImportProcess: data.processoDeImportacao,
						derivedCultivar: data.deQualCultivarEDerivada,
						cultivarGeneticTransformationEvent: data.eventoId,
						institutionCompany: data.instituicaoEmpresaObtentoraIntrodutorasDetentora,
						breederParticipant: data.melhoriasParticipanteNaObtencao,
						crossingYear: data.anoDeRealizacao,
						crossingPlace: data.local,
						crossingInstitution: data.instituicaoQueRealizou,
						genealogy: data.genealogia,
						designation: data.denominacaoExperimentalOuPreComercial,
						yearOfcommercialization: data.inicioDeComercializacao,
						experimentalMaterial: data.quantidadeEmEstoqueMaterialExperimental,
						productionSystemsForTheCultivar: data.sistemaDeProducaoSugeridos,
						limitationsOfTheCultivar: data.limitacoesDaCultivar,
						additionalInformation: data.informacoesAdicionais,
						generalObservations: data.observacoes,
						cancelReason: data.motivoCancelamento,
						caracteristicasAlgodaoCicloColheita: data.caracteristicasAlgodaoCicloColheita,
						caracteristicasAlgodaoCicloFlorescimento: data.caracteristicasAlgodaoCicloFlorescimento,
						caracteristicasAlgodaoPercentagemFibras: data.caracteristicasAlgodaoPercentagemFibras,
						caracteristicasAlgodaoPesoCapulho: data.caracteristicasAlgodaoPesoCapulho,
						caracteristicasAlgodaoPesoCemSementes: data.caracteristicasAlgodaoPesoCemSementes,
						caracteristicasAlgodaoPrecocidadeMaturacao: data.caracteristicasAlgodaoPrecocidadeMaturacao,
						caracteristicasAlgodaoRetencaoPluma: data.caracteristicasAlgodaoRetencaoPluma,
						caracteristicasArrozCicloMaturacao: data.caracteristicasArrozCicloMaturacao,
						caracteristicasArrozComportamentoAcamamento: data.caracteristicasArrozComportamentoAcamamento,
						caracteristicasArrozComportamentoDegrane: data.caracteristicasArrozComportamentoDegrane,
						caracteristicasArrozDiasSemeadura: data.caracteristicasArrozDiasSemeadura,
						caracteristicasArrozPesoMilSementes: data.caracteristicasArrozPesoMilSementes,
						caracteristicasArrozSistemaCultivo: data.caracteristicasArrozSistemaCultivo

					})
					setCodigoRequisicao(data.codigo);
					setSpecieName(data.nomeComumDaEspecie);
					setRecordTypeName(data.tipoRegistroNome);
					setSelectedInstitution({
						id: data.instituicaoId,
						cep: data.cep,
						cnpj: data.cnpj,
						email: data.email,
						endereco: data.endereco,
						municipio: data.municipio,
						uf: data.uf,
						pais: data.paisInstituicao,
						nome: data.nome,
						telefone: data.telefone,
					})
					setProtectedCultivar(data.cultivarProtegida);
					setTransferredCultivar(data.cultivarTransferida);
					setForeignCultivar(data.cultivarEstrangeira);
					setEssentiallyDerived(data.cultivarEssencialmenteDerivada);
					setModifiedOrganism(data.cultivarGeneticamenteModificada);
					setSelectedEvent({
						id: data.eventoId,
						nomeEvento: data.nomeDoEvento,
					});
					getCultureById(Number(data.culturaId));
					if (data.cultivaresRequerentes.length) {
						const allApplicants = data.cultivaresRequerentes.map(req => {
							return req.requerente;
						})
						setSelectedApplicants(allApplicants);
						setInsertApplicants(true);
					}
					if (data.cultivaresEquipeTecnicas.length) {
						const allBreeders = data.cultivaresEquipeTecnicas.map(melhorista => {
							return melhorista.equipeTecnicaMelhorista;
						});
						setSelectedBreeder(allBreeders);
						setInsertBreeder(true);
					}
					if (data.arquivoAvaliacaoCultivar)
						setFileField7(data.arquivoAvaliacaoCultivar);
					if (data.arquivoCicloAgronomico)
						setFileField10(data.arquivoCicloAgronomico);
					if (data.arquivoProdutividade)
						setFileField11(data.arquivoProdutividade);
					if (data.arquivoQualidadeTecnologica)
						setFileField12(data.arquivoQualidadeTecnologica);
					if (data.arquivoRelatorioTecnicoObtencao)
						setFileField17(data.arquivoRelatorioTecnicoObtencao);
					if (data.arquivoDeclaracaoDoMelhorista)
						setFileField18(data.arquivoDeclaracaoDoMelhorista);
					if (data.arquivoConclusao) setFileFieldConclusao(data.arquivoConclusao);
					if (data.cultivaresDescritores) {
						const allDescriptors = data.cultivaresDescritores.map(descriptor => {
							return descriptor;
						})
						setSelectedDescriptors(allDescriptors);
						setChoosenDescriptors(true);
					}
					if (data.cultivaresDoencas) {
						const allDiseases = data.cultivaresDoencas.map(disease => {
							return disease;
						})
						setSelectedDiseasesOrNematodes(allDiseases);
						setChoosenDiseases(true);
					}
					if (data.cultivaresCulturaRegioes) {
						const allRegions = data.cultivaresCulturaRegioes.map(region => {
							return region.culturaRegiao;
						})
						setSelectedAdaptationRegions(allRegions);
						setInsertAdaptationRegions(true);
					}
					if (data.arquivosAnexos) {
						const allFiles = data.arquivosAnexos.map(file => {
							return file;
						})
						setSelectedFilesAdditionals(allFiles);
					}
					setStatus({
						status: data.status,
						statusDescricao: data.statusDescricao
					});
					handleRequiredFields(data.tipoRegistroId);
					setRegistroInfoValidation({
						descritoresValidado: data.descritoresValidado,
						doencaValidada: data.doencaValidada,
						usuarioValidacaoDescritores: data.usuarioValidacaoDescritores,
						usuarioValidacaoDoencas: data.usuarioValidacaoDoencas
					});
					setProtocolo(data.protocolo);
					if (data.dataProtocolo) {
						setDataProtocolo(moment(data.dataProtocolo).toDate());
					}
					if (data.dataRegistro) {
						setDataRegistro(moment(data.dataRegistro).toDate());
					}
					setRegistro(data.registro);
				}
			} catch (error:any) {
				setErrorGetNeededData(true);
			}

			setTimeout(() => {
				setLoadingRequestData(false);
			}, 2500)
		}

		getDataNeeded();
		getRequest();
	}, [loadingNeededData, location.state, reset]);

	const onRowSelectedApplicant = ({ api }: any) => {
		const data = api.getSelectedRows();
		setChoosenApplicants(data);
	}

	const downloadModel = async (modelCode: string, fileName: string) => {
		setLoadingDownload(true);
		try {
			const { data } = await apiFileDownload.get(`api/v1/cultivares/Download?codigo=${modelCode}`);
			let blob = new Blob([data], {
				type: 'application/pdf'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
			}

		} catch (error: any) {
		}
		setLoadingDownload(false);
	}

	const downloadModelFile = async (file: ModelFileRegister | undefined) => {
		if (file != undefined) {
			setLoadingDownload(true);
			try {
				apiFileDownload.get(`api/v1/cultivares/Download?codigo=${file.codigo}`)
					.then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.nome); //or any other extension
						document.body.appendChild(link);
						link.click();
					});

			} catch (error:any) {
				console.error(error);
			}
			setLoadingDownload(false);
		}
	}

	const getCultureById = async (idCulture_: any) => {
		try {
			let idCulture = Number(idCulture_);
			if(!idCulture && idCulture > 0)
			{

				const { data, status } = await api.get<CultureResults>(`api/v1/cultivares/Cultura/${Number(idCulture)}`);
				if (status === 200 || status === 201) {
					setCultureDetails(data);
					setDescriptorsByCulture(data.descritores);
					setDiseasesByCulture(data.doencas);
					setRegions(data.culturaRegioes);
					if (data.modeloRegistrosCultivares.length) {
						setModels(data.modeloRegistrosCultivares[0]);
					}
				}
			}
			
		} catch (error:any) {
			console.error(error);
		}
	}

	const [fileFieldConclusao, setFileFieldConclusao] = useState<ModelFileRegister>();

	return <div className="container container-fluid">
		<h3>Visualização de registro rnc</h3>
		<If condition={loadingRequestData}>
			<span className="loading-page">
				<Loading />
			</span>
		</If>
		<If condition={!loadingRequestData}>
			<Form>
				<Fieldset title="Registro">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Código
							</Form.Label>
							<Form.Control
								value={codigoRequisicao}
								disabled
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Status
							</Form.Label>
							<Form.Control
								value={status.statusDescricao}
								disabled
								readOnly
							/>
						</Form.Group>
					</Form.Row>
					<If condition={status.status === STATUS_REGISTER.Cancelado}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Motivo Cancelamento
								</Form.Label>
								<Form.Control
									ref={register}
									name="cancelReason"
									readOnly
								/>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>
				<Fieldset title="1. Identificação">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Nome comum da espécie
							</Form.Label>
						&nbsp;&nbsp;
						<If condition={loadingNeededData}>
								<LoadingInForm />
							</If>
							<Form.Control
								as="select"
								ref={register}
								name="speciesName"
								disabled
								isInvalid={errors.speciesName !== undefined}
							>
								{culture.map(item => (
									<Form.Control
										as="option"
										label={item.nome}
										value={item.id}
										key={item.id}
									/>
								))}
							</Form.Control>

						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Nome científico da espécie
							</Form.Label>
							<Form.Control
								ref={register}
								name="scientificName"
								autoComplete="off"
								placeholder="Nome científico da espécie"
								readOnly
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Grupo da Espécie
							</Form.Label>
							<Form.Control
								as="select"
								ref={register}
								name="speciesGroup"
								disabled
							>
								<Form.Control
									as="option"
									label="Grandes Culturas"
									value="Grandes Culturas"
								/>
							</Form.Control>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Tipo de registro
							</Form.Label>
						&nbsp;&nbsp;
						<If condition={loadingNeededData}>
								<LoadingInForm />
							</If>
							<Form.Control
								disabled
								as="select"
								ref={register}
								name="registerType"
							>
								<Form.Control
									as="option"
									label="Escolha uma opção"
								/>
								{recordsType.map(item => (
									<Form.Control
										as="option"
										key={item.id}
										label={item.nome}
										value={item.id}
									/>
								))}
							</Form.Control>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Denominação da Cultivar/Linhagem
							</Form.Label>
							<Form.Control
								disabled
								ref={register}
								name="nameCultivar"
								placeholder="Insira a denominação da cultivar"
								autoComplete="off"
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="2. Requerente">
					<If condition={inserApplicants && selectedApplicants.length}>
						<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedApplicants}
								defaultColDef={{ flex: 1, sortable: true }}
							>
								<AgGridColumn
									headerName='Requerente'
									field="nome"
								/>
							</AgGridReact>
						</div>
					</If>
				</Fieldset>
				<Fieldset title="3. Melhorista responsável">
					<If condition={insertBreeder && selectedBreeder.length}>
						<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedBreeder}
								defaultColDef={{ flex: 1, sortable: true }}
							>
								<AgGridColumn
									headerName='Melhorista'
									field='nome'
								/>
							</AgGridReact>
						</div>
					</If>
				</Fieldset>
				<Fieldset title="4. Instituição responsável pelo ensaio">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Instituição
							</Form.Label>
						&nbsp;&nbsp;
						<If condition={loadingNeededData}>
								<LoadingInForm />
							</If>
							<Form.Control
								disabled
								as="select"
								ref={register}
								name="institutionName"
							>
								<Form.Control
									as="option"
									label="Escolha uma instituição"
								/>
								{institutions.map(item => (
									<Form.Control
										as="option"
										key={item.id}
										label={item.nome}
										value={item.id}
									/>
								))}
							</Form.Control>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>
								Técnicos responsáveis pelo ensaio{fieldInstitution ? <em>*</em> : null}
							</Form.Label>
							<Form.Control
								disabled
								ref={register}
								name="responsibleTechnician"
								autoComplete="off"
								placeholder="Insira os nomes dos técnicos"
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="5. Informações complementares">
					<Form.Group controlId="protectedCultivar">
						<div className="option-cultivar">
							<span>Cultivar protegida?</span>
							<Form.Check
								disabled
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								checked={protectedCultivar}
							/>
							<Form.Check
								disabled
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								checked={!protectedCultivar}
							/>
						</div>
					</Form.Group>
					<If condition={protectedCultivar}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									País
								</Form.Label>
								<Form.Control
									disabled
									ref={register}
									name="cultivarCountry"
									autoComplete="off"
									placeholder="Insira o país da cultivar"
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Número do certificado de proteção<em>*</em>
								</Form.Label>
								<Form.Control
									disabled
									ref={register}
									name="cultivarCertificateNumber"
									autoComplete="off"
									placeholder="Insira o número do certificado de proteção"
								/>
							</Form.Group>
						</Form.Row>
					</If>
					<Form.Group controlId="transferredCultivar">
						<div className="option-cultivar">
							<span>Cultivar transferida?</span>
							<Form.Check
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								disabled
								checked={transferredCultivar}
							/>
							<Form.Check
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								disabled
								checked={!transferredCultivar}
							/>
						</div>
					</Form.Group>
					<If condition={transferredCultivar}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Nome do cedente
								</Form.Label>
								<Form.Control
									disabled
									ref={register}
									name="cultivarNameOfTransferor"
									autoComplete="off"
									placeholder="Insira o nome do cedente"
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Instrumento utilizado para a transferência<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="cultivarInstrumentUsed"
									autoComplete="off"
									placeholder="Insira o instrumento utilizado"
									disabled
								/>
							</Form.Group>
						</Form.Row>
					</If>
					<Form.Group controlId="foreignCultivar">
						<div className="option-cultivar">
							<span>Cultivar estrangeira?</span>
							<Form.Check
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								disabled
								checked={foreignCultivar}
							/>
							<Form.Check
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								disabled
								checked={!foreignCultivar}
							/>
						</div>
					</Form.Group>
					<If condition={foreignCultivar}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									País de origem
								</Form.Label>
								<Form.Control
									ref={register}
									name="cultivarCountryOfOrigin"
									autoComplete="off"
									placeholder="Insira o país de origem"
									disabled
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									País de Importação/procedência
								</Form.Label>
								<Form.Control
									ref={register}
									name="cultivarImportCountry"
									autoComplete="off"
									placeholder="Insira o país de importação/procedência"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col}>
								<Form.Label>
									Processo de importação
								</Form.Label>
								<Form.Control
									as="textarea"
									ref={register}
									name="cultivarImportProcess"
									autoComplete="off"
									placeholder="Informe como se deu o processo de importação"
									disabled
								/>
							</Form.Group>
						</Form.Row>
					</If>
					<Form.Group controlId="foreignCultivar">
						<div className="option-cultivar">
							<span>Cultivar essencialmente derivada?</span>
							<Form.Check
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								disabled
								checked={essentiallyDerived}
							/>
							<Form.Check
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								disabled
								checked={!essentiallyDerived}
							/>
						</div>
					</Form.Group>
					<If condition={essentiallyDerived}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Cultivar
								</Form.Label>
								<Form.Control
									ref={register}
									name="derivedCultivar"
									autoComplete="off"
									placeholder="Insira a cultivar de origem"
									disabled
								/>
							</Form.Group>
						</Form.Row>
					</If>
					<Form.Group controlId="foreignCultivar">
						<div className="option-cultivar">
							<span>Organismo geneticamente modificado?</span>
							<Form.Check
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								disabled
								checked={modifiedOrganism}
							/>
							<Form.Check
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								disabled
								checked={!modifiedOrganism}
							/>
						</div>
					</Form.Group>
					<If condition={modifiedOrganism}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Evento de transformação genética
								</Form.Label>
								<Form.Control
									ref={register}
									name="cultivarGeneticTransformationEvent"
									autoComplete="off"
									as="select"
									disabled
								>
									{events.map(item => (
										<Form.Control
											key={item.id}
											as="option"
											label={item.nomeEvento}
											value={item.id}
										/>
									))}
								</Form.Control>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>
				<Fieldset title="6. Origem da cultivar">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Instituição ou empresa obtentora, introdutoras e/ou detentora
							</Form.Label>
							<Form.Control
								ref={register}
								name="institutionCompany"
								placeholder="Informe a instituição ou empresa"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Melhoristas participantes
							</Form.Label>
							<Form.Control
								as="textarea"
								ref={register}
								name="breederParticipant"
								placeholder="Insira os melhoristas participantes"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>
								Cruzamento
					</Form.Label>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={3}>
							<Form.Label>
								Ano de realização
							</Form.Label>
							<Form.Control
								ref={register}
								name="crossingYear"
								placeholder="Insira o ano"
								autoComplete="off"
								disabled
								type="number"
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Local
							</Form.Label>
							<Form.Control
								ref={register}
								name="crossingPlace"
								placeholder="Insira o local de realização do cruzamento"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Instituição que realizou
							</Form.Label>
							<Form.Control
								ref={register}
								name="crossingInstitution"
								placeholder="Insira a instituição responsável pela realização"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Genealogia
							</Form.Label>
							<Form.Control
								ref={register}
								name="genealogy"
								placeholder="Insira os parentais imediatos"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Denominação experimental ou pré-comercial
							</Form.Label>
							<Form.Control
								ref={register}
								name="designation"
								placeholder="Insira a denominação"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="7. Avaliações da cultivar">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Locais da avaliação
							</Form.Label>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={!!fileField7?.id}>
							<FileExistent text={fileField7?.nome} date={fileField7?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField7);
								}}
							/>
						</If>
						<br />
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group className="label-btn">
							<Form.Label>
								Região de adaptação
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={insertAdaptationRegions && selectedAdaptationRegions.length}>
						<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedAdaptationRegions}
								defaultColDef={{ flex: 1, sortable: true }}
							>
								<AgGridColumn
									headerName="Região"
									field="descricao"
								/>
							</AgGridReact>
						</div>
					</If>
				</Fieldset>
				<Fieldset title="8. Descritores">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Descritores
							</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</Form.Group>
					</Form.Row>
					<If condition={choosenDescriptors && selectedDescriptors.length}>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedDescriptors}
								defaultColDef={{ flex: 1, sortable: true, resizable: true }}
								suppressRowClickSelection={true}
							>
								<AgGridColumn
									headerName="Descritor"
									cellStyle={{
										alignItems: 'center'
									}}
									wrapText
									autoHeight
									valueGetter={({ data }: any) => data.descritores.descricao}
								/>
								<AgGridColumn
									headerName="Característica"
									valueGetter={({ data }: any) => data.caracteristicasDescritores.descricao}
									cellStyle={{
										alignItems: 'center'
									}}
								/>
							</AgGridReact>
						</div>
					</If>
					<br />
					<If condition={status.status !== STATUS_REGISTER.EmPreenchimento && status.status !== STATUS_REGISTER.EmAnalisePeloRegulatorio && status.status !== STATUS_REGISTER.ValidadoPeloRegulatorio && status.status !== STATUS_REGISTER.ReprovadoPeloRegulatorio && status.status !== STATUS_REGISTER.EmCorrecao && registroInfoValidation?.usuarioValidacaoDescritores}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Validador
								</Form.Label>
								<Form.Control
									value={registroInfoValidation?.usuarioValidacaoDescritores}
									disabled
									readOnly
									style={{ textTransform: 'capitalize' }}
								/>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Status
								</Form.Label>
								<Form.Control
									value={registroInfoValidation?.descritoresValidado ? "Validado" : "Em validação"}
									disabled
									readOnly
								/>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>
				<Fieldset title="9. Reação a doenças e nematóides">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Reação a doenças e nematóides
							</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</Form.Group>
					</Form.Row>
					<If condition={choosenDiseases && selectedDiseasesOrNematodes.length}>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedDiseasesOrNematodes}
								defaultColDef={{ flex: 1, sortable: true, resizable: true }}
								suppressRowClickSelection={true}
								rowSelection={'multiple'}
							>
								<AgGridColumn
									headerName="Doença"
									wrapText
									autoHeight
									cellStyle={{
										alignItems: 'center'
									}}
									valueGetter={({ data }: any) => data.doencas.descricao}
								/>
								<AgGridColumn
									headerName="Reação em casa de vegetação"
									wrapText
									autoHeight
									cellStyle={{
										alignItems: 'center'
									}}
									valueGetter={({ data }: any) => data.reacaoDoencaCasaVegetacao.descricao}
								/>
								<AgGridColumn
									headerName="Reação em campo"
									wrapText
									autoHeight
									cellStyle={{
										alignItems: 'center'
									}}
									valueGetter={({ data }: any) => data.reacaoDoencaCampo.descricao}
								/>
								<AgGridColumn
									headerName="Observação"
									wrapText
									autoHeight
									cellStyle={{
										alignItems: 'center'
									}}
									valueGetter={({ data }: any) => data.observacao}
								/>
							</AgGridReact>
						</div>
					</If>
					<br />
					<If condition={status.status !== STATUS_REGISTER.EmPreenchimento && status.status !== STATUS_REGISTER.EmAnalisePeloRegulatorio && status.status !== STATUS_REGISTER.ValidadoPeloRegulatorio && status.status !== STATUS_REGISTER.ReprovadoPeloRegulatorio && status.status !== STATUS_REGISTER.EmCorrecao && registroInfoValidation?.usuarioValidacaoDoencas}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Validador
								</Form.Label>
								<Form.Control
									value={registroInfoValidation?.usuarioValidacaoDoencas}
									disabled
									readOnly
									style={{ textTransform: 'capitalize' }}
								/>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Status
								</Form.Label>
								<Form.Control
									value={registroInfoValidation?.doencaValidada ? "Validado" : "Em validação"}
									disabled
									readOnly
								/>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>
				<Fieldset title="10. Avaliação do ciclo e características agronômicas da cultivar">
				<If condition={specieName === "Soja"}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Avaliação do ciclo e características agronômicas da cultivar
							</Form.Label>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<If condition={models?.modeloCicloAgronomico}>
								<AddButton
									type="button"
									onClick={() => {
										if (models?.modeloCicloAgronomico) {
											downloadModel(models.modeloCicloAgronomico.codigo, models.modeloCicloAgronomico.nome);
										}
									}}
									otherTitle="Baixar modelo"
									isDownload={true}
								/>
							</If>

							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={!!fileField10?.id}>
							<FileExistent text={fileField10?.nome} date={fileField10?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField10);
								}}
							/>
						</If>
						<br />
						</Form.Row>
					</If>
					<If condition={specieName === "Arroz"}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Sistema de Cultivo
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasArrozSistemaCultivo"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Comportamento da Cultivar em Relação ao Acamamento
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasArrozComportamentoAcamamento"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Comportamento da Cultivar em Relação ao Degrane
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasArrozComportamentoDegrane"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Ciclo - Dias até a Maturação
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasArrozCicloMaturacao"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Peso de Mil Sementes em Gramas
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasArrozPesoMilSementes"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Número de Dias da Semeadura
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasArrozDiasSemeadura"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
					</If>
					<If condition={specieName === "Algodão"}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Ciclo de Florescimento
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasAlgodaoCicloFlorescimento"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Peso de Cem Sementes em Gramas
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasAlgodaoPesoCemSementes"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Percentagem de Fibras
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasAlgodaoPercentagemFibras"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Precocidade da Maturação
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasAlgodaoPrecocidadeMaturacao"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Ciclo até a Colheita
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasAlgodaoCicloColheita"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Retenção da Pluma
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasAlgodaoRetencaoPluma"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={4}>
								<Form.Label>
									Peso do Capulho
								</Form.Label>
							</Form.Group>
							<Form.Group as={Col} md={4}>
								<Form.Control
									ref={register}
									name="caracteristicasAlgodaoPesoCapulho"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>


				<Fieldset title="11. Avaliação da produtividade">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Avaliação da produtividade
							</Form.Label>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<If condition={models?.modeloProdutividade}>
								<AddButton
									type="button"
									onClick={() => {
										if (models?.modeloProdutividade) {
											downloadModel(models.modeloProdutividade.codigo, models.modeloProdutividade.nome);
										}
									}}
									otherTitle="Baixar modelo"
									isDownload={true}
								/>
							</If>
							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={!!fileField11?.id}>
							<FileExistent text={fileField11?.nome} date={fileField11?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField11);
								}}
							/>
						</If>
						<br />
					</Form.Row>
				</Fieldset>
				<Fieldset title="12. Avaliação da qualidade tecnológica/industrial">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Avaliação da qualidade
							</Form.Label>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<If condition={models?.modeloQualidade}>
								<AddButton
									type="button"
									onClick={() => {
										if (models?.modeloQualidade) {
											downloadModel(models.modeloQualidade.codigo, models.modeloQualidade.nome);
										}
									}}
									otherTitle="Baixar modelo"
									isDownload={true}
								/>
							</If>
							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={!!fileField12?.id}>
							<FileExistent text={fileField12?.nome} date={fileField12?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField12);
								}}
							/>
						</If>
						<br />
					</Form.Row>
				</Fieldset>
				<Fieldset title="13. Intenção de comercialização">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Início de comercialização&#40;ano&#41;
							</Form.Label>
							<Form.Control
								ref={register}
								name="yearOfcommercialization"
								type="number"
								placeholder="Insira o ano início de comercialização"
								disabled
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Quantidade em estoque de material experimental &#45; pré-comercial da cultivar &#40;em kg&#41;
							</Form.Label>
							<Form.Control
								ref={register}
								name="experimentalMaterial"
								type="number"
								placeholder="Insira a quantidade em (kg)"
								disabled
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="14. Sistemas de produção sugeridos para a cultivar">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Sistemas De Produção Sugeridos
							</Form.Label>
							<Form.Control
								as="textarea"
								ref={register}
								name="productionSystemsForTheCultivar"
								type="text"
								placeholder="Insira as informações dos sistemas de produção"
								disabled
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="15. Limitações da cultivar: condições de cultivo e uso que devem ser evitadas">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Limitações da cultivar
							</Form.Label>
							<Form.Control
								as="textarea"
								ref={register}
								name="limitationsOfTheCultivar"
								type="text"
								placeholder="Insira as informações sobre as limitações da cultivar"
								disabled
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="16. Informações adicionais">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Informações adicionais
							</Form.Label>
							<Form.Control
								as="textarea"
								ref={register}
								name="additionalInformation"
								type="text"
								placeholder="Insira as informações adicionais"
								disabled
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="17. Relatório técnico de obtenção">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Relatório técnico de obtenção
							</Form.Label>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<If condition={models?.modeloRelatorioTecnico}>
								<AddButton
									type="button"
									onClick={() => {
										if (models?.modeloRelatorioTecnico) {
											downloadModel(models.modeloRelatorioTecnico.codigo, models.modeloRelatorioTecnico.nome);
										}
									}}
									otherTitle="Baixar modelo"
									isDownload={true}
								/>
							</If>
							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={!!fileField17?.id}>
							<FileExistent text={fileField17?.nome} date={fileField17?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField17);
								}}
							/>
						</If>
						<br />
					</Form.Row>
				</Fieldset>
				<Fieldset title="18. Declaração do melhorista">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Declaração do melhorista<em>*</em>
							</Form.Label>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<If condition={models?.modeloDeclaracaoMelhorista}>
								<AddButton
									type="button"
									onClick={() => {
										if (models?.modeloDeclaracaoMelhorista) {
											downloadModel(models.modeloDeclaracaoMelhorista.codigo, models.modeloDeclaracaoMelhorista.nome);
										}
									}}
									otherTitle="Baixar modelo"
									isDownload={true}
								/>
							</If>
							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={!!fileField18?.id}>
							<FileExistent text={fileField18?.nome} date={fileField18?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField18);
								}}
							/>
						</If>
						<br />
					</Form.Row>
				</Fieldset>
				<Fieldset title="19. Anexos complementares">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Anexo complementar
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={selectedFilesAdditionals.length}>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedFilesAdditionals}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
								onRowSelected={onRowSelectedApplicant}
								rowSelection={'multiple'}
							>
								<AgGridColumn
									headerName="Arquivo"
									valueGetter={({ data }: any) => data.arquivo.nome}
								/>
							</AgGridReact>
						</div>
					</If>
				</Fieldset>

				<Fieldset title="20. Protocolo">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Número Protocolo
							</Form.Label>
							<Form.Control
								disabled
								value={protocolo}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data Protocolo
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="protocolDate"
								render={({ onChange, value }) => (
									<DatePicker
										readOnly
										selected={dataProtocolo}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>

				<Fieldset title="21. Registro MAPA">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>
								Número do Registro
							</Form.Label>
							<Form.Control
								disabled
								value={registro}
								type="text"
							/>
						</Form.Group>
						<Form.Group as={Col} md={12}>
							<Form.Label>
								Data do Registro
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dateRegistro"
								render={({ onChange, value }) => (
									<DatePicker
										readOnly
										selected={dataRegistro}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
									/>
								)}
							/>
						</Form.Group>
						<If condition={!!fileFieldConclusao?.id}>
							<FileExistent
								label="Arquivo de Registro RNC"
								text={fileFieldConclusao?.nome}
								date={fileFieldConclusao?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileFieldConclusao);
								}}
							/>
						</If>
					</Form.Row>
				</Fieldset>

				<Fieldset title="22. Observações Gerais">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Control
								as="textarea"
								ref={register}
								name="generalObservations"
								type="text"
								placeholder="Insira as observações gerais"
								maxLength={4000}
								disabled
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<div className="button-position">
					<Button
						title="Voltar"
						type="button"
						contained={false}
						onClick={() => history.goBack()}
					/>
				</div>
			</Form>
		</If>
	</div>;
}