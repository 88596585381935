import React from 'react';
import { FaFileArchive } from 'react-icons/fa';
import Tooltip from './Tooltip';

interface Props {
    onClick: any;
    Text: string;
}

function GenerateZip({ onClick, Text }: Props) {
    return (
        <Tooltip tooltipTxt={Text}>
            <FaFileArchive className="view-btn" size={20} onClick={onClick} />
        </Tooltip>
    );
}

export default GenerateZip;