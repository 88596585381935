export const PERMISSIONS = {
    Admin: "rclpma.admin",
    Regulatorio: "rclpma.regulatorio",
    VisualizarTodas: "rclpma.visualizar.todas",
    CriarRequisicao: "rclpma.criar_requisicao",
    EditarRequisicao: "rclpma.editar_requisicao",
    EditarMinhas: "rclpma.editar.minhas",
    VisualizarMinhas: "rclpma.visualizar.minhas",
    EditarTabelasAuxiliar: "rclpma.editar_tabelas_auxiliar",
    Tecnico: "rclpma.tecnico",
}

export const isAdmin = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.Admin);

    return isRole.length ? true : false;
}
export const isCheck = (roles: string[], isPerm: string): boolean => {
    const isRole = roles.filter(role => role === isPerm);
    return isRole.length ? true : false;
}
