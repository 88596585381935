export const STRINGS_RCLPMA = {
    NAV_RCLPMA: "Relatórios Conclusivos",
    NAV_REGISTERS: "Cadastros",
    NAV_SOLICITACOES: "Solicitações",
    NAV_UNITYS_BASF: "Unidades/Estações BASF",
    NAV_CULTURE: "Cultura",
    NAV_PROJETO: "Projetos",
    NAV_LOCAL: "Locais",
    NAV_EQUIPE_TECNICA: "Equipe Técnica",
    NAV_EQUIPE_CIBIO: "Composição da CIBio",
    NAV_CONFIGURACOES: "Configurações",


    SEARCH_RCLPMA: "Consulta de Relatório Conclusivo do LPMA",
    CANCEL_SUCCESS: "Relatório Conclusivo cancelada com sucesso",

    INI_RCLPMA: "Iniciando Relatório Conclusivo do LPMA",
    NEW_RCLPMA: "Novo Relatório Conclusivo do LPMA",
    EDIT_RCLPMA: "Edição de Relatório Conclusivo do LPMA",
    VIEW_RCLPMA: "Visualização de Relatório Conclusivo do LPMA",

    REGISTER_RCLPMA: "RC do LPMA",

    ANEXO_REM_CONFIRMATION_CANCEL: "Confirmar a excluir anexo?",
    ANEXO_REM_TITLE_CONFIRMATION: "Tem certeza?",
    ANEXO_REM_CONFIRM_LEFT_EDITION: "Sim, excluir",
    ANEXO_REM_CANCEL_LEFT_EDITION: "Não, permanecer",

    ENVIAR_DATAS_FAIL_TITLE: "Campo de data invalido!",
    ENVIAR_DATAS_FAIL_TEXT: "Campo não pode estar vazio ou ser preenchido com campo invalido. Verifique a seguinte data:",
    

    ALERT_WARNING_FIELDS: "Campos obrigatórios devem ser preenchidos",
    ALERT_MESSAGE_SUCCESS: "O registro foi salvo com sucesso",
    ALERT_STATUS_CORRECAO_SUCCESS: "Solicitação Enviada para Análise Tecníco com sucesso",
    ALERT_STATUS_APROVADA_SUCCESS: "Solicitação Aprovada com sucesso",
    ALERT_STATUS_CONCLUIDA_SUCCESS: "Solicitação Concluída com sucesso",
    ALERT_STATUS_REGULATORIO_SUCCESS: "Solicitação atualizada e Enviada ao Regulatório com sucesso",

    MODO_CONFIGURAR: "Algumas sessões e items necessitam de ajuste na geração do documento final(PDF).",
    MODO_CONFIGURAR_DLG: "Selecione os items que inciaram em nova pagina",

    LABEL_SESSAO_10: "Informar as alterações genéticas introduzidas e as consequências",
    LABEL_SESSAO_16: "Descrever as medidas de biossegurança adotadas",
    LABEL_SESSAO_17: "Descrever os procedimentos de monitoramento utilizados. Informar se houve sobrevivência de OGM no local da liberação, após o término dos experimentos",
    LABEL_SESSAO_18: "Apresentar um resumo dos resultados obtidos e informar se os objetivos da Liberação Planejada foram alcançados",
    LABEL_SESSAO_19: "Relatar quaisquer efeitos inesperados ocorridos durante a Liberação Planejada",
    LABEL_SESSAO_20: "Informar a quantidade de OGM proveniente desta Liberação e qual seu destino. Informar o procedimento de descarte.",
    LABEL_SESSAO_21: "Informar se houve fiscalização por parte do órgão competente, anexando cópia do Termo de Fiscalização e se houver do Auto de Infração.",

    LABEL_CONFIDENC: "As informações a seguir serão confidenciais, disponível em anexo a parte",

    LABEL_RCAREAUTILIZADA: "A área em questão foi utilizada?",

};