import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { DropdownSubmenu, NavDropdownMenu } from 'react-bootstrap-submenu';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse';
import './styles.css';
import logo from '../assets/logo.svg';
import { STRINGS } from '../util/strings';
import { STRINGS_CADERNOCONFORMIDADE } from '../util/cadernoConformidade/string';
import { STRINGS_CIBIO } from '../util/authorizationsCIBIO/string';
import { STRINGS_SHIPMENT } from '../util/shipment/string';
import { STRINGS_LPMA } from '../util/lpma/string';
import { STRINGS_CQB } from '../util/cqb/string';
import { DecodedToken } from '../models/user';
import { Context } from '../context/AuthContext';
import If from './If';
import { isAdmin as isAdminCultivares } from '../users/isAdmin';
import { isDescriptors } from '../users/isDescriptors';
import { isDiseases } from '../users/isDiseases';
import { isReguladorProtection, isReguladorRegister } from '../users/isRegulador';
import {
	isRequesterReadProtection,
	isRequesterReadRegister,
	isRequesterWriteProtection,
	isRequesterWriteRegister
} from '../users/isRequester';
import { isSysAdmin } from '../users/isSysAdmin';

import { isAdmin as isAdminCIBIO } from '../users/AuthorizationsCIBio/isAdmin';
import {
	isReguladorWriter as isReguladorWriterCIBio,
	isReguladorReader as isReguladorReaderCIBio
} from '../users/AuthorizationsCIBio/isRegulador';
import {
	isRequesterReader as isRequesterReaderCIBio,
	isRequesterWriter as isRequesterWriterCIBio
} from '../users/AuthorizationsCIBio/isRequester';

import { isAdmin as isAdminCadernoConformidade } from '../users/CadernoConformidade/isAdmin';
import {
	isReguladorWriter as isReguladorWriterCadernoConformidade,
	isReguladorReader as isReguladorReaderCadernoConformidade
} from '../users/CadernoConformidade/isRegulador';
import {
	isRequesterReader as isRequesterReaderCadernoConformidade,
	isRequesterWriter as isRequesterWriterCadernoConformidade
} from '../users/CadernoConformidade/isRequester';

import { 
	isCheck as isShipmentCheckRoles
} from '../users/shipment/isCheck';

import { 
	PERMISSIONS as SHIPMENT_PERMISSIONS
} from '../users/usersPermissions/usersPermissionsShipment';

import { 
	PERMISSIONS as LPMA_PERMISSIONS
} from '../users/usersPermissions/usersPermissionsLPMA';

import { 
	isCheck as isLPMACheckRoles
} from '../users/lpma/isCheck';

import { 
	isCheck as isCQBCheckRoles,
	PERMISSIONS as CQB_PERMISSIONS
} from '../users/usersPermissions/usersPermissionsCQB';

import { 
	isCheck as isRCLPMACheckRoles,
	PERMISSIONS as RCLPMA_PERMISSIONS
} from '../users/usersPermissions/usersPermissionsRCLPMA';

import {
	PERMISSIONS as CADERNOCONFORMIDADE_PERMISSIONS
} from '../users/usersPermissions/usersPermissionsCadernoConformidade';

import { isVisitor as isVisitorCIBio } from '../users/AuthorizationsCIBio/isVisitor';
import jwtDecode from 'jwt-decode';
import { STRINGS_RCLPMA } from '../util/rclpma/string';

function NavbarSignedIn() {
	const { handleLogout, token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);

	const isAuthorizationsCIBio = (): boolean => {
		return (isReguladorReaderCIBio(decoded.roles) || isReguladorWriterCIBio(decoded.roles) || isRequesterReaderCIBio(decoded.roles) || isRequesterWriterCIBio(decoded.roles) || isVisitorCIBio(decoded.roles) || isAdminCIBIO(decoded.roles));
	}

	const isCultivares = (): boolean => {
		return (isAdminCultivares(decoded.roles) || isDescriptors(decoded.roles) || isDiseases(decoded.roles) || isReguladorProtection(decoded.roles) || isReguladorRegister(decoded.roles) || isRequesterReadProtection(decoded.roles) || isRequesterReadRegister(decoded.roles) || isRequesterWriteProtection(decoded.roles) || isRequesterWriteRegister(decoded.roles));
	}

	const isCadernoConformidade = (): boolean => {
		return false;
	//	return (isReguladorReaderCadernoConformidade(decoded.roles) || isReguladorWriterCadernoConformidade(decoded.roles) ||
	//		isRequesterReaderCadernoConformidade(decoded.roles) || isRequesterWriterCadernoConformidade(decoded.roles) ||
	//		isVisitorCadernoConformidade(decoded.roles) || isAdminCadernoConformidade(decoded.roles));
	}

	return (
		<Navbar expand="lg" fixed="top" className="color-nav">
			<Navbar.Brand href="http://www.basf.com.br">
				<img src={logo} alt="BASF" id="logo" />
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<Nav.Link as={Link} to="/">{STRINGS.NAV_HOME}</Nav.Link>

					<If condition={isSysAdmin(decoded.roles)}>
						<NavDropdown title={STRINGS.NAVBAR_ADMIN} id="basic-nav-dropdown">
							<NavDropdown.Item as={Link} to="/usuario/consultar" className="drop-custom-item">
								{STRINGS.NAV_USER}
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/grupo-permissao/consultar" className="drop-custom-item">
								{STRINGS.NAV_USER_GROUP}
							</NavDropdown.Item>
						</NavDropdown>
					</If>

					<If condition={isCultivares() || isSysAdmin(decoded.roles)}>
						<NavDropdownMenu title={STRINGS.NAV_CULTIVARS} id="collasible-nav-dropdown">

							<If condition={isAdminCultivares(decoded.roles)}>
								<DropdownSubmenu title={STRINGS.NAV_REGISTERS}>
									<NavDropdown.Item as={Link} to='/cultivares/consultar'>
										{STRINGS.NAV_CULTURE}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/cultivares/eventos/consultar'>
										{STRINGS.NAV_EVENTS}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/cultivares/instituicao/consultar'>
										{STRINGS.NAV_INSTITUTION_DATA}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to={'/cultivares/melhoristas/consultar'}>
										{STRINGS.NAV_BREEDER}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/cultivares/requerente/consultar'>
										{STRINGS.NAV_APPLICANT}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/cultivares/tipos-de-registro/consultar'>
										{STRINGS.NAV_RECORD_TYPES}
									</NavDropdown.Item>
								</DropdownSubmenu>
							</If>

							<DropdownSubmenu title={STRINGS.NAV_REQUEST}>
								<NavDropdown.Item as={Link} to='/cultivares/registro-rnc/consultar'>
									{STRINGS.NAV_CULTIVARS_REGISTER}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/cultivares/protecao-snpc/consultar'>
									{STRINGS.NAV_CULTIVARS_PROTECTION}
								</NavDropdown.Item>
							</DropdownSubmenu>
						</NavDropdownMenu>
					</If>

					<If condition={isAuthorizationsCIBio() || isSysAdmin(decoded.roles)}>
						<NavDropdownMenu title={STRINGS_CIBIO.NAV_AUTHORIZATION_CIBIO} id="collasible-nav-dropdown">
							<If condition={isAdminCIBIO(decoded.roles)}>
								<DropdownSubmenu title={STRINGS_CIBIO.NAV_REGISTERS}>
									<NavDropdown.Item as={Link} to='/autorizacoes/consultar/unidades-estacoes-basf'>
										{STRINGS_CIBIO.NAV_UNITYS_BASF}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/autorizacoes/consultar/cultura'>
										{STRINGS_CIBIO.NAV_CULTURE}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/autorizacoes/consultar/evento'>
										{STRINGS_CIBIO.NAV_EVENT}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/autorizacoes/consultar/destinatario'>
										{STRINGS_CIBIO.NAV_RECEIVER}
									</NavDropdown.Item>
								</DropdownSubmenu>
							</If>

							<DropdownSubmenu title={STRINGS_CIBIO.NAV_AUTHORIZATIONS}>
								<NavDropdown.Item as={Link} to='/autorizacoes/consultar/ami'>
									{STRINGS_CIBIO.NAV_AUTHORIZATIONS_AMI}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/autorizacoes/consultar/aac'>
									{STRINGS_CIBIO.NAV_AUTHORIZATIONS_AAC}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/autorizacoes/consultar/ai'>
									{STRINGS_CIBIO.NAV_AUTHORIZATIONS_AI}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/autorizacoes/consultar/ae'>
									{STRINGS_CIBIO.NAV_AUTHORIZATIONS_AE}
								</NavDropdown.Item>
							</DropdownSubmenu>
						</NavDropdownMenu>
					</If>

					{isShipmentCheckRoles(decoded.roles, SHIPMENT_PERMISSIONS.VisualizarMinhas) || isShipmentCheckRoles(decoded.roles, SHIPMENT_PERMISSIONS.VisualizarTodas) ?
						<NavDropdownMenu title={STRINGS_SHIPMENT.NAV_SHIPMENT} id="collasible-nav-dropdown">
							{isShipmentCheckRoles(decoded.roles, SHIPMENT_PERMISSIONS.EditarTabelasAuxiliar) ?
								<DropdownSubmenu title={STRINGS_SHIPMENT.NAV_REGISTERS}>
									<NavDropdown.Item as={Link} to='/shipment/cultura/consultar'>
										{STRINGS_SHIPMENT.NAV_CULTURE}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/shipment/material/consultar'>
										{STRINGS_SHIPMENT.NAV_MATERIAL}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/shipment/requisitante/consultar'>
										{STRINGS_SHIPMENT.NAV_REQUISITANTES}
									</NavDropdown.Item>
								</DropdownSubmenu>
							:''}

							<NavDropdown.Item as={Link} to='/shipment/consultar'>
								{STRINGS_SHIPMENT.NAV_SOLICITACOES}
							</NavDropdown.Item>	
						</NavDropdownMenu>
					:''}

					{isLPMACheckRoles(decoded.roles, LPMA_PERMISSIONS.VisualizarMinhas) || isLPMACheckRoles(decoded.roles, LPMA_PERMISSIONS.VisualizarTodas) ||
					isRCLPMACheckRoles(decoded.roles, RCLPMA_PERMISSIONS.VisualizarMinhas) || isRCLPMACheckRoles(decoded.roles, RCLPMA_PERMISSIONS.VisualizarTodas)?
						<NavDropdownMenu title={STRINGS_LPMA.NAV_LPMA} id="collasible-nav-dropdown">
							{isLPMACheckRoles(decoded.roles, LPMA_PERMISSIONS.EditarTabelasAuxiliar) ||
							isRCLPMACheckRoles(decoded.roles, RCLPMA_PERMISSIONS.EditarTabelasAuxiliar) ?
								<DropdownSubmenu title={STRINGS_LPMA.NAV_REGISTERS}>
									<NavDropdown.Item as={Link} to='/lpma/local/consultar'>
										{STRINGS_LPMA.NAV_LOCAL}
									</NavDropdown.Item>	
									<NavDropdown.Item as={Link} to='/lpma/projeto/consultar'>
										{STRINGS_LPMA.NAV_PROJETO}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/lpma/equipe-tecnica/consultar'>
										{STRINGS_LPMA.NAV_EQUIPE_TECNICA}
									</NavDropdown.Item>										
									<NavDropdown.Item as={Link} to='/lpma/configuracoes/consultar'>
										{STRINGS_LPMA.NAV_CONFIGURACOES}
									</NavDropdown.Item>
								</DropdownSubmenu>
							:''}
							{isLPMACheckRoles(decoded.roles, LPMA_PERMISSIONS.VisualizarMinhas) || isLPMACheckRoles(decoded.roles, LPMA_PERMISSIONS.VisualizarTodas) ?<>
							<NavDropdown.Item as={Link} to='/lpma/rn35/consultar'>
								{STRINGS_LPMA.REGISTER_LPMA_RN35}
							</NavDropdown.Item>	
							<NavDropdown.Item as={Link} to='/lpma/rn06/consultar'>
								{STRINGS_LPMA.REGISTER_LPMA_RN06}
							</NavDropdown.Item>	
							</>: ''}
							{isRCLPMACheckRoles(decoded.roles, RCLPMA_PERMISSIONS.VisualizarMinhas) || isRCLPMACheckRoles(decoded.roles, RCLPMA_PERMISSIONS.VisualizarTodas)?
							<NavDropdown.Item as={Link} to='/rclpma/consultar'>
								{STRINGS_RCLPMA.NAV_RCLPMA}
							</NavDropdown.Item>	
							:''}
						</NavDropdownMenu>
					:''}	

					{/* {isRCLPMACheckRoles(decoded.roles, RCLPMA_PERMISSIONS.VisualizarMinhas) || isRCLPMACheckRoles(decoded.roles, RCLPMA_PERMISSIONS.VisualizarTodas)?
						<NavDropdownMenu title={STRINGS_RCLPMA.NAV_RCLPMA} id="collasible-nav-dropdown">
							{isRCLPMACheckRoles(decoded.roles, RCLPMA_PERMISSIONS.EditarTabelasAuxiliar) ?
								<DropdownSubmenu title={STRINGS_LPMA.NAV_REGISTERS}>
									<NavDropdown.Item as={Link} to='/rclpma/local/consultar'>
										{STRINGS_LPMA.NAV_LOCAL}
									</NavDropdown.Item>	
									<NavDropdown.Item as={Link} to='/rclpma/projeto/consultar'>
										{STRINGS_LPMA.NAV_PROJETO}
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/rclpma/equipe-tecnica/consultar'>
										{STRINGS_LPMA.NAV_EQUIPE_TECNICA}
									</NavDropdown.Item>										
									<NavDropdown.Item as={Link} to='/rclpma/configuracoes/consultar'>
										{STRINGS_LPMA.NAV_CONFIGURACOES}
									</NavDropdown.Item>
								</DropdownSubmenu>
							:''}
							<NavDropdown.Item as={Link} to='/rclpma/consultar'>
								{STRINGS_RCLPMA.REGISTER_RCLPMA}
							</NavDropdown.Item>	
						</NavDropdownMenu>
					:''}	 */}

					{isCQBCheckRoles(decoded.roles, CQB_PERMISSIONS.VisualizarMinhas) || isCQBCheckRoles(decoded.roles, CQB_PERMISSIONS.VisualizarTodas)?
						<NavDropdownMenu title={STRINGS_CQB.NAV_CQB} id="collasible-nav-dropdown">
							{isCQBCheckRoles(decoded.roles, CQB_PERMISSIONS.EditarTabelasAuxiliar) ?
								<DropdownSubmenu title={STRINGS_CQB.NAV_REGISTERS}>
									<NavDropdown.Item as={Link} to='/cqb-local/consultar'>
										{STRINGS_CQB.NAV_LOCAL}
									</NavDropdown.Item>	
									{/* <NavDropdown.Item as={Link} to='/lpma/projeto/consultar'>
										{STRINGS_CQB.NAV_PROJETO}
									</NavDropdown.Item> */}
									<NavDropdown.Item as={Link} to='/cqb-equipe-tecnica/consultar'>
										{STRINGS_CQB.NAV_EQUIPE_TECNICA}
									</NavDropdown.Item>		
									<NavDropdown.Item as={Link} to='/cqb-comissao-cibio'>
										{STRINGS_CQB.NAV_EQUIPE_CIBIO}
									</NavDropdown.Item>									
									<NavDropdown.Item as={Link} to='/cqb-configuracoes/consultar'>
										{STRINGS_CQB.NAV_CONFIGURACOES}
									</NavDropdown.Item>
								</DropdownSubmenu>
							:''}
							<NavDropdown.Item as={Link} to='/cqb/consultar'>
								{STRINGS_CQB.REGISTER_CQB}
							</NavDropdown.Item>	
						</NavDropdownMenu>
					:''}		

					<If condition={isCadernoConformidade() || isSysAdmin(decoded.roles)}>
						<NavDropdownMenu title={STRINGS_CADERNOCONFORMIDADE.NAV_CADERNO_CONFORMIDADE} id="collasible-nav-dropdown">
							<DropdownSubmenu title={STRINGS_CADERNOCONFORMIDADE.NAV_REGISTRO_CADERNO_CONFORMIDADE}>
								<NavDropdown.Item as={Link} to='/cadernoconformidade/consultar/vegetacao'>
									{STRINGS_CADERNOCONFORMIDADE.NAV_REGISTRO_CADERNO_CONFORMIDADE_VEGETACAO}
								</NavDropdown.Item>
							</DropdownSubmenu>
						</NavDropdownMenu>
					</If>
				</Nav>
			</Navbar.Collapse>

			<NavbarCollapse className="justify-content-end">
				<NavDropdown title={decoded.unique_name} id="basic-nav-dropdown">
					<NavDropdown.Item onClick={() => {handleLogout()}} className="drop-custom-item">
						{STRINGS.NAV_LOGOUT}
					</NavDropdown.Item>
				</NavDropdown>
			</NavbarCollapse>
		</Navbar>
	);
}

export default NavbarSignedIn;