import React from "react";
import { Form, Col } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from 'react-select';
import CheckMsgErros from "./_msgErrs";

interface DropDownProps
{
	form?           : any;
	name?           : string;
	label?          : string;
	isInvalid?      : boolean;
	placeholder?    : string;
	control?        : any;
	length?         : number;	
	layoutColumns?  : number;
    options?        : any;
	isClearable?    : boolean;
	required?       : boolean;
	enabled?        : boolean;
    isInvalidCheck? : boolean;
    onChange?       : any;
    onInputChange?  : any;
    onMenuClose?    : any;
    onBlur?         : any;
    key?		    : any;
	defaultValue?   : any;
    isMulti?        : boolean;
    insertButton?   : any;
    changedValue    ?: boolean;
}

export default function DropDown(props: DropDownProps)
{
	const nameDef = "realDropDown";
	const [enabled_, setEnabled] = React.useState<boolean>(true);
    const [asErro, setAsErro] = React.useState<boolean>(true);
    const [msgError,    setMsgError]    = React.useState<string | null> (null);

    const GrpRef        = React.useRef();

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    }, [props.enabled, props.form?.errors, props?.name]);

    // React.useEffect(() => {
    //     let _asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
    //     setAsErro(_asErro)
    // }, [props.form?.errors, props?.name]);

    React.useEffect(() => {
		CheckMsgErros(props, (msg:any)=>{ setMsgError(msg) }, (err:any)=>{ 
            setAsErro(err) 
            if(err && props.isInvalidCheck){
                if(GrpRef != null) {
                    let elmt = GrpRef.current as any;
					const yOffset = -100; 
					const y = elmt.getBoundingClientRect().top + window.pageYOffset + yOffset;
					window.scrollTo({top: y, behavior: 'smooth'});
                }
            }
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.errors, props.name]);


    return <Form.Group ref={GrpRef} as={Col} md={props.layoutColumns ?? 6}>
                <Form.Label>{props.label} {props.required ? <em>*</em> : ''}</Form.Label>
                &nbsp;&nbsp;
                {/* <If condition={isLoadingScreen('get_data_form')}>
                    <LoadingInForm />
                </If> */}
                <Controller 
                    defaultValue={props.defaultValue}
                    key={props.key}
                    control={props.form?.control}
                    name={props.name ?? nameDef}
                    isInvalid={(props.isInvalidCheck && asErro)}                    
                    as={<Select
                        ref={props.form?.register}		
                        className="dropDown-select-input"
                        classNamePrefix="dropDown-select-input-element"
                        isMulti={props.isMulti}
                        options={props.options}
                        placeholder={props.placeholder ?? "Selecionar.."}
                        isClearable={(props.isClearable ?? false) ?? enabled_}
						isDisabled={!enabled_}
                        onChange={props.onChange}
                        onInputChange={(newValue: string, actionMeta:any)=>{
                            //newValue: string, actionMeta: InputActionMeta
                            if(!(!props.form) && !(!props.name) && props.changedValue === true){
                                const watch = props.form.watch(props.name)
                                props.form.setValue(props.name, watch);
                            }

                            if(props.onInputChange){
                                props.onInputChange(newValue, actionMeta)
                            }
                        }}
                        onBlur={props.onBlur}
                        onMenuClose={props.onMenuClose}
                        />}
                />
                {(props.isInvalidCheck && asErro)? 
                <p className="error-field">{props?.name ? ( msgError ??  props.form?.errors[props?.name]?.message) : ''}</p>
                :''}
                
                {/* {(props.insertButton ?? '')}
                {(props.isInvalidCheck && asErro)? 
                <p className="error-field">{props?.name ?props.form?.errors[props?.name]?.message : ''}</p>
                :''} */}
                {/* <If condition={props.form.errors.cultura}>
                    <p className="error-field">{props.form.errors.cultura?.message}</p>
                </If> */}
        </Form.Group>;

}
