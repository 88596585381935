import React from 'react';


interface Props {
    disable: boolean;
    onClick: any;
}

function InactiveButton({ disable, onClick }: Props) {
    return (
        <button
            disabled={disable}
            className="btn-default inactive-btn"
            style={{ backgroundColor: disable ? "lightgray" : "#c71700" }}
            type="button"
            onClick={onClick}
        >
            Excluir
        </button>
    );
}

export default InactiveButton;