import { PERMISSIONS } from '../usersPermissions/usersPermissionsShipment';

export const isAdmin = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.Admin);

    return isRole.length ? true : false;
}
export const isCheck = (roles: string[], isPerm: string): boolean => {
    const isRole = roles.filter(role => role === isPerm);
    return isRole.length ? true : false;
}

/*
export const isReguladorReader = (roles: string[]): boolean => {
    const isRole = roles.filter(role => role === PERMISSIONS.ReguladorRead);

    return isRole.length ? true : false;
}
*/