import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import * as HookForm from 'react-hook-form';

import { uuid } from '../../../services/uuid';

import "./style.scss";

type InputType = 'text' | 'number' | 'textarea';
type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props
{
	type?					: InputType;
	name?					: string;
	label?					: string;
	placeholder?			: string;
	mask?					: string;
	columns?				: ColumnSize;
	required?				: boolean;
	readOnly?				: boolean;
	value?					: number | string;
	onChange				: {(event:any) : any};
}

interface State
{
	name					: string;
}

export default class Text extends React.Component<Props, State>
{
	public static defaultProps = {
		type							: 'text',
		required						: false,
		readOnly						: false,
		columns							: 12,
		onChange						: (event:any) => {},
	};

	constructor (props:Props)
	{
		super(props)

		this.state = {
			name		: this.props.name ? this.props.name : uuid(),
		};
	}

	// componentDidMount ()
	// {
		
	// }

	OnChange (event:any)
	{
		
	}

	ParseLabel () : JSX.Element
	{
		if (!this.props.label)
			return <React.Fragment />;

		return <Bootstrap.Form.Label>
			{this.props.label} {this.props.required ? <em>*</em> : ''}
		</Bootstrap.Form.Label>;
	}

	render ()
	{
		return <Bootstrap.Form.Group as={Bootstrap.Col} md={this.props.columns}>
			{this.ParseLabel()}
			<Bootstrap.Form.Control
				name={this.state.name}
				placeholder={this.props.placeholder}
				as={this.props.type == 'textarea' ? 'textarea' : 'input'}
				type={this.props.type}
				required={this.props.required}
				readOnly={this.props.readOnly}
				style={{height:this.props.type == 'textarea' ? '100px' : 'auto'}}
				value={this.props.value}
				onChange={this.props.onChange}
			/>
		</Bootstrap.Form.Group>;
	}
}