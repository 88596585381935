import React from 'react';
import { FiEye } from 'react-icons/fi';
import Tooltip from './Tooltip';

interface Props {
    onClick?: any;
    disable?: boolean;
}

function ViewButton({ onClick, disable }: Props) {
    return (
        <Tooltip tooltipTxt="Visualizar">
            <FiEye style={{color: disable ? '#c9cacc' : '#003f79'}}  className="view-btn" size={20} onClick={!disable ? onClick : null} />
        </Tooltip>
    );
}

export default ViewButton;