import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../../components/Button';
import If from '../../../../components/If';
import AlertError from '../../../../components/AlertError';
import AlertSuccess from '../../../../components/AlertSuccess';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { STRINGS } from '../../../../util/strings';
import { STRINGS_CIBIO } from '../../../../util/authorizationsCIBIO/string';
import { UnityStationForm } from '../../../../models/modelsAuthorizationsCIBIO';
import { isEmptyObj } from '../../../../functions/isEmptyObject';
import api from '../../../../services/api';
import '../../styles_add.scss';

export default function UnitiesStationsBASF ()
{
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);
	const [isConfirmation, setIsConfirmation] = useState<boolean>(false);

	const unitySchema = yup.object().shape({
		nome: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		active: yup
			.boolean()
	})

	const initialValues: UnityStationForm = {
		nome: '',
		active: true
	}

	const { register, errors, handleSubmit, getValues, formState: { isDirty } } = useForm({
		defaultValues: initialValues,
		mode: 'onTouched',
		resolver: yupResolver(unitySchema)
	})

	const verifyErrorsOnForm = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errors)) {
			Object.entries(errors).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const onSubmit = async () => {
		setLoading(true);
		setError(null);

		try {
			const { status } = await api.post('api/v1/autorizacoes-cibio/UnidadeEstacao', {
				nome: getValues('nome'),
				ativo: getValues('active')
			});

			if (status === 201) {
				setSuccess(true);

				setTimeout(() => {
					history.goBack();
				}, 3500);
			}
		} catch (error:any) {
			setError(error?.response?.data);
		}
		setLoading(false);
	}

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			{verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
		</If>
		<If condition={success && !error}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS}
			/>
		</If>
		<If condition={!success && error !== null}>
			{error !== null && error.message.length > 0 ? error.message.map((errorE: string) => <AlertError message={errorE} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<h3>{STRINGS_CIBIO.REGISTER_UNITIES_STATIONS}</h3>
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Nome da Unidade/Estação<em>*</em>
					</Form.Label>
					<Form.Control
						ref={register}
						name="nome"
						type="text"
						placeholder="Insira o nome da unidade/estação"
						isInvalid={errors.nome !== undefined}
						autoComplete="off"
						maxLength={150}
					/>
					<If condition={errors.nome}>
						<Form.Control.Feedback type="invalid">
							{errors.nome?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				&nbsp;&nbsp;
				<Form.Group className="checkbox-forms">
					<Form.Check
						type="checkbox"
						ref={register}
						name="active"
						label="Ativo?"
					/>
				</Form.Group>
			</Form.Row>
			<div className="button-position">
				<Button
					title="Cancelar"
					type="button"
					contained={false}
					onClick={() => {
						isDirty ? setIsConfirmation(true) :
							history.goBack();
					}}
				/>
				<Button
					title="Cadastrar"
					type="submit"
					contained={true}
					isLoading={loading}
				/>
			</div>
		</Form>
		<If condition={isConfirmation}>
			<ConfirmationModal
				show={isConfirmation}
				confirmationMsg={STRINGS_CIBIO.CONFIRM_LEFT_EDITION}
				cancelMsg={STRINGS_CIBIO.CANCEL_LEFT_EDITION}
				onConfirm={() => history.goBack()}
				onHide={() => setIsConfirmation(false)}
				title={STRINGS_CIBIO.EDITION_TITLE_CONFIRMATION}
				type="button"
				variant="danger"
			>
				{STRINGS_CIBIO.EDITION_REGISTER_CONFIRMATION_CANCEL}
			</ConfirmationModal>
		</If>
	</div>;
}