import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import Select from 'react-select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Form, Col } from 'react-bootstrap';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import AddButton from '../../../components/AddButton';
import Button from '../../../components/Button';
import GenericButton from '../../../components/FlowGenericButton';
import EditButton from '../../../components/EditButton';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import LoadingInForm from '../../../components/LoadingInForm';
import ConfirmationModal from '../../../components/ConfirmationModal';
import {
	UnityStationResult,
	EventResult,
	AACForm,
	CultureResult,
	Enum,
	StateType,
	AACByIdToEdit,
	EventsOnEditAAC,
	DocumentsOnEditAAC
} from '../../../models/modelsAuthorizationsCIBIO';
import { STRINGS } from '../../../util/strings';
import { STRINGS_CIBIO } from '../../../util/authorizationsCIBIO/string';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { Context } from '../../../context/AuthContext'
import { DecodedToken } from '../../../models/user';
import '../../../pages/Registers/styles.css';
import api, { apiFileDownload } from '../../../services/api';
import jwtDecode from 'jwt-decode';
import { STATUS_CIBIO } from '../../../util/authorizationsCIBIO/status';
import { isAdmin } from '../../../users/AuthorizationsCIBio/isAdmin';
import { isReguladorReader, isReguladorWriter } from '../../../users/AuthorizationsCIBio/isRegulador';
import { isRequesterReader, isRequesterWriter } from '../../../users/AuthorizationsCIBio/isRequester';
import { DecimalAsComma } from '../NumberFormat';
import '../styles_edit.scss';

registerLocale('pt-BR', ptBR);

export default function AAC ()
{
	const history = useHistory();
	const location = useLocation<StateType>();
	const { token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);
	const [statusAuthorization, setStatusAuthorization] = useState<number>();
	const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
	const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [loadingGetData, setLoadingGetData] = useState<boolean>(true);
	const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [errorGetData, setErrorGetData] = useState<any>(null);
	const [locations, setLocations] = useState<UnityStationResult[]>([]);
	const [events, setEvents] = useState<EventResult[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [transport, setTransport] = useState<Enum[]>([]);
	const [contencao, setContencao] = useState<Enum[]>([]);
	const [documents, setDocuments] = useState<Enum[]>([]);
	const [selectedEvents, setSelectedEvents] = useState<EventsOnEditAAC[]>([]);
	const [eventToEdit, setEventToEdit] = useState<EventsOnEditAAC>({
		eventoId: 0,
		evento: { label: '', value: 0 },
		materialId: 0,
		material: { label: '', value: 0 },
		qtdUtilizada: 0,
		unidadeMedidaId: 0,
		unidadeMedida: { label: '', value: 0 }
	});
	
	const [selectedDocuments, setSelectedDocuments] = useState<DocumentsOnEditAAC[]>([]);
	const [documentToEdit, setDocumentToEdit] = useState<DocumentsOnEditAAC>({
		documentoId: 0,
		documento: { label: '', value: 0 },
		quantidadeKg: '',
		unidadeMedidaId: 0,
		unidadeMedida: { label: '', value: 0 },
		unidMedOutros: ''
	});
	const [enumMaterial, setEnumMaterial] = useState<Enum[]>([]);
	const [enumUnidadeMedida, setEnumUnidadeMedida] = useState<Enum[]>([]);
	const [gridProps, setGridProps] = useState<any>(null);
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);
	const [isAprove, setIsAprove] = useState<boolean>(false);
	const [avisoDataTermino, setAvisoDataTermino] = useState<boolean>(false);
	const [loadingAprove, setLoadingAprove] = useState<boolean>(false);
	const [successAprove, setSuccessAprove] = useState<boolean>(false);
	//const [errorAprove, setErrorAprove] = useState<any>(null);
	const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
	const [successComplete, setSuccessComplete] = useState<boolean>(false);
	const [errorComplete, setErrorComplete] = useState<any>(null);
	const [isCorrection, setIsCorrection] = useState<boolean>(false);
	const [loadingCorrection, setLoadingCorrection] = useState<boolean>(false);
	const [successCorrection, setSuccessCorrection] = useState<boolean>(false);
	const [errorCorrection, setErrorCorrection] = useState<any>(null);
	const [eventMsg, setEventsMsg] = useState('');

	const [lblRelatorioFotoContencao, setLblRelatorioFotoContencao] = useState('Escolher arquivo');
	const [relatoriosFotograficos, setRelatoriosFotograficos] = useState<any[]>([]);

	const [modalAtivo, setModalAtivo] = useState<string>('');
	const Modals = {
		RelatorioFotografico : 'RELATORIO_FOTOGRAFICO',
		AdicaoDocumento: 'ADICAO_DOCUMENTO',
		EdicaoDocumento : 'EDICAO_DOCUMENTO',
		AdicaoEvento: 'ADICAO_EVENTO',
		EdicaoEvento: 'EDICAO_EVENTO'
	}

	const isEvento = modalAtivo === Modals.AdicaoEvento ? {
		evento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		evento: yup.mixed()
	}
	
	const isQtdUtilizada = modalAtivo === Modals.AdicaoEvento ? {
		qtdUtilizada: yup
		.number()
		.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
		.nullable()
		.required(STRINGS.FORM_FIELD_REQUIRED)
		.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
	} : {
		qtdUtilizada: yup.number().nullable()
	}
	
	const isMaterial = modalAtivo === Modals.AdicaoEvento ? {
		material: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		material: yup.mixed()
	}

	const isEditEvento = modalAtivo === Modals.EdicaoEvento ? {
		editEvento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		editEvento: yup.mixed()
	}

	const isEditQtdUtilizada = modalAtivo === Modals.EdicaoEvento ? {
		editQtdUtilizada: yup
			.number()
			.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
			.nullable()
			.required(STRINGS.FORM_FIELD_REQUIRED)
			.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
	} : {
		editQtdUtilizada: yup.number().nullable()
	}

	const isEditMaterial = modalAtivo === Modals.EdicaoEvento ? {
		editMaterial: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		editMaterial: yup.mixed()
	}

	const amiSchema = yup.object({
		localDeEnvio: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		destinatario: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		localDeDestino: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		possuiCQB: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		modoDeTransporte: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		origem: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		objetivoUtilizacao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		culturaOrganismo: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		observacoes: yup.string(),
		trechoLPMA: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		escopoLPMA: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		embalagemDupla: yup.string(),
		identificacaoComSimboloRiscoBiologico: yup.string(),
		responsavelTransporte: yup.string(),
		dataEnvio: yup.date(),
		materialPerfeitasCondicoes: yup.string(),
		qtdRecebidaDeAcordoQtdEnviada: yup.string(),
		responsavelRecebimento: yup.string(),
		dataRecebimento: yup.date(),
		observacoesAnaliseRegulatorio: yup.string(),
	});

	const initialValues: AACForm = {
		departamentoArea: '',
		unidadeEstacao: undefined,
		local: '',
		numeroInstalacao: '',
		codigoEnsaio: '',
		culturaOrganismo: undefined,
		projeto: '',
		dataPrevisaoInicio: undefined,
		dataPrevisaoTermino: undefined,
		contencao: {value: 1, label: 'Fixa'},
		eventoPassouRN6: '1',
		localPossuiCQB: '1',
		escopoCQBContemplaAtividadeComReferidoMaterialCultura: '1',
		haveraColheitadaAAC: '0',
		usoFinalidade: '',
		destruicaoDescarte: '',
		atividadeObjetivaFuturasLPMA: '1',
		observacoes: '',
		observacoesAnaliseRegulatorio: '',
		objetivoProjeto: '',
		descricaoAtividadeMaterialUtilizado: '',
		finalizacaoProjeto: '',
		plantioConducaoAtividade: '',
		origemMaterial: '',
		finalidade: '',
		nomePropriedade: '',
		enderecoPropriedade: '',
		georreferenciamentoArea: '',
		localizacaoArea: '',
		id: undefined,
		numeroAutorizacaoCibio: undefined,
		solicitanteTecnicoPrincipal: '',
		tecnicoPrincipal: '',
		dataCadastro: undefined,
		dataAprovacao: undefined,
		dataCancelamento: undefined,
		dataEmissaoCibio: undefined,
		culturaId: undefined
	}

	const eventSchema = yup.object({
		...isEvento,
		...isQtdUtilizada,
		unidadeMedida: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		...isMaterial,
		unidMedOutros: yup.string(),
		materialOutros: yup.string(),
		unidMedTranspOutros: yup.string()
	});

	const eventEditSchema = yup.object({
		...isEditEvento,
		...isEditQtdUtilizada,
		editUnidadeMedida: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		...isEditMaterial,
		editUnidMedOutros: yup.string(),
		editMaterialOutros: yup.string()
	});

	const documentSchema = yup.object({
		documento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		descricao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		quantidadeKg: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		unidadeMedida: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		unidMedOutros: yup.string(),
	});

	const editDocumentSchema = yup.object({
		editDocumento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		editDescricao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		editQuantidadeKg: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		editUnidadeMedida: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		editUnidMedOutros: yup.string(),
	});


	const { register, reset, getValues, setValue,errors, trigger, handleSubmit, control, watch, formState: { isDirty, dirtyFields } } = useForm({
		defaultValues: initialValues,
		mode: 'onSubmit',
		resolver: yupResolver(amiSchema),
		shouldUnregister: false
	});

	const { getValues: getValuesEvent, control: controlEvent, register: registerEvent, errors: errorsEvent, handleSubmit: handleSubmitEvent, trigger: triggerEvent, watch: watchEvent } = useForm({
		mode: 'all',
		resolver: yupResolver(eventSchema)
	})

	const { control: controlEditEvent, register: registerEditEvent, errors: errorsEditEvent, handleSubmit: handleSubmitEditEvent, trigger: triggerEditEvent, watch: watchEditEvent } = useForm({
		mode: 'all',
		resolver: yupResolver(eventEditSchema),
		shouldUnregister: false
	})

	const { control: controlDocument, register: registerDocument, errors: errorsDocument, handleSubmit: handleSubmitDocument, trigger: triggerDocument, watch: watchDocument } = useForm({
		mode: 'all',
		resolver: yupResolver(documentSchema),
	})

	const { control: controlEditDocument, register: registerEditDocument, errors: errorsEditDocument, handleSubmit: handleSubmitEditDocument, trigger: triggerEditDocument, watch: watchEditDocument } = useForm({
		mode: 'all',
		resolver: yupResolver(editDocumentSchema),
	})

	const verifyErrorsOnFormDocument = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsDocument)) {
			Object.entries(errorsDocument).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const verifyErrorsOnFormEditDocument = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEditDocument)) {
			Object.entries(errorsEditDocument).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	//fim config campos documento...

	//inicio config modal arquivo relatório...
	const modalArquivo = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(yup.object({
			arquivos: yup.mixed()
		})),
		shouldUnregister: false
	})
	//fim config modal arquivo relatório...

	const getAAC = async () => {
		try
		{
			const { data, status } = await api.get<AACByIdToEdit>(`api/v1/autorizacoes-cibio/AtividadeContencao/${location.state.id}`);
			
			if (status === 200)
			{
				const dataFromServer = {
					id: data.id,
					atividadeObjetivaFuturasLPMA: String(data.atividadeObjetivaFuturasLPMA),
					codigoEnsaio: data.codigoEnsaio,
					dataAprovacao: data.dataAprovacao ? moment(data.dataAprovacao).toDate() : null,
					dataCadastro: moment(data.dataCadastro).toDate(),
					dataCancelamento: data.dataCancelamento ? moment(data.dataCancelamento).toDate() : null,
					dataEmissaoCibio: data.dataEmissaoCibio ? moment(data.dataEmissaoCibio).toDate() : null,
					dataPrevisaoInicio: data.dataPrevisaoInicio ? moment(data.dataPrevisaoInicio).toDate() : null,
					dataPrevisaoTermino: data.dataPrevisaoTermino ? moment(data.dataPrevisaoTermino).toDate() : null,
					departamentoArea: data.departamentoArea,
					descricaoAtividadeMaterialUtilizado: data.descricaoAtividadeMaterialUtilizado,
					destruicaoDescarte: data.destruicaoDescarte,
					enderecoPropriedade: data.enderecoPropriedade,
					escopoCQBContemplaAtividadeComReferidoMaterialCultura: String(data.escopoCQBContemplaAtividadeComReferidoMaterialCultura),
					haveraColheitadaAAC: String(data.haveraColheitadaAAC),
					eventoPassouRN6: String(data.eventoPassouRN6),
					finalidade: data.finalidade,
					finalizacaoProjeto: data.finalizacaoProjeto,
					georreferenciamentoArea: data.georreferenciamentoArea,
					local: data.local,
					localPossuiCQB: String(data.localPossuiCQB),
					localizacaoArea: data.localizacaoArea,
					nomePropriedade: data.nomePropriedade,
					numeroAutorizacaoCibio: data.numeroAutorizacaoCibio,
					numeroInstalacao: data.numeroInstalacao,
					objetivoProjeto: data.objetivoProjeto,
					observacoes: data.observacoes,
					observacoesAnaliseRegulatorio: data.observacoesAnaliseRegulatorio,
					origemMaterial: data.origemMaterial,
					plantioConducaoAtividade: data.plantioConducaoAtividade,
					projeto: data.projeto,
					solicitanteTecnicoPrincipal: data.solicitanteTecnicoPrincipal,
					tecnicoPrincipal: data.tecnicoPrincipal,
					motivoCancelamento: data.motivoCancelamento,
					contencao: {
						value: data.contencaoId ? data.contencaoId : initialValues.contencao.value,
						label: data.contencaoNome ? data.contencaoNome : initialValues.contencao.label
					},
					culturaOrganismo: {
						value: data.cultura?.id,
						label: data.cultura?.nome
					},
					unidadeEstacao: {
						value: data.unidadeEstacaoId,
						label: data.unidadeEstacaoNome
					},
					usoFinalidade: data.usoFinalidade
				};

				reset(dataFromServer);
				setStatusAuthorization(data.status);

				setSelectedEvents([]);
				setSelectedDocuments([]);
				setRelatoriosFotograficos([]);

				setTimeout(() => {

					if (data?.eventos?.length)
					{
						setSelectedEvents(data.eventos);
					}

					if (data?.documentos?.length)
					{
						setSelectedDocuments(data.documentos);
					}

					if (data?.relatoriosFotograficos?.length)
					{
						setRelatoriosFotograficos(data.relatoriosFotograficos);
					}

				}, 500);
			}
		}
		catch (error : any)
		{
			setErrorGetData(error?.response?.data);
		}

		setLoadingGetData(false);
	}

	const getDataToForm = async () => {
		try {
			const locations = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');
			const events = await api.get<EventResult[]>('api/v1/autorizacoes-cibio/Evento/obterporfiltro?Ativo=true');
			const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');
			const material = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/material');
			const unidadeMedida = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/unidade-medida');
			const transporte = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/modo-transporte');
			const contencaoReq = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/contencao');
			const documentoReq = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/documento');

			if (locations.status === 200) {
				setLocations(locations.data);
			}

			if (events.status === 200) {
				setEvents(events.data);
			}

			if (cultures.status === 200) {
				setCultures(cultures.data);
			}

			if (material.status === 200) {
				setEnumMaterial(material.data);
			}

			if (unidadeMedida.status === 200) {
				setEnumUnidadeMedida(unidadeMedida.data);
			}

			if (transporte.status === 200) {
				setTransport(transporte.data);
			}

			if (contencaoReq.status === 200) {
				setContencao(contencaoReq.data);
			}

			if (documentoReq.status === 200) {
				setDocuments(documentoReq.data);
			}

		} catch (error : any) {
			setErrorGetData(error?.response?.data ?? 'Ocorreu um erro ao tentar buscar os enumeradores.');
		}
		setLoadingGetData(false);
	}

	useEffect(() => {
		getDataToForm();
		getAAC();

	}, [location.state.id, reset]);

	const verifyErrorsOnForm = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errors)) {
			Object.entries(errors).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const verifyErrorsOnFormEvent = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEvent)) {
			Object.entries(errorsEvent).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const verifyErrorsOnFormEditEvent = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEditEvent)) {
			Object.entries(errorsEditEvent).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const getValuesToSubmit = () => {
		return {
			id: location.state.id,
			requisitante: decoded.unique_name,
			solicitanteTecnicoPrincipal: getValues('solicitanteTecnicoPrincipal'),
			tecnicoPrincipal: getValues('tecnicoPrincipal'),
			destinatario: getValues('destinatario'),
			localDestinoPossuiCQB: getValues('possuiCQB'),
			origem: getValues('origem'),
			objetivoUtilizacao: getValues('objetivoUtilizacao'),
			observacoes: getValues('observacoes'),
			trechoLPMALocaisMovimentacaoMaterial: getValues('trechoLPMA'),
			escopoLPMAContemplaMaterial: getValues('escopoLPMA'),
			embalagemDuplaImpermeavel: getValues('embalagemDupla'),
			identificacaoSimboloRiscoBiologico: getValues('identificacaoComSimboloRiscoBiologico'),
			responsavelTransporte: getValues('responsavelTransporte'),
			dataEnvio: getValues('dataEnvio'),
			materialChegouDestinoPerfeitasCondicoes: getValues('materialPerfeitasCondicoes'),
			qtdRecebidaDeAcordoComQtdEnviada: getValues('qtdRecebidaDeAcordoQtdEnviada'),
			responsavelRecebimento: getValues('responsavelRecebimento'),
			dataRecebimento: getValues('dataRecebimento'),
			culturaId: getValues('culturaOrganismo')?.value,
			departamentoArea: getValues('departamentoArea'),
			local: getValues('local'),
			numeroInstalacao: getValues('numeroInstalacao'),
			codigoEnsaio: getValues('codigoEnsaio'),
			projeto: getValues('projeto'),
			dataPrevisaoInicio: getValues('dataPrevisaoInicio'),
			dataPrevisaoTermino: getValues('dataPrevisaoTermino'),
			contencaoId: getValues('contencao')?.value,
			eventoPassouRN6: getValues('eventoPassouRN6'),
			localPossuiCQB: getValues('localPossuiCQB'),
			escopoCQBContemplaAtividadeComReferidoMaterialCultura: getValues('escopoCQBContemplaAtividadeComReferidoMaterialCultura'),
			haveraColheitadaAAC: getValues('haveraColheitadaAAC'),
			usoFinalidade: getValues('usoFinalidade'),
			destruicaoDescarte: getValues('destruicaoDescarte'),
			atividadeObjetivaFuturasLPMA: getValues('atividadeObjetivaFuturasLPMA'),
			observacoesAnaliseRegulatorio: getValues('observacoesAnaliseRegulatorio'),
			objetivoProjeto: getValues('objetivoProjeto'),
			descricaoAtividadeMaterialUtilizado: getValues('descricaoAtividadeMaterialUtilizado'),
			finalizacaoProjeto: getValues('finalizacaoProjeto'),
			plantioConducaoAtividade: getValues('plantioConducaoAtividade'),
			origemMaterial: getValues('origemMaterial'),
			finalidade: getValues('finalidade'),
			unidadeEstacaoId: getValues('unidadeEstacao')?.value,
			nomePropriedade: getValues('nomePropriedade'),
			enderecoPropriedade: getValues('enderecoPropriedade'),
			georreferenciamentoArea: getValues('georreferenciamentoArea'),
			localizacaoArea: getValues('localizacaoArea'),
			numeroAutorizacaoCibio: getValues('numeroAutorizacaoCibio'),
			dataAprovacao: getValues('dataAprovacao'),
			dataEmissaoCibio: getValues('dataEmissaoCibio'),
			eventos: selectedEvents,
			documentos: selectedDocuments,
		};
	}

	const validarRelatorioFotograficoContencao = () => {
		let contencaoId = getValues('contencao')?.value;
		if (contencaoId === 2) {
			if (!relatoriosFotograficos || relatoriosFotograficos?.length === 0) {
				const error = {response: {data: {message: ['Relatório Fotográfico é obrigatório.']}}};
				throw error;
			}
		}
	}

	const validarDataConclusao = (): boolean => {
		let dataHoje = moment();
		let dataLimite = moment(getValues('dataPrevisaoTermino')).add(-15, 'd');

		if (dataHoje < dataLimite)
			return false;
		else
			return true;
	}

	const onSubmit = async () => {
		setErrorSubmit(null);
		setLoadingSubmit(true);

		try {
			validarRelatorioFotograficoContencao();

			const valuesToSubmit = getValuesToSubmit();
			const { status, data } = await api.post('api/v1/autorizacoes-cibio/AtividadeContencao/enviarregulatorio', valuesToSubmit);

			if (status === 200 || status === 201) {
				getAAC();
				setStatusAuthorization(data.status);
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setSuccessSubmit(true);
			}
		} catch (error : any) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error?.response?.data);
		}

		setLoadingSubmit(false);
	}

	const onSubmitDraft = async () => {
		setErrorDraft(null);
		setLoadingDraft(true);

		try {
			validarRelatorioFotograficoContencao();

			const valuesToSubmit = getValuesToSubmit();
			const { status, data } = await api.put('api/v1/autorizacoes-cibio/AtividadeContencao/rascunho', valuesToSubmit);

			if (status === 201 || status === 200) {
				getAAC();
				setStatusAuthorization(data.status);
				setSuccessDraft(true);
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		} catch (error:any) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorDraft(error?.response?.data);
		}

		setLoadingDraft(false);
	}

	const complete = async () => {
		setLoadingComplete(true);
		setErrorSubmit(null);

		try {
			validarRelatorioFotograficoContencao();

			const valuesToSubmit = getValuesToSubmit();
			const { status, data } = await api.post('/api/v1/autorizacoes-cibio/AtividadeContencao/concluir', valuesToSubmit);

			if (status === 200 || status === 201) {
				getAAC();
				setStatusAuthorization(data.status);
				setSuccessComplete(true);
				setTimeout(() => setIsComplete(false), 2900);
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		} catch (error : any) {
			setErrorSubmit(error?.response?.data);
			setIsComplete(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
		setLoadingComplete(true);
	}

	const requestCorrection = async () => {
		setLoadingCorrection(true);
		setErrorSubmit(null);

		try {
			validarRelatorioFotograficoContencao();

			const valuesToSubmit = getValuesToSubmit();
			const { status, data } = await api.post('api/v1/autorizacoes-cibio/AtividadeContencao/solicitarcorrecao', valuesToSubmit);

			if (status === 200 || status === 201) {
				getAAC();
				setStatusAuthorization(data.status);
				setSuccessCorrection(true);
				setTimeout(() => setIsCorrection(false), 2900);
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		} catch (error: any) {
			setErrorSubmit(error.response.data);
			setIsCorrection(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
		setLoadingCorrection(false);
	}

	const submitForm_aprove = async () => {
		setLoadingAprove(true);
		setErrorSubmit(null);
		try {
			validarRelatorioFotograficoContencao();

			const valuesToSubmit = getValuesToSubmit();
			const { status, data } = await api.post('/api/v1/autorizacoes-cibio/AtividadeContencao/aprovar', valuesToSubmit);

			if (status === 200 || status === 201) {
				getAAC();
				setStatusAuthorization(data.status);
				setSuccessAprove(true);
				setTimeout(() => setIsAprove(false), 2900);
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		} catch (error: any) {
			setErrorSubmit(error.response.data);
			setIsAprove(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
		setLoadingAprove(false);
	}

	const onSubmitEvent = (info: EventsOnEditAAC): void => {
		setModalAtivo('');
		setSelectedEvents([...selectedEvents, {
			eventoId: info.evento.value,
			eventoNome: info.evento.label,
			evento: info.evento,
			materialId: info.material.value,
			materialDescricao: info.material.label,
			material: info.material,
			materialOutros: info.materialOutros,
			qtdUtilizada: info.qtdUtilizada,
			unidadeMedidaId: info.unidadeMedida.value,
			unidadeMedidaDescricao: info.unidadeMedida.label,
			unidadeMedida: info.unidadeMedida,
			unidMedOutros: info.unidMedOutros
		}]);
	}


	const submitArquivos = async () => {
		setModalAtivo('');

		const arquivo = modalArquivo.getValues('arquivos');
		const aacId = location.state.id;

		var formDataFiles = new FormData();
		formDataFiles.append('arquivo', arquivo[0]);

		const {data} = await api.post(`api/v1/autorizacoes-cibio/AtividadeContencao/${aacId}/SalvarArquivos`, formDataFiles);
		setRelatoriosFotograficos(data);
		setLblRelatorioFotoContencao('Escolher arquivo');
	}

	const onSubmitEditiEvent = (info: any): void => {
		setSelectedEvents([]);
		let arr: any = [];

		gridProps.gridApi.updateRowData({ remove: [gridProps.data] })

		gridProps.gridApi.forEachNode(({ data }: any) => arr.push(data));
		setSelectedEvents([...arr, {
			eventoId: info.editEvento.value,
			eventoNome: info.editEvento.label,
			evento: info.editEvento,
			materialId: info.editMaterial.value,
			materialDescricao: info.editMaterial.label,
			material: info.editMaterial,
			materialOutros: info.editMaterialOutros,
			qtdUtilizada: info.editQtdUtilizada,
			unidadeMedidaId: info.editUnidadeMedida.value,
			unidadeMedidaDescricao: info.editUnidadeMedida.label,
			unidadeMedida: info.editUnidadeMedida,
			unidMedOutros: info.editUnidMedOutros
		}]);

		setModalAtivo('');
	}

	const actionsEvents = (props: any) => {

		if (!can_edit_values('basic'))
			return <Fragment />;

		return <Fragment>
			<EditButton onClick={() => {
				setEventToEdit({
					eventoId: props.data.eventoId,
					eventoNome: props.data.eventoNome,
					evento: {
						label: props.data.eventoNome,
						value: props.data.eventoId
					},
					materialId: props.data.materialId,
					materialDescricao: props.data.materialDescricao,
					material: {
						label: props.data.materialDescricao,
						value: props.data.materialId
					},
					materialOutros: props.data.materialOutros,
					qtdUtilizada: props.data.qtdUtilizada,
					unidadeMedidaId: props.data.unidadeMedidaId,
					unidadeMedidaDescricao: props.data.unidadeMedidaDescricao,
					unidadeMedida: {
						label: props.data.unidadeMedidaDescricao,
						value: props.data.unidadeMedidaId
					},
					unidMedOutros: props.data.unidMedOutros
				});

				setGridProps(props.node);
				setModalAtivo(Modals.EdicaoEvento);
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedEvents([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedEvents([...arr]);
			}} />
		</Fragment>;
	}

	const acoesRelatorioFoto = (props: any) => {

		if (!can_edit_values('basic'))
			return <Fragment />;

		return <Fragment>
			<DeleteButton onClick={() => {
				const aacId = location.state.id;
				const { data } = props.node;
		
				api.post(`api/v1/autorizacoes-cibio/AtividadeContencao/${aacId}/ExcluirArquivo`, data).then((response) => {
					setRelatoriosFotograficos(response.data);
				});
			}} />
		</Fragment>;
	}

	const linkRelatorioFoto = (props: any) => (
		<Fragment>
			<a href='#' onClick={(e) => {downloadArquivo(e, props.node.data)}}>
				{props.node.data.filename}
			</a>
		</Fragment>
	)

	const actionsDocuments = (props: any) => {

		if (!can_edit_values('projeto'))
			return <Fragment />;

		return <Fragment>
			<EditButton onClick={() => {
				setDocumentToEdit({
					documentoId: props.data.documentoId,
					documentoNome: props.data.documentoNome,
					documento: {
						label: props.data.documentoNome,
						value: props.data.documentoId
					},
					descricao: props.data.descricao,
					quantidadeKg: props.data.quantidadeKg,

					unidadeMedidaId: props.data.unidadeMedidaId,
					unidadeMedidaDescricao: props.data.unidadeMedidaDescricao,
					unidadeMedida: {
						label: props.data.unidadeMedidaDescricao,
						value: props.data.unidadeMedidaId
					},
					unidMedOutros: props.data.unidMedOutros

				});
				setGridProps(props.node);
				setModalAtivo(Modals.EdicaoDocumento);
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedDocuments([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedDocuments([...arr]);
			}} />
		</Fragment>
	}
	
	const onSubmitDocument = (info: DocumentsOnEditAAC): void => {
		setModalAtivo('');
		setSelectedDocuments([...selectedDocuments, {
			documentoId: info.documento.value,
			documentoNome: info.documento.label,
			documento: info.documento,
			descricao: info.descricao,
			quantidadeKg: info.quantidadeKg,
			unidadeMedidaId: info.unidadeMedida.value,
			unidadeMedidaDescricao: info.unidadeMedida.label,
			unidadeMedida: info.unidadeMedida,
			unidMedOutros: info.unidMedOutros

		}]);
	}

	const onSubmitEditiDocument = (info: any): void => {
		setSelectedDocuments([]);
		let arr: any = [];

		gridProps.gridApi.updateRowData({ remove: [gridProps.data] })

		gridProps.gridApi.forEachNode(({ data }: any) => arr.push(data));
		setSelectedDocuments([...arr, {
			documentoId: info.editDocumento.value,
			documentoNome: info.editDocumento.label,
			documento: info.editDocumento,
			descricao: info.editDescricao,
			quantidadeKg: info.editQuantidadeKg,
			unidadeMedidaId: info.editUnidadeMedida.value,
			unidadeMedidaDescricao: info.editUnidadeMedida.label,
			unidadeMedida: info.editUnidadeMedida,
			unidMedOutros: info.editUnidMedOutros

		}]);

		setModalAtivo('');
	}

	const [activeStep, setActiveStep] = React.useState(0);
	const steps = ['Autorização AAC', 'Projeto OGM'];

	const handleStep = (step: number) => () => {
		setActiveStep(step);
	};

	const downloadArquivo = (e: any, arquivo: any) => {
		e.preventDefault();

		if (arquivo !== undefined) {
			try {
				apiFileDownload.get(`api/v1/autorizacoes-cibio/AtividadeContencao/download?path=${arquivo.path}`)
					.then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', arquivo.filename);
						document.body.appendChild(link);
						link.click();
					});
			} catch (error:any) {
			}
		}
	}

	const exibirMsgConfirmacao = () => {
		setIsConfirmation(true);
	}

	const can_edit_values = (itemGroup? : string) => {

		if (location?.state?.readonly === true)
			return false;

		if (isAdmin(decoded.roles))
		{
			return true;
		}

		if (statusAuthorization === STATUS_CIBIO.Cancelado)
			return false;

		if (statusAuthorization === STATUS_CIBIO.Concluido)
			return false;

		let isRequester : boolean = isRequesterReader(decoded.roles) || isRequesterWriter(decoded.roles);
		let isRegulador : boolean = isReguladorReader(decoded.roles) || isReguladorWriter(decoded.roles);

		if (statusAuthorization === STATUS_CIBIO.EmPreenchimento || statusAuthorization === STATUS_CIBIO.EmCorrecao || statusAuthorization === STATUS_CIBIO.CorrecaoSolicitada)
		{
			if (!isRequester)
				return false;

			if (itemGroup != 'basic' && itemGroup != 'projeto' && itemGroup != 'plantio')
				return false;
		}

		if (statusAuthorization === STATUS_CIBIO.EmAnalisePeloRegulatorio)
		{
			if (!isRegulador)
				return false;

			if (itemGroup != 'aprovacao')
				return false;
		}

		if(statusAuthorization === STATUS_CIBIO.AprovadopeloRegulatorio)
		{
			if (!isRequester)
				return false;

			if (itemGroup != 'plantio')
				return false;
		}

		return true;
	}

	return <div className="container container-fluid">
			<h3>{STRINGS_CIBIO.REGISTER_AAC}</h3>
		<If condition={verifyErrorsOnForm()}>
			{verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
		</If>
		<If condition={eventMsg}>
			<AlertError message={eventMsg} />
		</If>
		<If condition={successDraft && !errorDraft}>
			<AlertSuccess message={STRINGS.SUCCESS_DRAFT} />
		</If>
		<If condition={!successDraft && errorDraft}>
			{errorDraft !== null && errorDraft?.message?.length > 0 ? errorDraft?.message?.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<If condition={successSubmit && !errorSubmit}>
			<AlertSuccess
				message={STRINGS.REGULATORY_SUCCESS}
			/>
		</If>
		<If condition={!successSubmit && errorSubmit !== null}>
			{errorSubmit !== null && errorSubmit?.message?.length > 0 ? errorSubmit?.message?.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<If condition={!successComplete && errorComplete !== null}>
			{errorComplete !== null && errorComplete?.message?.length > 0 ? errorComplete?.message?.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<Stepper nonLinear activeStep={activeStep}>
				{steps.map((label, index) => (
				<Step key={label}>
					<StepButton color="inherit" onClick={handleStep(index)}>
					{label}
					</StepButton>
				</Step>
				))}
			</Stepper>
			<If condition={activeStep === 0}>
				<Fieldset disabled={!can_edit_values('basic')} title={STRINGS_CIBIO.AMI_BLOCK_ONE}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
							Número da Solicitação
							</Form.Label>
							<Form.Control
								ref={register}
								readOnly
								name="id"
								maxLength={10}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Solicitante
							</Form.Label>
							<Form.Control
								ref={register}
								type="text"
								name="solicitanteTecnicoPrincipal"
								readOnly
								maxLength={100}
							/>
						</Form.Group>
						<If condition="watchEvent().motivoCancelamento != ''">
							<Form.Group as={Col} md={12}>
								<Form.Label>
									Motivo do Cancelamento
								</Form.Label>
								<Form.Control
									ref={register}
									as="textarea"
									name="motivoCancelamento"
									readOnly
								/>
							</Form.Group>
						</If>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Técnico(a) Principal
							</Form.Label>
							<Form.Control
								ref={register}
								type="text"
								name="tecnicoPrincipal"
								maxLength={100}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Departamento/Área<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="departamentoArea"
								isInvalid={errors.departamentoArea !== undefined}
								placeholder="Insira o departamento/área"
								type="text"
								maxLength={150}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.departamentoArea?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Estação/Unidade Operativa<em>*</em>
								&nbsp;&nbsp;
								<If condition={loadingGetData}>
									<LoadingInForm />
								</If>
							</Form.Label>
							<Controller
								control={control}
								name="unidadeEstacao"
								readOnly
								isInvalid={errors.unidadeEstacao !== undefined}
								as={<Select
									placeholder="Selecione uma unidade/estação"
									isDisabled={!can_edit_values('basic')}
									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
									isClearable
								/>}
							/>
							<If condition={errors.unidadeEstacao}>
								<p className="error-field">{errors.unidadeEstacao?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Local<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="local"
								isInvalid={errors.local !== undefined}
								placeholder="Insira o local"
								type="text"
								maxLength={150}
							/>
							<span className="small text-info">(Descrever o local: Casa de Vegetação, Laboratório, Sala etc.)</span>
							<Form.Control.Feedback type="invalid">
								{errors.local?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Nº da Instalação<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="numeroInstalacao"
								isInvalid={errors.numeroInstalacao !== undefined}
								placeholder="Insira o número da instalação"
								type="text"
							/>
							<Form.Control.Feedback type="invalid">
								{errors.numeroInstalacao?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Código do Ensaio<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="codigoEnsaio"
								isInvalid={errors.codigoEnsaio !== undefined}
								placeholder="Insira o código do ensaio"
								type="text"
							/>
							<Form.Control.Feedback type="invalid">
								{errors.codigoEnsaio?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Cultura/Organismo<em>*</em>
							</Form.Label>
							&nbsp;&nbsp;
							<If condition={loadingGetData}>
								<LoadingInForm />
							</If>
							<Controller
								control={control}
								name="culturaOrganismo"
								isInvalid={errors.culturaOrganismo}
								as={<Select
									options={cultures.map(culture => Object.assign({ label: culture.nome, value: culture.id }))}
									isDisabled={!can_edit_values('basic')}
									placeholder="Selecione uma cultura/organismo"
									isClearable
								/>}
							/>
							<If condition={errors.culturaOrganismo}>
								<p className="error-field">{errors.culturaOrganismo?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Projeto<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="projeto"
								as="textarea"
								rows={5}
								placeholder="Descrever um breve projeto da Contenção, e caso seja necessário anexar ao documento a AMI)"
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Previsão de Início da Atividade<em>*</em>
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataPrevisaoInicio"
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										disabled={!can_edit_values('basic')}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Previsão de Término da Atividade<em>*</em>
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataPrevisaoTermino"
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										disabled={!can_edit_values('basic')}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										minDate={new Date()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Contenção<em>*</em>
							</Form.Label>
							<Controller
								control={control}
								name="contencao"
								defaultValue={initialValues.contencao}
								isInvalid={errors.contencao}
								as={<Select
									options={contencao.map(transp => Object.assign({ label: transp.value, value: transp.key }))}
									isDisabled={!can_edit_values('basic')}
									placeholder="Selecione a contenção"
									isClearable
								/>}
							/>
							<If condition={errors.contencao}>
								<p className="error-field">{errors.contencao?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<If condition={watch().contencao?.value !== 3}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Relatório Fotográfico
									<If condition={watch().contencao?.value === 2}>
										<em>*</em>
									</If>
									{``}
									<AddButton type="button" onClick={() => {if (can_edit_values('basic')) {setModalAtivo(Modals.RelatorioFotografico);}}} />
								</Form.Label>
								<If condition={eventMsg}>
									<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<If condition={relatoriosFotograficos?.length}>
							<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
								<AgGridReact
									rowData={relatoriosFotograficos}
									defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
									gridOptions={{ headerHeight: 80 }}
									stopEditingWhenGridLosesFocus={true}
									singleClickEdit={true}
									frameworkComponents={{
										actions: acoesRelatorioFoto,
										linkRelatorioFoto: linkRelatorioFoto
									}}
								>
									<AgGridColumn
										headerName='Nome'
										cellStyle={{
											justifyContent: 'left'
										}}
										wrapText
										minWidth={250}
										cellRenderer="linkRelatorioFoto"
									/>
									<AgGridColumn
										headerName="Ações"
										cellRenderer="actions"
										maxWidth={100}
									/>
								</AgGridReact>
							</div>
						</If>
					</If>
					<If condition={watch().contencao?.value === 2}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={12}>
								<Form.Label>
									O evento já passou por uma RN6?<em>*</em>
								</Form.Label>
							</Form.Group>
							<br />
							&nbsp;&nbsp;
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="eventoPassouRN6"
								type="radio"
								label="Sim"
								value={2}
							/>
							<Form.Check
								ref={register}
								name="eventoPassouRN6"
								type="radio"
								label="Não"
								value={1}
							/>
							&nbsp;&nbsp;
							<Form.Check
								ref={register}
								name="eventoPassouRN6"
								type="radio"
								label="Não se aplica"
								value={3}
							/>
							<br />
							<If condition={errors.eventoPassouRN6}>
								<p className="error-field">{errors.eventoPassouRN6?.message}</p>
							</If>
						</Form.Row>
					</If>
					<p />
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							O local possui CQB?<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="localPossuiCQB"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="localPossuiCQB"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="localPossuiCQB"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
						/>
					</Form.Row>
					<p />
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							O escopo do CQB do local contempla a atividade com referido material/cultura objeto da solicitação?<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="escopoCQBContemplaAtividadeComReferidoMaterialCultura"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="escopoCQBContemplaAtividadeComReferidoMaterialCultura"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="escopoCQBContemplaAtividadeComReferidoMaterialCultura"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Uso/Finalidade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="usoFinalidade"
								as="textarea"
								rows={4}
								placeholder="Descreva o uso pretendido do material reprodutivo produzido: sementes, toletes, etc"
								isInvalid={errors.usoFinalidade !== undefined}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.usoFinalidade?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Destruição e Descarte<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="destruicaoDescarte"
								as="textarea"
								rows={4}
								placeholder="Informe o local e descreva os procedimentos planejados para a destruição e descarte das sobras, resíduos e restos vegetais do material"
								isInvalid={errors.destruicaoDescarte !== undefined}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.destruicaoDescarte?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							A atividade em contenção objetiva futuras liberações planejadas no meio ambiente (LPMAs)?<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="atividadeObjetivaFuturasLPMA"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="atividadeObjetivaFuturasLPMA"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="atividadeObjetivaFuturasLPMA"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observações
							</Form.Label>
							<Form.Control
								ref={register}
								name="observacoes"
								as="textarea"
								rows={5}
							/>
						</Form.Group>
					</Form.Row>

					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							Haverá Colheita da AAC?<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="haveraColheitadaAAC"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="haveraColheitadaAAC"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
					</Form.Row>

					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Evento<em>*</em>{``}
								<AddButton
									type="button"
									onClick={() => {
										
										if (!can_edit_values('basic'))
											return;

										setModalAtivo(Modals.AdicaoEvento);
									}}
								/>
							</Form.Label>
							<If condition={eventMsg}>
								<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
							</If>
						</Form.Group>
					</Form.Row>

					<If condition={selectedEvents?.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedEvents}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsEvents,
								}}
							>
								<AgGridColumn
									headerName='Evento'
									field="eventoNome"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
									minWidth={250}
								/>
								<AgGridColumn
									headerName='Material'
									field="materialDescricao"
									valueFormatter={({ data }: any) => {

										if (data.materialDescricao == 'Outros')
											return `Outros (${data.materialOutros})`;

										return data.materialDescricao;
										
									}}
									headerClass="myfunc-default-header"
								/>
								<AgGridColumn
									headerName='Quantidade a ser Utilizada'
									field="qtdUtilizada"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => {return DecimalAsComma(data.qtdUtilizada);}}
								/>
								<AgGridColumn
									headerName='Unidade Medida'
									field="unidadeMedidaDescricao"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => {

										if (data.unidadeMedidaDescricao == 'Outros')
											return `Outros (${data.unidMedOutros})`;

										return data.unidadeMedidaDescricao;
										
									}}
								/>
								<AgGridColumn
									headerName="Ações"
									cellRenderer="actions"
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>
				</Fieldset>
				<Fieldset disabled={!can_edit_values('aprovacao')} title={STRINGS_CIBIO.AMI_BLOCK_TWO}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Nº Autorização CIBio
							</Form.Label>
							<Form.Control
								disabled={true}
								readOnly={true}
								ref={register}
								name="numeroAutorizacaoCibio"
								placeholder="Nº autorização CIBio"
								maxLength={20}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data Emissão CIBio
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataEmissaoCibio"
								render={({ onChange, value }) => (
									<DatePicker
										disabled={true}
										readOnly={true}
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										maxDate={new Date()}
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data da Aprovação
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dataAprovacao"
								render={({ onChange, value }) => (
									<DatePicker
										disabled={true}
										readOnly={true}
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										maxDate={new Date()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observações da Análise do Regulatório
							</Form.Label>
							<Form.Control
								ref={register}
								name="observacoesAnaliseRegulatorio"
								as="textarea"
								rows={5}
								placeholder="Observações da análise do regulatório"
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
			</If>
			<If condition={activeStep === 1}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Objetivo<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							disabled={!can_edit_values('projeto')}
							name="objetivoProjeto"
							as="textarea"
							rows={5}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Descrição da atividade/Descrição do material utilizado no ensaio<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							disabled={!can_edit_values('projeto')}
							name="descricaoAtividadeMaterialUtilizado"
							as="textarea"
							rows={5}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Origem do Material<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							disabled={!can_edit_values('projeto')}
							name="origemMaterial"
							isInvalid={errors.origemMaterial !== undefined}
							placeholder="Insira a origem do material"
							type="text"
						/>
						<Form.Control.Feedback type="invalid">
							{errors.origemMaterial?.message}
						</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Finalidade<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							disabled={!can_edit_values('projeto')}
							name="finalidade"
							isInvalid={errors.finalidade !== undefined}
							placeholder="Insira a finalidade"
							type="text"
						/>
						<Form.Control.Feedback type="invalid">
							{errors.finalidade?.message}
						</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Fieldset title={'Local de condução do projeto'}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Nome da Propriedade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								disabled={!can_edit_values('projeto')}
								name="nomePropriedade"
								isInvalid={errors.nomePropriedade !== undefined}
								placeholder="Insira o nome da propriedade"
								type="text"
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Endereço da Propriedade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="enderecoPropriedade"
								disabled={!can_edit_values('projeto')}
								isInvalid={errors.enderecoPropriedade !== undefined}
								placeholder="Insira o endereço da propriedade"
								type="text"
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Georreferenciamento da área<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								disabled={!can_edit_values('projeto')}
								name="georreferenciamentoArea"
								isInvalid={errors.georreferenciamentoArea !== undefined}
								placeholder="Insira a referência geográfica da área"
								type="text"
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Localização da área<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								disabled={!can_edit_values('projeto')}
								name="localizacaoArea"
								isInvalid={errors.localizacaoArea !== undefined}
								placeholder="Insira a localização da área"
								type="text"
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title={'Documentação solicitada para condução do projeto'}>
					<Form.Label>
						Documentos<em>*</em>
					</Form.Label>
					<AddButton type="button" onClick={() => {if (can_edit_values('projeto')) {setModalAtivo(Modals.AdicaoDocumento);}}} />
					<If condition={selectedDocuments?.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedDocuments}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsDocuments,
								}}
							>
								<AgGridColumn
									headerName='Documento'
									field="documentoNome"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
									minWidth={250}
								/>
								<AgGridColumn
									headerName='Descrição'
									field="descricao"
									headerClass="myfunc-default-header"
								/>
								<AgGridColumn
									headerName='Quantidade'
									field="quantidadeKg"
									headerClass="myfunc-default-header"
								/>
								<AgGridColumn
									headerName='Unidade Medida'
									field="unidadeMedidaDescricao"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => {

										if (data.unidadeMedidaDescricao == 'Outros')
											return `Outros (${data.unidMedOutros})`;

										return data.unidadeMedidaDescricao;

									}}
								/>
								<AgGridColumn
									headerName="Ações"
									cellRenderer="actions"
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Finalização do projeto<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="finalizacaoProjeto"
								disabled={!can_edit_values('projeto')}
								as="textarea"
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Plantio e condução da atividade (tratos culturais, etc)
							</Form.Label>
							<Form.Control
								ref={register}
								name="plantioConducaoAtividade"
								disabled={!can_edit_values('plantio')}
								as="textarea"
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
			</If>
			<div className="button-position">
				<Button
					title="Voltar"
					contained={false}
					type="button"
					onClick={() => {
						isDirty ? exibirMsgConfirmacao() : history.push('/autorizacoes/consultar/aac');
					}}
				/>
				<If condition={statusAuthorization === STATUS_CIBIO.EmAnalisePeloRegulatorio && (isReguladorWriter(decoded.roles) || isAdmin(decoded.roles))}>
					<GenericButton
						title="Solicitar correção"
						variant="btn-danger"
						type="button"
						loading={false}
						onClick={() => setIsCorrection(true)}
					/>
				</If>
				<If condition={(statusAuthorization === STATUS_CIBIO.EmPreenchimento || statusAuthorization === STATUS_CIBIO.CorrecaoSolicitada || statusAuthorization === STATUS_CIBIO.EmCorrecao) && (isRequesterWriter(decoded.roles) || isAdmin(decoded.roles))}>
					<GenericButton
						title="Salvar e enviar para regulatório"
						variant="btn-warning"
						type="button"
						loading={loadingSubmit}
						onClick={() => trigger().then(validated => {
							if (validated && selectedEvents?.length) {
								setIsRegulatory(validated);
								setEventsMsg('');
							} else if (validated && !selectedEvents?.length) {
								setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
								window.scrollTo({ top: 0, behavior: 'smooth' });
							} else {
								setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
							}
						})}
					/>
				</If>
				<If condition={statusAuthorization === STATUS_CIBIO.EmAnalisePeloRegulatorio && (isAdmin(decoded.roles) || isReguladorWriter(decoded.roles) || isReguladorReader(decoded.roles))}>
					<GenericButton
						title="Aprovar"
						variant="btn-success"
						type="button"
						loading={false}
						onClick={() => trigger().then(validated => {
							if (validated && selectedEvents?.length) {
								setIsAprove(validated);
								setEventsMsg('');
							} else if (validated && !selectedEvents?.length) {
								setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
								window.scrollTo({ top: 0, behavior: 'smooth' });
							} else {
								setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
							}
						})}
					/>
				</If>
				<If condition={
						statusAuthorization !== STATUS_CIBIO.EmPreenchimento && isAdmin(decoded.roles)
						||
						statusAuthorization === STATUS_CIBIO.AprovadopeloRegulatorio && (isRequesterReader(decoded.roles) || isRequesterWriter(decoded.roles))
					}>
					<Button
						title="Salvar"
						contained
						type="button"
						onClick={() => trigger().then(validated => {
							if (validated && selectedEvents?.length) {
								onSubmitDraft();
								setEventsMsg('');
							} else if (validated && !selectedEvents?.length) {
								setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
								window.scrollTo({ top: 0, behavior: 'smooth' });
							} else {
								setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
							}
						})}
						isLoading={loadingSubmit}
					/>
				</If>
				<If condition={statusAuthorization === STATUS_CIBIO.AprovadopeloRegulatorio && (isAdmin(decoded.roles) || isRequesterWriter(decoded.roles))}>
					<GenericButton
						title="Concluir"
						variant="btn-info"
						type="button"
						loading={false}
						onClick={() =>
							{
								if (validarDataConclusao()) 
									setIsComplete(true)
								else
									setAvisoDataTermino(true)
							}
						}
					/>
				</If>
				<If condition={statusAuthorization === STATUS_CIBIO.EmPreenchimento}>
					<Button
						title="Salvar rascunho"
						contained={true}
						type="button"
						onClick={() => onSubmitDraft()}
						isLoading={loadingDraft}
					/>
				</If>
			</div>
			<If condition={isRegulatory}>
				<ConfirmationModal
					show={isRegulatory}
					onHide={() => {
						setIsRegulatory(false);
					}}
					title={STRINGS.REGULATORY_TITLE}
					confirmationMsg={STRINGS.REGULATORY_ACTION}
					onConfirm={() => {
						onSubmit();
						setIsRegulatory(false);
					}}
					loading={loadingSubmit}
					variant="success"
					color="white"
				>
					<p>{STRINGS.REGULATORY_MESSAGE}</p>
				</ConfirmationModal>
			</If>
			<If condition={isCorrection}>
				<ConfirmationModal
					show={isCorrection}
					onHide={() => setIsCorrection(false)}
					title={STRINGS_CIBIO.AMI_CORRECTION_TITLE}
					confirmationMsg={STRINGS_CIBIO.AMI_CORRECTION_CONFIRMATION_MSG}
					onConfirm={() => requestCorrection()}
					loading={loadingCorrection}
					variant="success"
					color="white"
					hideFooter={successCorrection}
				>
					<If condition={successCorrection}>
						<AlertSuccess
							message={STRINGS_CIBIO.AMI_CORRECTION_SUCCESS}
						/>
					</If>
					<p>{STRINGS_CIBIO.AMI_CORRECTION_BODY_MSG}</p>
				</ConfirmationModal>
			</If>
			<If condition={isComplete}>
				<ConfirmationModal
					show={isComplete}
					onHide={() => setIsComplete(false)}
					title={STRINGS_CIBIO.AMI_COMPLETE_TITLE}
					confirmationMsg={STRINGS_CIBIO.AMI_COMPLETE_CONFIRMATION_MSG}
					onConfirm={() => complete()}
					loading={loadingComplete}
					variant="success"
					color="white"
					hideFooter={successComplete}
				>
					<If condition={successComplete}>
						<AlertSuccess
							message={STRINGS_CIBIO.AMI_COMPLETE_SUCCESS}
						/>
					</If>
					<p>{STRINGS_CIBIO.AMI_COMPLETE_BODY_MSG}</p>
				</ConfirmationModal>
			</If>
			<If condition={isAprove}>
				<ConfirmationModal
					show={isAprove}
					onHide={() => setIsAprove(false)}
					title={STRINGS_CIBIO.AMI_APROVE_TITLE}
					confirmationMsg={STRINGS.APROVE_CONFIRMATION}
					onConfirm={() => submitForm_aprove()}
					loading={loadingAprove}
					variant="success"
					color="white"
					hideFooter={successAprove}
				>
					<If condition={successAprove}>
						<AlertSuccess
							message={STRINGS_CIBIO.AMI_APROVE_SUCCESS}
						/>
					</If>
					<p>{STRINGS_CIBIO.AMI_APROVE_BODY_MSG}</p>
				</ConfirmationModal>
			</If>
			<If condition={avisoDataTermino}>
				<Modal
					show={true}
					onHide={() => setAvisoDataTermino(false)}
					title={STRINGS_CIBIO.AAC_AVISO_DATA_TERMINO}
				>
					<Form autoComplete="off">
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									A AAC só pode ser concluída a partir de 15 dias antes da data prevista de término.
								</Form.Label>
							</Form.Group>
						</Form.Row>
						<div className="button-position">
							<Button
								title="Voltar"
								contained={false}
								type="button"
								onClick={() => setAvisoDataTermino(false)}
							/>
						</div>
					</Form>

				</Modal>
			</If>

		</Form>
		<If condition={modalAtivo === Modals.AdicaoEvento}>
			<Modal
				show={true}
				onHide={() => setModalAtivo('')}
				title={STRINGS_CIBIO.AMI_EVENT_MODAL_TITLE}
			>
				<If condition={verifyErrorsOnFormEvent()}>
					{verifyErrorsOnFormEvent().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
				</If>
				<Form onSubmit={handleSubmitEvent(onSubmitEvent)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Evento<em>*</em>
							</Form.Label>
							<Controller
								control={controlEvent}
								name="evento"
								isInvalid={errorsEvent.evento !== undefined}
								as={
									<Select
										placeholder="Selecione um evento"
										options={events.map(event => Object.assign({ value: event.id, label: event.nome }))}
										isClearable
									/>
								}
							/>
							<If condition={errorsEvent.evento}>
								<p className="error-field">{errorsEvent.evento?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Material<em>*</em>
							</Form.Label>
							<Controller
								control={controlEvent}
								name="material"
								isInvalid={errorsEvent.material !== undefined}
								as={<Select
									placeholder="Selecione um material"
									options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
									isClearable
								/>}
							/>
							<If condition={errorsEvent.material}>
								<p className="error-field">{errorsEvent.material?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<If condition={watchEvent().material?.value === 7}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Especifique o material<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerEvent}
									required
									name="materialOutros"
									placeholder="Insira o material"
								/>
								<span className="error-field">
									{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
								</span>
							</Form.Group>
						</Form.Row>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade a ser utilizada<em>*</em>
							</Form.Label>
							<Form.Control
								ref={registerEvent}
								name="qtdUtilizada"
								placeholder="Insira a quantidade a ser utilizada"
								maxLength={5}
								type="number"
								step="any"
								isInvalid={errorsEvent.quantidadeAutorizada !== undefined}
							/>
							<Form.Control.Feedback type="invalid">
								{errorsEvent.quantidadeAutorizada?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Unidade de Medida<em>*</em>
							</Form.Label>
							<Controller
								control={controlEvent}
								name="unidadeMedida"
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<If condition={watchEvent().unidadeMedida?.value === 4}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Especifique a unidade de medida<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerEvent()}
									required
									name="unidMedOutros"
									placeholder="Insira a unidade de medida"
								/>
								<span className="error-field">
									{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
								</span>
							</Form.Group>
						</Form.Row>
					</If>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalAtivo('')}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>
		<If condition={modalAtivo === Modals.EdicaoEvento}>
			<Modal
				show={true}
				onHide={() => setModalAtivo('')}
				title={STRINGS_CIBIO.AMI_EVENT_EDIT_MODAL_TITLE}
			>
				<If condition={verifyErrorsOnFormEditEvent()}>
					{verifyErrorsOnFormEditEvent().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
				</If>
				<Form autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Evento<em>*</em>
							</Form.Label>
							<Controller
								control={controlEditEvent}
								name="editEvento"
								isInvalid={errorsEditEvent.editEvento !== undefined}
								defaultValue={eventToEdit.evento}
								as={
									<Select
										placeholder="Selecione um evento"
										options={events.map(event => Object.assign({ value: event.id, label: event.nome }))}
										isClearable
									/>
								}
							/>
							<If condition={errorsEditEvent.editEvento}>
								<p className="error-field">{errorsEditEvent.editEvento?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Material<em>*</em>
							</Form.Label>
							<Controller
								control={controlEditEvent}
								name="editMaterial"
								isInvalid={errorsEditEvent.editMaterial !== undefined}
								defaultValue={eventToEdit.material}
								as={<Select
									placeholder="Selecione um material"
									options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
									isClearable
									onInputChange={() =>
										setEventToEdit(state => ({
											eventoId: state.evento.value,
											evento: state.evento,
											materialId: state.material.value,
											material: state.material,
											materialOutros: '',
											qtdUtilizada: state.qtdUtilizada,
											unidadeMedidaId: state.unidadeMedida.value,
											unidadeMedida: state.unidadeMedida,
											unidMedOutros: state.unidMedOutros
										}))
									}
								/>}
							/>
							<If condition={errorsEditEvent.editMaterial}>
								<p className="error-field">{errorsEditEvent.editMaterial?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<If condition={watchEditEvent().editMaterial?.value === 7 || eventToEdit.materialOutros}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Especifique o material<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerEditEvent}
									required
									name="editMaterialOutros"
									placeholder="Insira o material"
									defaultValue={eventToEdit.materialOutros}
								/>
								<span className="error-field">
									{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
								</span>
							</Form.Group>
						</Form.Row>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade a ser utilizada<em>*</em>
							</Form.Label>
							<Form.Control
								ref={registerEditEvent}
								name="editQtdUtilizada"
								placeholder="Insira a quantidade a ser utilizada"
								maxLength={5}
								type="number"
								step="any"
								isInvalid={errorsEditEvent.editQtdUtilizada !== undefined}
								defaultValue={eventToEdit.qtdUtilizada}
							/>
							<Form.Control.Feedback type="invalid">
								{errorsEditEvent.editQtdUtilizada?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Unidade Medida<em>*</em>
							</Form.Label>
							<Controller
								control={controlEditEvent}
								name="editUnidadeMedida"
								defaultValue={eventToEdit.unidadeMedida}
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
									onInputChange={() =>
										setEventToEdit(state => ({
											eventoId: state.evento.value,
											evento: state.evento,
											materialId: state.material.value,
											material: state.material,
											materialOutros: '',
											qtdUtilizada: state.qtdUtilizada,
											unidadeMedidaId: state.unidadeMedida.value,
											unidadeMedida: state.unidadeMedida,
											unidMedOutros: state.unidMedOutros
										}))
									}
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<If condition={watchEditEvent().editUnidadeMedida?.value === 4 || eventToEdit.unidMedOutros}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Especifique a unidade de medida transportada<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerEditEvent}
									defaultValue={eventToEdit.unidMedOutros}
									required
									name="editUnidMedOutros"
									placeholder="Insira a unidade de medida transportada"
								/>
								<span className="error-field">
									{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
								</span>
							</Form.Group>
						</Form.Row>
					</If>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalAtivo('')}
						/>
						<Button
							title="Salvar"
							contained={true}
							type="button"
							onClick={() => {
								triggerEditEvent().then(validated => {
									if (validated) {
										handleSubmitEditEvent(onSubmitEditiEvent)();
									}
								})
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>
		<If condition={modalAtivo === Modals.AdicaoDocumento }>
			<Modal
				show={true}
				onHide={() => setModalAtivo('')}
				title={"Adicionar Documento"}
			>
				<If condition={verifyErrorsOnFormDocument()}>
					{verifyErrorsOnFormDocument().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
				</If>
				<Form onSubmit={handleSubmitDocument(onSubmitDocument)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Documento<em>*</em>
							</Form.Label>
							<Controller
								control={controlDocument}
								name="documento"
								isInvalid={errorsDocument.documento !== undefined}
								as={
									<Select
										placeholder="Selecione um documento"
										options={documents.map(doc => Object.assign({ value: doc.key, label: doc.value }))}
										isClearable
									/>
								}
							/>
							<If condition={errorsDocument.documento}>
								<p className="error-field">{errorsDocument.documento?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Descrição<em>*</em>
							</Form.Label>
							<Form.Control
								ref={registerDocument()}
								required
								name="descricao"
								placeholder="Insira a descrição"
								isInvalid={errorsDocument.descricao !== undefined}
							/>
							<If condition={errorsDocument.descricao}>
								<p className="error-field">{errorsDocument.descricao?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={registerDocument}
								name="quantidadeKg"
								placeholder="Insira a quantidade a ser utilizada"
								type="number"
								step="any"
								isInvalid={errorsDocument.quantidadeKg !== undefined}
							/>
							<Form.Control.Feedback type="invalid">
								{errorsDocument.quantidadeKg?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Unidade de Medida<em>*</em>
							</Form.Label>
							<Controller
								control={controlDocument}
								name="unidadeMedida"
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<If condition={watchDocument().unidadeMedida?.value === 4}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Especifique a unidade de medida<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerDocument()}
									required
									name="unidMedOutros"
									placeholder="Insira a unidade de medida"
								/>
								<span className="error-field">
									{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
								</span>
							</Form.Group>
						</Form.Row>
					</If>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalAtivo('')}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>
		<If condition={modalAtivo === Modals.EdicaoDocumento}>
			<Modal
				show={true}
				onHide={() => setModalAtivo('')}
				title={"Editar Documento"}
			>
				<If condition={verifyErrorsOnFormEditDocument()}>
					{verifyErrorsOnFormEditDocument().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
				</If>
				<Form onSubmit={handleSubmitEditDocument(onSubmitEditiDocument)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Documento<em>*</em>
							</Form.Label>
							<Controller
								control={controlEditDocument}
								name="editDocumento"
								isInvalid={errorsEditDocument.editDocumento !== undefined}
								defaultValue={documentToEdit.documento}
								as={
									<Select
										placeholder="Selecione um documento"
										options={documents.map(doc => Object.assign({ value: doc.key, label: doc.value }))}
										isClearable
										onInputChange={() =>
											setDocumentToEdit(state => ({
												documentoId: state.documento.value,
												documento: state.documento,
												descricao: state.descricao,
												quantidadeKg: state.quantidadeKg,
												unidadeMedidaId: state.unidadeMedida.value,
												unidadeMedida: state.unidadeMedida,
												unidMedOutros: state.unidMedOutros
											}))
										}
									/>
								}
							/>
							<If condition={errorsEditDocument.editDocumento}>
								<p className="error-field">{errorsEditDocument.editDocumento?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Descrição<em>*</em>
							</Form.Label>
							<Form.Control
								ref={registerEditDocument()}
								required
								name="editDescricao"
								placeholder="Insira a descrição"
								isInvalid={errorsEditDocument.editDescricao !== undefined}
								defaultValue={documentToEdit.descricao}
							/>
							<If condition={errorsEditDocument.editDescricao}>
								<p className="error-field">{errorsEditDocument.editDescricao?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={registerEditDocument}
								name="editQuantidadeKg"
								placeholder="Insira a quantidade a ser utilizada"
								step="any"
								type="number"
								isInvalid={errorsEditDocument.editQuantidadeKg !== undefined}
								defaultValue={documentToEdit.quantidadeKg}
							/>
							<Form.Control.Feedback type="invalid">
								{errorsEditDocument.editQuantidadeKg?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Unidade Medida<em>*</em>
							</Form.Label>
							<Controller
								control={controlEditDocument}
								name="editUnidadeMedida"
								defaultValue={documentToEdit.unidadeMedida}
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
									onInputChange={() =>
										setDocumentToEdit(state => ({
											documentoId: state.documento.value,
											documento: state.documento,
											descricao: state.descricao,
											quantidadeKg: state.quantidadeKg,
											unidadeMedidaId: state.unidadeMedida.value,
											unidadeMedida: state.unidadeMedida,
											unidMedOutros: state.unidMedOutros
										}))
									}
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<If condition={watchEditDocument().editUnidadeMedida?.value === 4 || documentToEdit.unidMedOutros}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Especifique a unidade de medida transportada<em>*</em>
								</Form.Label>
								<Form.Control
									ref={registerEditDocument}
									defaultValue={documentToEdit.unidMedOutros}
									required
									name="editUnidMedOutros"
									placeholder="Insira a unidade de medida"
								/>
								<span className="error-field">
									{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
								</span>
							</Form.Group>
						</Form.Row>
					</If>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalAtivo('')}
						/>
						<Button
							title="Editar"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>
		<If condition={modalAtivo === Modals.RelatorioFotografico}>
			<Modal show={true} onHide={() => setModalAtivo('')} title={'Adicionar arquivo'}>
				<Form onSubmit={modalArquivo.handleSubmit(submitArquivos)} autoComplete="off">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Anexar Relatório Fotográfico<em>*</em>
						</Form.Label>
						<Form.File
							ref={modalArquivo.register}
							name="arquivos"
							label={lblRelatorioFotoContencao}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblRelatorioFotoContencao(`${target.files[0].name}`);
							}}
						/>
					</Form.Group>
					<div className="button-position">
						<Button title="Cancelar" contained={false} type="button" onClick={() => setModalAtivo('')} />
						<Button title="Inserir" contained={true} type="submit" />
					</div>
				</Form>
			</Modal>
		</If>
		<If condition={isConfirmation}>
			<ConfirmationModal
				show={isConfirmation}
				confirmationMsg={STRINGS_CIBIO.CONFIRM_LEFT_EDITION}
				cancelMsg={STRINGS_CIBIO.CANCEL_LEFT_EDITION}
				onConfirm={() => history.goBack()}
				onHide={() => setIsConfirmation(false)}
				title={STRINGS_CIBIO.EDITION_TITLE_CONFIRMATION}
				type="button"
				variant="danger"
			>
				{STRINGS_CIBIO.EDITION_REGISTER_CONFIRMATION_CANCEL}
			</ConfirmationModal>
		</If>
	</div>;
}