//Basic REACT Components
import React, { useState, useEffect, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

//Custom Componentes
import Button from "../../../components/Button";
import EditButton from '../../../components/EditButton';
import CancelButton from '../../../components/CancelRequisitionButton';
import ViewButton from '../../../components/ViewButton';
import api, { apiFileDownload } from '../../../services/api';
import If from "../../../components/If";
import SearchEmpty from '../../../components/SearchEmpty';
import Loading from '../../../components/Loading';
import ReasonModal from '../../../components/ReasonModal';
import AlertSuccess from '../../../components/AlertSuccess';

//Custom Services
import { DecodedToken, User } from '../../../models/user';
import jwtDecode from 'jwt-decode';
import { _generateDate } from '../../../util/convert';

import { STRINGS_ADMIN } from '../../../util/admin/strings';

//Page Specifics
import SimpleInput from 'react-simple-input';
import { UsuarioDto } from '../../../models/modelsAuthorizationsCIBIO';


export default function List ()
{
	const baseUrl: string = 'api/v1/admin/usuarios';
	const titleText: string = STRINGS_ADMIN.SEARCH_USUARIOS;

	//Base da página
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const token = String(localStorage.getItem('token'));
	const { roles, unique_name }: DecodedToken = jwtDecode(token);

	//Interações da Página
	const [error, setError] = useState<any>(null);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	const [isCancel, setIsCancel] = useState<boolean>(false);
	const [successCancel, setSuccessCancel] = useState<boolean>(false);
	const [cancelError, setCancelError] = useState<any>(null);
	const [reasonCancel, setReasonCancel] = useState('');
	
	//Campos do filtro
	const [usuarios, setUsuarios] = useState<User[]>([]);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [loadingUsuariosById, setLoadingUsuariosById] = useState<boolean>(false);
	const [usuario, setUsuario] = useState<User>();
	
	const { register, handleSubmit, watch, control } = useForm({
		defaultValues: {
			userName: '',
			nome: '',
			email: '',
            ativo: false,
		}
	})




	useEffect(() => {	
		
		const getUsuarios = async () => {
			setError(false);
			try {
				const { data } = await api.get<UsuarioDto[]>(`api/v1/admin/usuarios/usuarios_obtertodos`);
				setUsuarios(data);
			} catch (error: any) {
				setError(true);
			}
		}
		getUsuarios();
	
	}, []);

	const search = async (queries?: any) => {

		setError(false);
		setLoadingSearch(true);

		const {
			//codigo,
			nome
		} = watch();

		try {

			let params = new URLSearchParams();

			// params.append("Codigo", codigo);
			params.append("userName", queries?.userName ?? '');
            params.append("nome", queries?.nome ?? '');
            params.append("email", queries?.email ?? '');

			const { data } = await api.get<UsuarioDto[]>(`${baseUrl}/obterporfiltro?${params.toString()}`);

			if (data.length)
			{
				setUsuarios(data);
				setEmptySearch(false);
			}
			else
			{
				setEmptySearch(true);
			}
		}
		catch (error:any)
		{
			setError(true);
		}

		setLoadingSearch(false);
	}

	const cancelRequisition = async (data: any) => {
		setLoadingCancel(true);
		setCancelError(null);

		try {
			const { status } = await api.post(`${baseUrl}/${data.id}/cancelar`);
			if (status === 200) {
				setSuccessCancel(true);
				setTimeout(() => {
					setIsCancel(false);
					search();
				}, 3000);
			}
		} catch (error:any) {
			setCancelError(error.response.data);
		}
		setLoadingCancel(false);
	}

	const getUsuariosById = async (id: number) => {
		
		setLoadingUsuariosById(true);
		
		try
		{
			const { data } = await api.get(`${baseUrl}/${id}`);
			setUsuarios(data);
		}
		catch (error:any)
		{
			throw new Error(error);
		}

		setLoadingUsuariosById(false);
	}
	
	const acoes = ({ data }: any) => {

		return (
		<Fragment>
			<EditButton onClick={() => {
				history.push({
                    pathname: `/usuario/editar/`,
                    state: {
                        userName: data.userName
                    }
                })
			}} />
			&nbsp;&nbsp;
			<CancelButton onClick={() => {
				setIsCancel(true);
                getUsuariosById(data.id);
                setSuccessCancel(false);
                setReasonCancel('');
			}} />

		</Fragment>);
	}

	return (
		<div className="container-custom-search">
			<div className="top-content">
				<h3>{titleText}</h3>
			</div>
			<div className="row-buttons">
                <Button
                    onClick={() => history.push(`/usuario/cadastrar`)}
                    contained
                    title="Adicionar Usuário"
                    type="button"
				/>
			</div>
			<br />
			<Form onSubmit={handleSubmit(search)} autoComplete="off">
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>Username</Form.Label>
						<Controller
							control={control}
							name="userName"
							maxLength={150}
							render={({ onChange, value }) => (
								<SimpleInput
									defaultValue={value}
									clearButton
									className="form-control"
									onChange={onChange}
								/>
							)}
						/>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Nome 
						</Form.Label>
						<Controller
							control={control}
							name="nome"
							maxLength={150}
							render={({ onChange, value }) => (
								<SimpleInput
									defaultValue={value}
									clearButton
									className="form-control"
									onChange={onChange}
								/>
							)}
						/>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							E-mail
						</Form.Label>
						<Controller
							control={control}
							name="email"
							maxLength={150}
							render={({ onChange, value }) => (
								<SimpleInput
									defaultValue={value}
									clearButton
									className="form-control"
									onChange={onChange}
								/>
							)}
						/>
					</Form.Group>
                    <span className="filter-btn">
						<Button
							title="Buscar"
							type="submit"
							contained
							isLoading={loadingSearch}
						/>
					</span>
				</Form.Row>				
			</Form>
			<If condition={emptySearch}>
				<div className="div-empty">
					<SearchEmpty text={'Nenhum usuário disponível.'} />
				</div>
			</If>
			<If condition={loading}>
				<div className="loading-container">
					<Loading />
				</div>
			</If>
			<If condition={isCancel}>
				<ReasonModal
					show={isCancel}
					onHide={() => setIsCancel(false)}
					title={STRINGS_ADMIN.USER_DISABLE_TITLE}
					actionMsg="Confirmar"
					onConfirm={() => {
						cancelRequisition(usuario);
					}}
					loading={loadingCancel}
					disabled={false}
					hideFooter={successCancel}
				>
					<If condition={loadingUsuariosById}>
						<div className="loading-container">
							<Loading />
						</div>
					</If>
					<If condition={successCancel}>
						<AlertSuccess
							message={STRINGS_ADMIN.USER_DISABLE_TITLE}
						/>
					</If>
					<If condition={!loadingUsuariosById}>
						<Form.Label>
							<strong>{STRINGS_ADMIN.APROVE_BODY_MSG}</strong>
						</Form.Label>
					</If>
				</ReasonModal>
			</If>

			<If condition={!loading && !error && !emptySearch && usuarios.length}>
				<div className="search-content">
					<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
						<AgGridReact
							rowData={usuarios}
							defaultColDef={{ flex: 1, sortable: true, resizable: true }}
							pagination
							paginationPageSize={10}
							gridOptions={{ headerHeight: 80 }}
							frameworkComponents={{
								actions: acoes,
							}}
						>
							<AgGridColumn
								headerName='UserName'
								headerClass="myfunc-default-header"
								field='userName'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Nome'
								headerClass="myfunc-default-header"
								field='nome'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='E-mail'
								headerClass="myfunc-default-header"
								field='email'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Ativo'
								headerClass="myfunc-default-header"
								field='ativo'
								valueFormatter={({ data }: any) => data.ativo ? 'Ativo' : 'Inativo'  }
							/>			
                            <AgGridColumn
                                headerName="Ações"
                                cellRenderer="actions"
                                maxWidth={100}
                            />				
						</AgGridReact>
					</div>
				</div>
			</If>
		</div>
	);
}