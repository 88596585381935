export const STRINGS = {
	FORM_FIELD_REQUIRED: "Campo obrigatório",
	FORM_EMAIL_INVALID: "Informe um e-mail válido",
	ZIP_CODE_ERROR: "CEP inválido",

	WARNING_PDFS: "Permitido upload apenas de arquivos .pdf",

	ALERT_ERROR: "Atenção!",
	ALERT_SUCCESS_TITLE: "Sucesso",
	ALERT_WARNING_FIELDS: "Campos obrigatórios devem ser preenchidos",
	ALERT_MESSAGE_SUCCESS: "O registro foi salvo com sucesso",
	ALERT_CULTURE_SUCCESS: "A cultura foi salva com sucesso",
	ALERT_FILES_SUCCESS: "Os arquivos foram salvos com sucesso",
	ALERT_FILES_ERROR: "Ocorreu um erro nos servidores ao cadastrar os arquivos",
	ALERT_GENERIC_ERROR: "Ocorreu um erro com os servidores",
	ALERT_MESSAGE_SUCCESS_EDIT: "A edição foi salva com sucesso",
	TITLE_REGISTER_REQUESTER: "Cadastro de requisitante",
	TTTLE_OPERATING_UNITIES: "Cadastro de unidades operativas/Lotes de apoio",
	TITLE_REGULATORY_BODIES: "Cadastro de órgãos regulatórios",
	TITLE_CULTURE: "Cadastro de cultura",
	TITLE_GROUNDS_TYPE: "Cadastro de tipos de solo",
	TITLE_MATERIAL_CATEGORIES: "Cadastro de categorias do material",

	// Navbar texts
	NAV_HOME: "Início",
	NAV_OPTIONS: "Opções",
	NAV_RECORD: "Registros",
	NAV_REGISTER_GENERAL: "Cadastros Gerais",
	NAV_REGISTERS: "Cadastros",
	NAV_SEARCH: "Consulta",
	NAV_REQUESTER: "Requisitante",
	NAV_UNITYS_LOTS: "Unidades Operativas/Lotes de Apoio",
	NAV_ORGANS: "Orgãos Reguladores",
	NAV_CULTURE: "Cultura",
	NAV_GROUNDS_TYPE: "Tipos de solo",
	NAV_MATERIAL_CATEGORIES: "Categorias do material",
	NAV_DOCS: "Documentos",
	NAV_APPLICANT: "Requerente",
	NAV_RECORD_TYPES: "Tipos de registro",
	NAV_EXTERNAL_ORGANS: "Órgãos Externos",
	NAV_CULTIVARS: "Cultivares",
	NAV_GENERALS: "Gerais",
	NAV_EVENTS: "Eventos",
	NAV_TECHNICAL_TEAM: "Equipe técnica",
	NAV_BREEDER: "Melhorista",
	NAV_INSTITUTION_DATA: "Instituição responsável pelo ensaio",
	NAV_CULTIVARS_SEARCH: "Cultivares",
	NAV_REQUEST: "Requisições",
	NAV_CULTIVARS_PROTECTION: "Proteção - SNPC",
	NAV_CULTIVARS_REGISTER: "Registro - RNC",
	NAV_REGISTER_USER: "Cadastrar usuário",
	NAV_LOGOUT: "Sair",

	//Select 
	SELECT_AREA: "Escolha a área",

	//route params
	ROUTE_BREEDER: "melhorista",
	ROUTE_TECHNICAL_TEAM: "equipe-tecnica",

	//Response Error
	RESPONSE_ERROR_REGISTER: "Ocorreu um erro ao cadastrar",
	RESPONSE_ERROR_EDIT: "Ocorreu um erro ao editar",

	//Empty Search
	EMPTY_SEARCH: "Nenhum resultado foi encontrado...",

	//Modal
	CONFIRMATION_TITLE: "Atenção!",
	CONFIRMATION_MSG: "Tem certeza que deseja excluir?",
	REASON_MODAL_TITLE: "Deseja cancelar essa requisição?",
	REASON_MODAL_MSG: "Essa operação não poderá ser desfeita. Insira o motivo do cancelamento abaixo:",
	REASON_MODAL_ACTION: "Confirmar",
	REASON_REPROVE_TITLE: "Deseja reprovar essa requisição?",
	REASON_REPROVE_MSG: "Essa operação não poderá ser desfeita. Insira o motivo da reprovação abaixo:",
	REASON_REPROVE_ACTION: "Reprovar",
	APROVE_TITLE: "Deseja aprovar requisição?",
	APROVE_MSG: "Esta operação não poderá ser desfeita.",
	APROVE_CONFIRMATION: "Aprovar",
	APROVE_SUCCESS: "Requisição aprovada com sucesso",
	REPROVE_SUCCESS: "Requisição reprovada com sucesso",
	SUCCESS_SG_FITO: "Enviada para SG/FITO com sucesso",
	SUCCESS_SG_FITO_SKIP: "Enviada com sucesso sem passar por Colaboradores Descritores e Colaboradores de Doenças",
	REGULATORY_TITLE: "Deseja enviar a Requisição para Análise do Regulatório?",
	REGULATORY_MESSAGE: "Essa operação não poderá ser desfeita.",
	REGULATORY_ACTION: "Enviar",
	REGULATORY_SUCCESS: "Enviada para o regulatório com sucesso",
	AMI_SUBMIT_SUCCESS: "Autorização salva com sucesso",
	VALIDATE_SUCCESS: "Validação realizada com sucesso",

	USER_REGISTERED: "Usuário cadastrado com sucesso",
	NAVBAR_ADMIN: "Administração",
	NAV_USER: "Usuários",
	NAV_USER_GROUP: "Grupo de Permissão",

	MODAL_PROTOCOL_TITLE: "Informar protocolo",
	MODAL_PROTOCOL_MESSAGE_CONFIRMATION: "Essa operação não poderá ser desfeita",
	MODAL_PROTOCOL_SUCCESS: "Protocolo enviado com sucesso",

	MODAL_REGISTRO_MAPA_TITLE: "Informar Registro MAPA",
	MODAL_REGISTRO_MESSAGE_CONFIRMATION: "Essa operação não poderá ser desfeita",
	MODAL_REGISTRO_SUCCESS: "Registro enviado com sucesso",

	EMPTY_BOX_PROTOCOL: "Protocolo não informado ainda",
	EMPTY_BOX_MAPA: "Registro MAPA não informado ainda",
	EMPTY_INFO_ARRAY: "Nenhum resultado encontrado",

	ERROR_LOGIN_401: "Usuário não cadastrado",
	ERROR_LOGIN_500: "Ocorreu um erro com os servidores",

	SUCCESS_DRAFT: "Salvo com sucesso"
}