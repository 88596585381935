import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import AlertSuccess from '../../components/AlertSuccess';
import EditButton, { InsertButton } from '../../components/EditButton';
import DropDown from '../../components/form/DropDown';
import TextField from '../../components/form/TextField';
import Loading, { LoadingIf } from '../../components/Loading';
import ListingView, { ListCol } from "../../components/page/ListingView";

import ReasonModal from '../../components/ReasonModal';
import ViewButton from '../../components/ViewButton';
import PDFButton 	from '../../components/GeneratePDF';
import CancelButton from '../../components/CancelRequisitionButton';

import { CqbDto, CqbEquipeTecnica, CqbLocal } from '../../models/modelsCQB';
import { _generateDate } from '../../util/convert';
import { STRINGS_CQB } from "../../util/cqb/string";
import { STATUS, STATUSTEXT } from '../../util/lpma/status';
import CqbService, { RolesService } from './services';
import { listEnumItem } from '../../models/modelCommum';
import { DialogEditSingle } from '../../components/form/DialogEdit';
import CheckBox from '../../components/form/CheckBox';
import If from '../../components/If';

export default function List (){
	const CqbServiceApi = new CqbService();
	const CqbRoles		 = new RolesService(useContext);
	const history = useHistory();
	const goUrl = (url: string)=> {
		history.push({pathname: url,});
	}

	const nomeSolicitante =()=>{
		return (CqbRoles.isAdmin() || CqbRoles.isRegulatorio()) ? ''  : CqbRoles.getUserName();
	}

    const defaultForm = useForm({
		defaultValues: {
            titulo		: 	'',
			solicitante	: 	nomeSolicitante(),
			local		: 	{value: null, label: null},
			tecnicoPrincipal: {value: null, label: null},
			status		:  	{value: null, label: null},
			tipo		:  	{value: null, label: null},
			documentConfigList : [],
			numeroAutorizacao: '',
        }// as CqbDto,
	})	
	const [loading, setLoading] = useState<boolean>(false);

	const [successCancel, setSuccessCancel] = useState<boolean>(false);
	const [loadingById, setLoadingById] = useState<boolean>(false);
	const [reasonCancel, setReasonCancel] = useState('');
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	const [isCancel, setIsCancel] = useState<boolean>(false);
	const [itemSel, setItemSel] = useState<any>();
	const [loadingButPdf, setLoadingButPdf] = useState<boolean>(false);
	const [defaultData, setDefaultData] = useState<any>({});	
	const [refreshFilter, setRefreshFilter] = useState<boolean>(false);

	React.useEffect(() => {
        if(refreshFilter === true) { setRefreshFilter(false); }
    }, [refreshFilter]);

	const CancelPopUp = () =>{
		return <ReasonModal
				show={isCancel}
				onHide={() => setIsCancel(false)}
				title={STRINGS_CQB.CANCEL_TITLE}
				actionMsg="Confirmar"
				onConfirm={() => { cancelCQB(itemSel); }}
				loading={loadingCancel}
				disabled={false}
				hideFooter={successCancel}
			>
				<LoadingIf condition={loadingById} />
				{/*TODO:remover {loadingById ? 
					<div className="loading-container">
						<Loading />
					</div>
				: ''} */}
				<If condition={successCancel}>
					<AlertSuccess
						message={STRINGS_CQB.CANCEL_SUCCESS}
					/>
				</If>
				<If condition={!loadingById}>
					<Form.Label>
					<strong>{STRINGS_CQB.APROVE_BODY_MSG}</strong>
					</Form.Label>
					<Form.Control
						onChange={({ target }: any) =>
							setReasonCancel(target.value)
						}
						value={reasonCancel}
						as="textarea"
						maxLength={200}
					/>
				</If>
			</ReasonModal>	;
	}

	const DocumentConfigCQB = async (dados?: any) =>{		
		return await UpdateCQB(
			'update_config', 
			{...dados, documentConfigList: dados.documentConfigList}
			//{...dados, documentConfigList :  defaultForm.getValues()?.documentConfigList?.map((e:any)=> parseInt(e))}
			);		
	};

	const cancelCQB = async (dados?: any) =>{	
		setLoadingCancel(true);	
		return UpdateCQB(
			'cancelar', 
			{...dados, motivoCancelamento :  reasonCancel},
			(sucesso:boolean)=>{ 
				if(sucesso) 	{setSuccessCancel(false);}	
				if(!sucesso) 	{setLoadingCancel(false);}							
			},
			()=>{ setIsCancel(false); setLoadingCancel(false); }
			);
	};

	const UpdateCQB = async (acao: string, dados?: any, finish:any = null, timeOutFinish: any = null) =>{		
		try {
			let saveDados = {...dados};// as CqbDto;

			let keysToIgnore = ['documentConfig'];
			let formData = new FormData();
			for (var key in saveDados) {
				if (keysToIgnore.includes(key) || !saveDados[key]) { continue; }
				const valueByKey = saveDados[key];
				if(key === 'finalidadeList' || key === 'atividadeList' || key === 'documentConfigList'){
					for(let i = 0, t = valueByKey.length; i < t; i ++){
						formData.append(`${key}[${i}]`, valueByKey[i]);  
					}					
					continue;
				}
				formData.append(key, saveDados[key]);
			}
			saveDados = await CqbServiceApi.getCQBAction(acao, formData);
			if(finish != null) {finish(true);}
			setRefreshFilter(true);
			setTimeout(() => {				
				if(timeOutFinish != null) {timeOutFinish();}						
			}, 3000);			
			return true;
		} catch (error:any) {
			if(finish != null) {finish(false);}
			console.error("erro", error);
			return false;
		}
	};

	const btnNumeroAutorizacao = (data:any)=>{
		if(!(CqbRoles.isAdmin() || CqbRoles.isRegulatorio())) { return; }
		return <DialogEditSingle label="Infomar Número de Autorização CIBIO" 	
		dlgTitle={<span className="singleTitle">{`${STRINGS_CQB.MODO_NUMER_AUTORIZ_DLG}`}<br />
		{`Código da Solicitação: ${data.id} - ${(data.numeroAutorizacao ?? '')}`}</span>}			
		isButtonType='add' 
		width25
		enabled={true}
		popData={data}
		onShow={(pdata:any)=>{
			setItemSel(pdata);		
			defaultForm.setValue("numeroAutorizacao", pdata.numeroAutorizacao);
		}}
		onConfirme={async ()=>{
			const idSel = defaultForm.watch('numeroAutorizacao');
			const sedData = {...itemSel, ...data, numeroAutorizacao: idSel}
			// return await DocumentConfigCQB(sedData);
			const updateCQB = await UpdateCQB(
				'update_config_num_aut', 
				{...sedData, numeroAutorizacao: sedData.numeroAutorizacao}
				);		
			return updateCQB;
		}}>						
		<TextField<CqbDto> label={STRINGS_CQB.MODO_NUMER_AUTORIZ}
		form={defaultForm} name="numeroAutorizacao" length={150}
		defaultValue={data.numeroAutorizacao}
		isClearable enabled={true} layoutColumns={12}  />				
		</DialogEditSingle>
	}
    
	
	return <ListingView<CqbDto>
	ModalPopups = {CancelPopUp()}
	title	=	{STRINGS_CQB.SEARCH_CQB}	
	ButtonAddText="Nova Solicitação CQB"
	form={defaultForm}
	actionsMinWidth={180}
	OnInit={async ()=>{
			const equipeTecnica = await CqbServiceApi.getEquipeTecnica();
			const locais		= await CqbServiceApi.getLocais();
			const defaultDataR 	= await CqbServiceApi.getDefaultData();
			const dd = {
				equipeTecnica	: equipeTecnica,
				locais			: locais,
				tipoCqb			: defaultDataR.tipoCqb,
				atividades		: defaultDataR.atividades,
				finalidades		: defaultDataR.finalidades,
				documentConfig	: defaultDataR.documentConfig,
			};
			setDefaultData(dd);
			return dd;
	}}
	onFilterSearch = {async (queries?: any)=>{

		let filtro = {
			tipo					: defaultForm.getValues('tipo')?.value,
			localId               	: defaultForm.getValues('local')?.value,
			//tecnicoPrincipalId      : defaultForm.getValues('tecnicoPrincipal')?.value,
			status					: defaultForm.getValues('status')?.value,
			solicitante				: defaultForm.getValues('solicitante'),
		}; //as CqbDto;
		let retApi = await CqbServiceApi.getList(filtro);
		const rApi = retApi.map((e:any)=>{
			return {...e, numeroProcessoCIBIO: <div>TESTE</div>}
		});
		return rApi;
	}}
	ShowButtonAddNew = {CqbRoles.isCreatRequisicao()}
	onButtonAddEvent = {()=>{ history.push("/cqb/nova") }}	
	filterRefresh={refreshFilter}
	filters={[
		<TextField name="solicitante" label="Solicitante/Requisinate" form={defaultForm}
		defaultValue={nomeSolicitante()}
		isClearable
		enabled={CqbRoles.isAdmin() || CqbRoles.isRegulatorio()}
		layoutColumns={6}  />
		,
		<DropDown name="tipo" label="TIPO" layoutColumns={4} form={defaultForm}
		options={defaultData?.tipoCqb?.map((val:any, index: any)=>  Object.assign({ value: val.id, label: val.value } ) )}
		isClearable={true}
		/>
		,
		<DropDown name="local" label="Local Proponente" layoutColumns={6} form={defaultForm}
		options={defaultData?.locais?.map((i:any) => Object.assign({ value: i.id, label: `${i.nomeDaFazenda} - ${i.municipio}/${i.uf}` }))}  
		isClearable={true}
		/>,
		<DropDown name="status" label="STATUS do CQB" layoutColumns={4} form={defaultForm}
		options={STATUSTEXT.map((val:any, index: any)=>  Object.assign({ value: val.s, label: val.t } ) )}
		isClearable={true}
		/>	
	 ]}
	 onListItemActionsButtons = {( data : any) =>{
		let estagio_ = data.status ?? STATUS.EmPreenchimento;
		let isEditavel = (CqbRoles.isRegulatorio() 
			&& (estagio_ === STATUS.EmAnalisePeloRegulatorio || estagio_ === STATUS.Concluido))
		|| ((CqbRoles.isEditRequisicao() || CqbRoles.isCreatRequisicao()) 
			&& (estagio_ === STATUS.EmPreenchimento || estagio_ === STATUS.EmCorrecao))
		|| CqbRoles.isAdmin();

		let isCancel = 	isEditavel && estagio_ !== STATUS.Cancelado;

		return (
				<Fragment>			
					<EditButton disable={!isEditavel} 
					onClick={() => { goUrl(`/cqb/editar/${data.id}`);}} />
					&nbsp;&nbsp;
					<ViewButton 
					onClick={() => { goUrl(`/cqb/visualizar/${data.id}`); }} />
				
					&nbsp;&nbsp;
					<CancelButton 
					disabled={!isCancel} 
					onClick={() => {
						setIsCancel(true);
						setItemSel(data);
						setSuccessCancel(false);
						setReasonCancel('');
					}} /> 
										
					&nbsp;&nbsp;					
					<DialogEditSingle label="Configurar" 	
					dlgTitle={<><h5 style={{width:'100%'}}>{`${STRINGS_CQB.MODO_CONFIGURAR_DLG}`}</h5>
					<h5>{`Código da Solicitação: ${data.id}`}</h5></>}			
					isButtonType='config' 
					width50
					enabled={isEditavel}
					popData={data}
					onShow={(pdata:any)=>{	
						setItemSel(pdata);		
						defaultForm.setValue("documentConfigList", data.documentConfigList);
					}}
					onConfirme={async ()=>{
						const idSel = defaultForm.watch('documentConfigList')?.map((e:any)=> parseInt(e));;
						const sedData = {...itemSel, ...data, documentConfigList: idSel}
						return await DocumentConfigCQB(sedData);
					}}>
					<label>{STRINGS_CQB.MODO_CONFIGURAR}</label>								
					{defaultData.documentConfig?.map((e:listEnumItem)=>{
						return <CheckBox form={defaultForm}  name="documentConfigList"
						{...defaultForm.register(`documentConfigList`)}
						layoutColumns={12}		
						label={e.nome} value={e.id}	
						enabled={isEditavel} 
						onChange={(chk: any)=>{e.checked = chk}}
						defaultChecked={data.documentConfigList?.includes(e.id)}
					/>})}				
					</DialogEditSingle>

					&nbsp;&nbsp;
					{loadingButPdf ? '' :
					<PDFButton  onClick={async () => { 
						setLoadingButPdf(true); 
						await CqbServiceApi.DownloadCQBPDF(data.id, data.documentoFileName); 
						setLoadingButPdf(false);
					}} /> }


				</Fragment>);
	 }}	
	>		

	<ListCol
		header='Cód.'
		field='id'
		maxWidth={75}
		headerClass="myfunc-default-header"
		sort="asc"
	/>	

	<ListCol
		header='Solicitante/ Requisitante'
		field='solicitante'
		headerClass="myfunc-default-header"
	/>

	<ListCol
		maxWidth={100}
		header='Tipo de CQB'
		field='tipo'
		headerClass="myfunc-default-header"
		valueFormatter={({ data }: any) => defaultData?.tipoCqb?.find((e:any)=> e.id === (data?.tipo ?? 1))?.nome  }
	/>

	<ListCol
		header='Local Proponente/Tecnico Responsável'
		field='local'
		headerClass="myfunc-default-header"
		valueFormatter={({ data }: any) => `${(data?.local?.nomeDaFazenda ?? '(Localidade não definida)')}/${(data?.tecnicoPrincipal?.nome ?? '')}`  }
		cellStyle={{
			justifyContent: 'left'
		}}
		wrapText={true}
		minWidth={275}
	/>

	{/* <ListCol
		header='Tecnico Responsável'
		headerClass="myfunc-default-header"
		field='tecnicoPrincipal'
		valueFormatter={({ data }: any) => data?.tecnicoPrincipal?.nome ?? ''}
		cellStyle={{
			justifyContent: 'left'
		}}
	/>	 */}

	<ListCol
		header='Data da Aprovação'
		field='dataAprovacao'
		headerClass="myfunc-default-header"
		maxWidth={130}
		valueFormatter={({ data }: any) => data.dataAprovacao ? _generateDate(data.dataAprovacao, 'Pt') : ''}
	/>	

	<ListCol
		header='Nº Controle'
		headerClass="myfunc-default-header"
		field='numeroProcessoEAno'
	/>

	<ListCol
		header='Nº Processo CIBIO'
		headerClass="myfunc-default-header"
		field='numeroAutorizacao'
		cellRenderer={({data}:any)=>{
			if(data.status === STATUS.Aprovado){// && (!data.numeroAutorizacao || data.numeroAutorizacao?.length === 0)){
				return <span>{data.numeroAutorizacao}&nbsp;&nbsp;{btnNumeroAutorizacao(data)}</span>;
			}
			//else if( !data.numeroAutorizacao || data.numeroAutorizacao?.length === 0) 
			return "";
			//return <span>{data.numeroAutorizacao}</span>;
		}}
		minWidth={150}
		cellStyle={{
			justifyContent: (CqbRoles.isAdmin() || CqbRoles.isRegulatorio()) ? 'right' : 'center'
		}}
	/>

	<ListCol
		header='Status'
		headerClass="myfunc-default-header"
		field='status'
		valueFormatter={({ data }: any) => STATUSTEXT.find(a=> a.s === (data.status ?? 1))?.t }
		cellStyle={{
			justifyContent: 'left'
		}}
		wrapText={true}
		minWidth={100}
	/>	

	</ListingView>
}
