import React from "react";
import { Form, Col } from "react-bootstrap";
import RichTextEditor, {ToolbarConfig} from 'react-rte';
import "./style.scss";
import { uuid } from "../../services/uuid";
import CheckMsgErros from "./_msgErrs";

interface Props<Type>
{
	form? 		: any;//UseFormMethods<Type>;
	
	name? 		: string;
	label?			: any;
	placeholder?   	: 	string;
	adicionalText? 	: 	string;
	length? 		: 	number;
	rows? 			: 	number;
	required? 		: 	boolean;
	readonly? 		: 	boolean;
	layoutColumns? 	: 	number;
	enabled?		:	boolean;
	key?			: 	any;
	defaultValue?	: 	string;
	onInitValue?	: 	any;
	isInvalidCheck? : 	any;
	nolabel			?:	boolean;
	posText			?: 	any | null;
}

export default function RichText<Type> (props: Props<Type>)
{
	const defaultName = `RichText-${uuid()}`;
	const [enabled_, setEnabled] = React.useState<boolean>(true);
	const [value_, setValue] = React.useState(RichTextEditor.createEmptyValue());
	const [init_, setInit_] = React.useState<boolean>(false);
	const [asErro, setAsErro] = React.useState<boolean>(true);
	const [msgError, setMsgError] = React.useState<any>();
	const myRef = React.useRef(null);

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    },[props.enabled]);

	React.useEffect(() => {		
		if(props?.name == null || init_ === true) {return;}				
		props.form.register(props.name)
		const val = props.form.getValues(props.name)
		if(val === undefined) { return; }
		setValue(RichTextEditor.createValueFromString((val ?? ""), "html"));
		setInit_(true);
	},[init_, props.form, props.name]);

	React.useEffect(() => {	
		const val = RichTextEditor.createValueFromString((props.defaultValue ?? ""), "html");
		onChange(val);

	},[props.defaultValue]);

	React.useEffect(() => {	
		if(props.onInitValue == null) {return;}
		const val = RichTextEditor.createValueFromString((props.onInitValue() ?? ""), "html");
		onChange(val);
	},[props.onInitValue]);

	// React.useEffect(() => {
    //     let _asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
    //     setAsErro(_asErro)

    // }, [props.form?.errors, props?.name]);

	React.useEffect(() => {
		CheckMsgErros(props, (msg:any)=>{ setMsgError(msg) }, (err:any)=>{ 
			setAsErro(err) 
			if(err && props.isInvalidCheck){
				if(myRef != null) {
					let elmt = myRef.current as any;
					const yOffset = -100; 
					const y = elmt.getBoundingClientRect().top + window.pageYOffset + yOffset;
					window.scrollTo({top: y, behavior: 'smooth'});
					
					//window.scrollTo(0, elmt?.offsetTop - 100)   
					//elmt?.scrollIntoView({ top: elmt?.offsetTop - 100, behavior: 'smooth', block: 'start', inline: "start" }, 500);
				}
			}
		})
        // let _asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
        // setAsErro(_asErro)
    }, [props.form?.errors, props?.name]);

	const onChange = (value:any)=>{	
		setInit_(true);
		setValue(value); 
		if(props.form !== null && props?.name !== null)
		{
			let valueEnd = (value?.toString('html') ?? "");
			if(valueEnd === '<p><br></p>') { valueEnd = ''; }
			props.form.setValue(props.name, valueEnd);
		}
	};
	
	const toolbarConfig = {
		// Optionally specify the groups to display (displayed in the order listed).
		display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
		INLINE_STYLE_BUTTONS: [
		  {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
		  {label: 'Italico', style: 'ITALIC', className: 'custom-css-class'},
		  {label: 'Sublinhado', style: 'UNDERLINE', className: 'custom-css-class'}
		],
		BLOCK_TYPE_DROPDOWN: [
		  {label: 'Normal', style: 'unstyled', className: 'custom-css-class'},
		  {label: 'Grande (Heading Large)', style: 'header-one', className: 'custom-css-class'},
		  {label: 'Médio (Heading Medium)', style: 'header-two', className: 'custom-css-class'},
		  {label: 'Pequeno (Heading Small)', style: 'header-three', className: 'custom-css-class'}
		],
		BLOCK_TYPE_BUTTONS: [
		  {label: 'UL', style: 'unordered-list-item', className: 'custom-css-class'},
		  {label: 'OL', style: 'ordered-list-item', className: 'custom-css-class'}
		]
	  } as ToolbarConfig;

	return <Form.Group ref={myRef}  className='richText' as={Col} md={props.layoutColumns ?? 12}>
		<Form.Label>
			{props.nolabel === true ? '' : <>
			{props.label} {props.required ? <em>*</em> : ''}
			</>}
			{(props.isInvalidCheck && asErro)? 
			<p className="error-field">{props?.name ? ( msgError ??  props.form?.errors[props?.name]?.message) : ''}</p>
			:''}
		</Form.Label>
		<RichTextEditor		
				className={(!enabled_ ? 'disabled-color' : '') + " editor "}			
				toolbarConfig={toolbarConfig}
				key={defaultName}
				ref={props?.form?.register}
				value={value_}
				onChange={onChange}				
				disabled={!enabled_}
				editorClassName="editor"
			/>
		{props.posText ? <label className="posText">{props.posText}</label> : ''}
	</Form.Group>;
}