import React, { Fragment } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { colors } from '../util/colors';

interface LoadingProps
{
	center?					: boolean;
	mensagem?				: string | any;
	lg?						: any;
}

interface LoadingIfProps
{
	condition				: boolean
}

export default function Loading (props:LoadingProps)
{
	let style : React.CSSProperties = props.center === true ? {width: '32px', marginLeft: '-16px', position: 'relative', left: '50%'} : {};

	return <Col lg={props.lg ?? 1} className="loader-container" style={style}>
		<Spinner animation="grow" style={{backgroundColor: colors.green5}} />
		<span>{props.mensagem ?? 'Carregando...'}</span>
	</Col>;
}


export function LoadingIf (props: LoadingIfProps) {
	
	if(!props.condition)
		return <Fragment />;

	return <div className="loading-container">
		<Loading />
	</div>;
}