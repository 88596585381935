import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import TextField from '../../../components/form/TextField';
import ListingView, { ListCol } from "../../../components/page/ListingView";
import { LpmaEquipeTecnica } from '../../../models/modelsLPMA';
import { STRINGS_LPMA } from "../../../util/lpma/string";
import LpmaService from '../services';

export default function List (){
	const LpmaServiceApi = new LpmaService();
	const history = useHistory();


    const defaultForm = useForm({
		defaultValues: {
			id: 0,
			codigo: '',
			nome: '',
			documento: '',
			link_CV_Lattes: '',
			endereco: '',
			telefone: '',
			email: '',
			ativo: true,
        } as LpmaEquipeTecnica,
	})	
    
	
	return <ListingView<LpmaEquipeTecnica>
	title	=	{STRINGS_LPMA.SEARCH_EQUIPE_TECNICA}	
	form={defaultForm}
	onEditEvent = {(data:any )=>{  history.push({
		pathname: `/lpma/equipe-tecnica/editar/${data.id}`,						
		})
	 }}

	 ShowButtonAddNew={true}
	 onButtonAddEvent = {()=>{ history.push("/lpma/equipe-tecnica/novo") }}
	 ButtonAddText="Novo Técnico"

	 filters={[
		<TextField<LpmaEquipeTecnica> form={defaultForm} layoutColumns={4} name="nome" 
		label="Nome" required={false}  isClearable={true}
		/>,
		<TextField<LpmaEquipeTecnica> form={defaultForm} layoutColumns={6} name="documento" 
		label="Documento/CPF/CREA" required={false}  isClearable={true}
		/>
	 ]}

	onFilterSearch = {async (queries?: any)=>{
		// console.log(queries);
		let filtro = {
			...defaultForm.getValues(),
			...queries
		}
		return await LpmaServiceApi.getEquipeTecnica(filtro as LpmaEquipeTecnica);
	}}
	>		

	<ListCol
		header='Código'
		field='id'
		maxWidth={100}
	/>	

	<ListCol
		header='Nome'
		field='nome'
		cellStyle={{
			justifyContent: 'left'
		}}
	/>			

	<ListCol
		header='E-Mail'
		field='email'
		cellStyle={{
			justifyContent: 'left'
		}}
	/>	

	<ListCol
		header='Documento/CPF/CREA'
		field='documento'
	/>			

	<ListCol
		header='Curriculo Lates'
		field='link_CV_Lattes'	
		cellStyle={{
			justifyContent: 'left'
		}}							
	/>	

	</ListingView>
}