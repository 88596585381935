import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Col, Row } from 'react-bootstrap';
import AddButton from '../../../components/AddButton';
import Button from '../../../components/Button';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import LoadingInForm from '../../../components/LoadingInForm';
import NotAvailableModel from '../../../components/NotAvailableModel';
import GenericButton from '../../../components/FlowGenericButton';
import FileExistent from '../../../components/FileExistent';
import ReasonModal from '../../../components/ReasonModal';
import { STRINGS } from '../../../util/strings';
import {
	CultureResults,
	RecordTypesResults,
	ApplicantResults,
	EventsResult,
	BreederResults,// BreederResults,
	InstitutionResults,
	FilesTypeResults,
	DescriptorsResults,
	CharacteristicsDescriptors,
	DiseasesReactionsResults,
	DiseasesResults,
	RequestRegisterMelhoristas,
	RequestRegisterApplicants,
	RequestRegisterRegions,
	ExpectedDescriptors,
	ExpectedDisease,
	ModelFileRegister,
	ModelsFiles,
	CultivaresRegistroResponse,
	DescritoresResponseRegistro,
	DiseasesResponseRegistro,
	Regions,
	AdditionalsFilesRegistroUpdate,
	ColaboratorUser,
	Status,
	RegistroInfoValidation,
} from '../../../models/models';
import api, { apiFileDownload } from '../../../services/api';
import Loading from '../../../components/Loading';
import ValidateRequisition from '../ValidateRequisition';
import ConfirmationModal from '../../../components/ConfirmationModal';
import EmptyBox from '../../../components/EmptyBox';
import { isDescriptors } from '../../../users/isDescriptors';
import { isDiseases } from '../../../users/isDiseases';
import { isAdmin } from '../../../users/isAdmin';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from 'react-datepicker';
import { STATUS_REGISTER } from '../../../util/status';
import { isRequesterWriteRegister } from '../../../users/isRequester';
import { isReguladorRegister } from '../../../users/isRegulador';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../../../models/user';
import { AxiosResponse } from 'axios';
import '../styles_edit.css';
import { hasUncaughtExceptionCaptureCallback } from 'process';
import { _generateDate } from '../../../util/convert';

import Group_10 from './group_10';
import Group_12 from './group_12';
import EditButton from '../../../components/EditButton';

registerLocale('pt-BR', ptBR);

interface AprovationData
{
	need	: boolean;
	date	: Date | null;
	user	: string | null;
}

export default function RegisterRNC ()
{
	const [aproveDataDescritores, setAproveDataDescritores] = useState<AprovationData>({need:false, date:null, user:null});
	const [aproveDataDoencas, setAproveDataDoencas] = useState<AprovationData>({need:false, date:null, user:null});
	const history = useHistory();
	const location = useLocation<{id:number}>();
	const token = String(localStorage.getItem('token'));
	const { roles }: DecodedToken = jwtDecode(token);
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [protectedCultivar, setProtectedCultivar] = useState<boolean>(false);
	const [transferredCultivar, setTransferredCultivar] = useState<boolean>(false);
	const [foreignCultivar, setForeignCultivar] = useState<boolean>(false);
	const [essentiallyDerived, setEssentiallyDerived] = useState<boolean>(false);
	const [modifiedOrganism, setModifiedOrganism] = useState<boolean>(false);
	const [chooseApplicant, setChooseApplicant] = useState<boolean>(false);
	const [selectedApplicants, setSelectedApplicants] = useState<Array<ApplicantResults>>([]);
	const [unselectedApplicants, setUnselectedApplicants] = useState<Array<ApplicantResults>>([]);
	const [choosenApplicants, setChoosenApplicants] = useState<Array<ApplicantResults>>([]);
	const [inserApplicants, setInsertApplicants] = useState<boolean>(false);
	const [insertDescriptors, setInsertDescriptors] = useState<boolean>(false);
	const [insertDiseases, setInsertDiseases] = useState<boolean>(false);
	const [choosenDescriptors, setChoosenDescriptors] = useState<boolean>(false);
	const [descriptorsByCulture, setDescriptorsByCulture] = useState<Array<DescriptorsResults>>([]);
	const [characteristicsByDescriptor, setCharacteristicsByDescriptor] = useState<Array<CharacteristicsDescriptors>>([]);
	const [selectedDescriptors, setSelectedDescriptors] = useState<Array<DescritoresResponseRegistro>>([]);
	const [choosenDiseases, setChoosenDiseases] = useState<boolean>(false);
	const [diseasesByCulture, setDiseasesByCulture] = useState<Array<DiseasesResults>>([]);
	const [diseasesReactions, setDiseasesReactions] = useState<Array<DiseasesReactionsResults>>([]);
	const [selectedDiseasesOrNematodes, setSelectedDiseasesOrNematodes] = useState<Array<DiseasesResponseRegistro>>([]);
	const [selectedFilesAdditionals, setSelectedFilesAdditionals] = useState<Array<any>>([]);
	const [labelAdditionalFiles, setLabelAdditionalFiles] = useState('Escolher arquivo');
	const [lblAdaptationRegions, setLblAdaptationRegions] = useState('Escolher arquivo');
	const [lblAgronomicCharacteristics, setLblAgronomicCharacteristics] = useState('Escolher arquivo');
	const [lblProductivityAssessment, setLblProductivityAssessment] = useState('Escolher arquivo');
	const [lblQualityAssessment, setLblQualityAssessment] = useState('Escolher arquivo');
	const [lblTechnicalObtainingReport, setLblTechnicalObtainingReport] = useState('Escolher arquivo');
	const [lblBreederStatement, setLblBreederStatement] = useState('Escolher arquivo');
	const [insertAdaptationRegions, setInsertAdaptationRegions] = useState<boolean>(false);
	const [chooseAdaptationRegions, setChooseAdaptationRegions] = useState<boolean>(false);
	const [unselectedAdaptationRegions, setUnselectedAdaptationRegions] = useState<Array<Regions>>([]);
	const [choosenAdaptationRegions, setChoosenAdaptationRegions] = useState<Array<Regions>>([]);
	const [selectedAdaptationRegions, setSelectedAdaptationRegions] = useState<Array<Regions>>([]);
	const [chooseBreeder, setChooseBreeder] = useState<boolean>(false);
	const [insertBreeder, setInsertBreeder] = useState<boolean>(false);
	const [selectedBreeder, setSelectedBreeder] = useState<Array<BreederResults>>([]);
	const [unselectedBreeder, setUnselectedBreeder] = useState<Array<BreederResults>>([]);
	const [choosenBreeder, setChoosenBreeder] = useState<Array<BreederResults>>([]);
	const [submited, setSubmited] = useState<boolean>(false);
	const [errorOnSubmit, setErrorOnSubmit] = useState<any>(null);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [culture, setCulture] = useState<CultureResults[]>([]);
	const [cultureDetails, setCultureDetails] = useState<CultureResults>();
	const [recordsType, setRecordsType] = useState<RecordTypesResults[]>([]);
	const [applicants, setApplicants] = useState<ApplicantResults[]>([]);
	const [events, setEvents] = useState<EventsResult[]>([]);
	const [breeders, setBreeders] = useState<BreederResults[]>([]);
	const [regions, setRegions] = useState<Regions[]>([]);
	const [institutions, setInstitutions] = useState<InstitutionResults[]>([]);
	const [selectedInstitution, setSelectedInstitution] = useState<InstitutionResults>();
	const [filesType, setFilesType] = useState<FilesTypeResults[]>([]);
	const [loadingNeededData, setLoadingNeededData] = useState<boolean>(true);
	const [errorGetNeededData, setErrorGetNeededData] = useState<boolean>(false);
	const [fieldApplicantResponsible, setFieldApplicantResponsible] = useState<boolean>(false);
	const [fieldInstitution, setFieldInstitution] = useState<boolean>(false);
	const [fieldCultivarOrigin, setFieldCultivarOrigin] = useState<boolean>(false);
	const [fieldCultivarRate, setFieldCultivarRate] = useState<boolean>(false);
	const [fieldDescriptors, setFieldDescriptors] = useState<boolean>(false);
	const [fieldDiseaseOrNematode, setFieldDiseaseOrNematode] = useState<boolean>(false);
	const [fieldAgronomicCharacteristics, setFieldAgronomicCharacteristics] = useState<boolean>(false);
	const [fieldProductivity, setFieldProductivity] = useState<boolean>(false);
	const [fieldQuality, setFieldQuality] = useState<boolean>(false);
	const [fieldCommercialization, setFieldCommercialization] = useState<boolean>(false);
	const [fieldProductionSystem, setFieldProductionSystem] = useState<boolean>(false);
	const [fieldLimitationCultivar, setFieldLimitationCultivar] = useState<boolean>(false);
	const [fieldAdditionalInformation, setFieldAdditionalInformation] = useState<boolean>(false);
	const [descriptorName, setDescriptorName] = useState<string>('');
	const [descriptorCharacteristic, setDescriptorCharacteristic] = useState<string>('');
	const [diseaseName, setDiseaseName] = useState<string>('');
	const [diseaseReaction, setDiseaseReaction] = useState<string>('');
	const [diseaseReactionGreenHouse, setDiseaseReactionGreenHouse] = useState<string>('');
	const [recordTypeName, setRecordTypeName] = useState<string>('');
	const [selectedEvent, setSelectedEvent] = useState<EventsResult>();
	const [specieName, setSpecieName] = useState<string>('');
	const [models, setModels] = useState<ModelsFiles[]>([]);
	const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
	const [successFiles, setSuccessFiles] = useState<boolean>(false);
	const [loadingRequestData, setLoadingRequestData] = useState<boolean>(true);
	const [fileField7, setFileField7] = useState<ModelFileRegister>();
	const [fileField10, setFileField10] = useState<ModelFileRegister>();
	const [fileField11, setFileField11] = useState<ModelFileRegister>();
	const [fileField12, setFileField12] = useState<ModelFileRegister>();
	const [fileField17, setFileField17] = useState<ModelFileRegister>();
	const [fileField18, setFileField18] = useState<ModelFileRegister>();
	const [additionalFiles, setAdditionalFiles] = useState<any[]>([]);
	const [showModalValidate, setShowModalValidate] = useState<boolean>(false);
	const [requestCode, setRequestCode] = useState<string>('');
	const [reproveModal, setReproveModal] = useState<boolean>(false);
	const [reproveTxt, setReproveTxt] = useState<string>('');
	const [loadingReprove, setLoadingReprove] = useState<boolean>(false);
	const [aproveModal, setAproveModal] = useState<boolean>(false);
	const [loadingAprove, setLoadingAprove] = useState<boolean>(false);
	const [isApproving, setIsApproving] = useState<boolean>(false);
	const [successAprove, setSuccessAprove] = useState<boolean>(false);
	const [successReprove, setSuccessReprove] = useState<boolean>(false);
	const [regulatoryModal, setRegulatoryModal] = useState<boolean>(false);
	const [successRegulatory, setSuccessRegulatory] = useState<boolean>(false);
	const [loadingRegulatory, setLoadingRegulatory] = useState<boolean>(false);
	const [colaboratorsDescriptors, setColaboratorsDescriptors] = useState<ColaboratorUser[]>([]);
	const [colaboratorsDiseases, setColaboratorsDiseases] = useState<ColaboratorUser[]>([]);
	const [successSGFITO, setSuccessSGFITO] = useState<boolean>(false);
	const [loadingSGFITO, setLoadingSGFITO] = useState<boolean>(false);
	const [errorSGFITO, setErrorSGFITO] = useState<any>(null);
	const [status, setStatus] = useState<Status>({});
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);
	const [filteredModels, setFilteredModels] = useState<ModelsFiles>();
	const [registerId, setRegisterId] = useState<number>(0);
	const [registroInfoValidation, setRegistroInfoValidation] = useState<RegistroInfoValidation>();
	const [filteredRecords, setFilteredRecords] = useState<RecordTypesResults[]>([]);
	const [modalProtocol, setModalProtocol] = useState<boolean>(false);
	const [sucessProtocol, setSuccessProtocol] = useState<boolean>(false);
	const [loadingProtocol, setLoadingProtocol] = useState<boolean>(false);
	const [protocol, setProtocol] = useState('');
	const [dateProtocol, setDateProtocol] = useState<Date>(new Date());
	const [modalRegistro, setModalRegistro] = useState<boolean>(false);
	const [modalArquivoConclusao, setModalArquivoConclusao] = useState<boolean>(false);
	const [modalArquivoConclusaoLoading, setModalArquivoConclusaoLoading] = useState<boolean>(false);
	const [sucessRegistro, setSuccessRegistro] = useState<boolean>(false);
	const [loadingRegistro, setLoadingRegistro] = useState<boolean>(false);
	const [registro, setRegistro] = useState('');
	const [dataRegistro, setDataRegistro] = useState<Date>(new Date());
	const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [errorOnForm, setErrorOnForm] = useState<boolean>(false);
	const [arquivosParaExcluir, setArquivosParaExcluir] = useState<number[]>([]);

	var formDataFiles = new FormData();
	var formDataAdditionalFiles = new FormData();

	// yup.addMethod(yup.string, 'validateAllDescritores', function (errorMessage) {
	// 	return this.test('validate-all-descritores', errorMessage, function (value) {
	// 		const { path, createError} = this;

	// 		return false;
	// 	});
	// });

	// yup.addMethod(yup.string, )

	const registerRNCSchema : any = yup.object().shape({
		all_descriptors							: yup.string().test("all-descriptors", "Todos os descritores precisam ser inclusos", value => {
			return !fieldDescriptors || selectedDescriptors.length > 0 && selectedDescriptors.length == cultureDetails?.descritores?.length;
		}),
		all_diseases_or_nematoides				: yup.string().test("all-diseases", "Todas as doenças e nematóides precisam ser inclusas", value => {
			return !fieldDiseaseOrNematode || selectedDiseasesOrNematodes.length > 0 && selectedDiseasesOrNematodes.length == cultureDetails?.doencas?.length;
		}),
		ratePlaces								: yup.mixed().test("file-rateplaces", "Você precisa enviar um arquivo", value => {

			if (!fieldCultivarRate)
				return true;
			
			return fileField7?.id || (value && value.length > 0);
		
		}),
		productivityAssessment					: yup.mixed().test("file-productivityassessment", "Você precisa enviar um arquivo", value => {

			if (!fieldProductivity)
				return true;
			
			return fileField11?.id || (value && value.length > 0);
		
		}),
		qualityAssessment						: yup.mixed().test("file-qualityassessment", "Você precisa enviar um arquivo", value => {

			if (!fieldQuality)
				return true;
			
			return fileField12?.id || (value && value.length > 0);
		
		}),
		technicalObtainingReport				: yup.mixed().test("file-qualityassessment", "Você precisa enviar um arquivo", value => {

			return fileField17?.id || (value && value.length > 0);
		
		}),
		breederStatement						: yup.mixed().test("file-qualityassessment", "Você precisa enviar um arquivo", value => {

			return fileField18?.id || (value && value.length > 0);
		
		}),
		speciesName								: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		scientificName							: yup.string(),
		speciesGroup							: yup.string(),
		registerType							: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		nameCultivar							: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		institutionName							: fieldInstitution ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		responsibleTechnician					: fieldInstitution ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		cultivarCountry							: protectedCultivar ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		cultivarCertificateNumber				: protectedCultivar ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		cultivarNameOfTransferor				: transferredCultivar ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		cultivarInstrumentUsed					: transferredCultivar ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		cultivarCountryOfOrigin					: foreignCultivar ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		cultivarImportCountry					: foreignCultivar ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		cultivarImportProcess					: foreignCultivar ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		derivedCultivar							: essentiallyDerived ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		cultivarGeneticTransformationEvent		: modifiedOrganism ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		experimentalMaterial					: fieldCommercialization ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		institutionCompany						: fieldCultivarOrigin ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		breederParticipant						: fieldCultivarOrigin ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		crossingYear							: fieldCultivarOrigin ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		crossingPlace							: fieldCultivarOrigin ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		crossingInstitution						: fieldCultivarOrigin ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		genealogy								: fieldCultivarOrigin ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		designation								: fieldCultivarOrigin ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		descriptorRegisterRNC					: insertDescriptors ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		characteristicRegisterRNC				: insertDescriptors ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		diseaseOrNematode						: insertDiseases ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		countrysideReactionDisease				: insertDiseases ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		greenhouseReactionDisease				: insertDiseases ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		yearOfcommercialization					: fieldCommercialization ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		productionSystemsForTheCultivar			: fieldProductionSystem ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		limitationsOfTheCultivar				: fieldLimitationCultivar ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		additionalInformation					: fieldAdditionalInformation ? yup.string().required(STRINGS.FORM_FIELD_REQUIRED): yup.string(),
		diseaseOrNematodeNote					: yup.string(),
		textoAvaliacaoCultivar					: yup.mixed(),
		textoCicloAgronomico					: yup.mixed(),
		fileType								: yup.string(),
		additionalFiles							: yup.mixed(),
		agronomicCharacteristics				: yup.mixed(),
		cancelReason							: yup.mixed(),

		caracteristicasAlgodaoCicloColheita			: yup.mixed(),
		caracteristicasAlgodaoCicloFlorescimento	: yup.mixed(),
		caracteristicasAlgodaoPercentagemFibras		: yup.mixed(),
		caracteristicasAlgodaoPesoCapulho			: yup.mixed(),
		caracteristicasAlgodaoPesoCemSementes		: yup.mixed(),
		caracteristicasAlgodaoPrecocidadeMaturacao	: yup.mixed(),
		caracteristicasAlgodaoRetencaoPluma			: yup.string(),
	
		caracteristicasArrozCicloMaturacao			: yup.mixed(),
		caracteristicasArrozComportamentoAcamamento	: yup.string(),
		caracteristicasArrozComportamentoDegrane	: yup.string(),
		caracteristicasArrozDiasSemeadura			: yup.mixed(),
		caracteristicasArrozPesoMilSementes			: yup.mixed(),
		caracteristicasArrozSistemaCultivo			: yup.mixed(),

	})

	const defaultForm : any = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(registerRNCSchema)
	})

	const handleRequiredFields = (idRecordType: number) =>
	{
		const recordType = recordsType.filter(record => {
			return record.id === Number(idRecordType);
		});
		const fields: RecordTypesResults = recordType[0];

		setFieldApplicantResponsible(fields.melhoristaResponsavel);
		setFieldInstitution(fields.instituicaoResponsavel);
		setFieldCultivarOrigin(fields.origemDaCultivar);
		setFieldCultivarRate(fields.avaliacoesDeCultivar);
		setFieldDescriptors(fields.descritores);
		setFieldDiseaseOrNematode(fields.reacoesDoencas);
		setFieldAgronomicCharacteristics(fields.avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura);
		setFieldProductivity(fields.avaliacaoDaProdutividade);
		setFieldQuality(fields.avaliacaoDaQualidadeTecnologicaIndustrical);
		setFieldCommercialization(fields.intencaoDeComercializacao);
		setFieldProductionSystem(fields.sistemasDeProducaoSugeridosParaCultivar);
		setFieldLimitationCultivar(fields.limitacoesDaCultivar);
		setFieldAdditionalInformation(fields.informacoesAdicionais);
	}

	useEffect(() =>
	{
		const getDataNeeded = async () =>
		{
			try
			{
				const responseCulture = await api.get<CultureResults[]>('api/v1/cultivares/Cultura/obterporfiltro?Ativo=true');
				const responseRecordsType = await api.get<RecordTypesResults[]>('api/v1/cultivares/TipoRegistro/obterporfiltro?Ativo=true');
				const responseApplicants = await api.get<ApplicantResults[]>('api/v1/cultivares/Requerente/obterporfiltro?Ativo=true');
				const responseEvents = await api.get<EventsResult[]>('api/v1/cultivares/Evento/obterporfiltro?Ativo=true');
				const responseBreeders = await api.get<BreederResults[]>('api/v1/cultivares/EquipeTecnicaMelhorista/obterporfiltro?Ativo=true');
				const responseInstitutions = await api.get<InstitutionResults[]>('api/v1/Instituicao');
				const responseFilesType = await api.get<FilesTypeResults[]>('api/v1/TipoDeAnexo');

				if (responseCulture.status === 200) {setCulture(responseCulture.data);}
				if (responseRecordsType.status === 200) {
					setRecordsType(responseRecordsType.data);
					setFilteredRecords(responseRecordsType.data);
				}
				if (responseApplicants.status === 200) {setApplicants(responseApplicants.data);}
				if (responseEvents.status === 200) {setEvents(responseEvents.data);}
				if (responseBreeders.status === 200) {setBreeders(responseBreeders.data);}
				if (responseInstitutions.status === 200) {setInstitutions(responseInstitutions.data);}
				if (responseFilesType.status === 200) {setFilesType(responseFilesType.data);}
			}
			catch (error:any)
			{
				setErrorGetNeededData(true);
			}
			
			setLoadingNeededData(false);
			getRequest();
			getColaborators();
		}

		const getRequest = async () =>
		{
			try
			{
				const { data } = await api.get<CultivaresRegistroResponse>(`api/v1/cultivares/CultivaresRegistro/${location.state?.id}`);

				if (!loadingNeededData)
				{
					defaultForm.reset({
						speciesName: data.culturaId,
						scientificName: data.nomeCientificoDaEspecie,
						speciesGroup: data.grandesEspecie,
						registerType: data.tipoRegistroId,
						nameCultivar: data.denominacaoDaCultivarLinhagem,
						institutionName: data.instituicaoId,
						responsibleTechnician: data.tecnicoResponsavelPeloEnsaio,
						cultivarCountry: data.pais,
						cultivarCertificateNumber: data.numeroDoCertificadoDeProtecao,
						cultivarNameOfTransferor: data.nomeDoCedente,
						cultivarInstrumentUsed: data.instrumentoUltilizadoParaTransferencia,
						cultivarCountryOfOrigin: data.paisDeOrigem,
						cultivarImportCountry: data.paisDeImportacaoProcedencia,
						cultivarImportProcess: data.processoDeImportacao,
						derivedCultivar: data.deQualCultivarEDerivada,
						cultivarGeneticTransformationEvent: data.eventoId,
						institutionCompany: data.instituicaoEmpresaObtentoraIntrodutorasDetentora,
						breederParticipant: data.melhoriasParticipanteNaObtencao,
						crossingYear: data.anoDeRealizacao,
						crossingPlace: data.local,
						crossingInstitution: data.instituicaoQueRealizou,
						genealogy: data.genealogia,
						designation: data.denominacaoExperimentalOuPreComercial,
						yearOfcommercialization: data.inicioDeComercializacao,
						experimentalMaterial: data.quantidadeEmEstoqueMaterialExperimental,
						productionSystemsForTheCultivar: data.sistemaDeProducaoSugeridos,
						limitationsOfTheCultivar: data.limitacoesDaCultivar,
						textoAvaliacaoCultivar: data.textoAvaliacaoCultivar,
						textoCicloAgronomico: data.textoCicloAgronomico,
						additionalInformation: data.informacoesAdicionais,
						protocolNumber: data.protocolo,
						generalObservations: data.observacoes,
						protocolDate: data.dataProtocolo ? moment(data.dataProtocolo).toDate() : null,
						cancelReason: data.motivoCancelamento,
						caracteristicasAlgodaoCicloColheita: data.caracteristicasAlgodaoCicloColheita,
						caracteristicasAlgodaoCicloFlorescimento: data.caracteristicasAlgodaoCicloFlorescimento,
						caracteristicasAlgodaoPercentagemFibras: data.caracteristicasAlgodaoPercentagemFibras,
						caracteristicasAlgodaoPesoCapulho: data.caracteristicasAlgodaoPesoCapulho,
						caracteristicasAlgodaoPesoCemSementes: data.caracteristicasAlgodaoPesoCemSementes,
						caracteristicasAlgodaoPrecocidadeMaturacao: data.caracteristicasAlgodaoPrecocidadeMaturacao,
						caracteristicasAlgodaoRetencaoPluma: data.caracteristicasAlgodaoRetencaoPluma,
						caracteristicasArrozCicloMaturacao: data.caracteristicasArrozCicloMaturacao,
						caracteristicasArrozComportamentoAcamamento: data.caracteristicasArrozComportamentoAcamamento,
						caracteristicasArrozComportamentoDegrane: data.caracteristicasArrozComportamentoDegrane,
						caracteristicasArrozDiasSemeadura: data.caracteristicasArrozDiasSemeadura,
						caracteristicasArrozPesoMilSementes: data.caracteristicasArrozPesoMilSementes,
						caracteristicasArrozSistemaCultivo: data.caracteristicasArrozSistemaCultivo
					});

					setAproveDataDescritores({need: data.descritores_aprovar, date: data.descritores_aprovar_data, user:data.descritores_aprovar_usuario});
					setAproveDataDoencas({need: data.doencas_aprovar, date: data.doencas_aprovar_data, user:data.doencas_aprovar_usuario});

					defaultForm.setValue("textoAvaliacaoCultivar", data.textoAvaliacaoCultivar);
					defaultForm.setValue("textoCicloAgronomico", data.textoCicloAgronomico);
					setSpecieName(data.nomeComumDaEspecie);
					setRecordTypeName(data.tipoRegistroNome);
					setSelectedInstitution({
						id: data.instituicaoId,
						cep: data.cep,
						cnpj: data.cnpj,
						email: data.email,
						endereco: data.endereco,
						municipio: data.municipio,
						uf: data.uf,
						pais: data.paisInstituicao,
						nome: data.nome,
						telefone: data.telefone,
					})
					setProtectedCultivar(data.cultivarProtegida);
					setTransferredCultivar(data.cultivarTransferida);
					setForeignCultivar(data.cultivarEstrangeira);
					setEssentiallyDerived(data.cultivarEssencialmenteDerivada);
					setModifiedOrganism(data.cultivarGeneticamenteModificada);
					setSelectedEvent({
						id: data.eventoId,
						nomeEvento: data.nomeDoEvento,
					});
					(await getCultureById(data.culturaId));
					
					if (data.cultivaresRequerentes.length)
					{
						const allApplicants = data.cultivaresRequerentes.map(req => {
							return req.requerente;
						})
						setSelectedApplicants(allApplicants);
						setInsertApplicants(true);
					}
					
					if (data.cultivaresEquipeTecnicas.length)
					{
						const allBreeders = data.cultivaresEquipeTecnicas.map(melhorista => {
							return melhorista.equipeTecnicaMelhorista;
						});
						setSelectedBreeder(allBreeders);
						setInsertBreeder(true);
					}

					if (data.arquivoAvaliacaoCultivar) setFileField7(data.arquivoAvaliacaoCultivar);
					if (data.arquivoCicloAgronomico) setFileField10(data.arquivoCicloAgronomico);
					if (data.arquivoProdutividade) setFileField11(data.arquivoProdutividade);
					if (data.arquivoQualidadeTecnologica) setFileField12(data.arquivoQualidadeTecnologica);
					if (data.arquivoRelatorioTecnicoObtencao) setFileField17(data.arquivoRelatorioTecnicoObtencao);
					if (data.arquivoDeclaracaoDoMelhorista) setFileField18(data.arquivoDeclaracaoDoMelhorista);
					if (data.arquivoConclusao) setFileFieldConclusao(data.arquivoConclusao);
					
					if (data.cultivaresDescritores)
					{
						const allDescriptors = data.cultivaresDescritores.map(descriptor => {
							return descriptor;
						})
						setSelectedDescriptors(allDescriptors);
						setChoosenDescriptors(true);
					}
					
					if (data.cultivaresDoencas)
					{
						const allDiseases = data.cultivaresDoencas.map(disease => {
							return disease;
						})
						setSelectedDiseasesOrNematodes(allDiseases);
						setChoosenDiseases(true);
					}
					
					if (data.cultivaresCulturaRegioes)
					{
						const allRegions = data.cultivaresCulturaRegioes.map(region => {
							return region.culturaRegiao;
						})
						setSelectedAdaptationRegions(allRegions);
						setInsertAdaptationRegions(true);
					}
					
					if (data.arquivosAnexos)
					{
						const allFiles = data.arquivosAnexos.map(file => {
							return file;
						})
						setSelectedFilesAdditionals(allFiles);
					}
					
					setRequestCode(data.codigo);
					setStatus({
						status: data.status,
						statusDescricao: data.statusDescricao
					});
					handleRequiredFields(data.tipoRegistroId);
					setRegisterId(data.tipoRegistroId);
					setRegistroInfoValidation({
						descritoresValidado: data.descritoresValidado,
						doencaValidada: data.doencaValidada,
						usuarioValidacaoDescritores: data.usuarioValidacaoDescritores,
						usuarioValidacaoDoencas: data.usuarioValidacaoDoencas
					});
					setProtocol(data.protocolo);
					setDateProtocol(moment(data.dataProtocolo).toDate());
					setRegistro(data.registro);
					setDataRegistro(moment(data.dataRegistro).toDate())
				}
			}
			catch (error:any)
			{
				setErrorGetNeededData(true);
			}

			setTimeout(() => {setLoadingRequestData(false);}, 2500)
		}

		const getColaborators = async () =>
		{
			try
			{
				const { data: descritores } = await api.get<ColaboratorUser[]>('api/v1/cultivares/CultivaresRegistro/obteravalidadoresdescritores');
				const { data: doencas } = await api.get<ColaboratorUser[]>('api/v1/cultivares/CultivaresRegistro/obteravalidadoresdoencas');
				setColaboratorsDescriptors(descritores);
				setColaboratorsDiseases(doencas);
			}
			catch (error:any)
			{
				console.error(error);
			}
		}

		getDataNeeded();

	}, [loadingNeededData, location.state, defaultForm.reset]);

	const setRespectiveReactionsDiseases = (diseaseId: number) =>
	{
		const reactions = diseasesByCulture.filter(item => {
			return item.id === Number(diseaseId);
		})
		let { reacoesDoencas } = reactions[0];
		setDiseasesReactions(reacoesDoencas);
	}

	const onRowSelectedApplicant = ({ api }: any) =>
	{
		const data = api.getSelectedRows();
		setChoosenApplicants(data);
	}

	const onRowSelectedBreeder = ({ api }: any) =>
	{
		const data = api.getSelectedRows();
		setChoosenBreeder(data);
	}

	const onRowSelectedAdaptationRegions = ({ api }: any) =>
	{
		const data = api.getSelectedRows();
		setChoosenAdaptationRegions(data);
	}

	const setUnselectedApplicant = (selectedApplicant: ApplicantResults[]) =>
	{
		let results: ApplicantResults[] = [];
		
		for (let i = 0, t = applicants.length; i < t; i++)
		{
			const result: ApplicantResults = {
				id: Number(applicants[i].id),
				nome: String(applicants[i].nome),
				ativo: Boolean(applicants[i].ativo),
				cnpj: String(applicants[i].cnpj),
				endereco: String(applicants[i].endereco),
				cep: String(applicants[i].cep),
				municipio: String(applicants[i].municipio),
				email: String(applicants[i].email),
				pais: String(applicants[i].pais),
				telefone: String(applicants[i].telefone),
				uf: String(applicants[i].uf)
			}

			let exist = selectedApplicant.some(c => c.id === applicants[i].id);
			
			if (exist !== false)
				continue;

			results.push(result);
		}
		setUnselectedApplicants(results);
	}

	const setUnselectedAdaptationRegion = (regionsSelected: Regions[]) =>
	{
		let results: Regions[] = [];
		
		for (let count = 0, countLen = regions.length; count < countLen; count++)
		{
			if (regionsSelected.some(c => c.id === regions[count].id))
				continue;

			results.push(regions[count]);
		}

		setUnselectedAdaptationRegions(results);
	}

	const setSelectedDescriptorsList = () =>
	{
		defaultForm.trigger(["descriptorRegisterRNC", "characteristicRegisterRNC"]).then((validated:any) => {
			
			if (!validated)
				return;

			setInsertDescriptors(false);
			setChoosenDescriptors(true);

			let descritores = {
				id: Number(defaultForm.getValues("descriptorRegisterRNC")),
				descricao: descriptorName
			}
			
			let caracteristicasDescritores = {
				id: Number(defaultForm.getValues("characteristicRegisterRNC")),
				descricao: descriptorCharacteristic
			}

			setSelectedDescriptors([...selectedDescriptors, {
				descritores,
				caracteristicasDescritores,
				observacao: defaultForm.getValues('descriptorNote')
			}]);
		});
	}

	const setFilteredDescriptors = (selectedDescriptors: DescritoresResponseRegistro[]) =>
	{
		let arr1: DescriptorsResults[] = [];
		for (let count = 0, countLen = descriptorsByCulture.length; count < countLen; count++) {
			if (!selectedDescriptors.some(c => c.descritores.id === descriptorsByCulture[count].id))
				arr1.push(descriptorsByCulture[count]);
		}
		setDescriptorsByCulture(arr1);
	}

	const setSelectedDiseasesOrNematodesList = () =>
	{
		defaultForm.trigger(['diseaseOrNematode', 'greenhouseReactionDisease', 'countrysideReactionDisease']).then((validated:any) => {
			
			if (!validated)
				return;

			let doencas = {
				id: Number(defaultForm.getValues('diseaseOrNematode')),
				descricao: diseaseName
			}
			let reacaoDoencaCampo = {
				id: Number(defaultForm.getValues('greenhouseReactionDisease')),
				descricao: diseaseReaction
			}
			let reacaoDoencaCasaVegetacao = {
				id: Number(defaultForm.getValues('countrysideReactionDisease')),
				descricao: diseaseReactionGreenHouse
			}
			let diseases: DiseasesResponseRegistro = {
				doencas,
				reacaoDoencaCampo,
				reacaoDoencaCasaVegetacao,
				observacao: defaultForm.getValues('diseaseOrNematodeNote'),
			}

			setInsertDiseases(false);
			setChoosenDiseases(true);
			setSelectedDiseasesOrNematodes([...selectedDiseasesOrNematodes, diseases]);
		});
	}

	const setFilteredDiseases = (selectedDiseases: DiseasesResponseRegistro[]) =>
	{
		let results: DiseasesResults[] = [];
		
		for (let count = 0, countLen = diseasesByCulture.length; count < countLen; count++)
		{
			if (selectedDiseases.some(c => c.doencas.id === diseasesByCulture[count].id))
				continue;

			results.push(diseasesByCulture[count]);
		}

		setDiseasesByCulture(results);
	}

	const setSelectedFilesAdditionalsList = () =>
	{
		setSelectedFilesAdditionals([...selectedFilesAdditionals, {
			arquivo: {nome: defaultForm.getValues('additionalFiles')[0].name}
		}]);
	}

	const setArrFilesToSubmit = (file: any) =>
	{
		setAdditionalFiles([...additionalFiles, {arquivo: file[0],}])
	}

	const actionDescriptors = (props: any) =>
	{
		return <DeleteButton
			onClick={() => {
				setSelectedDescriptors([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedDescriptors([...arr]);
				giveBackDescriptor();
			}}
		/>;
	}

	const giveBackDescriptor = () =>
	{
		setDescriptorsByCulture([...descriptorsByCulture]);
	}

	const actionDiseases = (props: any) =>
	{
		return <DeleteButton
			onClick={() => {
				setSelectedDiseasesOrNematodes([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedDiseasesOrNematodes([...arr]);
				giveBackDiseases();
			}}
		/>;
	}

	const giveBackDiseases = () =>
	{
		setDiseasesByCulture([...diseasesByCulture]);
	}

	const actionApplicants = (props: any) =>
	{
		let deleteButton = <DeleteButton
			onClick={() => {
				setSelectedApplicants([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedApplicants([...arr]);
			}}
		/>;

		return can_edit('default') ? deleteButton : <React.Fragment />;
	}

	const actionBreeder = (props: any) =>
	{
		return <If condition={can_edit('default')}>
			<DeleteButton
				onClick={() => {
					setSelectedBreeder([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setSelectedBreeder([...arr]);
				}}
			/>
		</If>;
	}

	const actionAdditionalFiles = (props: any) =>
	{
		return <DeleteButton onClick={() => {
			setSelectedFilesAdditionals([]);
			let arr: any = [];
			let arrToSubmit: any = [];
			const { node } = props;

			node.gridApi.updateRowData({ remove: [node.data] })

			node.gridApi.forEachNode(({ data }: any) => arr.push(data));
			node.gridApi.forEachNode(({ data }: any) => {
				if (!data.id)
					arrToSubmit.push(data);
			});
			setSelectedFilesAdditionals([...arr]);
			setAdditionalFiles(arrToSubmit);
		}} />;
	}

	const actionAdaptationRegions = (props: any) =>
	{
		return <DeleteButton onClick={() => {
			setSelectedAdaptationRegions([]);
			let arr: any = [];
			const { node } = props;

			node.gridApi.updateRowData({ remove: [node.data] })

			node.gridApi.forEachNode(({ data }: any) => arr.push(data));
			setSelectedAdaptationRegions([...arr]);
		}} />;
	}

	const setInstitutionSelected = (institutionId: number) =>
	{
		const institution = institutions.filter(item => {
			return item.id === Number(institutionId);
		})

		setSelectedInstitution(institution[0]);
	}

	const setEventSelected = (eventId: number) =>
	{
		const event = events.filter(item => {
			return item.id === Number(eventId);
		})

		setSelectedEvent(event[0]);
	}

	const GetDataToSubmit = (sendFiles:boolean = false) =>
	{
		let data = {
			id													: location.state.id,
			culturaId											: cultureDetails?.id,
			nomeComumDaEspecie									: specieName,
			nomeCientificoDaEspecie								: defaultForm.getValues('scientificName'),
			grandesEspecie										: defaultForm.getValues('speciesGroup'),
			tipoRegistroId										: defaultForm.getValues('registerType'),
			tipoRegistroNome									: recordTypeName,
			denominacaoDaCultivarLinhagem						: defaultForm.getValues('nameCultivar'),
			instituicaoId										: Number(defaultForm.getValues('institutionName')),
			nome												: selectedInstitution?.nome,
			cnpj												: selectedInstitution?.cnpj,
			endereco											: selectedInstitution?.endereco,
			cep													: selectedInstitution?.cep,
			municipio											: selectedInstitution?.municipio,
			uf													: selectedInstitution?.uf,
			paisInstituicao										: selectedInstitution?.pais,
			telefone											: selectedInstitution?.telefone,
			email												: selectedInstitution?.email,
			tecnicoResponsavelPeloEnsaio						: defaultForm.getValues('responsibleTechnician'),
			status												: 1,
			cultivarProtegida									: protectedCultivar,
			pais												: defaultForm.getValues('cultivarCountry'),
			numeroDoCertificadoDeProtecao						: defaultForm.getValues('cultivarCertificateNumber'),
			cultivarTransferida									: transferredCultivar,
			nomeDoCedente										: defaultForm.getValues('cultivarNameOfTransferor'),
			instrumentoUltilizadoParaTransferencia				: defaultForm.getValues('cultivarInstrumentUsed'),
			cultivarEstrangeira									: foreignCultivar,
			paisDeOrigem										: defaultForm.getValues('cultivarCountryOfOrigin'),
			paisDeImportacaoProcedencia							: defaultForm.getValues('cultivarImportCountry'),
			processoDeImportacao								: defaultForm.getValues('cultivarImportProcess'),
			cultivarEssencialmenteDerivada						: essentiallyDerived,
			deQualCultivarEDerivada								: defaultForm.getValues('derivedCultivar'),
			cultivarGeneticamenteModificada						: modifiedOrganism,
			eventoId											: selectedEvent?.id,
			nomeDoEvento										: selectedEvent?.nomeEvento,
			instituicaoEmpresaObtentoraIntrodutorasDetentora	: defaultForm.getValues('institutionCompany'),
			melhoriasParticipanteNaObtencao						: defaultForm.getValues('breederParticipant'),
			anoDeRealizacao										: Number(defaultForm.getValues('crossingYear')),
			local												: defaultForm.getValues('crossingPlace'),
			instituicaoQueRealizou								: defaultForm.getValues('crossingInstitution'),
			genealogia											: defaultForm.getValues('genealogy'),
			denominacaoExperimentalOuPreComercial				: defaultForm.getValues('designation'),
			inicioDeComercializacao								: Number(defaultForm.getValues('yearOfcommercialization')),
			quantidadeEmEstoqueMaterialExperimental				: Number(defaultForm.getValues('experimentalMaterial')),
			sistemaDeProducaoSugeridos							: defaultForm.getValues('productionSystemsForTheCultivar'),
			textoAvaliacaoCultivar								: defaultForm.getValues('textoAvaliacaoCultivar'),
			textoCicloAgronomico								: defaultForm.getValues('textoCicloAgronomico'),
			limitacoesDaCultivar								: defaultForm.getValues('limitationsOfTheCultivar'),
			informacoesAdicionais								: defaultForm.getValues('additionalInformation'),
			usuario												: 'teste',
			cultivaresRequerentes								: expectedApplicants(),
			cultivaresDescritores								: expectedDescriptors(),
			cultivaresDoencas									: expectedDiseases(),
			cultivaresCulturaRegioes							: expectedRegions(),
			cultivaresEquipeTecnicas							: extractEquipeTecnicaMelhorista(),
			arquivosAnexos										: expectedFilesAdditionals(),
			observacoes											: defaultForm.getValues('generalObservations'),
			arquivosParaExcluir									: arquivosParaExcluir,
			arquivoAvaliacaoCultivarId							: null,
			arquivoCicloAgronomicoId							: null,
			arquivoProdutividadeId								: null,
			arquivoQualidadeTecnologicaId						: null,
			arquivoRelatorioTecnicoObtencaoId					: null,
			arquivoDeclaracaoDoMelhoristaId: null,

			caracteristicasAlgodaoCicloColheita					: defaultForm.getValues('caracteristicasAlgodaoCicloColheita'),
			caracteristicasAlgodaoCicloFlorescimento			: defaultForm.getValues('caracteristicasAlgodaoCicloFlorescimento'),
			caracteristicasAlgodaoPercentagemFibras				: Number(defaultForm.getValues('caracteristicasAlgodaoPercentagemFibras')),
			caracteristicasAlgodaoPesoCapulho					: Number(defaultForm.getValues('caracteristicasAlgodaoPesoCapulho')),
			caracteristicasAlgodaoPesoCemSementes				: Number(defaultForm.getValues('caracteristicasAlgodaoPesoCemSementes')),
			caracteristicasAlgodaoPrecocidadeMaturacao			: defaultForm.getValues('caracteristicasAlgodaoPrecocidadeMaturacao'),
			caracteristicasAlgodaoRetencaoPluma					: defaultForm.getValues('caracteristicasAlgodaoRetencaoPluma'),

			caracteristicasArrozCicloMaturacao					: defaultForm.getValues('caracteristicasArrozCicloMaturacao'),
			caracteristicasArrozComportamentoAcamamento			: defaultForm.getValues('caracteristicasArrozComportamentoAcamamento'),
			caracteristicasArrozComportamentoDegrane			: defaultForm.getValues('caracteristicasArrozComportamentoDegrane'),
			caracteristicasArrozDiasSemeadura					: defaultForm.getValues('caracteristicasArrozDiasSemeadura'),
			caracteristicasArrozPesoMilSementes					: Number(defaultForm.getValues('caracteristicasArrozPesoMilSementes')),
			caracteristicasArrozSistemaCultivo					: defaultForm.getValues('caracteristicasArrozSistemaCultivo'),


		};

		if (sendFiles)
		{
			data.arquivoAvaliacaoCultivarId						= fileField7?.id;
			data.arquivoCicloAgronomicoId						= fileField10?.id;
			data.arquivoProdutividadeId							= fileField11?.id;
			data.arquivoQualidadeTecnologicaId					= fileField12?.id;
			data.arquivoRelatorioTecnicoObtencaoId				= fileField17?.id;
			data.arquivoDeclaracaoDoMelhoristaId				= fileField18?.id;
		}

		return data;
	}

	const onSubmit = async () =>
	{
		setLoadingSubmit(true);
		setErrorOnSubmit(null);

		try
		{
			const dados = GetDataToSubmit(true);
			const request = await api.put('api/v1/cultivares/CultivaresRegistro', dados);

			if (request.status === 200)
			{
				if (status.status === STATUS_REGISTER.EmPreenchimento || status.status === STATUS_REGISTER.EmCorrecao || status.status === STATUS_REGISTER.ReprovadoPeloRegulatorio)
					sendToRegulatory();

				await onSubmitFiles();
				await onSubmitAdditionalFiles();
				setErrorOnForm(false);

				if (isApproving)
					aproveRequisition();

				setSuccessfulResponse(true);
			}
		}
		catch (error:any)
		{
			setErrorOnSubmit(error.response.data);
			setLoadingSubmit(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
			
		setLoadingSubmit(false);
	}

	const onSubmitDraft = async () =>
	{
		setLoadingDraft(true);
		
		try
		{
			const dados = GetDataToSubmit();
			const request = await api.put('api/v1/cultivares/CultivaresRegistro/rascunho', dados);

			if (request.status === 200)
			{
				setSuccessDraft(true);
				await onSubmitFiles();
				await onSubmitAdditionalFiles();
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}

		}
		catch (error:any)
		{
			setErrorDraft(error.response.data);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}

		setLoadingDraft(false);
	}

	const onSubmitFiles = async () =>
	{
		let fileModeloAvaliacaoCultivar = defaultForm.getValues('ratePlaces');
		let fileModeloCicloAgronomico = defaultForm.getValues('agronomicCharacteristics');
		let fileModeloProdutividade = defaultForm.getValues('productivityAssessment');
		let fileModeloQualidade = defaultForm.getValues('qualityAssessment');
		let fileModeloRelatorioTecnico = defaultForm.getValues('technicalObtainingReport');
		let fileModeloDeclaracaoMelhorista = defaultForm.getValues('breederStatement');

		if (fileModeloAvaliacaoCultivar && fileModeloAvaliacaoCultivar.length)
			formDataFiles.append('fileModeloAvaliacaoCultivar', fileModeloAvaliacaoCultivar[0]);

		if (fileModeloCicloAgronomico && fileModeloCicloAgronomico.length)
			formDataFiles.append('fileModeloCicloAgronomico', fileModeloCicloAgronomico[0]);

		if (fileModeloProdutividade && fileModeloProdutividade.length)
			formDataFiles.append('fileModeloProdutividade', fileModeloProdutividade[0]);

		if (fileModeloQualidade && fileModeloQualidade.length)
			formDataFiles.append('fileModeloQualidade', fileModeloQualidade[0]);

		if (fileModeloRelatorioTecnico && fileModeloRelatorioTecnico.length)
			formDataFiles.append('fileModeloRelatorioTecnico', fileModeloRelatorioTecnico[0]);
		
		if (fileModeloDeclaracaoMelhorista && fileModeloDeclaracaoMelhorista.length)
			formDataFiles.append('fileModeloDeclaracaoMelhorista', fileModeloDeclaracaoMelhorista[0]);

		try {
			const { data, status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${location.state.id}/SalvarArquivosRegistro`, formDataFiles);

			if (status === 200) {
				setSuccessFiles(true);
			}
		} catch (error:any) {
			console.error(error)
		}
	}

	const onSubmitAdditionalFiles = async () =>
	{
		additionalFiles.map(file => formDataAdditionalFiles.append("files", file.arquivo));
		try {
			const { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${location.state.id}/SalvarArquivosAnexos`, formDataAdditionalFiles);

			if (status === 200 && !isApproving) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setTimeout(() => {
					history.goBack();
				}, 2500)
			}
		} catch (error:any) {
			console.error(error);
		}
	}

	const downloadModelFile = async (file: ModelFileRegister | undefined) =>
	{
		if (file != undefined) {
			setLoadingDownload(true);
			try {
				apiFileDownload.get(`api/v1/cultivares/Download?codigo=${file.codigo}`)
					.then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.nome); //or any other extension
						document.body.appendChild(link);
						link.click();
					});

			} catch (error:any) {
				console.error(error);
			}
			setLoadingDownload(false);
		}
	}

	const downloadModel = async (modelCode: string, fileName: string) =>
	{
		setLoadingDownload(true);
		try {
			const { data } = await apiFileDownload.get(`/api/v1/cultivares/Download?codigo=${modelCode}`);
			let blob = new Blob([data], {
				type: 'application/pdf'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
			}

		} catch (error:any) {
			console.error(error);
		}
		setLoadingDownload(false);
	}

	const getCultureById = async (idCulture_: any) =>
	{
		try
		{
			let idCulture = Number(idCulture_);

			if(!idCulture || idCulture <= 0)
				throw new Error('Cultura inválida');
				
			const { data, status } = await api.get<CultureResults>(`api/v1/cultivares/Cultura/${Number(idCulture)}`);

			if (status !== 200 && status !== 201)
				throw new Error('Erro ao obter cultura');
			
			setCultureDetails(data);
			setDescriptorsByCulture(data.descritores);
			setDiseasesByCulture(data.doencas);
			setRegions(data.culturaRegioes);
			filterRecordType(data.nome);
			if (data.modeloRegistrosCultivares.length) {
				setModels(data.modeloRegistrosCultivares);
			}
		}
		catch (error:any)
		{
			console.error(error);
		}
	}

	const extractEquipeTecnicaMelhorista = (): RequestRegisterMelhoristas[] =>
	{
		let breedersModel: RequestRegisterMelhoristas[] = [];

		for (let count = 0, countLen = selectedBreeder.length; count < countLen; count++)
		{
			if (!selectedBreeder[count])
				continue;

			
			breedersModel.push({equipeTecnicaMelhoristaId: selectedBreeder[count].id});
		}

		return breedersModel;
	}

	const expectedApplicants = () : RequestRegisterApplicants[] =>
	{
		let applicantsModel: RequestRegisterApplicants[] = [];

		for (let i = 0, t = selectedApplicants.length; i < t; i++) {
			applicantsModel.push({
				requerenteId: selectedApplicants[i].id
			})
		}

		return applicantsModel;
	}

	const expectedRegions = (): RequestRegisterRegions[] =>
	{
		let regionsModel: RequestRegisterRegions[] = [];

		for (let count = 0, countLen = selectedAdaptationRegions.length; count < countLen; count++) {
			regionsModel.push({
				culturaRegiaoId: Number(selectedAdaptationRegions[count].id)
			})
		}

		return regionsModel;
	}

	const expectedDescriptors = (): ExpectedDescriptors[] =>
	{
		let descriptorsModel: ExpectedDescriptors[] = [];

		for (let count = 0, countLen = selectedDescriptors.length; count < countLen; count++) {
			descriptorsModel.push({
				descritoresId: selectedDescriptors[count].descritores.id,
				caracteristicasDescritoresId: selectedDescriptors[count].caracteristicasDescritores.id,
				observacao: selectedDescriptors[count].observacao
			})
		}
		return descriptorsModel;
	}

	const expectedDiseases = (): ExpectedDisease[] =>
	{
		let diseaseModel: ExpectedDisease[] = [];

		for (let count = 0, countLen = selectedDiseasesOrNematodes.length; count < countLen; count++) {
			diseaseModel.push({
				doencasId: selectedDiseasesOrNematodes[count].doencas.id,
				reacaoDoencaCampoId: selectedDiseasesOrNematodes[count].reacaoDoencaCampo.id,
				reacaoDoencaCasaVegetacaoId: selectedDiseasesOrNematodes[count].reacaoDoencaCasaVegetacao.id,
				observacao: selectedDiseasesOrNematodes[count].observacao
			})
		}
		return diseaseModel;
	}

	const expectedFilesAdditionals = (): AdditionalsFilesRegistroUpdate[] =>
	{
		let allAdditionalFiles: AdditionalsFilesRegistroUpdate[] = [];

		for (let count = 0, countLen = selectedFilesAdditionals.length; count < countLen; count++) {
			if (!!selectedFilesAdditionals[count].id) {
				allAdditionalFiles.push({
					id: selectedFilesAdditionals[count].id,
					arquivoId: selectedFilesAdditionals[count].arquivo.id,
					cultivaresId: location.state.id
				})
			}
		}
		return allAdditionalFiles;
	}

	const aproveRequisition = async () =>
	{
		setLoadingAprove(true);

		try
		{
			const { status } = await api.post(`/api/v1/cultivares/CultivaresRegistro/${location.state.id}/aprovar`);

			if (status === 200)
			{
				setSuccessAprove(true);
				setTimeout(() => {
					setAproveModal(false);
					history.goBack();
				}, 2000)
			}
		}
		catch (error:any)
		{
			console.error(error);
		}

		setIsApproving(false);
		setLoadingAprove(false);
	}

	const reproveRequisition = async () =>
	{
		setLoadingReprove(true);

		try
		{
			await onSubmit();

			const { status } = await api.post(`/api/v1/cultivares/CultivaresRegistro/${location.state.id}/reprovar`, {
				observacoes: defaultForm.getValues('generalObservations'),
				motivo: reproveTxt
			})

			if (status === 200)
			{
				setSuccessReprove(true);
				setTimeout(() => {
					history.goBack();
					setReproveModal(false);
				}, 2000)
			}
		}
		catch (error:any)
		{
			console.error(error);
		}

		setLoadingReprove(false);
	}

	const sendToRegulatory = async () =>
	{
		const { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${location.state.id}/enviarparaaprovacao`);

		if (status === 200)
			setSuccessRegulatory(true);
	}

	const validateSGFITO_Descritores = async () =>
	{
		if (!isAdmin(roles) && !isDescriptors(roles))
			return;

		setLoadingSGFITO(true);
		setErrorSGFITO(null);

		try
		{
			let { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${location.state.id}/validardescritores`, expectedDescriptors());

			if (status !== 200)
				throw new Error('Ocorreu um erro ao processar a requisição');

			await onSubmit();
			
			setSuccessSGFITO(true);
			// setTimeout(() => {history.goBack();}, 2000);
		}
		catch (error:any)
		{
			setErrorSGFITO(error.response.data);
		}

		window.scrollTo({ top: 0, behavior: 'smooth' });
		setLoadingSGFITO(false);
	}

	const validateSGFITO_Doencas = async () =>
	{
		if (!isAdmin(roles) && !isDiseases(roles))
			return;

		setLoadingSGFITO(true);
		setErrorSGFITO(null);

		try
		{
			let { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${location.state?.id}/validardoencas`, expectedDiseases());

			if (status !== 200)
				throw new Error('Ocorreu um erro ao processar a requisição');

			await onSubmit();

			setSuccessSGFITO(true);
			// setTimeout(() => {history.goBack();}, 2000);
		}
		catch (error:any)
		{
			setErrorSGFITO(error.response.data);
		}

		window.scrollTo({ top: 0, behavior: 'smooth' });
		setLoadingSGFITO(false);
	}

	const filterModels = (registerId: number) =>
	{
		const result = models.filter(item => item.tipoRegistroId === Number(registerId));

		setFilteredModels(result[0]);
	}

	useEffect(() =>
	{
		filterModels(registerId);
	}, [registerId, models]);

	const filterRecordType = (selectedCulture: string) =>
	{
		if (selectedCulture.toLowerCase() !== 'arroz')
		{
			const newRecordsType = recordsType.filter(record => !record.nome.toLowerCase().endsWith('parental'));
			setFilteredRecords(newRecordsType)
		}
		else
		{
			setFilteredRecords([...recordsType]);
		}
	}

	const sendProtocol = async () =>
	{
		setLoadingProtocol(true);
		
		setProtocol(defaultForm.getValues('protocolNumber'));
		setDateProtocol(defaultForm.getValues('protocolDate'));

		const { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${location.state?.id}/enviarprotocolo`, {
			protocolo: defaultForm.getValues('protocolNumber'),
			dataProtocolo: defaultForm.getValues('protocolDate')
		})

		if (status === 200) {
			setSuccessProtocol(true);
			setTimeout(() => {
				setModalProtocol(false);
			}, 1300)
			setTimeout(() => {
				history.goBack();
			}, 3000)
		}
		setLoadingProtocol(false);
	}

	const sendRegistroMAPA = async () =>
	{
		setLoadingRegistro(true);

		setRegistro(defaultForm.getValues('registro'));
		setDataRegistro(defaultForm.getValues('dateRegistro'));

		const { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${location.state?.id}/registromapaaprovado`, {
			Registro		: defaultForm.getValues('registro'),
			DataRegistro	: defaultForm.getValues('dateRegistro')
		});

		let arquivoConclusao = defaultForm.getValues('fileRegistro');

		if (arquivoConclusao && arquivoConclusao.length)
		{
			var formData = new FormData();

			formData.append('fileArquivoConclusao', arquivoConclusao[0]);

			try
			{
				const requestArquivo = await api.post(`api/v1/cultivares/CultivaresRegistro/${location.state.id}/SalvarArquivosConclusao`, formData);
			}
			catch (error:any)
			{
				console.error(error)
			}
		}

		if (status !== 200)
			throw new Error('Erro ao enviar para o MAPA');

		setSuccessRegistro(true);
		setTimeout(() => {
			setRegistro(defaultForm.getValues('registro'));
			setDataRegistro(defaultForm.getValues('dateRegistro'));
		}, 1300)
		setTimeout(() => {setModalRegistro(false);}, 1500)
		setTimeout(() => {setModalRegistro(false); history.goBack();}, 3000);

		setLoadingRegistro(false);
	}

	const sendRegistroMAPAArquivo = async () =>
	{
		setModalArquivoConclusaoLoading(true);
		let arquivoConclusao = defaultForm.getValues('fileRegistroUpload');

		if (!arquivoConclusao || !arquivoConclusao.length)
			return;
		try
		{
			var formData = new FormData();
			formData.append('fileArquivoConclusao', arquivoConclusao[0]);

			const requestArquivo = await api.post(`api/v1/cultivares/CultivaresRegistro/${location.state.id}/SalvarArquivosConclusao`, formData);
			setTimeout(() => {setModalArquivoConclusao(false); setModalArquivoConclusaoLoading(false); history.goBack();}, 1000);
		}
		catch (error:any)
		{
			console.error(error);
			setModalArquivoConclusaoLoading(false);
		}
	}

	const parseButton = () =>
	{
		let buttonCancelar = <Button
			title="Cancelar"
			type="button"
			contained={false}
			onClick={() => history.goBack()}
		/>;

		let buttonAprovar					= <Fragment />;
		let buttonReprovar					= <Fragment />;
		let buttonEnviar					= <Fragment />;
		let buttonEnviarRegultorio			= <Fragment />;
		let buttonValidaSG_FITO				= <Fragment />;
		let buttonValidaSG_FITO_Descritores	= <Fragment />;
		let buttonValidaSG_FITO_Doencas		= <Fragment />;
		let buttonSalvar					= <Fragment />;
		let buttonSalvarRascunho			= <Fragment />;

		let userIsAdmin = isAdmin(roles);
		let userIsRegulatorioRegister = isReguladorRegister(roles);
		let userIsRequesterWriteRegister = isRequesterWriteRegister(roles);
		let userIsDescriptors = isDescriptors(roles);
		let userIsDiseases = isDiseases(roles);

		if(can_edit('aprove'))
		{
			buttonAprovar = <GenericButton
				title="Aprovar"
				variant="btn-success"
				onClick={() => {
					setAproveModal(true);
					setIsApproving(true);
				}}
			/>;

			buttonReprovar = <GenericButton
				title="Reprovar"
				variant="btn-danger"
				onClick={() => {
					setReproveModal(true);
				}}
			/>;
		}

		if(can_edit('send_to_fito'))
		{
			buttonEnviar = <GenericButton
				title="Enviar SG/FITO"
				onClick={() => setShowModalValidate(true)}
				variant="btn-info"
			/>;
		}

		if(can_edit('send_to_regulatory'))
		{
			buttonEnviarRegultorio = <GenericButton
				title="Salvar e enviar para regulatório"
				variant="btn-warning"
				onClick={() => {
					defaultForm.trigger().then((validated:any) => {

						setSubmited(validated);
						setRegulatoryModal(validated);
						if (!validated) {
							setErrorOnForm(true);
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}
					})
				}}
				loading={loadingSubmit}
			/>;
		}

		// if(can_edit('validate_fito'))
		// {
		// 	buttonValidaSG_FITO = <GenericButton
		// 		title="Validar SG/FITO"
		// 		onClick={() => validateSGFITO()}
		// 		variant="btn-primary"
		// 		loading={loadingSGFITO}
		// 	/>;
		// }

		if(can_edit('validate_fito_descritores'))
		{
			buttonValidaSG_FITO_Descritores = <GenericButton
				title="Validar SG"
				onClick={() => validateSGFITO_Descritores()}
				variant="btn-primary"
				loading={loadingSGFITO}
			/>;
		}

		if(can_edit('validate_fito_doencas'))
		{
			buttonValidaSG_FITO_Doencas = <GenericButton
				title="Validar FITO"
				onClick={() => validateSGFITO_Doencas()}
				variant="btn-primary"
				loading={loadingSGFITO}
			/>;
		}

		if(can_edit('save'))
		{
			buttonSalvar = <Button
				title="Salvar"
				type="button"
				contained={true}
				onClick={() => {
					onSubmitDraft();
					// setSubmited(true);
				}}
				isLoading={isRegulatory ? false : loadingSubmit}
			/>;
		}


		if (can_edit('draft'))
		{
			buttonSalvarRascunho = <Button
				title="Salvar rascunho"
				type="button"
				contained={true}
				onClick={() => {
					onSubmitDraft();
				}}
				isLoading={loadingDraft}
			/>;
		}

		return <div className="button-position">
			{buttonCancelar}
			{buttonAprovar}
			{buttonReprovar}
			{buttonEnviar}
			{buttonEnviarRegultorio}
			{buttonValidaSG_FITO_Descritores}
			{buttonValidaSG_FITO_Doencas}
			{buttonSalvar}
			{buttonSalvarRascunho}
		</div>;
	}

	const can_edit = (group: string) =>
	{
		let userIsAdmin = isAdmin(roles);
		let userIsRegulatorioRegister = isReguladorRegister(roles);
		let userIsRequesterWriteRegister = isRequesterWriteRegister(roles);
		let userIsDescriptors = isDescriptors(roles);
		let userIsDiseases = isDiseases(roles);

		switch (group)
		{
			case 'default':

				if (userIsAdmin)
					return true;

				if (userIsRequesterWriteRegister && (status.status == STATUS_REGISTER.EmPreenchimento || status.status == STATUS_REGISTER.EmCorrecao || status.status == STATUS_REGISTER.ReprovadoPeloRegulatorio))
					return true;

				return false;

			case 'preenchimento_observacoes':

				return true;

			case 'save':

				if ((userIsAdmin || userIsRegulatorioRegister) && status.status != STATUS_REGISTER.EmPreenchimento)
					return true;

				if ((userIsDescriptors || userIsDiseases) && status.status == STATUS_REGISTER.EmValidacaoPelaSGFITO)
					return true;

				if (userIsRequesterWriteRegister && (status.status == STATUS_REGISTER.EmCorrecao || status.status == STATUS_REGISTER.Cancelado))
					return true;

				return false;

			case 'draft':

				if (status.status !== STATUS_REGISTER.EmPreenchimento)
					return false;

				return true;

			case 'aprove':

				return status.status === STATUS_REGISTER.EmAnalisePeloRegulatorio && (userIsAdmin || userIsRegulatorioRegister);

			case 'send_to_fito':

				return status.status === STATUS_REGISTER.ValidadoPeloRegulatorio && (userIsAdmin || userIsRegulatorioRegister);

			case 'send_to_regulatory':

				if (status.status !== STATUS_REGISTER.EmPreenchimento && status.status !== STATUS_REGISTER.EmCorrecao && status.status !== STATUS_REGISTER.ReprovadoPeloRegulatorio)
					return false;

				return userIsAdmin || userIsRequesterWriteRegister;

			case 'validate_fito':

				return status.status === STATUS_REGISTER.EmValidacaoPelaSGFITO && (userIsDescriptors || userIsDiseases || userIsAdmin);

			case 'validate_fito_descritores':

				if (status.status !== STATUS_REGISTER.EmValidacaoPelaSGFITO)
					return false;

				if (!userIsDescriptors && !userIsAdmin)
					return false;

				if (aproveDataDescritores.need !== true)
					return false;

				if (aproveDataDescritores.date != null)
					return false;

				return true;

			case 'validate_fito_doencas':

				if (status.status !== STATUS_REGISTER.EmValidacaoPelaSGFITO)
					return false;

				if (!userIsDiseases && !userIsAdmin)
					return false;

				if (aproveDataDoencas.need !== true)
					return false;

				if (aproveDataDoencas.date != null)
					return false;

				return true;

			case 'can_save':

				if (status.status === STATUS_REGISTER.EmCorrecao && (userIsRequesterWriteRegister || userIsAdmin))
					return true;
				
				if (status.status === STATUS_REGISTER.ReprovadoPeloRegulatorio && (userIsRequesterWriteRegister || userIsAdmin))
					return true;

				let statusInRegister : boolean = status.status === STATUS_REGISTER.ValidadoPelaSGFITO || status.status === STATUS_REGISTER.ValidadoPelaSGFITOComAlteracoes || status.status === STATUS_REGISTER.AguardandoAprovacaoMAPA;

				if (statusInRegister && (userIsAdmin || userIsRegulatorioRegister))
					return true;
				
				if (status.status === STATUS_REGISTER.EmAnalisePeloRegulatorio && userIsAdmin)
					return true;

				return false;

			case 'post_validation':

				return status.status === STATUS_REGISTER.AguardandoAprovacaoMAPA
					|| status.status === STATUS_REGISTER.AprovadoPeloMAPA
					|| status.status === STATUS_REGISTER.Cancelado
					|| status.status === STATUS_REGISTER.EmValidacaoPelaSGFITO
					|| status.status === STATUS_REGISTER.ReprovadoPeloMAPA
					|| status.status === STATUS_REGISTER.ValidadoPelaSGFITO
					|| status.status === STATUS_REGISTER.ValidadoPelaSGFITOComAlteracoes
				;

			case 'protocol':

				if (!userIsRegulatorioRegister && !userIsAdmin)
					return false;

				if (status.status !== STATUS_REGISTER.ValidadoPeloRegulatorio && status.status !== STATUS_REGISTER.ValidadoPelaSGFITO && status.status !== STATUS_REGISTER.ValidadoPelaSGFITOComAlteracoes)
					return false;

				return true;
		}

		return true;
	}

	const ParseMessages = () : JSX.Element =>
	{
		return <Fragment>
			<If condition={successfulResponse && !errorOnSubmit}>
				<AlertSuccess
					message={STRINGS.ALERT_MESSAGE_SUCCESS}
				/>
			</If>

			<If condition={successRegulatory}>
				<AlertSuccess
					message={STRINGS.REGULATORY_SUCCESS}
				/>
			</If>

			<If condition={!successfulResponse && errorOnSubmit}>
				{errorOnSubmit !== null && errorOnSubmit.message.length > 0 ? errorOnSubmit.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
			</If>

			<If condition={loadingRequestData}>
				<span className="loading-page">
					<Loading />
				</span>
			</If>

			<If condition={successSGFITO && !errorSGFITO}>
				<AlertSuccess message={STRINGS.VALIDATE_SUCCESS} />
			</If>

			<If condition={!successfulResponse && errorSGFITO !== null}>
				{errorSGFITO !== null && errorSGFITO.message?.length > 0 ? errorSGFITO.message.map((errorE: string) => <AlertError message={errorE} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
			</If>
			<If condition={successDraft && !errorDraft}>
				<AlertSuccess message={STRINGS.SUCCESS_DRAFT} />
			</If>
			<If condition={!successDraft && errorDraft}>
				{errorDraft !== null && errorDraft.message.length > 0 ? errorDraft.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
			</If>
			<If condition={errorOnForm}>
				<AlertError message={STRINGS.ALERT_WARNING_FIELDS} />
			</If>
		</Fragment>;
	}

	const ParseGroup00 = () : JSX.Element =>
	{
		return <Fieldset title="Registro">
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={4}>
					<Form.Label>
						Código
					</Form.Label>
					<Form.Control
						value={requestCode}
						disabled
						readOnly
					/>
				</Form.Group>
				<Form.Group as={Col} md={4}>
					<Form.Label>
						Status
					</Form.Label>
					<Form.Control
						value={status.statusDescricao}
						disabled
						readOnly
					/>
				</Form.Group>
			</Form.Row>
			<If condition={status.status === STATUS_REGISTER.Cancelado}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Motivo Cancelamento
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="cancelReason"
							readOnly
						/>
					</Form.Group>
				</Form.Row>
			</If>
		</Fieldset>;
	}

	const ParseGroup01 = () : JSX.Element =>
	{
		return <Fieldset title="1. Identificação" required disabled={!can_edit('default')}>
			<If condition={defaultForm.errors.speciesName ||
				defaultForm.errors.registerType ||
				defaultForm.errors.nameCultivar}
			>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Nome comum da espécie<em>*</em>
					</Form.Label>
				&nbsp;&nbsp;
				<If condition={loadingNeededData}>
						<LoadingInForm />
					</If>
					<Form.Control
						as="select"
						ref={defaultForm.register}
						name="speciesName"
						isInvalid={defaultForm.errors.speciesName !== undefined}
						onChange={({ target }: any) => {
							getCultureById(target.value);
							
							let scientificName = culture.filter((item => {
								return item.id === Number(target.value);
							}))
					
							let result = scientificName[0];
							
							if (!!result)
								defaultForm.setValue('scientificName', result.descricao);

							let index = target.options.selectedIndex;
							setSpecieName(target.options[index].label);
							filterRecordType(target.options[index].label);
						}}
					>
						<Form.Control
							as="option"
							label="Escolha uma opção"
						/>
						{culture.map(item => (
							<Form.Control
								as="option"
								label={item.nome}
								value={item.id}
								key={item.id}
							/>
						))}
					</Form.Control>
					<If condition={defaultForm.errors.speciesName}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.speciesName?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>
						Nome científico da espécie<em>*</em>
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="scientificName"
						autoComplete="off"
						placeholder="Nome científico da espécie"
						readOnly
					/>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Grupo da Espécie<em>*</em>
					</Form.Label>
					<Form.Control
						as="select"
						ref={defaultForm.register}
						name="speciesGroup"
						readOnly
					>
						<Form.Control
							as="option"
							label="Grandes Culturas"
							value="Grandes Culturas"
						/>
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>
						Tipo de registro<em>*</em>
					</Form.Label>
				&nbsp;&nbsp;
				<If condition={loadingNeededData}>
						<LoadingInForm />
					</If>
					<Form.Control
						as="select"
						ref={defaultForm.register}
						name="registerType"
						isInvalid={defaultForm.errors.registerType !== undefined}
						onChange={({ target }: any) => {
							handleRequiredFields(target.value);
							let index = target.options.selectedIndex;
							setRecordTypeName(target.options[index].label);
							filterModels(target.value);
						}}
					>
						<Form.Control
							as="option"
							label="Escolha uma opção"
						/>
						{filteredRecords.map(item => (
							<Form.Control
								as="option"
								key={item.id}
								label={item.nome}
								value={item.id}
							/>
						))}
					</Form.Control>
					<If condition={defaultForm.errors.registerType}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.registerType?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.Label>
						Denominação da Cultivar/Linhagem<em>*</em>
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="nameCultivar"
						placeholder="Insira a denominação da cultivar"
						isInvalid={defaultForm.errors.nameCultivar !== undefined}
						autoComplete="off"
						maxLength={150}
					/>
					<If condition={defaultForm.errors.nameCultivar}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.nameCultivar?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
		</Fieldset>;
	}

	const ParseGroup02 = () : JSX.Element =>
	{
		return <Fragment>
			<Fieldset title="2. Requerente" disabled={!can_edit('default')}>
				<If condition={submited && !selectedApplicants.length}
				>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group className="label-btn">
						<Form.Label>
							Requerente<em>*</em>
						</Form.Label>
						<AddButton
							onClick={() => {
								setChooseApplicant(true);
								setUnselectedApplicant(selectedApplicants);
								setChoosenApplicants([]);
							}}
							type="button"
						/>
					&nbsp;&nbsp;
					<If condition={loadingNeededData}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<If condition={inserApplicants && selectedApplicants.length}>
					<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedApplicants}
							defaultColDef={{ flex: 1, sortable: true }}
							frameworkComponents={{
								action: actionApplicants,
							}}
						>
							<AgGridColumn
								headerName='Requerente'
								field="nome"
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
							/>
						</AgGridReact>
					</div>
				</If>
			</Fieldset>
			<If condition={chooseApplicant}>
				<Modal
					onHide={() => setChooseApplicant(false)}
					show={true}
					title="Lista de requerentes"
				>
					<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
						<AgGridReact
							rowData={unselectedApplicants}
							defaultColDef={{ flex: 1, sortable: true }}
							suppressRowClickSelection={true}
							onRowSelected={onRowSelectedApplicant}
							rowSelection={'multiple'}
						>
							<AgGridColumn
								headerName="Selecionar"
								checkboxSelection={true}
								headerCheckboxSelection
								pinned="left"
							/>
							<AgGridColumn
								headerName='Requerente'
								field="nome"
							/>
						</AgGridReact>
					</div>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setChooseApplicant(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="button"
							name="addApplicants"
							onClick={() => {
								setInsertApplicants(true);
								setChooseApplicant(false);
								setSelectedApplicants([...selectedApplicants, ...choosenApplicants])
							}}
							disabled={!choosenApplicants.length}
						/>
					</div>
				</Modal>
			</If>
		</Fragment>;
	}

	const ParseGroup03 = () : JSX.Element =>
	{
		return <Fragment>
			<Fieldset title="3. Melhorista responsável" disabled={!can_edit('default')}>
				<If condition={submited && (fieldApplicantResponsible && !selectedBreeder.length)}>
					<AlertError message={STRINGS.ALERT_WARNING_FIELDS} />
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} className="label-btn">
						<Form.Label>
							Nome&#40;s&#41;{fieldApplicantResponsible ? <em>*</em> : null}
						</Form.Label>
						<If condition={can_edit('default')}>
							<AddButton
								otherTitleAdd="Selecionar"
								onClick={() => {
									setChooseBreeder(true);

									let results: BreederResults[] = [];
									
									for (let i = 0, t = breeders.length; i < t; i++)
									{
										if (selectedBreeder.some(c => c.id === breeders[i].id))
											continue;

										results.push(breeders[i]);
									}
									
									setUnselectedBreeder(results);
									setChoosenBreeder([]);
								}}
								type="button"
							/>
						</If>
					&nbsp;&nbsp;
					<If condition={loadingNeededData}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<If condition={insertBreeder && selectedBreeder.length}>
					<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedBreeder}
							defaultColDef={{ flex: 1, sortable: true }}
							frameworkComponents={{
								action: actionBreeder,
							}}
						>
							<AgGridColumn
								headerName='Melhorista'
								field='nome'
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
							/>
						</AgGridReact>
					</div>
				</If>
			</Fieldset>
			<If condition={chooseBreeder}>
				<Modal
					title="Lista de melhoristas"
					show={true}
					onHide={() => setChooseBreeder(false)}
				>
					<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
						<AgGridReact
							rowData={unselectedBreeder}
							defaultColDef={{ flex: 1, sortable: true }}
							suppressRowClickSelection={false}
							onRowSelected={onRowSelectedBreeder}
							rowSelection={'multiple'}
						>
							<AgGridColumn
								headerName="Selecionar"
								checkboxSelection
								pinned="left"
							/>
							<AgGridColumn
								headerName='Melhorista'
								field='nome'
							/>
						</AgGridReact>
					</div>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setChooseBreeder(false)}
						/>
						<Button
							title="Selecionar"
							contained={true}
							type="button"
							onClick={() => {
								setChooseBreeder(false);
								setInsertBreeder(true);
								setSelectedBreeder([...selectedBreeder, ...choosenBreeder])
							}}
							disabled={!choosenBreeder.length}
						/>
					</div>
				</Modal>
			</If>
		</Fragment>;
	}

	const ParseGroup04 = () : JSX.Element =>
	{
		return <Fieldset title="4. Instituição responsável pelo ensaio" disabled={!can_edit('default')}>
			<If condition={defaultForm.errors.institutionName || defaultForm.errors.responsibleTechnician}>
				<AlertError message={STRINGS.ALERT_WARNING_FIELDS}/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.Label>
						Instituição{fieldInstitution ? <em>*</em> : null}
					</Form.Label>
					&nbsp;&nbsp;
					<If condition={loadingNeededData}>
						<LoadingInForm />
					</If>
					<Form.Control
						as="select"
						ref={defaultForm.register}
						name="institutionName"
						isInvalid={defaultForm.errors.institutionName !== undefined}
						onChange={({ target }: any) => setInstitutionSelected(target.value)}
					>
						<Form.Control
							as="option"
							label="Escolha uma instituição"
						/>
						{institutions.map(item => (
							<Form.Control
								as="option"
								key={item.id}
								label={item.nome}
								value={item.id}
							/>
						))}
					</Form.Control>
					<If condition={defaultForm.errors.institutionName}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.institutionName?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col}>
					<Form.Label>
						Técnicos responsáveis pelo ensaio{fieldInstitution ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="responsibleTechnician"
						autoComplete="off"
						placeholder="Insira os nomes dos técnicos"
						isInvalid={defaultForm.errors.responsibleTechnician !== undefined}
					/>
					<If condition={defaultForm.errors.responsibleTechnician}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.responsibleTechnician?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
		</Fieldset>;
	}

	const ParseGroup05 = () : JSX.Element =>
	{
		return <Fieldset title="5. Informações complementares" disabled={!can_edit('default')}>
			<If condition={defaultForm.errors.cultivarCountry
				|| defaultForm.errors.cultivarCertificateNumber
				|| defaultForm.errors.cultivarNameOfTransferor
				|| defaultForm.errors.cultivarInstrumentUsed
				|| defaultForm.errors.cultivarCountryOfOrigin
				|| defaultForm.errors.cultivarImportCountry
				|| defaultForm.errors.cultivarImportProcess
				|| defaultForm.errors.derivedCultivar
				|| defaultForm.errors.cultivarGeneticTransformationEvent
			}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Group controlId="protectedCultivar">
				<div className="option-cultivar">
					<span>Cultivar protegida?<em>*</em></span>
					<Form.Check
						value="sim"
						type="radio"
						aria-label="radio 1"
						label="Sim"
						onChange={() => setProtectedCultivar(true)}
						checked={protectedCultivar}
					/>
					<Form.Check
						value="não"
						type="radio"
						aria-label="radio 2"
						label="Não"
						onChange={() => setProtectedCultivar(false)}
						checked={!protectedCultivar}
					/>
				</div>
			</Form.Group>
			<If condition={protectedCultivar}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							País<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="cultivarCountry"
							autoComplete="off"
							placeholder="Insira o país da cultivar"
							isInvalid={defaultForm.errors.cultivarCountry !== undefined}
							maxLength={50}
						/>
						<If condition={defaultForm.errors.cultivarCountry}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.cultivarCountry?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Número do certificado de proteção<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="cultivarCertificateNumber"
							autoComplete="off"
							placeholder="Insira o número do certificado de proteção"
							isInvalid={defaultForm.errors.cultivarCertificateNumber !== undefined}
							maxLength={50}
						/>
						<If condition={defaultForm.errors.cultivarCertificateNumber}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.cultivarCertificateNumber?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</If>
			<Form.Group controlId="transferredCultivar">
				<div className="option-cultivar">
					<span>Cultivar transferida?<em>*</em></span>
					<Form.Check
						value="sim"
						type="radio"
						aria-label="radio 1"
						label="Sim"
						onChange={() => setTransferredCultivar(true)}
						checked={transferredCultivar}
					/>
					<Form.Check
						value="não"
						type="radio"
						aria-label="radio 2"
						label="Não"
						onChange={() => setTransferredCultivar(false)}
						checked={!transferredCultivar}
					/>
				</div>
			</Form.Group>
			<If condition={transferredCultivar}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Nome do cedente<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="cultivarNameOfTransferor"
							autoComplete="off"
							placeholder="Insira o nome do cedente"
							isInvalid={defaultForm.errors.cultivarNameOfTransferor !== undefined}
							maxLength={100}
						/>
						<If condition={defaultForm.errors.cultivarNameOfTransferor}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.cultivarNameOfTransferor?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Instrumento utilizado para a transferência<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="cultivarInstrumentUsed"
							autoComplete="off"
							placeholder="Insira o instrumento utilizado"
							isInvalid={defaultForm.errors.cultivarInstrumentUsed !== undefined}
							maxLength={100}
						/>
						<If condition={defaultForm.errors.cultivarInstrumentUsed}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.cultivarInstrumentUsed?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</If>
			<Form.Group controlId="foreignCultivar">
				<div className="option-cultivar">
					<span>Cultivar estrangeira?<em>*</em></span>
					<Form.Check
						value="sim"
						type="radio"
						aria-label="radio 1"
						label="Sim"
						onChange={() => setForeignCultivar(true)}
						checked={foreignCultivar}
					/>
					<Form.Check
						value="não"
						type="radio"
						aria-label="radio 2"
						label="Não"
						onChange={() => setForeignCultivar(false)}
						checked={!foreignCultivar}
					/>
				</div>
			</Form.Group>
			<If condition={foreignCultivar}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							País de origem<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="cultivarCountryOfOrigin"
							autoComplete="off"
							placeholder="Insira o país de origem"
							isInvalid={defaultForm.errors.cultivarCountryOfOrigin !== undefined}
							maxLength={50}
						/>
						<If condition={defaultForm.errors.cultivarCountryOfOrigin}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.cultivarCountryOfOrigin?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							País de Importação/procedência<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="cultivarImportCountry"
							autoComplete="off"
							placeholder="Insira o país de importação/procedência"
							isInvalid={defaultForm.errors.cultivarImportCountry !== undefined}
							maxLength={50}
						/>
						<If condition={defaultForm.errors.cultivarImportCountry}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.cultivarImportCountry?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>
							Processo de importação<em>*</em>
						</Form.Label>
						<Form.Control
							as="textarea"
							ref={defaultForm.register}
							name="cultivarImportProcess"
							autoComplete="off"
							placeholder="Informe como se deu o processo de importação"
							isInvalid={defaultForm.errors.cultivarImportProcess !== undefined}
							maxLength={100}
						/>
						<If condition={defaultForm.errors.cultivarImportProcess}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.cultivarImportProcess?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</If>
			<Form.Group controlId="foreignCultivar">
				<div className="option-cultivar">
					<span>Cultivar essencialmente derivada?<em>*</em></span>
					<Form.Check
						value="sim"
						type="radio"
						aria-label="radio 1"
						label="Sim"
						onChange={() => setEssentiallyDerived(true)}
						checked={essentiallyDerived}
					/>
					<Form.Check
						value="não"
						type="radio"
						aria-label="radio 2"
						label="Não"
						onChange={() => setEssentiallyDerived(false)}
						checked={!essentiallyDerived}
					/>
				</div>
			</Form.Group>
			<If condition={essentiallyDerived}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Cultivar<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="derivedCultivar"
							autoComplete="off"
							placeholder="Insira a cultivar de origem"
							isInvalid={defaultForm.errors.derivedCultivar !== undefined}
							maxLength={200}
						/>
						<If condition={defaultForm.errors.derivedCultivar}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.derivedCultivar?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</If>
			<Form.Group controlId="foreignCultivar">
				<div className="option-cultivar">
					<span>Organismo geneticamente modificado?<em>*</em></span>
					<Form.Check
						value="sim"
						type="radio"
						aria-label="radio 1"
						label="Sim"
						onChange={() => setModifiedOrganism(true)}
						checked={modifiedOrganism}
					/>
					<Form.Check
						value="não"
						type="radio"
						aria-label="radio 2"
						label="Não"
						onChange={() => setModifiedOrganism(false)}
						checked={!modifiedOrganism}
					/>
				</div>
			</Form.Group>
			<If condition={modifiedOrganism}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Evento de transformação genética<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="cultivarGeneticTransformationEvent"
							as="select"
							isInvalid={defaultForm.errors.cultivarGeneticTransformationEvent !== undefined}
							onChange={({ target }: any) => setEventSelected(target.value)}
							title={selectedEvent?.nomeEvento}
						>
							<Form.Control
								as="option"
								label="Selecione o Evento de transformação genética"
								value=""
							/>
							{events.map(item => (
								<Form.Control
									key={item.id}
									as="option"
									label={`${item.nomeEvento.substring(0, 135)}...`}
									value={item.id}
									title={item.nomeEvento}
								/>
							))}
						</Form.Control>
						<If condition={defaultForm.errors.cultivarGeneticTransformationEvent}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.cultivarGeneticTransformationEvent?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</If>
		</Fieldset>;
	}

	const ParseGroup06 = () : JSX.Element =>
	{
		return <Fieldset title="6. Origem da cultivar" disabled={!can_edit('default')}>
			<If condition={defaultForm.errors.institutionCompany
				|| defaultForm.errors.breederParticipant
				|| defaultForm.errors.crossingYear
				|| defaultForm.errors.crossingPlace
				|| defaultForm.errors.crossingInstitution
				|| defaultForm.errors.genealogy
				|| defaultForm.errors.designation
			}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.Label>
						Instituição ou empresa obtentora, introdutoras e/ou detentora{fieldCultivarOrigin ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="institutionCompany"
						placeholder="Informe a instituição ou empresa"
						autoComplete="off"
						isInvalid={defaultForm.errors.institutionCompany !== undefined}
						maxLength={150}
					/>
					<If condition={defaultForm.errors.institutionCompany}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.institutionCompany?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Melhoristas participantes{fieldCultivarOrigin ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						as="textarea"
						ref={defaultForm.register}
						name="breederParticipant"
						placeholder="Insira os melhoristas participantes"
						autoComplete="off"
						isInvalid={defaultForm.errors.breederParticipant !== undefined}
					/>
					<If condition={defaultForm.errors.breederParticipant}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.breederParticipant?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col}>
					<Form.Label>
						Cruzamento
			</Form.Label>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={3}>
					<Form.Label>
						Ano de realização{fieldCultivarOrigin ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="crossingYear"
						placeholder="Insira o ano"
						autoComplete="off"
						isInvalid={defaultForm.errors.crossingYear !== undefined}
						type="number"
						maxLength={4}
					/>
					<If condition={defaultForm.errors.crossingYear}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.crossingYear?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
				<Form.Group as={Col} md={4}>
					<Form.Label>
						Local{fieldCultivarOrigin ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="crossingPlace"
						placeholder="Insira o local de realização do cruzamento"
						autoComplete="off"
						isInvalid={defaultForm.errors.crossingPlace !== undefined}
						maxLength={200}
					/>
					<If condition={defaultForm.errors.crossingPlace}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.crossingPlace?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>
						Instituição que realizou{fieldCultivarOrigin ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="crossingInstitution"
						placeholder="Insira a instituição responsável pela realização"
						autoComplete="off"
						isInvalid={defaultForm.errors.crossingInstitution !== undefined}
						maxLength={150}
					/>
					<If condition={defaultForm.errors.crossingInstitution}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.crossingInstitution?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Genealogia{fieldCultivarOrigin ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="genealogy"
						placeholder="Insira os parentais imediatos"
						autoComplete="off"
						isInvalid={defaultForm.errors.genealogy !== undefined}
						maxLength={150}
					/>
					<If condition={defaultForm.errors.genealogy}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.genealogy?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>
						Denominação experimental ou pré-comercial{fieldCultivarOrigin ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="designation"
						placeholder="Insira a denominação"
						autoComplete="off"
						isInvalid={defaultForm.errors.designation !== undefined}
						maxLength={150}
					/>
					<If condition={defaultForm.errors.designation}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.designation?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
		</Fieldset>;
	}

	const ParseGroup07 = () : JSX.Element =>
	{
		return <Fieldset title={"7. Avaliações da cultivar"} required={fieldCultivarRate} disabled={!can_edit('default')}>
			<If condition={submited && (fieldCultivarRate && (defaultForm.getValues("ratePlaces") && !defaultForm.getValues("ratePlaces").length && !fileField7) && !selectedAdaptationRegions.length)}>
				<AlertError message={STRINGS.ALERT_WARNING_FIELDS} />
			</If>
			<If condition={!defaultForm.watch().speciesName}>
				<AlertError	message={"Selecione 'Nome comum da espécie' primeiro."} />
			</If>
			<If condition={defaultForm.errors.ratePlaces}>
				<AlertError message={defaultForm.errors?.ratePlaces?.message} />
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Locais da avaliação{fieldCultivarRate ? <em>*</em> : null}
					</Form.Label>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<If condition={filteredModels?.modeloAvaliacaoCultivar}>
						<AddButton
							type="button"
							onClick={() => {
								if (filteredModels?.modeloAvaliacaoCultivar) {
									downloadModel(filteredModels.modeloAvaliacaoCultivar?.codigo, filteredModels.modeloAvaliacaoCultivar.nome);
								}
							}}
							otherTitle="Baixar modelo"
							isDownload={true}
						/>
					</If>
					<If condition={!filteredModels?.modeloAvaliacaoCultivar}>
						<NotAvailableModel />
					</If>
					<If condition={loadingDownload}>
						<LoadingInForm />
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.File
						ref={defaultForm.register}
						name="ratePlaces"
						label={lblAdaptationRegions}
						data-browse="upload"
						custom
						onChange={({ target }: any) => {
							if (!target.value) {
								return
							}
							setLblAdaptationRegions(`${target.files[0].name}`);
						}}
					/>
				</Form.Group>
				<If condition={!!fileField7?.id}>
					<FileExistent text={fileField7?.nome} date={fileField7?.dataAtualizacao}
						onDownload={(e: any) => {
							e.preventDefault();
							downloadModelFile(fileField7);
						}}
						onClick={() => {
							setArquivosParaExcluir([...arquivosParaExcluir, fileField7?.id]);
							setFileField7({id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: ''});
						}}
					/>
				</If>
				<br />
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group className="label-btn">
					<Form.Label>
						Região de adaptação{fieldCultivarRate ? <em>*</em> : null}
					</Form.Label>
					<AddButton
						type="button"
						onClick={() => {
							setChooseAdaptationRegions(true);
							setUnselectedAdaptationRegion(selectedAdaptationRegions);
							setChoosenAdaptationRegions([]);
						}}
					/>
				</Form.Group>
			</Form.Row>
			<If condition={selectedAdaptationRegions.length}>
				<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
					<AgGridReact
						rowData={selectedAdaptationRegions}
						defaultColDef={{ flex: 1, sortable: true }}
						frameworkComponents={{
							action: actionAdaptationRegions
						}}
					>
						<AgGridColumn
							headerName="Região"
							field="descricao"
						/>
						<AgGridColumn
							headerName="Ação"
							cellRenderer="action"
						/>
					</AgGridReact>
				</div>
			</If>
			<If condition={chooseAdaptationRegions}>
				<Modal
					title="Lista de regiões de adaptação"
					onHide={() => setChooseAdaptationRegions(false)}
					show={true}
				>
					<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
						<AgGridReact
							rowData={unselectedAdaptationRegions}
							defaultColDef={{ flex: 1, sortable: true }}
							suppressRowClickSelection={true}
							onRowSelected={onRowSelectedAdaptationRegions}
							rowSelection={'multiple'}
						>
							<AgGridColumn
								headerName="Selecionar"
								headerCheckboxSelection
								checkboxSelection={true}
								pinned="left"
							/>
							<AgGridColumn
								headerName='Região'
								field='descricao'
							/>
						</AgGridReact>
					</div>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setChooseAdaptationRegions(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="button"
							onClick={() => {
								setChooseAdaptationRegions(false);
								setInsertAdaptationRegions(true);
								setSelectedAdaptationRegions([...selectedAdaptationRegions, ...choosenAdaptationRegions])
							}}
							disabled={!choosenAdaptationRegions.length}
						/>
					</div>
				</Modal>
			</If>
		</Fieldset>;
	}

	const ParseGroup08 = () : JSX.Element =>
	{
		return <Fieldset title={"8. Descritores"} required={fieldDescriptors}>
			<If condition={defaultForm.errors.all_descriptors}>
				<AlertError message={defaultForm.errors?.all_descriptors?.message} />
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Descritores{fieldDescriptors ? <em>*</em> : null}
					</Form.Label>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<AddButton
					onClick={() => {
						setInsertDescriptors(true);
						setFilteredDescriptors(selectedDescriptors);
					}}
					type="button"
				/>
				<input type="hidden" ref={defaultForm.register} name="all_descriptors" value="" />
				</Form.Group>
			</Form.Row>
			<If condition={choosenDescriptors && selectedDescriptors.length}>
				<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
					<AgGridReact
						rowData={selectedDescriptors}
						defaultColDef={{ flex: 1, sortable: true, resizable: true }}
						suppressRowClickSelection={true}
						onRowSelected={onRowSelectedApplicant}
						rowSelection={'multiple'}
						frameworkComponents={{
							action: actionDescriptors,
						}}
					>
						<AgGridColumn
							headerName="Descritor"
							cellStyle={{
								alignItems: 'center'
							}}
							wrapText
							autoHeight
							valueGetter={({ data }: any) => data.descritores.descricao}
						/>
						<AgGridColumn
							headerName="Característica"
							valueGetter={({ data }: any) => data.caracteristicasDescritores.descricao}
							cellStyle={{
								alignItems: 'center'
							}}
						/>
						<AgGridColumn
							headerName="Observação"
							valueGetter={({ data }: any) => data.observacao}
							cellStyle={{
								alignItems: 'center'
							}}
						/>
						<AgGridColumn
							headerName="Ação"
							cellRenderer="action"
							cellStyle={{
								alignItems: 'center'
							}}
						/>
					</AgGridReact>
				</div>
			</If>
			<br />
			<If condition={status.status !== STATUS_REGISTER.EmPreenchimento && status.status !== STATUS_REGISTER.EmAnalisePeloRegulatorio && status.status !== STATUS_REGISTER.ValidadoPeloRegulatorio && status.status !== STATUS_REGISTER.ReprovadoPeloRegulatorio && status.status !== STATUS_REGISTER.EmCorrecao && registroInfoValidation?.usuarioValidacaoDescritores}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Validador
						</Form.Label>
						<Form.Control
							value={registroInfoValidation?.usuarioValidacaoDescritores}
							disabled
							readOnly
							style={{ textTransform: 'capitalize' }}
						/>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Status
						</Form.Label>
						<Form.Control
							value={registroInfoValidation?.descritoresValidado ? "Validado" : "Em validação"}
							disabled
							readOnly
						/>
					</Form.Group>
				</Form.Row>
			</If>
			<If condition={insertDescriptors}>
				<Modal
					title="Lista de descritores"
					onHide={() => setInsertDescriptors(false)}
					show={true}
				>
					<If condition={defaultForm.errors.descriptorRegisterRNC || defaultForm.errors.characteristicRegisterRNC}>
						<AlertError
							message={STRINGS.ALERT_WARNING_FIELDS}
						/>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Descritor<em>*</em>
							</Form.Label>
							<Form.Control
								as="select"
								ref={defaultForm.register}
								name="descriptorRegisterRNC"
								isInvalid={defaultForm.errors.descriptorRegisterRNC !== undefined}
								onChange={({ target }: any) => {
									let characteristics = descriptorsByCulture.filter(desc => {
										return desc.id === Number(target.value);
									})
									let { caracteristicasDescritores } = characteristics[0];
									setCharacteristicsByDescriptor(caracteristicasDescritores);
									let index = target.options.selectedIndex;
									setDescriptorName(target.options[index].label);
								}}
							>
								<Form.Control
									as="option"
									label="Escolha um descritor"
								/>
								{descriptorsByCulture.map(item => (
									<Form.Control
										key={item.id}
										as="option"
										label={item.descricao}
										value={item.id}
									/>
								))}
							</Form.Control>
							<If condition={defaultForm.errors.descriptorRegisterRNC}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.descriptorRegisterRNC?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Característica<em>*</em>
							</Form.Label>
							<Form.Control
								as="select"
								ref={defaultForm.register}
								name="characteristicRegisterRNC"
								isInvalid={defaultForm.errors.characteristicRegisterRNC !== undefined}
								onChange={({ target }: any) => {
									let index = target.options.selectedIndex;
									setDescriptorCharacteristic(target.options[index].label);
								}}
							>
								<Form.Control
									as="option"
									label="Escolha uma característica"
								/>
								{characteristicsByDescriptor.map(item => (
									<Form.Control
										key={item.id}
										as="option"
										label={item.descricao}
										value={item.id}
									/>
								))}
							</Form.Control>
							<If condition={defaultForm.errors.characteristicRegisterRNC}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.characteristicRegisterRNC?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observações
							</Form.Label>
							<Form.Control
								as="textarea"
								ref={defaultForm.register}
								name="descriptorNote"
								maxLength={200}
							/>
						</Form.Group>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							contained={false}
							onClick={() => setInsertDescriptors(false)}
						/>
						<Button
							title="Inserir"
							type="button"
							contained={true}
							onClick={() => {
								setSelectedDescriptorsList();
							}}
						/>
					</div>
				</Modal>
			</If>
		</Fieldset>;
	}
	
	const ParseGroup09 = () : JSX.Element =>
	{
		return <Fieldset title="9. Reação a doenças e nematóides" required={fieldDiseaseOrNematode}>
			<If condition={defaultForm.errors.all_diseases_or_nematoides}>
				<AlertError message={defaultForm.errors?.all_diseases_or_nematoides?.message} />
			</If>
			<If condition={submited && (fieldDiseaseOrNematode && !selectedDiseasesOrNematodes.length)}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<If condition={!defaultForm.watch().speciesName}>
				<AlertError message={"Selecione 'Nome comum da espécie' primeiro."} />
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Reação a doenças e nematóides{fieldDiseaseOrNematode ? <em>*</em> : null}
					</Form.Label>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<AddButton
						disabled={!defaultForm.watch().speciesName}
						type="button"
						onClick={() => {
							setInsertDiseases(true);
							setFilteredDiseases(selectedDiseasesOrNematodes)
						}
						}
					/>
					<input type="hidden" ref={defaultForm.register} name="all_diseases_or_nematoides" value="" />
				</Form.Group>
			</Form.Row>
			<If condition={choosenDiseases && selectedDiseasesOrNematodes.length}>
				<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
					<AgGridReact
						rowData={selectedDiseasesOrNematodes}
						defaultColDef={{ flex: 1, sortable: true, resizable: true }}
						suppressRowClickSelection={true}
						onRowSelected={onRowSelectedApplicant}
						rowSelection={'multiple'}
						frameworkComponents={{
							action: actionDiseases,
						}}
					>
						<AgGridColumn
							headerName="Doença"
							wrapText
							autoHeight
							cellStyle={{
								alignItems: 'center'
							}}
							valueGetter={({ data }: any) => data.doencas.descricao}
						/>
						<AgGridColumn
							headerName="Reação em casa de vegetação"
							wrapText
							autoHeight
							cellStyle={{
								alignItems: 'center'
							}}
							valueGetter={({ data }: any) => data.reacaoDoencaCasaVegetacao.descricao}
						/>
						<AgGridColumn
							headerName="Reação em campo"
							wrapText
							autoHeight
							cellStyle={{
								alignItems: 'center'
							}}
							valueGetter={({ data }: any) => data.reacaoDoencaCampo.descricao}
						/>
						<AgGridColumn
							headerName="Observação"
							wrapText
							autoHeight
							cellStyle={{
								alignItems: 'center'
							}}
							valueGetter={({ data }: any) => data.observacao}
						/>
						<AgGridColumn
							headerName="Ação"
							wrapText
							autoHeight
							cellRenderer="action"
							cellStyle={{
								alignItems: 'center'
							}}
						/>
					</AgGridReact>
				</div>
			</If>
			<br />
			<If condition={status.status !== STATUS_REGISTER.EmPreenchimento && status.status !== STATUS_REGISTER.EmAnalisePeloRegulatorio && status.status !== STATUS_REGISTER.ValidadoPeloRegulatorio && status.status !== STATUS_REGISTER.ReprovadoPeloRegulatorio && status.status !== STATUS_REGISTER.EmCorrecao && registroInfoValidation?.usuarioValidacaoDoencas}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Validador
						</Form.Label>
						<Form.Control
							value={registroInfoValidation?.usuarioValidacaoDoencas}
							disabled
							readOnly
							style={{ textTransform: 'capitalize' }}
						/>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Status
						</Form.Label>
						<Form.Control
							value={registroInfoValidation?.doencaValidada ? "Validado" : "Em validação"}
							disabled
							readOnly
						/>
					</Form.Group>
				</Form.Row>
			</If>
			<If condition={insertDiseases}>
				<Modal
					title="Lista de doenças e nematóides"
					onHide={() => setInsertDiseases(false)}
					show={true}
				>
					<If condition={defaultForm.errors.diseaseOrNematode ||
						defaultForm.errors.greenhouseReactionDisease ||
						defaultForm.errors.countrysideReactionDisease
					}>
						<AlertError
							message={STRINGS.ALERT_WARNING_FIELDS}
						/>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Espécie: {defaultForm.getValues('scientificName')}
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Doença/Nematóide<em>*</em>
							</Form.Label>
							<Form.Control
								as="select"
								ref={defaultForm.register}
								name="diseaseOrNematode"
								isInvalid={defaultForm.errors.diseaseOrNematode !== undefined}
								onChange={({ target }: any) => {
									setRespectiveReactionsDiseases(target.value);
									let index = target.options.selectedIndex;
									setDiseaseName(target.options[index].label);
								}}
							>
								<Form.Control
									as="option"
									label="Escolha uma doença"
								/>
								{diseasesByCulture.map(item => (
									<Form.Control
										as="option"
										label={item.descricao}
										value={item.id}
										key={item.id}
									/>
								))}
							</Form.Control>
							<If condition={defaultForm.errors.diseaseOrNematode}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.diseaseOrNematode?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Reação em casa de vegetação<em>*</em>
							</Form.Label>
							<Form.Control
								as="select"
								ref={defaultForm.register}
								name="greenhouseReactionDisease"
								isInvalid={defaultForm.errors.greenhouseReactionDisease !== undefined}
								onChange={({ target }: any) => {
									let index = target.options.selectedIndex;
									setDiseaseReactionGreenHouse(target.options[index].label);
								}}
							>
								<Form.Control
									as="option"
									label="Escolha uma reação"
								/>
								{diseasesReactions.map(item => (
									<Form.Control
										as="option"
										label={item.descricao}
										value={item.id}
										key={item.id}
									/>
								))}
							</Form.Control>
							<If condition={defaultForm.errors.greenhouseReactionDisease}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.greenhouseReactionDisease?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Reação em campo<em>*</em>
							</Form.Label>
							<Form.Control
								as="select"
								ref={defaultForm.register}
								name="countrysideReactionDisease"
								isInvalid={defaultForm.errors.countrysideReactionDisease !== undefined}
								onChange={({ target }: any) => {
									let index = target.options.selectedIndex;
									setDiseaseReaction(target.options[index].label);
								}}
							>
								<Form.Control
									as="option"
									label="Escolha uma reação"
								/>
								{diseasesReactions.map(item => (
									<Form.Control
										as="option"
										label={item.descricao}
										value={item.id}
										key={item.id}
									/>
								))}
							</Form.Control>
							<If condition={defaultForm.errors.countrysideReactionDisease}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.countrysideReactionDisease?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observações
							</Form.Label>
							<Form.Control
								as="textarea"
								ref={defaultForm.register}
								name="diseaseOrNematodeNote"
								maxLength={200}
							/>
						</Form.Group>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							type="button"
							onClick={() => setInsertDiseases(false)}
							contained={false}
						/>
						<Button
							title="Inserir"
							type="button"
							contained={true}
							onClick={() => {
								setSelectedDiseasesOrNematodesList();
							}}
						/>
					</div>
				</Modal>
			</If>
		</Fieldset>;
	}
	
	const ParseGroup10 = () : JSX.Element =>
	{
		return <Fieldset title="10. Avaliação do ciclo e características agronômicas da cultivar" disabled={!can_edit('default')}>
			<If condition={submited &&
				(fieldAgronomicCharacteristics && defaultForm.getValues("agronomicCharacteristics") && !defaultForm.getValues("agronomicCharacteristics").length && !fileField10)}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<If condition={!defaultForm.watch().speciesName}>
				<AlertError message={"Selecione 'Nome comum da espécie' primeiro."} />
			</If>
			<Group_10 name="textoCicloAgronomico" form={defaultForm} culture={cultureDetails} />
			<If condition={specieName === "Soja"}>
				<If condition={cultureDetails && !cultureDetails?.cicloAgronomicoAsText}>
					<Form.Row className="row-custom">
						<input type="hidden" ref={defaultForm.register} name="textoCicloAgronomico" />
						<Form.Group as={Col}>
							<Form.Label>
								Avaliação do ciclo e características agronômicas da cultivar{fieldAgronomicCharacteristics ? <em>*</em> : null}
							</Form.Label>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<If condition={filteredModels?.modeloCicloAgronomico}>
								<AddButton
									type="button"
									onClick={() => {
										if (filteredModels?.modeloCicloAgronomico) {
											downloadModel(filteredModels.modeloCicloAgronomico.codigo, filteredModels.modeloCicloAgronomico.nome);
										}
									}}
									otherTitle="Baixar modelo"
									isDownload={true}
								/>
							</If>
							<If condition={!filteredModels?.modeloCicloAgronomico}>
								<NotAvailableModel />
							</If>
							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.File
								ref={defaultForm.register}
								name="agronomicCharacteristics"
								label={lblAgronomicCharacteristics}
								data-browse="upload"
								custom
								onChange={({ target }: any) => {
									if (!target.value) {
										return
									}
									setLblAgronomicCharacteristics(`${target.files[0].name}`);
								}}
							/>
						</Form.Group>
						<If condition={!!fileField10?.id}>
							<FileExistent text={fileField10?.nome} date={fileField10?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField10);
								}}
								onClick={() => {
									setArquivosParaExcluir([...arquivosParaExcluir, fileField10?.id]);
									setFileField10({ id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: '' });
								}}
							/>
						</If>
						<br />
					</Form.Row>
				</If>
			</If>
			<If condition={specieName === "Arroz"}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Sistema de Cultivo: Irrigação por Inundação, Sequeiro ou Outro (Especificar)
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasArrozSistemaCultivo"
							placeholder="Informe o Sistema de Cultivo"
							autoComplete="off"
							isInvalid={defaultForm.errors.sistemaCultivo !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Comportamento da Cultivar em Relação ao Acamamento: Resistente, Mëdio Resistente, Mëdio Suscetível, Suscetível
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasArrozComportamentoAcamamento"
							placeholder="Informe o Comportamento da Cultivar em Relação ao Acamamento"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasArrozComportamentoAcamamento !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Comportamento da Cultivar em Relação ao Degrane Natural: Fácil, Intermediário, Difícil
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasArrozComportamentoDegrane"
							placeholder="Informe o Comportamento da Cultivar em Relação ao Degrane"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasArrozComportamentoDegrane !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Ciclo - Número de Dias da Semeadura à Completa Maturação
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasArrozCicloMaturacao"
							placeholder="Informe o Ciclo - Dias até a Maturação"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasArrozCicloMaturacao !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Peso de Mil Sementes em Gramas
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasArrozPesoMilSementes"
							placeholder="Informe o Peso de Mil Sementes em Gramas"
							type="number"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasArrozPesoMilSementes !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Número de Dias da Semeadura até 50% das Panículas Emergidas
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasArrozDiasSemeadura"
							placeholder="Informe o Número de Dias da Semeadura"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasArrozDiasSemeadura !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
			</If>
			<If condition={specieName === "Algodão"}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Ciclo até Florescimento em Dias
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasAlgodaoCicloFlorescimento"
							placeholder="Informe o Ciclo de Florescimento"
							type="number"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasAlgodaoCicloFlorescimento !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Peso de Cem Sementes em Gramas
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasAlgodaoPesoCemSementes"
							placeholder="Informe o Peso de Cem Sementes em Gramas"
							type="number"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasAlgodaoPesoCemSementes !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Percentagem de Fibras
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasAlgodaoPercentagemFibras"
							placeholder="Informe o Percentagem de Fibras"
							autoComplete="off"
							type="number"
							isInvalid={defaultForm.errors.caracteristicasAlgodaoPercentagemFibras !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Precocidade de Maturação em Dias
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasAlgodaoPrecocidadeMaturacao"
							placeholder="Informe a Precocidade da Maturação"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasAlgodaoPrecocidadeMaturacao !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Ciclo até a Colheita em Dias
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasAlgodaoCicloColheita"
							placeholder="Informe o Ciclo até a Colheita"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasAlgodaoCicloColheita !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Retenção da Pluma pela Cápsula após a Deiscência
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasAlgodaoRetencaoPluma"
							placeholder="Informe a Retenção da Pluma"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasAlgodaoRetencaoPluma !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Peso do Capulho em gramas
						</Form.Label>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Control
							ref={defaultForm.register}
							name="caracteristicasAlgodaoPesoCapulho"
							placeholder="Informe o Peso do Capulho"
							type="number"
							autoComplete="off"
							isInvalid={defaultForm.errors.caracteristicasAlgodaoPesoCapulho !== undefined}
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
			</If>

		</Fieldset>;
	}
	
	const ParseGroup11 = () : JSX.Element =>
	{
		return <Fieldset title="11. Avaliação da produtividade" required={fieldProductivity} disabled={!can_edit('default')}>
			<If condition={submited &&
				(fieldProductivity && (defaultForm.getValues("productivityAssessment") && !defaultForm.getValues("productivityAssessment").length && !fileField10))}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<If condition={!defaultForm.watch().speciesName}>
				<AlertError message={"Selecione 'Nome comum da espécie' primeiro."} />
			</If>
			<If condition={defaultForm.errors.productivityAssessment}>
				<AlertError message={defaultForm.errors?.productivityAssessment?.message} />
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Avaliação da produtividade{fieldProductivity ? <em>*</em> : null}
					</Form.Label>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<If condition={filteredModels?.modeloProdutividade}>
						<AddButton
							type="button"
							onClick={() => {
								if (filteredModels?.modeloProdutividade) {
									downloadModel(filteredModels.modeloProdutividade.codigo, filteredModels.modeloProdutividade.nome);
								}
							}}
							otherTitle="Baixar modelo"
							isDownload={true}
						/>
					</If>
					<If condition={!filteredModels?.modeloProdutividade}>
						<NotAvailableModel />
					</If>
					<If condition={loadingDownload}>
						<LoadingInForm />
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.File
						ref={defaultForm.register}
						name="productivityAssessment"
						label={lblProductivityAssessment}
						data-browse="upload"
						custom
						onChange={({ target }: any) => {
							if (!target.value) {
								return
							}
							setLblProductivityAssessment(`${target.files[0].name}`);
						}}
					/>
				</Form.Group>
				<If condition={!!fileField11?.id}>
					<FileExistent text={fileField11?.nome} date={fileField11?.dataAtualizacao}
						onDownload={(e: any) => {
							e.preventDefault();
							downloadModelFile(fileField11);
						}}
						onClick={() => {
							setArquivosParaExcluir([...arquivosParaExcluir, fileField11?.id]);
							setFileField11({id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: ''});
						}}
					/>
				</If>
				<br />
			</Form.Row>
		</Fieldset>;
	}
	
	const ParseGroup12 = () : JSX.Element =>
	{
		return <Fieldset title="12. Avaliação da qualidade tecnológica/industrial" required={fieldQuality} disabled={!can_edit('default')}>
			<If condition={submited &&
				(fieldQuality && defaultForm.getValues("qualityAssessment") && !defaultForm.getValues("qualityAssessment").length && !fileField10)}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<If condition={!defaultForm.watch().speciesName}>
			<AlertError
					message={"Selecione 'Nome comum da espécie' primeiro."}
				/>
			</If>
			<If condition={defaultForm.errors.qualityAssessment}>
				<AlertError message={defaultForm.errors?.qualityAssessment?.message} />
			</If>
			<Group_12 name="textoAvaliacaoCultivar" form={defaultForm} culture={cultureDetails} />
			<If condition={cultureDetails && !cultureDetails?.avaliacaoCulivarAsText}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Avaliação da qualidade{fieldQuality ? <em>*</em> : null}
						</Form.Label>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<If condition={filteredModels?.modeloQualidade}>
							<AddButton
								type="button"
								onClick={() => {
									if (filteredModels?.modeloQualidade) {
										downloadModel(filteredModels.modeloQualidade.codigo, filteredModels.modeloQualidade.nome);
									}
								}}
								otherTitle="Baixar modelo"
								isDownload={true}
							/>
						</If>
						<If condition={!filteredModels?.modeloQualidade}>
							<NotAvailableModel />
						</If>
						<If condition={loadingDownload}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.File
							ref={defaultForm.register}
							name="qualityAssessment"
							label={lblQualityAssessment}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblQualityAssessment(`${target.files[0].name}`);
							}}
						/>
					</Form.Group>
					<If condition={!!fileField12?.id}>
						<FileExistent text={fileField12?.nome} date={fileField12?.dataAtualizacao}
							onDownload={(e: any) => {
								e.preventDefault();
								downloadModelFile(fileField12);
							}}
							onClick={() => {
								setArquivosParaExcluir([...arquivosParaExcluir, fileField12?.id]);
								setFileField12({id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: ''});
							}}
						/>
					</If>
					<br />
				</Form.Row>
			</If>
		</Fieldset>;
	}
	
	const ParseGroup13 = () : JSX.Element =>
	{
		return <Fieldset title="13. Intenção de comercialização" required={fieldCommercialization} disabled={!can_edit('default')}>
			<If condition={defaultForm.errors.yearOfcommercialization
				|| defaultForm.errors.experimentalMaterial
			}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={4}>
					<Form.Label>
						Início de comercialização&#40;ano&#41;{fieldCommercialization ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="yearOfcommercialization"
						type="number"
						placeholder="Insira o ano início de comercialização"
						isInvalid={defaultForm.errors.yearOfcommercialization !== undefined}
						maxLength={4}
					/>
					<If condition={defaultForm.errors.yearOfcommercialization}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.yearOfcommercialization?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col} md={6}>
					<Form.Label>
						Quantidade em estoque de material experimental &#45; pré-comercial da cultivar &#40;em kg&#41;{fieldCommercialization ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="experimentalMaterial"
						type="number"
						placeholder="Insira a quantidade em (kg)"
						isInvalid={defaultForm.errors.experimentalMaterial !== undefined}
						maxLength={30}
					/>
					<If condition={defaultForm.errors.experimentalMaterial}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.experimentalMaterial?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
		</Fieldset>;
	}
	
	const ParseGroup14 = () : JSX.Element =>
	{
		return <Fieldset title="14. Sistemas de produção sugeridos para a cultivar" disabled={!can_edit('default')}>
			<If condition={defaultForm.errors.productionSystemsForTheCultivar}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Sistemas De Produção Sugeridos{fieldProductionSystem ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						as="textarea"
						ref={defaultForm.register}
						name="productionSystemsForTheCultivar"
						type="text"
						placeholder="Insira as informações dos sistemas de produção"
						isInvalid={defaultForm.errors.productionSystemsForTheCultivar !== undefined}
					/>
					<If condition={defaultForm.errors.productionSystemsForTheCultivar}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.productionSystemsForTheCultivar?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
		</Fieldset>;
	}
	
	const ParseGroup15 = () : JSX.Element =>
	{
		return <Fieldset title="15. Limitações da cultivar: condições de cultivo e uso que devem ser evitadas" required={fieldLimitationCultivar} disabled={!can_edit('default')}>
			<If condition={defaultForm.errors.limitationsOfTheCultivar}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Limitações da cultivar{fieldLimitationCultivar ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						as="textarea"
						ref={defaultForm.register}
						name="limitationsOfTheCultivar"
						type="text"
						placeholder="Insira as informações sobre as limitações da cultivar"
						isInvalid={defaultForm.errors.limitationsOfTheCultivar !== undefined}
					/>
					<If condition={defaultForm.errors.limitationsOfTheCultivar}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.limitationsOfTheCultivar?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
		</Fieldset>;
	}
	
	const ParseGroup16 = () : JSX.Element =>
	{
		return <Fieldset title="16. Informações adicionais" required={fieldAdditionalInformation} disabled={!can_edit('default')}>
			<If condition={defaultForm.errors.additionalInformation}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Informações adicionais {fieldAdditionalInformation ? <em>*</em> : null}
					</Form.Label>
					<Form.Control
						as="textarea"
						ref={defaultForm.register}
						name="additionalInformation"
						type="text"
						placeholder="Insira as informações adicionais"
						isInvalid={defaultForm.errors.additionalInformation !== undefined}
					/>
					<If condition={defaultForm.errors.additionalInformation}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.additionalInformation?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
		</Fieldset>;
	}
	
	const ParseGroup17 = () : JSX.Element =>
	{
		return <Fieldset title="17. Relatório técnico de obtenção" required disabled={!can_edit('default')}>
			<If condition={submited &&
				(defaultForm.getValues("technicalObtainingReport") && !defaultForm.getValues("technicalObtainingReport").length && !fileField17)}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<If condition={!defaultForm.watch().speciesName}>
				<AlertError
					message={"Selecione 'Nome comum da espécie' primeiro."}
				/>
			</If>
			<If condition={defaultForm.errors.technicalObtainingReport}>
				<AlertError message={defaultForm.errors?.technicalObtainingReport?.message} />
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Relatório técnico de obtenção<em>*</em>
					</Form.Label>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<If condition={filteredModels?.modeloRelatorioTecnico}>
						<AddButton
							type="button"
							onClick={() => {
								if (filteredModels?.modeloRelatorioTecnico) {
									downloadModel(filteredModels.modeloRelatorioTecnico.codigo, filteredModels.modeloRelatorioTecnico.nome);
								}
							}}
							otherTitle="Baixar modelo"
							isDownload={true}
						/>
					</If>
					<If condition={!filteredModels?.modeloRelatorioTecnico}>
						<NotAvailableModel />
					</If>
					<If condition={loadingDownload}>
						<LoadingInForm />
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.File
						ref={defaultForm.register}
						type="file"
						name="technicalObtainingReport"
						label={lblTechnicalObtainingReport}
						data-browse="upload"
						custom
						onChange={({ target }: any) => {
							if (!target.value) {
								return
							}
							setLblTechnicalObtainingReport(`${target.files[0].name}`);
						}}
					/>
				</Form.Group>
				<If condition={!!fileField17?.id}>
					<FileExistent text={fileField17?.nome} date={fileField17?.dataAtualizacao}
						onDownload={(e: any) => {
							e.preventDefault();
							downloadModelFile(fileField17);
						}}
						onClick={() => {
							setArquivosParaExcluir([...arquivosParaExcluir, fileField17?.id]);
							setFileField17({id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: ''});
						}}
					/>
				</If>
				<br />
			</Form.Row>
		</Fieldset>;
	}
	
	const ParseGroup18 = () : JSX.Element =>
	{
		return <Fieldset title="18. Declaração do melhorista" required disabled={!can_edit('default')}>
			<If condition={submited &&
				(defaultForm.getValues("breederStatement") && !defaultForm.getValues("breederStatement").length && !fileField18)}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<If condition={!defaultForm.watch().speciesName}>
				<AlertError
					message={"Selecione 'Nome comum da espécie' primeiro."}
				/>
			</If>
			<If condition={defaultForm.errors.breederStatement}>
				<AlertError message={defaultForm.errors?.breederStatement?.message} />
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Declaração do melhorista<em>*</em>
					</Form.Label>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<If condition={filteredModels?.modeloDeclaracaoMelhorista}>
						<AddButton
							type="button"
							onClick={() => {
								if (filteredModels?.modeloDeclaracaoMelhorista) {
									downloadModel(filteredModels.modeloDeclaracaoMelhorista.codigo, filteredModels.modeloDeclaracaoMelhorista.nome);
								}
							}}
							otherTitle="Baixar modelo"
							isDownload={true}
						/>
					</If>
					<If condition={!filteredModels?.modeloDeclaracaoMelhorista}>
						<NotAvailableModel />
					</If>
					<If condition={loadingDownload}>
						<LoadingInForm />
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.File
						ref={defaultForm.register}
						name="breederStatement"
						label={lblBreederStatement}
						data-browse="upload"
						custom
						onChange={({ target }: any) => {
							if (!target.value) {
								return
							}
							setLblBreederStatement(`${target.files[0].name}`);
						}}
						accept=".pdf"
					/>
					<span className="warning-pdfs">{STRINGS.WARNING_PDFS}</span>
				</Form.Group>
				<If condition={!!fileField18?.id}>
					<FileExistent text={fileField18?.nome} date={fileField18?.dataAtualizacao}
						onDownload={(e: any) => {
							e.preventDefault();
							downloadModelFile(fileField18);
						}}
						onClick={() => {
							setArquivosParaExcluir([...arquivosParaExcluir, fileField18?.id]);
							setFileField18({id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: ''});
						}}
					/>
				</If>
				<br />
			</Form.Row>
		</Fieldset>;
	}
	
	const ParseGroup19 = () : JSX.Element =>
	{
		return <Fieldset title="19. Anexos complementares" disabled={!can_edit('default')}>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={8}>
					<Form.Label>
						Anexo complementar
					</Form.Label>
					<Form.File
						ref={defaultForm.register}
						name="additionalFiles"
						label={labelAdditionalFiles}
						data-browse="upload"
						custom
						onChange={({ target }: any) => {
							if (!target.value) {
								return
							}
							setLabelAdditionalFiles(`${target.files[0].name}`);
							setArrFilesToSubmit(target.files);
						}}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<br />
					<AddButton
						onClick={() => {
							if (defaultForm.getValues('additionalFiles').length) {
								setSelectedFilesAdditionalsList()
								setLabelAdditionalFiles('Escolher arquivo...');
							}
						}}
						type="button"
					/>
				</Form.Group>
			</Form.Row>
			<If condition={selectedFilesAdditionals.length}>
				<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
					<AgGridReact
						rowData={selectedFilesAdditionals}
						defaultColDef={{ flex: 1, sortable: true }}
						suppressRowClickSelection={true}
						onRowSelected={onRowSelectedApplicant}
						rowSelection={'multiple'}
						frameworkComponents={{
							action: actionAdditionalFiles,
						}}
					>
						<AgGridColumn
							headerName="Arquivo"
							valueGetter={({ data }: any) => data.arquivo.nome}
						/>
						<AgGridColumn
							headerName="Ação"
							cellRenderer="action"
						/>
					</AgGridReact>
				</div>
			</If>
		</Fieldset>;
	}
	
	const ParseGroup20 = () : JSX.Element =>
	{
		return <Fieldset title="20. Protocolo">
			<AddButton
				otherTitleAdd="Informar protocolo"
				onClick={() => setModalProtocol(true)}
				type="button"
				disabled={!can_edit('protocol')}
			/>
			<If condition={protocol && dateProtocol}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Número Protocolo
						</Form.Label>
						<Form.Control
							disabled
							value={protocol}
							type="text"
							placeholder="Insira o número do protocolo"
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Data Protocolo
						</Form.Label>
						<br />
						<Controller
							control={defaultForm.control}
							name="protocolDate"
							render={({ onChange, value }) => (
								<DatePicker
									readOnly
									selected={dateProtocol}
									onChange={onChange}
									className="input-custom-to-date"
									locale="pt-BR"
									dateFormat="dd/MM/yyyy"
									placeholderText="Selecionar a data"
								/>
							)}
						/>
					</Form.Group>
				</Form.Row>
			</If>
		</Fieldset>;
	}

	const [fileFieldConclusao, setFileFieldConclusao] = useState<ModelFileRegister>();
	const [arquivoConclusaoName, setArquivoConclusaoName] = useState('Escolher arquivo');
	const [arquivoConclusaoNovoName, setArquivoConclusaoNovoName] = useState('Escolher arquivo');
	
	const ParseGroup21 = () : JSX.Element =>
	{
		return <Fieldset title="21. Registro MAPA">
			<AddButton
				otherTitleAdd="Informar Registro MAPA"
				onClick={() => setModalRegistro(true)}
				type="button"
				disabled={(!isReguladorRegister(roles) && !isAdmin(roles)) || !(status.status === STATUS_REGISTER.AguardandoAprovacaoMAPA)}
			/>
			<If condition={registro && dataRegistro}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={12}>
						<Form.Label>
							Número do Registro
						</Form.Label>
						<Form.Control
							disabled
							value={registro}
							type="text"
						/>
					</Form.Group>
					<Form.Group as={Col} md={12}>
						<Form.Label>
							Data do Registro
						</Form.Label>
						<br />
						<Controller
							control={defaultForm.control}
							name="dateRegistro"
							render={({ onChange, value }) => (
								<DatePicker
									readOnly
									selected={dataRegistro}
									onChange={onChange}
									className="input-custom-to-date"
									locale="pt-BR"
									dateFormat="dd/MM/yyyy"
									placeholderText="Selecionar a data"
								/>
							)}
						/>
					</Form.Group>
					<If condition={!!fileFieldConclusao?.id}>
						<FileExistent
							label="Arquivo de Registro RNC"
							text={fileFieldConclusao?.nome}
							date={fileFieldConclusao?.dataAtualizacao}
							onDownload={(e: any) => {
								e.preventDefault();
								downloadModelFile(fileFieldConclusao);
							}}
						/>
					</If>
					&nbsp; &nbsp;
					<EditButton onClick={(e:any) => {setModalArquivoConclusao(true);}} />
				</Form.Row>
			</If>
			<If condition={modalRegistro}>
				<ConfirmationModal
					confirmationMsg={STRINGS.REASON_MODAL_ACTION}
					onConfirm={() => sendRegistroMAPA()}
					loading={loadingRegistro}
					title={STRINGS.MODAL_REGISTRO_MAPA_TITLE}
					onHide={() => setModalRegistro(false)}
					show={modalRegistro}
					variant="success"
					color="white"
				>
					<If condition={sucessRegistro}>
						<AlertSuccess message={STRINGS.MODAL_REGISTRO_SUCCESS} />
					</If>
					<p>{STRINGS.MODAL_REGISTRO_MESSAGE_CONFIRMATION}</p>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>
								Número do Registro
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="registro"
								type="text"
								placeholder="Insira o número do registro"
								required
								maxLength={100}
							/>
						</Form.Group>
						<Form.Group as={Col} md={12}>
							<Form.Label>
								Data Registro
							</Form.Label>
							<br />
							<Controller
								control={defaultForm.control}
								name="dateRegistro"
								render={({ onChange, value }) => (
									<DatePicker
										required
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col} md={12}>
							<Form.Label>
								Arquivo de Registro RNC
							</Form.Label>
							<br />
							<Form.File
								ref={defaultForm.register}
								type="file"
								name="fileRegistro"
								label={arquivoConclusaoName}
								data-browse="upload"
								custom
								style={{zIndex:0}}
								onChange={({ target }: any) => {
									if (!target.value) return;
									setArquivoConclusaoName(`${target.files[0].name}`);
								}}
							/>
						</Form.Group>
					</Form.Row>
				</ConfirmationModal>
			</If>
			<ConfirmationModal
				show={modalArquivoConclusao}
				title="Substituir arquivo do Registro RNC"
				confirmationMsg="Substituído"
				loading={modalArquivoConclusaoLoading}
				onConfirm={() => sendRegistroMAPAArquivo()}
				onHide={() => setModalArquivoConclusao(false)}
				variant="success"
				color="white"
			>
				<p>Substituir arquivo do Registro RNC</p>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={12}>
						<Form.Label>
							Arquivo de Registro RNC
						</Form.Label>
						<br />
						<Form.File
							ref={defaultForm.register}
							type="file"
							name="fileRegistroUpload"
							label={arquivoConclusaoNovoName}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) return;
								setArquivoConclusaoNovoName(`${target.files[0].name}`);
							}}
						/>
					</Form.Group>
				</Form.Row>
			</ConfirmationModal>
		</Fieldset>;
	}

	const ParseGroup22 = () : JSX.Element =>
	{
		return <Fieldset title="22. Observações Gerais" disabled={!can_edit('preenchimento_observacoes')}>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={12}>
					<Form.Control
						as="textarea"
						ref={defaultForm.register}
						name="generalObservations"
						type="text"
						placeholder="Insira as observações gerais"
						rows={5}
					/>
				</Form.Group>
				<Form.Group as={Col} md={12}>
					<If condition={can_edit('post_validation')}>
						{ParseDataValidacaoDescritores()}
						{ParseDataValidacaoDoencas()}
					</If>
				</Form.Group>
			</Form.Row>
		</Fieldset>;
	}
	
	const ParseDataValidacaoDescritores = () : JSX.Element =>
	{
		if (!aproveDataDescritores.need)
			return <Fragment />;

		let aproveInfo : string = 'Pendente';

		if (aproveDataDescritores.date != null)
			aproveInfo = `Aprovado por ${aproveDataDescritores.user} em ${_generateDate(aproveDataDescritores.date, 'Pt')}`;

		return <Form.Group as={Col} md={12}>
			<Form.Label>
				Aprovação SG:
			</Form.Label>
			<Form.Control
				name="aprovacao_sg_data"
				value={aproveInfo}
				autoComplete="off"
				disabled={true}
				type="text"
			/>
		</Form.Group>;
	}

	const ParseDataValidacaoDoencas = () : JSX.Element =>
	{
		if (!aproveDataDoencas.need)
			return <Fragment />;

		let aproveInfo : string = 'Pendente';

		if (aproveDataDoencas.date != null)
			aproveInfo = `Aprovado por ${aproveDataDoencas.user} em ${_generateDate(aproveDataDoencas.date, 'Pt')}`;

		return <Form.Group as={Col} md={12}>
			<Form.Label>
				Aprovação FITO:
			</Form.Label>
			<Form.Control
				name="aprovacao_fito_data"
				value={aproveInfo}
				autoComplete="off"
				disabled={true}
				type="text"
			/>
		</Form.Group>;
	}

	const ParseModals = () : JSX.Element =>
	{
		return <Fragment>
			<If condition={showModalValidate}>
				<ValidateRequisition
					show={showModalValidate}
					onHide={() => setShowModalValidate(false)}
					colaboratorsDescriptors={colaboratorsDescriptors}
					colaboratorsDiseases={colaboratorsDiseases}
					apiURL={`api/v1/cultivares/CultivaresRegistro/${location.state?.id}/enviarparavalidacao`}
				/>
			</If>
			<If condition={reproveModal}>
				<ReasonModal
					show={reproveModal}
					onHide={() => setReproveModal(false)}
					title={STRINGS.REASON_REPROVE_TITLE}
					actionMsg={STRINGS.REASON_REPROVE_ACTION}
					disabled={reproveTxt.length ? false : true}
					loading={loadingReprove}
					onConfirm={() => {
						reproveRequisition();
					}}
				>
					<If condition={successReprove}>
						<AlertSuccess
							message={STRINGS.REPROVE_SUCCESS}
						/>
					</If>
					<Form.Label>
						{STRINGS.REASON_MODAL_MSG}
					</Form.Label>
					<Form.Control
						value={reproveTxt}
						onChange={({ target }: any) => setReproveTxt(target.value)}
						as="textarea"
						maxLength={200}
					/>
				</ReasonModal>
			</If>

			<If condition={aproveModal}>
				<ConfirmationModal
					show={aproveModal}
					onHide={() => setAproveModal(false)}
					title={STRINGS.APROVE_TITLE}
					confirmationMsg={STRINGS.APROVE_CONFIRMATION}
					onConfirm={() => {
						onSubmit();
					}}
					loading={loadingAprove}
					variant="success"
					color="white"
				>
					<If condition={successAprove}>
						<AlertSuccess
							message={STRINGS.APROVE_SUCCESS}
						/>
					</If>
					<p>{STRINGS.APROVE_MSG}</p>
				</ConfirmationModal>
			</If>

			<If condition={regulatoryModal}>
				<ConfirmationModal
					show={regulatoryModal}
					onHide={() => {
						setRegulatoryModal(false);
						setIsRegulatory(false);
					}}
					title={STRINGS.REGULATORY_TITLE}
					confirmationMsg={STRINGS.REGULATORY_ACTION}
					onConfirm={() => {
						setRegulatoryModal(false);
						onSubmit();
					}}
					loading={loadingRegulatory}
					variant="success"
					color="white"
				>
					<p>{STRINGS.REGULATORY_MESSAGE}</p>
				</ConfirmationModal>
			</If>

			<If condition={modalProtocol}>
				<ConfirmationModal
					confirmationMsg={STRINGS.REASON_MODAL_ACTION}
					onConfirm={() => sendProtocol()}
					loading={loadingProtocol}
					title={STRINGS.MODAL_PROTOCOL_TITLE}
					onHide={() => setModalProtocol(false)}
					show={modalProtocol}
					variant="success"
					disabled={false}
					color="white"
				>
					<If condition={sucessProtocol}>
						<AlertSuccess message={STRINGS.MODAL_PROTOCOL_SUCCESS} />
					</If>
					<p>{STRINGS.MODAL_PROTOCOL_MESSAGE_CONFIRMATION}</p>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Número Protocolo
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="protocolNumber"
								type="text"
								placeholder="Insira o número do protocolo"
								required
								maxLength={100}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>
								Data Protocolo
							</Form.Label>
							<br />
							<Controller
								control={defaultForm.control}
								name="protocolDate"
								render={({ onChange, value }) => (
									<DatePicker
										required
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
				</ConfirmationModal>
			</If>
		</Fragment>;
	}

	return <div className="container container-fluid">
		{ParseMessages()}
		<If condition={!loadingRequestData}>
			<h3>Edição de registro rnc</h3>
			<p className="text-helper">Campos com <em>*</em> são de preenchimento obrigatório</p>
			<Form onSubmit={defaultForm.handleSubmit(onSubmit)}>
				{ParseGroup00()}
				{ParseGroup01()}
				{ParseGroup02()}
				{ParseGroup03()}
				{ParseGroup04()}
				{ParseGroup05()}
				{ParseGroup06()}
				{ParseGroup07()}
				{ParseGroup08()}
				{ParseGroup09()}
				{ParseGroup10()}
				{ParseGroup11()}
				{ParseGroup12()}
				{ParseGroup13()}
				{ParseGroup14()}
				{ParseGroup15()}
				{ParseGroup16()}
				{ParseGroup17()}
				{ParseGroup18()}
				{ParseGroup19()}
				{ParseGroup20()}
				{ParseGroup21()}
				{ParseGroup22()}
				{parseButton()}
			</Form>
		</If>
		{ParseModals()}
	</div>;
}