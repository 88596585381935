export const STRINGS_ADMIN = {

    SEARCH_USUARIOS: "Consulta de Usuário Cadastrados",
    USER_DISABLE_TITLE: "Deseja desativar usuário?",
    APROVE_BODY_MSG: "Essa ação não poderá ser desfeita.",

    EDITION_USUARIO: "Edição de Usuário",

    EDITION_REGISTER_CONFIRMATION_CANCEL: "Todos os dados informados serão perdidos. Tem certeza que deseja cancelar?",
    EDITION_TITLE_CONFIRMATION: "Tem certeza?",
    CONFIRM_LEFT_EDITION: "Sim, voltar",
    CANCEL_LEFT_EDITION: "Não, permanecer",


}