export const PERMISSIONS = {
    Admin: "shipment.admin",
    EditarFluxo: "shipment.editar_fluxo",
    VisualizarTodas: "shipment.visualizar.todas",
    CriarRequisicao: "shipment.criar_requisicao",
    EditarRequisicao: "shipment.editar_requisicao",
    VisualizarMinhas: "shipment.visualizar.minhas",
    EditarTabelasAuxiliar: "shipment.editar_tabelas_auxiliar",
}

