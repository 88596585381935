import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import Button from '../../../../components/Button';
import If from '../../../../components/If';
import AlertError from '../../../../components/AlertError';
import AlertSuccess from '../../../../components/AlertSuccess';
import { STRINGS } from '../../../../util/strings';
import { InstitutionDataForm } from '../../../../models/models';
import { estados } from '../../../../util/states.json';
import { countries } from '../../../../util/countries';
import api from '../../../../services/api';
import '../../styles_add.css';

export default function InstitutionData ()
{
	const history = useHistory();
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);
	const [country, setCountry] = useState<string>('');

	const institutionDataSchema = yup.object().shape({
		name: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		cnpj: country === "Brasil" ? yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
			.min(14, 'Mínimo de 14 caracteres.') : yup.string(),
		address: yup
			.string(),
		zipCode: yup
			.string(),
		city: yup
			.string(),
		state: yup
			.string(),
		country: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		phone: yup
			.string(),
		email: yup
			.string()
			.email(STRINGS.FORM_EMAIL_INVALID)
			.required(STRINGS.FORM_FIELD_REQUIRED),
		active: yup
			.bool()
	})

	const initialValues: InstitutionDataForm = {
		name: '',
		cnpj: '',
		address: '',
		zipCode: '',
		city: '',
		state: '',
		phone: '',
		email: '',
		country: '',
		active: true
	}

	const { register, handleSubmit, errors, reset, control, setValue, trigger } = useForm({
		defaultValues: initialValues,
		mode: 'onTouched',
		resolver: yupResolver(institutionDataSchema)
	})

	const verifyErrorsOnForm = (): boolean => {
		if (errors.name ||
			errors.cnpj ||
			errors.email) {
			return true;
		}
		return false;
	}

	const onSubmit = async (institutionData: InstitutionDataForm) => {
		setLoading(true);
		setError(null);
		try {
			const { status } = await api.post('api/v1/Instituicao', {
				nome: institutionData.name,
				cnpj: institutionData.cnpj,
				endereco: institutionData.address,
				cep: institutionData.zipCode,
				municipio: institutionData.city,
				uf: institutionData.state,
				pais: institutionData.country,
				telefone: institutionData.phone,
				email: institutionData.email,
				ativo: institutionData.active
			});

			if (status === 201) {
				setSuccessfulResponse(true);
				reset();
				setTimeout(() => {
					history.goBack();
				}, 3000)
			}
		} catch (error:any) {
			setError(error.response.data);
		}
		setLoading(false);
	}

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			<AlertError
				message={STRINGS.ALERT_WARNING_FIELDS}
			/>
		</If>
		<If condition={successfulResponse && !verifyErrorsOnForm() && !error}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS}
			/>
		</If>
		<If condition={!successfulResponse && error}>
			{error !== null && error.message.length > 0 ? error.message.map((error: string) => <AlertError message={error} />) : null}
		</If>
		<h3>Cadastro de instituição responsável pelo ensaio</h3>
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Nome da instituição<em>*</em>
					</Form.Label>
					<Form.Control
						ref={register}
						type="text"
						name="name"
						placeholder="Insira o nome da instituição"
						isInvalid={errors.name !== undefined}
						autoComplete="off"
						maxLength={200}
					/>
					<If condition={errors.name}>
						<Form.Control.Feedback type="invalid">
							{errors.name?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>
						CNPJ da instituição
						<If condition={country === "Brasil"}>
							<em>*</em>
						</If>
					</Form.Label>
					<Controller
						isInvalid={errors.cnpj !== undefined}
						control={control}
						name="cnpj"
						render={(props: any) => {
							return (
								<NumberFormat
									value={props.value}
									onValueChange={(target) => {
										props.onChange();
										setValue('cnpj', target.value);
										trigger('cnpj');
									}}
									onBlur={props.onBlur}
									format="##.###.###/####-##"
									className="form-control"
								/>
							)
						}}
					/>
					<If condition={errors.cnpj}>
						<div className="error-field">
							{errors.cnpj?.message}
						</div>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.Label>
						Email da instituição<em>*</em>
					</Form.Label>
					<Form.Control
						ref={register}
						type="text"
						name="email"
						placeholder="Insira o email da instituição"
						isInvalid={errors.email !== undefined}
						autoComplete="off"
						maxLength={200}
					/>
					<If condition={errors.email}>
						<Form.Control.Feedback type="invalid">
							{errors.email?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Telefone</Form.Label>
					<Form.Control
						ref={register}
						as={NumberFormat}
						getInputRef={register}
						type="text"
						placeholder="Insira o telefone da instituição"
						name="phone"
						format="(##) #####-####"
						autoComplete="off"
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>
						CEP
					</Form.Label>
					<Form.Control
						ref={register}
						as={NumberFormat}
						getInputRef={register}
						type="text"
						name="zipCode"
						placeholder="Insira o CEP da instituição"
						isInvalid={errors.zipCode !== undefined}
						format="#####-###"
						autoComplete="off"
					/>
					<If condition={errors.zipCode}>
						<Form.Control.Feedback type="invalid">
							{errors.zipCode?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>Endereço</Form.Label>
					<Form.Control
						ref={register}
						type="text"
						placeholder="Insira o endereço da instituição"
						name="address"
						autoComplete="off"
						maxLength={255}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Município</Form.Label>
					<Form.Control
						ref={register}
						type="text"
						placeholder="Insira o município da instituição"
						name="city"
						autoComplete="off"
						maxLength={100}
					/>
				</Form.Group>
				<Form.Group as={Col} md={1}>
					<Form.Label>UF</Form.Label>
					<Form.Control
						as="select"
						name="state"
						ref={register}
					>
						<Form.Control
							as="option"
							label=''
						/>
						{estados.map((state) => (
							<Form.Control
								key={state.id}
								as="option"
								label={state.nome}
								value={state.nome}
							/>
						))}
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>País<em>*</em></Form.Label>
					<Form.Control
						as="select"
						name="country"
						ref={register}
						onChange={({ target }: any) => setCountry(target.value)}
					>
						<Form.Control
							as="option"
							label=''
						/>
						{countries.map((country) => (
							<Form.Control
								key={country}
								as="option"
								label={country}
								value={country}
							/>
						))}
					</Form.Control>
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col} md={1} className="checkbox-lg">
					<Form.Check
						type="checkbox"
						ref={register}
						name="active"
						label="Ativo"
					/>
				</Form.Group>
			</Form.Row>
			<div className="button-position">
				<Button
					title="Cancelar"
					type="button"
					contained={false}
					onClick={() => history.goBack()}
				/>
				<Button
					title="Cadastrar"
					type="submit"
					contained={true}
					isLoading={loading}
				/>
			</div>
		</Form>
	</div>;
}