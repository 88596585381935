import React from 'react';
import LoadingInForm from './LoadingInForm';

interface Props {
    title: string;
    variant: string;
    onClick: any;
    fontColor?: string;
    loading?: boolean;
    disabled?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
}

function FlowGenericButton({ title, variant, onClick, fontColor, loading, type, disabled }: Props) {
    return (
        <button type={type ? type : "button"} className={`btn-default ${variant}`} onClick={onClick} disabled={disabled}>
            <span style={{ color: fontColor ? fontColor : 'white' }}>
                {loading ? <LoadingInForm color={fontColor ? fontColor : 'white'} /> : title}
            </span>
        </button>
    );
}

export default FlowGenericButton;