import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './styles.css';
import Button from '../../components/Button';
import InactiveButton from '../../components/InactiveButton';
import EditButton from '../../components/EditButton';

import { operatingUnitysResults } from '../../tempdb.json';

function OperatingUnitys() {
    const history = useHistory();
    const [rowSelected, setRowSelected] = useState<boolean>(false);

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            name: '',
            active: false
        }
    })

    const onSubmit = (data: any) => {
        // console.log(data);
    }

    const onRowSelected = (event: any) => {
        const { api } = event
        // console.log(api.getSelectedNodes())

        setRowSelected(true)

        if (!api.getSelectedNodes().length) {
            setRowSelected(false)
        }
    }

    const EditBtn = (props: any) => (
        <EditButton
            onClick={() => {}}
        />
    )

    return (
        <div className="container-custom-search">
            <div className="top-content">
                <h3>Consulta de unidades operativas/lotes de apoio</h3>
            </div>
            <div>
                <Button
                    onClick={() =>
                        history.push('/cadastrar/unidades-operativas')
                    }
                    contained
                    title="Adicionar"
                    type="button"
                />
            </div>
            <br />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Form.Group as={Col} md={4}>
                        <Form.Label>
                            Nome da unidade operativa
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            name="name"
                            autoComplete="off"
                            maxLength={100}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={.5}>
                        <Form.Check
                            ref={register}
                            name="active"
                            type="checkbox"
                            label="Inativos"
                        />
                    </Form.Group>
                    <span className="filter-btn">
                        <Button
                            title="Buscar"
                            type="submit"
                            contained
                        />
                    </span>
                    <span className="filter-btn">
                        <InactiveButton
                            disable={!rowSelected}
                            onClick={() => alert()}
                        />
                    </span>
                </Form.Row>
            </Form>
            <div className="search-content">
                <div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
                    <AgGridReact
                        rowData={operatingUnitysResults.rows}
                        suppressRowClickSelection={true}
                        rowSelection={'multiple'}
                        onRowSelected={onRowSelected}
                        defaultColDef={{ flex: 1, sortable: true }}
                        pagination
                        paginationPageSize={10}
                        frameworkComponents={{
                            editBtn: EditBtn
                        }}
                    >
                        <AgGridColumn
                            headerName='Selecionar'
                            headerCheckboxSelection={true}
                            checkboxSelection={true}
                            pinned="left"
                        />
                        <AgGridColumn
                            headerName='Nome da unidade operativa'
                            field='nome'
                        />
                        <AgGridColumn
                            headerName='Tipo de operação'
                            field='tipo'
                        />
                        <AgGridColumn
                            headerName='Editar'
                            cellRenderer={'editBtn'}
                        />
                    </AgGridReact>
                </div>
            </div>
        </div>
    )
}

export default OperatingUnitys;