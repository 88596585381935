import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../../components/Button';
import If from '../../../../components/If';
import AlertError from '../../../../components/AlertError';
import AlertSuccess from '../../../../components/AlertSuccess';
import ResponseError from '../../../../components/ResponseError';
import Loading from '../../../../components/Loading';
import { STRINGS } from '../../../../util/strings';
import { EventsForm, EventsResult } from '../../../../models/models';
import api from '../../../../services/api';
import '../../styles_edit.css';

interface stateType {
	id: number;
}

export default function Event ()
{
	const history = useHistory();
	const location = useLocation<stateType>();
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);

	const eventSchema = yup.object().shape({
		name: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		active: yup
			.boolean()
	});

	const { handleSubmit, register, errors, reset } = useForm({
		mode: 'onTouched',
		resolver: yupResolver(eventSchema)
	});

	useEffect(() => {
		const getEventById = async () => {
			try {
				const { data } = await api.get<EventsResult>(`api/v1/cultivares/Evento/${location.state.id}`);

				reset({
					name: data.nomeEvento,
					active: data.ativo
				})
			} catch (error:any) {
				setError(error.response.data);
			}
			setLoading(false);
		}

		getEventById();
	}, [location.state.id, reset]);

	const verifyErrorsOnForm = (): boolean => {
		if (errors.name) {
			return true;
		}
		return false;
	}

	const onSubmit = async (eventData: EventsForm) => {
		setLoadingSubmit(true);
		setError(null);
		try {
			const { status } = await api.put('api/v1/cultivares/Evento', {
				id: location.state.id,
				nomeEvento: eventData.name,
				ativo: eventData.active
			});

			if (status === 200) {
				setSuccessfulResponse(true);
				reset({
					name: ''
				});
				setTimeout(() => {
					history.goBack();
				}, 3000);
			}
		} catch (error:any) {
			setError(error.response.data);
		}
		setLoadingSubmit(false);
	}

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			<AlertError
				message={STRINGS.ALERT_WARNING_FIELDS}
			/>
		</If>
		<If condition={successfulResponse && !verifyErrorsOnForm()}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS_EDIT}
			/>
		</If>
		<If condition={!successfulResponse && error !== null}>
			{error !== null && error.message.length > 0 ? error.message.map((errorE: string) => <AlertError message={errorE} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<h3>Edição de evento</h3>
		<If condition={loading}>
			<span className="loading-page">
				<Loading />
			</span>
		</If>
		<If condition={!loading && !error}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Nome do evento<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="name"
							as="textarea"
							placeholder="Insira o nome do evento"
							isInvalid={errors.name !== undefined}
							autoComplete="off"
							maxLength={500}
						/>
						<If condition={errors.name}>
							<Form.Control.Feedback type="invalid">
								{errors.name?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					&nbsp;&nbsp;
					<Form.Group className="checkbox-forms">
						<Form.Check
							type="checkbox"
							ref={register}
							name="active"
							label="Ativo?"
						/>
					</Form.Group>
				</Form.Row>
				<div className="button-position">
					<Button
						title="Cancelar"
						type="button"
						contained={false}
						onClick={() => history.goBack()}
					/>
					<Button
						title="Salvar"
						type="submit"
						contained={true}
						isLoading={loadingSubmit}
					/>
				</div>
			</Form>
		</If>
	</div>;
}