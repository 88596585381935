//Basic REACT Components
import React, { useState, useEffect, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import SimpleInput from 'react-simple-input';
import moment from 'moment';

//Custom Componentes
import Button from "../../../components/Button";
import EditButton from '../../../components/EditButton';
import ViewButton from '../../../components/ViewButton';
import CancelButton from '../../../components/CancelRequisitionButton';

import api, { apiFileDownload } from '../../../services/api';
import If from "../../../components/If";
import SearchEmpty from '../../../components/SearchEmpty';
import Loading from '../../../components/Loading';

//Custom Services
import { DecodedToken } from '../../../models/user';
import jwtDecode from 'jwt-decode';

//Page Specifics
import { STRINGS_SHIPMENT } from "../../../util/shipment/string";
import { CultureResult, RequsitanteDto, Enum, Shipment } from '../../../models/modelShipment';

import AlertSuccess from '../../../components/AlertSuccess';
import ReasonModal from '../../../components/ReasonModal';

import { isAdmin } from '../../../users/shipment/isAdmin';

import { 
	isCheck as isShipmentCheckRoles
} from '../../../users/shipment/isCheck';
import { 
	PERMISSIONS as SHIPMENT_PERMISSIONS
} from '../../../users/usersPermissions/usersPermissionsShipment';

import "./styles.scss";
import { STATUS_SHIPMENT } from '../../../util/shipment/status';

export default function List ()
{
	//const apiUrlShipment = "/api/v1/Shipment/salvar";
	const apiUrlShipment = "api/v1/Shipment"
	const apiUrlShipmentFiltro = "api/v1/Shipment/obterporfiltro?"
	const apiUrlShipment_GetCulturas = `${apiUrlShipment}/cultura_obter_todos`
	const apiUrlShipment_GetMaterial = `${apiUrlShipment}/material_obter_todos`
	const apiUrlShipment_GetStatus = `${apiUrlShipment}/status_obter_todos`
	const apiUrlShipment_GetEstadoCondicao = `${apiUrlShipment}/status_estados_condicoes`
	const apiUrlShipment_GetStatusRegulatorioArrays = `${apiUrlShipment}/status_regulatorio_arrays`
	const apiUrlShipment_GetRequisitantes = `${apiUrlShipment}/lista_requesitantes`
	//'api/v1/shipment/Cultura/obterporfiltro?Ativo=true');

	//Base da página
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const token = String(localStorage.getItem('token'));
	const { roles, unique_name }: DecodedToken = jwtDecode(token);

	//Interações da Página
	const [error, setError] = useState<any>(null);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

	
	//Campos do filtro
	const [listShipment, setListShipment] = useState<Shipment[]>([]);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [enumMaterial, setEnumMaterial] = useState<CultureResult[]>([]);
	const [enumEstado, setEnumEstado] = useState<Enum[]>([]);
	const [enumCodicao, setEnumCodicao] = useState<Enum[]>([]);
	const [status, setStatus] = useState<Enum[]>([]);
	const [loadingAuthorizationById, setLoadingAuthorizationById] = useState<boolean>(false);
	const [shipmentIten, setShipmentItem] = useState<Shipment>();

	const [isCancel, setIsCancel] = useState<boolean>(false);
	const [motivoCancelamento, setMotivoCancelamento] = useState('');
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	const [successCancel, setSuccessCancel] = useState<boolean>(false);
	const [requisicao, setRequisicao] = useState<Shipment>();


	const { register, handleSubmit, watch, control } = useForm({
		defaultValues: {
			codigo: '',
			solicitante: unique_name, //(isRequesterReader(roles) || isRequesterWriter(roles)) ? unique_name : '',
			status: null,
			pais_exportador: null,
			pais_importador: null,
			pais_origem: null,
			dataReferenciaCriada: null,
		}
	})	

	const statusCheck = (stt: any) =>{
		let st = status.find(e=> e.key === stt);
		return !st ? "Não Encontrado" : st.value;
	};

	const isCreatRequisicao = ()=> {
		return isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.CriarRequisicao)
	}
	const isEditRequisicao = ()=> {
		return isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.EditarRequisicao) ||
		isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.EditarFluxo) ||
		isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.VisualizarTodas)
	}

	const getData = async (q:any, _finishHandler:any) => {
			
		try
		{
			//const locais = await api.get<UnityStationResult[]>('api/v1/shipment/UnidadeEstacao/obterporfiltro?Ativo=true');
			const cultures = await api.get<CultureResult[]>(apiUrlShipment_GetCulturas)

			//const locations = await api.get<UnityStationResult[]>('api/v1/shipment/UnidadeEstacao/obterporfiltro?Ativo=true');
			const material = await api.get<CultureResult[]>(apiUrlShipment_GetMaterial);
			const estadocondicao = await api.get<Enum[][]>(apiUrlShipment_GetEstadoCondicao);
			const requistanteslst = await api.get<RequsitanteDto[]>(apiUrlShipment_GetRequisitantes);

			//setLocais(locais.data);
			if (cultures.status === 200)
				setCultures(cultures.data);
			
			if (material.status === 200)
				setEnumMaterial(material.data);

			if (estadocondicao.status === 200)
			{
				setEnumEstado(estadocondicao.data[0])
				setEnumCodicao(estadocondicao.data[1])
			}			
			
			_finishHandler(q, estadocondicao.data, requistanteslst.data);
		}
		catch (error:any)
		{

		}
		
		setLoading(false);
	};

	useEffect(() => {		
		

		const getShipments = async (q: any, _estadocondicao: any, _requisitantesLst: any) => {
		
			setError(false);
			
			try
			{
				let requester = ''; //(isRequesterReader(roles) || isRequesterWriter(roles)) ? unique_name : '';
				const pesquisa = await api.get<Shipment[]>(`${apiUrlShipmentFiltro}Requisitante=${requester}`);
	
				if (pesquisa.status !== 200)
					throw new Error('Erro ao buscar os dados da lista');
	
				if (pesquisa.data.length)
				{
					pesquisa.data = pesquisa.data.map(item => {
						let requE = (_requisitantesLst as RequsitanteDto[]).find(e=> e.id === item.requisitantesExportadorId);
						let requI = (_requisitantesLst as RequsitanteDto[]).find(e=> e.id === item.requisitantesImportadorId);
						let estd = (_estadocondicao[0] as Enum[]).find(e=> Number(e.key) === item.estado);
						let cond = (_estadocondicao[1] as Enum[]).find(e=> Number(e.key) === item.condicoes);

						return {
							...item,
							culturaId		: item.cultura?.nome,
							materialId		: item.material?.nome,
							estado			: estd?.value,
							condicoes		: cond?.value,
							pais_exportador : item.pais_exportador.split("/")[1],
							pais_importador : item.pais_importador.split("/")[1],
							pais_origem     : item.pais_origem === null ? 'Não definido' : item.pais_origem.split("/")[1],
							requisitantesExportadorId:  `${requE?.nome } (${requE?.email})`,
							requisitantesImportadorId: 	 `${requI?.nome } (${requI?.email})`,
						}
					});

					setListShipment(pesquisa.data); 
					setEmptySearch(false);
				}
				else
				{
					setEmptySearch(true);
				}
			}
			catch (error:any)
			{
				setError(true);
			}
		};

		getData(null, (q:any, estadocondicao: any, requistanteslst: any)=>{
			getShipments(q, estadocondicao, requistanteslst);
		});
		
	
	}, []);

	const search = async (queries?: any) => {

		setError(false);
		setLoadingSearch(true);

		try {

			const getShipmentsSearch =  async (local_queries: any, _estadocondicao: any, _requisitantesLst: any) => {

					let params = new URLSearchParams();

					// params.append("Codigo", codigo);
					//params.append("solicitante", solicitante);
					params.append("codigo", local_queries?.codigo ?? '');
					//params.append("status", queries?.status ? queries?.status.value : '');

					let apiUrlResult = apiUrlShipmentFiltro + params.toString()
					const pesquisa = await api.get<Shipment[]>(
						apiUrlResult 
					);

					if (pesquisa.data.length)
					{
						pesquisa.data = pesquisa.data.map(item => {
							let requE = (_requisitantesLst as RequsitanteDto[]).find(e=> e.id === item.requisitantesExportadorId);
							let requI = (_requisitantesLst as RequsitanteDto[]).find(e=> e.id === item.requisitantesImportadorId);
							let estd = (_estadocondicao[0] as Enum[]).find(e=> Number(e.key) === item.estado);
							let cond = (_estadocondicao[1] as Enum[]).find(e=> Number(e.key) === item.condicoes);
	
							return {
								...item,
								culturaId		: item.cultura?.nome,
								materialId		: item.material?.nome,
								estado			: estd?.value,
								condicoes		: cond?.value,
								pais_exportador : item.pais_exportador.split("/")[1],
								pais_importador : item.pais_importador.split("/")[1],
								pais_origem     : item.pais_origem === null ? 'XX' : item.pais_origem.split("/")[1],
 								requisitantesExportadorId:  `${requE?.nome } (${requE?.email})`,
								requisitantesImportadorId: 	 `${requI?.nome } (${requI?.email})`,
							}
						});

						setListShipment(pesquisa.data); 	
						setEmptySearch(false);
					}
					else
					{
						setEmptySearch(true);
					}
				};

				getData(queries, (q:any, estadocondicao: any, requistanteslst: any)=>{
					getShipmentsSearch(q, estadocondicao, requistanteslst);
				});
				
		}
		catch (error:any)
		{
			setError(true);
		}

		setLoadingSearch(false);
	}	

	const acoes = ({ data }: any) => {

		const possuiPerfilAdmin = isAdmin(roles);
		let ehRegulatorio = false;

		if (possuiPerfilAdmin)
		{
			ehRegulatorio = true;
		} 
		let canCancel = ehRegulatorio && data.dataCancelamento === null;
		let isCancelled = data.dataCancelamento !== null;

		return (
		<Fragment>
			<If condition={!isEditRequisicao() || isCancelled}>
				<ViewButton onClick={() => {
					history.push({
						pathname: '/shipment/visualizar',
						state: {
							id: data.id
						}
					})
				}} />
			</If>

			<If condition={isEditRequisicao() && !isCancelled}>
				<EditButton onClick={() => {
					history.push({
						pathname: '/shipment/editar',
						state: {
							id: data.id
						}
					})
				}} />
			</If>

			<If condition={!isEditRequisicao() || canCancel}>
				&nbsp;&nbsp;
				<CancelButton onClick={() => {

					if (!canCancel) return;

					setIsCancel(true);
					getRequestById(data.id);
					setSuccessCancel(false);
					setMotivoCancelamento('');

				}} />
			</If>			
		</Fragment>);
	}

	const getRequestById = async (id: number) => {
		//setLoadingAuthorizationById(true);
		try {
			const { data } = await api.get(`api/v1/shipment/${id}`);

			setRequisicao(data);
		} catch (error: any) {
			throw new Error(error);
		}
		//setLoadingAuthorizationById(false);
	}

	const cancelRequisition = async (data: any) => {
		setLoadingCancel(true);

		try {
			const { status } = await api.post('api/v1/Shipment/salvar', {
				id: data.id,

				codigo: data.codigo,
				status: data.status,
				solicitante: data.solicitante,
				pais_exportador: data.pais_exportador,
				pais_importador: data.pais_importador,
				pais_origem: data.pais_origem,
				culturaId: data.culturaId,
				materialId: data.materialId,

				status_regulatorio: data.status_regulatorio,
				status_regulatoriogm: data.status_regulatoriogm,
				status_regulatoriogmregulado: data.status_regulatoriogmregulado,

				estado: data.estado,
				condicoes: data.condicoes,
				condicoes_observacoes: data.condicoes_observacoes,

				centroCusto: data.centroCusto,
				numeroProjeto: data.numeroProjeto,

				requisitantesExportadorId: data.requisitantesExportadorId,
				alerta_requisitantes_exportador: data.alerta_requisitantes_exportador,

				requisitantesImportadorId: data.requisitantesImportadorId,
				alerta_requisitantes_importador: data.alerta_requisitantes_importador,

				dataAguardandoDocumentos: data.dataAguardandoDocumentos ? moment(data.dataAguardandoDocumentos).toDate() : null,
				dataAguardandoGreenLight: data.dataAguardandoGreenLight ? moment(data.dataAguardandoGreenLight).toDate() : null,
				dataColetaEntrega: data.dataColetaEntrega ? moment(data.dataColetaEntrega).toDate() : null,
				dataDocumentosEnviados: data.dataDocumentosEnviados ? moment(data.dataDocumentosEnviados).toDate() : null,
				dataEmLiberacaoOrigem: data.dataEmLiberacaoOrigem ? moment(data.dataEmLiberacaoOrigem).toDate() : null,
				dataEmLiberadaDestino: data.dataEmLiberadaDestino ? moment(data.dataEmLiberadaDestino).toDate() : null,
				dataEmQuarentena: data.dataEmQuarentena ? moment(data.dataEmQuarentena).toDate() : null,
				dataEmTransitoDestinoFinal: data.dataEmTransitoDestinoFinal ? moment(data.dataEmTransitoDestinoFinal).toDate() : null,
				dataEmTransitoInternacional: data.dataEmTransitoInternacional ? moment(data.dataEmTransitoInternacional).toDate() : null,
				dataEmbarqueAprovado: data.dataEmbarqueAprovado ? moment(data.dataEmbarqueAprovado).toDate() : null,
				dataEntregue: data.dataEntregue ? moment(data.dataEntregue).toDate() : null,
				dataReferenciaCriada: data.dataReferenciaCriada ? moment(data.dataReferenciaCriada).toDate() : null,

				responsavelCancelamento: unique_name,
				dataCancelamento: moment().toDate(),
				motivoCancelamento: motivoCancelamento
			});
			if (status === 200) {
				setSuccessCancel(true);

				setTimeout(() => {
					setIsCancel(false);
					search();
				}, 3000);
			}
		} catch (error: any) {
			//setCancelError(error.response.data);
		}
		setLoadingCancel(false);
	}


	return (
		<div className="container-custom-search">
			<div className="search-content-filters ">
			
				<div className="top-content">
					<h3>{STRINGS_SHIPMENT.SEARCH_SHIPMENT}</h3>
				</div>
				<div className="row-buttons">
					<If condition={isCreatRequisicao()}>
						<Button
						onClick={() => history.push("/shipment/nova")}
						contained
						title="Nova Requisição/New Requisition"
						type="button"
						/>
					</If>				
				</div>
				<br />
				<Form onSubmit={handleSubmit(search)} autoComplete="off">
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>Código/Code</Form.Label>
							<Controller
								control={control}
								name="codigo"
								disabled={false}
								maxLength={12}
								render={({ onChange, value }) => (
									<SimpleInput
										defaultValue={value}
										clearButton
										className="form-control"
										onChange={onChange}
									/>
								)}
							/>
						</Form.Group>
										
						<span className="filter-btn">
							<Button
								title="Buscar/Search"
								type="submit"
								contained
								isLoading={loadingSearch}
							/>
						</span>
					</Form.Row>
				</Form>
				<If condition={emptySearch}>
					<div className="div-empty">
						<SearchEmpty text={'Código não encontrado/Code not found.'} />
					</div>
				</If>
				<If condition={loading}>
					<div className="loading-container">
						<Loading />
					</div>
				</If>

			</div>
			
			<If condition={!loading && !error && !emptySearch && listShipment.length}>
				<div className="search-content">
					<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
						<AgGridReact
							rowData={listShipment}
							defaultColDef={{ flex: 1, sortable: true, resizable: true }}
							pagination
							paginationPageSize={10}
							gridOptions={{ headerHeight: 80 }}
							frameworkComponents={{
								actions: acoes,
							}}
						>
							<AgGridColumn
								headerName='Código/Code'
								field='codigo'
								maxWidth={130}
							/>
							<AgGridColumn
								headerName='Exportador/Sender'
								headerClass="myfunc-default-header"
								field='pais_exportador'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Importador/Receiver'
								headerClass="myfunc-default-header"
								field='pais_importador'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Origem/Origin'
								headerClass="myfunc-default-header"
								field='pais_origem'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>

							<AgGridColumn
								headerName='Cultura/Crop'
								headerClass="myfunc-default-header"
								field='culturaId'
								
							/>
							<AgGridColumn
								headerName='Material/Material'
								headerClass="myfunc-default-header"
								field='materialId'
								
							/>
							<AgGridColumn
								headerName='Estado/State'
								headerClass="myfunc-default-header"
								field='estado'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Condições/Cargo Temp.'
								headerClass="myfunc-default-header"
								field='condicoes'
							/>
							<AgGridColumn
								headerName='Req. Exportador/Sender'
								headerClass="myfunc-default-header"
								field='requisitantesExportadorId'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Req. Importador/Receiver'
								headerClass="myfunc-default-header"
								field='requisitantesImportadorId'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Ações/Actions'
								field='Ações'
								cellRenderer='actions'
							/>
						</AgGridReact>
					</div>
				</div>
			</If>

			<If condition={isCancel}>
				<ReasonModal
					show={isCancel}
					onHide={() => setIsCancel(false)}
					title={STRINGS_SHIPMENT.CANCEL_MODAL_TITLE}
					actionMsg="Confirmar"
					disabled={motivoCancelamento.length ? false : true}
					onConfirm={() => {
						cancelRequisition(requisicao);
					}}
					loading={loadingCancel}
					hideFooter={successCancel}
				>
					<If condition={loadingAuthorizationById}>
						<div className="loading-container">
							<Loading />
						</div>
					</If>
					<If condition={successCancel}>
						<AlertSuccess
							message={STRINGS_SHIPMENT.SHIPMENT_CANCEL_SUCCESS}
						/>
					</If>
					<If condition={!loadingAuthorizationById}>
						<Form.Label>
							<strong>{STRINGS_SHIPMENT.SHIPMENT_CANCEL_BODY_MSG}</strong>
						</Form.Label>
						<Form.Control
							onChange={({ target }: any) =>
								setMotivoCancelamento(target.value)
							}
							value={motivoCancelamento}
							as="textarea"
							rows={5}
						/>
					</If>
				</ReasonModal>
			</If>

		</div>
	);
}