import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import TextField from '../../../components/form/TextField';
import ListingView, { ListCol } from "../../../components/page/ListingView";
import { LpmaConfigucaoes } from '../../../models/modelsLPMA';
import { STRINGS_LPMA } from "../../../util/lpma/string";
import LpmaService from '../services';

export default function List (){
	const LpmaServiceApi = new LpmaService();
	const history = useHistory();


    const defaultForm = useForm({
		defaultValues: {
            id	: 0,
			parametro	: '',  
			conteudo	: '', 
			descricao	: '',
        } as LpmaConfigucaoes,
	})	
    
	
	return <ListingView<LpmaConfigucaoes>
	title	=	{STRINGS_LPMA.SEARCH_CONFIG}	
	form={defaultForm}
	onEditEvent = {(data:any )=>{  history.push({
		pathname: `/lpma/configuracoes/editar/${data.id}`,						
		})
	 }}
	 filters={[
		<TextField<LpmaConfigucaoes> form={defaultForm} layoutColumns={4} name="parametro" 
		label="Código do configuracoes/Descrição" required={false}  isClearable={true}
		/>,
		<TextField<LpmaConfigucaoes> form={defaultForm} layoutColumns={6} name="conteudo" 
		label="Conteúdo" required={false}  isClearable={true}
		/>
	 ]}

	onFilterSearch = {async (queries?: any)=>{
		let filtro = {
			...defaultForm.getValues(),
			...queries
		}
		return await LpmaServiceApi.getConfigs(filtro);
	}}
	actionsMaxWidth={75}
	>		

	<ListCol header='Parametro de Configuração'
		field='parametro'	sort="asc"	maxWidth={300}
	/>		

	<ListCol header='Descrição'	field='descricao'
		maxWidth={400}		cellStyle={{justifyContent: 'left'}}
	/>		

	<ListCol header='Conteúdo' field='conteudo'
		cellStyle={{justifyContent: 'left'}}		
	/>		

	</ListingView>
}