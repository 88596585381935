import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import TextField from '../../../components/form/TextField';
import ListingView, { ListCol } from "../../../components/page/ListingView";
import { LpmaLocal } from '../../../models/modelsLPMA';
import { STRINGS_LPMA } from "../../../util/lpma/string";
import LpmaService from '../services';

export default function List (){
	const LpmaServiceApi = new LpmaService();
	const history = useHistory();


    const defaultForm = useForm({
		defaultValues: {
            id: 0,
            cnpj: '', 
			cEP: '',endereço: '', localExecucao: '', email: '',
			municipio: '', nomeDaFazenda: '', nomePessoaResponsavel: '', nomeProprietario: '',
			pais: '', telefone:'', uF: '', taX_ID_VAT: '', parecerTecnico: '', ativo: true,
        } as LpmaLocal,
	})	

    
	
	return <ListingView<LpmaLocal>
	title	=	{STRINGS_LPMA.SEARCH_LOCAL}	
	form={defaultForm}
	onEditEvent = {(data:any )=>{  history.push({
		pathname: `/lpma/local/editar/${data.id}`,						
		})
	 }}

	 ShowButtonAddNew={true}
	 onButtonAddEvent = {()=>{ history.push("/lpma/local/novo") }}
	 ButtonAddText="Novo Local"

	 filters={[
		<TextField<LpmaLocal> form={defaultForm} layoutColumns={4} name="nomeDaFazenda" 
		label="Nome da Fazenda" required={false}  isClearable={true}
		/>,
		<TextField<LpmaLocal> form={defaultForm} layoutColumns={6} name="cnpj" 
		label="CNPJ" required={false}  isClearable={true}
		/>
	 ]}

	onFilterSearch = {async (queries?: any)=>{
		let filtro = {
			...defaultForm.getValues(),
			...queries
		}
		return await LpmaServiceApi.getLocais(filtro as LpmaLocal);
	}}
	>		

	<ListCol
		header='Nome da Fazenda'
		field='nomeDaFazenda'
		sort="asc"
	/>			

	<ListCol
		header='CNPJ'
		field='cnpj'
		sort="asc"
	/>			

	<ListCol
		header='UF/Pais'
		field='uf'
		valueFormatter={(item:any)=>{ return `${item?.data.uf}/${item?.data.pais}`  }}
		sort="asc"
	/>	

	</ListingView>
}