import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import Button from '../../../../components/Button';
import If from '../../../../components/If';
import AlertError from '../../../../components/AlertError';
import AlertSuccess from '../../../../components/AlertSuccess';
import ResponseError from '../../../../components/ResponseError';
import Loading from '../../../../components/Loading';
import { STRINGS } from '../../../../util/strings';
import { BreederForm, BreederResults } from '../../../../models/models';
import api from '../../../../services/api';
import '../../styles_edit.css';

interface stateType {
	id: number;
}

export default function Breeder ()
{
	const history = useHistory();
	const location = useLocation<stateType>();
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [errorRequest, setErrorRequest] = useState<any>(null);

	const breederSchema = yup.object().shape({
		name: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		cpf: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		registrationClassOrgan: yup.string(),
		professionalAddress: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		phone: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		email: yup.string().email(STRINGS.FORM_EMAIL_INVALID).required(STRINGS.FORM_FIELD_REQUIRED),
		titration: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		cvLattes: yup.string(),
		mainTechnician: yup.boolean(),
		active: yup.boolean()
	});

	const { handleSubmit, register, errors, reset, setValue, control, setError
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(breederSchema)
	});

	useEffect(() => {
		const getBreederById = async () => {
			try {
				const { data } = await api.get<BreederResults>(`api/v1/cultivares/EquipeTecnicaMelhorista/${location.state.id}`);

				reset({
					name: data.nome,
					cpf: data.cpf,
					registrationClassOrgan: data.registroOrgaoClasse,
					professionalAddress: data.enderecoProfissional,
					phone: data.telefone,
					email: data.email,
					titration: data.titulacao,
					cvLattes: data.cvLattes,
					mainTechnician: data.tecnicoPrincipal,
					active: data.ativo
				});
			} catch (errRequest:any) {
				setErrorRequest(errRequest.response.data);
			}
			setLoading(false);
		}

		getBreederById();
	}, [location.state.id, reset]);

	const verifyErrorsOnForm = (): boolean => {
		if (errors.name) {
			return true;
		}
		return false;
	}

	const onSubmit = async (breederData: BreederForm) => {
		setLoadingSubmit(true);
		setErrorRequest(null);
		try {
			const { status } = await api.put('api/v1/cultivares/EquipeTecnicaMelhorista', {
				id: location.state.id,
				nome: breederData.name,
				cpf: breederData.cpf,
				registroOrgaoClasse: breederData.registrationClassOrgan,
				enderecoProfissional: breederData.professionalAddress,
				telefone: breederData.phone,
				email: breederData.email,
				titulacao: breederData.titration,
				cvLattes: breederData.cvLattes,
				tecnicoPrincipal: breederData.mainTechnician,
				ativo: breederData.active
			});

			if (status === 200) {
				setSuccessfulResponse(true);
				reset();
				setTimeout(() => {
					history.goBack();
				}, 3000)
			}
		} catch (errRequest:any) {
			setErrorRequest(errRequest.response.data);
		}
		setLoadingSubmit(false);
	}

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			<AlertError
				message={STRINGS.ALERT_WARNING_FIELDS}
			/>
		</If>
		<If condition={successfulResponse && !errorRequest}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS_EDIT}
			/>
		</If>
		<If condition={!successfulResponse && errorRequest !== null}>
			{errorRequest !== null && errorRequest.message.length > 0 ? errorRequest.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<h3>Edição de melhorista</h3>
		<If condition={loading}>
			<span className="loading-page">
				<Loading />
			</span>
		</If>
		<If condition={!loading && !errorRequest}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={5}>
						<Form.Label>
							Nome do&#40;a&#41; melhorista<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="name"
							type="text"
							placeholder='Insira o nome do&#40;a&#41; melhorista'
							isInvalid={errors.name !== undefined}
							autoComplete="off"
							maxLength={100}
						/>
						<If condition={errors.name}>
							<Form.Control.Feedback type="invalid">
								{errors.name?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							CPF<em>*</em>
						</Form.Label>
						<Controller
							control={control}
							name="cpf"
							isIn
							render={(props: any) => {
								return (
									<NumberFormat
										value={props.value}
										onValueChange={(target) => {
											props.onChange();
											setValue('cpf', target.value);
										}}
										onBlur={props.onBlur}
										format="###.###.###-##"
										className="form-control"
									/>
								)
							}}
						/>
						<If condition={errors.cpf}>
							<Form.Control.Feedback type="invalid">
								{errors.cpf?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col} md={3}>
						<Form.Label>
							Telefone<em>*</em>
						</Form.Label>
						<Controller
							control={control}
							name="phone"
							rules={{
								required: true,
							}}
							render={(props: any) => {
								return (
									<NumberFormat
										value={props.value}
										onValueChange={(target) => {
											props.onChange();
											setValue('phone', target.value);
										}}
										onBlur={props.onBlur}
										format="(##) #####-####"
										className="form-control"
									/>
								)
							}}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Registro no órgão de classe &#40;CREA/CTNBio&#41;
					</Form.Label>
						<Form.Control
							ref={register}
							name="registrationClassOrgan"
							type="text"
							placeholder='Insira o registro no órgão de classe do&#40;a&#41; melhorista'
							autoComplete="off"
							maxLength={50}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Email<em>*</em>
					</Form.Label>
						<Form.Control
							ref={register}
							name="email"
							type="email"
							placeholder='Insira o email do&#40;a&#41; melhorista'
							autoComplete="off"
							isInvalid={errors.email !== undefined}
							maxLength={150}
						/>
						<If condition={errors.email}>
							<Form.Control.Feedback type="invalid">
								{errors.email?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Endereço profissional<em>*</em>
					</Form.Label>
						<Form.Control
							ref={register}
							name="professionalAddress"
							type="text"
							placeholder='Insira o endereço profissional do&#40;a&#41; melhorista'
							autoComplete="off"
							maxLength={400}
						/>
						<If condition={errors.professionalAddress}>
							<Form.Control.Feedback type="invalid">
								{errors.professionalAddress?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Titulação<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="titration"
							placeholder='Insira a titulação do&#40;a&#41; melhorista'
							autoComplete="off"
							maxLength={200}
						/>
						<If condition={errors.titration}>
							<Form.Control.Feedback type="invalid">
								{errors.titration?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							CV Lattes
						</Form.Label>
						<Form.Control
							ref={register}
							name="cvLattes"
							placeholder='Insira a url do CV Lattes do&#40;a&#41; melhorista'
							autoComplete="off"
							maxLength={150}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={2} className="checkbox-lg">
						<Form.Check
							type="checkbox"
							ref={register}
							name="mainTechnician"
							label="Técnico(a) principal?"
						/>
					</Form.Group>
					<Form.Group as={Col} className="checkbox-lg">
						<Form.Check
							type="checkbox"
							ref={register}
							name="active"
							label="Ativo?"
						/>
					</Form.Group>
				</Form.Row>
				<div className="button-position">
					<Button
						title="Cancelar"
						type="button"
						contained={false}
						onClick={() => history.goBack()}
					/>
					<Button
						title="Salvar"
						type="submit"
						contained={true}
						isLoading={loadingSubmit}
					/>
				</div>
			</Form>
		</If>
	</div>;
}