/*
 * OLD IMPORTS
 */

//Basic REACT
import React, { useState, useEffect, useContext, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Col } from "react-bootstrap";
import Select from 'react-select';

//Custom Componentes
import Button from "../../../components/Button";
import If from "../../../components/If";
import AlertSuccess from "../../../components/AlertSuccess";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Modal from "../../../components/Modal";
import Fieldset from "../../../components/Fieldset";
import AddButton from "../../../components/AddButton";
import GenericButton from "../../../components/FlowGenericButton";
import LoadingInForm from '../../../components/LoadingInForm';
import EditButton from '../../../components/EditButton';
import DeleteButton from '../../../components/DeleteButton';
import DatePicker, { registerLocale } from 'react-datepicker';

//Custom services
import { DecodedToken } from '../../../models/user';
import jwtDecode from 'jwt-decode';
import api from "../../../services/api";
import { Context } from '../../../context/AuthContext'
import { isAdmin } from '../../../users/AuthorizationsCIBio/isAdmin';
import { isReguladorReader, isReguladorWriter } from '../../../users/AuthorizationsCIBio/isRegulador';
import { isRequesterReader, isRequesterWriter } from '../../../users/AuthorizationsCIBio/isRequester';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';

//Page Specifics
import { STRINGS } from "../../../util/strings";
import { STRINGS_CIBIO } from "../../../util/authorizationsCIBIO/string";
import { STATUS_CIBIO } from '../../../util/authorizationsCIBIO/status';
import "../styles_edit.scss";
import {
	AutorizationImportExport,
	CulturaEvento,
	CultureResult,
	EventResult,
	UnityStationResult,
	Enum,
	StateType
} from "../../../models/modelsAuthorizationsCIBIO";
import { DecimalAsComma } from "../NumberFormat";

/**
 * New stuff
 */
import NavigationMessage, {MessageType, MessageContent } from "../../../components/NavigationMessages";
import { uuid } from "../../../services/uuid";
import { isEmptyObj } from "../../../functions/isEmptyObject";
import { NamedImportsOrExports } from "typescript";

registerLocale('pt-BR', ptBR);

export default function Edit ()
{
	const {token} = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);
	const baseUrl: string = '/api/v1/autorizacoes-cibio/Importacao';
	const titleText: string = STRINGS_CIBIO.REGISTER_AUTHORIZATIONS_AI;
	const subtitleText: string = STRINGS_CIBIO.REGISTER_AUTHORIZATIONS_AI_SUBTITLE;
	const GoBackToTop = () => {window.scrollTo({ top: 0, behavior: 'smooth' })}
	const ParseDefaultBackendMessage = (backendMessage : any) => {

		let hasBackendMessage = backendMessage && backendMessage.response && backendMessage.response.data && Array.isArray(backendMessage.response.data);

		if (hasBackendMessage)
		{
			for (let i in backendMessage.response.data)
			{
				let item = backendMessage.response.data[i][0];
				addPageMessages({type:MessageType.error, content:`O campo ${item} é obrigatório`});
			}
		}
		else
		{
			addPageMessages({type:MessageType.error, content:STRINGS.ALERT_GENERIC_ERROR});
		}
	}

	//Page status flow
	enum statuses {
		idle,					//Nothing is taking the screen right now
		modal_itens,			//Showing modal for add itens
		go_back,				//Going back to list screen
		send_correction,		//Send business rule do correction
		send_regulatory,		//Send business rule to regulatory
		send_completion,		//Send business rule to completion
		send_aproval,			//Send business rule to aproval
	}
	const [status, setStatus] = useState<statuses>(statuses.idle);

	const [statusRequisicao] = useState<any>({
		1:'Em Preenchimento',
		2:'Em Análise Pelo Regulatório',
		3:'Correção Solicitada',
		4:'Em Correção',
		5:'Aprovado pelo Regulatório',
		6:'Concluído',
		7:'Cancelado'
	});

	//Loading screen
	const [loading, setLoading] = useState<string[]>([]);
	const setLoadingScreen = (tag : string, type: boolean = true) => {
		
		let currentLoading = loading;
		let index = currentLoading.indexOf(tag);
		
		if (type)
		{
			if (index >= 0)
				return;

			currentLoading.push(tag);
		}
		else
		{
			if (index <= -1)
				return;

			currentLoading.splice(index, 1);
		}

		setLoading([]);
		setLoading(currentLoading);
	}
	const isLoadingScreen = (tag:string) : boolean => {return loading.indexOf(tag) >= 0;}

	//Page notification
	const [pageMessages, setPageMessages] = useState<MessageContent[]>([]);
	const hasPageMessages = () : boolean => {return pageMessages.length > 0;}
	const clearPageMessages = async () => {await setPageMessages([]); }
	const addPageMessages = (message : MessageContent, clearMessages : boolean = true) => {
		let currentPageMessages:MessageContent[] = !clearMessages ? pageMessages : [];
		setPageMessages([]);
		currentPageMessages.push(message);
		setPageMessages(currentPageMessages);
	}
	const setSuccessMessageAndRedirect = (message : string, callback: any) => {
		setStatus(statuses.idle);
		addPageMessages({type:MessageType.success, content:message});
		GoBackToTop();
	}

	//Data entity
	const [entityItem, setEntityItem] = useState<CulturaEvento>({});
	const [entity, setEntity] = useState<AutorizationImportExport>({
		id: undefined,
		status: STATUS_CIBIO.EmPreenchimento,
		solicitante: decoded.unique_name,
		area: '',
		origem: '',
		destino: '',
		localEnsaio: '',
		objetivo: '',
		itens: [],
		observacoes: '',

		numeroAutorizacao: '',
		dataCriacao: '',
		dataAprovacao: '',
		observacoesRegulatorio: '',
	});

	/**
	 * OLD IMPLEMENTATION
	 */
	const history = useHistory();
	const location = useLocation<StateType>();
	const [locations, setLocations] = useState<UnityStationResult[]>([]);
	const [events, setEvents] = useState<EventResult[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [enumMaterial, setEnumMaterial] = useState<Enum[]>([]);	
	const [enumUnidadeMedida, setEnumUnidadeMedida] = useState<Enum[]>([]);
	
	//inicio config campos evento...
	const eventForm = useForm({
		defaultValues: entityItem,
		mode: 'onSubmit',
		resolver: yupResolver(yup.object({
			evento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
			quantidade: yup
				.number()
				.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
				.nullable()
				.required(STRINGS.FORM_FIELD_REQUIRED)
				.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
			,
			unidadeMedidaData: yup.mixed().nullable(),
			materialData: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
			unidMedAutorizadaOutros: yup.string(),
			materialOutros: yup.string(),
			unidMedTranspOutros: yup.string()
		})),
		shouldUnregister: false
	})

	const defaultForm = useForm({
		defaultValues: entity,
		mode: "onSubmit",
		resolver: yupResolver(yup.object().shape({
			nome: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
			active: yup.boolean(),
			area: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
			origem: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
			destino: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
			localEnsaio: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
			objetivo: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		})),
	});

	const can_edit_values = (itemGroup : string) => {

		if (location?.state?.readonly === true)
			return false;

		if (isAdmin(decoded.roles))
		{
			return true;
		}

		if(entity.status === STATUS_CIBIO.AprovadopeloRegulatorio)
			return false;

		if (entity.status === STATUS_CIBIO.Cancelado)
			return false;

		if (entity.status === STATUS_CIBIO.Concluido)
			return false;

		let isRequester : boolean = isRequesterReader(decoded.roles) || isRequesterWriter(decoded.roles);
		let isRegulador : boolean = isReguladorReader(decoded.roles) || isReguladorWriter(decoded.roles);

		if (entity.status === STATUS_CIBIO.EmPreenchimento || entity.status === STATUS_CIBIO.EmCorrecao || entity.status === STATUS_CIBIO.CorrecaoSolicitada)
		{
			if (!isRequester)
				return false;

			if (itemGroup != 'basic')
				return false;
		}

		if (entity.status === STATUS_CIBIO.EmAnalisePeloRegulatorio)
		{
			if (!isRegulador)
				return false;

			if (itemGroup != 'aprovacao')
				return false;
		}

		return true;
	}

	const submitForm_update_events = (info: CulturaEvento): void => {

		let currentEntityItem = entityItem;
		let currentEntityItens = entity.itens;

		let index = currentEntityItens.findIndex(e=> e.uid === info?.uid)	
		
		currentEntityItem.id						= info?.id;
		currentEntityItem.uid						= info?.uid;
		currentEntityItem.cultura					= info?.cultura;
		currentEntityItem.evento					= info?.evento;
		currentEntityItem.material					= info?.materialData?.value;
		currentEntityItem.materialEspecifico		= info?.materialEspecifico;
		currentEntityItem.quantidade				= info?.quantidade;
		currentEntityItem.unidadeMedida				= info?.unidadeMedida?.value;
		currentEntityItem.unidadeMedidaEspecifica	= info?.unidadeMedidaEspecifica;
		
		if (index < 0)
		{
			currentEntityItem.uid = uuid();
			currentEntityItens.push(currentEntityItem);
		}
		else
		{ 
			currentEntityItens[index] = currentEntityItem;
		}

		entity.itens = [];
		setEntity(entity);

		// let uuid : string = uuid();

		eventForm.reset({});
		setEntityItem({});

		entity.itens = currentEntityItens;
		setEntity(entity);
		setStatus(statuses.idle);
	}

	const submitForm_complete = async () => {

		await clearPageMessages();
		setLoadingScreen('complete');
		
		try
		{
			const valuesToSubmit = getValuesToSubmit();
			const { data, status } = await api.post(`${baseUrl}/concluir`, valuesToSubmit);

			if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');

			let defaultValues = defaultForm.getValues();
			defaultValues.itens = entity.itens;

			defaultForm.reset({});
			setEntity({itens:[]} as AutorizationImportExport);

			let dataFromServer : AutorizationImportExport = updateStatusFromServer(defaultValues, data);

			setTimeout(() => {
				defaultForm.reset(dataFromServer);
				setEntity(dataFromServer);
			}, 500);

			setSuccessMessageAndRedirect(STRINGS_CIBIO.AMI_COMPLETE_SUCCESS, () => {history.goBack();})
		}
		catch (error:any)
		{
			ParseDefaultBackendMessage(error);
			setStatus(statuses.idle);
		}

		GoBackToTop();
		setLoadingScreen('complete', false);
	}

	const submitForm_aprove = async () => {

		await clearPageMessages();
		setLoadingScreen('aprove');
		
		try
		{
			const valuesToSubmit = getValuesToSubmit();
			const { data, status } = await api.post(`${baseUrl}/aprovar`, valuesToSubmit);

			if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');

			let defaultValues = defaultForm.getValues();
			defaultValues.itens = entity.itens;
			
			defaultForm.reset({});
			setEntity({itens:[]} as AutorizationImportExport);

			let dataFromServer : AutorizationImportExport = updateStatusFromServer(defaultValues, data);

			setTimeout(() => {
				defaultForm.reset(dataFromServer);
				setEntity(dataFromServer);
			}, 500);

			setSuccessMessageAndRedirect(STRINGS_CIBIO.AMI_APROVE_SUCCESS, () => {history.goBack();})
		}
		catch (error:any)
		{
			ParseDefaultBackendMessage(error);
			setStatus(statuses.idle);
		}

		GoBackToTop();
		setLoadingScreen('aprove', false);
	}

	const submitForm_regulatory = async () => {

		await clearPageMessages();
		setLoadingScreen('submit');
		setStatus(statuses.idle);
		
		try
		{
			const valuesToSubmit = getValuesToSubmit();
			const { data, status } = await api.post(`${baseUrl}/enviarregulatorio`, valuesToSubmit);

			if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');

			let defaultValues = defaultForm.getValues();
			defaultValues.itens = entity.itens;

			defaultForm.reset({});
			setEntity({itens:[]} as AutorizationImportExport);

			let dataFromServer : AutorizationImportExport = updateStatusFromServer(defaultValues, data);

			setTimeout(() => {
				defaultForm.reset(dataFromServer);
				setEntity(dataFromServer);
			}, 500);

			setSuccessMessageAndRedirect(STRINGS.REGULATORY_SUCCESS, () => {history.goBack();})
		}
		catch (error:any)
		{
			ParseDefaultBackendMessage(error);
		}
		
		GoBackToTop();
		setLoadingScreen('submit', false);
	};

	const submitForm_correction = async () => {

		await clearPageMessages();
		setLoadingScreen('correction');

		try
		{
			const valuesToSubmit = getValuesToSubmit();
			const { data, status } = await api.post(`${baseUrl}/solicitarcorrecao`, valuesToSubmit);

			if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');

			let defaultValues = defaultForm.getValues();
			defaultValues.itens = entity.itens;

			defaultForm.reset({});
			setEntity({itens:[]} as AutorizationImportExport);

			let dataFromServer : AutorizationImportExport = updateStatusFromServer(defaultValues, data);

			setTimeout(() => {
				defaultForm.reset(dataFromServer);
				setEntity(dataFromServer);
			}, 500);

			setSuccessMessageAndRedirect(STRINGS_CIBIO.AMI_CORRECTION_SUCCESS, () => {history.goBack();})
		}
		catch (error:any)
		{
			ParseDefaultBackendMessage(error);
			setStatus(statuses.idle);
		}
		
		GoBackToTop();
		setLoadingScreen('correction', false);
	}

	const submitForm_draft = async () => {
		
		await clearPageMessages();
		setLoadingScreen('draft');

		try
		{
			const valuesToSubmit = getValuesToSubmit();
		
			if (entity.id == undefined)
			{
				const { status, data } = await api.post(`${baseUrl}/rascunho`, valuesToSubmit);
				entity.id = data?.id;

				if ([200, 201].indexOf(status) <= -1)
					throw new Error('Erro ao obter resposta do servidor!');
			}
			else
			{
				const { status, data } = await api.put(`${baseUrl}/rascunho`, valuesToSubmit);

				if ([200, 201].indexOf(status) <= -1)
					throw new Error('Erro ao obter resposta do servidor!');
			}

			setSuccessMessageAndRedirect(STRINGS.ALERT_MESSAGE_SUCCESS, () => { history.goBack(); })
		}
		catch (error:any)
		{
			ParseDefaultBackendMessage(error);
		}
		
		GoBackToTop();
		setLoadingScreen('draft', false);
	}

	const eventsActions = (props: any) => {

		if (!can_edit_values('basic'))
			return <Fragment />;

		return <Fragment>
			<EditButton onClick={() => {

				let itemsel = props.node.data;
				let material = enumMaterial.find((item) => item.key === itemsel.material);					
				itemsel.materialData = {value: material?.key, label: material?.value}; 

				let unidade = enumUnidadeMedida.find((item) => item.key === itemsel.unidadeMedida);					
				itemsel.unidadeMedidaData = {value: unidade?.key, label: unidade?.value}; 
				itemsel.unidadeMedida = {value: unidade?.key, label: unidade?.value}; 

				eventForm.reset(itemsel);
				setStatus(statuses.modal_itens);
			
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {

				let currentEntity = entity;
				let newItens: CulturaEvento[] = [];
				
				props.node.gridApi.updateRowData({ remove: [props.node.data] })
				props.node.gridApi.forEachNode(({ data }: any) => newItens.push(data));
				
				currentEntity.itens = newItens;

				setEntity(currentEntity);
			
			}}/>
		</Fragment>;
	};

	const getValuesToSubmit = () => {

		let origem = defaultForm.getValues('origem');
		let destino = defaultForm.getValues('destino');

		let valuesToSubmit = {
			id						: entity.id,
			status					: entity.status,
			solicitante				: defaultForm.getValues('solicitante'),
			area					: defaultForm.getValues('area'),
			origemId				: origem?.value,
			destinoId				: destino?.value,
			localEnsaio				: defaultForm.getValues('localEnsaio'),
			objetivo				: defaultForm.getValues('objetivo'),
			itens					: entity.itens.map((item) => {return {
										culturaId			: item?.cultura?.value,
										eventoId			: item?.evento.value,
										material			: item?.material,
										materialEspecifico	: item?.materialEspecifico,
										quantidade			: item?.quantidade,
										unidadeMedida		: item?.unidadeMedida,
										UnidadeMedidaEspecifica	: item?.unidadeMedidaEspecifica,
									};}),
			observacoes				: defaultForm.getValues('observacoes'),
			numeroAutorizacao		: defaultForm.getValues('numeroAutorizacao'),
			observacoesRegulatorio	: defaultForm.getValues('observacoesRegulatorio'),
		};

		return valuesToSubmit;
	}

	const SubStringEdt = (texto: string, max: number) =>{
		if(texto == null) return '';
		return texto.substring(0, max - 1);
	}

	const parseDataFromServer = (data:any) : AutorizationImportExport => {

		let dataCriacao = moment(data?.dataCriacao);
		let dataAprovacao = moment(data?.dataAprovacao);

		let dataFromServer : AutorizationImportExport = {
			id: data?.id,
			status: data?.status,
			solicitante: data?.solicitante,
			area: data?.area,
			origem: { value: data?.origem?.id, label: data?.origem?.nome },
			destino: { value: data?.destino?.id, label: data?.destino?.nome },
			localEnsaio: SubStringEdt(data?.localEnsaio, 500),
			objetivo: data?.objetivo,
			itens: [],
			observacoes: data?.observacoes,
			numeroAutorizacao: data?.numeroAutorizacao,
			dataCriacao: dataCriacao.isValid() ? dataCriacao.toDate() : null,
			dataAprovacao: dataAprovacao.isValid() ? dataAprovacao.toDate() : null,
			observacoesRegulatorio: data?.observacoesRegulatorio,
			motivoCancelamento: data?.motivoCancelamento
		};

		if (Array.isArray(data?.itens) && data?.itens.length > 0)
		{
			for (let i = 0, t = data?.itens.length; i < t; i++)
			{
				let currentItem = data?.itens[i];

				let cultura : any = {
					value: currentItem?.cultura?.id,
					label: currentItem?.cultura?.nome
				};

				if (currentItem?.cultura == null)
				{
					cultura = cultures.find(cultura => cultura.id = currentItem?.culturaId);
				}
				
				let evento : any = {
					value: currentItem?.evento?.id,
					label: currentItem?.evento?.nome,
				};

				if (currentItem?.evento == null)
				{
					evento = events.find(evento => evento.id = currentItem?.eventoId);
				}

				dataFromServer.itens.push({
					id					: currentItem?.id,
					uid					: uuid(),
					culturaId			: currentItem?.culturaId,
					cultura				: cultura,
					eventoId			: currentItem?.eventoId,
					evento				: evento,
					material			: currentItem?.material,
					materialEspecifico	: currentItem?.materialEspecifico,
					quantidade			: currentItem?.quantidade,
					unidadeMedida		: currentItem?.unidadeMedida,
					unidadeMedidaEspecifica : currentItem?.unidadeMedidaEspecifica,
				});

			}
		}

		return dataFromServer;
	}

	const updateStatusFromServer = (originalData: AutorizationImportExport, data:any) : AutorizationImportExport => {

		let dataCriacao = moment(data?.dataCriacao);
		let dataAprovacao = moment(data?.dataAprovacao);

		originalData.id						= data?.id;
		originalData.status					= data?.status;
		originalData.numeroAutorizacao		= data?.numeroAutorizacao;
		originalData.dataCriacao			= dataCriacao.isValid() ? dataCriacao.toDate() : null;
		originalData.dataAprovacao			= dataAprovacao.isValid() ? dataAprovacao.toDate() : null;
		originalData.observacoesRegulatorio	= data?.observacoesRegulatorio;

		return originalData;
	}

	const parseModalItens = () => {
		return <Modal show={status === statuses.modal_itens} onHide={() => {setStatus(statuses.idle); eventForm.reset({});}} title={STRINGS_CIBIO.AMI_EVENT_MODAL_TITLE}>
			<Form onSubmit={eventForm.handleSubmit(submitForm_update_events)} autoComplete="off">
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Cultura/Organismo<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<If condition={isLoadingScreen('get_data_form')}>
							<LoadingInForm />
						</If>
						<Controller
							control={eventForm.control}
							name="cultura"
							isInvalid={eventForm.errors.cultura}
							as={<Select
								options={cultures.map(culture => Object.assign({ label: culture.nome, value: culture.id }))}
								placeholder="Selecione uma cultura/organismo"
								isClearable
							/>}
						/>
						<If condition={eventForm.errors.cultura}>
							<p className="error-field">{eventForm.errors.cultura?.message}</p>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Evento<em>*</em>
						</Form.Label>
						<Controller
							control={eventForm.control}
							name="evento"
							isInvalid={eventForm.errors.evento !== undefined}
							as={
								<Select
									placeholder="Selecione um evento"
									options={events.map(event => Object.assign({ value: event.id, label: event.nome }))}
									isClearable
								/>
							}
						/>
						<If condition={eventForm.errors.evento}>
							<p className="error-field">{eventForm.errors.evento?.message}</p>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Material<em>*</em>
						</Form.Label>
						<Controller
							control={eventForm.control}
							name="materialData"
							isInvalid={eventForm.errors.materialData !== undefined}
							as={<Select
								placeholder="Selecione um material"
								options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
								isClearable
							/>}
						/>
						<If condition={eventForm.errors.material}>
							<p className="error-field">{eventForm.errors.material?.message}</p>
						</If>
					</Form.Group>
				</Form.Row>
				<If condition={eventForm.watch().materialData?.value === 7}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Especifique o material<em>*</em>
							</Form.Label>
							<Form.Control
								ref={eventForm.register}
								required
								name="materialEspecifico"
								placeholder="Insira o material"
							/>
							<span className="error-field">
								{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
							</span>
						</Form.Group>
					</Form.Row>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Quantidade a ser utilizada<em>*</em>
						</Form.Label>
						<Form.Control
							ref={eventForm.register}
							name="quantidade"
							placeholder="Insira a quantidade a ser utilizada"
							maxLength={5}
							type="number"
							step="any"
							isInvalid={eventForm.errors.quantidade !== undefined}
						/>
						<Form.Control.Feedback type="invalid">
							{eventForm.errors.quantidade?.message}
						</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Unidade de Medida
						</Form.Label>
						<Controller
							control={eventForm.control}
							name="unidadeMedida"
							as={<Select
								placeholder="Selecione uma unidade de medida"
								options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
								isClearable
							/>}
						/>
					</Form.Group>
				</Form.Row>
				<If condition={eventForm.watch().unidadeMedida?.value === 4}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Especifique a unidade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={eventForm.register}
								required
								name="unidadeMedidaEspecifica"
								placeholder="Insira a unidade"
							/>
							<span className="error-field">
								{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
							</span>
						</Form.Group>
					</Form.Row>
				</If>
				<div className="button-position">
					<Button
						title="Cancelar"
						contained={false}
						type="button"
						onClick={() => {eventForm.reset({}); setStatus(statuses.idle); }}
					/>
					<Button
						title="Salvar"
						contained={true}
						type="submit"
					/>
				</div>
			</Form>
		</Modal>;
	}

	const parseButton = () => {

		let buttonCancelar = <Button
			title="Voltar"
			contained={false}
			type="button"
			onClick={() => {
				defaultForm.formState.isDirty ? setStatus(statuses.go_back) :
				history.goBack();
			}}
		/>;
		
		let CanEdit = location?.state?.readonly !== true;
		let buttonSolicitarCorrecao =  <Fragment />;
		let buttonEnviarRegulatorio =  <Fragment />;
		let buttonAprovar =  <Fragment />;
		let buttonSalvarConcluir =  <Fragment />;
		let buttonRascunho =  <Fragment />;
		let buttonSalvar =  <Fragment />;

		if (CanEdit && entity.status !== STATUS_CIBIO.EmPreenchimento && isAdmin(decoded.roles))
		{
			buttonSalvar = <Button
				title="Salvar"
				contained
				type="button"
				onClick={() => submitForm_draft()}
			/>;
		}

		if(CanEdit && entity.status === STATUS_CIBIO.EmAnalisePeloRegulatorio && (isReguladorWriter(decoded.roles) || 		
		isAdmin(decoded.roles)))
		{
			buttonSolicitarCorrecao = 	<GenericButton
			title="Solicitar correção"
			variant="btn-danger"
			type="button"
			loading={false}
			onClick={() => setStatus(statuses.send_correction)}
			/>
		}	
		if(CanEdit && (entity.status === STATUS_CIBIO.EmPreenchimento || entity.status === STATUS_CIBIO.CorrecaoSolicitada || entity.status === STATUS_CIBIO.EmCorrecao) && (isRequesterWriter(decoded.roles) || isAdmin(decoded.roles)))
		{
			buttonEnviarRegulatorio = <GenericButton
			title="Salvar e enviar para regulatório"
			variant="btn-warning"
			type="button"
			loading={isLoadingScreen('submit')}
			onClick={() => defaultForm.trigger().then(validated => {

				clearPageMessages();
				let hasErrors : boolean = false;

				if (entity.itens.length <= 0)
				{
					hasErrors = true;
					addPageMessages({type: MessageType.error, content:STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED});
				}

				if (!isEmptyObj(defaultForm.errors))
				{
					hasErrors = true;
				}

				if (!hasErrors)
				{
					setStatus(statuses.send_regulatory);
					clearPageMessages();
				}
				else
				{
					GoBackToTop();
				}
			})}
		/>

		}

		if(CanEdit && entity.status === STATUS_CIBIO.EmAnalisePeloRegulatorio && (isAdmin(decoded.roles) || isReguladorWriter(decoded.roles) || isReguladorReader(decoded.roles)))
		{
			buttonAprovar = <GenericButton
				title="Aprovar"
				variant="btn-success"
				type="button"
				loading={false}
				onClick={() => defaultForm.trigger().then(validated => {
					if (validated && entity.itens.length) {
						setStatus(statuses.send_aproval);
						clearPageMessages();
					} else if (validated && !entity.itens.length) {
						addPageMessages({type: MessageType.error, content:STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED})
						GoBackToTop();
					} else {
						addPageMessages({type: MessageType.error, content:STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED})
						GoBackToTop();
					}
				})}
			/>
		}

		if(CanEdit && entity.status === STATUS_CIBIO.AprovadopeloRegulatorio && (isAdmin(decoded.roles) || isRequesterWriter(decoded.roles)))
		{
			buttonSalvarConcluir = <GenericButton
				title="Concluir"
				variant="btn-info"
				type="button"
				loading={false}
				onClick={() => setStatus(statuses.send_completion)}
			/>
		}

		if(CanEdit && entity.status === STATUS_CIBIO.EmPreenchimento)
		{
			buttonRascunho = <Button
				title="Salvar rascunho"
				contained={true}
				type="button"
				onClick={() => submitForm_draft()}
				isLoading={isLoadingScreen('draft')}
			/>;
		}

		// <If condition={(statusAuthorization === STATUS_CIBIO.EmAnalisePeloRegulatorio && (isReguladorWriter(decoded.roles) || isAdmin(decoded.roles))) || ((statusAuthorization === STATUS_CIBIO.CorrecaoSolicitada || statusAuthorization === STATUS_CIBIO.EmCorrecao) && (isRequesterWriter(decoded.roles) || isAdmin(decoded.roles))) || (statusAuthorization === STATUS_CIBIO.AprovadopeloRegulatorio && (isRequesterWriter(decoded.roles) || isReguladorWriter(decoded.roles) || isAdmin(decoded.roles))) || ((isReguladorWriter(decoded.roles) || isAdmin(decoded.roles)) && statusAuthorization === STATUS_CIBIO.Cancelado)}>
        //                     <Button
        //                         title="Salvar"
        //                         contained
        //                         type="button"
        //                         onClick={() => trigger().then(validated => {
        //                             if (validated && selectedEvents.length) {
        //                                 onSubmit();
        //                                 setEventsMsg('');
        //                             } else if (validated && !selectedEvents.length) {
        //                                 setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
        //                                 window.scrollTo({ top: 0, behavior: 'smooth' });
        //                             } else {
        //                                 setEventsMsg(STRINGS_CIBIO.AMI_NO_EVENTS_SELECTED);
        //                             }
        //                         })}
        //                         isLoading={loadingSubmit}
        //                     />
        //                 </If>


		return <div className="button-position">
			{buttonCancelar}
			{buttonSalvar}
			{buttonSolicitarCorrecao}
			{buttonEnviarRegulatorio}
			{buttonAprovar}
			{buttonSalvarConcluir}
			{buttonRascunho}
		</div>;
	}

	const parseModals = () => {

		let modalGoBack = <ConfirmationModal
				show={status == statuses.go_back}
				confirmationMsg={STRINGS_CIBIO.CONFIRM_LEFT_EDITION}
				cancelMsg={STRINGS_CIBIO.CANCEL_LEFT_EDITION}
				onConfirm={() => history.goBack()}
				onHide={() => setStatus(statuses.idle)}
				title={STRINGS_CIBIO.EDITION_TITLE_CONFIRMATION}
				type="button"
				variant="danger"
			>
				{STRINGS_CIBIO.EDITION_REGISTER_CONFIRMATION_CANCEL}
			</ConfirmationModal>
		;

		let modalSendRegulatory = <ConfirmationModal
				show={status == statuses.send_regulatory}
				onHide={() => {setStatus(statuses.idle);}}
				title={STRINGS.REGULATORY_TITLE}
				confirmationMsg={STRINGS.REGULATORY_ACTION}
				onConfirm={() => {submitForm_regulatory();}}
				loading={isLoadingScreen('submit')}
				variant="success"
				color="white"
			>
				<p>{STRINGS.REGULATORY_MESSAGE}</p>
			</ConfirmationModal>
		;

		let modalSendConfirmation = <ConfirmationModal
				show={status == statuses.send_correction}
				onHide={() => setStatus(statuses.idle)}
				title={STRINGS_CIBIO.AMI_CORRECTION_TITLE}
				confirmationMsg={STRINGS_CIBIO.AMI_CORRECTION_CONFIRMATION_MSG}
				onConfirm={() => submitForm_correction()}
				loading={isLoadingScreen('correction')}
				variant="success"
				color="white"
			>
				<p>{STRINGS_CIBIO.AMI_CORRECTION_BODY_MSG}</p>
			</ConfirmationModal>
		;

		let modalSendConclusion = <ConfirmationModal
				show={status == statuses.send_completion}
				onHide={() => setStatus(statuses.idle)}
				title={STRINGS_CIBIO.AMI_COMPLETE_TITLE}
				confirmationMsg={STRINGS_CIBIO.AMI_COMPLETE_CONFIRMATION_MSG}
				onConfirm={() => submitForm_complete()}
				loading={isLoadingScreen('complete')}
				variant="success"
				color="white"
			>
				<p>{STRINGS_CIBIO.AMI_COMPLETE_BODY_MSG}</p>
			</ConfirmationModal>
		;

		let modalSendAproval = <ConfirmationModal
				show={status == statuses.send_aproval}
				onHide={() => setStatus(statuses.idle)}
				title={STRINGS_CIBIO.AMI_APROVE_TITLE}
				confirmationMsg={STRINGS.APROVE_CONFIRMATION}
				onConfirm={() => submitForm_aprove()}
				loading={isLoadingScreen('aprove')}
				variant="success"
				color="white"
				hideFooter={hasPageMessages()}
			>
				<If condition={hasPageMessages()}>
					<AlertSuccess
						message={STRINGS_CIBIO.AMI_APROVE_SUCCESS}
					/>
				</If>
				<p>{STRINGS_CIBIO.AMI_APROVE_BODY_MSG}</p>
			</ConfirmationModal>
		;

		return <Fragment>
			{modalGoBack}
			{modalSendRegulatory}
			{modalSendConfirmation}
			{modalSendConclusion}
			{modalSendAproval}
		</Fragment>
	}
	
	useEffect(() => {

		clearPageMessages();

		const getDataToForm = async (FinishHandler : any) => {

			setLoadingScreen('get_data_form');
			
			try
			{
				const locations = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');
				const events = await api.get<EventResult[]>('api/v1/autorizacoes-cibio/Evento/obterporfiltro?Ativo=true');
				const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');
				const material = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/material');
				const unidadeMedida = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/unidade-medida');
				
				if (locations.status === 200)
					setLocations(locations.data);
				
				if (events.status === 200)
					setEvents(events.data);

				if (cultures.status === 200)
					setCultures(cultures.data);
				
				if (material.status === 200)
					setEnumMaterial(material.data); 

				if (unidadeMedida.status === 200)
					setEnumUnidadeMedida(unidadeMedida.data);  
				
				FinishHandler();
			}
			catch (error:any)
			{
				ParseDefaultBackendMessage(error);
				GoBackToTop();
			}
			
			setLoadingScreen('get_data_form', false);
		}

		const getEntity = async (entityId:any) => {

			setLoadingScreen('get_data_entity');

			try
			{
				const { data, status } = await api.get<AutorizationImportExport>(`${baseUrl}/${entityId}`);

				if ([200,201].indexOf(status) <= -1)
					throw new Error('Erro ao obter os dados da entidade!');

				defaultForm.reset({});
				setEntity({itens:[]} as AutorizationImportExport);

				let dataFromServer : AutorizationImportExport = parseDataFromServer(data);

				setTimeout(() => {
					defaultForm.reset(dataFromServer);
					setEntity(dataFromServer);
				}, 500);
			}
			catch (error:any)
			{
				ParseDefaultBackendMessage(error);
				GoBackToTop();
			}

			setLoadingScreen('get_data_entity');
		}

		getDataToForm(()=>{
			if (location.state && location.state.id)
			getEntity(location.state.id);
		})		
		

	}, [location.state, defaultForm.reset]);

	return (
		<div className="container container-fluid">
			<NavigationMessage messages={pageMessages} />
			<h3>{titleText}</h3>
			<h6>{subtitleText}</h6>
			<Form onSubmit={defaultForm.handleSubmit(submitForm_regulatory)}>
				<Fieldset disabled={!can_edit_values('basic')} title={STRINGS_CIBIO.AMI_BLOCK_ONE}>
					<Form.Row className="row-custom">
						<If condition={entity.id}>
							<Form.Group as={Col}>
								<Form.Label>Nº da Solicitação</Form.Label>
								<Form.Control
									value={entity.id}
									readOnly
									maxLength={100}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Status</Form.Label>
								<Form.Control
									value={statusRequisicao[entity.status ?? 1]}
									readOnly
									maxLength={100}
								/>
							</Form.Group>
						</If>
						<Form.Group as={Col}>
							<Form.Label>Solicitante</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="solicitante"
								type="text"
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Área<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="area"
								isInvalid={defaultForm.errors.area !== undefined}
								placeholder="Insira a área"
								type="text"
								maxLength={150}
							/>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.area?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<If condition={entity.status == 7}>
							<Form.Group as={Col} md={12}>
								<Form.Label>
									Motivo do Cancelamento
								</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									as="textarea"
									name="motivoCancelamento"
									readOnly
								/>
							</Form.Group>
						</If>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Origem<em>*</em>
							</Form.Label>
							<Controller
								control={defaultForm.control}
								name="origem"
								isInvalid={defaultForm.errors.origem !== undefined}
								as={<Select
									isDisabled={!can_edit_values('basic')}
									placeholder="Selecione uma unidade/estação"
									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
									isClearable
								/>}
							/>
							<span className="small text-info">(Instituição, Cidade, País, etc.)</span>
							<If condition={defaultForm?.errors?.origem}>
								<p className="error-field">{defaultForm.errors.origem?.message}</p>
							</If>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Destino<em>*</em>
							</Form.Label>
							<Controller
								control={defaultForm.control}
								name="destino"
								isInvalid={defaultForm.errors.destino !== undefined}
								as={<Select
									isDisabled={!can_edit_values('basic')}
									placeholder="Selecione uma unidade/estação"
									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
									isClearable
								/>}
							/>
							<span className="small text-info">(Instituição, Cidade, País, etc.)</span>
							<If condition={defaultForm.errors.destino}>
								<p className="error-field">{defaultForm.errors.destino?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Local do Ensaio (Atividade em Contenção)<em>*</em></Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="localEnsaio"
								type="text"
								placeholder="Insira o destino"
								autoComplete="off"
								maxLength={150}
							/>
							<span className="small text-info">(Casa de Vegetação, Laboratório, etc.)</span>
							<If condition={defaultForm.errors.localEnsaio}>
								<p className="error-field">{defaultForm.errors.localEnsaio?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Objetivo<em>*</em></Form.Label>
							<Form.Control
								as="textarea"
								ref={defaultForm.register}
								name="objetivo"
								type="text"
								placeholder="Insira os objetivos"
								rows={5}
							/>
							<If condition={defaultForm.errors.objetivo}>
								<p className="error-field">{defaultForm.errors.objetivo?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Evento<em>*</em>{`  `}
								<AddButton
									type="button"
									onClick={() => {

										if (!can_edit_values('basic'))
											return;
											
										setStatus(statuses.modal_itens); eventForm.reset({});
									}}
								/>
							</Form.Label>
							<If condition={hasPageMessages()}>
								<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
							</If>
						</Form.Group>
					</Form.Row>
				
					<If condition={entity.itens.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={entity.itens}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: eventsActions,
								}}
							>
								<AgGridColumn
									headerName='Evento'
									field="evento.label"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
									minWidth={250}
								/>
								<AgGridColumn
									headerName='Material'
									field="material"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => { 
										let material = enumMaterial.find((item) => item.key === data.material || item.key === data.material?.value);
										if(!material) return 'Desconhecido';
										return material.key === 7 ?  data.materialEspecifico : material.value;
										
									}}
								/>
								<AgGridColumn
									headerName='Quantidade'
									field="quantidade"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => {return DecimalAsComma(data.quantidade);}}
								/>
								<AgGridColumn
									headerName='Unidade Medida'
									field="unidadeMedida"
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => {

										let unidade = enumUnidadeMedida.find((item) => item.key === data.unidadeMedida || item.key === data.unidadeMedida?.value);
										if(!unidade) return 'Desconhecido';
										return (unidade.key === 4 ?  data.unidadeMedidaEspecifica : unidade.value)
									}}
								/>
								<AgGridColumn
									headerName="Ações"
									cellRenderer="actions"
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								as="textarea"
								ref={defaultForm.register}
								name="observacoes"
								type="text"
								placeholder="Observações"
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset disabled={!can_edit_values('aprovacao')} title={STRINGS_CIBIO.AMI_BLOCK_TWO}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Nº Autorização CIBio
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="numeroAutorizacao"
								isInvalid={defaultForm.errors.numeroAutorizacao !== undefined}
								placeholder="Nº autorização CIBio"
								type="text"
								maxLength={150}
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data Emissão CIBio
							</Form.Label>
							<br />
							<Controller
								control={defaultForm.control}
								name="dataCriacao"
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										disabled={true}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										readOnly
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data da Aprovação
							</Form.Label>
							<br />
							<Controller
								control={defaultForm.control}
								name="dataAprovacao"
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										disabled={true}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										readOnly
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Observações da Análise do Regulatório
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesRegulatorio"
								as="textarea"
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
			</Form>
			{parseModalItens()}
			{parseModals()}
			{parseButton()}
		</div>
	);
}