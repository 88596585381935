import React,  { useContext } from "react";
import api, { apiFile , apiFileDownload } from "../../services/api"
import { 
	PERMISSIONS as CQB_PERMISSIONS,
    isCheck as isCQBCheckRoles
} from '../../users/usersPermissions/usersPermissionsCQB';
import { DecodedToken } from "../../models/user";
import jwtDecode from "jwt-decode";
import { Context } from '../../context/AuthContext'
import { CqbDto, CqbEquipeTecnica, CqbLocal } from "../../models/modelsCQB";


export default class CqbService {
    baseUrl: string = '/api/v1/Cqb';
    urlEquiTecnica: string = `${this.baseUrl}/EquipeTecnica`;
    urlEquiCiBio: string = `${this.baseUrl}/EquipeCIBIO`;
    urlLocais: string = `${this.baseUrl}/Local`;
    urlProjeto: string = `${this.baseUrl}/Projeto`;
    urlConfigs: string = `${this.baseUrl}/Configs`;

    public async getList(filtro:any = null){
      const { data, status } = await api.post<CqbDto[]>(`${this.baseUrl}/Cqb`, filtro);
        if ([200,201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public async getCQBById(id: any){
      const { data, status } = await api.get<CqbDto>(`${this.baseUrl}/Cqb/${id}`);
        if ([200,201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public async getCQBAction(acao:string, dto:any){
      const { data, status } = await api.post(`${this.baseUrl}/Cqb/action/${acao}`, dto);
        if ([200,201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
      
    public async DownloadCQBPDF(id: number, documentFileName:string){
        try {
        const { data, headers } = await apiFileDownload.get(`${this.baseUrl}/Cqb/document/pdf/${id}`);
        let blob = new Blob([data], { type: 'application/pdf' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        if (typeof a.download === "undefined") { 	window.location.href = url; }
        else {
          a.href = url; a.download = documentFileName ?? `cqb_${id}`;
          document.body.appendChild(a);
          a.click();
        }
      } catch (error:any) {
        console.error(error);
        throw new Error(error);       
      }
    }

    public async getCQBSaveFiles(id: number, dto:FormData){
      const { data, status } = await apiFile.post(`${this.baseUrl}/${id}`, dto);
        if ([200,201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }

    /******** Api's para Tratamento de Dados Pré-Configurados *********/

    public  async getConfigs (filter:any = null){
      const { data, status } = await api.post<any[]>(`${this.urlConfigs}`, filter);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }
    public  async getConfigsById (id: any){
      const { data, status } = await api.get<any>(`${this.urlConfigs}/${id}`);
        if ([200,201].indexOf(status) <= -1)
        throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public  async getConfigsSave (values:any){
      const { data, status } = await api.post<any[]>(`${this.urlConfigs}/save-form-data`, values);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }

    public async getDefaultData(){
      const { data, status } = await api.get<any>(`${this.urlConfigs}/GetConfigData/all`);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }

     /******* API's Para Tratamento de Locais para o LPMA *******/
     public  async getLocais (filter:any = null){
      if(!filter) filter =  {id: 0,ativo: true, } as CqbLocal;
      const { data, status } = await api.post<CqbLocal[]>(`${this.urlLocais}`, filter);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
  }
  public  async getLocaisById (id: any){
    const { data, status } = await api.get<CqbLocal>(`${this.urlLocais}/${id}`);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
  }
  public  async getLocaisSave (values:any){
    const { data, status } = await api.post<CqbLocal[]>(`${this.urlLocais}/save`, values);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }
  /****** API's para Tratamento das Equipe Tecnicas *******/
  public  async getEquipeTecnica (filter:any = null){
     if(!filter) filter =  {id: 0,ativo: true} as CqbEquipeTecnica;
      const { data, status } = await api.post<CqbEquipeTecnica[]>(`${this.urlEquiTecnica}`, filter);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
  }
  public  async getEquipeTecnicaById (id: any){
    const { data, status } = await api.get<CqbEquipeTecnica>(`${this.urlEquiTecnica}/${id}`);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
  }
  public  async getEquipeTecnicaSaveFormData (values:any){
    const { data, status } = await api.post<CqbEquipeTecnica[]>(`${this.urlEquiTecnica}/save-form-data`, values);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }

  public  async getEquipeCIBIOLoad (){
    const { data, status } = await api.post<CqbEquipeTecnica[]>(`${this.urlEquiCiBio}`, {ativo: true, id: 0} as CqbEquipeTecnica);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }

  public  async getEquipeCIBIOSave (values:any){
    const { data, status } = await api.post<CqbEquipeTecnica[]>(`${this.urlEquiCiBio}/savelist`, values);
    if ([200,201].indexOf(status) <= -1)
    throw new Error('Erro ao obter resposta do servidor!');
    return data;
  }
}

export class RolesService {
  conext:   any;
  decode:   DecodedToken;
  roles:    any;
  constructor(_useContext:any) {
      this.conext = _useContext(Context);;//Context;//React.createContext(Context);// useContext(Context); };
      this.decode = jwtDecode(this.conext.token.access_token);
      this.roles  = this.decode.roles;
  }
  public getUserName(){
    //const { roles, unique_name }: DecodedToken = jwtDecode(token);
    return this.decode?.unique_name;
  }
  public isCreatRequisicao(){
    return isCQBCheckRoles(this.roles, CQB_PERMISSIONS.CriarRequisicao)||
	  isCQBCheckRoles(this.roles, CQB_PERMISSIONS.Admin) 
  }
  
  public isEditRequisicao() {
		return isCQBCheckRoles(this.roles, CQB_PERMISSIONS.EditarRequisicao) ||
		isCQBCheckRoles(this.roles, CQB_PERMISSIONS.Admin) 
	}

  public isCreatOREditRequisicao() {
    return this.isCreatRequisicao() || this.isEditRequisicao();
  }
	
  public isRegulatorio() {
		return isCQBCheckRoles(this.roles, CQB_PERMISSIONS.Regulatorio)||
		isCQBCheckRoles(this.roles, CQB_PERMISSIONS.Admin) 
	}

  public isAdmin(){
    return isCQBCheckRoles(this.roles, CQB_PERMISSIONS.Admin);
  }

}