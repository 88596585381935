import React, { useState, useEffect, useContext, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Col } from 'react-bootstrap';
import AddButton from '../../../components/AddButton';
import Button from '../../../components/Button';
import GenericButton from '../../../components/FlowGenericButton';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import LoadingInForm from '../../../components/LoadingInForm';
import NotAvailableModel from '../../../components/NotAvailableModel';
import { STRINGS } from '../../../util/strings';
import {
	RegisterRNCForm,
	CultureResults,
	RecordTypesResults,
	ApplicantResults,
	EventsResult,
	BreederResults,
	InstitutionResults,
	FilesTypeResults,
	DescriptorsResults,
	CharacteristicsDescriptors,
	RegionsResults,
	DiseasesReactionsResults,
	DiseasesResults,
	RequestRegisterDisease,
	ReactionsDiseasesResult,
	RequestRegisterDescriptors,
	RequestRegisterMelhoristas,
	RequestRegisterApplicants,
	RequestRegisterRegions,
	ExpectedDescriptors,
	ExpectedDisease,
	ModelFileRegister,
	ModelsFiles,
	DescritoresResponseRegistro
} from '../../../models/models';
import api, { apiFileDownload } from '../../../services/api';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Group_10 from './group_10';
import Group_12 from './group_12';
import { isAdmin } from '../../../users/isAdmin';
import { isReguladorRegister } from '../../../users/isRegulador';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../../../models/user';
import { isDescriptors } from '../../../users/isDescriptors';
import { isDiseases } from '../../../users/isDiseases';
import '../styles_add.css';
import { Context } from '../../../context/AuthContext'

export default function RegisterRNC() {
	const history = useHistory();
	const { token } = useContext(Context);
	const { roles, unique_name }: DecodedToken = jwtDecode(token.access_token);
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [protectedCultivar, setProtectedCultivar] = useState<boolean>(false);
	const [transferredCultivar, setTransferredCultivar] = useState<boolean>(false);
	const [foreignCultivar, setForeignCultivar] = useState<boolean>(false);
	const [essentiallyDerived, setEssentiallyDerived] = useState<boolean>(false);
	const [modifiedOrganism, setModifiedOrganism] = useState<boolean>(false);
	const [chooseApplicant, setChooseApplicant] = useState<boolean>(false);
	const [selectedApplicants, setSelectedApplicants] = useState<Array<ApplicantResults>>([]);
	const [unselectedApplicants, setUnselectedApplicants] = useState<Array<ApplicantResults>>([]);
	const [choosenApplicants, setChoosenApplicants] = useState<Array<ApplicantResults>>([]);
	const [inserApplicants, setInsertApplicants] = useState<boolean>(false);
	const [insertDescriptors, setInsertDescriptors] = useState<boolean>(false);
	const [insertDiseases, setInsertDiseases] = useState<boolean>(false);
	const [choosenDescriptors, setChoosenDescriptors] = useState<boolean>(false);
	const [descriptorsByCulture, setDescriptorsByCulture] = useState<Array<DescriptorsResults>>([]);
	const [characteristicsByDescriptor, setCharacteristicsByDescriptor] = useState<Array<CharacteristicsDescriptors>>([]);
	const [selectedDescriptors, setSelectedDescriptors] = useState<Array<RequestRegisterDescriptors>>([]);
	const [choosenDiseases, setChoosenDiseases] = useState<boolean>(false);
	const [diseasesByCulture, setDiseasesByCulture] = useState<Array<DiseasesResults>>([]);
	const [diseasesReactions, setDiseasesReactions] = useState<Array<DiseasesReactionsResults>>([]);
	const [selectedDiseasesOrNematodes, setSelectedDiseasesOrNematodes] = useState<Array<RequestRegisterDisease>>([]);
	const [selectedFilesAdditionals, setSelectedFilesAdditionals] = useState<Array<object>>([]);
	const [labelAdditionalFiles, setLabelAdditionalFiles] = useState('Escolher arquivo');
	const [lblAdaptationRegions, setLblAdaptationRegions] = useState('Escolher arquivo');
	const [lblAgronomicCharacteristics, setLblAgronomicCharacteristics] = useState('Escolher arquivo');
	const [lblProductivityAssessment, setLblProductivityAssessment] = useState('Escolher arquivo');
	const [lblQualityAssessment, setLblQualityAssessment] = useState('Escolher arquivo');
	const [lblTechnicalObtainingReport, setLblTechnicalObtainingReport] = useState('Escolher arquivo');
	const [lblBreederStatement, setLblBreederStatement] = useState('Escolher arquivo');
	const [insertAdaptationRegions, setInsertAdaptationRegions] = useState<boolean>(false);
	const [chooseAdaptationRegions, setChooseAdaptationRegions] = useState<boolean>(false);
	const [unselectedAdaptationRegions, setUnselectedAdaptationRegions] = useState<Array<RegionsResults>>([]);
	const [choosenAdaptationRegions, setChoosenAdaptationRegions] = useState<Array<RegionsResults>>([]);
	const [selectedAdaptationRegions, setSelectedAdaptationRegions] = useState<Array<RegionsResults>>([]);
	const [chooseBreeder, setChooseBreeder] = useState<boolean>(false);
	const [insertBreeder, setInsertBreeder] = useState<boolean>(false);
	const [selectedBreeder, setSelectedBreeder] = useState<Array<BreederResults>>([]);
	const [unselectedBreeder, setUnselectedBreeder] = useState<Array<BreederResults>>([]);
	const [choosenBreeder, setChoosenBreeder] = useState<Array<BreederResults>>([]);
	const [submited, setSubmited] = useState<boolean>(false);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [culture, setCulture] = useState<CultureResults[]>([]);
	const [cultureDetails, setCultureDetails] = useState<CultureResults>();
	const [recordsType, setRecordsType] = useState<RecordTypesResults[]>([]);
	const [applicants, setApplicants] = useState<ApplicantResults[]>([]);
	const [events, setEvents] = useState<EventsResult[]>([]);
	const [breeders, setBreeders] = useState<BreederResults[]>([]);
	const [regions, setRegions] = useState<RegionsResults[]>([]);
	const [institutions, setInstitutions] = useState<InstitutionResults[]>([]);
	const [selectedInstitution, setSelectedInstitution] = useState<InstitutionResults>();
	const [filesType, setFilesType] = useState<FilesTypeResults[]>([]);
	const [loadingNeededData, setLoadingNeededData] = useState<boolean>(true);
	const [errorGetNeededData, setErrorGetNeededData] = useState<boolean>(false);
	const [fieldApplicantResponsible, setFieldApplicantResponsible] = useState<boolean>(false);
	const [fieldInstitution, setFieldInstitution] = useState<boolean>(false);
	const [fieldCultivarOrigin, setFieldCultivarOrigin] = useState<boolean>(false);
	const [fieldCultivarRate, setFieldCultivarRate] = useState<boolean>(false);
	const [fieldDescriptors, setFieldDescriptors] = useState<boolean>(false);
	const [fieldDiseaseOrNematode, setFieldDiseaseOrNematode] = useState<boolean>(false);
	const [fieldAgronomicCharacteristics, setFieldAgronomicCharacteristics] = useState<boolean>(false);
	const [fieldProductivity, setFieldProductivity] = useState<boolean>(false);
	const [fieldQuality, setFieldQuality] = useState<boolean>(false);
	const [fieldCommercialization, setFieldCommercialization] = useState<boolean>(false);
	const [fieldProductionSystem, setFieldProductionSystem] = useState<boolean>(false);
	const [fieldLimitationCultivar, setFieldLimitationCultivar] = useState<boolean>(false);
	const [fieldAdditionalInformation, setFieldAdditionalInformation] = useState<boolean>(false);
	const [descriptorName, setDescriptorName] = useState<string>('');
	const [descriptorCharacteristic, setDescriptorCharacteristic] = useState<string>('');
	const [diseaseName, setDiseaseName] = useState<string>('');
	const [diseaseReaction, setDiseaseReaction] = useState<string>('');
	const [diseaseReactionGreenHouse, setDiseaseReactionGreenHouse] = useState<string>('');
	const [recordTypeName, setRecordTypeName] = useState<string>('');
	const [selectedEvent, setSelectedEvent] = useState<EventsResult>();
	const [specieName, setSpecieName] = useState<string>('');
	const [models, setModels] = useState<ModelsFiles[]>([]);
	const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [successFiles, setSuccessFiles] = useState<boolean>(false);
	const [additionalFiles, setAdditionalFiles] = useState<any[]>([]);
	const [successAdditionalFiles, setSuccessAdditionalFiles] = useState<boolean>(false);
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);
	const [regulatoryModal, setRegulatoryModal] = useState<boolean>(false);
	const [successRegulatory, setSuccessRegulatory] = useState<boolean>(false);
	const [errorRegulatory, setErrorRegulatory] = useState<any>(null);
	const [filteredModels, setFilteredModels] = useState<ModelsFiles>();
	const [filteredRecords, setFilteredRecords] = useState<RecordTypesResults[]>([]);
	const [isDraft, setIsDraft] = useState<boolean>(false);
	const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
	const [errorOnForm, setErrorOnForm] = useState<boolean>(false);
	const [selectedCulture, setSelectedCulture] = useState<string>('');

	var formDataFiles = new FormData();
	var formDataAdditionalFiles = new FormData();

	const conditionalCultivarCountry = protectedCultivar ? {
		cultivarCountry: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarCountry: yup
			.string()
	}

	const conditionalCultivarCertificateNumber = protectedCultivar ? {
		cultivarCertificateNumber: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarCertificateNumber: yup
			.string()
	}

	const conditionalCultivarNameOfTransferor = transferredCultivar ? {
		cultivarNameOfTransferor: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarNameOfTransferor: yup
			.string()
	}

	const conditionalCultivarInstrumentUsed = transferredCultivar ? {
		cultivarInstrumentUsed: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarInstrumentUsed: yup
			.string()
	}

	const conditionalCultivarCountryOfOrigin = foreignCultivar ? {
		cultivarCountryOfOrigin: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarCountryOfOrigin: yup
			.string()
	}

	const conditionalCultivarImportCountry = foreignCultivar ? {
		cultivarImportCountry: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarImportCountry: yup
			.string()

	}

	const conditionalCultivarImportProcess = foreignCultivar ? {
		cultivarImportProcess: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarImportProcess: yup
			.string()

	}

	const conditionalDerivedCultivar = essentiallyDerived ? {
		derivedCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		derivedCultivar: yup
			.string()
	}

	const conditionalCultivarGeneticTransformationEvent = modifiedOrganism ? {
		cultivarGeneticTransformationEvent: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarGeneticTransformationEvent: yup
			.string()
	}

	const conditionalDescriptorRegisterRNC = insertDescriptors ? {
		descriptorRegisterRNC: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		descriptorRegisterRNC: yup
			.string()
	}

	const conditionalCharacteristicRegisterRNC = insertDescriptors ? {
		characteristicRegisterRNC: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		characteristicRegisterRNC: yup
			.string()
	}

	const conditionalDiseaseOrNematode = insertDiseases ? {
		diseaseOrNematode: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		diseaseOrNematode: yup
			.string()
	}

	const conditionalGreenhouseReactionDisease = insertDiseases ? {
		greenhouseReactionDisease: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		greenhouseReactionDisease: yup
			.string()
	}

	const conditionalCountrysideReactionDisease = insertDiseases ? {
		countrysideReactionDisease: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		countrysideReactionDisease: yup
			.string()
	}

	const conditionalInstitutionName = fieldInstitution ? {
		institutionName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		institutionName: yup
			.string()
	}

	const conditionalResponsibleTechnician = fieldInstitution ? {
		responsibleTechnician: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
	} : {
		responsibleTechnician: yup
			.string()
	}

	const conditionalInstitutionCompany = fieldCultivarOrigin ? {
		institutionCompany: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		institutionCompany: yup
			.string()
	}

	const conditionalBreederParticipant = fieldCultivarOrigin ? {
		breederParticipant: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		breederParticipant: yup
			.string()
	}

	const conditionalCrossingYear = fieldCultivarOrigin ? {
		crossingYear: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		crossingYear: yup
			.string()
	}

	const conditionalCrossingPlace = fieldCultivarOrigin ? {
		crossingPlace: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		crossingPlace: yup
			.string()
	}

	const conditionalCrossingInstitution = fieldCultivarOrigin ? {
		crossingInstitution: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		crossingInstitution: yup
			.string()
	}

	const conditionalGenealogy = fieldCultivarOrigin ? {
		genealogy: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		genealogy: yup
			.string()
	}

	const conditionalDesignation = fieldCultivarOrigin ? {
		designation: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		designation: yup
			.string()
	}

	const conditionalYearOfcommercialization = fieldCommercialization ? {
		yearOfcommercialization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		yearOfcommercialization: yup
			.string()
	}

	const conditionalExperimentalMaterial = fieldCommercialization ? {
		experimentalMaterial: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		experimentalMaterial: yup
			.string()
	}

	const conditionalProductionSystemsForTheCultivar = fieldProductionSystem ? {
		productionSystemsForTheCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		productionSystemsForTheCultivar: yup
			.string()
	}

	const conditionalLimitationCultivar = fieldLimitationCultivar ? {
		limitationsOfTheCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		limitationsOfTheCultivar: yup
			.string()
	}

	const conditionalAdditionalInformation = fieldAdditionalInformation ? {
		additionalInformation: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		additionalInformation: yup
			.string()
	}

	const conditionalSpeciesName = isDraft ? {
		speciesName: yup
			.string()
	} : {
		speciesName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	}

	const conditionalRegisterType = isDraft ? {
		registerType: yup
			.string()
	} : {
		registerType: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	}

	const conditionalNameCultivar = isDraft ? {
		nameCultivar: yup
			.string()
	} : {
		nameCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	}

	const registerRNCSchema = yup.object().shape({
		all_descriptors							: yup.string().test("all-descriptors", "Todos os descritores precisam ser inclusos", value => {
			return !fieldDescriptors || selectedDescriptors.length > 0 && selectedDescriptors.length == cultureDetails?.descritores?.length;
		}),
		all_diseases_or_nematoides				: yup.string().test("all-diseases", "Todas as doenças e nematóides precisam ser inclusas", value => {
			return !fieldDiseaseOrNematode || selectedDiseasesOrNematodes.length > 0 && selectedDiseasesOrNematodes.length == cultureDetails?.doencas?.length;
		}),
		ratePlaces								: yup.mixed().test("file-rateplaces", "Você precisa enviar um arquivo", value => {

			if (!fieldCultivarRate)
				return true;
			
			return value && value.length > 0;
		
		}),
		productivityAssessment					: yup.mixed().test("file-productivityassessment", "Você precisa enviar um arquivo", value => {

			if (!fieldProductivity)
				return true;
			
			return value && value.length > 0;
		
		}),
		qualityAssessment						: yup.mixed().test("file-qualityassessment", "Você precisa enviar um arquivo", value => {

			if (!fieldQuality)
				return true;
			
			return value && value.length > 0;
		
		}),
		technicalObtainingReport				: yup.mixed().test("file-qualityassessment", "Você precisa enviar um arquivo", value => {

			return value && value.length > 0;
		
		}),
		breederStatement						: yup.mixed().test("file-qualityassessment", "Você precisa enviar um arquivo", value => {

			return value && value.length > 0;
		
		}),
		...conditionalSpeciesName,
		scientificName: yup
			.string(),
		speciesGroup: yup
			.string(),
		...conditionalRegisterType,
		...conditionalNameCultivar,
		...conditionalInstitutionName,
		...conditionalResponsibleTechnician,
		...conditionalCultivarCountry,
		...conditionalCultivarCertificateNumber,
		...conditionalCultivarNameOfTransferor,
		...conditionalCultivarInstrumentUsed,
		...conditionalCultivarCountryOfOrigin,
		...conditionalCultivarImportCountry,
		...conditionalCultivarImportProcess,
		...conditionalDerivedCultivar,
		...conditionalCultivarGeneticTransformationEvent,
		...conditionalInstitutionCompany,
		...conditionalBreederParticipant,
		...conditionalCrossingYear,
		...conditionalCrossingPlace,
		...conditionalCrossingInstitution,
		...conditionalGenealogy,
		...conditionalDesignation,
		...conditionalDescriptorRegisterRNC,
		...conditionalCharacteristicRegisterRNC,
		...conditionalDiseaseOrNematode,
		diseaseOrNematodeNote: yup.string(),
		...conditionalCountrysideReactionDisease,
		...conditionalGreenhouseReactionDisease,
		...conditionalYearOfcommercialization,
		...conditionalExperimentalMaterial,
		...conditionalProductionSystemsForTheCultivar,
		...conditionalLimitationCultivar,
		...conditionalAdditionalInformation,
		textoAvaliacaoCultivar: yup.mixed(),
		textoCicloAgronomico: yup.string(),
		fileType: yup.string(),
		additionalFiles: yup.mixed(),
		agronomicCharacteristics: yup.mixed(),
	})

	const defaultForm = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(registerRNCSchema)
	})

	useEffect(() => {
		const getDataNeeded = async () => {
			try {
				const responseCulture = await api.get<CultureResults[]>('api/v1/cultivares/Cultura/obterporfiltro?Ativo=true');
				const responseRecordsType = await api.get<RecordTypesResults[]>('api/v1/cultivares/TipoRegistro/obterporfiltro?Ativo=true');
				const responseApplicants = await api.get<ApplicantResults[]>('api/v1/cultivares/Requerente/obterporfiltro?Ativo=true');
				const responseEvents = await api.get<EventsResult[]>('api/v1/cultivares/Evento/obterporfiltro?Ativo=true');
				const responseBreeders = await api.get<BreederResults[]>('api/v1/cultivares/EquipeTecnicaMelhorista/obterporfiltro?Ativo=true');
				const responseInstitutions = await api.get<InstitutionResults[]>('api/v1/Instituicao');
				const responseFilesType = await api.get<FilesTypeResults[]>('api/v1/TipoDeAnexo');

				if (responseCulture.status === 200) {
					setCulture(responseCulture.data);
				}
				if (responseRecordsType.status === 200) {
					setRecordsType(responseRecordsType.data);
					setFilteredRecords(responseRecordsType.data);
				}
				if (responseApplicants.status === 200) {
					setApplicants(responseApplicants.data);
				}
				if (responseEvents.status === 200) {
					setEvents(responseEvents.data);
				}
				if (responseBreeders.status === 200) {
					setBreeders(responseBreeders.data);
				}
				if (responseInstitutions.status === 200) {
					setInstitutions(responseInstitutions.data);
				}
				if (responseFilesType.status === 200) {
					setFilesType(responseFilesType.data);
				}
			} catch (error:any) {
				setErrorGetNeededData(true);
			}
			setLoadingNeededData(false);
		}
		getDataNeeded();
	}, []);

	const verifyErrorsOnForm = (): boolean => {
		let ratePlaces = defaultForm.getValues("ratePlaces");
		let productivityAssessment = defaultForm.getValues("productivityAssessment");
		let qualityAssessment = defaultForm.getValues("qualityAssessment");
		let technicalObtainingReport = defaultForm.getValues("technicalObtainingReport");
		let breederStatement = defaultForm.getValues("breederStatement");
		let agronomicCharacteristics = defaultForm.getValues("agronomicCharacteristics");

		if (defaultForm.errors.speciesName ||
			defaultForm.errors.registerType ||
			defaultForm.errors.nameCultivar ||
			defaultForm.errors.institutionName ||
			defaultForm.errors.responsibleTechnician ||
			defaultForm.errors.institutionCompany ||
			defaultForm.errors.breederParticipant ||
			defaultForm.errors.crossingYear ||
			defaultForm.errors.crossingPlace ||
			defaultForm.errors.crossingInstitution ||
			defaultForm.errors.genealogy ||
			defaultForm.errors.designation ||
			defaultForm.errors.productionSystemsForTheCultivar ||
			defaultForm.errors.limitationsOfTheCultivar ||
			(submited && ((fieldDescriptors && !selectedDescriptors.length) ||
				(fieldDiseaseOrNematode && !selectedDiseasesOrNematodes.length) ||
				!selectedApplicants.length ||
				(!selectedBreeder.length && fieldApplicantResponsible) ||
				(fieldCultivarRate && (!ratePlaces.length || (!selectedAdaptationRegions.length || selectedAdaptationRegions.length === 0))) ||
				(fieldAgronomicCharacteristics && !agronomicCharacteristics.length) ||
				(fieldProductivity && !productivityAssessment.length) ||
				(fieldQuality && !qualityAssessment.length) ||
				!technicalObtainingReport.length ||
				!breederStatement.length))
		) {
			return true;
		}
		return false;
	}

	const setScientificName = (cultureId: number) => {
		const scientificName = culture.filter((item => {
			return item.id === Number(cultureId);
		}))

		let result = scientificName[0];
		if (!!result)
			defaultForm.setValue('scientificName', result.descricao);
	}

	const setUnselectedBreederList = (selectedBreeder: BreederResults[]) => {
		const breeder = breeders.filter((item => {
			return !selectedBreeder.includes(item);
		}))
		setUnselectedBreeder(breeder);
	}

	const setRespectiveCharacteristics = (descriptorId: number) => {
		const characteristics = descriptorsByCulture.filter(desc => {
			return desc.id === Number(descriptorId);
		})
		let { caracteristicasDescritores } = characteristics[0];
//		caracteristicasDescritores = caracteristicasDescritores.sort((a, b) => a.descricao.localeCompare(b.descricao));
		setCharacteristicsByDescriptor(caracteristicasDescritores);
	}

	const setRespectiveReactionsDiseases = (diseaseId: number) => {
		const reactions = diseasesByCulture.filter(item => {
			return item.id === Number(diseaseId);
		})
		let { reacoesDoencas } = reactions[0];
		setDiseasesReactions(reacoesDoencas);
	}

	const onRowSelectedApplicant = ({ api }: any) => {
		const data = api.getSelectedRows();

		setChoosenApplicants(data);
	}

	const onRowSelectedBreeder = ({ api }: any) => {
		const data = api.getSelectedRows();

		setChoosenBreeder(data);
	}

	const onRowSelectedAdaptationRegions = ({ api }: any) => {
		const data = api.getSelectedRows();

		setChoosenAdaptationRegions(data);
	}

	const setUnselectedApplicant = (selectedApplicant: ApplicantResults[]) => {
		let arr1: ApplicantResults[] = [];
		for (let count = 0, countLen = applicants.length; count < countLen; count++) {
			const result: ApplicantResults = {
				id: Number(applicants[count].id),
				nome: String(applicants[count].nome),
				ativo: Boolean(applicants[count].ativo),
				cnpj: String(applicants[count].cnpj),
				endereco: String(applicants[count].endereco),
				cep: String(applicants[count].cep),
				municipio: String(applicants[count].municipio),
				email: String(applicants[count].email),
				pais: String(applicants[count].pais),
				telefone: String(applicants[count].telefone),
				uf: String(applicants[count].uf)
			}
			let exist = selectedApplicant.some(c => c.id === applicants[count].id);
			if (exist === false)
				arr1.push(result);
		}
		setUnselectedApplicants(arr1);
	}

	const setUnselectedAdaptationRegion = (regionsSelected: RegionsResults[]) => {
		const region = regions.filter((item => {
			return !regionsSelected.includes(item);
		}))
		setUnselectedAdaptationRegions(region);
	}

	const setSelectedDescriptorsList = () =>
	{
		defaultForm.trigger(["descriptorRegisterRNC", "characteristicRegisterRNC"])
		.then(validated => {

			if (!validated)
				return;

			setInsertDescriptors(false);
			setChoosenDescriptors(true);

			let descriptors: RequestRegisterDescriptors = {
				descritoresId: Number(defaultForm.getValues("descriptorRegisterRNC")),
				caracteristicasDescritoresId: Number(defaultForm.getValues("characteristicRegisterRNC")),
				descricao: descriptorName,
				caracteristica: descriptorCharacteristic,
				observacao: defaultForm.getValues('descriptorNote')
			}

			setSelectedDescriptors([...selectedDescriptors, descriptors]);
		});
	}

	const setSelectedDiseasesOrNematodesList = () => {
		defaultForm.trigger(['diseaseOrNematode', 'greenhouseReactionDisease', 'countrysideReactionDisease'])
		.then(validated => {

			if (!validated)
				return;
				
			let diseases: RequestRegisterDisease = {
				doencasId: Number(defaultForm.getValues('diseaseOrNematode')),
				reacaoDoencaCampoId: Number(defaultForm.getValues('greenhouseReactionDisease')),
				reacaoDoencaCasaVegetacaoId: Number(defaultForm.getValues('countrysideReactionDisease')),
				observacao: defaultForm.getValues('diseaseOrNematodeNote'),
				diseaseName: diseaseName,
				diseaseReaction: diseaseReaction,
				diseaseReactionGreenHouse: diseaseReactionGreenHouse
			}
			setInsertDiseases(false);
			setChoosenDiseases(true);
			setSelectedDiseasesOrNematodes([...selectedDiseasesOrNematodes, diseases]);
		});
	}

	const setSelectedFilesAdditionalsList = () => {
		const file = {
			name: defaultForm.getValues('additionalFiles')[0].name
		}
		setSelectedFilesAdditionals([...selectedFilesAdditionals, file]);
	}

	const setArrFilesToSubmit = (file: any) => {
		setAdditionalFiles([...additionalFiles, {
			arquivo: file[0],
		}])
	}

	const actionDescriptors = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setSelectedDescriptors([]);
					let arr: any = [];

					const { node } = props;
					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));

					setSelectedDescriptors([...arr]);
					giveBackDeletedDescriptor();
				}}
			/>
		)
	}

	const actionDiseases = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setSelectedDiseasesOrNematodes([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setSelectedDiseasesOrNematodes([...arr]);
					giveBackDiseases();
				}}
			/>
		)
	}

	const actionApplicants = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setSelectedApplicants([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setSelectedApplicants([...arr]);
				}}
			/>
		)
	}

	const actionBreeder = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setSelectedBreeder([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setSelectedBreeder([...arr]);
				}}
			/>
		)
	}

	const actionAdditionalFiles = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setSelectedFilesAdditionals([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setSelectedFilesAdditionals([...arr]);
				}}
			/>
		)
	}

	const actionAdaptationRegions = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setSelectedAdaptationRegions([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setSelectedAdaptationRegions([...arr]);
				}}
			/>
		)
	}

	const setInstitutionSelected = (institutionId: number) => {
		const institution = institutions.filter(item => {
			return item.id === Number(institutionId);
		})

		setSelectedInstitution(institution[0]);
	}

	const setEventSelected = (eventId: number) => {
		const event = events.filter(item => {
			return item.id === Number(eventId);
		})

		setSelectedEvent(event[0]);
	}

	const onSubmit = async () =>
	{
		setLoadingSubmit(true);
		setErrorSubmit(null);
		
		try
		{
			let data = {
				culturaId: cultureDetails?.id,
				nomeComumDaEspecie: specieName,
				nomeCientificoDaEspecie: defaultForm.getValues('scientificName'),
				grandesEspecie: defaultForm.getValues('speciesGroup'),
				tipoRegistroId: Number(defaultForm.getValues('registerType')),
				tipoRegistroNome: recordTypeName,
				denominacaoDaCultivarLinhagem: defaultForm.getValues('nameCultivar'),
				instituicaoId: Number(defaultForm.getValues('institutionName')),
				nome: selectedInstitution?.nome,
				cnpj: selectedInstitution?.cnpj,
				endereco: selectedInstitution?.endereco,
				cep: selectedInstitution?.cep,
				municipio: selectedInstitution?.municipio,
				uf: selectedInstitution?.uf,
				paisInstituicao: selectedInstitution?.pais,
				telefone: selectedInstitution?.telefone,
				email: selectedInstitution?.email,
				tecnicoResponsavelPeloEnsaio: defaultForm.getValues('responsibleTechnician'),
				status: 1,
				cultivarProtegida: protectedCultivar,
				pais: defaultForm.getValues('cultivarCountry'),
				numeroDoCertificadoDeProtecao: defaultForm.getValues('cultivarCertificateNumber'),
				cultivarTransferida: transferredCultivar,
				nomeDoCedente: defaultForm.getValues('cultivarNameOfTransferor'),
				instrumentoUltilizadoParaTransferencia: defaultForm.getValues('cultivarInstrumentUsed'),
				cultivarEstrangeira: foreignCultivar,
				paisDeOrigem: defaultForm.getValues('cultivarCountryOfOrigin'),
				paisDeImportacaoProcedencia: defaultForm.getValues('cultivarImportCountry'),
				processoDeImportacao: defaultForm.getValues('cultivarImportProcess'),
				cultivarEssencialmenteDerivada: essentiallyDerived,
				deQualCultivarEDerivada: defaultForm.getValues('derivedCultivar'),
				cultivarGeneticamenteModificada: modifiedOrganism,
				eventoId: selectedEvent?.id,
				nomeDoEvento: selectedEvent?.nomeEvento,
				instituicaoEmpresaObtentoraIntrodutorasDetentora: defaultForm.getValues('institutionCompany'),
				melhoriasParticipanteNaObtencao: defaultForm.getValues('breederParticipant'),
				anoDeRealizacao: Number(defaultForm.getValues('crossingYear')),
				local: defaultForm.getValues('crossingPlace'),
				instituicaoQueRealizou: defaultForm.getValues('crossingInstitution'),
				genealogia: defaultForm.getValues('genealogy'),
				denominacaoExperimentalOuPreComercial: defaultForm.getValues('designation'),
				inicioDeComercializacao: Number(defaultForm.getValues('yearOfcommercialization')),
				quantidadeEmEstoqueMaterialExperimental: Number(defaultForm.getValues('experimentalMaterial')),
				sistemaDeProducaoSugeridos: defaultForm.getValues('productionSystemsForTheCultivar'),
				limitacoesDaCultivar: defaultForm.getValues('limitationsOfTheCultivar'),
				informacoesAdicionais: defaultForm.getValues('additionalInformation'),
				usuario: unique_name,
				cultivaresRequerentes: expectedApplicants(),
				cultivaresDescritores: expectedDescriptors(),
				cultivaresDoencas: expectedDiseases(),
				cultivaresCulturaRegioes: expectedRegions(),
				cultivaresEquipeTecnicas: expectedBreeders(),
				observacoes: defaultForm.getValues('generalObservations'),
				textoAvaliacaoCultivar: defaultForm.getValues('textoAvaliacaoCultivar'),
				textoCicloAgronomico: defaultForm.getValues('textoCicloAgronomico'),

				caracteristicasAlgodaoCicloColheita: defaultForm.getValues('caracteristicasAlgodaoCicloColheita'),
				caracteristicasAlgodaoCicloFlorescimento: defaultForm.getValues('caracteristicasAlgodaoCicloFlorescimento'),
				caracteristicasAlgodaoPercentagemFibras: Number(defaultForm.getValues('caracteristicasAlgodaoPercentagemFibras')),
				caracteristicasAlgodaoPesoCapulho: Number(defaultForm.getValues('caracteristicasAlgodaoPesoCapulho')),
				caracteristicasAlgodaoPesoCemSementes: Number(defaultForm.getValues('caracteristicasAlgodaoPesoCemSementes')),
				caracteristicasAlgodaoPrecocidadeMaturacao: defaultForm.getValues('caracteristicasAlgodaoPrecocidadeMaturacao'),
				caracteristicasAlgodaoRetencaoPluma: defaultForm.getValues('caracteristicasAlgodaoRetencaoPluma'),

				caracteristicasArrozCicloMaturacao: defaultForm.getValues('caracteristicasArrozCicloMaturacao'),
				caracteristicasArrozComportamentoAcamamento: defaultForm.getValues('caracteristicasArrozComportamentoAcamamento'),
				caracteristicasArrozComportamentoDegrane: defaultForm.getValues('caracteristicasArrozComportamentoDegrane'),
				caracteristicasArrozDiasSemeadura: defaultForm.getValues('caracteristicasArrozDiasSemeadura'),
				caracteristicasArrozPesoMilSementes: Number(defaultForm.getValues('caracteristicasArrozPesoMilSementes')),
				caracteristicasArrozSistemaCultivo: defaultForm.getValues('caracteristicasArrozSistemaCultivo'),

				arquivosParaExcluir: [],
			};

						
			const request = await api.post('api/v1/cultivares/CultivaresRegistro', data)

			if (request.status === 201) {
				setSuccessfulResponse(true);
				await onSubmitFiles(request.data);
				await onSubmitAdditionalFiles(request.data);
				setLoadingSubmit(false);
				setErrorOnForm(false);

				sendToRegulatory(request.data.id);

				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}
		catch (error:any)
		{
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);
			setLoadingSubmit(false);
			setRegulatoryModal(false);
		}
	}

	const onSubmitDraft = async () =>
	{
		setLoadingDraft(true);
		setErrorDraft(null);

		try
		{
			let data = {
				culturaId: cultureDetails?.id,
				nomeComumDaEspecie: specieName,
				nomeCientificoDaEspecie: defaultForm.getValues('scientificName'),
				grandesEspecie: defaultForm.getValues('speciesGroup'),
				tipoRegistroId: defaultForm.getValues('registerType'),
				tipoRegistroNome: recordTypeName,
				denominacaoDaCultivarLinhagem: defaultForm.getValues('nameCultivar'),
				instituicaoId: Number(defaultForm.getValues('institutionName')),
				nome: selectedInstitution?.nome,
				cnpj: selectedInstitution?.cnpj,
				endereco: selectedInstitution?.endereco,
				cep: selectedInstitution?.cep,
				municipio: selectedInstitution?.municipio,
				uf: selectedInstitution?.uf,
				paisInstituicao: selectedInstitution?.pais,
				telefone: selectedInstitution?.telefone,
				email: selectedInstitution?.email,
				tecnicoResponsavelPeloEnsaio: defaultForm.getValues('responsibleTechnician'),
				status: 1,
				cultivarProtegida: protectedCultivar,
				pais: defaultForm.getValues('cultivarCountry'),
				numeroDoCertificadoDeProtecao: defaultForm.getValues('cultivarCertificateNumber'),
				cultivarTransferida: transferredCultivar,
				nomeDoCedente: defaultForm.getValues('cultivarNameOfTransferor'),
				instrumentoUltilizadoParaTransferencia: defaultForm.getValues('cultivarInstrumentUsed'),
				cultivarEstrangeira: foreignCultivar,
				paisDeOrigem: defaultForm.getValues('cultivarCountryOfOrigin'),
				paisDeImportacaoProcedencia: defaultForm.getValues('cultivarImportCountry'),
				processoDeImportacao: defaultForm.getValues('cultivarImportProcess'),
				cultivarEssencialmenteDerivada: essentiallyDerived,
				deQualCultivarEDerivada: defaultForm.getValues('derivedCultivar'),
				cultivarGeneticamenteModificada: modifiedOrganism,
				eventoId: selectedEvent?.id,
				nomeDoEvento: selectedEvent?.nomeEvento,
				instituicaoEmpresaObtentoraIntrodutorasDetentora: defaultForm.getValues('institutionCompany'),
				melhoriasParticipanteNaObtencao: defaultForm.getValues('breederParticipant'),
				anoDeRealizacao: Number(defaultForm.getValues('crossingYear')),
				local: defaultForm.getValues('crossingPlace'),
				instituicaoQueRealizou: defaultForm.getValues('crossingInstitution'),
				genealogia: defaultForm.getValues('genealogy'),
				denominacaoExperimentalOuPreComercial: defaultForm.getValues('designation'),
				inicioDeComercializacao: Number(defaultForm.getValues('yearOfcommercialization')),
				quantidadeEmEstoqueMaterialExperimental: Number(defaultForm.getValues('experimentalMaterial')),
				sistemaDeProducaoSugeridos: defaultForm.getValues('productionSystemsForTheCultivar'),
				limitacoesDaCultivar: defaultForm.getValues('limitationsOfTheCultivar'),
				informacoesAdicionais: defaultForm.getValues('additionalInformation'),
				usuario: unique_name,
				cultivaresRequerentes: expectedApplicants(),
				cultivaresDescritores: expectedDescriptors(),
				cultivaresDoencas: expectedDiseases(),
				cultivaresCulturaRegioes: expectedRegions(),
				cultivaresEquipeTecnicas: expectedBreeders(),
				observacoes: defaultForm.getValues('generalObservations'),
				textoAvaliacaoCultivar: defaultForm.getValues('textoAvaliacaoCultivar'),
				textoCicloAgronomico: defaultForm.getValues('textoCicloAgronomico'),

				caracteristicasAlgodaoCicloColheita: defaultForm.getValues('caracteristicasAlgodaoCicloColheita'),
				caracteristicasAlgodaoCicloFlorescimento: defaultForm.getValues('caracteristicasAlgodaoCicloFlorescimento'),
				caracteristicasAlgodaoPercentagemFibras: Number(defaultForm.getValues('caracteristicasAlgodaoPercentagemFibras')),
				caracteristicasAlgodaoPesoCapulho: Number(defaultForm.getValues('caracteristicasAlgodaoPesoCapulho')),
				caracteristicasAlgodaoPesoCemSementes: Number(defaultForm.getValues('caracteristicasAlgodaoPesoCemSementes')),
				caracteristicasAlgodaoPrecocidadeMaturacao: defaultForm.getValues('caracteristicasAlgodaoPrecocidadeMaturacao'),
				caracteristicasAlgodaoRetencaoPluma: defaultForm.getValues('caracteristicasAlgodaoRetencaoPluma'),

				caracteristicasArrozCicloMaturacao: defaultForm.getValues('caracteristicasArrozCicloMaturacao'),
				caracteristicasArrozComportamentoAcamamento: defaultForm.getValues('caracteristicasArrozComportamentoAcamamento'),
				caracteristicasArrozComportamentoDegrane: defaultForm.getValues('caracteristicasArrozComportamentoDegrane'),
				caracteristicasArrozDiasSemeadura: defaultForm.getValues('caracteristicasArrozDiasSemeadura'),
				caracteristicasArrozPesoMilSementes: Number(defaultForm.getValues('caracteristicasArrozPesoMilSementes')),
				caracteristicasArrozSistemaCultivo: defaultForm.getValues('caracteristicasArrozSistemaCultivo'),

				arquivosParaExcluir: [],
			};

			const request = await api.post('api/v1/cultivares/CultivaresRegistro/rascunho', data)

			if (request.status === 201)
			{
				setSuccessDraft(true);
				await onSubmitFiles(request.data);
				await onSubmitAdditionalFiles(request.data);
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setTimeout(() => {
					history.goBack();
				}, 2500);
			}
		}
		catch (error:any)
		{
			setErrorDraft(error.response.data);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
		setLoadingDraft(false);
	}

	const onSubmitFiles = async (cultivarRegistro?: any) =>
	{
		let fileModeloAvaliacaoCultivar = defaultForm.getValues('ratePlaces');
		let fileModeloCicloAgronomico = defaultForm.getValues('agronomicCharacteristics');
		let fileModeloProdutividade = defaultForm.getValues('productivityAssessment');
		let fileModeloQualidade = defaultForm.getValues('qualityAssessment');
		let fileModeloRelatorioTecnico = defaultForm.getValues('technicalObtainingReport');
		let fileModeloDeclaracaoMelhorista = defaultForm.getValues('breederStatement');

		if (fileModeloAvaliacaoCultivar && fileModeloAvaliacaoCultivar.length)
			formDataFiles.append('fileModeloAvaliacaoCultivar', fileModeloAvaliacaoCultivar[0]);

		if (fileModeloCicloAgronomico && fileModeloCicloAgronomico.length)
			formDataFiles.append('fileModeloCicloAgronomico', fileModeloCicloAgronomico[0]);

		if (fileModeloProdutividade && fileModeloProdutividade.length)
			formDataFiles.append('fileModeloProdutividade', fileModeloProdutividade[0]);

		if (fileModeloQualidade && fileModeloQualidade.length)
			formDataFiles.append('fileModeloQualidade', fileModeloQualidade[0]);

		if (fileModeloRelatorioTecnico && fileModeloRelatorioTecnico.length)
			formDataFiles.append('fileModeloRelatorioTecnico', fileModeloRelatorioTecnico[0]);

		if (fileModeloDeclaracaoMelhorista && fileModeloDeclaracaoMelhorista.length)
			formDataFiles.append('fileModeloDeclaracaoMelhorista', fileModeloDeclaracaoMelhorista[0]);

		try
		{
			const { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${cultivarRegistro.id}/SalvarArquivosRegistro`, formDataFiles);

			if (status === 200)
			{
				setSuccessFiles(true);
			}
		}
		catch (error:any)
		{
			console.error(error);
		}
	}

	const onSubmitAdditionalFiles = async (cultivarRegistro?: any) => {
		additionalFiles.map(file => formDataAdditionalFiles.append("files", file.arquivo));
		try {
			const { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${cultivarRegistro.id}/SalvarArquivosAnexos`, formDataAdditionalFiles);

			if (status === 200)
				setSuccessAdditionalFiles(true);
		} catch (error:any) {
					}
	}

	const downloadModel = async (modelCode: string, fileName: string) => {
		setLoadingDownload(true);
		try {
			const { data } = await apiFileDownload.get(`api/v1/cultivares/Download?codigo=${modelCode}`);
			let blob = new Blob([data], {
				type: 'application/pdf'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
			}

		} catch (error:any) {
					}
		setLoadingDownload(false);
	}

	const sendToRegulatory = async (id: number) => {
		setErrorRegulatory(null);

		try {
			const { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${id}/enviarparaaprovacao`);
			if (status === 200) {
				setSuccessRegulatory(true);
				setTimeout(() => {
					history.goBack();
				}, 3500)
			}
		} catch (error:any) {
			setErrorRegulatory(error.response.data);
		}
	}

	const handleRequiredFields = (idRecordType: string) => {
		const recordType = recordsType.filter(record => {
			return record.id === Number(idRecordType);
		});
		const fields: RecordTypesResults = recordType[0];

		setFieldApplicantResponsible(fields.melhoristaResponsavel);
		setFieldInstitution(fields.instituicaoResponsavel);
		setFieldCultivarOrigin(fields.origemDaCultivar);
		setFieldCultivarRate(fields.avaliacoesDeCultivar);
		setFieldDescriptors(fields.descritores);
		setFieldDiseaseOrNematode(fields.reacoesDoencas);
		setFieldAgronomicCharacteristics(fields.avaliacaoDoCicloDeCaracteristicasAgronomicasDaCultura);
		setFieldProductivity(fields.avaliacaoDaProdutividade);
		setFieldQuality(fields.avaliacaoDaQualidadeTecnologicaIndustrical);
		setFieldCommercialization(fields.intencaoDeComercializacao);
		setFieldProductionSystem(fields.sistemasDeProducaoSugeridosParaCultivar);
		setFieldLimitationCultivar(fields.limitacoesDaCultivar);
		setFieldAdditionalInformation(fields.informacoesAdicionais);
	}

	const getCultureById = async (idCulture: number) => {
		try {
			const { data, status } = await api.get<CultureResults>(`api/v1/cultivares/Cultura/${Number(idCulture)}`);
			if (status === 200) {
				setCultureDetails(data);
				setDescriptorsByCulture(data.descritores);
				setDiseasesByCulture(data.doencas);
				setRegions(data.culturaRegioes);
				if (data.modeloRegistrosCultivares.length) {
					setModels(data.modeloRegistrosCultivares);
				}
			}
		} catch (error:any) {
					}
	}

	const expectedBreeders = (): RequestRegisterMelhoristas[] => {
		let breedersModel: RequestRegisterMelhoristas[] = [];

		for (let i = 0, t = selectedBreeder.length; i < t; i++) {
			breedersModel.push({
				equipeTecnicaMelhoristaId: selectedBreeder[i].id
			})
		}
				return breedersModel;
	}

	const expectedApplicants = (): RequestRegisterApplicants[] => {
		
		let applicantsModel: RequestRegisterApplicants[] = [];

				
		for (let i = 0, t = selectedApplicants.length; i < t; i++) {
			applicantsModel.push({
				requerenteId: selectedApplicants[i].id
			})
		}
		return applicantsModel;
	}

	const expectedRegions = (): RequestRegisterRegions[] => {
		let regionsModel: RequestRegisterRegions[] = [];

		for (let count = 0, countLen = selectedAdaptationRegions.length; count < countLen; count++) {
			regionsModel.push({
				culturaRegiaoId: Number(selectedAdaptationRegions[count].id)
			})
		}
		return regionsModel;
	}

	const expectedDescriptors = (): ExpectedDescriptors[] => {
		let descriptorsModel: ExpectedDescriptors[] = [];

		for (let count = 0, countLen = selectedDescriptors.length; count < countLen; count++) {
			descriptorsModel.push({
				descritoresId: selectedDescriptors[count].descritoresId,
				caracteristicasDescritoresId: selectedDescriptors[count].caracteristicasDescritoresId,
				observacao: selectedDescriptors[count].observacao
			})
		}
		return descriptorsModel;
	}

	const expectedDiseases = (): ExpectedDisease[] => {
		let diseaseModel: ExpectedDisease[] = [];

		for (let count = 0, countLen = selectedDiseasesOrNematodes.length; count < countLen; count++) {
			diseaseModel.push({
				doencasId: selectedDiseasesOrNematodes[count].doencasId,
				reacaoDoencaCampoId: selectedDiseasesOrNematodes[count].reacaoDoencaCampoId,
				reacaoDoencaCasaVegetacaoId: selectedDiseasesOrNematodes[count].reacaoDoencaCasaVegetacaoId,
				observacao: selectedDiseasesOrNematodes[count].observacao
			})
		}
		return diseaseModel;
	}

	const setFilteredDescriptors = (selectedDescriptors: RequestRegisterDescriptors[]) => {
		let arr1: DescriptorsResults[] = [];
		for (let count = 0, countLen = descriptorsByCulture.length; count < countLen; count++) {
			if (!selectedDescriptors.some(c => c.descritoresId === descriptorsByCulture[count].id))
				arr1.push(descriptorsByCulture[count]);
		}
		setDescriptorsByCulture(arr1);
	}

	const giveBackDeletedDescriptor = () => {
		setDescriptorsByCulture([...descriptorsByCulture]);
	}

	const setFilteredDiseases = (selectedDiseases: RequestRegisterDisease[]) => {
		let arr1: DiseasesResults[] = [];
		for (let count = 0, countLen = diseasesByCulture.length; count < countLen; count++) {
			if (!selectedDiseases.some(c => c.doencasId === diseasesByCulture[count].id))
				arr1.push(diseasesByCulture[count]);
		}
		setDiseasesByCulture(arr1);
	}

	const giveBackDiseases = () => {
		setDiseasesByCulture([...diseasesByCulture]);
	}

	const filterModels = (registerId: number) => {
		const result = models.filter(item => item.tipoRegistroId === Number(registerId));

		setFilteredModels(result[0]);
	}

	const filterRecordType = (selectedCulture: string) => {
		if (selectedCulture.toLowerCase() !== 'arroz') {
			const newRecordsType = recordsType.filter(record => !record.nome.toLowerCase().endsWith('parental'));
			setFilteredRecords(newRecordsType)
		} else {
			setFilteredRecords([...recordsType]);
		}
	}

	const can_edit = (group: string) =>
	{
		let userIsAdmin = isAdmin(roles);
		let userIsRegulatorioRegister = isReguladorRegister(roles);
		let userIsDescriptors = isDescriptors(roles);
		let userIsDiseases = isDiseases(roles);

		switch (group)
		{
			case 'preenchimento_observacoes':

				return true;
		}

		return true;
	}

	return <div className="container container-fluid">
		<If condition={successfulResponse && successFiles && !errorSubmit}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS}
			/>
		</If>
		<If condition={!successfulResponse && errorSubmit !== null}>
			{errorSubmit !== null && errorSubmit.message.length > 0 ? errorSubmit.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<If condition={successRegulatory && !errorRegulatory}>
			<AlertSuccess
				message={STRINGS.REGULATORY_SUCCESS}
			/>
		</If>
		<If condition={!successRegulatory && errorRegulatory !== null}>
			{errorRegulatory !== null && errorRegulatory.message.length > 0 ? errorRegulatory.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<If condition={successDraft && !errorDraft}>
			<AlertSuccess message={STRINGS.SUCCESS_DRAFT} />
		</If>
		<If condition={!successDraft && errorDraft}>
			{errorDraft !== null && errorDraft.message.length > 0 ? errorDraft.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<If condition={errorOnForm}>
			<AlertError message={STRINGS.ALERT_WARNING_FIELDS} />
		</If>
		<h3>Cadastro de registro &#45; rnc</h3>
		<p className="text-helper">Campos com <em>*</em> são de preenchimento obrigatório</p>
		<Form onSubmit={defaultForm.handleSubmit(onSubmit)}>
			<Fieldset title="1. Identificação" required>
				<If condition={defaultForm.errors.speciesName ||
					defaultForm.errors.registerType ||
					defaultForm.errors.nameCultivar}
				>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Nome comum da espécie<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<If condition={loadingNeededData}>
							<LoadingInForm />
						</If>
						<Form.Control
							as="select"
							ref={defaultForm.register}
							name="speciesName"
							isInvalid={defaultForm.errors.speciesName !== undefined}
							onChange={({ target }: any) => {
								getCultureById(target.value);
								setScientificName(target.value);
								let index = target.options.selectedIndex;
								setSpecieName(target.options[index].label);
								filterRecordType(target.options[index].label);
								setSelectedCulture(target.options[index].label);
							}}
						>
							<Form.Control
								as="option"
								label="Escolha uma opção"
							/>
							{culture.map(item => (
								<Form.Control
									as="option"
									label={item.nome}
									value={item.id}
									key={item.id}
								/>
							))}
						</Form.Control>
						<If condition={defaultForm.errors.speciesName}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.speciesName?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Nome científico da espécie<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="scientificName"
							autoComplete="off"
							placeholder="Nome científico da espécie"
							readOnly
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Grupo da Espécie<em>*</em>
						</Form.Label>
						<Form.Control
							as="select"
							ref={defaultForm.register}
							name="speciesGroup"
							readOnly
						>
							<Form.Control
								as="option"
								label="Grandes Culturas"
								value="Grandes Culturas"
							/>
						</Form.Control>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Tipo de registro<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<If condition={loadingNeededData}>
							<LoadingInForm />
						</If>
						<Form.Control
							as="select"
							ref={defaultForm.register}
							name="registerType"
							isInvalid={defaultForm.errors.registerType !== undefined}
							onChange={({ target }: any) => {
								handleRequiredFields(target.value);
								let index = target.options.selectedIndex;
								setRecordTypeName(target.options[index].label);
								filterModels(target.value);
							}}
						>
							<Form.Control
								as="option"
								label="Escolha uma opção"
							/>
							{filteredRecords.map(item => (
								<Form.Control
									as="option"
									key={item.id}
									label={item.nome}
									value={item.id}
								/>
							))}
						</Form.Control>
						<If condition={defaultForm.errors.registerType}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.registerType?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Denominação da Cultivar/Linhagem<em>*</em>
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="nameCultivar"
							placeholder="Insira a denominação da cultivar"
							isInvalid={defaultForm.errors.nameCultivar !== undefined}
							autoComplete="off"
							maxLength={150}
						/>
						<If condition={defaultForm.errors.nameCultivar}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.nameCultivar?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="2. Requerente">
				<If condition={submited && !selectedApplicants.length}
				>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group className="label-btn">
						<Form.Label>
							Requerente<em>*</em>
						</Form.Label>
						<AddButton
							onClick={() => {
								setChooseApplicant(true);
								setUnselectedApplicant(selectedApplicants);
								setChoosenApplicants([]);
							}}
							type="button"
						/>
						&nbsp;&nbsp;
						<If condition={loadingNeededData}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<If condition={inserApplicants && selectedApplicants.length}>
					<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedApplicants}
							defaultColDef={{ flex: 1, sortable: true }}
							frameworkComponents={{
								action: actionApplicants,
							}}
						>
							<AgGridColumn
								headerName='Requerente'
								field="nome"
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
							/>
						</AgGridReact>
					</div>
				</If>
				<If condition={chooseApplicant}>
					<Modal
						onHide={() => setChooseApplicant(false)}
						show={true}
						title="Lista de requerentes"
					>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
							<AgGridReact
								rowData={unselectedApplicants}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
								onRowSelected={onRowSelectedApplicant}
								rowSelection={'multiple'}
							>
								<AgGridColumn
									headerName="Selecionar"
									checkboxSelection={true}
									headerCheckboxSelection
									pinned="left"
								/>
								<AgGridColumn
									headerName='Requerente'
									field='nome'
								/>
							</AgGridReact>
						</div>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setChooseApplicant(false)}
							/>
							<Button
								title="Inserir"
								contained={true}
								type="button"
								onClick={() => {
									setInsertApplicants(true);
									setChooseApplicant(false);
									setSelectedApplicants([...selectedApplicants, ...choosenApplicants])
								}}
								disabled={!choosenApplicants.length}
							/>
						</div>
					</Modal>
				</If>
			</Fieldset>

			<Fieldset title="3. Melhorista Responsável" required={fieldApplicantResponsible}>
				<If condition={submited && (fieldApplicantResponsible && !selectedBreeder.length)}
				>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} className="label-btn">
						<Form.Label>
							Nome&#40;s&#41;{fieldApplicantResponsible ? <em>*</em> : null}
						</Form.Label>
						<AddButton
							onClick={() => {
								setChooseBreeder(true);
								setUnselectedBreederList(selectedBreeder);
								setChoosenBreeder([]);
							}}
							type="button"
						/>
						&nbsp;&nbsp;
						<If condition={loadingNeededData}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<If condition={insertBreeder && selectedBreeder.length}>
					<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedBreeder}
							defaultColDef={{ flex: 1, sortable: true }}
							frameworkComponents={{
								action: actionBreeder,
							}}
						>
							<AgGridColumn
								headerName='Melhorista'
								field='nome'
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
							/>
						</AgGridReact>
					</div>
				</If>
				<If condition={chooseBreeder}>
					<Modal
						title="Lista de melhoristas"
						show={true}
						onHide={() => setChooseBreeder(false)}
					>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
							<AgGridReact
								rowData={unselectedBreeder}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
								onRowSelected={onRowSelectedBreeder}
								rowSelection={'multiple'}
							>
								<AgGridColumn
									headerName="Selecionar"
									headerCheckboxSelection
									checkboxSelection
									pinned="left"
								/>
								<AgGridColumn
									headerName='Melhorista'
									field='nome'
								/>
							</AgGridReact>
						</div>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setChooseBreeder(false)}
							/>
							<Button
								title="Inserir"
								contained={true}
								type="button"
								onClick={() => {
									setChooseBreeder(false);
									setInsertBreeder(true);
									setSelectedBreeder([...selectedBreeder, ...choosenBreeder])
								}}
								disabled={!choosenBreeder.length}
							/>
						</div>
					</Modal>
				</If>
			</Fieldset>

			<Fieldset title="4. Instituição responsável pelo ensaio">
				<If condition={defaultForm.errors.institutionName || defaultForm.errors.responsibleTechnician}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Instituição{fieldInstitution ? <em>*</em> : null}
						</Form.Label>
						&nbsp;&nbsp;
						<If condition={loadingNeededData}>
							<LoadingInForm />
						</If>
						<Form.Control
							as="select"
							ref={defaultForm.register}
							name="institutionName"
							isInvalid={defaultForm.errors.institutionName !== undefined}
							onChange={({ target }: any) => setInstitutionSelected(target.value)}
						>
							<Form.Control
								as="option"
								label="Escolha uma instituição"
							/>
							{institutions.map(item => (
								<Form.Control
									as="option"
									key={item.id}
									label={item.nome}
									value={item.id}
								/>
							))}
						</Form.Control>
						<If condition={defaultForm.errors.institutionName}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.institutionName?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>
							Técnicos responsáveis pelo ensaio{fieldInstitution ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="responsibleTechnician"
							autoComplete="off"
							placeholder="Insira os nomes dos técnicos"
							isInvalid={defaultForm.errors.responsibleTechnician !== undefined}
							as="textarea"
							rows={3}
						/>
						<If condition={defaultForm.errors.responsibleTechnician}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.responsibleTechnician?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="5. Informações complementares">
				<If condition={defaultForm.errors.cultivarCountry
					|| defaultForm.errors.cultivarCertificateNumber
					|| defaultForm.errors.cultivarNameOfTransferor
					|| defaultForm.errors.cultivarInstrumentUsed
					|| defaultForm.errors.cultivarCountryOfOrigin
					|| defaultForm.errors.cultivarImportCountry
					|| defaultForm.errors.cultivarImportProcess
					|| defaultForm.errors.derivedCultivar
					|| defaultForm.errors.cultivarGeneticTransformationEvent
				}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Group controlId="protectedCultivar">
					<div className="option-cultivar">
						<span>Cultivar protegida?<em>*</em></span>
						<Form.Check
							value="sim"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							onChange={() => setProtectedCultivar(true)}
							checked={protectedCultivar}
						/>
						<Form.Check
							value="não"
							type="radio"
							aria-label="radio 2"
							label="Não"
							onChange={() => setProtectedCultivar(false)}
							checked={!protectedCultivar}
						/>
					</div>
				</Form.Group>
				<If condition={protectedCultivar}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								País<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="cultivarCountry"
								autoComplete="off"
								placeholder="Insira o país da cultivar"
								isInvalid={defaultForm.errors.cultivarCountry !== undefined}
								maxLength={50}
							/>
							<If condition={defaultForm.errors.cultivarCountry}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.cultivarCountry?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Número do certificado de proteção<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="cultivarCertificateNumber"
								autoComplete="off"
								placeholder="Insira o número do certificado de proteção"
								isInvalid={defaultForm.errors.cultivarCertificateNumber !== undefined}
								maxLength={50}
							/>
							<If condition={defaultForm.errors.cultivarCertificateNumber}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.cultivarCertificateNumber?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
				</If>
				<Form.Group controlId="transferredCultivar">
					<div className="option-cultivar">
						<span>Cultivar transferida?<em>*</em></span>
						<Form.Check
							value="sim"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							onChange={() => setTransferredCultivar(true)}
							checked={transferredCultivar}
						/>
						<Form.Check
							value="não"
							type="radio"
							aria-label="radio 2"
							label="Não"
							onChange={() => setTransferredCultivar(false)}
							checked={!transferredCultivar}
						/>
					</div>
				</Form.Group>
				<If condition={transferredCultivar}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Nome do cedente<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="cultivarNameOfTransferor"
								autoComplete="off"
								placeholder="Insira o nome do cedente"
								isInvalid={defaultForm.errors.cultivarNameOfTransferor !== undefined}
								maxLength={100}
							/>
							<If condition={defaultForm.errors.cultivarNameOfTransferor}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.cultivarNameOfTransferor?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Instrumento utilizado para a transferência<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="cultivarInstrumentUsed"
								autoComplete="off"
								placeholder="Insira o instrumento utilizado"
								isInvalid={defaultForm.errors.cultivarInstrumentUsed !== undefined}
								maxLength={100}
							/>
							<If condition={defaultForm.errors.cultivarInstrumentUsed}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.cultivarInstrumentUsed?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
				</If>
				<Form.Group controlId="foreignCultivar">
					<div className="option-cultivar">
						<span>Cultivar estrangeira?<em>*</em></span>
						<Form.Check
							value="sim"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							onChange={() => setForeignCultivar(true)}
							checked={foreignCultivar}
						/>
						<Form.Check
							value="não"
							type="radio"
							aria-label="radio 2"
							label="Não"
							onChange={() => setForeignCultivar(false)}
							checked={!foreignCultivar}
						/>
					</div>
				</Form.Group>
				<If condition={foreignCultivar}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								País de origem<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="cultivarCountryOfOrigin"
								autoComplete="off"
								placeholder="Insira o país de origem"
								isInvalid={defaultForm.errors.cultivarCountryOfOrigin !== undefined}
								maxLength={50}
							/>
							<If condition={defaultForm.errors.cultivarCountryOfOrigin}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.cultivarCountryOfOrigin?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								País de Importação/procedência<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="cultivarImportCountry"
								autoComplete="off"
								placeholder="Insira o país de importação/procedência"
								isInvalid={defaultForm.errors.cultivarImportCountry !== undefined}
								maxLength={50}
							/>
							<If condition={defaultForm.errors.cultivarImportCountry}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.cultivarImportCountry?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>
								Processo de importação<em>*</em>
							</Form.Label>
							<Form.Control
								as="textarea"
								ref={defaultForm.register}
								name="cultivarImportProcess"
								autoComplete="off"
								placeholder="Informe como se deu o processo de importação"
								isInvalid={defaultForm.errors.cultivarImportProcess !== undefined}
								maxLength={100}
							/>
							<If condition={defaultForm.errors.cultivarImportProcess}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.cultivarImportProcess?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
				</If>
				<Form.Group controlId="foreignCultivar">
					<div className="option-cultivar">
						<span>Cultivar essencialmente derivada?<em>*</em></span>
						<Form.Check
							value="sim"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							onChange={() => setEssentiallyDerived(true)}
							checked={essentiallyDerived}
						/>
						<Form.Check
							value="não"
							type="radio"
							aria-label="radio 2"
							label="Não"
							onChange={() => setEssentiallyDerived(false)}
							checked={!essentiallyDerived}
						/>
					</div>
				</Form.Group>
				<If condition={essentiallyDerived}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Cultivar<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="derivedCultivar"
								autoComplete="off"
								placeholder="Insira a cultivar de origem"
								isInvalid={defaultForm.errors.derivedCultivar !== undefined}
								maxLength={200}
							/>
							<If condition={defaultForm.errors.derivedCultivar}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.derivedCultivar?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
				</If>
				<Form.Group controlId="foreignCultivar">
					<div className="option-cultivar">
						<span>Organismo geneticamente modificado?<em>*</em></span>
						<Form.Check
							value="sim"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							onChange={() => setModifiedOrganism(true)}
							checked={modifiedOrganism}
						/>
						<Form.Check
							value="não"
							type="radio"
							aria-label="radio 2"
							label="Não"
							onChange={() => setModifiedOrganism(false)}
							checked={!modifiedOrganism}
						/>
					</div>
				</Form.Group>
				<If condition={modifiedOrganism}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Evento de transformação genética<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="cultivarGeneticTransformationEvent"
								autoComplete="off"
								as="select"
								isInvalid={defaultForm.errors.cultivarGeneticTransformationEvent !== undefined}
								onChange={({ target }: any) => setEventSelected(target.value)}
								title={selectedEvent?.nomeEvento}
							>
								<Form.Control
									as="option"
									label="Selecione o Evento de transformação genética"
									value=""
								/>
								{events.map(item => (
									<Form.Control
										key={item.id}
										as="option"
										label={`${item.nomeEvento.substring(0, 135)}...`}
										value={item.id}
										title={item.nomeEvento}
									/>
								))}
							</Form.Control>
							<If condition={defaultForm.errors.cultivarGeneticTransformationEvent}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.cultivarGeneticTransformationEvent?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
				</If>
			</Fieldset>

			<Fieldset title="6. Origem da cultivar">
				<If condition={defaultForm.errors.institutionCompany
					|| defaultForm.errors.breederParticipant
					|| defaultForm.errors.crossingYear
					|| defaultForm.errors.crossingPlace
					|| defaultForm.errors.crossingInstitution
					|| defaultForm.errors.genealogy
					|| defaultForm.errors.designation
				}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Instituição ou empresa obtentora, introdutoras e/ou detentora{fieldCultivarOrigin ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="institutionCompany"
							placeholder="Informe a instituição ou empresa"
							autoComplete="off"
							isInvalid={defaultForm.errors.institutionCompany !== undefined}
							maxLength={150}
						/>
						<If condition={defaultForm.errors.institutionCompany}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.institutionCompany?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Melhoristas participantes{fieldCultivarOrigin ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							as="textarea"
							ref={defaultForm.register}
							name="breederParticipant"
							placeholder="Insira os melhoristas participantes"
							autoComplete="off"
							isInvalid={defaultForm.errors.breederParticipant !== undefined}
						/>
						<If condition={defaultForm.errors.breederParticipant}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.breederParticipant?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>
							Cruzamento
					</Form.Label>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={3}>
						<Form.Label>
							Ano de realização{fieldCultivarOrigin ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="crossingYear"
							placeholder="Insira o ano"
							autoComplete="off"
							isInvalid={defaultForm.errors.crossingYear !== undefined}
							type="number"
							maxLength={4}
						/>
						<If condition={defaultForm.errors.crossingYear}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.crossingYear?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Local{fieldCultivarOrigin ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="crossingPlace"
							placeholder="Insira o local de realização do cruzamento"
							autoComplete="off"
							isInvalid={defaultForm.errors.crossingPlace !== undefined}
							maxLength={200}
						/>
						<If condition={defaultForm.errors.crossingPlace}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.crossingPlace?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Instituição que realizou{fieldCultivarOrigin ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="crossingInstitution"
							placeholder="Insira a instituição responsável pela realização"
							autoComplete="off"
							isInvalid={defaultForm.errors.crossingInstitution !== undefined}
							maxLength={150}
						/>
						<If condition={defaultForm.errors.crossingInstitution}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.crossingInstitution?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Genealogia{fieldCultivarOrigin ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="genealogy"
							placeholder="Insira os parentais imediatos"
							autoComplete="off"
							isInvalid={defaultForm.errors.genealogy !== undefined}
							maxLength={150}
						/>
						<If condition={defaultForm.errors.genealogy}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.genealogy?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Denominação experimental ou pré-comercial{fieldCultivarOrigin ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="designation"
							placeholder="Insira a denominação"
							autoComplete="off"
							isInvalid={defaultForm.errors.designation !== undefined}
							maxLength={150}
						/>
						<If condition={defaultForm.errors.designation}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.designation?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="7. Avaliações da cultivar" required={fieldCultivarRate} >
				<If condition={submited &&
					(fieldCultivarRate
						&& (
							!defaultForm.getValues("ratePlaces").length
							|| (!selectedAdaptationRegions.length || selectedAdaptationRegions.length === 0)
						)
					)}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<If condition={!defaultForm.watch().speciesName}>
					<AlertError message={"Selecione 'Nome comum da espécie' primeiro."} />
				</If>
				<If condition={defaultForm.errors.ratePlaces}>
					<AlertError message={defaultForm.errors?.ratePlaces?.message} />
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Locais da avaliação{fieldCultivarRate ? <em>*</em> : null}
						</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<If condition={filteredModels?.modeloAvaliacaoCultivar}>
							<AddButton
								type="button"
								onClick={() => {
									if (filteredModels?.modeloAvaliacaoCultivar) {
										downloadModel(filteredModels.modeloAvaliacaoCultivar.codigo, filteredModels.modeloAvaliacaoCultivar.nome);
									}
								}}
								otherTitle="Baixar modelo"
								isDownload={true}
							/>
						</If>
						<If condition={!filteredModels?.modeloAvaliacaoCultivar}>
							<NotAvailableModel />
						</If>
						<If condition={loadingDownload}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.File
							ref={defaultForm.register}
							name="ratePlaces"
							label={lblAdaptationRegions}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblAdaptationRegions(`${target.files[0].name}`);
							}}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group className="label-btn">
						<Form.Label>
							Região de adaptação{fieldCultivarRate ? <em>*</em> : null}
						</Form.Label>
						<AddButton
							type="button"
							onClick={() => {
								setChooseAdaptationRegions(true);
								setUnselectedAdaptationRegion(selectedAdaptationRegions)
								setChoosenAdaptationRegions([]);
							}}
						/>
					</Form.Group>
				</Form.Row>
				<If condition={selectedAdaptationRegions.length}>
					<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedAdaptationRegions}
							defaultColDef={{ flex: 1, sortable: true }}
							frameworkComponents={{
								action: actionAdaptationRegions
							}}
						>
							<AgGridColumn
								headerName="Região"
								field="descricao"
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
							/>
						</AgGridReact>
					</div>
				</If>
				<If condition={chooseAdaptationRegions}>
					<Modal
						title="Lista de regiões de adaptação"
						onHide={() => setInsertAdaptationRegions(false)}
						show={true}
					>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
							<AgGridReact
								rowData={unselectedAdaptationRegions}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
								onRowSelected={onRowSelectedAdaptationRegions}
								rowSelection={'multiple'}
							>
								<AgGridColumn
									headerName="Selecionar"
									headerCheckboxSelection
									checkboxSelection={true}
									pinned="left"
								/>
								<AgGridColumn
									headerName='Região'
									field='descricao'
								/>
							</AgGridReact>
						</div>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setChooseAdaptationRegions(false)}
							/>
							<Button
								title="Inserir"
								contained={true}
								type="button"
								onClick={() => {
									setChooseAdaptationRegions(false);
									setInsertAdaptationRegions(true);
									setSelectedAdaptationRegions([...selectedAdaptationRegions, ...choosenAdaptationRegions])
								}}
								disabled={!choosenAdaptationRegions.length}
							/>
						</div>
					</Modal>
				</If>
			</Fieldset>

			<Fieldset title="8. Descritores" required={fieldDescriptors}>
					<If condition={defaultForm.errors.all_descriptors}>
						<AlertError message={defaultForm.errors?.all_descriptors?.message} />
					</If>
					<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Descritores{fieldDescriptors ? <em>*</em> : null}
						</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<AddButton
							onClick={() => {
								setInsertDescriptors(true);
								setFilteredDescriptors(selectedDescriptors);
							}}
							type="button"
						/>
					<input type="hidden" ref={defaultForm.register} name="all_descriptors" value="" />
					</Form.Group>
				</Form.Row>
				<If condition={choosenDescriptors && selectedDescriptors.length}>
					<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedDescriptors}
							defaultColDef={{ flex: 1, sortable: true, resizable: true }}
							suppressRowClickSelection={true}
							onRowSelected={onRowSelectedApplicant}
							rowSelection={'multiple'}
							frameworkComponents={{
								action: actionDescriptors,
							}}
						>
							<AgGridColumn
								headerName="Descritor"
								field="descricao"
								cellStyle={{
									alignItems: 'center'
								}}
								wrapText
								autoHeight
							/>
							<AgGridColumn
								headerName="Característica"
								field="caracteristica"
								cellStyle={{
									alignItems: 'center'
								}}
							/>
							<AgGridColumn
								headerName="Observação"
								field="observacao"
								cellStyle={{
									alignItems: 'center'
								}}
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
								cellStyle={{
									alignItems: 'center'
								}}
							/>
						</AgGridReact>
					</div>
				</If>
				<If condition={insertDescriptors}>
					<Modal
						title="Lista de descritores"
						onHide={() => setInsertDescriptors(false)}
						show={true}
					>
						<If condition={defaultForm.errors.descriptorRegisterRNC || defaultForm.errors.characteristicRegisterRNC}>
							<AlertError
								message={STRINGS.ALERT_WARNING_FIELDS}
							/>
						</If>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Descritor<em>*</em>
								</Form.Label>
								<Form.Control
									as="select"
									ref={defaultForm.register}
									name="descriptorRegisterRNC"
									isInvalid={defaultForm.errors.descriptorRegisterRNC !== undefined}
									onChange={({ target }: any) => {
										setRespectiveCharacteristics(target.value);
										let index = target.options.selectedIndex;
										setDescriptorName(target.options[index].label);
									}}
								>
									<Form.Control
										as="option"
										label="Escolha um descritor"
									/>
									{descriptorsByCulture.map(item => (
										<Form.Control
											key={item.id}
											as="option"
											label={item.descricao}
											value={item.id}
										/>
									))}
								</Form.Control>
								<If condition={defaultForm.errors.descriptorRegisterRNC}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.descriptorRegisterRNC?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Característica<em>*</em>
								</Form.Label>
								<Form.Control
									as="select"
									ref={defaultForm.register}
									name="characteristicRegisterRNC"
									isInvalid={defaultForm.errors.characteristicRegisterRNC !== undefined}
									onChange={({ target }: any) => {
										let index = target.options.selectedIndex;
										setDescriptorCharacteristic(target.options[index].label);
									}}
								>
									<Form.Control
										as="option"
										label="Escolha uma característica"
									/>
									{characteristicsByDescriptor.map(item => (
										<Form.Control
											key={item.id}
											as="option"
											label={item.descricao}
											value={item.id}
										/>
									))}
								</Form.Control>
								<If condition={defaultForm.errors.characteristicRegisterRNC}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.characteristicRegisterRNC?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Observações
								</Form.Label>
								<Form.Control
									as="textarea"
									ref={defaultForm.register}
									name="descriptorNote"
									maxLength={200}
								/>
							</Form.Group>
						</Form.Row>
						<div className="button-position">
							<Button
								title="Cancelar"
								type="button"
								contained={false}
								onClick={() => setInsertDescriptors(false)}
							/>
							<Button
								title="Inserir"
								type="button"
								contained={true}
								onClick={() => {
									setSelectedDescriptorsList();
								}}
							/>
						</div>
					</Modal>
				</If>
			</Fieldset>

			<Fieldset title="9. Reação a doenças e nematóides" required={fieldDiseaseOrNematode}>
				<If condition={defaultForm.errors.all_diseases_or_nematoides}>
						<AlertError message={defaultForm.errors?.all_diseases_or_nematoides?.message} />
					</If>
				<If condition={!defaultForm.watch().speciesName}>
					<AlertError
						message={"Selecione 'Nome comum da espécie' primeiro."}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Reação a doenças e nematóides{fieldDiseaseOrNematode ? <em>*</em> : null}
						</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<AddButton
						disabled={!defaultForm.watch().speciesName}
							type="button"
							onClick={() => {
								setInsertDiseases(true);
								setFilteredDiseases(selectedDiseasesOrNematodes);
							}}
						/>
					<input type="hidden" ref={defaultForm.register} name="all_diseases_or_nematoides" value="" />
					</Form.Group>
				</Form.Row>
				<If condition={choosenDiseases && selectedDiseasesOrNematodes.length}>
					<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedDiseasesOrNematodes}
							defaultColDef={{ flex: 1, sortable: true, resizable: true }}
							suppressRowClickSelection={true}
							onRowSelected={onRowSelectedApplicant}
							rowSelection={'multiple'}
							frameworkComponents={{
								action: actionDiseases,
							}}
						>
							<AgGridColumn
								headerName="Doença"
								field="diseaseName"
								wrapText
								autoHeight
								cellStyle={{
									alignItems: 'center'
								}}
							/>
							<AgGridColumn
								headerName="Reação em casa de vegetação"
								field="diseaseReaction"
								wrapText
								autoHeight
								cellStyle={{
									alignItems: 'center'
								}}
							/>
							<AgGridColumn
								headerName="Reação em campo"
								field="diseaseReactionGreenHouse"
								cellStyle={{
									alignItems: 'center'
								}}
							/>
							<AgGridColumn
								headerName="Observação"
								field="observacao"
								wrapText
								autoHeight
								cellStyle={{
									alignItems: 'center'
								}}
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
								wrapText
								autoHeight
								cellStyle={{
									alignItems: 'center'
								}}
							/>
						</AgGridReact>
					</div>
				</If>
				<If condition={insertDiseases}>
					<Modal
						title="Lista de doenças e nematóides"
						onHide={() => setInsertDiseases(false)}
						show={true}
					>
						<If condition={defaultForm.errors.diseaseOrNematode ||
							defaultForm.errors.greenhouseReactionDisease ||
							defaultForm.errors.countrysideReactionDisease
						}>
							<AlertError
								message={STRINGS.ALERT_WARNING_FIELDS}
							/>
						</If>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Espécie: {defaultForm.getValues('scientificName')}
								</Form.Label>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Doença/Nematóide<em>*</em>
								</Form.Label>
								<Form.Control
									as="select"
									ref={defaultForm.register}
									name="diseaseOrNematode"
									isInvalid={defaultForm.errors.diseaseOrNematode !== undefined}
									onChange={({ target }: any) => {
										setRespectiveReactionsDiseases(target.value);
										let index = target.options.selectedIndex;
										setDiseaseName(target.options[index].label);
									}}
								>
									<Form.Control
										as="option"
										label="Escolha uma doença"
									/>
									{diseasesByCulture.map(item => (
										<Form.Control
											as="option"
											label={item.descricao}
											value={item.id}
											key={item.id}
										/>
									))}
								</Form.Control>
								<If condition={defaultForm.errors.diseaseOrNematode}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.diseaseOrNematode?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Reação em casa de vegetação<em>*</em>
								</Form.Label>
								<Form.Control
									as="select"
									ref={defaultForm.register}
									name="greenhouseReactionDisease"
									isInvalid={defaultForm.errors.greenhouseReactionDisease !== undefined}
									onChange={({ target }: any) => {
										let index = target.options.selectedIndex;
										setDiseaseReactionGreenHouse(target.options[index].label);
									}}
								>
									<Form.Control
										as="option"
										label="Escolha uma reação"
									/>
									{diseasesReactions.map(item => (
										<Form.Control
											as="option"
											label={item.descricao}
											value={item.id}
											key={item.id}
										/>
									))}
								</Form.Control>
								<If condition={defaultForm.errors.greenhouseReactionDisease}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.greenhouseReactionDisease?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Reação em campo<em>*</em>
								</Form.Label>
								<Form.Control
									as="select"
									ref={defaultForm.register}
									name="countrysideReactionDisease"
									isInvalid={defaultForm.errors.countrysideReactionDisease !== undefined}
									onChange={({ target }: any) => {
										let index = target.options.selectedIndex;
										setDiseaseReaction(target.options[index].label);
									}}
								>
									<Form.Control
										as="option"
										label="Escolha uma reação"
									/>
									{diseasesReactions.map(item => (
										<Form.Control
											as="option"
											label={item.descricao}
											value={item.id}
											key={item.id}
										/>
									))}
								</Form.Control>
								<If condition={defaultForm.errors.countrysideReactionDisease}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.countrysideReactionDisease?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Observações
								</Form.Label>
								<Form.Control
									as="textarea"
									ref={defaultForm.register}
									name="diseaseOrNematodeNote"
									maxLength={200}
								/>
							</Form.Group>
						</Form.Row>
						<div className="button-position">
							<Button
								title="Cancelar"
								type="button"
								onClick={() => setInsertDiseases(false)}
								contained={false}
							/>
							<Button
								title="Inserir"
								type="button"
								contained={true}
								onClick={() => {
									setSelectedDiseasesOrNematodesList();
								}}
							/>
						</div>
					</Modal>
				</If>
			</Fieldset>

			<Fieldset title="10. Avaliação do ciclo e características agronômicas da cultivar">
				<If condition={submited &&
					(fieldAgronomicCharacteristics && defaultForm.getValues("agronomicCharacteristics") && !defaultForm.getValues("agronomicCharacteristics").length)}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<If condition={!defaultForm.watch().speciesName}>
					<AlertError message={"Selecione 'Nome comum da espécie' primeiro."} />
				</If>
				<Group_10 name="textoCicloAgronomico" form={defaultForm} culture={cultureDetails} />
				<If condition={selectedCulture === "Soja"}>
					<If condition={cultureDetails && !cultureDetails?.cicloAgronomicoAsText}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Avaliação do ciclo e características agronômicas da cultivar{fieldAgronomicCharacteristics ? <em>*</em> : null}
								</Form.Label>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<If condition={filteredModels?.modeloCicloAgronomico}>
									<AddButton
										type="button"
										onClick={() => {
											if (filteredModels?.modeloCicloAgronomico) {
												downloadModel(filteredModels.modeloCicloAgronomico.codigo, filteredModels.modeloCicloAgronomico.nome);
											}
										}}
										otherTitle="Baixar modelo"
										isDownload={true}
									/>
								</If>
								<If condition={!filteredModels?.modeloCicloAgronomico}>
									<NotAvailableModel />
								</If>
								<If condition={loadingDownload}>
									<LoadingInForm />
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Anexar arquivo{fieldAgronomicCharacteristics ? <em>*</em> : null}
								</Form.Label>
								<Form.File
									ref={defaultForm.register}
									name="agronomicCharacteristics"
									label={lblAgronomicCharacteristics}
									data-browse="upload"
									custom
									onChange={({ target }: any) => {
										if (!target.value) {
											return
										}
										setLblAgronomicCharacteristics(`${target.files[0].name}`);
									}}
								/>
							</Form.Group>
						</Form.Row>
					</If>
				</If>
				<If condition={specieName === "Arroz"}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Sistema de Cultivo: Irrigação por Inundação, Sequeiro ou Outro (Especificar)
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasArrozSistemaCultivo"
								placeholder="Informe o Sistema de Cultivo"
								autoComplete="off"
								isInvalid={defaultForm.errors.sistemaCultivo !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Comportamento da Cultivar em Relação ao Acamamento: Resistente, Mëdio Resistente, Mëdio Suscetível, Suscetível
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasArrozComportamentoAcamamento"
								placeholder="Informe o Comportamento da Cultivar em Relação ao Acamamento"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasArrozComportamentoAcamamento !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Comportamento da Cultivar em Relação ao Degrane Natural: Fácil, Intermediário, Difícil
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasArrozComportamentoDegrane"
								placeholder="Informe o Comportamento da Cultivar em Relação ao Degrane"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasArrozComportamentoDegrane !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Ciclo - Número de Dias da Semeadura à Completa Maturação
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasArrozCicloMaturacao"
								placeholder="Informe o Ciclo - Dias até a Maturação"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasArrozCicloMaturacao !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Peso de Mil Sementes em Gramas
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasArrozPesoMilSementes"
								placeholder="Informe o Peso de Mil Sementes em Gramas"
								type="number"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasArrozPesoMilSementes !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Número de Dias da Semeadura até 50% das Panículas Emergidas
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasArrozDiasSemeadura"
								placeholder="Informe o Número de Dias da Semeadura"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasArrozDiasSemeadura !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
				</If>

				<If condition={specieName === "Algodão"}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Ciclo até Florescimento em Dias
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasAlgodaoCicloFlorescimento"
								placeholder="Informe o Ciclo de Florescimento"
								type="number"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasAlgodaoCicloFlorescimento !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Peso de Cem Sementes em Gramas
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasAlgodaoPesoCemSementes"
								placeholder="Informe o Peso de Cem Sementes em Gramas"
								type="number"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasAlgodaoPesoCemSementes !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Percentagem de Fibras
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasAlgodaoPercentagemFibras"
								placeholder="Informe o Percentagem de Fibras"
								autoComplete="off"
								type="number"
								isInvalid={defaultForm.errors.caracteristicasAlgodaoPercentagemFibras !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Precocidade de Maturação em Dias
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasAlgodaoPrecocidadeMaturacao"
								placeholder="Informe a Precocidade da Maturação"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasAlgodaoPrecocidadeMaturacao !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Ciclo até a Colheita em Dias
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasAlgodaoCicloColheita"
								placeholder="Informe o Ciclo até a Colheita"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasAlgodaoCicloColheita !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Retenção da Pluma pela Cápsula após a Deiscência
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasAlgodaoRetencaoPluma"
								placeholder="Informe a Retenção da Pluma"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasAlgodaoRetencaoPluma !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Peso do Capulho em gramas
							</Form.Label>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Control
								ref={defaultForm.register}
								name="caracteristicasAlgodaoPesoCapulho"
								placeholder="Informe o Peso do Capulho"
								type="number"
								autoComplete="off"
								isInvalid={defaultForm.errors.caracteristicasAlgodaoPesoCapulho !== undefined}
								maxLength={150}
							/>
						</Form.Group>
					</Form.Row>
				</If>
 			</Fieldset>

			<Fieldset title="11. Avaliação da produtividade" required={fieldProductivity}>
				<If condition={submited &&
					(fieldProductivity && !defaultForm.getValues("productivityAssessment").length)}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<If condition={!defaultForm.watch().speciesName}>
					<AlertError message={"Selecione 'Nome comum da espécie' primeiro."} />
				</If>
				<If condition={defaultForm.errors.productivityAssessment}>
					<AlertError message={defaultForm.errors?.productivityAssessment?.message} />
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Avaliação da produtividade{fieldProductivity ? <em>*</em> : null}
						</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<If condition={filteredModels?.modeloProdutividade}>
							<AddButton
								type="button"
								onClick={() => {
									if (filteredModels?.modeloProdutividade) {
										downloadModel(filteredModels.modeloProdutividade.codigo, filteredModels.modeloProdutividade.nome);
									}
								}}
								otherTitle="Baixar modelo"
								isDownload={true}
							/>
						</If>
						<If condition={!filteredModels?.modeloProdutividade}>
							<NotAvailableModel />
						</If>
						<If condition={loadingDownload}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Anexar arquivo{fieldProductivity ? <em>*</em> : null}
						</Form.Label>
						<Form.File
							ref={defaultForm.register}
							name="productivityAssessment"
							label={lblProductivityAssessment}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblProductivityAssessment(`${target.files[0].name}`);
							}}
						/>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="12. Avaliação da qualidade tecnológica/industrial" required={fieldQuality}>
				<If condition={submited &&
					(fieldQuality && defaultForm.getValues("qualityAssessment") && !defaultForm.getValues("qualityAssessment").length)}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<If condition={!defaultForm.watch().speciesName}>
					<AlertError message={"Selecione 'Nome comum da espécie' primeiro."} />
				</If>
				<If condition={defaultForm.errors.qualityAssessment}>
					<AlertError message={defaultForm.errors?.qualityAssessment?.message} />
				</If>
				<Group_12 name="textoAvaliacaoCultivar" form={defaultForm} culture={cultureDetails} />
				<If condition={cultureDetails && !cultureDetails?.avaliacaoCulivarAsText}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Avaliação da qualidade{fieldQuality ? <em>*</em> : null}
							</Form.Label>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<If condition={filteredModels?.modeloQualidade}>
								<AddButton
									type="button"
									onClick={() => {
										if (filteredModels?.modeloQualidade) {
											downloadModel(filteredModels.modeloQualidade.codigo, filteredModels.modeloQualidade.nome);
										}
									}}
									otherTitle="Baixar modelo"
									isDownload={true}
								/>
							</If>
							<If condition={!filteredModels?.modeloQualidade}>
								<NotAvailableModel />
							</If>
							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Anexar arquivo{fieldQuality ? <em>*</em> : null}
							</Form.Label>
							<Form.File
								ref={defaultForm.register}
								name="qualityAssessment"
								label={lblQualityAssessment}
								data-browse="upload"
								custom
								onChange={({ target }: any) => {
									if (!target.value) {
										return
									}
									setLblQualityAssessment(`${target.files[0].name}`);
								}}
							/>
						</Form.Group>
					</Form.Row>
				</If>
			</Fieldset>

			<Fieldset title="13. Intenção de comercialização" required={fieldCommercialization}>
				<If condition={defaultForm.errors.yearOfcommercialization
					|| defaultForm.errors.experimentalMaterial
				}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Início de comercialização&#40;ano&#41;{fieldCommercialization ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="yearOfcommercialization"
							type="number"
							placeholder="Insira o ano início de comercialização"
							isInvalid={defaultForm.errors.yearOfcommercialization !== undefined}
							maxLength={4}
						/>
						<If condition={defaultForm.errors.yearOfcommercialization}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.yearOfcommercialization?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Quantidade em estoque de material experimental &#45; pré-comercial da cultivar &#40;em kg&#41;{fieldCommercialization ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="experimentalMaterial"
							type="number"
							placeholder="Insira a quantidade em (kg)"
							isInvalid={defaultForm.errors.experimentalMaterial !== undefined}
							maxLength={30}
						/>
						<If condition={defaultForm.errors.experimentalMaterial}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.experimentalMaterial?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="14. Sistemas de produção sugeridos para a cultivar">
				<If condition={defaultForm.errors.productionSystemsForTheCultivar}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Sistemas De Produção Sugeridos{fieldProductionSystem ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							as="textarea"
							ref={defaultForm.register}
							name="productionSystemsForTheCultivar"
							type="text"
							placeholder="Insira as informações dos sistemas de produção"
							isInvalid={defaultForm.errors.productionSystemsForTheCultivar !== undefined}
						/>
						<If condition={defaultForm.errors.productionSystemsForTheCultivar}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.productionSystemsForTheCultivar?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="15. Limitações da cultivar: condições de cultivo e uso que devem ser evitadas" required={fieldLimitationCultivar}>
				<If condition={defaultForm.errors.limitationsOfTheCultivar}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Limitações da cultivar{fieldLimitationCultivar ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							as="textarea"
							ref={defaultForm.register}
							name="limitationsOfTheCultivar"
							type="text"
							placeholder="Insira as informações sobre as limitações da cultivar"
							isInvalid={defaultForm.errors.limitationsOfTheCultivar !== undefined}
						/>
						<If condition={defaultForm.errors.limitationsOfTheCultivar}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.limitationsOfTheCultivar?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="16. Informações adicionais" required={fieldAdditionalInformation}>
				<If condition={defaultForm.errors.additionalInformation}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Informações adicionais{fieldAdditionalInformation ? <em>*</em> : null}
						</Form.Label>
						<Form.Control
							as="textarea"
							ref={defaultForm.register}
							name="additionalInformation"
							type="text"
							placeholder="Insira as informações adicionais"
							isInvalid={defaultForm.errors.additionalInformation !== undefined}
						/>
						<If condition={defaultForm.errors.additionalInformation}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.additionalInformation?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="17. Relatório técnico de obtenção" required>
				<If condition={submited &&
					!defaultForm.getValues("technicalObtainingReport").length}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<If condition={!defaultForm.watch().speciesName}>
					<AlertError message={"Selecione 'Nome comum da espécie' primeiro."} />
				</If>
				<If condition={defaultForm.errors.technicalObtainingReport}>
					<AlertError message={defaultForm.errors?.technicalObtainingReport?.message} />
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Relatório técnico de obtenção<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<If condition={filteredModels?.modeloRelatorioTecnico}>
							<AddButton
								type="button"
								onClick={() => {
									if (filteredModels?.modeloRelatorioTecnico) {
										downloadModel(filteredModels.modeloRelatorioTecnico.codigo, filteredModels.modeloRelatorioTecnico.nome);
									}
								}}
								otherTitle="Baixar modelo"
								isDownload={true}
							/>
						</If>
						<If condition={!filteredModels?.modeloRelatorioTecnico}>
							<NotAvailableModel />
						</If>
						<If condition={loadingDownload}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Anexar arquivo<em>*</em>
						</Form.Label>
						<Form.File
							ref={defaultForm.register}
							type="file"
							name="technicalObtainingReport"
							label={lblTechnicalObtainingReport}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblTechnicalObtainingReport(`${target.files[0].name}`);
							}}
						/>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="18. Declaração do melhorista" required>
				<If condition={submited &&
					!defaultForm.getValues("breederStatement").length}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<If condition={!defaultForm.watch().speciesName}>
					<AlertError message={"Selecione 'Nome comum da espécie' primeiro."} />
				</If>
				<If condition={defaultForm.errors.breederStatement}>
					<AlertError message={defaultForm.errors?.breederStatement?.message} />
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Declaração do melhorista<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<If condition={filteredModels?.modeloDeclaracaoMelhorista}>
							<AddButton
								type="button"
								onClick={() => {
									if (filteredModels?.modeloDeclaracaoMelhorista) {
										downloadModel(filteredModels.modeloDeclaracaoMelhorista.codigo, filteredModels.modeloDeclaracaoMelhorista.nome);
									}
								}}
								otherTitle="Baixar modelo"
								isDownload={true}
							/>
						</If>
						<If condition={!filteredModels?.modeloDeclaracaoMelhorista}>
							<NotAvailableModel />
						</If>
						<If condition={loadingDownload}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Anexar arquivo<em>*</em>
						</Form.Label>
						<Form.File
							ref={defaultForm.register}
							name="breederStatement"
							label={lblBreederStatement}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblBreederStatement(`${target.files[0].name}`);
							}}
							accept=".pdf"
						/>
						<span className="warning-pdfs">{STRINGS.WARNING_PDFS}</span>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="19. Anexos complementares">
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={8}>
						<Form.Label>
							Anexo complementar
						</Form.Label>
						<Form.File
							ref={defaultForm.register}
							name="additionalFiles"
							label={labelAdditionalFiles}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLabelAdditionalFiles(`${target.files[0].name}`);
								setArrFilesToSubmit(target.files);
							}}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<br />
						<AddButton
							onClick={() => {
								if (defaultForm.getValues('additionalFiles').length) {
									setSelectedFilesAdditionalsList()
									setLabelAdditionalFiles('Escolher arquivo...');
								}
							}}
							type="button"
						/>
					</Form.Group>
				</Form.Row>
				<If condition={selectedFilesAdditionals.length}>
					<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedFilesAdditionals}
							defaultColDef={{ flex: 1, sortable: true }}
							suppressRowClickSelection={true}
							onRowSelected={onRowSelectedApplicant}
							rowSelection={'multiple'}
							frameworkComponents={{
								action: actionAdditionalFiles,
							}}
						>
							<AgGridColumn
								headerName="Arquivo"
								field="name"
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
							/>
						</AgGridReact>
					</div>
				</If>
			</Fieldset>

			<Fieldset title="20. Observações Gerais" disabled={!can_edit('preenchimento_observacoes')}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Control
							as="textarea"
							ref={defaultForm.register}
							name="generalObservations"
							type="text"
							placeholder="Insira as observações gerais"
							rows={5}
						/>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<div className="button-position">
				<Button
					title="Voltar"
					type="button"
					contained={false}
					onClick={() => history.goBack()}
				/>
				<GenericButton
					title="Salvar e enviar para regulatório"
					variant="btn-warning"
					onClick={() => {
						defaultForm.trigger().then(validated => {
							setSubmited(validated);
							setRegulatoryModal(validated);
							if (!validated) {
								setErrorOnForm(true);
								window.scrollTo({ top: 0, behavior: 'smooth' });
							}
						})
					}}
					loading={loadingSubmit}
				/>
				<Button
					title="Salvar rascunho"
					type="button"
					contained={true}
					onClick={() => {
						setIsDraft(true);
						onSubmitDraft();
					}}
					isLoading={loadingDraft}
				/>
			</div>

			<If condition={regulatoryModal}>
				<ConfirmationModal
					show={regulatoryModal}
					onHide={() => {
						setRegulatoryModal(false);
						setIsRegulatory(false);
					}}
					title={STRINGS.REGULATORY_TITLE}
					confirmationMsg={STRINGS.REGULATORY_ACTION}
					onConfirm={() => {
						setRegulatoryModal(false);
						onSubmit();
					}}
					loading={isRegulatory ? loadingSubmit : false}
					variant="success"
					color="white"
				>
					<p>{STRINGS.REGULATORY_MESSAGE}</p>
				</ConfirmationModal>
			</If>
		</Form>
	</div>;
}