import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
	//BrowserRouter as Router,
	useParams,
	useHistory
} from "react-router-dom";
import { Form, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import NavigationMessage, { MessageContent, MessageType } from "../../../components/NavigationMessages";
import { STRINGS_LPMA } from "../../../util/lpma/string";
import { STATUS, STATUSTEXT } from "../../../util/lpma/status";

import DropDown from "../../../components/form/DropDown";
import Grid from "../../../components/form/Grid";
import TabControl from "../../../components/form/TabControl";
import TextField from "../../../components/form/TextField";
import TextFields, { Field as TField } from "../../../components/form/TextFields";
import TextArea from "../../../components/form/TextArea";
import FileUpload from "../../../components/form/FileUpload";
import DateField from "../../../components/form/DateField";
import DataHide from "../../../components/form/DataHide";
import Select from 'react-select';
import Fieldset from "../../../components/Fieldset";
import Button from "../../../components/Button";
import GenericButton from '../../../components/FlowGenericButton';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import api, { apiFileDownload } from '../../../services/api';

import LpmaDialogs, { LpmaDialogShow } from "../lpmaDialogs";

import { useLocation } from "react-router-dom";
import {
	LpmaRN35,
	LpmaEquipeTecnica, LpmaLocal, LpmaProjeto,
	Lpma_RN_PotencialConstrucoes,
	Lpma_RN35_ProcessosAnteriores,
	Lpma_RN_EquipeTecnica,

	LpmaRNEnsaio,
	LpmaRNArea,
	LpmaRNEnsaioAvaliacaoAgronomica,
	LpmaRNEnsaioTratamento,
	LpmaRNArea_Ensaio,
	LpmaProjeto_Aprovados,
	Lpma_RN_GenesInteresse,
	LPMA_GenesInteresseGrid,
	LPMA_GenesInteresseList
} from "../../../models/modelsLPMA";

import LpmaService, { RolesService } from "../services";
import { STRINGS } from "../../../util/strings";
import { uuid } from "../../../services/uuid";

import "./../styles.scss";
import RichText from "../../../components/form/RichText";
import { listEnumItem, ParamsType, StateType } from "../../../models/modelCommum";
import { isNumber } from "../../../util/jsextends";
import { EditField } from "../../../components/page/EditView";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import If from '../../../components/If';
import EditButton from '../../../components/EditButton';
import DeleteButton from '../../../components/DeleteButton';
import Modal from '../../../components/Modal';
import AddButton from '../../../components/AddButton';


export default function Edit() {
	const MODO_VIEW = {
		DADOS_PRINCIPAIS: 1,
		PROTOCOLO_EXPERIMENTAL: 2,
		LOCAIS_ENSAIO: 3,
		CONSTRUCOES: 4,
		GENES: 5,
		REGULATORIO: 6
	}

	const LpmaServiceApi = new LpmaService();
	const LpmaRoles = new RolesService(useContext);

	const UrlParam = useParams<ParamsType>();
	const formRef = useRef<HTMLFormElement>(null);

	const GoBackToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }) }

	const location = useLocation<StateType>();
	const history = useHistory();

	const [itemTecnicoPrincipal, setItemTecnicoPrincipal] = useState<LpmaEquipeTecnica>();
	const [itemsEquipeTecnica, setItemsEquipeTecnica] = useState<LpmaEquipeTecnica[]>();
	const [itemsProjetos, setItemsProjetos] = useState<LpmaProjeto[]>();
	const [itemsLocais, setItemsLocais] = useState<LpmaLocal[]>();
	const [itemsTipoTratamento, setItemsTipoTratamento] = useState<any[]>();
	const [itemsPorcentagemFitotoxidez, setListPorcentagemFitotoxidez] = useState<any[]>();
	const [itemsPotenciaisConstrucoes, setItemsPotenciaisConstrucoes] = useState<Lpma_RN_PotencialConstrucoes[]>([]);
	const [itemsProcessosAnteriores, setItemsProcessosAnteriores] = useState < any[] > ([]);
	const [unidadeArea, setUnidadeArea] = useState<any[]>();
	const [entity, setEntity] = useState<LpmaRN35>();

	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [estagio, setEstagio] = useState<any>(STATUS.EmPreenchimento);
	const [editarAll, setEditarAll] = useState<boolean>(false);
	const [editarReg, setEditarReg] = useState<boolean>(false);
	const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [msgSuccessSubmit, setMsgSuccessSubmit] = useState<string>("");
	const [msgErrorSubmit, setMsgErrorSubmit] = useState<any>(null);
	const [enviarRegulatorio, setEnviarRegulatorio] = useState<boolean>(false);

	const [itemRemovedEnsaios, setItemRemovedEnsaios] = useState<any[]>([]);
	const [itemRemovedAreas, setItemRemovedAreas] = useState<any[]>([]);
	const [itemsGenesInteresse, setItemsGenesInteresse] = useState<LPMA_GenesInteresseGrid[]>([]);

	const [lpmaDlgShow, setLpmaDlgShow] = useState<LpmaDialogShow>();

	const [titleForm, setTitleForm] = useState<string>(`${STRINGS_LPMA.NEW_LPMA_RN} RN35`);

	// GENES DE INTERESSE
	const [modalGeneInteresse, setModalGeneInteresse] = useState<boolean>(false);
	const [modalEditGeneInteresse, setModalEditGeneInteresse] = useState<boolean>(false);

	const [modoFluxo, setModoFluxo] = useState<Number>(MODO_VIEW.DADOS_PRINCIPAIS);

	const [geneInteresseToEdit, setGeneInteresseToEdit] = useState<LPMA_GenesInteresseGrid>({
		contagem: 0,
		id: 0,
		lpma_Id: 0,
		construcaoId: 0,
		nomeConstrucao: '',
		nomeGeneInteresse: '',
		organismoDoadorGeneInteresse: '',
		funcaoGeneInteresse: '',
		funcaoResumida: '',
	});

	const geneInteresseSchema = yup.object({
	});

	const { getValues: getValuesGeneInteresse, control: controlGeneInteresse, register: registerGeneInteresse, errors: errorsGeneInteresse, handleSubmit: handleSubmitGeneInteresse, trigger: triggerGeneInteresse, watch: watchGeneInteresse } = useForm({
		mode: 'all',
		resolver: yupResolver(geneInteresseSchema)
	})

	const onSubmitGeneInteresse = (info: LPMA_GenesInteresseList): void => {

		const Construcao = itemsPotenciaisConstrucoes.filter(x => x.nomeContrucao === info.construcao!.label)[0];

		setItemsGenesInteresse([...itemsGenesInteresse, {
			contagem: itemsGenesInteresse!.length + 1,
			id: 0,
			lpma_Id: entity!.id,
			construcaoId: Construcao.id,
			nomeConstrucao: Construcao.nomeContrucao,
			nomeGeneInteresse: info.nomeGeneInteresse,
			organismoDoadorGeneInteresse: info.organismoDoadorGeneInteresse,
			funcaoGeneInteresse: info.funcaoGeneInteresse,
			funcaoResumida: info?.funcaoGeneInteresse?.substring(0, 50) + "[...]",

		}]);

		setModalGeneInteresse(false);
	}

	const geneInteresseEditSchema = yup.object({
	});

	const { control: controlEditGeneInteresse, register: registerEditGeneInteresse, errors: errorsEditGeneInteresse, handleSubmit: handleSubmitEditGeneInteresse, trigger: triggerEditGeneInteresse, watch: watchEditGeneInteresse } = useForm({
		mode: 'all',
		resolver: yupResolver(geneInteresseEditSchema)
	})

	const onSubmitEditGeneInteresse = (info: any): void => {

		var arr = itemsGenesInteresse.filter(e => e.contagem !== (info.editContagem * 1));

		const Construcao = itemsPotenciaisConstrucoes.filter(x => x.nomeContrucao === info.editConstrucao!.label)[0];

		setItemsGenesInteresse([...arr, {
			contagem: info.editContagem,
			id: 0,
			lpma_Id: entity!.id,
			construcaoId: Construcao.id,
			nomeConstrucao: Construcao.nomeContrucao,
			organismoDoadorGeneInteresse: info.editOrganismoDoadorGeneInteresse,
			funcaoGeneInteresse: info.editFuncaoGeneInteresse,
			funcaoResumida: info.editFuncaoGeneInteresse.substring(0, 50) + "[...]",

		}]);

		setModalEditGeneInteresse(false);
	}


	//Page notification
	const [pageMessages, setPageMessages] = useState<MessageContent[]>([]);
	//const hasPageMessages = () : boolean => {return pageMessages.length > 0;}
	const clearPageMessages = async () => { await setPageMessages([]); }
	const addPageMessages = (message: MessageContent, clearMessages: boolean = true) => {
		let currentPageMessages: MessageContent[] = !clearMessages ? pageMessages : [];
		setPageMessages([]);
		currentPageMessages.push(message);
		setPageMessages(currentPageMessages);
	}
	const setSuccessMessageAndRedirect = (message: string, callback: any) => {
		//setStatus(statuses.idle);
		addPageMessages({ type: MessageType.success, content: message });
		GoBackToTop();
		setTimeout(() => { callback(); }, 3500);
	}

	//Loading screen
	const [loading, setLoading] = useState<string[]>([]);
	const setLoadingScreen = (tag: string, type: boolean = true) => {

		let currentLoading = loading;
		let index = currentLoading.indexOf(tag);

		if (type) {
			if (index >= 0)
				return;

			currentLoading.push(tag);
		}
		else {
			if (index <= -1)
				return;

			currentLoading.splice(index, 1);
		}

		setLoading([]);
		setLoading(currentLoading);
	}
	//const isLoadingScreen = (tag:string) : boolean => {return loading.indexOf(tag) >= 0;}

	//const [valTitulo, setValTitulo] = useState<boolean>(false );
	// const validacaoTitulo = valTitulo ? {
	//     titulo : yup.string()
	// 	.required(STRINGS.FORM_FIELD_REQUIRED),
	// } : {
	//     titulo : yup.string()
	// }


	const defaultForm = useForm({
		defaultValues: entity,
		mode: "onSubmit",
		resolver: yupResolver(yup.object().shape({
			...((estagio === STATUS.EmAnalisePeloRegulatorio) ? {
				titulo: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
				//projeto: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				//tecnicoPrincipal: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				//ensaios		: 		yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				ensaios: yup.array().of(yup.object().shape({
					descricao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					//croquiFileTemp	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
					largura: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					comprimento: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					parcelaLargura: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					parcelaComprimento: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					densidade: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					numeroRepeticoes: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					percentualSemente: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),

					tratamentos: yup.array().of(yup.object().shape({
						descricao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
						dose: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
						estagioAplicacao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),

					}))
						.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED},  requer pelo menos 1 tipo de tratamento`)
						.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 tipo de tratamento`),

					avaliacaoAgronomica: yup.array().of(yup.object().shape({
						pE11AD1: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
						descricao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),

					}))
						.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED},  requer pelo menos 1 avaliação agronomica`)
						.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 avaliação agronomica`),

				}))
					.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 ensaio`)
					.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 ensaio`),

				areas: yup.array().of(yup.object().shape({
					//descricao	: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					local: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
					tecnicoResponsavel: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
					areaEnsaio: yup.array().of(yup.object().shape({}))
						.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 ensaio`)
						.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 ensaio`),
				}))
					.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 area`)
					.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 area`),

				construcoes: yup.array()
					.of(yup.object().shape({
						nomeContrucao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
					}))
					.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 construção`)
					.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 construção`),


			} : {
				titulo: yup.string(),
				projeto: yup.object(),
				tecnicoPrincipal: yup.object(),
				ensaios: yup.array(),
				areas: yup.array(),
				construcoes: yup.array(),
				genesInteresse: yup.array(),
			}),

			...((estagio === STATUS.Concluido || estagio === STATUS.Aprovado) ? {
				dataProposta: yup.date().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				dataEndosso: yup.date().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				equipeTecnica: yup.array().of(yup.object().shape({
					tecnico: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
				})).min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 técnico na Lista de Equipe Técnica`),
			} : {
				dataProposta: yup.object().nullable(),
				dataEndosso: yup.object().nullable(),
				equipeTecnica: yup.object().nullable()
			}),

			...((estagio === STATUS.EmCorrecao) ? {
				observacoesRegulatorio: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
			} : {
				observacoesRegulatorio: yup.string(),
			}),


		})),
	});

	const EnsaiosList = () => {
		const list = defaultForm.watch('ensaios') ?? defaultForm.getValues('ensaios')
		if (!list) return [];
		list.forEach((e: any, index: number) => {
			if (!e.uid) {
				e.uid = uuid()
				defaultForm.register(`ensaios.${index}.uid`)
				defaultForm.setValue(`ensaios.${index}.uid`, e.uid);
			}
		});
		return list;
	}

	useEffect(() => {

		clearPageMessages();
		setModoFluxo(MODO_VIEW.DADOS_PRINCIPAIS);

		const getDataToForm = async (FinishHandler: any) => {
			setLoadingScreen('get_data_form');

			try {

				const unidadesArea_ = [
					{ label: 'm2', value: 1 },
					{ label: 'ha', value: 2 },
				];
				const locais_ = await LpmaServiceApi.getLocais();
				const eqTecn_ = await LpmaServiceApi.getEquipeTecnica({ ativo: true });
				const tpTrat_ = await LpmaServiceApi.getEnsaioTipoTratamento();
				const porcFt_ = await LpmaServiceApi.getListPorcentagemFitotoxidez();
				const proj_ = await LpmaServiceApi.getProjetos({ ativo: true });

				setUnidadeArea(unidadesArea_);
				setItemsLocais(locais_);
				setItemsTipoTratamento(tpTrat_);
				setItemsEquipeTecnica(eqTecn_);
				setListPorcentagemFitotoxidez(porcFt_);
				setItemsProjetos(proj_);

				const tecPrin = eqTecn_.find(e => e.userName === LpmaRoles.getUserName() && e.ativo)
				setItemTecnicoPrincipal(tecPrin)

				FinishHandler(unidadesArea_, locais_, eqTecn_, tpTrat_, proj_);
			}
			catch (error: any) {
				console.error(error);
				//ParseDefaultBackendMessage(error);
				GoBackToTop();
			}

			setLoadingScreen('get_data_form', false);
		};

		const getEntity = async (unidadesArea_: any[], locais_: any[], eqTecn_: any[], tpTrat_: any[], proj_: any[]) => {
			// setLoadingScreen('get_data_entity');
			try {
				const dados = await LpmaServiceApi.getRN35ById(UrlParam.id);
				setFormData(dados, unidadesArea_, locais_, eqTecn_, tpTrat_, proj_);

				if (dados.processosAnteriores !== null)
					setItemsProcessosAnteriores(dados!.processosAnteriores!);

				if (dados.construcoes !== null)
					setItemsPotenciaisConstrucoes(dados!.construcoes!);

				if (dados?.genesInteresse?.length) {
					// Carregando genes de Interesse
					let genesInteresse: LPMA_GenesInteresseGrid[] = [];

					for (let n = 0, t = dados.genesInteresse.length; n < t; n++) {

						let geneInteresseLista = dados.genesInteresse[n];
						const Construcao = dados.construcoes!.filter(e => e.id === geneInteresseLista.construcaoId)[0];

						let geneInteresse: LPMA_GenesInteresseGrid = {
							contagem: n + 1,
							id: geneInteresseLista.id,
							lpma_Id: geneInteresseLista.lpma_Id,
							construcaoId: geneInteresseLista.construcaoId,
							nomeConstrucao: Construcao?.nomeContrucao,
							nomeGeneInteresse: geneInteresseLista.nomeGeneInteresse,
							organismoDoadorGeneInteresse: geneInteresseLista.organismoDoadorGeneInteresse,
							funcaoGeneInteresse: geneInteresseLista.funcaoGeneInteresse,
							funcaoResumida: geneInteresseLista?.funcaoGeneInteresse?.substring(0, 50) + "[...]",

						};

						genesInteresse.push(geneInteresse);
					};

					setItemsGenesInteresse(genesInteresse);
				}

				if (UrlParam.modo !== 'editar') {
					//!visualizar
					setEditarAll(false);
					setEditarReg(false);
					setTitleForm(`${STRINGS_LPMA.VIEW_LPMA_RN} RN35`)
				}
				else {
					setTitleForm(`${STRINGS_LPMA.EDIT_LPMA_RN} RN35`)
				}
			}
			catch (error: any) {
				console.error(error);
				//ParseDefaultBackendMessage(error);
				GoBackToTop();
			}

			//setLoadingScreen('get_data_entity');

		};

		getDataToForm((unidadesArea_: any[], locais_: any[], eqTecn_: any[], tpTrat_: any[], proj_: any[]) => {
			if (UrlParam && UrlParam.id) { getEntity(unidadesArea_, locais_, eqTecn_, tpTrat_, proj_); }
			else { setEditarAll(true); }
		})

	}, [location.state, defaultForm.reset]);

	const [aprovadosInit, setAprovadosInit] = useState<LpmaProjeto_Aprovados[]>();

	useEffect(() => {
		if (!itemsProjetos) { return; }

		const p = defaultForm.watch("projeto");
		if (p) {
			if (!(!aprovadosInit)) {
				const jaAdd = aprovadosInit.filter(e => e.projeto_Id === p.value).length !== 0;
				if (jaAdd) { return; }
			}
			const projSel = itemsProjetos?.find(e => e.id === p.value);
			if (projSel) {
				const aprovados = projSel.aprovados?.map((e: any) => {
					return {
						...e,
						uid: uuid(),
						dataDecisao: e.dataDecisao ? moment(e.dataDecisao).toDate() : null
					} as LpmaProjeto_Aprovados;
				})
				if ((aprovados?.length ?? 0) > 0) {
					setAprovadosInit(aprovados);
				}
			}
		}
		else if ((aprovadosInit?.length ?? 0) > 0) {
			setAprovadosInit([]);
			defaultForm.setValue("processosAnteriores", []);
		}

	}, [defaultForm.watch]);
	//[defaultForm.watch]);

	const getByLocalRef = (locais_: any[], e: any) => {
		let elmLocal = locais_.find(elm => elm.id === e.lpma_LocalId);
		return { ...elmLocal, value: e.lpma_LocalId, label: elmLocal?.nomeDaFazenda };
	}

	const getByTecnicoRef = (tecnicos_: any[], tecId: any) => {
		let elmEquipe = tecnicos_.find(elm => elm.id === tecId);
		return { ...elmEquipe, value: tecId, label: elmEquipe?.nome };
	}

	const getByTipTratRef = (tpTrat_: any[], Tid: any) => {
		let elm = tpTrat_?.find(elm => elm.id === Tid);
		return { value: Tid, label: elm?.nome };
	}

	const actionsGene = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				var gene = props.data;

				setGeneInteresseToEdit({
					contagem: gene.contagem,
					id: gene.id,
					lpma_Id: gene.lpma_Id,
					construcaoId: gene.construcaoId,
					nomeConstrucao: gene.nomeConstrucao,
					nomeGeneInteresse: gene.nomeGeneInteresse,
					organismoDoadorGeneInteresse: gene.organismoDoadorGeneInteresse,
					funcaoGeneInteresse: gene.funcaoGeneInteresse,
				});

				setModalEditGeneInteresse(true);

			}} />
			<DeleteButton
				onClick={() => {
					setItemsGenesInteresse([]);
					let arr: any = [];
					let novaLista: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));

					for (let i = 0, t = arr.length; i < t; i++) {
						let geneInteresseLista = arr[i];

						let geneInteresse: LPMA_GenesInteresseGrid = {
							contagem: i + 1,
							id: geneInteresseLista.id,
							lpma_Id: geneInteresseLista.lpma_Id,
							construcaoId: geneInteresseLista.construcaoid,
							nomeConstrucao: geneInteresseLista.nomeConstrucao,
							nomeGeneInteresse: geneInteresseLista.nomeGeneInteresse,
							organismoDoadorGeneInteresse: geneInteresseLista.organismoDoadorGeneInteresse,
							funcaoGeneInteresse: geneInteresseLista.funcaoGeneInteresse,
							funcaoResumida: geneInteresseLista.funcaoGeneInteresse.substring(0, 50) + "[...]",
						};

						novaLista.push(geneInteresse);

					}

					setItemsGenesInteresse(novaLista);
				}}
			/>

		</Fragment>
	)

	const setFormData = (dados: any, unidadesArea_?: any, locais_?: any, tecnicos_?: any, tpTrat_?: any, proj_?: any) => {
		unidadesArea_ = unidadesArea_ ?? unidadeArea;
		locais_ = locais_ ?? itemsLocais;
		tecnicos_ = tecnicos_ ?? itemsEquipeTecnica;
		tpTrat_ = tpTrat_ ?? itemsTipoTratamento;
		proj_ = proj_ ?? itemsProjetos;

		let projeto = proj_.filter((x: { id: any; }) => x.id == dados?.projetoId)[0];
		const projDef = projeto !== undefined ? `${projeto.nome} (${projeto.cultura})` : '';

		const tecDef = dados?.tecnicoPrincipal?.nome !== undefined ? dados.tecnicoPrincipal.nome : dados?.tecnicoPrincipal?.label ?? '';

		const parsenData = {
			...dados,
			projeto: { value: dados?.projetoId, label: projDef },
			tecnicoPrincipal: { value: dados?.tecnicoPrincipalId, label: tecDef },

			dataPrevistaInicioDe: dados.dataPrevistaInicioDe ? moment(dados.dataPrevistaInicioDe).toDate() : null,
			dataPrevistaInicioAte: dados.dataPrevistaInicioAte ? moment(dados.dataPrevistaInicioAte).toDate() : null,
			dataPrevistaFimDe: dados.dataPrevistaFimDe ? moment(dados.dataPrevistaFimDe).toDate() : null,
			dataPrevistaFimAte: dados.dataPrevistaFimAte ? moment(dados.dataPrevistaFimAte).toDate() : null,

			ensaios: dados.ensaios?.map((e: any) => {
				return {
					...e,
					uid: uuid(),
					tratamentos: e.tratamentos?.map((t: any) => {
						return {
							...t,
							uid: uuid(),
							tipoTratamento: getByTipTratRef(tpTrat_, t.tipoTratamento.value ?? t.tipoTratamento),
						}
					}),
				}
			}),
			areas: dados.areas?.map((e: any) => {
				return {
					...e,
					uid: uuid(),
					tecnicoResponsavel: getByTecnicoRef(tecnicos_, e?.tecnicoResponsavelId),
					local: getByLocalRef(locais_, e)
				}
			}),

			dataEndosso: dados.dataEndosso ? moment(dados.dataEndosso).toDate() : null,
			dataProposta: dados.dataProposta ? moment(dados.dataProposta).toDate() : null,

			processosAnteriores: dados.processosAnteriores?.map((e: any) => {
				return {
					...e,
					uid: uuid(),
					dataDecisao: e.dataDecisao ? moment(e.dataDecisao).toDate() : null
				}
			}),
			equipeTecnica: dados.equipeTecnica?.map((e: any) => {
				return {
					...e,
					uid: uuid(),
					tecnico: getByTecnicoRef(tecnicos_, e?.tecnico_Id),
				}
			}),
			dataPrevistaColheira: dados.dataPrevistaColheira ? moment(dados.dataPrevistaColheira).toDate() : null,
			epocas: dados.epocas ? dados.epocas : 0,
			dataPrevistaColheitaInicio: dados.dataPrevistaColheitaInicio ? moment(dados.dataPrevistaColheitaInicio).toDate() : null,
			dataPrevistaColheitaTermino: dados.dataPrevistaColheitaTermino ? moment(dados.dataPrevistaColheitaTermino).toDate() : null,

			construcoes: dados.construcoes,
		}

		parsenData.areas?.forEach((areas: any) => {

			if (areas.area_Ensaio !== undefined) {
				areas.area_Ensaio.forEach(
					(dd: any) => { }
				)
				areas.areaEnsaio = areas.area_Ensaio.map((e: any, i: number) => {
					const ensaioDef = parsenData.ensaios.find((ens: any) => ens.id === e.ensaioId);
					return {
						...e,
						...ensaioDef,
						value: ensaioDef.id,
						label: ensaioDef.descricao,
					}
				})
			}
		})		

		let estagio_ = parsenData.status ?? STATUS.EmPreenchimento;
		defaultForm.reset(parsenData);
		setEntity(parsenData);
		setEstagio(estagio_);
		setEditarAll(((estagio_ === STATUS.EmPreenchimento || estagio_ === STATUS.EmCorrecao) && LpmaRoles.isCreatOREditRequisicao()) || LpmaRoles.isAdmin());
		setEditarReg((estagio_ === STATUS.EmAnalisePeloRegulatorio && LpmaRoles.isRegulatorio()) || LpmaRoles.isAdmin());

		setItemRemovedAreas([])
		setItemRemovedEnsaios([])
	};

	const concluirLPMA = async () => {
		try {
			setLoadingSubmit(true);
			setSuccessSubmit(false);
			setErrorSubmit(null);

			const dados = await submitFormUpdateStatus('concluir', STRINGS_LPMA.ALERT_STATUS_CONCLUIDA_SUCCESS);
			setLoadingSubmit(false);
			setSuccessSubmit(true);
			setMsgSuccessSubmit(STRINGS_LPMA.ALERT_STATUS_CONCLUIDA_SUCCESS);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} catch (error: any) {
			console.error(error);
			setLoadingSubmit(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);

		}
	}

	const aprovarLPMA = async () => {
		try {
			setLoadingSubmit(true);
			setSuccessSubmit(false);
			setErrorSubmit(null);

			const dados = await submitFormSaveData('aprovar', STRINGS_LPMA.ALERT_STATUS_APROVADA_SUCCESS); 
			setLoadingSubmit(false);
			setSuccessSubmit(true);
			setMsgSuccessSubmit(STRINGS_LPMA.ALERT_STATUS_APROVADA_SUCCESS);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} catch (error: any) {
			console.error(error);
			setLoadingSubmit(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);

		}
	}

	const solicitarCorrecao = async () => {
		try {
			setLoadingSubmit(true);
			setSuccessSubmit(false);
			setErrorSubmit(null);

			const dados = await submitFormUpdateStatus('correcao', STRINGS_LPMA.ALERT_STATUS_CORRECAO_SUCCESS);
			setLoadingSubmit(false);
			setSuccessSubmit(true);
			setMsgSuccessSubmit(STRINGS_LPMA.ALERT_STATUS_CORRECAO_SUCCESS);

			window.scrollTo({ top: 0, behavior: 'smooth' });
		} catch (error: any) {
			console.error(error);
			setLoadingSubmit(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);

		}
	}

	const salvarRegulatorio = async () => {
		try {
			setLoadingSubmit(true);
			setSuccessSubmit(false);
			setErrorSubmit(null);

			const dados = await submitFormSaveData('save_regulatorio', STRINGS_LPMA.ALERT_STATUS_REGULATORIO_SUCCESS);
			setLoadingSubmit(false);
			setSuccessSubmit(true);
			setMsgSuccessSubmit(STRINGS_LPMA.ALERT_STATUS_REGULATORIO_SUCCESS);

			window.scrollTo({ top: 0, behavior: 'smooth' });
		} catch (error: any) {
			console.error(error);
			setLoadingSubmit(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);

		}
	}

	const salvarRascunhoLPMA = async () => {
		try {
			setLoadingSubmit(true);
			setSuccessSubmit(false);
			setErrorSubmit(null);

			const dados = await submitFormSaveData('save', STRINGS_LPMA.ALERT_MESSAGE_SUCCESS);

			setLoadingSubmit(false);
			//setSuccessSubmit(true);
			//setMsgSuccessSubmit(STRINGS_LPMA.ALERT_MESSAGE_SUCCESS);
			//window.scrollTo({ top: 0, behavior: 'smooth' });
		} catch (error: any) {
			console.error(error);
			setLoadingSubmit(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);

		}
	}

	const submitForm = async () => {
		switch (estagio) {
			case STATUS.EmpAprovacao:
				await submitFormSaveData('aprovar', STRINGS_LPMA.ALERT_STATUS_APROVADA_SUCCESS); break;
			case STATUS.EmCorrecao:
				await submitFormUpdateStatus('correcao', STRINGS_LPMA.ALERT_STATUS_CORRECAO_SUCCESS); break;
			case STATUS.EmAnalisePeloRegulatorio:
				await submitFormSaveData('save_regulatorio', STRINGS_LPMA.ALERT_STATUS_REGULATORIO_SUCCESS); break;
			case STATUS.Concluido:
				await submitFormUpdateStatus('concluir', STRINGS_LPMA.ALERT_STATUS_CONCLUIDA_SUCCESS); break;
			default:
				await submitFormSaveData('save', STRINGS_LPMA.ALERT_MESSAGE_SUCCESS); break;

		}

	}

	const gravarDados = (fluxo: any, regulatorio: boolean = false) => {

		setSuccessSubmit(false);
		setErrorSubmit(null);

		const getValues = Object.assign(defaultForm.getValues());
		var finalizado = entity?.status === STATUS.Aprovado;

		let dados: LpmaRN35 =
		{
			titulo: "",
			objetivo: "",
		};

		if (entity !== undefined)
			dados = entity;

		if (fluxo === MODO_VIEW.DADOS_PRINCIPAIS) {
			let projeto = defaultForm.getValues('projeto');
			let tecnicoPrincipal = defaultForm.getValues('tecnicoPrincipal');

			if (dados != null) {
				dados.titulo = defaultForm.getValues('titulo');
				dados.projeto = projeto;
				if (projeto !== undefined)
					dados.projetoId = projeto?.value;

				dados.tecnicoPrincipal = tecnicoPrincipal;

				if (tecnicoPrincipal !== undefined)
					dados.tecnicoPrincipalId = tecnicoPrincipal.value;

				dados.epocas = defaultForm.getValues('epocas');

				if (getValues.dataPrevistaInicioDe !== undefined)
					dados.dataPrevistaInicioDe = getValues.dataPrevistaInicioDe ? (new Date(getValues.dataPrevistaInicioDe)).toUTCString() : null;
				if (getValues.dataPrevistaInicioAte !== undefined)
					dados.dataPrevistaInicioAte = getValues.dataPrevistaInicioAte ? (new Date(getValues.dataPrevistaInicioAte)).toUTCString() : null;
				if (getValues.dataPrevistaFimDe !== undefined)
					dados.dataPrevistaFimDe = getValues.dataPrevistaFimDe ? (new Date(getValues.dataPrevistaFimDe)).toUTCString() : null;
				if (getValues.dataPrevistaFimAte !== undefined)
					dados.dataPrevistaFimAte = getValues.dataPrevistaFimAte ? (new Date(getValues.dataPrevistaFimAte)).toUTCString() : null;
				if (getValues.dataPrevistaColheitaInicio !== undefined)
					dados.dataPrevistaColheitaInicio = getValues.dataPrevistaColheitaInicio ? (new Date(getValues.dataPrevistaColheitaInicio)).toUTCString() : null;
				if (getValues.dataPrevistaColheitaTermino !== undefined)
					dados.dataPrevistaColheitaTermino = getValues.dataPrevistaColheitaTermino ? (new Date(getValues.dataPrevistaColheitaTermino)).toUTCString() : null;

				dados.objetivo = defaultForm.getValues('objetivo');

				dados.protocoloExperimentalTexto = getValues.protocoloExperimentalTexto ? defaultForm.getValues('protocoloExperimentalTexto') : "";
				dados.motivoCancelamento = defaultForm.getValues('motivoCancelamento') ? defaultForm.getValues('motivoCancelamento') : "";
			}
		}
		else if (fluxo === MODO_VIEW.PROTOCOLO_EXPERIMENTAL) {
			if (dados != null) {
				const getValues = Object.assign(defaultForm.getValues())

				const ensaiosLst = getValues.ensaios?.filter((e: any, index: any) => {
					return itemRemovedEnsaios.indexOf(index) === -1;
				})
				let ensaiosTmp = ensaiosLst?.map((e: any) => {
					return {
						...e,
						id: isNumber(e.id) === true ? e.id : null,
					}
				});

				dados.ensaios = ensaiosTmp;
			}
		}
		else if (fluxo === MODO_VIEW.LOCAIS_ENSAIO) {
			if (dados != null) {
				const getValues = Object.assign(defaultForm.getValues())

				const areasLst = getValues.areas?.filter((e: any, index: any) => {
					return itemRemovedAreas.indexOf(index) === -1;
				})

				let areasTmp = areasLst?.map((e: any) => {
					return {
						...e,
						id: isNumber(e.id) === true ? e.id : null,
						lpma_LocalId: e.local?.value,
						tecnicoResponsavelId: e.tecnicoResponsavel?.value,
					}
				});

				dados.areas = areasTmp;
			}
		}
		else if (fluxo === MODO_VIEW.CONSTRUCOES) {
			let construcoesTmp: Lpma_RN_PotencialConstrucoes[] = [];
			let construcoes = defaultForm.getValues('construcoes');

			if (construcoes != null) {
				for (let i = 0, t = construcoes.length; i < t; i++) {
					construcoesTmp.push(construcoes[i]);
				}
			}

			let processosAnterioresTmp: Lpma_RN35_ProcessosAnteriores[] = [];
			let processosAnteriores = defaultForm.getValues('processosAnteriores');

			if (processosAnteriores != null) {
				for (let i = 0, t = processosAnteriores.length; i < t; i++) {
					processosAnterioresTmp.push(processosAnteriores[i]);
				}
			}

			if (dados != null) {
				setItemsPotenciaisConstrucoes(construcoesTmp);
				dados.construcoes = construcoesTmp;
				setItemsProcessosAnteriores(processosAnterioresTmp);
				dados.processosAnteriores = processosAnterioresTmp;
			}
		}
		else if (fluxo === MODO_VIEW.GENES) {
			let genesTmp: Lpma_RN_GenesInteresse[] = [];

			for (let i = 0, t = itemsGenesInteresse.length; i < t; i++) {
				let geneInteresse: Lpma_RN_GenesInteresse = {
					id: itemsGenesInteresse[i].id,
					lpma_Id: itemsGenesInteresse[i].lpma_Id,
					construcaoId: itemsGenesInteresse[i].construcaoId,
					nomeConstrucao: itemsGenesInteresse[i].nomeConstrucao,
					nomeGeneInteresse: itemsGenesInteresse[i].nomeGeneInteresse,
					organismoDoadorGeneInteresse: itemsGenesInteresse[i].organismoDoadorGeneInteresse,
					funcaoGeneInteresse: itemsGenesInteresse[i].funcaoGeneInteresse,
				};

				genesTmp.push(geneInteresse);
			}

			if (dados != null) {
				dados.genesInteresse = genesTmp;
			}
		}
		else if (fluxo === MODO_VIEW.REGULATORIO) {
			let equipeTecnicaTmp: LpmaEquipeTecnica[] = [];
			let equipeTecnica = defaultForm.getValues('equipeTecnica');

			if (equipeTecnica != null) {
				for (let i = 0, t = equipeTecnica.length; i < t; i++) {
					equipeTecnicaTmp.push(equipeTecnica[i]);
				}
			}

			if (dados != null) {
				dados.dataEndosso = defaultForm.getValues('dataEndosso') ? (new Date(getValues.dataEndosso)).toUTCString() : undefined;
				dados.dataProposta = defaultForm.getValues('dataProposta') ? (new Date(getValues.dataProposta)).toUTCString() : undefined;
				dados.observacoesRegulatorio = defaultForm.getValues('observacoesRegulatorio') ?? '';

				dados.equipeTecnica = equipeTecnicaTmp;
			}
		}

		setFormData(dados, null, null, null, null, null);
		setEntity(dados);

		if (dados.ensaios !== undefined && !regulatorio && !finalizado)
			salvarRascunhoLPMA();

		return dados;

	};


	const submitFormUpdateStatus = async (acao: string, msgSucess: string) => {
		try {
			setLoadingSubmit(true);	
			setSuccessSubmit(false);
			setErrorSubmit(null);

			let formData = new FormData();
			formData.append("id", entity?.id);
			formData.append("observacoesRegulatorio", entity?.observacoesRegulatorio ?? "");
			formData.append("observacoes", entity?.observacoes ?? "");

			const dados = await LpmaServiceApi.getRN35Action(acao, formData);

			setLoadingSubmit(false);
			setSuccessSubmit(true);
			window.scrollTo({ top: 0, behavior: 'smooth' });

		} catch (error: any) {
			console.error(error);
			setLoadingSubmit(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);

		}
		setLoadingSubmit(false);
	};

	const submitFormSaveData = async (acao: string, msgSucess: string) => {
		try {
			setLoadingSubmit(true);
			setSuccessSubmit(false);
			setErrorSubmit(null);

			const getValues = Object.assign(defaultForm.getValues())

			let projeto = defaultForm.getValues('projeto');
			let tecnicoPrincipal = defaultForm.getValues('tecnicoPrincipal');

			const ensaiosLst = entity!.ensaios?.filter((e: any, index: any) => {
				return itemRemovedEnsaios.indexOf(index) === -1;
			});

			let ensaiosTmp = ensaiosLst?.map((e: any) => {
				return {
					...e,
					id: isNumber(e.id) === true ? e.id : null,
				}
			});

			const areasLst = entity!.areas?.filter((e: any, index: any) => {
				return itemRemovedAreas.indexOf(index) === -1;
			});

			let areasTmp = areasLst?.map((e: any) => {

				const Ensaio = e.areaEnsaio?.length > 0 ? ensaiosTmp!.filter(x => x.descricao === e.areaEnsaio[0].label)[0]
					:
					{
						id: 0,
						descricao: ''
					}
				;

				return {
					...e,
					id: isNumber(e.id) === true ? e.id : null,
					lpma_LocalId: e.local?.value !== undefined ? e.local?.value : e.lpma_LocalId,
					tecnicoResponsavelId: e.tecnicoResponsavel?.value != null ? e.tecnicoResponsavel?.value : e.tecnicoResponsavelId,
					areaEnsaio: { value: Ensaio?.id, label: Ensaio?.descricao },
					area_Ensaio: { value: Ensaio?.id, label: Ensaio?.descricao },
				}
			});

			var processosAnterioresTmp = entity!.processosAnteriores?.map((e: any) => {
				return { ...e, dataDecisao: e.dataDecisao ? (new Date(e.dataDecisao)).toUTCString() : undefined }
			});

			let construcoesTmp: Lpma_RN_PotencialConstrucoes[] = [];

			for (let i = 0, t = entity!.construcoes?.length ?? 0; i < t; i++) {
				let construcao: Lpma_RN_PotencialConstrucoes = {
					id: entity!.construcoes![i].id ?? 0,
					lpma_Id: entity!.construcoes![i].lpma_Id ?? entity?.id,
					nomeContrucao: entity!.construcoes![i].nomeContrucao,
					processoCTNBio: entity!.construcoes![i].processoCTNBio,
					notas: entity!.construcoes![i].notas,
					nomeSemente: entity!.construcoes![i].nomeSemente,
					parecerTecnicoCIBIO: entity!.construcoes![i].parecerTecnicoCIBIO,
				};

				construcoesTmp.push(construcao);
			}

			let genesTmp: Lpma_RN_GenesInteresse[] = [];

			if (itemsGenesInteresse !== undefined) {
				for (let i = 0, t = itemsGenesInteresse.length; i < t; i++) {

					const Construcao = construcoesTmp.filter(x => x.nomeContrucao === itemsGenesInteresse![i].nomeConstrucao)[0];

					let geneInteresse: Lpma_RN_GenesInteresse = {
						id: itemsGenesInteresse![i].id ?? 0,
						lpma_Id: itemsGenesInteresse![i].lpma_Id ?? entity?.id,
						construcaoId: Construcao.id,
						nomeGeneInteresse: itemsGenesInteresse![i].nomeGeneInteresse,
						organismoDoadorGeneInteresse: itemsGenesInteresse![i].organismoDoadorGeneInteresse,
						funcaoGeneInteresse: itemsGenesInteresse![i].funcaoGeneInteresse,
					};

					genesTmp.push(geneInteresse);
				}
			}

			let values = {
				...getValues,
				id: entity?.id,
				titulo: entity?.titulo,
				projetoId: entity?.projetoId,
				tecnicoPrincipalId: entity?.tecnicoPrincipalId,
				objetivo: entity?.objetivo ?? '',// defaultForm.getValues('objetivo'),
				tratosCulturais: defaultForm.getValues('tratosCulturais'),
				croquiTexto: defaultForm.getValues('croquiTexto'),
				dataPrevistaInicioDe: entity?.dataPrevistaInicioDe,
				dataPrevistaInicioAte: entity?.dataPrevistaInicioAte,
				dataPrevistaFimDe: entity?.dataPrevistaFimDe,
				dataPrevistaFimAte: entity?.dataPrevistaFimAte,


				//mapaFile                : defaultForm.getValues('mapaFileGuid'),
				//--------------------------------------
				ensaios: ensaiosTmp,
				areas: areasTmp,
				//
				dataEndosso: entity?.dataEndosso,
				dataProposta: entity?.dataProposta, 
				processosAnteriores: processosAnterioresTmp,
				equipeTecnica: entity?.equipeTecnica,
				//---------------------------------------
				observacoesRegulatorio: entity?.observacoesRegulatorio,
				//---------------------------------------
				protocoloExperimentalTexto: entity?.protocoloExperimentalTexto,
				status: entity?.status,
				//---------------------------------------
				dataPrevistaColheira: entity?.dataPrevistaColheira,
				dataPrevistaColheitaInicio: entity?.dataPrevistaColheitaInicio,
				dataPrevistaColheitaTermino: entity?.dataPrevistaColheitaTermino,

				epocas: entity?.epocas,

				construcoes: construcoesTmp,
				genesInteresse: genesTmp,
			};

			let formData = new FormData();
			let keysToIgnore = [
				'attachFiles',
				'attachFilesDto',
				'userReadPermissionDto',
				'userReadPermission',
			];

			let EnsaioDadosFormatados = [
				'largura', 'comprimento', 'parcelaLargura', 'parcelaComprimento',
				'percentualSemente', 'densidade', 'numeroRepeticoes'
			]
			const EnsaioDadosFormatadosClear = (value: any) => {
				let isNumber = Number(value) === value && (value % 1 !== 0 || value % 1 === 0);
				if (isNumber === true) { return value; }
				if (value === null || value === undefined) { return 0; }

				value = value.replace("m (larg.)", '');
				value = value.replace("m (comp.)", '');
				value = value.replace("%", '');
				value = value.replace("kg/ha", '');
				value = value.replace("Nº", '');

				return value.trim();
			}

			const listAddFormData = (key: string, listName: string, listing: any[], formData_: any) => {
				if (key === listName && listing?.length > 0) {
					for (let i = 0, t = listing.length; i < t; i++) {
						var itemFrom = listing[i];
						for (var ekey in itemFrom) {
							formData_.append(`${listName}[${i}].${ekey}`, itemFrom[ekey]);
						}
					}
					return true;
				}
				return false;
			}

			for (var key in values) {
				if (keysToIgnore.includes(key) || !values[key]) {
					continue;
				}
				if (listAddFormData(key, 'processosAnteriores', (processosAnterioresTmp as any[]), formData)) {
					continue;
				}
				if (listAddFormData(key, 'construcoes', construcoesTmp, formData)) {
					continue;
				}
				if (listAddFormData(key, 'genesInteresse', genesTmp, formData)) {
					continue;
				}
				if (key === 'ensaios') {
					if (ensaiosTmp !== null) {
						for (let i = 0, t = ensaiosTmp!.length; i < t; i++) {
							var itemEnsaio = ensaiosTmp![i];
							for (var ekey in itemEnsaio) {
								if (ekey === 'croquiFileTemp') {
									let croquiFile = itemEnsaio[ekey];
									if (croquiFile.length > 0) {
										for (let x = 0, et = croquiFile.length; x < et; x++) {
											formData.append(`ensaios[${i}].croquiFile`, croquiFile[x]);
										}
									}
								}
								if (ekey === 'avaliacaoAgronomica' && itemEnsaio[ekey]) {
									const ava_agro = itemEnsaio[ekey] as LpmaRNEnsaioAvaliacaoAgronomica[];
									for (let av = 0, tav = ava_agro.length; av < tav; av++) {
										const ava = ava_agro[av];
										formData.append(`ensaios[${i}].avaliacaoAgronomica[${av}].pE11AD1`, ava.pE11AD1);
										formData.append(`ensaios[${i}].avaliacaoAgronomica[${av}].descricao`, ava.descricao);
									}
								}
								else if (ekey === 'tratamentos' && itemEnsaio[ekey]) {
									const ens_trat = itemEnsaio[ekey] as LpmaRNEnsaioTratamento[];
									for (let tt = 0, ttt = ens_trat.length; tt < ttt; tt++) {
										const trat = ens_trat[tt];
										formData.append(`ensaios[${i}].tratamentos[${tt}].tipoTratamento`, trat.tipoTratamento?.value);
										formData.append(`ensaios[${i}].tratamentos[${tt}].descricao`, trat.descricao);
										formData.append(`ensaios[${i}].tratamentos[${tt}].dose`, (trat.dose ?? 0).toString());
										formData.append(`ensaios[${i}].tratamentos[${tt}].estagioAplicacao`, trat.estagioAplicacao);
									}
								}
								else if (EnsaioDadosFormatados.includes(ekey)) {
									formData.append(`ensaios[${i}].${ekey}`, EnsaioDadosFormatadosClear(itemEnsaio[ekey]));
								}
								else if (itemEnsaio[ekey]) {
									formData.append(`ensaios[${i}].${ekey}`, itemEnsaio[ekey]);
								}
							}
						}
					}
					continue;
				}
				if (key === 'areas') {
					if (areasTmp !== null) {
						for (let i = 0, t = areasTmp!.length; i < t; i++) {
							var itemArea = areasTmp![i];
							for (var akey in itemArea) {
								if (akey === 'mapaFileTemp') {
									let mapaFile = itemArea[akey];
									if (mapaFile.length > 0) {
										for (let x = 0, et = mapaFile.length; x < et; x++) {
											formData.append(`areas[${i}].mapaFile`, mapaFile[x]);
										}
									}
								}
								else if (akey === 'areaEnsaio' && itemArea[akey]) {
									const areaEnsaio = itemArea[akey];
									if (areaEnsaio.length > 0) {
										for (let x = 0, et = areaEnsaio.length; x < et; x++) {
											const areaUid = areaEnsaio[x];
											formData.append(`areas[${i}].ensaioUid[${x}]`, areaUid.value);
										}
									}
								}
								else if (akey === 'area_Ensaio' && itemArea[akey]) {
									const area_Ensaio = itemArea[akey];
									if (area_Ensaio?.value != 0) {
										formData.append(`areas[${i}].area_Ensaio[0].AreaId`, areasTmp![i].id != null ? areasTmp![i].id : 0);
										formData.append(`areas[${i}].area_Ensaio[0].AreaNome`, areasTmp![i].id != null ? areasTmp![i].local?.nomeDaFazenda : '');
										formData.append(`areas[${i}].area_Ensaio[0].Ensaioid`, area_Ensaio.value);
										formData.append(`areas[${i}].area_Ensaio[0].EnsaioNome`, area_Ensaio.label);
									}
								}
								else if (itemArea[akey]) {
									formData.append(`areas[${i}].${akey}`, itemArea[akey]);
								}
							}
						}
					}
					continue;
				}
				if (key === 'mapaFileTemp') {
					const mapaFile = defaultForm.getValues('mapaFileTemp');
					if (mapaFile) {
						for (let i = 0, t = mapaFile.length; i < t; i++) {
							formData.append('mapaFile', mapaFile[i]);
						}
						continue;
					}
				}
				if (key === 'equipeTecnica') {
					const equi_tec = values[key].map((equi: any) => equi.tecnico.value);
					for (let i = 0, t = equi_tec.length; i < t; i++) {
						formData.append(`equipeTecnica[${i}].tecnico_Id`, equi_tec[i]);
					}
					continue;
				}
				formData.append(key, values[key]);
			}
			const dados = await LpmaServiceApi.getRN35Action(acao, formData);
			setEntity(dados);

			setLoadingSubmit(false);
			//setSuccessSubmit(true);
			//window.scrollTo({ top: 0, behavior: 'smooth' });

		} catch (error: any) {
			console.error(error);
			setLoadingSubmit(false);
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);

		}
	};

	const parseButton = () => {
		let btnCancelar = <Button
			title="Voltar"
			contained={false}
			type="button"
			onClick={() => {
				//defaultForm.formState.isDirty ? setStatus(statuses.go_back) :
				history.goBack();
			}}
		/>;

		let btnViewPDF = '';
		//  entity?.id ? 
		// 	<Button 
		// 		title="Download PDF" contained={false} type="button"
		// 		onClick={() => {
		// 			LpmaServiceApi.DownloadRN35PDF(entity?.id)
		// 		}} />
		// 	:  <Fragment />;


		let btnSalvarAprovar = <Fragment />
		let btnSalvarEnviarRegulatorio = <Fragment />
		let btnSolicitarCorrecao = <Fragment />
		let btnSalvarAdmin = null;
		let eStatus = (entity?.status ?? STATUS.EmPreenchimento);

		if (LpmaRoles.isCreatOREditRequisicao()
			&& (!(entity?.status)
				|| entity?.status === STATUS.EmPreenchimento
				|| entity?.status === STATUS.EmCorrecao
				//|| entity?.status === STATUS.Aprovado
			)
		) {
			let title = "Salvar e enviar para Regulatório";
			if (entity?.status === STATUS.EmCorrecao) { title = "Reenviar Correção para Regulatório" }
			else if (entity?.status === STATUS.Aprovado) { title = "Concluir" }

			btnSalvarEnviarRegulatorio = <GenericButton
				title={title}
				variant="btn-warning"
				loading={loadingSubmit && (estagio === STATUS.EmAnalisePeloRegulatorio)}
				onClick={() => {
					defaultForm.register("dataProposta", { required: true })
					if (entity?.status === STATUS.Aprovado) {
						gravarDados(modoFluxo, true);
						concluirLPMA();
						//setEstagio(STATUS.Concluido);
					}
					else {
						gravarDados(modoFluxo, true);
						salvarRegulatorio();
						//setEstagio(STATUS.EmAnalisePeloRegulatorio);
					}
				}}
				type="button"
			/>

//			if (entity?.status !== STATUS.Aprovado) {
////				btnSalvarAprovar = <Button
////					title="Salvar Rascunho"
////					contained={true}
////					className="btn-success"
////					isLoading={loadingSubmit && (estagio !== STATUS.EmAnalisePeloRegulatorio)}
////					onClick={() => {
////						gravarDados(modoFluxo);
////						salvarRascunhoLPMA();
//////						setEstagio(STATUS.SAVE);
////					}}
////					type="button"
////				/>;

//			} else if (LpmaRoles.isAdmin() && (editarAll || editarReg)) {
//				btnSalvarAdmin = <Button
//					title="Salvar"
//					contained={true}
//					className="btn-success"
//					isLoading={loadingSubmit && (estagio !== STATUS.EmAnalisePeloRegulatorio)}
//					onClick={() => {
//						gravarDados(modoFluxo);
//						salvarRascunhoLPMA();
//						//setEstagio(STATUS.SAVE);
//					}}
//					type="button"
//				/>;
//			}

		}
		else if (entity?.status === STATUS.EmAnalisePeloRegulatorio && LpmaRoles.isRegulatorio()) {
			btnSalvarAprovar = <Button
				title="Aprovar"
				contained={true}
				className="btn-success"
				type="button"
				isLoading={loadingSubmit && (estagio !== STATUS.EmCorrecao)}
				onClick={() => {
					gravarDados(modoFluxo, true);
					aprovarLPMA();
//					setEstagio(STATUS.EmpAprovacao);
				}}
			/>;

			btnSolicitarCorrecao = <GenericButton
				title="Solicitar Correção"
				variant="btn-danger"
				type="button"
				loading={loadingSubmit && (estagio === STATUS.EmCorrecao)}
				onClick={() => {
					gravarDados(modoFluxo, true);
					solicitarCorrecao();
				//	setEstagio(STATUS.EmCorrecao);
				}}
			/>;

			//if (LpmaRoles.isAdmin() && (editarAll || editarReg)) {
			//	btnSalvarAdmin = <Button
			//		title="Salvar"
			//		contained={true}
			//		className="btn-success"
			//		isLoading={loadingSubmit && (estagio !== STATUS.EmAnalisePeloRegulatorio)}
			//		onClick={() => {
			//			gravarDados(modoFluxo);
			//			salvarRascunhoLPMA();
			//			//setEstagio(STATUS.SAVE);
			//		}}
			//		type="button"
			//	/>;
			//}
		}
		//if (UrlParam.modo !== 'editar' && UrlParam.modo !== 'nova') return <div className="button-position">{btnCancelar}</div>
		//if ((btnSalvarAdmin === null && eStatus !== STATUS.EmPreenchimento) && LpmaRoles.isAdmin() && (editarAll || editarReg)) {
		//	btnSalvarAdmin = <Button
		//		title="Salvar"
		//		contained={true}
		//		className="btn-success"
		//		isLoading={loadingSubmit && (estagio !== STATUS.EmAnalisePeloRegulatorio)}
		//		onClick={() => {
		//			gravarDados(modoFluxo);
		//			salvarRascunhoLPMA();
		//			//setEstagio(STATUS.SAVE);
		//		}}
		//		type="button"
		//	/>;
		//}

		return <div className="button-position">
			{btnCancelar}
			{btnViewPDF}
			{btnSolicitarCorrecao}
			{btnSalvarEnviarRegulatorio}
			{btnSalvarAprovar}
			{btnSalvarAdmin ?? <Fragment />}
		</div>;
	}

	const parseBotoesFluxo =
		<div className="button-position tab-buttons-local">
			<Button
				title="Dados Principais"
				contained={false}
				className={modoFluxo === MODO_VIEW.DADOS_PRINCIPAIS ? "btn-success" : ''}
				type="button"
				onClick={() => {
					setEnviarRegulatorio(false);
					gravarDados(modoFluxo);
					setModoFluxo(MODO_VIEW.DADOS_PRINCIPAIS);
				}}
			/>

			<Button
				title="Protocolo"
				className={modoFluxo === MODO_VIEW.PROTOCOLO_EXPERIMENTAL ? "btn-success" : ''}
				contained={false}
				type="button"
				onClick={() => {
					setEnviarRegulatorio(false);
					gravarDados(modoFluxo);
					setModoFluxo(MODO_VIEW.PROTOCOLO_EXPERIMENTAL);
				}}
			/>

			<Button
				title="Locais de Ensaio"
				className={modoFluxo === MODO_VIEW.LOCAIS_ENSAIO ? "btn-success" : ''}
				contained={false}
				type="button"
				onClick={() => {
					setEnviarRegulatorio(false);
					gravarDados(modoFluxo);
					setModoFluxo(MODO_VIEW.LOCAIS_ENSAIO);
				}}
			/>

			<Button
				title="Construções"
				className={modoFluxo === MODO_VIEW.CONSTRUCOES ? "btn-success" : ''}
				contained={false}
				type="button"
				onClick={() => {
					setEnviarRegulatorio(false);
					gravarDados(modoFluxo);
					setModoFluxo(MODO_VIEW.CONSTRUCOES);
				}}
			/>

			{(LpmaRoles?.isAdmin() && (editarAll || editarReg)) ?
				<Button
					title="Genes"
					className={modoFluxo === MODO_VIEW.GENES ? "btn-success" : ''}
					contained={false}
					type="button"
					onClick={() => {
						setEnviarRegulatorio(false);
						gravarDados(modoFluxo);
						setModoFluxo(MODO_VIEW.GENES);
					}}
				/>
				: ''}

			{((entity?.status ?? STATUS.EmPreenchimento) !== STATUS.EmPreenchimento) ?
				<Button
					title="Regulatório"
					className={modoFluxo === MODO_VIEW.REGULATORIO ? "btn-success" : ''}
					contained={false}
					type="button"
					onClick={() => {
					setEnviarRegulatorio(false);
						gravarDados(modoFluxo);
						setModoFluxo(MODO_VIEW.REGULATORIO);
					}}
				/>
				: ''}
		</div>;

	const [ensaiosTabIndex, setEnsaiosTabIndex] = useState<any>(null);

	const errosHandler = (errData: any) => {
		for (var el in errData) {
			if (el === "ensaios" || el === "areas") {
				for (var ix in errData[el]) {
					setEnsaiosTabIndex(ix);
				}
			}
		}
		console.error(errData);
	};

	return (
		<div className="container container-fluid lpma">
			<NavigationMessage messages={pageMessages} />
			<If condition={successSubmit && !errorSubmit && msgSuccessSubmit.length > 0}>
				<AlertSuccess
					message={msgSuccessSubmit}
				/>
			</If>
			<If condition={!successSubmit && errorSubmit !== null}>
				<AlertError
					message={STRINGS.ALERT_GENERIC_ERROR}
				/>
			</If>
			<h3>{titleForm}</h3>
			<Form ref={formRef} onSubmit={defaultForm.handleSubmit(submitForm, errosHandler)}>
				{parseBotoesFluxo}
				<If condition={modoFluxo === MODO_VIEW.DADOS_PRINCIPAIS}>
				<Fieldset disabled={location?.state?.readonly} title={STRINGS_LPMA.BLOCK_ONE}>
					<Form.Row className="row-custom">

						{entity?.id ? <>
							<TextField<LpmaRN35> isSingle form={defaultForm}
								defaultValue={entity?.id}
								name="id" label="Nº da Solicitação" layoutColumns={4}
							/>

							<TextField<LpmaRN35> isSingle form={defaultForm}
								defaultValue={(entity?.numeroProcessoEAno ?? "-")}
								name="numeroProcessoEAno" layoutColumns={2} label="Nº Controle" />

							<TextField<LpmaRN35> isSingle form={defaultForm}
								name="status" label="Status" layoutColumns={4}
								value={STATUSTEXT.find(a => a.s === (entity.status ?? 1))?.t}
							/>
							<TextField<LpmaRN35> isSingle form={defaultForm}
								name="solicitante" label="Solicitante" layoutColumns={4}
							/>
						</>
							: ''}

						<TextField<LpmaRN35> required form={defaultForm}
							name="titulo" label="Título" layoutColumns={12}
							enabled={editarAll}
							isInvalidCheck={!location?.state?.readonly}
						/>

						<DropDown name="projeto" label="Código do Projeto"
							layoutColumns={6} form={defaultForm}
							options={itemsProjetos?.map(i => Object.assign({ value: i.id, label: `${i.nome} (${i.cultura})` }))}
							enabled={editarAll} isClearable={editarAll}
							placeholder="Selecione Projeto"
							required
							isInvalidCheck={!location?.state?.readonly}
						/>

						<DropDown name="tecnicoPrincipal" label="Técnico(a) Principal"
							layoutColumns={6} form={defaultForm}
							options={itemsEquipeTecnica?.map(i => Object.assign({ value: i.id, label: i.nome }))}
							enabled={editarAll} isClearable={editarAll}
							placeholder="Selecione o(a) Técnico(a) Principal"
							required
							isInvalidCheck={!location?.state?.readonly}
						/>

						<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaInicioDe" maxDate={defaultForm.watch().dataPrevistaInicioAte} label="Data Prevista de Início De:" layoutColumns={3} form={defaultForm} enabled={editarAll} ></DateField>
						<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaInicioAte" minDate={defaultForm.watch().dataPrevistaInicioDe} label="Até" layoutColumns={3} form={defaultForm} enabled={editarAll} ></DateField>
						<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaFimDe" minDate={defaultForm.watch().dataPrevistaInicioAte} maxDate={defaultForm.watch().dataPrevistaFimAte} label="Data Prevista de Fim De:" layoutColumns={3} form={defaultForm} enabled={editarAll} ></DateField>
						<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaFimAte" minDate={defaultForm.watch().dataPrevistaFimDe} label="Até" layoutColumns={3} form={defaultForm} enabled={editarAll} ></DateField>

						<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaColheitaInicio" maxDate={defaultForm.watch().dataPrevistaColheitaInicio} label="Data Colheita de Início De:" layoutColumns={3} form={defaultForm} enabled={editarAll} ></DateField>
						<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaColheitaTermino" minDate={defaultForm.watch().dataPrevistaColheitaTermino} label="Até" layoutColumns={3} form={defaultForm} enabled={editarAll} ></DateField>   
						{/*<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaColheira" label="Data prevista para colheita" layoutColumns={6} form={defaultForm} enabled={editarAll} ></DateField>*/}

						<TextField<LpmaRN35>
							form={defaultForm}
							name="epocas" label="Quantidade de Épocas *" layoutColumns={6}
							enabled={editarAll}
							isInvalidCheck={!location?.state?.readonly}
						/>

						{entity?.status === STATUS.Cancelado ?
							<TextArea<LpmaRN35> required form={defaultForm} name="motivoCancelamento" label="Item Cancelado, Motivo:" layoutColumns={12} enabled={editarAll} />
							: ''}

						<TextArea<LpmaRN35> form={defaultForm} name="objetivo" label="Objetivo" layoutColumns={12} rows={3} enabled={editarAll} />

						<RichText<LpmaRN35> enabled={false} form={defaultForm}
							{...defaultForm.register(`protocoloExperimentalTexto`)}
							name="protocoloExperimentalTexto"
							defaultValue={entity?.protocoloExperimentalTexto != undefined ? entity?.protocoloExperimentalTexto : "O protocolo experimental abaixo se refere exclusivamente ao delineamento, descrição do material e métodos, disposição das parcelas dentro dos blocos ou dentro de lote experimental delimitado para os ensaios, bem como à forma de aplicação dos tratamentos de interesse e práticas agronômicas. Os aspectos de isolamento e prevenção de dispersão são tratados no item 15 (Procedimentos de Biossegurança)."}
							label="Descrição do experimento com desenho experimental e avaliações a serem realizadas"
							layoutColumns={12} />
					</Form.Row>
				</Fieldset>
				</If>
				<If condition={modoFluxo === MODO_VIEW.PROTOCOLO_EXPERIMENTAL}>
					<Fieldset disabled={location?.state?.readonly} title={STRINGS_LPMA.BLOCK_PROTOCOLO}>
						<TabControl<LpmaRN35, LpmaRNEnsaio>
							location={location?.state?.readonly}
							label="Locais de Ensaio"
							form={defaultForm}
							enabled={editarAll}
							tabIndex={ensaiosTabIndex}
							isInvalidCheck={!location?.state?.readonly}
							name="ensaios"
							msgTabRemoveTitle="Remover ensaio"
							msgTabRemovePrompt="Confirmar remoção de Ensaio"
							addNewItem={() => { return { descricao: 'EXPERIMENTO ', uid: '', id: 0 } as LpmaRNEnsaio }}
							titleWatchField="descricao"
							defaultValue={entity?.ensaios}
							indicesRemoved={(idxRmv: any[]) => { setItemRemovedEnsaios(idxRmv) }}
							renderItem={(item: any, index: any) => {
								return <Form.Row className="row-custom">
									<TextField isInvalidCheck={!location?.state?.readonly} enabled={editarAll} form={defaultForm} {...defaultForm.register(`ensaios.${index}.descricao`)} name={`ensaios.${index}.descricao`} defaultValue={item.descricao} label="Descrição" layoutColumns={10} />

									<RichText<LpmaRN35> enabled={editarAll}
										form={defaultForm}
										{...defaultForm.register(`ensaios.${index}.objetivo`)}
										name={`ensaios.${index}.objetivo`}
										defaultValue={item.objetivo}
										label="Objetivo do Ensaio" layoutColumns={12} />

									<TextField required isInvalidCheck={!location?.state?.readonly} label="Número de Entradas" placeholder="Número de Entradas" enabled={editarAll} layoutColumns={4} form={defaultForm} name={`ensaios.${index}.numeroEntradas`} defaultValue={item.numeroEntradas} />
									<TextField required isInvalidCheck={!location?.state?.readonly} label="Número de Tratamentos" placeholder="Número de Tratamentos" enabled={editarAll} layoutColumns={4} form={defaultForm} name={`ensaios.${index}.numeroTratamentos`} defaultValue={item.numeroTratamentos} />
									<TextField required isInvalidCheck={!location?.state?.readonly} label="Número de Repetições" placeholder="Número de Repetições" enabled={editarAll} layoutColumns={4} form={defaultForm} name={`ensaios.${index}.numeroRepeticoes`} defaultValue={item.numeroRepeticoes} />

									<RichText<LpmaRNEnsaio> enabled={editarAll}
										form={defaultForm}
										{...defaultForm.register(`ensaios.${index}.tratamento`)}
										name={`ensaios.${index}.tratamento`}
										defaultValue={item.tratamento}
										label="Protocolo Experimental - Observações de Tratamento" layoutColumns={12} />

									<TextFields required label="Dimensão da Parcela [Largura x Comprimento]" layoutColumns={8}>
										<TField isInvalidCheck={!location?.state?.readonly} placeholder="Largura" layoutChildColumns={6} enabled={editarAll} form={defaultForm} name={`ensaios.${index}.parcelaLargura`} defaultValue={item.parcelaLargura} />
										<TField isInvalidCheck={!location?.state?.readonly} placeholder="Comprimento" layoutChildColumns={6} enabled={editarAll} form={defaultForm} name={`ensaios.${index}.parcelaComprimento`} defaultValue={item.parcelaComprimento} />
									</TextFields>
									<TextField isInvalidCheck={!location?.state?.readonly} label="Número de Locais" placeholder="Número de Locais" layoutColumns={4} enabled={editarAll} form={defaultForm} name={`ensaios.${index}.numeroLocais`} defaultValue={item.numeroLocais} />

									<TextField enabled={editarAll} form={defaultForm} name={`ensaios.${index}.croquiTexto`} defaultValue={item.croquiTexto} label="Croqui Experimental " layoutColumns={6} />
									<FileUpload isInvalidCheck={!location?.state?.readonly} enabled={editarAll} form={defaultForm}
										name={`ensaios.${index}.croquiFileTemp`}
										preview={true} isClearable={editarAll}
										urlpreview={LpmaServiceApi.UrlFileDownloadRN35(entity?.id, item.id, 'croqui')}
										onDownloadUrl={(url: any) => {
											LpmaServiceApi.DownloadFile(url, item.croquiFileName);
										}}
										label="Croqui (Arquivo de Imagem)"
										text={item.croquiFileName} textButton="Inserir Arquivo" layoutColumns={4}
										onChange={(a: any, e: any) => {
											defaultForm.setValue(`ensaios.${index}.croquiFileName`, e);
										}}
										accept="image/png, image/jpeg"
									/>
									<DataHide form={defaultForm} binds={[
										{ defaultValue: item.uid, name: `ensaios.${index}.uid` },
										{ defaultValue: item.croquiFileGuid, name: `ensaios.${index}.croquiFileGuid` },
										{ defaultValue: item.croquiFileName, name: `ensaios.${index}.croquiFileName` }]}
									/>

									<TextField required isInvalidCheck={!location?.state?.readonly} label="Densidade" placeholder="Densidade" suffix=" kg/ha" enabled={editarAll} layoutColumns={6} form={defaultForm} name={`ensaios.${index}.densidade`} defaultValue={item.densidade} />
									<TextField required isInvalidCheck={!location?.state?.readonly} label="% de Perda de Sementes" placeholder="Percentual de Perda" enabled={editarAll} layoutColumns={6} form={defaultForm} name={`ensaios.${index}.percentualSemente`} defaultValue={item.percentualSemente} suffix=" %" />

									<RichText<LpmaRN35> enabled={editarAll}
										form={defaultForm}
										{...defaultForm.register(`ensaios.${index}.material`)}
										name={`ensaios.${index}.material`}
										defaultValue={item.material}
										label="Material do Ensaio" layoutColumns={6} />

									<RichText<LpmaRN35> enabled={editarAll}
										form={defaultForm}
										{...defaultForm.register(`ensaios.${index}.delineamentoExperimental`)}
										name={`ensaios.${index}.delineamentoExperimental`}
										defaultValue={item.delineamentoExperimental}
										label="Delineamento Experimental" layoutColumns={6} />

									<RichText<LpmaRN35> enabled={editarAll}
										form={defaultForm}
										{...defaultForm.register(`ensaios.${index}.tratosCulturais`)}
										name={`ensaios.${index}.tratosCulturais`}
										defaultValue={item.tratosCulturais}
										label="Tratos Culturais" layoutColumns={12} />

									<Grid<LpmaRN35, LpmaRNEnsaioAvaliacaoAgronomica>
										form={defaultForm}
										// {...defaultForm.register(`ensaios.${index}.avaliacaoAgronomica`)} 
										name={`ensaios.${index}.avaliacaoAgronomica`}
										defaultValue={item.avaliacaoAgronomica ?? itemsPorcentagemFitotoxidez}

										required={true}
										isInvalidCheck={!location?.state?.readonly}
										style={{ height: '210px', width: '100%' }}
										label="Avaliações Agronômicas - Tabela de tolerância"
										layoutColumns={12}
										enabled={editarAll}
										collumns={[
											{
												header: 'PE11AD1', field: 'pE11AD1', maxWidth: 100,
												showOnModal: true, custumControls: <TextField name="pE11AD1" label="PE11AD1" layoutColumns={4} />
											},
											{
												header: 'Baseado Nessa Escala a Fitotoxidez deve ser estimada em porcentagem como', field: 'descricao', justifyContent: 'left',
												showOnModal: true, custumControls: <TextArea rows={3} name="descricao" label="Baseado Nessa Escala a Fitotoxidez deve ser estimada em porcentagem como" layoutColumns={12} />
											},
										]}
									></Grid>

									<RichText<LpmaRNEnsaio> enabled={editarAll}
										form={defaultForm}
										{...defaultForm.register(`ensaios.${index}.avaliacaoAgronomicaTexto`)}
										name={`ensaios.${index}.avaliacaoAgronomicaTexto`}
										defaultValue={item.avaliacaoAgronomicaTexto}
										label="Avaliações Agronomica - Observações" layoutColumns={12} />

									<Form.Row className="row-invisible">
										<TextField readonly required isInvalidCheck={!location?.state?.readonly} label="ID" placeholder="ID" isNumer={true} enabled={editarAll} layoutColumns={1} form={defaultForm} name={`ensaios.${index}.id`} defaultValue={item.id} />

										<TextFields required label="Dimensões do Croqui [Largura x Comprimento]" layoutColumns={1} >
											<TField isInvalidCheck={!location?.state?.readonly} placeholder="Largura" layoutChildColumns={1} enabled={editarAll} isNumer={true} form={defaultForm} name={`ensaios.${index}.largura`} suffix=" m (larg.)" defaultValue={item.largura} />
											<TField isInvalidCheck={!location?.state?.readonly} placeholder="Comprimento" layoutChildColumns={1} enabled={editarAll} isNumer={true} form={defaultForm} name={`ensaios.${index}.comprimento`} suffix=" m (comp.)" defaultValue={item.comprimento} />
										</TextFields>

										<Grid<LpmaRN35, LpmaRNEnsaioTratamento>
											form={defaultForm}
											// {...defaultForm.register(`ensaios.${index}.avaliacaoAgronomica`)} 
											name={`ensaios.${index}.tratamentos`}
											defaultValue={item.tratamentos}

											required={true}
											isInvalidCheck={!location?.state?.readonly}
											style={{ height: '1px', width: '100%' }}
											label="Protocolo Experimental - Tratamento"
											layoutColumns={12}
											enabled={editarAll}
											DropList={itemsTipoTratamento?.map(e => e.nome)}
											collumns={[
												{
													header: 'Tipo de Protocolo AAA',
													field: 'tipoTratamento',
													showOnModal: true, minWidth: 100,
													dropListGetData: (data: any, eindex: any, isNew: any) => {
														if (!data) return null;
														const it = (itemsTipoTratamento as any[])[data.index];
														return (isNew ? Object.assign({ value: it.id, label: it.nome }) : null);
													},
													custumControls:
														<DropDown layoutColumns={6} enabled={false}
															isClearable={editarAll}
															placeholder={"Tipo de Tratamento"}
															options={itemsTipoTratamento?.map(i => Object.assign({ value: i.id, label: i.nome }))} />
												},
												{
													header: 'Descrição', field: 'descricao',
													showOnModal: true, custumControls: <TextField layoutColumns={6} />
												},
												{
													header: 'Dose(g)', field: 'dose', minWidth: 10,
													showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} />
												},
												{
													header: 'Estágio da Aplicação', field: 'estagioAplicacao', minWidth: 200,
													showOnModal: true, custumControls: <TextField layoutColumns={6} />
												},
											]}
										></Grid>

									</Form.Row>


								</Form.Row>
							}}
						></TabControl>
					</Fieldset>
				</If>
				<If condition={modoFluxo === MODO_VIEW.LOCAIS_ENSAIO}>
					<Fieldset disabled={location?.state?.readonly} title={STRINGS_LPMA.BLOCK_L_E}>
						<TabControl<LpmaRN35, LpmaRNArea>
							location={location?.state?.readonly}
							label="Areas"
							form={defaultForm}
							enabled={editarAll}
							isInvalidCheck={!location?.state?.readonly}
							name="areas"
							msgTabRemoveTitle="Remover área"
							msgTabRemovePrompt="Confirmar remoção de Área"
							addNewItem={() => { return { descricao: 'AREA', uid: '', id: 0 } as LpmaRNArea }}
							//titleWatchField="local.value"
							titleWatch={(index: any) => {
								var localname = (defaultForm.watch(`areas.${index}.local`) as any)?.label ?? '[Não definida]';
								//var descricao =  (defaultForm.watch(`areas.${index}.descricao`) as any) ?? '';
								return `Área: ${localname}`;
							}}
							defaultValue={entity?.areas}
							indicesRemoved={(idxRmv: any[]) => { setItemRemovedAreas(idxRmv) }}
							renderItem={(item: any, index: any) => {
								return <Form.Row className="row-custom">
									{/* <TextField required enabled={editarAll} form={defaultForm} 
							{...defaultForm.register(`areas.${index}.descricao`)}    
							name={`areas.${index}.descricao`} defaultValue={item.descricao} 
							label="Descrição" layoutColumns={4} 
							isInvalidCheck={!location?.state?.readonly}/>       */}

									<DropDown required enabled={editarAll} form={defaultForm}
										name={`areas.${index}.local`} label="Local" layoutColumns={4}
										isClearable={editarAll}
										placeholder={"Selecione um Local"} defaultValue={item.local}
										options={itemsLocais?.map(i => Object.assign({ value: i.id, label: i.nomeDaFazenda }))}
										isInvalidCheck={editarAll} />

									<DropDown required enabled={editarAll} form={defaultForm} name={`areas.${index}.tecnicoResponsavel`} label="Técnico Responsável" layoutColumns={4}
										isClearable={editarAll}
										placeholder={"Selecione um Técnico Responsável"} defaultValue={item.tecnicoResponsavel}
										options={itemsEquipeTecnica?.map(i => Object.assign({ value: i.id, label: i.nome }))}
										isInvalidCheck={editarAll} />

									<TextField enabled={editarAll} form={defaultForm} name={`areas.${index}.mapaTexto`} defaultValue={item.mapaTexto} label="Mapa Planialtimétrico: Descrição " layoutColumns={6} />
									<FileUpload enabled={editarAll} form={defaultForm}
										name={`areas.${index}.mapaFileTemp`}
										preview={true} isClearable={editarAll}
										urlpreview={LpmaServiceApi.UrlFileDownloadRN35(entity?.id, item.id, 'mapa-principal')}
										onDownloadUrl={(url: any) => {
											LpmaServiceApi.DownloadFile(url, item.mapaFileName);
										}}
										label="Mapa Planialtimétrico (PDF ou Arquivo de Imagem)" text={item.mapaFileName}
										textButton="Inserir Arquivo" layoutColumns={6}
										onChange={(a: any, e: any) => {
											defaultForm.setValue(`areas.${index}.mapaFileName`, e);
										}}
										accept="application/pdf, image/png, image/jpeg"
									/>

									<DataHide form={defaultForm} binds={[
										{ defaultValue: item.mapaFileGuid, name: `areas.${index}.mapaFileGuid` },
										{ defaultValue: item.mapaFileName, name: `areas.${index}.mapaFileName` }]}
									/>

									<DropDown required isInvalidCheck={editarAll} enabled={editarAll} form={defaultForm}
										name={`areas.${index}.areaEnsaio`}
										label="Ensaios Associados a está Área" layoutColumns={6}
										isClearable={editarAll}
										placeholder={"Selecione Ensaios"}
										isMulti={true} defaultValue={item.areaEnsaio}
										options={EnsaiosList().map(i => Object.assign({ value: i.id, label: i.descricao }))} />

									<Form.Row className="row-invisible">
										<TextField readonly required isInvalidCheck={!location?.state?.readonly} label="ID" placeholder="ID" isNumer={true} enabled={editarAll} layoutColumns={1} form={defaultForm} name={`areas.${index}.id`} defaultValue={item.id} />
									</Form.Row>

								{/*	<TextField enabled={editarAll} form={defaultForm} name={`areas.${index}.id`} defaultValue={item.id} label="ID " layoutColumns={6} />*/}
								</Form.Row>
							}}
						></TabControl>
						&nbsp;&nbsp;
					</Fieldset>
				</If>
				<If condition={modoFluxo === MODO_VIEW.CONSTRUCOES}>
					<Grid<LpmaRN35, Lpma_RN_PotencialConstrucoes>
						form={defaultForm}
						{...defaultForm.register(`construcoes`)}
						name="construcoes"
						defaultValue={entity?.construcoes}
						required={true}
						style={{ height: '210px', width: '100%' }}
						label="Potenciais Construções a serem utilizadas"
						layoutColumns={12}
						enabled={editarAll}
						isInvalidCheck={!location?.state?.readonly}
						collumns={[
							// {header: 'Número', field: 'numeroContrucao', minWidth: 150,
							// showOnModal: true, custumControls : <TextField name="numeroContrucao" label="Número" layoutColumns={5} /> },
							{
								header: 'Nome da Construção', field: 'nomeContrucao',
								showOnModal: true, custumControls: <TextField name="nomeContrucao" layoutColumns={5} />
							},
							{
								header: 'Processo CTNBio RN06 no parecer técnico PT', field: 'processoCTNBio',
								showOnModal: true, custumControls: <TextField name="processoCTNBio" layoutColumns={12} />
							},
							{
								header: 'Nome da Semente', field: 'nomeSemente',
								showOnModal: false, custumControls: <TextField name="nomeSemente" layoutColumns={12} />
							},
							{
								header: 'Parecer Técnico CIBIO', field: 'parecerTecnicoCIBIO',
								showOnModal: true, custumControls: <TextField name="parecerTecnicoCIBIO" layoutColumns={12} />
							},
						]}
					></Grid>

					<Grid<LpmaRN35, Lpma_RN35_ProcessosAnteriores>
						style={{ height: '210px', width: '100%' }}
						form={defaultForm}
						name="processosAnteriores"
						{...defaultForm.register(`processosAnteriores`)}
						label="Nº dos Processos Aprovados pela CTNBio"
						layoutColumns={12}
						defaultValue={entity?.processosAnteriores ?? aprovadosInit}
						enabled={editarAll}
						collumns={[
							{
								header: 'Nº Processos Anteriores', field: 'numProcessoAnterior',
								showOnModal: true, custumControls: <TextField layoutColumns={4} />
							},

							{
								header: 'Data da Publicação no DOU', field: 'dataDecisao',
								gridValueFormatter: (event: any) => { return moment(event.data?.dataDecisao).format('DD/MM/YYYY'); },
								showOnModal: true, custumControls: <DateField layoutColumns={6}  />
							},

							{
								header: 'Extrato Parecer', field: 'extratoParececer',
								showOnModal: true, custumControls: <TextArea rows={4} layoutColumns={10} />
							},

						]}
					></Grid>
				</If>
				<If condition={modoFluxo === MODO_VIEW.GENES}>
					{LpmaRoles?.isAdmin() && (editarAll || editarReg) ?
					<Fieldset disabled={location?.state?.readonly} title={STRINGS_LPMA.BLOCK_GENES}>
						<Form.Row className="row-custom">
							<Form.Label>
								Genes de Interesse
								<AddButton
									type="button"
									onClick={() => setModalGeneInteresse(true)}
								/>
							</Form.Label>
						</Form.Row>
						<Form.Row className="row-custom">
							<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
								<AgGridReact
									rowData={itemsGenesInteresse}
									defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
									gridOptions={{ headerHeight: 80 }}
									stopEditingWhenGridLosesFocus={true}
									singleClickEdit={true}
									frameworkComponents={{
										actions: actionsGene,
									}}
								>
									<AgGridColumn
										headerName='Contagem'
										field="contagem"
										cellStyle={{
											justifyContent: 'left'
										}}
									/>
									<AgGridColumn
										headerName='Construção'
										field="nomeConstrucao"
										cellStyle={{
											justifyContent: 'left'
										}}
										autoHeight
										wrapText
										minWidth={100}
									/>
									<AgGridColumn
										headerName='Nome'
										field="nomeGeneInteresse"
										cellStyle={{
											justifyContent: 'left'
										}}
										autoHeight
										wrapText
										minWidth={100}
									/>
									<AgGridColumn
										headerName='Organismo Doador'
										field="organismoDoadorGeneInteresse"
										cellStyle={{
											justifyContent: 'left'
										}}
										autoHeight
										wrapText
										minWidth={100}
									/>
									<AgGridColumn
										headerName='Função'
										field="funcaoResumida"
										cellStyle={{
											justifyContent: 'left'
										}}
										autoHeight
										wrapText
										minWidth={100}
									/>
									<AgGridColumn
										headerName="Ações"
										cellRenderer="actions"
										maxWidth={100}
									/>
								</AgGridReact>
							</div>
						</Form.Row>
					</Fieldset>
					: ''}
				</If>

				<If condition={modoFluxo === MODO_VIEW.REGULATORIO}>
					{((entity?.status ?? STATUS.EmPreenchimento) !== STATUS.EmPreenchimento) ?
						<Fieldset disabled={location?.state?.readonly} title={STRINGS_LPMA.BLOCK_TWO}>
							<Form.Row className="row-custom">
								<DateField name="dataProposta" dateFormat="MMMM yyyy"
									isInvalidCheck={editarReg} 
									showMonthYearPicker
									label="Data da Proposta" maxDate={defaultForm.watch().dataEndosso} layoutColumns={3} form={defaultForm} enabled={editarReg} ></DateField>
								<DateField name="dataEndosso"
									isInvalidCheck={editarReg} 
									label="Data do Endosso" minDate={defaultForm.watch().dataProposta} layoutColumns={3} form={defaultForm} enabled={editarReg} ></DateField>

								<Grid<LpmaRN35, Lpma_RN_EquipeTecnica>
									isInvalidCheck={editarReg} required={editarReg}
									form={defaultForm}
									style={{ height: '210px', width: '100%' }}
									name="equipeTecnica" label="Equipe Técnica BASF"
									layoutColumns={12}
									enabled={editarReg}
									defaultValue={entity?.equipeTecnica}
									collumns={[
										{
											header: 'Técnico', field: 'tecnico',
											gridValueFormatter: (event: any) => { return event.data?.tecnico?.label },
											showOnModal: true, custumControls: <DropDown name="tecnico" label="Escolha um técnico para ser inserido" layoutColumns={12}
												options={itemsEquipeTecnica?.map(i => Object.assign({ value: i.id, label: i.nome }))} />
										},

										{
											showOnModal: false, showOnGrid: false, field: 'tecnico_Id',
											gridValueFormatter: (event: any) => { return event.data?.tecnico?.id }, header: ''
										},

									]}
								></Grid>
								<TextArea<LpmaRN35> isInvalidCheck={!location?.state?.readonly} enabled={editarReg} rows={3} form={defaultForm} name="observacoesRegulatorio" label="Observações da Análise do Regulatório" layoutColumns={12} />
							</Form.Row>
						</Fieldset> : ''}
					<LpmaDialogs
						ShowConfirmeSendRegulatorio={lpmaDlgShow?.ShowConfirmeSendRegulatorio}
						onConfirmeSendRegulatorio={lpmaDlgShow?.onConfirmeSendRegulatorio}
						onHide={() => { setLpmaDlgShow({}) }}
					/>
				</If>
				{parseButton()}

				<If condition={modalGeneInteresse}>
					<Modal
						show={true}
						onHide={() => setModalGeneInteresse(false)}
						title={STRINGS_LPMA.GENEINTERESSE_MODAL_TITLE}
					>
						<Form onSubmit={handleSubmitGeneInteresse(onSubmitGeneInteresse)} autoComplete="off">
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={8}>
									<Form.Label>
										Construção
									</Form.Label>
									<Controller
										control={controlGeneInteresse}
										name="construcao"
										as={
											<Select
												options={
													itemsPotenciaisConstrucoes!.map(local => Object.assign({
														value: local.id, label: local.nomeContrucao
													}))
												}
											/>
										}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Nome do Gene de Interesse<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerGeneInteresse()}
										required
										name="nomeGeneInteresse"
										placeholder="Insira o nome do gene de interesse"
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Organismo Doador do Gene de Interesse<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerGeneInteresse()}
										required
										name="organismoDoadorGeneInteresse"
										placeholder="Insira o organismo doador do gene de interesse"
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Função do Gene de Interesse<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerGeneInteresse()}
										required
										name="funcaoGeneInteresse"
										as="textarea"
										rows={10}
										placeholder="Insira a função do gene de interesse"
									/>
								</Form.Group>
							</Form.Row>
							<div className="button-position">
								<Button
									title="Cancelar"
									contained={false}
									type="button"
									onClick={() => setModalGeneInteresse(false)}
								/>
								<Button
									title="Salvar"
									contained={true}
									type="button"
									onClick={() => {
										triggerGeneInteresse().then(validated => {
											if (validated) {
												handleSubmitGeneInteresse(onSubmitGeneInteresse)();
											}
										})
									}}
								/>

							</div>
						</Form>
					</Modal>
				</If>

				<If condition={modalEditGeneInteresse}>
					<Modal
						show={true}
						onHide={() => setModalEditGeneInteresse(false)}
						title={STRINGS_LPMA.GENEINTERESSE_EDIT_MODAL_TITLE}
					>
						<Form autoComplete="off">
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={8}>
									<Form.Label>
										Contagem<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditGeneInteresse}
										name="editContagem"
										defaultValue={geneInteresseToEdit.contagem}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={8}>
									<Form.Label>
										Construção
									</Form.Label>
									<Controller
										control={controlEditGeneInteresse}
										name="editConstrucao"
										defaultValue={geneInteresseToEdit.construcaoId}
										as={
											<Select
												options={
													itemsPotenciaisConstrucoes!.map(local => Object.assign({
														value: local.id, label: local.nomeContrucao
													}))
												}
											/>
										}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={8}>
									<Form.Label>
										Nome do Gene de Interesse<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditGeneInteresse}
										name="editNomeGeneInteresse"
										defaultValue={geneInteresseToEdit.nomeGeneInteresse}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={8}>
									<Form.Label>
										Organismo Doador do Gene de Interesse<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditGeneInteresse}
										name="editOrganismoDoadorGeneInteresse"
										defaultValue={geneInteresseToEdit.organismoDoadorGeneInteresse}
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={8}>
									<Form.Label>
										Função do Gene de Interesse<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditGeneInteresse}
										name="editFuncaoGeneInteresse"
										as="textarea"
										rows={10}
										defaultValue={geneInteresseToEdit.funcaoGeneInteresse}
									/>
								</Form.Group>
							</Form.Row>
							<div className="button-position">
								<Button
									title="Cancelar"
									contained={false}
									type="button"
									onClick={() => setModalEditGeneInteresse(false)}
								/>
								<Button
									title="Salvar"
									contained={true}
									type="button"
									onClick={() => {
										triggerEditGeneInteresse().then(validated => {
											if (validated) {
												handleSubmitEditGeneInteresse(onSubmitEditGeneInteresse)();
											}
										})
									}}
								/>
							</div>
						</Form>
					</Modal>
				</If>


			</Form>
		</div>
	);
}