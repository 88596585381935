import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import Button from "../Button";
import DeleteButton from '../DeleteButton';
import EditButton from '../EditButton';
import TextField from './TextField';
import ReasonModal from '../../components/ReasonModal';
import { Form } from 'react-bootstrap';

interface Props<RowType>{
    form?       :any;
	name?       :string;
	label?      :string;
    layoutColumns?  : number;

    
	rows?					: RowType[];
	striped?				: boolean;
	bordered?				: boolean;
	hover?					: boolean;
}

interface Column<RowType>
{
	title?					: string;
    key?                    : string;
	parser					: keyof RowType | {(row:RowType) : string | JSX.Element}
	style?					: React.CSSProperties;
	colspan?				: number;
    index?                  : number;
}

export default class JsonToTable<RowType> extends React.Component<Props<RowType>>
{
    defaultProps: Partial<Props<RowType>> = {
		striped				: false,
		bordered			: false,
		hover				: false,
	};

    state = {
        columns		        : [],
        rowsData            : [],
        itemEdit            : {},
        editLinha           : 0,
        showDialog          : false,
    }

    constructor (props:Props<RowType>)
	{
		super(props);
        this.props.form?.register(this.props.name);
	}

	componentDidMount ()
	{
        try{
            const valor = this.props.form?.getValues(this.props.name);
            const jsonR = JSON.parse(valor);
            if(jsonR.columns!=null){
                let coluns: { title: any; style:any; item: boolean, key?: string }[] = [];
                let _cont = 0;       
                let collumsv = {...jsonR.columns}  
                for(var col in collumsv){ 
                    coluns.push({title : collumsv[col], style: null, item: true, key: col})
                    _cont++;
                }
                this.setState({columns: coluns});
                if(jsonR.dados!=null){
                    let dadolst = jsonR.dados as any [];
                    let rows = [];
                    for(var index = 0; index < dadolst.length; index ++){
                         const rowData = dadolst[index];
                        let datacell = {...rowData}
                        let cell: { value:any }[] = [];
                        for(var celula in datacell){
                            cell.push(datacell[celula]);
                        }
                        rows.push(cell);
                    }
                    this.setState({rowsData: rows});
                }
            }            
        }catch(error:any){
            console.error("ERROR", error)
        }
	}

    InsertOuUpdateItem():any{
            if(this.state.itemEdit !== null){
                let cell: any[] = [];
                for(var i = 0; i < this.state.columns?.length; i++){
                    const   col = this.state.columns[i] as Column<RowType>;
                    let     imp = false;  
                    //const   items = {...this.state.itemEdit};
                    for(var a in this.state.itemEdit){
                        const item = {...this.state.itemEdit}[a];
                        if(a === col.key) { cell.push(item); imp = true;  continue; }
                    }
                    if(!imp) { cell.push('') }
                }        
                let items: any[] = [];                
                if(this.state.editLinha !== 0)
                {
                    for(var r = 0, row = {}, lista = this.state.rowsData; r < lista.length; r++){
                        row = lista[r]
                        if(r === (this.state.editLinha - 1)){
                            items.push(cell as never);
                        }else{
                            items.push(row);
                        }
                        
                    }
                    //items[this.state.editLinha - 1] = cell;
                    this.setState({rowsData: items});
                }
                else {
                    items = this.state.rowsData;
                    items.push(cell as never);
                    this.setState({rowsData: items});
                }                  
            }
    }

    ColunaAction(linha:any):any{
        return <React.Fragment>            
             <EditButton tooltip={'Editar '} onClick={() => {
                 let item = this.state.rowsData[linha - 1];
                 let editItem = {}
                 for(var i = 0; i < this.state.columns?.length; i++){
                    const   colum = this.state.columns[i] as Column<RowType>;
                    let resultObj =  JSON.parse(`{ "${colum?.key}":  "${item[i]}" }`);
                    editItem = {...editItem, ...resultObj};
                 }
                this.setState({itemEdit : editItem, editLinha: linha, showDialog : true})
			}} />
             <DeleteButton tooltip={'Remover '} onClick={() => {
                 let items = this.state.rowsData;
                 items.splice((linha - 1), 1);
                 this.setState({rowsData: items});
			}} />
        </React.Fragment>;
    }

    componentDidUpdate(){
        let resultJson = {}
        let lstCollums = {}
        let lstDados   :any[] = []
        for(var i = 0; i < this.state.columns?.length; i++){
            const   colum = this.state.columns[i] as Column<RowType>;
            let resultObj =  JSON.parse(`{ "${colum?.key}":  "${colum?.title}"} `); //"${{...colum}[colum?.key ?? '']}" }`);
            lstCollums = {...lstCollums, ...resultObj}
        }
        for(var r = 0; r < this.state.rowsData.length; r++){
            let elemet = {}
            for(var i = 0; i < this.state.columns?.length; i++){
                const   colum = this.state.columns[i] as Column<RowType>;
                const   icell = (this.state.rowsData[r])[i];
                let resultObj =  JSON.parse(`{ "${colum?.title}":  "${icell}"} `);
            }
        }
        resultJson = {"columns": lstCollums, "dados": lstDados }
    }

    updateInputValue(evt:any, colunaIdx: any) {
        const colum = this.state.columns[colunaIdx]  as Column<RowType>;
        let resultObj =  JSON.parse(`{ "${colum?.key}":  "${evt.target.value}" }`);
        const newItemEdit = {
            ...this.state.itemEdit,
            ...resultObj,          
        }
        this.setState({ itemEdit : newItemEdit });
    }

	dialogEdit(childrens: any):any {
        return <ReasonModal
            className_={`dialogEditBox modal-dialog-50`} show={this.state.showDialog}
            onHide={() => this.setState({showDialog : false})}
            title={"Editar"}
            actionMsg={"Confirmar"}
            onConfirm={() => {            
                this.InsertOuUpdateItem();
                this.setState({showDialog : false})
            }}
            loading={false}
            disabled={false}
            // hideFooter={successCancel}
        ><Form.Row className="row-custom">{ 
            (Array.isArray(childrens) ? childrens : [childrens])?.map((child:any)=>{
            return {...child, enabled: child.enabled , name: child.name,  classNames: 'disableMode ' + (child?.className ?? ''), defaultValue: child?.defaultValue }
        })} </Form.Row>
        </ReasonModal>;

    }

	render ()
	{
		return <Bootstrap.Form.Group as={Bootstrap.Col} md={12}>{
            this.dialogEdit(<>
            {this.state.columns?.filter((e:any)=> e.item === true)?.map((column:Column<RowType>, colunaIdx: any) => {
                let initValue: any = '';
                if(this.state.itemEdit !== null && column?.key !== null){
                    initValue = {...this.state.itemEdit}[column?.key ?? 'x'];
                }
                return <TextField key={column.key} defaultValue={initValue} label={column.title} onChange={(evt:any) => this.updateInputValue(evt, colunaIdx)}></TextField>
            })}
            </>)
            }
            <Bootstrap.Form.Row className="button-position">
                <Button 
                title="+Adicionar"
                contained={true}
                type="button"
                onClick={() => { this.setState({itemEdit: null, editLinha: 0, showDialog : true})}} />
            </Bootstrap.Form.Row>
            <Bootstrap.Form.Group as={Bootstrap.Row}>
                <Bootstrap.Table
                    striped={this.props.striped}
                    bordered={this.props.bordered}
                    hover={this.props.hover}
                >
                    <thead>
                        {this.state.columns?.map((column:Column<RowType>, colIndex:any) => {
                            return <th style={column.style}>{column.title}</th>;
                        })}
                        <th>Ações</th>
                    </thead>
                    <tbody>
                        {this.state.rowsData?.map((row:any, rowIndex:any) => {
                            return <tr>
                                {this.state.columns?.map((column:Column<RowType>, colIndex:any) => {
                                return <td style={column.style}>{row[colIndex]}</td>;
                                })}
                                <td>{this.ColunaAction(rowIndex + 1)}</td>
                            </tr>;
                        })} 

                    </tbody>
                    <tfoot>

                    </tfoot>
                </Bootstrap.Table>
            </Bootstrap.Form.Group>
        </Bootstrap.Form.Group>;
	}
}