import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import Button from '../../components/Button';
import If from '../../components/If';
import AlertError from '../../components/AlertError';
import AlertSuccess from '../../components/AlertSuccess';
import { STRINGS } from '../../util/strings';
import { GroundsTypeForm } from '../../models/models';

function GroundsType() {
    const history = useHistory();
    const [successfulResponse, setSuccessfulResponse] = useState<boolean>();

    const groundsTypeSchema = yup.object().shape({
        name: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        description: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
    })

    const initialValues: GroundsTypeForm = {
        name: '',
        description: '',
    }

    const { register, handleSubmit, errors } = useForm({
        mode: 'onTouched',
        defaultValues: initialValues,
        resolver: yupResolver(groundsTypeSchema)
    });

    const verifyErrorsOnForm = (): boolean => {
        if (errors.name ||
            errors.description
        )
            return true;
        return false;
    }

    const onSubmit = (data: any) => {
        // console.log(data);
        setSuccessfulResponse(true);
    }

    return (
        <div className="container container-fluid">
            <If condition={verifyErrorsOnForm()}>
                <AlertError
                    message={STRINGS.ALERT_WARNING_FIELDS}
                />
            </If>
            <If condition={successfulResponse && !verifyErrorsOnForm()}>
                <AlertSuccess
                    message={STRINGS.ALERT_MESSAGE_SUCCESS}
                />
            </If>
            <h3>Cadastro de tipos de solo</h3>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row className="row-custom">
                    <Form.Group as={Col}>
                        <Form.Label>
                            Nome do tipo de solo<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            name="name"
                            type="text"
                            placeholder="Insira o nome do tipo de solo"
                            isInvalid={errors.name !== undefined}
                            autoComplete="off"
                            maxLength={100}
                        />
                        <If condition={errors.name}>
                            <Form.Control.Feedback type="invalid">
                                {errors.name?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Descrição<em>*</em>
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            name="description"
                            type="text"
                            placeholder="Insira a descrição do tipo de solo"
                            isInvalid={errors.description !== undefined}
                            autoComplete="off"
                        />
                        <If condition={errors.description}>
                            <Form.Control.Feedback type="invalid">
                                {errors.description?.message}
                            </Form.Control.Feedback>
                        </If>
                    </Form.Group>
                </Form.Row>
                <div className="button-position">
                    <Button
                        title="Cancelar"
                        type="button"
                        contained={false}
                        onClick={() => history.goBack()}
                    />
                    <Button
                        title="Cadastrar"
                        type="submit"
                        contained={true}
                    />
                </div>
            </Form>
        </div>
    );
}

export default GroundsType;