import React, { CSSProperties } from 'react';
import { Modal, Button } from 'react-bootstrap';
import LoadingInForm from './LoadingInForm';
import If from './If';

interface Props {
    show        : boolean;
    title       : string | any;
    actionMsg   : string;
    children    : any;
    onHide      : any;
    onConfirm   : any;
    disabled    : boolean;
    loading     : boolean;
    hideFooter  ?: boolean;
    footerOpctional?: boolean;
    className_  ?: any;
    style       ?: CSSProperties;
}

function ReasonModal({ show, title, actionMsg, children, onHide, onConfirm, disabled, loading, hideFooter, footerOpctional, className_, style }: Props) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            animation={true}
            className={className_} 
            style={style}                       
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <If condition={!hideFooter}>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={onHide}>
                        Cancelar
                    </Button>
                    <Button disabled={disabled || loading} variant="danger" onClick={onConfirm}>
                        {loading ? <LoadingInForm color="white"/> : actionMsg}
                    </Button>
                </Modal.Footer>
            </If>
            <If condition={footerOpctional}>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={onHide}>
                        {loading ? <LoadingInForm color="white"/> : actionMsg}
                    </Button>
                </Modal.Footer>
            </If>
        </Modal>
    );
}

export default ReasonModal;