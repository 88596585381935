import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import If from '../../../../components/If';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/Button';
import EditButton from '../../../../components/EditButton';
import ViewButton from '../../../../components/ViewButton';
import SearchEmpty from '../../../../components/SearchEmpty';
import api from '../../../../services/api';
import { countries } from '../../../../util/countries';
import { InstitutionResults } from '../../../../models/models';
import '../../styles_list.css';

export default function InstitutionData ()
{
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(true);

	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

	const [error, setError] = useState<boolean>(false);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);

	const [institutions, setInstitutions] = useState<Array<InstitutionResults>>([]);

	const { register, handleSubmit, getValues } = useForm({
		defaultValues: { name: '', cnpj: '', country: '', active: false},
	})

	useEffect(() => {
		const getInstitutions = async () => {
			try {
				const { data } = await api.get(`api/v1/Instituicao`);
				setInstitutions(data);
			} catch (error:any) {
				setError(true);
			}
			setLoading(false);
		};

		getInstitutions();
	}, [getValues])

	const search = async () => {
		setError(false);
		setLoadingSearch(true);
		let cpnjToFilter = getValues('cnpj') == undefined ? '' : getValues('cnpj');
		try {
			const { data } = await api.get(`api/v1/Instituicao/obterporfiltro?Nome=${getValues('name')}&Cnpj=${cpnjToFilter}&Pais=${getValues('country')}&Ativo=${getValues('active')}`);

			if (data.length) {
				setInstitutions(data);
				setEmptySearch(false);
			} else {
				setEmptySearch(true);
			}
		} catch (error:any) {
			setError(true);
		}
		setLoadingSearch(false);
	}

	const actions = ({ data }: any) => (
		<Fragment>
			<EditButton
				onClick={() => {
					history.push({
						pathname: '/cultivares/instituicao/editar',
						state: {
							id: data.id
						}
					})
				}}
			/>
			&nbsp;&nbsp;
			<ViewButton onClick={() => history.push({
				pathname: '/cultivares/instituicao/visualizar',
				state: {
					id: data.id
				}
			})} />
		</Fragment>
	)

	const cnpjFormatter = (params: any) => {
		let bruto: string = params.value;
		let semEspeciais = bruto.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "");

		let p1 = semEspeciais.substr(0, 2);
		let p2 = semEspeciais.substr(2, 3);
		let p3 = semEspeciais.substr(5, 3);
		let p4 = semEspeciais.substr(8, 4);
		let p5 = semEspeciais.substr(12, 2);

		return `${p1}.${p2}.${p3}/${p4}-${p5}`;
	}

	return <div className="container-custom-search">
		<div className="top-content">
			<h3>Consulta de instituição responsável pelo ensaio</h3>
		</div>
		<div className="row-buttons">
			<Button
				onClick={() => history.push('/cultivares/instituicao/cadastrar')}
				contained
				title="Adicionar"
				type="button"
			/>
		</div>
		<br />
		<Form onSubmit={handleSubmit(search)}>
			<Form.Row>
				<Form.Group as={Col} md={4}>
					<Form.Label>Nome</Form.Label>
					<Form.Control
						ref={register}
						name="name"
						maxLength={100}
						autoComplete="off"
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>CNPJ</Form.Label>
					<Form.Control
							ref={register}
							getInputRef={register}
							as={NumberFormat}
							type="text"
							name="cnpj"
							placeholder="Insira o CNPJ da instituição"
							autoComplete="off"
							format="##.###.###/####-##"                
					/>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>País</Form.Label>
					<Form.Control
						as="select"
						ref={register}
						name="country"
					>
						<Form.Control
							as="option"
							label="Escolha uma opção"
						/>
						{countries.map(country => (
							<Form.Control
								as="option"
								label={country}
								value={country}
								key={country}
							/>
						))}
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col} md={1} className="checkbox-lg check-in-search">
					<Form.Check
						type="checkbox"
						ref={register}
						name="active"
						label="Ativo"
					/>
				</Form.Group>
				<span className="filter-btn">
					<Button
						title="Buscar"
						type="submit"
						contained
						isLoading={loadingSearch}
					/>
				</span>
			</Form.Row>
		</Form>
		<If condition={emptySearch}>
			<div className="div-empty">
				<SearchEmpty />
			</div>
		</If>
		<If condition={!loading && !error && !emptySearch && institutions.length}>
			<div className="search-content">
				<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
					<AgGridReact
						rowData={institutions}
						defaultColDef={{ flex: 1, sortable: true, resizable: true }}
						pagination
						paginationPageSize={10}
						frameworkComponents={{
							actions: actions,
						}}
					>
						<AgGridColumn
							headerName='Nome Instituição'
							field='nome'
						/>
						<AgGridColumn
							headerName='CNPJ'
							field='cnpj'
							valueFormatter={cnpjFormatter}
						/>
						<AgGridColumn
							headerName="País"
							field="pais"
						/>
						<AgGridColumn
							field='Ações'
							cellRenderer='actions'
						/>
					</AgGridReact>
				</div>
			</div>
		</If>
		<If condition={loading}>
			<div className="loading-container">
				<Loading />
			</div>
		</If>
	</div>;
}