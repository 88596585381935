import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Form, Col } from 'react-bootstrap';
import SimpleInput from '../../../components/ReactSimpleInput';
import AddButton from '../../../components/AddButton';
import Button from '../../../components/Button';
import GenericButton from '../../../components/FlowGenericButton';
import EditButton from '../../../components/EditButton';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import LoadingInForm from '../../../components/LoadingInForm';
import ConfirmationModal from '../../../components/ConfirmationModal';
import {
	DestinatarioResult,
	UnityStationResult,
	EventResult,
	AMIForm,
	TransportListAMI,
	EventListAMI,
	CultureResult,
	Enum,
	ExpectedEvento,
	ExpectedTransporte,
	AACOnAMI,
	AACOnAMISearch,
	AACSearchResult,
	UsuarioDto
} from '../../../models/modelsAuthorizationsCIBIO';
import { STRINGS } from '../../../util/strings';
import { STRINGS_CIBIO } from '../../../util/authorizationsCIBIO/string';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { Context } from '../../../context/AuthContext'
import { DecodedToken } from '../../../models/user';
import '../../../pages/Registers/styles.css';
import api from '../../../services/api';
import jwtDecode from 'jwt-decode';
import { DecimalAsComma } from '../NumberFormat';
import { _generateDate } from '../../../util/convert';
import '../styles_add.scss';

registerLocale('pt-BR', ptBR);

export default function AMI ()
{
	const somaQuantidadeAutorizadaTotal = 0;
	const history = useHistory();
	const { token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);
	const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
	const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [loadingGetData, setLoadingGetData] = useState<boolean>(true);
	const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [errorGetData, setErrorGetData] = useState<any>(null);
	const [locations, setLocations] = useState<UnityStationResult[]>([]);
	const [events, setEvents] = useState<EventResult[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [transport, setTransport] = useState<Enum[]>([]);
	const [modalTransports, setModalTransports] = useState<boolean>(false);
	const [modalEnvio, setModalEnvio] = useState<boolean>(false);
	const [modalRecebimentos, setModalRecebimentos] = useState<boolean>(false);
	const [modalEditEnvio, setModalEditEnvio] = useState<boolean>(false);
	const [modalEditRecebimento, setModalEditRecebimento] = useState<boolean>(false);
	const [selectedTransports, setSelectedTransports] = useState<TransportListAMI[]>([]);
	const [modalEvents, setModalEvents] = useState<boolean>(false);
	const [modalEditEvents, setModalEditEvents] = useState<boolean>(false);
	const [selectedEvents, setSelectedEvents] = useState<EventListAMI[]>([]);
	const [eventToEdit, setEventToEdit] = useState<EventListAMI>({
		id: 0,
		evento: { label: '', value: 0 },
		material: { label: '', value: 0 },
		quantidadeAutorizada: 0,
		quantidadeTransportada: 0,
		unidadeMedidaAutorizada: { label: '', value: 0 },
		unidadeMedidaTransportada: { label: '', value: 0 }
	});
	const [transportToEdit, setTransportToEdit] = useState<TransportListAMI>({
		id: 0,
		localPartida: { label: '', value: 0 },
		localDestino: { label: '', value: 0 },
		embalagemDuplaImpermeavel: 0,
		identificacaoSimboloRiscoBiologico: 0,
		responsavelTransporte: '',
		dataEnvio: '',
		qtdTransportada: 0,
		unidadeMedidaTransportada: { label: '', value: 0 },
		materialChegouDestinoPerfeitasCondicoes: 0,
		qtdRecebidaDeAcordoComQtdEnviada: 0,
		responsavelRecebimento: '',
		dataRecebimento: '',
		qtdRecebida: 0,
		unidadeMedidaRecebida: { label: '', value: 0 },
		contagem: 0
	});
	const [aacVinculada, setAACVinculada] = useState<AACOnAMI[]>([]);
	const [modalAAC, setModalAAC] = useState<boolean>(false);
	const [listaAAC, setListaAAC] = useState<AACSearchResult[]>([]);
	const [listaBuscaAAC, setListaBuscaAAC] = useState<AACOnAMISearch[]>([]);
	const [divAAC, setDivAAC] = useState<boolean>(false);

	const [enumMaterial, setEnumMaterial] = useState<Enum[]>([]);
	const [enumUnidadeMedida, setEnumUnidadeMedida] = useState<Enum[]>([]);
	const [gridProps, setGridProps] = useState<any>(null);
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);
	const [eventMsg, setEventsMsg] = useState('');
	const [transportMsg, setTransportsMsg] = useState('');
	const [destinatarios, setDestinatarios] = useState<DestinatarioResult[]>([]);
	const [successMaterial, setSuccessMaterial] = useState<boolean>(false);


	const isEvento = modalEvents ? {
		evento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		evento: yup.mixed()
	}

	const isEditEvento = modalEditEvents ? {
		editEvento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
			editEvento: yup.mixed()
	}

	const isQuantidadeAutorizada = modalEvents ? {
		quantidadeAutorizada: yup
			.number()
			.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
			.nullable()
			.required(STRINGS.FORM_FIELD_REQUIRED)
			.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
	} : {
		quantidadeAutorizada: yup.number().nullable()
	}

	const isEditQuantidadeAutorizada = modalEditEvents ? {
		editQuantidadeAutorizada: yup
			.number()
			.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
			.nullable()
			.required(STRINGS.FORM_FIELD_REQUIRED)
			.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
	} : {
		editQuantidadeAutorizada: yup.number().nullable()
	}    

	const isMaterial = modalEvents ? {
		material: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		material: yup.mixed()
	}

	const isEditMaterial = modalEditEvents ? {
		editMaterial: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		editMaterial: yup.mixed()
	}

	const isResponsavelTransporte = modalTransports ? {
		responsavelTransporte: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		responsavelTransporte: yup.mixed()
	}

	const isEditResponsavelRecebimento = modalEditRecebimento ? {
		editresponsavelRecebimento: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		editresponsavelRecebimento: yup.mixed()
	}

	const isQuantidadeTransportada = modalTransports ? {
		qtdTransportada: yup
			.number()
			.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
			.nullable()
			.required(STRINGS.FORM_FIELD_REQUIRED)
			.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
	} : {
			qtdTransportada: yup.number().nullable()
	}

	const isEditqtdRecebida = modalEditRecebimento ? {
		editqtdRecebida: yup
			.number()
			.moreThan(0, STRINGS_CIBIO.AMI_VALIDATION_NUMBER_FIELD)
			.nullable()
			.required(STRINGS.FORM_FIELD_REQUIRED)
			.typeError(STRINGS_CIBIO.AMI_DEFAULT_NUMBER_ERROR)
	} : {
			editqtdRecebida: yup.number().nullable()
	}

	const amiSchema = yup.object({
		localDeEnvio: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		//destinatario: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		localDeDestino: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		possuiCQB: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		modoDeTransporte: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		origem: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		objetivoUtilizacao: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		observacaoPosAprovacao: yup.string(),
		culturaOrganismo: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		observacoes: yup.string(),
		trechoLPMA: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		escopoLPMA: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		embalagemDupla: yup.string(),
		identificacaoComSimboloRiscoBiologico: yup.string(),
		responsavelTransporte: yup.string(),
		dataEnvio: yup.date(),
		materialPerfeitasCondicoes: yup.string(),
		qtdRecebidaDeAcordoQtdEnviada: yup.string(),
		responsavelRecebimento: yup.string(),
		dataRecebimento: yup.date(),
		listaDestinatario: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED)
	});

	const initialValues: AMIForm = {
		requisitante:'',
		localDeEnvio: undefined,
		destinatario: '',
		listaDestinatario: { label: '', value: 0 },
		localDeDestino: undefined,
		possuiCQB: '0',
		modoDeTransporte: undefined,
		origem: '',
		objetivoUtilizacao: '',
		observacaoPosAprovacao: '',
		culturaOrganismo: undefined,
		observacoes: '',
		trechoLPMA: '',
		escopoLPMA: '0',
		embalagemDupla: '0',
		identificacaoComSimboloRiscoBiologico: '0',
		responsavelTransporte: '',
		dataEnvio: undefined,
		materialPerfeitasCondicoes: '1',
		qtdRecebidaDeAcordoQtdEnviada: '1',
		responsavelRecebimento: '',
		dataRecebimento: undefined
	}

	const eventSchema = yup.object({
		...isEvento,
		...isQuantidadeAutorizada,
		unidadeMedidaAutorizada: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		unidadeMedidaTransportada: yup.mixed().nullable(),
		...isMaterial,
		unidMedAutorizadaOutros: yup.string(),
		materialOutros: yup.string(),
		unidMedTranspOutros: yup.string()
	});

	const transportSchema = yup.object({
		...isResponsavelTransporte,
	});

	const envioSchema = yup.object({
		...isQuantidadeTransportada,
		...isResponsavelTransporte,
	});

	const eventEditSchema = yup.object({
		...isEditEvento,
		...isEditQuantidadeAutorizada,
		editUnidadeMedidaAutorizada: yup.mixed().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		editUnidadeMedidaTransportada: yup.mixed().nullable(),
		...isEditMaterial,
		unidMedAutorizadaOutros: yup.string(),
		materialOutros: yup.string(),
		unidMedTranspOutros: yup.string()
	});

	const transportEditSchema = yup.object({
		...isEditResponsavelRecebimento
	});

	const envioEditSchema = yup.object({
	});

	const { register, getValues, errors, trigger, handleSubmit, control, watch, formState: { isDirty } } = useForm({
		defaultValues: initialValues,
		mode: 'onSubmit',
		resolver: yupResolver(amiSchema)
	});
	
	const { getValues: getValuesEvent, control: controlEvent, register: registerEvent, errors: errorsEvent, handleSubmit: handleSubmitEvent, trigger: triggerEvent, watch: watchEvent } = useForm({
		mode: 'all',
		resolver: yupResolver(eventSchema)
	})

	const { getValues: getValuesTransport, control: controlTransport, register: registerTransport, errors: errorsTransport, handleSubmit: handleSubmitTransport, trigger: triggerTransport, watch: watchTransport } = useForm({
		mode: 'all',
		resolver: yupResolver(transportSchema)
	})

	const { control: controlEditEvent, register: registerEditEvent, errors: errorsEditEvent, handleSubmit: handleSubmitEditEvent, trigger: triggerEditEvent, watch: watchEditEvent } = useForm({
		mode: 'all',
		resolver: yupResolver(eventEditSchema)
	})

	const { control: controlEditTransport, register: registerEditTransport, errors: errorsEditTransport, handleSubmit: handleSubmitEditTransport, trigger: triggerEditTransport, watch: watchEditTransport } = useForm({
		mode: 'all',
		resolver: yupResolver(transportEditSchema)
	})

	const { control: controlEditEnvio, register: registerEditEnvio, errors: errorsEditEnvio, handleSubmit: handleSubmitEditEnvio, trigger: triggerEditEnvio, watch: watchEditEnvio } = useForm({
		mode: 'all',
		resolver: yupResolver(envioEditSchema)
	})

	const { getValues: getValuesBuscaAAC, control: controlBuscaAAC, register: registerBuscaAAC, errors: errorsBuscaAAC, handleSubmit: handleSubmitBuscaAAC, trigger: triggerBuscaAAC, watch: watchBuscaAAC } = useForm({
		mode: 'all',
		//		resolver: yupResolver(BuscaAACSchema)
	})

	useEffect(() => {
		const getDataToForm = async () => {
			try {
				const locations = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');

				const events = await api.get<EventResult[]>('api/v1/autorizacoes-cibio/Evento/obterporfiltro?Ativo=true');

				const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');

				const material = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/material');

				const unidadeMedida = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/unidade-medida');

				const transporte = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/modo-transporte');

				const destinatarios = await api.get<DestinatarioResult[]>('api/v1/autorizacoes-cibio/Destinatario/obterporfiltro?Ativo=true');

				if (locations.status === 200) {
					setLocations(locations.data);
				}

				if (events.status === 200) {
					setEvents(events.data);
				}

				if (cultures.status === 200) {
					setCultures(cultures.data);
				}

				if (material.status === 200) {
					setEnumMaterial(material.data);
				}

				if (unidadeMedida.status === 200) {
					setEnumUnidadeMedida(unidadeMedida.data);
				}

				if (transporte.status === 200) {
					setTransport(transporte.data);
				}

				if (destinatarios.status === 200) {
					setDestinatarios(destinatarios.data);
				}

			} catch (error:any) {
				setErrorGetData(error?.response?.data);
			}

			setSuccessMaterial(true);

			setLoadingGetData(false);
		}

		getDataToForm();
	}, []);

	const verifyErrorsOnForm = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errors)) {
			Object.entries(errors).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const verifyErrorsOnFormEvent = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEvent)) {
			Object.entries(errorsEvent).map(err => errorsForm.push(err[0]));
		}

		return errorsForm;
	}

	const verifyErrorsOnFormTransport = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsTransport)) {
			Object.entries(errorsTransport).map(err => errorsForm.push(err[0]));
		}

		return errorsForm;
	}

	const verifyErrorsOnFormEditEvent = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEditEvent)) {
			Object.entries(errorsEditEvent).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const verifyErrorsOnFormEditEnvio = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEditEnvio)) {
			Object.entries(errorsEditEnvio).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	const verifyErrorsOnFormEditTransport = (): string[] => {
		let errorsForm: any[] = [];

		if (!isEmptyObj(errorsEditTransport)) {
			Object.entries(errorsEditTransport).map(err => errorsForm.push(err[0]));
		}
		return errorsForm;
	}

	// #region Search AAC
	const searchAAC = async () => {
		let filtroBuscaAAC: AACOnAMI =
		{
			id: 0,
			numeroAutorizacaoCibio: getValues('numeroAutorizacaoCibioAAC') != null ? getValues('numeroAutorizacaoCibioAAC') : '',
			solicitanteTecnicoPrincipal: getValues('tecnicoPrincipalAAC') != null ? getValues('tecnicoPrincipalAAC') : '',
		};

		try {
			const listaBuscaAAC = await api.get<AACOnAMISearch[]>(`api/v1/autorizacoes-cibio/AtividadeContencao/obterporfiltro?Codigo=${filtroBuscaAAC.numeroAutorizacaoCibio}&SolicitanteTecnicoPrincipal=${filtroBuscaAAC.solicitanteTecnicoPrincipal}`);

			if (listaBuscaAAC.data.length) {
				setListaBuscaAAC(listaBuscaAAC.data);
			}
		} catch (error: any) {
			//setError(true);
		}
	}
	//#endregion

	const getValuesToSubmit = () => {

		let atividadeContencaoId = null;

		if (divAAC)
			atividadeContencaoId = aacVinculada.length > 0 ? aacVinculada[0].id : null;

		return {
			requisitante: decoded.unique_name,
			destinatario: getValues('listaDestinatario')?.label,
			destinatarioId: getValues('listaDestinatario')?.value,
			localDestinoPossuiCQB: getValues('possuiCQB'),
			origem: getValues('origem'),
			objetivoUtilizacao: getValues('objetivoUtilizacao'),
			observacaoPosAprovacao: getValues('observacaoPosAprovacao'),
			observacoes: getValues('observacoes'),
			trechoLPMALocaisMovimentacaoMaterial: getValues('trechoLPMA'),
			escopoLPMAContemplaMaterial: getValues('escopoLPMA'),
			embalagemDuplaImpermeavel: 0,
			identificacaoSimboloRiscoBiologico: 0,
			responsavelTransporte: '',
			dataEnvio: '',
			materialChegouDestinoPerfeitasCondicoes: 0,
			qtdRecebidaDeAcordoComQtdEnviada: 0,
			responsavelRecebimento: '',
			dataRecebimento: '',
			culturaId: getValues('culturaOrganismo')?.value,
			modoTransporteId: getValues('modoDeTransporte')?.value,
			localPartidaId: getValues('localDeEnvio')?.value,
			localDestinoId: getValues('localDeDestino')?.value,
			eventos: expectedEventos(),
			transportes: expectedTransports(),
			atividadeContencaoId: atividadeContencaoId,
		};
	}

	const onSubmit = async () => {
		setErrorSubmit(null);
		setLoadingSubmit(true);

		try {
			const valuesToSubmit = getValuesToSubmit();
			const { status, data } = await api.post('api/v1/autorizacoes-cibio/MovimentacaoInterna/enviarregulatorio', valuesToSubmit);

			if (status === 200) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setSuccessSubmit(true);
				setTimeout(() => {
					history.push('/autorizacoes/editar/ami', { id: data.id });
				}, 3000);
			}
		} catch (error:any) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorSubmit(error.response.data);
		}

		setLoadingSubmit(false);
	}

	const onSubmitDraft = async () => {
		setErrorDraft(null);
		setLoadingDraft(true);
		
		try {
			const amiToSubmitDraft = getValuesToSubmit();
			const { status, data } = await api.post('api/v1/autorizacoes-cibio/MovimentacaoInterna/rascunho', amiToSubmitDraft);

			if (status === 201) {
				setSuccessDraft(true);
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setTimeout(() => {
					history.push('/autorizacoes/editar/ami', { id: data.id });
				}, 3000);
			}
		} catch (error:any) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setErrorDraft(error.response.data);
		}

		setLoadingDraft(false);
	}

	const onSubmitEvent = (info: EventListAMI): void => {
		let unidadeMedidaAutorizada = info.unidadeMedidaAutorizada.label;
		let unidadeMedidaTransportada = info.unidadeMedidaTransportada?.label;
		let material = info.material.label;

		let validacao = true;

		if (material == "Grão") {
			if (unidadeMedidaAutorizada !== '')
				if (unidadeMedidaAutorizada !== "Kg" && unidadeMedidaAutorizada !== "Gramas")
					validacao = false;

			if (typeof unidadeMedidaTransportada !== 'undefined')
				if (unidadeMedidaTransportada !== "Kg" && unidadeMedidaTransportada !== "Gramas")
					validacao = false;
		}

		if (validacao) {
			setSuccessMaterial(true);

			setModalEvents(false);
			setSelectedEvents([...selectedEvents, {
				id: 0,
				evento: info.evento,
				material: info.material,
				quantidadeAutorizada: info.quantidadeAutorizada,
				unidadeMedidaAutorizada: info.unidadeMedidaAutorizada,
				quantidadeTransportada: info.quantidadeTransportada,
				unidadeMedidaTransportada: info.unidadeMedidaTransportada,
				materialOutros: info.materialOutros,
				unidMedAutorizadaOutros: info.unidMedAutorizadaOutros,
				unidMedTranspOutros: info.unidMedTranspOutros
			}]);
		}
		else {
			setSuccessMaterial(false);
		}

	}

	const onSubmitEditiEvent = (info: any): void => {
		let unidadeMedidaAutorizada = info.editUnidadeMedidaAutorizada.label;
		let unidadeMedidaTransportada = info.unidadeMedidaTransportada?.label;
		let material = info.editMaterial.label;

		let validacao = true;

		if (material == "Grão") {
			if (unidadeMedidaAutorizada !== '')
				if (unidadeMedidaAutorizada !== "Kg" && unidadeMedidaAutorizada !== "Gramas")
					validacao = false;

			if (typeof unidadeMedidaTransportada !== 'undefined')
				if (unidadeMedidaTransportada !== "Kg" && unidadeMedidaTransportada !== "Gramas")
					validacao = false;
		}

		if (validacao)
		{
			setSuccessMaterial(true);

			setSelectedEvents([]);
			let arr: any = [];

			gridProps.gridApi.updateRowData({ remove: [gridProps.data] })

			gridProps.gridApi.forEachNode(({ data }: any) => arr.push(data));
			setSelectedEvents([...arr, {
				id: info.id,
				unidadeMedidaAutorizadaDescricao: info.editUnidadeMedidaAutorizada.label,
				materialDescricao: info.editMaterial.label,
				unidadeMedidaTransportadaDescricao: info.editUnidadeMedidaTransportada.label,
				eventoNome: info.editEvento.label,
				qtdAutorizada: info.editQuantidadeAutorizada,
				qtdTransportada: info.editQuantidadeTransportada,
				unidadeMedidaAutorizada: info.editUnidadeMedidaAutorizada.value,
				unidMedAutorizadaOutros: info?.unidMedAutorizadaOutros,
				material: info.editMaterial.value,
				materialOutros: info?.materialOutros,
				unidadeMedidaTransportada: info.editUnidadeMedidaTransportada.value,
				unidMedTranspOutros: info?.unidMedTranspOutros,
				eventoId: info.editEvento.value
			}]);

			setModalEditEvents(false);
		}
		else {
			setSuccessMaterial(false);
		}
	}

	const onSubmitTransport = (info: TransportListAMI): void => {

		let unidadeMedidaTransportada = info?.unidadeMedidaTransportada?.label;
		let unidadeMedidaRecebida = info?.unidadeMedidaRecebida?.label;
		let material = selectedEvents[0]?.material.label;

		let validacao = true;

		if (material == "Grão") {
			if (typeof unidadeMedidaRecebida !== 'undefined')
				if (unidadeMedidaRecebida !== "Kg" && unidadeMedidaRecebida !== "Gramas")
					validacao = false;

			if (typeof unidadeMedidaTransportada !== 'undefined')
				if (unidadeMedidaTransportada !== "Kg" && unidadeMedidaTransportada !== "Gramas")
					validacao = false;
		}

		if (validacao) {
			setSuccessMaterial(true);

			setModalTransports(false);

			let itens = selectedTransports.length + 1;

			setSelectedTransports([...selectedTransports, {
				id: 0,
				localPartida: info.localPartida,
				localDestino: info.localDestino,
				embalagemDuplaImpermeavel: info.embalagemDuplaImpermeavel,
				identificacaoSimboloRiscoBiologico: info.identificacaoSimboloRiscoBiologico,
				responsavelTransporte: info.responsavelTransporte,
				dataEnvio: info.dataEnvio !== undefined ? info.dataEnvio : "",
				qtdTransportada: info.qtdTransportada !== undefined ? info.qtdTransportada : 0,
				unidadeMedidaTransportada: info.unidadeMedidaTransportada !== undefined ? info.unidadeMedidaTransportada : { label: '', value: 0 },
				materialChegouDestinoPerfeitasCondicoes: info.materialChegouDestinoPerfeitasCondicoes !== undefined ? info.materialChegouDestinoPerfeitasCondicoes : 0,
				qtdRecebidaDeAcordoComQtdEnviada: info.qtdRecebidaDeAcordoComQtdEnviada !== undefined ? info.qtdRecebidaDeAcordoComQtdEnviada : 0,
				responsavelRecebimento: info.responsavelRecebimento !== undefined ? info.responsavelRecebimento : "",
				dataRecebimento: info.dataRecebimento !== undefined ? info.dataRecebimento : "",
				qtdRecebida: info.qtdRecebida !== undefined ? info.qtdRecebida : 0,
				unidadeMedidaRecebida: info.unidadeMedidaRecebida !== undefined ? info.unidadeMedidaRecebida : { label: '', value: 0 },
				contagem: itens
			}]);
		}
		else {
			setSuccessMaterial(false);
		}
	}

	const onSubmitEditEnvio = (info: any): void => {

		let unidadeMedidaTransportada = info?.editUnidadeMedidaTransportada?.label;
		let material = selectedEvents[0]?.material.label;

		let validacao = true;

		if (material == "Grão") {
			if (typeof unidadeMedidaTransportada !== 'undefined')
				if (unidadeMedidaTransportada !== "Kg" && unidadeMedidaTransportada !== "Gramas")
					validacao = false;
		}

		if (validacao)
		{
			setSuccessMaterial(true);

			const arr = selectedTransports.filter(e => e.contagem != info.editContagem);

			setSelectedTransports([...arr, {
				id: info.id,
				localPartida: info.editlocalPartida,
				localDestino: info.editlocalDestino,
				embalagemDuplaImpermeavel: info.editembalagemDuplaImpermeavel,
				identificacaoSimboloRiscoBiologico: info.editidentificacaoSimboloRiscoBiologico,
				responsavelTransporte: info.editresponsavelTransporte,
				dataEnvio: info.editdataEnvio,
				qtdTransportada: info.editqtdTransportada,
				unidadeMedidaTransportada: info.editunidadeMedidaTransportada,
				materialChegouDestinoPerfeitasCondicoes: info.materialChegouDestinoPerfeitasCondicoes !== undefined ? info.materialChegouDestinoPerfeitasCondicoes : 0,
				qtdRecebidaDeAcordoComQtdEnviada: info.qtdRecebidaDeAcordoComQtdEnviada !== undefined ? info.qtdRecebidaDeAcordoComQtdEnviada : 0,
				responsavelRecebimento: info.responsavelRecebimento !== undefined ? info.responsavelRecebimento : "",
				dataRecebimento: info.dataRecebimento !== undefined ? info.dataRecebimento : "",
				qtdRecebida: info.qtdRecebida !== undefined ? info.qtdRecebida : 0,
				unidadeMedidaRecebida: info.unidadeMedidaRecebida !== undefined ? info.unidadeMedidaRecebida : 0,
				contagem: info.editContagem
			}]);

			setModalEditEnvio(false);
		}
		else {
			setSuccessMaterial(false);
		}
	}

	const onSubmitEditTransport = (info: any): void => {

		let unidadeMedidaRecebida = info?.editunidadeMedidaRecebida?.label;
		let material = selectedEvents[0]?.material.label;

		let validacao = true;

		if (material == "Grão" || material == "Sementes") {
			if (typeof unidadeMedidaRecebida !== 'undefined')
				if (unidadeMedidaRecebida !== "Kg" && unidadeMedidaRecebida !== "Gramas")
					validacao = false;
		}

		if (validacao) {
			setSuccessMaterial(true);

			const arr = selectedTransports.filter(e => e.contagem != info.editContagem);

			setSelectedTransports([...arr, {
				id: info.id,
				localPartida: info.editlocalPartida,
				localDestino: info.editlocalDestino,
				embalagemDuplaImpermeavel: info.editembalagemDuplaImpermeavel,
				identificacaoSimboloRiscoBiologico: info.editidentificacaoSimboloRiscoBiologico,
				responsavelTransporte: info.editresponsavelTransporte,
				dataEnvio: info.editdataEnvio,
				qtdTransportada: info.editqtdTransportada,
				unidadeMedidaTransportada: info.editunidadeMedidaTransportada,
				materialChegouDestinoPerfeitasCondicoes: info.editmaterialChegouDestinoPerfeitasCondicoes !== undefined ?
					info.editmaterialChegouDestinoPerfeitasCondicoes : 0,
				qtdRecebidaDeAcordoComQtdEnviada: info.editqtdRecebidaDeAcordoComQtdEnviada !== undefined ?
					info.editqtdRecebidaDeAcordoComQtdEnviada : 0,
				responsavelRecebimento: info.editresponsavelRecebimento,
				dataRecebimento: info.editdataRecebimento,
				qtdRecebida: info.editqtdRecebida,
				unidadeMedidaRecebida: info.editunidadeMedidaRecebida,
				contagem: info.editContagem
			}]);

			setModalEditRecebimento(false);
		}
		else {
			setSuccessMaterial(false);
		}
	}

	const expectedEventos = (): ExpectedEvento[] => {
		let arrEvent: ExpectedEvento[] = [];

		for (let count = 0, countLen = selectedEvents.length; count < countLen; count++) {
			arrEvent.push({
				Id: 0,
				eventoId: selectedEvents[count].evento.value,
				eventoNome: selectedEvents[count].evento.label,
				qtdAutorizada: selectedEvents[count].quantidadeAutorizada,
				qtdTransportada: selectedEvents[count].quantidadeTransportada !== undefined ? selectedEvents[count].quantidadeTransportada : 0,
				unidadeMedidaAutorizada: selectedEvents[count].unidadeMedidaAutorizada.value,
				unidadeMedidaAutorizadaDescricao: selectedEvents[count].unidadeMedidaAutorizada.label,
				unidMedAutorizadaOutros: selectedEvents[count].unidMedAutorizadaOutros,
				material: selectedEvents[count].material.value,
				materialDescricao: selectedEvents[count].material.label,
				materialOutros: selectedEvents[count].materialOutros,
				unidadeMedidaTransportada: selectedEvents[count].unidadeMedidaTransportada?.value,
				unidMedTranspOutros: selectedEvents[count].unidMedTranspOutros,
				unidadeMedidaTransportadaDescricao: selectedEvents[count].unidadeMedidaTransportada?.label
			})
		}
		return arrEvent;
	}

	const expectedTransports = (): ExpectedTransporte[] => {
		let arrTransport: ExpectedTransporte[] = [];

		for (let count = 0, countLen = selectedTransports.length; count < countLen; count++) {
			arrTransport.push({
				Id: 0,
				localPartidaId: selectedTransports[count].localPartida.value,
				localDestinoId: selectedTransports[count].localDestino.value,
				localPartidaNome: selectedTransports[count].localPartida.label,
				localDestinoNome: selectedTransports[count].localDestino.label,
				embalagemDuplaImpermeavel: selectedTransports[count].embalagemDuplaImpermeavel,
				identificacaoSimboloRiscoBiologico: selectedTransports[count].identificacaoSimboloRiscoBiologico,
				responsavelTransporte: selectedTransports[count].responsavelTransporte,
				dataEnvio: selectedTransports[count].dataEnvio !== undefined ? selectedTransports[count].dataEnvio : '',
				qtdTransportada: selectedTransports[count].qtdTransportada,
				unidadeMedidaTransportada: selectedTransports[count].unidadeMedidaTransportada.value,
				materialChegouDestinoPerfeitasCondicoes: selectedTransports[count].materialChegouDestinoPerfeitasCondicoes,
				qtdRecebidaDeAcordoComQtdEnviada: selectedTransports[count].qtdRecebidaDeAcordoComQtdEnviada,
				responsavelRecebimento: selectedTransports[count].responsavelRecebimento,
				dataRecebimento: selectedTransports[count].dataRecebimento !== undefined ? selectedTransports[count].dataRecebimento : '',
				qtdRecebida: selectedTransports[count].qtdRecebida,
				unidadeMedidaRecebida: selectedTransports[count].unidadeMedidaRecebida.value,
				contagem: selectedTransports[count].contagem
			})
		}
		return arrTransport;
	}

	const actionsEvents = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				setSuccessMaterial(true);

				setEventToEdit(props.data);

				setGridProps(props.node);

				setModalEditEvents(true)
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedEvents([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedEvents([...arr]);
			}} />
		</Fragment>
	)

	const actionsEnvio = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				setSuccessMaterial(true);

				setTransportToEdit(props.data);

				setGridProps(props.node);

				setModalEditEnvio(true)
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedTransports([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedTransports([...arr]);
			}} />
		</Fragment>
	)

	const actionsTransports = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				setTransportToEdit(props.data);

				setGridProps(props.node);

				setModalEditRecebimento(true)
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedTransports([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedTransports([...arr]);
			}} />
		</Fragment>
	)

	const actionsBuscaAAC = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {
				setGridProps(props.node);

				let listaAACVinculada: AACOnAMI[] = [];

				let aac: AACOnAMI =
				{
					id: props.data.id,
					numeroAutorizacaoCibio: props.data.numeroAutorizacaoCibio,
					solicitanteTecnicoPrincipal: props.data.solicitanteTecnicoPrincipal
				};

				listaAACVinculada.push(aac);

				setAACVinculada(listaAACVinculada);

				setModalAAC(false);
			}} />
		</Fragment>
	)

	return <div className="container container-fluid">
		<If condition={verifyErrorsOnForm()}>
			{verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
		</If>
		<If condition={eventMsg}>
			<AlertError message={eventMsg} />
		</If>
		<If condition={successDraft && !errorDraft}>
			<AlertSuccess message={STRINGS.SUCCESS_DRAFT} />
		</If>
		{/*<If condition={!successDraft && errorDraft}>*/}
		{/*	{errorDraft !== null && errorDraft.message.length > 0 ? errorDraft.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}*/}
		{/*</If>*/}
		<If condition={successSubmit && !errorSubmit}>
			<AlertSuccess
				message={STRINGS.REGULATORY_SUCCESS}
			/>
		</If>
		<If condition={!successSubmit && errorSubmit !== null}>
			{errorSubmit !== null && errorSubmit.message.length > 0 ? errorSubmit.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<h3>{STRINGS_CIBIO.REGISTER_AMI}</h3>
		<Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<Fieldset title={STRINGS_CIBIO.AMI_BLOCK_ONE}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Solicitante/Requisitante
						</Form.Label>
						<Form.Control
							value={decoded.unique_name}
							readOnly
							maxLength={100}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Local Envio/Partida<em>*</em>
							&nbsp;&nbsp;
							<If condition={loadingGetData}>
								<LoadingInForm />
							</If>
						</Form.Label>
						<Controller
							control={control}
							name="localDeEnvio"
							isInvalid={errors.localDeEnvio !== undefined}
							as={<Select
								placeholder="Selecione um local"
								options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
								isClearable
							/>}
						/>
						<If condition={errors.localDeEnvio}>
							<p className="error-field">{errors.localDeEnvio?.message}</p>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Destinatário<em>*</em>
							&nbsp;&nbsp;
							<If condition={loadingGetData}>
								<LoadingInForm />
							</If>
						</Form.Label>
						<Controller
							control={control}
							name="listaDestinatario"
							isInvalid={errors.listaDestinatario !== undefined}
							as={<Select
								placeholder="Selecione um destinatario"
								options={destinatarios.map(local => Object.assign({ value: local.id, label: local.nome }))}
								isClearable
							/>}
						/>
						<If condition={errors.listaDestinatario}>
							<p className="error-field">{errors.listaDestinatario?.message}</p>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Local Destino<em>*</em>
							&nbsp;&nbsp;
							<If condition={loadingGetData}>
								<LoadingInForm />
							</If>
						</Form.Label>
						<Controller
							control={control}
							name="localDeDestino"
							isInvalid={errors.localDeDestino !== undefined}
							as={<Select
								options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
								placeholder="Selecione um local"
								isClearable
							/>}
						/>
						<If condition={errors.localDeDestino}>
							<span className="error-field">{errors.localDeDestino?.message}</span>
						</If>
					</Form.Group>
				</Form.Row>
				<p />
				<Form.Row className="row-custom">
					&nbsp;&nbsp;
					<Form.Label>
						O local de destino possui CQB?<em>*</em>
					</Form.Label>
					&nbsp;&nbsp;
					<Form.Check
						ref={register}
						name="possuiCQB"
						type="radio"
						aria-label="radio 1"
						label="Sim"
						value={2}
					/>
					&nbsp;&nbsp;
					<Form.Check
						ref={register}
						name="possuiCQB"
						type="radio"
						aria-label="radio 1"
						label="Não"
						value={1}
					/>
					&nbsp;&nbsp;
					<Form.Check
						ref={register}
						name="possuiCQB"
						type="radio"
						aria-label="radio 1"
						label="Não se aplica"
						value={3}
					/>
				</Form.Row>
				<p />
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Modo de transporte<em>*</em>
						</Form.Label>
						<Controller
							control={control}
							name="modoDeTransporte"
							isInvalid={errors.modoDeTransporte}
							as={<Select
								options={transport.map(transp => Object.assign({ label: transp.value, value: transp.key }))}
								placeholder="Selecione um modo de transporte"
								isClearable
							/>}
						/>
						<If condition={errors.modoDeTransporte}>
							<p className="error-field">{errors.modoDeTransporte?.message}</p>
						</If>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Origem<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="origem"
							placeholder="Insira a origem"
							isInvalid={errors.origem !== undefined}
							maxLength={150}
						/>
						<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_ORIGEM_HELP_TEXT}</Form.Text>
						<Form.Control.Feedback type="invalid">
							{errors.origem?.message}
						</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Objetivo da utilização<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="objetivoUtilizacao"
							as="textarea"
							rows={4}
							placeholder="Insira o objetivo da utilização"
							isInvalid={errors.objetivoUtilizacao !== undefined}
						/>
						<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_OBJETIVO_HELP_TEXT}</Form.Text>
						<Form.Control.Feedback type="invalid">
							{errors.objetivoUtilizacao?.message}
						</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>

				<If condition={false}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<div className="option-cultivar">
								<span>Esta AMI está vinculada a uma AAC aprovada?</span>
								&nbsp;
								<Form.Check
									value="sim"
									type="radio"
									aria-label="radio 1"
									label="Sim"
									onChange={() => setDivAAC(true)}
									checked={divAAC}
								/>
								<Form.Check
									value="não"
									type="radio"
									aria-label="radio 2"
									label="Não"
									onChange={() => setDivAAC(false)}
									checked={!divAAC}
								/>
							</div>
						</Form.Group>
					</Form.Row>
				</If>

				<If condition={divAAC}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Selecionar Atividade de Contenção Vinculada
								<AddButton
									type="button"
									onClick={() => setModalAAC(true)}
								/>
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={aacVinculada != null}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={aacVinculada}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
							>
								<AgGridColumn
									headerName='Solicitante'
									field="solicitanteTecnicoPrincipal"
									headerClass="myfunc-default-header"
									autoHeight
									wrapText
								/>
								<AgGridColumn
									headerName='Número de Autorização Cibio'
									field="numeroAutorizacaoCibio"
									headerClass="myfunc-default-header"
									autoHeight
									wrapText
								/>
							</AgGridReact>
						</div>
					</If>

				</If>
				<br />

				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Evento<em>*</em>{`  `}
							<AddButton
								type="button"
								onClick={() => setModalEvents(true)}
							/>
						</Form.Label>
						<If condition={eventMsg}>
							<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
						</If>
					</Form.Group>
				</Form.Row>
				<If condition={selectedEvents.length}>
					<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
						<AgGridReact
							rowData={selectedEvents}
							defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
							gridOptions={{ headerHeight: 80 }}
							stopEditingWhenGridLosesFocus={true}
							singleClickEdit={true}
							frameworkComponents={{
								actions: actionsEvents,
							}}
						>
							<AgGridColumn
								headerName='Evento'
								valueGetter={({ data }: any) => data.evento.label}
								cellStyle={{
									justifyContent: 'left'
								}}
								autoHeight
								wrapText
								minWidth={250}
							/>
							<AgGridColumn
								headerName='Quantidade Autorizada'
								field="quantidadeAutorizada"
								headerClass="myfunc-default-header"
								valueFormatter={({ data }: any) => {return DecimalAsComma(data.quantidadeAutorizada);}}
							/>
							<AgGridColumn
								headerName='Unidade Medida Autorizada'
								headerClass="myfunc-default-header"
								field="unidadeMedidaAutorizadaDescricao"
								valueFormatter={({ data }: any) => {

									if (data?.unidadeMedidaAutorizada?.label == 'Outros')
										return `Outros (${data.unidMedAutorizadaOutros})`;

									return data?.unidadeMedidaAutorizada?.label;
									
								}}
							/>
							<AgGridColumn
								headerName='Quantidade Transportada'
								field="qtdTransportada"
								headerClass="myfunc-default-header"
								valueFormatter={({ data }: any) => {return DecimalAsComma(data.quantidadeTransportada);}}
							/>
							<AgGridColumn
								headerName='Unidade Medida Transportada'
								field="unidadeMedidaTransportadaDescricao"
								headerClass="myfunc-default-header"
								valueFormatter={({ data }: any) => {

									if (data?.unidadeMedidaTransportada?.label == 'Outros')
										return `Outros (${data.unidMedTranspOutros})`;

									return data?.unidadeMedidaTransportada?.label;
									
								}}
							/>
							<AgGridColumn
								headerName='Material'
								field="materialDescricao"
								headerClass="myfunc-default-header"
								valueFormatter={({ data }: any) => {

									if (data?.material?.label == 'Outros')
										return `Outros (${data.materialOutros})`;

									return data?.material?.label;
									
								}}
							/>
							<AgGridColumn
								headerName="Ações"
								cellRenderer="actions"
								maxWidth={100}
							/>
						</AgGridReact>
					</div>
				</If>
				<br />
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Cultura/Organismo<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;
						<If condition={loadingGetData}>
							<LoadingInForm />
						</If>
						<Controller
							control={control}
							name="culturaOrganismo"
							isInvalid={errors.culturaOrganismo}
							as={<Select
								options={cultures.map(culture => Object.assign({ label: culture.nome, value: culture.id }))}
								placeholder="Selecione uma cultura/organismo"
								isClearable
							/>}
						/>
						<If condition={errors.culturaOrganismo}>
							<p className="error-field">{errors.culturaOrganismo?.message}</p>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Observações
						</Form.Label>
						<Form.Control
							ref={register}
							name="observacoes"
							as="textarea"
							rows={5}
							placeholder="Insira as observações necessárias"
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Transcrever o trecho da LPMA em que constam os locais contemplados para movimentação de material<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="trechoLPMA"
							as="textarea"
							rows={4}
							placeholder="Insira o trecho LPMA em que constam os locais contemplados para movimentação de material"
							isInvalid={errors.trechoLPMA !== undefined}
						/>
						<If condition={errors.trechoLPMA}>
							<Form.Control.Feedback type="invalid">
								{errors.trechoLPMA?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={12}>
						<Form.Label>
							O escopo da LPMA, CQB ou Permissão de Importação contempla a referida atividade com material/cultura objeto da solicitação?<em>*</em>
						</Form.Label>
						<Form.Text className="warning-pdfs">{STRINGS_CIBIO.AMI_ESCOPO_LPMA_HELP_TEXT}</Form.Text>
					</Form.Group>
					<br />
					&nbsp;&nbsp;
					<Form.Check
						ref={register}
						name="escopoLPMA"
						type="radio"
						label="Não"
						value={1}
					/>
					&nbsp;&nbsp;
					<Form.Check
						ref={register}
						name="escopoLPMA"
						type="radio"
						label="Sim"
						value={2}
					/>
					&nbsp;&nbsp;
					<Form.Check
						ref={register}
						name="escopoLPMA"
						type="radio"
						label="Não se aplica"
						value={3}
					/>
					<br />
					<If condition={errors.escopoLPMA}>
						<p className="error-field">{errors.escopoLPMA?.message}</p>
					</If>
				</Form.Row>
			</Fieldset>

			<Fieldset disabled={true} title={STRINGS_CIBIO.AMI_BLOCK_TWO}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Nº Autorização CIBio
						</Form.Label>
						<Form.Control
							placeholder="Nº autorização CIBio"
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Data Emissão CIBio
						</Form.Label>
						<Form.Control
							placeholder="Data Emissão CIBio"
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							Data da Aprovação
						</Form.Label>
						<Form.Control
							placeholder="Data da aprovação"
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Observações da Análise do Regulatório
						</Form.Label>
						<Form.Control
							as="textarea"
							rows={5}
							placeholder="Observações da análise do regulatório"
						/>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title={STRINGS_CIBIO.AMI_BLOCK_THREE}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							<b>Não esqueça de inserir informações do envio, principalmente as informações de embalagem dupla impermeável e de identificação de risco biológico!</b>
						</Form.Label>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Informações do Envio<em>*</em>{`  `}
							<AddButton
								type="button"
								onClick={() => setModalTransports(true)}
							/>
						</Form.Label>
						<If condition={transportMsg}>
							<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
						</If>
					</Form.Group>
				</Form.Row>
				<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
					<AgGridReact
						rowData={selectedTransports}
						defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
						gridOptions={{ headerHeight: 80 }}
						stopEditingWhenGridLosesFocus={true}
						singleClickEdit={true}
						frameworkComponents={{
							actions: actionsEnvio,
						}}
					>
						<AgGridColumn
							headerName='Responsável pelo Transporte'
							valueGetter={({ data }: any) => data.responsavelTransporte}
							cellStyle={{
								justifyContent: 'left'
							}}
							autoHeight
							wrapText
							minWidth={250}
						/>
						<AgGridColumn
							headerName='Data do Envio'
							field="dataEnvio"
							headerClass="myfunc-default-header"
							valueFormatter={({ data }: any) => data.dataEnvio ? _generateDate(data.dataEnvio, 'Pt') : ''}

						/>
						<AgGridColumn
							headerName='Local de Recebimento'
							valueGetter={({ data }: any) => data.localDestino.label}
							cellStyle={{
								justifyContent: 'left'
							}}

						/>
						<AgGridColumn
							headerName='Quantidade Transportada'
							field="qtdTransportada"
							headerClass="myfunc-default-header"
							valueFormatter={({ data }: any) => { return DecimalAsComma(data.qtdTransportada); }}
						/>
						<AgGridColumn
							headerName='Unidade Medida Transportada'
							field="unidadeMedidaTransportadaDescricao"
							headerClass="myfunc-default-header"
							valueFormatter={({ data }: any) => {

								if (data?.unidadeMedidaTransportada?.label == 'Outros')
									return `Outros (${data.unidMedTranspOutros})`;

								return data?.unidadeMedidaTransportada?.label;

							}}
						/>
						<AgGridColumn
							headerName="Ações"
							cellRenderer="actions"
							maxWidth={100}
						/>
					</AgGridReact>
				</div>
			</Fieldset>

			<Fieldset title={STRINGS_CIBIO.AMI_BLOCK_FOUR}>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Informações do Recebimento<em>*</em>{`  `}
						</Form.Label>
						<If condition={transportMsg}>
							<p className="error-field">{STRINGS.FORM_FIELD_REQUIRED}</p>
						</If>
					</Form.Group>
				</Form.Row>
				<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
					<AgGridReact
						rowData={selectedTransports}
						defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
						gridOptions={{ headerHeight: 80 }}
						stopEditingWhenGridLosesFocus={true}
						singleClickEdit={true}
						frameworkComponents={{
							actions: actionsTransports,
						}}
					>
						<AgGridColumn
							headerName='Responsável pelo Transporte'
							valueGetter={({ data }: any) => data.responsavelTransporte}
							cellStyle={{
								justifyContent: 'left'
							}}
							autoHeight
							wrapText
							minWidth={250}
						/>
						<AgGridColumn
							headerName='Data do Envio'
							field="dataEnvio"
							headerClass="myfunc-default-header"
							valueFormatter={({ data }: any) => data.dataEnvio ? _generateDate(data.dataEnvio, 'Pt') : ''}

						/>
						<AgGridColumn
							headerName='Local de Envio'
							valueGetter={({ data }: any) => data.localPartida.label}
							cellStyle={{
								justifyContent: 'left'
							}}

						/>
						<AgGridColumn
							headerName='Responsável pelo Recebimento'
							valueGetter={({ data }: any) => data.responsavelRecebimento}
							cellStyle={{
								justifyContent: 'left'
							}}
							autoHeight
							wrapText
							minWidth={250}
						/>	
						<AgGridColumn
							headerName='Data do Recebimento'
							field="dataRecebimento"
							headerClass="myfunc-default-header"
							valueFormatter={({ data }: any) => data.dataRecebimento ? _generateDate(data.dataRecebimento, 'Pt') : ''}

						/>
						<AgGridColumn
							headerName='Quantidade Recebida'
							field="qtdRecebida"
							headerClass="myfunc-default-header"
						/>
						<AgGridColumn
							headerName="Ações"
							cellRenderer="actions"
							maxWidth={100}
						/>
					</AgGridReact>
				</div>
			</Fieldset>

			<div className="button-position">
				<Button
					title="Voltar"
					contained={false}
					type="button"
					onClick={() => {
						isDirty ? setIsConfirmation(true) :
							history.goBack();
					}}
				/>
				<GenericButton
					title="Salvar e enviar para regulatório"
					variant="btn-warning"
					type="button"
					loading={loadingSubmit}
					onClick={() => trigger().then(validated => {
						if (validated) {
							setIsRegulatory(validated);
						} else {
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}
					})}
				/>
				<Button
					title="Salvar rascunho"
					contained={true}
					type="button"
					onClick={() => onSubmitDraft()}
					isLoading={loadingDraft}
				/>
			</div>

			<If condition={isRegulatory}>
				<ConfirmationModal
					show={isRegulatory}
					onHide={() => {
						setIsRegulatory(false);
					}}
					title={STRINGS.REGULATORY_TITLE}
					confirmationMsg={STRINGS.REGULATORY_ACTION}
					onConfirm={() => {
						onSubmit();
						setIsRegulatory(false);
					}}
					loading={loadingSubmit}
					variant="success"
					color="white"
				>
					<p>{STRINGS.REGULATORY_MESSAGE}</p>
				</ConfirmationModal>
			</If>
		</Form>

		<If condition={modalEvents}>
			<Modal
				show={true}
				onHide={() => setModalEvents(false)}
				title={STRINGS_CIBIO.AMI_EVENT_MODAL_TITLE}
			>
				<If condition={verifyErrorsOnFormEvent()}>
					{verifyErrorsOnFormEvent().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
				</If>
				<If condition={!successMaterial}>
					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
				</If>
				<Form onSubmit={handleSubmitEvent(onSubmitEvent)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Evento<em>*</em>
							</Form.Label>
							<Controller
								control={controlEvent}
								name="evento"
								isInvalid={errorsEvent.evento !== undefined}
								as={
									<Select
										placeholder="Selecione um evento"
										options={events.map(event => Object.assign({ value: event.id, label: event.nome }))}
										isClearable
									/>
								}
							/>
							<If condition={errorsEvent.evento}>
								<p className="error-field">{errorsEvent.evento?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade Autorizada<em>*</em>
							</Form.Label>
							<Form.Control
								ref={registerEvent}
								name="quantidadeAutorizada"
								placeholder="Insira a quantidade autorizada"
								maxLength={5}
								type="number"
								step="any"
								isInvalid={errorsEvent.quantidadeAutorizada !== undefined}
							/>
							<Form.Control.Feedback type="invalid">
								{errorsEvent.quantidadeAutorizada?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Unidade de Medida Autorizada<em>*</em>
							</Form.Label>
							<Controller
								control={controlEvent}
								name="unidadeMedidaAutorizada"
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
								/>}
							/>
							<If condition={errorsEvent.unidadeMedidaAutorizada}>
								<span className="error-field">{errorsEvent.unidadeMedidaAutorizada?.message}</span>
							</If>
						</Form.Group>
					</Form.Row>
					<If condition={!isEmptyObj(watchEvent())}>
						<If condition={watchEvent().unidadeMedidaAutorizada?.value === 4}>
							<Form.Row className="row-custom">
								<Form.Group as={Col} />
								<Form.Group as={Col}>
									<Form.Label>
										Especifique a unidade de medida autorizada<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEvent()}
										required
										name="unidMedAutorizadaOutros"
										placeholder="Insira a unidade de medida autorizada"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade Transportada
							</Form.Label>
							<Form.Control
								ref={registerEvent}
								name="quantidadeTransportada"
								placeholder="Insira a quantidade transportada"
								maxLength={5}
								type="number"
								step="any"
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Unidade de Medida Transportada
							</Form.Label>
							<Controller
								control={controlEvent}
								name="unidadeMedidaTransportada"
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<If condition={!isEmptyObj(watchEvent())}>
						<If condition={watchEvent().unidadeMedidaTransportada?.value === 4}>
							<Form.Row className="row-custom">
								<Form.Group as={Col} />
								<Form.Group as={Col}>
									<Form.Label>
										Especifique a unidade de medida transportada<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEvent}
										required
										name="unidMedTranspOutros"
										placeholder="Insira a unidade de medida transportada"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Material<em>*</em>
							</Form.Label>
							<Controller
								control={controlEvent}
								name="material"
								isInvalid={errorsEvent.material !== undefined}
								as={<Select
									placeholder="Selecione um material"
									options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
									isClearable
								/>}
							/>
							<If condition={errorsEvent.material}>
								<p className="error-field">{errorsEvent.material?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<If condition={!isEmptyObj(watchEvent())}>
						<If condition={watchEvent().material?.value === 7}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Especifique o material<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEvent}
										required
										name="materialOutros"
										placeholder="Insira o material"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
					</If>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalEvents(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalEditEvents}>
			<Modal
				show={true}
				onHide={() => setModalEditEvents(false)}
				title={STRINGS_CIBIO.AMI_EVENT_EDIT_MODAL_TITLE}
			>
				<If condition={verifyErrorsOnFormEditEvent()}>
					{verifyErrorsOnFormEditEvent().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
				</If>
				<If condition={!successMaterial}>
					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
				</If>
				<Form autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Evento<em>*</em>
							</Form.Label>
							<Controller
								control={controlEditEvent}
								name="editEvento"
								isInvalid={errorsEditEvent.editEvento !== undefined}
								defaultValue={eventToEdit.evento}
								as={
									<Select
										placeholder="Selecione um evento"
										options={events.map(event => Object.assign({ value: event.id, label: event.nome }))}
										isClearable
									/>
								}
							/>
							<If condition={errorsEditEvent.editEvento}>
								<p className="error-field">{errorsEditEvent.editEvento?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade Autorizada<em>*</em>
							</Form.Label>
							<Form.Control
								ref={registerEditEvent}
								name="editQuantidadeAutorizada"
								placeholder="Insira a quantidade autorizada"
								maxLength={5}
								type="number"
								step="any"
								isInvalid={errorsEditEvent.editQuantidadeAutorizada !== undefined}
								defaultValue={eventToEdit.quantidadeAutorizada}
							/>
							<Form.Control.Feedback type="invalid">
								{errorsEditEvent.editQuantidadeAutorizada?.message}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Unidade Medida Autorizada<em>*</em>
							</Form.Label>
							<Controller
								control={controlEditEvent}
								name="editUnidadeMedidaAutorizada"
								defaultValue={eventToEdit.unidadeMedidaAutorizada}
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
									onInputChange={() =>
										setEventToEdit(state => ({
											id: state.id,
											evento: state.evento,
											material: state.material,
											quantidadeAutorizada: state.quantidadeAutorizada,
											quantidadeTransportada: state.quantidadeTransportada,
											unidadeMedidaAutorizada: state.unidadeMedidaAutorizada,
											unidadeMedidaTransportada: state.unidadeMedidaTransportada,
											materialOutros: state.materialOutros,
											unidMedAutorizadaOutros: '',
											unidMedTranspOutros: state.unidMedTranspOutros,
										}))
									}
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<If condition={!isEmptyObj(watchEditEvent()) || eventToEdit.unidadeMedidaAutorizada}>
						<If condition={watchEditEvent().editUnidadeMedidaAutorizada?.value === 4 || eventToEdit.unidMedAutorizadaOutros}>
							<Form.Row className="row-custom">
								<Form.Group as={Col} />
								<Form.Group as={Col}>
									<Form.Label>
										Especifique a unidade de medida autorizada<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditEvent}
										defaultValue={eventToEdit.unidMedAutorizadaOutros}
										required
										name="unidMedAutorizadaOutros"
										placeholder="Insira a unidade de medida autorizada"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade Transportada
							</Form.Label>
							<Form.Control
								ref={registerEditEvent}
								name="editQuantidadeTransportada"
								placeholder="Insira a quantidade transportada"
								maxLength={5}
								type="number"
								step="any"
								defaultValue={eventToEdit.quantidadeTransportada}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Unidade Medida Transportada
							</Form.Label>
							<Controller
								control={controlEditEvent}
								name="editUnidadeMedidaTransportada"
								defaultValue={eventToEdit.unidadeMedidaTransportada}
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
									onInputChange={() =>
										setEventToEdit(state => ({
											id: state.id,
											evento: state.evento,
											material: state.material,
											quantidadeAutorizada: state.quantidadeAutorizada,
											quantidadeTransportada: state.quantidadeTransportada,
											unidadeMedidaAutorizada: state.unidadeMedidaAutorizada,
											unidadeMedidaTransportada: state.unidadeMedidaTransportada,
											materialOutros: state.materialOutros,
											unidMedAutorizadaOutros: state.unidMedAutorizadaOutros,
											unidMedTranspOutros: '',
										}))
									}
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<If condition={!isEmptyObj(watchEditEvent()) || eventToEdit.unidadeMedidaTransportada}>
						<If condition={watchEditEvent().editUnidadeMedidaTransportada?.value === 4 || eventToEdit.unidMedTranspOutros}>
							<Form.Row className="row-custom">
								<Form.Group as={Col} />
								<Form.Group as={Col}>
									<Form.Label>
										Especifique a unidade de medida transportada<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditEvent}
										defaultValue={eventToEdit.unidMedTranspOutros}
										required
										name="unidMedTranspOutros"
										placeholder="Insira a unidade de medida transportada"
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Material<em>*</em>
							</Form.Label>
							<Controller
								control={controlEditEvent}
								name="editMaterial"
								isInvalid={errorsEditEvent.editMaterial !== undefined}
								defaultValue={eventToEdit.material}
								as={<Select
									placeholder="Selecione um material"
									options={enumMaterial.map(material => Object.assign({ value: material.key, label: material.value }))}
									isClearable
									onInputChange={() =>
										setEventToEdit(state => ({
											id: state.id,
											evento: state.evento,
											material: state.material,
											quantidadeAutorizada: state.quantidadeAutorizada,
											quantidadeTransportada: state.quantidadeTransportada,
											unidadeMedidaAutorizada: state.unidadeMedidaAutorizada,
											unidadeMedidaTransportada: state.unidadeMedidaTransportada,
											materialOutros: '',
											unidMedAutorizadaOutros: state.unidMedAutorizadaOutros,
											unidMedTranspOutros: state.unidMedTranspOutros,
										}))
									}
								/>}
							/>
							<If condition={errorsEditEvent.editMaterial}>
								<p className="error-field">{errorsEditEvent.editMaterial?.message}</p>
							</If>
						</Form.Group>
					</Form.Row>
					<If condition={!isEmptyObj(watchEditEvent()) || eventToEdit.materialOutros}>
						<If condition={watchEditEvent().editMaterial?.value === 7 || eventToEdit.materialOutros}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Especifique o material<em>*</em>
									</Form.Label>
									<Form.Control
										ref={registerEditEvent}
										required
										name="materialOutros"
										placeholder="Insira o material"
										defaultValue={eventToEdit.materialOutros}
									/>
									<span className="error-field">
										{STRINGS_CIBIO.AMI_FIELD_TO_SPECIFY}
									</span>
								</Form.Group>
							</Form.Row>
						</If>
					</If>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalEditEvents(false)}
						/>
						<Button
							title="Salvar"
							contained={true}
							type="button"
							onClick={() => {
								triggerEditEvent().then(validated => {
									if (validated) {
										handleSubmitEditEvent(onSubmitEditiEvent)();
									}
								})
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalTransports}>
			<Modal
				show={true}
				onHide={() => setModalTransports(false)}
				title={STRINGS_CIBIO.AMI_TRANSPORT_MODAL_TITLE}
			>
				<If condition={verifyErrorsOnFormTransport()}>
					{verifyErrorsOnFormTransport().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
				</If>
				<If condition={!successMaterial}>
					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
				</If>
				<Form onSubmit={handleSubmitTransport(onSubmitTransport)} autoComplete="off">
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							Embalagem dupla sendo uma impermeável?
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerTransport}
							name="embalagemDuplaImpermeavel"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerTransport}
							name="embalagemDuplaImpermeavel"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerTransport}
							name="embalagemDuplaImpermeavel"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
						/>
					</Form.Row>
					<br />
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							Identificação com o símbolo de risco biológico?
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerTransport}
							name="identificacaoSimboloRiscoBiologico"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerTransport}
							name="identificacaoSimboloRiscoBiologico"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerTransport}
							name="identificacaoSimboloRiscoBiologico"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
						/>
					</Form.Row>
					<br />
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Responsável pelo transporte
							</Form.Label>
							<Form.Control
								ref={registerTransport}
								name="responsavelTransporte"
								placeholder="Insira o responsável pelo transporte"
								maxLength={150}
								isInvalid={errorsTransport.responsavelTransporte !== undefined}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data do Envio
							</Form.Label>
							<br />
							<Controller
								control={controlTransport}
								name="dataEnvio"
								isInvalid={errorsTransport.dataEnvio !== undefined}
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										minDate={moment().toDate()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Local de Envio
							</Form.Label>
							<Controller
								control={controlTransport}
								defaultValue={getValues('localDeEnvio')}
								name="localPartida"
								as={<Select
									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
									placeholder="Selecione um local"
									isClearable
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Local de Recebimento
							</Form.Label>
							<Controller
								control={controlTransport}
								defaultValue={getValues('localDeDestino')}
								name="localDestino"								
								as={<Select
									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
									placeholder="Selecione um local"
									isClearable
								/>}
							/>

						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade Transportada
							</Form.Label>
							<Form.Control
								ref={registerTransport}
								name="qtdTransportada"
								placeholder="Insira a quantidade transportada"
								maxLength={5}
								type="number"
								step="any"
								isInvalid={errorsTransport.qtdTransportada !== undefined}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Unidade de medida
							</Form.Label>
							<Controller
								control={controlTransport}
								name="unidadeMedidaTransportada"
								as={<Select
									placeholder="Selecione uma unidade de medida"
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalTransports(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalEditEnvio}>
			<Modal
				show={true}
				onHide={() => setModalEditEnvio(false)}
				title={STRINGS_CIBIO.AMI_TRANSPORT_EDIT_MODAL_TITLE}
			>
				<If condition={verifyErrorsOnFormEditEnvio()}>
					{verifyErrorsOnFormEditEnvio().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
				</If>
				<If condition={!successMaterial}>
					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
				</If>
				<Form autoComplete="off">
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							Embalagem dupla sendo uma impermeável?
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerEditEnvio}
							name="editembalagemDuplaImpermeavel"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
							defaultChecked={transportToEdit.embalagemDuplaImpermeavel === 2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerEditEnvio}
							name="editembalagemDuplaImpermeavel"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
							defaultChecked={transportToEdit.embalagemDuplaImpermeavel === 1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerEditEnvio}
							name="editembalagemDuplaImpermeavel"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
							defaultChecked={transportToEdit.embalagemDuplaImpermeavel === 3}
						/>
					</Form.Row>
					<br />
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							Identificação com o símbolo de risco biológico?
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerEditEnvio}
							name="editidentificacaoSimboloRiscoBiologico"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
							defaultChecked={transportToEdit.identificacaoSimboloRiscoBiologico === 2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerEditEnvio}
							name="editidentificacaoSimboloRiscoBiologico"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
							defaultChecked={transportToEdit.identificacaoSimboloRiscoBiologico === 1}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={registerEditEnvio}
							name="editidentificacaoSimboloRiscoBiologico"
							type="radio"
							aria-label="radio 1"
							label="Não se aplica"
							value={3}
							defaultChecked={transportToEdit.identificacaoSimboloRiscoBiologico === 3}
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Responsável pelo transporte
							</Form.Label>
							<Form.Control
								ref={registerEditEnvio}
								name="editresponsavelTransporte"
								maxLength={150}
								defaultValue={transportToEdit.responsavelTransporte}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data do Envio
							</Form.Label>
							<br />
							<Controller
								control={controlEditEnvio}
								name="editdataEnvio"
								defaultValue={transportToEdit.dataEnvio}
								isDisabled={true}
								readOnly
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										minDate={moment().toDate()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Local de Envio
							</Form.Label>
							<Controller
								control={controlEditEnvio}
								name="editlocalPartida"
								defaultValue={transportToEdit.localPartida}
								as={
									<Select
										options={
											locations.map(local => Object.assign({
												value: local.id, label: local.nome
											}))
										}
									/>
								}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Local de Recebimento
							</Form.Label>
							<Controller
								control={controlEditEnvio}
								name="editlocalDestino"
								defaultValue={transportToEdit.localDestino}
								as={
									<Select
										options={
											locations.map(local => Object.assign({
												value: local.id, label: local.nome
											}))
										}
									/>
								}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade Transportada
							</Form.Label>
							<Form.Control
								ref={registerEditEnvio}
								name="editqtdTransportada"
								maxLength={150}
								defaultValue={transportToEdit.qtdTransportada}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Unidade de medida
							</Form.Label>
							<Controller
								control={controlEditEnvio}
								name="editunidadeMedidaTransportada"
								as={<Select
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
								/>}
								defaultValue={transportToEdit.unidadeMedidaTransportada}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-invisible" >
						<Form.Control
							ref={registerEditEnvio}
							name="editContagem"
							readOnly
							defaultValue={transportToEdit.contagem}
						/>
					</Form.Row>

					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalEditEnvio(false)}
						/>
						<Button
							title="Salvar"
							contained={true}
							type="button"
							onClick={() => {
								triggerEditEnvio().then(validated => {
									if (validated) {
										handleSubmitEditEnvio(onSubmitEditEnvio)();
									}
								})
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalEditRecebimento}>
			<Modal
				show={true}
				onHide={() => setModalEditRecebimento(false)}
				title={STRINGS_CIBIO.AMI_TRANSPORT_EDIT_MODAL_TITLE}
			>
				<If condition={verifyErrorsOnFormEditTransport()}>
					{verifyErrorsOnFormEditTransport().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
				</If>
				<If condition={!successMaterial}>
					<AlertError message="O material só permite a Unidade de Medida em Gramas ou Kg!" />
				</If>
				<Form autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Local de Envio
							</Form.Label>
							<Controller
								control={controlEditTransport}
								name="editlocalPartida"
								defaultValue={transportToEdit.localPartida}
								as={<Select
									options={locations.map(local => Object.assign({ value: local.id, label: local.nome }))}
									isOptionDisabled={(option) => option.disabled}
								/>}
							/>

						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Local de Recebimento
							</Form.Label>
							<Controller
								control={controlEditTransport}
								name="editlocalDestino"
								defaultValue={transportToEdit.localDestino}								
								as={
									<Select
										options={
											locations.map(local => Object.assign({
																				value: local.id, label: local.nome
																				}))
												}
										isOptionDisabled={(option) => option.disabled}
									/>
								}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Quantidade Transportada
							</Form.Label>
							<Form.Control
								ref={registerEditTransport}
								name="editqtdTransportada"
								readOnly
								maxLength={150}
								defaultValue={transportToEdit.qtdTransportada}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Unidade de medida
							</Form.Label>
							<Controller
								control={controlEditTransport}
								name="editunidadeMedidaTransportada"
								isDisabled={ true }
								as={<Select
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
								/>}
								readOnly
								defaultValue={transportToEdit.unidadeMedidaTransportada}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Responsável pelo transporte
							</Form.Label>
							<Form.Control
								ref={registerEditTransport}
								name="editresponsavelTransporte"
								readOnly
								maxLength={150}
								defaultValue={transportToEdit.responsavelTransporte}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data do Envio
							</Form.Label>
							<br />
							<Controller
								control={controlEditTransport}
								name="editdataEnvio"
								defaultValue={transportToEdit.dataEnvio}
								isDisabled={ true }
								readOnly
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										minDate={moment().toDate()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-invisible" >
						<Form.Control
							ref={registerEditTransport}
							name="editContagem"
							readOnly
							defaultValue={transportToEdit.contagem}
						/>
						<Form.Control
							ref={registerEditTransport}
							name="editembalagemDuplaImpermeavel"
							readOnly
							defaultValue={transportToEdit.embalagemDuplaImpermeavel}
						/>
						<Form.Control
							ref={registerEditTransport}
							name="editidentificacaoSimboloRiscoBiologico"
							readOnly
							defaultValue={transportToEdit.identificacaoSimboloRiscoBiologico}
						/>
					</Form.Row>
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							O material chegou ao destino em perfeitas condições?
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="editmaterialChegouDestinoPerfeitasCondicoes"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="editmaterialChegouDestinoPerfeitasCondicoes"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
					</Form.Row>
					<br />
					<Form.Row className="row-custom">
						&nbsp;&nbsp;
						<Form.Label>
							A quantidade recebida está de acordo com a quantidade enviada e/ou transportada?
						</Form.Label>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="editqtdRecebidaDeAcordoComQtdEnviada"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							value={2}
						/>
						&nbsp;&nbsp;
						<Form.Check
							ref={register}
							name="editqtdRecebidaDeAcordoComQtdEnviada"
							type="radio"
							aria-label="radio 1"
							label="Não"
							value={1}
						/>
					</Form.Row>
					<br />
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Responsável pelo recebimento
							</Form.Label>
							<Form.Control
								ref={registerEditTransport}
								name="editresponsavelRecebimento"
								placeholder="Insira o responsável pelo recebimento"
								maxLength={150}
								defaultValue={transportToEdit.responsavelRecebimento}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data do Recebimento
							</Form.Label>
							<br />
							<Controller
								control={controlEditTransport}
								name="editdataRecebimento"
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										minDate={moment().toDate()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Quantidade Recebida
							</Form.Label>
							<Form.Control
								ref={registerEditTransport}
								name="editqtdRecebida"
								placeholder="Insira a quantidade recebida"
								maxLength={5}
								type="number"
								step="any"
								isInvalid={errorsTransport.editqtdRecebida !== undefined}
								defaultValue={transportToEdit.qtdRecebida}

							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Unidade de medida
							</Form.Label>
							<Controller
								control={controlEditTransport}
								isDisabled={ true}
								name="editunidadeMedidaRecebida"
								as={<Select
									options={enumUnidadeMedida.map(unidade => Object.assign({ value: unidade.key, label: unidade.value }))}
									isClearable
								/>}
								readOnly
								defaultValue={transportToEdit.unidadeMedidaTransportada}
							/>
						</Form.Group>
					</Form.Row>

					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalEditRecebimento(false)}
						/>
						<Button
							title="Salvar"
							contained={true}
							type="button"
							onClick={() => {
								triggerEditTransport().then(validated => {
									if (validated) {
										handleSubmitEditTransport(onSubmitEditTransport)();
									}
								})
							}}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalAAC}>
			<Modal
				show={true}
				onHide={() => setModalAAC(false)}
				title={STRINGS_CIBIO.AMI_AAC_MODAL_TITLE}
			>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Selecionar Atividade de Contenção
						</Form.Label>
					</Form.Group>
				</Form.Row>
				<Form onSubmit={handleSubmitBuscaAAC(searchAAC)} autoComplete="off">
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>Solicitante</Form.Label>
							<Controller
								control={control}
								name="tecnicoPrincipalAAC"
								render={({ onChange, value }) => (
									<SimpleInput
										defaultValue={value}
										className="form-control disabled"
										onChange={onChange}
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Nº Autorização CIBio</Form.Label>
							<Controller
								control={control}
								name="numeroAutorizacaoCibioAAC"
								render={({ onChange, value }) => (
									<SimpleInput
										defaultValue={value}
										clearButton
										className="form-control"
										onChange={onChange}
									/>
								)}
							/>
						</Form.Group>
						<span className="filter-btn">
							<Button
								title="Buscar"
								type="submit"
								contained
								isLoading={loadingDraft}
							/>
						</span>
					</Form.Row>
					<If condition={listaBuscaAAC != null}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={listaBuscaAAC}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsBuscaAAC,
								}}
							>
								<AgGridColumn
									headerName='Solicitante'
									field="solicitanteTecnicoPrincipal"
									headerClass="myfunc-default-header"
									autoHeight
									wrapText
								/>
								<AgGridColumn
									headerName='Número de Autorização Cibio'
									field="numeroAutorizacaoCibio"
									headerClass="myfunc-default-header"
									autoHeight
									wrapText
								/>
								<AgGridColumn
									headerName="Selecionar"
									cellRenderer="actions"
								/>
							</AgGridReact>
						</div>
					</If>

					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalAAC(false)}
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={isConfirmation}>
			<ConfirmationModal
				show={isConfirmation}
				confirmationMsg={STRINGS_CIBIO.CONFIRM_LEFT_EDITION}
				cancelMsg={STRINGS_CIBIO.CANCEL_LEFT_EDITION}
				onConfirm={() => history.goBack()}
				onHide={() => setIsConfirmation(false)}
				title={STRINGS_CIBIO.EDITION_TITLE_CONFIRMATION}
				type="button"
				variant="danger"
			>
				{STRINGS_CIBIO.EDITION_REGISTER_CONFIRMATION_CANCEL}
			</ConfirmationModal>
		</If>

	</div>;
}