import React, { useState } from 'react';
import './styles.css';
import { Container, Row, Col } from 'react-bootstrap';
import Loading from '../../components/Loading';
import If from '../../components/If';
import basfImg from '../../assets/images/basf-img-4.jpeg';

function Home() {
	const [loading, setLoading] = useState<boolean>(false);

	const load = () => {
		setLoading(true);
	}

	return (
		<div className="background-custom">
			<If condition={!loading}>
				<Container className="container-load">
					<Loading />
				</Container>
			</If>
			<Container className="container-home">
				<Row>
					<Col>
						<p>Bem-vindo&#40;a&#41; ao Hermes</p>
					</Col>
					<img src={basfImg} alt="basf" onLoad={load} />
				</Row>
			</Container>
		</div>
	);
}

export default Home;
