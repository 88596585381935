import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../../components/Button';
import If from '../../../../components/If';
import { STRINGS } from '../../../../util/strings';

import { estados } from '../../../../util/states.json';
import { countries } from '../../../../util/countries';
import { InstitutionDataForm, InstitutionResults } from '../../../../models/models';

import api from '../../../../services/api';
import '../../styles_view.css';

interface stateType {
	id: number;
}

export default function InstitutionData ()
{
	const history = useHistory();
	const location = useLocation<stateType>();
	const [loading, setLoading] = useState<boolean>(true);
	const [country, setCountry] = useState<string>('');

	const institutionDataSchema = yup.object().shape({
		name: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		cnpj: country == "Brasil" ? yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED) : yup.string(),
		address: yup
			.string(),
		zipCode: yup
			.string(),
		city: yup
			.string(),
		state: yup
			.string(),
		country: yup
			.string(),
		phone: yup
			.string(),
		email: yup
			.string()
			.email(STRINGS.FORM_EMAIL_INVALID)
			.required(STRINGS.FORM_FIELD_REQUIRED),
		active: yup
			.bool()
	})

	const { register, reset, control } = useForm({
		mode: 'onTouched',
		resolver: yupResolver(institutionDataSchema)
	});

	useEffect(() => {
		const getInstitutionById = async () => {
			try {
				const { data } = await api.get<InstitutionResults>(`api/v1/Instituicao/${location.state.id}`);

				reset({
					name: data.nome,
					cnpj: data.cnpj.replace(".", "").replace("/", "").replace("-", ""),
					address: data.endereco,
					zipCode: data.cep,
					city: data.municipio,
					state: data.uf,
					country: data.pais,
					phone: data.telefone,
					email: data.email,
					active: data.ativo
				});
				setCountry(data.pais);
			} catch (error:any) {
			}
			setLoading(false);
		}

		getInstitutionById();
	}, [location.state.id, reset]);

	return <div className="container container-fluid">
		<h3>Visualização de instituição responsável pelo ensaio</h3>
		<If condition={!loading}>
			<Form>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Nome da instituição<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							name="name"
							placeholder="Insira o nome da instituição"
							disabled
							autoComplete="off"
							maxLength={100}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							CNPJ da instituição<em>*</em>
						</Form.Label>
						<Controller
							control={control}
							name="cnpj"                                
							render={(props: any) => {
								return (
									<NumberFormat
										disabled
										value={props.value}
										onBlur={props.onBlur}
										format="##.###.###/####-##"
										className="form-control"
									/>
								)
							}}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Email da instituição<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							name="email"
							disabled
							placeholder="Insira o email da instituição"
							autoComplete="off"
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Telefone</Form.Label>
						<Form.Control
							ref={register}
							as={NumberFormat}
							getInputRef={register}
							type="text"
							placeholder="Insira o telefone da instituição"
							name="phone"
							format="(##) #####-####"
							autoComplete="off"
							disabled
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>
							CEP
						</Form.Label>
						<Form.Control
							ref={register}
							as={NumberFormat}
							getInputRef={register}
							type="text"
							name="zipCode"
							placeholder="Insira o CEP da instituição"
							disabled
							format="#####-###"
							autoComplete="off"
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>Endereço</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							placeholder="Insira o endereço da instituição"
							name="address"
							autoComplete="off"
							maxLength={255}
							disabled
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Município</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							placeholder="Insira o município da instituição"
							name="city"
							autoComplete="off"
							maxLength={100}
							disabled
						/>
					</Form.Group>
					<Form.Group as={Col} md={1}>
						<Form.Label>UF</Form.Label>
						<Form.Control
							as="select"
							name="state"
							ref={register}
							disabled
						>
							<Form.Control
								as="option"
								label=''
							/>
							{estados.map((state) => (
								<Form.Control
									key={state.id}
									as="option"
									label={state.nome}
									value={state.nome}
								/>
							))}
						</Form.Control>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>País</Form.Label>
						<Form.Control
							as="select"
							name="country"
							ref={register}
							disabled
						>
							<Form.Control
								as="option"
								label=''
							/>
							{countries.map((country) => (
								<Form.Control
									key={country}
									as="option"
									label={country}
									value={country}
								/>
							))}
						</Form.Control>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} md={1} className="checkbox-lg">
						<Form.Check
							type="checkbox"
							ref={register}
							name="active"
							label="Ativo"
							disabled
						/>
					</Form.Group>
				</Form.Row>                    
				<div className="button-position">
					<Button
						title="Voltar"
						type="button"
						contained={false}
						onClick={() => history.goBack()}
					/>
				</div>
			</Form>
		</If>
	</div>;
}