
import DropDown from  "../../../components/form/DropDown";
import EditView, {EditField} from  "../../../components/page/EditView";
import Grid 	from  "../../../components/form/Grid";

import { useForm } from "react-hook-form";
import CqbService from "../services";
import { STRINGS_CQB } from "../../../util/cqb/string";
import { CqbEquipeTecnica, CqbTecnicos } from "../../../models/modelsCQB";
import { useState } from "react";

import { listEnumItem, ParamsType, StateType } from "../../../models/modelCommum";
import TextField from "../../../components/form/TextField";
import { uuid } from "../../../services/uuid";
import Button from "../../../components/Button";

interface ICIBio{
	ativo		: boolean,
	equipeCIBIO	: any,
	presidente	: any,
}
interface tempObject {
	[key: string]: any
 }
export default function EditCIBIO (){
	const CqbServiceApi = new CqbService();

	const [itemsEquipeTecnica, setItemsEquipeTecnica] = useState<CqbEquipeTecnica[]>();
	const [equipeCIBIO, setEquipeCIBIO] = useState<CqbEquipeTecnica[]>();
	const [presidente, setPresidente] = useState<any>();
	const [titulacao, setTitulacao] = useState<listEnumItem[]>([]);
	
	const defaultForm = useForm({
		defaultValues: {
			ativo		: true,
			equipeCIBIO	: null,
			presidente	: null,
		} as ICIBio,
		mode: "onSubmit",
	});

	return <EditView<ICIBio> 		
		title="Composição atual da CIBio da BASF S.A."
		// getLoadById={async (id: any)=>{ 
		// 	let dados = await CqbServiceApi.getEquipeTecnicaById(id);
		// 	let t = Titulacao.find(e=> e.id === dados.titulacao);
		// 	dados.titulacao = t ? Object.assign({ value: t.id, label: t.nome }) : null;
		// 	return dados;
		//  }}
		OnInit={async ()=>{		

			const equipeTecnica = await CqbServiceApi.getEquipeTecnica();
			setItemsEquipeTecnica(equipeTecnica)
			let defaultData = await CqbServiceApi.getDefaultData();
			if(defaultData != null){
				setTitulacao(defaultData.titulacao);
			}
			
			let dados = await CqbServiceApi.getEquipeCIBIOLoad();
			dados = dados.map(e=>{
				let t = defaultData.titulacao.find((tt:any)=> tt.id === e.titulacao);
				return {...e, 
					id: e.id,
					uid: uuid(),
					tecnico		: {...e, value: e.id, label: `${(t?.nome ?? '')} ${e.nome}`},
					titulacao	: (t ? Object.assign({...t, value: t.id, label: t.nome }) : null)
				}
			});
			const equipe = dados.filter(e=> e.funcaoCIBIO !== 2);
			const pCIBIO = dados.find(e=> e.funcaoCIBIO === 2);
			const pTitul = defaultData.titulacao?.find((e:any)=> e.id === pCIBIO?.titulacao.id)?.nome ?? "";
			const nCIBIO = {...pCIBIO, value: pCIBIO?.id, label: `${pTitul} ${pCIBIO?.nome}`  }

			setEquipeCIBIO(equipe);
			setPresidente(nCIBIO);
			defaultForm.reset({equipeCIBIO: equipe, presidente: nCIBIO});
			const initData =  {			
				presidente	: nCIBIO,
				equipeCIBIO	: equipe,
			};
			return initData;
		 }}
		 form={defaultForm}
		 getEntity={(entity:any)=>{}}		
		 MsgSaveSubmitSucess="Tecnico Salvo com Sucesso"
		 OnSaveSubmitErro={(error:any)=>{
			console.log("Error em OnSaveSubmitErro: ", error);
		 }}
		 OnSaveSubmit={async (values:any, entity: any)=>{	
			try{
				const newvalues={
					...values,
					...defaultForm.getValues()
				}
				let lista:any[] = []
				lista.push( newvalues.presidente ? {...newvalues.presidente, funcaoCIBIO: 2} : null);
				for(var i = 0, t = newvalues.equipeCIBIO.length; i < t; i++){
					lista.push({...newvalues.equipeCIBIO[i], funcaoCIBIO: 1});
				}
				lista = lista.map((e:any)=>{
					return {
						...e,
						titulacao	: e.titulacao?.id,
						tecnico		: null,						
					}
				});
				//let formData = new FormData();	
				let cibio = [];
				for(let i = 0, t = lista.length; i < t; i++){
					const refObj = lista[i];
					let jsonObj : tempObject = {}
					for (var key in refObj) {
						jsonObj[`${key}`] =  refObj[key];
					}
					cibio.push(jsonObj);
				}	
				await CqbServiceApi.getEquipeCIBIOSave(cibio); 
				return {isOk: true, message: "Lista da Equipe CIBIO Salva com Sucesso"};
			} catch (error:any) {
				 console.log(error);
				return {isOk: false};
			}
		 }}
		 OnSaveSubmitIsOkGoBack={false}	 
		>

		<DropDown name="presidente" label="Presidente da CIBIO" 		
		layoutColumns={8} form={defaultForm}
		defaultValue={presidente}
		options={itemsEquipeTecnica?.map(i => Object.assign({...i, value: i.id, label: `${titulacao.find(e=> e.id === i.titulacao)?.nome} ${i.nome}`  }))}
		enabled={true} isClearable={true} 
		placeholder="Selecione o Presidente da CIBIO"
		required
		isInvalidCheck={true}
		/>
		
		<EditField title="Relacionar os nomes, CPF, formação profissional, titulação e capacitação em biossegurança da equipe técnica envolvida no trabalho com OGM e seus derivados, apresentando Curriculum Vitae de cada participante que não estiver inscrito na Plataforma Lattes.">
			<Grid<CqbEquipeTecnica, CqbEquipeTecnica>
				{...defaultForm.register(`equipeCIBIO`)} 
				isInvalidCheck={true} required={true}
				form={defaultForm}
				style={{ height: '210px', width: '100%' }}
				name="equipeCIBIO" label="Equipe Técnica BASF" 
				layoutColumns={12}
				enabled={true} 
				defaultValue={equipeCIBIO}
				collumns={[
				{header: 'Nome', field: 'tecnico', justifyContent: 'left',
				gridValueFormatter: (event:any)=>{ return event.data?.tecnico?.label },
				showOnModal: true, custumControls : <DropDown name="tecnico" label="Escolha um técnico para ser inserido" layoutColumns={12} 
				options={itemsEquipeTecnica?.map(i => Object.assign({...i, value: i.id, label: `${i.nome} (${titulacao.find(e=> e.id === i.titulacao)?.nome})`  }))}  />  },
				
				{showOnModal: true, field: 'cargoCIBIO', header: 'Cargo na Comissão', justifyContent: 'left',
				gridValueFormatter: (event:any)=>{ return event.data?.cargoCIBIO },
				custumControls : <TextField layoutColumns={12} />},

				// {showOnModal: false, field: 'documento', header: 'CPF/CREA/CRBio', justifyContent: 'left',
				// gridValueFormatter: (event:any)=>{ return event.data?.documento } },

				{showOnModal: false, showOnGrid: false, field: 'id',  justifyContent: 'left',
				gridValueFormatter: (event:any)=>{ return event.data?.id }, header: '' },
				{showOnModal: false, showOnGrid: false, field: 'uid',  justifyContent: 'left',
				gridValueFormatter: (event:any)=>{ return event.data?.uid }, header: '' },

				]}
			></Grid>
		</EditField>

	</EditView>
}
