import { isNull, isUndefined } from "util";

export const DecimalAsComma = (value : any) : string =>
{
	if (isNaN(value) || isNull(value) || isUndefined(value))
		return '';

	let stringValue : string = '';

	stringValue += value;

	stringValue = stringValue.replace(',', '');
	stringValue = stringValue.replace('.', ',');

	return stringValue;
}