import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import SimpleInput from '../../../components/ReactSimpleInput';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../../pages/Searchs/styles.css';
import Select from 'react-select';
import If from '../../../components/If';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';
import EditButton from '../../../components/EditButton';
import CancelButton from '../../../components/CancelRequisitionButton';
import ViewButton from '../../../components/ViewButton';
import PDFButton from '../../../components/GeneratePDF';
import ZipButton from '../../../components/GenerateZip';
import SearchEmpty from '../../../components/SearchEmpty';
import ReasonModal from '../../../components/ReasonModal';
import { DecodedToken } from '../../../models/user';
import { STRINGS_CIBIO } from '../../../util/authorizationsCIBIO/string';

import { isAdmin } from '../../../users/AuthorizationsCIBio/isAdmin';
import { isRequesterReader, isRequesterWriter } from '../../../users/AuthorizationsCIBio/isRequester';
import { isReguladorWriter, isReguladorReader } from '../../../users/AuthorizationsCIBio/isRegulador';

import { STATUS_CIBIO } from '../../../util/authorizationsCIBIO/status';
import jwtDecode from 'jwt-decode';
import api, { apiFileDownload } from '../../../services/api';
import ptBR from 'date-fns/locale/pt-BR';
import { _generateDate } from '../../../util/convert';
import AlertSuccess from '../../../components/AlertSuccess';
import '../styles_add.scss';

import { AMIById, AACSearchResult, CultureResult, Enum, UnityStationResult } from '../../../models/modelsAuthorizationsCIBIO';

registerLocale('pt-BR', ptBR);

export default function AAC ()
{
	const history = useHistory();
	const token = String(localStorage.getItem('token'));
	const { roles, unique_name }: DecodedToken = jwtDecode(token);
	const [amis, setAmis] = useState<AACSearchResult[]>([]);
	const [locais, setLocais] = useState<UnityStationResult[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [status, setStatus] = useState<Enum[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [isCancel, setIsCancel] = useState<boolean>(false);
	const [reasonCancel, setReasonCancel] = useState('');
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	const [successCancel, setSuccessCancel] = useState<boolean>(false);
	const [cancelError, setCancelError] = useState<any>(null);
	const [tempId, setTempId] = useState<number>(0);
	const [loadingAuthorizationById, setLoadingAuthorizationById] = useState<boolean>(false);
	const [authorization, setAuthorization] = useState<AMIById>();

	const { register, handleSubmit, watch, control } = useForm({
		defaultValues: {
			codigo: '',
			solicitanteTecnicoPrincipal: (isRequesterReader(roles) || isRequesterWriter(roles)) ? unique_name : '',
			unidadeEstacao: null,
			cultura: null,
			dataInicial: null,
			dataFinal: null,
			status: null,
		}
	})

	const getAuthorizations = async () =>
	{
		setError(false);
		try
		{
			let requester = (isRequesterReader(roles) || isRequesterWriter(roles)) ? unique_name : '';
			const { data } = await api.get<AACSearchResult[]>(`api/v1/autorizacoes-cibio/AtividadeContencao/obterporfiltro?SolicitanteTecnicoPrincipal=${requester}`);
			setAmis(data);
		}
		catch (error:any)
		{
			setError(true);
		}
	}

	const getData = async () =>
	{
		setLoading(true);

		try
		{
			const locais = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');
			const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');
			const status = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/status');

			setLocais(locais.data);
			setCultures(cultures.data);
			setStatus(status.data);
		}
		catch (error:any)
		{

		}
		
		setLoading(false);
	}

	useEffect(() =>
	{
		getData();
		getAuthorizations();
	}, [])


	const search = async (queries?: any) =>
	{
		setError(false);
		setLoadingSearch(true);

		const {
			codigo,
			solicitanteTecnicoPrincipal
		} = watch();

		try {
			const { data } = await api.get<AACSearchResult[]>(`api/v1/autorizacoes-cibio/AtividadeContencao/obterporfiltro?Codigo=${codigo}&SolicitanteTecnicoPrincipal=${solicitanteTecnicoPrincipal}&UnidadeEstacaoId=${queries?.unidadeEstacao ? queries?.unidadeEstacao.value : ''}&CulturaId=${queries?.cultura ? queries?.cultura.value : ''}&DataInicial=${queries?.dataInicial ? _generateDate(new Date(queries?.dataInicial), 'En') : ''}&DataFinal=${queries?.dataFinal ? _generateDate(new Date(queries?.dataFinal), 'En') : ''}&Status=${queries?.status ? queries.status.value : ''}`);

			if (data.length) {
				setAmis(data);
				setEmptySearch(false);
			} else {
				setEmptySearch(true);
			}
		} catch (error:any) {
			setError(true);
		}
		setLoadingSearch(false);
	}

	const can_edit = (info : string) : boolean =>
	{
		const possuiPerfilAdmin = isAdmin(roles);
		const possuiPerfilRegulador = isReguladorReader(roles) || isReguladorWriter(roles);

		switch (info)
		{
			case 'filtro_solicitante':

				return possuiPerfilRegulador || possuiPerfilAdmin;
		}

		return true;
	}

	const acoes = ({ data }: any) =>
	{
		const possuiPerfilAdmin = isAdmin(roles);
		const possuiPerfilRegulador = isReguladorReader(roles) || isReguladorWriter(roles);
		const ehRequisitanteDaRequisicao = data.solicitanteTecnicoPrincipal === unique_name;

		const canEditStatusPerfilRequisitante = [
			STATUS_CIBIO.EmPreenchimento
			, STATUS_CIBIO.CorrecaoSolicitada
			, STATUS_CIBIO.EmCorrecao
			, STATUS_CIBIO.AprovadopeloRegulatorio
		];

		const canEditStatusPerfilRegulador = [
			STATUS_CIBIO.EmAnalisePeloRegulatorio
		];

		let canEdit = false;

		if (possuiPerfilAdmin) {
			canEdit = true;
		} else if (possuiPerfilRegulador && canEditStatusPerfilRegulador.includes(data.status)) {
			canEdit = true;
		} else if (ehRequisitanteDaRequisicao && canEditStatusPerfilRequisitante.includes(data.status)) {
			canEdit = true;
		}

		let canCancel = canEdit && data.status !== STATUS_CIBIO.Cancelado;

		if (!possuiPerfilAdmin && !possuiPerfilRegulador)
		{
			const canCancelPerfilRequisitante = [
				STATUS_CIBIO.EmPreenchimento,
				STATUS_CIBIO.CorrecaoSolicitada,
				STATUS_CIBIO.EmAnalisePeloRegulatorio,
			];

			canCancel = canCancel && canCancelPerfilRequisitante.includes(data.status);
		}

		return <Fragment>
			<EditButton disable={!canEdit} onClick={() => {
				if (canEdit) {
					history.push({
						pathname: '/autorizacoes/editar/aac',
						state: {
							id: data.id
						}
					})
				}
			}} />
			&nbsp;&nbsp;
			<CancelButton disabled={!canCancel} onClick={() => {

				if (!canCancel) return;
				
				setIsCancel(true);
				getAuthorizationById(data.id);
				setSuccessCancel(false);
				setReasonCancel('');

			}} />
			&nbsp;&nbsp;

			<ViewButton onClick={() => history.push({
				pathname: '/autorizacoes/visualizar/aac',
				state: {
					id: data.id
				}
			})} />
			&nbsp;&nbsp;

			<PDFButton onClick={() => getPdf(data.id)} />

			<ZipButton Text="Gerar Zip" onClick={() => getZip(data.id)} />
		</Fragment>;
	}

	const getPdf = async (id: number) =>
	{
		try
		{
			const { data } = await apiFileDownload.get(`api/v1/autorizacoes-cibio/Relatorios/atividade-contencao?id=${id}`);

			let blob = new Blob([data], {
				type: 'application/pdf'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = `aac-${id}`;
				document.body.appendChild(a);
				a.click();
			}
		}
		catch (error:any)
		{
			console.error(error);
		}
	}

	const getZip = async (id: number) =>
	{
		try
		{
			const { data } = await apiFileDownload.get(`api/v1/autorizacoes-cibio/AtividadeContencao/downloadzip/${id}`);

			let blob = new Blob([data], {
				type: 'application/zip'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = `aac-documentos-${id}`;
				document.body.appendChild(a);
				a.click();
			}
		}
		catch (error:any)
		{
			console.error(error);
		}
	}

	const getAuthorizationById = async (id: number) =>
	{
		setLoadingAuthorizationById(true);
		
		try
		{
			const { data } = await api.get(`api/v1/autorizacoes-cibio/AtividadeContencao/${id}`);

			setAuthorization(data);
		}
		catch (error:any)
		{
			throw new Error(error);
		}

		setLoadingAuthorizationById(false);
	}

	const cancelRequisition = async (data: any) =>
	{
		setLoadingCancel(true);
		setCancelError(null);

		try
		{
			const { status } = await api.post('api/v1/autorizacoes-cibio/AtividadeContencao/cancelar', {
				id: data.id,
				motivoCancelamento: reasonCancel
			});

			if (status === 200)
			{
				setSuccessCancel(true);
				setTimeout(() => {
					setIsCancel(false);
					search();
				}, 3000);
			}
		} catch (error:any) {
			setCancelError(error.response.data);
		}
		setLoadingCancel(false);
	}

	return <div className="container-custom-search">
		<div className="top-content">
			<h3>{STRINGS_CIBIO.SEARCH_AAC}</h3>
		</div>
		<div className="row-buttons">
			<If condition={isAdmin(roles) || isRequesterWriter(roles)}>
				<Button
					onClick={() => history.push('/autorizacoes/cadastrar/aac')}
					contained
					title="Adicionar Autorização"
					type="button"
				/>
			</If>
		</div>
		<br />
		<Form onSubmit={handleSubmit(search)} autoComplete="off">
			<Form.Row>
				<Form.Group as={Col}>
					<Form.Label>Solicitante/Técnico(a) Principal</Form.Label>
					<Controller
						control={control}
						name="solicitanteTecnicoPrincipal"
						render={({ onChange, value }) => (
							<SimpleInput
								defaultValue={value}
								clearButton={can_edit('filtro_solicitante')}
								disabled={!can_edit('filtro_solicitante')}
								className="form-control disabled"
								onChange={onChange}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={4}>
					<Form.Label>
						Estação/Unidade Operativa
					</Form.Label>
					<Controller
						control={control}
						name="unidadeEstacao"
						placeholder=""
						isClearable
						as={<Select options={locais.map(local => Object.assign({ value: local.id, label: local.nome }))} />}
					/>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Cultura/Organismo
					</Form.Label>
					<Controller
						control={control}
						name="cultura"
						isSearchable={false}
						isClearable
						placeholder=""
						as={<Select options={cultures.map(culture => Object.assign({ value: culture.id, label: culture.nome }))} />}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>Nº Autorização CIBio</Form.Label>
					<Controller
						control={control}
						name="codigo"
						render={({ onChange, value }) => (
							<SimpleInput
								defaultValue={value}
								clearButton
								className="form-control"
								onChange={onChange}
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Data inicial
					</Form.Label>
					<br />
					<Controller
						control={control}
						name="dataInicial"
						render={({ onChange, value }) => (
							<DatePicker
								selected={value}
								onChange={onChange}
								className="input-custom-to-date"
								locale="pt-BR"
								dateFormat="dd/MM/yyyy"
								placeholderText="Selecionar a data"
								isClearable
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Data Final
					</Form.Label>
					<br />
					<Controller
						control={control}
						name="dataFinal"
						render={({ onChange, value }) => (
							<DatePicker
								selected={value}
								onChange={onChange}
								className="input-custom-to-date"
								locale="pt-BR"
								dateFormat="dd/MM/yyyy"
								placeholderText="Selecionar a data"
								minDate={watch().dataInicial}
								isClearable
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Status
					</Form.Label>
					<Controller
						control={control}
						name="status"
						isSearchable={false}
						isClearable
						placeholder=""
						as={<Select options={status.map(stat => Object.assign({ value: stat.key, label: stat.value }))} />}
					/>
				</Form.Group>
				<span className="filter-btn">
					<Button
						title="Buscar"
						type="submit"
						contained
						isLoading={loadingSearch}
					/>
				</span>
			</Form.Row>
		</Form>
		<If condition={emptySearch}>
			<div className="div-empty">
				<SearchEmpty />
			</div>
		</If>
		<If condition={loading}>
			<div className="loading-container">
				<Loading />
			</div>
		</If>
		<If condition={!loading && !error && !emptySearch && amis.length}>
			<div className="search-content">
				<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
					<AgGridReact
						rowData={amis}
						defaultColDef={{ flex: 1, sortable: true, resizable: true }}
						pagination
						paginationPageSize={10}
						gridOptions={{ headerHeight: 80 }}
						frameworkComponents={{
							actions: acoes,
						}}
					>
						<AgGridColumn
							headerName='Código'
							field='id'
							maxWidth={130}
							sort="asc"
						/>
						<AgGridColumn
							headerName='Solicitante/ Requisitante'
							headerClass="myfunc-default-header"
							field='solicitanteTecnicoPrincipal'
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName='Estacao/ Unidade Operativa'
							headerClass="myfunc-default-header"
							field='unidadeEstacaoNome'
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName='Cultura/ Organismo'
							headerClass="myfunc-default-header"
							field='culturaNome'
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName='Nº Autorização CIBio'
							headerClass="myfunc-default-header"
							field='numeroAutorizacaoCibio'
						/>
						<AgGridColumn
							headerName='Emissão CIBio'
							headerClass="myfunc-default-header"
							field='dataEmissaoCibio'
							valueFormatter={({ data }: any) => data.dataEmissaoCibio ? _generateDate(data.dataEmissaoCibio, 'Pt') : ''}
						/>
						<AgGridColumn
							headerName='Status'
							field='statusDescricao'
						/>
						<AgGridColumn
							field='Ações'
							cellRenderer='actions'
						/>
					</AgGridReact>
				</div>
			</div>
		</If>

		<If condition={isCancel}>
			<ReasonModal
				show={isCancel}
				onHide={() => setIsCancel(false)}
				title={STRINGS_CIBIO.AMI_CANCEL_TITLE}
				actionMsg="Confirmar"
				onConfirm={() => {
					cancelRequisition(authorization);
				}}
				loading={loadingCancel}
				disabled={false}
				hideFooter={successCancel}
			>
				<If condition={loadingAuthorizationById}>
					<div className="loading-container">
						<Loading />
					</div>
				</If>
				<If condition={successCancel}>
					<AlertSuccess
						message={STRINGS_CIBIO.AMI_CANCEL_SUCCESS}
					/>
				</If>
				<If condition={!loadingAuthorizationById}>
					<Form.Label>
						<strong>{STRINGS_CIBIO.AMI_CANCEL_BODY_MSG}</strong>
					</Form.Label>
					<Form.Control
						onChange={({ target }: any) =>
							setReasonCancel(target.value)
						}
						value={reasonCancel}
						as="textarea"
						maxLength={200}
					/>
				</If>
			</ReasonModal>
		</If>
	</div>;
}