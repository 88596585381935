import React from 'react';
import AlertError from "./AlertError";
import AlertSuccess from "./AlertSuccess";

import {uuid} from "../services/uuid";

export enum MessageType
{
	error,
	success,
}

export interface MessageContent
{
	type: MessageType;
	content: string;
}

export default function NavigationMessage (props:any)
{
	return <React.Fragment>{props.messages.map((message:any) => {

		let html;

		switch (message.type)
		{
			case MessageType.error:

				html = <AlertError key={uuid()} message={message.content} />;
				break;

			case MessageType.success:

				html = <AlertSuccess key={uuid()} message={message.content} />;
				break;
		}

		return html;

	})}</React.Fragment>;
}