import React from 'react';
import { Spinner } from 'react-bootstrap';
import { colors } from '../util/colors';

interface Loader {
    color?: string;
}

function LoadingInForm({ color }: Loader) {
    return (
        <Spinner
            animation="border"
            size="sm"
            style={{ color: color ? color : colors.green5 }}
        />
    );
}

export default LoadingInForm;