import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { Form } from 'react-bootstrap';

import ViewButton from '../../../components/ViewButton';
import CancelButton from '../../../components/CancelRequisitionButton';
import EditButton from '../../../components/EditButton';
import PDFButton 	from '../../../components/GeneratePDF';

import DropDown from '../../../components/form/DropDown';
import TextField from '../../../components/form/TextField';
import ListingView, { ListCol } from "../../../components/page/ListingView";


import { LpmaRN35, LpmaEquipeTecnica, LpmaProjeto, OficioCIBIO } from '../../../models/modelsLPMA';
import { STATUS, STATUSTEXT } from '../../../util/lpma/status';
import { STRINGS_LPMA } from "../../../util/lpma/string";
import LpmaService, { RolesService } from '../services';
import AlertSuccess from '../../../components/AlertSuccess';
import Loading from '../../../components/Loading';
import ReasonModal from '../../../components/ReasonModal';
import LoadingInForm from '../../../components/LoadingInForm';
import { _generateDate } from '../../../util/convert';
import LoadingRequest from '../../../components/LoadingRequest';
import { DialogEditSingle } from '../../../components/form/DialogEdit';
import OficioLPMA from '../OficioLPMA';
import api from '../../../services/api';
import { listEnumItem } from '../../../models/modelCommum';
import CheckBox from '../../../components/form/CheckBox';

export default function List (){
	const LpmaServiceApi = new LpmaService();
	const LpmaRoles		 = new RolesService(useContext);
	const history = useHistory();

	const nomeSolicitante =()=>{
		return (LpmaRoles.isAdmin() || LpmaRoles.isRegulatorio()) ? ''  : LpmaRoles.getUserName();
	}
	
    const defaultForm = useForm({
		defaultValues: {
            titulo: '',
			solicitante: nomeSolicitante(),
			projeto: {value: null, label: null},
			tecnicoPrincipal: {value: null, label: null},
			status:  {value: null, label: null},
			documentConfigList : [],
			numeroAutorizacao: '',
        }// as LpmaRN35,
	})	

	const [itemsProjetos, setItemsProjetos] = useState<LpmaProjeto[]>();
	const [itemsEquipeTecnica, setItemsEquipeTecnica] = useState<LpmaEquipeTecnica[]>();
	const [loading, setLoading] = useState<boolean>(false);

	const [successCancel, setSuccessCancel] = useState<boolean>(false);
	const [loadingById, setLoadingById] = useState<boolean>(false);
	const [reasonCancel, setReasonCancel] = useState('');
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	const [isCancel, setIsCancel] = useState<boolean>(false);
	const [itemSel, setItemSel] = useState<any>();
	const [loadingButPdf, setLoadingButPdf] = useState<boolean>(false);
	const [refreshFilter, setRefreshFilter] = useState<boolean>(false);

	const [documentConfig, setDocumentConfig] = useState<listEnumItem[]>([]);

	React.useEffect(() => {
        if(refreshFilter === true) { setRefreshFilter(false); }
    }, [refreshFilter]);

	// const getData = async (q:any, _finishHandler:any) => 
	// {			
	// 	try
	// 	{
	// 		setItemsProjetos(await LpmaServiceApi.getProjetos());
	// 		setItemsEquipeTecnica(await LpmaServiceApi.getEquipeTecnica());
	// 		setDocumentConfig(await LpmaServiceApi.getListCondfigDocumentLPMA('RN35'));
	// 		_finishHandler();
			
	// 	}
	// 	catch (error:any)
	// 	{
	// 		console.error(error);
	// 		//ParseDefaultBackendMessage(error);
	// 		//GoBackToTop();
	// 	}
		
	// 	setLoading(false);
	// };

	// useEffect(() => {

	// 	// getData(null, ( )=>{
	// 	// 	//searchOrGetListLpma( null);
	// 	// });		

	// }, []);

	const cancelLpma = async (dados?: any) =>{		
		try {
			setLoadingCancel(true);
			let saveDados = {...dados, motivoCancelamento :  reasonCancel};// as LmpaRN35;
			// console.log(saveDados);
			let formData = new FormData();
			for (var key in saveDados) {
				if(!saveDados[key]){
					continue;
				}
				formData.append(key, saveDados[key]);
			}
			
			saveDados = await LpmaServiceApi.getRN35Action('cancelar', formData);
			setSuccessCancel(true);
			setRefreshFilter(true);
			setTimeout(() => {
				setIsCancel(false);
				//searchOrGetListLpma();
			}, 3000);
		} catch (error:any) {
			
		}
		setLoadingCancel(false);
	};

	const CancelPopUp = () =>{
		return <ReasonModal
					show={isCancel}
					onHide={() => setIsCancel(false)}
					title={STRINGS_LPMA.CANCEL_TITLE}
					actionMsg="Confirmar"
					onConfirm={() => {
						cancelLpma(itemSel);
					}}
					loading={loadingCancel}
					disabled={false}
					hideFooter={successCancel}
				>
					{loadingById ? 
						<div className="loading-container">
							<Loading />
						</div>
					: ''}
					{successCancel ? 
						<AlertSuccess
							message={STRINGS_LPMA.CANCEL_SUCCESS}
						/>
					:''}
					{!loadingById ? 
						<div>
							<Form.Label>
							<strong>{STRINGS_LPMA.APROVE_BODY_MSG}</strong>
							</Form.Label>
							<Form.Control
								onChange={({ target }: any) =>
									setReasonCancel(target.value)
								}
								value={reasonCancel}
								as="textarea"
								maxLength={200}
							/>
						</div>
						:''}
				</ReasonModal>	;
	}

	const updateLPMA = async (acao: string, dados?: any, finish:any = null, timeOutFinish: any = null) =>{		
		try {
			let saveDados = {...dados};// as CqbDto;

			let keysToIgnore = ['documentConfig'];
			let formData = new FormData();
			for (var key in saveDados) {
				if (keysToIgnore.includes(key) || !saveDados[key]) { continue; }
				const valueByKey = saveDados[key];
				if(key === 'documentConfigList'){
					for(let i = 0, t = valueByKey.length; i < t; i ++){
						formData.append(`${key}[${i}]`, valueByKey[i]);  
					}					
					continue;
				}
				//const valueByKey = saveDados[key];				
				formData.append(key, saveDados[key]);
			}
			saveDados = await LpmaServiceApi.getRN35Action(acao, formData);
			if(finish != null) {finish(true);}
			setRefreshFilter(true);
			setTimeout(() => {				
				if(timeOutFinish != null) {timeOutFinish();}						
			}, 3000);	
			setSuccessCancel(true);
			setTimeout(() => {
				setIsCancel(false);
				//searchOrGetListLpma();
			}, 3000);

			return true;
		} catch (error:any) {
			if(finish != null) {finish(false);}
			console.error("erro", error);
			return false;
		}
	};

	const btnNumeroAutorizacao = (data:any)=>{
		if(!(LpmaRoles.isAdmin() || LpmaRoles.isRegulatorio())) { return; }
		return <DialogEditSingle label="Infomar Número de Autorização CIBIO" 	
		dlgTitle={<span className="singleTitle">{`${STRINGS_LPMA.MODO_NUMER_AUTORIZ_DLG}`}<br />
		{`Código da Solicitação: ${data.id}`} <br /> {`${(data.numeroAutorizacao ?? '')}`}</span>}			
		isButtonType='add' 
		width25
		enabled={true}
		popData={data}
		onShow={(pdata:any)=>{
			setItemSel(pdata);		
			defaultForm.setValue("numeroAutorizacao", pdata.numeroAutorizacao);
		}}
		onConfirme={async ()=>{
			const idSel = defaultForm.watch('numeroAutorizacao');
			const sedData = {...itemSel, ...data, numeroAutorizacao: idSel}
			// return await DocumentConfigCQB(sedData);
			const updateLPMAItem = await updateLPMA(
				'update_config_num_aut', 
				{...sedData, numeroAutorizacao: sedData.numeroAutorizacao}
				);		
			return updateLPMAItem;
		}}>						
		<TextField<LpmaRN35> label={STRINGS_LPMA.MODO_NUMER_AUTORIZ}
		form={defaultForm} name="numeroAutorizacao"
		value={data.numeroAutorizacao}
		length={150}
		isClearable enabled={true} layoutColumns={12}  />				
		</DialogEditSingle>
	}

	const btnConfig = (data:any)=>{
		let estagio_ = data.status ?? STATUS.EmPreenchimento;
		let isEditavel = (LpmaRoles.isRegulatorio() 
			&& (estagio_ === STATUS.EmAnalisePeloRegulatorio || estagio_ === STATUS.Concluido))
		|| ((LpmaRoles.isEditRequisicao() || LpmaRoles.isCreatRequisicao()) 
			&& (estagio_ === STATUS.EmPreenchimento || estagio_ === STATUS.EmCorrecao))
		|| LpmaRoles.isAdmin();

		return <DialogEditSingle label="Configurar" 	
		dlgTitle={<><h5 style={{width:'100%'}}>{`${STRINGS_LPMA.MODO_CONFIGURAR_DLG}`}</h5>
		<h5>{`Código da Solicitação: ${data.id}`}</h5></>}			
		isButtonType='config' 
		width50
		enabled={isEditavel}
		popData={data}
		onShow={(pdata:any)=>{				
			setItemSel(pdata);		
			defaultForm.setValue("documentConfigList", data.documentConfigList);
		}}
		onConfirme={async ()=>{
			const idSel = defaultForm.watch('documentConfigList')?.map((e:any)=> parseInt(e));;
			const sedData = {...itemSel, ...data, documentConfigList: idSel}
			// return await DocumentConfigCQB(sedData);
			const updateLPMAItem = await updateLPMA(
				'update_config', 
				{...sedData, documentConfigList: sedData.documentConfigList}
				);		
			return updateLPMAItem;
		}}>
		<label>{STRINGS_LPMA.MODO_CONFIGURAR}</label>								
		{documentConfig?.map((e:listEnumItem)=>{
			return <CheckBox form={defaultForm}  name="documentConfigList"
			{...defaultForm.register(`documentConfigList`)}
			layoutColumns={12}		
			label={e.nome} value={e.id}	
			enabled={isEditavel} 
			onChange={(chk: any)=>{e.checked = chk}}
			defaultChecked={data.documentConfigList?.includes(e.id)}
		/>})}				
		</DialogEditSingle>
	}
	
	return <ListingView<LpmaRN35>
		OnInit={async ()=>{
			setLoading(true);
			try
			{
				const proj = await LpmaServiceApi.getProjetos({ id: 0 });
				const tecn = await LpmaServiceApi.getEquipeTecnica({ id: 0 });
				const docC = await LpmaServiceApi.getListCondfigDocumentLPMA('RN35');
				setItemsProjetos(proj);
				setItemsEquipeTecnica(tecn);
				setDocumentConfig(docC);
				//_finishHandler();		
				setLoading(false);
				return 	{
					projeto: proj, equipeTecnica: tecn,
					condfigDocumentLPMA: docC
				};
			}
			catch (error:any)
			{
				console.error(error);	

			}			
			setLoading(false);
		}}
		ModalPopups={CancelPopUp()}
		title={STRINGS_LPMA.SEARCH_LPMA_RN35}
		ButtonAddText="Nova LPMA RN35"
		form={defaultForm}
		filterRefresh={refreshFilter}
		onFilterSearch = {async (queries?: any) => {
			let projeto = defaultForm.getValues('projeto');
			let tecnicoPrincipal = defaultForm.getValues('tecnicoPrincipal');
			let status = defaultForm.getValues('status');
			let filtro = {
				titulo					: defaultForm.getValues('titulo'),
				projetoId               : projeto?.value,
				tecnicoPrincipalId      : tecnicoPrincipal?.value,
				status					: status?.value,
				solicitante				: defaultForm.getValues('solicitante'),
			}; //as LpmaRN35;

			//console.log(queries, filtro);
			return await LpmaServiceApi.getListRN35(filtro);
		}}
		ShowButtonAddNew={LpmaRoles.isCreatRequisicao()}
		onButtonAddEvent={()=>{ history.push("/lpma/rn35/nova") }}
		filters={[
			<TextField
				name="solicitante"
				label="Solicitante/Requisitante"
				form={defaultForm}
				defaultValue={nomeSolicitante()}
				isClearable
				enabled={LpmaRoles.isAdmin() || LpmaRoles.isRegulatorio()}
				layoutColumns={3}
			/>,
			<TextField<LpmaRN35>
				form={defaultForm}
				layoutColumns={6}
				name="titulo"
				label="Título"
				required={false}
				isClearable
			/>,
			<DropDown
				name="projeto"
				label="Código do Projeto"
				layoutColumns={3}
				form={defaultForm}
				options={itemsProjetos?.map(i => Object.assign({ value: i.id, label: `${i.nome} - ${i.cultura}` }))}
				isClearable
			/>,
			<DropDown
				name="tecnicoPrincipal"
				label="Tecnico Responsável"
				layoutColumns={3}
				form={defaultForm}
				options={itemsEquipeTecnica?.map(i => Object.assign({ value: i.id, label: i.nome }))}
				isClearable
			/>,
			<DropDown
				name="status"
				label="STATUS da LPMA"
				layoutColumns={3}
				form={defaultForm}
				options={STATUSTEXT.map((val:any, index: any)=>  Object.assign({ value: val.s, label: val.t } ) )}
				isClearable
			/>
		]}
		onEditEvent = {(data:any )=>{  history.push({pathname: `/lpma/rn35/editar/${data.id}`,})}}
		onListItemActionsButtons = {( data : any) => {
			
			let estagio_ = data.status ?? STATUS.EmPreenchimento;
			
			let isEditavel = (
				LpmaRoles.isRegulatorio()
				&& (
					estagio_ === STATUS.EmAnalisePeloRegulatorio
					||
					estagio_ === STATUS.Concluido
				)
			)
			||
			(
				(LpmaRoles.isEditRequisicao() || LpmaRoles.isCreatRequisicao())
				&& (estagio_ === STATUS.EmPreenchimento || estagio_ === STATUS.EmCorrecao)
			)
			||
			LpmaRoles.isAdmin();

			let isCancel = isEditavel && estagio_ !== STATUS.Cancelado;
			// (LpmaRoles.isCreatOREditRequisicao() && estagio_ !== STATUS.Cancelado && estagio_ !== STATUS.Aprovado) 
			// 				|| 
			// 				(LpmaRoles.isAdmin() && estagio_ !== STATUS.Cancelado) 
			// 				|| 
			// 				isEditavel;

			let canUseOficio : boolean = estagio_ == STATUS.Aprovado  && (LpmaRoles.isRegulatorio() || LpmaRoles.isAdmin());
			let idOficio : number | undefined = undefined;

			if (data && data.oficios && Array.isArray(data.oficios) && data.oficios.length > 0)
				idOficio = data.oficios[0].id || undefined;

			return <Fragment>
				
				<EditButton
					disable={!isEditavel}
					onClick={() => {history.push({pathname: `/lpma/rn35/editar/${data.id}`})}}
				/>

				&nbsp;&nbsp;

				<ViewButton
					onClick={() => {history.push({pathname: `/lpma/rn35/visualizar/${data.id}`})}}
				/>

				&nbsp;&nbsp;

				<CancelButton 
					disabled={!isCancel}
					onClick={() => {
						setIsCancel(true);
						setItemSel(data);
						setSuccessCancel(false);
						setReasonCancel('');
					}}
				/>

				&nbsp;&nbsp;
				{btnConfig(data)}
				&nbsp;&nbsp;

				<PDFButton onClick={async () => {
					setLoadingButPdf(true);
					// Desativado - não gera a listagem de Gene de Interesse na RN35, mas é usada em Relatórios Conclusivos 
					//if (LpmaRoles.isAdmin() || LpmaRoles.isRegulatorio())
					//	await LpmaServiceApi.DownloadRN35PDF(data.id, data.documentoFileName, "C");
					await LpmaServiceApi.DownloadRN35PDF(data.id, data.documentoFileName, "");
					setLoadingButPdf(false);
				}} />

				&nbsp;&nbsp;

				<OficioLPMA
					show={canUseOficio}
					onAction={(id: number) => { history.push({ pathname: `/lpma/rn35/comunicados/${data.id}` }) }}
					onCreate={(callback: { (id: number): void }): any => {

						api.get<OficioCIBIO>(`api/v1/lpma/oficio/rn35/${data.id}`).then((response) => {
							callback(response?.data?.id || 0);
						});


					}}
					id={data.id}
				/>

				{/*<OficioLPMA*/}
				{/*	show={canUseOficio}*/}
				{/*	onAction={(id:number) => {history.push({pathname:`/oficiolpma/${id}`})}}*/}
				{/*	onCreate={(callback:{(id:number) : void}) : any => {*/}
					
				{/*		api.get<OficioCIBIO>(`api/v1/lpma/oficio/rn35/${data.id}`).then((response) => {*/}
				{/*			callback(response?.data?.id || 0);*/}
				{/*		});*/}
						
	
				{/*	}}*/}
				{/*	id={idOficio}*/}
				{/*/>*/}
			</Fragment>;
		}}

	actionsCellStyle ={{ justifyContent: 'left' }}
	actionsMinWidth ={200}
	>

		<ListCol
			header='Código'
			field='id'
			maxWidth={80}
			headerClass="myfunc-default-header"
			sort="asc"
		/>	
		<ListCol header='Nº Controle' field='numeroProcessoEAno' maxWidth={100} 	headerClass="myfunc-default-header" sort="asc" />	

		<ListCol
			header='Solicitante/ Requisitante'
			field='solicitante'
			headerClass="myfunc-default-header"
		/>

		<ListCol header='Projeto / Titulo' minWidth={300} valueFormatter={({ data }: any) => {
			const prj = (data?.projeto ? ` (${data?.projeto?.nome} - ${data?.projeto?.cultura}) ` : '');
			return `${prj} ${data.titulo}`			
		}} 
		field='Titulo' wrapText	headerClass="myfunc-default-header" cellStyle={{ justifyContent: 'left' }}  />	


		{/* <ListCol
			header='Projeto'
			field='projeto'
			maxWidth={150}
			valueFormatter={({ data }: any) => data?.projeto ? `${data?.projeto?.nome} - ${data?.projeto?.cultura}` : ''}
			headerClass="myfunc-default-header"
			cellStyle={{
				justifyContent: 'left'
			}}
		/>			

		<ListCol
			header='Titulo'
			headerClass="myfunc-default-header"
			field='titulo'
			cellStyle={{
				justifyContent: 'left'
			}}
		/>			 */}

		<ListCol
			header='Tecnico Responsável'
			headerClass="myfunc-default-header"
			field='tecnicoPrincipal'
			valueFormatter={({ data }: any) => data?.tecnicoPrincipal?.nome ?? ''}
			cellStyle={{
				justifyContent: 'left'
			}}
		/>	

		<ListCol
			header='Data do Endosso'
			field='dataEndosso'
			headerClass="myfunc-default-header"
			maxWidth={130}
			valueFormatter={({ data }: any) => data.dataEndosso ? _generateDate(data.dataEndosso, 'Pt') : ''}
		/>	

		<ListCol
			header='Nº Processo CIBIO'
			headerClass="myfunc-default-header"
			field='numeroAutorizacao'
			cellRenderer={({data}:any)=>{
				if(data.status === STATUS.Aprovado){// && (!data.numeroAutorizacao || data.numeroAutorizacao?.length === 0)){
					return <span>{data.numeroAutorizacao}&nbsp;&nbsp;{btnNumeroAutorizacao(data)}</span>;
				}
				return "";
			}}
			minWidth={150}
			cellStyle={{
				justifyContent: (LpmaRoles.isAdmin() || LpmaRoles.isRegulatorio()) ? 'right' : 'center'
			}}
		/>
		
		<ListCol
			header='Status'
			headerClass="myfunc-default-header"
			field='status'
			cellStyle={{
				justifyContent: 'left'
			}}
			valueFormatter={({ data }: any) =>  STATUSTEXT.find(a=> a.s === (data.status ?? 1))?.t }
		/>	

	</ListingView>
}