import React from 'react';
// import NumberFormat from 'react-number-format';
import * as Models from '../../../models/models';
import * as Bootstrap from 'react-bootstrap';
import * as HookForm from 'react-hook-form';
import If from '../../../components/If';

// import "./style.scss";

interface Props
{
	form					: HookForm.UseFormMethods;
	name					: string;
	culture?				: Models.CultureResults;
}

interface State
{
	arr_10_1				: string;
	arr_10_2				: string;
	arr_10_3				: string;
	arr_10_4				: string;
	arr_10_5				: string;
	arr_10_6				: string;
	arr_10_7				: string;
	alg_10_1				: string;
	alg_10_2				: string;
	alg_10_3				: string;
	alg_10_4				: string;
	alg_10_5				: string;
	alg_10_6				: string;
}

export default class Group_10 extends React.Component<Props, State>
{
	public static defaultProps = {
		required						: false,
		readOnly						: false,
		columns							: 12,
	};

	static emptyData = {
		arr_10_1			: '',
		arr_10_2			: '',
		arr_10_3			: '',
		arr_10_4			: '',
		arr_10_5			: '',
		arr_10_6			: '',
		arr_10_7			: '',
		alg_10_1			: '',
		alg_10_2			: '',
		alg_10_3			: '',
		alg_10_4			: '',
		alg_10_5			: '',
		alg_10_6			: '',
	};

	constructor (props:Props)
	{
		super(props)
		this.props.form.register(this.props.name);
	}

	componentDidMount ()
	{
		let data = this.props.form.getValues(this.props.name) ? JSON.parse(this.props.form.getValues(this.props.name)) : Group_10.emptyData;

		this.setState(data);
	}

	save ()
	{
		this.props.form.setValue(this.props.name, JSON.stringify(this.state));

		// console.log(JSON.parse(JSON.stringify(this.state)));
	}

	formAlgodao () : JSX.Element
	{
		let labelSize: number = 5;

		return <Bootstrap.Form.Group as={Bootstrap.Col}>
		</Bootstrap.Form.Group>;
	}

	formArroz () : JSX.Element
	{
		let labelSize: number = 7;

		return <Bootstrap.Form.Group as={Bootstrap.Col}>
		</Bootstrap.Form.Group>;
	}

	formTextoLivre () : JSX.Element
	{
		return <Bootstrap.Form.Group as={Bootstrap.Col}>
			<Bootstrap.Form.Control
				as="textarea"
				type="text"
				ref={this.props.form.register}
				name={this.props.name}
				placeholder="Insira os detalhes do ciclo agronomico"
				autoComplete="off"
				isInvalid={this.props.form.errors[this.props.name] !== undefined}
				rows={10}
			/>
			<If condition={this.props.form.errors[this.props.name]}>
				<Bootstrap.Form.Control.Feedback type="invalid">
					{this.props.form.errors[this.props.name]?.message}
				</Bootstrap.Form.Control.Feedback>
			</If>
		</Bootstrap.Form.Group>;
	}

	render ()
	{
		if (!this.props.culture || !this.props.culture?.cicloAgronomicoAsText)
			return <React.Fragment />;

		switch (this.props.culture.nome.toLowerCase())
		{
			case 'arroz':

				return this.formArroz();

			case 'algodão':

				return this.formAlgodao();

			default:

				return this.formTextoLivre();

		}
	}
}