import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Form, Col } from 'react-bootstrap';
import AddButton from '../../../components/AddButton';
import Button from '../../../components/Button';
import GenericButton from '../../../components/FlowGenericButton';
import EditButton from '../../../components/EditButton';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import LoadingInForm from '../../../components/LoadingInForm';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { STRINGS } from '../../../util/strings';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { Context } from '../../../context/AuthContext'
import { DecodedToken } from '../../../models/user';
import '../../../pages/Registers/styles.css';
import api from '../../../services/api';
import jwtDecode from 'jwt-decode';
import { DecimalAsComma } from '../NumberFormat';
import { _generateDate } from '../../../util/convert';
import '../styles_add.scss';

import { STRINGS_CADERNOCONFORMIDADE } from '../../../util/cadernoConformidade/string';

import { isAdmin } from '../../../users/CadernoConformidade/isAdmin';
import { isRequesterReader, isRequesterWriter } from '../../../users/CadernoConformidade/isRequester';
import { isReguladorWriter, isReguladorReader } from '../../../users/CadernoConformidade/isRegulador';

import { STATUS_CADERNOCONFORMIDADE } from '../../../util/cadernoConformidade/status';

import {
	StateType, Enum, VegetacaoSearchResult, CulturaResult,
	ResponsavelRegulatorioResult, TecnicoPrincipalResult,
	VegetacaoAnotacoes, VegetacaoPlantios, VegetacaoLimpezaDestruicao, VegetacaoDataColheita,
	VegetacaoInspecaoEquipamentos, VegetacaoLimpezaInstalacoes
} from '../../../models/modelsCadernoConformidade';

registerLocale('pt-BR', ptBR);

export default function CadernoConformidade() {
	const history = useHistory();
	const { token } = useContext(Context);
	const decoded: DecodedToken = jwtDecode(token.access_token);
	const [entity, setEntity] = useState<VegetacaoSearchResult>();

	const [contagemAnotacao, setContagemAnotacao] = useState<any>(null);
	const [anotacaoEdit, setAnotacaoEdit] = useState<VegetacaoAnotacoes>({
		id: 0,
		responsavelAnotacao: '',
		dataAnotacao: undefined,
		anotacao: '',
	});
	const [selectedAnotacoes, setSelectedAnotacoes] = useState<VegetacaoAnotacoes[]>([]);
	const [modalAnotacoes, setModalAnotacoes] = useState<boolean>(false);

	const [plantioEdit, setPlantioEdit] = useState<VegetacaoPlantios>({
		id: 0,
		datasPlantio: '',
		numeroPlantio: 0,
		modulo: '',
		material: '',
		informacoesAdicionaisMaterial: '',
		numeroAMI: '',
		sobraMaterial: '',
		numeroAmostrasRestantes: '',
		quantidadeAmostrasRestantes: '',
		destinoSobra: '',
		entradaArmazenamento: '',
		informacoesAdicionais: '',
	});
	const [selectedPlantios, setSelectedPlantios] = useState<VegetacaoPlantios[]>([]);
	const [modalPlantios, setModalPlantios] = useState<boolean>(false);

	const [limpezaDestruicaoEdit, setLimpezaDestruicaoEdit] = useState<VegetacaoLimpezaDestruicao>({
		id: 0,
		maquinario: '',
		dataAntesUso: '',
		dataAposUso: '',
		metodoLimpeza: '',
		responsavel: '',
	});
	const [selectedLimpezaDestruicao, setSelectedLimpezaDestruicao] = useState<VegetacaoLimpezaDestruicao[]>([]);
	const [modalLimpezaDestruicao, setModalLimpezaDestruicao] = useState<boolean>(false);

	const [selectedDatasColheita, setSelectedDatasColheita] = useState<VegetacaoDataColheita[]>([]);
	const [modalDatasColheita, setModalDatasColheita] = useState<boolean>(false);


	const [contagemInspecaoEquipamento, setContagemInspecaoEquipamento] = useState<any>(null);

	const [inspecaoEquipamentosEdit, setInspecaoEquipamentosEdit] = useState<VegetacaoInspecaoEquipamentos>({
		id: 0,
		contagemInspecaoEquipamento: '',
		maquinarioEquipamento: '',
		idEquipamento: '',
		dataEquipamentoAntesUso: '',
		dataEquipamentoAposUso: '',
		faseEnsaioEquipamento: '',
		metodoLimpezaEquipamento: '',
	});
	const [selectedInspecaoEquipamentos, setSelectedInspecaoEquipamentos] = useState<VegetacaoInspecaoEquipamentos[]>([]);
	const [modalInspecaoEquipamentos, setModalInspecaoEquipamentos] = useState<boolean>(false);

	const [contagemLimpezaInstalacao, setContagemLimpezaInstalacao] = useState<any>(null);

	const [limpezaInstalacoesEdit, setLimpezaInstalacoesEdit] = useState<VegetacaoLimpezaInstalacoes>({
		id: 0,
		contagemLimpezaInstalacao: '',
		dataLimpezaInstalacao: '',
		casaVegetacaoLimpezaInstalacao: '',
		responsavelLimpezaInstalacao: '',
	});
	const [selectedLimpezaInstalacoes, setSelectedLimpezaInstalacoes] = useState<VegetacaoLimpezaInstalacoes[]>([]);
	const [modalLimpezaInstalacoes, setModalLimpezaInstalacoes] = useState<boolean>(false);


	const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
	const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [loadingGetData, setLoadingGetData] = useState<boolean>(true);
	const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [errorGetData, setErrorGetData] = useState<any>(null);

	const initialValues: VegetacaoSearchResult = {
		id: 0,
		aac_id: 0,
		projeto: '', 
		municipio: '',
		uf: '',
		cultura: undefined,
		nomecientifico: '',
		numeromapa: '',
		responsavelRegulatorio: undefined,
		tecnicoPrincipal: undefined,

		treinamentoBiosseguranca: false,
		aacAprovadaCibio: false,
		documentacaoImportacao: false,
		permissaoImportacao: false,
		materialIdentificacao: false,
		sementesConferidas: false,
		epiTrabalhadores: false,

		anotacoes: undefined,

	}

	const { register, getValues, errors, trigger, handleSubmit, control, watch, formState: { isDirty } } = useForm({
		defaultValues: initialValues,
		mode: 'onSubmit',
		//resolver: yupResolver(amiSchema)
	});

	const { getValues: getValuesAnotacoes, control: controlAnotacoes, register: registerAnotacoes, errors: errorsAnotacoes, handleSubmit: handleSubmitAnotacoes, trigger: triggerAnotacoes, watch: watchAnotacoes } = useForm({
		mode: 'all',
		//resolver: yupResolver(AnotacoesSchema)
	})

	const { getValues: getValuesPlantios, control: controlPlantios, register: registerPlantios, errors: errorsPlantios, handleSubmit: handleSubmitPlantios, trigger: triggerPlantios, watch: watchPlantios } = useForm({
		mode: 'all',
		//resolver: yupResolver(PlantiosSchema)
	})

	const { getValues: getValuesLimpezaDestruicao, control: controlLimpezaDestruicao, register: registerLimpezaDestruicao, errors: errorsLimpezaDestruicao, handleSubmit: handleSubmitLimpezaDestruicao, trigger: triggerLimpezaDestruicao, watch: watchLimpezaDestruicao } = useForm({
		mode: 'all',
		//resolver: yupResolver(LimpezaDestruicaochema)
	})

	const { getValues: getValuesDatasColheita, control: controlDatasColheita, register: registerDatasColheita, errors: errorsDatasColheita, handleSubmit: handleSubmitDatasColheita, trigger: triggerDatasColheita, watch: watchDatasColheita } = useForm({
		mode: 'all',
		//resolver: yupResolver(DatasColheitachema)
	})

	const { getValues: getValuesInspecaoEquipamentos, control: controlInspecaoEquipamentos, register: registerInspecaoEquipamentos, errors: errorsInspecaoEquipamentos, handleSubmit: handleSubmitInspecaoEquipamentos, trigger: triggerInspecaoEquipamentos, watch: watchInspecaoEquipamentos } = useForm({
		mode: 'all',
		//resolver: yupResolver(InspecaoEquipamentoschema)
	})

	const { getValues: getValuesLimpezaInstalacoes, control: controlLimpezaInstalacoes, register: registerLimpezaInstalacoes, errors: errorsLimpezaInstalacoes, handleSubmit: handleSubmitLimpezaInstalacoes, trigger: triggerLimpezaInstalacoes, watch: watchLimpezaInstalacoes } = useForm({
		mode: 'all',
		//resolver: yupResolver(LimpezaInstalacoeschema)
	})

	const MODO_VIEW = {
		DADOS_PRINCIPAIS: 1,
		PLANEJAMENTO: 2,
		DESCARTE: 3,
		NOTAS: 4,
	}

	const [modoFluxo, setModoFluxo] = useState<Number>(MODO_VIEW.DADOS_PRINCIPAIS);
	const [cultures, setCultures] = useState<CulturaResult[]>([]);
	const [responsaveis, setResponsaveis] = useState<ResponsavelRegulatorioResult[]>([]);
	const [tecnicoPrincipal, setTecnicoPrincipal] = useState<TecnicoPrincipalResult[]>([]);

	const defaultForm = useForm({
		defaultValues: entity,
		mode: "onSubmit",
	});

	const [gridProps, setGridProps] = useState<any>(null);
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);

	const [enumDestinoSobra, setEnumDestinoSobra] = useState<Enum[]>([]);
	const [enumLocalDescarte, setEnumLocalDescarte] = useState<Enum[]>([]);
	const [enumMetodoDescarte, setEnumMetodoDescarte] = useState<Enum[]>([]);
	const [enumTipoMaterialDescartado, setEnumTipoMaterialDescartado] = useState<Enum[]>([]);
	const [enumTipoColheita, setEnumTipoColheita] = useState<Enum[]>([]);
	const [enumMetodoLimpeza, setEnumMetodoLimpeza] = useState<Enum[]>([]);
	const [enumAtividadesManutencao, setEnumAtividadesManutencao] = useState<Enum[]>([]);

	useEffect(() => {
		const getDataToForm = async () => {
			try {
				const cultures = await api.get<CulturaResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');

				const responsaveis = await api.get<ResponsavelRegulatorioResult[]>('api/v1/autorizacoes-cibio/Destinatario/obterporfiltro?Ativo=true');

				const tecnicos = await api.get<ResponsavelRegulatorioResult[]>('api/v1/autorizacoes-cibio/Destinatario/obterporfiltro?Ativo=true');

				const destinoSobra = await api.get<Enum[]>('api/v1/caderno-conformidade/Enum/destino-sobra');
				const localDescarte = await api.get<Enum[]>('api/v1/caderno-conformidade/Enum/local-descarte');
				const metodoDescarte = await api.get<Enum[]>('api/v1/caderno-conformidade/Enum/metodo-descarte');
				const tipoMaterialDescarte = await api.get<Enum[]>('api/v1/caderno-conformidade/Enum/tipo-material-descarte');
				const tipoColheita = await api.get<Enum[]>('api/v1/caderno-conformidade/Enum/tipo-colheita');
				const metodoLimpeza = await api.get<Enum[]>('api/v1/caderno-conformidade/Enum/metodo-limpeza');
				const atividadesManutencao = await api.get<Enum[]>('api/v1/caderno-conformidade/Enum/atividades-manutencao');

				if (cultures.status === 200) {
					setCultures(cultures.data);
				}

				if (responsaveis.status === 200) {
					setResponsaveis(responsaveis.data);
				}

				if (tecnicos.status === 200) {
					setTecnicoPrincipal(tecnicos.data);
				}

				if (destinoSobra.status === 200) {
					setEnumDestinoSobra(destinoSobra.data);
				}
				if (localDescarte.status === 200) {
					setEnumLocalDescarte(localDescarte.data);
				}
				if (metodoDescarte.status === 200) {
					setEnumMetodoDescarte(metodoDescarte.data);
				}
				if (tipoMaterialDescarte.status === 200) {
					setEnumTipoMaterialDescartado(tipoMaterialDescarte.data);
				}
				if (tipoColheita.status === 200) {
					setEnumTipoColheita(tipoColheita.data);
				}
				if (metodoLimpeza.status === 200) {
					setEnumMetodoLimpeza(metodoLimpeza.data);
				}
				if (atividadesManutencao.status === 200) {
					setEnumAtividadesManutencao(atividadesManutencao.data);
				}

			} catch (error: any) {
				setErrorGetData(error?.response?.data);
			}

			setLoadingGetData(false);
		}

		getDataToForm();
	}, []);

	const gravarDados = (fluxo: any) => {

		let dados: VegetacaoSearchResult =
		{
		};

		if (fluxo === 1) {
			dados =
			{
				id: 0,
				aac_id: 0,
				projeto: entity?.projeto !== defaultForm.getValues('projeto') ? defaultForm.getValues('projeto') : entity?.projeto,
				municipio: entity?.municipio !== defaultForm.getValues('municipio') ? defaultForm.getValues('municipio') : entity?.municipio,
				uf: entity?.uf !== defaultForm.getValues('uf') ? defaultForm.getValues('uf') : entity?.uf,
				cultura: undefined,
				nomecientifico: entity?.nomecientifico !== defaultForm.getValues('nomeCientifico') ? defaultForm.getValues('nomeCientifico') : entity?.nomecientifico,
				numeromapa: entity?.numeromapa !== defaultForm.getValues('numeroMAPA') ? defaultForm.getValues('numeroMAPA') : entity?.numeromapa,
				responsavelRegulatorio: undefined,
				tecnicoPrincipal: undefined,

				copiaAAC: entity?.copiaAAC !== defaultForm.getValues('copiaAAC') ? defaultForm.getValues('copiaAAC') : entity?.copiaAAC,
				copiaListasParticipacao: entity?.copiaListasParticipacao !== defaultForm.getValues('copiaListasParticipacao') ? defaultForm.getValues('copiaListasParticipacao') : entity?.copiaListasParticipacao,
				confirmacaoRecebimentoSementesAMI: entity?.confirmacaoRecebimentoSementesAMI !== defaultForm.getValues('confirmacaoRecebimentoSementesAMI') ? defaultForm.getValues('confirmacaoRecebimentoSementesAMI') : entity?.confirmacaoRecebimentoSementesAMI,
				confirmacaoRecebimentoSementesQuarentena: entity?.confirmacaoRecebimentoSementesQuarentena !== defaultForm.getValues('confirmacaoRecebimentoSementesQuarentena') ? defaultForm.getValues('confirmacaoRecebimentoSementesQuarentena') : entity?.confirmacaoRecebimentoSementesQuarentena,
				copiaUltimoExtratoParecerTecnico: entity?.copiaUltimoExtratoParecerTecnico !== defaultForm.getValues('copiaUltimoExtratoParecerTecnico') ? defaultForm.getValues('copiaUltimoExtratoParecerTecnico') : entity?.copiaUltimoExtratoParecerTecnico,
				croquiExperimental: entity?.croquiExperimental !== defaultForm.getValues('croquiExperimental') ? defaultForm.getValues('croquiExperimental') : entity?.croquiExperimental,
				destruicaoDescarte: entity?.destruicaoDescarte !== defaultForm.getValues('destruicaoDescarte') ? defaultForm.getValues('destruicaoDescarte') : entity?.destruicaoDescarte,
				documentosDestino: entity?.documentosDestino !== defaultForm.getValues('documentosDestino') ? defaultForm.getValues('documentosDestino') : entity?.documentosDestino,
				documentosMateriais: entity?.documentosMateriais !== defaultForm.getValues('documentosMateriais') ? defaultForm.getValues('documentosMateriais') : entity?.documentosMateriais,
				termosFiscalizacao: entity?.termosFiscalizacao !== defaultForm.getValues('termosFiscalizacao') ? defaultForm.getValues('termosFiscalizacao') : entity?.termosFiscalizacao,
				observacoesCopiaAAC: entity?.observacoesCopiaAAC !== defaultForm.getValues('observacoesCopiaAAC') ? defaultForm.getValues('observacoesCopiaAAC') : entity?.observacoesCopiaAAC,
				observacoesCopiaListasParticipacao: entity?.observacoesCopiaListasParticipacao !== defaultForm.getValues('observacoesCopiaListasParticipacao') ? defaultForm.getValues('observacoesCopiaListasParticipacao') : entity?.observacoesCopiaListasParticipacao,
				observacoesConfirmacaoRecebimentoSementesAMI: entity?.observacoesConfirmacaoRecebimentoSementesAMI !== defaultForm.getValues('observacoesConfirmacaoRecebimentoSementesAMI') ? defaultForm.getValues('observacoesConfirmacaoRecebimentoSementesAMI') : entity?.observacoesConfirmacaoRecebimentoSementesAMI,
				observacoesConfirmacaoRecebimentoSementesQuarentena: entity?.observacoesConfirmacaoRecebimentoSementesQuarentena !== defaultForm.getValues('observacoesConfirmacaoRecebimentoSementesQuarentena') ? defaultForm.getValues('observacoesConfirmacaoRecebimentoSementesQuarentena') : entity?.observacoesConfirmacaoRecebimentoSementesQuarentena,
				observacoesCopiaUltimoExtratoParecerTecnico: entity?.observacoesCopiaUltimoExtratoParecerTecnico !== defaultForm.getValues('observacoesCopiaUltimoExtratoParecerTecnico') ? defaultForm.getValues('observacoesCopiaUltimoExtratoParecerTecnico') : entity?.observacoesCopiaUltimoExtratoParecerTecnico,
				observacoesCroquiExperimental: entity?.observacoesCroquiExperimental !== defaultForm.getValues('observacoesCroquiExperimental') ? defaultForm.getValues('observacoesCroquiExperimental') : entity?.observacoesCroquiExperimental,
				observacoesDestruicaoDescarte: entity?.observacoesDestruicaoDescarte !== defaultForm.getValues('observacoesDestruicaoDescarte') ? defaultForm.getValues('observacoesDestruicaoDescarte') : entity?.observacoesDestruicaoDescarte,
				observacoesDocumentosDestino: entity?.observacoesDocumentosDestino !== defaultForm.getValues('observacoesDocumentosDestino') ? defaultForm.getValues('observacoesDocumentosDestino') : entity?.observacoesDocumentosDestino,
				observacoesDocumentosMateriais: entity?.observacoesDocumentosMateriais !== defaultForm.getValues('observacoesDocumentosMateriais') ? defaultForm.getValues('observacoesDocumentosMateriais') : entity?.observacoesDocumentosMateriais,
				observacoesTermosFiscalizacao: entity?.observacoesTermosFiscalizacao !== defaultForm.getValues('observacoesTermosFiscalizacao') ? defaultForm.getValues('observacoesTermosFiscalizacao') : entity?.observacoesTermosFiscalizacao,

				treinamentoBiosseguranca: entity?.treinamentoBiosseguranca ,
				aacAprovadaCibio: entity?.aacAprovadaCibio ,
				documentacaoImportacao: entity?.documentacaoImportacao ,
				permissaoImportacao: entity?.permissaoImportacao ,
				materialIdentificacao: entity?.materialIdentificacao ,
				sementesConferidas: entity?.sementesConferidas ,
				epiTrabalhadores: entity?.epiTrabalhadores,

				dispositivosBiosseguranca: entity?.dispositivosBiosseguranca,
				ensaioConformidade: entity?.ensaioConformidade,
				envelopesDestruidos: entity?.envelopesDestruidos,
				equipamentosVerificados: entity?.equipamentosVerificados,
				identificacaoCasa: entity?.identificacaoCasa,
				observacoesDispositivosBiosseguranca: entity?.observacoesDispositivosBiosseguranca,
				observacoesEnsaioConformidade: entity?.observacoesEnsaioConformidade,
				observacoesEnvelopesDestruidos: entity?.observacoesEnvelopesDestruidos,
				observacoesEquipamentosVerificados: entity?.observacoesEquipamentosVerificados,
				observacoesIdentificacaoCasa: entity?.observacoesIdentificacaoCasa,

				tipoColheita: entity?.tipoColheita,

				embalagemDisponivel: entity?.embalagemDisponivel,
				observacoesEmbalagemDisponivel: entity?.observacoesEmbalagemDisponivel,
				materialIdentificado: entity?.materialIdentificado,
				observacoesMaterialIdentificado: entity?.observacoesMaterialIdentificado,
				restosVegetaisDescartados: entity?.restosVegetaisDescartados,
				observacoesRestosVegetaisDescartados: entity?.observacoesRestosVegetaisDescartados,

				tipoDescarte: entity?.tipoDescarte,
				numeroAmostras: entity?.numeroAmostras ,
				pesoAmostras: entity?.pesoAmostras ,
				localDescarte: entity?.localDescarte ,
				observacoesLocalDescarte: entity?.observacoesLocalDescarte ,
				metodoDescarte: entity?.metodoDescarte ,
				observacoesMetodoDescarte: entity?.observacoesMetodoDescarte,

				informacoesMaterialLiberadoNaoIntencionalmente: entity?.informacoesMaterialLiberadoNaoIntencionalmente ,
				dataMaterialLiberadoNaoIntencionalmente: entity?.dataMaterialLiberadoNaoIntencionalmente ,
				medidasMaterialLiberadoNaoIntencionalmente: entity?.medidasMaterialLiberadoNaoIntencionalmente ,
				responsavelMaterialLiberadoNaoIntencionalmente: entity?.responsavelMaterialLiberadoNaoIntencionalmente ,
				cargoMaterialLiberadoNaoIntencionalmente: entity?.cargoMaterialLiberadoNaoIntencionalmente ,
				dataAvisoMaterialLiberadoNaoIntencionalmente: entity?.dataAvisoMaterialLiberadoNaoIntencionalmente,

				anotacoes: selectedAnotacoes,
			}
		}
		else if (fluxo === 2) {
			dados =
			{
				id: 0,
				aac_id: 0,
				projeto: entity?.projeto,
				municipio: entity?.municipio,
				uf: entity?.uf,
				cultura: undefined,
				nomecientifico: entity?.nomecientifico,
				numeromapa: entity?.numeromapa,
				responsavelRegulatorio: undefined,
				tecnicoPrincipal: undefined,

				copiaAAC: entity?.copiaAAC,
				copiaListasParticipacao: entity?.copiaListasParticipacao,
				confirmacaoRecebimentoSementesAMI: entity?.confirmacaoRecebimentoSementesAMI,
				confirmacaoRecebimentoSementesQuarentena: entity?.confirmacaoRecebimentoSementesQuarentena,
				copiaUltimoExtratoParecerTecnico: entity?.copiaUltimoExtratoParecerTecnico,
				croquiExperimental: entity?.croquiExperimental,
				destruicaoDescarte: entity?.destruicaoDescarte,
				documentosDestino: entity?.documentosDestino,
				documentosMateriais: entity?.documentosMateriais,
				termosFiscalizacao: entity?.termosFiscalizacao,
				observacoesCopiaAAC: entity?.observacoesCopiaAAC,
				observacoesCopiaListasParticipacao: entity?.observacoesCopiaListasParticipacao,
				observacoesConfirmacaoRecebimentoSementesAMI: entity?.observacoesConfirmacaoRecebimentoSementesAMI,
				observacoesConfirmacaoRecebimentoSementesQuarentena: entity?.observacoesConfirmacaoRecebimentoSementesQuarentena,
				observacoesCopiaUltimoExtratoParecerTecnico: entity?.observacoesCopiaUltimoExtratoParecerTecnico,
				observacoesCroquiExperimental: entity?.observacoesCroquiExperimental,
				observacoesDestruicaoDescarte: entity?.observacoesDestruicaoDescarte,
				observacoesDocumentosDestino: entity?.observacoesDocumentosDestino,
				observacoesDocumentosMateriais: entity?.observacoesDocumentosMateriais,
				observacoesTermosFiscalizacao: entity?.observacoesTermosFiscalizacao,

				treinamentoBiosseguranca: entity?.treinamentoBiosseguranca !== defaultForm.getValues('treinamentoBiosseguranca') ? defaultForm.getValues('treinamentoBiosseguranca') : entity?.treinamentoBiosseguranca,
				aacAprovadaCibio: entity?.aacAprovadaCibio !== defaultForm.getValues('aacAprovadaCibio') ? defaultForm.getValues('aacAprovadaCibio') : entity?.aacAprovadaCibio,
				documentacaoImportacao: entity?.documentacaoImportacao !== defaultForm.getValues('documentacaoImportacao') ? defaultForm.getValues('documentacaoImportacao') : entity?.documentacaoImportacao,
				permissaoImportacao: entity?.permissaoImportacao !== defaultForm.getValues('permissaoImportacao') ? defaultForm.getValues('permissaoImportacao') : entity?.permissaoImportacao,
				materialIdentificacao: entity?.materialIdentificacao !== defaultForm.getValues('materialIdentificacao') ? defaultForm.getValues('materialIdentificacao') : entity?.materialIdentificacao,
				sementesConferidas: entity?.sementesConferidas !== defaultForm.getValues('sementesConferidas') ? defaultForm.getValues('sementesConferidas') : entity?.sementesConferidas,
				epiTrabalhadores: entity?.epiTrabalhadores !== defaultForm.getValues('epiTrabalhadores') ? defaultForm.getValues('epiTrabalhadores') : entity?.epiTrabalhadores,

				dispositivosBiosseguranca: entity?.dispositivosBiosseguranca !== defaultForm.getValues('dispositivosBiosseguranca') ? defaultForm.getValues('dispositivosBiosseguranca') : entity?.dispositivosBiosseguranca,
				ensaioConformidade: entity?.ensaioConformidade !== defaultForm.getValues('ensaioConformidade') ? defaultForm.getValues('ensaioConformidade') : entity?.ensaioConformidade,
				envelopesDestruidos: entity?.envelopesDestruidos !== defaultForm.getValues('envelopesDestruidos') ? defaultForm.getValues('envelopesDestruidos') : entity?.envelopesDestruidos,
				equipamentosVerificados: entity?.equipamentosVerificados !== defaultForm.getValues('equipamentosVerificados') ? defaultForm.getValues('equipamentosVerificados') : entity?.equipamentosVerificados,
				identificacaoCasa: entity?.identificacaoCasa !== defaultForm.getValues('identificacaoCasa') ? defaultForm.getValues('identificacaoCasa') : entity?.identificacaoCasa,
				observacoesDispositivosBiosseguranca: entity?.observacoesDispositivosBiosseguranca !== defaultForm.getValues('observacoesDispositivosBiosseguranca') ? defaultForm.getValues('observacoesDispositivosBiosseguranca') : entity?.observacoesDispositivosBiosseguranca,
				observacoesEnsaioConformidade: entity?.observacoesEnsaioConformidade !== defaultForm.getValues('observacoesEnsaioConformidade') ? defaultForm.getValues('observacoesEnsaioConformidade') : entity?.observacoesEnsaioConformidade,
				observacoesEnvelopesDestruidos: entity?.observacoesEnvelopesDestruidos !== defaultForm.getValues('observacoesEnvelopesDestruidos') ? defaultForm.getValues('observacoesEnvelopesDestruidos') : entity?.observacoesEnvelopesDestruidos,
				observacoesEquipamentosVerificados: entity?.observacoesEquipamentosVerificados !== defaultForm.getValues('observacoesEquipamentosVerificados') ? defaultForm.getValues('observacoesEquipamentosVerificados') : entity?.observacoesEquipamentosVerificados,
				observacoesIdentificacaoCasa: entity?.observacoesIdentificacaoCasa !== defaultForm.getValues('observacoesIdentificacaoCasa') ? defaultForm.getValues('observacoesIdentificacaoCasa') : entity?.observacoesIdentificacaoCasa,

				tipoColheita: entity?.tipoColheita !== defaultForm.getValues('tipoColheita') ? defaultForm.getValues('tipoColheita') : entity?.tipoColheita,

				embalagemDisponivel: entity?.embalagemDisponivel !== defaultForm.getValues('embalagemDisponivel') ? defaultForm.getValues('embalagemDisponivel') : entity?.embalagemDisponivel,
				observacoesEmbalagemDisponivel: entity?.observacoesEmbalagemDisponivel !== defaultForm.getValues('observacoesEmbalagemDisponivel') ? defaultForm.getValues('observacoesEmbalagemDisponivel') : entity?.observacoesEmbalagemDisponivel,
				materialIdentificado: entity?.materialIdentificado !== defaultForm.getValues('materialIdentificado') ? defaultForm.getValues('materialIdentificado') : entity?.materialIdentificado,
				observacoesMaterialIdentificado: entity?.observacoesMaterialIdentificado !== defaultForm.getValues('observacoesMaterialIdentificado') ? defaultForm.getValues('observacoesMaterialIdentificado') : entity?.observacoesMaterialIdentificado,
				restosVegetaisDescartados: entity?.restosVegetaisDescartados !== defaultForm.getValues('restosVegetaisDescartados') ? defaultForm.getValues('restosVegetaisDescartados') : entity?.restosVegetaisDescartados,
				observacoesRestosVegetaisDescartados: entity?.observacoesRestosVegetaisDescartados !== defaultForm.getValues('observacoesRestosVegetaisDescartados') ? defaultForm.getValues('observacoesRestosVegetaisDescartados') : entity?.observacoesRestosVegetaisDescartados,

				tipoDescarte: entity?.tipoDescarte,
				numeroAmostras: entity?.numeroAmostras,
				pesoAmostras: entity?.pesoAmostras,
				localDescarte: entity?.localDescarte,
				observacoesLocalDescarte: entity?.observacoesLocalDescarte,
				metodoDescarte: entity?.metodoDescarte,
				observacoesMetodoDescarte: entity?.observacoesMetodoDescarte,

				informacoesMaterialLiberadoNaoIntencionalmente: entity?.informacoesMaterialLiberadoNaoIntencionalmente,
				dataMaterialLiberadoNaoIntencionalmente: entity?.dataMaterialLiberadoNaoIntencionalmente,
				medidasMaterialLiberadoNaoIntencionalmente: entity?.medidasMaterialLiberadoNaoIntencionalmente,
				responsavelMaterialLiberadoNaoIntencionalmente: entity?.responsavelMaterialLiberadoNaoIntencionalmente,
				cargoMaterialLiberadoNaoIntencionalmente: entity?.cargoMaterialLiberadoNaoIntencionalmente,
				dataAvisoMaterialLiberadoNaoIntencionalmente: entity?.dataAvisoMaterialLiberadoNaoIntencionalmente,

				anotacoes: selectedAnotacoes,

			}
		}
		else if (fluxo === 3) {
			dados =
			{
				id: 0,
				aac_id: 0,
				projeto: entity?.projeto,
				municipio: entity?.municipio,
				uf: entity?.uf,
				cultura: undefined,
				nomecientifico: entity?.nomecientifico,
				numeromapa: entity?.numeromapa,
				responsavelRegulatorio: undefined,
				tecnicoPrincipal: undefined,

				copiaAAC: entity?.copiaAAC,
				copiaListasParticipacao: entity?.copiaListasParticipacao,
				confirmacaoRecebimentoSementesAMI: entity?.confirmacaoRecebimentoSementesAMI,
				confirmacaoRecebimentoSementesQuarentena: entity?.confirmacaoRecebimentoSementesQuarentena,
				copiaUltimoExtratoParecerTecnico: entity?.copiaUltimoExtratoParecerTecnico,
				croquiExperimental: entity?.croquiExperimental,
				destruicaoDescarte: entity?.destruicaoDescarte,
				documentosDestino: entity?.documentosDestino,
				documentosMateriais: entity?.documentosMateriais,
				termosFiscalizacao: entity?.termosFiscalizacao,
				observacoesCopiaAAC: entity?.observacoesCopiaAAC,
				observacoesCopiaListasParticipacao: entity?.observacoesCopiaListasParticipacao,
				observacoesConfirmacaoRecebimentoSementesAMI: entity?.observacoesConfirmacaoRecebimentoSementesAMI,
				observacoesConfirmacaoRecebimentoSementesQuarentena: entity?.observacoesConfirmacaoRecebimentoSementesQuarentena,
				observacoesCopiaUltimoExtratoParecerTecnico: entity?.observacoesCopiaUltimoExtratoParecerTecnico,
				observacoesCroquiExperimental: entity?.observacoesCroquiExperimental,
				observacoesDestruicaoDescarte: entity?.observacoesDestruicaoDescarte,
				observacoesDocumentosDestino: entity?.observacoesDocumentosDestino,
				observacoesDocumentosMateriais: entity?.observacoesDocumentosMateriais,
				observacoesTermosFiscalizacao: entity?.observacoesTermosFiscalizacao,

				treinamentoBiosseguranca: entity?.treinamentoBiosseguranca,
				aacAprovadaCibio: entity?.aacAprovadaCibio,
				documentacaoImportacao: entity?.documentacaoImportacao,
				permissaoImportacao: entity?.permissaoImportacao,
				materialIdentificacao: entity?.materialIdentificacao,
				sementesConferidas: entity?.sementesConferidas,
				epiTrabalhadores: entity?.epiTrabalhadores,

				dispositivosBiosseguranca: entity?.dispositivosBiosseguranca,
				ensaioConformidade: entity?.ensaioConformidade,
				envelopesDestruidos: entity?.envelopesDestruidos,
				equipamentosVerificados: entity?.equipamentosVerificados,
				identificacaoCasa: entity?.identificacaoCasa,
				observacoesDispositivosBiosseguranca: entity?.observacoesDispositivosBiosseguranca,
				observacoesEnsaioConformidade: entity?.observacoesEnsaioConformidade,
				observacoesEnvelopesDestruidos: entity?.observacoesEnvelopesDestruidos,
				observacoesEquipamentosVerificados: entity?.observacoesEquipamentosVerificados,
				observacoesIdentificacaoCasa: entity?.observacoesIdentificacaoCasa,

				tipoColheita: entity?.tipoColheita,

				embalagemDisponivel: entity?.embalagemDisponivel,
				observacoesEmbalagemDisponivel: entity?.observacoesEmbalagemDisponivel,
				materialIdentificado: entity?.materialIdentificado,
				observacoesMaterialIdentificado: entity?.observacoesMaterialIdentificado,
				restosVegetaisDescartados: entity?.restosVegetaisDescartados,
				observacoesRestosVegetaisDescartados: entity?.observacoesRestosVegetaisDescartados,

				tipoDescarte: entity?.tipoDescarte !== defaultForm.getValues('tipoDescarte') ? defaultForm.getValues('tipoDescarte') : entity?.tipoDescarte,
				numeroAmostras: entity?.numeroAmostras !== defaultForm.getValues('numeroAmostras') ? defaultForm.getValues('numeroAmostras') : entity?.numeroAmostras,
				pesoAmostras: entity?.pesoAmostras !== defaultForm.getValues('pesoAmostras') ? defaultForm.getValues('pesoAmostras') : entity?.pesoAmostras,
				localDescarte: entity?.localDescarte !== defaultForm.getValues('localDescarte') ? defaultForm.getValues('localDescarte') : entity?.localDescarte,
				observacoesLocalDescarte: entity?.observacoesLocalDescarte !== defaultForm.getValues('observacoesLocalDescarte') ? defaultForm.getValues('observacoesLocalDescarte') : entity?.observacoesLocalDescarte,
				metodoDescarte: entity?.metodoDescarte !== defaultForm.getValues('metodoDescarte') ? defaultForm.getValues('metodoDescarte') : entity?.metodoDescarte,
				observacoesMetodoDescarte: entity?.observacoesMetodoDescarte !== defaultForm.getValues('observacoesMetodoDescarte') ? defaultForm.getValues('observacoesMetodoDescarte') : entity?.observacoesMetodoDescarte,

				informacoesMaterialLiberadoNaoIntencionalmente: entity?.informacoesMaterialLiberadoNaoIntencionalmente,
				dataMaterialLiberadoNaoIntencionalmente: entity?.dataMaterialLiberadoNaoIntencionalmente,
				medidasMaterialLiberadoNaoIntencionalmente: entity?.medidasMaterialLiberadoNaoIntencionalmente,
				responsavelMaterialLiberadoNaoIntencionalmente: entity?.responsavelMaterialLiberadoNaoIntencionalmente,
				cargoMaterialLiberadoNaoIntencionalmente: entity?.cargoMaterialLiberadoNaoIntencionalmente,
				dataAvisoMaterialLiberadoNaoIntencionalmente: entity?.dataAvisoMaterialLiberadoNaoIntencionalmente,

				anotacoes: selectedAnotacoes,

			}
		}
		else if (fluxo === 4) {
			dados =
			{
				id: 0,
				aac_id: 0,
				projeto: entity?.projeto,
				municipio: entity?.municipio,
				uf: entity?.uf,
				cultura: undefined,
				nomecientifico: entity?.nomecientifico,
				numeromapa: entity?.numeromapa,
				responsavelRegulatorio: undefined,
				tecnicoPrincipal: undefined,

				copiaAAC: entity?.copiaAAC,
				copiaListasParticipacao: entity?.copiaListasParticipacao,
				confirmacaoRecebimentoSementesAMI: entity?.confirmacaoRecebimentoSementesAMI,
				confirmacaoRecebimentoSementesQuarentena: entity?.confirmacaoRecebimentoSementesQuarentena,
				copiaUltimoExtratoParecerTecnico: entity?.copiaUltimoExtratoParecerTecnico,
				croquiExperimental: entity?.croquiExperimental,
				destruicaoDescarte: entity?.destruicaoDescarte,
				documentosDestino: entity?.documentosDestino,
				documentosMateriais: entity?.documentosMateriais,
				termosFiscalizacao: entity?.termosFiscalizacao,
				observacoesCopiaAAC: entity?.observacoesCopiaAAC,
				observacoesCopiaListasParticipacao: entity?.observacoesCopiaListasParticipacao,
				observacoesConfirmacaoRecebimentoSementesAMI: entity?.observacoesConfirmacaoRecebimentoSementesAMI,
				observacoesConfirmacaoRecebimentoSementesQuarentena: entity?.observacoesConfirmacaoRecebimentoSementesQuarentena,
				observacoesCopiaUltimoExtratoParecerTecnico: entity?.observacoesCopiaUltimoExtratoParecerTecnico,
				observacoesCroquiExperimental: entity?.observacoesCroquiExperimental,
				observacoesDestruicaoDescarte: entity?.observacoesDestruicaoDescarte,
				observacoesDocumentosDestino: entity?.observacoesDocumentosDestino,
				observacoesDocumentosMateriais: entity?.observacoesDocumentosMateriais,
				observacoesTermosFiscalizacao: entity?.observacoesTermosFiscalizacao,

				treinamentoBiosseguranca: entity?.treinamentoBiosseguranca,
				aacAprovadaCibio: entity?.aacAprovadaCibio,
				documentacaoImportacao: entity?.documentacaoImportacao,
				permissaoImportacao: entity?.permissaoImportacao,
				materialIdentificacao: entity?.materialIdentificacao,
				sementesConferidas: entity?.sementesConferidas,
				epiTrabalhadores: entity?.epiTrabalhadores,

				dispositivosBiosseguranca: entity?.dispositivosBiosseguranca,
				ensaioConformidade: entity?.ensaioConformidade,
				envelopesDestruidos: entity?.envelopesDestruidos,
				equipamentosVerificados: entity?.equipamentosVerificados,
				identificacaoCasa: entity?.identificacaoCasa,
				observacoesDispositivosBiosseguranca: entity?.observacoesDispositivosBiosseguranca,
				observacoesEnsaioConformidade: entity?.observacoesEnsaioConformidade,
				observacoesEnvelopesDestruidos: entity?.observacoesEnvelopesDestruidos,
				observacoesEquipamentosVerificados: entity?.observacoesEquipamentosVerificados,
				observacoesIdentificacaoCasa: entity?.observacoesIdentificacaoCasa,

				tipoColheita: entity?.tipoColheita,

				embalagemDisponivel: entity?.embalagemDisponivel,
				observacoesEmbalagemDisponivel: entity?.observacoesEmbalagemDisponivel,
				materialIdentificado: entity?.materialIdentificado,
				observacoesMaterialIdentificado: entity?.observacoesMaterialIdentificado,
				restosVegetaisDescartados: entity?.restosVegetaisDescartados,
				observacoesRestosVegetaisDescartados: entity?.observacoesRestosVegetaisDescartados,

				tipoDescarte: entity?.tipoDescarte,
				numeroAmostras: entity?.numeroAmostras,
				pesoAmostras: entity?.pesoAmostras,
				localDescarte: entity?.localDescarte,
				observacoesLocalDescarte: entity?.observacoesLocalDescarte,
				metodoDescarte: entity?.metodoDescarte,
				observacoesMetodoDescarte: entity?.observacoesMetodoDescarte,

				informacoesMaterialLiberadoNaoIntencionalmente: entity?.informacoesMaterialLiberadoNaoIntencionalmente !== defaultForm.getValues('informacoesMaterialLiberadoNaoIntencionalmente') ? defaultForm.getValues('informacoesMaterialLiberadoNaoIntencionalmente') : entity?.informacoesMaterialLiberadoNaoIntencionalmente, 
				dataMaterialLiberadoNaoIntencionalmente: entity?.dataMaterialLiberadoNaoIntencionalmente !== defaultForm.getValues('dataMaterialLiberadoNaoIntencionalmente') ? defaultForm.getValues('dataMaterialLiberadoNaoIntencionalmente') : entity?.dataMaterialLiberadoNaoIntencionalmente,
				medidasMaterialLiberadoNaoIntencionalmente: entity?.medidasMaterialLiberadoNaoIntencionalmente !== defaultForm.getValues('medidasMaterialLiberadoNaoIntencionalmente') ? defaultForm.getValues('medidasMaterialLiberadoNaoIntencionalmente') : entity?.medidasMaterialLiberadoNaoIntencionalmente,
				responsavelMaterialLiberadoNaoIntencionalmente: entity?.responsavelMaterialLiberadoNaoIntencionalmente !== defaultForm.getValues('responsavelMaterialLiberadoNaoIntencionalmente') ? defaultForm.getValues('responsavelMaterialLiberadoNaoIntencionalmente') : entity?.responsavelMaterialLiberadoNaoIntencionalmente,
				cargoMaterialLiberadoNaoIntencionalmente: entity?.cargoMaterialLiberadoNaoIntencionalmente !== defaultForm.getValues('cargoMaterialLiberadoNaoIntencionalmente') ? defaultForm.getValues('cargoMaterialLiberadoNaoIntencionalmente') : entity?.cargoMaterialLiberadoNaoIntencionalmente,
				dataAvisoMaterialLiberadoNaoIntencionalmente: entity?.dataAvisoMaterialLiberadoNaoIntencionalmente !== defaultForm.getValues('dataAvisoMaterialLiberadoNaoIntencionalmente') ? defaultForm.getValues('dataAvisoMaterialLiberadoNaoIntencionalmente') : entity?.dataAvisoMaterialLiberadoNaoIntencionalmente,

				anotacoes: selectedAnotacoes,

			}
		}

		setEntity(dados);

		return dados;
	
	};

	const onSubmitDraft = async () => {
		//setErrorDraft(null);
		//setLoadingDraft(true);

		try {
		//	const VegetacaoToSubmitDraft = getValuesToSubmit();
		//	const { status, data } = await api.post('api/v1/autorizacoes-cibio/MovimentacaoInterna/rascunho', amiToSubmitDraft);

		//	if (status === 201) {
		//		setSuccessDraft(true);
		//		window.scrollTo({ top: 0, behavior: 'smooth' });
		//		setTimeout(() => {
		//			history.push('/autorizacoes/editar/ami', { id: data.id });
		//		}, 3000);
		//	}
		} catch (error: any) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		//	setErrorDraft(error.response.data);
		}

	//	setLoadingDraft(false);
	}

	const actionsAnotacoes = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {

				setAnotacaoEdit(props.data);
				setContagemAnotacao(props.data.contagemAnotacao * 1);

				setGridProps(props.node);

				setModalAnotacoes(true)
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedAnotacoes([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedAnotacoes([...arr]);
			}} />
		</Fragment>
	)

	const onSubmitAnotacoes = (info: VegetacaoAnotacoes): void => {

		setSelectedAnotacoes([]);

		let indice = 0;
		const arr = selectedAnotacoes.filter(e => e.contagemAnotacao != contagemAnotacao);

		for (let i = 0, t = arr.length; i < t; i++)
		{
			indice = arr[i].contagemAnotacao * 1;
		}
		setSelectedAnotacoes([...arr, {
				id: 0,
				responsavelAnotacao: decoded.unique_name,
				dataAnotacao: moment().toDate(),
				anotacao: info.anotacao,
				contagemAnotacao: contagemAnotacao == 0 ? indice + 1 : contagemAnotacao,
				}]);

		setModalAnotacoes(false);
	}

	const actionsPlantios = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {

				setPlantioEdit(props.data);

				setGridProps(props.node);

				setModalPlantios(true)
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedPlantios([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedPlantios([...arr]);
			}} />
		</Fragment>
	)

	const onSubmitPlantios = (info: VegetacaoPlantios): void => {

		setSelectedPlantios([]);

		const arr = selectedPlantios.filter(e => e.numeroPlantio != info.numeroPlantio);

		setSelectedPlantios([...arr, {
			id: 0,
			datasPlantio: '',
			numeroPlantio: info.numeroPlantio,
			modulo: info.modulo,
			material: info.material,
			informacoesAdicionaisMaterial: info.informacoesAdicionaisMaterial,
			numeroAMI: info.numeroAMI,
			sobraMaterial: info.sobraMaterial,
			numeroAmostrasRestantes: info.numeroAmostrasRestantes,
			quantidadeAmostrasRestantes: info.quantidadeAmostrasRestantes,
			destinoSobra: info.destinoSobra,
			entradaArmazenamento: info.entradaArmazenamento,
			informacoesAdicionais: info.informacoesAdicionais,
		}]);

		setModalPlantios(false);
	}

	const actionsLimpezaDestruicao = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {

				setLimpezaDestruicaoEdit(props.data);

				setGridProps(props.node);

				setModalLimpezaDestruicao(true)
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedLimpezaDestruicao([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedLimpezaDestruicao([...arr]);
			}} />
		</Fragment>
	)

	const onSubmitLimpezaDestruicao = (info: VegetacaoLimpezaDestruicao): void => {

		setSelectedLimpezaDestruicao([]);

		const arr = selectedLimpezaDestruicao.filter(e => e.id != info.id);

		setSelectedLimpezaDestruicao([...arr, {
			id: 0,
			maquinario: info.maquinario,
			dataAntesUso: moment(info.dataAntesUso).format('DD/MM/YYYY'),
			dataAposUso: moment(info.dataAposUso).format('DD/MM/YYYY'),
			metodoLimpeza: info.metodoLimpeza,
			responsavel: info.responsavel,
		}]);

		setModalLimpezaDestruicao(false);
	}

	const actionsDatasColheita = (props: any) => (
		<Fragment>
			<DeleteButton onClick={() => {
				setSelectedDatasColheita([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedDatasColheita([...arr]);
			}} />
		</Fragment>
	)

	const onSubmitDatasColheita = (info: VegetacaoDataColheita): void => {

		setSelectedDatasColheita([...selectedDatasColheita, {
			id: 0,
			dataColheita: moment(info.dataColheita).toDate(),
		}]);

		setModalDatasColheita(false);
	}

	const actionsInspecaoEquipamentos = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {

				setInspecaoEquipamentosEdit(props.data);
				setContagemInspecaoEquipamento(props.data.contagemInspecaoEquipamento * 1);

				setGridProps(props.node);

				setModalInspecaoEquipamentos(true)
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedInspecaoEquipamentos([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedInspecaoEquipamentos([...arr]);
			}} />
		</Fragment>
	)

	const onSubmitInspecaoEquipamentos = (info: VegetacaoInspecaoEquipamentos): void => {

		setSelectedInspecaoEquipamentos([]);

		let indice = 0;
		const arr = selectedInspecaoEquipamentos.filter(e => e.contagemInspecaoEquipamento != contagemInspecaoEquipamento);

		for (let i = 0, t = arr.length; i < t; i++) {
			indice = arr[i].contagemInspecaoEquipamento * 1;
		}
		setSelectedInspecaoEquipamentos([...arr, {
			id: 0,
			contagemInspecaoEquipamento: contagemInspecaoEquipamento == 0 ? indice + 1 : contagemInspecaoEquipamento,
			maquinarioEquipamento: info.maquinarioEquipamento,
			idEquipamento: info.idEquipamento,
			dataEquipamentoAntesUso: moment(info.dataEquipamentoAntesUso).toDate(),
			dataEquipamentoAposUso: moment(info.dataEquipamentoAposUso).toDate(),
			metodoLimpezaEquipamento: info.metodoLimpezaEquipamento,
			faseEnsaioEquipamento: info.faseEnsaioEquipamento,
		}]);

		setModalInspecaoEquipamentos(false);
	}

	const actionsLimpezaInstalacoes = (props: any) => (
		<Fragment>
			<EditButton onClick={() => {

				setLimpezaInstalacoesEdit(props.data);
				setContagemLimpezaInstalacao(props.data.contagemLimpezaInstalacao * 1);

				setGridProps(props.node);

				setModalLimpezaInstalacoes(true)
			}} />
			&nbsp;&nbsp;
			<DeleteButton onClick={() => {
				setSelectedLimpezaInstalacoes([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedLimpezaInstalacoes([...arr]);
			}} />
		</Fragment>
	)

	const onSubmitLimpezaInstalacoes = (info: VegetacaoLimpezaInstalacoes): void => {

		setSelectedLimpezaInstalacoes([]);

		let indice = 0;
		const arr = selectedLimpezaInstalacoes.filter(e => e.contagemLimpezaInstalacao != contagemLimpezaInstalacao);

		for (let i = 0, t = arr.length; i < t; i++) {
			indice = arr[i].contagemLimpezaInstalacao * 1;
		}
		setSelectedLimpezaInstalacoes([...arr, {
			id: 0,
			contagemLimpezaInstalacao: contagemLimpezaInstalacao == 0 ? indice + 1 : contagemLimpezaInstalacao,
			responsavelLimpezaInstalacao: info.responsavelLimpezaInstalacao,
			dataLimpezaInstalacao: moment(info.dataLimpezaInstalacao).format('DD/MM/YYYY'),
			casaVegetacaoLimpezaInstalacao: info.casaVegetacaoLimpezaInstalacao,
		}]);

		setModalLimpezaInstalacoes(false);
	}

	let BotoesFases =
		<div className="button-position tab-buttons-local">
			<Button
				title="Dados Principais/Documentos"
				contained={false}
				className={modoFluxo === MODO_VIEW.DADOS_PRINCIPAIS ? "active" : ''}
				type="button"
				onClick={() => {
					gravarDados(modoFluxo);
					setModoFluxo(MODO_VIEW.DADOS_PRINCIPAIS);
				}}
			/>

			<Button
				title="Planejamento/Condução"
				className={modoFluxo === MODO_VIEW.PLANEJAMENTO ? "active" : ''}
				contained={false}
				type="button"
				onClick={() => {
					gravarDados(modoFluxo);
					setModoFluxo(MODO_VIEW.PLANEJAMENTO);
				}}
			/>

			<Button
				title="Destruição/Descarte"
				className={modoFluxo === MODO_VIEW.DESCARTE ? "active" : ''}
				contained={false}
				type="button"
				onClick={() => {
					gravarDados(modoFluxo);
					setModoFluxo(MODO_VIEW.DESCARTE);
				}}
			/>

			<Button
				title="Reportes/Anotações"
				className={modoFluxo === MODO_VIEW.NOTAS ? "active" : ''}
				contained={false}
				type="button"
				onClick={() => {
					gravarDados(modoFluxo);
					setModoFluxo(MODO_VIEW.NOTAS);
				}}
			/>
		</div>;


	return <div className="container-custom-search">
		<h3>{STRINGS_CADERNOCONFORMIDADE.REGISTER_CADERNO_CONFORMIDADE_VEGETACAO}</h3>
		{/*<Form onSubmit={defaultForm.handleSubmit(submitForm_salvar)}>*/}
		<Form>
			{BotoesFases}
			<If condition={modoFluxo === MODO_VIEW.DADOS_PRINCIPAIS}>
				<Fieldset disabled={false} title={STRINGS_CADERNOCONFORMIDADE.BLOCK_ONE}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={5}>
							<Form.Label>Projeto</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="projeto"
								placeholder="Insira o nome do projeto"
								maxLength={100}
								defaultValue={entity?.projeto}
							/>
						</Form.Group>
						<Form.Group as={Col} md={5}>
							<Form.Label>Municipio</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="municipio"
								placeholder="Insira o município"
								maxLength={100}
								defaultValue={entity?.municipio}

							/>
						</Form.Group>
						<Form.Group as={Col} md={2}>
							<Form.Label>UF</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="uf"
								placeholder="Insira a UF"
								maxLength={30}
								defaultValue={entity?.uf}

							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Cultura</Form.Label>
							<Controller
								control={defaultForm.control}
								name="cultura"
								required

								isInvalid={defaultForm.errors.cultura !== undefined}
								as={<Select
									options={cultures.map(culture => Object.assign({ label: culture.nome, value: culture.id }))}
									placeholder="Selecione uma cultura/organismo"
									isClearable
								/>}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>Nome Científico</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="nomeCientifico"
								placeholder="Insira o nome científico da cultura"
								maxLength={100}
								defaultValue={entity?.nomecientifico}

							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>AAC</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="aac"
								placeholder="Insira o código da AAC"
								maxLength={100}
								defaultValue={entity?.aac_id}

							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>Nº MAPA</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="numeroMAPA"
								placeholder="Insira o número do MAPA"
								maxLength={100}
								defaultValue={entity?.numeromapa}

							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Responsável Regulatório</Form.Label>
							<Controller
								control={defaultForm.control}
								name="responsavelRegulatorio"
								required

								isInvalid={defaultForm.errors.responsavelRegulatorio !== undefined}
								as={<Select
									options={responsaveis.map(responsavel => Object.assign({ label: responsavel.nome, value: responsavel.id }))}
									placeholder="Selecione um responsável do Regulatório"
									isClearable
								/>}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>Técnico Principal</Form.Label>
							<Controller
								control={defaultForm.control}
								name="tecnicoPrincipal"
								required

								isInvalid={defaultForm.errors.tecnicoPrincipal !== undefined}
								as={<Select
									options={tecnicoPrincipal.map(tecnico => Object.assign({ label: tecnico.nome, value: tecnico.id }))}
									placeholder="Selecione um técnico principal"
									isClearable
								/>}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset disabled={false} title={STRINGS_CADERNOCONFORMIDADE.BLOCK_TWO}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="copiaAAC"
								label="Existe cópia da AAC?"
								defaultChecked={entity?.copiaAAC ? entity?.copiaAAC : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesCopiaAAC"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesCopiaAAC}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="copiaListasParticipacao"
								label="Existem cópias das Listas de Participação em Treinamentos?"
								defaultChecked={entity?.copiaListasParticipacao ? entity?.copiaListasParticipacao : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesCopiaListasParticipacao"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesCopiaListasParticipacao}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="copiaUltimoExtratoParecerTecnico"
								label="Existe cópia do último Extrato de Parecer Técnico da CTNBio concedendo CQB para Casa-de-Vegetação?"
								defaultChecked={entity?.copiaUltimoExtratoParecerTecnico ? entity?.copiaUltimoExtratoParecerTecnico : false}

							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesCopiaUltimoExtratoParecerTecnico"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesCopiaUltimoExtratoParecerTecnico}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="confirmacaoRecebimentoSementesQuarentena"
								label="Existe termo de liberação de quarentena de confirmação de recebimento de sementes?"
								defaultChecked={entity?.confirmacaoRecebimentoSementesQuarentena ? entity?.confirmacaoRecebimentoSementesQuarentena : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesConfirmacaoRecebimentoSementesQuarentena"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesConfirmacaoRecebimentoSementesQuarentena}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="confirmacaoRecebimentoSementesAMI"
								label="Existe AMI e/ou declaração de transferência de amostras entre CIBios de confirmação de recebimento de sementes?"
								defaultChecked={entity?.confirmacaoRecebimentoSementesAMI ? entity?.confirmacaoRecebimentoSementesAMI : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesConfirmacaoRecebimentoSementesAMI"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesConfirmacaoRecebimentoSementesAMI}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="croquiExperimental"
								label="Existe croqui experimental do ensaio (Plot design map) dos diferentes módulos?"
								defaultChecked={entity?.croquiExperimental ? entity?.croquiExperimental : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesCroquiExperimental"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesCroquiExperimental}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="documentosDestino"
								label="Existem documentos que comprovam o destino do material colhido?"
								defaultChecked={entity?.documentosDestino ? entity?.documentosDestino : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesDocumentosDestino"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesDocumentosDestino}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="documentosMateriais"
								label="Existem documentos que comprovam materiais amostrados?"
								defaultChecked={entity?.documentosMateriais ? entity?.documentosMateriais : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesDocumentosMateriais"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesDocumentosMateriais}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="destruicaoDescarte"
								label="Existem formulários de destruição e descarte?"
								defaultChecked={entity?.destruicaoDescarte ? entity?.destruicaoDescarte : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesDestruicaoDescarte"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesDestruicaoDescarte}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="termosFiscalizacao"
								label="Existem cópias de todos os Termos de Fiscalização?"
								defaultChecked={entity?.termosFiscalizacao ? entity?.termosFiscalizacao : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesTermosFiscalizacao"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesTermosFiscalizacao}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
			</If>

			<If condition={modoFluxo === MODO_VIEW.PLANEJAMENTO}>
				<Fieldset disabled={false} title={STRINGS_CADERNOCONFORMIDADE.BLOCK_THREE}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="treinamentoBiosseguranca"
								label="Todo pessoal envolvido nas atividades de casa de vegetação recebeu treinamento em Biossegurança?"
								defaultChecked={entity?.treinamentoBiosseguranca ? entity?.treinamentoBiosseguranca : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="aacAprovadaCibio"
								label="A AAC foi aprovada pela CIBio da BASF previamente à instalação do ensaio?"
								defaultChecked={entity?.aacAprovadaCibio ? entity?.aacAprovadaCibio : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="documentacaoImportacao"
								label="Em caso de material importado, a documentação de Importação (Termo de Liberação Quarentena) se encontra disponível?"
								defaultChecked={entity?.documentacaoImportacao ? entity?.documentacaoImportacao : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="permissaoImportacao"
								label="A casa de vegetação ou módulo dedicado ao ensaio está de acordo com o informado na AI (Autorização de Importação) para obtenção da Permissão de Importação?"
								defaultChecked={entity?.permissaoImportacao ? entity?.permissaoImportacao : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="materialIdentificacao"
								label="Todo material de identificação e proteção do ensaio, necessários para cumprimento dos requisitos de biossegurança, estão disponíveis?"
								defaultChecked={entity?.materialIdentificacao ? entity?.materialIdentificacao : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="sementesConferidas"
								label="As sementes recebidas para o plantio foram conferidas de acordo com o mapa de plantio?"
								defaultChecked={entity?.sementesConferidas ? entity?.sementesConferidas : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="epiTrabalhadores"
								label="Existem EPIs (Equipamento de Proteção Individual) disponíveis para todos os trabalhadores?"
								defaultChecked={entity?.epiTrabalhadores ? entity?.epiTrabalhadores : false}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset disabled={false} title={STRINGS_CADERNOCONFORMIDADE.BLOCK_FOUR}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Formulário de Notificação de Plantio
								<br />
								<br />
								<AddButton
									type="button"
									onClick={() => setModalPlantios(true) }
								/>
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={selectedPlantios.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedPlantios}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsPlantios,
								}}
							>
								<AgGridColumn
									headerName='Número do Plantio'
									field="numeroPlantio"
									cellStyle={{
										justifyContent: 'left'
									}}
								/>
								<AgGridColumn
									headerName='Casa de Vegetação/Módulos'
									field="modulo"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
								/>
								<AgGridColumn
									headerName="Ações"
									cellRenderer="actions"
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="ensaioConformidade"
								label="O ensaio foi instalado na casa de vegetação em conformidade com AAC aprovada pela CIBio?"
								defaultChecked={entity?.ensaioConformidade ? entity?.ensaioConformidade : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesEnsaioConformidade"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesEnsaioConformidade}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="identificacaoCasa"
								label="A casa de vegetação está identificada com a placa de biossegurança informando a classe de risco, símbolo de risco biológico, nome e contato do técnico principal?"
								defaultChecked={entity?.identificacaoCasa ? entity?.identificacaoCasa : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesIdentificacaoCasa"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesIdentificacaoCasa}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="envelopesDestruidos"
								label="Os envelopes de sementes e outros materiais remanescentes do plantio do ensaio foram destruídos e o(s) e os registros foram realizados nos formulários de Destruição e Descarte?"
								defaultChecked={entity?.envelopesDestruidos ? entity?.envelopesDestruidos : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesEnvelopesDestruidos"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesEnvelopesDestruidos}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="dispositivosBiosseguranca"
								label="Os dispositivos de biossegurança como, tela de proteção de ralos, controle de insetos e roedores, telas antiafídicas, além de outros métodos de controle da entrada de polinizadores e dispersão de pólen foram checados e estão em perfeitas condições?"
								defaultChecked={entity?.dispositivosBiosseguranca ? entity?.dispositivosBiosseguranca : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesDispositivosBiosseguranca"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesDispositivosBiosseguranca}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="equipamentosVerificados"
								label="Roupas, calçados e utensílios e equipamentos utilizados pela equipe na atividade de plantio foram limpos e inspecionados ANTES de entrar e ANTES DE SAIR das instalações? No caso de equipamentos e utensílios o registro da limpeza foi realizado no GCN?"
								defaultChecked={entity?.equipamentosVerificados ? entity?.equipamentosVerificados : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesEquipamentosVerificados"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesEquipamentosVerificados}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Tipo de colheita</Form.Label>
							<Controller
								control={defaultForm.control}
								name="tipoColheita"
								required

								isInvalid={defaultForm.errors.tipoColheita !== undefined}
								as={<Select
									options={enumTipoColheita.map(tipoColheita => Object.assign({ value: tipoColheita.key, label: tipoColheita.value }))}
									placeholder="Selecione um tipo de colheita"
									isClearable
								/>}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Informações da Colheita
								<br />
								<br />
								<AddButton
									type="button"
									onClick={() => setModalDatasColheita(true)}
								/>
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={selectedDatasColheita.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedDatasColheita}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsDatasColheita,
								}}
							>
								<AgGridColumn
									headerName='Data'
									field="dataColheita"
									cellStyle={{
										justifyContent: 'left'
									}}
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => data.dataColheita ? _generateDate(data.dataColheita, 'Pt') : ''}
								/>
								<AgGridColumn
									headerName="Ações"
									cellRenderer="actions"
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="embalagemDisponivel"
								label="Há embalagem disponível para o armazenamento dos materiais a serem colhidos (sacos, envelopes, etiquetas, etc.)?"
								defaultChecked={entity?.embalagemDisponivel ? entity?.embalagemDisponivel : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesEmbalagemDisponivel"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesEmbalagemDisponivel}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="materialIdentificado"
								label="O material colhido está embalado e identificado?"
								defaultChecked={entity?.materialIdentificado ? entity?.materialIdentificado : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesMaterialIdentificado"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesMaterialIdentificado}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Check
								type="checkbox"
								ref={defaultForm.register}
								name="restosVegetaisDescartados"
								label="Restos vegetais proveniente da colheita e limpeza das instalações foram devidamente descartados após a atividade de colheita? Os registros foram realizados no GCN?"
								defaultChecked={entity?.restosVegetaisDescartados ? entity?.restosVegetaisDescartados : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesRestosVegetaisDescartados"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesRestosVegetaisDescartados}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Formulário de Inspeção de Equipamentos após a Limpeza
								<br />
								<br />
								<AddButton
									type="button"
									onClick={() => {
										setContagemInspecaoEquipamento(0);
										setModalInspecaoEquipamentos(true);
										}
									}
								/>
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={selectedInspecaoEquipamentos.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedInspecaoEquipamentos}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsInspecaoEquipamentos,
								}}
							>
								<AgGridColumn
									headerName='#'
									field="contagemEquipamento"
									cellStyle={{
										justifyContent: 'left'
									}}
								/>
								<AgGridColumn
									headerName='Maquinário'
									field="maquinarioEquipamento"
									cellStyle={{
										justifyContent: 'left'
									}}
								/>
								<AgGridColumn
									headerName='ID'
									field="idEquipamento"
									cellStyle={{
										justifyContent: 'left'
									}}
								/>
								<AgGridColumn
									headerName='Data Antes Uso'
									field="dataEquipamentoAntesUso"
									cellStyle={{
										justifyContent: 'left'
									}}
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => data.dataEquipamentoAntesUso ? _generateDate(data.dataEquipamentoAntesUso, 'Pt') : ''}

								/>
								<AgGridColumn
									headerName='Data Após Uso'
									field="dataEquipamentoAposUso"
									cellStyle={{
										justifyContent: 'left'
									}}
									valueFormatter={({ data }: any) => data.dataEquipamentoAposUso ? _generateDate(data.dataEquipamentoAposUso, 'Pt') : ''}
								/>
								<AgGridColumn
									headerName="Ações"
									cellRenderer="actions"
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>	
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Formulário de registro de limpeza das Instalações
								<br />
								<br />
								<AddButton
									type="button"
									onClick={() => {
										setContagemLimpezaInstalacao(0);
										setModalLimpezaInstalacoes(true);
									}
									}
								/>
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={selectedLimpezaInstalacoes.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedLimpezaInstalacoes}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsLimpezaInstalacoes,
								}}
							>
								<AgGridColumn
									headerName='#'
									field="contagemLimpezaInstalacao"
									cellStyle={{
										justifyContent: 'left'
									}}
								/>
								<AgGridColumn
									headerName='Data'
									field="dataLimpezaInstalacao"
									cellStyle={{
										justifyContent: 'left'
									}}
									headerClass="myfunc-default-header"
									valueFormatter={({ data }: any) => data.dataLimpezaInstalacao ? _generateDate(data.dataLimpezaInstalacao, 'Pt') : ''}
								/>
								<AgGridColumn
									headerName='Responsavel'
									field="responsavelLimpezaInstalacao"
									cellStyle={{
										justifyContent: 'left'
									}}
								/>
								<AgGridColumn
									headerName="Ações"
									cellRenderer="actions"
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>	
				</Fieldset>
			</If>
	
			<If condition={modoFluxo === MODO_VIEW.DESCARTE}>
				<Fieldset disabled={false} title={STRINGS_CADERNOCONFORMIDADE.BLOCK_FIVE}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Tipo de material destruído/descartado</Form.Label>
							<Controller
								control={defaultForm.control}
								name="tipoDescarte"
								required

								isInvalid={defaultForm.errors.tipoDescarte !== undefined}
								as={<Select
									placeholder="Selecione um tipo de material de descarte"
									options={enumTipoMaterialDescartado.map(tipoMaterialDescartado => Object.assign({ value: tipoMaterialDescartado.key, label: tipoMaterialDescartado.value }))}
									isClearable
								/>}
							/>
						</Form.Group>
						<Form.Group as={Col} md={3}>
							<Form.Label>Número de Amostras</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="numeroAmostras"
								placeholder="Insira o total de amostras"
								maxLength={100}
								defaultValue={entity?.numeroAmostras}

							/>
						</Form.Group>
						<Form.Group as={Col} md={3}>
							<Form.Label>Peso TOTAL das amostras (Kg)</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="pesoAmostras"
								placeholder="Insira o peso total em quilos de amostras"
								maxLength={100}
								defaultValue={entity?.pesoAmostras}

							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Local da destruição/descarte:</Form.Label>
							<Controller
								control={defaultForm.control}
								name="localDescarte"
								required

								isInvalid={defaultForm.errors.localDescarte !== undefined}
								as={<Select
									placeholder="Selecione um local de descarte"
									options={enumLocalDescarte.map(localDescarte => Object.assign({ value: localDescarte.key, label: localDescarte.value }))}
									isClearable
								/>}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesLocalDescarte"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesLocalDescarte}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Método de destruição</Form.Label>
							<Controller
								control={defaultForm.control}
								name="metodoDescarte"
								required

								isInvalid={defaultForm.errors.metodoDescarte !== undefined}
								as={<Select
									placeholder="Selecione um método de descarte"
									options={enumMetodoDescarte.map(metodoDescarte => Object.assign({ value: metodoDescarte.key, label: metodoDescarte.value }))}
									isClearable
								/>}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>Observações</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="observacoesMetodoDescarte"
								as="textarea"
								rows={4}
								placeholder="Insira as observações, se pertinente"
								defaultValue={entity?.observacoesMetodoDescarte}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Formulário de limpeza de maquinários/equipamentos utilizados na destruição e descarte
								<br />
								<br />
								<AddButton
									type="button"
									onClick={() => setModalLimpezaDestruicao(true) }
								/>
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={selectedLimpezaDestruicao.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedLimpezaDestruicao}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsLimpezaDestruicao,
								}}
							>
								<AgGridColumn
									headerName='Maquinário'
									field="maquinario"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
								/>
								<AgGridColumn
									headerName='Responsavel'
									field="responsavel"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
									minWidth={250}
								/>
								<AgGridColumn
									headerName='Data Antes do Uso'
									field="dataAntesUso"
									valueFormatter={({ data }: any) => data.dataAntesUso ? _generateDate(data.dataAntesUso, 'Pt') : ''}
									maxWidth={200}
								/>
								<AgGridColumn
									headerName='Data Após do Uso'
									field="dataAposUso"
									valueFormatter={({ data }: any) => data.dataAposUso ? _generateDate(data.dataAposUso, 'Pt') : ''}
									maxWidth={200}
								/>
								<AgGridColumn
									headerName="Ações"
									cellRenderer="actions"
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>

				</Fieldset>
			</If>

			<If condition={modoFluxo === MODO_VIEW.NOTAS}>
				<Fieldset disabled={false} title={STRINGS_CADERNOCONFORMIDADE.BLOCK_SIX}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>Informações sobre o material GM liberado não intencionalmente</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="informacoesMaterialLiberadoNaoIntencionalmente"
								as="textarea"
								rows={4}
								placeholder="Insira as informações sobre o material GM liberado não intencionalmente, sendo o mais específico possível, descrevendo as circunstâncias da liberação"
								defaultValue={entity?.informacoesMaterialLiberadoNaoIntencionalmente}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>Data e hora estimada da liberação não intencional</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="dataMaterialLiberadoNaoIntencionalmente"
								placeholder="Insira a data e hora da liberação não intencional"
								defaultValue={entity?.dataMaterialLiberadoNaoIntencionalmente}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>Medidas de Correção</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="medidasMaterialLiberadoNaoIntencionalmente"
								as="textarea"
								rows={4}
								placeholder="Insira as medidas de correção (passos tomados para a mitigação da liberação não-intencional - ação corretiva)"
								defaultValue={entity?.medidasMaterialLiberadoNaoIntencionalmente}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Pessoa que reportou a Liberação Não-Intencional</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="responsavelMaterialLiberadoNaoIntencionalmente"
								placeholder="Nome do responsável"
								defaultValue={entity?.responsavelMaterialLiberadoNaoIntencionalmente}
							/>
						</Form.Group>
						<Form.Group as={Col} md={3}>
							<Form.Label>Cargo</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="cargoMaterialLiberadoNaoIntencionalmente"
								placeholder="Cargo do responsável"
								defaultValue={entity?.cargoMaterialLiberadoNaoIntencionalmente}
							/>
						</Form.Group>
						<Form.Group as={Col} md={3}>
							<Form.Label>Data de aviso</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="dataAvisoMaterialLiberadoNaoIntencionalmente"
								placeholder="Data e hora de aviso"
								defaultValue={entity?.dataAvisoMaterialLiberadoNaoIntencionalmente}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset disabled={false} title={STRINGS_CADERNOCONFORMIDADE.BLOCK_SEVEN}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								<AddButton
									type="button"
									onClick={() => {
											setContagemAnotacao(0);
											setModalAnotacoes(true);
										}
									}
								/>
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={selectedAnotacoes.length}>
						<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
							<AgGridReact
								rowData={selectedAnotacoes}
								defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
								gridOptions={{ headerHeight: 80 }}
								stopEditingWhenGridLosesFocus={true}
								singleClickEdit={true}
								frameworkComponents={{
									actions: actionsAnotacoes,
								}}
							>
								<AgGridColumn
									headerName='Contagem'
									field="contagemAnotacao"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
									maxWidth={100}
								/>
								<AgGridColumn
									headerName='Responsavel'
									field="responsavelAnotacao"
									cellStyle={{
										justifyContent: 'left'
									}}
									autoHeight
									wrapText
									minWidth={250}
								/>
								<AgGridColumn
									headerName='Data'
									field="dataAnotacao"
									minWidth={200}
									valueFormatter={({ data }: any) => data.dataAnotacao ? _generateDate(data.dataAnotacao, 'Pt') : ''}
								/>
								<AgGridColumn
									headerName="Ações"
									cellRenderer="actions"
									maxWidth={100}
								/>
							</AgGridReact>
						</div>
					</If>

				</Fieldset>
			</If>

			<div className="button-position">
				<Button
					title="Voltar"
					contained={false}
					type="button"
					onClick={() => {
						isDirty ? setIsConfirmation(true) :
							history.goBack();
					}}
				/>
				<GenericButton
					title="Salvar e enviar para regulatório"
					variant="btn-warning"
					type="button"
					loading={loadingSubmit}
					onClick={() => trigger().then(validated => {
						if (validated) {
							setIsRegulatory(validated);
						} else {
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}
					})}
				/>
				<Button
					title="Salvar rascunho"
					contained={true}
					type="button"
					onClick={() => onSubmitDraft()}
					isLoading={loadingDraft}
				/>
			</div>
		</Form>

		<If condition={modalPlantios}>
			<Modal
				show={true}
				onHide={() => setModalPlantios(false)}
				title={STRINGS_CADERNOCONFORMIDADE.PLANTIOS_MODAL_TITLE}
			>
				<Form onSubmit={handleSubmitPlantios(onSubmitPlantios)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>Número do Plantio</Form.Label>
							<Form.Control
								ref={registerPlantios}
								name="numeroPlantio"
								placeholder="Insira o número do plantio"
								maxLength={30}
								defaultValue={plantioEdit.numeroPlantio}
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>Casa de Vegetação/Módulo(s)</Form.Label>
							<Form.Control
								ref={registerPlantios}
								name="modulo"
								placeholder="Insira a casa de vegetação/módulo(s)"
								defaultValue={plantioEdit.modulo}
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>Material(is) Plantado(s)</Form.Label>
							<Form.Control
								ref={registerPlantios}
								name="material"
								placeholder="Informar material(is) plantado(s) ou anexar lista de plantio"
								defaultValue={plantioEdit.material}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>Informações Adicionais do Material</Form.Label>
							<Form.Control
								ref={registerPlantios}
								name="informacoesAdicionaisMaterial"
								as="textarea"
								rows={4}
								placeholder="Insira a anotação"
								defaultValue={plantioEdit.informacoesAdicionaisMaterial}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Número da AMI</Form.Label>
							<Form.Control
								ref={registerPlantios}
								name="numeroAMI"
								placeholder="Insira o número da AMI"
								defaultValue={plantioEdit.numeroAMI}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Check
								type="checkbox"
								ref={registerPlantios}
								name="sobraMaterial"
								label="Houve sobra de material após o plantio?"
								defaultChecked={plantioEdit.sobraMaterial ? plantioEdit.sobraMaterial : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Número de Amostras Restantes</Form.Label>
							<Form.Control
								ref={registerPlantios}
								name="numeroAmostrasRestantes"
								placeholder="Insira o número de amostras restantes"
								defaultValue={plantioEdit.numeroAmostrasRestantes}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>Quantidade de Amostras Restantes</Form.Label>
							<Form.Control
								ref={registerPlantios}
								name="quantidadeAmostrasRestantes"
								placeholder="Insira a quantidade de material que sobrou (kg)"
								defaultValue={plantioEdit.quantidadeAmostrasRestantes}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Destino da Sobra</Form.Label>
							<Controller
								control={controlPlantios}
								name="destinoSobra"
								required

								isInvalid={defaultForm.errors.cultura !== undefined}
								as={<Select
									placeholder="Selecione um método de descarte"
									options={enumDestinoSobra.map(destinoSobra => Object.assign({ value: destinoSobra.key, label: destinoSobra.value }))}
									isClearable
								/>}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Check
								type="checkbox"
								ref={registerPlantios}
								name="entradaArmazenamento"
								label="Foi dada entrada no de Armazenamento?"
								defaultChecked={plantioEdit.entradaArmazenamento ? plantioEdit.entradaArmazenamento : false}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>Informações Adicionaisl</Form.Label>
							<Form.Control
								ref={registerPlantios}
								name="informacoesAdicionais"
								as="textarea"
								rows={4}
								placeholder="Insira a anotação"
								defaultValue={plantioEdit.informacoesAdicionais}
							/>
						</Form.Group>
					</Form.Row>

					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalPlantios(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalDatasColheita}>
			<Modal
				show={true}
				onHide={() => setModalDatasColheita(false)}
				title={STRINGS_CADERNOCONFORMIDADE.DATACOLHEITA_MODAL_TITLE}
			>
				<Form onSubmit={handleSubmitDatasColheita(onSubmitDatasColheita)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>Data da colheita</Form.Label>
							<br />
							<Controller
								control={controlDatasColheita}
								name="dataColheita"
								isInvalid={errorsDatasColheita.dataColheita !== undefined}
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalDatasColheita(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalLimpezaDestruicao}>
			<Modal
				show={true}
				onHide={() => setModalLimpezaDestruicao(false)}
				title={STRINGS_CADERNOCONFORMIDADE.LIMPEZADESTRUICAO_MODAL_TITLE}
			>
				<Form onSubmit={handleSubmitLimpezaDestruicao(onSubmitLimpezaDestruicao)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>Maquinário</Form.Label>
							<Form.Control
								ref={registerLimpezaDestruicao}
								name="maquinario"
								placeholder="Insira a identificação do maquinário"
								maxLength={30}
								defaultValue={limpezaDestruicaoEdit.maquinario}
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>Data ANTES do Uso</Form.Label>
							<Controller
								control={controlLimpezaDestruicao}
								name="dataAntesUso"
								defaultValue={limpezaDestruicaoEdit.dataAntesUso}
								isInvalid={errorsLimpezaDestruicao.datAntesUso !== undefined}
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>

						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>Data APÓS do Uso</Form.Label>
							<Controller
								control={controlLimpezaDestruicao}
								name="dataAposUso"
								defaultValue={limpezaDestruicaoEdit.dataAposUso}
								isInvalid={errorsLimpezaDestruicao.dataAposUso !== undefined}
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Método de limpeza</Form.Label>
							<Form.Control
								ref={registerLimpezaDestruicao}
								name="metodoLimpeza"
								placeholder="Insira o método de limpeza"
								defaultValue={limpezaDestruicaoEdit.metodoLimpeza}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>Responsável</Form.Label>
							<Form.Control
								ref={registerLimpezaDestruicao}
								name="responsavel"
								placeholder="Insira o responsavel"
								defaultValue={limpezaDestruicaoEdit.responsavel}
							/>
						</Form.Group>
					</Form.Row>

					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalLimpezaDestruicao(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalAnotacoes}>
			<Modal
				show={true}
				onHide={() => setModalAnotacoes(false)}
				title={STRINGS_CADERNOCONFORMIDADE.ANOTACOES_MODAL_TITLE}
			>
				<Form onSubmit={handleSubmitAnotacoes(onSubmitAnotacoes)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>Anotação</Form.Label>
							<Form.Control
								ref={registerAnotacoes}
								name="anotacao"
								as="textarea"
								rows={4}
								placeholder="Insira a anotação"
								defaultValue={anotacaoEdit.anotacao}
							/>
						</Form.Group>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalAnotacoes(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalInspecaoEquipamentos}>
			<Modal
				show={true}
				onHide={() => setModalInspecaoEquipamentos(false)}
				title={STRINGS_CADERNOCONFORMIDADE.INSPECAOEQUIPAMENTOS_MODAL_TITLE}
			>
				<Form onSubmit={handleSubmitInspecaoEquipamentos(onSubmitInspecaoEquipamentos)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Número do Plantio</Form.Label>
							<Form.Control
								ref={registerInspecaoEquipamentos}
								name="maquinarioEquipamento"
								placeholder="Insira o maquinário"
								defaultValue={inspecaoEquipamentosEdit.maquinarioEquipamento}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>ID do Equipamento</Form.Label>
							<Form.Control
								ref={registerInspecaoEquipamentos}
								name="idEquipamento"
								placeholder="Insira o ID do equipamento"
								defaultValue={inspecaoEquipamentosEdit.idEquipamento}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Data ANTES do Uso</Form.Label>
							<br />
							<Controller
								control={controlInspecaoEquipamentos}
								name="dataEquipamentoAntesUso"
								defaultValue={inspecaoEquipamentosEdit.dataEquipamentoAntesUso}
								isInvalid={errorsInspecaoEquipamentos.dataEquipamentoAntesUso !== undefined}
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>Data APÓS do Uso</Form.Label>
							<br />
							<Controller
								control={controlInspecaoEquipamentos}
								name="dataEquipamentoAposUso"
								defaultValue={inspecaoEquipamentosEdit.dataEquipamentoAposUso}
								isInvalid={errorsInspecaoEquipamentos.dataEquipamentoAposUso !== undefined}
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>Fase do Ensaio</Form.Label>
							<Form.Control
								ref={registerInspecaoEquipamentos}
								name="faseEnsaioEquipamento"
								placeholder="Insira a fase de ensaio"
								defaultValue={inspecaoEquipamentosEdit.faseEnsaioEquipamento}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>Método de Limpeza</Form.Label>
							<Form.Control
								ref={registerInspecaoEquipamentos}
								name="metodoLimpezaEquipamento"
								placeholder="Insira o método de limpeza do equipamento"
								defaultValue={inspecaoEquipamentosEdit.metodoLimpezaEquipamento}
							/>
						</Form.Group>
					</Form.Row>

					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalInspecaoEquipamentos(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>

		<If condition={modalLimpezaInstalacoes}>
			<Modal
				show={true}
				onHide={() => setModalLimpezaInstalacoes(false)}
				title={STRINGS_CADERNOCONFORMIDADE.LIMPEZAINSTALACOES_MODAL_TITLE}
			>
				<Form onSubmit={handleSubmitLimpezaInstalacoes(onSubmitLimpezaInstalacoes)} autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>Data </Form.Label>
							<br />
							<Controller
								control={controlLimpezaInstalacoes}
								name="dataLimpezaInstalacao"
								defaultValue={limpezaInstalacoesEdit.dataLimpezaInstalacao}
								isInvalid={errorsLimpezaInstalacoes.dataLimpezaInstalacao !== undefined}
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>Nº/Código da Casa-de-Vegetação</Form.Label>
							<Form.Control
								ref={registerLimpezaInstalacoes}
								name="casaVegetacaoLimpezaInstalacao"
								placeholder="Insira o nº/código da casa-de-vegetação"
								defaultValue={limpezaInstalacoesEdit.casaVegetacaoLimpezaInstalacao}
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>Responsável</Form.Label>
							<Form.Control
								ref={registerLimpezaInstalacoes}
								name="responsavelLimpezaInstalacao"
								placeholder="Insira o responsável pela limpeza do equipamento"
								defaultValue={limpezaInstalacoesEdit.responsavelLimpezaInstalacao}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>Método de Limpeza</Form.Label>
							<br />
							<Controller
								control={defaultForm.control}
								name="metodoLimpeza"
								required

								isInvalid={defaultForm.errors.metodoLimpeza !== undefined}
								as={<Select
									placeholder="Selecione um método de limpeza"
									options={enumMetodoLimpeza.map(metodoLimpeza => Object.assign({ value: metodoLimpeza.key, label: metodoLimpeza.value }))}
									isClearable
								/>}
							/>
						</Form.Group>
					</Form.Row>

					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setModalLimpezaInstalacoes(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="submit"
						/>
					</div>
				</Form>
			</Modal>
		</If>
	</div>


	//	const verifyErrorsOnForm = (): string[] => {
	//		let errorsForm: any[] = [];

	//		if (!isEmptyObj(errors)) {
	//			Object.entries(errors).map(err => errorsForm.push(err[0]));
	//		}
	//		return errorsForm;
	//	}


	//	const getValuesToSubmit = () => {
	//		return {
	//			requisitante: decoded.unique_name,
	//			destinatario: getValues('listaDestinatario')?.label,
	//			destinatarioId: getValues('listaDestinatario')?.value,
	//			localDestinoPossuiCQB: getValues('possuiCQB'),
	//			origem: getValues('origem'),
	//			objetivoUtilizacao: getValues('objetivoUtilizacao'),
	//			observacaoPosAprovacao: getValues('observacaoPosAprovacao'),
	//			observacoes: getValues('observacoes'),
	//			trechoLPMALocaisMovimentacaoMaterial: getValues('trechoLPMA'),
	//			escopoLPMAContemplaMaterial: getValues('escopoLPMA'),
	//			embalagemDuplaImpermeavel: 0,
	//			identificacaoSimboloRiscoBiologico: 0,
	//			responsavelTransporte: '',
	//			dataEnvio: '',
	//			materialChegouDestinoPerfeitasCondicoes: 0,
	//			qtdRecebidaDeAcordoComQtdEnviada: 0,
	//			responsavelRecebimento: '',
	//			dataRecebimento: '',
	//			culturaId: getValues('culturaOrganismo')?.value,
	//			modoTransporteId: getValues('modoDeTransporte')?.value,
	//			localPartidaId: getValues('localDeEnvio')?.value,
	//			localDestinoId: getValues('localDeDestino')?.value,
	//			eventos: expectedEventos(),
	//			transportes: expectedTransports()
	//		};
	//	}

	//	const onSubmit = async () => {
	//		setErrorSubmit(null);
	//		setLoadingSubmit(true);

	//		try {
	//			const valuesToSubmit = getValuesToSubmit();
	//			const { status, data } = await api.post('api/v1/autorizacoes-cibio/MovimentacaoInterna/enviarregulatorio', valuesToSubmit);

	//			if (status === 200) {
	//				window.scrollTo({ top: 0, behavior: 'smooth' });
	//				setSuccessSubmit(true);
	//				setTimeout(() => {
	//					history.push('/autorizacoes/editar/ami', { id: data.id });
	//				}, 3000);
	//			}
	//		} catch (error:any) {
	//			window.scrollTo({ top: 0, behavior: 'smooth' });
	//			setErrorSubmit(error.response.data);
	//		}

	//		setLoadingSubmit(false);
	//	}

	//	const onSubmitDraft = async () => {
	//		setErrorDraft(null);
	//		setLoadingDraft(true);

	//		try {
	//			const amiToSubmitDraft = getValuesToSubmit();
	//			const { status, data } = await api.post('api/v1/autorizacoes-cibio/MovimentacaoInterna/rascunho', amiToSubmitDraft);

	//			if (status === 201) {
	//				setSuccessDraft(true);
	//				window.scrollTo({ top: 0, behavior: 'smooth' });
	//				setTimeout(() => {
	//					history.push('/autorizacoes/editar/ami', { id: data.id });
	//				}, 3000);
	//			}
	//		} catch (error:any) {
	//			window.scrollTo({ top: 0, behavior: 'smooth' });
	//			setErrorDraft(error.response.data);
	//		}

	//		setLoadingDraft(false);
	//	}

	//	const expectedEventos = (): ExpectedEvento[] => {
	//		let arrEvent: ExpectedEvento[] = [];

	//		for (let count = 0, countLen = selectedEvents.length; count < countLen; count++) {
	//			arrEvent.push({
	//				Id: 0,
	//				eventoId: selectedEvents[count].evento.value,
	//				eventoNome: selectedEvents[count].evento.label,
	//				qtdAutorizada: selectedEvents[count].quantidadeAutorizada,
	//				qtdTransportada: selectedEvents[count].quantidadeTransportada !== undefined ? selectedEvents[count].quantidadeTransportada : 0,
	//				unidadeMedidaAutorizada: selectedEvents[count].unidadeMedidaAutorizada.value,
	//				unidadeMedidaAutorizadaDescricao: selectedEvents[count].unidadeMedidaAutorizada.label,
	//				unidMedAutorizadaOutros: selectedEvents[count].unidMedAutorizadaOutros,
	//				material: selectedEvents[count].material.value,
	//				materialDescricao: selectedEvents[count].material.label,
	//				materialOutros: selectedEvents[count].materialOutros,
	//				unidadeMedidaTransportada: selectedEvents[count].unidadeMedidaTransportada?.value,
	//				unidMedTranspOutros: selectedEvents[count].unidMedTranspOutros,
	//				unidadeMedidaTransportadaDescricao: selectedEvents[count].unidadeMedidaTransportada?.label
	//			})
	//		}
	//		return arrEvent;
	//	}

	//		<If condition={isConfirmation}>
	//			<ConfirmationModal
	//				show={isConfirmation}
	//				confirmationMsg={STRINGS_CIBIO.CONFIRM_LEFT_EDITION}
	//				cancelMsg={STRINGS_CIBIO.CANCEL_LEFT_EDITION}
	//				onConfirm={() => history.goBack()}
	//				onHide={() => setIsConfirmation(false)}
	//				title={STRINGS_CIBIO.EDITION_TITLE_CONFIRMATION}
	//				type="button"
	//				variant="danger"
	//			>
	//				{STRINGS_CIBIO.EDITION_REGISTER_CONFIRMATION_CANCEL}
	//			</ConfirmationModal>
	//		</If>
}