import moment from 'moment';

export const isTokenValid = (expireTime: number): boolean => {
    let timeExpire = new Date(expireTime * 1000);
    let today = new Date();

    if (today > timeExpire) {
        return false;
    }
    return true;
}

export const isRefreshTokenValid = (expireTime: number, created: string): boolean => {
    let expire = moment(new Date(created)).add(expireTime, 'seconds').toDate();
    let today = new Date();

    if (today > expire)
        return false;
    return true;
}