/*
 * OLD IMPORTS
 */

//Basic REACT
import React, { useState, useEffect, useContext, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Col, Modal } from "react-bootstrap";
import Select from 'react-select';
import moment from 'moment';

//import ReactDOM from 'react-dom';
//import MultiStep from "react-multistep";

//Custom Componentes
import Button from "../../../components/Button";
import If from "../../../components/If";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ReasonModal from '../../../components/ReasonModal';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';

import Fieldset from "../../../components/Fieldset";
import LoadingInForm from '../../../components/LoadingInForm';

import DatePicker, { registerLocale } from 'react-datepicker';

//Custom services
import { DecodedToken } from '../../../models/user';
import jwtDecode from 'jwt-decode';
import api, { apiFile, apiFileDownload } from "../../../services/api";
import { Context } from '../../../context/AuthContext'

import { FaFileDownload } from 'react-icons/fa';
import { GoCircleSlash } from 'react-icons/go';
import ButtonCustom from '../../../components/ButtonCustom'

//import { isAdmin, isReguladorReader, isReguladorWriter, isRequesterWriter } from '../../../users/shipment/isCheck';

//Page Specifics
import { STRINGS } from "../../../util/strings";
import { STRINGS_SHIPMENT } from "../../../util/shipment/string";
import { STATUS_SHIPMENT } from '../../../util/shipment/status';
import "../../AuthorizationsCIBIO/styles_edit.scss";
import {
	CultureResult,
	Enum,
	RequsitanteDto,
	StateType,
	Shipment,
	ShipmentEnviarDto,
	ShipmentAnexo
} from "../../../models/modelShipment";

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import DataFluxoComponent from "./datafluxoComponent";
/**
 * New stuff
 */
import NavigationMessage, {MessageType, MessageContent } from "../../../components/NavigationMessages";

import {countriesWithCode} from "../../../util/countries"

import "./styles.scss";

import { 
	isCheck as isShipmentCheckRoles
} from '../../../users/shipment/isCheck';
import { 
	PERMISSIONS as SHIPMENT_PERMISSIONS
} from '../../../users/usersPermissions/usersPermissionsShipment';
import { NewLineKind } from "typescript";

const MODO_VIEW = {
    EDICAO  : 1,
    ANEXOS  : 2,
    ENVIOS  : 3,
}

export default function Edit ()
{
	//const apiUrlShipment = "/api/v1/Shipment/salvar";
	const apiUrlShipment = "api/v1/Shipment"
	const apiUrlShipmentSalvar = `${apiUrlShipment}/salvar`
	const apiUrlShipmentAnexos= `${apiUrlShipment}/anexos`
	const apiUrlShipmentEnviar = `${apiUrlShipment}/enviar`
	const apiUrlShipmentDownloadAnexo = `${apiUrlShipment}/download_anexo`
	const apiUrlShipmentRemoveAnexo = `${apiUrlShipment}/remove_anexo`
	const apiUrlShipmentFiltro = "api/v1/Shipment/obterporfiltro?"
	const apiUrlShipment_GetCulturas = `${apiUrlShipment}/cultura_obter_todos`
	const apiUrlShipment_GetMaterial = `${apiUrlShipment}/material_obter_todos`
	const apiUrlShipment_GetStatus = `${apiUrlShipment}/status_obter_todos`
	const apiUrlShipment_GetEstadoCondicao = `${apiUrlShipment}/status_estados_condicoes`
	const apiUrlShipment_GetStatusRegulatorioArrays = `${apiUrlShipment}/status_regulatorio_arrays`
	const apiUrlShipment_GetRequisitantes = `${apiUrlShipment}/lista_requesitantes`
	//'api/v1/shipment/Cultura/obterporfiltro?Ativo=true');

	const [success, setSuccess] = useState<boolean>(false);

	const GoBackToTop = () => {window.scrollTo({ top: 0, behavior: 'smooth' })}
	const ParseDefaultBackendMessage = (backendMessage : any) => {

		let hasBackendMessage = backendMessage && backendMessage.response && backendMessage.response.data && Array.isArray(backendMessage.response.data);

		if (hasBackendMessage)
		{
			for (let i in backendMessage.response.data)
			{
				let item = backendMessage.response.data[i][0];
				addPageMessages({type:MessageType.error, content:`O campo ${item} é obrigatório`});
			}
		}
		else
		{		
			addPageMessages({type:MessageType.error, content:STRINGS.ALERT_GENERIC_ERROR});
		}
	}

	const prevStyle = {'background': '#33c3f0', 'border-width': '2px'}
	const nextStyle = {'background': '#33c3f0',  'border-width': '2px'}

	//Page status flow
	enum statuses {
		idle,					//Nothing is taking the screen right now
		modal_itens,			//Showing modal for add itens
		go_back,				//Going back to list screen
		send_correction,		//Send business rule do correction
		send_regulatory,		//Send business rule to regulatory
		send_completion,		//Send business rule to completion
		send_aproval,			//Send business rule to aproval
	}
	const [status, setStatus] = useState<statuses>(statuses.idle);
	const [paisesExImp, setPaisesExImp] = useState<any>();

	//Loading screen
	const [loading, setLoading] = useState<string[]>([]);
	const setLoadingScreen = (tag : string, type: boolean = true) => {
		
		let currentLoading = loading;
		let index = currentLoading.indexOf(tag);
		
		if (type)
		{
			if (index >= 0)
				return;

			currentLoading.push(tag);
		}
		else
		{
			if (index <= -1)
				return;

			currentLoading.splice(index, 1);
		}

		setLoading([]);
		setLoading(currentLoading);
	}
	const isLoadingScreen = (tag:string) : boolean => {return loading.indexOf(tag) >= 0;}

	//Page notification
	const [pageMessages, setPageMessages] = useState<MessageContent[]>([]);
	const hasPageMessages = () : boolean => {return pageMessages.length > 0;}
	const clearPageMessages = async () => {await setPageMessages([]); }
	const addPageMessages = (message : MessageContent, clearMessages : boolean = true) => {
		let currentPageMessages:MessageContent[] = !clearMessages ? pageMessages : [];
		setPageMessages([]);
		currentPageMessages.push(message);
		setPageMessages(currentPageMessages);
	}
	const setSuccessMessageAndRedirect = (message : string, callback: any) => {

		setStatus(statuses.idle);
		addPageMessages({type:MessageType.success, content:message});
		GoBackToTop();
		// setTimeout(() => setStatus(statuses.idle), 2900);
		setTimeout(() => { callback(); }, 3500);
	
	}

	//Data entity
	const [entity, setEntity] = useState<Shipment>({
		id: undefined,
		codigo: '',
		status: STATUS_SHIPMENT.EmCriacao,
		solicitante: '',
		pais_exportador: null,
		pais_importador: null,
		pais_origem: null,
		culturaId: null,
		materialId: null,
		status_regulatorio: null,
		status_regulatoriogm: null,
		status_regulatoriogmregulado: null,
		requisitantesExportadorId: null,
		requisitantesImportadorId: null,
		alerta_requisitantes_exportador: null,
		alerta_requisitantes_importador : null,
		estado: null,
		condicoes_observacoes: '',
		condicoes: null,
		centroCusto: '',
		numeroProjeto: '',
		dataAguardandoDocumentos: null,
		dataAguardandoGreenLight: null,
		dataColetaEntrega: null,
		dataDocumentosEnviados: null,
		dataEmLiberacaoOrigem: null,
		dataEmLiberadaDestino: null,
		dataEmQuarentena: null,
		dataEmTransitoDestinoFinal: null,
		dataEmTransitoInternacional: null,
		dataEmbarqueAprovado: null,
		dataEntregue: null,
		dataPermissaoImportacao: null,
		dataReferenciaCriada: null,

		dataCancelamento: null,
		responsavelCancelamento: '',
		motivoCancelamento: '',
	});

	const [anexosItems, setAnexosList] = useState<ShipmentAnexo[]>([]);
	const [anexosItemsNames, setAnexosNames] = useState<string[]>([]);
	

	/**
	 * OLD IMPLEMENTATION
	 */
	const [modoFluxo, setModoFluxo] = useState<Number>(MODO_VIEW.EDICAO);

	const history = useHistory();
	const location = useLocation<StateType>();
	const [requisitantes, setRequisitantes] = useState<RequsitanteDto[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [enumMaterial, setEnumMaterial] = useState<CultureResult[]>([]);
	const [enumEstado, setEnumEstado] = useState<Enum[]>([]);
	const [enumCodicao, setEnumCodicao] = useState<Enum[]>([]);
	const [statusRegulatorio, setStatusRegulatorio] = useState<Enum[]>([]);
	const [statusRegulatorioGM, setStatusRegulatorioGM] = useState<Enum[]>([]);
	const [statusRegulatorioRegulado, setStatusRegulatorioGMRegulado] = useState<Enum[]>([]);
	
	const {token} = useContext(Context);
	//const decoded: DecodedToken = jwtDecode(token.access_token);
	const { roles, unique_name }: DecodedToken =jwtDecode(token.access_token);
	
	const [botoesEnvio, setBotoesEnvio] = useState<any>({ StatusAtual : STATUS_SHIPMENT.ReferenciaCriada });

	const [isDadosEditavel, setIsDadosEditavel] = useState<boolean>(true);
	const [isDadosEditavelAnexo, setIsDadosEditavelAnexo] = useState<boolean>(true);
	const [anexoRemConfirmeShow, setAnexoRemConfirmeShow] = useState<{Show: boolean, shipObj?: any}>({Show: false, shipObj: null});
	const [enviarStatusFail, setEnviarStatusFail] = useState<{ Show: boolean, i?: Number, textoComplemento?: string }>({ Show: false, i: 0 });
	const [cancelled, setCancelled] = useState<boolean>(false);

	const [viewDatePickerClassName, setViewDatePickerClassName]= useState<string>("");

	const defaultForm = useForm({
		defaultValues: entity,
		mode: "onSubmit",
		resolver: yupResolver(yup.object({
			pais_exportador: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
			pais_importador: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
			pais_origem: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),

			culturaId: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
			materialId: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),

			status_regulatorio: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),

			estado: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
			condicoes: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),


			requisitantesExportadorId: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
			requisitantesImportadorId: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),

			alerta_requisitantes_exportador: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
			alerta_requisitantes_importador: yup.mixed().required(STRINGS.FORM_FIELD_REQUIRED),
		})),
		//resolver: yupResolver(cultureSchema),
	});

	/*const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Autorização AAC', 'Projeto OGM'];

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };*/
	const anexo_acao = async ({remove, remove_confirma, downld}:any, shipAnexodados: any)=>{
		if(remove){
			setAnexoRemConfirmeShow({Show: true, shipObj: shipAnexodados })
		}
		if(remove_confirma){

			setLoadingScreen('remove_anexos');
			try
			{

			const { data, status } = await api.get(`${apiUrlShipmentRemoveAnexo}/${shipAnexodados.id}`);

			if ([200,201].indexOf(status) <= -1)
			throw new Error('Erro ao obter resposta do servidor!');
			
				setAnexosList(data.getAnexos);
				setAnexoRemConfirmeShow({Show: false});

			}
			catch (error:any)
			{
				ParseDefaultBackendMessage(error);
			}

			setLoadingScreen('remove_anexos', false);
		}
		else if(downld){
			setLoadingScreen('download_anexos');
			try
			{				
				const { data } = await apiFileDownload.get(`${apiUrlShipmentDownloadAnexo}/${shipAnexodados.id}`);

				let blob = new Blob([data], {
					//type: 'application/pdf'
				});

				let url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				
				if (typeof a.download === "undefined")
				{
					window.location.href = url;
				}
				else
				{
					a.href = url;
					a.download = shipAnexodados.filename;
					document.body.appendChild(a);
					a.click();
				}
			}
			catch (error:any)
			{
				console.error(error);
			}
			setLoadingScreen('download_anexos', false);
		}
	};
	const submitForm_anexos = async () => {

		await clearPageMessages();
		setLoadingScreen('anexos');

		try
		{
			let formData = new FormData();
			let filesAnexo = defaultForm.getValues('setAnexos');			
			//let responseStatus = {status: 0, data: {id : 0}};
			let shipId = location?.state?.id ?? 0
			if(shipId === 0){
				throw new Error("location?.state?.id == 0, location?.state= " + (location?.state));
				
			}
			
			//formData.append("shipId", `${shipId}`);
			for(let i = 0, t = filesAnexo.length; i < t; i++){
				formData.append("shipmentFileAnexos", filesAnexo[i]);			
			}			

			const { data, status } = await apiFile.post<Shipment>(`${apiUrlShipmentAnexos}/${shipId}`, formData);

			if ([200,201].indexOf(status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');

			setAnexosList(data.getAnexos);
			setAnexosNames([]);
			//defaultForm.setValue('setAnexos', []);
			
		}
		catch (error:any)
		{
			ParseDefaultBackendMessage(error);
		}

		GoBackToTop();
		setLoadingScreen('anexos', false);
	}

	const submitForm_salvar = async () => {
		
		await clearPageMessages();
		setLoadingScreen('salvar');

		try
		{
			const valuesToSubmit = getValuesToSubmit();
			
			let responseStatus = {status: 0, data: {id : 0}};
			let shipId = location?.state?.id ?? 0

			if(shipId === 0)
				responseStatus = await api.put(apiUrlShipmentSalvar, valuesToSubmit);
			else
				responseStatus = await api.post(apiUrlShipmentSalvar, valuesToSubmit);

			if ([200,201].indexOf(responseStatus.status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
						
			setSuccessMessageAndRedirect(STRINGS.ALERT_MESSAGE_SUCCESS, () => {
				//setModoFluxo(true);
				//history.goBack();
				history.push({
					pathname: '/shipment/editar',
					state: {
						id: responseStatus.data.id
					}
				})
			})
		}
		catch (error:any)
		{
			ParseDefaultBackendMessage(error);
		}
		
		GoBackToTop();
		setLoadingScreen('salvar', false);
	}

	const submitForm_enviar= async () => {
		await clearPageMessages();
		setLoadingScreen('enviar');

		try
		{
			let valuesToSubmit =  getValuesToSubmit() as ShipmentEnviarDto;
			
			//let statusLst = Object.values(STATUS_SHIPMENT);
			//for(var i = 1; i)
			let datas = enviarEmailGetDatas();
			valuesToSubmit.Items = [
				{ status: STATUS_SHIPMENT.ReferenciaCriada, data: datas.dataReferenciaCriada },
				{ status: STATUS_SHIPMENT.PermissaoImportacao, data: datas.dataPermissaoImportacao },
				{ status: STATUS_SHIPMENT.AguardandoDocumentos, 	data: datas.dataAguardandoDocumentos },
				{ status: STATUS_SHIPMENT.DocumentosEnviados, 	data: datas.dataDocumentosEnviados },
				{ status: STATUS_SHIPMENT.AguardandoGreenLight , data: datas.dataAguardandoGreenLight },
				{ status: STATUS_SHIPMENT.EmbarqueAprovado, 	data: datas.dataEmbarqueAprovado },
				{ status: STATUS_SHIPMENT.Coleta_Entrega , 		data: datas.dataColetaEntrega },
				{ status: STATUS_SHIPMENT.EmLiberacaoOrigem, 	data: datas.dataEmLiberacaoOrigem },
				{ status: STATUS_SHIPMENT.EmTransitoInternacional , data: datas.dataEmTransitoInternacional },
				{ status: STATUS_SHIPMENT.EmLiberadaDestino, 	data: datas.dataEmLiberadaDestino },
				////{ status: STATUS_SHIPMENT.EmQuarentena , 		data: datas.dataEmQuarentena },
				// { status: STATUS_SHIPMENT.EmTransitoFinal , 	data: datas.dataEmTransitoDestinoFinal },
				// { status: STATUS_SHIPMENT.Entregue , 			data: datas.dataEntregue },
				];
			
			if(CheckImportBR()){
				valuesToSubmit.Items.push({ status: STATUS_SHIPMENT.EmQuarentena , data: datas.dataEmQuarentena })
			}
			valuesToSubmit.Items.push({ status: STATUS_SHIPMENT.EmTransitoFinal , 	data: datas.dataEmTransitoDestinoFinal })
			valuesToSubmit.Items.push({ status: STATUS_SHIPMENT.Entregue, data: datas.dataEntregue })

			for(let i = 0, t = valuesToSubmit.Items.length; i < t; i++)
			{
				let dtSel = valuesToSubmit.Items[i];
				if (dtSel.data !== null && dtSel.data !== undefined)
				{
					var statI = dtSel.status;
				}
			}

			//var dt = FluxoComponentList().find(e => e.fluxoMinimo === statI);
			//if (dt?.data == null)
			//	setEnviarStatusFail({ Show: true, textoComplemento: dt?.text })
			setLoadingScreen('enviar', true);	
				
			let responseStatus = {status: 0};
			let shipId = location?.state?.id ?? 0
			
			responseStatus = await api.post(apiUrlShipmentEnviar, valuesToSubmit);

			if ([200, 201].indexOf(responseStatus.status) <= -1)
				throw new Error('Erro ao obter resposta do servidor!');
			else {
				setSuccess(true);
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}

			//setSuccessMessageAndRedirect(STRINGS_SHIPMENT.ALERT_SEND_SUCCESS, () => {
			//	history.goBack();				
			//})

		}
		catch (error:any)
		{
			ParseDefaultBackendMessage(error);
		}
		
		GoBackToTop();
		setLoadingScreen('enviar', false);
	};

	const enviarEmailGetDatas = () =>{
		let datas = {
			dataReferenciaCriada: defaultForm.getValues('dataReferenciaCriada'),
			dataPermissaoImportacao: defaultForm.getValues('dataPermissaoImportacao'),
			dataAguardandoDocumentos : defaultForm.getValues('dataAguardandoDocumentos'),
			dataDocumentosEnviados	 : defaultForm.getValues('dataDocumentosEnviados'),
			dataAguardandoGreenLight : defaultForm.getValues('dataAguardandoGreenLight'),
			dataEmbarqueAprovado	: defaultForm.getValues('dataEmbarqueAprovado'),
			dataColetaEntrega 		: defaultForm.getValues('dataColetaEntrega'),
			dataEmLiberacaoOrigem 	: defaultForm.getValues('dataEmLiberacaoOrigem'),
			dataEmTransitoInternacional : defaultForm.getValues('dataEmTransitoInternacional'),
			dataEmLiberadaDestino 	: defaultForm.getValues('dataEmLiberadaDestino'),
			dataEmQuarentena 		: defaultForm.getValues('dataEmQuarentena'),
			dataEmTransitoDestinoFinal : defaultForm.getValues('dataEmTransitoDestinoFinal'),
			dataEntregue 			: defaultForm.getValues('dataEntregue'),
		}
		return datas;
	}

	const LimparProximo = (item:any ) =>{
		let datas = enviarEmailGetDatas();
		// if(item.fluxoMinimo <= STATUS_SHIPMENT.Entregue) { datas.dataEntregue = null;}
		// if(item.fluxoMinimo <= STATUS_SHIPMENT.EmTransitoFinal)  { datas.dataEmTransitoDestinoFinal = null;}
		// if(item.fluxoMinimo <= STATUS_SHIPMENT.EmQuarentena) {datas.dataEmQuarentena = null;}
		// if(item.fluxoMinimo <= STATUS_SHIPMENT.EmLiberadaDestino) datas.dataEmLiberadaDestino = null;
		// if(item.fluxoMinimo <= STATUS_SHIPMENT.EmTransitoInternacional) datas.dataEmTransitoInternacional = null;


		// if(item.fluxoMinimo <= STATUS_SHIPMENT.EmLiberacaoOrigem) datas.dataEmLiberacaoOrigem = null;
		// if(item.fluxoMinimo <= STATUS_SHIPMENT.Coleta_Entrega) datas.dataColetaEntrega = null;		
		// if(item.fluxoMinimo <= STATUS_SHIPMENT.EmbarqueAprovado) datas.dataEmbarqueAprovado = null;

		// if(item.fluxoMinimo <= STATUS_SHIPMENT.AguardandoGreenLight) datas.dataAguardandoGreenLight = null;
		// if(item.fluxoMinimo <= STATUS_SHIPMENT.AguardandoDocumentos) datas.dataAguardandoDocumentos = null;
		// if(item.fluxoMinimo <= STATUS_SHIPMENT.ReferenciaCriada) datas.dataReferenciaCriada = null;

		let dataFromServer = {
			...entity,
			...datas,
		} 
		defaultForm.setValue(item.dataBind, null) ;					
		
		defaultForm.reset(dataFromServer);
		setEntity(dataFromServer);			

	};

	const enviarEmailFluxoCheck = (fluxo:any, data:any, min:any) => {
		let datas = enviarEmailGetDatas();
		let elseNaoPre = 0;

		if (fluxo <= STATUS_SHIPMENT.ReferenciaCriada && datas.dataReferenciaCriada != null)
			{ min.val = Number(STATUS_SHIPMENT.ReferenciaCriada) + 1; } else if (datas.dataReferenciaCriada === null) { elseNaoPre++; }
		if (fluxo <= STATUS_SHIPMENT.PermissaoImportacao && datas.dataPermissaoImportacao != null)
			{ min.val = Number(STATUS_SHIPMENT.PermissaoImportacao) + 1; } else if (datas.dataPermissaoImportacao === null) { elseNaoPre++; }
		if (fluxo <= STATUS_SHIPMENT.AguardandoDocumentos && datas.dataAguardandoDocumentos != null)
			{ min.val = Number(STATUS_SHIPMENT.AguardandoDocumentos) + 1; } else if (datas.dataAguardandoDocumentos === null) { elseNaoPre++; }
		if (fluxo <= STATUS_SHIPMENT.DocumentosEnviados && datas.dataDocumentosEnviados != null)
			{ min.val = Number(STATUS_SHIPMENT.DocumentosEnviados) + 1; } else if (datas.dataDocumentosEnviados === null) { elseNaoPre++; }
		if (fluxo <= STATUS_SHIPMENT.AguardandoGreenLight && datas.dataAguardandoGreenLight != null)
			{ min.val = Number(STATUS_SHIPMENT.AguardandoGreenLight) + 1; } else if (datas.dataAguardandoGreenLight === null) { elseNaoPre++; }
		if (fluxo <= STATUS_SHIPMENT.EmbarqueAprovado && datas.dataEmbarqueAprovado != null)
			{ min.val = Number(STATUS_SHIPMENT.EmbarqueAprovado) + 1; } else if (datas.dataEmbarqueAprovado === null) { elseNaoPre++; }
		if (fluxo <= STATUS_SHIPMENT.Coleta_Entrega && datas.dataColetaEntrega != null)
			{ min.val = Number(STATUS_SHIPMENT.Coleta_Entrega) + 1; } else if (datas.dataColetaEntrega === null) { elseNaoPre++; }
		if (fluxo <= STATUS_SHIPMENT.EmLiberacaoOrigem && datas.dataEmLiberacaoOrigem != null)
			{ min.val = Number(STATUS_SHIPMENT.EmLiberacaoOrigem) + 1; } else if (datas.dataEmLiberacaoOrigem === null) { elseNaoPre++; }
		if (fluxo <= STATUS_SHIPMENT.EmTransitoInternacional && datas.dataEmTransitoInternacional != null)
			{ min.val = Number(STATUS_SHIPMENT.EmTransitoInternacional) + 1; } else if (datas.dataEmTransitoInternacional === null) { elseNaoPre++; }
		
		if(CheckImportBR())
		{
			if (fluxo <= STATUS_SHIPMENT.EmLiberadaDestino && datas.dataEmLiberadaDestino != null)
				{ min.val = Number(STATUS_SHIPMENT.EmLiberadaDestino) + 1; } else if (datas.dataEmLiberadaDestino === null) { elseNaoPre++; }
			if (fluxo <= STATUS_SHIPMENT.EmQuarentena && datas.dataEmQuarentena != null)
				{ min.val = Number(STATUS_SHIPMENT.EmQuarentena) + 1; } else if (datas.dataEmQuarentena === null) { elseNaoPre++; }
		}
		else {
			if (fluxo <= STATUS_SHIPMENT.EmLiberadaDestino && datas.dataEmLiberadaDestino != null)
				{ min.val = Number(STATUS_SHIPMENT.EmLiberadaDestino) + 2; } else if (datas.dataEmLiberadaDestino === null) { elseNaoPre++; }
		}
		if (fluxo <= STATUS_SHIPMENT.EmTransitoFinal && datas.dataEmTransitoDestinoFinal != null)
			{ min.val = Number(STATUS_SHIPMENT.EmTransitoFinal) + 1; } else if (datas.dataEmTransitoDestinoFinal === null) { elseNaoPre++; }
		if (fluxo <= STATUS_SHIPMENT.Entregue && datas.dataEntregue != null)
			{ min.val = Number(STATUS_SHIPMENT.Entregue) + 1; } else if (datas.dataEntregue === null) { elseNaoPre++; }

	};
	

	//TODO
	const getValuesToSubmit = () => {

		const savePais = (campo:any) =>{
			let code = defaultForm.getValues(campo)?.value?.code ?? "";
			let name = defaultForm.getValues(campo)?.value?.name ?? "";
			return  `${code}/${name}`
		}

		const getRepresentantesListaId = (campo:any) =>{
			let lstcm = defaultForm.getValues(campo) as []
			let lstId = lstcm?.map(ret => (ret as any)?.value );
			return lstId;
		}
		

		 let valuesToSubmit = {
			id:						entity.id,
			codigo:					entity.codigo,
			status: 				entity.status,
			solicitante:			defaultForm.getValues('solicitante'),
			pais_exportador:		savePais('pais_exportador'),
			pais_importador:		savePais('pais_importador'),	
			pais_origem:            savePais('pais_origem'),
			culturaId:				defaultForm.getValues('culturaId')?.value,
			materialId:				defaultForm.getValues('materialId')?.value,

			status_regulatorio:		defaultForm.getValues('status_regulatorio')?.value?.key,
			status_regulatoriogm:	defaultForm.getValues('status_regulatoriogm')?.value?.key,
			status_regulatoriogmregulado: defaultForm.getValues('status_regulatoriogmregulado')?.value?.key,

			estado:					defaultForm.getValues('estado')?.value,
			condicoes:				defaultForm.getValues('condicoes')?.value,
			condicoes_observacoes:	defaultForm.getValues('condicoes_observacoes') ?? '',

			centroCusto:			defaultForm.getValues('centroCusto'),
			numeroProjeto:			defaultForm.getValues('numeroProjeto'),
			
			requisitantesExportadorId:	defaultForm.getValues('requisitantesExportadorId')?.value,
			alerta_requisitantes_exportador:	getRepresentantesListaId('alerta_requisitantes_exportador'),

			requisitantesImportadorId:			defaultForm.getValues('requisitantesImportadorId')?.value,
			alerta_requisitantes_importador:	getRepresentantesListaId('alerta_requisitantes_importador'),

			/*area:					entity?.area,
			origemId:				entity?.origem?.value,
			destinoId:				entity?.destino?.value,
			localEnsaio:			entity?.local,
			objetivo:				entity?.objetivo,
			,*/
			//setAnexos:				setAnexosLst
			//anexosItems.map(e=> e.fileData)
		} as Shipment;

		let formData = new FormData();
		let anexoFiles = defaultForm.getValues('setAnexos');
		if(anexoFiles){
			for(let i = 0, t = anexoFiles.length; i < t; i++){
				formData.append('setAnexos', anexoFiles[0]);    
			}
		}

		return valuesToSubmit; 
	}

	const isCreatRequisicao = ()=> {
		return isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.CriarRequisicao)||
		isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.Admin) 
	}
	const isEditRequisicao = ()=> {
		return isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.EditarRequisicao) ||
		isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.Admin) 
	}
	const isEditFluxo = ()=> {
		return isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.EditarFluxo)||
		isShipmentCheckRoles(roles, SHIPMENT_PERMISSIONS.Admin) 
	}

	

	const parseButton = () => {
		let buttonEnviarAnexos = <Fragment />
		let buttonSalvar  = <Fragment />
		let buttonEnviar  = <Fragment />
		let buttonCancelar = <Fragment />

		let txtCancel = (modoFluxo === MODO_VIEW.EDICAO && (entity?.id == null || entity?.id === 0));

		buttonCancelar = <Button
			title={txtCancel ? "Cancelar/Cancel" : "Voltar/Back"}
			contained={false}
			type="button"
			onClick={() => {
				defaultForm.formState.isDirty ? setStatus(statuses.go_back) :
				history.goBack();
			}}
		/>;
					

		let status_ship = entity?.status ?? STATUS_SHIPMENT.EmCriacao;
		const _isDadosEditavel = (status_ship <= STATUS_SHIPMENT.AguardandoDocumentos) && (entity?.id == null || entity?.id === 0)

		if(modoFluxo === MODO_VIEW.EDICAO && status_ship <= STATUS_SHIPMENT.ReferenciaCriada && isCreatRequisicao()){
			buttonSalvar = <Button
				title={entity?.id == null || entity?.id === 0 ? "Criar Nova Requisição/Create New Requisition" : "Atualizar Requisição/Update Requisition"}			
				type="submit"		
				contained={true}				
				isLoading={isLoadingScreen('salvar')}
			/>;
			
		}		

		
		if(modoFluxo === MODO_VIEW.ANEXOS && isDadosEditavelAnexo)
		{
			buttonEnviarAnexos = <Button
					title="Enviar Anexos/Send Attachments"			
					type="button"		
					contained={true}
					onClick={() => submitForm_anexos()}
					isLoading={isLoadingScreen('anexos')}
				/>;

		}
		if (modoFluxo === MODO_VIEW.ENVIOS && isEditFluxo())
		{
			if (entity?.dataCancelamento === null)
			{ 
				buttonEnviar = <Button
						title="Enviar/Send"
						contained={true}
						type="button"					
						isLoading={isLoadingScreen('enviar')}
						onClick={() =>  submitForm_enviar()}
					/>
			}
		}


		return <div className="button-position addpaddingTop">
			 {buttonCancelar}	
			 {buttonSalvar}
			 {buttonEnviarAnexos}				
			 {buttonEnviar}
		</div>;
	}

	const parseModals = () => {

		let ConfirmAnexo = <ConfirmationModal
				show={anexoRemConfirmeShow.Show}
				confirmationMsg={STRINGS_SHIPMENT.ANEXO_REM_CONFIRM_LEFT_EDITION}
				cancelMsg={STRINGS_SHIPMENT.ANEXO_REM_CANCEL_LEFT_EDITION}
				onConfirm={() => anexo_acao({remove_confirma: true}, anexoRemConfirmeShow.shipObj)}
				onHide={() => setAnexoRemConfirmeShow({Show: false}) }
				title={STRINGS_SHIPMENT.ANEXO_REM_TITLE_CONFIRMATION}
				type="button"
				variant="danger"
			>
				{STRINGS_SHIPMENT.ANEXO_REM_CONFIRMATION_CANCEL}
				<br />
				<span className="span_anexo_filename">{anexoRemConfirmeShow.shipObj?.filename}</span>
			</ConfirmationModal>
		;
		
		let enviarDataFail1 = <ReasonModal
				show={false}
				onConfirm={() => setEnviarStatusFail({Show: false}) }
				onHide={() => setEnviarStatusFail({Show: false}) }
				title={STRINGS_SHIPMENT.ENVIAR_DATAS_FAIL_TITLE}
				actionMsg={STRINGS.REASON_REPROVE_ACTION}
				disabled={false}
				loading={false}
			>
				{STRINGS_SHIPMENT.ENVIAR_DATAS_FAIL_TEXT}
				<br />
				<span className="span_anexo_filename">{enviarStatusFail.textoComplemento}</span>
			</ReasonModal>
		;
		
		let enviarDataFail = <ConfirmationModal
                    show={enviarStatusFail.Show}
                    onHide={() => {
						setEnviarStatusFail({Show: false})
                    }}
                    title={STRINGS_SHIPMENT.ENVIAR_DATAS_FAIL_TITLE}
                    confirmationMsg={STRINGS.REGULATORY_ACTION}
                    onConfirm={() => {
						setEnviarStatusFail({Show: false})
                    }}
                    loading={false}
                    variant="success"
                    color="white"
                >
                    <p>{STRINGS_SHIPMENT.ENVIAR_DATAS_FAIL_TEXT}</p>
					<p>{enviarStatusFail.textoComplemento}</p>
                </ConfirmationModal>;

	
		
		return <Fragment>
			{ConfirmAnexo}
			{enviarDataFail}
		</Fragment>
	}

	const FluxoComponentList = ()=>{
		let itemsDeFluxo = [];
		itemsDeFluxo.push({text: "Referência Criada / Reference Created"
			, fluxoMinimo: STATUS_SHIPMENT.ReferenciaCriada, dataBind: "dataReferenciaCriada", data: null})
		itemsDeFluxo.push({text: "Permissão de Importação / Import Permit"
			, fluxoMinimo: STATUS_SHIPMENT.PermissaoImportacao, dataBind: "dataPermissaoImportacao", data: null})
		itemsDeFluxo.push({text: "Aguardando dados-documentos / Waiting for documents/cargo data"
			, fluxoMinimo: STATUS_SHIPMENT.AguardandoDocumentos, dataBind: "dataAguardandoDocumentos", data: null})
		itemsDeFluxo.push({text: "Documentos Enviados / Documents available"
			, fluxoMinimo: STATUS_SHIPMENT.DocumentosEnviados, dataBind: "dataDocumentosEnviados", data: null})
		itemsDeFluxo.push({text: "Aguardando Green light / Waiting for Green light"
			, fluxoMinimo: STATUS_SHIPMENT.AguardandoGreenLight, dataBind: "dataAguardandoGreenLight", data: null})
		itemsDeFluxo.push({text: "Embarque aprovado / Green light to dispatch"
			, fluxoMinimo: STATUS_SHIPMENT.EmbarqueAprovado, dataBind: "dataEmbarqueAprovado", data: null})
		itemsDeFluxo.push({text: "Coleta-Entrega / Cargo collected"
			, fluxoMinimo: STATUS_SHIPMENT.Coleta_Entrega, dataBind: "dataColetaEntrega", data: null})
		itemsDeFluxo.push({text: "Em Liberação na Origem / Under clearance at origin"
			, fluxoMinimo: STATUS_SHIPMENT.EmLiberacaoOrigem, dataBind: "dataEmLiberacaoOrigem", data: null})
		itemsDeFluxo.push({text: "Em trânsito internacional / International Transit"
			, fluxoMinimo: STATUS_SHIPMENT.EmTransitoInternacional, dataBind: "dataEmTransitoInternacional", data: null})
		itemsDeFluxo.push({text: "Em Liberação no Destino / Under Clearance at Destiny"
			, fluxoMinimo: STATUS_SHIPMENT.EmLiberadaDestino, dataBind: "dataEmLiberadaDestino", data: null})
		if (CheckImportBR()) {
			itemsDeFluxo.push({
				text: "Em quarentena"
				, fluxoMinimo: STATUS_SHIPMENT.EmQuarentena, dataBind: "dataEmQuarentena", data: null})
		}

		itemsDeFluxo.push({text: "Em trânsito para o destino final / In transit to final destination"
			, fluxoMinimo: STATUS_SHIPMENT.EmTransitoFinal, dataBind: "dataEmTransitoDestinoFinal", data: null})
		itemsDeFluxo.push({text: "Entregue / Cargo delivered"
			, fluxoMinimo: STATUS_SHIPMENT.Entregue, dataBind: "dataEntregue", data: null})
		
		return itemsDeFluxo;
	}

	const CheckImportBR = () =>{
		var pais_code = defaultForm.getValues('pais_importador')?.value?.code ?? null;
		//var paisE = defaultForm.getValues('pais_exportador')?.value?;		
		if(pais_code === 'BR' || paisesExImp?.PaisImp === 'BR') return true;
		return false;
	};
	const CheckImportBRPorDados = (dataFromServer:any) =>{
		var pais_code = dataFromServer?.pais_importador?.value?.code ?? null;
		//var paisE = defaultForm.getValues('pais_exportador')?.value?;		
		if(pais_code === 'BR' || paisesExImp?.PaisImp === 'BR') return true;
		return false;
	};

	const StepperFluxoComponentRenderAll = () => {
		let itemsDeFluxo = FluxoComponentList();		
		let status = botoesEnvio.StatusAtual <= 11 ? (botoesEnvio.StatusAtual - 1) : (botoesEnvio.StatusAtual - 2);
		let activeStep = botoesEnvio.StatusAtual <= 12 ? (botoesEnvio.StatusAtual - 3) : (botoesEnvio.StatusAtual - 4);

		return <div>
			<Stepper alternativeLabel nonLinear activeStep={ (botoesEnvio.StatusAtual < 3) ? 0 : activeStep}>
				{itemsDeFluxo.map((item, index) => (					
					<Step key={item.dataBind}>
						<div className="StepItemNTxt">
						<StepButton color="inherit" >
								{
									(item.fluxoMinimo === status) ? item.text : ''
								}							
						</StepButton>
						</div>
					</Step>
				))}
			</Stepper>
		</div>;
	}	
	const DataFuturaTest = (item: any) => {		
		let d = defaultForm.getValues(item.dataBind) as Date;
		if(item.data != null) {
			d = item.data;
		}else {
			item.data = d;
		}
		if(moment(d).toDate().getDate() > new Date().getDate()){			
			return true;
		}
		return false;
	}

	const AnteriorPreenchido = (itemsDeFluxo:any[], item:any, i: any) => {
		var atual = botoesEnvio.StatusAtual - 1;
		if(!CheckImportBR() && botoesEnvio.StatusAtual === STATUS_SHIPMENT.EmTransitoFinal){
			atual = botoesEnvio.StatusAtual - 2;
			return (item.fluxoMinimo === atual)
		}
		return (item.fluxoMinimo === atual)
	};

	const DataFluxoComponentRenderAll = () => {
		let itemsDeFluxo = FluxoComponentList();		

		let _SimuladoStatusAtual = Number(defaultForm.getValues('status') ?? STATUS_SHIPMENT.ReferenciaCriada);

		let creat = <div>
			{
			itemsDeFluxo
			.filter(item=>  item.fluxoMinimo <= botoesEnvio.StatusAtual)
			.map(item=>
			<DataFluxoComponent key={item.dataBind}
			fluxo={item.fluxoMinimo}
			buttonEnviar={item.fluxoMinimo === botoesEnvio.StatusAtual}
			reenviar={item.fluxoMinimo < (botoesEnvio.StatusAtual - 1)}
			placeholderText="Selecionar a data/Select date"
			text={item.text}
			control={defaultForm.control}
			name={item.dataBind}
			value={defaultForm.watch(item.dataBind)}
			enabled={isEditFluxo()}
			isClearable={isEditFluxo() && AnteriorPreenchido(itemsDeFluxo, item, item.fluxoMinimo)}
			DatePickerClassName={ DataFuturaTest(item) ? 'ViewDatePickerDateFuture' : ''}
			minDate={defaultForm.watch().condicoes_observacoes}	
			
			onDataChanged={(d:any)=>{
				item.data = d;
				//setViewDatePickerClassName('');
				if(d == null){
					let _simuladoStatusAtualE = item.fluxoMinimo;
					//let nextItem = 
					//LimparProximo(item)
					
					setBotoesEnvio({ StatusAtual: _simuladoStatusAtualE })
					return;
				}
				
				if(moment(d).toDate().getDate() > new Date().getDate()){
					//DatePickerClassName=""
					//setViewDatePickerClassName(item.dataBind);
				}
				let min = item.fluxoMinimo
				let obj = {val: min}
				enviarEmailFluxoCheck(item.fluxoMinimo, d, obj);
				let _simuladoStatusAtual = Number(obj.val);
				setBotoesEnvio({ StatusAtual: _simuladoStatusAtual })
				}}
			/>
			)}

		 </div>;

		return creat;
	};

	const AnexoAcoes = ({ data }: any) => {	
		const isEdit = true;
		return (
			<Fragment>
				<ButtonCustom 
					title="Remover arquivo/Remove file"
					isLoading={isLoadingScreen('remove_anexos')}
				>
					<GoCircleSlash style={{color: !isEdit ? '#c9cacc' : 'red'}} 
					className="cancel-btn" size={20} 
					onClick={()=> anexo_acao({remove: true}, data) } />
				</ButtonCustom>
				<ButtonCustom 
					title="Download de Arquivo/Download file"
					isLoading={isLoadingScreen('download_anexos')}
					>
					<FaFileDownload className="generate-btn" size={20} 
					onClick={()=> anexo_acao({downld: true}, data) } />
				</ButtonCustom>
			</Fragment>);
	};
	
	useEffect(() => {

		clearPageMessages();

		const getDataToForm = async (FinishHandler : any) => {

			setLoadingScreen('get_data_form');
			
			try
			{
				const cultures = await api.get<CultureResult[]>(apiUrlShipment_GetCulturas)

				//const locations = await api.get<UnityStationResult[]>('api/v1/shipment/UnidadeEstacao/obterporfiltro?Ativo=true');
				const statusRegulatorioArrays = await api.get<Enum[][]>(apiUrlShipment_GetStatusRegulatorioArrays);
				const material = await api.get<CultureResult[]>(apiUrlShipment_GetMaterial);
				const status = await api.get<Enum[]>(apiUrlShipment_GetStatus);
				const estadocondicao = await api.get<Enum[][]>(apiUrlShipment_GetEstadoCondicao);
				const requistanteslst = await api.get<RequsitanteDto[]>(apiUrlShipment_GetRequisitantes);

				if (requistanteslst.status === 200)
					setRequisitantes(requistanteslst.data);
				
				if (statusRegulatorioArrays.status === 200)
				{
					setStatusRegulatorio(statusRegulatorioArrays.data[0]);
					setStatusRegulatorioGM(statusRegulatorioArrays.data[1]);
					setStatusRegulatorioGMRegulado(statusRegulatorioArrays.data[2]);
				}

				if (cultures.status === 200)
					setCultures(cultures.data);
				
				if (material.status === 200)
					setEnumMaterial(material.data);

				if (estadocondicao.status === 200)
				{
					setEnumEstado(estadocondicao.data[0])
					setEnumCodicao(estadocondicao.data[1])
				}				

				FinishHandler(statusRegulatorioArrays.data, estadocondicao.data, requistanteslst.data);
				
			}
			catch (error:any)
			{
				ParseDefaultBackendMessage(error);
				GoBackToTop();
			}
			
			setLoadingScreen('get_data_form', false);
		}

		const getEntity = async (entityId:any, _statusRegulatorioArrays: any, _estadocondicao: any, _requisitantesLst: any) => {

			setLoadingScreen('get_data_entity');

			try
			{
				const { data, status } = await api.get<Shipment>(`${apiUrlShipment}/${entityId}`);

				if ([200,201].indexOf(status) <= -1)
					throw new Error('Erro ao obter os dados da entidade!');

				let stReg = (_statusRegulatorioArrays[0] as Enum[]).find(e=> Number(e.key) === data.status_regulatorio);
				let stRegMg = (_statusRegulatorioArrays[1] as Enum[]).find(e=> Number(e.key) === data.status_regulatoriogm);
				let stRegReg = (_statusRegulatorioArrays[2] as Enum[]).find(e=> Number(e.key) === data.status_regulatoriogmregulado);

				let estd = (_estadocondicao[0] as Enum[]).find(e=> Number(e.key) === data.estado);
				let cond = (_estadocondicao[1] as Enum[]).find(e=> Number(e.key) === data.condicoes);
				
				let pais_e = data.pais_exportador.split("/");
				let pais_i = data.pais_importador.split("/");
				let pais_o = data.pais_origem === null ? '' : data.pais_origem.split("/");

				let requE = (_requisitantesLst as RequsitanteDto[]).find(e=> e.id === data.requisitantesExportadorId);
				let requI = (_requisitantesLst as RequsitanteDto[]).find(e=> e.id === data.requisitantesImportadorId);

				let requAE = (_requisitantesLst as RequsitanteDto[]).filter(e=> data.alerta_requisitantes_exportador?.includes(e.id));
				requAE = requAE.map(r=> Object.assign({ value: r?.id, label: `${r?.nome } (${r?.email})` }))

				let requAI = (_requisitantesLst as RequsitanteDto[]).filter(e=> data.alerta_requisitantes_importador?.includes(e.id));
				requAI = requAI.map(r=> Object.assign({ value: r?.id, label: `${r?.nome } (${r?.email})` }))

				let dataFromServer = {
					//...data,
					id				: 			data.id,
					codigo			: 			data.codigo,
					solicitante		:			data.solicitante,
					status			:			data.status,
					estado			:			{ value: estd?.key, label: estd?.value },
					condicoes		:			{ value: cond?.key, label: cond?.value },
					condicoes_observacoes:		data.condicoes_observacoes,
					centroCusto		:			data.centroCusto,
					numeroProjeto	:			data.numeroProjeto,
					pais_exportador	:			{ value: { code: pais_e[0], name: pais_e[1] }, label: pais_e[1]},
					pais_importador	:			{ value: { code: pais_i[0], name: pais_i[1] }, label: pais_i[1]},
					pais_origem     :           { value: { code: pais_o[0], name: pais_o[1] }, label: pais_o[1] },
					requisitantesExportadorId:  { value: requE?.id, label: `${requE?.nome } (${requE?.email})` },
					requisitantesImportadorId: 	{ value: requI?.id, label: `${requI?.nome } (${requI?.email})` },
					alerta_requisitantes_exportador:	requAE,
					alerta_requisitantes_importador:	requAI,					
					status_regulatorio		:	{ value: stReg, label: stReg?.value },
					status_regulatoriogm	:	{ value: stRegMg, label: stRegMg?.value },				
					status_regulatoriogmregulado:	{ value: stRegReg, label: stRegReg?.value },
					culturaId		:			{ value: data.cultura?.id, label: data.cultura?.nome },
					materialId		: 			{ value: data.material?.id, label: data.material?.nome },
					
					dataAguardandoDocumentos	: data.dataAguardandoDocumentos ? moment(data.dataAguardandoDocumentos).toDate() : null,
					dataAguardandoGreenLight	: data.dataAguardandoGreenLight ? moment(data.dataAguardandoGreenLight).toDate() : null,
					dataColetaEntrega			: data.dataColetaEntrega 		? moment(data.dataColetaEntrega).toDate() : null,
					dataDocumentosEnviados		: data.dataDocumentosEnviados 	? moment(data.dataDocumentosEnviados).toDate() : null,
					dataEmLiberacaoOrigem		: data.dataEmLiberacaoOrigem 	? moment(data.dataEmLiberacaoOrigem).toDate() : null,
					dataEmLiberadaDestino		: data.dataEmLiberadaDestino 	? moment(data.dataEmLiberadaDestino).toDate() : null,
					dataEmQuarentena			: data.dataEmQuarentena 		? moment(data.dataEmQuarentena).toDate() : null,
					dataEmTransitoDestinoFinal	: data.dataEmTransitoDestinoFinal ? moment(data.dataEmTransitoDestinoFinal).toDate() : null,
					dataEmTransitoInternacional	: data.dataEmTransitoInternacional ? moment(data.dataEmTransitoInternacional).toDate() : null,
					dataEmbarqueAprovado		: data.dataEmbarqueAprovado 	? moment(data.dataEmbarqueAprovado).toDate() : null,
					dataEntregue: data.dataEntregue ? moment(data.dataEntregue).toDate() : null,
					dataPermissaoImportacao: data.dataPermissaoImportacao ? moment(data.dataPermissaoImportacao).toDate() : null,
					dataReferenciaCriada		: data.dataReferenciaCriada 	? moment(data.dataReferenciaCriada).toDate() : null,

					dataCancelamento			: data.dataCancelamento ? moment(data.dataCancelamento).toDate() : null,
					responsavelCancelamento		: data.responsavelCancelamento,
					motivoCancelamento			: data.motivoCancelamento,
				};

				setPaisesExImp({PaisExp: pais_e[0], PaisImp: pais_i[0]})

				defaultForm.reset(dataFromServer);
				let incremento = 1;
				if(!CheckImportBRPorDados(dataFromServer) && (dataFromServer.status ?? -1) >= STATUS_SHIPMENT.EmLiberadaDestino )
				{
					incremento++;
				}
				setBotoesEnvio({ StatusAtual: ((dataFromServer?.status ?? 0) + incremento) })		
				setEntity(dataFromServer);		
				setModoFluxo(MODO_VIEW.ENVIOS)	
				
				let status_ship = data.status ?? STATUS_SHIPMENT.EmCriacao;
				const _isDadosEditavel = (status_ship <= STATUS_SHIPMENT.AguardandoDocumentos);//&& (data.id == null || data.id === 0)
				setIsDadosEditavel(_isDadosEditavel && isEditRequisicao());

				// const _isDadosEditavelA = (status_ship <= STATUS_SHIPMENT.ReferenciaCriada && isCreatRequisicao() && isEditRequisicao())
				// setIsDadosEditavelAnexo(_isDadosEditavelA)
				const _isDadosEditavelA = ((isCreatRequisicao() && isEditRequisicao()) || isEditFluxo())
				setIsDadosEditavelAnexo(_isDadosEditavelA)
				setAnexosList(data.getAnexos);

			}
			catch (error:any)
			{
				console.error("ERRO:", error);
				
				ParseDefaultBackendMessage(error);
				GoBackToTop();
			}

			setLoadingScreen('get_data_entity', false);
		}

		getDataToForm((_statusRegulatorioArrays:any, _estadocondicao: any, _requisitantesLst: any)=>{
			if (location.state && location.state.id)
			getEntity(location.state.id, _statusRegulatorioArrays, _estadocondicao, _requisitantesLst);
		})			

	}, [location.state, defaultForm.reset]);

	let Botton = <Fragment />
	if(!(entity?.id == null || entity?.id === 0))
	{
		

		Botton =  <div className="button-position tab-buttons-local">
			<Button
				title="Dados da Solicitação/Requisition Data"
					contained={false}
					className={modoFluxo === MODO_VIEW.EDICAO ? "active" : '' }
					type="button"
					onClick={() => {
						setModoFluxo(MODO_VIEW.EDICAO);
					}}
				/>			
			
			<Button
				title="Anexos/Attachments"			
					className={modoFluxo === MODO_VIEW.ANEXOS ? "active" : '' }	
					contained={false}
					type="button"
					onClick={() => {
						setModoFluxo(MODO_VIEW.ANEXOS);
					}}
				/>

			<Button
				title="Fluxo de Envio/Process Flow"
					className={modoFluxo === MODO_VIEW.ENVIOS ? "active" : '' }
					contained={false}
					type="button"
					onClick={() => {
						setBotoesEnvio({ StatusAtual: ((entity?.status ?? 0) + 1) })
						setModoFluxo(MODO_VIEW.ENVIOS);
					}}
				/>
			</div>;

	}
	
	return (
		<div className="container container-fluid solicitacao-edit">
			<NavigationMessage messages={pageMessages} />
			<If condition={loading.length > 0}>
				<LoadingInForm />
			</If>
			
			<h3>{STRINGS_SHIPMENT.REGISTER_SHIPMENT}</h3>
			<br />
			{Botton}			
			<Form onSubmit={defaultForm.handleSubmit(submitForm_salvar)}>
				<If condition={modoFluxo === MODO_VIEW.EDICAO}>
					<Fieldset disabled={false} title={STRINGS_SHIPMENT.BLOCK_ONE}>
						<Form.Row className="row-custom">
							<If condition={ !(entity?.id == null || entity?.id === 0) }>
								<Form.Group as={Col}>
									<Form.Label>Código/Code</Form.Label>
									<Form.Control
										ref={defaultForm.register}
										name="codigo"
										readOnly
										maxLength={100}
									/>
								</Form.Group>
							</If>
							<Form.Group as={Col}>
								<Form.Label>Solicitante/Requestor</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									name = "solicitante"
									readOnly
									maxLength={100}
								/>
							</Form.Group>		
							
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									País Exportador/Exporting Country<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="pais_exportador"
									required
									
									isInvalid={defaultForm.errors.pais_exportador !== undefined}
									as={<Select
										isDisabled={!isDadosEditavel}
										placeholder="Insira o País Exportador/Select Exporting Country"										
										options={countriesWithCode.map(local => Object.assign({ value: local, label: local.name }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.pais_exportador}>
									<p className="error-field">{defaultForm.errors.pais_exportador?.message}</p>
								</If>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									País Importador/Importing Country<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="pais_importador"
									required
									readOnly={!isDadosEditavel}
									isInvalid={defaultForm.errors.pais_importador !== undefined}
									as={<Select
										isDisabled={!isDadosEditavel}
										placeholder="Insira o País Importador/Select Importing Country"
										options={countriesWithCode.map(local => Object.assign({ value: local, label: local.name }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.pais_importador}>
									<p className="error-field">{defaultForm.errors.pais_importador?.message}</p>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									País de Origem/Origin's Country<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="pais_origem"
									required

									isInvalid={defaultForm.errors.pais_origem !== undefined}
									as={<Select
										isDisabled={!isDadosEditavel}
										placeholder="Insira o País de Origem/Select Origin Country"
										options={countriesWithCode.map(local => Object.assign({ value: local, label: local.name }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.pais_origem}>
									<p className="error-field">{defaultForm.errors.pais_origem?.message}</p>
								</If>
							</Form.Group>
							<Form.Group as={Col}>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Cultura/Crop<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="culturaId"
									readOnly={!isDadosEditavel}
									isInvalid={defaultForm.errors.culturaId !== undefined}
									as={<Select
										isDisabled={!isDadosEditavel}
										placeholder="Selecione uma Cultura/Select Crop"
										options={cultures.map(local => Object.assign({ value: local.id, label: local.nome }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.culturaId}>
									<p className="error-field">{defaultForm.errors.culturaId?.message}</p>
								</If>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Material/Material<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="materialId"
									readOnly={!isDadosEditavel}
									isInvalid={defaultForm.errors.materialId !== undefined}
									as={<Select
										isDisabled={!isDadosEditavel}
										placeholder="Selecione um Material/Select Material"
										options={enumMaterial.map(local => Object.assign({ value: local.id, label: local.nome }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.materialId}>
									<p className="error-field">{defaultForm.errors.materialId?.message}</p>
								</If>
							</Form.Group>						
						</Form.Row>

						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Status Regulatório/Regulatory Status<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="status_regulatorio"
									isInvalid={defaultForm.errors.status_regulatorio !== undefined}									
									as={<Select	
										isDisabled={!isDadosEditavel}
										placeholder="Selecione um Status Regulatório/Select Regulatory Status"
										options={statusRegulatorio.map(item => Object.assign({ value: item, label: item.value }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.status_regulatorio}>
									<p className="error-field">{defaultForm.errors.status_regulatorio?.message}</p>
								</If>
							</Form.Group>
							<If condition={defaultForm.watch().status_regulatorio?.value?.accept === true}>
								<Form.Group as={Col}>
									<Form.Label>
										GM/GM<em>*</em>
									</Form.Label>
									<Controller
										control={defaultForm.control}
										name="status_regulatoriogm"
										isInvalid={defaultForm.errors.status_regulatoriogm !== undefined}
										as={<Select
											isDisabled={!isDadosEditavel}
											placeholder="Selecione um GM/Select GM"
											options={statusRegulatorioGM.map(item => Object.assign({ value: item, label: item.value }))}
											isClearable
										/>}
									/>
									<If condition={defaultForm.errors.status_regulatoriogm}>
										<p className="error-field">{defaultForm.errors.status_regulatoriogm?.message}</p>
									</If>
								</Form.Group>	
							</If>
							<If condition={defaultForm.watch().status_regulatoriogm?.value?.accept === true}>
								<Form.Group as={Col}>
									<Form.Label>
										Regulado/Regulated<em>*</em>
									</Form.Label>
									<Controller
										control={defaultForm.control}
										name="status_regulatoriogmregulado"
										isInvalid={defaultForm.errors.status_regulatoriogmregulado !== undefined}
										as={<Select
											isDisabled={!isDadosEditavel}
											placeholder="Selecione um Regulado/Select Regulated"
											options={statusRegulatorioRegulado.map(item => Object.assign({ value: item, label: item.value }))}
											isClearable
										/>}
									/>
									<If condition={defaultForm.errors.status_regulatoriogmregulado}>
										<p className="error-field">{defaultForm.errors.status_regulatoriogm?.message}</p>
									</If>
								</Form.Group>	
							</If>
										
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Estado/State<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="estado"
									isInvalid={defaultForm.errors.estado !== undefined}
									as={<Select
										isDisabled={!isDadosEditavel}
										placeholder="Selecione um Estado/Select State"
										options={enumEstado.map(local => Object.assign({ value: local.key, label: local.value }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.estado}>
									<p className="error-field">{defaultForm.errors.estado?.message}</p>
								</If>
							</Form.Group>	
							<Form.Group as={Col}>
								<Form.Label>
									Condição/Condition<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="condicoes"
									isInvalid={defaultForm.errors.condicoes !== undefined}
									as={<Select
										isDisabled={!isDadosEditavel}
										placeholder="Selecione um Condição/Select Cargo Temp."
										options={enumCodicao.map(local => Object.assign({ value: local.key, label: local.value }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.condicoes}>
									<p className="error-field">{defaultForm.errors.condicoes?.message}</p>
								</If>
							</Form.Group>						
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>Centro de Custo/Cost Center</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									placeholder="Insira o Centro de Custo/Insert Cost Center"
									name="centroCusto"
									maxLength={100}
									disabled={!isDadosEditavel}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Número do Projeto/Project Number</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									placeholder="Insira o Número do Projeto/Insert Project Number"
									name="numeroProjeto"
									maxLength={100}
									disabled={!isDadosEditavel}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>Observações/Notes</Form.Label>
								<Form.Control
									as="textarea"
									ref={defaultForm.register}
									name="condicoes_observacoes"
									type="text"
									placeholder="Observações/Comments"
									maxLength={2000}
									rows={5}
									disabled={!isDadosEditavel}
								/>
							</Form.Group>
						</Form.Row>		
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Requisitante Exportador/Sender<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="requisitantesExportadorId"
									isInvalid={defaultForm.errors.requisitantesExportadorId !== undefined}
									as={<Select
										isDisabled={!isDadosEditavel}
										placeholder="Selecione um Requisitante/Select Requester"
										options={requisitantes.map(local => Object.assign({ value: local.id, label: `${local.nome } (${local.email})` }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.requisitantesExportadorId}>
									<p className="error-field">{defaultForm.errors.requisitantesExportadorId?.message}</p>
								</If>
								
							</Form.Group>	

							<Form.Group as={Col}>
								<Form.Label>
									Requisitante Importador/Receiver<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="requisitantesImportadorId"
									isInvalid={defaultForm.errors.requisitantesImportadorId !== undefined}
									as={<Select	
										isDisabled={!isDadosEditavel}									
										placeholder="Selecione um Requisitante/Select Requester"
										options={requisitantes.map(local => Object.assign({ value: local.id, label: `${local.nome } (${local.email})` }))}
										isClearable
									/>}
								/>
								<If condition={defaultForm.errors.requisitantesImportadorId}>
									<p className="error-field">{defaultForm.errors.requisitantesImportadorId?.message}</p>
								</If>							
								
							</Form.Group>	

						</Form.Row>		

						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Alerta Secundário Exportador/Additional sender<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="alerta_requisitantes_exportador"
									isInvalid={defaultForm.errors.alerta_requisitantes_exportador !== undefined && (defaultForm.errors.alerta_requisitantes_exportador as any[])?.length > 0}
									as={<Select
										isDisabled={!isDadosEditavel}
										isMulti={true}
										placeholder="Selecione um Requisitante/Select Requester"
										options={requisitantes.map(local => Object.assign({ value: local.id, label: `${local.nome } (${local.email})` }))}
										isClearable
									/>}
								/>

								<If condition={defaultForm.errors.alerta_requisitantes_exportador}>
									<p className="error-field">{(defaultForm.errors.alerta_requisitantes_exportador)?.message}</p>
								</If>

							</Form.Group>	

							<Form.Group as={Col}>
								<Form.Label>
									Alerta Secundário Importador/Additional Receiver<em>*</em>
								</Form.Label>
								<Controller
									control={defaultForm.control}
									name="alerta_requisitantes_importador"
									isInvalid={defaultForm.errors.alerta_requisitantes_importador !== undefined && (defaultForm.errors.alerta_requisitantes_exportador as any[])?.length > 0}
									as={<Select
										isDisabled={!isDadosEditavel}
										isMulti={true}
										placeholder="Selecione um Requisitante/Select Requester"
										options={requisitantes.map(local => Object.assign({ value: local.id, label: `${local.nome } (${local.email})` }))}
										isClearable
									/>}
								/>
								
								<If condition={defaultForm.errors.alerta_requisitantes_importador}>
									<p className="error-field">{(defaultForm.errors.alerta_requisitantes_importador)?.message}</p>
								</If>
							</Form.Group>	

						</Form.Row>	

					</Fieldset>
				</If>
				<If condition={modoFluxo === MODO_VIEW.ANEXOS}>
					<Fieldset disabled={false} title={STRINGS_SHIPMENT.BLOCK_TWO}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}> 
								<If condition={isDadosEditavelAnexo}>
									<Form.File				
										ref={defaultForm.register}
										name="setAnexos"
										label={
											anexosItemsNames.map(e=> e).join(", ")
										}
										data-browse="Adicionar Arquivo"
										multiple={true}
										custom
										onChange={({ target }: any) => {
											if (!target.value) {
												return
											}
											let Lista = [];
											for(let i = 0, t = target.files.length; i < t; i++){
												Lista.push(target.files[i]?.name);
											}
											setAnexosNames(Lista);
										}}
									/>	
								</If>

								<div className="ag-theme-alpine" style={{ height: "50vh", width: '100%', paddingBottom: '30px' }}>
									<AgGridReact
										rowData={anexosItems}
										defaultColDef={{ flex: 1, sortable: true, resizable: true }}
										pagination
										paginationPageSize={10}
										gridOptions={{ headerHeight: 80 }}
										frameworkComponents={{
											actions: AnexoAcoes,
										}}
									>
										<AgGridColumn
											headerName='Nome do Arquivo/Filename'
											field='filename'											
											sort="asc"
											cellStyle={{
												justifyContent: 'left'
											}}
										/>
										<AgGridColumn
											field='Ações/Actions'
											cellRenderer='actions'
											maxWidth={100}
										/>
									</AgGridReact>
								</div>						
							</Form.Group>
						</Form.Row>		
					</Fieldset>
				</If>
				<If condition={modoFluxo === MODO_VIEW.ENVIOS}>	
					<If condition={success}>
						<AlertSuccess
							message={STRINGS_SHIPMENT.ALERT_SEND_SUCCESS}
						/>
					</If>
					<If condition={entity?.dataCancelamento !== null}>
						<Fieldset disabled={false} title={STRINGS_SHIPMENT.BLOCK_CANCEL}>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={4}>
									<Form.Label>Responsável pelo Cancelamento</Form.Label>
									<Form.Control
										ref={defaultForm.register}
										name="responsavelCancelamento"
										readOnly
									/>
								</Form.Group>
								<Form.Group as={Col} md={4}>
									<Form.Label>Data do Cancelamento</Form.Label>
									<Controller
										control={defaultForm.control}
										name="dataCancelamento"
										readOnly
										render={({ onChange, value }) => (
											<DatePicker
												disabled={true}
												selected={value}
												onChange={onChange}
												className="input-custom-to-date"
												locale="pt-BR"
												dateFormat="dd/MM/yyyy"
												placeholderText="Selecionar a data"
												maxDate={new Date()}
											/>
										)}
									/>

								</Form.Group>
							</Form.Row>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>Motivo do Cancelamento</Form.Label>
									<Form.Control
										ref={defaultForm.register}
										name="motivoCancelamento"
										as="textarea"
										rows={5}
										readOnly
									/>
								</Form.Group>
							</Form.Row>
						</Fieldset>
					</If>
					<Fieldset disabled={false} title={STRINGS_SHIPMENT.BLOCK_THR}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
									<Form.Label>Código/Code</Form.Label>
									<Form.Control
										ref={defaultForm.register}
										name="codigo"
										readOnly
										maxLength={100}
									/>
								</Form.Group>
						</Form.Row>
						{StepperFluxoComponentRenderAll()}
						{DataFluxoComponentRenderAll()}
					</Fieldset>		
				</If>		
				{parseButton()}		
			</Form>
			{parseModals()}	
		</div>
	);
}