import React from 'react';
import { Alert } from 'react-bootstrap';

interface Props {
  message: string;
}

function AlertSuccess({ message }: Props) {
  return (
    <div>
      <Alert variant="success">
        <span className="alert-message">{message}</span>
      </Alert>
    </div>
  );
}

export default AlertSuccess;