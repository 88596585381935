import { CSSProperties, useEffect, useRef, useState } from "react";
import {
	//BrowserRouter as Router,
	useParams,
	useHistory
} from "react-router-dom";
import { Form, Col, Collapse } from "react-bootstrap";
import Button from "../../components/Button";
import { useLocation } from "react-router-dom";
import NavigationMessage, { MessageContent, MessageType } from "../NavigationMessages";

import "./../form/style.scss"
import { uuid } from "../../services/uuid";
import React from "react";
import Loading from "../Loading";

interface ParamsType{
	id: string
}
interface StateType {
	readonly: boolean;
	id: number;
}
export interface OnSaveSubmitResult{
	isOk?			: boolean;
	message?		: string;
}
interface EditViewProps<FormType>
{
	id				?: any;
	title			: string;	
	form			: any;
	entity			: FormType | null | undefined;
	children		?: any;
	loading			?: boolean;
	loadingMsg		?: string;

	OnSaveSubmit		?: 	(newvalues:any, entity:any) => Promise<OnSaveSubmitResult>;
	OnSaveSubmitErro	?: 	(errData:any) => void;
	OnSaveSubmitIsOk	?:	() => void;
	OnSaveSubmitIsOkGoBack?	:() => void;

	MsgSaveSubmitSucess	?:any;
	MsgPopUpClear		?: boolean;
	parseButtons		?: any;
	ReloadById			?: boolean;

	setformRef			?: (f: React.RefObject<HTMLFormElement>) => any;
}

export default function EditBox<FormType>(props: EditViewProps<FormType>){
	const formRef 		= useRef<HTMLFormElement>(null);
	const history 		= useHistory();
	
	const [loadinEntity, 	setLoadinEntity] = useState<boolean>(false);
	const [loadingSubmit, 	setLoadingSubmit] = useState<boolean>(false);
	
	const GoBackToTop = () => {window.scrollTo({ top: 0, behavior: 'smooth' })}
	const [pageMessages, setPageMessages] = useState<MessageContent[]>([]);
	const hasPageMessages = () : boolean => {return pageMessages.length > 0;}
	const clearPageMessages = async () => {await setPageMessages([]); }
	const addPageMessages = (message : MessageContent, clearMessages : boolean = true) => {
		let currentPageMessages:MessageContent[] = !clearMessages ? pageMessages : [];
		setPageMessages([]);
		currentPageMessages.push(message);
		setPageMessages(currentPageMessages);
	}
	const setSuccessMessageAndRedirect = (message : string, callback: any) => {
		//setStatus(statuses.idle);
		addPageMessages({type:MessageType.success, content:message});
		GoBackToTop();
		setTimeout(() => { callback(); }, 3500);
	}

	React.useEffect(() => {
		if(props.MsgPopUpClear === true) {clearPageMessages();}
	}, [props.MsgPopUpClear]);

	const parseDefaultButton = (formref_:any) => {
		const btnCancelar = <Button title="Voltar" contained={false}
			type="button" onClick={() => { history.goBack(); }}
		/>;
        const btnSalvar = <Button  title="Salvar" isLoading={loadingSubmit}
        contained={true} onClick={()=> setLoadingSubmit(true)} className="btn-success" type="submit" />;
		
        return <div className="button-position">
			{btnCancelar} {btnSalvar}
		</div>;
	}

	const submitForm = async (values:any) => {
		try {
			setLoadingSubmit(true);
			let _submitOk : OnSaveSubmitResult = {isOk: false};
			if(props.OnSaveSubmit){	
				let newvalues = {
					...props.entity,
					...values
				}			
				_submitOk = await props.OnSaveSubmit(newvalues, props.entity);
				if(!_submitOk.isOk) { 
					setLoadingSubmit(false); 
					return;
				}
			}
			setSuccessMessageAndRedirect( _submitOk.message ?? (props.MsgSaveSubmitSucess ?? ""), () => {
				if(props.OnSaveSubmitIsOk && !props.OnSaveSubmitIsOkGoBack){
					props.OnSaveSubmitIsOk()
				}
				else if(props.OnSaveSubmitIsOkGoBack) {
					history.goBack();
				}
			})
		} catch (error:any) {
			console.error(error)
		}
		setLoadingSubmit(false);
	};

	const errosHandler = (errData:any) =>{
		if(props.OnSaveSubmitErro!= null){ props.OnSaveSubmitErro(errData); }
		console.log(errData);
	};
    return (
		<div className="container container-fluid">
			<NavigationMessage messages={pageMessages} />			
			<Form id={props.id ?? uuid()} ref={formRef} onSubmit={props.form.handleSubmit(submitForm, errosHandler)}>
				{props.setformRef ? props.setformRef(formRef)  : ''}
				<h3 className="mb-12 form-group col">{props.title}</h3>
				{!loadinEntity ? <>
				<Form.Group className="mb-0" as={Col}>
					<Form.Row className="row-custom">
						{props.children}
						 
					</Form.Row>    
				</Form.Group>
				<div className="button-position">
					{props.parseButtons ? props.parseButtons (formRef) : parseDefaultButton(formRef)}
				</div>
				</>
				:<span id="content-loading"><Loading /></span>
					
				}
				
			</Form>
		</div>
	);
}
