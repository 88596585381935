import React from "react";
import { Form, Col } from "react-bootstrap";
import TextField from "./TextField";
import "./style.scss";

interface Props<Type>
{
	form? 		: any;// UseFormMethods<Type>;	
	enabled?	:boolean;
	layoutColumns? 	: number;
	label? 		: string;
	required? 	: boolean;
	children?	: any;
	
	// name? 		: string;
	// label? 		: string;
	// placeholder? : string;
	// adicionalText? : string;
	// length? 	: number;
	// required? 	: boolean;
	// readonly? 	: boolean;
	// autoComplete? 	: boolean;
	// layoutColumns? 	: number;
	// className?	:string;
	// enabled?	:boolean;
	// isNumer?	: boolean;
	// isInvalid?	: any;
	// isInvalidCheck? : boolean;
	// isClearable?: boolean;
	// onChange?	: any;
	// onKeyDown?	: any;
	// key?		: any;
	// defaultValue?: any;
}
interface PropsField
{
	form? 		: any;// UseFormMethods<Type>;	
	name? 		: string;
	readonly? 	: boolean;
	enabled?	:boolean;
	key?		: any;
	onChange?	: any;
	onKeyDown?	: any;
	placeholder? : string;
	adicionalText? : string;
	defaultValue?: any;
	isNumer?	: boolean;
	isInvalid?	: any;
	isInvalidCheck? : boolean;
	isClearable?: boolean;
	length? 	: number;
	autoComplete? 	: boolean;
	className?	:string;
	layoutChildColumns? 	: number;
	prefix?		: any;
	suffix?		: any;
	thousandsGroupStyle?: 'thousand' | 'lakh' | 'wan';
	required?	: boolean;
}
export function Field(chprops: PropsField)
{
	const [enabled_, setEnabled] = React.useState<boolean>(true);
	//	{/* {props?.isInvalid ? props?.isInvalid(props.form.errors) : ''} */}

	React.useEffect(() => {
        if(chprops.enabled === true || chprops.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    },[chprops.enabled]);

	return <TextField {...chprops} layoutColumns={chprops.layoutChildColumns} FormGroupClassName="margin-bottom"  />
}
export default function TextFields<Type> (props: Props<Type>)
{
	const [enabled_, setEnabled] = React.useState<boolean>(true);
	//	{/* {props?.isInvalid ? props?.isInvalid(props.form.errors) : ''} */}

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    },[props.enabled]);

	const Field = () =>{

	};

	return <Form.Group as={Col} md={props.layoutColumns ?? 12}>
		<Form.Label>
			{props.label} {props.required ? <em>*</em> : ''}
		</Form.Label>
		<Form.Row>
		{props.children.map((child:any)=>{
			return {...child, enabled: enabled_, name: child.name,  form: props.form, classNames: 'disableMode ' + (child?.className ?? '') }
		})}
		</Form.Row>		
	</Form.Group>;
}

