import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import Button from '../../../components/Button';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import Loading from '../../../components/Loading';
import { STRINGS } from '../../../util/strings';
import { STRINGS_ADMIN } from '../../../util/admin/strings';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { DecodedToken, User, UsersGroups, UserStateType } from '../../../models/user';
import ToggleSwitch from '../../../components/ToggleSwitch';


import './../styles.scss';
import api from '../../../services/api';

export default function Edit (){

    const apiUrl = 'api/v1/admin/Usuarios';
    const apiUrlGrupos = 'api/v1/admin/Usuarios/grupos';

    const history = useHistory();
    const location = useLocation<UserStateType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [errorGetData, setErrorGetData] = useState<any>(null);
    const [isConfirmation, setIsConfirmation] = useState<boolean>(false);

    const [grupos, setGrupos] = useState<UsersGroups[]>([] as UsersGroups[]);

    const UsuarioSchema = yup.object().shape({
        userName: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        nome: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        email: yup
            .string()
            .required(STRINGS.FORM_FIELD_REQUIRED),
        ativo: yup
            .boolean()
    })

    const { register, errors, handleSubmit, reset, formState: { isDirty } } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(UsuarioSchema)
    });

    useEffect(() => {
        const getUsuarioData = async () => {

            const { status, data } = await api.get(apiUrlGrupos);

            if (!(status === 200 || status === 201)) {
                setError(error.response.data);
                return;
            }
            let g = data as UsersGroups[];           

            if (location.state && location.state.userName) {
                try {
                    const { data } = await api.get<User>(`api/v1/admin/Usuarios/${location.state.userName}`);
                    
                    reset({
                        nome: data.nome,
                        userName: data.userName,
                        email: data.email,
                        ativo: data.ativo
                    })

                    let n_g = [] as UsersGroups[];
                    g.forEach(e=>{
                        let check = false;
                        let elm =  data.usuarioGrupos?.find(us=> e.id === us.grupoId);
                        if(elm!=null) check = true;      
                        n_g.push({
                            ...e,
                            checked: check, 
                        });     
                    })
                    setGrupos(n_g);
                } catch (error:any) {
                    setErrorGetData(error.response.data);
                }
                setLoading(false);
            }else {
                setGrupos(g)
            }
        }
        getUsuarioData();
    }, [location.state, reset])

    const verifyErrorsOnForm = (): string[] => {
        let errorsForm: any[] = [];

        if (!isEmptyObj(errors)) {
            Object.entries(errors).map(err => errorsForm.push(err[0]));
        }
        return errorsForm;
    }

    const onSubmit = async (user: User) => {
        setLoadingSubmit(true);
        setError(null);

        try {
            let gruSave = grupos.filter(e=> e.checked === true).map(item=>{
                return {
                    ...item,
                    grupoId: item.id,
                }
            })
            let saveApi = api.put;
            let itemID = location?.state?.userName ?? null;
            // console.log("itemID = ", itemID)

            if(!itemID) saveApi = api.post;

            const { status } = await saveApi(apiUrl, {                
                email:  user.email,
                nome:   user.nome,
                userName: user.userName,
                ativo:  user.ativo,
                usuarioGrupos: gruSave,
            });

            if (status === 200 || status === 201) {
                setSuccess(true);

                setTimeout(() => {
                    history.goBack();
                }, 3500);
            }
        } catch (error:any) {
            console.error("error = ", error);
            setError(error.response.data);
        }

        setLoadingSubmit(false);
    }

    const GrupoPermissoes = () =>{        
        const creatElemnts = <div className="riquered-fieldset">{
            grupos.map((item, index) =>
                    <Form.Row className="row-custom">
                        <Col xs={10}>
                            <Form.Label>
                                {item.nomeDoGrupo}
                        </Form.Label>
                        </Col>
                        <Col>
                            <ToggleSwitch
                                id={item.id?.toString() ?? ''}
                                small
                                disabled={false}
                                checked={item.checked}        
                                onChange={()=>{ 
                                    grupos[index].checked = !grupos[index].checked                                                                   
                                    setGrupos([... grupos]); 
                                    // console.log(index, grupos[index].checked);
                                }}                                    
                            />
                        </Col>
                    </Form.Row>
            )}</div>
        //onChange={() => setMelhoristaResponsavel(!melhoristaResponsavel)}

        return creatElemnts
    }

    return (
        <div className="container container-fluid">
            <div className="search-content-filters">
                <If condition={verifyErrorsOnForm()}>
                    {verifyErrorsOnForm().map(item => <AlertError message={`O campo ${item} é obrigatório`} />)}
                </If>
                <If condition={success && !error}>
                    <AlertSuccess
                        message={STRINGS.ALERT_MESSAGE_SUCCESS_EDIT}
                    />
                </If>
                <If condition={!success && error !== null}>
                    {error !== null && error.message.length > 0 ? error.message.map((errorE: string) => <AlertError message={errorE} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
                </If>
                <h3>{STRINGS_ADMIN.EDITION_USUARIO}</h3>
                <If condition={loading}>
                    <span className="loading-page">
                        <Loading />
                    </span>
                </If>
                <If condition={!loading && !errorGetData}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row className="row-custom">
                        <Form.Group as={Col}>
                                <Form.Label>
                                    UserName<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="userName"
                                    type="text"
                                    placeholder="Insira o UserName"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={100}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>
                                    Nome de Usuário<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="nome"
                                    type="text"
                                    placeholder="Insira o nome do(a) Usuário"
                                    isInvalid={errors.nome !== undefined}
                                    autoComplete="off"
                                    maxLength={100}
                                />
                                <If condition={errors.nome}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nome?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>
                                    E-mail<em>*</em>
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    name="email"
                                    type="text"
                                    placeholder="Insira o e-mail"
                                    isInvalid={errors.email !== undefined}
                                    autoComplete="off"
                                    maxLength={100}
                                />
                                <If condition={errors.email}>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email?.message}
                                    </Form.Control.Feedback>
                                </If>
                            </Form.Group>

                        </Form.Row>                       
                        <Form.Row className="row-custom">
                            &nbsp;&nbsp;
                        <Form.Group className="checkbox-forms">
                                <Form.Check
                                    type="checkbox"
                                    ref={register}
                                    name="ativo"
                                    label="Ativo?"                                    
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            {GrupoPermissoes()}
                        </Form.Row>
                        <div className="button-position">
                            <Button
                                title="Cancelar"
                                type="button"
                                contained={false}
                                onClick={() => {
                                    isDirty ? setIsConfirmation(true) :
                                        history.goBack();
                                }}
                            />
                            <Button
                                title="Salvar"
                                type="submit"
                                contained={true}
                                isLoading={loadingSubmit}
                            />
                        </div>
                    </Form>
                </If>
                <If condition={isConfirmation}>
                    <ConfirmationModal
                        show={isConfirmation}
                        confirmationMsg={STRINGS_ADMIN.CONFIRM_LEFT_EDITION}
                        cancelMsg={STRINGS_ADMIN.CANCEL_LEFT_EDITION}
                        onConfirm={() => history.goBack()}
                        onHide={() => setIsConfirmation(false)}
                        title={STRINGS_ADMIN.EDITION_TITLE_CONFIRMATION}
                        type="button"
                        variant="danger"
                    >
                        {STRINGS_ADMIN.EDITION_REGISTER_CONFIRMATION_CANCEL}
                    </ConfirmationModal>
                </If>
            </div>
        </div>
    );

}