import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { STRINGS_CIBIO } from '../../../../util/authorizationsCIBIO/string';
import { STRINGS } from '../../../../util/strings';
import If from '../../../../components/If';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/Button';
import EditButton from '../../../../components/EditButton';
import SearchEmpty from '../../../../components/SearchEmpty';
import AlertError from '../../../../components/AlertError';
import { UnityStationResult } from '../../../../models/modelsAuthorizationsCIBIO';
import { statusToText } from '../../../../functions/formatStatus';
import api from '../../../../services/api';
import '../../../Cultivares/styles_list.css';


export default function UnitiesStationsBASF ()
{
	const history = useHistory();
	const [unities, setUnities] = useState<UnityStationResult[]>([]);
	const [errorSearch, setErrorSearch] = useState<any>(null);
	const [errorGetUnities, setErrorGetUnities] = useState<any>(null);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

	const { register, handleSubmit, getValues } = useForm({
		defaultValues: { name: '', active: true },
	})

	useEffect(() => {
		const getUnities = async () => {
			try {
				const { data, status } = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');

				if (status === 200) {
					setUnities(data);
				}
			} catch (error:any) {
				setErrorGetUnities(error?.response?.data);
			}
			setLoading(false);
		}

		getUnities();
	}, []);

	const search = async () => {
		let nome = getValues('name');
		let ativo = getValues('active');
		setLoadingSearch(true);
		setErrorSearch(null);

		try {
			const { data } = await api.get<UnityStationResult[]>(`api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Nome=${nome}&Ativo=${ativo}`);

			if (data.length) {
				setUnities(data);
				setEmptySearch(false);
			} else {
				setEmptySearch(true);
			}

		} catch (error:any) {
			setErrorSearch(error?.response?.data);
		}
		setLoadingSearch(false);
	}

	const actions = ({ data }: any) => (
		<Fragment>
			<EditButton
				onClick={() => history.push({
					pathname: '/autorizacoes/editar/unidades-estacoes-basf',
					state: {
						id: data.id
					}
				})}
			/>
		</Fragment>
	)

	return <div className="container-custom-search">
		<If condition={errorGetUnities !== null && !unities.length}>
			{errorGetUnities !== null && errorGetUnities.message.length > 0 ? errorGetUnities.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<If condition={errorSearch !== null && !unities.length}>
			{errorSearch !== null && errorSearch.message.length > 0 ? errorSearch.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<div className="top-content">
			<h3>{STRINGS_CIBIO.SEARCH_UNITIES_STATIONS}</h3>
		</div>
		<div className="row-buttons">
			<Button
				onClick={() => history.push('/autorizacoes/cadastrar/unidades-estacoes-basf')}
				contained
				title="Adicionar"
				type="button"
			/>
		</div>
		<br />
		<Form onSubmit={handleSubmit(search)}>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={8}>
					<Form.Label>Nome da Unidade/Estação</Form.Label>
					<Form.Control
						ref={register}
						name="name"
						maxLength={150}
						autoComplete="off"
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label />
					<Form.Check
						type="checkbox"
						ref={register}
						name="active"
						label="Ativos"
					/>
				</Form.Group>
				<span className="filter-btn">
					<Button
						title="Buscar"
						type="submit"
						contained
						isLoading={loadingSearch}
					/>
				</span>
			</Form.Row>
		</Form>
		<If condition={!loading && !emptySearch && unities.length}>
			<div className="search-content">
				<div className="ag-theme-alpine" style={{ height: '70vh', width: '100%' }}>
					<AgGridReact
						rowData={unities}
						defaultColDef={{ flex: 1, sortable: true, resizable: true }}
						pagination
						paginationPageSize={10}
						frameworkComponents={{
							actions: actions,
						}}
					>
						<AgGridColumn
							headerName='Nome da Unidade/Estação'
							field="nome"
							cellStyle={{
								justifyContent: 'left'
							}}
							sort="asc"
						/>
						<AgGridColumn
							headerName="Status"
							field="ativo"
							valueFormatter={({ data }: any) => statusToText(data.ativo)}
							maxWidth={130}
						/>
						<AgGridColumn
							field='Ações'
							cellRenderer='actions'
							maxWidth={130}
						/>
					</AgGridReact>
				</div>
			</div>
		</If>
		<If condition={loading}>
			<div className="loading-container">
				<Loading />
			</div>
		</If>
		<If condition={emptySearch}>
			<div className="div-empty">
				<SearchEmpty />
			</div>
		</If>
	</div>;
}