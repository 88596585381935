import React, { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Col } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../../components/Button';
import GenericButton from '../../../components/FlowGenericButton';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import AddButton from '../../../components/AddButton';
import { STRINGS } from '../../../util/strings';
import { STRINGS_CULTIVARES } from '../../../util/cultivares/string';
import * as Models from '../../../models/models';
import { DecodedToken } from '../../../models/user';
import { protectionSNPC } from '../../../tempdb.json';
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect } from 'react';
import api, { apiFileDownload } from '../../../services/api';
import FileExistent from '../../../components/FileExistent';
import moment from 'moment';
import Loading from '../../../components/Loading';
import NotAvailableModel from '../../../components/NotAvailableModel';
import LoadingInForm from '../../../components/LoadingInForm';
import ReasonModal from '../../../components/ReasonModal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import jwtDecode from 'jwt-decode';
import { STATUS_PROTECTION, STATUS_PROTECTION_ARCHIVE, STATUS_PROTECTION_ATTACHMENT } from '../../../util/status';
import { isAdmin } from '../../../users/isAdmin';
import { isRequesterWriteProtection } from '../../../users/isRequester';
import { isReguladorProtection } from '../../../users/isRegulador';
import '../styles_edit.css';
import EditButton from '../../../components/EditButton';
import { isDescriptors } from '../../../users/isDescriptors';
import { isDiseases } from '../../../users/isDiseases';
import ValidateRequisition from '../ValidateRequisition';
import { _generateDate } from '../../../util/convert';
import { uuid } from "../../../services/uuid";

import AttachmentGrid, { attachmentFile } from '../Components/AttachmentGrid';

registerLocale('pt-BR', ptBR);

interface AprovationData {
	need: boolean;
	date: Date | null;
	user: string | null;
}

export default function ProtectionSNPC() {
	let token = localStorage.getItem('token');
	const { roles }: DecodedToken = jwtDecode(String(token));
	const history = useHistory();
	const location = useLocation<{ id: number }>();
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [chooseApplicant, setChooseApplicant] = useState<boolean>(false);
	const [insertApplicants, setInsertApplicants] = useState<boolean>(false);
	const [selectedApplicants, setSelectedApplicants] = useState<Array<Models.ApplicantResults>>([]);
	const [unselectedApplicants, setUnselectedApplicants] = useState<Array<object>>([]);
	const [choosenApplicants, setChoosenApplicants] = useState<Array<Models.ApplicantResults>>([]);
	const [chooseBreeder, setChooseBreeder] = useState<boolean>(false);
	const [insertBreeder, setInsertBreeder] = useState<boolean>(false);
	const [selectedBreeder, setSelectedBreeder] = useState<Array<Models.BreederResults>>([]);
	const [unselectedBreeder, setUnselectedBreeder] = useState<Array<Models.BreederResults>>([]);
	const [choosenBreeder, setChoosenBreeder] = useState<Array<Models.BreederResults>>([]);
	const [transferredCultivar, setTransferredCultivar] = useState<boolean>(false);
	const [neededSpecifyModality, setNeededSpecifyModality] = useState<boolean>(false);
	const [lblModality, setLblModality] = useState('Escolher arquivo');
	const [modifiedOrganism, setModifiedOrganism] = useState<boolean>(false);
	const [cultivarProtection, setCultivarProtection] = useState<boolean>(false);
	const [registerCultivarProtection, setRegisterCultivarProtection] = useState<boolean>(false);
	const [protectionOfCultivarItems, setProtectionOfCultivarItems] = useState<Array<object>>([]);
	const [cultivarInBrazil, setCultivarInBrasil] = useState<boolean>(false);
	const [cultivarOutside, setCultivarOutside] = useState<boolean>(false);
	const [lblNFCultivar, setLblNFCultivar] = useState('Escolher arquivo');
	const [lblProcurement, setLblProcurement] = useState('Escolher arquivo');
	const [lblDescriptor, setLblDescriptor] = useState('Escolher arquivo');
	const [lblDescriptorsTable, setLblDescriptorsTable] = useState('Escolher arquivo');
	const [lblAdditionalFiles, setLblAdditionalFiles] = useState('Escolher arquivo');
	const [lblProcuracao, setLblProcuracao] = useState('Escolher arquivo');
	const [submited, setSubmited] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [erroModalArquivoAnexo, setErroModalArquivoAnexo] = useState<boolean>(false);
	const [erroModalArquivoRelatorioTecnico, setErroModalArquivoRelatorioTecnico] = useState<boolean>(false);
	const [erroModalArquivoDescritores, setErroModalArquivoDescritores] = useState<boolean>(false);
	const [msgErroModalArquivoAnexo, setMsgErroModalArquivoAnexo] = useState('');
	const [msgErroModalArquivoRelatorioTecnico, setMsgErroModalArquivoRelatorioTecnico] = useState('');
	const [msgErroModalArquivoDescritores, setMsgErroModalArquivoDescritores] = useState('');
	const [msgRecusaModalArquivoAnexo, setMsgRecusaModalArquivoAnexo] = useState('');
	const [msgRecusaModalArquivoRelatorioTecnico, setMsgRecusaModalArquivoRelatorioTecnico] = useState('');
	const [msgRecusaModalArquivoDescritores, setMsgRecusaModalArquivoDescritores] = useState('');
	const [arrayCulture, setArrayCulture] = useState<Models.CultureResults[]>([]);
	const [arrayEvents, setArrayEvents] = useState<Models.EventsResult[]>([]);
	const [breeders, setBreeders] = useState<Models.BreederResults[]>([]);
	const [applicants, setApplicants] = useState<Models.ApplicantResults[]>([]);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [specieName, setSpecieName] = useState<string>('');
	const [selectedEvent, setSelectedEvent] = useState<Models.EventsResult>();
	const [selectedModality, setSelectedModality] = useState<any>();
	const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
	const [models, setModels] = useState<Models.ModelFilesProtection>();
	const [successFiles, setSuccessFiles] = useState<boolean>(false);
	const [additionalFiles, setAdditionalFiles] = useState<any[]>([]);
	const [cultureDetails, setCultureDetails] = useState<Models.CultureResults>();
	const [loadingNeededData, setLoadingNeededData] = useState<boolean>(true);
	const [errorGetNeededData, setErrorGetNeededData] = useState<boolean>(false);
	const [loadingRequestData, setLoadingRequestData] = useState<boolean>(true);
	const [fileField1, setFileField1] = useState<Models.ModelFileRegister>();
	const [fileField2, setFileField2] = useState<Models.ModelFileRegister>();
	const [fileField3, setFileField3] = useState<Models.ModelFileRegister>();
	const [fileField4, setFileField4] = useState<Models.ModelFileRegister>();
	const [fileField5, setFileField5] = useState<Models.ModelFileRegister>();
	const [fileField6, setFileField6] = useState<Models.ModelFileRegister>();
	const [fileFieldAnexo, setFileFieldAnexo] = useState<Models.ModelFileRegister>();
	const [fileFieldRelatorioTecnico, setFileFieldRelatorioTecnico] = useState<Models.ModelFileRegister>();
	const [fileFieldDescritores, setFileFieldDescritores] = useState<Models.ModelFileRegister>();
	const [fileFieldProcuracao, setFileFieldProcuracao] = useState<Models.ModelFileRegister>();
	const [requestCode, setResquestCode] = useState<string>('');
	const [motivoRecusaCultivares, setMotivoRecusaCultivares] = useState<string>('');
	const [reproveModal, setReproveModal] = useState<boolean>(false);
	const [reproveTxt, setReproveTxt] = useState<string>('');
	const [loadingReprove, setLoadingReprove] = useState<boolean>(false);
	const [isApproving, setIsApproving] = useState<boolean>(false);
	const [aproveModal, setAproveModal] = useState<boolean>(false);
	const [successAprove, setSuccessAprove] = useState<boolean>(false);
	const [loadingAprove, setLoadingAprove] = useState<boolean>(false);
	const [regulatoryModal, setRegulatoryModal] = useState<boolean>(false);
	const [successRegulatory, setSuccessRegulatory] = useState<boolean>(false);
	const [loadingRegulatory, setLoadingRegulatory] = useState<boolean>(false);
	const [status, setStatus] = useState<Models.Status>({});
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);
	const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [errorOnForm, setErrorOnForm] = useState<boolean>(false);
	const [arquivosParaExcluir, setArquivosParaExcluir] = useState<number[]>([]);

	const [selectedArquivosRelatorioTecnico, setSelectedArquivosRelatorioTecnico] = useState<Models.ArquivosRelatorioTecnico[]>([]);
	const [modalArquivoRelatorioTecnico, setModalArquivoRelatorioTecnico] = useState<boolean>(false);
	const [arquivoRelatorioTecnicoID, setArquivoRelatorioTecnicoID] = useState<any>();
	const [statusArquivoRelatorio, setStatusArquivoRelatorio] = useState<Models.Status>({});

	const [selectedFilesAdditionals, setSelectedFilesAdditionals] = useState<Array<any>>([]);
	const [arquivoAnexoID, setArquivoAnexoID] = useState<any>();
	const [statusArquivoAnexo, setStatusArquivoAnexo] = useState<Models.Status>({});
	const [modalArquivoAnexo, setModalArquivoAnexo] = useState<boolean>(false);

	const [selectedArquivosDescritores, setSelectedArquivosDescritores] = useState<Models.ArquivosDescritores[]>([]);
	const [modalArquivoDescritores, setModalArquivoDescritores] = useState<boolean>(false);
	const [arquivoDescritoresID, setArquivoDescritoresID] = useState<any>();
	const [statusArquivoDescritores, setStatusArquivoDescritores] = useState<Models.Status>({});

	const [regulatoryModalAssinaturas, setRegulatoryModalAssinaturas] = useState<boolean>(false);


	var formDataFiles = new FormData();
	var formDataAdditionalFiles = new FormData();

	const conditionalModality = transferredCultivar ? {
		modality: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		modality: yup
			.string()
	}

	const conditionalModifiedOrganism = modifiedOrganism ? {
		cultivarGeneticTransformationEvent: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarGeneticTransformationEvent: yup
			.string()
	}

	const conditionalFileCultivar = cultivarInBrazil ? {
		commercializationFile: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		commercializationFile: yup
			.string()
	}

	const conditionalDenominationCultivar = cultivarInBrazil ? {
		denominationCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		denominationCultivar: yup
			.string()
	}

	const conditionalountryOfCommercialization = cultivarOutside ? {
		countryOfCommercialization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		countryOfCommercialization: yup
			.string()
	}

	const conditionalDenominationOfCommercialization = cultivarOutside ? {
		denominationOfCommercialization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		denominationOfCommercialization: yup
			.string()
	}

	const conditionalSpecificModality = neededSpecifyModality ? {
		specificModality: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		specificModality: yup
			.string()
	}

	const conditionalIntergovernametalOrganization = registerCultivarProtection &&
		cultivarProtection ? {
		intergovernametalOrganization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		intergovernametalOrganization: yup
			.string()
	}

	const conditionalTypeOfprotection = registerCultivarProtection &&
		cultivarProtection ? {
		typeOfprotection: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		typeOfprotection: yup
			.string()
	}

	const conditionalProtocolNumber = registerCultivarProtection &&
		cultivarProtection ? {
		protocolNumber: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		protocolNumber: yup
			.string()
	}

	const conditionalDenomination = registerCultivarProtection &&
		cultivarProtection ? {
		denomination: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		denomination: yup
			.string()
	}

	const protectionSNPCSchema = yup.object().shape({
		scientificName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		speciesName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		proposedDenomination: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		experimentalDenomination: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		...conditionalModality,
		...conditionalSpecificModality,
		modalityDocument: yup
			.mixed(),
		countryOfCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		...conditionalModifiedOrganism,
		...conditionalIntergovernametalOrganization,
		...conditionalTypeOfprotection,
		date: yup
			.date(),
		...conditionalProtocolNumber,
		...conditionalDenomination,
		...conditionalDenominationCultivar,
		dateOfFirstCommercialization: yup
			.date(),
		...conditionalFileCultivar,
		commercializationFile: yup
			.mixed(),
		...conditionalountryOfCommercialization,
		...conditionalDenominationOfCommercialization,
		dateOfCommercialization: yup
			.date(),
		sampleAddress: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		contactPersonInfo: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		procurementReport: yup
			.mixed(),
		descriptorsTable: yup
			.mixed(),
		fileType: yup
			.string(),
		additionalFiles: yup
			.mixed(),
	})

	const defaultForm = useForm({
		mode: 'onTouched',
		resolver: yupResolver(protectionSNPCSchema)
	})

	const getProtectionList = async () => {
		try {
			let resultCulture = await api.get<Models.CultureResults[]>(`api/v1/cultivares/Cultura/obterporfiltro?Ativo=true`);
			let restulEvents = await api.get<Models.EventsResult[]>(`api/v1/cultivares/Evento/obterporfiltro?Ativo=true`);

			let responseBreeders = await api.get<Models.BreederResults[]>('api/v1/cultivares/EquipeTecnicaMelhorista/obterporfiltro?Ativo=true');
			let responseApplicants = await api.get<Models.ApplicantResults[]>('api/v1/cultivares/Requerente/obterporfiltro?Ativo=true');

			if (resultCulture.status === 200)
				setArrayCulture(resultCulture.data);
			if (restulEvents.status === 200)
				setArrayEvents(restulEvents.data);
			if (responseBreeders.status === 200) {
				setBreeders(responseBreeders.data);
			}
			if (responseApplicants.status === 200) {
				setApplicants(responseApplicants.data);
			}
		}
		catch (error: any) {
			setError(true);
		}
		setLoadingNeededData(false);
	}

	const getRequest = async () => {
		try {
			const { data } = await api.get<Models.RequestProtectionSNPC>(`api/v1/cultivares/CultivaresProtecao/${location.state.id}`);

			if (!loadingNeededData) {
				setSelectedModality(data.tipoModalidade);
				setNeededSpecifyModality(data.tipoModalidade == 3);

				defaultForm.reset({
					speciesName: data.culturaId,
					scientificName: data.nomeCientificoDaEspecie,
					proposedDenomination: data.denominacaoProposta,
					experimentalDenomination: data.denominacaoExperimental,
					selectedModality: data.tipoModalidade,
					specificModality: data.outraEspecificar,
					countryOfCultivar: data.paisCultivarObtida,
					cultivarGeneticTransformationEvent: data.eventoId,
					intergovernametalOrganization: data.paisOrganizacao,
					denomination: data.denominacao,
					denominationCultivar: data.denominacaoVendaComercializacao,
					dateOfFirstCommercialization: data.dataDaPrimeiraComercializacao ? moment(data.dataDaPrimeiraComercializacao).toDate() : null,
					countryOfCommercialization: data.pais,
					denominationOfCommercialization: data.denominacaoOferecidaComercializacaoNoExeterior,
					dateOfCommercialization: data.datePrimeiraComercializacaoNoExterior ? moment(data.datePrimeiraComercializacaoNoExterior).toDate() : null,
					sampleAddress: data.enderecoOndeAmostraVivaSeraMantida,
					contactPersonInfo: data.pessoaDeContato,
					date: data.data ? moment(data.data).toDate() : null,
					protocolNumber: data.numeroProtocolo,
					protocolo: data.protocolo,
					protocolDate: data.dataProtocolo ? moment(data.dataProtocolo).toDate() : null,
					generalObservations: data.observacoes
				})

				if (data.status === STATUS_PROTECTION.ReprovadoPeloRegulatorio) {
					for (let i = 0, t = data.reprovacoes.length; i < t; i++) {
						setMotivoRecusaCultivares(data.reprovacoes[i].motivo);
					}
				}
				else
					setMotivoRecusaCultivares('');

				setSpecieName(data.nomeComumDaEspecie);
				setCultivarOutside(data.cultivarFoiOferecidaComercializacaoNoExeterior);
				setTransferredCultivar(data.cultivarTransferida);
				setModifiedOrganism(data.cultivarGeneticamenteModificada);
				setCultivarProtection(data.protecaoDaCultivarFoiSolicitadaNoExterior);
				setCultivarInBrasil(data.cultivarOferecidaVendaComercializadaBrasil);
				setSelectedEvent({
					id: data.eventoId,
					nomeEvento: data.nomeDoEvento,
				});
				getCultureById(Number(data.culturaId));
				setAproveDataDescritores({ need: data.descritores_aprovar, date: data.descritores_aprovar_data, user: data.descritores_aprovar_usuario });
				setAproveDataDoencas({ need: data.doencas_aprovar, date: data.doencas_aprovar_data, user: data.doencas_aprovar_usuario });
				if (data.cultivaresRequerentes.length) {
					const allApplicants = data.cultivaresRequerentes.map(req => {
						return req.requerente;
					})
					setSelectedApplicants(allApplicants);
					setInsertApplicants(true);
				}
				if (data.cultivaresProtecaoEquipeTecnicas.length) {
					const allBreeders = data.cultivaresProtecaoEquipeTecnicas.map(melhorista => {
						return melhorista.equipeTecnicaMelhorista;
					});

					// console.log('Breeders');
					// console.log(allBreeders);
					setSelectedBreeder(allBreeders);
					setInsertBreeder(true);
				}

				if (data.arquivosRelatorioTecnico) {
					setSelectedArquivosRelatorioTecnico(data.arquivosRelatorioTecnico)

					for (let i = 0, t = data.arquivosRelatorioTecnico.length; i < t; i++) {
						let relatorioTecnico = data.arquivosRelatorioTecnico[i];
						let arquivoRelatorioTecnico: Models.ModelFileRegister =
						{
							id: relatorioTecnico.arquivo.id,
							codigo: relatorioTecnico.arquivo.codigo,
							path: relatorioTecnico.arquivo.path,
							nome: relatorioTecnico.arquivo.nome,
							usuario: relatorioTecnico.arquivo.usuario,
							dataAtualizacao: relatorioTecnico.arquivo.dataAtualizacao,
						};

						setFileField3(arquivoRelatorioTecnico);
					}
				}

				if (data.arquivosDescritores) {
					setSelectedArquivosDescritores(data.arquivosDescritores)
					for (let i = 0, t = data.arquivosDescritores.length; i < t; i++) {
						let Descritores = data.arquivosDescritores[i];
						let arquivoDescritores: Models.ModelFileRegister =
						{
							id: Descritores.arquivo.id,
							codigo: Descritores.arquivo.codigo,
							path: Descritores.arquivo.path,
							nome: Descritores.arquivo.nome,
							usuario: Descritores.arquivo.usuario,
							dataAtualizacao: Descritores.arquivo.dataAtualizacao,
						};

						setFileField4(arquivoDescritores);
					}
				}

				setModalArquivoRelatorioTecnico(false);
				setModalArquivoDescritores(false);

				setErroModalArquivoDescritores(false);
				setErroModalArquivoRelatorioTecnico(false);

				if (data.arquivoAnexoVendaComercializacao) setFileField2(data.arquivoAnexoVendaComercializacao);
				if (data.arquivoDocumentoComprobatorio) setFileField1(data.arquivoDocumentoComprobatorio);
				//				if (data.arquivoRelatorioTecnicoDeObtencao)	setFileField3(data.arquivoRelatorioTecnicoDeObtencao);
				//				if (data.arquivoTabelaDescritores) 			setFileField4(data.arquivoTabelaDescritores);
				if (data.arquivoProtocolo) setFileField5(data.arquivoProtocolo);
				if (data.arquivoProtecaoMAPA) setFileField6(data.arquivoProtecaoMAPA);
				if (data.arquivoConclusao) setFileField6(data.arquivoConclusao);
				if (data.arquivoProcuracao) setFileFieldProcuracao(data.arquivoProcuracao);
				if (data.arquivosAnexos) {

					let files: attachmentFile[] = [];

					for (let i = 0, t = data.arquivosAnexos.length; i < t; i++) {
						let file = data.arquivosAnexos[i];

						console.log('Loading', file);

						files.push({
							uuid: file.arquivoId.toString(),
							name: file.arquivo ? file.arquivo.nome : '',
							aditionalData: { id: file.id, cultivares: file.cultivaresProtecaoId },
						});
					}

					const allFiles = data.arquivosAnexos.map(file => { return file; })

					setSelectedFilesAdditionals([]);
					setTimeout(() => {
						setSelectedFilesAdditionals(allFiles);
					}, 500);
				}
				if (data.paisOrganizacao) {
					setProtectionOfCultivarItems([...protectionOfCultivarItems, {
						intergovernametalOrganization: data.paisOrganizacao,
						typeOfprotection: data.tipoDeProtecao,
						date: moment(data.data).toDate(),
						protocolNumber: data.numeroProtocolo,
						denomination: data.denominacao,
					}])
				}
				setResquestCode(data.codigo);
				setStatus({
					status: data.status,
					statusDescricao: data.statusDescricao,
				})
				setProtocolo(data.protocolo);
				setDateProtocolo(moment(data.dataProtocolo).toDate());
				setRegistro(data.registro);
				setDataRegistro(moment(data.dataRegistro).toDate());
				setDataTerminoRegistro(moment(data.dataTerminoRegistro).toDate());
			}
		}
		catch (error: any) {
			setErrorGetNeededData(true);
		}

		setTimeout(() => {
			setLoadingRequestData(false);
		}, 2500)
	}

	useEffect(() => {
		getProtectionList();
		getRequest();
		getColaborators();
	}, [loadingNeededData, location.state.id, defaultForm.reset]);

	const verifyErrorsOnForm = (): boolean => {
		let modalityDocument = defaultForm.getValues("modalityDocument");
		let procurementReport = defaultForm.getValues("procurementReport");
		let descriptorsTable = defaultForm.getValues("descriptorsTable");

		if (((submited &&
			(!selectedApplicants.length ||
				!selectedBreeder.length ||
				(!procurementReport.length && !fileField3) ||
				(!descriptorsTable.length && !fileField4) ||
				(neededSpecifyModality
					&& (!modalityDocument.length && !fileField1)) ||
				(cultivarInBrazil &&
					(defaultForm.errors.denominationCultivar &&
						defaultForm.errors.commercializationFile)))) ||
			defaultForm.errors.countryOfCommercialization ||
			defaultForm.errors.denominationOfCommercialization ||
			(cultivarOutside && (defaultForm.errors.countryOfCommercialization ||
				defaultForm.errors.denominationOfCommercialization)
			)) ||
			defaultForm.errors.speciesName ||
			defaultForm.errors.proposedDenomination ||
			defaultForm.errors.experimentalDenomination ||
			defaultForm.errors.modality ||
			defaultForm.errors.countryOfCultivar ||
			defaultForm.errors.cultivarGeneticTransformationEvent ||
			defaultForm.errors.specificModality || defaultForm.errors.sampleAddress ||
			defaultForm.errors.contactPersonInfo) {
			return true;
		}
		return false;
	}

	const expectedApplicants = (): Models.RequestProtectionApplicants[] => {
		let applicantsModel: Models.RequestProtectionApplicants[] = [];

		for (let i = 0, t = selectedApplicants.length; i < t; i++) {
			applicantsModel.push({
				//cultivaresProtecaoId: location.state.id,
				requerenteId: selectedApplicants[i].id
			})
		}
		return applicantsModel;
	}

	const setUnselectedApplicant = (selectedApplicant: Models.ApplicantResults[]) => {
		let arr1: Models.ApplicantResults[] = [];
		for (let count = 0, countLen = applicants.length; count < countLen; count++) {
			const result: Models.ApplicantResults = {
				id: Number(applicants[count].id),
				nome: String(applicants[count].nome),
				ativo: Boolean(applicants[count].ativo),
				cnpj: String(applicants[count].cnpj),
				endereco: String(applicants[count].endereco),
				cep: String(applicants[count].cep),
				municipio: String(applicants[count].municipio),
				email: String(applicants[count].email),
				pais: String(applicants[count].pais),
				telefone: String(applicants[count].telefone),
				uf: String(applicants[count].uf),
			}
			let exist = selectedApplicant.some(c => c.id === applicants[count].id);
			if (exist === false)
				arr1.push(result);
		}
		setUnselectedApplicants(arr1);
	}

	const onRowSelectedApplicant = ({ api }: any) => {
		const data = api.getSelectedRows();


		setChoosenApplicants([]);
		setTimeout(() => { setChoosenApplicants(data); }, 500);
	}

	const onRowSelectedBreeder = ({ api }: any) => {
		const data = api.getSelectedRows();

		setChoosenBreeder([]);
		setTimeout(() => { setChoosenBreeder(data); }, 500);
	}

	const setUnselectedBreederList = (selecteds: Models.BreederResults[]) => {
		let arr1: Models.BreederResults[] = [];
		for (let i = 0, t = breeders.length; i < t; i++) {
			if (!selectedBreeder.some(c => c.id === breeders[i].id))
				arr1.push(breeders[i]);
		}
		setUnselectedBreeder(arr1);
	}

	const expectedBreeders = (): Models.RequestProtectionMelhoristas[] => {
		let breedersModel: Models.RequestProtectionMelhoristas[] = [];

		for (let i = 0, t = selectedBreeder.length; i < t; i++) {
			breedersModel.push({
				equipeTecnicaMelhoristaId: selectedBreeder[i].id
			})
		}
		return breedersModel;
	}

	const actionApplicants = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setSelectedApplicants([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setSelectedApplicants([...arr]);
				}}
			/>
		)
	}

	const actionBreeder = (props: any) => {
		return <DeleteButton
			onClick={() => {

				setSelectedBreeder([]);
				let arr: any = [];
				const { node } = props;

				node.gridApi.updateRowData({ remove: [node.data] })

				node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				setSelectedBreeder([...arr]);

				//	let arr: any = [];

				//	props.node.gridApi.updateRowData({ remove: [props.node.data] })
				//	props.node.gridApi.forEachNode(({ data }: any) => arr.push(data));
				//	selectedBreeder.push([]);
				//	setSelectedBreeder([...arr]);
			}}
		/>;
	}

	const actionArquivosRelatorioTecnico = (props: any) => {
		return <EditButton
			onClick={() => {
				setArquivoRelatorioTecnicoID(props.data.id)
				setStatusArquivoRelatorio(props.data.status)
				setMsgRecusaModalArquivoRelatorioTecnico(props.data.motivoRecusa)

				let arquivo = props.data.arquivo;
				let arquivoRelatorioTecnico: Models.ModelFileRegister =
				{
					id: arquivo.id,
					codigo: arquivo.codigo,
					path: arquivo.path,
					nome: arquivo.nome,
					usuario: arquivo.usuario,
					dataAtualizacao: arquivo.dataAtualizacao,
				};

				setFileFieldRelatorioTecnico(arquivoRelatorioTecnico);
				setModalArquivoRelatorioTecnico(true)
			}}
		/>;
	}

	const actionArquivosDescritores = (props: any) => {
		return <EditButton
			onClick={() => {
				setArquivoDescritoresID(props.data.id)
				setStatusArquivoDescritores(props.data.status)
				setMsgRecusaModalArquivoDescritores(props.data.motivoRecusa)

				let arquivo = props.data.arquivo;
				let arquivoDescritores: Models.ModelFileRegister =
				{
					id: arquivo.id,
					codigo: arquivo.codigo,
					path: arquivo.path,
					nome: arquivo.nome,
					usuario: arquivo.usuario,
					dataAtualizacao: arquivo.dataAtualizacao,
				};

				setFileFieldDescritores(arquivoDescritores);

				setModalArquivoDescritores(true)
			}}
		/>;
	}

	const getModality = (value: any) => {
		if (value === '3')
			setNeededSpecifyModality(true);
		else
			setNeededSpecifyModality(false)

		setSelectedModality(value);
	}

	const actionAdditionalFiles = (props: any) => {
		return (
			<Fragment>
				<EditButton
					disable={props.data.status === 1}
					onClick={() => {
						setArquivoAnexoID(props.data.id)
						setStatusArquivoAnexo(props.data.status)
						setMsgRecusaModalArquivoAnexo(props.data.motivoRecusa)

						let arquivo = props.data.arquivo;
						let arquivoAnexo: Models.ModelFileRegister =
						{
							id: arquivo.id,
							codigo: arquivo.codigo,
							path: arquivo.path,
							nome: arquivo.nome,
							usuario: arquivo.usuario,
							dataAtualizacao: arquivo.dataAtualizacao,
						};

						setFileFieldAnexo(arquivoAnexo);

						setModalArquivoAnexo(true)
					}}
				/>
				&nbsp;
				<DeleteButton
					onClick={() => {
						setSelectedFilesAdditionals([]);
						let arr: any = [];
						let arrToSubmit: any = [];
						const { node } = props;
						node.gridApi.updateRowData({ remove: [node.data] })
						node.gridApi.forEachNode(({ data }: any) => arr.push(data));
						setSelectedFilesAdditionals([...arr]);
						setAdditionalFiles(arrToSubmit);
					}}
				/>
			</Fragment>
		)
	}

	const setSelectedFilesAdditionalsList = () => {
		const file = {
			arquivo: {
				nome: defaultForm.getValues('additionalFiles')[0].name
			}
		}
		setSelectedFilesAdditionals([]);
		setTimeout(() => {
			setSelectedFilesAdditionals([...selectedFilesAdditionals, file]);
		}, 500);
	}


	const GetDataToSubmit = () => {
		let data = {
			id: location.state.id,
			culturaId: cultureDetails?.id,
			nomeComumDaEspecie: specieName,
			nomeCientificoDaEspecie: defaultForm.getValues("scientificName"),
			denominacaoProposta: defaultForm.getValues("proposedDenomination"),
			denominacaoExperimental: defaultForm.getValues("experimentalDenomination"),
			cultivarTransferida: transferredCultivar,
			tipoModalidade: selectedModality,
			outraEspecificar: defaultForm.getValues("specificModality"),
			paisCultivarObtida: defaultForm.getValues("countryOfCultivar"),
			cultivarGeneticamenteModificada: modifiedOrganism,
			eventoId: selectedEvent?.id,
			nomeDoEvento: selectedEvent?.nomeEvento,
			protecaoDaCultivarFoiSolicitadaNoExterior: cultivarProtection,
			paisOrganizacao: defaultForm.getValues("intergovernametalOrganization"),
			data: defaultForm.getValues("date"),
			numeroProtocolo: defaultForm.getValues("protocolNumber"),
			denominacao: defaultForm.getValues("denomination"),
			cultivarOferecidaVendaComercializadaBrasil: cultivarInBrazil,
			denominacaoVendaComercializacao: defaultForm.getValues("denominationCultivar"),
			dataDaPrimeiraComercializacao: defaultForm.getValues("dateOfFirstCommercialization"),
			cultivarFoiOferecidaComercializacaoNoExeterior: cultivarOutside,
			pais: defaultForm.getValues("countryOfCommercialization"),
			denominacaoOferecidaComercializacaoNoExeterior: defaultForm.getValues("denominationOfCommercialization"),
			datePrimeiraComercializacaoNoExterior: defaultForm.getValues("dateOfCommercialization"),
			enderecoOndeAmostraVivaSeraMantida: defaultForm.getValues("sampleAddress"),
			pessoaDeContato: defaultForm.getValues("contactPersonInfo"),
			cultivaresRequerentes: expectedApplicants(),
			cultivaresProtecaoEquipeTecnicas: expectedBreeders(),
			arquivosAnexos: expectedFilesAdditionals(),
			arquivosRelatorioTecnico: expectedArquivosRelatorioTecnico(),
			arquivosDescritores: expectedArquivosDescritores(),
			observacoes: defaultForm.getValues("generalObservations"),
			arquivosParaExcluir: arquivosParaExcluir,
		};

		return data;
	}

	const onSubmitForm = async () => {
		await onSubmit(true);
	}

	const onSubmit = async (sendBack: boolean = false) => {
		setLoadingSubmit(true);
		setErrorSubmit(null);

		try {

			const dados = GetDataToSubmit();

			const request = await api.put('api/v1/cultivares/CultivaresProtecao', dados);

			//const request = await api.put('api/v1/cultivares/CultivaresProtecao', {
			//	id: location.state.id,
			//	culturaId: cultureDetails?.id,
			//	nomeComumDaEspecie: specieName,
			//	nomeCientificoDaEspecie: defaultForm.getValues("scientificName"),
			//	denominacaoProposta: defaultForm.getValues("proposedDenomination"),
			//	denominacaoExperimental: defaultForm.getValues("experimentalDenomination"),
			//	cultivarTransferida: transferredCultivar,
			//	tipoModalidade: selectedModality,
			//	outraEspecificar: defaultForm.getValues("specificModality"),
			//	paisCultivarObtida: defaultForm.getValues("countryOfCultivar"),
			//	cultivarGeneticamenteModificada: modifiedOrganism,
			//	eventoId: selectedEvent?.id,
			//	nomeDoEvento: selectedEvent?.nomeEvento,
			//	protecaoDaCultivarFoiSolicitadaNoExterior: cultivarProtection,
			//	paisOrganizacao: defaultForm.getValues("intergovernametalOrganization"),
			//	data: defaultForm.getValues("date"),
			//	numeroProtocolo: defaultForm.getValues("protocolNumber"),
			//	denominacao: defaultForm.getValues("denomination"),
			//	cultivarOferecidaVendaComercializadaBrasil: cultivarInBrazil,
			//	denominacaoVendaComercializacao: defaultForm.getValues("denominationCultivar"),
			//	dataDaPrimeiraComercializacao: defaultForm.getValues("dateOfFirstCommercialization"),
			//	cultivarFoiOferecidaComercializacaoNoExeterior: cultivarOutside,
			//	pais: defaultForm.getValues("countryOfCommercialization"),
			//	denominacaoOferecidaComercializacaoNoExeterior: defaultForm.getValues("denominationOfCommercialization"),
			//	datePrimeiraComercializacaoNoExterior: defaultForm.getValues("dateOfCommercialization"),
			//	enderecoOndeAmostraVivaSeraMantida: defaultForm.getValues("sampleAddress"),
			//	pessoaDeContato: defaultForm.getValues("contactPersonInfo"),
			//	cultivaresRequerentes: expectedApplicants(),
			//	cultivaresProtecaoEquipeTecnicas: expectedBreeders(),
			//	arquivosAnexos: expectedFilesAdditionals(),
			//	arquivosRelatorioTecnico: expectedArquivosRelatorioTecnico(),
			//	arquivosDescritores: expectedArquivosDescritores(),
			//	observacoes: defaultForm.getValues("generalObservations"),
			//	arquivosParaExcluir: arquivosParaExcluir,
			//});

			if (request.status === 200) {
				if (status.status === STATUS_PROTECTION.EmPreenchimento || status.status === STATUS_PROTECTION.EmCorrecao || status.status === STATUS_PROTECTION.ReprovadoPeloRegulatorio || status.status === STATUS_PROTECTION.ValidadoPeloRegulatorio) {
					sendToRegulatory();
				}

				await onSubmitFiles();
				await onSubmitAdditionalFiles();
				setErrorOnForm(false);

				if (isApproving) {
					aproveRequisition();
				}

				setSuccessfulResponse(true);

				if (sendBack) {
					window.scrollTo({ top: 0, behavior: 'smooth' });
					setTimeout(() => {
						history.goBack();
					}, 3500)
				}
			}
		}
		catch (error: any) {
			setErrorSubmit(error.response.data);
			setLoadingSubmit(false);
		}

		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	const onSubmitDraft = async () => {
		setLoadingDraft(true);
		try {
			const { status } = await api.put('api/v1/cultivares/CultivaresProtecao/rascunho', {
				id: location.state.id,
				culturaId: cultureDetails?.id,
				nomeComumDaEspecie: specieName,
				nomeCientificoDaEspecie: defaultForm.getValues("scientificName"),
				denominacaoProposta: defaultForm.getValues("proposedDenomination"),
				denominacaoExperimental: defaultForm.getValues("experimentalDenomination"),
				cultivarTransferida: transferredCultivar,
				tipoModalidade: selectedModality,
				outraEspecificar: defaultForm.getValues("specificModality"),
				paisCultivarObtida: defaultForm.getValues("countryOfCultivar"),
				cultivarGeneticamenteModificada: modifiedOrganism,
				eventoId: selectedEvent?.id,
				nomeDoEvento: selectedEvent?.nomeEvento,
				protecaoDaCultivarFoiSolicitadaNoExterior: cultivarProtection,
				paisOrganizacao: defaultForm.getValues("intergovernametalOrganization"),
				data: defaultForm.getValues("date"),
				numeroProtocolo: defaultForm.getValues("protocolNumber"),
				denominacao: defaultForm.getValues("denomination"),
				cultivarOferecidaVendaComercializadaBrasil: cultivarInBrazil,
				denominacaoVendaComercializacao: defaultForm.getValues("denominationCultivar"),
				dataDaPrimeiraComercializacao: defaultForm.getValues("dateOfFirstCommercialization"),
				cultivarFoiOferecidaComercializacaoNoExeterior: cultivarOutside,
				pais: defaultForm.getValues("countryOfCommercialization"),
				denominacaoOferecidaComercializacaoNoExeterior: defaultForm.getValues("denominationOfCommercialization"),
				datePrimeiraComercializacaoNoExterior: defaultForm.getValues("dateOfCommercialization"),
				enderecoOndeAmostraVivaSeraMantida: defaultForm.getValues("sampleAddress"),
				pessoaDeContato: defaultForm.getValues("contactPersonInfo"),
				cultivaresRequerentes: expectedApplicants(),
				cultivaresProtecaoEquipeTecnicas: expectedBreeders(),
				arquivosAnexos: expectedFilesAdditionals(),
				arquivosRelatorioTecnico: expectedArquivosRelatorioTecnico(),
				arquivosDescritores: expectedArquivosDescritores(),
				observacoes: defaultForm.getValues("generalObservations"),
				arquivosParaExcluir: arquivosParaExcluir,
			})

			if (status === 200) {
				setSuccessDraft(true);
				await onSubmitFiles();
				await onSubmitAdditionalFiles();

				window.scrollTo({ top: 0, behavior: 'smooth' });
				setTimeout(() => {
					history.goBack();
				}, 3500)
			}
		}
		catch (error: any) {
			setErrorDraft(error.response.data);
		}

		window.scrollTo({ top: 0, behavior: 'smooth' });
		setLoadingDraft(false);
	}

	const onSubmitAdditionalFiles = async () => {
		additionalFiles.map(file => formDataAdditionalFiles.append("files", file.arquivo));

		try {
			const { status } = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/SalvarArquivosAnexos`, formDataAdditionalFiles);
		}
		catch (error: any) {
			console.error(error);
		}

		setLoadingSubmit(false);
	}

	const downloadModelFile = async (file: Models.ModelFileRegister | undefined) => {
		if (file != undefined) {
			setLoadingDownload(true);
			try {
				apiFileDownload.get(`api/v1/cultivares/Download?codigo=${file.codigo}`)
					.then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.nome);
						document.body.appendChild(link);
						link.click();
					});

			} catch (error: any) {
				console.error(error);
			}
			setLoadingDownload(false);
		}
	}

	const downloadModel = async (modelCode: string, fileName: string) => {
		setLoadingDownload(true);
		try {
			const { data } = await apiFileDownload.get(`/api/v1/cultivares/Download?codigo=${modelCode}`);
			let blob = new Blob([data], {
				type: 'application/pdf'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
			}

		} catch (error: any) {
			console.error(error);
		}
		setLoadingDownload(false);
	}

	const onSubmitFiles = async () => {
		let fileProcurementReport = defaultForm.getValues('procurementReport');
		//let fileProcurementReport = expectedArquivosRelatorioTecnico();

		let fileDescriptorsTable = defaultForm.getValues('descriptorsTable');
		//let fileDescriptorsTable = expectedArquivosDescritores();


		let fileModalityDocument = defaultForm.getValues('modalityDocument');
		let fileCommercializationFile = defaultForm.getValues('commercializationFile');
		let fileProtecaoMAPA = defaultForm.getValues('fileRegistro');
		let fileProcuracao = defaultForm.getValues('fileProcuracao');

		if(fileProcurementReport !== undefined)
			formDataFiles.append('fileArquivoRelatorioTecnicoDeObtencao', fileProcurementReport[0]);

		if (fileDescriptorsTable !== undefined)
			formDataFiles.append('fileArquivoTabelaDescritores', fileDescriptorsTable[0]);

		if (fileModalityDocument !== undefined)
			formDataFiles.append('fileArquivoDocumentoComprobatorio', fileModalityDocument[0]);
		if (fileCommercializationFile !== undefined)
			formDataFiles.append('fileArquivoAnexoVendaComercializacao', fileCommercializationFile[0]);
		if (fileProtecaoMAPA !== undefined)
			formDataFiles.append('fileProtecaoMAPA', fileProtecaoMAPA[0]);
		if (fileProcuracao !== undefined)
			formDataFiles.append('fileArquivoProcuracao', fileProcuracao[0]);

		try {
			const { data, status } = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/SalvarArquivosProtecao`, formDataFiles);

			if (status === 200) {
				setSuccessFiles(true);
			}
		}
		catch (error: any) {
			console.error(error);
		}

		setLoadingSubmit(false);
	}

	const expectedFilesAdditionals = (): Models.AdditionalsFilesRegistroUpdate[] => {
		let allAdditionalFiles: Models.AdditionalsFilesRegistroUpdate[] = [];

		// for (let i = 0, t = attachmentFiles.length; i < t; i++)
		// {
		// 	let file : attachmentFile = attachmentFiles[i];

		// 	if (file.raw)
		// 		continue;

		// 	allAdditionalFiles.push({
		// 		id: parseInt(file.aditionalData.id ? file.aditionalData.id : '0'),
		// 		arquivoId: parseInt(file.uuid ? file.uuid : '0'),
		// 		cultivaresId: location.state.id
		// 	});
		// }

		for (let count = 0, countLen = selectedFilesAdditionals.length; count < countLen; count++) {
			if (!!selectedFilesAdditionals[count].id) {
				allAdditionalFiles.push({
					id: selectedFilesAdditionals[count].id,
					arquivoId: selectedFilesAdditionals[count].arquivo.id,
					cultivaresId: location.state.id,
					status: selectedFilesAdditionals[count].status,
					motivoRecusa: selectedFilesAdditionals[count].motivoRecusa,
				})
			}
		}

		return allAdditionalFiles;
	}

	const setArrFilesToSubmit = (file: any) => {
		setAdditionalFiles([...additionalFiles, {
			arquivo: file[0],
		}])
	}

	const expectedArquivosRelatorioTecnico = (): Models.ArquivosRelatorioTecnico[] => {
		let todosRelatorios: Models.ArquivosRelatorioTecnico[] = [];

		for (let count = 0, countLen = selectedArquivosRelatorioTecnico.length; count < countLen; count++) {
			if (!!selectedArquivosRelatorioTecnico[count].id) {
				todosRelatorios.push(selectedArquivosRelatorioTecnico[count]);
			}
		}

		return todosRelatorios;
	}

	const expectedArquivosDescritores = (): Models.ArquivosDescritores[] => {
		let todosRelatorios: Models.ArquivosDescritores[] = [];

		for (let count = 0, countLen = selectedArquivosDescritores.length; count < countLen; count++) {
			if (!!selectedArquivosDescritores[count].id) {
				todosRelatorios.push(selectedArquivosDescritores[count]);
			}
		}

		return todosRelatorios;
	}

	const getCultureById = async (idCulture: number) => {
		try {
			const { data, status } = await api.get<Models.CultureResults>(`api/v1/cultivares/Cultura/${Number(idCulture)}`);
			if (status === 200) {
				setCultureDetails(data);
				if (data.modeloProtecaoCultivares !== null) {
					setModels(data.modeloProtecaoCultivares);
				}
			}
		} catch (error: any) {
			console.error(error);
		}
	}

	const setScientificName = (cultureId: number) => {
		const scientificName = arrayCulture.filter((item => {
			return item.id === Number(cultureId);
		}))

		let result = scientificName[0];
		if (!!result)
			defaultForm.setValue('scientificName', result.descricao);
	}

	const aproveRequisition = async () => {
		setLoadingAprove(true);
		try {
			const { status } = await api.post(`/api/v1/cultivares/CultivaresProtecao/${location.state.id}/aprovar`);

			if (status === 200) {
				setSuccessAprove(true);
			}
		} catch (error: any) {
			console.error(error);
		}
		setLoadingAprove(false);
	}

	const reproveRequisition = async () => {
		setLoadingReprove(true);

		try {
			await onSubmit(false);

			const { status } = await api.post(`/api/v1/cultivares/CultivaresProtecao/${location.state.id}/reprovar`, {
				motivo: reproveTxt,
				observacoes: defaultForm.getValues("generalObservations"),
			})

			if (status === 200) {
				setReproveModal(false);
				setTimeout(() => {
					history.goBack();
				}, 1200)
			}
		} catch (error: any) {
			console.error(error);
		}
		setLoadingReprove(false);
	}

	const sendToRegulatory = async () => {
		setLoadingRegulatory(true);

		try {
			const { status } = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/enviarparaaprovacao`);

			if (status === 200) {
				setSuccessRegulatory(true);
			}
		}
		catch (error: any) {
			console.error(error);
		}

		setLoadingRegulatory(false);
	}

	const setEventSelected = (eventId: number) => {
		const event = arrayEvents.filter(item => {
			return item.id === Number(eventId);
		})

		setSelectedEvent(event[0]);
	}

	const [registro, setRegistro] = useState('');
	const [dataRegistro, setDataRegistro] = useState<Date>(new Date());
	const [dataTerminoRegistro, setDataTerminoRegistro] = useState<Date>(new Date());
	const [modalRegistro, setModalRegistro] = useState<boolean>(false);
	const [sucessRegistro, setSuccessRegistro] = useState<boolean>(false);
	const [loadingRegistro, setLoadingRegistro] = useState<boolean>(false);
	const [modalArquivoConclusao, setModalArquivoConclusao] = useState<boolean>(false);
	const [modalArquivoConclusaoLoading, setModalArquivoConclusaoLoading] = useState<boolean>(false);
	const [arquivoConclusaoName, setArquivoConclusaoName] = useState('Escolher arquivo');
	const [arquivoConclusaoNovoName, setArquivoConclusaoNovoName] = useState('Escolher arquivo');

	const [protocolo, setProtocolo] = useState('');
	const [dateProtocolo, setDateProtocolo] = useState<Date>(new Date());
	const [modalProtocolo, setModalProtocolo] = useState<boolean>(false);
	const [sucessProtocolo, setSuccessProtocolo] = useState<boolean>(false);
	const [loadingProtocolo, setLoadingProtocolo] = useState<boolean>(false);
	const [modalArquivoProtocolo, setModalArquivoProtocolo] = useState<boolean>(false);
	const [modalArquivoProtocoloLoading, setModalArquivoProtocoloLoading] = useState<boolean>(false);
	const [arquivoProtocoloName, setArquivoProtocoloName] = useState('Escolher arquivo');
	const [arquivoProtocoloNovoName, setArquivoProtocoloNovoName] = useState('Escolher arquivo');

	const [fileFieldConclusao, setFileFieldConclusao] = useState<Models.ModelFileRegister>();
	const [aproveDataDescritores, setAproveDataDescritores] = useState<AprovationData>({ need: false, date: null, user: null });
	const [aproveDataDoencas, setAproveDataDoencas] = useState<AprovationData>({ need: false, date: null, user: null });

	const sendProtocolo = async () => {
		setLoadingProtocolo(true);

		setProtocolo(defaultForm.getValues('protocolo'));
		setDateProtocolo(defaultForm.getValues('protocolDate'));

		const { status } = await api.post(`/api/v1/cultivares/CultivaresProtecao/${location.state.id}/enviarprotocolo`, {
			protocolo: defaultForm.getValues('protocolo'),
			dataProtocolo: defaultForm.getValues('protocolDate')
		})

		if (status === 200) {
			setSuccessProtocolo(true);
			setTimeout(() => { setModalProtocolo(false); }, 1300)
			setTimeout(() => { history.goBack(); }, 3000)
		}

		setLoadingProtocolo(false);
	}

	const sendProtocoloArquivo = async () => {
		setModalArquivoProtocoloLoading(true);
		let arquivoProtocolo = defaultForm.getValues('fileProtocoloUpload');

		if (!arquivoProtocolo || !arquivoProtocolo.length)
			return;
		try {
			var formData = new FormData();
			formData.append('fileArquivoProtocolo', arquivoProtocolo[0]);

			const requestArquivo = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/SalvarArquivosProtocolo`, formData);
			setTimeout(() => { setModalArquivoProtocolo(false); setModalArquivoProtocoloLoading(false); history.goBack(); }, 1000);
		}
		catch (error: any) {
			console.error(error);
			setModalArquivoProtocoloLoading(false);
		}
	}

	const sendProtecaoMAPA = async () => {
		setLoadingRegistro(true);

		setRegistro(defaultForm.getValues('registro'));
		setDataRegistro(defaultForm.getValues('dateRegistro'));
		setDataTerminoRegistro(defaultForm.getValues('dateTerminoRegistro'));

		const { status } = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/registromapaaprovado`, {
			registro: defaultForm.getValues('registro'),
			dataRegistro: defaultForm.getValues('dateRegistro'),
			dataTerminoRegistro: defaultForm.getValues('dateTerminoRegistro'),
		});

		let arquivoConclusao = defaultForm.getValues('fileRegistro');

		if (arquivoConclusao && arquivoConclusao.length) {
			var formData = new FormData();

			formData.append('fileArquivoConclusao', arquivoConclusao[0]);

			try {
				const requestArquivo = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/SalvarArquivosConclusao`, formData);
			}
			catch (error: any) {
				console.error(error)
			}
		}

		if (status === 200) {
			setSuccessRegistro(true);
			setTimeout(() => {
				setRegistro(defaultForm.getValues('registro'));
				setDataRegistro(defaultForm.getValues('dateRegistro'));
				setDataTerminoRegistro(defaultForm.getValues('dateTerminoRegistro'));
			}, 1300)
			setTimeout(() => { setModalRegistro(false); }, 1500)
			setTimeout(() => { setModalRegistro(false); history.goBack(); }, 3000)
		}

		setLoadingRegistro(false);
	}

	const sendProtecaoMAPAArquivo = async () => {
		setModalArquivoConclusaoLoading(true);
		let arquivoConclusao = defaultForm.getValues('fileRegistroUpload');

		if (!arquivoConclusao || !arquivoConclusao.length)
			return;
		try {
			var formData = new FormData();
			formData.append('fileArquivoConclusao', arquivoConclusao[0]);

			const requestArquivo = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/SalvarArquivosConclusao`, formData);
			setTimeout(() => { setModalArquivoConclusao(false); setModalArquivoConclusaoLoading(false); history.goBack(); }, 1000);
		}
		catch (error: any) {
			console.error(error);
			setModalArquivoConclusaoLoading(false);
		}
	}

	const validaArquivoRelatorioTecnico = async () => {
		try {

			setSelectedArquivosRelatorioTecnico([]);

			const arr = selectedArquivosRelatorioTecnico.filter(e => e.id !== arquivoRelatorioTecnicoID);
			let relatorioAntigo = selectedArquivosRelatorioTecnico.filter(e => e.id === arquivoRelatorioTecnicoID)[0];

			let relatorioNovo: Models.ArquivosRelatorioTecnico = {
				id: relatorioAntigo.id,
				arquivoId: relatorioAntigo.arquivoId,
				cultivaresProtecaoId: relatorioAntigo.cultivaresProtecaoId,
				dataInsercao: relatorioAntigo.dataInsercao,
				motivoRecusa: relatorioAntigo.motivoRecusa,
				status: 2,
				statusDescricao: "Validado",
				usuarioInsercao: relatorioAntigo.usuarioInsercao,
				arquivo: relatorioAntigo.arquivo
			};

			arr.push(relatorioNovo);

			setSelectedArquivosRelatorioTecnico(arr);

			setModalArquivoRelatorioTecnico(false);
		}
		catch (error: any) {
			console.error(error);
			setModalArquivoRelatorioTecnico(false);
		}
	}

	const recusaArquivoRelatorioTecnico = async () => {
		try {
			setMsgErroModalArquivoRelatorioTecnico('');

			if (defaultForm.getValues('motivoRecusaRelatorio') !== "") {
				setSelectedArquivosRelatorioTecnico([]);

				const arr = selectedArquivosRelatorioTecnico.filter(e => e.id != arquivoRelatorioTecnicoID);
				let relatorioAntigo = selectedArquivosRelatorioTecnico.filter(e => e.id == arquivoRelatorioTecnicoID)[0];

				let relatorioNovo: Models.ArquivosRelatorioTecnico = {
					id: relatorioAntigo.id,
					arquivoId: relatorioAntigo.arquivoId,
					cultivaresProtecaoId: relatorioAntigo.cultivaresProtecaoId,
					dataInsercao: relatorioAntigo.dataInsercao,
					motivoRecusa: defaultForm.getValues('motivoRecusaRelatorio'),
					status: 3,
					statusDescricao: "Recusado",
					usuarioInsercao: relatorioAntigo.usuarioInsercao,
					arquivo: relatorioAntigo.arquivo
				};

				arr.push(relatorioNovo);

				setSelectedArquivosRelatorioTecnico(arr);
				setErroModalArquivoRelatorioTecnico(false);
				setModalArquivoRelatorioTecnico(false);
			}
			else {
				setErroModalArquivoRelatorioTecnico(true);
				setMsgErroModalArquivoRelatorioTecnico('O motivo da recusa é obrigatório');
			}

		}
		catch (error: any) {
			console.error(error);
			setModalArquivoRelatorioTecnico(false);
		}
	}

	const validaArquivoDescritores = async () => {
		try {

			setSelectedArquivosDescritores([]);

			const arr = selectedArquivosDescritores.filter(e => e.id !== arquivoDescritoresID);
			let relatorioAntigo = selectedArquivosDescritores.filter(e => e.id === arquivoDescritoresID)[0];

			let relatorioNovo: Models.ArquivosDescritores = {
				id: relatorioAntigo.id,
				arquivoId: relatorioAntigo.arquivoId,
				cultivaresProtecaoId: relatorioAntigo.cultivaresProtecaoId,
				dataInsercao: relatorioAntigo.dataInsercao,
				motivoRecusa: relatorioAntigo.motivoRecusa,
				status: 2,
				statusDescricao: "Validado",
				usuarioInsercao: relatorioAntigo.usuarioInsercao,
				arquivo: relatorioAntigo.arquivo,
				validacaoSG: relatorioAntigo.validacaoSG,
				validacaoFITO: relatorioAntigo.validacaoFITO
			};

			arr.push(relatorioNovo);

			setSelectedArquivosDescritores(arr);

			setModalArquivoDescritores(false);
		}
		catch (error: any) {
			console.error(error);
			setModalArquivoDescritores(false);
		}
	}

	const recusaArquivoDescritores = async () => {
		try {
			setMsgErroModalArquivoDescritores('');

			if (defaultForm.getValues('motivoRecusaDescritores') !== "") {

				setSelectedArquivosDescritores([]);

				const arr = selectedArquivosDescritores.filter(e => e.id !== arquivoDescritoresID);
				let relatorioAntigo = selectedArquivosDescritores.filter(e => e.id === arquivoDescritoresID)[0];

				let relatorioNovo: Models.ArquivosDescritores = {
					id: relatorioAntigo.id,
					arquivoId: relatorioAntigo.arquivoId,
					cultivaresProtecaoId: relatorioAntigo.cultivaresProtecaoId,
					dataInsercao: relatorioAntigo.dataInsercao,
					motivoRecusa: defaultForm.getValues('motivoRecusaDescritores'),
					status: 3,
					statusDescricao: "Recusado",
					usuarioInsercao: relatorioAntigo.usuarioInsercao,
					arquivo: relatorioAntigo.arquivo,
					validacaoSG: relatorioAntigo.validacaoSG,
					validacaoFITO: relatorioAntigo.validacaoFITO

				};

				arr.push(relatorioNovo);

				setSelectedArquivosDescritores(arr);
				setErroModalArquivoDescritores(false);
				setModalArquivoDescritores(false);
			}
			else {
				setErroModalArquivoDescritores(true);
				setMsgErroModalArquivoDescritores('O motivo da recusa é obrigatório');
			}

		}
		catch (error: any) {
			console.error(error);
			setModalArquivoDescritores(false);
		}
	}

	const assinaturaArquivoRelatorioTecnico = async () => {
		try {

			setSelectedArquivosRelatorioTecnico([]);
			setMsgErroModalArquivoRelatorioTecnico('');

			let validadoSGFito = defaultForm.getValues('validacaoSGFITOArquivoRT');

			if (validadoSGFito) {
				let novoArquivo = defaultForm.getValues('novoArquivoRelatorioTecnico');

				formDataFiles.append('fileArquivoRelatorioTecnicoDeObtencao', novoArquivo[0]);
				formDataFiles.append('novoArquivo', novoArquivo[0]);

				const { status } = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/SalvarArquivosProtecao`, formDataFiles);

				if (status === 200) {
					const { data } = await api.get<Models.RequestProtectionSNPC>(`api/v1/cultivares/CultivaresProtecao/${location.state.id}`);

					setSuccessFiles(true);

					setSelectedArquivosRelatorioTecnico(data.arquivosRelatorioTecnico);
				}
				setErroModalArquivoRelatorioTecnico(false);
				setModalArquivoRelatorioTecnico(false);
			}
			else {
				const { data } = await api.get<Models.RequestProtectionSNPC>(`api/v1/cultivares/CultivaresProtecao/${location.state.id}`);

				setSelectedArquivosRelatorioTecnico(data.arquivosRelatorioTecnico);

				setErroModalArquivoRelatorioTecnico(true);
				setMsgErroModalArquivoRelatorioTecnico('É obrigatória a validação de SG e FITO no arquivo final');
			}
		}
		catch (error: any) {
			console.error(error);
			setErroModalArquivoRelatorioTecnico(false);
			setModalArquivoRelatorioTecnico(false);
		}
	}

	const assinaturaArquivoDescritores = async () => {
		try {

			setSelectedArquivosDescritores([]);
			setMsgErroModalArquivoDescritores('');

			let validadoSGFito = defaultForm.getValues('validacaoSGFITOArquivoD');

			if (validadoSGFito) {

				let novoArquivo = defaultForm.getValues("novoArquivoDescritores");

				formDataFiles.append('fileArquivoTabelaDescritores', novoArquivo[0]);
				formDataFiles.append('novoArquivo', novoArquivo[0]);

				const { status } = await api.post(`/api/v1/cultivares/CultivaresProtecao/${location.state.id}/SalvarArquivosProtecao`, formDataFiles);

				if (status === 200) {
					const { data } = await api.get<Models.RequestProtectionSNPC>(`api/v1/cultivares/CultivaresProtecao/${location.state.id}`);

					setSuccessFiles(true);

					setSelectedArquivosDescritores(data.arquivosDescritores);
				}
				setErroModalArquivoDescritores(false);
				setModalArquivoDescritores(false);
			}
			else {
				const { data } = await api.get<Models.RequestProtectionSNPC>(`api/v1/cultivares/CultivaresProtecao/${location.state.id}`);

				setSelectedArquivosDescritores(data.arquivosDescritores);

				setErroModalArquivoDescritores(true);
				setMsgErroModalArquivoDescritores('É obrigatória a validação de SG e FITO no arquivo final');
			}
		}
		catch (error: any) {
			console.error(error);
			setErroModalArquivoDescritores(false);
			setModalArquivoDescritores(false);
		}
	}

	const reenviarArquivoRelatorioTecnico = async () => {
		try {

			setSelectedArquivosRelatorioTecnico([]);

			let novoArquivo = defaultForm.getValues('novoArquivoRelatorioTecnico');

			formDataFiles.append('fileArquivoRelatorioTecnicoDeObtencao', novoArquivo[0]);

			const { status } = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/SalvarArquivosProtecao`, formDataFiles);

			if (status === 200) {
				const { data } = await api.get<Models.RequestProtectionSNPC>(`api/v1/cultivares/CultivaresProtecao/${location.state.id}`);

				setSuccessFiles(true);

				setSelectedArquivosRelatorioTecnico(data.arquivosRelatorioTecnico);
			}

			setModalArquivoRelatorioTecnico(false);
		}
		catch (error: any) {
			console.error(error);
			setModalArquivoRelatorioTecnico(false);
		}
	}

	const reenviarArquivoDescritores = async () => {
		try {

			setSelectedArquivosDescritores([]);

			let novoArquivo = defaultForm.getValues("novoArquivoDescritores");

			formDataFiles.append('fileArquivoTabelaDescritores', novoArquivo[0]);

			const { status } = await api.post(`/api/v1/cultivares/CultivaresProtecao/${location.state.id}/SalvarArquivosProtecao`, formDataFiles);

			if (status === 200) {
				const { data } = await api.get<Models.RequestProtectionSNPC>(`api/v1/cultivares/CultivaresProtecao/${location.state.id}`);

				setSuccessFiles(true);

				setSelectedArquivosDescritores(data.arquivosDescritores);
			}
			setModalArquivoDescritores(false);
		}
		catch (error: any) {
			console.error(error);
			setModalArquivoDescritores(false);
		}
	}

	const validaArquivoAnexo = async () => {
		try {
			setSelectedFilesAdditionals([]);

			const arr = selectedFilesAdditionals.filter(e => e.id !== arquivoAnexoID);
			let arquivoAntigo = selectedFilesAdditionals.filter(e => e.id === arquivoAnexoID)[0];

			let arquivoNovo: Models.AdditionalFiles = {
				id: arquivoAntigo.id,
				arquivoId: arquivoAntigo.arquivoId,
				cultivaresProtecaoId: arquivoAntigo.cultivaresProtecaoId,
				status: 1,
				statusDescricao: "Validado",
				motivoRecusa: defaultForm.getValues('motivoRecusaAnexo'),
				arquivo: arquivoAntigo.arquivo

			};

			arr.push(arquivoNovo);

			setSelectedFilesAdditionals(arr);
			setMsgErroModalArquivoAnexo('');
			setModalArquivoAnexo(false);
		}
		catch (error: any) {
			console.error(error);
			setModalArquivoAnexo(false);
		}
	}

	const recusaArquivoAnexo = async () => {
		try {
			setMsgErroModalArquivoAnexo('');

			if (defaultForm.getValues('motivoRecusaAnexo') !== "") {

				setSelectedFilesAdditionals([]);

				const arr = selectedFilesAdditionals.filter(e => e.id !== arquivoAnexoID);
				let arquivoAntigo = selectedFilesAdditionals.filter(e => e.id === arquivoAnexoID)[0];

				let arquivoNovo: Models.AdditionalFiles = {
					id: arquivoAntigo.id,
					arquivoId: arquivoAntigo.arquivoId,
					cultivaresProtecaoId: arquivoAntigo.cultivaresProtecaoId,
					status: 2,
					statusDescricao: "Recusado",
					motivoRecusa: defaultForm.getValues('motivoRecusaAnexo'),
					arquivo: arquivoAntigo.arquivo
				};

				arr.push(arquivoNovo);

				setSelectedFilesAdditionals(arr);
				setMsgErroModalArquivoAnexo('');
				setModalArquivoAnexo(false);
			}
			else {
				setErroModalArquivoAnexo(true);
				setMsgErroModalArquivoAnexo('O motivo da recusa é obrigatório');
			}

		}
		catch (error: any) {
			console.error(error);
			setModalArquivoAnexo(false);
		}
	}

	const verificarDocumentos = (): boolean => {
		let verificacaoRelatorioTecnico: boolean = false;
		let verificacaoDescritores: boolean = false;

		const arrRelatorioTecnico = selectedArquivosRelatorioTecnico;
		const arrDescritores = selectedArquivosDescritores;

		for (let i = 0, t = arrRelatorioTecnico.length; i < t; i++) {
			if (arrRelatorioTecnico[i].status === STATUS_PROTECTION_ARCHIVE.Validado)
				verificacaoRelatorioTecnico = true;
		}

		for (let i = 0, t = arrDescritores.length; i < t; i++) {
			if (arrDescritores[i].status === STATUS_PROTECTION_ARCHIVE.Validado)
				verificacaoDescritores = true;
		}


		return (verificacaoRelatorioTecnico && verificacaoDescritores);
	}

	const verificarDocumentosAssinados = (): boolean => {
		let verificacaoRelatorioTecnico: boolean = false;
		let verificacaoDescritores: boolean = false;
		let arquivosValidados: boolean = false;

		const arrRelatorioTecnico = selectedArquivosRelatorioTecnico;
		const arrDescritores = selectedArquivosDescritores;

		if (arrRelatorioTecnico[arrRelatorioTecnico.length - 1].status === STATUS_PROTECTION_ARCHIVE.Assinado
			||
			arrDescritores[arrDescritores.length - 1].status === STATUS_PROTECTION_ARCHIVE.Assinado
		) {
			if (arrRelatorioTecnico[arrRelatorioTecnico.length - 1].status !== arrDescritores[arrDescritores.length - 1].status)
				return false
			else
				return true;
		}
		else
			return true;
	}



	const can_edit = (group: string): boolean => {
		let userIsRegulator: boolean = isReguladorProtection(roles);
		let userIsAdmin: boolean = isAdmin(roles);

		switch (group) {
			case 'send_protocol':

				if (!userIsRegulator && !userIsAdmin)
					return false;

				return status.status === STATUS_PROTECTION.AguardandoProtocolo;

			case 'post_validation':

				return status.status === STATUS_PROTECTION.AguardandoAprovacaoMAPA
					|| status.status === STATUS_PROTECTION.AprovadoPeloMAPA
					|| status.status === STATUS_PROTECTION.Cancelado
					|| status.status === STATUS_PROTECTION.EmValidacaoPelaSGFITO
					|| status.status === STATUS_PROTECTION.ReprovadoPeloMAPA
					|| status.status === STATUS_PROTECTION.ValidadoPelaSGFITO
					;

		}

		return false;
	}

	const GetRowNodeId = (node: any): any => {
		return node?.id;
	}

	const ParseGroup01 = (): JSX.Element => {
		return <Fieldset title="1. Requerente">
			<If condition={submited && !selectedApplicants.length}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group className="label-btn">
					<Form.Label>
						Requerente<em>*</em>
					</Form.Label>
					<AddButton
						type="button"
						onClick={() => {
							setChooseApplicant(true);
							setUnselectedApplicant(selectedApplicants);
							setChoosenApplicants([]);
						}}
					/>
				</Form.Group>
			</Form.Row>
			<If condition={insertApplicants && selectedApplicants.length}>
				<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
					<AgGridReact
						rowData={selectedApplicants}
						deltaRowDataMode={true}
						getRowNodeId={GetRowNodeId}
						defaultColDef={{ flex: 1, sortable: true }}
						suppressRowClickSelection={true}
						onRowSelected={onRowSelectedApplicant}
						rowSelection={'multiple'}
						frameworkComponents={{
							action: actionApplicants,
						}}
					>
						<AgGridColumn
							headerName='Requerente'
							field="nome"
						/>
						<AgGridColumn
							headerName="Ação"
							cellRenderer="action"
						/>
					</AgGridReact>
				</div>
			</If>
			<If condition={chooseApplicant}>
				<Modal
					onHide={() => setChooseApplicant(false)}
					show={true}
					title="Lista de requerentes"
				>
					<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
						<AgGridReact
							rowData={unselectedApplicants}
							getRowNodeId={GetRowNodeId}
							defaultColDef={{ flex: 1, sortable: true }}
							deltaRowDataMode={true}
							suppressRowClickSelection={true}
							onRowSelected={onRowSelectedApplicant}
							rowSelection={'multiple'}
						>
							<AgGridColumn
								headerCheckboxSelection
								headerName="Selecionar"
								checkboxSelection={true}
								pinned="left"
							/>
							<AgGridColumn
								headerName='Requerente'
								field='nome'
							/>
						</AgGridReact>
					</div>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setChooseApplicant(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="button"
							onClick={() => {
								setInsertApplicants(true);
								setChooseApplicant(false);
								setSelectedApplicants([...selectedApplicants, ...choosenApplicants]);
							}}
							disabled={!choosenApplicants.length}
						/>
					</div>
				</Modal>
			</If>
		</Fieldset>
	}

	const ParseGroup04 = (): JSX.Element => {
		return <Fieldset title="4. Obtenção da cultivar">
			<If condition={(submited &&
				(!selectedBreeder.length ||
					(neededSpecifyModality
						&& (!defaultForm.getValues("modalityDocument").length || !fileField1)))) ||
				defaultForm.errors.modality ||
				defaultForm.errors.countryOfCultivar ||
				defaultForm.errors.cultivarGeneticTransformationEvent ||
				defaultForm.errors.specificModality}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col} className="label-btn">
					<Form.Label>
						O melhoramento foi realizado pelas seguintes pessoas<em>*</em>
					</Form.Label>
					<AddButton
						onClick={() => {
							setChooseBreeder(true);
							setUnselectedBreederList(selectedBreeder);
							setChoosenBreeder([]);
						}}
						type="button"
					/>
				</Form.Group>
			</Form.Row>
			<If condition={insertBreeder && selectedBreeder.length}>
				<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
					<AgGridReact
						rowData={selectedBreeder}
						deltaRowDataMode={true}
						getRowNodeId={GetRowNodeId}
						defaultColDef={{ flex: 1, sortable: true }}
						frameworkComponents={{
							action: actionBreeder,
						}}
					>
						<AgGridColumn
							headerName='Melhorista'
							field='nome'
						/>
						<AgGridColumn
							headerName="Ação"
							cellRenderer="action"
						/>

					</AgGridReact>
				</div>
			</If>
			<If condition={chooseBreeder}>
				<Modal
					title="Lista de melhoristas"
					show={true}
					onHide={() => setChooseBreeder(false)}
				>
					<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
						<AgGridReact
							rowData={unselectedBreeder}
							deltaRowDataMode={true}
							getRowNodeId={GetRowNodeId}
							defaultColDef={{ flex: 1, sortable: true }}
							suppressRowClickSelection={true}
							onRowSelected={onRowSelectedBreeder}
							rowSelection={'multiple'}
						>
							<AgGridColumn
								headerCheckboxSelection
								headerName="Selecionar"
								checkboxSelection={true}
								pinned="left"
							/>
							<AgGridColumn
								headerName='Melhorista'
								field='nome'
							/>
						</AgGridReact>
					</div>
					<div className="button-position">
						<Button
							title="Cancelar"
							contained={false}
							type="button"
							onClick={() => setChooseBreeder(false)}
						/>
						<Button
							title="Inserir"
							contained={true}
							type="button"
							onClick={() => {
								if (selectedBreeder.length === breeders.length)
									return;
								setChooseBreeder(false);
								setInsertBreeder(true);
								setSelectedBreeder([...selectedBreeder, ...choosenBreeder])
							}}
							disabled={!choosenBreeder.length}
						/>
					</div>
				</Modal>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col} controlId="transferredCultivar">
					<div className="option-cultivar">
						<span>Cultivar transferida?<em>*</em></span>
						<Form.Check
							value="sim"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							onChange={() => setTransferredCultivar(true)}
							checked={transferredCultivar}
						/>
						<Form.Check
							value="não"
							type="radio"
							aria-label="radio 2"
							label="Não"
							onChange={() => setTransferredCultivar(false)}
							checked={!transferredCultivar}
						/>
					</div>
				</Form.Group>
			</Form.Row>
			<If condition={transferredCultivar}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Modalidade<em>*</em>
						</Form.Label>
						<Form.Control
							as="select"
							ref={defaultForm.register}
							name="selectedModality"
							onChange={({ target }: any) => getModality(target.value)}
							isInvalid={defaultForm.errors.modality !== undefined}
						>
							{protectionSNPC.modalitys.map((item, index) => (
								<Form.Control
									key={index}
									as="option"
									label={item.modality}
									value={item.id}
								/>
							))}
						</Form.Control>
						<If condition={defaultForm.errors.modality}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.modality?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<If condition={neededSpecifyModality}>
						<Form.Group as={Col}>
							<Form.Label>
								Especificar a modalidade<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="specificModality"
								placeholder="Insira a modalidade"
								autoComplete="off"
								isInvalid={defaultForm.errors.specificModality !== undefined}
								maxLength={300}
							/>
							<If condition={defaultForm.errors.specificModality}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.specificModality?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Documento comprobatório<em>*</em>
							</Form.Label>
							<Form.File
								ref={defaultForm.register}
								name="modalityDocument"
								type="file"
								label={lblModality}
								data-browse="upload"
								custom
								onChange={({ target }: any) => {
									if (!target.value) {
										return
									}
									setLblModality(`${target.files[0].name}`);
								}}
								accept=".pdf"
							/>
							<span className="warning-pdfs">{STRINGS.WARNING_PDFS}</span>
						</Form.Group>
						<If condition={fileField1}>
							<FileExistent text={fileField1?.nome} date={fileField1?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField1);
								}}
								onClick={() => {
									setArquivosParaExcluir([...arquivosParaExcluir, fileField1?.id]);
									setFileField1({ id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: '' })
								}}
							/>
						</If>
					</If>
				</Form.Row>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={6}>
					<Form.Label>
						País em que a cultivar foi obtida<em>*</em>
					</Form.Label>
					<Form.Control
						ref={defaultForm.register}
						name="countryOfCultivar"
						placeholder="Insira o país em que a cultivar foi obtida"
						isInvalid={defaultForm.errors.countryOfCultivar !== undefined}
						autoComplete="off"
						maxLength={255}
					/>
					<If condition={defaultForm.errors.countryOfCultivar}>
						<Form.Control.Feedback type="invalid">
							{defaultForm.errors.countryOfCultivar?.message}
						</Form.Control.Feedback>
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<Form.Group as={Col} controlId="modifiedOrganism">
					<div className="option-cultivar">
						<span>Cultivar geneticamente modificada?<em>*</em></span>
						<Form.Check
							value="sim"
							type="radio"
							aria-label="radio 1"
							label="Sim"
							onChange={() => setModifiedOrganism(true)}
							checked={modifiedOrganism}
						/>
						<Form.Check
							value="não"
							type="radio"
							aria-label="radio 2"
							label="Não"
							onChange={() => setModifiedOrganism(false)}
							checked={!modifiedOrganism}
						/>
					</div>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<If condition={modifiedOrganism}>
					<Form.Group as={Col}>
						<Form.Label>
							Nome do evento<em>*</em>
						</Form.Label>
						<Form.Control
							as="select"
							ref={defaultForm.register}
							name="cultivarGeneticTransformationEvent"
							isInvalid={defaultForm.errors.cultivarGeneticTransformationEvent !== undefined}
							onChange={({ target }: any) => setEventSelected(target.value)}
							title={selectedEvent?.nomeEvento}
						>
							<Form.Control
								as="option"
								label="Selecione o Evento de transformação genética"
								value=""
							/>
							{arrayEvents.map(item => (
								<Form.Control
									key={item.id}
									as="option"
									label={`${item.nomeEvento.substring(0, 135)}...`}
									value={item.id}
									title={item.nomeEvento}
								/>
							))}
						</Form.Control>
						<If condition={defaultForm.errors.cultivarGeneticTransformationEvent}>
							<Form.Control.Feedback type="invalid">
								{defaultForm.errors.cultivarGeneticTransformationEvent?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</If>
			</Form.Row>
		</Fieldset>;
	}

	const ParseGroup08 = (): JSX.Element => {
		return <Fieldset title="8. Relatório técnico de obtenção">
			<If condition={submited && !fileField3}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Relatório técnico<em>*</em>
					</Form.Label>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<If condition={models && models?.modeloRelatorioTecnicoObtencao}>
						<AddButton
							type="button"
							onClick={() => {
								if (models?.modeloRelatorioTecnicoObtencao) {
									downloadModel(models.modeloRelatorioTecnicoObtencao?.codigo, models.modeloRelatorioTecnicoObtencao.nome);
								}
							}}
							otherTitle="Baixar modelo"
							isDownload={true}
						/>
					</If>
					<If condition={!models?.modeloRelatorioTecnicoObtencao}>
						<NotAvailableModel />
					</If>
					<If condition={loadingDownload}>
						<LoadingInForm />
					</If>
				</Form.Group>
			</Form.Row>
			<Form.Row className="row-custom">
				<If condition={!selectedArquivosRelatorioTecnico.length}>
					<Form.Group as={Col} md={6}>
						<Form.File
							ref={defaultForm.register}
							name="procurementReport"
							label={lblProcurement}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblProcurement(`${target.files[0].name}`);
							}}
						/>
					</Form.Group>
				</If>
				<If condition={selectedArquivosRelatorioTecnico.length}>
					<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedArquivosRelatorioTecnico}
							deltaRowDataMode={true}
							getRowNodeId={GetRowNodeId}
							defaultColDef={{ flex: 1, sortable: true }}
							suppressRowClickSelection={true}
							frameworkComponents={{
								action: actionArquivosRelatorioTecnico
							}}
						>
							<AgGridColumn
								headerName='Arquivo'
								field="arquivo.nome"
								minWidth={800}
							/>
							<AgGridColumn
								headerName='Status'
								field="statusDescricao"
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
							/>
						</AgGridReact>
					</div>
				</If>
				<If condition={modalArquivoRelatorioTecnico}>
					<Modal
						show={true}
						onHide={() => setModalArquivoRelatorioTecnico(false)}
						title="Arquivo de Relatório Técnico"
					>
						<Form autoComplete="off">
							<If condition={erroModalArquivoRelatorioTecnico}>
								<AlertError
									message={msgErroModalArquivoRelatorioTecnico}
								/>
							</If>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<FileExistent text={fileFieldRelatorioTecnico?.nome} date={fileFieldRelatorioTecnico?.dataAtualizacao}
										onDownload={(e: any) => {
											e.preventDefault();
											downloadModelFile(fileFieldRelatorioTecnico);
										}}
										onClick={() => {
											setFileFieldRelatorioTecnico({ id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: '' });
										}}
									/>
								</Form.Group>
							</Form.Row>
							<If condition={
								(
									statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Enviado
								)
							}>
								<Form.Row className="row-custom">
									<Form.Group as={Col}>
										<Form.Label>
											Motivo da recusa
										</Form.Label>
										<Form.Control
											as="textarea"
											ref={defaultForm.register}
											name="motivoRecusaRelatorio"
											type="text"
											placeholder="Em caso de recusa, explique o motivo aqui"
											rows={5}
										/>
									</Form.Group>
								</Form.Row>
							</If>
							<If condition={
								(
									statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Validado ||
									statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Recusado
								)
							}>
								<If condition={
									statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Validado
								}>
									<Form.Row>
										<Form.Group as={Col} className="checkbox-lg">
											<Form.Check
												type="checkbox"
												ref={defaultForm.register}
												name="validacaoSGFITOArquivoRT"
												label="Confirmo que o arquivo assinado foi validado por SG/FITO."
											/>
										</Form.Group>
									</Form.Row>
								</If>
								<If condition={
									(
										statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Recusado
									)
								}>
									<Form.Row className="row-custom">
										<Form.Group as={Col}>
											<Form.Label>
												Motivo da recusa
											</Form.Label>
											<Form.Control
												disabled
												as="textarea"
												ref={defaultForm.register}
												name="msgRecusaModalArquivoRelatorioTecnico"
												defaultValue={msgRecusaModalArquivoRelatorioTecnico}
												type="text"
												rows={5}
											/>
										</Form.Group>
									</Form.Row>
								</If>
								<Form.Row className="row-custom">
									<Form.Group as={Col} md={6}>
										<Form.File
											ref={defaultForm.register}
											name="novoArquivoRelatorioTecnico"
											label={lblProcurement}
											data-browse="upload"
											custom
											accept={statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Validado ? "application/pdf" : ""}
											onChange={({ target }: any) => {
												if (!target.value) {
													return
												}
												setLblProcurement(`${target.files[0].name}`);
											}}
										/>
									</Form.Group>
								</Form.Row>
								<If condition={
									statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Validado
								}>
									<Form.Row>
										<Form.Group as={Col}>
											<Form.Label>
												Anexar arquivo em formato PDF
											</Form.Label>
										</Form.Group>
									</Form.Row>
								</If>


							</If>
							<div className="button-position">
								<Button
									title="Cancelar"
									contained={false}
									type="button"
									onClick={() => setModalArquivoRelatorioTecnico(false)}
								/>
								<If condition={
									statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Enviado
								}>
									<Button
										title="Validar"
										contained={true}
										type="button"
										onClick={() => { validaArquivoRelatorioTecnico(); }}
									/>
									<GenericButton
										title="Recusar"
										variant="btn-danger"
										type="button"
										loading={false}
										onClick={() => { recusaArquivoRelatorioTecnico(); }}
									/>
								</If>
								<If condition={
									statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Validado
								}>
									<Button
										title="Enviar Arquivo Assinado"
										contained={true}
										type="button"
										onClick={() => {
											assinaturaArquivoRelatorioTecnico();
										}}
									/>
								</If>
								<If condition={
									statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Recusado
								}>
									<Button
										title="Enviar Arquivo Corrigido"
										contained={true}
										type="button"
										onClick={() => {
											reenviarArquivoRelatorioTecnico();
										}}
									/>
								</If>
							</div>
						</Form>
					</Modal>
				</If>
			</Form.Row>
		</Fieldset>;
	}

	const ParseGroup09 = (): JSX.Element => {
		return <Fieldset title="9. Tabela de descritores">
			<If condition={submited && !fileField4}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<Form.Row className="row-custom">
				<Form.Group as={Col}>
					<Form.Label>
						Tabela de descritores<em>*</em>
					</Form.Label>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<If condition={models && models?.modeloTabelaDescritores}>
						<AddButton
							type="button"
							onClick={() => {
								if (models?.modeloTabelaDescritores) {
									downloadModel(models?.modeloTabelaDescritores?.codigo, models?.modeloTabelaDescritores?.nome);
								}
							}}
							otherTitle="Baixar modelo"
							isDownload={true}
						/>
					</If>
					<If condition={!models?.modeloTabelaDescritores}>
						<NotAvailableModel />
					</If>
					<If condition={loadingDownload}>
						<LoadingInForm />
					</If>
				</Form.Group>
			</Form.Row>
			<If condition={!selectedArquivosDescritores.length}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.File
							ref={defaultForm.register}
							name="descriptorsTable"
							label={lblDescriptorsTable}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblDescriptorsTable(`${target.files[0].name}`);
							}}
							accept=".docx"
						/>
					</Form.Group>
				</Form.Row>
			</If>
			<If condition={selectedArquivosDescritores.length}>
				<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
					<AgGridReact
						rowData={selectedArquivosDescritores}
						deltaRowDataMode={true}
						getRowNodeId={GetRowNodeId}
						defaultColDef={{ flex: 1, sortable: true }}
						suppressRowClickSelection={true}
						frameworkComponents={{
							action: actionArquivosDescritores
						}}
					>
						<AgGridColumn
							headerName='Arquivo'
							field="arquivo.nome"
							minWidth={800}
						/>
						<AgGridColumn
							headerName='Status'
							field="statusDescricao"
						/>
						<AgGridColumn
							headerName="Ação"
							cellRenderer="action"
						/>
					</AgGridReact>
				</div>
			</If>
			<If condition={modalArquivoDescritores}>
				<Modal
					show={true}
					onHide={() => setModalArquivoDescritores(false)}
					title="Arquivo de Tabela de Descritores"
				>
					<Form autoComplete="off">
						<If condition={erroModalArquivoDescritores}>
							<AlertError
								message={msgErroModalArquivoDescritores}
							/>
						</If>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<FileExistent text={fileFieldDescritores?.nome} date={fileFieldDescritores?.dataAtualizacao}
									onDownload={(e: any) => {
										e.preventDefault();
										downloadModelFile(fileFieldDescritores);
									}}
									onClick={() => {
										setFileFieldDescritores({ id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: '' });
									}}
								/>
							</Form.Group>
						</Form.Row>
						<If condition={
							(
								statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Enviado
							)
						}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Motivo da recusa
									</Form.Label>
									<Form.Control
										as="textarea"
										ref={defaultForm.register}
										name="motivoRecusaDescritores"
										type="text"
										placeholder="Em caso de recusa, explique o motivo aqui"
										rows={5}
									/>
								</Form.Group>
							</Form.Row>
						</If>
						<If condition={
							(
								statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Validado ||
								statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Recusado
							)
						}>
							<If condition={
								statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Validado
							}>
								<Form.Row>
									<Form.Group as={Col} className="checkbox-lg">
										<Form.Check
											type="checkbox"
											ref={defaultForm.register}
											name="validacaoSGFITOArquivoD"
											label="Confirmo que o arquivo assinado foi validado por SG/FITO."
										/>
									</Form.Group>
								</Form.Row>
							</If>
							<If condition={
								(
									statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Recusado
								)
							}>
								<Form.Row className="row-custom">
									<Form.Group as={Col}>
										<Form.Label>
											Motivo da recusa
										</Form.Label>
										<Form.Control
											disabled
											as="textarea"
											ref={defaultForm.register}
											name="msgRecusaModalArquivoDescritores"
											defaultValue={msgRecusaModalArquivoDescritores}
											type="text"
											rows={5}
										/>
									</Form.Group>
								</Form.Row>

							</If>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={6}>
									<Form.File
										ref={defaultForm.register}
										name="novoArquivoDescritores"
										label={lblDescriptor}
										data-browse="upload"
										accept={statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Validado ? "application/pdf" : ""}
										custom
										onChange={({ target }: any) => {
											if (!target.value) {
												return
											}
											setLblDescriptor(`${target.files[0].name}`);
										}}
									/>
								</Form.Group>
							</Form.Row>
							<If condition={
								statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Validado
							}>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>
											Anexar arquivo em formato PDF
										</Form.Label>
									</Form.Group>
								</Form.Row>
							</If>
						</If>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setModalArquivoDescritores(false)}
							/>
							<If condition={
								statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Enviado
							}>
								<Button
									title="Validar"
									contained={true}
									type="button"
									onClick={() => { validaArquivoDescritores(); }}
								/>
								<GenericButton
									title="Recusar"
									variant="btn-danger"
									type="button"
									loading={false}
									onClick={() => { recusaArquivoDescritores(); }}
								/>
							</If>
							<If condition={
								statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Validado
							}>
								<Button
									title="Enviar Arquivo Assinado"
									contained={true}
									type="button"
									onClick={() => {
										assinaturaArquivoDescritores();
									}}
								/>
							</If>
							<If condition={
								statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Recusado
							}>
								<Button
									title="Enviar Arquivo Corrigido"
									contained={true}
									type="button"
									onClick={() => {
										reenviarArquivoDescritores();
									}}
								/>
							</If>
						</div>
					</Form>
				</Modal>
			</If>
		</Fieldset>;
	}

	const [attachmentFiles, setAttachmentFiles] = useState<attachmentFile[]>([]);

	const ParseGroup10 = (): JSX.Element => {
		return <Fieldset title="10. Anexos complementares">
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={8}>
					<Form.Label>
						Anexo complementar
					</Form.Label>
					<Form.File
						ref={defaultForm.register}
						name="additionalFiles"
						label={lblAdditionalFiles}
						data-browse="upload"
						custom
						onChange={({ target }: any) => {
							if (!target.value) {
								return
							}
							setLblAdditionalFiles(`${target.files[0].name}`);
							setArrFilesToSubmit(target.files);
						}}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<br />
					<AddButton
						type="button"
						onClick={() => {
							if (defaultForm.getValues('additionalFiles').length) {
								setSelectedFilesAdditionalsList()
								setLblAdditionalFiles('Escolher arquivo...');
							}
						}}
					/>
				</Form.Group>
			</Form.Row>
			<If condition={selectedFilesAdditionals.length}>
				<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
					<AgGridReact
						rowData={selectedFilesAdditionals}
						deltaRowDataMode={true}
						getRowNodeId={GetRowNodeId}
						defaultColDef={{ flex: 1, sortable: true }}
						suppressRowClickSelection={true}
						frameworkComponents={{
							action: actionAdditionalFiles
						}}
					>
						<AgGridColumn
							headerName="Arquivo"
							field="arquivo.nome"
							minWidth={800}
						/>
						<AgGridColumn
							headerName='Status'
							field="statusDescricao"
							minWidth={100}
						/>
						<AgGridColumn
							headerName="Ação"
							cellRenderer="action"
						/>
					</AgGridReact>
				</div>
			</If>
			<If condition={modalArquivoAnexo}>
				<Modal
					show={true}
					onHide={() => setModalArquivoAnexo(false)}
					title="Anexo Complementar"
				>
					<Form autoComplete="off">
						<If condition={erroModalArquivoAnexo}>
							<AlertError
								message={msgErroModalArquivoAnexo}
							/>
						</If>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<FileExistent text={fileFieldAnexo?.nome} date={fileFieldAnexo?.dataAtualizacao}
									onDownload={(e: any) => {
										e.preventDefault();
										downloadModelFile(fileFieldAnexo);
									}}
									onClick={() => {
										setFileFieldAnexo({ id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: '' });
									}}
								/>
							</Form.Group>
						</Form.Row>
						<If condition={
							(
								statusArquivoAnexo === STATUS_PROTECTION_ATTACHMENT.EmAnalise
							)
						}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Motivo da recusa
									</Form.Label>
									<Form.Control
										as="textarea"
										ref={defaultForm.register}
										name="motivoRecusaAnexo"
										type="text"
										placeholder="Em caso de recusa, explique o motivo aqui"
										rows={5}
									/>
								</Form.Group>
							</Form.Row>
						</If>
						<If condition={
							(
								statusArquivoAnexo === STATUS_PROTECTION_ATTACHMENT.Recusado
							)
						}>
							<Form.Row className="row-custom">
								<Form.Group as={Col}>
									<Form.Label>
										Motivo da recusa
									</Form.Label>
									<Form.Control
										disabled
										as="textarea"
										ref={defaultForm.register}
										name="msgRecusaModalArquivoAnexo"
										defaultValue={msgRecusaModalArquivoAnexo}
										type="text"
										rows={5}
									/>
								</Form.Group>
							</Form.Row>

						</If>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setModalArquivoAnexo(false)}
							/>
							<If condition={
								statusArquivoAnexo === STATUS_PROTECTION_ATTACHMENT.EmAnalise
							}>
								<Button
									title="Validar"
									contained={true}
									type="button"
									onClick={() => { validaArquivoAnexo(); }}
								/>
								<GenericButton
									title="Recusar"
									variant="btn-danger"
									type="button"
									loading={false}
									onClick={() => { recusaArquivoAnexo(); }}
								/>
							</If>
						</div>
					</Form>
				</Modal>
			</If>

		</Fieldset>;
	}

	const ParseGroup11 = (): JSX.Element => {
		return <Fieldset title="11. Protocolo">
			<AddButton
				otherTitleAdd="Informar Protocolo"
				onClick={() => setModalProtocolo(true)}
				type="button"
				disabled={!can_edit('send_protocol')}
			/>
			<If condition={protocolo && dateProtocolo}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Número do Protocolo
						</Form.Label>
						<Form.Control
							disabled
							value={protocolo}
							type="text"
							placeholder="Insira o número do protocolo"
						/>

					</Form.Group>
					<Form.Group as={Col} md={8}>
						<Form.Label>
							Data do Protocolo
						</Form.Label>
						<br />
						<Controller
							control={defaultForm.control}
							name="protocolDate"
							render={({ onChange, value }) => (
								<DatePicker
									readOnly
									selected={dateProtocolo}
									onChange={onChange}
									className="input-custom-to-date"
									locale="pt-BR"
									dateFormat="dd/MM/yyyy"
									placeholderText="Selecionar a data"
								/>
							)}
						/>
					</Form.Group>
					<If condition={!!fileField5?.id}>
						<FileExistent
							label="Arquivo de Protocolo"
							text={fileField5?.nome}
							date={fileField5?.dataAtualizacao}
							onDownload={(e: any) => {
								e.preventDefault();
								downloadModelFile(fileField5);
							}}
						/>
						&nbsp; &nbsp;
						<EditButton onClick={(e: any) => { setModalProtocolo(true); }} />
					</If>
				</Form.Row>
			</If>
			<ConfirmationModal
				show={modalArquivoProtocolo}
				title="Substituir arquivo de Protocolo"
				confirmationMsg="Substituído"
				loading={modalArquivoProtocoloLoading}
				onConfirm={() => sendProtocoloArquivo()}
				onHide={() => setModalArquivoProtocolo(false)}
				variant="success"
				color="white"
			>
				<p>Substituir arquivo de Protocolo</p>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={12}>
						<Form.Label>
							Arquivo de Protocolo
						</Form.Label>
						<br />
						<Form.File
							ref={defaultForm.register}
							type="file"
							name="fileProtocoloUpload"
							label={arquivoProtocoloNovoName}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) return;
								setArquivoProtocoloNovoName(`${target.files[0].name}`);
							}}
						/>
					</Form.Group>
				</Form.Row>
			</ConfirmationModal>
		</Fieldset>;
	}

	const ParseGroup12 = (): JSX.Element => {
		return <Fieldset title="12. Proteção MAPA">
			<AddButton
				otherTitleAdd="Informar Proteção MAPA"
				onClick={() => setModalRegistro(true)}
				type="button"
				disabled={(!isReguladorProtection(roles) && !isAdmin(roles)) || !(status.status === STATUS_PROTECTION.AguardandoAprovacaoMAPA)}
			/>
			<If condition={registro && dataRegistro}>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Número da Proteção
						</Form.Label>
						<Form.Control
							disabled
							value={registro}
							type="text"
						/>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Data de Início da Proteção
						</Form.Label>
						<br />
						<Controller
							control={defaultForm.control}
							name="dateRegistro"
							render={({ onChange, value }) => (
								<DatePicker
									readOnly
									selected={dataRegistro}
									onChange={onChange}
									className="input-custom-to-date"
									locale="pt-BR"
									dateFormat="dd/MM/yyyy"
									placeholderText="Selecionar a data"
								/>
							)}
						/>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Data de Término da Proteção
						</Form.Label>
						<br />
						<Controller
							control={defaultForm.control}
							name="dateTerminoRegistro"
							render={({ onChange, value }) => (
								<DatePicker
									readOnly
									selected={dataTerminoRegistro}
									onChange={onChange}
									className="input-custom-to-date"
									locale="pt-BR"
									dateFormat="dd/MM/yyyy"
									placeholderText="Selecionar a data"
								/>
							)}
						/>
					</Form.Group>
					<If condition={!!fileField6?.id}>
						<FileExistent
							label="Arquivo de Proteção SNPC"
							text={fileField6?.nome}
							date={fileField6?.dataAtualizacao}
							onDownload={(e: any) => {
								e.preventDefault();
								downloadModelFile(fileField6);
							}}
						/>
					</If>
					&nbsp; &nbsp;
					<EditButton onClick={(e: any) => { setModalArquivoConclusao(true); }} />
				</Form.Row>
			</If>
			<If condition={modalRegistro}>
				<ConfirmationModal
					confirmationMsg={STRINGS.REASON_MODAL_ACTION}
					onConfirm={() => sendProtecaoMAPA()}
					loading={loadingRegistro}
					title={STRINGS.MODAL_REGISTRO_MAPA_TITLE}
					onHide={() => setModalRegistro(false)}
					show={modalRegistro}
					variant="success"
					color="white"
				>
					<If condition={sucessRegistro}>
						<AlertSuccess message={STRINGS.MODAL_REGISTRO_SUCCESS} />
					</If>
					<p>{STRINGS.MODAL_REGISTRO_MESSAGE_CONFIRMATION}</p>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>
								Número do Certificado de Proteção
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="registro"
								type="text"
								placeholder="Insira o número do certificado de proteção"
								required
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Data de Início da Proteção
							</Form.Label>
							<br />
							<Controller
								control={defaultForm.control}
								name="dateRegistro"
								render={({ onChange, value }) => (
									<DatePicker
										required
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Data de Término da Proteção
							</Form.Label>
							<br />
							<Controller
								control={defaultForm.control}
								name="dateTerminoRegistro"
								render={({ onChange, value }) => (
									<DatePicker
										required
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										minDate={moment().toDate()}
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col} md={12}>
							<Form.Label>
								Arquivo de Proteção SNPC
							</Form.Label>
							<br />
							<Form.File
								ref={defaultForm.register}
								type="file"
								name="fileRegistro"
								label={arquivoConclusaoName}
								data-browse="upload"
								custom
								style={{ zIndex: 0 }}
								onChange={({ target }: any) => {
									if (!target.value) return;
									setArquivoConclusaoName(`${target.files[0].name}`);
								}}
							/>
						</Form.Group>
					</Form.Row>
				</ConfirmationModal>
			</If>
			<ConfirmationModal
				show={modalArquivoConclusao}
				title="Substituir arquivo do Proteção SNPC"
				confirmationMsg="Substituído"
				loading={modalArquivoConclusaoLoading}
				onConfirm={() => sendProtecaoMAPAArquivo()}
				onHide={() => setModalArquivoConclusao(false)}
				variant="success"
				color="white"
			>
				<p>Substituir arquivo de Proteção SNPC</p>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={12}>
						<Form.Label>
							Arquivo de Proteção SNPC
						</Form.Label>
						<br />
						<Form.File
							ref={defaultForm.register}
							type="file"
							name="fileRegistroUpload"
							label={arquivoConclusaoNovoName}
							data-browse="upload"
							custom
							onChange={({ target }: any) => {
								if (!target.value) return;
								setArquivoConclusaoNovoName(`${target.files[0].name}`);
							}}
						/>
					</Form.Group>
				</Form.Row>
			</ConfirmationModal>
		</Fieldset>;
	}

	const ParseGroup13 = (): JSX.Element => {
		return <Fieldset title="13. Procuração">
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={8}>
					<Form.Label>
						Procuração
					</Form.Label>
					<Form.File
						ref={defaultForm.register}
						name="fileProcuracao"
						type="file"
						label={lblProcuracao}
						data-browse="upload"
						custom
						onChange={({ target }: any) => {
							if (!target.value) {
								return
							}
							setLblProcuracao(`${target.files[0].name}`);
						}}
						accept=".pdf"
					/>
				</Form.Group>
				<If condition={fileFieldProcuracao}>
					<FileExistent text={fileFieldProcuracao?.nome} date={fileFieldProcuracao?.dataAtualizacao}
						onDownload={(e: any) => {
							e.preventDefault();
							downloadModelFile(fileFieldProcuracao);
						}}
						onClick={() => {
							setArquivosParaExcluir([...arquivosParaExcluir, fileFieldProcuracao?.id]);
							setFileFieldProcuracao({ id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: '' })
						}}
					/>
				</If>
			</Form.Row>

		</Fieldset>;
	}

	const ParseGroup14 = (): JSX.Element => {
		return <Fieldset title="14. Observações Gerais">
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={12}>
					<Form.Control
						as="textarea"
						ref={defaultForm.register}
						name="generalObservations"
						type="text"
						placeholder="Insira as observações gerais"
						rows={5}
					/>
				</Form.Group>
				<Form.Group as={Col} md={12}>
					<If condition={can_edit('post_validation')}>
						{ParseDataValidacaoDescritores()}
						{ParseDataValidacaoDoencas()}
					</If>
				</Form.Group>
			</Form.Row>
		</Fieldset>;
	}

	const ParseButtons = (): JSX.Element => {
		let buttonCancelar: JSX.Element = <Button
			title="Cancelar"
			type="button"
			contained={false}
			onClick={() => history.goBack()}
		/>;

		let buttonAprovar: JSX.Element = <GenericButton
			title="Aprovar"
			variant="btn-success"
			onClick={() => {
				setAproveModal(true);
				setIsApproving(true);
			}}
		/>;

		let buttonReprovar: JSX.Element = <GenericButton
			title="Reprovar"
			variant="btn-danger"
			onClick={() => {
				setReproveModal(true);
			}}
		/>;

		//let enviarSGFITO : JSX.Element = <GenericButton
		//	title="Enviar Validação SG/FITO"
		//	variant="btn-primary"
		//	onClick={() => setSendSGFITO(true)}
		///>;

		//let buttonAprovarSG : JSX.Element = <GenericButton
		//	title="Validar SG"
		//	loading={sendingSGFITO}
		//	variant="btn-primary"
		//	onClick={() => {sendAproveSGFITO('sg');}}
		///>;

		//let buttonAprovarFito : JSX.Element = <GenericButton
		//	title="Validar FITO"
		//	loading={sendingSGFITO}
		//	variant="btn-primary"
		//	onClick={() => {sendAproveSGFITO('fito');}}
		///>;

		let buttonSendRegulatory: JSX.Element = <GenericButton
			title="Salvar e enviar para regulatório"
			variant="btn-warning"
			onClick={() => {
				defaultForm.trigger().then(validated => {
					if (verificarDocumentosAssinados()) {
						setSubmited(validated);
						setRegulatoryModal(validated);
						if (!validated) {
							setErrorOnForm(true);
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}
					}
					else {
						setRegulatoryModalAssinaturas(validated);
					}
				})
			}}
			loading={loadingSubmit}
		/>;

		let buttonSave: JSX.Element = <Button
			title="Salvar"
			type="submit"
			contained={true}
			onClick={() => {
				setSubmited(true);
			}}
			isLoading={isRegulatory ? false : loadingSubmit}
		/>;

		let buttonSaveDraft: JSX.Element = <Button
			title="Salvar rascunho"
			type="button"
			contained={true}
			onClick={() => {
				onSubmitDraft();
			}}
			isLoading={loadingDraft}
		/>;

		let userIsRequester: boolean = isRequesterWriteProtection(roles);
		let userIsRegulatory: boolean = isReguladorProtection(roles);
		let userIsDescriptors: boolean = isDescriptors(roles);
		let userIsDiseases: boolean = isDiseases(roles);
		let userIsAdmin: boolean = isAdmin(roles);

		if (status.status !== STATUS_PROTECTION.EmAnalisePeloRegulatorio || (!userIsRegulatory && !userIsAdmin)) {
			buttonAprovar = <Fragment />;
			buttonReprovar = <Fragment />;
		}

		if (status.status === STATUS_PROTECTION.EmAnalisePeloRegulatorio && !verificarDocumentos()) {
			buttonAprovar = <Fragment />;
		}

		let regulatoryStatuses: number[] = [STATUS_PROTECTION.EmPreenchimento, STATUS_PROTECTION.EmCorrecao, STATUS_PROTECTION.ReprovadoPeloRegulatorio, STATUS_PROTECTION.ValidadoPeloRegulatorio];

		if ((status.status && !regulatoryStatuses.includes(status.status)) || !userIsRequester && !userIsAdmin)
			buttonSendRegulatory = <Fragment />;

		let saveStatuses: number[] =
			[STATUS_PROTECTION.ReprovadoPeloRegulatorio, STATUS_PROTECTION.EmPreenchimento];

		let saveStatusesRegulatorio: number[] =
			[STATUS_PROTECTION.AguardandoProtocolo, STATUS_PROTECTION.AguardandoAprovacaoMAPA,
			STATUS_PROTECTION.ReprovadoPeloMAPA, STATUS_PROTECTION.AprovadoPeloMAPA];

		if (
			(status.status && saveStatuses.includes(status.status?.valueOf()))
			||
			(!userIsRegulatory && !userIsAdmin)
		)
			buttonSave = <Fragment />;

		if (status.status !== STATUS_PROTECTION.EmPreenchimento)
			buttonSaveDraft = <Fragment />;

		//if (status.status != STATUS_PROTECTION.ValidadoPeloRegulatorio || (!userIsRegulatory && !userIsAdmin))
		//	enviarSGFITO = <Fragment />;

		//if (!aproveDataDescritores.need || aproveDataDescritores.date != null || status.status != STATUS_PROTECTION.EmValidacaoPelaSGFITO || (!userIsDescriptors && !userIsAdmin))
		//	buttonAprovarSG = <Fragment />;

		//if (!aproveDataDoencas.need || aproveDataDoencas.date != null || status.status != STATUS_PROTECTION.EmValidacaoPelaSGFITO || (!userIsDiseases && !userIsAdmin))
		//	buttonAprovarFito = <Fragment />;

		return <div className="button-position">
			{buttonCancelar}
			{buttonAprovar}
			{/*{enviarSGFITO}*/}
			{/*{buttonAprovarSG}*/}
			{/*{buttonAprovarFito}*/}
			{buttonReprovar}
			{buttonSendRegulatory}
			{buttonSave}
			{buttonSaveDraft}
		</div>;
	}

	const [sendingSGFITO, setSendingSGFITO] = useState<boolean>(false);

	const sendAproveSGFITO = async (type: string) => {
		setSendingSGFITO(true);

		try {
			let formData = new FormData();

			formData.set('fileSG', '');
			formData.set('fileFITO', '');

			switch (type) {
				case 'fito':
				case 'sg':

					let fileSG = defaultForm.getValues('descriptorsTable');
					let fileFITO = defaultForm.getValues('procurementReport');

					if (fileSG !== undefined)
						formData.set('fileSG', fileSG[0] || '');

					if (fileFITO !== undefined)
						formData.set('fileFITO', fileFITO[0] || '');

					break;

				default:

					throw Error('Tipo incorreto ');
			}

			formData.set('observacoes', defaultForm.getValues("generalObservations"));

			let request = await api.post(`api/v1/cultivares/CultivaresProtecao/${location.state.id}/aprovar-sgfito/${type}`, formData);

			await onSubmit(false);

			if (request.status === 200) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setTimeout(() => { history.goBack(); }, 3500)
			}
		}
		catch (exception: any) {
			console.error('Error Sending: ' + type, exception);
		}

		setSendingSGFITO(false);
	}

	const [sendSGFITO, setSendSGFITO] = useState<boolean>(false);
	const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
	const [colaboratorsDescriptors, setColaboratorsDescriptors] = useState<Models.ColaboratorUser[]>([]);
	const [colaboratorsDiseases, setColaboratorsDiseases] = useState<Models.ColaboratorUser[]>([]);

	const ParseDataValidacaoDescritores = (): JSX.Element => {
		if (!aproveDataDescritores.need)
			return <Fragment />;

		let aproveInfo: string = 'Pendente';

		if (aproveDataDescritores.date != null)
			aproveInfo = `Aprovado por ${aproveDataDescritores.user} em ${_generateDate(aproveDataDescritores.date, 'Pt')}`;

		return <Form.Group as={Col} md={12}>
			<Form.Label>
				Aprovação SG:
			</Form.Label>
			<Form.Control
				name="aprovacao_sg_data"
				value={aproveInfo}
				autoComplete="off"
				disabled={true}
				type="text"
			/>
		</Form.Group>;
	}

	const ParseDataValidacaoDoencas = (): JSX.Element => {
		if (!aproveDataDoencas.need)
			return <Fragment />;

		let aproveInfo: string = 'Pendente';

		if (aproveDataDoencas.date != null)
			aproveInfo = `Aprovado por ${aproveDataDoencas.user} em ${_generateDate(aproveDataDoencas.date, 'Pt')}`;

		return <Form.Group as={Col} md={12}>
			<Form.Label>
				Aprovação FITO:
			</Form.Label>
			<Form.Control
				name="aprovacao_fito_data"
				value={aproveInfo}
				autoComplete="off"
				disabled={true}
				type="text"
			/>
		</Form.Group>;
	}

	const getColaborators = async () => {
		try {
			const { data: descritores } = await api.get<Models.ColaboratorUser[]>('api/v1/cultivares/CultivaresRegistro/obteravalidadoresdescritores');
			const { data: doencas } = await api.get<Models.ColaboratorUser[]>('api/v1/cultivares/CultivaresRegistro/obteravalidadoresdoencas');
			setColaboratorsDescriptors(descritores);
			setColaboratorsDiseases(doencas);
		}
		catch (error: any) {
			console.error(error);
		}
	}

	const ParseModalSendSGFITO = (): JSX.Element => {
		return <ValidateRequisition
			show={sendSGFITO}
			onHide={() => setSendSGFITO(false)}
			colaboratorsDescriptors={colaboratorsDescriptors}
			colaboratorsDiseases={colaboratorsDiseases}
			apiURL={`api/v1/cultivares/CultivaresProtecao/${location.state?.id}/enviarparavalidacao`}
		/>;
	}

	return <div className="container container-fluid">
		<If condition={successfulResponse && successFiles && !errorSubmit}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS}
			/>
		</If>
		<If condition={!successfulResponse && errorSubmit !== null}>
			{errorSubmit !== null && errorSubmit.message.length > 0 ? errorSubmit.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<If condition={loadingRequestData}>
			<span className="loading-page">
				<Loading />
			</span>
		</If>
		<If condition={successDraft && !errorDraft}>
			<AlertSuccess message={STRINGS.SUCCESS_DRAFT} />
		</If>
		<If condition={!successDraft && errorDraft}>
			{errorDraft !== null && errorDraft.message.length > 0 ? errorDraft.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}
		</If>
		<If condition={errorOnForm}>
			<AlertError message={STRINGS.ALERT_WARNING_FIELDS} />
		</If>
		<If condition={successRegulatory}>
			<AlertSuccess
				message={STRINGS.REGULATORY_SUCCESS}
			/>
		</If>
		<If condition={!loadingRequestData}>
			<h3>Edição de proteção snpc</h3>
			<p className="text-helper">Campos com <em>*</em> são de preenchimento obrigatório</p>
			<Form aria-disabled onSubmit={defaultForm.handleSubmit(onSubmitForm)}>
				<Fieldset title="Proteção">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Código
							</Form.Label>
							<Form.Control
								value={requestCode}
								disabled
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Status
							</Form.Label>
							<Form.Control
								value={status.statusDescricao}
								disabled
								readOnly
							/>
						</Form.Group>
					</Form.Row>
					<If condition={status.status === STATUS_PROTECTION.ReprovadoPeloRegulatorio}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={12}>
								<Form.Label>
									Motivo da Recusa
								</Form.Label>
								<Form.Control
									value={motivoRecusaCultivares}
									disabled
									readOnly
								/>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>

				{ParseGroup01()}

				<Fieldset title="2. Táxon">
					<If condition={defaultForm.errors.speciesName}>
						<AlertError
							message={STRINGS.ALERT_WARNING_FIELDS}
						/>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Nome comum da espécie<em>*</em>
							</Form.Label>
							<Form.Control
								as="select"
								ref={defaultForm.register}
								name="speciesName"
								isInvalid={defaultForm.errors.speciesName !== undefined}
								onChange={({ target }: any) => {
									getCultureById(target.value);
									setScientificName(target.value);
									let index = target.options.selectedIndex;
									setSpecieName(target.options[index].label);
								}}
							>
								<Form.Control
									as="option"
									label="Escolha uma opção"
									value=""
								/>
								{arrayCulture.map(item => (
									<Form.Control
										as="option"
										label={item.nome}
										value={item.id}
										key={item.id}
									/>
								))}
							</Form.Control>
							<If condition={defaultForm.errors.speciesName}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.speciesName?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Nome científico da espécie<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="scientificName"
								autoComplete="off"
								placeholder="Nome científico da espécie"
								readOnly
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>

				<Fieldset title="3. Cultivar">
					<If condition={defaultForm.errors.proposedDenomination || defaultForm.errors.experimentalDenomination}>
						<AlertError
							message={STRINGS.ALERT_WARNING_FIELDS}
						/>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Denominação proposta<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="proposedDenomination"
								placeholder="Insira a denominação proposta"
								autoComplete="off"
								isInvalid={defaultForm.errors.proposedDenomination !== undefined}
								maxLength={100}
							/>
							<If condition={defaultForm.errors.proposedDenomination}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.proposedDenomination?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Denominação experimental ou pré-comercial<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="experimentalDenomination"
								placeholder="Insira a denominação experimental ou pré-comercial"
								autoComplete="off"
								isInvalid={defaultForm.errors.experimentalDenomination !== undefined}
								maxLength={100}
							/>
							<If condition={defaultForm.errors.experimentalDenomination}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.experimentalDenomination?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
				</Fieldset>

				{ParseGroup04()}

				<Fieldset title="5. Proteção da cultivar">
					<If condition={defaultForm.errors.intergovernametalOrganization ||
						defaultForm.errors.typeOfprotection ||
						defaultForm.errors.protocolNumber ||
						defaultForm.errors.denomination
					}>
						<AlertError
							message={STRINGS.ALERT_WARNING_FIELDS}
						/>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col} controlId="cultivarProtection">
							<div className="option-cultivar">
								<span>A proteção da cultivar foi solicitada anteriormente no exterior?<em>*</em></span>
								<Form.Check
									value="sim"
									type="radio"
									aria-label="radio 1"
									label="Sim"
									onChange={() => setCultivarProtection(true)}
									checked={cultivarProtection}
								/>
								<Form.Check
									value="não"
									type="radio"
									aria-label="radio 2"
									label="Não"
									onChange={() => setCultivarProtection(false)}
									checked={!cultivarProtection}
								/>
							</div>
						</Form.Group>
					</Form.Row>
					<If condition={cultivarProtection}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									País/Organização Intergovernamental<em>*</em>
								</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									name="intergovernametalOrganization"
									placeholder="Insira o país/organização governamental"
									autoComplete="off"
									isInvalid={defaultForm.errors.intergovernametalOrganization !== undefined}
									maxLength={100}
								/>
								<If condition={defaultForm.errors.intergovernametalOrganization}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.intergovernametalOrganization?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Tipo de proteção<em>*</em>
								</Form.Label>
								<Form.Control
									as="select"
									ref={defaultForm.register}
									name="typeOfprotection"
									isInvalid={defaultForm.errors.typeOfprotection !== undefined}
								>
									{protectionSNPC.typeOfProtection.map(item => (
										<Form.Control
											as="option"
											key={item.id}
											label={item.type}
											value={item.type}
										/>
									))}
								</Form.Control>
								<If condition={defaultForm.errors.typeOfprotection}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.typeOfprotection?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Data<em>*</em>
								</Form.Label>
								<br />
								<Controller
									control={defaultForm.control}
									name="date"
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
										/>
									)}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Número de protocolo<em>*</em>
								</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									name="protocolNumber"
									placeholder="Insira o número do protocolo"
									autoComplete="off"
									isInvalid={defaultForm.errors.protocolNumber !== undefined}
									maxLength={50}
								/>
								<If condition={defaultForm.errors.protocolNumber}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.protocolNumber?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Denominação<em>*</em>
								</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									name="denomination"
									placeholder="Insira a denominação"
									autoComplete="off"
									isInvalid={defaultForm.errors.denomination !== undefined}
									maxLength={200}
								/>
								<If condition={defaultForm.errors.denomination}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.denomination?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>

				<Fieldset title="6. Primeira comercialização da cultivar">
					<If condition={(submited && ((cultivarInBrazil &&
						defaultForm.errors.denominationCultivar && defaultForm.errors.commercializationFile) ||
						defaultForm.errors.countryOfCommercialization ||
						defaultForm.errors.denominationOfCommercialization ||
						(cultivarOutside && (defaultForm.errors.countryOfCommercialization ||
							defaultForm.errors.denominationOfCommercialization))))
					}>
						<AlertError
							message={STRINGS.ALERT_WARNING_FIELDS}
						/>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col} controlId="cultivarInBrazil">
							<div className="option-cultivar">
								<span>A cultivar foi oferecida à venda ou comercializada no Brasil?<em>*</em></span>
								<Form.Check
									value="sim"
									type="radio"
									aria-label="radio 1"
									label="Sim"
									onChange={() => setCultivarInBrasil(true)}
									checked={cultivarInBrazil}
								/>
								<Form.Check
									value="não"
									type="radio"
									aria-label="radio 2"
									label="Não"
									onChange={() => setCultivarInBrasil(false)}
									checked={!cultivarInBrazil}
								/>
							</div>
						</Form.Group>
					</Form.Row>
					<If condition={cultivarInBrazil}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Denominação<em>*</em>
								</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									name="denominationCultivar"
									placeholder="Insira a denominação da cultivar"
									autoComplete="off"
									isInvalid={defaultForm.errors.denominationCultivar !== undefined}
									maxLength={200}
								/>
								<If condition={defaultForm.errors.denominationCultivar}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.denominationCultivar?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Data da primeira comercialização<em>*</em>
								</Form.Label>
								<br />
								<Controller
									control={defaultForm.control}
									name="dateOfFirstCommercialization"
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
											minDate={moment().add(-1, 'y').toDate()}
										/>
									)}
								/>
							</Form.Group>
						</Form.Row>
						<If condition={(
							submited && cultivarInBrazil && !defaultForm.getValues("commercializationFile").length
							&& defaultForm.errors.commercializationFile
						)}>
							<Form.Row className="row-custom">
								<Form.Group as={Col} md={7}>
									<AlertError
										message={STRINGS.ALERT_WARNING_FIELDS}
									/>
								</Form.Group>
							</Form.Row>
						</If>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={7}>
								<Form.Label>
									Anexar 1ª nota fiscal de comercialização da cultivar<em>*</em>
								</Form.Label>
								<Form.File
									ref={defaultForm.register}
									name="commercializationFile"
									label={lblNFCultivar}
									type="file"
									onChange={({ target }: any) => {
										if (!target.value) {
											return
										}
										setLblNFCultivar(`${target.files[0].name}`);
									}}
									accept=".pdf"
									data-browse="upload"
									custom
								/>
								<If condition={defaultForm.errors.commercializationFile}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.commercializationFile?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
							<If condition={fileField2}>
								<FileExistent text={fileField2?.nome} date={fileField2?.dataAtualizacao}
									onDownload={(e: any) => {
										e.preventDefault();
										downloadModelFile(fileField2);
									}}
									onClick={() => {
										setArquivosParaExcluir([...arquivosParaExcluir, fileField2?.id]);
										setFileField2({ id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: '' });
									}}
								/>
							</If>
						</Form.Row>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col} controlId="cultivarOutside">
							<div className="option-cultivar">
								<span>A cultivar foi oferecida à venda ou comercializada no Exterior?<em>*</em></span>
								<Form.Check
									value="sim"
									type="radio"
									aria-label="radio 1"
									label="Sim"
									onChange={() => setCultivarOutside(true)}
									checked={cultivarOutside}
								/>
								<Form.Check
									value="não"
									type="radio"
									aria-label="radio 2"
									label="Não"
									onChange={() => setCultivarOutside(false)}
									checked={!cultivarOutside}
								/>
							</div>
						</Form.Group>
					</Form.Row>
					<If condition={cultivarOutside}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									País<em>*</em>
								</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									name="countryOfCommercialization"
									placeholder="Insira o país"
									autoComplete="off"
									isInvalid={defaultForm.errors.countryOfCommercialization !== undefined}
									maxLength={100}
								/>
								<If condition={defaultForm.errors.countryOfCommercialization}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.countryOfCommercialization?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Denominação<em>*</em>
								</Form.Label>
								<Form.Control
									ref={defaultForm.register}
									name="denominationOfCommercialization"
									placeholder="Insira a denominação"
									autoComplete="off"
									isInvalid={defaultForm.errors.denominationOfCommercialization !== undefined}
									maxLength={255}
								/>
								<If condition={defaultForm.errors.denominationOfCommercialization}>
									<Form.Control.Feedback type="invalid">
										{defaultForm.errors.denominationOfCommercialization?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Data da primeira comercialização<em>*</em>
								</Form.Label>
								<br />
								<Controller
									control={defaultForm.control}
									name="dateOfCommercialization"
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
										/>
									)}
								/>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>

				<Fieldset title="7. Declaração de amostra viva">
					<If condition={defaultForm.errors.sampleAddress ||
						defaultForm.errors.contactPersonInfo
					}>
						<AlertError
							message={STRINGS.ALERT_WARNING_FIELDS}
						/>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Endereço onde a amostra viva será mantida<em>*</em>
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="sampleAddress"
								placeholder="Insira o endereço da amostra viva"
								autoComplete="off"
								isInvalid={defaultForm.errors.sampleAddress !== undefined}
								maxLength={300}
							/>
							<If condition={defaultForm.errors.sampleAddress}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.sampleAddress?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Pessoa de contato<em>*</em> &#40;nome, e-mail e telefone&#41;
							</Form.Label>
							<Form.Control
								ref={defaultForm.register}
								name="contactPersonInfo"
								placeholder="Insira o nome da pessoa de contato"
								autoComplete="off"
								isInvalid={defaultForm.errors.contactPersonInfo !== undefined}
								maxLength={500}
							/>
							<If condition={defaultForm.errors.contactPersonInfo}>
								<Form.Control.Feedback type="invalid">
									{defaultForm.errors.contactPersonInfo?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
				</Fieldset>

				{ParseGroup08()}

				{ParseGroup09()}

				{ParseGroup10()}

				{ParseGroup11()}

				{ParseGroup12()}

				{ParseGroup13()}

				{ParseGroup14()}

				{ParseButtons()}
			</Form>
		</If>

		{ParseModalSendSGFITO()}

		<If condition={aproveModal}>
			<ConfirmationModal
				show={aproveModal}
				onHide={() => setAproveModal(false)}
				title={STRINGS.APROVE_TITLE}
				confirmationMsg={STRINGS.APROVE_CONFIRMATION}
				onConfirm={() => {
					onSubmitForm();
				}}
				loading={loadingAprove}
				variant="success"
				color="white"
			>
				<If condition={successAprove}>
					<AlertSuccess
						message={STRINGS.APROVE_SUCCESS}
					/>
				</If>
				<p>{STRINGS.APROVE_MSG}</p>
			</ConfirmationModal>
		</If>

		<If condition={reproveModal}>
			<ReasonModal
				show={reproveModal}
				onHide={() => setReproveModal(false)}
				title={STRINGS.REASON_REPROVE_TITLE}
				actionMsg={STRINGS.REASON_REPROVE_ACTION}
				disabled={reproveTxt.length ? false : true}
				loading={loadingReprove}
				onConfirm={() => {
					reproveRequisition();
				}}
			>
				<Form.Label>
					{STRINGS.REASON_MODAL_MSG}
				</Form.Label>
				<Form.Control
					value={reproveTxt}
					onChange={({ target }: any) => setReproveTxt(target.value)}
					as="textarea"
				/>
			</ReasonModal>
		</If>

		<If condition={regulatoryModal}>
			<ConfirmationModal
				show={regulatoryModal}
				onHide={() => {
					setRegulatoryModal(false);
					setIsRegulatory(false);
				}}
				title={STRINGS.REGULATORY_TITLE}
				confirmationMsg={STRINGS.REGULATORY_ACTION}
				onConfirm={() => {
					setRegulatoryModal(false);
					setIsRegulatory(false);
					onSubmitForm();
				}}
				loading={loadingRegulatory}
				variant="success"
				color="white"
			>
				<p>{STRINGS.REGULATORY_MESSAGE}</p>
			</ConfirmationModal>
		</If>

		<If condition={modalProtocolo}>
			<ConfirmationModal
				confirmationMsg={STRINGS.REASON_MODAL_ACTION}
				onConfirm={() => sendProtocolo()}
				loading={loadingProtocolo}
				title={STRINGS.MODAL_PROTOCOL_TITLE}
				onHide={() => setModalProtocolo(false)}
				show={modalProtocolo}
				variant="success"
				disabled={false}
				color="white"
			>
				<If condition={sucessProtocolo}>
					<AlertSuccess message={STRINGS.MODAL_PROTOCOL_SUCCESS} />
				</If>
				<p>{STRINGS.MODAL_PROTOCOL_MESSAGE_CONFIRMATION}</p>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={8}>
						<Form.Label>
							Número do Protocolo
						</Form.Label>
						<Form.Control
							ref={defaultForm.register}
							name="protocolo"
							type="text"
							placeholder="Insira o número do protocolo"
							required
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>
							Data do Protocolo
						</Form.Label>
						<br />
						<Controller
							control={defaultForm.control}
							name="protocolDate"
							render={({ onChange, value }) => (
								<DatePicker
									required
									selected={value}
									onChange={onChange}
									className="input-custom-to-date"
									locale="pt-BR"
									dateFormat="dd/MM/yyyy"
									placeholderText="Selecionar a data"
								/>
							)}
						/>
					</Form.Group>
				</Form.Row>
				<If condition={!!fileField5?.id}>
					<Form.Row>
						<Form.Group as={Col}>
							<FileExistent
								label="Arquivo de Protocolo"
								text={fileField5?.nome}
								date={fileField5?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField5);
								}}
							/>
							&nbsp; &nbsp;
							<EditButton onClick={(e: any) => { setModalProtocolo(true); }} />
						</Form.Group>
					</Form.Row>
				</If>
			</ConfirmationModal>
		</If>


		<If condition={regulatoryModalAssinaturas}>
			{/*<ConfirmationModal*/}
			{/*	show={regulatoryModalAssinaturas}*/}
			{/*	onHide={() => {*/}
			{/*		setRegulatoryModalAssinaturas(false);*/}
			{/*		setIsRegulatory(false);*/}
			{/*	}}*/}
			{/*	title={STRINGS_CULTIVARES.TITULO_CONFIRMAR_ASSINATURAS}*/}
			{/*	confirmationMsg={STRINGS_CULTIVARES.BOTAO_CONFIRMAR_ASSINATURAS}*/}
			{/*	onConfirm={() => {*/}
			{/*		setRegulatoryModal(false);*/}
			{/*		setIsRegulatory(false);*/}
			{/*		onSubmitForm();*/}
			{/*	}}*/}
			{/*	loading={loadingRegulatory}*/}
			{/*	variant="success"*/}
			{/*	color="white"*/}
			{/*>*/}
			{/*	<p>{STRINGS_CULTIVARES.CONFIRMAR_ASSINATURAS}</p>*/}

			{/*</ConfirmationModal>*/}
			<Modal
				show={true}
				onHide={() => setRegulatoryModalAssinaturas(false)}
				title={STRINGS_CULTIVARES.TITULO_CONFIRMAR_ASSINATURAS}
			>
				<Form autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								<p>{STRINGS_CULTIVARES.CONFIRMAR_ASSINATURAS}</p>
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Voltar"
							contained={false}
							type="button"
							onClick={() => setRegulatoryModalAssinaturas(false)}
						/>
					</div>
				</Form>
			</Modal>
		</If>
	</div>;
}