export const STRINGS_CADERNOCONFORMIDADE = {
    NAV_CADERNO_CONFORMIDADE: "Caderno de Conformidade",
    NAV_REGISTRO_CADERNO_CONFORMIDADE: "Registros",

    NAV_REGISTRO_CADERNO_CONFORMIDADE_VEGETACAO: "Caderno de Conformidade - Vegetação",

    SEARCH_CADERNO_CONFORMIDADE_VEGETACAO: "Consulta de caderno de conformidade - vegetação",
    REGISTER_CADERNO_CONFORMIDADE_VEGETACAO: "Cadastro de caderno de conformidade - vegetação",
    EDITION_CADERNO_CONFORMIDADE_VEGETACAO: "Edição de caderno de conformidade  - vegetação",

    BLOCK_ONE: "1. Dados principais",
    BLOCK_TWO: "2. Documentos disponíveis na Pasta de Processo",
    BLOCK_THREE: "3. Planejamento do Ensaio",
    BLOCK_FOUR: "4. Condução do Ensaio",
    BLOCK_FIVE: "5. Destruição e Descarte",
    BLOCK_SIX: "6. Reportes e Relatórios",
    BLOCK_SEVEN: "7. Anotações Gerais",

    ANOTACOES_MODAL_TITLE: "Anotações Gerais",
    PLANTIOS_MODAL_TITLE: "Formulário de Notificação de Plantio",
    LIMPEZADESTRUICAO_MODAL_TITLE: "Formulário de limpeza de maquinários/equipamentos utilizados na destruição e descarte",
    DATACOLHEITA_MODAL_TITLE: "Data da colheita",
    INSPECAOEQUIPAMENTOS_MODAL_TITLE: "Formulário de Registro de Limpeza e Inspeção de Equipamentos",
    LIMPEZAINSTALACOES_MODAL_TITLE: "Formulário de registro de limpeza das Instalações",

}