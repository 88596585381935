import React, { useState, useEffect } from 'react';
import { ContextData, Token, User, UserForm } from '../../models/user';
import api, { apiFile, apiFileDownload } from '../../services/api';
import history from '../../routes/history';
import { AxiosResponse } from 'axios';

const emptySessionData: ContextData = {
	handleLogin: null,
	handleLogout: null,
	loading: true,
	user: {},
	token: {
		access_token: '',
		refresh_token: '',
		refresh_expires_in: ''
	}
};

export default function useAuth() {
	const [user, setUser] = useState<User>(emptySessionData.user);
	const [token, setToken] = useState<Token>(emptySessionData.token);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const token = localStorage.getItem('token');
		const refreshToken = localStorage.getItem('refresh_token');
		const refreshExpiresIn = localStorage.getItem('refresh_expires_in');

		console.log('Use Auth', token, refreshToken, refreshExpiresIn);

		if (token) {
			api.defaults.headers.Authorization = `Bearer ${token}`
			apiFile.defaults.headers.Authorization = `Bearer ${token}`
			apiFileDownload.defaults.headers.Authorization = `Bearer ${token}`

			setToken({
				access_token: token,
				refresh_token: refreshToken,
				refresh_expires_in: String(refreshExpiresIn)
			})
		}
		setLoading(false);
	}, [])

	const handleLogin = async ({ username, password }: UserForm): Promise<AxiosResponse> => {
		let response: any = null;

		try
		{
			const { data } = await api.get('saml/get-sesion-data');

			if (data.status === true)
			{
				setToken(data.data);
				localStorage.setItem('token', data.data.access_token);
				localStorage.setItem('refreshToken', data.data.refresh_token);
				localStorage.setItem('refreshExpiresIn', data.data.refresh_expires_in);
				localStorage.setItem('tokenTimeCreated', data.data.created);
		
				api.defaults.headers.Authorization = `${data.data.token_type} ${data.data.access_token}`
				apiFile.defaults.headers.Authorization = `${data.data.token_type} ${data.data.access_token}`
				apiFileDownload.defaults.headers.Authorization = `${data.data.token_type} ${data.data.access_token}`
		
				history.push('/');
			}

			response = data;
		}
		catch (error:any)
		{
			response = error?.response;
			// history.push('saml/login');
		}

		return response;
	}

	const handleLogout = async () => {

		setToken({
			access_token: '',
			refresh_token: '',
			refresh_expires_in: ''
		});
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('refreshExpiresIn');
		localStorage.removeItem('tokenTimeCreated');

		api.defaults.headers.Authorization = undefined;
		apiFile.defaults.headers.Authorization = undefined;
		apiFileDownload.defaults.headers.Authorization = undefined;

		const { data } = await api.get('saml/logout');

		setTimeout(() => {
			history.push('/login');
		}, 500);
	}

	return { user, loading, token, handleLogin, handleLogout };
}