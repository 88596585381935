//Basic REACT Components
import React, { useState, useEffect, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

//Custom Componentes
import Button from "../../../components/Button";
import EditButton from '../../../components/EditButton';
import CancelButton from '../../../components/CancelRequisitionButton';
import ViewButton from '../../../components/ViewButton';
import api, { apiFileDownload } from '../../../services/api';
import If from "../../../components/If";
import SearchEmpty from '../../../components/SearchEmpty';
import Loading from '../../../components/Loading';
import ReasonModal from '../../../components/ReasonModal';
import AlertSuccess from '../../../components/AlertSuccess';

//Custom Services
import { DecodedToken, User, UsersGroups } from '../../../models/user';
import jwtDecode from 'jwt-decode';

import { STRINGS_ADMIN } from '../../../util/admin/strings';

//Page Specifics
import SimpleInput from 'react-simple-input';


export default function List ()
{
	const baseUrl: string = '/api/v1/admin/Usuarios/grupos';
	const titleText: string = STRINGS_ADMIN.SEARCH_USUARIOS;

	//Base da página
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const token = String(localStorage.getItem('token'));
	const { roles, unique_name }: DecodedToken = jwtDecode(token);

	//Interações da Página
	const [error, setError] = useState<any>(null);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	const [isCancel, setIsCancel] = useState<boolean>(false);
	const [successCancel, setSuccessCancel] = useState<boolean>(false);
	const [cancelError, setCancelError] = useState<any>(null);
	const [reasonCancel, setReasonCancel] = useState('');
	
	//Campos do filtro
	const [usuarios, setUsuarios] = useState<User[]>([]);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [loadingUsuariosById, setLoadingUsuariosById] = useState<boolean>(false);
	const [grupos, setGrupos] = useState<UsersGroups>();
	
	const { register, handleSubmit, watch, control } = useForm({
		defaultValues: {
			userName: '',
			nome: '',
			email: '',
            ativo: false,
		}
	})


	useEffect(() => {	
		

		const getGrupos = async () => {
		
			setError(false);
			
			try
			{
				const { data, status } = await api.get<User[]>(`${baseUrl}`);
	
				if (status !== 200 && status !== 201)
					throw new Error('Erro ao buscar os dados da lista');
	
				if (data.length)
				{
					setUsuarios(data);
					setEmptySearch(false);
				}
				else
				{
					setEmptySearch(true);
				}
			}
			catch (error:any)
			{
				setError(true);
			}
		};
		
		getGrupos();
	
	}, []);

	const search = async (queries?: any) => {

		setError(false);
		setLoadingSearch(true);

		const {
			//codigo,
			nome
		} = watch();

		try {

			let params = new URLSearchParams();

            params.append("nomeDoGrupo", queries?.nomeDoGrupo);

			const { data } = await api.get<User[]>(`${baseUrl}/?${params.toString()}`);

			if (data.length)
			{
				setUsuarios(data);
				setEmptySearch(false);
			}
			else
			{
				setEmptySearch(true);
			}
		}
		catch (error:any)
		{
			setError(true);
		}

		setLoadingSearch(false);
	}

	
	const getGruposById = async (id: number) => {
		
		setLoadingUsuariosById(true);
		
		try
		{
			const { data } = await api.get(`${baseUrl}/${id}`);
			setUsuarios(data);
		}
		catch (error:any)
		{
			throw new Error(error);
		}

		setLoadingUsuariosById(false);
	}
	

	const acoes = ({ data }: any) => {

		return (
		<Fragment>
			<EditButton onClick={() => {
				history.push({
                    pathname: `/grupo-permissao/editar`,
                    state: {
                        id: data.id
                    }
                })
			}} />
			&nbsp;&nbsp;
			<CancelButton onClick={() => {
				setIsCancel(true);
                getGruposById(data.id);
                setSuccessCancel(false);
                setReasonCancel('');
			}} />

		</Fragment>);
	}

	return (
		<div className="container-custom-search">
			<div className="top-content">
				<h3>{titleText}</h3>
			</div>
			<div className="row-buttons">
                <Button
                    onClick={() => history.push(`/grupo-permissao/cadastrar`)}
                    contained
                    title="Adicionar Grupo de Permissão"
                    type="button"
				/>
			</div>
			<br />
			<Form onSubmit={handleSubmit(search)} autoComplete="off">
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>Nome do Grupo</Form.Label>
						<Controller
							control={control}
							name="nomeDoGrupo"
							maxLength={150}
							render={({ onChange, value }) => (
								<SimpleInput
									defaultValue={value}
									clearButton
									className="form-control"
									onChange={onChange}
								/>
							)}
						/>
					</Form.Group>
                    <span className="filter-btn">
						<Button
							title="Buscar"
							type="submit"
							contained
							isLoading={loadingSearch}
						/>
					</span>
				</Form.Row>				
			</Form>
			<If condition={emptySearch}>
				<div className="div-empty">
					<SearchEmpty text={'Nenhum grupo disponível.'} />
				</div>
			</If>
			<If condition={loading}>
				<div className="loading-container">
					<Loading />
				</div>
			</If>

			<If condition={!loading && !error && !emptySearch && usuarios.length}>
				<div className="search-content">
					<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
						<AgGridReact
							rowData={usuarios}
							defaultColDef={{ flex: 1, sortable: true, resizable: true }}
							pagination
							paginationPageSize={10}
							gridOptions={{ headerHeight: 80 }}
							frameworkComponents={{
								actions: acoes,
							}}
						>
							<AgGridColumn
								headerName='Nome do Grupo'
								headerClass="myfunc-default-header"
								field='nomeDoGrupo'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>								
                            <AgGridColumn
                                headerName="Ações"
                                cellRenderer="actions"
                                maxWidth={100}
                            />				
						</AgGridReact>
					</div>
				</div>
			</If>
		</div>
	);
}