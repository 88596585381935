import React from "react";
import { Form, Col } from "react-bootstrap";
import { Controller } from "react-hook-form";

import {uuid} from "../../../services/uuid";
import "./style.scss";

interface Props
{
	name				: string;
	label				: string | JSX.Element;
	onChange			: (newStats:boolean) => void;
	defaultValue?		: boolean;
	id?					: string;
	small?				: boolean;
	disabled?			: boolean;
	columns?			: number;
}

interface State
{
	checked				: boolean;
}

export default class Toggle extends React.Component<Props, State>
{
	public static defaultProps = {
		id				: uuid(),
		defaultValue	: false,
		small			: false,
		disabled		: false,
		columns			: 12,
	};

	constructor (props:Props)
	{
		super(props)

		this.state = {
			checked : this.props.defaultValue || false,
		}
	}

	onChange (event:any)
	{
		let newStats = !this.state.checked

		this.setState({checked:newStats});
		this.props.onChange(newStats);
	}

	render()
	{
		return <Form.Group as={Col} md={this.props.columns}>
			<Form.Label>{this.props.label}</Form.Label>
			<div className={"toggle-switch" + (this.props.small ? " small-switch" : "")}>
				<input
					type="checkbox"
					id={this.props.id}
					name={this.props.name}
					className="toggle-switch-checkbox"
					checked={this.state.checked}
					onChange={(event:any) => {this.onChange(event);}}
					disabled={this.props.disabled}
				/>
				<label
					className="toggle-switch-label"
					tabIndex={this.props.disabled ? -1 : 1}
					onKeyDown={(event:any) => {this.onChange(event);}}
					htmlFor={this.props.id}
				>
					<span
						className={this.props.disabled ? "toggle-switch-inner toggle-switch-disabled" : "toggle-switch-inner"}
						data-yes={true}
						data-no={false}
						tabIndex={-1}
					/>
					<span
						className={this.props.disabled ? "toggle-switch-switch toggle-switch-disabled" : "toggle-switch-switch"}
						tabIndex={-1}
					/>
				</label>
			</div>
		</Form.Group>;
	}
}