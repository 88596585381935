import React, { useState, useContext } from 'react';
import { Context } from '../../context/AuthContext';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import If from '../../components/If';
import AlertError from '../../components/AlertError';
import { STRINGS } from '../../util/strings';
import logo from '../../assets/logoColor.svg';
import Loading from '../../components/Loading';
import './styles.css';

export default function Login()
{
	const { handleLogin } = useContext(Context);
	const [loading, setLoading] = useState<boolean>(true);
	const [errorLogin, setErrorLogin] = useState<string>('');

	const defaultForm = useForm({
		defaultValues: {username: '', password: ''},
		mode: 'onSubmit',
		resolver: yupResolver(yup.object().shape({
			username: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
			password: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		})),
	});

	const login = (data: any) => {
		
		setErrorLogin('');
		setLoading(true);

		handleLogin(data).then((res: any) => {

			if (res && res.status !== true && res.status !== 401)
				setErrorLogin(res?.message || 'Ocorreu um erro ao efetuar o login');
			
			setLoading(false);
		})
	}

	React.useEffect(() => {
		login({});
	}, [])

	return (
		<div className="card-container">
			<div className="card">
				<span className="logo">
					<img src={logo} alt="logo basf" />
				</span>
				<If condition={defaultForm.errors.username || defaultForm.errors.password}>
					<AlertError message={STRINGS.ALERT_WARNING_FIELDS} />
				</If>
				<If condition={errorLogin}>
					<AlertError message={errorLogin} />
				</If>
				<If condition={loading}>
					<Loading center={true} />
				</If>
				<If condition={!loading}>
					<a href="/saml/login?callbackUrl=/logincallback&returnUrl=/cadastro" className="btn btn-info text-center">Efetuar Login</a>
				</If>
			</div>
		</div>
	);
}