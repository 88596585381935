export const STRINGS_CIBIO = {
    NAV_AUTHORIZATION_CIBIO: "Autorizações CIBio",
    NAV_REGISTERS: "Cadastros",
    NAV_AUTHORIZATIONS: "Autorizações",
    NAV_UNITYS_BASF: "Unidades/Estações BASF",
    NAV_CULTURE: "Cultura",
    NAV_EVENT: "Evento",
    NAV_RECEIVER: "Destinatário",
    NAV_AUTHORIZATIONS_AMI: "AMI - Autorização para Movimentação Interna",
    NAV_AUTHORIZATIONS_AAC: "AAC - Autorização para Atividades em Contenção",
    NAV_AUTHORIZATIONS_AI: "AI - Autorização Importação",
    NAV_AUTHORIZATIONS_AE: "AE - Autorização Exportação",

    SEARCH_UNITIES_STATIONS: "Consulta de Unidades/Estações BASF",
    REGISTER_UNITIES_STATIONS: "Cadastro de Unidades/Estações BASF",
    EDITION_UNITIES_STATIONS: "Edição de Unidades/Estações BASF",
    SEARCH_CULTURE: "Consulta de culturas",
    REGISTER_CULTURE: "Cadastro de cultura",
    EDITION_CULTURE: "Edição de cultura",
    SEARCH_RECEIVER: "Consulta de destinatário",
    REGISTER_RECEIVER: "Cadastro de destinatário",
    EDITION_RECEIVER: "Edição de destinatário",
    SEARCH_EVENT: "Consulta de eventos",
    REGISTER_EVENT: "Cadastro de evento",
    EDITION_EVENT: "Edição de evento",

    SEARCH_AMI: "Consulta de AMI - autorização para movimentação interna",    
    REGISTER_AMI: "Cadastro de autorização para movimentação interna de ogm (ami) e seus derivados",
    EDITION_AMI: "Edição de autorização para movimentação interna de ogm (ami) e seus derivados",

    SEARCH_AAC: "Consulta de AAC - autorização para atividades em contenção",
    REGISTER_AAC: "Cadastro de autorização para atividades em contenção com ogm e seus derivados",
    EDITION_AAC: "Edição de autorização para atividades em contenção com ogm e seus derivados",

    SEARCH_AUTHORIZATIONS_AI: "Consulta de Autorizações Importação",
    REGISTER_AUTHORIZATIONS_AI: "Autorização importação de OGM (AI) e seus derivados para fins de contenção",
    REGISTER_AUTHORIZATIONS_AI_SUBTITLE: "Em acordo com o Parecer CONJUR/MCT n. 058/2010, compete às CIBios autorizar atividades e projetos que envolvam OGM da Classe de Risco I,  em regime de contenção,  englobando a importação e exportação de OGM e seus derivados",

    SEARCH_AUTHORIZATIONS_AE: "Consulta de Autorizações Exportação",
    REGISTER_AUTHORIZATIONS_AE: "Autorização exportação de OGM (AE) e seus derivados para fins de contenção",
    REGISTER_AUTHORIZATIONS_AE_SUBTITLE: "Em acordo com o Parecer CONJUR/MCT n. 058/2010, compete às CIBios autorizar atividades e projetos que envolvam OGM da Classe de Risco I,  em regime de contenção,  englobando a importação e exportação de OGM e seus derivados",

    EDITION_REGISTER_CONFIRMATION_CANCEL: "Todos os dados informados serão perdidos. Tem certeza que deseja cancelar?",
    EDITION_TITLE_CONFIRMATION: "Tem certeza?",
    CONFIRM_LEFT_EDITION: "Sim, voltar",
    CANCEL_LEFT_EDITION: "Não, permanecer",

    AMI_ORIGEM_HELP_TEXT: "(Ex.: Nº da LPMA/Atividade em Contenção etc.)",
    AMI_OBJETIVO_HELP_TEXT: "Objetivo da utilização:* (Ex.: Plantio de LPMA, Plantio em Casa de Vegetação, Análise, Armazenamento, Descarte etc.)",
    AMI_ESCOPO_LPMA_HELP_TEXT: "(Conferir escopo do CQB remetente/destinatário e escopo da LPMA ou da Permissão de Importação)",
    AMI_RADIO_BUTTON_NONE_SELECTED: "Escolha uma opção",
    AMI_EVENT_MODAL_TITLE: "Adicionar evento",
    AMI_EVENT_EDIT_MODAL_TITLE: "Editar evento",
    AMI_TRANSPORT_MODAL_TITLE: "Adicionar envio",
    AMI_RECEBIMENTO_MODAL_TITLE: "Adicionar recebimento",
    AMI_TRANSPORT_EDIT_MODAL_TITLE: "Editar envio",
    AMI_RECEBIMENTO_EDIT_MODAL_TITLE: "Editar recebimento",
    AMI_AAC_MODAL_TITLE: "Selecionar Atividade de Contenção",
    AMI_VALIDATION_NUMBER_FIELD: "O valor deve ser maior que zero",
    AMI_DEFAULT_NUMBER_ERROR: "O valor deste campo deve ser um número",
    AMI_NO_EVENTS_SELECTED: "O campo evento é obrigatório.",
    AMI_BLOCK_ONE: "1. Dados da Solicitação",
    AMI_BLOCK_TWO: "2. Dados da Aprovação",
    AMI_BLOCK_THREE: "3. Dados do Envio",
    AMI_BLOCK_FOUR: "4. Dados do Recebimento",
    AMI_FIELD_TO_SPECIFY: "Este campo deve ser preenchido",

    AMI_APROVE_TITLE: "Deseja aprovar a autorização?",
    AMI_APROVE_BODY_MSG: "Essa ação não poderá ser desfeita.",
    AMI_APROVE_SUCCESS: "Autorização aprovada com sucesso",
    AMI_CANCEL_TITLE: "Deseja cancelar a autorização?",
    AMI_CANCEL_BODY_MSG: "Essa ação não poderá ser desfeita. Insira o motivo do cancelamento abaixo:",
    AMI_CANCEL_SUCCESS: "Autorização cancelada com sucesso",
    AMI_CORRECTION_TITLE: "Deseja solicitar a correção da autorização?",
    AMI_CORRECTION_BODY_MSG: "Essa ação não poderá ser desfeita.",
    AMI_CORRECTION_CONFIRMATION_MSG: "Enviar",
    AMI_CORRECTION_SUCCESS: "Solicitação de correção realizada com sucesso",
    AMI_COMPLETE_TITLE: "Deseja concluir a autorização?",
    AMI_COMPLETE_BODY_MSG: "Essa ação não poderá ser desfeita.",
    AMI_COMPLETE_CONFIRMATION_MSG: "Confirmar",
    AMI_COMPLETE_SUCCESS: "Autorização concluída com sucesso",

    AAC_AVISO_DATA_TERMINO: "Aviso sobre data de Conclusão"
}