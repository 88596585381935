//Basic REACT Components
import React, { useState, useEffect, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

//Custom Componentes
import Button from "../../../components/Button";
import EditButton from '../../../components/EditButton';
import CancelButton from '../../../components/CancelRequisitionButton';
import ViewButton from '../../../components/ViewButton';
import PDFButton from '../../../components/GeneratePDF';
import api, { apiFileDownload } from '../../../services/api';
import If from "../../../components/If";
import SearchEmpty from '../../../components/SearchEmpty';
import Loading from '../../../components/Loading';
import ReasonModal from '../../../components/ReasonModal';
import AlertSuccess from '../../../components/AlertSuccess';

//Custom Services
import { DecodedToken } from '../../../models/user';
import jwtDecode from 'jwt-decode';
import { _generateDate } from '../../../util/convert';
import { isAdmin } from '../../../users/AuthorizationsCIBio/isAdmin';

//Page Specifics
import { STRINGS_CIBIO } from "../../../util/authorizationsCIBIO/string";
import { STATUS_CIBIO } from '../../../util/authorizationsCIBIO/status';
import { CultureResult, UnityStationResult, Enum, AutorizationImportExport } from '../../../models/modelsAuthorizationsCIBIO';
import { isRequesterReader, isRequesterWriter } from '../../../users/AuthorizationsCIBio/isRequester';
import { isReguladorWriter, isReguladorReader } from '../../../users/AuthorizationsCIBio/isRegulador';
import SimpleInput from '../../../components/ReactSimpleInput';

export default function List ()
{
	const baseUrl: string = '/api/v1/autorizacoes-cibio/Importacao';
	const reportUrl: string = '/api/v1/autorizacoes-cibio/Relatorios/Importacao';
	const routeUrl: string = 'ai';
	const titleText: string = STRINGS_CIBIO.SEARCH_AUTHORIZATIONS_AI;

	//Base da página
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const token = String(localStorage.getItem('token'));
	const { roles, unique_name }: DecodedToken = jwtDecode(token);

	//Interações da Página
	const [error, setError] = useState<any>(null);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
	const [isCancel, setIsCancel] = useState<boolean>(false);
	const [successCancel, setSuccessCancel] = useState<boolean>(false);
	const [cancelError, setCancelError] = useState<any>(null);
	const [reasonCancel, setReasonCancel] = useState('');
	
	//Campos do filtro
	const [amis, setAmis] = useState<AutorizationImportExport[]>([]);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [locais, setLocais] = useState<UnityStationResult[]>([]);
	const [cultures, setCultures] = useState<CultureResult[]>([]);
	const [status, setStatus] = useState<Enum[]>([]);
	const [loadingAuthorizationById, setLoadingAuthorizationById] = useState<boolean>(false);
	const [authorization, setAuthorization] = useState<AutorizationImportExport>();
	
	const { register, handleSubmit, watch, control } = useForm({
		defaultValues: {
			codigo: '',
			solicitante: (isRequesterReader(roles) || isRequesterWriter(roles)) ? unique_name : '',
			numeroAutorizacao: null,
			cultura: null,
			origem: null,
			destino: null,
			dataEmissaoInicial: null,
			dataEmissaoFinal: null,
			status: null,
		}
	})

	const [statuses] = useState<any>({
		1:'Em Preenchimento',
		2:'Em Análise Pelo Regulatório',
		3:'Correção Solicitada',
		4:'Em Correção',
		5:'Aprovado pelo Regulatório',
		6:'Concluído',
		7:'Cancelado'
	});

	useEffect(() => {
		
		const getData = async () => {
			
			try
			{
				const locais = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');
				const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');
				const status = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/status');

				setLocais(locais.data);
				setCultures(cultures.data);
				setStatus(status.data);
			}
			catch (error:any)
			{

			}
			
			setLoading(false);
		};

		const getAuthorizations = async () => {
		
			setError(false);
			
			try
			{
				let requester = (isRequesterReader(roles) || isRequesterWriter(roles)) ? unique_name : '';
				const { data, status } = await api.get<AutorizationImportExport[]>(`${baseUrl}/obterporfiltro?solicitante=${requester}`);
	
				if (status != 200)
					throw new Error('Erro ao buscar os dados da lista');
	
				if (data.length)
				{
					setAmis(data);
					setEmptySearch(false);
				}
				else
				{
					setEmptySearch(true);
				}
			}
			catch (error:any)
			{
				setError(true);
			}
		};

		getData();
		getAuthorizations();
	
	}, []);

	const search = async (queries?: any) => {

		setError(false);
		setLoadingSearch(true);

		const {
			//codigo,
			solicitante
		} = watch();

		try {

			let params = new URLSearchParams();

			params.append("solicitante", solicitante);
			params.append("origem", queries?.origem ? queries?.origem.value : '');
			params.append("destino", queries?.destino ? queries?.destino.value : '');
			params.append("cultura", queries?.cultura ? queries?.cultura.value : '');
			params.append("numeroAutorizacao", queries?.numeroAutorizacao ? queries?.numeroAutorizacao : '');
			params.append("dataEmissaoInicial", queries?.dataEmissaoInicial ? _generateDate(queries?.dataEmissaoInicial, 'En') : "");
			params.append("dataEmissaoFinal", queries?.dataEmissaoFinal ? _generateDate(queries?.dataEmissaoFinal, 'En') : "");
			params.append("status", queries?.status ? queries?.status.value : '');

			
			const { data } = await api.get<AutorizationImportExport[]>(`${baseUrl}/obterporfiltro?${params.toString()}`);

			if (data.length)
			{
				setAmis(data);
				setEmptySearch(false);
			}
			else
			{
				setEmptySearch(true);
			}
		}
		catch (error:any)
		{
			setError(true);
		}

		setLoadingSearch(false);
	}

	const cancelRequisition = async (data: any) => {
		setLoadingCancel(true);
		setCancelError(null);

		try {

			const { status } = await api.post(`${baseUrl}/cancelar`, {
				id: data.id,
				motivoCancelamento: reasonCancel
			});

			if (status === 200)
			{
				setSuccessCancel(true);
				setTimeout(() => {
					setIsCancel(false);
					search();
				}, 3000);
			}
			
		} catch (error:any) {
			setCancelError(error.response.data);
		}
		setLoadingCancel(false);
	}

	const getAuthorizationById = async (id: number) => {
		
		setLoadingAuthorizationById(true);
		
		try
		{
			const { data } = await api.get(`${baseUrl}/${id}`);
			setAuthorization(data);
		}
		catch (error:any)
		{
			throw new Error(error);
		}

		setLoadingAuthorizationById(false);
	}

	const getPdf = async (id: number) => {
		
		try
		{
			const { data } = await apiFileDownload.get(`${reportUrl}?id=${id}`);

			let blob = new Blob([data], {
				type: 'application/pdf'
			});

			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			
			if (typeof a.download === "undefined")
			{
				window.location.href = url;
			}
			else
			{
				a.href = url;
				a.download = `${routeUrl}_${id}`;
				document.body.appendChild(a);
				a.click();
			}
		}
		catch (error:any)
		{
			console.error(error);
		}
	}

	const can_edit = (info : string) : boolean =>
	{
		const possuiPerfilAdmin = isAdmin(roles);
		const possuiPerfilRegulador = isReguladorReader(roles) || isReguladorWriter(roles);

		switch (info)
		{
			case 'filtro_solicitante':

				return possuiPerfilRegulador || possuiPerfilAdmin;
		}

		return true;
	}

	const acoes = ({ data }: any) => {

		const possuiPerfilAdmin = isAdmin(roles);
		const possuiPerfilRegulador = isReguladorReader(roles) || isReguladorWriter(roles);
		const ehRequisitanteDaRequisicao = data.solicitante === unique_name;

		const canEditStatusPerfilRequisitante = [
			STATUS_CIBIO.EmPreenchimento
			, STATUS_CIBIO.CorrecaoSolicitada
			, STATUS_CIBIO.EmCorrecao
			, STATUS_CIBIO.AprovadopeloRegulatorio
		];

		const canEditStatusPerfilRegulador = [
			STATUS_CIBIO.EmAnalisePeloRegulatorio
			, STATUS_CIBIO.Concluido
		];

		let canEdit = false;

		if (possuiPerfilAdmin) {
			canEdit = true;
		} else if (possuiPerfilRegulador && canEditStatusPerfilRegulador.includes(data.status)) {
			canEdit = true;
		} else if (ehRequisitanteDaRequisicao && canEditStatusPerfilRequisitante.includes(data.status)) {
			canEdit = true;
		}

		let canCancel = canEdit && data.status !== STATUS_CIBIO.Cancelado;

		if (!possuiPerfilAdmin && !possuiPerfilRegulador)
		{
			const canCancelPerfilRequisitante = [
				STATUS_CIBIO.EmPreenchimento,
				STATUS_CIBIO.CorrecaoSolicitada,
				STATUS_CIBIO.EmAnalisePeloRegulatorio,
			];

			canCancel = canCancel && canCancelPerfilRequisitante.includes(data.status);
		}

		return (
		<Fragment>
			<EditButton disable={!canEdit} onClick={() => {
				if (canEdit) {
					history.push({
						pathname: `/autorizacoes/editar/${routeUrl}`,
						state: {
							id: data.id
						}
					})
				}
			}} />
			&nbsp;&nbsp;
			<CancelButton disabled={!canCancel} onClick={() => {
				
				if (!canCancel) return;
				
				setIsCancel(true);
				getAuthorizationById(data.id);
				setSuccessCancel(false);
				setReasonCancel('');

			}} />
			&nbsp;&nbsp;

			<ViewButton onClick={() => history.push({
				pathname: `/autorizacoes/visualizar/${routeUrl}`,
				state: {
					id: data.id,
					readonly: true,
				}
			})} />
			&nbsp;&nbsp;

			<PDFButton onClick={() => getPdf(data.id)} />
		</Fragment>);
	}

	return (
		<div className="container-custom-search">
			<div className="top-content">
				<h3>{titleText}</h3>
			</div>
			<div className="row-buttons">
				<If condition={isAdmin(roles) || isRequesterReader(roles) || isRequesterWriter(roles)}>
					<Button
						onClick={() => history.push(`/autorizacoes/cadastrar/${routeUrl}`)}
						contained
						title="Adicionar Autorização"
						type="button"
					/>
				</If>
			</div>
			<br />
			<Form onSubmit={handleSubmit(search)} autoComplete="off">
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>Solicitante</Form.Label>
						<Controller
							control={control}
							name="solicitante"
							disabled={isRequesterReader(roles) || isRequesterWriter(roles)}
							maxLength={150}
							render={({ onChange, value }) => (
								<SimpleInput
									defaultValue={value}
									clearButton={can_edit('filtro_solicitante')}
									disabled={!can_edit('filtro_solicitante')}
									className="form-control"
									onChange={onChange}
								/>
							)}
						/>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Origem
						</Form.Label>
						<Controller
							control={control}
							name="origem"
							placeholder=""
							isClearable
							as={<Select options={locais.map(local => Object.assign({ value: local.id, label: local.nome }))} />}
						/>
					</Form.Group>
					<Form.Group as={Col} md={4}>
						<Form.Label>
							Destino
						</Form.Label>
						<Controller
							control={control}
							name="destino"
							placeholder=""
							isClearable
							as={<Select options={locais.map(local => Object.assign({ value: local.id, label: local.nome }))} />}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={2}>
						<Form.Label>
							Cultura/Organismo
						</Form.Label>
						<Controller
							control={control}
							name="cultura"
							isSearchable={false}
							isClearable
							placeholder=""
							as={<Select options={cultures.map(culture => Object.assign({ value: culture.id, label: culture.nome }))} />}
						/>
					</Form.Group>
					<Form.Group as={Col} md={2}>
						<Form.Label>Nº Autorização CIBio</Form.Label>
						<Controller
							control={control}
							name="numeroAutorizacao"
							render={({ onChange }) => (
								<SimpleInput 
									clearButton 
									className="form-control" 
									onChange={onChange}
								/>
							)}
						/>
					</Form.Group>
					<Form.Group as={Col} md={2}>
						<Form.Label>
							Data inicial
						</Form.Label>
						<br />
						<Controller
							control={control}
							name="dataEmissaoInicial"
							render={({ onChange, value }) => (
								<DatePicker
									selected={value}
									onChange={onChange}
									className="input-custom-to-date"
									locale="pt-BR"
									dateFormat="dd/MM/yyyy"
									placeholderText="Selecionar a data"
									maxDate={watch().dataEmissaoFinal}
									isClearable
								/>
							)}
						/>
					</Form.Group>
					<Form.Group as={Col} md={2}>
						<Form.Label>
							Data Final
						</Form.Label>
						<br />
						<Controller
							control={control}
							name="dataEmissaoFinal"
							render={({ onChange, value }) => (
								<DatePicker
									selected={value}
									onChange={onChange}
									className="input-custom-to-date"
									locale="pt-BR"
									dateFormat="dd/MM/yyyy"
									placeholderText="Selecionar a data"
									minDate={watch().dataEmissaoInicial}
									isClearable
								/>
							)}
						/>
					</Form.Group>
					<Form.Group as={Col} md={2}>
						<Form.Label>
							Status
						</Form.Label>
						<Controller
							control={control}
							name="status"
							isSearchable={false}
							isClearable
							placeholder=""
							as={<Select options={status.map(stat => Object.assign({ value: stat.key, label: stat.value }))} />}
						/>
					</Form.Group>
					<span className="filter-btn">
						<Button
							title="Buscar"
							type="submit"
							contained
							isLoading={loadingSearch}
						/>
					</span>
				</Form.Row>
			</Form>
			<If condition={emptySearch}>
				<div className="div-empty">
					<SearchEmpty text={'Nenhuma importação disponível.'} />
				</div>
			</If>
			<If condition={loading}>
				<div className="loading-container">
					<Loading />
				</div>
			</If>
			<If condition={isCancel}>
				<ReasonModal
					show={isCancel}
					onHide={() => setIsCancel(false)}
					title={STRINGS_CIBIO.AMI_CANCEL_TITLE}
					actionMsg="Confirmar"
					onConfirm={() => {
						cancelRequisition(authorization);
					}}
					loading={loadingCancel}
					disabled={false}
					hideFooter={successCancel}
				>
					<If condition={loadingAuthorizationById}>
						<div className="loading-container">
							<Loading />
						</div>
					</If>
					<If condition={successCancel}>
						<AlertSuccess
							message={STRINGS_CIBIO.AMI_CANCEL_SUCCESS}
						/>
					</If>
					<If condition={!loadingAuthorizationById}>
						<Form.Label>
							<strong>{STRINGS_CIBIO.AMI_APROVE_BODY_MSG}</strong>
						</Form.Label>
						<Form.Control
							onChange={({ target }: any) =>
								setReasonCancel(target.value)
							}
							value={reasonCancel}
							as="textarea"
							maxLength={200}
						/>
					</If>
				</ReasonModal>
			</If>

			<If condition={!loading && !error && !emptySearch && amis.length}>
				<div className="search-content">
					<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
						<AgGridReact
							rowData={amis}
							defaultColDef={{ flex: 1, sortable: true, resizable: true }}
							pagination
							paginationPageSize={10}
							gridOptions={{ headerHeight: 80 }}
							frameworkComponents={{
								actions: acoes,
							}}
						>
							<AgGridColumn
								headerName='Código'
								field='id'
								maxWidth={130}
								sort="asc"
							/>
							<AgGridColumn
								headerName='Solicitante'
								headerClass="myfunc-default-header"
								field='solicitante'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Origem'
								headerClass="myfunc-default-header"
								field='origem.nome'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Destino'
								headerClass="myfunc-default-header"
								field='destino.nome'
								cellStyle={{
									justifyContent: 'left'
								}}
							/>
							<AgGridColumn
								headerName='Data Criação'
								headerClass="myfunc-default-header"
								field='dataCriacao'
								valueFormatter={({ data }: any) => data.dataCriacao ? _generateDate(data.dataCriacao, 'Pt') : ''}
							/>
							<AgGridColumn
								headerName='Nº Autorização CIBio'
								headerClass="myfunc-default-header"
								field='numeroAutorizacao'
							/>
							<AgGridColumn
								headerName='Emissão CIBio'
								headerClass="myfunc-default-header"
								field='dataAprovacao'
								valueFormatter={({ data }: any) => data.dataCriacao ? _generateDate(data.dataCriacao, 'Pt') : ''}
							/>
							<AgGridColumn
								headerName='Status'
								field='status'
								valueFormatter={({ data }: any) => statuses[data.status] }
							/>
							<AgGridColumn
								field='Ações'
								cellRenderer='actions'
							/>
						</AgGridReact>
					</div>
				</div>
			</If>
		</div>
	);
}