import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../../components/Button';
import EditButton from '../../../components/EditButton';
import ViewButton from '../../../components/ViewButton';
import GeneratePDF from '../../../components/GeneratePDF';
import ZipButton from '../../../components/GenerateZip';
import Loading from '../../../components/Loading';
import CancelButton from '../../../components/CancelRequisitionButton';
import If from '../../../components/If';
import SearchEmpty from '../../../components/SearchEmpty';
import ReasonModal from '../../../components/ReasonModal';
import { STRINGS } from '../../../util/strings';
import { RegisterRequestResults, StatusResults, CultureResults, RecordTypesResults } from '../../../models/models';
import ptBR from 'date-fns/locale/pt-BR';
import api, { apiFileDownload } from '../../../services/api';
import { _generateDate } from '../../../util/convert';
import { isAdmin } from '../../../users/isAdmin';
import { isRequesterWriteRegister, isOnlyRequesterRegister, isDescritor, isAvaliadorDoenca } from '../../../users/isRequester';
import { DecodedToken } from '../../../models/user';
import jwtDecode from 'jwt-decode';
import { isReguladorRegister } from '../../../users/isRegulador';
import { STATUS_REGISTER } from '../../../util/status';
import { isDescriptors } from '../../../users/isDescriptors';
import { isDiseases } from '../../../users/isDiseases';
import '../styles_list.css';

registerLocale('pt-BR', ptBR);

export default function RegisterRNC ()
{
	const history = useHistory();
	const token = String(localStorage.getItem('token'));
	const { roles, unique_name }: DecodedToken = jwtDecode(token);
	const [registerRequests, setRegisterRequests] = useState<RegisterRequestResults[]>([]);
	const [loadingRequests, setLoadingRequests] = useState<boolean>(true);
	const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
	const [emptySearch, setEmptySearch] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [status, setStatus] = useState<StatusResults[]>([]);
	const [cultures, setCultures] = useState<CultureResults[]>([]);
	const [recordsType, setRecordsType] = useState<RecordTypesResults[]>([]);
	const [tempId, setTempId] = useState<number>(0);
	const [showReasonModal, setShowReasonModal] = useState<boolean>(false);
	const [reasonTxt, setReasonTxt] = useState<string>('');
	const [loadingCancelling, setLoadingCancelling] = useState<boolean>(false);

	const { register, handleSubmit, control, setValue } = useForm({
		defaultValues: {
			code: '',
			requester: isOnlyRequesterRegister(roles) ? unique_name : '',
			initialDate: null,
			finalDate: null,
			status: '',
			culture: '',
		}
	});

	const getRequestsRegister = async () => {
		setError(false);
		try {
			let filtro = '';
			if (isOnlyRequesterRegister(roles))
				filtro = 'Requisitante=' + unique_name;

			//if (isDescritor(roles))
			//	filtro = 'UsuarioValidacaoDescritores=' + unique_name;

			//if (isAvaliadorDoenca(roles))
			//	filtro = 'UsuarioValidacaoDoencas=' + unique_name;

			const { data } = await api.get<RegisterRequestResults[]>(`api/v1/cultivares/CultivaresRegistro/obterporfiltro?${filtro}`);

			setRegisterRequests(data);
		} catch (error:any) {
			setError(true);
		}
	}

	useEffect(() => {
		const getRequests = async () => {
			try {
				const responseStatus = await api.get<StatusResults[]>('/api/v1/cultivares/CultivaresRegistro/obterlistastatus');
				const responseCulture = await api.get<CultureResults[]>('api/v1/cultivares/Cultura/obterporfiltro?Ativo=true');
				const responseRecordsType = await api.get<RecordTypesResults[]>('api/v1/cultivares/TipoRegistro/obterporfiltro?Ativo=true');
				
				setStatus(responseStatus.data);
				setCultures(responseCulture.data);
				setRecordsType(responseRecordsType.data);
			} catch (error:any) {
				setError(true);
			}
			setLoadingRequests(false);
		}
		getRequests();
		getRequestsRegister();
	}, [])

	const search = async (queries?: any) => {
		setError(false);
		setLoadingSearch(true);

		let requesterUsername = queries?.requester;
		if (isOnlyRequesterRegister(roles)) {
			requesterUsername = unique_name;
		}

		try {
			const { data } = await api.get<RegisterRequestResults[]>(`api/v1/cultivares/CultivaresRegistro/obterporfiltro?Requisitante=${requesterUsername}&Codigo=${queries.code}&Status=${queries?.status}&DataInicio=${queries?.initialDate ? _generateDate(new Date(queries?.initialDate), 'En') : ''}&DataFim=${queries?.finalDate ? _generateDate(new Date(queries?.finalDate), 'En') : ''}&CulturaId=${queries?.culture}&DenominacaoCultivarLinhagem=${queries?.nameOfCultivarLineage}&TipoRegistroId=${queries?.registerType}`);

			if (data.length) {
				setRegisterRequests(data);
				setEmptySearch(false);
			} else {
				setEmptySearch(true);
			}

		} catch (error:any) {
			setError(true);
		}
		setLoadingSearch(false);
	}

	const cancelRequisition = async (id: number) => {
		setLoadingCancelling(true);
		try {
			const { status } = await api.post(`api/v1/cultivares/CultivaresRegistro/${id}/cancelar`, {
				motivo: reasonTxt
			});
			if (status === 200) {
				setShowReasonModal(false);
				getRequestsRegister();
				setReasonTxt('');
			}
		} catch (error:any) {
			console.error(error);
		}
		setLoadingCancelling(false);
	}

	const generatePDF = async (id: number) =>
	{
		try
		{
			// const { data } = await apiFileDownload.get(`api/v1/cultivares/Relatorios/requisicaoregistro?id=${id}`);

			// let blob = new Blob([data], {
			// 	type: 'application/pdf'
			// });
			// var url = window.URL.createObjectURL(blob)

			// let a = document.createElement("a");
			// if (typeof a.download === "undefined") {
			// 	window.location.href = url;
			// } else {
			// 	a.href = url;
			// 	a.download = `requisição-registro-${id}`;
			// 	document.body.appendChild(a);
			// 	a.click();
			// }

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = `api/v1/cultivares/Relatorios/requisicaoregistro?id=${id}`;
			}
			else
			{
				a.href = `api/v1/cultivares/Relatorios/requisicaoregistro?id=${id}`;
				a.target = '_blank';
				a.download = `requisição-registro-${id}`;
				document.body.appendChild(a);
				a.click();
			}
		}
		catch (error:any)
		{
			console.error(error);
		}
	}

	const getZip = async (id: number) => {
		try {
			const { data } = await apiFileDownload.get(`api/v1/cultivares/CultivaresRegistro/downloadzip/${id}`);

			let blob = new Blob([data], {
				type: 'application/zip'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = `registro-documentos-${id}`;
				document.body.appendChild(a);
				a.click();
			}
		} catch (error:any) {
			console.error(error);
		}
	}

	const actions = ({ data }: any) => {

		let canEdit : boolean = 
			(
				isRequesterWriteRegister(roles)
				&& (
					data.status === STATUS_REGISTER.EmPreenchimento
					||
					data.status === STATUS_REGISTER.ReprovadoPeloRegulatorio
					||
					data.status === STATUS_REGISTER.EmCorrecao
				)
			)
			||
			(
				isReguladorRegister(roles)
				&& (
					data.status === STATUS_REGISTER.EmAnalisePeloRegulatorio
					|| data.status === STATUS_REGISTER.ValidadoPeloRegulatorio
					|| data.status === STATUS_REGISTER.ValidadoPelaSGFITO
					|| data.status === STATUS_REGISTER.ValidadoPelaSGFITOComAlteracoes
					|| data.status === STATUS_REGISTER.AguardandoAprovacaoMAPA
					|| data.status === STATUS_REGISTER.AprovadoPeloMAPA
					|| data.status === STATUS_REGISTER.ReprovadoPeloMAPA
				)
			)
			||
			(
				(isDiseases(roles) || isDescriptors(roles))
				&& data.status === STATUS_REGISTER.EmValidacaoPelaSGFITO
			)
			||
			isAdmin(roles);

		let canCancel : boolean = (isRequesterWriteRegister(roles) && (data.status === STATUS_REGISTER.EmPreenchimento || data.status === STATUS_REGISTER.ReprovadoPeloRegulatorio || data.status === STATUS_REGISTER.EmCorrecao)) || (isReguladorRegister(roles) && (data.status === STATUS_REGISTER.EmAnalisePeloRegulatorio || data.status === STATUS_REGISTER.ValidadoPeloRegulatorio)) || isAdmin(roles);

		let buttonEdit = <EditButton disable={!canEdit} onClick={() => {
			if (!canEdit)
				return;

			history.push({
				pathname: '/cultivares/registro-rnc/editar',
				state: {
					id: data.id
				}
			});
		}} />;

		let buttonCancel = <CancelButton disabled={!canCancel} onClick={() => {
			if (!canCancel)
				return;

			setShowReasonModal(true);
			setTempId(data.id);
		}} />;

		let buttonView = <ViewButton onClick={() => history.push({
			pathname: '/cultivares/registro-rnc/visualizar',
			state: {id: data.id}
		})} />;

		// let buttonPdf = <GeneratePDF onClick={() => generatePDF(data.id)} />;
		let buttonPdf = <a href={`/api/v1/cultivares/Relatorios/requisicaoregistro?id=${data.id}`} target="_blank"><GeneratePDF onClick={() => {}} /></a>;

		let buttonZip = <ZipButton Text="Gerar Zip" onClick={() => getZip(data.id)} />;

		return <Fragment>
			{buttonEdit}
			&nbsp;&nbsp;
			{buttonCancel}
			&nbsp;&nbsp;
			{buttonView}
			&nbsp;&nbsp;
			{buttonPdf}
			&nbsp;&nbsp;
			{buttonZip}
			&nbsp;&nbsp;
		</Fragment>;
	}

	return <div className="container-custom-search">
		<div className="top-content">
			<h3>Consulta de Registros RNC</h3>
		</div>
		<If condition={isAdmin(roles) || isRequesterWriteRegister(roles)}>
			<div className="row-buttons">
				<Button
					onClick={() => history.push('/cultivares/registro-rnc/cadastrar')}
					contained
					title="Adicionar Requisição"
					type="button"
				/>
			</div>
		</If>
		<br />
		<Form onSubmit={handleSubmit(search)}>
			<Form.Row>
				<Form.Group as={Col} md={3}>
					<Form.Label>Código</Form.Label>
					<Form.Control
						ref={register}
						name="code"
						maxLength={100}
						autoComplete="off"
					/>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>Denominação da Cultivar/Linhagem</Form.Label>
					<Form.Control
						ref={register}
						name="nameOfCultivarLineage"
						maxLength={100}
						autoComplete="off"
					/>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>Cultura</Form.Label>
					<Form.Control
						as="select"
						ref={register}
						name="culture"
					>
						<Form.Control
							as="option"
							label="Escolha uma cultura"
						/>
						{cultures.map(culture =>
							<Form.Control
								key={culture.id}
								as="option"
								label={culture.nome}
								value={culture.id}
							/>
						)}
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>Tipo de Registro</Form.Label>
					<Form.Control
						ref={register}
						as="select"
						name="registerType"
					>
						<Form.Control
							as="option"
							label="Escolha um tipo de registro"
						/>
						{recordsType.map(item =>
							<Form.Control
								as="option"
								key={item.id}
								label={item.nome}
								value={item.id}
							/>
						)}
					</Form.Control>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>Requisitante</Form.Label>
					<Form.Control
						ref={register}
						name="requester"
						maxLength={100}
						autoComplete="off"
						disabled={isOnlyRequesterRegister(roles)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Data inicial
					</Form.Label>
					<br />
					<Controller
						control={control}
						name="initialDate"
						render={({ onChange, value }) => (
							<DatePicker
								selected={value}
								onChange={onChange}
								className="input-custom-to-date"
								locale="pt-BR"
								dateFormat="dd/MM/yyyy"
								placeholderText="Selecionar a data"
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={2}>
					<Form.Label>
						Data final
					</Form.Label>
					<br />
					<Controller
						control={control}
						name="finalDate"
						render={({ onChange, value }) => (
							<DatePicker
								selected={value}
								onChange={onChange}
								className="input-custom-to-date"
								locale="pt-BR"
								dateFormat="dd/MM/yyyy"
								placeholderText="Selecionar a data"
							/>
						)}
					/>
				</Form.Group>
				<Form.Group as={Col} md={3}>
					<Form.Label>Status</Form.Label>
					<Form.Control
						ref={register}
						as="select"
						name="status"
					>
						<Form.Control
							as="option"
							label="Escolha um status"
						/>
						{status.map(item =>
							<Form.Control
								as="option"
								label={item.nome}
								value={item.id}
							/>
						)}
					</Form.Control>
				</Form.Group>
				<span className="filter-btn">
					<Button
						title="Buscar"
						type="submit"
						contained
						isLoading={loadingSearch}
					/>
				</span>
			</Form.Row>
		</Form>
		<If condition={emptySearch}>
			<div className="div-empty">
				<SearchEmpty />
			</div>
		</If>
		<If condition={!loadingRequests && !error && !emptySearch && registerRequests.length}>
			<div className="search-content">
				<div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
					<AgGridReact
						rowData={registerRequests}
						defaultColDef={{ flex: 1, sortable: true, resizable: true, sort: "" }}
						pagination
						paginationPageSize={10}
						frameworkComponents={{
							actions: actions,
						}}
					>
						<AgGridColumn
							headerName='Código'
							field='codigo'
							maxWidth={130}
						/>
						<AgGridColumn
							headerName='Denominação da Cultivar/Linhagem'
							field='denominacaoDaCultivarLinhagem'
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName='Cultura'
							field='nomeComumDaEspecie'
						/>
						<AgGridColumn
							headerName='Tipo de Registro'
							field='tipoRegistroNome'
						/>
						<AgGridColumn
							headerName='Requisitante'
							field='usuario'
							cellStyle={{
								justifyContent: 'left'
							}}
						/>
						<AgGridColumn
							headerName='Data de criação'
							field='dataCriacao'
							valueFormatter={({ data }: any) => _generateDate(data.dataCriacao, 'Pt')}
						/>
						<AgGridColumn
							headerName='Status'
							field='statusDescricao'
						/>
						<AgGridColumn
							field='Ações'
							cellRenderer='actions'
						/>
					</AgGridReact>
				</div>
			</div>
		</If>
		<If condition={loadingRequests}>
			<div className="loading-container">
				<Loading />
			</div>
		</If>
		<If condition={showReasonModal}>
			<ReasonModal
				show={showReasonModal}
				onHide={() => setShowReasonModal(false)}
				title={STRINGS.REASON_MODAL_TITLE}
				actionMsg={`${STRINGS.REASON_MODAL_ACTION}`}
				disabled={reasonTxt.length ? false : true}
				onConfirm={() => {
					setShowReasonModal(false);
					cancelRequisition(tempId)
				}}
				loading={loadingCancelling}
			>
				<Form.Label>
					<strong>{STRINGS.REASON_MODAL_MSG}</strong>
				</Form.Label>
				<Form.Control
					onChange={({ target }: any) =>
						setReasonTxt(target.value)
					}
					value={reasonTxt}
					as="textarea"
					maxLength={200}
				/>
			</ReasonModal>
		</If>
	</div>;
}