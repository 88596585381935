import TextField from "../../../components/form/TextField";
import CheckBox from "../../../components/form/CheckBox";
import EditView from  "../../../components/page/EditView";
import { LpmaProjeto, LpmaProjeto_Aprovados } 
from "../../../models/modelsLPMA";
import { useForm } from "react-hook-form";
import LpmaService from "../services";
import { STRINGS_LPMA } from "../../../util/lpma/string";
import RichText from "../../../components/form/RichText";
import Grid from  "../../../components/form/Grid"
import DateField from "../../../components/form/DateField";
import moment from "moment";
import { uuid } from "../../../services/uuid";
import React from "react";

export default function Edit (){
	const LpmaServiceApi = new LpmaService();
	const [aprovadosInit, setAprovadosInit] = React.useState<LpmaProjeto_Aprovados[]>();
	const [item,setItem] = React.useState<any>({});
	
	const defaultForm = useForm({
		defaultValues: {} as LpmaProjeto,
		mode: "onSubmit",
	});

	return <EditView<LpmaProjeto> 
		title={STRINGS_LPMA.EDITION_PROJETOS}
		getLoadById={async (id: any)=>{ 
			var dados = await LpmaServiceApi.getProjetosById(id);			
			var resultD = {
				...dados,
				aprovados: dados.aprovados?.map(e=>{
					return {
						...e, uid: uuid(), 
						dataDecisao: e.dataDecisao ? moment(e.dataDecisao).toDate() : null 
					}
				})
			}			
			setAprovadosInit(resultD.aprovados);
			return resultD;
		 }}
		 OnInit={()=>{
			setItem({})
		 }}
		 form={defaultForm}
		 getEntity={(entity:any)=>{
			//console.log(entity);
			setItem(entity);
		 }}
		 MsgSaveSubmitSucess="Projeto Salvo com Sucesso"
		 OnSaveSubmit={async (values:any, entity: any)=>{		
			 try{
				// const getValues = Object.assign(defaultForm.getValues())
				//  console.log(getValues, values, entity);
				const saveValues = {
					...values,
				   aprovados: values.aprovados?.map((e:any)=>{
					   return {...e, dataDecisao: e.dataDecisao ? (new Date(e.dataDecisao)).toUTCString(): undefined }})
				}	 			 
				await LpmaServiceApi.getProjetosSave(saveValues); 
				return {isOk: true};
			} catch (error:any) {
				return {isOk: false};
			}					 
		 }}
		 OnSaveSubmitIsOkGoBack={true}	 
		>
		<TextField<LpmaProjeto> form={defaultForm} required name="nome" label="Nome" layoutColumns={4}  />
		<TextField<LpmaProjeto> form={defaultForm} required name="cultura" label="Cultura" layoutColumns={4} />
		<TextField<LpmaProjeto> form={defaultForm} required name="nomeCientifico" label="Nome Científico" layoutColumns={4} />

		<CheckBox form={defaultForm} name="ativo" label="Ativo" {...defaultForm.register(`ativo`)} defaultChecked={ item.ativo } />
				
		<RichText<LpmaProjeto> form={defaultForm} required name="ogm" {...defaultForm.register(`ogm`)} defaultValue={item.ogm}
			label="OGM Planejado para RN35" layoutColumns={12}  rows={8} />

		<RichText<LpmaProjeto> form={defaultForm} required name="medidasBiossegurancaRN35" {...defaultForm.register(`medidasBiossegurancaRN35`)} defaultValue={item.medidasBiossegurancaRN35}
			label="Medidas de Biosseguranca para o RN35" layoutColumns={12}  rows={8} />
				
		<RichText<LpmaProjeto> form={defaultForm} required name="medidasBiossegurancaRN06" {...defaultForm.register(`medidasBiossegurancaRN06`)}   defaultValue={item.medidasBiossegurancaRN06}
			label="Medidas de Biosseguranca para o RN06" layoutColumns={12}  rows={8} />

		<RichText<LpmaProjeto> form={defaultForm} required name="infoContencaoFluxoGenico" {...defaultForm.register(`infoContencaoFluxoGenico`)} defaultValue={item.infoContencaoFluxoGenico}
			label="Informar a existência de espécies sexualmente compatíveis no local de liberação e as medidas adotadas para contenção do fluxo gênico." layoutColumns={12} />

		<RichText<LpmaProjeto> form={defaultForm} required name="infoMedidadasRemocaoOGM" {...defaultForm.register(`infoMedidadasRemocaoOGM`)} defaultValue={item.infoMedidadasRemocaoOGM}
			label="Informar as medidas a serem tomadas para remoção do OGM de origem vegetal, caso ocorra algum perigo evidente no decorrer do experimento de liberação planejada" layoutColumns={12} />

		<RichText<LpmaProjeto> form={defaultForm} required name="infoTermoCientifico" {...defaultForm.register(`infoTermoCientifico`)} defaultValue={item.infoTermoCientifico}
			label="Informar sobre a espécie do vegetal a ser liberada (incluir, quando apropriado, nome científico, subespécie, cultivar, etc.)" layoutColumns={12} />

		<RichText<LpmaProjeto> form={defaultForm} required name="infoLiberacaoPlanejada" {...defaultForm.register(`infoLiberacaoPlanejada`)} defaultValue={item.infoLiberacaoPlanejada}
			label="Informar se existe a possibilidade de a liberação planejada afetar as características ou abundância de outras espécies, e como isto será monitorado" layoutColumns={12} />

		<RichText<LpmaProjeto> form={defaultForm} required name="infoMonitoramentoOGM" {...defaultForm.register(`infoMonitoramentoOGM`)} defaultValue={item.infoMonitoramentoOGM}
			label="Descrever como será monitorada a sobrevivência do OGM de origem vegetal no local de liberação planejada" layoutColumns={12} />

		<Grid<LpmaProjeto, LpmaProjeto_Aprovados>
			form={defaultForm}
			style={{ height: '210px', width: '100%' }}
			name="aprovados" 
			label="Nº dos Processos Aprovados pela CTNBio" 
			layoutColumns={12}
			defaultValue={aprovadosInit}
			{...defaultForm.register("aprovados")}
			collumns={[
				{header: 'Nº processo Anteriores', field: 'numProcessoAnterior', 
				showOnModal: true, custumControls : <TextField layoutColumns={4} />  },
				
				{header: 'Data da Publicação no DOU', 	field: 'dataDecisao', 
								gridValueFormatter: (event:any)=>{ return  moment(event.data?.dataDecisao).format('DD/MM/YYYY'); },
								showOnModal: true, custumControls : <DateField layoutColumns={6} />   },

				{header: 'Extrato Parecer', field: 'extratoParececer', 
				showOnModal: true, custumControls : <TextField layoutColumns={4} />  },				
				

			]}
		></Grid>
	</EditView>
}