import React from 'react';
import { FaFilePdf, FaTruckLoading, FaFileContract } from 'react-icons/fa';
import LoadingRequest from './LoadingRequest';
import Tooltip from './Tooltip';

interface Props {
    onClick     : any;
    disabled    ?: any;
    isLoading   ?: boolean;
    tooltipTxt  ?: string;
}

function GeneratePDF({ onClick, disabled, isLoading, tooltipTxt }: Props) {
    return (
        <Tooltip tooltipTxt={tooltipTxt ?? "Gerar PDF"}>
            {isLoading ? <FaTruckLoading size={20}  className="generate-btn"  style={{color:  'red'}}/> :
            <FaFilePdf style={{color: disabled ? '#c9cacc' : 'red'}} className="generate-btn" size={20} onClick={disabled? null: onClick} />
            }
        </Tooltip>
    );
}

export function GenerateAnexo({ onClick, disabled, isLoading, tooltipTxt }: Props) {
    return (
        <Tooltip tooltipTxt={tooltipTxt ?? "Gerar PDF"}>
            {isLoading ? <FaTruckLoading size={20}  className="generate-btn"  style={{color:  'red'}}/> :
            <FaFileContract style={{color: disabled ? '#c9cacc' : '#453f3f'}} className="generate-btn" size={20} onClick={disabled? null: onClick} />
            }
        </Tooltip>
    );
}



export default GeneratePDF;
