import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Col } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import Button from '../../../components/Button';
import Fieldset from '../../../components/Fieldset';
import Modal from '../../../components/Modal';
import If from '../../../components/If';
import AlertError from '../../../components/AlertError';
import AlertSuccess from '../../../components/AlertSuccess';
import DeleteButton from '../../../components/DeleteButton';
import AddButton from '../../../components/AddButton';
import { STRINGS } from '../../../util/strings';
import { ApplicantResults, BreederResults, CultureResults, EventsResult, ModelFilesProtection, ModelsFiles, ProtectionSNPCForm, RequestProtectionApplicants, RequestProtectionMelhoristas, TypeAttachment } from '../../../models/models';
import { protectionSNPC } from '../../../tempdb.json';
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect } from 'react';
import api, { apiFileDownload } from '../../../services/api';
import LoadingInForm from '../../../components/LoadingInForm';
import NotAvailableModel from '../../../components/NotAvailableModel';
import GenericButton from '../../../components/FlowGenericButton';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../../../models/user';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { isRequesterWriteProtection } from '../../../users/isRequester';
import { isReguladorProtection } from '../../../users/isRegulador';
import { isAdmin } from '../../../users/isAdmin';
import { isEmptyObj } from '../../../functions/isEmptyObject';
import { Context } from '../../../context/AuthContext'

import AttachmentGrid, {attachmentFile} from '../Components/AttachmentGrid';

import '../styles_add.css';

registerLocale('pt-BR', ptBR);

export default function ProtectionSNPC ()
{
	const history = useHistory();
	const { token } = useContext(Context);
	const { roles, unique_name }: DecodedToken = jwtDecode(token.access_token);
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [chooseApplicant, setChooseApplicant] = useState<boolean>(false);
	const [insertApplicants, setInsertApplicants] = useState<boolean>(false);
	const [selectedApplicants, setSelectedApplicants] = useState<Array<ApplicantResults>>([]);
	const [unselectedApplicants, setUnselectedApplicants] = useState<Array<object>>([]);
	const [choosenApplicants, setChoosenApplicants] = useState<Array<ApplicantResults>>([]);
	const [chooseBreeder, setChooseBreeder] = useState<boolean>(false);
	const [insertBreeder, setInsertBreeder] = useState<boolean>(false);
	const [selectedBreeder, setSelectedBreeder] = useState<Array<BreederResults>>([]);
	const [unselectedBreeder, setUnselectedBreeder] = useState<Array<object>>([]);
	const [choosenBreeder, setChoosenBreeder] = useState<Array<object>>([]);
	const [transferredCultivar, setTransferredCultivar] = useState<boolean>(false);
	const [neededSpecifyModality, setNeededSpecifyModality] = useState<boolean>(false);
	const [lblModality, setLblModality] = useState('Escolher arquivo');
	const [modifiedOrganism, setModifiedOrganism] = useState<boolean>(false);
	const [cultivarProtection, setCultivarProtection] = useState<boolean>(false);
	const [protectionOfCultivarItems, setProtectionOfCultivarItems] = useState<Array<object>>([]);
	const [cultivarInBrazil, setCultivarInBrasil] = useState<boolean>(false);
	const [cultivarOutside, setCultivarOutside] = useState<boolean>(false);
	const [lblNFCultivar, setLblNFCultivar] = useState('Escolher arquivo');
	const [lblProcurement, setLblProcurement] = useState('Escolher arquivo');
	const [lblDescriptorsTable, setLblDescriptorsTable] = useState('Escolher arquivo');
	const [selectedFilesAdditionals, setSelectedFilesAdditionals] = useState<Array<object>>([]);
	const [lblAdditionalFiles, setLblAdditionalFiles] = useState('Escolher arquivo');
	const [submited, setSubmited] = useState<boolean>(false);
	const [regulatory, setRegulatory] = useState<boolean>();
	const [error, setError] = useState<any>(null);
	const [arrayCulture, setArrayCulture] = useState<CultureResults[]>([]);
	const [arrayEvents, setArrayEvents] = useState<EventsResult[]>([]);
	const [arrayTypeAttachment, setArrayAttachment] = useState<TypeAttachment[]>([]);
	const [breeders, setBreeders] = useState<BreederResults[]>([]);
	const [applicants, setApplicants] = useState<ApplicantResults[]>([]);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [specieName, setSpecieName] = useState<string>('');
	const [selectedEvent, setSelectedEvent] = useState<EventsResult>();
	const [selectedModality, setSelectedModality] = useState<any>();
	const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
	const [models, setModels] = useState<ModelFilesProtection>();
	const [successFiles, setSuccessFiles] = useState<boolean>(false);
	const [additionalFiles, setAdditionalFiles] = useState<any[]>([]);
	const [cultureDetails, setCultureDetails] = useState<CultureResults>();
	const [loadingNeededData, setLoadingNeededData] = useState<boolean>(true);
	const [date, setDate] = useState(new Date());
	const [errorSubmit, setErrorSubmit] = useState<any>(null);
	const [isRegulatory, setIsRegulatory] = useState<boolean>(false);
	const [regulatoryModal, setRegulatoryModal] = useState<boolean>(false);
	const [successRegulatory, setSuccessRegulatory] = useState<boolean>(false);
	const [errorRegulatory, setErrorRegulatory] = useState<any>(null);
	const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
	const [errorDraft, setErrorDraft] = useState<any>(null);
	const [successDraft, setSuccessDraft] = useState<boolean>(false);
	const [errorOnForm, setErrorOnForm] = useState<boolean>(false);

	var formDataFiles = new FormData();
	var formDataAdditionalFiles = new FormData();

	useEffect(() => {
		const getProtectionList = async () => {
			try {
				let resultCulture = await api.get<CultureResults[]>(`api/v1/cultivares/Cultura/obterporfiltro?Ativo=true`);
				let restulEvents = await api.get<EventsResult[]>(`api/v1/cultivares/Evento/obterporfiltro?Ativo=true`);
				let resultTypeAttachment = await api.get<TypeAttachment[]>(`api/v1/TipoDeAnexo`);
				let responseBreeders = await api.get<BreederResults[]>('api/v1/cultivares/EquipeTecnicaMelhorista/obterporfiltro?Ativo=true');
				let responseApplicants = await api.get<ApplicantResults[]>('api/v1/cultivares/Requerente/obterporfiltro?Ativo=true');

				if (resultCulture.status === 200)
					setArrayCulture(resultCulture.data);
				if (restulEvents.status === 200)
					setArrayEvents(restulEvents.data);
				if (resultTypeAttachment.status === 200)
					setArrayAttachment(resultTypeAttachment.data);
				if (responseBreeders.status === 200) {
					setBreeders(responseBreeders.data);
				}
				if (responseApplicants.status === 200) {
					setApplicants(responseApplicants.data);
				}
			} catch (error:any) {
				setError(error.response.data);
			}
		}
		setLoadingNeededData(false);
		getProtectionList();
	}, []);

	const conditionalModality = transferredCultivar ? {
		modality: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		modality: yup
			.string()
	}

	const conditionalModifiedOrganism = modifiedOrganism ? {
		cultivarGeneticTransformationEvent: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarGeneticTransformationEvent: yup
			.string()
	}

	const conditionalDenominationCultivar = cultivarInBrazil ? {
		denominationCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		denominationCultivar: yup
			.string()
	}

	const conditionalFileCultivar = cultivarInBrazil ? {
		commercializationFile: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		commercializationFile: yup
			.string()
	}


	const conditionalountryOfCommercialization = cultivarOutside ? {
		countryOfCommercialization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		countryOfCommercialization: yup
			.string()
	}

	const conditionalDenominationOfCommercialization = cultivarOutside ? {
		denominationOfCommercialization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		denominationOfCommercialization: yup
			.string()
	}

	const conditionalSpecificModality = neededSpecifyModality ? {
		specificModality: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		specificModality: yup
			.string()
	}

	const conditionalIntergovernametalOrganization = cultivarProtection ? {
		intergovernametalOrganization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		intergovernametalOrganization: yup
			.string()
	}

	const conditionalTypeOfprotection = cultivarProtection ? {
		typeOfprotection: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		typeOfprotection: yup
			.string()
	}

	const conditionalProtocolNumber = cultivarProtection ? {
		protocolNumber: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		protocolNumber: yup
			.string()
	}

	const conditionalDenomination = cultivarProtection ? {
		denomination: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		denomination: yup
			.string()
	}

	const protectionSNPCSchema = yup.object().shape({
		scientificName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		speciesName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		proposedDenomination: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		experimentalDenomination: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		...conditionalModality,
		...conditionalSpecificModality,
		modalityDocument: yup
			.mixed(),
		countryOfCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		...conditionalModifiedOrganism,
		...conditionalIntergovernametalOrganization,
		...conditionalTypeOfprotection,
		date: yup
			.date(),
		...conditionalProtocolNumber,
		...conditionalDenomination,
		...conditionalDenominationCultivar,
		dateOfFirstCommercialization: yup
			.date(),
		...conditionalFileCultivar,
		commercializationFile: yup
			.mixed(),
		...conditionalountryOfCommercialization,
		...conditionalDenominationOfCommercialization,
		dateOfCommercialization: yup
			.date(),
		sampleAddress: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		contactPersonInfo: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		procurementReport: yup
			.mixed(),
		descriptorsTable: yup
			.mixed(),
		fileType: yup
			.string(),
		additionalFiles: yup
			.mixed(),
	})

	const initialValues: ProtectionSNPCForm = {
		scientificName: '',
		speciesName: '',
		proposedDenomination: '',
		experimentalDenomination: '',
		modality: '',
		specificModality: '',
		modalityDocument: '',
		countryOfCultivar: '',
		cultivarGeneticTransformationEvent: '',
		denomination: '',
		intergovernametalOrganization: '',
		protocolNumber: '',
		typeOfprotection: 'Proteção de Cultivar (PBR)',
		date: new Date(),
		denominationCultivar: '',
		dateOfFirstCommercialization: new Date(),
		commercializationFile: '',
		countryOfCommercialization: '',
		denominationOfCommercialization: '',
		dateOfCommercialization: new Date(),
		sampleAddress: '',
		contactPersonInfo: '',
		procurementReport: '',
		descriptorsTable: '',
		fileType: '',
		additionalFiles: ''
	}

	const { register, handleSubmit, setValue, getValues, errors, control, trigger } = useForm({
		defaultValues: initialValues,
		mode: 'onSubmit',
		resolver: yupResolver(protectionSNPCSchema)
	})

	const verifyErrorsOnForm = (): boolean => {
		let modalityDocument = getValues("modalityDocument");
		let procurementReport = getValues("procurementReport");
		let descriptorsTable = getValues("descriptorsTable");
		let commercializationFile = getValues("commercializationFile");

		let errorsForm: any[] = [];

		if (errors.speciesName)
			errorsForm.push("Nome comum da espécie é obrigatório")

		if (errors.proposedDenomination)
			errorsForm.push("Denominação proposta é obrigatória")

		if (errors.experimentalDenomination)
			errorsForm.push("Denominação experimental ou pré-comercial é obrigatória")

		if (regulatory && errors.modality)
			errorsForm.push("Modalidade é obrigatória quando é caso de Cultivar Transferida")

		if (regulatory && errors.countryOfCultivar)
			errorsForm.push("País em que a cultivar foi obtida é obrigatório")

		if (regulatory && errors.sampleAddress)
			errorsForm.push("Endereço é obrigatório")

		if (regulatory && errors.contactPersonInfo)
			errorsForm.push("Pessoa de Contato é obrigatória")

		if (regulatory && modifiedOrganism && errors.cultivarGeneticTransformationEvent)
			errorsForm.push("Evento é obrigatório quando é caso de Cultivar Geneticamente Modificada")

		if (regulatory && transferredCultivar && errors.specificModality)
			errorsForm.push("Modalidade da Cultivar Transferida é obrigatória")

		if (regulatory && selectedApplicants.length === 0)
			errorsForm.push("Obrigatório ao menos um requerente")

		if (regulatory && selectedBreeder.length === 0)
			errorsForm.push("Obrigatório ao menos um melhorista")

		if (regulatory && procurementReport.length === 0)
			errorsForm.push("Relatório Técnico de Obtenção é obrigatório")

		if (regulatory && descriptorsTable.length === 0)
			errorsForm.push("Tabela de Descritores é obrigatória")

		if (regulatory && neededSpecifyModality && modalityDocument.length === 0)
			errorsForm.push("Documento Comprobatório é obrigatório quando é caso de Cultivar Transferida")

		if (regulatory && cultivarInBrazil && errors.denominationCultivar)
			errorsForm.push("Denominação é obrigatória quando é caso de Cultivar oferecida à venda no Brasil")

		if (regulatory && cultivarInBrazil && commercializationFile.length === 0)
			errorsForm.push("Nota Fiscal é obrigatória quando é caso de Cultivar oferecida à venda no Brasil")

		if (regulatory && cultivarOutside && errors.countryOfCommercialization)
			errorsForm.push("País é obrigatório quando é caso de Cultivar oferecida à venda no exterior")

		if (regulatory && cultivarOutside && errors.denominationOfCommercialization)
			errorsForm.push("Denominação é obrigatória quando é caso de Cultivar oferecida à venda no Brasil")

		if (errorsForm.length > 0)
			return true;
		else
			return false;

	//	if (((submited &&
	//		(!selectedApplicants.length ||
	//			!selectedBreeder.length ||
	//			!procurementReport.length ||
	//			!descriptorsTable.length ||
	//			(neededSpecifyModality
	//				&& !modalityDocument.length) ||
	//			(cultivarInBrazil &&
	//				errors.denominationCultivar && errors.commercializationFile))) ||
	//		errors.countryOfCommercialization ||
	//		errors.denominationOfCommercialization ||
	//		(cultivarOutside && (errors.countryOfCommercialization ||
	//			errors.denominationOfCommercialization)
	//		)) ||
	//		errors.speciesName ||
	//		errors.proposedDenomination ||
	//		errors.experimentalDenomination ||
	//		errors.modality ||
	//		errors.countryOfCultivar ||
	//		errors.cultivarGeneticTransformationEvent ||
	//		errors.specificModality) {
	//		return true;
	//	}
	//	return false;
	}

	const expectedApplicants = (): RequestProtectionApplicants[] => {
		let applicantsModel: RequestProtectionApplicants[] = [];

		for (let i = 0, t = selectedApplicants.length; i < t; i++) {
			applicantsModel.push({
				requerenteId: selectedApplicants[i].id
			})
		}
		return applicantsModel;
	}

	const setUnselectedApplicant = (selectedApplicant: ApplicantResults[]) => {
		const applicant = applicants.filter((item => {
			return !selectedApplicant.includes(item);
		}))

		setUnselectedApplicants(applicant);
		setTimeout(() => {setUnselectedApplicants(applicant);}, 500);
	}

	const onRowSelectedApplicant = ({ api }: any) => {
		const data = api.getSelectedRows();

		setChoosenApplicants(data);
	}

	const onRowSelectedBreeder = ({ api }: any) => {
		const data = api.getSelectedRows();

		setChoosenBreeder(data);
	}

	const setUnselectedBreederList = (item: any) => {
		const breeder = breeders.filter((item => {
			return !selectedBreeder.includes(item);
		}))

		setUnselectedBreeder([]);
		setTimeout(() => {
			setUnselectedBreeder(breeder);
		}, 500);
	}

	const expectedBreeders = (): RequestProtectionMelhoristas[] => {
		let breedersModel: RequestProtectionMelhoristas[] = [];

		for (let i = 0, t = selectedBreeder.length; i < t; i++) {
			breedersModel.push({equipeTecnicaMelhoristaId: selectedBreeder[i].id});
		}
		return breedersModel;
	}

	const actionApplicants = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setSelectedApplicants([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));

					setTimeout(() => {
						setSelectedApplicants([...arr]);
					}, 500);
				}}
			/>
		)
	}

	const actionBreeder = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {

					if (!props)
						return;

					setSelectedBreeder([]);
					let arr: any = [];
					
					props.node.gridApi.updateRowData({ remove: [props.node.data] })

					props.node.gridApi.forEachNode(({ data }: any) => arr.push(data));

					setSelectedBreeder([]);
					setTimeout(() => {
						setSelectedBreeder([...arr]);
					}, 500);
				}}
			/>
		)
	}

	const getModality = (value: any) => {
		if (value === '3')
			setNeededSpecifyModality(true);
		else
			setNeededSpecifyModality(false)

		setSelectedModality(value);
	}

	const actionAdditionalFiles = (props: any) => {
		return (
			<DeleteButton
				onClick={() => {
					setSelectedFilesAdditionals([]);
					let arr: any = [];
					const { node } = props;

					node.gridApi.updateRowData({ remove: [node.data] })

					node.gridApi.forEachNode(({ data }: any) => arr.push(data));
					setSelectedFilesAdditionals([...arr]);
				}}
			/>
		)
	}

	const setSelectedFilesAdditionalsList = () => {
		const file = {
			nome: getValues('additionalFiles')[0].name
		}

		setSelectedFilesAdditionals([]);
		setTimeout(() => {
			setSelectedFilesAdditionals([...selectedFilesAdditionals, file]);
		}, 500);
	}

	const onSubmitForm = async () =>
	{
		await onSubmit(true);
	}

	const onSubmit = async (sendBack : boolean = false) =>
	{
		setLoadingSubmit(true);

		if (verifyErrorsOnForm()) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setLoadingSubmit(false);
		}
		else
		{
			try {
				const { data, status } = await api.post('api/v1/cultivares/CultivaresProtecao', {
					culturaId: cultureDetails?.id,
					nomeComumDaEspecie: specieName,
					nomeCientificoDaEspecie: getValues('scientificName'),
					denominacaoProposta: getValues('proposedDenomination'),
					denominacaoExperimental: getValues('experimentalDenomination'),
					cultivarTransferida: transferredCultivar,
					tipoModalidade: selectedModality,
					outraEspecificar: getValues('specificModality'),
					paisCultivarObtida: getValues('countryOfCultivar'),
					cultivarGeneticamenteModificada: modifiedOrganism,
					eventoId: selectedEvent?.id,
					nomeDoEvento: selectedEvent?.nomeEvento,
					protecaoDaCultivarFoiSolicitadaNoExterior: cultivarProtection,
					paisOrganizacao: getValues('intergovernametalOrganization'),
					data: getValues('date'),
					numeroProtocolo: getValues('protocolNumber'),
					denominacao: getValues('denomination'),
					cultivarOferecidaVendaComercializadaBrasil: cultivarInBrazil,
					denominacaoVendaComercializacao: getValues('denominationCultivar'),
					dataDaPrimeiraComercializacao: getValues('dateOfFirstCommercialization'),
					cultivarFoiOferecidaComercializacaoNoExeterior: cultivarOutside,
					pais: getValues('countryOfCommercialization'),
					denominacaoOferecidaComercializacaoNoExeterior: getValues('denominationOfCommercialization'),
					datePrimeiraComercializacaoNoExterior: getValues('dateOfCommercialization'),
					enderecoOndeAmostraVivaSeraMantida: getValues('sampleAddress'),
					pessoaDeContato: getValues('contactPersonInfo'),
					usuario: unique_name,
					cultivaresRequerentes: expectedApplicants(),
					cultivaresProtecaoEquipeTecnicas: expectedBreeders(),
					observacoes: getValues('generalObservations'),
					arquivosParaExcluir: [],
				})

				if (status === 201) {
					await sendToRegulatory(data.id);

					setSuccessfulResponse(true);

					await onSubmitFiles(data);
					await onSubmitAdditionalFiles(data);

					setErrorOnForm(false);

					if (sendBack) {
						window.scrollTo({ top: 0, behavior: 'smooth' });
						setTimeout(() => {
							history.goBack();
						}, 3500)
					}
				}
			}
			catch (error: any) {
				setErrorSubmit(error.response.data);
				setLoadingSubmit(false);
			}
		}
	}

	const onSubmitDraft = async () =>
	{
		setLoadingDraft(true);
		setErrorDraft(null);
		
		if (verifyErrorsOnForm()) {
			setErrorSubmit(error.response.data);
			setLoadingSubmit(false);
		}
		else {
			try {
				const { data, status } = await api.post('/api/v1/cultivares/CultivaresProtecao/rascunho', {
					culturaId: cultureDetails?.id,
					nomeComumDaEspecie: specieName,
					nomeCientificoDaEspecie: getValues('scientificName'),
					denominacaoProposta: getValues('proposedDenomination'),
					denominacaoExperimental: getValues('experimentalDenomination'),
					cultivarTransferida: transferredCultivar,
					tipoModalidade: selectedModality,
					outraEspecificar: getValues('specificModality'),
					paisCultivarObtida: getValues('countryOfCultivar'),
					cultivarGeneticamenteModificada: modifiedOrganism,
					eventoId: selectedEvent?.id,
					nomeDoEvento: selectedEvent?.nomeEvento,
					protecaoDaCultivarFoiSolicitadaNoExterior: cultivarProtection,
					paisOrganizacao: getValues('intergovernametalOrganization'),
					data: getValues('date'),
					numeroProtocolo: getValues('protocolNumber'),
					denominacao: getValues('denomination'),
					cultivarOferecidaVendaComercializadaBrasil: cultivarInBrazil,
					denominacaoVendaComercializacao: getValues('denominationCultivar'),
					dataDaPrimeiraComercializacao: getValues('dateOfFirstCommercialization'),
					cultivarFoiOferecidaComercializacaoNoExeterior: cultivarOutside,
					pais: getValues('countryOfCommercialization'),
					denominacaoOferecidaComercializacaoNoExeterior: getValues('denominationOfCommercialization'),
					datePrimeiraComercializacaoNoExterior: getValues('dateOfCommercialization'),
					enderecoOndeAmostraVivaSeraMantida: getValues('sampleAddress'),
					pessoaDeContato: getValues('contactPersonInfo'),
					usuario: unique_name,
					cultivaresRequerentes: expectedApplicants(),
					cultivaresProtecaoEquipeTecnicas: expectedBreeders(),
					observacoes: getValues('generalObservations'),
					arquivosParaExcluir: [],
				})

				if (status === 201) {
					setSuccessDraft(true);
					await onSubmitFiles(data);
					await onSubmitAdditionalFiles(data);

					window.scrollTo({ top: 0, behavior: 'smooth' });
					setTimeout(() => {
						history.goBack();
					}, 2500);
				}
			}
			catch (error: any) {
				setErrorDraft(error.response.data);
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
			setLoadingDraft(false);
		}
	}

	const sendToRegulatory = async (id: number) =>
	{
		setErrorRegulatory(null);

		
		try
		{
			
			const { status } = await api.post(`api/v1/cultivares/CultivaresProtecao/${id}/enviarparaaprovacao`);
			if (status === 200)
			{
				setSuccessRegulatory(true);
			}
		}
		catch (error:any)
		{
			setErrorRegulatory(error.response.data);
		}
	}

	const onSubmitAdditionalFiles = async (cultivarRegistro?: any) =>
	{

		additionalFiles.map(file => formDataAdditionalFiles.append("files", file.arquivo));
		
		try
		{
			const { status } = await api.post(`api/v1/cultivares/CultivaresProtecao/${cultivarRegistro.id}/SalvarArquivosAnexos`, formDataAdditionalFiles);

		}
		catch (error:any)
		{
			console.error(error);
		}
		
		setLoadingSubmit(false);
	}

	const downloadModel = async (modelCode: string, fileName: string) => {
		setLoadingDownload(true);
		try {
			const { data } = await apiFileDownload.get(`api/v1/cultivares/Download?codigo=${modelCode}`);
			let blob = new Blob([data], {
				type: 'application/pdf'
			});
			var url = window.URL.createObjectURL(blob)

			let a = document.createElement("a");
			if (typeof a.download === "undefined") {
				window.location.href = url;
			} else {
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
			}

		} catch (error:any) {
			console.error(error);
		}
		setLoadingDownload(false);
	}

	const onSubmitFiles = async (cultivarProtecao?: any) =>
	{
		let fileDescriptorsTable = getValues('descriptorsTable');
		let fileProcurementReport = getValues('procurementReport');
		let fileModalityDocument = getValues('modalityDocument');
		let fileCommercializationFile = getValues('commercializationFile');

		formDataFiles.append('fileArquivoTabelaDescritores', fileDescriptorsTable[0]);
		formDataFiles.append('fileArquivoRelatorioTecnicoDeObtencao', fileProcurementReport[0]);
		
		if (fileModalityDocument !== undefined)
			formDataFiles.append('fileArquivoDocumentoComprobatorio', fileModalityDocument[0]);
		
		if (fileCommercializationFile !== undefined)
			formDataFiles.append('fileArquivoAnexoVendaComercializacao', fileCommercializationFile[0]);

		try
		{
			const { data, status } = await api.post(`/api/v1/cultivares/CultivaresProtecao/${cultivarProtecao.id}/SalvarArquivosProtecao`, formDataFiles);

			if (status === 200)
			{
				setSuccessFiles(true);
			}
		}
		catch (error:any)
		{
			console.error(error);
		}
		setLoadingSubmit(false);
	}

	const setArrFilesToSubmit = (file: any) => {
		setAdditionalFiles([...additionalFiles, {
			arquivo: file[0],
		}])
	}
	const getCultureById = async (idCulture: number) => {
		try {
			const { data, status } = await api.get<CultureResults>(`api/v1/cultivares/Cultura/${Number(idCulture)}`);
			if (status === 200) {
				setCultureDetails(data);
				if (data.modeloProtecaoCultivares !== null) {
					setModels(data.modeloProtecaoCultivares);
				}
			}
		} catch (error:any) {
			console.error(error);
		}
	}

	const setScientificName = (cultureId: number) => {
		const scientificName = arrayCulture.filter((item => {
			return item.id === Number(cultureId);
		}))

		let result = scientificName[0];
		if (!!result)
			setValue('scientificName', result.descricao);
	}

	const setEventSelected = (eventId: number) => {
		const event = arrayEvents.filter(item => {
			return item.id === Number(eventId);
		})

		setSelectedEvent(event[0]);
	}

	const GetRowNodeId = (node:any) : any =>
	{
		return node?.id;
	}

	const [attachmentFiles, setAttachmentFiles] = useState<attachmentFile[]>([]);

	const ParseGroup10 = () : JSX.Element =>
	{
		// return <AttachmentGrid
		// 	title="10. Anexos complementares"
		// 	label="Anexo Complementar"
		// 	onChange={(files:attachmentFile[]) => {setAttachmentFiles(files);}}
		// />;

		return <Fieldset title="10. Anexos complementares">
			<Form.Row className="row-custom">
				<Form.Group as={Col} md={8}>
					<Form.Label>
						Anexo complementar
					</Form.Label>
					<Form.File
						ref={register}
						name="additionalFiles"
						label={lblAdditionalFiles}
						data-browse="upload"
						custom
						onChange={({ target }: any) => {
							if (!target.value) {
								return
							}
							setLblAdditionalFiles(`${target.files[0].name}`);
							setArrFilesToSubmit(target.files);
						}}
					/>
				</Form.Group>
				<Form.Group as={Col}>
					<br />
					<AddButton
						type="button"
						onClick={() => {
							if (getValues('additionalFiles').length) {
								setSelectedFilesAdditionalsList()
								setLblAdditionalFiles('Escolher arquivo...');
							}
						}}
					/>
				</Form.Group>
			</Form.Row>
			<If condition={selectedFilesAdditionals.length}>
				<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
					<AgGridReact
						rowData={selectedFilesAdditionals}
						defaultColDef={{ flex: 1, sortable: true }}
						suppressRowClickSelection={true}
						onRowSelected={onRowSelectedApplicant}
						rowSelection={'multiple'}
						frameworkComponents={{
							action: actionAdditionalFiles,
						}}
					>
						<AgGridColumn
							headerName="Arquivo"
							field="nome"
						/>
						<AgGridColumn
							headerName="Ação"
							cellRenderer="action"
						/>
					</AgGridReact>
				</div>
			</If>
		</Fieldset>;
	}

	return <div className="container container-fluid">
		<If condition={successfulResponse && successFiles && !error}>
			<AlertSuccess
				message={STRINGS.ALERT_MESSAGE_SUCCESS}
			/>
		</If>
		<If condition={successDraft && !errorDraft}>
			<AlertSuccess message={STRINGS.SUCCESS_DRAFT} />
		</If>
		<If condition={successRegulatory && !errorRegulatory}>
			<AlertSuccess
				message={STRINGS.REGULATORY_SUCCESS}
			/>
		</If>
		{/*<If condition={!successRegulatory && errorRegulatory !== null}>*/}
		{/*	{errorRegulatory !== null && errorRegulatory.message.length > 0 ? errorRegulatory.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}*/}
		{/*</If>*/}
		{/*<If condition={!successDraft && errorDraft}>*/}
		{/*	{errorDraft !== null && errorDraft.message.length > 0 ? errorDraft.message.map((error: string) => <AlertError message={error} />) : <AlertError message={STRINGS.ALERT_GENERIC_ERROR} />}*/}
		{/*</If>*/}
		<If condition={errorOnForm}>
			<AlertError message={STRINGS.ALERT_WARNING_FIELDS} />
		</If>
		<h3>Cadastro de proteção &#45; snpc</h3>
		<Form onSubmit={handleSubmit(onSubmitForm)}>
			<Fieldset title="1. Requerente">
				<If condition={submited && !selectedApplicants.length}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group className="label-btn">
						<Form.Label>
							Requerente<em>*</em>
						</Form.Label>
						<If condition={loadingNeededData}>
							<LoadingInForm />
						</If>
						<AddButton
							type="button"
							onClick={() => {
								setChooseApplicant(true);
								setUnselectedApplicant(selectedApplicants);
								setChoosenApplicants([]);
							}}
						/>
					</Form.Group>
				</Form.Row>
				<If condition={insertApplicants && selectedApplicants.length}>
					<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedApplicants}
							getRowNodeId={GetRowNodeId}
							deltaRowDataMode={true}
							defaultColDef={{ flex: 1, sortable: true }}
							suppressRowClickSelection={true}
							onRowSelected={onRowSelectedApplicant}
							rowSelection={'multiple'}
							frameworkComponents={{
								action: actionApplicants,
							}}
						>
							<AgGridColumn
								headerName='Requerente'
								field='nome'
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
							/>
						</AgGridReact>
					</div>
				</If>
				<If condition={chooseApplicant}>
					<Modal
						onHide={() => setChooseApplicant(false)}
						show={true}
						title="Lista de requerentes"
					>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
							<AgGridReact
								rowData={unselectedApplicants}
								getRowNodeId={GetRowNodeId}
								deltaRowDataMode={true}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
								onRowSelected={onRowSelectedApplicant}
								rowSelection={'multiple'}
							>
								<AgGridColumn
									headerCheckboxSelection
									headerName="Selecionar"
									checkboxSelection={true}
									pinned="left"
								/>
								<AgGridColumn
									headerName='Requerente'
									field='nome'
								/>
							</AgGridReact>
						</div>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setChooseApplicant(false)}
							/>
							<Button
								title="Inserir"
								contained={true}
								type="button"
								onClick={() => {
									setInsertApplicants(true);
									setChooseApplicant(false);
									setSelectedApplicants([]);
									setTimeout(() => {
										setSelectedApplicants([...selectedApplicants, ...choosenApplicants]);
									}, 500);
								}}
								disabled={!choosenApplicants.length}
							/>
						</div>
					</Modal>
				</If>
			</Fieldset>

			<Fieldset title="2. Táxon">
				<If condition={errors.speciesName}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Nome comum da espécie<em>*</em>
						</Form.Label>
						<If condition={loadingNeededData}>
							<LoadingInForm />
						</If>
						<Form.Control
							as="select"
							ref={register}
							name="speciesName"
							isInvalid={errors.speciesName !== undefined}
							onChange={({ target }: any) => {
								getCultureById(target.value);
								setScientificName(target.value);
								let index = target.options.selectedIndex;
								setSpecieName(target.options[index].label);
							}}
						>
							<Form.Control
								as="option"
								label="Escolha uma opção"
								value=""
							/>
							{arrayCulture.map(item => (
								<Form.Control
									as="option"
									label={item.nome}
									value={item.id}
									key={item.id}
								/>
							))}
							<If condition={errors.speciesName}>
								<Form.Control.Feedback type="invalid">
									{errors.speciesName?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Control>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Nome científico da espécie<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="scientificName"
							autoComplete="off"
							placeholder="Nome científico da espécie"
							readOnly
						/>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="3. Cultivar">
				<If condition={errors.proposedDenomination || errors.experimentalDenomination}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Denominação proposta<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="proposedDenomination"
							placeholder="Insira a denominação proposta"
							autoComplete="off"
							isInvalid={errors.proposedDenomination !== undefined}
							maxLength={100}
						/>
						<If condition={errors.proposedDenomination}>
							<Form.Control.Feedback type="invalid">
								{errors.proposedDenomination?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							Denominação experimental ou pré-comercial<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="experimentalDenomination"
							placeholder="Insira a denominação experimental ou pré-comercial"
							autoComplete="off"
							isInvalid={errors.experimentalDenomination !== undefined}
							maxLength={100}
						/>
						<If condition={errors.experimentalDenomination}>
							<Form.Control.Feedback type="invalid">
								{errors.experimentalDenomination?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="4. Obtenção da cultivar">
				<If condition={(submited &&
					(!selectedBreeder.length ||
						(neededSpecifyModality
							&& !getValues("modalityDocument").length))) ||
					errors.modality ||
					errors.countryOfCultivar ||
					errors.cultivarGeneticTransformationEvent ||
					errors.specificModality}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} className="label-btn">
						<Form.Label>
							O melhoramento foi realizado pelas seguintes pessoas<em>*</em>
						</Form.Label>
						<AddButton
							onClick={() => {
								setChooseBreeder(true);
								setUnselectedBreederList(selectedBreeder);
								setChoosenBreeder([]);
							}}
							type="button"
						/>
					</Form.Group>
				</Form.Row>
				<If condition={insertBreeder && selectedBreeder.length}>
					<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
						<AgGridReact
							rowData={selectedBreeder}
							getRowNodeId={GetRowNodeId}
							deltaRowDataMode={true}
							defaultColDef={{ flex: 1, sortable: true }}
							frameworkComponents={{
								action: actionBreeder,
							}}
						>
							<AgGridColumn
								headerName='Melhorista'
								field='nome'
							/>
							<AgGridColumn
								headerName="Ação"
								cellRenderer="action"
							/>

						</AgGridReact>
					</div>
				</If>
				<If condition={chooseBreeder}>
					<Modal
						title="Lista de melhoristas"
						show={true}
						onHide={() => setChooseBreeder(false)}
					>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '300px', width: '100%' }}>
							<AgGridReact
								rowData={unselectedBreeder}
								getRowNodeId={GetRowNodeId}
								deltaRowDataMode={true}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
								onRowSelected={onRowSelectedBreeder}
								rowSelection={'multiple'}
							>
								<AgGridColumn
									headerCheckboxSelection
									headerName="Selecionar"
									checkboxSelection={true}
									pinned="left"
								/>
								<AgGridColumn
									headerName='Melhorista'
									field='nome'
								/>
							</AgGridReact>
						</div>
						<div className="button-position">
							<Button
								title="Cancelar"
								contained={false}
								type="button"
								onClick={() => setChooseBreeder(false)}
							/>
							<Button
								title="Inserir"
								contained={true}
								type="button"
								onClick={() => {
									setChooseBreeder(false);
									setInsertBreeder(true);
									setSelectedBreeder([])
									setTimeout(() => {
										setSelectedBreeder([...selectedBreeder, ...choosenBreeder] as BreederResults[])
									}, 500);
								}}
								disabled={!choosenBreeder.length}
							/>
						</div>
					</Modal>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} controlId="transferredCultivar">
						<div className="option-cultivar">
							<span>Cultivar transferida?<em>*</em></span>
							<Form.Check
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								onChange={() => setTransferredCultivar(true)}
								checked={transferredCultivar}
							/>
							<Form.Check
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								onChange={() => setTransferredCultivar(false)}
								checked={!transferredCultivar}
							/>
						</div>
					</Form.Group>
				</Form.Row>
				<If condition={transferredCultivar}>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Modalidade<em>*</em>
							</Form.Label>
							<Form.Control
								as="select"
								ref={register}
								name="modality"
								onChange={({ target }: any) => getModality(target.value)}
								isInvalid={errors.modality !== undefined}
							>
								{protectionSNPC.modalitys.map((item, index) => (
									<Form.Control
										key={index}
										as="option"
										label={item.modality}
										value={item.id}
									/>
								))}
							</Form.Control>
							<If condition={errors.modality}>
								<Form.Control.Feedback type="invalid">
									{errors.modality?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
						<If condition={neededSpecifyModality}>
							<Form.Group as={Col}>
								<Form.Label>
									Especificar a modalidade<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="specificModality"
									placeholder="Insira a modalidade"
									autoComplete="off"
									isInvalid={errors.specificModality !== undefined}
									maxLength={300}
								/>
								<If condition={errors.specificModality}>
									<Form.Control.Feedback type="invalid">
										{errors.specificModality?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Documento comprobatório<em>*</em>
								</Form.Label>
								<Form.File
									ref={register}
									name="modalityDocument"
									type="file"
									label={lblModality}
									data-browse="upload"
									custom
									onChange={({ target }: any) => {
										if (!target.value) {
											return
										}
										setLblModality(`${target.files[0].name}`);
									}}
									accept=".pdf"
								/>
							</Form.Group>
						</If>
					</Form.Row>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.Label>
							País em que a cultivar foi obtida<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="countryOfCultivar"
							placeholder="Insira o país em que a cultivar foi obtida"
							isInvalid={errors.countryOfCultivar !== undefined}
							autoComplete="off"
							maxLength={255}
						/>
						<If condition={errors.countryOfCultivar}>
							<Form.Control.Feedback type="invalid">
								{errors.countryOfCultivar?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} controlId="modifiedOrganism">
						<div className="option-cultivar">
							<span>Cultivar geneticamente modificada?<em>*</em></span>
							<Form.Check
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								onChange={() => setModifiedOrganism(true)}
								checked={modifiedOrganism}
							/>
							<Form.Check
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								onChange={() => setModifiedOrganism(false)}
								checked={!modifiedOrganism}
							/>
						</div>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<If condition={modifiedOrganism}>
						<Form.Group as={Col}>
							<Form.Label>
								Nome do evento<em>*</em>
							</Form.Label>
							<Form.Control
								as="select"
								ref={register}
								name="cultivarGeneticTransformationEvent"
								isInvalid={errors.cultivarGeneticTransformationEvent !== undefined}
								onChange={({ target }: any) => setEventSelected(target.value)}
								title={selectedEvent?.nomeEvento}
							>
								<Form.Control
									as="option"
									label="Selecione o Evento de transformação genética"
									value=""
								/>
								{arrayEvents.map(item => (
									<Form.Control
										key={item.id}
										as="option"
										label={`${item.nomeEvento.substring(0, 135)}...`}
										value={item.id}
										title={item.nomeEvento}
									/>
								))}
							</Form.Control>
							<If condition={errors.cultivarGeneticTransformationEvent}>
								<Form.Control.Feedback type="invalid">
									{errors.cultivarGeneticTransformationEvent?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</If>
				</Form.Row>
			</Fieldset>

			<Fieldset title="5. Proteção da cultivar">
				<If condition={errors.intergovernametalOrganization ||
					errors.typeOfprotection ||
					errors.protocolNumber ||
					errors.denomination
				}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} controlId="cultivarProtection">
						<div className="option-cultivar">
							<span>A proteção da cultivar foi solicitada anteriormente no exterior?<em>*</em></span>
							<Form.Check
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								onChange={() => setCultivarProtection(true)}
								checked={cultivarProtection}
							/>
							<Form.Check
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								onChange={() => setCultivarProtection(false)}
								checked={!cultivarProtection}
							/>
						</div>
					</Form.Group>
				</Form.Row>
				<If condition={cultivarProtection}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								País/Organização Intergovernamental<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="intergovernametalOrganization"
								placeholder="Insira o país/organização governamental"
								autoComplete="off"
								isInvalid={errors.intergovernametalOrganization !== undefined}
								maxLength={100}
							/>
							<If condition={errors.intergovernametalOrganization}>
								<Form.Control.Feedback type="invalid">
									{errors.intergovernametalOrganization?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Tipo de proteção<em>*</em>
							</Form.Label>
							<Form.Control
								as="select"
								ref={register}
								name="typeOfprotection"
								isInvalid={errors.typeOfprotection !== undefined}
							>
								{protectionSNPC.typeOfProtection.map(item => (
									<Form.Control
										as="option"
										key={item.id}
										label={item.type}
										value={item.type}
									/>
								))}
							</Form.Control>
							<If condition={errors.typeOfprotection}>
								<Form.Control.Feedback type="invalid">
									{errors.typeOfprotection?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Data<em>*</em>
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="date"
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Número de protocolo<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="protocolNumber"
								placeholder="Insira o número do protocolo"
								autoComplete="off"
								isInvalid={errors.protocolNumber !== undefined}
								maxLength={50}
							/>
							<If condition={errors.protocolNumber}>
								<Form.Control.Feedback type="invalid">
									{errors.protocolNumber?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Denominação<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="denomination"
								placeholder="Insira a denominação"
								autoComplete="off"
								isInvalid={errors.denomination !== undefined}
								maxLength={200}
							/>
							<If condition={errors.denomination}>
								<Form.Control.Feedback type="invalid">
									{errors.denomination?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
				</If>
			</Fieldset>

			<Fieldset title="6. Primeira comercialização da cultivar">
				<If condition={(
					submited &&
						(
							(cultivarInBrazil &&
								(errors.denominationCultivar || errors.commercializationFile)
							) ||
							errors.countryOfCommercialization ||
							errors.denominationOfCommercialization ||
							(cultivarOutside &&
								(errors.countryOfCommercialization || errors.denominationOfCommercialization)
							)
						)
				)}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} controlId="cultivarInBrazil">
						<div className="option-cultivar">
							<span>A cultivar foi oferecida à venda ou comercializada no Brasil?<em>*</em></span>
							<Form.Check
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								onChange={() => setCultivarInBrasil(true)}
								checked={cultivarInBrazil}
							/>
							<Form.Check
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								onChange={() => setCultivarInBrasil(false)}
								checked={!cultivarInBrazil}
							/>
						</div>
					</Form.Group>
				</Form.Row>
				<If condition={cultivarInBrazil}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Denominação<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="denominationCultivar"
								placeholder="Insira a denominação da cultivar"
								autoComplete="off"
								isInvalid={errors.denominationCultivar !== undefined}
								maxLength={200}
							/>
							<If condition={errors.denominationCultivar}>
								<Form.Control.Feedback type="invalid">
									{errors.denominationCultivar?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data da primeira comercialização<em>*</em>
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dateOfFirstCommercialization"
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
										minDate={moment().add(-1, 'y').toDate()}
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
					<If condition={(
						submited && cultivarInBrazil && !getValues("commercializationFile").length
					)}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={7}>
								<AlertError
									message={STRINGS.ALERT_WARNING_FIELDS}
								/>
							</Form.Group>
						</Form.Row>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={7}>
							<Form.Label>
								<span>Anexar 1ª nota fiscal de comercialização da cultivar<em>*</em></span>
							</Form.Label>
							<Form.File
								ref={register}
								name="commercializationFile"
								label={lblNFCultivar}
								type="file"
								onChange={({ target }: any) => {
									if (!target.value) {
										return
									}
									setLblNFCultivar(`${target.files[0].name}`);
								}}
								accept=".pdf"
								data-browse="upload"
								custom
							/>
							<If condition={errors.commercializationFile}>
								<Form.Control.Feedback type="invalid">
									{errors.commercializationFile?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
					</Form.Row>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col} controlId="cultivarOutside">
						<div className="option-cultivar">
							<span>A cultivar foi oferecida à venda ou comercializada no Exterior?<em>*</em></span>
							<Form.Check
								value="sim"
								type="radio"
								aria-label="radio 1"
								label="Sim"
								onChange={() => setCultivarOutside(true)}
								checked={cultivarOutside}
							/>
							<Form.Check
								value="não"
								type="radio"
								aria-label="radio 2"
								label="Não"
								onChange={() => setCultivarOutside(false)}
								checked={!cultivarOutside}
							/>
						</div>
					</Form.Group>
				</Form.Row>
				<If condition={cultivarOutside}>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								País<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="countryOfCommercialization"
								placeholder="Insira o país"
								autoComplete="off"
								isInvalid={errors.countryOfCommercialization !== undefined}
								maxLength={100}
							/>
							<If condition={errors.countryOfCommercialization}>
								<Form.Control.Feedback type="invalid">
									{errors.countryOfCommercialization?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Denominação<em>*</em>
							</Form.Label>
							<Form.Control
								ref={register}
								name="denominationOfCommercialization"
								placeholder="Insira a denominação"
								autoComplete="off"
								isInvalid={errors.denominationOfCommercialization !== undefined}
								maxLength={255}
							/>
							<If condition={errors.denominationOfCommercialization}>
								<Form.Control.Feedback type="invalid">
									{errors.denominationOfCommercialization?.message}
								</Form.Control.Feedback>
							</If>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data da primeira comercialização<em>*</em>
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dateOfCommercialization"
								render={({ onChange, value }) => (
									<DatePicker
										selected={value}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
										placeholderText="Selecionar a data"
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
				</If>
			</Fieldset>

			<Fieldset title="7. Declaração de amostra viva">
				<If condition={errors.sampleAddress ||
					errors.contactPersonInfo
				}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Endereço onde a amostra viva será mantida<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							name="sampleAddress"
							placeholder="Insira o endereço da amostra viva"
							autoComplete="off"
							isInvalid={errors.sampleAddress !== undefined}
							maxLength={300}
						/>
						<If condition={errors.sampleAddress}>
							<Form.Control.Feedback type="invalid">
								{errors.sampleAddress?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Pessoa de contato<em>*</em> &#40;nome, e-mail e telefone&#41;
						</Form.Label>
						<Form.Control
							ref={register}
							name="contactPersonInfo"
							placeholder="Insira o nome da pessoa de contato"
							autoComplete="off"
							isInvalid={errors.contactPersonInfo !== undefined}
							maxLength={500}
						/>
						<If condition={errors.contactPersonInfo}>
							<Form.Control.Feedback type="invalid">
								{errors.contactPersonInfo?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="8. Relatório técnico de obtenção">
				<If condition={submited &&
					!getValues("procurementReport").length}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Relatório técnico<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<If condition={models && models?.modeloRelatorioTecnicoObtencao}>
							<AddButton
								otherTitle="Baixar modelo"
								isDownload
								type="button"
								onClick={() => {
									if (models?.modeloRelatorioTecnicoObtencao) {
										downloadModel(models.modeloRelatorioTecnicoObtencao.codigo, models.modeloRelatorioTecnicoObtencao.nome);
									}
								}}
							/>
						</If>
						<If condition={!models?.modeloRelatorioTecnicoObtencao}>
							<NotAvailableModel />
						</If>
						<If condition={loadingDownload}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.File
							ref={register}
							name="procurementReport"
							label={lblProcurement}
							data-browse="upload"
							accept=".docx"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblProcurement(`${target.files[0].name}`);
							}}
						/>
						<If condition={errors.procurementReport}>
							<Form.Control.Feedback type="invalid">
								{errors.procurementReport?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
			</Fieldset>

			<Fieldset title="9. Tabela de descritores">
				<If condition={submited &&
					!getValues("descriptorsTable").length}>
					<AlertError
						message={STRINGS.ALERT_WARNING_FIELDS}
					/>
				</If>
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Label>
							Tabela de descritores<em>*</em>
						</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<If condition={models && models?.modeloTabelaDescritores}>
							<AddButton
								otherTitle="Baixar modelo"
								isDownload
								type="button"
								onClick={() => {
									if (models?.modeloTabelaDescritores) {
										downloadModel(models.modeloTabelaDescritores.codigo, models.modeloTabelaDescritores.nome);
									}
								}}
							/>
						</If>
						<If condition={!models?.modeloTabelaDescritores}>
							<NotAvailableModel />
						</If>
						<If condition={loadingDownload}>
							<LoadingInForm />
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={6}>
						<Form.File
							ref={register}
							name="descriptorsTable"
							label={lblDescriptorsTable}
							data-browse="upload"
							accept=".docx"
							custom
							onChange={({ target }: any) => {
								if (!target.value) {
									return
								}
								setLblDescriptorsTable(`${target.files[0].name}`);
							}}
						/>
						<If condition={errors.descriptorsTable}>
							<Form.Control.Feedback type="invalid">
								{errors.descriptorsTable?.message}
							</Form.Control.Feedback>
						</If>

					</Form.Group>
				</Form.Row>
			</Fieldset>

			{ParseGroup10()}
			
			<Fieldset title="11. Observações Gerais">
				<Form.Row className="row-custom">
					<Form.Group as={Col}>
						<Form.Control
							as="textarea"
							ref={register}
							name="generalObservations"
							type="text"
							placeholder="Insira as observações gerais"
							rows={5}
						/>
					</Form.Group>
				</Form.Row>
			</Fieldset>
			<div className="button-position">
				<Button
					title="Cancelar"
					type="button"
					contained={false}
					onClick={() => history.goBack()}
				/>
				<GenericButton
					title="Salvar e enviar para regulatório"
					variant="btn-warning"
					onClick={() => {
						trigger().then(validated => {
							setRegulatory(true);
							setSubmited(validated);
							setRegulatoryModal(validated);
							if (!validated) {
								setErrorOnForm(true);
								window.scrollTo({ top: 0, behavior: 'smooth' });
							}
						})
					}}
					loading={loadingSubmit}
				/>
				<Button
					title="Salvar rascunho"
					type="button"
					contained={true}
					onClick={() => {
						onSubmitDraft();
					}}
					isLoading={loadingDraft}
				/>
			</div>
		</Form>
		<If condition={regulatoryModal}>
			<ConfirmationModal
				show={regulatoryModal}
				onHide={() => {
					setRegulatoryModal(false);
					setIsRegulatory(false);
				}}
				title={STRINGS.REGULATORY_TITLE}
				confirmationMsg={STRINGS.REGULATORY_ACTION}
				onConfirm={() => {
					setRegulatoryModal(false);
					onSubmitForm();
				}}
				loading={isRegulatory ? loadingSubmit : false}
				variant="success"
				color="white"
			>
				<p>{STRINGS.REGULATORY_MESSAGE}</p>
			</ConfirmationModal>
		</If>
	</div>;
}