import React, { Fragment } from 'react';
import { Form, Col } from "react-bootstrap";
import DatePicker, { registerLocale } from 'react-datepicker';
import Button from "../../../components/Button";
import { useForm, Controller } from "react-hook-form";
import If from "../../../components/If";

export default function DataFluxoComponent (localprops:any)
{
    let DatePickerClassName = localprops.DatePickerClassName ?? '';

    return  <Form.Row className="row-custom">
                <Form.Group as={Col}>
                    <Form.Label>{localprops.text}</Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
                    <Controller                        
                        control={localprops.control}
                        name={localprops.name}
                        readOnly={true}
                        render={({ onChange, value }) => (
                        <DatePicker     
                            onKeyDown={(d)=>{                                
                                d.preventDefault();
                                return false;
                            }}         
                            value={localprops.value}              
                            selected={value}
                            disabled={!(localprops?.enabled ?? true)}
                            onChange={(d)=>{
                                onChange(d); 
                                localprops.onDataChanged(d)
                            }}
                            className={"input-custom-to-date " + DatePickerClassName}
                            locale="pt-BR"
                            dateFormat="dd/MM/yyyy"
                            placeholderText={localprops.placeholderText}
                            
                            isClearable={(localprops?.isClearable ?? true)}
                        />
                    )}
                />
                </Form.Group>
                <Form.Group>
                                      
                </Form.Group>
            </Form.Row>;

}