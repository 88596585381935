import React, { createContext } from 'react';
import { ContextData } from '../models/user';
import useAuth from './hooks/useAuth';

const emptySessionData: ContextData = {
    handleLogin: null,
    handleLogout: null,
    loading: true,
    user: {},
    token: {
        access_token: '',
        refresh_token: '',
        refresh_expires_in: ''
    }
};

const Context = createContext<ContextData>(emptySessionData);

function AuthProvider({ children }: any) {
    const {
        user, token, loading, handleLogin, handleLogout
    } = useAuth();

    return (
        <Context.Provider value={{ user, token, handleLogin, handleLogout, loading }}>
            {children}
        </Context.Provider>
    )
}

export { Context, AuthProvider };