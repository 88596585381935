export const PERMISSIONS = {    
        SysAdmin: "system.admin",
        Admin: "cultivares.admin",
        CulturaRead: "cultivares.cultura.read",
        CulturaWrite: "cultivares.cultura.write",
        EventoRead: "cultivares.evento.read",
        EventoWrite: "cultivares.evento.write",
        
        InstresponsavelRead: "cultivares.instresponsavel.read",
        InstresponsavelWrite: "cultivares.instresponsavel.write",

        MelhoristaRead: "cultivares.melhorista.read",
        MelhoristaWrite: "cultivares.melhorista.write",
        RequerenteRead: "cultivares.requerente.read",
        RequerenteWrite: "cultivares.requerente.write",
        TiporegistroRead: "cultivares.tiporegistro.read",
        TiporegistroWrite: "cultivares.tiporegistro.write",
        ReqRegistroRead: "cultivares.reqregistro.read",
        ReqRegistroNovo: "cultivares.reqregistro.novo",
        ReqRegistroRegulador: "cultivares.reqregistro.regulador",
        ReqRegistroAvaliarDoenca: "cultivares.reqregistro.avaliardoenca",
        ReqRegistroAvaliarDescritor: "cultivares.reqregistro.avaliardescritor",
        ReqProtecaoRead: "cultivares.reqprotecao.read",
        ReqProtecaoNovo: "cultivares.reqprotecao.novo",
        ReqProtecaoRegulador: "cultivares.reqprotecao.regulador"
}