import React from "react";
import * as Bootstrap from "react-bootstrap";
import TextField from "./TextField";


interface RadioBoxProps
{
	form?       :any;
	name?       :string;
	label?      :string;
	isInvalid?  :boolean;
	placeholder?:string;
	control?    :any;
	length?     :number;	
	layoutColumns?  : number;
    options?        : any;
	isClearable?: boolean;
	required?       : boolean;
	enabled?        :boolean;
    isInvalidCheck? : boolean;
    onChange?       : any;
    onInputChange?       : any;
    onMenuClose?       : any;
    onBlur?       : any;
    key?		    : any;
	defaultChecked?   : boolean;
    value?          : any;
    checked?    :boolean;
    textBoxChecked?    : boolean;
    textBoxName?       : string;
}

export default function RadioBox(props: RadioBoxProps)
{
	const nameDef = "realRadioBox";
	const [enabled_, setEnabled] = React.useState<boolean>(true);
    const [asErro, setAsErro] = React.useState<boolean>(true);
    const [textBoxChecked, setTextBoxChecked] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(props.enabled === true || props.enabled === undefined) { setEnabled(true)}
        else {setEnabled (false); }
    }, [props.enabled, props.form?.errors, props?.name]);

    React.useEffect(() => {
        let _asErro = (props?.name ? props.form?.errors[props?.name] : undefined)  !== undefined;
        setAsErro(_asErro)
    }, [props.form?.errors, props?.name]);

    React.useEffect(()=>{
        if(props?.textBoxChecked !== null )
        { setTextBoxChecked(props?.textBoxChecked ?? false);  }
        else { setTextBoxChecked(false);}
    },[props?.textBoxChecked])

    React.useEffect(()=>{        
        if(props?.defaultChecked !== null )
        { 
            const rvalue = props?.defaultChecked ?? false;
            setChecked(rvalue);  
            if(rvalue === true){props.form?.setValue(props.name, props.value);}
            
        }       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.defaultChecked])

    return <Bootstrap.Form.Group className="RadioBoxMain" as={Bootstrap.Col} md={props.layoutColumns ?? 6}>
                <Bootstrap.Form.Label>{props.label} {props.required ? <em>*</em> : ''}                                         
                 <input
                    // key={props.key}
                    disabled={!enabled_}
                    placeholder={props.placeholder}
                    className="form-check-input"
                    ref={props.form?.register}
                    name={props.name ?? nameDef}
                    type="radio"
                    checked={(props.form?.watch(props.name) === props.value)}                    
                    onClickCapture={(value:any)=>
                    {
                        const check = props.form?.watch(props.name) === props.value;
                        if(check) { props.form?.setValue(props.name, props.value); }
                        setChecked(check);
                        if(props.onChange!=null) {props.onChange();}
                    }}
                    onChange={()=>{
                        
                    }}
                    onClick={props.onInputChange}
                    value={props.value}        
                    />
                {(textBoxChecked === true && checked === true) ?
                <TextField form={props.form} noLabel
				name={props.textBoxName} />
				: ''}
                <span className="geekmark"></span>    
                </Bootstrap.Form.Label>
                 
        </Bootstrap.Form.Group>;

}
