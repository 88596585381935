import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { Form, Col } from 'react-bootstrap';
import './styles.css';
import Button from '../../components/Button';
import If from '../../components/If';
import LoadingInForm from '../../components/LoadingInForm';
import AlertError from '../../components/AlertError';
import AlertSuccess from '../../components/AlertSuccess';
import { STRINGS } from '../../util/strings';
import viaCepApi from '../../services/viaCepApi';
import estadosApi from '../../services/estadosApi';
import { States, RequesterForm, Address } from '../../models/models';
import tempdb from '../../tempdb.json';


function Requester() {
	const areas = tempdb.area;
	const history = useHistory();
	const [successfulResponse, setSuccessfulResponse] = useState<boolean>(false);
	const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
	const [states, setStates] = useState<States>([]);

	const requesterSchema = yup.object().shape({
		name: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		rg: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		cpf: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		area: yup
			.string().required(STRINGS.FORM_FIELD_REQUIRED),
		phone: yup
			.string(),
		email: yup
			.string()
			.email(STRINGS.FORM_EMAIL_INVALID)
			.required(STRINGS.FORM_FIELD_REQUIRED),
		address: yup
			.string(),
		zipCode: yup
			.string(),
		city: yup
			.string(),
		state: yup
			.string(),
	});

	const initialValues: RequesterForm = {
		name: '',
		rg: '',
		cpf: '',
		area: '',
		phone: '',
		email: '',
		address: '',
		zipCode: '',
		city: '',
		state: states,
	};
	const { register, handleSubmit, errors, setValue, setError } = useForm({
		defaultValues: initialValues,
		mode: 'onTouched',
		resolver: yupResolver(requesterSchema),
	});


	useEffect(() => {
		getStates();
	}, [])

	const getStates = async () => {
		try {
			const { data } = await estadosApi.get('');
			setStates(data);
		} catch (error:any) {
			console.error(error)
		}
	}

	const setZipCodeError = () => {
		setError('zipCode', {
			type: "validate",
			message: STRINGS.ZIP_CODE_ERROR
		})
	}

	const getAddress = async (e: any) => {
		const zipCode = e.target.value;

		try {
			const { data } = await viaCepApi.get<Address>(`${zipCode}/json/`);

			if (data.bairro && data.logradouro)
				setValue('address', `${data.logradouro}, ${data.bairro}`);

			if (data.localidade)
				setValue('city', data.localidade);

			if (data.uf)
				setValue('state', data.uf);

			if (data.erro) {
				setZipCodeError();
			}
		} catch (error:any) {
			setZipCodeError();
		}
		setLoadingAddress(false)
	}

	const onSubmit = (data: any) => {
		// console.log(data);

		setSuccessfulResponse(true);
	};

	const verifyErrorsOnForm = (): boolean => {
		if (errors.name ||
			errors.email ||
			errors.rg ||
			errors.cpf ||
			errors.area) {
			return true;
		}
		return false;
	}

	return (
		<div className="container container-fluid">
			<If condition={verifyErrorsOnForm()}>
				<AlertError
					message={STRINGS.ALERT_WARNING_FIELDS}
				/>
			</If>
			<If condition={successfulResponse && !verifyErrorsOnForm()}>
				<AlertSuccess
					message={STRINGS.ALERT_MESSAGE_SUCCESS}
				/>
			</If>
			<h3>Cadastro de requisitante</h3>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Row className="row-custom">
					<Form.Group
						as={Col} md={6}
						controlId="formGridName"
					>
						<Form.Label>
							Nome do requisitante<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							name="name"
							placeholder="Insira o nome do requisitante"
							autoComplete="off"
							isInvalid={errors.name !== undefined}
							maxLength={100}
						/>
						<If condition={errors.name}>
							<Form.Control.Feedback type="invalid">
								{errors.name?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col} md={6} controlId="formGridEmail">
						<Form.Label>
							E-mail<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							type="email"
							name="email"
							placeholder="Insira o e-mail do requisitante"
							autoComplete="off"
							isInvalid={errors.email !== undefined}
							maxLength={100}
						/>
						<If condition={errors.email}>
							<Form.Control.Feedback type="invalid">
								{errors.email?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={4} controlId="formGridRG">
						<Form.Label>
							RG<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							placeholder="Insira o RG do requisitante"
							name="rg"
							isInvalid={errors.rg !== undefined}
							autoComplete="off"
							maxLength={25}
						/>
						<If condition={errors.rg}>
							<Form.Control.Feedback type="invalid">
								{errors.rg?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col} md={4} controlId="formGridCPF">
						<Form.Label>
							CPF<em>*</em>
						</Form.Label>
						<Form.Control
							ref={register}
							as={NumberFormat}
							getInputRef={register}
							type="text"
							placeholder="Insira o CPF do requisitante"
							name="cpf"
							isInvalid={errors.cpf !== undefined}
							format="###.###.###-##"
							autoComplete="off"
						/>
						<If condition={errors.cpf}>
							<Form.Control.Feedback type="invalid">
								{errors.cpf?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col} md={4} controlId="formGridPhone">
						<Form.Label>Telefone</Form.Label>
						<Form.Control
							ref={register}
							as={NumberFormat}
							getInputRef={register}
							type="text"
							placeholder="Insira o telefone do requisitante"
							name="phone"
							format="(##) #####-####"
							autoComplete="off"
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={3} controlId="formGridZipCode">
						<Form.Label>CEP</Form.Label>
						<Form.Control
							ref={register}
							as={NumberFormat}
							getInputRef={register}
							type="text"
							placeholder="Insira o CEP do requisitante"
							name="zipCode"
							isInvalid={errors.zipCode !== undefined}
							format="#####-###"
							autoComplete="off"
							onBlur={(e: any) => {
								getAddress(e);
								setLoadingAddress(true);
							}}
						/>
						<If condition={loadingAddress && !errors.zipCode}>
							<LoadingInForm />
						</If>
						<If condition={errors.zipCode}>
							<Form.Control.Feedback type="invalid">
								{errors.zipCode?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
					<Form.Group as={Col} md={1} controlId="formGridUF">
						<Form.Label>UF</Form.Label>
						<Form.Control
							as="select"
							name="state"
							ref={register}
						>
							<Form.Control
								as="option"
								label=''
							/>
							{states.map((state) => (
								<Form.Control
									key={state.id}
									as="option"
									label={state.sigla}
									value={state.sigla}
								/>
							))}
						</Form.Control>
					</Form.Group>
					<Form.Group as={Col} md={8} controlId="formGridCity">
						<Form.Label>Município</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							placeholder="Insira o município do requisitante"
							name="city"
							autoComplete="off"
							maxLength={100}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row className="row-custom">
					<Form.Group as={Col} md={12} controlId="formGridAddress">
						<Form.Label>Endereço</Form.Label>
						<Form.Control
							ref={register}
							type="text"
							placeholder="Insira o endereço do requisitante"
							name="address"
							autoComplete="off"
							maxLength={255}
						/>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Form.Group as={Col} md={6} controlId="formGridArea">
						<Form.Label>Área<em>*</em></Form.Label>
						<Form.Control
							as="select"
							name="area"
							ref={register}
							isInvalid={errors.area !== undefined}
						>
							<Form.Control
								as="option"
								label={STRINGS.SELECT_AREA}
							/>
							{areas.map(area => (
								<Form.Control
									as="option"
									key={area.id}
									label={area.setor}
									value={area.setor}
								/>
							))}
						</Form.Control>
						<If condition={errors.area}>
							<Form.Control.Feedback type="invalid">
								{errors.area?.message}
							</Form.Control.Feedback>
						</If>
					</Form.Group>
				</Form.Row>
				<div className="button-position">
					<Button
						title="Cancelar"
						type="button"
						contained={false}
						onClick={() => history.goBack()}
					/>
					<Button
						title="Cadastrar"
						type="submit"
						contained={true}
					/>
				</div>
			</Form>
		</div>
	);
}

export default Requester;
