import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './styles.css';
import { STRINGS } from '../../util/strings';
import Button from '../../components/Button';
import If from '../../components/If';
import InactiveButton from '../../components/InactiveButton';
import EditButton from '../../components/EditButton';

import { requesterResults } from '../../tempdb.json';

function Requester() {
  const history = useHistory();
  const [rowSelected, setRowSelected] = useState<boolean>(false);

  const requesterSchema = yup.object().shape({
    email: yup
      .string()
      .email(STRINGS.FORM_EMAIL_INVALID)
  });

  const { register, handleSubmit, errors, getValues } = useForm({
    defaultValues: {
      name: '',
      cpf: '',
      rg: '',
      email: '',
      active: false,
    },
    resolver: yupResolver(requesterSchema),
    mode: "onTouched"
  });

  const onSubmit = (data: any) => {
    // console.log(data);
  }

  const onRowSelected = (event: any) => {
    const { api } = event;
    // console.log(api.getSelectedNodes());

    if (!api.getSelectedNodes().length)
      setRowSelected(false);
    else
      setRowSelected(true)

  }

  const EditBtn = (props: any) => (
    <EditButton
      onClick={() => {}}
    />
  )

  return (
    <div className="container-custom-search">
      <div className="top-content">
        <h3>Consulta de requisitante</h3>
      </div>
      <div className="row-buttons">
        <Button
          onClick={() => history.push('/cadastrar/requisitante')}
          contained
          title="Adicionar"
          type="button"
        />
      </div>
      <br />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md={2}>
            <Form.Label>Nome do requisitante</Form.Label>
            <Form.Control
              ref={register}
              name="name"
              maxLength={100}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>RG</Form.Label>
            <Form.Control
              ref={register}
              name="rg"
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>CPF</Form.Label>
            <Form.Control
              ref={register}
              name="cpf"
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              ref={register}
              name="email"
              autoComplete="off"
              isInvalid={errors.email !== undefined}
            />
            <If condition={errors.email}>
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </If>
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              ref={register}
              name="active"
              label="Inativos"
            />
          </Form.Group>
          <span className="filter-btn">
            <Button
              title="Buscar"
              type="submit"
              contained
            />
          </span>
          <span className="filter-btn">
            <InactiveButton
              disable={!rowSelected}
              onClick={() => alert()}
            />
          </span>
        </Form.Row>
      </Form>
      <div className="search-content">
        <div className="ag-theme-alpine" style={{ height: "70vh", width: '100%' }}>
          <AgGridReact
            rowData={requesterResults.rows}
            defaultColDef={{ flex: 1, sortable: true }}
            suppressRowClickSelection={true}
            rowSelection={'multiple'}
            onRowSelected={onRowSelected}
            pagination
            paginationPageSize={10}
            frameworkComponents={{
              editBtn: EditBtn,
            }}
          >
            <AgGridColumn
              headerName="Selecionar"
              headerCheckboxSelection={true}
              checkboxSelection={true}
              pinned="left"
            />
            <AgGridColumn
              headerName='Nome'
              field='nome'
            />
            <AgGridColumn
              headerName="RG"
              field="RG"
            />
            <AgGridColumn
              headerName="CPF"
              field="CPF"
            />
            <AgGridColumn
              headerName="Email"
              field='email'
            />
            <AgGridColumn
              headerName="Inativo"
              field="Inativo"
            />
            <AgGridColumn
              field='Editar'
              cellRenderer='editBtn'
            />
          </AgGridReact>
        </div>
      </div>
    </div>
  );
}

export default Requester;