import React from 'react';
import { Modal } from 'react-bootstrap';

interface Props {
    show    : boolean;
    title   : any;
    onHide  : any;
    size?   : "sm" | "lg" | "xl" | undefined;
    children: any;
}

function MyModal({ show, title, children, onHide}: Props) {
    return (
        <Modal
            show={show}
            animation={true}
            onHide={onHide}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>{title}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
}

export default MyModal;