export const STRINGS_SHIPMENT = {
    NAV_SHIPMENT: "Shipment",
    NAV_REGISTERS: "Cadastros/Registrations",
    NAV_SOLICITACOES: "Solicitações/Requests",
    NAV_UNITYS_BASF: "Unidades-Estações BASF/BASF Units",
    NAV_CULTURE: "Cultura/Crop",
    NAV_EVENT: "Evento/Event",
    NAV_MATERIAL: "Material/Material",
    NAV_REQUISITANTES: "Requisitantes/Requestors",

    SEARCH_UNITIES_STATIONS: "Consulta de Unidades-Estações BASF/BASF Unit Query",
    REGISTER_UNITIES_STATIONS: "Cadastro de Unidades-Estações BASF/BASF Unit Registration",
    EDITION_UNITIES_STATIONS: "Edição de Unidades-Estações BASF/BASF Unit Edit",

    SEARCH_CULTURE: "Consulta de culturas/Crop Query",
    REGISTER_CULTURE: "Cadastro de cultura/Crop Registration",
    EDITION_CULTURE: "Edição de cultura/Crop Edit",

    SEARCH_MATERIAL: "Consulta de material/Material Query",
    REGISTER_MATERIAL: "Cadastro de material/Material Registration",
    EDITION_MATERIAL: "Edição de material/Material Edit",

    SEARCH_REQUISITANTES: "Consulta de requisitantes/Requestor Query",
    REGISTER_REQUISITANTES: "Cadastro de requisitantes/Requestor Registration",
    EDITION_REQUISITANTES: "Edição de requisitantes/Requestor Edit",

    ALERT_SEND_SUCCESS: "E-Mail enviado com sucesso/E-mail sent sucessfully",

    SEARCH_SHIPMENT: "Consulta de Solicitações de Shipment/Shipment Request's Query",

    REGISTER_SHIPMENT: "SOLICITAÇÃO SHIPMENT/SHIPMENT REQUEST",
    REGISTER_SHIPMENT_SUBTITLE: "Em acordo com o Parecer CONJUR/MCT n. 058/2010, compete às CIBios autorizar atividades e projetos que envolvam OGM da Classe de Risco I,  em regime de contenção,  englobando a importação e exportação de OGM e seus derivados",

    ANEXO_REM_CONFIRMATION_CANCEL: "Confirmar a excluir anexo/Exclude attachment?",
    ANEXO_REM_TITLE_CONFIRMATION: "Tem certeza/Are you sure?",
    ANEXO_REM_CONFIRM_LEFT_EDITION: "Sim, excluir/Yes, remove",
    ANEXO_REM_CANCEL_LEFT_EDITION: "Não, permanecer/No, maintain",

    ENVIAR_DATAS_FAIL_TITLE: "Campo de data invalido/Invalid data field!",
    ENVIAR_DATAS_FAIL_TEXT: "Campo não pode estar vazio ou ser preenchido com campo invalido. Verifique a seguinte data:",

    BLOCK_ONE: "1. Dados da Solicitação/Request Data",
    BLOCK_TWO: "2. Anexos/Attachment",
    BLOCK_THR: "3. Fluxo de Envio/Process Flow",

    BLOCK_CANCEL: "Dados do Cancelamento",
    
    CANCEL_MODAL_TITLE: "Cancelar requisição/Cancel request",
    SHIPMENT_CANCEL_BODY_MSG: "Essa ação não poderá ser desfeita. Insira o motivo do cancelamento abaixo:",
    SHIPMENT_CANCEL_SUCCESS: "Requisição cancelada com sucesso/Request canceled",
};