import React from 'react';
import * as Bootstrap from 'react-bootstrap';

export interface Props<RowType>
{
	columns					: Column<RowType>[];
	rows					: RowType[];
	striped?				: boolean;
	bordered?				: boolean;
	hover?					: boolean;
	noRowsText?				: string;
	styleTable?				: React.CSSProperties;
	styleHeader?			: React.CSSProperties;
	styleBody?				: React.CSSProperties;
	styleFooter?			: React.CSSProperties;
}

export interface Column<RowType>
{
	title					: JSX.Element | string;
	parser					: keyof RowType | {(row:RowType) : string | JSX.Element}
	style?					: React.CSSProperties;
	colspan?				: number;
}

export default class Table<RowType> extends React.Component<Props<RowType>>
{
	static defaultProps = {
		striped				: false,
		bordered			: false,
		hover				: false,
		styleTable			: {},
		styleHeader			: {},
		styleBody			: {},
		styleFooter			: {},
		noRowsText			: 'Nenhuma informação a ser exibida',
	};

	constructor (props:Props<RowType>)
	{
		super(props);
	}

	componentDidMount ()
	{
		
	}

	ParseRows ()
	{
		if (this.props.rows.length <= 0)
			return <tr><td colSpan={this.props.columns.length} style={{textAlign:'center'}}>{this.props.noRowsText}</td></tr>;

		return this.props.rows.map((row:RowType) => {
			return <tr>{this.props.columns.map((column:Column<RowType>) => {
				return <td style={column.style}>{this.ParseRowContent(column.parser, row)}</td>;
			})}</tr>;
		});
	}

	ParseRowContent (parser: keyof RowType | {(row:RowType) : string | JSX.Element}, row:RowType) : string | JSX.Element
	{
		if (typeof parser === 'function')
			return parser(row);

		return row[parser] as unknown as string;
	}

	render ()
	{
		return <Bootstrap.Table
			striped={this.props.striped}
			bordered={this.props.bordered}
			hover={this.props.hover}
			style={this.props.styleTable}
		>
			<thead style={this.props.styleHeader}>
				{this.props.columns.map((column:Column<RowType>) => {
					return <th style={column.style}>{column.title}</th>;
				})}
			</thead>
			<tbody style={this.props.styleBody}>
				{this.ParseRows()}
			</tbody>
			<tfoot style={this.props.styleFooter}>

			</tfoot>
		</Bootstrap.Table>;
	}
}